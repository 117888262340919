export const engineeringTeamTypes = [
    {
        value: "Bellevue Eastside Team",
        label: "Bellevue Eastside Team",
    },
    {
        value: "Bellevue-University Team",
        label: "Bellevue-University Team",
    },
    {
        value: "Tukwila Team",
        label: "Tukwila Team",
    },
    {
        value: "Seattle - 505 Union Station",
        label: "Seattle - 505 Union Station",
    },
    {
        value: "Seattle-Starbucks Center",
        label: "Seattle-Starbucks Center",
    },
    {
        value: "Seattle-Northgate Team",
        label: "Seattle-Northgate Team",
    },
    {
        value: "Seattle - Vulcan Team",
        label: "Seattle - Vulcan Team",
    },
    {
        value: "Tacoma Team",
        label: "Tacoma Team",
    },
    {
        value: "Tacoma-WSECU",
        label: "Tacoma-WSECU",
    },
    {
        value: "Tacoma-Urban Waters",
        label: "Tacoma-Urban Waters",
    },
    {
        value: "Hudson Pacific Team",
        label: "Hudson Pacific Team",
    },
    {
        value: "Steelwave Team",
        label: "Steelwave Team",
    },
    {
        value: "Portland Team",
        label: "Portland Team",
    },
    {
        value: "Portland-CCBC Team",
        label: "Portland-CCBC Team",
    },
    {
        value: "Portland-FCC Team",
        label: "Portland-FCC Team",
    },
    {
        value: "Reno Team",
        label: "Reno Team",
    },
    {
        value: "Phoenix Team",
        label: "Phoenix Team",
    },
    {
        value: "California-North Team",
        label: "California-North Team",
    },
    {
        value: "California-Sacramento",
        label: "California-Sacramento",
    },
];
