import types from "./types";
import cloneDeep from "lodash/cloneDeep";
import clone from "lodash/clone";
import setWith from "lodash/setWith";
import moment from "moment";

export default (
    state = {
        isSaving: false,
        selected: {
            accounting_awaiting_payment: [],
            accounting_ready_payment: [],
            accounting_closed: [],
        },
        vouchersSelected: {
            accounting_awaiting_payment: [],
            accounting_ready_payment: [],
            accounting_closed: [],
        },
        vouchers: [],
        voucherView: "accounting_awaiting_payment",
        columnToSort: null,
        sortDirection: "desc",
        accountingBatchViewInputParams: {
            startDate: moment().subtract(14, "days").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
        },
    },
    action
) => {
    switch (action.type) {
        case types.RECEIVE_VOUCHERS:
            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    [action.tab]: action.vouchers,
                },
            };
        case types.SWITCH_VOUCHER_VIEW:
            return Object.assign({}, state, {
                voucherView: action.voucherView,
            });
        case types.SORT_VOUCHERS:
            return Object.assign({}, state, {
                columnToSort: action.columnToSort,
                sortDirection: action.sortDirection,
            });
        case types.FILTER_VOUCHERS:
            return Object.assign({}, state, {
                filterQuery: action.filterQuery,
            });
        case types.SELECT_ALL:
            return Object.assign({}, state, {
                selected: action.selected,
            });
        case types.CHECKBOX_CLICK:
            // return Object.assign({}, state, {
            //     selected: action.selected,
            // })
            return setWith(cloneDeep(state), `selected.${action.tab}`, action.selected, clone);
        case types.UPDATE_VOUCHERS_SELECTED:
            // return Object.assign({}, state, {
            //     selected: action.selected,
            // })
            return setWith(cloneDeep(state), `vouchersSelected.${action.tab}`, action.selected, clone);
        case types.AUTOSAVE_NOT_SUCCESS:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
                saveError: action.saveError,
            });
        case types.AUTOSAVE_SUCCESS:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
                lastSaved: action.lastSaved,
            });
        case types.TABLE_DATA_LOADING:
            return {
                ...state,
                tableDataLoading: action.tableDataLoading,
            };
        case types.VOUCHERS_DATA_CHANGE:
            return setWith(cloneDeep(state), `${action.path}`, action.value, clone);
        case types.SET_ACCOUNTING_INPUT_PARAM:
            return {
                ...state,
                accountingBatchViewInputParams: {
                    ...state.accountingBatchViewInputParams,
                    [action.name]: action.value,
                },
            };
        default:
            return state;
    }
};
