import types from "./types";

/**
 * @deprecated see notes below
 */
const initContacts = (contactType, contacts) => {
    return {
        type: types.INIT_CONTACTS,
        contactType: contactType,
        value: contacts,
    };
};

/**
 * @deprecated see notes below
 */
const updateContact = (contactType, key, field, value) => {
    console.log(contactType);
    console.log(key);
    console.log(field);
    console.log(value);
    return {
        type: types.UPDATE_CONTACT,
        contactType: contactType,
        key: key,
        field: field,
        value: value,
    };
};

/**
 * @deprecated see notes below
 */
const updateContactCompany = (contactType, key, field, value) => {
    return {
        type: types.UPDATE_CONTACT_COMPANY,
        contactType: contactType,
        key: key,
        field: field,
        value: value,
    };
};

/**
 * @deprecated see notes below
 */
const deleteContact = (contactType, key) => {
    return {
        type: types.DELETE_CONTACT,
        contactType: contactType,
        key: key,
    };
};

/**
 * @deprecated see notes below
 */
const addContact = (contactType, contactKey) => {
    return {
        type: types.ADD_CONTACT,
        contactType: contactType,
        key: contactKey,
    };
};

/**
 * @deprecated all code ABOVE this is deprecated.  use functions below along with
 *  ContactContainer and ContactForm moving forward. The code above still being used
 * in some code until it can be changed to use ContactContainer and ContactForm
 */

const addContactElement = (contactType, index) => {
    return {
        type: types.ADD_CONTACT_ELEMENT,
        contactType: contactType,
        index: index,
    };
};

const updateContactElement = (contactType, index, field, value) => {
    return {
        type: types.UPDATE_CONTACT_ELEMENT,
        contactType: contactType,
        index: index,
        field: field,
        value: value,
    };
};

const deleteContactElement = (contactType, index) => {
    return {
        type: types.DELETE_CONTACT_ELEMENT,
        contactType: contactType,
        index: index,
    };
};

const initializeContacts = (contactType, contacts) => {
    return {
        type: types.INITIALIZE_CONTACTS,
        contactType: contactType,
        contacts: contacts,
    };
};

const updateContactElementCompany = (contactType, index, field, value) => {
    return {
        type: types.UPDATE_CONTACT_ELEMENT_COMPANY,
        contactType: contactType,
        index: index,
        field: field,
        value: value,
    };
};

export default {
    updateContact,
    addContact,
    deleteContact,
    updateContactCompany,
    initContacts,
    addContactElement,
    updateContactElement,
    deleteContactElement,
    initializeContacts,
    updateContactElementCompany,
};
