import actions from "./actions";
import { urls } from "../constants";
import { request } from "../../utils/axios-wrapper";
import utils from "../../utils/index";

const getConditions = (parentEntityTypeKey) => {
    return (dispatch, getState) => {
        dispatch(actions.setParentEntityTypeKey(parentEntityTypeKey));
        dispatch(getActionTypes());
        dispatch(getDocumentTypes(getState().complianceReducer.modal.data.actions, parentEntityTypeKey));
        dispatch(getConditionTypesByParentEntityKey(parentEntityTypeKey));
        dispatch(getStates());
    };
};
const getActionTypes = () => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        request({
            url: urls.get_action_types(),
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                const actionTypes = response.data.map((actionType) => {
                    return {
                        value: actionType.actionTypeKey,
                        label: actionType.displayName,
                    };
                });
                dispatch(actions.getActionTypes(actionTypes));
                dispatch(selectFromActions("actionType", getState().complianceReducer.conditions.actionTypes[0].value));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(utils.actions.snackbar(true, "error", "Unable to load action types. Please try again."));
                dispatch(utils.actions.loading(false));
            });
    };
};

const getDocumentTypes = (ruleActions, parentEntityTypeKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        request({
            url: urls.get_document_types(),
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                const documentTypes = response.data
                    .filter((docType) => {
                        return docType.parentEntityType.parentEntityTypeKey === Number(parentEntityTypeKey);
                    })
                    .map((documentType) => {
                        return {
                            value: documentType.documentTypeKey,
                            label: documentType.name,
                            display:
                                ruleActions.find(
                                    (action) => documentType.documentTypeKey === action.documentTypeKey
                                ) === undefined,
                        };
                    });
                documentTypes.unshift({ value: 0, label: "Select", display: true, disabled: true });
                dispatch(actions.getDocumentTypes(documentTypes));
                dispatch(
                    selectFromActions(
                        "documentType",
                        getState().complianceReducer.conditions.documentTypes.find((docType) => {
                            return docType.display;
                        }).value
                    )
                );
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(utils.actions.snackbar(true, "error", "Unable to load document types. Please try again."));
                dispatch(utils.actions.loading(false));
            });
    };
};

const getConditionTypesByParentEntityKey = (parentEntityKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        request({
            url: urls.get_condition_types(parentEntityKey),
            method: "get",
            params: {
                parentEntityKey,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                const conditionTypes = response.data.map((conditionType) => {
                    return {
                        value: conditionType.conditionTypeKey,
                        label: conditionType.displayName,
                        display: true,
                        conditionOperators: conditionType.conditionOperators.map((operator) => {
                            return {
                                value: operator.conditionOperatorKey,
                                label: operator.displayName,
                            };
                        }),
                    };
                });
                conditionTypes.unshift({
                    value: 0,
                    label: "Select",
                    display: true,
                    disabled: true,
                    conditionOperators: [],
                });
                dispatch(actions.getConditionTypes(conditionTypes));
                if (getState().complianceReducer.conditions.conditionTypes.length > 0)
                    dispatch(
                        selectConditions(
                            "conditionTypeKey",
                            getState().complianceReducer.conditions.conditionTypes[0].value
                        )
                    );
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(utils.actions.snackbar(true, "error", "Unable to load condition types. Please try again."));
                dispatch(utils.actions.loading(false));
            });
    };
};

const getStates = () => {
    return (dispatch) => {
        dispatch(utils.actions.loading(true));
        request({
            url: urls.get_states(),
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                const states = response.data.map((state) => {
                    return {
                        value: state.stateCode,
                        label: state.stateName,
                    };
                });
                dispatch(actions.getStates(states));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(utils.actions.snackbar(true, "error", "Unable to load states. Please try again."));
                dispatch(utils.actions.loading(false));
            });
    };
};

const selectConditions = (field, value) => {
    return (dispatch) => {
        dispatch(actions.selectConditions(field, value));
    };
};

const selectConditionType = (conditionTypeKey) => {
    return (dispatch) => {
        dispatch(actions.selectConditionType(conditionTypeKey));
    };
};

const selectFromActions = (field, value) => {
    return (dispatch) => {
        dispatch(actions.selectFromActions(field, value));
    };
};

const getRulesByParentEntityTypeKey = (parentEntityTypeKey) => {
    return (dispatch) => {
        dispatch(utils.actions.loading(true));
        dispatch(utils.actions.somethingSaving(true, "getRules"));
        request({
            url: urls.get_rules_by_parent_entity_type_key(),
            method: "get",
            params: {
                parentEntityTypeKey,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                response.data.forEach((rule) => (rule.initialPriority = rule.priority));
                sortActionsByActionTypeKey(response.data);
                sortRulesByPriority(response.data);
                dispatch(actions.getRulesByParentEntityTypeKey(response.data));
                dispatch(utils.actions.somethingSaving(false, "getRules"));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(utils.actions.snackbar(true, "error", "Unable to load rules. Please try again."));
                dispatch(utils.actions.somethingSaving(false, "getRules"));
                dispatch(utils.actions.loading(false));
            });
    };
};

const sortActionsByActionTypeKey = (rules) => {
    rules.forEach((rule) => {
        rule.actions.sort((a, b) => a.actionType.actionTypeKey - b.actionType.actionTypeKey);
    });
};

const sortRulesByPriority = (rules) => {
    rules.sort((a, b) => a.priority - b.priority);
};

const addCondition = () => {
    return (dispatch) => {
        dispatch(actions.addCondition());
        dispatch(updateConditionTypeDropdown());
    };
};

const removeCondition = (conditionTypeKey) => {
    return (dispatch) => {
        dispatch(actions.removeCondition(conditionTypeKey));
        dispatch(updateConditionTypeDropdown());
    };
};

const addAction = () => {
    return (dispatch) => {
        dispatch(actions.addAction());
        dispatch(updateDocumentTypeDropdown());
    };
};

const removeAction = (documentTypeKey) => {
    return (dispatch) => {
        dispatch(actions.removeAction(documentTypeKey));
        dispatch(updateDocumentTypeDropdown());
    };
};

const deleteRule = (ruleKey, parentEntityTypeKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        request({
            url: urls.delete_rule(ruleKey),
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                dispatch(getRulesByParentEntityTypeKey(parentEntityTypeKey));
                dispatch(toggleModal(false));
                dispatch(revertDropdownDisplays());
                dispatch(utils.actions.snackbar(true, "success", "Rule deleted."));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(utils.actions.snackbar(true, "error", "Unable to delete rule. Please try again."));
                dispatch(utils.actions.loading(false));
            });
    };
};

const updateRule = (ruleKey, parentEntityTypeKey) => {
    return (dispatch, getState) => {
        const { ruleName, ruleDescription, conditions, actions, priority } = getState().complianceReducer.modal.data;
        if (conditions.length < 1 || actions.length < 1) {
            dispatch(
                utils.actions.snackbar(true, "error", "Unable to add rule. Conditions and actions cannot be empty.")
            );
            return;
        }
        dispatch(utils.actions.loading(true));
        const updateRule = {
            name: ruleName,
            description: ruleDescription,
            parentEntityType: parentEntityTypeKey,
            conditions: conditions.map((condition) => {
                const { conditionOperator, conditionType, conditionValue } = condition;
                return {
                    conditionTypeKey: conditionType.conditionTypeKey,
                    conditionOperatorKey: conditionOperator ? conditionOperator.conditionOperatorKey : null,
                    value: conditionValue,
                };
            }),
            actions: actions.map((action) => {
                const { documentTypeKey, actionType } = action;
                return {
                    documentTypeKey,
                    actionTypeKey: actionType.actionTypeKey,
                };
            }),
            priority,
        };
        request({
            url: urls.update_rule(ruleKey),
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            data: updateRule,
        })
            .then((response) => {
                dispatch(toggleModal(false));
                dispatch(getRulesByParentEntityTypeKey(parentEntityTypeKey));
                dispatch(utils.actions.snackbar(true, "success", "Rule saved successfully."));
                dispatch(revertDropdownDisplays());
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                let updatePriority = false;
                getState().complianceReducer.rules.forEach((rule) => {
                    if (rule.priority !== rule.initialPriority) {
                        updatePriority = true;
                    }
                });
                if (updatePriority) {
                    dispatch(
                        utils.actions.snackbar(
                            true,
                            "error",
                            "Unable to update rule. Please save priority and then try again."
                        )
                    );
                } else {
                    dispatch(utils.actions.snackbar(true, "error", "Unable to update rule. Please try again."));
                }
                dispatch(utils.actions.loading(false));
            });
    };
};

const saveNewRule = (parentEntityTypeKey) => {
    return (dispatch, getState) => {
        const { ruleName, ruleDescription, conditions, actions, priority } = getState().complianceReducer.modal.data;
        if (conditions.length < 1 || actions.length < 1) {
            dispatch(
                utils.actions.snackbar(true, "error", "Unable to add rule. Conditions and actions cannot be empty.")
            );
            return;
        }
        const newRule = {
            name: ruleName,
            description: ruleDescription,
            parentEntityType: parentEntityTypeKey,
            conditions: conditions.map((condition) => {
                const { conditionOperator, conditionType, conditionValue } = condition;
                return {
                    conditionTypeKey: conditionType.conditionTypeKey,
                    conditionOperatorKey: conditionOperator.conditionOperatorKey,
                    value: conditionValue,
                };
            }),
            actions: actions.map((action) => {
                const { documentTypeKey, actionType } = action;
                return {
                    documentTypeKey,
                    actionTypeKey: actionType.actionTypeKey,
                };
            }),
            priority,
        };
        dispatch(utils.actions.loading(true));
        request({
            url: urls.add_rule(),
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: newRule,
        })
            .then((response) => {
                dispatch(toggleModal(false));
                dispatch(getRulesByParentEntityTypeKey(parentEntityTypeKey));
                dispatch(revertDropdownDisplays());
                dispatch(utils.actions.snackbar(true, "success", "Rule saved successfully."));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(utils.actions.snackbar(true, "error", "Unable to add rule. Please try again."));
                dispatch(utils.actions.loading(false));
            });
    };
};

const addRule = () => {
    return (dispatch) => {
        dispatch(actions.addRule());
        dispatch(actions.toggleModal(true));
    };
};

const editRule = (ruleKey) => {
    return (dispatch) => {
        dispatch(actions.editRule(ruleKey));
        dispatch(actions.toggleModal(true));
    };
};

const toggleModal = (toggle) => {
    return (dispatch) => {
        dispatch(actions.toggleModal(toggle));
    };
};

const handleRuleInputChange = (field, value) => {
    return (dispatch) => {
        dispatch(actions.handleRuleInputChange(field, value));
    };
};

const updateDocumentTypeDropdown = () => {
    return (dispatch) => {
        dispatch(actions.updateDocumentTypeDropdown());
    };
};

const updateConditionTypeDropdown = () => {
    return (dispatch) => {
        dispatch(actions.updateConditionTypeDropdown());
    };
};

const handleDragEnd = (dragResult, rules) => {
    const { destination, source } = dragResult;
    if (!destination) {
        return (dispatch) => {
            dispatch(actions.updateRuleOrder(rules));
        };
    }
    //editor says this will never get hit - but it does
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return (dispatch, getState) => {
            dispatch(actions.updateRuleOrder(rules));
        };
    }

    const updatedRules = reorder(rules, dragResult.source.index, dragResult.destination.index);

    return (dispatch, getState) => {
        dispatch(actions.updateRuleOrder(updatedRules));
    };
};

const reorder = (data, startIndex, endIndex) => {
    const result = [...data];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((rule, i) => {
        rule.priority = i + 1;
    });
    return result;
};

const updatePriorities = (parentEntityTypeKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        const newPriorities = getState().complianceReducer.rules.map((rule) => {
            return {
                ruleKey: rule.ruleKey,
                newPriority: rule.priority,
            };
        });
        request({
            url: urls.update_priority(),
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: newPriorities,
        })
            .then((response) => {
                dispatch(getRulesByParentEntityTypeKey(parentEntityTypeKey));
                dispatch(utils.actions.snackbar(true, "success", "Priorities saved."));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(utils.actions.snackbar(true, "error", "Unable to update priorities. Please try again."));
                dispatch(utils.actions.loading(false));
            });
    };
};

const getRulesByDocTypeKey = (documentTypeKey) => {
    return (dispatch) => {
        dispatch(utils.actions.loading(true));
        request({
            url: urls.get_rules_by_document_type_key(),
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                documentTypeKey,
            },
        })
            .then((response) => {
                sortActionsByActionTypeKey(response.data);
                dispatch(actions.getRulesByDocTypeKey(response.data));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(utils.actions.snackbar(true, "error", "Unable to load rules. Please try again."));
                dispatch(actions.getRulesByDocTypeKey([]));
                dispatch(utils.actions.loading(false));
            });
    };
};

const resetRules = () => {
    return (dispatch) => {
        dispatch(actions.getRulesByDocTypeKey([]));
    };
};

const resetPriorities = () => {
    return (dispatch) => {
        dispatch(actions.resetPriorities());
    };
};

const revertDropdownDisplays = () => {
    return (dispatch) => {
        dispatch(actions.revertDropdownDisplays());
    };
};

const getAllParentEntityTypes = () => {
    return (dispatch) => {
        dispatch(utils.actions.loading(true));
        request({
            url: urls.get_all_parent_entities(),
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                dispatch(utils.actions.loading(false));
                dispatch(actions.getAllParentEntityTypes(response.data));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(
                    utils.actions.snackbar(true, "error", "Unable to load parent entity types. Please try again.")
                );
                dispatch(utils.actions.loading(false));
            });
    };
};

export default {
    getConditions,
    getActionTypes,
    getDocumentTypes,
    getConditionTypesByParentEntityKey,
    getStates,
    selectConditions,
    selectFromActions,
    getRulesByParentEntityTypeKey,
    addAction,
    removeAction,
    addCondition,
    removeCondition,
    updateRule,
    addRule,
    editRule,
    handleRuleInputChange,
    updateDocumentTypeDropdown,
    selectConditionType,
    saveNewRule,
    toggleModal,
    updateConditionTypeDropdown,
    deleteRule,
    handleDragEnd,
    updatePriorities,
    getRulesByDocTypeKey,
    revertDropdownDisplays,
    resetPriorities,
    resetRules,
    getAllParentEntityTypes,
};
