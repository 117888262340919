import React, { Component } from "react";
import { connect } from "react-redux";
import CorporateDataDeals from "./CorporateDataDeals";
import operations from "./ducks/operations";
import actions from "./ducks/actions";
import includes from "lodash/includes";
import { push } from "connected-react-router";
import ReactGA from "react-ga4";

const testRole = "scenario-1-broker-1";

class CorporateDataDealsContainer extends Component {
    componentDidMount() {
        this.props.getInitialDeals(this.props.auth.userProfile.user);
        ReactGA.send({ hitType: "pageview", page: "/deals", title: "CDT Deals" });
    }
    componentWillUnmount() {
        // this.props.handleClearDealsState();
    }

    render() {
        return <CorporateDataDeals {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        role: testRole,
        deals: state.dealsReducer.deals,
        valid: state.authReducer.validFormValues,
        snackbarOpen: state.authReducer.snackbarOpen,
        dealView: includes(
            ["data_entry_unassigned", "data_entry_open", "data_entry_closed"],
            state.dealsReducer.dealView
        )
            ? state.dealsReducer.dealView
            : "data_entry_unassigned",
        columnToSort: state.dealsReducer.columnToSort,
        sortDirection: state.dealsReducer.sortDirection,
        filterQuery: state.dealsReducer.filterQuery,
        selected: state.dealsReducer.selected,
        isLoading: state.utilsReducer.isLoading,
        elementsSaving: state.utilsReducer.elementsSaving,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchVoucherView: (event, value) => {
            // dispatch(operations.switchVoucherView(value))
            dispatch(operations.switchTab(value));
        },
        handleSort: (columnName) => {
            dispatch(operations.handleSort(columnName));
        },
        handleSelect: (dealKey) => {
            dispatch(operations.selectDeal(dealKey));
        },
        handleFilter: (query) => {
            dispatch(operations.filterVouchers(query));
        },
        getInitialDeals: (user) => {
            dispatch(operations.getInitialDeals("dataEntry", user));
        },
        handleStartReviewingAsCorporateData: (deal) => {
            dispatch(operations.handleStartReviewingAsCorporateData(deal));
        },
        handleClearDealsState: () => {
            dispatch(actions.clearDealsState());
        },
        handleDisplayActiveListings: () => {
            dispatch(push("/listings"));
        }, // sw: (event, value) => {
        //     dispatch(operations.switchVoucherView(value))
        // },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateDataDealsContainer);
