import React from "react";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import RequestModalContainer from "../listing/RequestModalContainer";
import ReactGA from "react-ga4";

class OutsideCompModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createDealTypeSelectorOpen: false,
            showModal: false,
        };
    }

    handleShowModal = (spaceType, transactionType) => {
        this.setState({ showModal: !this.state.showModal });
        this.props.toggleRequestModal(spaceType, transactionType);
    };

    handleCreateFromActiveListing = () => {
        this.setState({ createDealTypeSelectorOpen: false });
        this.triggerEventFromDropdownRequest("existing");
        this.props.handleDisplayActiveListings();
    };

    handleCreateDealButtonReset = () => {
        this.setState({ createDealTypeSelectorOpen: false });
    };

    handleCreateWithoutListing = () => {
        this.setState({ createDealTypeSelectorOpen: true });
        this.triggerEventFromDropdownRequest("new");
    };

    triggerEventFromDropdownRequest = (newOrExisting) => {
        if (this.props.internal && this.props.dashboard) {
            ReactGA.event("Get Paid", {
                newOrExisting: newOrExisting,
            });
        } else if (this.props.internal) {
            ReactGA.event("New Deal Request", {
                newOrExisting: newOrExisting,
            });
        }
    };

    render() {
        const { classes, internal, dashboard } = this.props;
        const dropDownTitle =
            internal && dashboard ? "Get Paid" : internal && !dashboard ? "Create New Deal" : "Create External Comp";
        const modalType =
            internal && dashboard ? "internal-comp" : internal && !dashboard ? "internal-comp" : "outside-comp";
        return (
            <React.Fragment>
                <DropdownButton
                    title={dropDownTitle}
                    className={internal && dashboard ? `${classes.getPaid} whenOpen` : ""}
                    onClick={(e) => this.handleCreateDealButtonReset()}
                    pullRight
                >
                    {internal && !this.state.createDealTypeSelectorOpen && (
                        <React.Fragment>
                            <MenuItem disableAutoFocusItem className={classes.activeListingPrompt}>
                                Do you have an active KM Listing to remove in KM Connect / Kidder.com for this Deal?
                            </MenuItem>
                            <MenuItem
                                onClick={() => this.handleCreateFromActiveListing()}
                                className={classes.menuSubItem}
                            >
                                Yes
                            </MenuItem>
                            <MenuItem onClick={() => this.handleCreateWithoutListing()} className={classes.menuSubItem}>
                                No
                            </MenuItem>
                        </React.Fragment>
                    )}
                    {(this.state.createDealTypeSelectorOpen || !internal) && (
                        <React.Fragment>
                            {internal && (
                                <React.Fragment>
                                    <MenuItem disableAutoFocusItem className={classes.menuCategory}>
                                        Consulting (No Listing)
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal(null, "Consulting")}
                                    >
                                        Consulting Deal
                                    </MenuItem>
                                </React.Fragment>
                            )}
                            <MenuItem disabled disableAutoFocusItem className={classes.menuCategory}>
                                {internal ? "Lease (No Listing)" : "Lease"}
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Office", "Lease")}
                            >
                                Office Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Industrial", "Lease")}
                            >
                                Industrial Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Retail", "Lease")}
                            >
                                Retail Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Land", "Lease")}
                            >
                                Land Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Multifamily", "Lease")}
                            >
                                Multifamily Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Life Science", "Lease")}
                            >
                                Life Science Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Healthcare", "Lease")}
                            >
                                Healthcare Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Religious", "Lease")}
                            >
                                Religious Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Educational", "Lease")}
                            >
                                Educational Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Other", "Lease")}
                            >
                                Other Lease
                            </MenuItem>

                            <MenuItem disabled disableAutoFocusItem className={classes.menuCategory}>
                                {internal ? "Sale (No Listing)" : "Sale"}
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Office", "Sale")}
                            >
                                Office Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Industrial", "Sale")}
                            >
                                Industrial Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Retail", "Sale")}
                            >
                                Retail Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Land", "Sale")}
                            >
                                Land Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Hospitality", "Sale")}
                            >
                                Hospitality Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Multifamily", "Sale")}
                            >
                                Multifamily Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Life Science", "Sale")}
                            >
                                Life Science Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Healthcare", "Sale")}
                            >
                                Healthcare Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Religious", "Sale")}
                            >
                                Religious Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Educational", "Sale")}
                            >
                                Educational Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Other", "Sale")}
                            >
                                Other Sale
                            </MenuItem>
                        </React.Fragment>
                    )}
                </DropdownButton>
                <RequestModalContainer
                    {...this.props}
                    handleShowModal={this.handleShowModal}
                    showModal={this.state.showModal}
                    modalType={modalType}
                />
            </React.Fragment>
        );
    }
}

const styles = (theme) => ({
    getPaid: {
        backgroundColor: "#43A047",
        borderColor: "#43A047",
        "&:hover": {
            backgroundColor: "#2e7031",
        },
        "&:active": {
            backgroundColor: "#2e7031",
        },
        "&:focus": {
            backgroundColor: "#2e7031",
        },
    },
    card: {
        display: "flex",
        marginBottom: "40px",
        border: "1px solid #dddddd",
        boxShadow: "none",
        minHeight: "74px",
        backgroundColor: "#f4f4f4",
    },
    cardSelected: {
        display: "flex",
        marginBottom: "40px",
        border: "1px solid #2C99D2",
        boxShadow: "none",
    },
    details: {
        width: "100%",
    },
    content: {
        flex: "1 0 auto",
        height: "100%",
        padding: "16px !important",
        // listed as important because in this modal, cards get 24px paddingBottom added to last-item
    },
    cover: {
        width: 151,
    },
    controls: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    instruction: {
        marginTop: "24px",
        marginBottom: "24px",
    },
    propertyType: {
        textTransform: "capitalize",
    },
    close: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    createFromListing: {
        color: "#000",
        textDecoration: "none",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        paddingBottom: "8px",
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 600,
        background: "rgb(250,250,250)",
        "&>a:hover": {
            background: "rgb(250,250,250) !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#9e9e9e !important",
        },
    },
    menuTitle: {
        background: "rgb(250,250,250)",
        borderTop: "1px solid rgb(210,210,210)",
        borderBottom: "1px solid rgb(230,230,230)",
        "&>a:hover": {
            backgroundColor: "rgb(250,250,250) !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            fontWeight: 700,
            color: "#999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
    propertyAddy: {
        textTransform: "uppercase",
        fontSize: "13px",
    },
    activeListingPrompt: {
        fontSize: "14px",
        fontWeight: 900,
        background: "rgb(250,250,250)",
        "&>a:hover": {
            background: "rgb(250,250,250) !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#9e9e9e !important",
            whiteSpace: "normal !important",
        },
    },
});

export default withStyles(styles)(OutsideCompModal);
