import React from "react";
import { Link } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import { withStyles } from "@material-ui/core/styles";
import { ListItem } from "@material-ui/core";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import Receipt from "@material-ui/icons/Receipt";
import Dashboard from "@material-ui/icons/Dashboard";
import LocationCity from "@material-ui/icons/LocationCity";
import MeetingRoom from "@material-ui/icons/MeetingRoom";
import Settings from "@material-ui/icons/Settings";
import People from "@material-ui/icons/People";
import CompareArrows from "@material-ui/icons/CompareArrows";
import Assessment from "@material-ui/icons/Assessment";
import includes from "lodash/includes";
import KMSvg from "../km-logo";
import "../../styles/components/NavBar.css";
import MoreDrawerLinks from "./MoreDrawerLinks";

const styles = (theme) => ({
    drawerPaper: {
        backgroundColor: "transparent",
        overflowY: "auto",
        position: "sticky",
        top: 0,
        width: "220px",
        height: "100vh",
    },
    logo: {
        marginLeft: "15px",
        marginTop: "15px",
        marginBottom: "70px",
    },
    icon: {
        marginRight: "10px",
        width: "20px",
    },
    activeItem: {
        backgroundColor: "#486f84",
        borderRadius: "3px",
    },
    listItem: {
        "&:hover": {
            backgroundColor: "#233a47",
            borderRadius: "3px",
        },
    },
});

const NavDrawer = (props) => {
    const { classes, role, user, currentPath, authenticatedUserProfile } = props;

    const fadeInMillis = 600;
    const fadeOutMillis = 200;

    return (
        <Drawer
            className="drawerPaper"
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.logo}>
                <KMSvg />
            </div>
            <List>
                {(authenticatedUserProfile.role === "dataEntry" ||
                    authenticatedUserProfile.role === "dataAdmin" ||
                    authenticatedUserProfile.user === user) &&
                    includes(["broker", "evp", "portlandBrokerAdmin", "arizonaBrokerAdmin"], role) && (
                        <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                            <Link className="navOption" to="/dashboard">
                                <ListItem
                                    className={currentPath === "dashboard" ? classes.activeItem : classes.listItem}
                                >
                                    <Dashboard className={classes.icon} />
                                    Dashboard
                                </ListItem>
                            </Link>
                        </Fade>
                    )}
                {includes(
                    [
                        "accounting",
                        "appraiser",
                        "broker",
                        "dataEntry",
                        "dataAdmin",
                        "debtEquity",
                        "evp",
                        "general",
                        "officeManager",
                        "research",
                        "researchAdmin",
                        "portlandBrokerAdmin",
                        "arizonaBrokerAdmin",
                        "propertyManagement",
                        "transition",
                    ],
                    role
                ) && (
                    <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                        <Link className="navOption" to="/properties">
                            <ListItem className={currentPath === "properties" ? classes.activeItem : classes.listItem}>
                                <LocationCity className={classes.icon} />
                                Properties
                            </ListItem>
                        </Link>
                    </Fade>
                )}
                {includes(
                    [
                        "accounting",
                        "appraiser",
                        "broker",
                        "dataAdmin",
                        "dataEntry",
                        "debtEquity",
                        "evp",
                        "general",
                        "officeManager",
                        "research",
                        "researchAdmin",
                        "portlandBrokerAdmin",
                        "propertyManagement",
                        "arizonaBrokerAdmin",
                        "transition",
                    ],
                    role
                ) && (
                    <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                        <Link className="navOption" to="/listings">
                            <ListItem className={currentPath === "listings" ? classes.activeItem : classes.listItem}>
                                <MeetingRoom className={classes.icon} />
                                Listings
                            </ListItem>
                        </Link>
                    </Fade>
                )}
                {includes(
                    [
                        "broker",
                        "dataAdmin",
                        "dataEntry",
                        "debtEquity",
                        "evp",
                        "portlandBrokerAdmin",
                        "arizonaBrokerAdmin",
                    ],
                    role
                ) && (
                    <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                        <Link className="navOption" to={"/deals"}>
                            <ListItem className={currentPath === "deals" ? classes.activeItem : classes.listItem}>
                                <Receipt className={classes.icon} />
                                Deals
                            </ListItem>
                        </Link>
                    </Fade>
                )}
                {includes(
                    [
                        "accounting",
                        "appraiser",
                        "broker",
                        "dataAdmin",
                        "dataEntry",
                        "debtEquity",
                        "evp",
                        "general",
                        "officeManager",
                        "research",
                        "researchAdmin",
                        "propertyManagement",
                        "portlandBrokerAdmin",
                        "transition",
                        "arizonaBrokerAdmin",
                    ],
                    role
                ) && (
                    <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                        <Link className="navOption" to="/comps">
                            <ListItem className={currentPath === "comps" ? classes.activeItem : classes.listItem}>
                                <BusinessCenter className={classes.icon} />
                                Comps
                            </ListItem>
                        </Link>
                    </Fade>
                )}
                {includes(
                    [
                        "accounting",
                        "appraiser",
                        "broker",
                        "dataAdmin",
                        "dataEntry",
                        "debtEquity",
                        "evp",
                        "general",
                        "officeManager",
                        "research",
                        "researchAdmin",
                        "portlandBrokerAdmin",
                        "arizonaBrokerAdmin",
                        "propertyManagement",
                        "transition",
                    ],
                    role
                ) && (
                    <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                        <Link className="navOption" to="/contacts">
                            <ListItem className={currentPath === "contacts" ? classes.activeItem : classes.listItem}>
                                <People className={classes.icon} />
                                Contacts
                            </ListItem>
                        </Link>
                    </Fade>
                )}
                {includes(["dataAdmin", "auditor", "dataEntry", "officeManager"], role) && (
                    <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                        <Link className="navOption" to="/audit/">
                            <ListItem className={currentPath === "audit" ? classes.activeItem : classes.listItem}>
                                <Assessment className={classes.icon} />
                                Audit
                            </ListItem>
                        </Link>
                    </Fade>
                )}
                {includes(["dataAdmin"], role) && (
                    <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                        <Link className="navOption" to="/admin/users">
                            <ListItem className={currentPath === "admin" ? classes.activeItem : classes.listItem}>
                                <Settings className={classes.icon} />
                                User Admin
                            </ListItem>
                        </Link>
                    </Fade>
                )}
                {includes(["dataAdmin", "dataEntry"], role) && (
                    <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                        <Link className="navOption" to="/admin/companies">
                            <ListItem className={currentPath === "admin" ? classes.activeItem : classes.listItem}>
                                <Settings className={classes.icon} />
                                Company Admin
                            </ListItem>
                        </Link>
                    </Fade>
                )}
                {includes(["dataAdmin"], role) && (
                    <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                        <Link className="navOption" to="/document-type">
                            <ListItem
                                className={currentPath === "document-type" ? classes.activeItem : classes.listItem}
                            >
                                <Settings className={classes.icon} />
                                Document Admin
                            </ListItem>
                        </Link>
                    </Fade>
                )}
                {includes(["accounting"], role) && (
                    <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                        <Link className="navOption" to={"/vouchers"}>
                            <ListItem className={currentPath === "vouchers" ? classes.activeItem : classes.listItem}>
                                <Receipt className={classes.icon} />
                                Vouchers
                            </ListItem>
                        </Link>
                    </Fade>
                )}
                {includes(["debtEquity"], role) && (
                    <Fade in={true} timeout={{ enter: fadeInMillis, exit: fadeOutMillis }}>
                        <Link className="navOption" to="/transactions">
                            <ListItem
                                className={currentPath === "transactions" ? classes.activeItem : classes.listItem}
                            >
                                <CompareArrows className={classes.icon} />
                                Transactions
                            </ListItem>
                        </Link>
                    </Fade>
                )}
            </List>
            {!includes(["auditor"], role) && <MoreDrawerLinks />}
        </Drawer>
    );
};

export default withStyles(styles)(NavDrawer);
