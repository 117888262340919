import React from "react";
import ReactExport from "react-data-export";
import moment from "moment";
import { round } from "../utils/operations";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ExcelExport extends React.Component {
    componentDidUpdate() {
        if (this.props.data != null) {
            this.props.clearExport();
        }
    }

    render() {
        const { data, filename, fileExtension, sheetName, columnDefinitions } = this.props;

        const dataSetTemplate = [
            {
                columns: [],
                data: [],
            },
        ];

        const disclaimerDataSet = [
            {
                columns: [],
                data: [],
            },
        ];

        disclaimerDataSet[0].columns.push({ title: "Please Read Disclaimer: " });
        disclaimerDataSet[0].data.push([
            { value: `© 2019 - ${new Date().getFullYear()} Kidder Mathews Inc and it’s subsidiaries and/or assigns. ` },
        ]);
        disclaimerDataSet[0].data.push([
            {
                value: "This information supplied herein is from sources we deem reliable. It is provided without any representation, warranty, ",
            },
        ]);
        disclaimerDataSet[0].data.push([
            {
                value: "or guarantee, expressed or implied as to its accuracy. Prospective Buyer or Tenant should conduct an independent investigation ",
            },
        ]);
        disclaimerDataSet[0].data.push([
            {
                value: "and verification of all matters deemed to be material, including, but not limited to, statements of income and expenses. ",
            },
        ]);
        disclaimerDataSet[0].data.push([
            {
                value: "Consult your attorney, accountant, or other professional advisor. All information is proprietary to Kidder Mathews and ",
            },
        ]);
        disclaimerDataSet[0].data.push([
            {
                value: "intended for the sole purpose of providing consultancy services to clients and should not be reproduced, distributed or used ",
            },
        ]);
        disclaimerDataSet[0].data.push([
            {
                value: "for any other purpose nor divulged to third party data collection agencies as outlined by corporate policy.",
            },
        ]);

        const addDataToSet = (dataExport, dataSet) => {
            //Add columns to set
            columnDefinitions.forEach((colDef, colIndex) => {
                const columnHeader = {
                    title: colDef.label,
                    width: colDef.width,
                };
                dataSet[0].columns.push(columnHeader);
            });

            //Add rows of data for each result
            dataExport.forEach((element, index) => {
                if (element !== null) {
                    const rowValues = [];
                    columnDefinitions.forEach((colDef, colIndex) => {
                        if (element.hasOwnProperty(colDef.name)) {
                            if (element.confidential_flg && colDef.confidential === true) {
                                const rowDataItem = {
                                    value: "",
                                    style: colDef.style,
                                };
                                rowValues.push(rowDataItem);
                            } else if (colDef.name === "km_managed_flg") {
                                const rowDataItem = {
                                    value: element[colDef.name] ? "Yes" : "No",
                                    style: colDef.style,
                                };
                                rowValues.push(rowDataItem);
                            } else if (colDef.name === "sublease_flg" || colDef.name === "imported_flg") {
                                const rowDataItem = {
                                    value: element[colDef.name] ? "Yes" : "No",
                                    style: colDef.style,
                                };
                                rowValues.push(rowDataItem);
                            } else if (colDef.label === "Deal Summary URL") {
                                const rowDataItem = {
                                    value: "https://connect.kidder.com/deal/" + element[colDef.name],
                                    style: colDef.style,
                                };
                                rowValues.push(rowDataItem);
                            } else if (colDef.label === "Listing Agent") {
                                let columnName =
                                    element["outside_listing_flg"] && element["outside_listing_flg"] === true
                                        ? "outside_listing_agent"
                                        : colDef.name;
                                const rowDataItem = {
                                    value: element[columnName] != null ? element[columnName] : "",
                                    style: colDef.style,
                                };
                                rowValues.push(rowDataItem);
                            } else if (colDef.label === "Company") {
                                let columnName =
                                    element["outside_listing_flg"] && element["outside_listing_flg"] === true
                                        ? "outside_listing_company"
                                        : colDef.name;
                                const rowDataItem = {
                                    value: element[columnName] != null ? element[columnName] : "",
                                    style: colDef.style,
                                };
                                rowValues.push(rowDataItem);
                            } else if (colDef.name === "listing_type") {
                                const rowDataItem = {
                                    value:
                                        element[colDef.name] === "LEASE"
                                            ? "For Lease"
                                            : element[colDef.name] === "SALE"
                                            ? "For Sale"
                                            : element[colDef.name],
                                    style: colDef.style,
                                };
                                rowValues.push(rowDataItem);
                            } else if (
                                colDef.name === "date_available" ||
                                colDef.name === "expiration_date" ||
                                colDef.name === "lease_expiration_date" ||
                                colDef.name === "commencement_date" ||
                                colDef.name === "transaction_date"
                            ) {
                                const rowDataItem = {
                                    value:
                                        element[colDef.name] && element[colDef.name].indexOf("T") > -1
                                            ? moment(
                                                  element[colDef.name].substring(0, element[colDef.name].indexOf("T"))
                                              ).format("MM/DD/YYYY")
                                            : "",
                                    style: colDef.style,
                                };
                                rowValues.push(rowDataItem);
                            } else if (colDef.name === "date_listed") {
                                let dateListed = element["date_listed"];
                                if (element["outside_listing_flg"]) {
                                    dateListed = element["audit_created_timestamp"];
                                }
                                const rowDataItem = {
                                    value:
                                        dateListed && dateListed.indexOf("T") > -1
                                            ? moment(dateListed.substring(0, dateListed.indexOf("T"))).format(
                                                  "MM/DD/YYYY"
                                              )
                                            : "",
                                    style: colDef.style,
                                };
                                rowValues.push(rowDataItem);
                            } else if (colDef.name === "ti_total") {
                                const rowDataItem = {
                                    value: element[colDef.name] != null ? round(element[colDef.name], 2) : "",
                                    style: colDef.style,
                                };
                                rowValues.push(rowDataItem);
                            } else {
                                const rowDataItem = {
                                    value: element[colDef.name] != null ? element[colDef.name] : "",
                                    style: colDef.style,
                                };
                                rowValues.push(rowDataItem);
                            }
                        }
                    });
                    dataSet[0].data.push(rowValues);
                }
            });
            return dataSet;
        };

        return (
            data &&
            data.length > 0 && (
                <ExcelFile filename={filename} fileExtension={fileExtension} hideElement={true}>
                    <ExcelSheet dataSet={disclaimerDataSet} name="DISCLAIMER"></ExcelSheet>
                    <ExcelSheet dataSet={addDataToSet(data, dataSetTemplate)} name={sheetName}></ExcelSheet>
                </ExcelFile>
            )
        );
    }
}

export default ExcelExport;
