import React from "react";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { us_states_options } from "../utils/constants";
import {
    propertyTypeFilterOptions,
    specialtyTypeFilterOptions,
    marketAreaOptions,
    submarketOptions,
    micromarketOptions,
} from "./constants";
import get from "lodash/get";
import MultiAddInput from "../../../common/js/components/MultiAddInput";
import Parcel from "./Parcel";
import ErrorBoundary from "../../../common/js/components/ErrorBoundary";

class PropertyForm extends React.Component {
    handlePropertyAttributesChange = (event) => {
        this.props.handleInputChange("property.propertyAttributes." + event.target.id, event.target.value);
    };

    handlePropertyChange = (event) => {
        this.props.handleInputChange("property." + event.target.id, event.target.value);
    };

    handlePropertyTypeChange = (event) => {
        this.handlePropertyChange(event);
        this.props.handleInputChange("property.propertyAttributes.specialtyType", "");
        this.props.handleInputChange("property.propertyAttributes.marketArea", "");
        this.props.handleInputChange("property.propertyAttributes.submarketArea", "");
        this.props.handleInputChange("property.propertyAttributes.micromarketArea", "");
    };

    handleMarketAreaChange = (event) => {
        let marketWithState = event.target.value;
        let marketArea = marketWithState.substring(5, marketWithState.length);

        if (marketArea === "") {
            this.props.handleInputChange("property.propertyAttributes.marketArea", "");
        } else {
            this.props.handleInputChange("property.propertyAttributes.marketArea", marketArea);
        }

        this.props.handleInputChange("property.propertyAttributes.submarketArea", "");
        this.props.handleInputChange("property.propertyAttributes.micromarketArea", "");
    };

    handleSubMarketChange = (event) => {
        this.handlePropertyAttributesChange(event);
        this.props.handleInputChange("property.propertyAttributes.micromarketArea", "");
    };

    handleAddParcel = () => {
        this.props.handleAddParcel();
    };

    handleAddAnchor = () => {
        this.props.handleAddAnchor();
    };

    render() {
        const {
            attributes,
            errors,
            disableInputs,
            propertyType,
            property,
            handleAddArrayElement,
            handleDeleteArrayElement,
            handleUpdateArrayElement,
        } = this.props;

        let stateCode = get(property, "primaryAddress.city.state.stateCode", "");

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <BSInput
                        id="propertyName"
                        label="PROPERTY NAME"
                        placeholder="Enter property name"
                        helperText={!disableInputs && "Required"}
                        errorText="Required"
                        error={get(errors, "propertyAttributes.propertyName")}
                        value={attributes.propertyName}
                        onChange={this.handlePropertyAttributesChange}
                        disabled={disableInputs === true}
                    />
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="primaryAddress.address1"
                            label="LOCATION ADDRESS"
                            placeholder="Enter location address"
                            helperText={!disableInputs && "Required"}
                            errorText="Required"
                            error={get(errors, "primaryAddress.address1", null)}
                            value={get(property, "primaryAddress.address1", null)}
                            onChange={this.handlePropertyChange}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={4}>
                        <BSInput
                            id="primaryAddress.city.city"
                            label="CITY"
                            error={get(errors, "primaryAddress.city.city", null)}
                            value={get(property, "primaryAddress.city.city", null)}
                            helperText={!disableInputs && "Required"}
                            errorText="Required"
                            onChange={this.handlePropertyChange}
                            placeholder="Enter city"
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSSelect
                            id="primaryAddress.city.state.stateCode"
                            label="STATE"
                            error={get(errors, "primaryAddress.city.state.stateCode", null)}
                            value={get(property, "primaryAddress.city.state.stateCode", null)}
                            helperText={!disableInputs && "Required"}
                            errorText="Required"
                            onChange={this.handlePropertyChange}
                            options={us_states_options}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSInput
                            id="primaryAddress.zipCode.zipCode"
                            label="ZIP CODE"
                            error={get(errors, "primaryAddress.zipCode.zipCode", null)}
                            value={get(property, "primaryAddress.zipCode.zipCode", null)}
                            helperText={!disableInputs && "Required"}
                            errorText="Required"
                            onChange={this.handlePropertyChange}
                            placeholder="Enter zip code"
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="primaryAddress.county.name"
                            label="PROPERTY COUNTY"
                            placeholder="Enter county"
                            error={get(errors, "primaryAddress.county.name", null)}
                            value={get(property, "primaryAddress.county.name", null)}
                            onChange={this.handlePropertyChange}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="propertyKey"
                            label="KM PROPERTY ID"
                            error={errors.propertyKey}
                            value={get(property, "propertyKey", null)}
                            disabled={Boolean(true)}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="secondaryAddress"
                            label="2ND ADDRESS"
                            placeholder="Enter 2nd address"
                            error={get(errors, "secondaryAddress", null)}
                            value={get(property, "secondaryAddress", null)}
                            onChange={this.handlePropertyChange}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="websiteUrl"
                            label="WEBSITE URL"
                            placeholder="Enter website url"
                            error={get(errors, "websiteUrl", null)}
                            value={get(property, "websiteUrl", null)}
                            onChange={this.handlePropertyChange}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSSelect
                            id="propertyType"
                            label="Property Type"
                            helperText={!disableInputs && "Required"}
                            errorText="Required"
                            error={get(errors, "propertyType")}
                            value={propertyType}
                            onChange={this.handlePropertyTypeChange}
                            options={propertyTypeFilterOptions()}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSSelect
                            id="specialtyType"
                            label="Sub-Type"
                            value={attributes.specialtyType}
                            error={get(errors, "propertyAttributes.specialtyType")}
                            onChange={this.handlePropertyAttributesChange}
                            options={specialtyTypeFilterOptions(propertyType)}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={4}>
                        <BSSelect
                            id="marketArea"
                            label="MARKET AREA"
                            value={
                                attributes.marketArea
                                    ? `${stateCode} - ${attributes.marketArea}`
                                    : attributes.marketArea
                            }
                            error={get(errors, "propertyAttributes.marketArea")}
                            onChange={this.handleMarketAreaChange}
                            options={marketAreaOptions(
                                propertyType,
                                get(property, "primaryAddress.city.state.stateCode", "")
                            )}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSSelect
                            id="submarketArea"
                            label="SUB-MARKET"
                            error={get(errors, "propertyAttributes.submarketArea")}
                            value={attributes.submarketArea}
                            onChange={this.handleSubMarketChange}
                            options={submarketOptions(propertyType, attributes.marketArea)}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSSelect
                            id="micromarketArea"
                            label="MICRO MARKET"
                            error={get(errors, "propertyAttributes.micromarketArea")}
                            value={attributes.micromarketArea}
                            onChange={this.handlePropertyAttributesChange}
                            options={micromarketOptions(propertyType, attributes.marketArea, attributes.submarketArea)}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <ErrorBoundary>
                            <MultiAddInput
                                addButtonText="Add Parcel"
                                disableInputs={disableInputs}
                                displayFirstWhenEmpty={true}
                                elements={attributes.parcels}
                                onAdd={() => handleAddArrayElement("property.propertyAttributes.parcels")}
                                onUpdate={(e, index) =>
                                    handleUpdateArrayElement(
                                        "property.propertyAttributes.parcels",
                                        index,
                                        e.target.id,
                                        e.target.value
                                    )
                                }
                                render={(element, elementIndex) => (
                                    <Parcel
                                        element={element}
                                        elementIndex={elementIndex}
                                        onDelete={(index) =>
                                            handleDeleteArrayElement("property.propertyAttributes.parcels", index)
                                        }
                                        {...this.props}
                                    />
                                )}
                            />
                        </ErrorBoundary>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default PropertyForm;
