import React from "react";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import schema from "./savedSearchesTable";
import TableCell from "@material-ui/core/TableCell";
import { Sorty } from "../../../common/js/icons";
import TableBody from "@material-ui/core/TableBody";
import get from "lodash/get";
import CircularProgress from "@material-ui/core/CircularProgress";
import Scroll from "react-scroll";
import { red } from "@material-ui/core/colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import includes from "lodash/includes";

class SavedSearchesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteDialog: false,
            itemToDelete: {},
        };
    }

    toggleDeleteDialog = (open, item) => {
        this.setState({ deleteDialog: open, itemToDelete: item });
    };

    handleDelete = (key, tabType) => {
        this.setState({ deleteDialog: false, itemToDelete: {} });
        if (tabType === "Search") {
            this.props.handleDeleteSavedSearch(key);
        } else if (tabType === "Group") {
            this.props.handleDeleteSavedGroup(key);
        }
    };

    render() {
        const {
            handleGridSort,
            showModal,
            classes,
            savedSearchData,
            savedGroupData,
            isLoading,
            toggleSavedSearchesModal,
            searchType,
            runSavedSearch,
            runSavedGroup,
            tab,
        } = this.props;

        const tabType = includes(["all_km_listings", "all_km_comps"], tab) ? "Search" : "Group";
        const searchTypeTitle =
            searchType === "listings" ? "Listings" : searchType === "comps" ? "Comps" : "Properties";
        const searchData =
            tabType === "Search" && savedSearchData
                ? savedSearchData
                : tabType === "Group" && savedGroupData
                ? savedGroupData
                : [];
        const tableName =
            tabType === "Group"
                ? "groups"
                : searchType === "property"
                ? "savedSearchesNoSelections"
                : "savedSearchesWithSelections";
        let Element = Scroll.Element;

        return (
            <div>
                <Modal aria-labelledby="saved-searches-modal" aria-describedby="view-saved-searches" open={showModal}>
                    <div style={getModalStyle()} className={classes.paper}>
                        {tabType === "Search" ? (
                            <h4 className="modal-title" style={{ paddingBottom: "25px", textAlign: "center" }}>
                                Saved Searches for {searchTypeTitle}
                            </h4>
                        ) : (
                            <h4 className="modal-title" style={{ paddingBottom: "25px", textAlign: "center" }}>
                                Saved Lists for {searchTypeTitle}
                            </h4>
                        )}
                        {tabType === "Search" ? (
                            <Grid container style={{ overflow: "auto" }} spacing={0}>
                                <Grid item xs style={{ minHeight: "25vh" }}>
                                    <Element style={{ height: "560px", overflowY: "scroll", overflowX: "hidden" }}>
                                        <Table className={classes.officeTable}>
                                            <TableHead>
                                                <TableRow>
                                                    {schema.viewHeaders[tableName].map((x, i) => (
                                                        <TableCell classes={classes} key={`thc-${i}`}>
                                                            <div
                                                                onClick={() => handleGridSort(x.name, tableName)}
                                                                style={
                                                                    x.name === "Search Name"
                                                                        ? searchType !== "property"
                                                                            ? {
                                                                                  display: "inline-block",
                                                                                  alignItems: "center",
                                                                                  width: "360px",
                                                                              }
                                                                            : {
                                                                                  display: "inline-block",
                                                                                  alignItems: "center",
                                                                                  width: "460px",
                                                                              }
                                                                        : x.name === "Delete Button"
                                                                        ? {
                                                                              display: "inline-block",
                                                                              alignItems: "center",
                                                                              paddingRight: 0,
                                                                          }
                                                                        : {
                                                                              display: "inline-block",
                                                                              alignItems: "center",
                                                                              minWidth: "110px",
                                                                          }
                                                                }
                                                            >
                                                                <span className={classes.sortable}>
                                                                    {x.name !== "Delete Button" ? x.name : ""}
                                                                </span>
                                                                {x.name !== "Delete Button" ? (
                                                                    <div style={{ display: "inline-block" }}>
                                                                        <Sorty />
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {searchData.length !== 0
                                                    ? searchData.map((x, i) =>
                                                          row(
                                                              x,
                                                              i,
                                                              classes,
                                                              isLoading,
                                                              searchType,
                                                              runSavedSearch,
                                                              runSavedGroup,
                                                              this.toggleDeleteDialog,
                                                              tableName
                                                          )
                                                      )
                                                    : ""}
                                            </TableBody>
                                        </Table>
                                        {isLoading ? (
                                            <div className={classes.loadingSpinner} style={{ marginTop: "25px" }}>
                                                <CircularProgress color="primary" />
                                            </div>
                                        ) : searchData.length === 0 ? (
                                            <div
                                                style={{
                                                    margin: "auto",
                                                    width: "25%",
                                                    paddingTop: "150px",
                                                    fontSize: "18px",
                                                    color: "rgba(0, 0, 0, 0.30)",
                                                }}
                                            >
                                                No Saved Searches
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </Element>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container style={{ overflow: "auto" }} spacing={0}>
                                <Grid item xs style={{ minHeight: "25vh" }}>
                                    <Element style={{ height: "560px", overflowY: "scroll", overflowX: "hidden" }}>
                                        <Table className={classes.officeTable}>
                                            <TableHead>
                                                <TableRow>
                                                    {schema.viewHeaders[tableName].map((x, i) => (
                                                        <TableCell classes={classes} key={`thc-${i}`}>
                                                            <div
                                                                onClick={() => handleGridSort(x.name, tableName)}
                                                                style={
                                                                    x.name === "List Name"
                                                                        ? {
                                                                              display: "inline-block",
                                                                              alignItems: "center",
                                                                              width: "360px",
                                                                          }
                                                                        : x.name === "Delete Button"
                                                                        ? {
                                                                              display: "inline-block",
                                                                              alignItems: "center",
                                                                              paddingRight: 0,
                                                                          }
                                                                        : {
                                                                              display: "inline-block",
                                                                              alignItems: "center",
                                                                              minWidth: "110px",
                                                                          }
                                                                }
                                                            >
                                                                <span className={classes.sortable}>
                                                                    {x.name !== "Delete Button" ? x.name : ""}
                                                                </span>
                                                                {x.name !== "Delete Button" ? (
                                                                    <div style={{ display: "inline-block" }}>
                                                                        <Sorty />
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {searchData.length !== 0 && schema.viewHeaders[tableName] !== undefined
                                                    ? searchData.map((x, i) =>
                                                          row(
                                                              x,
                                                              i,
                                                              classes,
                                                              isLoading,
                                                              searchType,
                                                              runSavedSearch,
                                                              runSavedGroup,
                                                              this.toggleDeleteDialog,
                                                              tableName
                                                          )
                                                      )
                                                    : ""}
                                            </TableBody>
                                        </Table>
                                        {isLoading ? (
                                            <div className={classes.loadingSpinner} style={{ marginTop: "25px" }}>
                                                <CircularProgress color="primary" />
                                            </div>
                                        ) : searchData.length === 0 ? (
                                            <div
                                                style={{
                                                    margin: "auto",
                                                    width: "25%",
                                                    paddingTop: "150px",
                                                    fontSize: "18px",
                                                    color: "rgba(0, 0, 0, 0.30)",
                                                }}
                                            >
                                                No Saved Groups
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </Element>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    style={{ marginTop: "25px", marginRight: "20px" }}
                                    onClick={() => toggleSavedSearchesModal(false)}
                                >
                                    Exit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Modal>

                <Dialog open={this.state.deleteDialog} maxWidth="sm" aria-labelledby="confirmation-dialog-title">
                    <DialogTitle style={{ textAlign: "center" }} id="confirmation-dialog-title">
                        Delete Saved Search?
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this Saved {tabType}? You will not be able to recover the{" "}
                        {tabType}.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleDeleteDialog(false, {})} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() =>
                                tabType === "Search"
                                    ? this.handleDelete(get(this.state.itemToDelete, "savedSearchKey", null), tabType)
                                    : this.handleDelete(get(this.state.itemToDelete, "savedGroupKey", null), tabType)
                            }
                            color="primary"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const row = (x, i, classes, isLoading, searchType, runSavedSearch, runSavedGroup, toggleDeleteDialog, tableName) => {
    return isLoading ? null : (
        <TableRow key={`tr-${i}`} selectable="false">
            {schema.viewHeaders[tableName].map((y, k) => (
                <TableCell
                    classes={{ root: classes.body }}
                    key={`trc-${k}`}
                    style={
                        y.name === "Delete Button"
                            ? { paddingRight: "10px", paddingLeft: 0 }
                            : y.name === "Retain Selections?"
                            ? { textAlign: "center" }
                            : { paddingRight: 0 }
                    }
                >
                    {y.name === "Delete Button" ? (
                        <Button
                            className={classes.deleteButton}
                            variant="outlined"
                            onClick={() => toggleDeleteDialog(true, x)}
                        >
                            Delete
                        </Button>
                    ) : y.name === "Retain Selections?" ? (
                        <span className={classes.sortable} style={{ minWidth: "100px" }}>
                            {get(x, y.prop, false) ? "Yes" : "No"}
                        </span>
                    ) : y.transform ? (
                        <span className={classes.sortable} style={{ minWidth: "100px" }}>
                            {y.transform(get(x, y.prop, null))}
                        </span>
                    ) : (
                        <div
                            onClick={() =>
                                tableName === "groups"
                                    ? runSavedGroup(get(x, "savedGroupKey", null), get(x, "selectedItems", null))
                                    : runSavedSearch(get(x, "savedSearchKey", null))
                            }
                        >
                            <span
                                className={
                                    y.name === "List Name" || y.name === "Search Name"
                                        ? classes.listingIdLink
                                        : classes.sortable
                                }
                                style={{ minWidth: "100px" }}
                            >
                                {get(x, y.prop, "N/A")}
                            </span>
                        </div>
                    )}
                </TableCell>
            ))}
        </TableRow>
    );
};

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        minWidth: "800px",
        maxHeight: "700px",
    };
}

const styles = (theme) => ({
    officeTable: {},
    loadingSpinner: {
        position: "absolute",
        left: "50%",
        top: "60%",
        transform: "translate(-50%, -50%)",
    },
    deleteButton: {
        border: "0px",
        color: red[500],
        "&:hover": {
            backgroundColor: red[100],
        },
    },
    sortable: {
        paddingRight: "6px",
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2 !important",
        fontWeight: "600 !important",
    },
});

export default withStyles(styles)(SavedSearchesModal);
