import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";

const styles = (theme) => ({
    close: {
        padding: theme.spacing.unit / 2,
    },
});

class Notification extends React.Component {
    state = {
        open: false,
    };

    handleClick = () => {
        this.setState({ open: true });
    };

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ open: false });
    };

    render() {
        const { open, onClose, message, autoHideDuration } = this.props;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={open}
                autoHideDuration={autoHideDuration}
                onClose={onClose}
                ContentProps={{
                    "aria-describedby": "message-id",
                }}
                message={<span id="message-id">{message}</span>}
            />
        );
    }
}

export default withStyles(styles)(Notification);
