import types from "./types";

const receiveComments = (comments) => {
    return {
        type: types.GET_COMMENTS,
        comments: comments,
    };
};

const handleChange = (field, value) => {
    return {
        type: types.MAIN_COMMENT_CHANGE,
        commentDraft: value,
    };
};

const toggleComments = (open) => {
    return {
        type: types.TOGGLE_COMMENTS_DRAWER,
        open: open,
    };
};

const clearComment = () => {
    return {
        type: types.CLEAR_COMMENT,
        commentDraft: "",
    };
};

const setStateForRequestUpdate = (redirectTo) => {
    return {
        type: types.SET_STATE_FOR_REQUEST_UPDATE,
        requestUpdateState: true,
        redirectTo: redirectTo,
    };
};

export default {
    receiveComments,
    handleChange,
    toggleComments,
    clearComment,
    setStateForRequestUpdate,
};
