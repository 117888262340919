import actions from "./actions";
import utilsActions from "../../utils/actions";
import utils from "../../utils/index";
import { push } from "connected-react-router";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import get from "lodash/get";
import set from "lodash/set";
import constants from "../constants";
import moment from "moment";
import { request } from "../../utils/axios-wrapper";

const getInitialVouchers = (role, user) => {
    return (dispatch, getState) => {
        const startDate = getState().vouchersReducer.accountingBatchViewInputParams.startDate;
        const endDate = getState().vouchersReducer.accountingBatchViewInputParams.endDate;

        switch (role) {
            case "accounting":
                dispatch(getVouchers(constants.urls.accounting_awaiting_payment(), "accounting_awaiting_payment"));
                dispatch(getVouchers(constants.urls.accounting_ready_payment(), "accounting_ready_payment"));
                dispatch(
                    getVouchers(constants.urls.accounting_closed_date_range(startDate, endDate), "accounting_closed")
                );
                break;
            default:
                dispatch(getVouchers(constants.urls.accounting_awaiting_payment(), "accounting_awaiting_payment"));
                dispatch(getVouchers(constants.urls.accounting_ready_payment(), "accounting_ready_payment"));
                dispatch(
                    getVouchers(constants.urls.accounting_closed_date_range(startDate, endDate), "accounting_closed")
                );
                break;
        }
    };
};

const getVouchers = (url, tab) => {
    return (dispatch, _) => {
        dispatch(utils.actions.somethingSaving(true, tab));
        request(
            {
                url: url,
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.receiveVouchers(response, tab));
                dispatch(utils.actions.somethingSaving(false, tab));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, tab));
            });
    };
};

const saveVoucher = (event) => {
    return (dispatch, getState) => {
        event.preventDefault();

        let url = null;

        request(
            {
                url: url,
                method: "get",
                data: {
                    voucherStatus: getState().authReducer.voucherStatus,
                },
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.retrieveVouchers(response.payload.voucherStatus, response.payload.voucherList));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.loginFailure(err));
            });
    };
};

const saveDocument = (url, file, mediaType) => {
    return (dispatch, _) => {
        const data = new FormData();
        data.append("file", file);
        data.append(
            "mediaAssetType",
            new Blob([JSON.stringify(constants["documentTypes"][mediaType]["display"])], {
                type: "application/json",
            })
        );
        request(
            {
                method: "post",
                url: url,
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.apiResponse(response));
            })
            .catch((err) => {
                dispatch(utils.actions.apiResponse(err));
            });
    };
};

// start voucher
const handleStart = (voucherId) => {
    return (dispatch, getState) => {
        dispatch(push(`/voucher/${voucherId}`));
    };
};

// sort vouchers
const handleSort = (columnName) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };
    return (dispatch, getState) => {
        let sortDirection =
            getState().vouchersReducer.columnToSort === columnName
                ? invertDirection[getState().vouchersReducer.sortDirection]
                : "asc";
        dispatch(actions.handleSort(columnName, sortDirection));
    };
};

// filter vouchers
const filterVouchers = (query) => {
    return (dispatch) => {
        if (typeof query === "string") query = query.toLowerCase();
        dispatch(actions.filterVouchers(query));
    };
};

// select voucher
const selectVoucher = (voucherId) => {
    return (dispatch, getState) => {
        dispatch(push(`/voucher/${voucherId}`));
    };
};

const switchVoucherView = (tab) => {
    return (dispatch, getState) => {
        dispatch(actions.switchVoucherView(tab));
    };
};

const handleSelectAll = (checked, indeterminate, tab) => {
    return (dispatch, getState) => {
        const state = getState().vouchersReducer;
        const numVouchers = state.vouchers[state.voucherView]["length"];
        let newSelected = [];
        let vouchersSelected = [];
        if (state.selected[tab].length === 0) {
            for (let i = 0; i < numVouchers; i++) {
                newSelected.push(`ch-${i}`);
            }
            each(state.vouchers[state.voucherView], (voucher) => {
                vouchersSelected.push(voucher.voucherKey);
            });
        } else {
        }
        dispatch(actions.updateVouchersSelected(vouchersSelected, tab));
        dispatch(actions.checkboxClick(newSelected, tab));
    };
};

const handleCheckboxClick = (id, tab, voucherId) => {
    return (dispatch, getState) => {
        const selected = getState().vouchersReducer.selected[tab];
        let newSelected = [];
        if (selected) {
            const selectedIndex = selected.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
                dispatch(addVoucherToSelected(voucherId, tab));
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
                dispatch(removeVoucherFromSelected(voucherId, tab));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
                dispatch(removeVoucherFromSelected(voucherId, tab));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
                dispatch(removeVoucherFromSelected(voucherId, tab));
            }
        } else {
            newSelected.push(id);
        }
        dispatch(actions.checkboxClick(newSelected, tab));
    };
};

const removeVoucherFromSelected = (voucherId, tab) => {
    return (dispatch, getState) => {
        const selected = cloneDeep(getState().vouchersReducer.vouchersSelected[tab]);
        console.log(selected);
        const index = selected.indexOf(voucherId);
        if (index > -1) {
            selected.splice(index, 1);
        }
        dispatch(actions.updateVouchersSelected(selected, tab));
    };
};

const addVoucherToSelected = (voucherId, tab) => {
    return (dispatch, getState) => {
        const selected = cloneDeep(getState().vouchersReducer.vouchersSelected[tab]);
        const index = selected.indexOf(voucherId);
        if (index > -1) {
            //
        } else {
            selected.push(voucherId);
        }
        dispatch(actions.updateVouchersSelected(selected, tab));
    };
};

const openPdf = (url) => {
    window.open(url.data, "_blank");
};

const generateBatch = () => {
    return (dispatch, getState) => {
        const sendToBatch = getState().vouchersReducer.vouchersSelected.accounting_ready_payment;
        request(
            {
                method: "put",
                url: constants.urls.send_to_batch(),
                data: sendToBatch,
                headers: { "Content-Type": "application/json" },
            },
            dispatch
        ).then((response) => {
            console.log("URL:" + response.data);
            openPdf(response);
            dispatch(actions.checkboxClick([], "accounting_ready_payment"));
            dispatch(actions.updateVouchersSelected([], "accounting_ready_payment"));
            dispatch(getVouchers(constants.urls.accounting_ready_payment(), "accounting_ready_payment"));
            dispatch(getVouchers(constants.urls.accounting_closed(), "accounting_closed"));
        });
    };
};

const sendToReadyForPayment = () => {
    return (dispatch, getState) => {
        const sendToReadyForPayment = getState().vouchersReducer.vouchersSelected.accounting_awaiting_payment;
        request(
            {
                method: "put",
                url: constants.urls.reopen_vouchers(),
                data: sendToReadyForPayment,
                headers: { "Content-Type": "application/json" },
            },
            dispatch
        ).then((response) => {
            dispatch(actions.checkboxClick([], "accounting_awaiting_payment"));
            dispatch(actions.updateVouchersSelected([], "accounting_awaiting_payment"));
            dispatch(getVouchers(constants.urls.accounting_awaiting_payment(), "accounting_awaiting_payment"));
            dispatch(getVouchers(constants.urls.accounting_closed(), "accounting_ready_payment"));
        });
    };
};

const reopenVouchers = () => {
    return (dispatch, getState) => {
        const unbatchList = getState().vouchersReducer.vouchersSelected.accounting_closed;
        request(
            {
                method: "put",
                url: constants.urls.reopen_vouchers(),
                data: unbatchList,
                headers: { "Content-Type": "application/json" },
            },
            dispatch
        ).then((response) => {
            dispatch(actions.checkboxClick([], "accounting_closed"));
            dispatch(actions.updateVouchersSelected([], "accounting_closed"));
            dispatch(getVouchers(constants.urls.accounting_ready_payment(), "accounting_ready_payment"));
            dispatch(getVouchers(constants.urls.accounting_closed(), "accounting_closed"));
        });
    };
};

const toggleProcessedBy = (processed, voucherId, tab) => {
    return (dispatch, getState) => {
        request(
            {
                method: "put",
                url: constants.urls.toggleProcess(voucherId),
                data: processed
                    ? {
                          acceptedBy: null,
                          acceptedDate: null,
                      }
                    : {
                          acceptedBy: getState().authReducer.userProfile.user,
                          acceptedDate: moment().format("YYYY-MM-DD HH:mm"),
                      },
                headers: { "Content-Type": "application/json" },
            },
            dispatch
        )
            .then((response) => {
                dispatch(markAsProcessed(response.data, tab));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

const generatePdf = (printed, voucherId, tab) => {
    return (dispatch, getState) => {
        if (printed) {
            dispatch(setPrintedBy(printed, voucherId, tab));
        } else {
            request(
                {
                    method: "get",
                    url: constants.urls.generatePdf(voucherId),
                    headers: { "Content-Type": "application/json" },
                },
                dispatch
            )
                .then((response) => {
                    console.log(response.data);
                    if (response.data.voucher) {
                        let voucherWindow = window.open("", "_blank");
                        voucherWindow.location.href = response.data.voucher;

                        dispatch(setPrintedBy(printed, voucherId, tab));
                    }
                    if (response.data.commission && response.data.commission.length > 0) {
                        let link = document.createElement("a");
                        link.href = response.data.commission[0];
                        link.dispatchEvent(new MouseEvent("click"));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
};

const setPrintedBy = (printed, voucherId, tab) => {
    return (dispatch, getState) => {
        request(
            {
                method: "put",
                url: constants.urls.setPrintedBy(voucherId),
                data: printed
                    ? {
                          printedBy: null,
                      }
                    : {
                          printedBy: getState().authReducer.userProfile.user,
                      },
                headers: { "Content-Type": "application/json" },
            },
            dispatch
        )
            .then((response) => {
                console.log("marking as printed");
                dispatch(markAsPrinted(response.data, tab));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

const markAsProcessed = (data, tab) => {
    return (dispatch, getState) => {
        let vouchers = cloneDeep(getState().vouchersReducer.vouchers[tab]);
        each(vouchers, (voucher) => {
            if (voucher.voucherKey === get(data, "voucherKey")) {
                set(voucher, "acceptedBy", data.acceptedBy);
                set(voucher, "acceptedDate", data.acceptedDate);
                set(voucher, "voucherStatus", get(data, "voucherStatus"));
            }
        });
        dispatch(actions.vouchersDataChange(`vouchers.${tab}`, vouchers));
    };
};

const markAsPrinted = (data, tab) => {
    return (dispatch, getState) => {
        let vouchers = cloneDeep(getState().vouchersReducer.vouchers[tab]);
        each(vouchers, (voucher) => {
            if (voucher.voucherKey === get(data, "voucherKey")) {
                set(voucher, "printedBy", data.printedBy);
            }
        });
        dispatch(actions.vouchersDataChange(`vouchers.${tab}`, vouchers));
    };
};

const changeAccountingInputParam = (name, value) => {
    return (dispatch, getState) => {
        dispatch(actions.setAccountingInputParam(name, value));
    };
};

const applyDateRangeFilter = () => {
    return (dispatch, getState) => {
        dispatch(utilsActions.clearErrors());

        // Validate the dates, if not valid or more than 30 days; display error
        const startDate = getState().vouchersReducer.accountingBatchViewInputParams.startDate;
        const endDate = getState().vouchersReducer.accountingBatchViewInputParams.endDate;
        const dateDiffInSeconds = Math.abs(new Date(endDate) - new Date(startDate)) / 1000;
        const daysDiff = Math.floor(dateDiffInSeconds / 86400);

        if (isNaN(daysDiff) || daysDiff > 31) {
            dispatch(utilsActions.addError("startDate", "Invalid date range"));
            dispatch(utilsActions.addError("endDate", "Date range must be 31 days or less"));
        } else {
            dispatch(getVouchers(constants.urls.accounting_closed_date_range(startDate, endDate), "accounting_closed"));
        }
    };
};

export default {
    saveDocument,
    handleStart,
    saveVoucher,
    switchVoucherView,
    handleSort,
    selectVoucher,
    filterVouchers,
    handleCheckboxClick,
    handleSelectAll,
    getVouchers,
    generateBatch,
    sendToReadyForPayment,
    toggleProcessedBy,
    setPrintedBy,
    generatePdf,
    getInitialVouchers,
    reopenVouchers,
    applyDateRangeFilter,
    changeAccountingInputParam,
};
