import React, { Component } from "react";
import { connect } from "react-redux";
import operations from "./ducks/operations";
import AccountingVouchers from "./AccountingVouchers";
import dealOperations from "../deals/ducks/operations";

class AccountingVouchersContainer extends Component {
    componentDidMount() {
        this.props.getInitialVouchers(this.props.userProfile.role, this.props.userProfile.user);
    }

    render() {
        return <AccountingVouchers {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.authReducer.userProfile,
        vouchers: state.vouchersReducer.vouchers,
        valid: state.authReducer.validFormValues,
        snackbarOpen: state.authReducer.snackbarOpen,
        voucherView: state.vouchersReducer.voucherView
            ? state.vouchersReducer.voucherView
            : "accounting_awaiting_payment",
        columnToSort: state.vouchersReducer.columnToSort,
        sortDirection: state.vouchersReducer.sortDirection,
        filterQuery: state.vouchersReducer.filterQuery,
        selectedBoxes: state.vouchersReducer.selected,
        selectedVouchers: state.vouchersReducer.vouchersSelected,
        tableDataLoading: state.vouchersReducer.tableDataLoading,
        elementsSaving: state.utilsReducer.elementsSaving,
        accountingBatchViewInputParams: state.vouchersReducer.accountingBatchViewInputParams,
        errors: state.utilsReducer.errors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchVoucherView: (event, value) => {
            dispatch(operations.switchVoucherView(value));
        },
        handleSort: (columnName) => {
            dispatch(operations.handleSort(columnName));
        },
        handleSelect: (dealKey) => {
            dispatch(operations.selectVoucher(dealKey));
        },
        handleViewDeal: (deal, voucherKey) => {
            dispatch(dealOperations.selectDeal(deal, voucherKey));
        },
        handleViewVoucherDeal: (voucher) => {
            dispatch(dealOperations.selectVoucherDeal(voucher));
        },
        handleFilter: (query) => {
            dispatch(operations.filterVouchers(query));
        },
        getInitialVouchers: (role, user) => {
            dispatch(operations.getInitialVouchers(role, user));
        },
        handleCheckboxClick: (id, tab, voucherId) => {
            dispatch(operations.handleCheckboxClick(id, tab, voucherId));
        },
        handleSelectAll: (event, tab) => {
            dispatch(operations.handleSelectAll(event.target.checked, event.target.indeterminate, tab));
        },
        generateBatch: () => {
            dispatch(operations.generateBatch());
        },
        sendToReadyForPayment: () => {
            dispatch(operations.sendToReadyForPayment());
        },
        reopenVouchers: () => {
            dispatch(operations.reopenVouchers());
        },
        toggleProcessedBy: (processed, voucherId, tab) => {
            console.log(tab);
            dispatch(operations.toggleProcessedBy(processed, voucherId, tab));
        },
        togglePrintedBy: (printed, voucherId, tab) => {
            console.log(tab);
            dispatch(operations.generatePdf(printed, voucherId, tab));
        },
        handleApplyDateRangeFilter: () => {
            dispatch(operations.applyDateRangeFilter());
        },
        handleChangeAccountingInputParam: (e) => {
            dispatch(operations.changeAccountingInputParam(e.target.id, e.target.value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountingVouchersContainer);
