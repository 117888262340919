import React from "react";
import { get, toLower } from "lodash";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import BSInput from "../../../../common/js/components/BSInput";
import BSSelect from "../../../../common/js/components/BSSelect";
import { propertyTypeFilterOptions } from "../constants";
import "./MixedUse.css";

const styles = () => ({
    centerCell: {
        fill: "#9e9e9e",
    },
    theCounter: {
        border: "1px solid #2C99D2",
        borderRadius: "50%",
        display: "inline-block",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 600,
        minWidth: "20px",
        minHeight: "20px",
    },
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    unitCount: {
        minWidth: "20%",
        paddingRight: "24px",
    },
    unitCountWIndex: {
        minWidth: "20%",
    },
});

const MixedUseDetail = (props) => {
    const {
        classes,
        dataPathPrefix,
        disableInputs,
        element,
        elementIndex,
        errors,
        handleUpdateArrayElement,
        onDelete,
    } = props;

    return (
        <div>
            <div className={elementIndex === 0 ? "mixedUseFlexContainer" : "mixedUseFlexContainerWElementIndex"}>
                <span className={classes.theCounter}>{parseInt(elementIndex) + 1}</span>
                <BSSelect
                    id="propertyType"
                    label={elementIndex === 0 ? "Property Type" : null}
                    error={get(errors, `mixedUses.${elementIndex}.propertyType`, null)}
                    value={element ? element.propertyType : null}
                    onChange={(e) =>
                        handleUpdateArrayElement(
                            dataPathPrefix + ".mixedUses",
                            elementIndex,
                            e.target.id,
                            e.target.value
                        )
                    }
                    options={propertyTypeFilterOptions()}
                    disabled={disableInputs}
                />
                <BSInput
                    id="sf"
                    label={elementIndex === 0 ? "Square Feet" : null}
                    errorText="(ex. 98101 or 981.01)"
                    placeholder="SF"
                    value={element ? element.sf : null}
                    error={get(errors, `mixedUses.${elementIndex}.sf`, null)}
                    onChange={(e) =>
                        handleUpdateArrayElement(
                            dataPathPrefix + ".mixedUses",
                            elementIndex,
                            e.target.id,
                            e.target.value
                        )
                    }
                    disabled={disableInputs}
                />

                <div className={elementIndex === 0 ? classes.unitCount : classes.unitCountWIndex}>
                    <BSInput
                        id="unitCount"
                        label={elementIndex === 0 ? "Unit Count" : null}
                        placeholder="Unit Count"
                        value={element ? element.unitCount : null}
                        error={get(errors, `mixedUses.${elementIndex}.unitCount`, null)}
                        onChange={(e) =>
                            handleUpdateArrayElement(
                                dataPathPrefix + ".mixedUses",
                                elementIndex,
                                e.target.id,
                                e.target.value
                            )
                        }
                        disabled={
                            disableInputs ||
                            (element &&
                                (toLower(element.propertyType) !== "hospitality" ||
                                    toLower(element.propertyType) !== "multifamily"))
                        }
                    />
                </div>
                <div className={classes.centerCell}>
                    {elementIndex > 0 && disableInputs !== true && (
                        <Button onClick={() => onDelete(elementIndex)} className={classes.shrinkButton}>
                            <Delete />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(MixedUseDetail);
