import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { get, debounce } from "lodash";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { company_type_options } from "../admin/constants";
import { us_states_options } from "../utils/constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "../../../common/js/components/Autocomplete";
import Search from "@material-ui/icons/Search";
import searchConstants from "../search/constants";

class CompanyForm extends Component {
    handleCompanyChange = (event) => {
        this.props.handleInputChange("company." + event.target.id, event.target.value);
    };

    handleChangeMultiple = (event) => {
        const { options } = event.target;
        const types = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                types.push(options[i].value);
            } else {
            }
        }
        this.props.handleInputChange("company.companyTypes", types);
    };

    handleParentCompanyCheck = (value, checked) => {
        if (checked === true) {
            this.props.handleInputChange("company.parentCompany", null);
        }
        this.props.handleInputChange(value, checked);
    };

    buildParentCompanyLabel = () => {
        if (get(this.props.company, `parentCompany`, null) !== null) {
            return [
                "Institutional Owner ",
                <span
                    onClick={() => {
                        this.props.handleInputChange("company.parentCompany", null);
                        this.props.handleChangeParentCompanyType("BUSINESS");
                    }}
                    className={this.props.classes.clearLink}
                >
                    (Clear)
                </span>,
            ];
        } else {
            return "Institutional Owner";
        }
    };

    debounceSearchParentCompany = debounce((handleSearch, term) => {
        let companyTypeFilter = get(this.props.company, `parentCompany.companyTypes`)
            ? get(this.props.company, `parentCompany.companyTypes`)
            : this.props.parentCompanyTypeFilter;
        handleSearch(term, companyTypeFilter);
    }, searchConstants["DEBOUNCE_SEARCH_DELAY"]);

    formatCompanyTypes(arr) {
        const companyTypesMap = {};
        const formattedCompanyTypes = [];
        company_type_options.forEach((ct) => {
            companyTypesMap[ct.value] = ct.label;
        });

        if (arr) {
            arr.forEach((e) => {
                formattedCompanyTypes.push(companyTypesMap[e]);
            });
        }
        return formattedCompanyTypes.join(", ");
    }

    render() {
        const {
            classes,
            errors,
            disableInputs,
            handleInputChange,
            parentCompanyTypeFilter,
            company,
            autocompleteResults,
            handleSearchParentCompany,
            handleChangeParentCompanyType,
        } = this.props;

        const parentCompanyType = get(company, `parentCompany.companyTypes`);
        console.log(errors);
        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <BSInput
                        id="legalName"
                        label="LEGAL NAME"
                        placeholder="Enter company legal name"
                        helperText={!disableInputs && "Required"}
                        errorText="Required"
                        error={get(errors, "company.legalName")}
                        value={company && company.legalName ? company.legalName : null}
                        onChange={this.handleCompanyChange}
                        disabled={disableInputs === true}
                    />
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <BSInput
                        id="displayName"
                        label="COMMON NAME"
                        placeholder="Enter company common name"
                        helperText={!disableInputs && "Required"}
                        errorText="Required"
                        error={get(errors, "company.displayName")}
                        value={company ? company.displayName : null}
                        onChange={this.handleCompanyChange}
                        disabled={disableInputs === true}
                    />
                </Grid>
                <Grid container alignItems="flex-start" spacing={8} style={{ paddingTop: "10px" }}>
                    <Grid item xs={9}>
                        <Autocomplete
                            id="institutionalOwner"
                            label={this.buildParentCompanyLabel()}
                            error={get(errors, `institutionalOwner`, "")}
                            initialInputValue={
                                company && company.parentCompany ? company.parentCompany.displayName : ""
                            }
                            itemToString={(i) => {
                                console.log("in itemToString in instOwner companyForm autocomplete");
                                console.log(i);
                                return get(i, `displayName`, null);
                            }} //change to display
                            onSelect={(company) => handleInputChange("company.parentCompany", company)}
                            placeholder="Enter Approved Company"
                            searchFunction={(input) =>
                                this.debounceSearchParentCompany(handleSearchParentCompany, input)
                            }
                            startAdornment={<Search />}
                            suggestions={autocompleteResults}
                            disabled={disableInputs || company.parentFlg}
                            errorText="Required"
                            options={{
                                key: "companyKey",
                                display: (suggestion) => {
                                    if (get(suggestion, "companyKey", "") === company.companyKey)
                                        return (
                                            <fieldset disabled={true}>
                                                {get(suggestion, "companyKey", "") +
                                                    " / " +
                                                    get(suggestion, "legalName", "") +
                                                    " / " +
                                                    get(suggestion, "displayName", "")}
                                            </fieldset>
                                        );
                                    else
                                        return (
                                            get(suggestion, "companyKey", "") +
                                            " / " +
                                            get(suggestion, "legalName", "") +
                                            " / " +
                                            get(suggestion, "displayName", "")
                                        );
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <BSSelect
                            label="Company Type"
                            id="companySearchType"
                            value={
                                parentCompanyType ? this.formatCompanyTypes(parentCompanyType) : parentCompanyTypeFilter
                            }
                            options={company_type_options}
                            onChange={(e) => {
                                handleChangeParentCompanyType(e.target.value);
                                handleInputChange("company.parentCompany", null);
                            }}
                            disabled={disableInputs || company.parentFlg}
                            extraInputStyles={{ paddingTop: "1px" }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: "-20px" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={company.parentFlg ? true : false}
                                onChange={(e) =>
                                    this.handleParentCompanyCheck("company." + e.target.value, e.target.checked)
                                }
                                value="parentFlg"
                                disabled={!company || (company.parentCompany !== null && company.parentCompany !== "")}
                                color="primary"
                            />
                        }
                        label="Is Institutional Owner / Parent Company"
                    />
                </Grid>
                <Grid container alignItems="flex-start" spacing={8} style={{ paddingTop: "20px" }}>
                    <Grid item xs={6}>
                        <FormControl
                            className={classes.formControl}
                            style={{ minWidth: 140, maxWidth: 300, paddingBottom: "40px" }}
                        >
                            <InputLabel shrink htmlFor="select-multiple-native">
                                Company Type
                            </InputLabel>
                            <Select
                                multiple
                                native
                                value={company.companyTypes}
                                onChange={(e) => this.handleChangeMultiple(e)}
                                inputProps={{
                                    id: "companyTypes",
                                    placeholder: "placeholder",
                                }}
                                // Hide dropdown icon by specifying a blank icon element
                                IconComponent={() => <i></i>}
                            >
                                {company_type_options.map((company) => (
                                    <option value={company.value}>{company.label}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: "20px", paddingLeft: "40px" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={company.approvedFlg ? true : false}
                                    onChange={(e) => handleInputChange("company." + e.target.value, e.target.checked)}
                                    value="approvedFlg"
                                    disabled={false}
                                    color="primary"
                                />
                            }
                            label="Approved by CDT"
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}></Grid>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="address1"
                            label="Address"
                            value={get(company, `mainAddress.address1`, "")}
                            error={get(errors, `mainAddress.address1`, null)}
                            onChange={(e) => handleInputChange("company.mainAddress.address1", e.target.value)}
                            placeholder="Enter address"
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={8}>
                    <Grid item xs={4}>
                        <BSInput
                            id="city"
                            label="City"
                            value={get(company, `mainAddress.city.city`, "")}
                            onChange={(e) => handleInputChange("company.mainAddress.city.city", e.target.value)}
                            placeholder="Enter city"
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSSelect
                            id="state"
                            label="State"
                            value={get(company, `mainAddress.city.state.stateCode`, "")}
                            error={get(errors, `mainAddress.city.state.stateCode`, null)}
                            onChange={(e) =>
                                handleInputChange("company.mainAddress.city.state.stateCode", e.target.value)
                            }
                            options={us_states_options}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSInput
                            id="zipCode"
                            label="Zip Code"
                            value={get(company, `mainAddress.zipCode.zipCode`, "")}
                            error={get(errors, `mainAddress.zipCode.zipCode`, null)}
                            onChange={(e) => handleInputChange("company.mainAddress.zipCode.zipCode", e.target.value)}
                            placeholder="Enter zip code"
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <BSInput
                        id="phoneNumber"
                        label="Phone"
                        value={get(company, `phoneNumber.phoneNumber`, "")}
                        error={get(errors, `phoneNumber.phoneNumber`, null)}
                        onChange={(e) => handleInputChange("company.phoneNumber.phoneNumber", e.target.value)}
                        errorText="Invalid"
                        placeholder="Enter contact phone"
                        disabled={disableInputs === true}
                        phone={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BSInput
                        id="webUrl"
                        label="COMPANY URL"
                        placeholder="Enter company url"
                        value={company ? company.webUrl : null}
                        error={get(errors, `licenseUrl`, null)}
                        onChange={(e) => handleInputChange("company.webUrl", e.target.value)}
                        disabled={disableInputs === true}
                    />
                </Grid>
                <Grid container alignItems="center" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="comments"
                            label="COMMENTS"
                            placeholder="Enter comments"
                            value={company ? company.comments : null}
                            multiline={true}
                            rows={8}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => handleInputChange("company.comments", e.target.value)}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const styles = (theme) => ({
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    connectorActive: {
        "& $connectorLine": {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        "& $connectorLine": {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        "& $connectorLine": {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create("border-color"),
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 800,
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
    menuRequestListing: {
        paddingBottom: "0px",
        "&>a": {
            paddingBottom: "0px !important",
        },
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
    },
    loadingPlaceholder: {
        marginTop: "3em",
        marginBottom: "1.5em",
        textAlign: "center",
        width: "100%",
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    lb: {
        textDecoration: "none",
    },
    propertyImage: {
        maxWidth: "100%",
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    propLink: {
        textDecoration: "none",
    },
    badge: {
        top: "1px",
        right: 0,
        width: "18px",
        height: "18px",
        fontSize: "11px", // spec says 12, but in the event of double digits in badge, it needs more room
        transform: "none",
        padding: 0,
        minWidth: 0,
    },
    sortable: {
        paddingRight: "5px",
    },
    toplessInput: {
        marginTop: 0,
    },
    badgePadding: {
        paddingRight: "24px",
    },
    filterInput: {
        height: "65px",
        marginBottom: "0px",
        minWidth: "10%",
    },
    divider: {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        margin: "0 -20px 0 -20px",
    },
    tabs: {
        borderBottom: "none",
        marginRight: "20px",
        position: "absolute",
        bottom: 0,
        width: "20em",
    },
    tabsNoAbsolute: {
        borderBottom: "none",
        marginRight: "20px",
        width: "20em",
    },
    tab: {
        borderBottom: "none",
        marginRight: "20px",
    },
    paperSpacing: {
        padding: "10px 20px",
        fontSize: 14,
        marginBottom: "15px",
    },
    clearLink: {
        textTransform: "none",
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
        },
    },
});

export default withStyles(styles)(CompanyForm);
