import React, { Component } from "react";
import { connect } from "react-redux";
import ConsultingDeal from "./ConsultingDeal";
import ConsultingDealToolbar from "./ConsultingDealToolbar";
import Grid from "@material-ui/core/Grid";
import Comments from "../comments/CommentsContainer";
import DocumentDrop from "../documents/DocumentDropContainer";
import operations from "./ducks/operations";
import searchOperations from "../search/ducks/operations";
import commentsOperations from "../comments/ducks/operations";
import commentsConstants from "../comments/constants";
import get from "lodash/get";
import utils from "../utils/index";
import actions from "./ducks/actions";
import ReactGA from "react-ga4";

class ConsultingDealContainer extends Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        ReactGA.send({ hitType: "pageview", page: "/consulting/*", title: `Consulting Deal Summary: ${id}` });
    }

    componentWillUnmount() {
        this.props.handleClearDealState();
        this.props.clearPropertyUpdate();
    }

    render() {
        const { id } = this.props.match.params;
        return (
            <div>
                <ConsultingDealToolbar {...this.props} />
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <ConsultingDeal {...this.props} />
                    </Grid>
                    <Grid item xs={6}>
                        <DocumentDrop
                            {...this.props}
                            entityKey={id}
                            type="voucher"
                            documentTypes="consulting"
                            formDisabled={false}
                            errors={get(this.props.errors, "documents")}
                            parentType="VOUCHER"
                            stateCode={get(
                                this.props.voucher,
                                "deal.property.primaryAddress.city.state.stateCode",
                                null
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Comments parentRecordType={commentsConstants.VOUCHER_PARENT_RECORD_TYPE} parentRecordId={id} />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.authReducer.userProfile,
        valid: state.authReducer.validFormValues,
        snackbarOpen: state.authReducer.snackbarOpen,
        isSaving: state.utilsReducer.isSaving,
        saveError: state.voucherReducer.saveError ? state.voucherReducer.saveError : null,
        lastSaved: state.voucherReducer.lastSaved ? state.voucherReducer.lastSaved : null,
        brokerCommissions: state.voucherReducer.voucher.brokerCommissions,
        outsideSplits: state.voucherReducer.outsideSplits,
        outsideSplitModal: state.voucherReducer.outsideSplitModal,
        numComments: state.commentsReducer.comments.length,
        voucher: state.voucherReducer.voucher,
        errors: state.voucherReducer.errors,
        outsideSplitsPresent: state.voucherReducer.outsideSplitsPresent,
        searchResults: state.utilsReducer.searchResults,
        autocompleteResults: state.searchReducer.autocompleteResults,
        snackbar: state.utilsReducer.snackbar,
        documentWarning: state.voucherReducer.documentWarning,
        confirmDialog: state.utilsReducer.confirmDialog,
        elementsSaving: state.utilsReducer.elementsSaving,
        updatedProperty: state.voucherReducer.updatedProperty,
        showDeleteDialog: state.voucherReducer.showDeleteDialog,
        deal: state.dealReducer.deal,
        openModal: state.voucherReducer.modals,
        displayDeleteDialog: state.voucherReducer.displayDeleteDialog,
        displayNoDeleteDialog: state.voucherReducer.displayNoDeleteDialog,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getVoucher: (voucherKey) => {
            dispatch(operations.handleGetVoucher(voucherKey));
        },
        handleSubmit: (save, callback, bypassConfirm) => {
            if (bypassConfirm) {
                dispatch(utils.operations.toggleConfirm());
            }
            dispatch(
                operations.handleSubmit(save, callback, "submit", true, {
                    validateDocs: true,
                    validateForm: true,
                    validateNonRequired: false,
                })
            );
        },
        handleSave: () => {
            dispatch(
                operations.handleSubmit(true, null, "saveAndContinue", false, {
                    validateDocs: false,
                    validateForm: false,
                    validateNonRequired: true,
                })
            );
        },
        handleSaveAndExit: () => {
            dispatch(
                operations.handleSubmit(true, null, "saveAndExit", true, {
                    validateDocs: false,
                    validateForm: false,
                    validateNonRequired: true,
                })
            );
        },
        toggleSnackBar: (open, variant, message) => {
            dispatch(utils.operations.snackbar(open, variant, message));
        },
        handleAutosaveNotSuccess: (isSaving, err) => {
            dispatch(operations.handleAutosaveNotSuccess(isSaving, err));
        },
        handleAutosaveSuccess: () => {
            dispatch(operations.handleAutosaveSuccess());
        },
        handleAddBrokerCommission: () => {
            dispatch(operations.handleAddBrokerCommission());
        },
        handleCreateOutsideSplitDraft: (enableFreeFormSearch, commissionType) => {
            dispatch(operations.createOutsideSplitDraft(enableFreeFormSearch, commissionType));
        },
        handleSaveOutsideSplit: (key, split) => {
            dispatch(operations.saveOutsideSplit(key, split));
        },
        handleDeleteOutsideSplit: (splitKey) => {
            dispatch(operations.deleteOutsideSplit(splitKey));
        },
        toggleOutsideSplitModal: (options) => {
            dispatch(operations.toggleOutsideSplitModal(options));
        },
        handleAddRunner: (parent) => {
            dispatch(operations.handleAddRunner(parent));
        },
        handleRemoveCommissionOrRunner: (options) => {
            dispatch(operations.handleRemoveCommissionOrRunner(options.key, options.parent, options.outside));
        },
        updateSplit: (splitKey, field, value, source) => {
            dispatch(operations.updateSplit(splitKey, field, value, source));
        },
        updateRunner: (parent, key, field, value) => {
            dispatch(operations.updateRunner(parent, key, field, value));
        },
        toggleComments: () => {
            dispatch(commentsOperations.toggleComments());
        },
        handleVoucherChange: (event) => {
            dispatch(operations.voucherChange(event.target.id, event.target.value));
        },
        handleCheckboxClick: (name, event) => {
            dispatch(operations.handleInput("voucher." + name, event.target.checked));
        },
        handleVoucherChangeAndCalculateSplits: (km, event) => {
            dispatch(operations.voucherChangeAndCalculateSplits(event.target.id, event.target.value, km));
        },
        determineOutsidePresent: (isPresent) => {
            dispatch(actions.determineOutsidePresent(isPresent));
        },
        handleSearchPerson: (inputValue) => {
            dispatch(operations.searchPerson(inputValue));
        },
        handleSearchOutsideBroker: (inputValue) => {
            dispatch(searchOperations.searchOutsideBroker(inputValue));
        },
        handleSearchCompany: (inputValue, companyType) => {
            dispatch(searchOperations.searchCompany(inputValue, companyType));
        },
        handleSearchBroker: (inputValue) => {
            dispatch(searchOperations.autoCompleteBroker(inputValue));
        },
        handleDocumentWarning: (open, proceed, docs) => {
            dispatch(operations.handleDocumentWarning(open, proceed, docs));
        },
        handleConfirmWarning: () => {
            dispatch(operations.handleDocumentWarning(false, true, []));
            dispatch(operations.saveVoucher(true));
        },
        toggleConfirm: (onSubmit) => {
            dispatch(utils.operations.toggleConfirm(onSubmit));
        },
        handleInput: (path, value) => {
            dispatch(operations.handleInput("voucher." + path, value));
        },
        handleReducerLevelInput: (path, value) => {
            dispatch(operations.handleInput(path, value));
        },
        handleClearDealState: () => {
            dispatch(
                operations.handleInput("voucher", {
                    deal: {},
                    brokerCommissions: {},
                })
            );
        },
        handleAddArrayElement: (path, object) => {
            dispatch(operations.addArrayElement("voucher." + path, object));
        },
        handleDeleteArrayElement: (path, index) => {
            dispatch(operations.deleteArrayElement("voucher." + path, index));
        },
        handleUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.handleInput("voucher." + arrayPath + "[" + index + "]." + field, value));
        },
        handleCalculateSplits: () => {
            dispatch(operations.handleCalculateSplits());
        },
        openModal: (isOpen, dealKey) => {
            dispatch(operations.openModal(isOpen, dealKey));
        },
        deleteDeal: (dealKey) => {
            dispatch(operations.handleDeleteDeal(dealKey));
        },
        handleChooseDisplayDeleteDialog: (isOpen, dealKey) => {
            dispatch(operations.displayDeleteDialog(isOpen, dealKey));
        },
        togglePropertySearchModal: (isOpen) => {
            dispatch(operations.togglePropertySearchModal(isOpen));
        },
        clearPropertyUpdate: () => {
            dispatch(operations.clearPropertyUpdate());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultingDealContainer);
