import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import Autocomplete from "../../../common/js/components/Autocomplete";
import { commencementDateTypes } from "../../../app/js/types/commencementDateTypes";
import { propertyManagerTypes } from "../../../app/js/types/propertyManagerTypes";
import { Add, Search } from "../../../common/js/icons";
import includes from "lodash/includes";
import get from "lodash/get";
import map from "lodash/map";
import Today from "../../../../node_modules/@material-ui/icons/Today";

class PropertyManagementInformation extends React.Component {
    toggleHistory = () => {
        this.setState({ seeHistory: !this.state.seeHistory });
    };

    handleUpdatePersonnelRole = (currentRole, currentPersonnel, newRole) => {
        const currentRoles = Object.keys(currentPersonnel);
        const updatedPersonnel = {};
        currentRoles.forEach((role) => {
            if (role === currentRole) {
                updatedPersonnel[newRole] = currentPersonnel[currentRole];
                updatedPersonnel[newRole]["role"] = newRole;
            } else {
                updatedPersonnel[role] = currentPersonnel[role];
            }
        });

        this.props.handleInput("personnel", updatedPersonnel);
    };

    handleNewPersonnel = () => {
        const propertyManagementKey = get(this.props.data, "propertyManagementKey");
        this.props.handleInput("personnel.Select", {});
        this.props.handleInput("personnel.Select.propertyManagementKey", propertyManagementKey);
        this.props.handleInput("personnel.Select.role", "Select");
    };

    isAddPersonnelValid = () => {
        if (get(this.props.data, "personnel")) {
            const usedPersonnelRoles = new Set(Object.keys(get(this.props.data, "personnel")));
            if (usedPersonnelRoles.size === propertyManagerTypes.length || usedPersonnelRoles.has("Select")) {
                return true;
            }
        }
        return false;
    };

    getOrderedPersonnel = () => {
        const orderedPersonnel = [];
        if (!("personnel" in this.props.data)) return orderedPersonnel;

        const personnelMap = this.props.data["personnel"];

        if ("Market Leader" in personnelMap) orderedPersonnel.push(personnelMap["Market Leader"]);
        if ("Senior Property Manager" in personnelMap) orderedPersonnel.push(personnelMap["Senior Property Manager"]);
        if ("Property Manager" in personnelMap) orderedPersonnel.push(personnelMap["Property Manager"]);
        if ("Property Admin" in personnelMap) orderedPersonnel.push(personnelMap["Property Admin"]);
        if ("Accounting" in personnelMap) orderedPersonnel.push(personnelMap["Accounting"]);
        if ("Associate Property Manager" in personnelMap)
            orderedPersonnel.push(personnelMap["Associate Property Manager"]);
        if ("Operations Manager" in personnelMap) orderedPersonnel.push(personnelMap["Operations Manager"]);
        if ("Other" in personnelMap) orderedPersonnel.push(personnelMap["Other"]);
        if ("Select" in personnelMap) orderedPersonnel.push(personnelMap["Select"]);

        return orderedPersonnel;
    };

    render() {
        const {
            classes,
            errors,
            handleDeleteArrayElement,
            disableInputs,
            data,
            handleInput,
            searchKMPerson,
            autocompleteResults,
        } = this.props;

        const disabledManagementRoles = ["Market Leader", "Property Admin", "Accounting"];

        const getPersonnelVal = (personnel) => {
            return personnel.person
                ? get(personnel, `person.firstName`, "") + " " + get(personnel, `person.lastName`, "")
                : "";
        };

        return (
            <div className={classes.managementInfoContainer}>
                <div className={classes.managementInfoContainerHeader}>
                    <Grid container justify="space-between" alignItems="center" spacing={16}>
                        <Grid item>
                            <h3 className={classes.managementHeading}>Management Information</h3>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" spacing={0}>
                                <Grid item>
                                    <span className={classes.defTerm}>Commencement Date:</span>
                                </Grid>
                                <Grid item xs>
                                    <BSInput
                                        id="commencementDate"
                                        value={data.commencementDate}
                                        placeholder="Enter date"
                                        extraInputStyles={{ borderRadius: "4px 0px 0px 4px", height: "2.24em" }}
                                        errorPlaceholder="hideIt"
                                        onChange={(e) => handleInput(e.target.id, e.target.value)}
                                        startAdornment={<Today />}
                                        disabled={disableInputs}
                                        noFullWidth={true}
                                        type="date"
                                        noHelperText={true}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <BSSelect
                                        id="commencementDateType"
                                        value={data.commencementDateType}
                                        placeholder="Effective Date"
                                        extraInputStyles={{ height: "21px", marginLeft: "-1px" }}
                                        onChange={(e) => handleInput(e.target.id, e.target.value)}
                                        combinedField="labelLessShiftLeft"
                                        disabled={disableInputs}
                                        noHelperText={true}
                                        options={[
                                            {
                                                value: "",
                                                label: "Select",
                                                disabled: true,
                                            },
                                            ...commencementDateTypes,
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                {this.getOrderedPersonnel().map((element, i) => (
                    <div
                        onDelete={(index) => handleDeleteArrayElement("personnel", index)}
                        {...this.props} // This needs to be refactored
                        className={classes.managementInfoContainerRow}
                    >
                        <Grid container justify="space-between" alignItems="center" spacing={16}>
                            <Grid item>
                                <Grid container alignItems="center" spacing={0}>
                                    <Grid item xs>
                                        <Autocomplete
                                            id="name"
                                            error={
                                                get(errors, `personnel.${i}.person`, null) !== null
                                                    ? get(errors, `personnel.${i}.person`, null)
                                                    : get(errors, `personnel.${i}.role`, null) !== null
                                                    ? " "
                                                    : null
                                            }
                                            initialInputValue={getPersonnelVal(element)}
                                            itemToString={(i) => (i == null ? "" : i.firstName + " " + i.lastName)}
                                            onSelect={(person) =>
                                                handleInput(`personnel.${element.role}.person`, person)
                                            }
                                            options={{
                                                key: "person",
                                                display: (suggestion) => {
                                                    return suggestion.firstName + " " + suggestion.lastName;
                                                },
                                            }}
                                            placeholder="Enter name"
                                            searchFunction={(input) => searchKMPerson(input)}
                                            startAdornment={<Search />}
                                            suggestions={autocompleteResults}
                                            disabled={disableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <BSSelect
                                            id="role"
                                            error={
                                                get(errors, `personnel.${i}.role`)
                                                    ? get(errors, `personnel.${i}.role`)
                                                    : get(errors, `personnel.${i}.person`)
                                                    ? " "
                                                    : null
                                            }
                                            value={element.role === "Select" ? "" : element.role}
                                            placeholder="Select"
                                            onChange={(e) =>
                                                this.handleUpdatePersonnelRole(
                                                    element.role,
                                                    get(data, "personnel"),
                                                    e.target.value
                                                )
                                            }
                                            combinedField="labelLessShiftLeft"
                                            options={[
                                                {
                                                    value: "",
                                                    label: "Select",
                                                    disabled: true,
                                                },
                                                ...map(propertyManagerTypes, function (p) {
                                                    return {
                                                        ...p,
                                                        disabled: includes(
                                                            [...Object.keys(get(data, "personnel", {}))],
                                                            p.value
                                                        ),
                                                    };
                                                }),
                                            ]}
                                            disabled={includes(disabledManagementRoles, element.role) || disableInputs}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                ))}
                {disableInputs !== true && (
                    <Button
                        className={classes.moveIt}
                        color="primary"
                        onClick={() => this.handleNewPersonnel()}
                        disabled={this.isAddPersonnelValid()}
                    >
                        <Add fill={this.isAddPersonnelValid() ? "#DCDCDC" : "#4b94c1"} /> Add Personnel
                    </Button>
                )}
            </div>
        );
    }
}

const styles = {
    defTerm: {
        fontSize: "13px",
        textTransform: "uppercase",
        color: "#9E9E9E",
        fontWeight: 600,
        display: "inline-block",
        padding: "0 10px 3px",
    },
    defData: {
        fontSize: "14px",
        color: "#000",
    },
    managementInfoContainer: {
        marginBottom: "20px",
    },
    managementInfoContainerHeader: {
        padding: "10px 12px",
        marginTop: "15px",
        border: "1px solid #dbdbdb",
    },
    managementInfoContainerRow: {
        padding: "11px 12px",
        border: "1px solid #dbdbdb",
        borderTop: "none",
        "&:last-of-type": {
            marginBottom: "8px",
        },
    },
    managementHeading: {
        margin: "7px 0",
    },
    moveIt: {
        margin: "0 0 10px",
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
};

export default withStyles(styles)(PropertyManagementInformation);
