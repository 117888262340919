const AUTOSAVE_NOT_SUCCESS = "AUTOSAVE_NOT_SUCCESS";
const AUTOSAVE_SUCCESS = "AUTOSAVE_SUCCESS";

const LEASE_DETAILS_CHANGE = "LEASE_DETAILS_CHANGE";
const PROPERTY_DETAILS_CHANGE = "PROPERTY_DETAILS_CHANGE";
const STATE_OBJECT_PROPERTY_CHANGE = "STATE_OBJECT_PROPERTY_CHANGE";
const COMP_SNACKBAR = "COMP_SNACKBAR";
const DOCUMENT_WARNING = "DOCUMENT_WARNING";
const COMP_VALID_DOCUMENTS = "COMP_VALID_DOCUMENTS";
const SWITCH_REVIEW_COMPS_TAB = "SWITCH_REVIEW_COMPS_TAB";
const RECEIVE_REVIEW_COMPS = "RECEIVE_REVIEW_COMPS";
const COUNT_REVIEW_COMPS = "COUNT_REVIEW_COMPS";
const ADD_OUTSIDE_COMP = "ADD_OUTSIDE_COMP";
const DELETE_COMP_ARRAY_ELEMENT = "DELETE_COMP_ARRAY_ELEMENT";
const GET_DEAL_REFACTOR = "GET_DEAL_REFACTOR";
const COMP_INPUT = "COMP_INPUT";
const SELECT_PROPERTY_FROM_AUTOCOMPLETE_OUTSIDE_COMP_MODAL = "SELECT_PROPERTY_FROM_AUTOCOMPLETE_OUTSIDE_COMP_MODAL";
const SET_CHECKED_COMPS = "SET_CHECKED_COMPS";
const TOGGLE_COMP_FOR_REPORT = "TOGGLE_COMP_FOR_REPORT";
const UPDATE_COMP_ORDER = "UPDATE_COMP_ORDER";
const UPDATE_SELECTED_COMP_ORDER = "UPDATE_SELECTED_COMP_ORDER";
const DELETE_OUTSIDE_COMP_DIALOG = "DELETE_OUTSIDE_COMP_DIALOG";
const CLEAR_ALL_COMPS = "CLEAR_ALL_COMPS";
const SET_COMPS_GROUP = "SET_COMPS_GROUP";
const UPDATE_SELECTED_COMPS_GROUP = "UPDATE_SELECTED_COMPS_GROUP";
const SET_GROUP_CHECKED_COMPS = "SET_GROUP_CHECKED_COMPS";
const UPDATE_SELECTED_BROKER_COMP = "UPDATE_SELECTED_BROKER_COMP";
const SET_SELECTED_BROKER_COMPS = "SET_SELECTED_BROKER_COMPS";
const TOGGLE_MODAL = "TOGGLE_MODAL";
const SET_DEAL_PARTY = "SET_DEAL_PARTY";
const TIME_ON_MARKET_INPUT = "TIME_ON_MARKET_INPUT";
const MARKETING_FLYER_PHOTO_INPUT = "MARKETING_FLYER_PHOTO_INPUT";
const GET_MARKETING_FLYER_PHOTO = "GET_MARKETING_FLYER_PHOTO";
const GET_MARKETING_FLYER_PHOTOS = "GET_MARKETING_FLYER_PHOTOS";
const SET_MARKETING_FLYER_PHOTO = "SET_MARKETING_FLYER_PHOTO";
const RESET_PHOTO = "RESET_PHOTO";
export default {
    AUTOSAVE_NOT_SUCCESS,
    AUTOSAVE_SUCCESS,
    STATE_OBJECT_PROPERTY_CHANGE,
    LEASE_DETAILS_CHANGE,
    PROPERTY_DETAILS_CHANGE,
    COMP_SNACKBAR,
    DOCUMENT_WARNING,
    COMP_VALID_DOCUMENTS,
    SWITCH_REVIEW_COMPS_TAB,
    RECEIVE_REVIEW_COMPS,
    COUNT_REVIEW_COMPS,
    ADD_OUTSIDE_COMP,
    DELETE_COMP_ARRAY_ELEMENT,
    GET_DEAL_REFACTOR,
    COMP_INPUT,
    SELECT_PROPERTY_FROM_AUTOCOMPLETE_OUTSIDE_COMP_MODAL,
    SET_CHECKED_COMPS,
    TOGGLE_COMP_FOR_REPORT,
    UPDATE_COMP_ORDER,
    UPDATE_SELECTED_COMP_ORDER,
    DELETE_OUTSIDE_COMP_DIALOG,
    CLEAR_ALL_COMPS,
    SET_COMPS_GROUP,
    UPDATE_SELECTED_COMPS_GROUP,
    SET_SELECTED_BROKER_COMPS,
    UPDATE_SELECTED_BROKER_COMP,
    SET_GROUP_CHECKED_COMPS,
    TOGGLE_MODAL,
    SET_DEAL_PARTY,
    TIME_ON_MARKET_INPUT,
    MARKETING_FLYER_PHOTO_INPUT,
    GET_MARKETING_FLYER_PHOTO,
    GET_MARKETING_FLYER_PHOTOS,
    RESET_PHOTO,
    SET_MARKETING_FLYER_PHOTO,
};
