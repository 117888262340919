import React, { Component } from "react";
import { connect } from "react-redux";
import operations from "./ducks/operations";
import DataAdminDeals from "./DataAdminDeals";
import actions from "./ducks/actions";
import includes from "lodash/includes";
import { push } from "connected-react-router";
import ReactGA from "react-ga4";

const testRole = "scenario-1-broker-1";

class DataAdminDealsContainer extends Component {
    componentDidMount() {
        this.props.getInitialDeals(this.props.auth.userProfile.user);
        ReactGA.send({ hitType: "pageview", page: "/deals", title: "Admin Deals" });
    }

    componentWillUnmount() {
        // this.props.handleClearDealsState();
    }
    render() {
        return <DataAdminDeals {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        role: testRole,
        deals: state.dealsReducer.deals,
        valid: state.authReducer.validFormValues,
        snackbarOpen: state.authReducer.snackbarOpen,
        dealView: includes(["data_admin_needs_final_review", "data_admin_closed"], state.dealsReducer.dealView)
            ? state.dealsReducer.dealView
            : "data_admin_needs_final_review",
        columnToSort: state.dealsReducer.columnToSort,
        sortDirection: state.dealsReducer.sortDirection,
        filterQuery: state.dealsReducer.filterQuery,
        selected: state.dealsReducer.selected,
        isLoading: state.utilsReducer.isLoading,
        elementsSaving: state.utilsReducer.elementsSaving,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchVoucherView: (event, value) => {
            // dispatch(operations.switchVoucherView(value))
            dispatch(operations.switchTab(value));
        },
        handleSort: (columnName) => {
            dispatch(operations.handleSort(columnName));
        },
        handleSelect: (deal) => {
            dispatch(operations.selectDeal(deal));
        },
        handleFilter: (query) => {
            dispatch(operations.filterVouchers(query));
        },
        getInitialDeals: (user) => {
            dispatch(operations.getInitialDeals("dataAdmin", user));
        },
        handleClearDealsState: () => {
            dispatch(actions.clearDealsState());
        },
        handleDisplayActiveListings: () => {
            dispatch(push("/listings"));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataAdminDealsContainer);
