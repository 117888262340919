import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Landvision } from "../icons";
import config from "../../../config";

const Gallery = (props) => {
    const { images, classes, propertyKey } = props;

    return (
        <div className="carousel-shell">
            <Carousel showIndicators={false}>
                {images.map((image) => {
                    return <img alt="" src={image} />;
                })}
            </Carousel>

            <div className="landvision">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${config.LANDVISION_URL}?resource=property&key=${propertyKey}`}
                >
                    <Landvision fill="#e0e0e0" />
                    <br />
                </a>
                <a
                    className={classes.lvText}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${config.LANDVISION_URL}?resource=property&key=${propertyKey}`}
                >
                    LandVision
                </a>
            </div>
        </div>
    );
};

const styles = (theme) => ({
    map: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    lvText: {
        textDecoration: "none",
        color: "white",
    },
});

export default withStyles(styles)(Gallery);
