import utils from "../../utils/index";
import actions from "./actions";
import validation from "./validation";
import get from "lodash/get";
import set from "lodash/set";
import isNil from "lodash/isNil";
import each from "lodash/each";
import constants from "../constants";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import compOperations from "../../comp/ducks/operations";
import filter from "lodash/filter";
import { push } from "connected-react-router";
import utilOperations, { convertForIntegerValidation, convertForNumberValidation } from "../../utils/operations";
import listingOperations from "../../listing/ducks/operations";
import searchActions from "../../search/ducks/actions";
import listingValidation from "../../listing/ducks/validation";
import listingActions from "../../listing/ducks/actions";
import { request } from "../../utils/axios-wrapper";
import comments_operations from "../../comments/ducks/operations";
import listingConstants from "../../listing/constants";
import { us_states_options } from "../../utils/constants";
import documentOperations from "../../documents/ducks/operations";
import documentConstants from "../../documents/constants";
import ReactGA from "react-ga4";

const validateForm = (dispatch, validationResponse) => {
    if (validationResponse) {
        each(Object.keys(validationResponse), (key) => {
            dispatch(utils.actions.addError(key, validationResponse[key][0]));
        });
    }
    return validationResponse;
};

const togglePropertySearchModal = (isOpen) => {
    return (dispatch, getState) => {
        dispatch(listingActions.clearErrors());
        dispatch(actions.togglePropertySearchModal(isOpen));
    };
};

const toggleUpdateDialog = (isOpen) => {
    return (dispatch, getState) => {
        if (isOpen === true) {
            const validationResponse = validateForm(
                dispatch,
                listingValidation.validateRequestListingFromExisting(
                    get(getState().listingReducer, "requestModal.selectedProperty")
                )
            );
            if (validationResponse) {
                dispatch(utils.actions.loading(false));
            } else {
                dispatch(actions.updatePropertyForListingDialog(isOpen));
            }
        } else {
            dispatch(actions.updatePropertyForListingDialog(isOpen));
        }
    };
};

const togglePropertyRequestModal = () => {
    return (dispatch, getState) => {
        dispatch(actions.togglePropertyRequestModal(!getState().propertyReducer.propertyRequestModal.open));
    };
};

const updatePropertyRequest = (field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updatePropertyRequest(field, value));
    };
};

const displayDeleteDialog = (isOpen, propertyKey) => {
    return (dispatch, getState) => {
        request({
            url: listingConstants.urls.unassigned_listings_by_property(propertyKey),
            method: "get",
            crossDomain: true,
        })
            .then((response) => {
                if (!isEmpty(response.data)) {
                    dispatch(
                        utils.actions.snackbar(
                            true,
                            "error",
                            "You cannot delete a Property Request that is associated with a Listing, Listing Request, Deal, or Outside Comp. Please move any associated data to a new Property before trying to delete this Property Request."
                        )
                    );
                } else {
                    dispatch(actions.updateDeletePropertyDialog(isOpen));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

const handleDeleteProperty = (propertyKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        dispatch(utils.actions.somethingSaving(true, "propertyDelete"));
        dispatch(actions.updateDeletePropertyDialog(false));
        request(
            {
                method: "delete",
                url: constants.urls.delete_property(propertyKey),
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.somethingSaving(false, "propertyDelete"));
                dispatch(push(`/properties`));
                dispatch(utils.actions.snackbar(true, "success", "Property Request Deleted"));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, "propertyDelete"));
                dispatch(
                    utils.actions.snackbar(
                        true,
                        "error",
                        "Unable to delete this Property Request because it is associated with a Listing, Deal, or Outside Comp."
                    )
                );
                console.log(err);
                dispatch(
                    utilOperations.reportError(
                        err,
                        "listing:operations:handleDeletePropertyRequest - Error trying to delete property request"
                    )
                );
            });
    };
};

const requestProperty = () => {
    return (dispatch, getState) => {
        const state = getState().propertyReducer;
        dispatch(utils.actions.clearErrors());
        const validationResponse = validateForm(
            dispatch,
            validation.validateRequestProperty(get(state, "propertyRequestModal"))
        );
        if (validationResponse) {
            return false;
        } else {
            request(
                {
                    url: constants.urls.request_property(),
                    method: "post",
                    data: buildPropertyRequestFromState(state),
                    headers: {
                        "content-type": "application/json",
                    },
                },
                dispatch
            )
                .then((response) => {
                    ReactGA.event("Property Request Submitted", {
                        propertyName: state.propertyRequestModal.propertyName,
                    });
                    dispatch(togglePropertyRequestModal());
                    dispatch(utils.actions.snackbar(true, "success", "Property request submitted"));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(
                        utils.actions.snackbar(true, "error", "Error trying to submit a property request \n " + err)
                    );
                    dispatch(utilOperations.reportError(err, "property:operations:requestProperty Error"));
                });
        }
    };
};

const buildPropertyRequestFromState = (state) => {
    const modalData = state.propertyRequestModal;
    let property = {};

    set(property, "propertyAttributes.propertyName", modalData.propertyName);
    set(property, "propertyType", modalData.propertyType);
    set(property, "primaryAddress.address1", modalData.propertyAddress);
    set(property, "primaryAddress.city.city", modalData.propertyCity);
    set(property, "primaryAddress.city.state.stateCode", modalData.propertyState);
    set(
        property,
        "primaryAddress.city.state.stateName",
        filter(us_states_options, (x) => x.abbreviation === modalData.propertyState)[0]["label"]
    );
    set(property, "primaryAddress.zipCode.zipCode", modalData.propertyZip);
    set(property, "memberComments", modalData.propertyComments);

    return property;
};

const buildPropertyRequest = (data) => {
    const fieldsToConvertToDouble = [
        "propertyAttributes.totalAcres",
        "propertyAttributes.totalBldgNra",
        "propertyAttributes.totalBldgSf",
        "propertyAttributes.totalLandSf",
        "propertyAttributes.percentLeased",
        "propertyAttributes.lotAcres",
        "propertyAttributes.hospitalitySf",
    ];

    const fieldsToConvertToInteger = [
        "propertyAttributes.parkingTotal",
        "propertyAttributes.elevators",
        "propertyAttributes.numBldg",
        "propertyAttributes.freightElevatorCount",
        "propertyAttributes.parkingGarageSpaceCount",
        "propertyAttributes.parkingReservedSpaceCount",
        "propertyAttributes.surfaceParkingSpaceCount",
        "propertyAttributes.carportParkingSpaceCount",
        "propertyAttributes.lotSf",
        "propertyAttributes.numFloors",
        "propertyAttributes.hospitalityUnitCount",
        "propertyAttributes.roomCount",
    ];

    let property = {
        ...data,
    };

    each(fieldsToConvertToDouble, (field) => {
        set(property, field, convertForNumberValidation(get(property, field)));
    });

    each(fieldsToConvertToInteger, (field) => {
        set(property, field, convertForIntegerValidation(get(property, field)));
    });

    return property;
};

const getPropertyDetail = (key, loadImages) => {
    return (dispatch, _) => {
        dispatch(getProperty(key, loadImages));
        dispatch(listingOperations.getOpenListingsByProperty(key, "property_listings"));
        dispatch(compOperations.getCompHistoryByPropertyKey(key));
        dispatch(getPropertyManagementDetails(key));
        dispatch(getPropertyManagementHistory(key));
    };
};

const getProperty = (key, loadImages) => {
    return (dispatch, _) => {
        dispatch(utils.actions.loading(true));
        request(
            {
                method: "get",
                url: constants.urls.get_property(key),
            },
            dispatch
        )
            .then((response) => {
                dispatch(loadProperty(response.data));
                if (loadImages === true) {
                    dispatch(getPropertyImages(key));
                }

                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                dispatch(loadProperty({}));
                dispatch(push("/properties"));
                dispatch(utils.actions.snackbar(true, "error", "Error trying to retrieve property: " + err));
                dispatch(utils.actions.loading(false));
                dispatch(utilOperations.reportError(err, "Error trying to retrieve property"));
            });
    };
};

const getPropertyImages = (key) => {
    return (dispatch, _) => {
        dispatch(utils.actions.loading(true));
        request(
            {
                url: constants.urls.get_property_images(key),
                method: "get",
            },
            dispatch
        )
            .then((response) => {
                dispatch(loadPropertyImages(response.data));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                dispatch(loadPropertyImages({}));
                dispatch(utils.actions.snackbar(true, "error", "Error trying to retrieve property images: " + err));
                dispatch(utils.actions.loading(false));
                dispatch(utilOperations.reportError(err, "Error trying to retrieve property images"));
            });
    };
};

const loadProperty = (property) => {
    return (dispatch, _) => {
        dispatch(actions.loadProperty(property));
    };
};

const loadPropertyImages = (images) => {
    return (dispatch, _) => {
        dispatch(actions.loadPropertyImages(images));
    };
};

const handleInputChange = (field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.inputChange(field, value));
    };
};

const addArrayElement = (arrayPath) => {
    return (dispatch, getState) => {
        dispatch(actions.addArrayElement(arrayPath));
    };
};

const addArrayElement_v2 = (pathToArray, obj = {}) => {
    return (dispatch, getState) => {
        let array = get(getState().propertyReducer, pathToArray, []);
        if (array == null) {
            array = [];
        }
        array.push(obj);
        dispatch(actions.setPropertyArray(pathToArray, array));
    };
};

const deleteArrayElement = (arrayPath, index) => {
    return (dispatch, getState) => {
        dispatch(actions.deleteArrayElement(arrayPath, index));
    };
};
const deleteArrayElement_v2 = (pathToArray, index) => {
    return (dispatch, getState) => {
        let array = cloneDeep(get(getState().propertyReducer, pathToArray, []));
        array.splice(index, 1);
        dispatch(actions.setPropertyArray(pathToArray, array));
    };
};

const switchTab = (tab) => {
    return (dispatch, getState) => {
        dispatch(actions.switchTab(tab));
        if (tab === "all_properties") {
            dispatch(searchActions.onSearchPage(true));
        } else {
            dispatch(searchActions.onSearchPage(false));
            if (!getState().propertyReducer.properties[tab]) {
                dispatch(getPropertiesByStatus(tab));
            }
        }
    };
};

const switchPropDetailTab = (tab) => {
    return (dispatch, getState) => {
        ReactGA.event("Property Detail Tab Change", {
            tab: tab,
        });
        dispatch(actions.switchPropDetailTab(tab));
    };
};

const getProperties = () => {
    return (dispatch, _) => {
        dispatch(getPropertiesByStatus("data_team_needs_review"));
    };
};

const getPropertiesByStatus = (tab) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, tab));
        if (tab !== "all_properties") {
            dispatch(utils.actions.loading(true));
            request(
                {
                    url: constants.urls[tab](getState().authReducer.userProfile.user),
                    method: "get",
                },
                dispatch
            )
                .then((response) => {
                    dispatch(actions.receiveProperties(response.data, tab));
                    dispatch(utils.actions.somethingSaving(false, tab));
                    dispatch(utils.actions.loading(false));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(utils.actions.somethingSaving(false, tab));
                    dispatch(utils.actions.loading(false));
                    dispatch(utilOperations.reportError(err, "Error trying to retrieve Properties by status"));
                });
        }
    };
};

const filterProperties = (query) => {
    return (dispatch, getState) => {
        if (typeof query === "string") query = query.toLowerCase();
        dispatch(actions.filterProperties(query));
    };
};

const handleSort = (columnName) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };
    return (dispatch, getState) => {
        let sortDirection =
            getState().propertyReducer.columnToSort === columnName
                ? invertDirection[getState().propertyReducer.sortDirection]
                : "asc";
        dispatch(actions.handleSort(columnName, sortDirection));
    };
};

const addPropertyManagementSection = (key) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.create_property_management(key),
                method: "post",
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.inputChange("propertyManagement", response.data));
                dispatch(getPropertyManagementPersonnel(response.data.propertyManagementKey));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error trying to add property management: " + err));
                dispatch(utilOperations.reportError(err, "property:operations:addPropertyManagementSectino Error"));
            });
    };
};

const getPropertyManagementDetails = (key) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "property_management_details"));
        dispatch(utils.actions.loading(true));
        request(
            {
                url: constants.urls.property_management_details(key),
                method: "get",
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.inputChange("propertyManagement", response.data));
                if (get(response, "data.propertyManagementKey", null)) {
                    dispatch(getPropertyManagementPersonnel(response.data.propertyManagementKey));
                }
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, "property_management_details"));
            })
            .catch((err) => {
                dispatch(
                    utils.actions.snackbar(
                        true,
                        "error",
                        "Error trying to retrieve property management details: " + err
                    )
                );
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, "property_management_details"));
                dispatch(utilOperations.reportError(err, "property:operations:getPropertyManagementDetails Error"));
            });
    };
};

const getPropertyManagementHistory = (key) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "property_management_history"));
        dispatch(utils.actions.loading(true));
        request(
            {
                url: constants.urls.property_management_history(key),
                method: "get",
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.inputChange("propertyManagementSnapshots", response.data));
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, "property_management_history"));
            })
            .catch((err) => {
                dispatch(
                    utils.actions.snackbar(
                        true,
                        "error",
                        "Error trying to retrieve property management history: " + err
                    )
                );
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, "property_management_history"));
                dispatch(utilOperations.reportError(err, "property:operations:getPropertyManagementHistory Error"));
            });
    };
};

const getPropertyManagementPersonnel = (key) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "property_management_personnel"));
        dispatch(utils.actions.loading(true));
        request(
            {
                url: constants.urls.property_management_personnel(key),
                method: "get",
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.inputChange("propertyManagement.personnel", response.data));
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, "property_management_personnel"));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error trying to retrieve comps: " + err));
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, "property_management_personnel"));
                dispatch(utilOperations.reportError(err, "property:operations:getPropertyManagementPersonnel Error"));
            });
    };
};

const savePropertyManagementDetails = (key, sync = false) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        dispatch(utils.actions.clearErrors());

        let data = buildPropertyManagementRequest(getState());
        const validationResponse = validateForm(dispatch, validation.validatePropertyManagementDetails(data));
        const snackbarMessage = "Errors found - please see red highlighted fields.";
        if (validationResponse) {
            dispatch(utils.actions.snackbar(true, "error", snackbarMessage));
            return false;
        } else {
            request(
                {
                    method: "put",
                    url: constants.urls.update_property_management_details(key),
                    data: data,
                    params: { sync },
                    headers: { "Content-Type": "application/json" },
                },
                dispatch
            )
                .then((response) => {
                    const personnel = get(getState().propertyReducer, "propertyManagement.personnel", null);
                    dispatch(actions.inputChange("propertyManagement", response.data));
                    dispatch(actions.inputChange("propertyManagement.personnel", personnel));
                    dispatch(utils.actions.loading(false));
                    dispatch(utils.actions.snackbar(true, "success", "All changes saved"));
                })
                .catch((err) => {
                    dispatch(
                        utils.actions.snackbar(
                            true,
                            "error",
                            "You have input the wrong datatype in a numeric or date field."
                        )
                    );
                    dispatch(utils.actions.loading(false));
                    dispatch(utilOperations.reportError(err, "property:operations:savePropertyMangementDetails Error"));
                });
        }
    };
};

const handleSubmitPropertyForm = (save, property, dispatch) => {
    if (save) {
        const data = buildPropertyRequest(property);
        return request(
            {
                method: "put",
                url: constants.urls.save_property(property.propertyKey),
                data: data,
                headers: { "Content-Type": "application/json" },
            },
            dispatch
        );
    }
};

const handleSave = () => {
    return handleSaveCommon(false, "propertySaveAndContinue");
};

const handleSaveAndExit = () => {
    return handleSaveCommon(true, "propertySaveAndExit");
};

const handleSaveCommon = (shouldExit, elementSaving) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        dispatch(utils.actions.somethingSaving(true, elementSaving));
        const property = getState().propertyReducer.property;
        const errors = validation.validateProperty(property, get(property, "propertyType"));
        if (isEmpty(errors) || isNil(errors)) {
            handleSubmitPropertyForm(true, property, dispatch)
                .then((response) => {
                    if (shouldExit) {
                        dispatch(push("/properties"));
                    }
                    dispatch(utils.actions.somethingSaving(false, elementSaving));
                    dispatch(utils.actions.snackbar(true, "success", "Success."));
                })
                .catch((err) => {
                    dispatch(utils.actions.snackbar(true, "error", "Error trying to save: " + err));
                    dispatch(utilOperations.reportError(err, "property:operations:handleSaveAndExit Error"));
                    dispatch(utils.actions.somethingSaving(false, elementSaving));
                });
        } else {
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                )
            );
            dispatch(utils.actions.addError("property", errors));
            dispatch(utils.actions.somethingSaving(false, elementSaving));
        }
    };
};

const handleApprovePropertyRequestAsDataEntry = () => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        dispatch(utils.actions.somethingSaving(true, "propertySubmit"));
        const property = getState().propertyReducer.property;
        const errors = validation.validateProperty(property, get(property, "propertyType"));
        if (isEmpty(errors) || isNil(errors)) {
            handleSubmitPropertyForm(true, property, dispatch)
                .then((response) => {
                    request(
                        {
                            method: "put",
                            url: constants.urls.update_property_status(property.propertyKey),
                            data: {
                                propertyStatus: "Active",
                            },
                            headers: { "Content-Type": "application/json" },
                        },
                        dispatch
                    ).then((response) => {
                        dispatch(push("/properties"));
                        dispatch(utils.actions.somethingSaving(false, "propertySubmit"));
                    });
                })
                .catch((err) => {
                    dispatch(utils.actions.somethingSaving(false, "propertySubmit"));
                    dispatch(utils.actions.snackbar(true, "error", "Error trying to save: " + err));
                    dispatch(
                        utilOperations.reportError(
                            err,
                            "property:operations:handleApprovePropertyRequestAsDataEntry Error"
                        )
                    );
                });
        } else {
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                )
            );
            dispatch(utils.actions.addError("property", errors));
            dispatch(utils.actions.somethingSaving(false, "propertySubmit"));
        }
    };
};

const clearPropertyDetailState = () => {
    return (dispatch, getState) => {
        dispatch(actions.resetState());
    };
};

const navigateToRequestPropertyUpdate = (key, redirectTo) => {
    return (dispatch, getState) => {
        dispatch(push(`/property/${key}`));
        dispatch(comments_operations.setStateForRequestUpdate(redirectTo));
    };
};

const editProperty = (key) => {
    return (dispatch, getState) => {
        dispatch(push(`/property/${key}`));
    };
};

const archiveAssetServicesRecord = (propertyManagementKey, record, propertyKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        dispatch(utils.actions.somethingSaving(true, "asset_services_record"));
        request(
            {
                method: "post",
                url: constants.urls.archive_asset_services_record(propertyManagementKey),
                data: record,
                headers: { "Content-Type": "application/json" },
                params: record,
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.snackbar(true, "success", "Archive Snapshot saved"));
                dispatch(utils.actions.somethingSaving(false, "asset_services_record"));
                dispatch(getPropertyManagementDetails(propertyKey));
                dispatch(getPropertyManagementHistory(propertyKey));
                dispatch(
                    documentOperations.getDocuments(
                        documentConstants.urls.get_documents(propertyManagementKey, "PROPERTY_MANAGEMENT")
                    )
                );
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error trying to save: " + err));
                dispatch(utils.actions.somethingSaving(false, "asset_services_record"));
                dispatch(utilOperations.reportError(err, "property:operations:archiveAssetServicesRecord Error"));
            });
    };
};

const buildPropertyManagementRequest = (state) => {
    const pmData = state.propertyReducer.propertyManagement;
    const personnelData = Object.entries(pmData["personnel"]);
    const filteredPersonnelData = personnelData.filter(([key, value]) => key !== "Select");
    const pmandPersonnelData = { propertyManagement: pmData, personnel: Object.fromEntries(filteredPersonnelData) };
    return pmandPersonnelData;
};

export default {
    togglePropertyRequestModal,
    togglePropertySearchModal,
    updatePropertyRequest,
    requestProperty,
    getProperty,
    getPropertyDetail,
    addArrayElement,
    addArrayElement_v2,
    deleteArrayElement,
    deleteArrayElement_v2,
    switchTab,
    handleSort,
    getProperties,
    getPropertiesByStatus,
    filterProperties,
    handleInputChange,
    loadProperty,
    switchPropDetailTab,
    addPropertyManagementSection,
    getPropertyManagementDetails,
    getPropertyManagementHistory,
    getPropertyManagementPersonnel,
    savePropertyManagementDetails,
    handleSubmitPropertyForm,
    handleApprovePropertyRequestAsDataEntry,
    handleSave,
    handleSaveAndExit,
    clearPropertyDetailState,
    navigateToRequestPropertyUpdate,
    buildPropertyRequest,
    editProperty,
    displayDeleteDialog,
    handleDeleteProperty,
    toggleUpdateDialog,
    archiveAssetServicesRecord,
};
