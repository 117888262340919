import React from "react";
import { DownChevron, UpChevron } from "../icons";
import { Button } from "react-bootstrap";
import BSInput from "./BSInput";
import { withStyles } from "@material-ui/core/styles";
import "../../styles/components/formstyles.css";

const styles = (theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    componentWrapper: {
        position: "relative",
    },
    upcounter: {
        padding: "0px",
        width: "2.5rem",
        height: "20px",
        position: "absolute",
        top: "22px",
        right: 0,
        borderTopLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        borderBottomLeftRadius: "0px",
        backgroundColor: "#f9f9f9",
        borderColor: "#e0e0e0",
        transition: "none",
        "&:active": {
            border: "none",
            backgroundColor: "#f9f9f9",
        },
        "&:focus": {
            outline: "none !important",
            backgroundColor: "#f9f9f9",
        },
        "&:hover": {
            backgroundColor: "#ededed !important",
        },
    },
    downcounter: {
        padding: "0px",
        width: "2.5rem",
        height: "20px",
        position: "absolute",
        top: "43px",
        right: 0,
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomLeftRadius: "0px",
        backgroundColor: "#f9f9f9",
        borderColor: "#e0e0e0",
        transition: "none",
        "&:active": {
            border: "none",
            backgroundColor: "#f9f9f9",
        },
        "&:focus": {
            outline: "none !important",
            backgroundColor: "#f9f9f9",
        },
        "&:hover": {
            backgroundColor: "#ededed !important",
        },
    },
    upchevron: {
        fontSize: "80%",
    },
    downchevron: {
        fontSize: "80%",
    },
});

class KMCounter extends React.Component {
    handleIncrement = () => {
        let num = Number(this.props.value);
        let increment = isNaN(this.props.step) ? 1 : this.props.step;
        const max = isNaN(this.props.max) ? Infinity : this.props.max;

        if (isNaN(num)) {
            this.props.onChange(this.props.id, 0);
        } else {
            let nextVal = num + increment;
            if (nextVal % 1) nextVal = nextVal.toFixed(2);
            if (nextVal <= max) this.props.onChange(this.props.id, nextVal);
        }
    };

    handleDecrement = () => {
        let num = Number(this.props.value);
        let decrement = isNaN(this.props.step) ? 1 : this.props.step;
        const min = isNaN(this.props.min) ? -Infinity : this.props.min;

        if (isNaN(num)) {
            this.props.onChange(this.props.id, 0);
        } else {
            let nextVal = num - decrement;
            if (nextVal % 1) nextVal = nextVal.toFixed(2);
            if (nextVal >= min) this.props.onChange(this.props.id, nextVal);
        }
    };

    render() {
        const { classes, id, label, placeholder, value, onChange, disabled, error, errorText, type } = this.props;

        return (
            <React.Fragment>
                <div className={classes.componentWrapper}>
                    <BSInput
                        {...this.props}
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => onChange(e.target.id, e.target.value)}
                        disabled={disabled}
                        error={error}
                        errorText={errorText}
                        type={type}
                    />
                    <Button className={classes.upcounter} disabled={disabled} onClick={this.handleIncrement}>
                        <UpChevron className={classes.upchevron} />
                    </Button>
                    <Button className={classes.downcounter} disabled={disabled} onClick={this.handleDecrement}>
                        <DownChevron className={classes.downchevron} />
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(KMCounter);
