import types from "./types";

const getDeal = (deal) => {
    console.log(deal);
    return {
        type: types.GET_DEAL_REFACTOR,
        deal: { ...deal },
    };
};
const autosaveNotSuccess = (isSaving, saveError) => {
    return {
        type: types.AUTOSAVE_NOT_SUCCESS,
        isSaving: isSaving,
        saveError: saveError,
    };
};

const autosaveSuccess = () => {
    return {
        type: types.AUTOSAVE_SUCCESS,
        isSaving: false,
        lastSaved: new Date(),
    };
};

const documentWarning = (open, proceed, docs) => {
    return {
        type: types.DOCUMENT_WARNING,
        open: open,
        proceed: proceed,
        docs: docs,
    };
};

const validDocuments = (valid) => {
    return {
        type: types.COMP_VALID_DOCUMENTS,
        valid: valid,
    };
};

const updateStateObjectProperty = (stateObjectProperty, field, value) => {
    return {
        type: types.STATE_OBJECT_PROPERTY_CHANGE,
        key: stateObjectProperty,
        field: field,
        value: value,
    };
};

export const compSnackbar = (open, variant, message) => {
    return {
        type: types.COMP_SNACKBAR,
        open: open,
        message: message,
        variant: variant,
    };
};

const switchTab = (tab) => {
    return {
        type: types.SWITCH_REVIEW_COMPS_TAB,
        tab: tab,
    };
};

const receiveComps = (data, tab) => {
    return {
        type: types.RECEIVE_REVIEW_COMPS,
        comps: data,
        tab: tab,
    };
};

const updateCompOrder = (data, tab) => {
    return {
        type: types.UPDATE_COMP_ORDER,
        comps: data,
        tab: tab,
    };
};

const updateSelectedCompOrder = (data) => {
    return {
        type: types.UPDATE_SELECTED_COMP_ORDER,
        comps: data,
    };
};

const countComps = (count) => {
    return {
        type: types.COUNT_REVIEW_COMPS,
    };
};

const updateCompValue = (path, value) => {
    return {
        type: types.COMP_INPUT,
        path: path,
        value: value,
    };
};

const addOutsideComp = (transactionType, propertyType) => {
    return {
        type: types.ADD_OUTSIDE_COMP,
        transactionType: transactionType,
        propertyType: propertyType,
    };
};

const selectPropertyFromAutocomplete = (property) => {
    return {
        type: types.SELECT_PROPERTY_FROM_AUTOCOMPLETE_OUTSIDE_COMP_MODAL,
        property: property,
    };
};
const setCheckedComps = (comps) => {
    return {
        type: types.SET_CHECKED_COMPS,
        comps: comps,
    };
};
const setGroupCheckedComps = (comps) => {
    return {
        type: types.SET_GROUP_CHECKED_COMPS,
        selectedCompsGroup: comps,
    };
};
const setBrokerCheckedComps = (newValue) => {
    return {
        type: types.SET_SELECTED_BROKER_COMPS,
        newValue: newValue,
    };
};
const checkboxClick = (key, newValue) => {
    return {
        type: types.TOGGLE_COMP_FOR_REPORT,
        key: key,
        newValue: newValue,
    };
};

const groupCheckboxClick = (key, newValue) => {
    return {
        type: types.UPDATE_SELECTED_COMPS_GROUP,
        key: key,
        newValue: newValue,
    };
};

const brokerCompCheckboxClick = (key, newValue) => {
    return {
        type: types.UPDATE_SELECTED_BROKER_COMP,
        key: key,
        newValue: newValue,
    };
};

const updateDeleteDialog = (open) => {
    return {
        type: types.DELETE_OUTSIDE_COMP_DIALOG,
        open: open,
    };
};

const clearAllComps = () => {
    return {
        type: types.CLEAR_ALL_COMPS,
    };
};

const setCurrentCompsGroup = (comps) => {
    return {
        type: types.SET_COMPS_GROUP,
        comps_group: comps,
    };
};

const toggleModal = (isOpen) => {
    return {
        type: types.TOGGLE_MODAL,
        isOpen,
    };
};

const setDealParty = (dealParty) => {
    return {
        type: types.SET_DEAL_PARTY,
        dealParty,
    };
};

const timeOnMarketInput = (input) => {
    return {
        type: types.TIME_ON_MARKET_INPUT,
        input,
    };
};

const getMarketingFlyerPhoto = (photo) => {
    return {
        type: types.GET_MARKETING_FLYER_PHOTO,
        photo,
    };
};

const getMarketingFlyerDropdownPhotos = (photos) => {
    return {
        type: types.GET_MARKETING_FLYER_PHOTOS,
        photos,
    };
};

const resetPhoto = () => {
    return {
        type: types.RESET_PHOTO,
    };
};

const setMarketingFlyerPhoto = (photo) => {
    return {
        type: types.SET_MARKETING_FLYER_PHOTO,
        photo,
    };
};

export default {
    autosaveNotSuccess,
    autosaveSuccess,
    compSnackbar,
    updateStateObjectProperty,
    documentWarning,
    validDocuments,
    switchTab,
    receiveComps,
    countComps,
    addOutsideComp,
    getDeal,
    updateCompValue,
    selectPropertyFromAutocomplete,
    setCheckedComps,
    checkboxClick,
    groupCheckboxClick,
    updateCompOrder,
    updateSelectedCompOrder,
    updateDeleteDialog,
    clearAllComps,
    setCurrentCompsGroup,
    setGroupCheckedComps,
    setBrokerCheckedComps,
    brokerCompCheckboxClick,
    toggleModal,
    setDealParty,
    timeOnMarketInput,
    getMarketingFlyerPhoto,
    getMarketingFlyerDropdownPhotos,
    resetPhoto,
    setMarketingFlyerPhoto,
};
