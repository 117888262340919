const RECEIVE_VOUCHERS = "RECEIVE_VOUCHERS";
const SWITCH_VOUCHER_VIEW = "SWITCH_VOUCHER_VIEW";
const SORT_VOUCHERS = "SORT_VOUCHERS";
const FILTER_VOUCHERS = "FILTER_VOUCHERS";
const SELECT_ALL = "SELECT_ALL";
const CHECKBOX_CLICK = "CHECKBOX_CLICK";
const ADD_DOCUMENTS = "ADD_DOCUMENTS";
const SORT_DOCUMENTS = "SORT_DOCUMENTS";
const SELECT_DOC_TYPE = "SELECT_DOC_TYPE";
const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
const OPEN_DOC_MENU = "OPEN_DOC_MENU";
const AUTOSAVE_NOT_SUCCESS = "AUTOSAVE_NOT_SUCCESS";
const AUTOSAVE_SUCCESS = "AUTOSAVE_SUCCESS";
const RECEIVE_VOUCHER_DOCUMENT = "RECEIVE_VOUCHER_DOCUMENT";
const TABLE_DATA_LOADING = "TABLE_DATA_LOADING";
const VOUCHERS_DATA_CHANGE = "VOUCHERS_DATA_CHANGE";
const UPDATE_VOUCHERS_SELECTED = "UPDATE_VOUCHERS_SELECTED";
const SET_ACCOUNTING_INPUT_PARAM = "SET_ACCOUNTING_INPUT_PARAM";

export default {
    RECEIVE_VOUCHERS,
    SWITCH_VOUCHER_VIEW,
    SORT_VOUCHERS,
    FILTER_VOUCHERS,
    SELECT_ALL,
    CHECKBOX_CLICK,
    ADD_DOCUMENTS,
    SORT_DOCUMENTS,
    SELECT_DOC_TYPE,
    REMOVE_DOCUMENT,
    OPEN_DOC_MENU,
    AUTOSAVE_NOT_SUCCESS,
    AUTOSAVE_SUCCESS,
    RECEIVE_VOUCHER_DOCUMENT,
    TABLE_DATA_LOADING,
    VOUCHERS_DATA_CHANGE,
    UPDATE_VOUCHERS_SELECTED,
    SET_ACCOUNTING_INPUT_PARAM,
};
