import React from "react";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

class TrackResearchModal extends React.Component {
    onExitModal = () => {
        this.props.closeModal();
    };

    affirmativeClick = () => {
        this.onExitModal();
        this.props.confirm();
    };

    render() {
        const { classes } = this.props;

        return (
            <Modal
                open={this.props.modalOpen}
                onClose={() => this.onExitModal()}
                style={{
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10vh",
                }}
            >
                <div className={classes.container}>
                    <h4 className={classes.header}>
                        {this.props.trackedValue === true ? "Stop Tracking Property?" : "Track Property?"}
                    </h4>
                    <Typography variant="body1" style={{ marginRight: "24px", marginLeft: "24px" }}>
                        {this.props.trackedValue === true
                            ? "Are you sure you want to stop tracking this property?"
                            : "Are you sure you want to start tracking this property?"}
                    </Typography>
                    <div style={{ position: "absolute", bottom: 24, right: 24 }}>
                        <Button
                            style={{ display: "inline-block", marginLeft: "10px" }}
                            variant="outlined"
                            onClick={() => this.onExitModal()}
                        >
                            No
                        </Button>
                        <Button
                            style={{ display: "inline-block", marginLeft: "10px" }}
                            variant="contained"
                            color="primary"
                            onClick={() => this.affirmativeClick()}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const styles = (theme) => ({
    header: {
        marginTop: "45px",
        textAlign: "left",
        fontSize: "18px",
        marginLeft: "24px",
    },
    container: {
        margin: "0 auto",
        marginTop: "10%",
        position: "relative",
        alignItems: "center",
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
        width: "400px",
        height: "200px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
    },
});

export default withStyles(styles)(TrackResearchModal);
