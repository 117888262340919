import React from "react";
import BSInput from "../../../common/js/components/BSInput";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Delete from "../../../../node_modules/@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
    iconOnly: {
        marginLeft: 0,
        marginBottom: "10px",
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
});
class Parcel extends React.Component {
    render() {
        const { classes, element, elementIndex, errors, handleUpdateArrayElement, disableInputs, onDelete } =
            this.props;
        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="latitude"
                            label="LATITUDE"
                            placeholder="Enter latitude"
                            value={element ? element.latitude : null}
                            error={get(errors, `parcels.${elementIndex}.latitude`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement(
                                    "property.propertyAttributes.parcels",
                                    elementIndex,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="longitude"
                            label="LONGITUDE"
                            placeholder="Enter longitude"
                            value={element ? element.longitude : null}
                            error={get(errors, `parcels.${elementIndex}.longitude`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement(
                                    "property.propertyAttributes.parcels",
                                    elementIndex,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={8}>
                    <Grid item xs={11}>
                        <BSInput
                            id="apn"
                            label="APN/PARCEL CODE/PARCEL ID"
                            placeholder="Enter parcel number"
                            value={element ? element.apn : null}
                            error={get(errors, `parcels.${elementIndex}.parcelNumber`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement(
                                    "property.propertyAttributes.parcels",
                                    elementIndex,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            disabled={disableInputs}
                        />
                    </Grid>

                    <Grid item xs={1}>
                        {elementIndex > 0 && disableInputs !== true && (
                            <Button onClick={() => onDelete(elementIndex)} className={classes.iconOnly}>
                                <Delete />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Parcel);
