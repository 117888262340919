import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTypeAdmin from "./DocumentTypeAdmin";
import operations from "./ducks/operations";
import { includes } from "lodash";
import ReactGA from "react-ga4";

class DocumentTypeAdminContainer extends Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        ReactGA.send({ hitType: "pageview", page: "/document-type/document/*", title: `Document Admin: ${id}` });
    }

    render() {
        const { id } = this.props.match.params;
        const { role } = this.props;
        if (includes(["dataAdmin"], role)) {
            return (
                <div>
                    <DocumentTypeAdmin {...this.props} documentTypeId={id} />
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        stateDocumentRequirements: state.stateDocumentRequirementsReducer.stateDocumentRequirements,
        role: state.authReducer.userProfile.role,
        errors: state.stateDocumentRequirementsReducer.errors,
        savedDocuments: state.stateDocumentRequirementsReducer.savedDocuments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleDeleteCancelDocumentType: (documentTypeKey) => {
            dispatch(operations.deleteDocumentType(documentTypeKey));
        },
        handleInputChange: (field, value) => {
            dispatch(operations.handleInputChange(field, value));
        },
        handleSaveAll: (documentTypeKey, updatedDocumentType, shouldExit) => {
            dispatch(operations.updateDocumentType(documentTypeKey, updatedDocumentType, shouldExit));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypeAdminContainer);
