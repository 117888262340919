import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import operations from "./ducks/operations.js";

class AdImportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
        };
    }

    onAdImportSubmit = async () => {
        this.setState({ processing: true });
        const status = await operations.adImport();
        if (status >= 200 && status < 300) {
            this.props.adImportResponse(true);
        } else {
            this.props.adImportResponse(false);
        }
        this.setState({ processing: false });
        this.props.closeModal();
    };

    onExitModal = () => {
        this.setState({ processing: false });
        this.props.closeModal();
    };

    render() {
        const { classes } = this.props;

        return (
            <Modal
                open={this.props.modalOpen}
                onClose={() => this.onExitModal()}
                style={{
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10vh",
                }}
            >
                <div className={classes.container}>
                    <h4 className={classes.header}>AD Import</h4>
                    <Typography variant="body1" style={{ marginRight: "24px", marginLeft: "24px" }}>
                        Are you sure you want to import user records from Active Directory?
                    </Typography>
                    <div style={{ position: "absolute", bottom: 24, right: 24 }}>
                        <Button
                            style={{ display: "inline-block", marginLeft: "10px" }}
                            variant="outlined"
                            onClick={() => this.onExitModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ display: "inline-block", marginLeft: "10px" }}
                            variant="contained"
                            color="primary"
                            disabled={this.state.processing ? true : false}
                            onClick={() => this.onAdImportSubmit()}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const styles = (theme) => ({
    header: {
        marginTop: "45px",
        textAlign: "left",
        fontSize: "18px",
        marginLeft: "24px",
    },
    text: {
        color: "black",
        fontSize: "16px",
        fontWeight: "300",
        textAlign: "left",
        marginLeft: "24px",
    },
    container: {
        margin: "0 auto",
        marginTop: "10%",
        position: "relative",
        alignItems: "center",
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
        width: "700px",
        height: "200px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
    },
    buttonStyling: {
        display: "inline",
        color: red[500],
        "&:hover": {
            backgroundColor: red[100],
        },
        border: "1px solid",
        borderColor: red[500],
    },
});

export default withStyles(styles)(AdImportModal);
