import React, { Component } from "react";
import { connect } from "react-redux";
import PropertyDetail from "./PropertyDetail";
import listingOperations from "../listing/ducks/operations";
import utilOperations from "../utils/operations";
import operations from "./ducks/operations";
import searchOperations from "../search/ducks/operations";
import toUpper from "lodash/toUpper";
import get from "lodash/get";
import ReactGA from "react-ga4";

class PropertyDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.getProperty(id, true);
        ReactGA.send({ hitType: "pageview", page: "/propdetail/*", title: `Property Detail: ${id}` });
    }

    componentWillUnmount() {
        this.props.clearPageState();
    }

    render() {
        const { id } = this.props.match.params;
        return (
            <div>
                <PropertyDetail
                    {...this.props}
                    propertyKey={id}
                    hasPortfolioProperties={
                        get(this.props.propertyManagement, "assetPortfolioProperties", []).length > 0
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        role: state.authReducer.userProfile.role,
        property: state.propertyReducer.property,
        images: state.propertyReducer.images,
        propertyType: state.propertyReducer.property.propertyType,
        isLoading: state.utilsReducer.isLoading,
        tab: state.propertyReducer.propertyDetailTab ? state.propertyReducer.propertyDetailTab : "property",
        listings: get(state, "listingReducer.listings.property_listings", []),
        compHistory: get(state, "compReducer.comps.comp_history_for_property", []),
        propertyManagement: state.propertyReducer.propertyManagement,
        propertyManagementSnapshots: state.propertyReducer.propertyManagementSnapshots,
        autocompleteResults: state.searchReducer.autocompleteResults,
        errors: state.utilsReducer.errors,
        elementsSaving: state.utilsReducer.elementsSaving,
        requestModal: state.listingReducer.requestModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProperty: (propKey, loadImages) => {
            dispatch(operations.getPropertyDetail(propKey, loadImages));
        },
        switchTab: (_, value) => {
            dispatch(operations.switchPropDetailTab(value));
        },
        closeDeal: (listing) => {
            dispatch(listingOperations.closeDeal(listing));
        },
        getFlyer: (listingKey) => {
            dispatch(listingOperations.getFlyer(listingKey));
        },
        navigateToRequestUpdate: (listingKey, redirectTo) => {
            dispatch(listingOperations.navigateToRequestUpdate(listingKey, redirectTo));
        },
        navigateToRequestPropertyUpdate: (listingKey, redirectTo) => {
            dispatch(operations.navigateToRequestPropertyUpdate(listingKey, redirectTo));
        },
        navigateToEditProperty: (propertyKey) => {
            dispatch(operations.editProperty(propertyKey));
        },
        toggleRequestModal: (spaceType, transactionType) => {
            dispatch(listingOperations.toggleRequestModal(spaceType, transactionType));
        },
        updateSearchTerm: (value, searchType) => {
            dispatch(searchOperations.updateFilter("searchTerm", value, searchType));
        },
        handleSearchSubmit: () => {
            dispatch(searchOperations.executeSearch("property_modal"));
            dispatch(searchOperations.storeSearchRun(true, toUpper("property_modal")));
        },
        addPropertyManagementSection: (key) => {
            dispatch(operations.addPropertyManagementSection(key));
        },
        handleAddArrayElement: (arrayPath) => {
            dispatch(operations.addArrayElement(arrayPath));
        },
        handleDeleteArrayElement: (arrayPath, index) => {
            dispatch(operations.deleteArrayElement(arrayPath, index));
        },
        handleUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.handleInputChange(arrayPath + "[" + index + "]." + field, value));
        },
        clearErrorByField: (field) => {
            dispatch(utilOperations.clearErrorByField(field));
        },
        handleInput: (field, value) => {
            dispatch(operations.handleInputChange(field, value));
        },
        savePropertyManagementDetails: (key) => {
            dispatch(operations.savePropertyManagementDetails(key));
        },
        searchKMPerson: (term) => {
            dispatch(searchOperations.searchKMPersonAutocomplete(term));
        },
        addListingGivenProperty: (property, useType, transactionType) => {
            dispatch(listingOperations.addListingGivenProperty(property, useType, transactionType));
        },
        clearPageState: () => {
            dispatch(listingOperations.clearTabContents("property_listings"));
            dispatch(operations.clearPropertyDetailState());
        },
        handleSearchCompany: (inputValue, companyType) => {
            dispatch(searchOperations.searchCompany(inputValue, companyType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetailContainer);
