import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import Root from "./Root";
import registerServiceWorker from "./registerServiceWorker";
import configureStore from "./store";
import config from "./config";
import "./index.css";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// this module is not supported, need to figure out when/why this was added
// import { init as initApm } from '@elastic/apm-rum'

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#56addb",
            main: "#2C99D2", // the blue
            dark: "#1e6b93",
            contrastText: "#fff",
        },
        secondary: {
            light: "#68b36b",
            main: "#43A047", // the green
            dark: "#2e7031",
            contrastText: "#fff",
        },
    },
    props: {
        // Name of the component ⚛
        MuiButtonBase: {
            // The properties to apply
            disableRipple: true, // No more ripple, on the whole application 💣
        },
    },
    typography: {
        fontFamily: '"IBM Plex Sans", sans-serif;',
    },
    overrides: {
        MuiPaper: {
            elevation2: {
                boxShadow: "0 1px 2px 0 rgba(34,54,70,.1)",
            },
        },
        MuiButton: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                textTransform: "none", // Some CSS
                marginLeft: "8px", // adds a margin to the left of all buttons
                fontWeight: 600,
            },
            contained: {
                boxShadow: "0 1px 2px rgba(34, 54, 70, .1)",
            },
        },
        MuiButtonBase: {
            focusVisible: {
                backgroundColor: "rgba(44, 153, 210, 0.08)",
                borderRadius: "4px",
            },
        },
        MuiExpansionPanel: {
            root: {
                marginBottom: "20px",
                marginTop: 0,
                boxShadow: "0px 1px 2px rgba(34, 54, 70, 0.1)",
                borderRadius: "3px",

                "&.withTable": {
                    // in MuiExpansionPanel-root, you also have the class thisone, that's what this does
                    borderBottom: "1px solid #E0E0E0",
                    borderRadius: 0,
                    boxShadow: "none",
                    marginBottom: 0,
                },
            },
            expanded: {
                marginBottom: "20px",
                marginTop: 0,
                paddingBottom: "20px",
                "&.withTable": {
                    // in MuiExpansionPanel-root, you also have the class thisone, that's what this does
                    marginBottom: 0,
                },
            },
        },
        MuiExpansionPanelSummary: {
            root: {
                color: "#212121",
                margin: 0,
                fontSize: "18px",
                padding: "0 20px",
                fontWeight: 700,
            },
            expanded: {},
            content: {
                paddingRight: "40px",
            },
        },
        MuiExpansionPanelDetails: {
            root: {
                display: "block", // 'flex' gets add here for some reason
                padding: "20px 20px 0",
                "&.withTable": {
                    // in MuiExpansionPanel-root, you also have the class thisone, that's what this does
                    paddingTop: 0,
                },
            },
        },
        MuiModal: {
            root: {},
        },
        MuiInput: {
            formControl: {
                "label + &": {
                    marginTop: "22px",
                },
            },
            focused: {
                border: "1px solid rgba(44, 153, 210, 0.5)",
            },
        },
        MuiInputBase: {
            root: {
                padding: 0,
                border: "1px solid #E0E0E0",
                borderRadius: "4px",
            },
            disabled: {
                background: "#fafafa",
                color: "rgba(0, 0, 0, 0.87) !important",
                "&:hover": {
                    cursor: "not-allowed",
                },
            },
        },
        MuiFormControl: {
            fullWidth: {
                marginBottom: "30px",
            },
        },
        MuiInputLabel: {
            shrink: {
                transform: "none",
            },
        },
        MuiFormLabel: {
            root: {
                textTransform: "uppercase",
                transform: "translate(0) scale(1)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                fontSize: "13px",
                fontWeight: 600,
                borderBottom: "none", // for radio groups
                "&$focused": {
                    // in MuiFormLabel-root, you also have the class MuiFormLabel-focused, that's what this does
                    color: "rgba(0, 0, 0, 0.54)", // when you focus in an input, this is the label color"
                },
            },
        },
        MuiCheckbox: {
            root: {
                borderRadius: "4px",
            },
        },
        MuiIconButton: {},
        MuiInputAdornment: {
            positionStart: {
                marginRight: "4px",
            },
        },
        MuiFormHelperText: {
            root: {
                color: "red",
            },
        },
        MuiTable: {
            root: {},
        },
        MuiTableCell: {
            root: {
                paddingRight: 0,
            },
            head: {
                fontWeight: 600,
                textTransform: "uppercase",
                color: "#9E9E9E",
            },
        },
        MuiTableRow: {
            root: {
                height: "60px",
            },
            head: {
                height: "36px",
            },
        },
        MuiTabs: {
            root: {},
            indicator: {
                background: "#2C99D2",
                height: "3px",
            },
            scroller: {},
        },
        MuiTab: {
            root: {
                minWidth: "0 !important",
                minHeight: 0,
                paddingBottom: "15px",
                marginRight: "30px",
            },
            label: {
                fontWeight: 600,
                textTransform: "none",
                fontSize: "14px",
            },
            labelContainer: {
                padding: "10px 0 !important",
            },
            wrapper: {
                minWidth: 0,
                width: "auto",
            },
            selected: {
                color: "#2C99D2",
            },
        },
        MuiStep: {
            color: "red",
            background: "green",
            fill: "red",
            root: {
                fill: "red",
            },
        },
        MuiCollapse: {
            container: {},
        },
        MuiTableSortLabel: {
            iconDirectionDesc: {
                color: "red",
                transform: "rotate(0deg)",
            },
            iconDirectionAsc: {
                color: "green",
                transform: "rotate(180deg)",
            },
            icon: {
                height: 16,
                marginRight: 4,
                marginLeft: 4,
            },
        },
        MuiList: {
            gutters: {
                padding: 0,
            },
        },
        MuiListItemText: {
            primary: {
                fontSize: 14,
                color: "#2C99D2",
                fontWeight: 600,
                paddingLeft: 16,
            },
        },
        MuiListItemIcon: {
            root: {
                color: "#2C99D2",
            },
        },
    },
});

const store = configureStore();
ReactGA.initialize(config.GA_ANALYTICS_TAG);

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Root store={store} />
    </MuiThemeProvider>,
    document.getElementById("root")
);

registerServiceWorker();
