import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import get from "lodash/get";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { us_states_options } from "../utils/constants";
import { propertyTypes } from "../types/propertyTypes";

const PropertyRequestModal = (props) => {
    const { classes, errors, handleSubmit, propertyRequestModal, updatePropertyRequest, togglePropertyRequestModal } =
        props;

    // const errors = propertyRequestModal.errors;

    const handleUpdate = (event) => {
        updatePropertyRequest(event.target.id, event.target.value);
    };

    return (
        <Modal
            aria-labelledby="property-request-modal"
            aria-describedby="request-property"
            open={propertyRequestModal.open}
            onClose={console.log()}
        >
            <div style={getModalStyle()} className={classes.paper}>
                <h4 className="modal-title">Request a Property</h4>
                <React.Fragment>
                    <Grid>
                        <BSInput
                            id="propertyName"
                            label="Property Name"
                            value={propertyRequestModal.propertyName}
                            error={get(errors, `propertyName`, null)}
                            onChange={handleUpdate}
                            placeholder="Enter property name"
                            disabled={false}
                        />
                    </Grid>
                    <Grid>
                        <BSInput
                            id="propertyAddress"
                            label="Address"
                            value={propertyRequestModal.propertyAddress}
                            error={get(errors, `propertyAddress`, null)}
                            onChange={handleUpdate}
                            placeholder="Enter address"
                            disabled={false}
                        />
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <BSInput
                                id="propertyCity"
                                label="City"
                                value={propertyRequestModal.propertyCity}
                                error={get(errors, `propertyCity`, null)}
                                onChange={handleUpdate}
                                placeholder="Enter city"
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BSSelect
                                id="propertyState"
                                label="State"
                                value={propertyRequestModal.propertyState}
                                error={get(errors, `propertyState`, null)}
                                onChange={handleUpdate}
                                placeholder="Select a state"
                                options={us_states_options}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BSInput
                                id="propertyZip"
                                label="Zip Code"
                                value={propertyRequestModal.propertyZip}
                                error={get(errors, `propertyZip`, null)}
                                onChange={handleUpdate}
                                placeholder="Enter zip code"
                                disabled={false}
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <BSSelect
                            id="propertyType"
                            label="Property Type"
                            error={get(errors, `propertyType`, null)}
                            value={propertyRequestModal.propertyType}
                            onChange={handleUpdate}
                            handleVoucherChange={handleUpdate}
                            placeholder="Select a property type"
                            options={[
                                {
                                    value: "",
                                    label: "Select",
                                    disabled: true,
                                },
                                ...propertyTypes,
                            ]}
                        />
                    </Grid>
                    <Grid>
                        <BSInput
                            id="propertyComments"
                            label="Internal Comments"
                            value={propertyRequestModal.propertyComments}
                            error={get(errors, `propertyComments`, null)}
                            onChange={handleUpdate}
                            placeholder="Comments entered here are only visible inside KMConnect."
                            disabled={false}
                            multiline={true}
                            rows={4}
                        />
                    </Grid>
                </React.Fragment>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button variant="outlined" onClick={togglePropertyRequestModal}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                            Request
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = (theme) => ({
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
});

export default withStyles(styles)(PropertyRequestModal);
