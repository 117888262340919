import configs from "../../../../config";

const HOST = configs.HOST_URL + "/";
const USER_URL = HOST + "user/";
const USER_ACCOUNT_URL = "/user/account/";
const urls = {
    get_user_details: (userAccountKey) => {
        return USER_ACCOUNT_URL + userAccountKey;
    },
    switch_user_profile: (userAccountKey) => {
        return "/user/switch/" + userAccountKey;
    },
    ad_import: () => {
        return USER_URL + "import";
    },
};

export default {
    urls,
};
