import React, { Component } from "react";
import { connect } from "react-redux";
import operations from "./ducks/operations";
import contactOperations from "../contact/ducks/operations";
import comments_operations from "../comments/ducks/operations";
import dealOperations from "../deal/ducks/operations";
import dealsOperations from "../deals/ducks/operations";
import propertyOperations from "../property/ducks/operations";
import dealActions from "../deal/ducks/actions";
import searchOperations from "../search/ducks/operations";
import CompToolbar from "./CompToolbar";
import OutsideCompToolbar from "./OutsideCompToolbar";
import OutsideCompComponent from "./OutsideCompComponent";
import AppraisalCompToolbar from "./AppraisalCompToolbar";
import AppraisalCompComponent from "./AppraisalCompComponent";
import CompComponent from "./CompComponent";
import utils from "../utils/index";
import get from "lodash/get";
import map from "lodash/map";
import includes from "lodash/includes";
import { push } from "connected-react-router";
import documentTypes from "../documents/documentTypes";
import ReactGA from "react-ga4";

class CompContainer extends Component {
    componentDidMount() {
        this.props.toggleTerminationDoc(false);
        const { id } = this.props.match.params;
        if (id) {
            this.props.getDeal(id);
        } else {
            this.props.setTransactionType(this.queryParams.type ? this.queryParams.type.toUpperCase() : "LEASE");
        }
        ReactGA.send({ hitType: "pageview", page: "/comp/*", title: `Comp Detail: ${id}` });
    }

    componentWillUnmount() {
        this.props.handleClearDealState();
    }

    render() {
        const { id } = this.props.match.params;

        if (this.props.allowAccess) {
            return includes(["Outside Draft", "Outside"], this.props.deal.compStatus) ? (
                <div>
                    <OutsideCompToolbar {...this.props} transactionType={this.props.deal.transactionType} />
                    <OutsideCompComponent
                        {...this.props}
                        id={id}
                        handleAutocalculateTiSf={this.handleAutocalculateTiSf}
                    />
                </div>
            ) : includes(["Appraisal Draft", "Appraisal"], this.props.deal.compStatus) ? (
                <div>
                    <AppraisalCompToolbar {...this.props} transactionType={this.props.deal.transactionType} />
                    <AppraisalCompComponent
                        {...this.props}
                        id={id}
                        handleAutocalculateTiSf={this.handleAutocalculateTiSf}
                    />
                </div>
            ) : (
                <div>
                    <CompToolbar
                        {...this.props}
                        transactionType={this.props.deal.transactionType}
                        handleAutocalculateTiSf={this.handleAutocalculateTiSf}
                    />

                    <CompComponent {...this.props} id={id} />
                </div>
            );
        } else {
            return null;
        }
    }
}

const determineIfDisabled = (user, role, personKey, brokersOnDeal, deal) => {
    const brokerPersonKeys = map(brokersOnDeal, (b) => {
        return get(b, "person.personKey");
    });
    const isBrokerOnDeal = includes(brokerPersonKeys, personKey);
    const isCreatedByUser = user === deal.auditCreatedBy;
    const isDataEntryOwner = deal.dataEntryOwner && user === deal.dataEntryOwner;
    const isLastModifiedByUser = user === deal.auditLastModifiedBy;
    const isMigrated = deal.auditCreatedBy === "MIGRATION";

    /**
     * Requirement from 4.18.19:
     *
     * All inside comps that are in status 'Closed' are not editable by anyone. All outside comps are editable in any status by the creator.
     *
     * Throughout the deal workflow, the comp may only be edited by the creator while it is in their 'possession',
     * or by CDT member while they are in 'possession' and they are the data entry owner (status In Review, Admin Action Required),
     *  or by a Data Admin user while it is in their 'possession' (status Final Review).
     *
     * A portland broker admin may edit if comp is in status 'Portland Review Required'
     *
     * New requirement 5.21.19:
     *
     * CDT needs to be able to edit comps in outside and closed statuses.
     */

    //needs to be user who created comp to edit for these statuses
    if (
        includes(
            [
                "Outside",
                "Draft",
                "Broker Action Required",
                "Outside Draft",
                "Portland Review Required",
                "AZ Review Required",
                "Outside",
                "Appraisal Draft",
                "Appraisal",
            ],
            deal.compStatus
        )
    ) {
        //typo here is on purpose
        if (isCreatedByUser || (isLastModifiedByUser && isMigrated)) {
            return false;
        }
    }

    //needs to be user who created comp OR broker on deal to edit for these statuses
    if (includes([], deal.compStatus)) {
        if (isCreatedByUser || isBrokerOnDeal) {
            return false;
        }
    }

    //needs to be Data Entry Owner to edit for these statuses
    if (includes(["In Review", "Admin Action Required"], deal.compStatus)) {
        if (isDataEntryOwner) {
            return false;
        }
    }

    //needs to be Data Admin to edit for these statuses
    if (includes(["Final Review"], deal.compStatus)) {
        if (role === "dataAdmin") {
            return false;
        }
    }

    //needs to be Portland Broker Admin to edit for these statuses
    if (includes(["Portland Review Required", "Portland Termination Review Required"], deal.compStatus)) {
        if (role === "portlandBrokerAdmin") {
            return false;
        }
    }

    //needs to be Arizona Broker Admin to edit for these statuses
    if (includes(["AZ Review Required", "AZ Termination Review Required"], deal.compStatus)) {
        if (role === "arizonaBrokerAdmin") {
            return false;
        }
    }

    //needs to be Data Entry or Data Admin to edit for these statuses
    if (includes(["Outside", "Closed"], deal.compStatus)) {
        if (includes(["dataEntry", "dataAdmin"], role)) {
            return false;
        }
    }

    //allows research to edit outside comps
    if (includes(["Outside", "Appraisal"], deal.compStatus)) {
        if (includes(["research", "researchAdmin"], role)) {
            return false;
        }
    }

    return true;
};

const displayConfidentialCompData = (user, role, personKey, brokersOnDeal, deal) => {
    const brokerPersonKeys = map(brokersOnDeal, (b) => {
        return get(b, "person.personKey");
    });
    const isBrokerOnDeal = includes(brokerPersonKeys, personKey);
    const isCreatedByUser = user === deal.auditCreatedBy;
    const isDataEntryOwner = deal.dataEntryOwner && user === deal.dataEntryOwner;
    const isLastModifiedByUser = user === deal.auditLastModifiedBy;

    return (
        isBrokerOnDeal ||
        isCreatedByUser ||
        isDataEntryOwner ||
        isLastModifiedByUser ||
        includes(["dataEntry", "dataAdmin", "accounting", "evp", "portlandBrokerAdmin", "arizonaBrokerAdmin"], role)
    );
};

const determineRestrictViewDocuments = (user, role, personKey, brokersOnDeal, deal) => {
    const brokerPersonKeys = map(brokersOnDeal, (b) => {
        return get(b, "person.personKey");
    });
    const isBrokerOnDeal = includes(brokerPersonKeys, personKey);
    const isCreatedByUser = user === deal.auditCreatedBy;
    const isLastModifiedByUser = user === deal.auditLastModifiedBy;
    const isMigrated = deal.auditCreatedBy === "MIGRATION";

    /**
     * Requirement from 4.18.19:
     * Documents on a comp are viewable during the deal workflow by the creator OR a CDT OR Data Admin user regardless of status.
     * Documents are viewable by the creator OR other brokers on the deal when the comp is moved to 'Closed' statuses.
     * Documents are viewable on submitted outside comps by anyone. Only creator and CDT/Data Admin may view while comp is in 'Outside Draft' status
     *
     * Requirement from 6.6.19:
     * RP users should be able to view documents on any comps that are pulled up through the comp search
     */

    //anyone can view any document on outside comp
    if (includes(["Outside", "Appraisal"], deal.compStatus)) {
        return false;
    }

    //if not closed, needs to be user who created comp or data entry
    if (
        includes(
            [
                "Outside Draft",
                "Draft",
                "Broker Action Required",
                "Portland Review Required",
                "Portland Termination Review Required",
                "AZ Review Required",
                "AZ Termination Review Required",
                "Needs Review",
                "In Review",
                "Admin Action Required",
                "Final Review",
            ],
            deal.compStatus
        )
    ) {
        if (isCreatedByUser || role === "dataEntry" || role === "dataAdmin" || (isLastModifiedByUser && isMigrated)) {
            return false;
        }
    }

    //portland
    if (includes(["Portland Review Required", "Portland Termination Review Required"], deal.compStatus)) {
        if (
            isCreatedByUser ||
            includes(["dataEntry", "dataAdmin", "portlandBrokerAdmin"], role) ||
            (isLastModifiedByUser && isMigrated)
        ) {
            return false;
        }
    }

    //arizona
    if (includes(["AZ Review Required", "AZ Termination Review Required"], deal.compStatus)) {
        if (
            isCreatedByUser ||
            includes(["dataEntry", "dataAdmin", "arizonaBrokerAdmin"], role) ||
            (isLastModifiedByUser && isMigrated)
        ) {
            return false;
        }
    }

    //if closed, needs to be user who created comp OR broker on deal OR RP, dataEntry, or dataAdmin
    if (includes(["Closed"], deal.compStatus)) {
        if (isCreatedByUser || isBrokerOnDeal || role === "dataEntry" || role === "dataAdmin" || role === "evp") {
            return false;
        }
    }

    //needs to be Portland Broker Admin
    if (includes(["Portland Review Required", "Portland Termination Review Required"], deal.compStatus)) {
        if (role === "portlandBrokerAdmin") {
            return false;
        }
    }

    //needs to be AZ Broker Admin
    if (includes(["AZ Review Required", "AZ Termination Review Required"], deal.compStatus)) {
        if (role === "arizonaBrokerAdmin") {
            return false;
        }
    }

    //portland broker admin special case
    if (
        role === "portlandBrokerAdmin" &&
        deal.transactionType === "Sale" &&
        get(deal, "listing.property.primaryAddress.city.state.stateCode") === "OR"
    ) {
        return false;
    }

    return true;
};

const determineDocumentOptions = (user, role, comp, personKey, authRole) => {
    const allDocTypes = get(documentTypes, "allDocTypesList");

    let options = {
        canView: [],
        canDelete: [],
        canUpload: [],
    };

    let compDocTypes = [];
    compDocTypes = [
        "AIR_PROPERTY_INFORMATION",
        "ANY_OFFER",
        "AMENDMENTS",
        "CLOSING_STATEMENT_BUYERS",
        "CLOSING_STATEMENT_BUYER_NOT_REPRESENTED",
        "CLOSING_STATEMENT_SELLERS",
        "CLOSING_STATEMENT_SELLERS_NOT_REPRESENTED",
        "CONTINGENCY_REMOVAL",
        "COMMISSION_STATEMENT",
        "CORRESPONDENCE",
        "EARNEST_MONEY_OR_EQUIVALENT",
        "EARTHQUAKE_DISCLOSURE",
        "ESCROW_INSTRUCTIONS",
        "ESCROW_RECEIPT",
        "EXHIBITS",
        "FIRPTA",
        "FULLY_EXECUTED_LEASE",
        "MASTER_LEASE_IF_SUBLEASE",
        "PRELIM_TITLE_REPORT",
        "FLYER",
        "SECONDARY_FLYER",
        "MARKETING_FLYER_PHOTO",
        "PURCHASE_AND_SALE_AGREEMENT",
        "RELEVANT_CORRESPONDENCE",
        "SELLERS_DISCLOSURE",
        "TERMINATION_DOCUMENT",
        "TERMITE_INSPECTION_REPORT",
        "OFFERING_MEMORANDUM",
        "OTHER",
    ];

    if (includes(["Closed"], comp.compStatus)) {
        if (authRole === "dataAdmin") {
            options.canView = [...allDocTypes];
            options.canDelete = [...allDocTypes];
            options.canUpload = [...compDocTypes];
        } else {
            options.canView = [...allDocTypes];
            options.canDelete = [];
            options.canUpload = [...compDocTypes];
        }
    } else {
        options = null;
    }
    return options;
};

const allowAccess = (user, role, personKey, brokersOnDeal, deal) => {
    const brokerPersonKeys = map(brokersOnDeal, (b) => {
        return get(b, "person.personKey");
    });
    const isBrokerOnDeal = includes(brokerPersonKeys, personKey);
    const isCreatedByUser = user === deal.auditCreatedBy;
    const isConfidential = get(deal, "confidentialFlg", true);

    if (isConfidential) {
        if (
            isBrokerOnDeal ||
            isCreatedByUser ||
            includes(["dataEntry", "dataAdmin"], role) ||
            (includes("portlandBrokerAdmin", role) && deal.portlandDeal) ||
            (includes("arizonaBrokerAdmin", role) && deal.arizonaDeal)
        ) {
            return true;
        } else {
            return false;
        }
    } else if (
        includes(["Appraisal", "Appraisal Draft"], deal.compStatus) &&
        !includes(["dataEntry", "dataAdmin", "appraiser", "research", "researchAdmin"], role)
    ) {
        return false;
    }
    return true;
};

const displaySellerLandlordCompData = (user, role, personKey, brokersOnDeal, deal) => {
    // The following are permitted to view seller/landlord data
    // Corp Data Team or Sys admin
    // Any broker on the deal
    // Any broker on the listing

    const listingBrokerPersonKeys = map(get(deal, "listing.listingBrokers.kmListingBrokers", []), (b) => {
        return get(b, "brokerAgentKey.person.personKey");
    });
    const brokerPersonKeys = map(brokersOnDeal, (b) => {
        return get(b, "person.personKey");
    });
    const isBrokerOnDeal = includes(brokerPersonKeys, personKey);
    const isCreatedByUser = user === deal.auditCreatedBy;
    const isBrokerOnListing = includes(listingBrokerPersonKeys, personKey);
    const isCDTOrAdmin = includes(["dataEntry", "dataAdmin"], role);
    const isPortlandDeal = includes("portlandBrokerAdmin", role) && deal.portlandDeal;
    const isArizonaDeal = includes("arizonaBrokerAdmin", role) && deal.arizonaDeal;

    return isCreatedByUser || isCDTOrAdmin || isBrokerOnDeal || isBrokerOnListing || isPortlandDeal || isArizonaDeal;
};

const mapStateToProps = (state) => {
    var nextState = {
        userProfile: state.authReducer.userProfile,
        valid: state.authReducer.validFormValues,
        snackbarOpen: state.authReducer.snackbarOpen,
        deal: state.dealReducer.deal,
        installments: state.dealReducer.installments ? state.dealReducer.installments : {},
        totalConsideration: state.dealReducer.totalConsideration ? state.dealReducer.totalConsideration : {},
        invoice: state.dealReducer.invoice ? state.dealReducer.invoice : null,
        propertyAttributes: state.dealReducer.propertyAttributes ? state.dealReducer.propertyAttributes : {},
        listingAttributes: get(state, "dealReducer.deal.listing.attributes", null)
            ? state.dealReducer.deal.listing.attributes
            : {},
        property: state.propertyReducer.property ? state.propertyReducer.property : {},
        errors: state.dealReducer.errors ? state.dealReducer.errors : {},
        expandedErrorPanels: state.utilsReducer.panelsToExpand,
        checkErrorPanels: state.utilsReducer.checkErrorPanels,
        searchResults: state.dealReducer.searchResults,
        autocompleteResults: state.searchReducer.autocompleteResults,
        classes: state.dealReducer.classes,
        companyInformation: state.dealReducer.companyInformation ? state.dealReducer.companyInformation : {},
        executeInvoiceModalOpen: state.dealReducer.executeInvoiceModalOpen
            ? state.dealReducer.executeInvoiceModalOpen
            : false,
        isSaving: state.utilsReducer.isSaving,
        saveError: state.compReducer.saveError ? state.voucherReducer.saveError : null,
        lastSaved: state.compReducer.lastSaved ? state.voucherReducer.lastSaved : null,
        numComments: state.commentsReducer.comments.length,
        snackbar: state.utilsReducer.snackbar,
        documentWarning: state.compReducer.documentWarning,
        confirmDialog: state.utilsReducer.confirmDialog,
        elementsSaving: state.utilsReducer.elementsSaving,
        terminateDialog: state.dealReducer.terminateDialog,
        confirmTerminateDialog: state.dealReducer.confirmTerminateDialog,
        terminationDocument: state.dealReducer.terminationDocument,
        deleteOutsideCompDialog: state.compReducer.deleteOutsideCompDialog,
        brokersOnDeal: state.dealReducer.brokersOnDeal,
        disableInputs: determineIfDisabled(
            state.authReducer.userProfile.user,
            state.authReducer.userProfile.role,
            state.authReducer.userProfile.personKey,
            state.dealReducer.brokersOnDeal,
            state.dealReducer.deal
        ),
        determineDocumentOptions: determineDocumentOptions(
            state.authReducer.userProfile.user,
            state.authReducer.userProfile.role,
            state.dealReducer.deal,
            state.authReducer.userProfile.personKey,
            get(state.authReducer.authenticatedUserProfile, "role", null)
        ),
        restrictViewDocuments: determineRestrictViewDocuments(
            state.authReducer.userProfile.user,
            state.authReducer.userProfile.role,
            state.authReducer.userProfile.personKey,
            state.dealReducer.brokersOnDeal,
            state.dealReducer.deal
        ),
        allowAccess: allowAccess(
            state.authReducer.userProfile.user,
            state.authReducer.userProfile.role,
            state.authReducer.userProfile.personKey,
            state.dealReducer.brokersOnDeal,
            state.dealReducer.deal
        ),
        displayConfidentialCompData: displayConfidentialCompData(
            state.authReducer.userProfile.user,
            state.authReducer.userProfile.role,
            state.authReducer.userProfile.personKey,
            state.dealReducer.brokersOnDeal,
            state.dealReducer.deal
        ),
        displaySellerLandlordCompData: displaySellerLandlordCompData(
            state.authReducer.userProfile.user,
            state.authReducer.userProfile.role,
            state.authReducer.userProfile.personKey,
            state.dealReducer.brokersOnDeal,
            state.dealReducer.deal
        ),
    };
    return nextState;
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleAddContactInfo(contactType) {
            dispatch(contactOperations.addContact(contactType));
        },
        handleContactInfoCompanyUpdate: (contactType, key, field, value) => {
            dispatch(contactOperations.updateContactCompany(contactType, key, field, value));
        },
        getDeal: (dealKey) => {
            dispatch(operations.getDeal(dealKey));
        },
        setTransactionType: (type) => {
            dispatch(dealOperations.dealChange("transactionType", type));
        },
        handleSaveAndExit: () => {
            //skips pre submission tasks like validation
            dispatch(operations.submitComp({ elementSaving: "saveAndExit", shouldExit: true }));
        },
        handleSearchCompany: (inputValue, companyType, excludeParentCompanies = false) => {
            dispatch(searchOperations.searchCompany(inputValue, companyType, excludeParentCompanies));
        },
        handleSearchPerson: (inputValue) => {
            dispatch(searchOperations.searchKMPersonAutocomplete(inputValue));
        },
        handleSearchKMBrokers: (inputValue) => {
            dispatch(searchOperations.searchKMBrokers(inputValue));
        },
        handleSearchOutsideBrokers: (inputValue) => {
            dispatch(searchOperations.searchOutsideBroker(inputValue));
        },
        handleObjectPropertyChange: (objectPropertyName, event) => {
            dispatch(
                dealOperations.handleObjectPropertyChange(objectPropertyName, event.target.id, event.target.value)
            );
        },
        handleCheckboxClick: (name, event) => {
            dispatch(dealOperations.dealChange(name, event.target.checked));
        },
        handlePropertyChange: (field, value) => {},
        handleConfidential: (field, value) => {
            dispatch(dealOperations.dealChange(field, value));
        },
        handleDealChange: (event) => {
            dispatch(dealOperations.dealChange(event.target.id, event.target.value));
        },
        handlePropertyDetailChange: (field, value) => {
            dispatch(operations.handlePropertyDetailsChange(field, value));
        },
        toggleSnackBar: (open, variant, message) => {
            dispatch(utils.operations.snackbar(open, variant, message));
        },
        toggleConfirm: (onSubmit) => {
            dispatch(utils.operations.toggleConfirm(onSubmit));
        },
        handleDocumentWarning: (open, proceed, docs) => {
            dispatch(operations.handleDocumentWarning(open, proceed, docs));
        },
        handleConfirmWarning: () => {
            dispatch(operations.handleDocumentWarning(false, true, []));
        },
        toggleComments: () => {
            dispatch(comments_operations.toggleComments());
        },
        handleToggleBooleanProperty: (field) => {
            dispatch(dealOperations.handleToggleBooleanProperty(field));
        },
        handleExecuteInvoice: () => {
            dispatch(dealOperations.executeInvoice());
        },
        handleTotalConsiderationChange: (field, value) => {
            dispatch(dealOperations.handleTotalConsiderationChange(field, value));
        },
        handleCalculateTotalConsiderationDetails() {
            dispatch(dealOperations.handleCalculateTotalConsiderationDetails());
        },
        handleClearRentSchedule() {
            dispatch(dealOperations.handleClearRentSchedule());
        },
        handlePeriodMonthChange: (periodNumber, month, field, value) => {
            dispatch(dealOperations.handlePeriodMonthChange(periodNumber, month, field, value));
        },
        handleCompanyPropertyChange: (companyType, property, value) => {
            dispatch(dealOperations.handleCompanyPropertyChange(companyType, property, value));
        },
        submitOutsideOrAppraisalComp: (callback, skipRequiredValidation, submitStatus, compType) => {
            dispatch(
                operations.handlePreOutsideOrAppraisalCompSubmissionTasks({
                    callback: callback,
                    skipRequiredValidation: skipRequiredValidation,
                    elementSaving: "submit",
                    submitStatus: submitStatus,
                    shouldExit: true,
                }),
                compType
            );
        },
        saveOutsideOrAppraisalComp: (compType) => {
            dispatch(
                operations.handlePreOutsideOrAppraisalCompSubmissionTasks(
                    {
                        callback: null,
                        skipRequiredValidation: true,
                        elementSaving: "saveAndContinue",
                        shouldExit: false,
                    },
                    compType
                )
            );
        },
        saveAndExitOutsideOrAppraisalComp: (compType) => {
            dispatch(
                operations.handlePreOutsideOrAppraisalCompSubmissionTasks(
                    { callback: null, skipRequiredValidation: true, elementSaving: "saveAndExit", shouldExit: true },
                    compType
                )
            );
        },

        //2.11.19
        submitInsideComp: (callback, skipRequiredValidation) => {
            dispatch(utils.operations.toggleConfirm());
            dispatch(
                operations.handlePreCompSubmissionTasks({
                    callback: callback,
                    skipRequiredValidation: skipRequiredValidation,
                    elementSaving: "submit",
                    shouldExit: true,
                })
            );
        },
        saveInsideComp: () => {
            dispatch(
                operations.handlePreCompSubmissionTasks({
                    callback: null,
                    skipRequiredValidation: true,
                    elementSaving: "saveAndContinue",
                    shouldExit: false,
                })
            );
        },
        saveAndExitInsideComp: () => {
            dispatch(
                operations.handlePreCompSubmissionTasks({
                    callback: null,
                    skipRequiredValidation: true,
                    elementSaving: "saveAndExit",
                    shouldExit: true,
                })
            );
        },
        handleInput: (path, value) => {
            dispatch(operations.handleInput("deal." + path, value));
        },
        handleClearDealState: () => {
            dispatch(operations.handleInput("deal", {}));
            dispatch(dealActions.clearErrors());
            dispatch(dealOperations.clearTotalConsiderationState());
        },
        handleAddArrayElement: (path, obj) => {
            dispatch(operations.addArrayElement("deal." + path, obj));
        },
        handleDeleteArrayElement: (path, index) => {
            dispatch(operations.deleteArrayElement("deal." + path, index));
        },
        handleUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.handleInput("deal." + arrayPath + "[" + index + "]." + field, value));
        },
        toggleCheckErrorPanels: (value) => {
            dispatch(utils.actions.toggleCheckErrorPanels(value));
        },
        handleStartReviewingAsCorporateData: (deal) => {
            dispatch(utils.operations.toggleConfirm());
            dispatch(dealsOperations.handleStartReviewingAsCorporateData(deal));
        },
        handleClearTotalConsiderationState: () => {
            dispatch(dealOperations.clearTotalConsiderationState());
        },
        handleDisplayActiveListings: () => {
            dispatch(push("/listings"));
        },
        togglePropertySearchModal: (isOpen) => {
            dispatch(propertyOperations.togglePropertySearchModal(isOpen));
        },
        toggleTerminateDialog: (isOpen, dealStatus) => {
            dispatch(dealOperations.toggleTerminateDialog(isOpen, dealStatus));
        },
        handleTerminateDeal: (dealKey, terminationDocument) => {
            dispatch(dealOperations.verifyDocumentAndTerminatePendingDeal(dealKey, terminationDocument));
        },
        toggleTerminationDoc: (attached) => {
            dispatch(dealOperations.terminationDocUpload(attached));
        },
        handleConfirmTerminateDeal: (dealKey, terminationDocument, dealStatus) => {
            dispatch(dealOperations.verifyDocumentAndTerminateDeal(dealKey, terminationDocument, dealStatus));
        },
        handleDisplayDeleteDialog: (isOpen) => {
            dispatch(operations.displayDeleteDialog(isOpen));
        },
        handleDeleteOutsideComp: (dealKey) => {
            dispatch(operations.handleDeleteOutsideComp(dealKey));
        },
        handlePeriodChange: (period, field, value) => {
            dispatch(dealOperations.handlePeriodChange(period, field, value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompContainer);
