import actions from "./actions";
import utils_actions from "../../utils/actions";
import constants from "../constants";
import toLower from "lodash/toLower";
import { request } from "../../utils/axios-wrapper";
import { push } from "connected-react-router";
import ReactGA from "react-ga4";

const getComments = (parentRecordType, parentRecordKey) => {
    return (dispatch, _) => {
        request({ method: "get", url: constants.urls.get_comments(parentRecordType, parentRecordKey) }, dispatch)
            .then((response) => {
                let comments = response.data;
                dispatch(actions.receiveComments(comments));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

const submitComment = (parentRecordType, parentRecordKey) => {
    return (dispatch, getState) => {
        console.log(parentRecordType);
        console.log(parentRecordKey);
        request(
            {
                method: "post",
                url: constants.urls.post_comment(parentRecordType, parentRecordKey),
                data: getState().commentsReducer.commentDraft,
                headers: { "Content-Type": "text/plain" },
            },
            dispatch
        ).then((response) => {
            dispatch(actions.clearComment());
            dispatch(getComments(parentRecordType, parentRecordKey));
            dispatch(utils_actions.apiResponse(response));
        });
    };
};

const handleChange = (field, value) => {
    return (dispatch, _) => {
        dispatch(actions.handleChange(field, value));
    };
};

const toggleComments = () => {
    return (dispatch, getState) => {
        dispatch(actions.toggleComments(!getState().commentsReducer.commentsDrawerOpen));
    };
};

const closeCommentDrawer = () => {
    return (dispatch, getState) => {
        dispatch(actions.toggleComments(false));
    };
};

const clearComment = () => {
    return (dispatch, getState) => {
        dispatch(actions.clearComment());
    };
};

const setStateForRequestUpdate = (redirectTo) => {
    return (dispatch, getState) => {
        dispatch(toggleComments());
        dispatch(actions.setStateForRequestUpdate(redirectTo));
    };
};

const requestUpdate = (parentRecordType, parentRecordKey) => {
    return (dispatch, getState) => {
        const redirectTo = getState().commentsReducer.redirectTo;
        submitCommentCall(parentRecordType, parentRecordKey, getState().commentsReducer.commentDraft, dispatch)
            .then((response) => {
                dispatch(actions.clearComment());
                dispatch(getComments(parentRecordType, parentRecordKey));
                //change status of entity
                switch (toLower(parentRecordType)) {
                    case "listing":
                        request(
                            {
                                method: "put",
                                url: constants.urls.request_listing_update(parentRecordKey),
                                headers: { "Content-Type": "application/json" },
                            },
                            dispatch
                        )
                            .then((response) => {
                                dispatch(toggleComments());
                                ReactGA.event("Listing Update Requested", {
                                    listingKey: parentRecordKey,
                                });
                                dispatch(push(redirectTo ? redirectTo : `/listing/${parentRecordKey}`));
                            })
                            .catch((err) => {
                                utils_actions.snackbar(
                                    true,
                                    "error",
                                    "Error moving listing to active update requested Status: " + err
                                );
                            });
                        break;
                    case "property":
                        request(
                            {
                                method: "put",
                                url: constants.urls.update_property_status(parentRecordKey),
                                headers: { "Content-Type": "application/json" },
                                data: {
                                    propertyStatus: "Update Requested",
                                },
                            },
                            dispatch
                        )
                            .then((response) => {
                                dispatch(toggleComments());
                                ReactGA.event("Property Update Requested", {
                                    propertyKey: parentRecordKey,
                                });
                                dispatch(push(redirectTo ? redirectTo : `/property/${parentRecordKey}`));
                            })
                            .catch((err) => {
                                utils_actions.snackbar(
                                    true,
                                    "error",
                                    "Error moving property to Needs Review Status: " + err
                                );
                            });
                        break;
                    default:
                        break;
                }
            })
            .catch((err) => {});
    };
};

const submitCommentCall = (parentRecordType, parentRecordKey, comment, dispatch) => {
    return request(
        {
            method: "post",
            url: constants.urls.post_comment(parentRecordType, parentRecordKey),
            data: comment,
            headers: { "Content-Type": "text/plain" },
        },
        dispatch
    );
};

export default {
    getComments,
    submitComment,
    handleChange,
    toggleComments,
    clearComment,
    closeCommentDrawer,
    setStateForRequestUpdate,
    requestUpdate,
};
