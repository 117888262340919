import React, { Component } from "react";
import { connect } from "react-redux";
import AccountingVouchersContainer from "./AccountingVouchersContainer";
import get from "lodash/get";
import includes from "lodash/includes";
import ReactGA from "react-ga4";

class VouchersContainer extends Component {
    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: "/vouchers", title: "Vouchers" });
    }

    render() {
        if (includes(["accounting"], get(this.props.userProfile, "role", null))) {
            switch (this.props.role) {
                case "accounting": {
                    return <AccountingVouchersContainer {...this.props} />;
                }
                default:
                    return <AccountingVouchersContainer {...this.props} />;
            }
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.authReducer.userProfile,
        role: state.authReducer.userProfile.role,
        user: state.authReducer.userProfile.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VouchersContainer);
