export const realEstateTaxesPaidByOptions = [
    {
        value: "",
        label: "Select",
        disabled: true,
    },
    {
        value: "Kidder",
        label: "Kidder",
    },
    {
        value: "Other",
        label: "Other",
    },
];

export const taxOwnershipInformationOptions = [
    {
        value: "",
        label: "Select",
        disabled: true,
    },
    {
        value: "Lender",
        label: "Lender",
    },
    {
        value: "Owner Pays Directly",
        label: "Owner Pays Directly",
    },
    {
        value: "Other",
        label: "Other",
    },
];

export const softwareLicenseReimbursedFrequencyOptions = [
    {
        value: "",
        label: "Select",
        disabled: true,
    },
    {
        value: "Monthly",
        label: "Monthly",
    },
    {
        value: "Annually",
        label: "Annually",
    },
];

export default {
    realEstateTaxesPaidByOptions,
    taxOwnershipInformationOptions,
    softwareLicenseReimbursedFrequencyOptions,
};
