import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AuditFiltersContainer from "./AuditFiltersContainer";
import AuditSearchHeaders from "./AuditSearchHeaders";
import AuditTabs from "./AuditTabs";
import AuditCompsTitle from "./AuditCompsTitle";
import AuditSearchResultsContainer from "./AuditSearchResultsContainer";
import PermissionsGate from "./permissions/PermissionsGate";
import { SCOPES, audit_tabs } from "./constants";
import AuditorListResults from "./AuditorListResults";

class AuditSearch extends Component {
    render() {
        const { searchResponse, handleSort, activeTab } = this.props;

        const { auditorSearch, auditorList } = audit_tabs;
        return (
            <React.Fragment>
                <PermissionsGate scopes={[SCOPES.canViewFilters]} hide>
                    <AuditFiltersContainer />
                </PermissionsGate>
                <AuditCompsTitle searchResponse={searchResponse} />
                <Grid container alignItems="center" spacing={16} style={{ maxWidth: "100%" }}>
                    <Grid item xs>
                        <div className="simPaper">
                            <AuditTabs />
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <AuditSearchHeaders activeHeader={activeTab} handleSort={handleSort} />
                                    {activeTab === auditorSearch && <AuditSearchResultsContainer />}
                                    {activeTab === auditorList && <AuditorListResults {...this.props} />}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

const styles = () => ({});

export default withStyles(styles)(AuditSearch);
