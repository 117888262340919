import React from "react";
import { Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { get, includes } from "lodash";

const PropertyAddAssetServicesDataComponent = (props) => {
    const { role, addPropertyManagementSection, classes, property } = props;

    return (
        <React.Fragment>
            <Grid container spacing={8}>
                <Grid item xs>
                    <div className={classes.propertyManagement}>
                        <h4 className={classes.propertyManagementHeader}>There is no asset services data</h4>
                        {includes(["transition", "dataAdmin", "dataEntry"], role) && (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => addPropertyManagementSection(get(property, "propertyKey", null))}
                            >
                                Add Asset Services Data
                            </Button>
                        )}
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const styles = {
    propertyManagement: {
        border: "2px dashed #dbdbdb",
        textAlign: "center",
        paddingBottom: 20,
        marginTop: 15,
        borderRadius: 3,
    },
    propertyManagementHeader: {
        fontSize: 22,
        margin: "26px 0 30px",
    },
};
export default withStyles(styles)(PropertyAddAssetServicesDataComponent);
