import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Dropzone from "react-dropzone";
import Modal from "@material-ui/core/Modal";
import FileIcon from "@material-ui/icons/Collections";
import Typography from "@material-ui/core/Typography";
import operations from "./ducks/operations.js";

class ListingImportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            selectedFileName: "",
            fileRejected: false,
            fileReady: false,
            templateLink:
                "https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/KMC_ListingImport_StandardTemplate.csv",
        };
    }

    onFileChange = (file) => {
        try {
            this.setState({
                selectedFile: file[0],
                selectedFileName: file[0].name,
                fileRejected: false,
                fileReady: true,
            });
        } catch (e) {
            this.setState({
                fileRejected: true,
                fileReady: false,
                selectedFileName: "",
            });
        }
    };

    onFileRemove = () => {
        this.setState({
            selectedFile: null,
            selectedFileName: "",
            fileReady: false,
            fileRejected: false,
        });
    };

    onFileSubmit = async () => {
        this.setState({ fileReady: false }); // Stops repeated button press after first submit
        const status = await operations.uploadListingFile(this.state.selectedFile);
        if (status >= 200 && status < 300) {
            this.props.importResponse(true);
        } else {
            this.props.importResponse(false);
        }
        this.onFileRemove();
        this.props.closeModal();
    };

    onExitModal = () => {
        this.onFileRemove();
        this.props.closeModal();
    };

    onTemplateClick = () => {
        window.open(this.state.templateLink, "_self");
    };

    trimFileName = () => {
        let stringLength = this.state.selectedFileName.length;
        if (stringLength > 40) {
            return this.state.selectedFileName
                .substring(0, 31)
                .concat("...", this.state.selectedFileName.substring(stringLength - 7, stringLength));
        } else {
            return this.state.selectedFileName;
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <Modal
                open={this.props.modalOpen}
                onClose={() => this.onExitModal()}
                style={{
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10vh",
                }}
            >
                <div className={classes.container}>
                    <h4 className={classes.header}>Import Listings</h4>
                    <Typography variant="body1" style={{ marginRight: "24px", marginLeft: "24px" }}>
                        Use the file selection tool to upload a CSV file containing outside listing data. Please ensure
                        your column names exactly match the provided{" "}
                        <a className={classes.linkStyling} href={this.state.templateLink} download>
                            Standard Import Template
                        </a>
                        .
                    </Typography>
                    <Typography variant="body1" style={{ marginRight: "24px", marginLeft: "24px", marginTop: "24px" }}>
                        Once your file is uploaded, you will receive an email summary within 30 minutes.
                    </Typography>
                    <Dropzone
                        accept={[
                            ".csv",
                            "text/csv",
                            "application/csv",
                            "text/x-csv",
                            "application/x-csv",
                            "text/comma-separated-values",
                            "text/x-comma-separated-values",
                        ]}
                        onDrop={(acceptedFiles) => this.onFileChange(acceptedFiles)}
                        className={classes.dropzoneComponent}
                    >
                        <div className="dragdrop-instructions">
                            <CloudUploadIcon className={classes.icon} />
                            <h4>Drop files in this area to attach or browse here</h4>
                            <p> (.csv files only)</p>
                        </div>
                    </Dropzone>
                    {this.state.fileRejected === false ? (
                        <div>
                            {this.state.selectedFileName !== "" ? (
                                <Typography variant="body1" style={{ marginLeft: "24px" }}>
                                    Selected file: <FileIcon className={classes.icon} />
                                    <p
                                        style={{ color: "#2C99D2", display: "inline", marginLeft: "0px" }}
                                        className={classes.text}
                                    >
                                        {this.trimFileName()}
                                    </p>
                                </Typography>
                            ) : (
                                <Typography variant="body1" style={{ marginLeft: "24px" }}>
                                    No File Selected
                                </Typography>
                            )}
                        </div>
                    ) : (
                        <div>
                            <Typography variant="body1" style={{ marginLeft: "24px", color: red[500] }}>
                                Failed: File must be of type CSV.
                            </Typography>
                        </div>
                    )}
                    <div style={{ position: "absolute", bottom: 24, right: 24 }}>
                        {this.state.selectedFileName !== "" && (
                            <Button
                                className={classes.buttonStyling}
                                variant="outlined"
                                onClick={() => this.onFileRemove()}
                            >
                                Remove File
                            </Button>
                        )}
                        <Button
                            style={{ display: "inline-block", marginLeft: "10px" }}
                            variant="outlined"
                            onClick={() => this.onExitModal()}
                        >
                            Exit
                        </Button>
                        <Button
                            style={{ display: "inline-block", marginLeft: "10px" }}
                            variant="contained"
                            color="primary"
                            disabled={this.state.fileReady ? false : true}
                            onClick={() => this.onFileSubmit()}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const styles = (theme) => ({
    header: {
        marginTop: "45px",
        textAlign: "left",
        fontSize: "18px",
        marginLeft: "24px",
    },
    text: {
        color: "black",
        fontSize: "16px",
        fontWeight: "300",
        textAlign: "left",
        marginLeft: "24px",
    },
    container: {
        margin: "0 auto",
        marginTop: "10%",
        position: "relative",
        alignItems: "center",
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
        width: "700px",
        height: "635px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
    },
    dropzoneComponent: {
        width: "560px",
        "& > input": {
            width: "700px",
        },
        textAlign: "center",
        paddingTop: "64px",
        paddingBottom: "64px",
        margin: "0 auto",
        marginTop: "25px",
        marginBottom: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderWidth: 2,
        borderRadius: 5,
        borderColor: "#9E9E9E",
        borderStyle: "dashed",
    },
    buttonStyling: {
        display: "inline",
        color: red[500],
        "&:hover": {
            backgroundColor: red[100],
        },
        border: "1px solid",
        borderColor: red[500],
    },
    icon: {
        color: "#9E9E9E",
        verticalAlign: "middle",
        // paddingBottom: '2px',
        // marginLeft: '5px',
        // marginRight: '3px',
        width: "70px",
        height: "70px",
    },
    linkStyling: {
        margin: 0,
        padding: 0,
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(44, 153, 210, 0.0)",
            color: "rgb(44, 153, 210)",
            textDecoration: "underline",
            cursor: "pointer",
        },
    },
});

export default withStyles(styles)(ListingImportModal);
