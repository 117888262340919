import React from "react";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = (theme) => ({
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    margin: {
        margin: 0,
        marginBottom: "10px",
    },
    // cssLabel: {
    //   '&$cssFocused': {
    //     color: purple[500],
    //   },
    // },
    cssFocused: {},
    cssUnderline: {
        "&:after": {
            // borderBottomColor: purple[500],
        },
    },
    bootstrapRoot: {
        "label + &": {
            marginTop: theme.spacing.unit * 3,
        },
    },
    bootstrapInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
    bootstrapFormLabel: {
        fontSize: 18,
    },
    clearLink: {
        textTransform: "none",
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
        },
    },
    progressDiv: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
    },
});

class ExecuteInvoiceModal extends React.Component {
    render() {
        const { open, classes, handleExecuteInvoice, toggleModal, saving } = this.props;
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={toggleModal}
            >
                <div style={getModalStyle()} className={classes.paper}>
                    <h4 className="modal-title">Are you sure you want to generate this invoice?</h4>
                    <p style={{ fontSize: 14, marginBottom: 20 }}>
                        Make sure to review all your changes and then click the button below.
                    </p>
                    <Grid container justify="flex-end" alignItems="center">
                        <Grid item>
                            <Button variant="outlined" color="primary" onClick={toggleModal}>
                                Cancel
                            </Button>
                            {saving ? (
                                <div className={classes.progressDiv}>
                                    <CircularProgress size={20} color="primary" />
                                </div>
                            ) : (
                                <Button variant="contained" color="secondary" onClick={handleExecuteInvoice}>
                                    Yes, Generate
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        );
    }
}

ExecuteInvoiceModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const ExecuteInvoiceModalWrapped = withStyles(styles)(ExecuteInvoiceModal);

export default ExecuteInvoiceModalWrapped;
