import types from "./types";
import cloneDeep from "lodash/cloneDeep";
import setWith from "lodash/setWith";
import clone from "lodash/clone";
import get from "lodash/get";

export default (
    state = {
        requestModal: {
            selectedProperty: {},
            tab: "existing",
        },
        listings: {
            property_listings: [],
            broker_active: [],
            listings_group: [],
        },
        isLoading: true,
        // listingTab:
        numListings: {},
        listing: {},
        selectedListings: new Map(),
        selectedListingsGroup: new Map(),
        activeSelectedListings: new Map(),
        inactivateListingDialog: {
            open: false,
            onSubmit: null,
        },
        deleteListingDialog: {
            open: false,
            onSubmit: null,
        },
        editProperty: {
            open: false,
            onSubmit: null,
        },
        reportTitleModal: {
            open: false,
            title: null,
        },
        brokerInfoReport: {
            include: true,
        },
        listingDuplicateDialog: {
            open: false,
        },
        duplicateSuccessDialog: {
            open: false,
        },
    },
    action
) => {
    switch (action.type) {
        case types.GET_LISTING:
            return {
                ...state,
                listingRequestData: action.data,
            };
        case types.TOGGLE_REQUEST_LISTING_MODAL:
            return {
                ...state,
                requestModal: {
                    open: action.open,
                    useType: action.useType,
                    transactionType: action.transactionType,
                    selectedProperty: {},
                    tab: "existing",
                },
            };
        case types.SELECT_PROPERTY_FROM_AUTOCOMPLETE:
            return {
                ...state,
                requestModal: {
                    ...state.requestModal,
                    selectedProperty: action.property,
                },
            };
        case types.REMOVE_SELECTED_PROPERTY_FROM_LISTING_REQUEST_MODAL:
            return {
                ...state,
                requestModal: {
                    ...state.requestModal,
                    selectedProperty: {},
                },
            };
        case types.UPDATE_LISTING_REQUEST:
            return {
                ...state,
                requestModal: {
                    ...state.requestModal,
                    [action.field]: action.value,
                },
            };
        case types.ADD_REQUEST_LISTING_MODAL_ERROR:
            return {
                ...state,
                requestModal: {
                    ...state.requestModal,
                    errors: {
                        ...state.requestModal.errors,
                        [action.field]: action.error,
                    },
                },
            };
        case types.CLEAR_REQUEST_LISTING_MODAL_ERRORS:
            return {
                ...state,
                requestModal: {
                    ...state.requestModal,
                    errors: {},
                },
            };
        case types.STORE_NEW_PROPERTY_INFO_FOR_LISTING_REQUEST:
            return {
                ...state,
                newPropertyInfo: action.info,
            };
        case types.LISTING_REQUEST_INPUT_CHANGE:
            return {
                ...state,
                listing: {
                    ...state.listing,
                    [action.field]: action.value,
                },
            };
        case types.LISTING_REQUEST_INPUT_UPDATE:
            return {
                ...state,
                listing: {
                    ...state.listing,
                    ...action.updates,
                },
            };
        case types.CLEAR_LISTINGS:
            return {
                ...state,
                listings: {
                    ...state.listings,
                    property_listings: {},
                    all_km_listings: {},
                },
            };
        case types.CLEAR_MY_LISTINGS:
            return {
                ...state,
                listings: {
                    ...state.listings,
                    property_listings: [],
                    broker_active: [],
                },
            };
        case types.UPDATE_LISTING_ORDER:
            return {
                ...state,
                listings: {
                    ...state.listings,
                    [action.tab]: action.listings,
                },
            };

        case types.UPDATE_SELECTED_LISTING_ORDER:
            return {
                ...state,
                selectedListings: action.listings,
            };

        case types.LOAD_LISTING:
            return {
                ...state,
                listing: action.listing,
            };

        case types.LISTING_INPUT_CHANGE:
            return setWith(cloneDeep(state), `${action.field}`, action.value, Object);

        case types.TOGGLE_LISTING_REQUEST_PANEL:
            return {
                ...state,
                panelsExpanded: {
                    ...state.panelsExpanded,
                    [action.panel]: action.expanded,
                },
            };
        case types.CLEAR_LISTING_SEARCH_FILTERS:
            return {
                ...state,
                listingSearchFilters: {},
            };
        case types.SWITCH_REVIEW_LISTINGS_TAB:
            return Object.assign({}, state, {
                listingTab: action.tab,
            });
        case types.RECEIVE_REVIEW_LISTINGS:
            return Object.assign({}, state, {
                listings: {
                    ...state.listings,
                    [action.tab]: action.listings,
                },
            });
        // case types.RECEIVE_REVIEW_LISTINGS:
        //   return {
        //     ...state,
        //     listings: {
        //       ...state.listings,
        //       [action.tab]: action.listings,
        //     }
        //   }
        case types.SORT_REVIEW_LISTINGS:
            return Object.assign({}, state, {
                columnToSort: action.columnToSort,
                sortDirection: action.sortDirection,
            });
        case types.COUNT_REVIEW_LISTINGS:
            return Object.assign({}, state, {
                numListings: {
                    ...state.numListings,
                    [action.tab]: action.count,
                },
            });
        case types.FILTER_LISTINGS:
            return Object.assign({}, state, {
                filterQuery: action.filterQuery,
            });

        case types.ADD_LISTING_ARRAY_ELEMENT:
            return setWith(cloneDeep(state), `${action.path}[${action.index}]`, action.object, clone);
        case types.SET_LISTING_ARRAY:
            return setWith(cloneDeep(state), `${action.pathToArray}`, action.array, Object);
        case types.DELETE_ARRAY_ELEMENT:
            let elements = cloneDeep(get(state, action.path, []));
            elements.splice(action.index, 1);

            const setObj = setWith(cloneDeep(state), `${action.path}`, elements, clone);
            return setObj;

        case types.UPDATE_ARRAY_ELEMENT:
            return setWith(cloneDeep(state), `${action.path}[${action.index}].${action.field}`, action.value, clone);
        case types.SET_CHECKED_LISTINGS:
            return {
                ...state,
                selectedListings: action.listings,
            };
        case types.TOGGLE_LISTINGS_FOR_REPORT:
            // return setWith(clone(state), `selectedListings.${action.key}`, action.newValue, clone);
            const newState = cloneDeep(state);
            newState.selectedListings.set(action.key, action.newValue);
            return newState;

        case types.UPDATE_SELECTED_LISTINGS_GROUP:
            const newState2 = cloneDeep(state);
            newState2.selectedListingsGroup.set(action.key, action.newValue);
            return newState2;

        case types.CLICK_ACTIVE_CHECKBOX:
            const newState3 = cloneDeep(state);
            newState3.activeSelectedListings.set(action.key, action.newValue);
            return newState3;

        case types.UPDATE_INACTIVATE_DIALOG:
            return {
                ...state,
                inactivateListingDialog: {
                    ...state.inactivateListingDialog,
                    open: action.open,
                },
            };
        case types.UPDATE_DELETE_DIALOG:
            return {
                ...state,
                deleteListingDialog: {
                    ...state.deleteListingDialog,
                    open: action.open,
                },
            };
        case types.UPDATE_DUPLICATE_DIALOG:
            return {
                ...state,
                listingDuplicateDialog: {
                    ...state.listingDuplicateDialog,
                    open: action.open,
                },
            };
        case types.DUPLICATE_SUCCESS_DIALOG:
            return {
                ...state,
                duplicateSuccessDialog: {
                    ...state.duplicateSuccessDialog,
                    open: action.open,
                },
            };
        case types.UPDATE_EDIT_PROPERTY:
            return {
                ...state,
                editProperty: {
                    ...state.editProperty,
                    open: action.open,
                },
            };
        case types.TOGGLE_REPORT_MODAL_STATUS:
            return {
                ...state,
                reportTitleModal: {
                    ...state.reportTitleModal,
                    open: action.open,
                },
            };
        case types.TOGGLE_BROKER_INFO_REPORT:
            return {
                ...state,
                brokerInfoReport: {
                    ...state.brokerInfoReport,
                    include: action.include,
                },
            };
        case types.SET_REPORT_TITLE:
            return {
                ...state,
                reportTitleModal: {
                    ...state.reportTitleModal,
                    title: action.title,
                },
            };
        case types.SET_LISTING_GROUP:
            return {
                ...state,
                listings: {
                    ...state.listings,
                    listings_group: action.listings_group,
                },
            };
        case types.CLEAR_LISTING_GROUP:
            return {
                ...state,
                listings: {
                    ...state.listings,
                    listings_group: [],
                },
            };
        case types.UPDATE_GROUP_SELECTED:
            return {
                ...state,
                selectedListingsGroup: action.selectedListingsGroup,
            };
        case types.UPDATE_ACTIVE_SELECTED_LISTINGS:
            return {
                ...state,
                activeSelectedListings: action.activeSelectedListings,
            };

        default:
            return state;
    }
};
