import React from "react";
import { connect } from "react-redux";
import ReviewProperties from "./ReviewProperties";
import operations from "./ducks/operations";
import searchOperations from "../search/ducks/operations";
import searchActions from "../search/ducks/actions";
import searchConstants from "../search/constants";
import get from "lodash/get";
import ReactGA from "react-ga4";
// import mockData from './mockData';

class ReviewPropertiesContainer extends React.Component {
    componentDidMount() {
        if (get(this.props, "role") === "dataEntry") {
            console.log("getting needs review properties");
            this.props.getProperties();
        }
        this.props.handleOnSearchPage(true);
        ReactGA.send({ hitType: "pageview", page: "/properties", title: "All Properties" });
    }

    componentWillUnmount() {
        console.log("componentWillUnmount");
        this.props.handleOnSearchPage(false);
    }

    render() {
        switch (this.props.role) {
            case "broker": {
                return <ReviewProperties {...this.props} />;
            }
            default:
                return <ReviewProperties {...this.props} />;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        role: state.authReducer.userProfile.role,
        user: state.authReducer.userProfile.user,
        properties: state.propertyReducer.properties,
        // properties: mockData.properties,
        valid: state.authReducer.validFormValues,
        snackbarOpen: state.authReducer.snackbarOpen,
        propertyTab: state.propertyReducer.propertyTab,
        columnToSort: state.propertyReducer.columnToSort,
        sortDirection: state.propertyReducer.sortDirection,
        filterQuery: state.propertyReducer.filterQuery,
        selected: state.propertyReducer.selected,
        isLoading: state.utilsReducer.isLoading,
        numResults: state.propertyReducer.numResults,
        searchResults: state.searchReducer.searchResults,
        errors: state.utilsReducer.errors,
        panelsExpanded: state.propertyReducer.panelsExpanded,
        numComments: state.commentsReducer.comments.length,
        propertySearchFilters: state.propertyReducer.propertySearchFilters,
        searchCriteria: state.searchReducer.PROPERTY.searchCriteria,
        propertyTypeExpansionPanels: state.propertyReducer.propertyTypeExpansionPanels,
        marketFilterExpansionPanels: state.propertyReducer.marketFilterExpansionPanels,
        propertySearch: get(state.searchReducer, searchConstants.PROPERTY_SEARCH_TYPE, {}),
        dataExport: get(state.searchReducer, searchConstants.PROPERTY_SEARCH_TYPE + ".dataExport", {}),
        propertyRequestModal: state.propertyReducer.propertyRequestModal,
        elementsSaving: state.utilsReducer.elementsSaving,
        searchCleared: state.searchReducer.PROPERTY.searchCleared,
        searchRun: state.searchReducer.PROPERTY.searchRun,
        savedSearchModal: state.searchReducer.savedSearchModal,
        savedSearchData: state.searchReducer.PROPERTY.savedSearches,
        nameSearchModal: state.searchReducer.nameSearchModal,
        savedSearchName: state.searchReducer.savedSearchName,
        loadedSearchCrumbs: state.searchReducer.loadedSearchCrumbs.PROPERTY,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchTab: (event, value) => {
            dispatch(operations.switchTab(value));
        },
        handleSort: (columnName) => {
            console.log("sort");
            dispatch(operations.handleSort(columnName));
        },
        handleGridSort: (columnName, tab) => {
            dispatch(searchOperations.sortGridTableData(columnName, tab, "PROPERTY"));
        },
        handleSelect: (dealKey) => {
            dispatch(operations.selectDeal(dealKey));
        },
        handleFilter: (query) => {
            dispatch(operations.filterProperties(query));
        },
        getProperties: () => {
            dispatch(operations.getProperties());
        },
        getPropertiesByStatus: (tab) => {
            dispatch(operations.getPropertiesByStatus(tab));
        },
        togglePropertyRequestModal: () => {
            dispatch(operations.togglePropertyRequestModal());
        },
        searchProperty: (term) => {
            dispatch(operations.searchAutocomplete(term));
        },
        removeSelectedProperty: () => {
            dispatch(operations.removeSelectedPropertyFromRequestModal());
        },
        handleSubmitPropertyRequest: () => {
            dispatch(operations.requestProperty());
        },
        updatePropertyRequest: (field, value) => {
            dispatch(operations.updatePropertyRequest(field, value));
        },
        handleStatusRadioClick: (field, checked) => {
            dispatch(searchOperations.handleStatusRadioClick(field, checked, "PROPERTY"));
        },
        handleSizeRadioClick: (field, checked) => {
            dispatch(searchOperations.handleSizeRadioClick(field, checked, "PROPERTY"));
        },
        handleDealTypeRadioClick: (field, checked) => {
            dispatch(searchOperations.handleDealTypeRadioClick(field, checked, "PROPERTY"));
        },
        clearSearchFilters: () => {
            dispatch(searchOperations.clearSearchFilters(searchConstants.PROPERTY_SEARCH_TYPE));
        },
        updateFilter: (field, value) => {
            dispatch(searchOperations.updateFilter(field, value, "PROPERTY"));
        },
        handleSelectAllPropertyType: (field, value) => {
            dispatch(searchOperations.selectAllPropertyType(field, value, "PROPERTY"));
        },
        clearFilters: () => {
            dispatch(searchOperations.clearFilters("PROPERTY"));
        },
        togglePropertyTypeExpansionPanel: (panelName) => {
            dispatch(searchOperations.togglePropertyTypeExpansionPanel(panelName));
        },

        handleDataExport: () => {
            dispatch(searchOperations.handleDataExport(searchConstants.PROPERTY_SEARCH_TYPE));
        },
        clearExport: () => {
            dispatch(searchOperations.clearDataExport(searchConstants.PROPERTY_SEARCH_TYPE));
        },
        handleSearchSubmit: () => {
            dispatch(searchOperations.storeSearchRun(true, "PROPERTY"));
            dispatch(searchOperations.executeSearch(searchConstants.PROPERTY_SEARCH_TYPE));
        },
        loadMoreSearchResults: () => {
            dispatch(searchOperations.loadMoreResults(searchConstants.PROPERTY_SEARCH_TYPE));
        },
        toggleMarketFilterExpansionPanel: (panelName) => {
            dispatch(searchOperations.toggleMarketFilterExpansionPanel(panelName));
        },
        handleSelectMarket: (field, value) => {
            dispatch(searchOperations.updateMarketFilter(field, value, "PROPERTY"));
        },
        handleSelectMicromarket: (market, submarket, micromarket, value) => {
            dispatch(searchOperations.updateMicromarketFilter(market, submarket, micromarket, value, "PROPERTY"));
        },
        handleSelectSubmarket: (market, submarket, value) => {
            dispatch(searchOperations.updateSubmarketFilter(market, submarket, value, "PROPERTY"));
        },
        handleOnSearchPage: (onSearch) => {
            dispatch(searchActions.onSearchPage(onSearch));
        },
        handleDeleteCrumb: (criteria, value) => {
            dispatch(searchOperations.deleteCrumb(criteria, value, "PROPERTY"));
        },
        handleClearAllCrumbs: () => {
            dispatch(searchOperations.clearCrumbsState());
        },
        toggleSavedSearchesModal: (open) => {
            dispatch(searchOperations.toggleSavedSearchesModal(open));
        },
        handleDeleteSavedSearch: (searchType, savedSearchId) => {
            dispatch(searchOperations.deleteSavedSearch("PROPERTY", savedSearchId));
        },
        toggleNameSearchModal: (open) => {
            dispatch(searchOperations.toggleNameSearchModal(open));
        },
        setSearchName: (name) => {
            dispatch(searchOperations.setSearchName(name));
        },
        handleSaveSearch: (searchType) => {
            dispatch(searchOperations.handleSaveSearch(searchType.toUpperCase()));
        },
        runSavedSearch: (searchKey, searchType) => {
            dispatch(searchOperations.runSavedSearch(searchKey, searchType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPropertiesContainer);
