import React from "react";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import get from "lodash/get";
import Button from "@material-ui/core/Button";
import Delete from "@material-ui/icons/Delete";
import Today from "@material-ui/icons/Today";
import Add from "../../../../node_modules/@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import isNil from "lodash/isNil";
import includes from "lodash/includes";
import moment from "moment";

const Installments = (props) => {
    const handleChangeInstallmentAmountAndCalculateSplits = (index, value) => {
        props.handleUpdateArrayElement("installments", index, "amount", value);
        if (!isNil(props.voucher)) {
            props.handleCalculateSplits();
        }
    };

    const numInstallments = props.installments ? props.installments.length : null;
    if (numInstallments === 0 && props.disableInputs !== true && !props.voucher && props.displayFirstWhenEmpty) {
        props.handleAddArrayElement("installments");
        props.handleUpdateArrayElement("installments", 0, "dueDate", moment().format("YYYY-MM-DD"));
    }

    const voucherViewContext = !isNil(props.voucher);

    //determine editable installments
    let editableInstallments = [];
    const currentInstallment = get(props.voucher, "installmentNum", null);
    const totalInstallments = get(props, "installments.length", 0);
    let upperBound = totalInstallments;
    let lowerBound = 1;
    if (voucherViewContext || (!voucherViewContext && !props.disableInputs)) {
        if (props.onlyEditCurrentInstallment) {
            lowerBound = currentInstallment;
            upperBound = currentInstallment;
        } else if (props.onlyEditCurrentAndFuture) {
            lowerBound = currentInstallment;
        }
        let i = lowerBound;
        while (i <= upperBound) {
            editableInstallments.push(i);
            i++;
        }
    }

    const {
        classes,
        disableInputs,
        errors,
        installments,
        handleAddArrayElement,
        handleDeleteArrayElement,
        handleUpdateArrayElement,
        onlyEditCurrentInstallment,
        onlyEditCurrentAndFuture,
        preventAddSubtract,
        consultingDeal,
    } = props;

    return (
        <div>
            {!consultingDeal && (
                <Grid container spacing={8}>
                    <Grid item>
                        <h3>Installment Schedule</h3>
                    </Grid>
                </Grid>
            )}
            {installments &&
                installments.map((installment, i) => {
                    const totalInstallments = installments.length;
                    const showDeleteButton =
                        i !== 0 &&
                        (preventAddSubtract !== true ||
                            (currentInstallment && installment.installmentNumberDisplay > currentInstallment) ||
                            isNil(installment.installmentKey));
                    return (
                        <div>
                            <Grid container spacing={8}>
                                <Grid item xs={5}>
                                    <BSInput
                                        id="amount"
                                        label="Total Amount of This Payment*"
                                        errorText="Required (ex. 980.01)"
                                        helperText="Required (ex. 981.01)"
                                        value={get(installment, "amount")}
                                        error={get(errors, `${i}.amount`, null)}
                                        onChange={(e) =>
                                            handleChangeInstallmentAmountAndCalculateSplits(i, e.target.value)
                                        }
                                        placeholder="Enter Amount"
                                        startAdornment="$"
                                        disabled={
                                            (disableInputs && !voucherViewContext) ||
                                            ((onlyEditCurrentInstallment || onlyEditCurrentAndFuture) &&
                                                !includes(editableInstallments, installment.installmentNumberDisplay) &&
                                                !isNil(installment.installmentKey))
                                        }
                                        numeric={true}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="dueDate"
                                        label="Due Date"
                                        value={get(installment, "dueDate")}
                                        error={get(errors, `${i}.dueDate`, null)}
                                        onChange={(e) =>
                                            handleUpdateArrayElement("installments", i, "dueDate", e.target.value)
                                        }
                                        helperText="Required"
                                        errorText="Required (mm/dd/yyyy)"
                                        disabled={
                                            (disableInputs && !voucherViewContext) ||
                                            ((onlyEditCurrentInstallment || onlyEditCurrentAndFuture) &&
                                                !includes(editableInstallments, installment.installmentNumberDisplay) &&
                                                !isNil(installment.installmentKey))
                                        }
                                        startAdornment={<Today />}
                                        type="date"
                                    />
                                </Grid>
                                <Grid item xs={showDeleteButton ? 2 : 3}>
                                    <BSInput
                                        id="installmentNumberDisplay"
                                        label="Installment"
                                        value={i + 1 + " of " + totalInstallments}
                                        error={get(errors, `${i}.installmentNumber`, null)}
                                        placeholder="Enter number of installments"
                                        disabled={true}
                                    />
                                </Grid>
                                {showDeleteButton && (
                                    <Grid item xs={1} style={{ display: "flex" }}>
                                        <Button
                                            onClick={() => handleDeleteArrayElement("installments", i)}
                                            className={classes.iconOnly}
                                        >
                                            <Delete />
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    );
                })}
            {((voucherViewContext && !consultingDeal) || (!voucherViewContext && preventAddSubtract !== true)) && (
                <Button className={classes.dashed} onClick={() => handleAddArrayElement("installments")}>
                    <Add className={classes.icon} />
                    Add Installment
                </Button>
            )}
        </div>
    );
};

const styles = (theme) => ({
    dashed: {
        border: "2px dashed #2C99D2",
        color: "#2C99D2",
        margin: "10px 0 40px",
        padding: "11px 16px",
        width: "100%",
        justifyContent: "start",
        "&hover": {
            backgroundColor: "rgba(44, 153, 210, 0.08)",
        },
    },
    iconOnly: {
        marginLeft: 0,
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
});

export default withStyles(styles)(Installments);
