import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import get from "lodash/get";
import schema from "./DocumentAdminLandingTable";
import { Search, Sorty } from "../../../common/js/icons";
import { Link } from "react-router-dom";
import operations from "./ducks/operations";
import BSInput from "../../../common/js/components/BSInput";
import React, { Component } from "react";

const styles = () => ({
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    expansionSubheader: {
        color: "#9E9E9E",
        fontSize: "13px",
        fontWeight: 600,
        marginLeft: "8px",
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    toplessInput: {
        marginTop: 0,
    },
    link: {
        textDecoration: "none",
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    loadingSpinner: {
        position: "absolute",
        left: "50%",
        top: "45%",
        transform: "translate(-50%, -50%)",
    },
});

class DocumentAdminLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "documents",
            viewHeaders: "documents",
            documentTypes: [],
            dataLoaded: false,
            sortMap: new Map(),
            searchInput: "",
            parentTypes: [],
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const docTypes = await operations.getAllDocumentTypes();
        this.setState({
            documentTypes: docTypes,
        });
        this.handleSort("name");
        this.setState({
            dataLoaded: true,
        });
    };

    switchTab = async (event, value) => {
        this.setState({
            viewHeaders: value,
            tab: value,
        });
        if (value === "parentTypes" && Object.keys(this.state.parentTypes).length === 0) {
            this.setState({
                dataLoaded: false,
            });
            this.setState({
                parentTypes: await operations.getAllParentEntityTypes(),
            });
        }
        this.setState({
            dataLoaded: true,
        });
    };

    handleSort = (header) => {
        let access = "";
        if (this.state.tab === "documents") {
            access = "documentTypes";
        } else {
            access = "parentTypes";
        }
        let newData = this.state[access].sort(function (a, b) {
            if (header === "parentEntityType") {
                return ("" + a.parentEntityType.name).localeCompare(b.parentEntityType.name);
            }
            return ("" + a[header]).localeCompare(b[header]);
        });
        if (this.state.sortMap.get(header)) {
            this.state.sortMap.set(header, false);
            newData = newData.reverse();
        } else {
            this.state.sortMap.set(header, true);
        }
        this.setState({
            documentTypes: newData,
        });
    };

    onKeyPress = async (event) => {
        if (event.key === "Enter") {
            this.setState({
                dataLoaded: false,
            });
            if (this.state.tab === "documents") {
                this.setState({
                    documentTypes: await operations.getAllDocumentTypes(),
                });
                let condition = new RegExp(this.state.searchInput.toLowerCase());
                let newData = this.state.documentTypes.filter(function (el) {
                    return condition.test(el.name.toLowerCase());
                });
                this.setState({
                    documentTypes: newData,
                });
            }
            this.setState({
                dataLoaded: true,
            });
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.pageToolbar}
                    spacing={16}
                >
                    <Grid item>
                        <h2 className="page-title">Document Admin</h2>
                    </Grid>
                    {this.state.tab === "parentTypes" ? null : (
                        <Button disabled={false} variant="contained" color="primary" href="/document-type/create">
                            + Add Document
                        </Button>
                    )}
                </Grid>
                <Grid container alignItems="center" spacing={16}>
                    <Grid item xs>
                        <div className="simPaper">
                            <Grid container justify="space-between" alignItems="center" spacing={0}>
                                <Grid item>
                                    <Tabs classes={classes} value={this.state.tab} onChange={this.switchTab}>
                                        <Tab classes={classes.tabOne} value="documents" label="Documents" />
                                        <Tab classes={classes} value="parentTypes" label="Parent Types" />
                                    </Tabs>
                                </Grid>
                                <Grid item justify="flex-end">
                                    {this.state.tab === "parentTypes" ? null : (
                                        <BSInput
                                            value={this.state.searchInput}
                                            placeholder="Search Documents"
                                            className={classes.toplessInput}
                                            errorPlaceholder="hideIt"
                                            startAdornment={<Search />}
                                            onChange={(event) => {
                                                this.setState({ searchInput: event.target.value });
                                            }}
                                            onKeyPress={(e) => this.onKeyPress(e)}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs style={{ minHeight: "60vh" }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {schema.viewHeaders[this.state.viewHeaders].map((header, i) => (
                                                        <TableCell classes={classes} key={`thc-${i}`}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                                onClick={() => this.handleSort(header.prop)}
                                                            >
                                                                <span className={classes.sortable}>{header.name}</span>

                                                                {header.name && (
                                                                    <div>
                                                                        <Sorty />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            {this.state.dataLoaded ? (
                                                <TableBody>
                                                    {this.state.tab === "documents"
                                                        ? Object.keys(this.state.documentTypes).map(
                                                              (documentType, index) => {
                                                                  return row(
                                                                      this.state.documentTypes[documentType],
                                                                      index,
                                                                      classes,
                                                                      this.state.viewHeaders,
                                                                      "/document-type/document/" +
                                                                          this.state.documentTypes[documentType]
                                                                              .documentTypeKey
                                                                  );
                                                              }
                                                          )
                                                        : this.state.parentTypes.map((parentType, index) => {
                                                              return row(
                                                                  parentType,
                                                                  index,
                                                                  classes,
                                                                  this.state.viewHeaders,
                                                                  "/parent-type/" + parentType.parentEntityTypeKey
                                                              );
                                                          })}
                                                </TableBody>
                                            ) : (
                                                <div className={classes.loadingSpinner} style={{ marginTop: "50px" }}>
                                                    <CircularProgress color="primary" />
                                                </div>
                                            )}
                                        </Table>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const row = (element, index, classes, viewHeader, link) => {
    return (
        <TableRow key={`tr-${index}`} selectable="false">
            {schema.viewHeaders[viewHeader].map((header, i) => (
                <TableCell classes={{ root: classes.body }} key={`trc-${i}`}>
                    {header.name === "NAME" ? (
                        <Link to={link} className={classes.link}>
                            <span className={classes.listingIdLink}>{get(element, header.prop, "")}</span>
                        </Link>
                    ) : header.prop === "parentEntityType" ? (
                        get(element, header.prop, "").name
                    ) : (
                        get(element, header.prop, "")
                    )}
                </TableCell>
            ))}
        </TableRow>
    );
};

export default withStyles(styles)(DocumentAdminLanding);
