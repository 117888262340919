import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress } from "@material-ui/core";
import Scroll from "react-scroll";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AuditSearchRow from "./AuditSearchRow";

const AuditSearchResults = (props) => {
    const {
        classes,
        searchCleared,
        handleDragEnd,
        comps,
        viewDocument,
        loadingSearch,
        searchRun,
        toggleCompAuditFlag,
    } = props;

    let Element = Scroll.Element;
    const getListStyle = (isDraggingOver) => ({
        padding: "",
    });

    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    {!searchCleared && Object.keys(comps).length !== 0 ? (
                        loadingSearch ? null : (
                            <Element style={{ height: "600px", overflow: "scroll" }}>
                                <DragDropContext onDragEnd={(result) => handleDragEnd(result, comps)}>
                                    <Droppable droppableId="droppable" style={{ maxWidth: "100%" }}>
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {comps.map((comp, i) => {
                                                        return (
                                                            <AuditSearchRow
                                                                toggleCompAuditFlag={toggleCompAuditFlag}
                                                                viewDocument={viewDocument}
                                                                comp={comp}
                                                                i={i}
                                                            />
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            );
                                        }}
                                    </Droppable>
                                </DragDropContext>
                            </Element>
                        )
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {loadingSearch ? (
                        <div className={classes.alternateContent}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : Object.keys(comps).length === 0 ? (
                        searchRun && !searchCleared ? (
                            <div className={classes.alternateContent}>Nothing to Show</div>
                        ) : (
                            <div className={classes.alternateContent}>Please apply search criteria</div>
                        )
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const styles = (theme) => ({
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
});

export default withStyles(styles)(AuditSearchResults);
