import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox, Chip, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { get, isEmpty } from "lodash";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import KMCounter from "../../../common/js/components/KMCounter";
import { propertyManagementTypes } from "../types/propertyManagementTypes";
import { assetTypes } from "../types/assetTypes";
import { clientTypes } from "../types/clientTypes";
import { marketRegions } from "../types/marketRegions";
import { engineeringTeamTypes } from "../types/engineeringTeamTypes";
import { Link } from "react-router-dom";
import { isEmptyStr } from "../utils/operations";

const styles = (theme) => ({
    switchTitle: {
        fontSize: 14,
        fontWeight: 600,
    },
    theSwitch: {
        marginRight: 0,
    },
    toplessInput: {
        marginTop: 0,
    },
    radioRow: {
        paddingTop: "0px",
        paddingBottom: "0px",
    },
    mDash: {
        marginBottom: "12px",
        display: "inline-block",
    },
    taxIdContainer: {
        display: "flex",
        position: "relative",
    },
    labelSpacing: {
        marginTop: "8px",
    },
    typeChipContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 0.5,
    },
    portfolioPropertyContainer: {
        overflow: "auto",
        maxHeight: "775px",
    },
    portfolioPropertyLinkContainer: {
        paddingBottom: "15px",
        paddingTop: "15px",
        borderBottom: "1px solid #E0E0E0",
    },
    portfolioPropertyLink: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "12px",
        fontWeight: "bold",
        textDecoration: "none",
    },
    portfolioPropertyAddress: {
        color: "#E0E0E0",
        fontSize: "12px",
        fontWeight: "normal",
    },
});
class MasterPropertyForm extends React.Component {
    constructor(props) {
        super(props);

        let selectedTaxType = null;
        if (!isEmpty(get(props.data, "w9Ssn"))) {
            selectedTaxType = "SSN";
        } else if (!isEmpty(get(props.data, "w9TaxId"))) {
            selectedTaxType = "W9";
        }
        this.state = {
            selectedTaxIdType: selectedTaxType,
        };
    }

    handleTaxIdSelection = (e) => {
        if (e.target.value === this.state.selectedTaxIdType) {
            this.setState({ selectedTaxIdType: null });
            this.props.handleInput("w9Ssn", "");
            this.props.handleInput("w9TaxId", "");
        } else if (e.target.value === "W9") {
            this.setState({ selectedTaxIdType: "W9" });
            this.props.handleInput("w9Ssn", "");
        } else if (e.target.value === "SSN") {
            this.setState({ selectedTaxIdType: "SSN" });
            this.props.handleInput("w9TaxId", "");
        }
    };

    removeChip = (key, chipArr, type) => {
        let filteredChips = chipArr
            .filter((value) => {
                return value !== type;
            })
            .toString();
        let updatedChips = isEmptyStr(filteredChips) ? null : filteredChips;
        this.props.handleInput(key, updatedChips);
    };

    render() {
        const { classes, data, disableInputs, errors, handleInput, hasPortfolioProperties, isSnapshot } = this.props;

        return (
            <React.Fragment>
                {/* Spacing is 24 because it spaces out the 2 columns better */}
                <Grid container justify="space-between" spacing={24} style={{ maxHeight: "810px" }}>
                    <Grid item xs={hasPortfolioProperties ? 5 : 6}>
                        <Grid container spacing={8}>
                            <Grid item xs={3} style={{ paddingTop: "12px" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!data.ownersAssociationFlg}
                                            onChange={(e, checked) => handleInput(e.target.value, checked)}
                                            value="ownersAssociationFlg"
                                            disabled={disableInputs}
                                            color="primary"
                                        />
                                    }
                                    label="Owners Association"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <KMCounter
                                    id="landAcreage"
                                    label="Land Acreage"
                                    placeholder="Enter land acreage"
                                    value={get(data, "landAcreage", "")}
                                    error={get(errors, `landAcreage`, null)}
                                    errorText={"Field must be a positive number"}
                                    onChange={handleInput}
                                    min={0}
                                    disabled={!data.ownersAssociationFlg || isSnapshot}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <KMCounter
                                    id="oaMemberCount"
                                    label="Owners Association Member Count"
                                    placeholder="Enter OA member count"
                                    value={get(data, "oaMemberCount", "")}
                                    error={get(errors, `oaMemberCount`, null)}
                                    errorText={"Field must be a positive integer"}
                                    onChange={handleInput}
                                    min={0}
                                    disabled={!data.ownersAssociationFlg || isSnapshot}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="accountingSoftwareId"
                                    label="Accounting Software System Id"
                                    placeholder="Enter ID"
                                    value={get(data, "accountingSoftwareId", "")}
                                    error={get(errors, `accountingSoftwareId`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="portfolioNumber"
                                    label="Portfolio Number"
                                    placeholder="Enter portfolio number"
                                    value={get(data, "portfolioNumber", "")}
                                    error={get(errors, `portfolioNumber`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <BSSelect
                                    id="marketRegion"
                                    label="Market Region"
                                    placeholder="Select market region"
                                    value={get(data, "marketRegion", "")}
                                    error={get(errors, `marketRegion`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    options={[
                                        {
                                            value: "",
                                            label: "Select",
                                            disabled: true,
                                        },
                                        ...marketRegions,
                                    ]}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="buildingName"
                                    label="Building Name"
                                    placeholder="Enter building name"
                                    value={get(data, "buildingName", "")}
                                    error={get(errors, `buildingName`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <BSSelect
                                    id="engineeringTeam"
                                    label="Engineering Team"
                                    placeholder="Select engineering team"
                                    value={get(data, "engineeringTeam", "")}
                                    error={get(errors, `engineeringTeam`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    options={[
                                        {
                                            value: "",
                                            label: "Select",
                                            disabled: true,
                                        },
                                        ...engineeringTeamTypes,
                                    ]}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KMCounter
                                    id="grossSqFt"
                                    label="Square Feet"
                                    placeholder="Enter square feet"
                                    value={get(data, "grossSqFt", "")}
                                    error={get(errors, `grossSqFt`, null)}
                                    errorText={"Field must be a positive integer"}
                                    onChange={handleInput}
                                    min={0}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <KMCounter
                                    id="numberOfBuildings"
                                    label="Number of Buildings"
                                    placeholder="Enter number of buildings"
                                    value={get(data, "numberOfBuildings", "")}
                                    error={get(errors, `numberOfBuildings`, null)}
                                    errorText={"Field must be a positive integer"}
                                    onChange={handleInput}
                                    min={0}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KMCounter
                                    id="numberOfTenants"
                                    label="Number of Units"
                                    placeholder="Enter number of units"
                                    value={get(data, "numberOfTenants", "")}
                                    error={get(errors, `numberOfTenants`, null)}
                                    errorText={"Field must be a positive integer"}
                                    onChange={handleInput}
                                    min={0}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <InputLabel id="select-multiple-client-type">Client Type</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        labeId="select-multiple-client-type"
                                        id="clientType"
                                        multiple
                                        value={
                                            get(data, "clientType", null) === null
                                                ? []
                                                : get(data, "clientType", "").split(",")
                                        }
                                        error={get(errors, `clientType`, null)}
                                        onChange={(e) => {
                                            handleInput(
                                                "clientType",
                                                isEmptyStr(e.target.value.toString()) ? null : e.target.value.toString()
                                            );
                                        }}
                                        className={classes.labelSpacing}
                                        disabled={disableInputs}
                                        disableUnderline
                                        style={{ height: "37px" }}
                                        renderValue={(selected) => {
                                            return (
                                                <div className={classes.typeChipContainer}>
                                                    {selected.map((value) => {
                                                        return (
                                                            <Chip
                                                                key={value}
                                                                label={value}
                                                                onDelete={() => {
                                                                    this.removeChip("clientType", selected, value);
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            );
                                        }}
                                    >
                                        {clientTypes.map((clientType) => (
                                            <MenuItem key={clientType.label} value={clientType.value}>
                                                {clientType.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}></Grid>
                    </Grid>

                    <Grid item xs={hasPortfolioProperties ? 5 : 6}>
                        <Grid container spacing={8}>
                            <Grid item xs={3} style={{ paddingTop: "12px" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!data.institutionalClientFlg}
                                            onChange={(e, checked) => handleInput(e.target.value, checked)}
                                            value="institutionalClientFlg"
                                            disabled={disableInputs}
                                            color="primary"
                                        />
                                    }
                                    label="Institutional Client"
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <BSInput
                                    id="trueClientName"
                                    label="True Client Name"
                                    placeholder='Enter "true" client name'
                                    value={get(data, "trueClientName", "")}
                                    error={get(errors, `trueClientName`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="propertyOwner.propertyOwnerName"
                                    label="Property Owner's Legal Entity / W-9 Entity"
                                    placeholder="Enter legal entity / W-9 entity name"
                                    value={
                                        isSnapshot
                                            ? get(data, "propertyOwnerLegalEntity")
                                            : get(data, "propertyOwner.propertyOwnerName")
                                    }
                                    error={get(errors, `propertyOwner.propertyOwnerName`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="w9TaxClassification"
                                    label="W-9 Federal Tax Classification"
                                    placeholder="Enter W-9 federal tax classification"
                                    value={get(data, "w9TaxClassification", "")}
                                    error={get(errors, `w9TaxClassification`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center" spacing={8}>
                            <Grid item xs={6} className={classes.taxIdContainer}>
                                <BSInput
                                    id="w9TaxId"
                                    label="W-9 Tax ID"
                                    placeholder="Enter W-9 Tax ID"
                                    value={get(data, "w9TaxId", null)}
                                    error={get(errors, `w9TaxId`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={this.state.selectedTaxIdType !== "W9" || disableInputs}
                                    checkbox={true}
                                    checkboxVal={"W9"}
                                    disableCheckbox={disableInputs}
                                    onChangeCheckbox={this.handleTaxIdSelection}
                                    checked={this.state.selectedTaxIdType === "W9"}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.taxIdContainer}>
                                <BSInput
                                    id="w9Ssn"
                                    label="SSN Tax ID"
                                    placeholder="Enter SSN Tax ID"
                                    value={get(data, "w9Ssn", null)}
                                    error={get(errors, `w9Ssn`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={this.state.selectedTaxIdType !== "SSN" || disableInputs}
                                    checkbox={true}
                                    checkboxVal={"SSN"}
                                    disableCheckbox={disableInputs}
                                    onChangeCheckbox={this.handleTaxIdSelection}
                                    checked={this.state.selectedTaxIdType === "SSN"}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <BSSelect
                                    id="managementType"
                                    label="Scope of Work"
                                    placeholder="Select scope of work"
                                    value={get(data, "managementType", "")}
                                    error={get(errors, `managementType`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    options={[
                                        {
                                            value: "",
                                            label: "Select",
                                            disabled: true,
                                        },
                                        ...propertyManagementTypes,
                                    ]}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <InputLabel id="select-multiple-asset-type">Asset Type</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        labeId="select-multiple-asset-type"
                                        id="assetType"
                                        value={
                                            get(data, "assetType", null) === null
                                                ? []
                                                : get(data, "assetType", "").split(",")
                                        }
                                        error={get(errors, `assetType`, null)}
                                        onChange={(e) => {
                                            handleInput(
                                                "assetType",
                                                isEmptyStr(e.target.value.toString()) ? null : e.target.value.toString()
                                            );
                                        }}
                                        className={classes.labelSpacing}
                                        disabled={disableInputs}
                                        disableUnderline
                                        style={{ height: "37px" }}
                                        renderValue={(selected) => {
                                            return (
                                                <div className={classes.typeChipContainer}>
                                                    <Chip
                                                        key={selected}
                                                        label={selected}
                                                        onDelete={() => {
                                                            this.removeChip("assetType", selected, selected.toString());
                                                        }}
                                                    />
                                                </div>
                                            );
                                        }}
                                    >
                                        {assetTypes.map((assetType) => (
                                            <MenuItem key={assetType.label} value={assetType.value}>
                                                {assetType.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    {hasPortfolioProperties ? (
                        <Grid item xs={2} className={classes.portfolioPropertyContainer}>
                            {get(data, "assetPortfolioProperties", []).map((property) => (
                                <Grid container xs={12} className={classes.portfolioPropertyLinkContainer}>
                                    <Link
                                        to={`/propdetail/${property.propertyKey}`}
                                        className={classes.portfolioPropertyLink}
                                        target="_blank"
                                    >
                                        {property.primaryAddress.address1}
                                        <br />
                                        <span className={classes.portfolioPropertyAddress}>
                                            {property.primaryAddress.city.city},{" "}
                                            {property.primaryAddress.city.state.stateCode}{" "}
                                            {property.primaryAddress.zipCode.zipCode}
                                        </span>
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    ) : null}
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(MasterPropertyForm);
