import React from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactTooltip from "react-tooltip";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import get from "lodash/get";
import includes from "lodash/includes";
import "./Comp.css";
import { NO_CONTENT_PLACEHOLDER } from "./constants";
import schema, { getButtonActionPropertiesForRoleAndStatus } from "./reviewCompsTables";
import { getCompInfo, getLeaseRate, getSalePrice } from "./utils";
import { noPhotoUrl } from "../utils/constants";
import utils, { getFromAttributeValues, navigateToLandvision } from "../utils/operations";
import { Landvision, Sorty } from "../../../common/js/icons";

const styles = (theme) => ({
    lightButton: {
        fontWeight: "400",
        minWidth: "0px",
        margin: "0px",
        marginRight: "5px",
        padding: "5px 10px",
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    lb: {
        textDecoration: "none",
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: "600",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    tooltip: {
        "&>a:hover": {
            cursor: "default",
        },
    },
    gridWrapper: {
        fontFamily: "IBM Plex Sans, sans-serif",
        overflowX: "auto",
    },
    gridHeaderRow: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid rgb(212, 212, 212)",
        color: "#9E9E9E",
        fontSize: "12px",
        fontWeight: "bold",
        padding: "1.5em 0",
        overflow: "hidden",
        scrollbarGutter: "stable",
    },
    gridBodyRow: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid rgb(212, 212, 212)",
        fontSize: "13px",
        height: "fit-content",
        padding: "0.75em 0",
    },
    gridBodyWrapper: {
        display: "block",
        maxHeight: "50vh",
        minHeight: "425px",
        overflowY: "auto",
        scrollbarGutter: "stable",
    },
});

const CompsWithoutMap = (props) => {
    const {
        compTab,
        role,
        classes,
        handleSort,
        searchCleared,
        searchRun,
        isLoading,
        loadingSearch,
        selectAll,
        selectAllGroup,
        selectAllBrokerComps,
        handleSelectAll,
        handleSelectAllGroup,
        handleSelectAllBrokerComps,
        handleDragEnd,
        selectedComps,
        selectedCompsGroup,
        brokerComps,
        handleCheckboxClick,
        filterQuery,
        isBrokerOnDeal,
    } = props;

    let comps = get(props.comps, compTab) || [];
    const hasComps = Object.keys(comps).length > 0;
    const brokerCompsTab = compTab === "broker_comps";
    const headers = brokerCompsTab ? schema.viewHeaders["broker_comps"] : schema.viewHeaders["all_km_comps"];
    const loadRows = brokerCompsTab ? !isLoading && hasComps : !loadingSearch && !searchCleared && hasComps;

    if (compTab === "broker_comps" && filterQuery) {
        comps = comps.filter((comp) => schema.filterByQuery(comp, schema.columnsToQueryFilter, filterQuery));
    }

    const getListStyle = (isDraggingOver) => ({
        padding: "",
    });

    return (
        <div>
            <Grid container spacing={0} className={classes.gridWrapper}>
                <Grid item xs={12} style={{ minWidth: "1450px" }}>
                    {/* Grid Header */}
                    <Grid container spacing={12} className={classes.gridHeaderRow}>
                        {headers.map((header, i) => {
                            return (
                                <Grid
                                    item
                                    xs={header.name === "Links" ? 3 : header.name === "Address" ? 2 : 1}
                                    key={`gh-c${i}`}
                                    numeric={header.numeric}
                                    onClick={() => handleSort(header.name, compTab)}
                                    style={{ paddingRight: "7px" }}
                                >
                                    {header.name === "Property" ? (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {compTab === "comps_group" ? (
                                                <Checkbox
                                                    className={
                                                        selectAllGroup
                                                            ? "compCheckboxAllSelectedMap"
                                                            : "compCheckboxAllMap"
                                                    }
                                                    checked={selectAllGroup}
                                                    value={selectAllGroup}
                                                    onChange={() => handleSelectAllGroup()}
                                                    color="primary"
                                                />
                                            ) : compTab === "broker_comps" ? (
                                                <Checkbox
                                                    className={
                                                        selectAllBrokerComps
                                                            ? "compCheckboxAllSelectedMap"
                                                            : "compCheckboxAllMap"
                                                    }
                                                    checked={selectAllBrokerComps}
                                                    value={selectAllBrokerComps}
                                                    onChange={() => handleSelectAllBrokerComps()}
                                                    color="primary"
                                                />
                                            ) : (
                                                <Checkbox
                                                    className={
                                                        selectAll ? "compCheckboxAllSelectedMap" : "compCheckboxAllMap"
                                                    }
                                                    checked={selectAll}
                                                    value={selectAll}
                                                    onChange={() => handleSelectAll()}
                                                    color="primary"
                                                />
                                            )}
                                            <span style={{ paddingLeft: "7px" }}>{header.name.toUpperCase()}</span>
                                        </div>
                                    ) : header.name === "Links" ? (
                                        <div>{header.name.toUpperCase()}</div>
                                    ) : (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <span style={{ paddingRight: "7px" }}>{header.name.toUpperCase()}</span>
                                            <Sorty />
                                        </div>
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>

                    {/* Grid Body */}
                    <DragDropContext onDragEnd={(result) => handleDragEnd(result, comps, compTab)}>
                        <Droppable droppableId="droppable" style={{ maxWidth: "100%" }}>
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {
                                        <Grid container spacing={12} className={classes.gridBodyWrapper}>
                                            {loadRows
                                                ? comps.map((comp, i) =>
                                                      row(
                                                          comp,
                                                          i,
                                                          headers,
                                                          compTab,
                                                          role,
                                                          classes,
                                                          selectedComps,
                                                          selectedCompsGroup,
                                                          brokerComps,
                                                          handleCheckboxClick,
                                                          isBrokerOnDeal
                                                      )
                                                  )
                                                : null}

                                            {/* Circular progress wheel, no content notifications, etc. */}
                                            {alternateContent(
                                                compTab,
                                                isLoading,
                                                loadingSearch,
                                                hasComps,
                                                searchRun,
                                                classes
                                            )}
                                        </Grid>
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Grid>
            </Grid>
        </div>
    );
};

const row = (
    comp,
    i,
    headers,
    compTab,
    role,
    classes,
    selectedComps,
    selectedCompsGroup,
    brokerComps,
    handleCheckboxClick,
    isBrokerOnDeal
) => {
    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging ? "#C5C5C5" : "",
        userSelect: "none",
        width: "100%",
        ...draggableStyle,
    });

    const buttonProperties = getButtonActionPropertiesForRoleAndStatus(role, comp.calculatedValueB);
    const dealKey = getFromAttributeValues(comp, ["dealkey", "dealKey"]);

    return (
        <Draggable draggableId={dealKey} index={i} style={{ width: "100%", height: "fit-content" }} key={`gb-r${i}`}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <Grid container spacing={12} className={classes.gridBodyRow}>
                        {headers.map((header, j) => {
                            const compPhoto = getFromAttributeValues(
                                comp,
                                ["listing_photo", "property_photo", "listingPhoto", "propertyPhoto"],
                                noPhotoUrl
                            );

                            return (
                                <Grid
                                    item
                                    key={`gb-r${i}-c${j}`}
                                    xs={header.name === "Links" ? 3 : header.name === "Address" ? 2 : 1}
                                    style={{ paddingRight: "7px" }}
                                >
                                    {
                                        /* --- Property --- */
                                        header.name === "Property" ? (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                {compTab === "comps_group" ? (
                                                    <Checkbox
                                                        className={
                                                            selectedCompsGroup.get(dealKey) === true
                                                                ? "compCheckboxAllSelectedMap"
                                                                : "compCheckboxAllMap"
                                                        }
                                                        checked={selectedCompsGroup.get(dealKey) === true}
                                                        value={selectedCompsGroup.get(dealKey) === true}
                                                        onChange={() => handleCheckboxClick(dealKey, compTab)}
                                                        color="primary"
                                                    />
                                                ) : compTab === "broker_comps" ? (
                                                    <Checkbox
                                                        className={
                                                            brokerComps.get(dealKey) === true
                                                                ? "compCheckboxAllSelectedMap"
                                                                : "compCheckboxAllMap"
                                                        }
                                                        checked={brokerComps.get(dealKey) === true}
                                                        value={brokerComps.get(dealKey) === true}
                                                        onChange={() => handleCheckboxClick(dealKey, compTab)}
                                                        color="primary"
                                                    />
                                                ) : (
                                                    <Checkbox
                                                        className={
                                                            selectedComps.get(dealKey) === true
                                                                ? "compCheckboxAllSelectedMap"
                                                                : "compCheckboxAllMap"
                                                        }
                                                        checked={selectedComps.get(dealKey) === true}
                                                        value={selectedComps.get(dealKey) === true}
                                                        onChange={() => handleCheckboxClick(dealKey, compTab)}
                                                        color="primary"
                                                    />
                                                )}
                                                <div
                                                    className="propertySmallImageContainer"
                                                    style={{
                                                        backgroundImage: `url(${compPhoto})`,
                                                        marginLeft: "7px",
                                                        width: "85px",
                                                        minWidth: "85px",
                                                        height: "85px",
                                                    }}
                                                ></div>
                                            </div>
                                        ) : /* --- Address --- */
                                        header.name === "Address" ? (
                                            <div>
                                                {!getFromAttributeValues(
                                                    comp,
                                                    ["confidentialFlg", "confidential_flg"],
                                                    false
                                                ) ||
                                                includes(["dataEntry", "dataAdmin"], role) ||
                                                isBrokerOnDeal(comp) ? (
                                                    <Link
                                                        to={`/comp/${dealKey}`}
                                                        target="_blank"
                                                        className={classes.lb}
                                                    >
                                                        <span className={classes.listingIdLink}>
                                                            {getFromAttributeValues(
                                                                comp,
                                                                ["listing_name", "listingName"],
                                                                ""
                                                            ).trim()}
                                                        </span>
                                                    </Link>
                                                ) : (
                                                    <div style={{ color: "red", fontWeight: "bold" }}>Confidential</div>
                                                )}
                                                <div>{`${get(comp, "address1", {})}`}</div>
                                                <div>{`${get(comp, "city", {})}, ${getFromAttributeValues(
                                                    comp,
                                                    ["state_code", "state"],
                                                    ""
                                                )}, ${getFromAttributeValues(
                                                    comp,
                                                    ["zip_postal_code", "zip"],
                                                    ""
                                                )}`}</div>
                                                <div>{getCompInfo(comp)}</div>
                                            </div>
                                        ) : /* --- Rate / Price --- */
                                        header.name === "Rate/Price" ? (
                                            getFromAttributeValues(
                                                comp,
                                                ["confidentialFlg", "confidential_flg"],
                                                false
                                            ) === true ? (
                                                NO_CONTENT_PLACEHOLDER
                                            ) : (
                                                <div numeric={header.numeric}>
                                                    {
                                                        /* - Lease - */
                                                        utils.parseTransactionType(
                                                            getFromAttributeValues(
                                                                comp,
                                                                ["transaction_type", "transactionType"],
                                                                null
                                                            )
                                                        ) === "Lease"
                                                            ? getLeaseRate(comp)
                                                            : /* - Sale - */
                                                              getSalePrice(comp)
                                                    }
                                                </div>
                                            )
                                        ) : /* --- Has Transform --- */
                                        header.transform ? (
                                            /* - Brokers - */
                                            header.name === "Brokers" ? (
                                                <div>
                                                    {getFromAttributeValues(comp, ["listing_agents", "brokers"], "") ? (
                                                        getFromAttributeValues(
                                                            comp,
                                                            ["listing_agents", "brokers"],
                                                            ""
                                                        ).includes(",") ? (
                                                            <span
                                                                className={classes.tooltip}
                                                                data-tip={getFromAttributeValues(comp, [
                                                                    "listing_agents",
                                                                    "brokers",
                                                                ])}
                                                            >
                                                                {header.transform(
                                                                    getFromAttributeValues(comp, [
                                                                        "listing_agents",
                                                                        "brokers",
                                                                    ])
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <div>
                                                                {getFromAttributeValues(
                                                                    comp,
                                                                    ["listing_agents", "brokers"],
                                                                    NO_CONTENT_PLACEHOLDER
                                                                )}
                                                            </div>
                                                        )
                                                    ) : (
                                                        NO_CONTENT_PLACEHOLDER
                                                    )}
                                                    <ReactTooltip
                                                        place="top"
                                                        type="dark"
                                                        effect="solid"
                                                        key={dealKey}
                                                    />
                                                </div>
                                            ) : (
                                                <div numeric={header.numeric}>
                                                    {get(comp, header.prop)
                                                        ? header.transform(get(comp, header.prop))
                                                        : NO_CONTENT_PLACEHOLDER}
                                                </div>
                                            )
                                        ) : // End of Has Transform
                                        /* --- Links --- */
                                        header.name === "Links" ? (
                                            <div
                                                style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}
                                            >
                                                {/* Landvision */}
                                                <span data-tip data-for="landvision">
                                                    <Button
                                                        onClick={() =>
                                                            navigateToLandvision(
                                                                getFromAttributeValues(
                                                                    comp,
                                                                    ["property_id", "propertyKey"],
                                                                    false
                                                                ),
                                                                get(comp, "listingkey", null)
                                                            )
                                                        }
                                                        color="primary"
                                                        className={classes.lightButton}
                                                    >
                                                        <Landvision fill="#2C99D2" />
                                                        <ReactTooltip place="top" type="dark" effect="solid" />
                                                    </Button>
                                                </span>
                                                <ReactTooltip
                                                    id="landvision"
                                                    place="top"
                                                    type="dark"
                                                    effect="solid"
                                                    className="statBoxTooltip"
                                                >
                                                    Landvision
                                                </ReactTooltip>

                                                {/* View Button */}
                                                {/* The view button isn't rendered if the comp is confidential and the user isn't a broker on the comp or dataEntry / dataAdmin */}
                                                {(!getFromAttributeValues(
                                                    comp,
                                                    ["confidentialFlg", "confidential_flg"],
                                                    false
                                                ) ||
                                                    includes(["dataEntry", "dataAdmin"], role) ||
                                                    isBrokerOnDeal(comp) ||
                                                    compTab === "broker_comps") && (
                                                    <Link to={`/comp/${dealKey}`} className={classes.lb}>
                                                        <Button
                                                            variant={buttonProperties.variant}
                                                            color="primary"
                                                            onClick={() => null}
                                                            style={{ marginLeft: "50px" }}
                                                        >
                                                            {buttonProperties.text}
                                                        </Button>
                                                    </Link>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                {get(comp, header.prop)
                                                    ? get(comp, header.prop)
                                                    : NO_CONTENT_PLACEHOLDER}
                                            </div>
                                        )
                                    }
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            )}
        </Draggable>
    );
};

const alternateContent = (compTab, isLoading, loadingSearch, hasComps, searchRun, classes) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                {compTab === "all_km_comps" &&
                    (loadingSearch ? (
                        <div className={classes.alternateContent}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : !hasComps ? (
                        searchRun ? (
                            <div className={classes.alternateContent}>No Comps</div>
                        ) : (
                            <div className={classes.alternateContent}>Please apply search criteria</div>
                        )
                    ) : null)}

                {(compTab === "comps_group" || compTab === "broker_comps") &&
                    (loadingSearch || isLoading ? (
                        <div className={classes.alternateContent}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : !hasComps ? (
                        <div className={classes.alternateContent}>No Comps</div>
                    ) : null)}
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(CompsWithoutMap);
