import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Popper from "@material-ui/core/Popper";
import Chip from "@material-ui/core/Chip";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Switch, FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import BSInput from "../../../common/js/components/BSInput";
import { DownChevron } from "../../../common/js/icons";
import get from "lodash/get";
import set from "lodash/set";
import each from "lodash/each";
import Scroll from "react-scroll";
import LocationFilters from "../property/LocationFilters";
import Today from "../../../../node_modules/@material-ui/icons/Today";
import searchConstants from "../search/constants";

const styles = {
    menuSearchButton: {
        marginBottom: "15px",
    },
    root: {
        "&:hover": {
            cursor: "pointer",
        },
        backgroundColor: "white",
    },
    statusContainer: {
        minWidth: "210px",
        padding: "0 15px",
    },
    fakeSelect: {
        height: "39px",
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
        marginLeft: 0,
        fontWeight: 400,
        width: "100%",
        justifyContent: "flex-start",
        padding: "0 26px 0 6px",
        backgroundColor: "white",
        fontSize: "14px",
    },
    focusVisible: {
        border: "1px solid #2C99D2",
        backgroundColor: "white",
    },
    fakeSelectIcon: {
        position: "absolute",
        right: "6px",
        fontSize: "16px",
    },
    filterBar: {
        backgroundColor: "white",
        paddingTop: "15px",
        paddingRight: "20px",
        paddingLeft: "15px",
        borderBottom: "1px solid #eee",
    },
    statusSwitch: {
        marginRight: "-15px",
        color: "red",
    },
    menuTitle: {
        fontSize: "14px",
    },
    locationFilterContainer: {
        minWidth: "450px",
        padding: "20px",
    },
    dealTypeFilterContainer: {
        minWidth: "400px",
        padding: "20px 0px 20px 0px",
        display: "flex",
        justifyContent: "center",
    },
    officeDropdown: {
        marginTop: 0,
        height: "30px",
        minWidth: "450px",
    },
    disabledButton: {
        color: "#c4c4c4",
    },
};

class AuditFilters extends React.Component {
    state = {
        anchorEl: null,
        open: {},
        placement: null,
    };

    handleClick = (placement) => (event, filter) => {
        const { currentTarget } = event;
        this.setState((state) => ({
            anchorEl: currentTarget,
            open: {
                [filter]: state.placement !== placement || !state.open[filter],
            },
            placement,
        }));
    };

    handleClose = () => {
        this.setState({ open: {} });
    };

    onKeyPress = (event) => {
        if (event.key === "Enter") {
            this.props.searchSubmit();
            this.handleClose();
        }
    };

    handleClearFilters = () => {
        this.props.clearFilters();
        this.props.handleSearchCleared(true);
        this.handleClose();
    };

    handleSearchSubmit = (searchCriteria) => {
        this.props.handleSearchCleared(false);
        this.props.searchSubmit(searchCriteria);
        this.handleClose();
    };

    countPropertyTypeSelected = (searchCriteria) => {
        let count = 0;
        each(get(searchCriteria, "propertyTypes", []), (p) => {
            if (p.selected === true) {
                count += 1;
            }
        });
        return count;
    };

    countFilter = (searchCriteria, filterName) => {
        let count = 0;
        each(get(searchCriteria, filterName, []), (option) => {
            if (option === true) {
                count += 1;
            }
        });
        return count;
    };

    componentDidMount = () => {
        this.props.updateFilter("dealTypeCriteria.kmCompsOnly", true);
    };

    checkForLeaseOnly = (checked, box) => {
        let currentState = {
            sale: get(this.props.searchCriteria, "dealTypeCriteria.sale"),
            kmCompsOnly: get(this.props.searchCriteria, "dealTypeCriteria.kmCompsOnly"),
            lease: get(this.props.searchCriteria, "dealTypeCriteria.lease"),
        };
        set(currentState, box, checked);
        this.props.updateFilter(`dealTypeCriteria.${box}`, checked);
    };

    combineFilterCount = (searchCriteria, criteriaOne, criteriaTwo) => {
        return this.countFilter(searchCriteria, criteriaOne) + this.countFilter(searchCriteria, criteriaTwo);
    };

    render() {
        const { classes, updateFilter, searchCriteria, loadedSearchCrumbs, handleDeleteCrumb, disabled } = this.props;

        const { anchorEl, open, placement } = this.state;

        let Element = Scroll.Element;

        return (
            <div className={classes.filterBar}>
                <Grid container justify="space-between" alignItems="center" spacing={8}>
                    <Grid item x style={{ fontWeight: 400, maxWidth: "70px", marginLeft: "12px", marginRight: "5px" }}>
                        <div>Filters:</div>
                    </Grid>
                    <Grid item xs>
                        <Popper open={open["location"]} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.locationFilterContainer}>
                                            <LocationFilters
                                                updateFilter={updateFilter}
                                                searchCriteria={searchCriteria}
                                                onKeyPress={this.onKeyPress}
                                            ></LocationFilters>
                                            <Grid container spacing={16} justify="flex-end">
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(searchCriteria) =>
                                                            this.handleSearchSubmit(searchCriteria)
                                                        }
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            disabled={disabled}
                            classes={{ disabled: classes.disabledButton }}
                            onClick={(e) => this.handleClick("bottom-start")(e, "location")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {"Location"}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs>
                        <Popper
                            open={get(open, "dealType", false)}
                            anchorEl={anchorEl}
                            placement={placement}
                            transition
                        >
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.dealTypeFilterContainer}>
                                            <Element>
                                                <Grid container style={{ margin: 0 }}>
                                                    <Grid item>
                                                        <Grid container justify="space-between" alignItems="center">
                                                            <Grid item>
                                                                <span className={classes.menuTitle}>KM Comps Only</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <Switch
                                                                    checked={get(
                                                                        searchCriteria,
                                                                        "dealTypeCriteria.kmCompsOnly",
                                                                        true
                                                                    )}
                                                                    disabled={true}
                                                                    onChange={(e) =>
                                                                        this.checkForLeaseOnly(
                                                                            e.target.checked,
                                                                            "kmCompsOnly"
                                                                        )
                                                                    }
                                                                    value="confidentialComps"
                                                                    className={classes.statusSwitch}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container justify="space-between" alignItems="center">
                                                            <Grid item>
                                                                <span className={classes.menuTitle}>
                                                                    Include Confidential Comps
                                                                </span>
                                                            </Grid>
                                                            <Grid item>
                                                                <Switch
                                                                    checked={get(
                                                                        searchCriteria,
                                                                        "dealTypeCriteria.confidentialComps",
                                                                        false
                                                                    )}
                                                                    onChange={(e) =>
                                                                        updateFilter(
                                                                            "dealTypeCriteria.confidentialComps",
                                                                            e.target.checked
                                                                        )
                                                                    }
                                                                    value="confidentialComps"
                                                                    className={classes.statusSwitch}
                                                                    color="primary"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Divider style={{ margin: "10px -20px 12px -20px" }} />
                                                        <Grid container justify="space-between" spacing={8}>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                style={{ display: "flex", justifyContent: "center" }}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={get(
                                                                                searchCriteria,
                                                                                "dealTypeCriteria.lease",
                                                                                false
                                                                            )}
                                                                            onChange={(e) =>
                                                                                this.checkForLeaseOnly(
                                                                                    e.target.checked,
                                                                                    "lease"
                                                                                )
                                                                            }
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Lease"
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                style={{ display: "flex", justifyContent: "center" }}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={get(
                                                                                searchCriteria,
                                                                                "dealTypeCriteria.sale",
                                                                                false
                                                                            )}
                                                                            onChange={(e) =>
                                                                                this.checkForLeaseOnly(
                                                                                    e.target.checked,
                                                                                    "sale"
                                                                                )
                                                                            }
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Sale"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Divider style={{ margin: "10px -20px 24px -20px" }} />
                                                        <Grid
                                                            container
                                                            style={{ display: "flex", flexDirection: "column" }}
                                                        >
                                                            <Grid item>
                                                                <Grid container justify="space-between" spacing={0}>
                                                                    <Grid item xs={5}>
                                                                        <BSInput
                                                                            label="Date Signed"
                                                                            placeholder="Date Signed (From)"
                                                                            value={get(
                                                                                searchCriteria,
                                                                                "dealTypeCriteria.transactionDateFrom",
                                                                                ""
                                                                            )}
                                                                            type={"date"}
                                                                            startAdornment={<Today />}
                                                                            onChange={(e) =>
                                                                                updateFilter(
                                                                                    "dealTypeCriteria.transactionDateFrom",
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item align="center" xs={1}>
                                                                        <div
                                                                            className="betweenInputs"
                                                                            style={{
                                                                                paddingTop: "30px",
                                                                                color: "grey",
                                                                            }}
                                                                        >
                                                                            &mdash;
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <BSInput
                                                                            placeholder="Date Signed (To)"
                                                                            value={get(
                                                                                searchCriteria,
                                                                                "dealTypeCriteria.transactionDateTo",
                                                                                ""
                                                                            )}
                                                                            type={"date"}
                                                                            startAdornment={<Today />}
                                                                            onChange={(e) =>
                                                                                updateFilter(
                                                                                    "dealTypeCriteria.transactionDateTo",
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container justify="space-between" spacing={0}>
                                                                    <Grid item xs={5}>
                                                                        <BSInput
                                                                            label="Transaction Date"
                                                                            placeholder="Transaction Date (From)"
                                                                            value={get(
                                                                                searchCriteria,
                                                                                "dealTypeCriteria.batchDateFrom",
                                                                                ""
                                                                            )}
                                                                            type={"date"}
                                                                            startAdornment={<Today />}
                                                                            onChange={(e) =>
                                                                                updateFilter(
                                                                                    "dealTypeCriteria.batchDateFrom",
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item align="center" xs={1}>
                                                                        <div
                                                                            className="betweenInputs"
                                                                            style={{
                                                                                paddingTop: "30px",
                                                                                color: "grey",
                                                                            }}
                                                                        >
                                                                            &mdash;
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <BSInput
                                                                            placeholder="Transaction Date (To)"
                                                                            value={get(
                                                                                searchCriteria,
                                                                                "dealTypeCriteria.batchDateTo",
                                                                                ""
                                                                            )}
                                                                            type={"date"}
                                                                            startAdornment={<Today />}
                                                                            onChange={(e) =>
                                                                                updateFilter(
                                                                                    "dealTypeCriteria.batchDateTo",
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container justify="flex-end">
                                                                    <Grid item>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={this.handleSearchSubmit}
                                                                            className={classes.menuSearchButton}
                                                                        >
                                                                            Search
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Element>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            disabled={disabled}
                            classes={{ disabled: classes.disabledButton }}
                            onClick={(e) => this.handleClick("bottom-start")(e, "dealType")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {`Deal Type ${
                                this.countFilter(searchCriteria, "dealTypeCriteria") > 0
                                    ? `(${this.countFilter(searchCriteria, "dealTypeCriteria")})`
                                    : ""
                            }`}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>

                    <Grid item xs>
                        <Popper open={open["kmOffice"]} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.statusContainer}>
                                            <Element style={{ height: "400px", overflowY: "scroll" }}>
                                                <Grid container direction="column">
                                                    {/*for each office*/}
                                                    {Object.keys(searchConstants.kmOffices).map((key, i) => {
                                                        const option = searchConstants.kmOffices[key];
                                                        return (
                                                            <Grid item className={classes.officeDropdown}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={get(
                                                                                searchCriteria,
                                                                                `officeCriteria.${key}`,
                                                                                false
                                                                            )}
                                                                            onChange={(e) =>
                                                                                updateFilter(
                                                                                    "officeCriteria." + key,
                                                                                    e.target.checked
                                                                                )
                                                                            }
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label={option.display}
                                                                />
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Element>
                                            <Grid container spacing={16} justify="flex-end">
                                                <Grid item style={{ paddingTop: "30px" }}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSearchSubmit}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            disabled={disabled}
                            classes={{ disabled: classes.disabledButton }}
                            onClick={(e) => this.handleClick("bottom-start")(e, "kmOffice")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {`KM Office ${
                                this.countFilter(searchCriteria, "officeCriteria") > 0
                                    ? `(${this.countFilter(searchCriteria, "officeCriteria")})`
                                    : ""
                            }`}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" onClick={this.handleClearFilters}>
                            Clear
                        </Button>
                    </Grid>
                </Grid>
                <Grid container justify="space-between" alignItems="center" spacing={8} style={{ marginTop: "10px" }}>
                    <Grid item>
                        {loadedSearchCrumbs
                            ? Object.keys(loadedSearchCrumbs).map((criteria) => {
                                  if (loadedSearchCrumbs[criteria].value === true) {
                                      if (Object.keys(loadedSearchCrumbs[criteria]).length === 2) {
                                          return (
                                              <Chip
                                                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                                                  color="primary"
                                                  label={criteria}
                                                  onDelete={() =>
                                                      handleDeleteCrumb(loadedSearchCrumbs[criteria], false)
                                                  }
                                              />
                                          );
                                      } else if (
                                          (loadedSearchCrumbs[criteria].valueMin !== "* " &&
                                              loadedSearchCrumbs[criteria].valueMin !== undefined) ||
                                          (loadedSearchCrumbs[criteria].valueMax !== " *" &&
                                              loadedSearchCrumbs[criteria].valueMax !== undefined)
                                      ) {
                                          return (
                                              <Chip
                                                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                                                  color="primary"
                                                  label={
                                                      criteria +
                                                      get(loadedSearchCrumbs[criteria], "unit", "") +
                                                      ": " +
                                                      get(loadedSearchCrumbs[criteria], "valueMin", "* ") +
                                                      "-" +
                                                      get(loadedSearchCrumbs[criteria], "valueMax", " *")
                                                  }
                                                  onDelete={() => handleDeleteCrumb(loadedSearchCrumbs[criteria], null)}
                                              />
                                          );
                                      }
                                  } else if (
                                      typeof loadedSearchCrumbs[criteria].value == "string" &&
                                      loadedSearchCrumbs[criteria].value != null
                                  ) {
                                      return (
                                          <Chip
                                              style={{ marginLeft: "10px", marginBottom: "10px" }}
                                              color="primary"
                                              label={criteria + ": " + loadedSearchCrumbs[criteria].value}
                                              onDelete={() => handleDeleteCrumb(loadedSearchCrumbs[criteria], null)}
                                          />
                                      );
                                  }
                                  return null;
                              })
                            : ""}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(AuditFilters);
