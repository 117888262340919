import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Select from "@material-ui/core/Select";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { UpChevron, DownChevron } from "../../../common/js/icons";
import get from "lodash/get";
import includes from "lodash/includes";
import cloneDeep from "lodash/cloneDeep";
import forEach from "lodash/forEach";
import { CloudUpload } from "../../../common/js/icons";
import Grid from "@material-ui/core/Grid";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { docTypeIcons } from "./documentTypes";
import utils from "../utils/index";
import AdminDocumentTypeList from "./AdminDocumentTypeList";

const styles = {
    root: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    },
    paperPadding: {
        padding: "0 20px 20px",
        border: "none",
        marginBottom: "20px",
        boxShadow: "0 1px 2px rgba(34, 54, 70, 0.1)",
    },
    paperHeading: {
        fontSize: "18px",
        margin: "14px 0",
    },
    hr: {
        margin: "0 -20px 20px -20px",
    },
    hrDivider: {
        margin: "0 -20px",
        borderTop: "1px solid rgba(0, 0, 0, 0.06)",
    },
    uploadButton: {
        color: "#757575",
        fill: "#757575",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    attachmentCell: {
        fontSize: 14,
    },
    approvedBy: {
        fontWeight: 600,
    },
    bsSelect: {
        fontSize: "14px",
        padding: "9px 6px 9px 6px",
        boxSizing: "border-box",
        height: "37px",
        width: "100%",
        "&:focus": {
            backgroundColor: "transparent",
            borderBottom: "none",
        },
        "&::before": {
            borderBottom: "none !important",
        },
        "&::after": {
            borderBottom: "none !important",
        },
    },
    bsSelectRoot: {},
    menuInstruction: {
        height: "12px",
        fontSize: "14px",
    },
    menuCategory: {
        height: "12px",
        fontSize: "11px",
        fontStyle: "italic",
        textTransform: "uppercase",
    },
    menuItem: {
        height: "12px",
        fontSize: "14px",
    },
    deleteProgress: {
        textAlign: "center",
        width: "24px",
        display: "inline-block",
    },
    table: {
        tableLayout: "fixed",
    },
    docIcon: {
        float: "left",
    },
    docName: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "block",
        textOverflow: "ellipsis",
        paddingTop: "2px",
        paddingLeft: "5px",
    },
};

const DocumentList = (props) => {
    const {
        open,
        header,
        classes,
        anchorEl,
        handleSort,
        docMenuKey,
        formDisabled,
        columnToSort,
        usedDocTypes,
        sortDirection,
        savedDocuments,
        handleMenuClose,
        toggleDeleteDialog,
        approveDocument,
        documentsUploading,
        selectDocumentType,
        handlePreviewDocument,
        handleOpenDocumentMenu,
        role,
        docApproved,
        restrictPreview,
        elementsSaving,
        alternateTitle,
        copyDocUrl,
        handleAddDocuments,
        options,
        toggleTerminationDoc,
        terminationDocument,
        type,
        voucherStatus,
        allDocumentTypes,
        noHeader,
    } = props;

    const handleSelectDocumentType = (key, event) => {
        selectDocumentType(key, event.target.value);
    };

    const handleRemoveDocument = (key, documentType) => {
        toggleDeleteDialog(true, key);

        if (documentType === "TERMINATION_DOCUMENT") {
            toggleTerminationDoc(false);
        }
    };

    const handleApproveDocument = (key) => {
        approveDocument(key);
    };

    const handleCopyDocumentUrl = (key) => {
        copyDocUrl(key);
        handleMenuClose();
    };

    let canView = [];
    let canDelete = [];
    let brokerPaymentDocTypes = cloneDeep(props.documentTypes);
    let documentTypes = cloneDeep(props.documentTypes);
    let disallowOtherOption = false;
    let voucherAwaitingPayment = voucherStatus === "Awaiting Payment" ? true : false;
    let voucherReadyForAccounting = voucherStatus === "Ready For Accounting" ? true : false;

    if (options) {
        canView = options.canView;
        canDelete = options.canDelete;
        if (get(options, "canUpload", []).length > 0 && documentTypes !== undefined) {
            forEach(Object.keys(documentTypes), (key) => {
                if (!includes(options.canUpload, key)) {
                    delete documentTypes[key];
                }
            });
        }
    }

    if (role === "broker") {
        forEach(Object.keys(documentTypes), (key) => {
            if (!includes(["OTHER", "COMMISSION_PAYMENT"], key)) {
                delete brokerPaymentDocTypes[key];
            }
        });
    }

    disallowOtherOption = get(options, "disallowOtherOption", false);
    let dropzoneRef;

    return (
        <Paper className={classes.paperPadding}>
            <Grid container justify="space-between" alignItems="center" spacing={0}>
                <Grid item>
                    <h3 className={classes.paperHeading}>
                        {noHeader ? "" : alternateTitle ? alternateTitle : "Attached Documents"}
                    </h3>
                </Grid>
                <Grid item>
                    <Dropzone
                        accept="image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ref={(node) => {
                            dropzoneRef = node;
                        }}
                        onDrop={(accepted, rejected) => {
                            handleAddDocuments(accepted, rejected, type);
                        }}
                        style={{ display: "none" }}
                    ></Dropzone>
                    <Button
                        disabled={formDisabled}
                        component="span"
                        onClick={() => {
                            dropzoneRef.open();
                        }}
                        className={classes.uploadButton}
                    >
                        <CloudUpload />
                        &nbsp;&nbsp;Upload
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs>
                    <hr className={classes.hrDivider} />
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {header.map((x, i) => (
                                    <TableCell
                                        onClick={() => handleSort(x.prop)}
                                        classes={classes}
                                        style={{ ...x.extraHeaderStyles }}
                                    >
                                        <span>{x.name}</span>
                                        {columnToSort === x.prop ? (
                                            sortDirection === "asc" ? (
                                                <UpChevron />
                                            ) : (
                                                <DownChevron />
                                            )
                                        ) : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {savedDocuments &&
                                Object.keys(savedDocuments).map((x, i) => {
                                    const document = savedDocuments[x];
                                    if (document["parentEntityType"] && props.parentEntityType) {
                                        if (document["parentEntityType"]["name"] !== props.parentEntityType) {
                                            return null;
                                        }
                                    }
                                    let removedDocumentType = false;
                                    if (
                                        document.mediaAssetType === "TERMINATION_DOCUMENT" &&
                                        get(terminationDocument, "upload", null) === false
                                    ) {
                                        console.log("toggle termination");
                                        console.log(props.terminationDocument);
                                        console.log(document.mediaAssetType);
                                        toggleTerminationDoc(true);
                                    }
                                    if (documentTypes !== undefined) {
                                        removedDocumentType = !documentTypes.hasOwnProperty(
                                            get(document, "mediaAssetType")
                                        );
                                    }
                                    return (
                                        <TableRow key={`tr-doc-${i}`}>
                                            <TableCell
                                                className={classes.attachmentCell}
                                                style={{ width: "48%", overflow: "hidden", textOverflow: "ellipsis" }}
                                                classes={classes}
                                            >
                                                <div className={classes.docIcon}>
                                                    {get(
                                                        docTypeIcons,
                                                        get(document, "mediaAssetName", " ").split(".").pop(),
                                                        docTypeIcons["default"]
                                                    )()}
                                                </div>
                                                <div className={classes.docName}>{document["mediaAssetName"]}</div>
                                            </TableCell>
                                            <TableCell className={classes.attachmentCell} classes={classes}>
                                                <AdminDocumentTypeList
                                                    {...props}
                                                    stateCode={props.stateCode}
                                                    parentEntityType={props.parentEntityType}
                                                    preexistingValue={document}
                                                    style={{ display: props.adminListReady ? "" : "none" }}
                                                    mediaAssetKey={x}
                                                    documentTypesEnumList={allDocumentTypes}
                                                    updateDocumentFromAdminList={props.updateDocumentFromAdminList}
                                                    savedDocuments={savedDocuments}
                                                    voucherStatus={voucherStatus}
                                                    formDisabled={formDisabled}
                                                    options={options}
                                                    role={role}
                                                />
                                                {voucherAwaitingPayment &&
                                                role === "broker" &&
                                                includes(
                                                    ["OTHER", "COMMISSION_PAYMENT"],
                                                    document["mediaAssetType"]
                                                ) ? (
                                                    //adjusted document dropdown on vouchers for brokers to ONLY add commission payments when voucher is Awaiting Payment
                                                    <Select
                                                        disabled={
                                                            options
                                                                ? !includes(
                                                                      options.canView,
                                                                      document["mediaAssetType"]
                                                                  ) && document["updated"] === true
                                                                : formDisabled
                                                        }
                                                        name={`docType-select-${i}`}
                                                        value={
                                                            document["updated"] !== true
                                                                ? "select"
                                                                : document["mediaAssetType"]
                                                        }
                                                        onChange={(e) => handleSelectDocumentType(x, e)}
                                                        classes={{
                                                            select: classes.bsSelect,
                                                            root: classes.bsSelectRoot,
                                                        }}
                                                        className={classes.bsSelect}
                                                        style={{ display: props.adminListReady ? "none" : "" }}
                                                    >
                                                        {documentTypes &&
                                                            Object.keys(brokerPaymentDocTypes).map((r, q) => {
                                                                if (
                                                                    !usedDocTypes.includes(r) &&
                                                                    !brokerPaymentDocTypes[r]["required"] &&
                                                                    !brokerPaymentDocTypes[r]["multipleAllowed"]
                                                                ) {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-${i}-${q}`}
                                                                            value={r}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {brokerPaymentDocTypes[r]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                } else if (r === "OTHER" && disallowOtherOption) {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-required-${i}-${q}`}
                                                                            value={r}
                                                                            style={{ display: "none" }}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {brokerPaymentDocTypes[r]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                } else if (
                                                                    !brokerPaymentDocTypes[r]["required"] &&
                                                                    brokerPaymentDocTypes[r]["multipleAllowed"]
                                                                ) {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-${i}-${q}`}
                                                                            value={r}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {brokerPaymentDocTypes[r]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-required-${i}-${q}`}
                                                                            value={r}
                                                                            style={{ display: "none" }}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {brokerPaymentDocTypes[r]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            })}
                                                    </Select>
                                                ) : (
                                                    <Select
                                                        disabled={
                                                            (voucherAwaitingPayment || voucherReadyForAccounting) &&
                                                            role === "broker"
                                                                ? true
                                                                : options && formDisabled
                                                                ? !(
                                                                      includes(
                                                                          options.canUpload,
                                                                          document["mediaAssetType"]
                                                                      ) && document["updated"] !== true
                                                                  )
                                                                : formDisabled
                                                        }
                                                        name={`docType-select-${i}`}
                                                        value={
                                                            document["updated"] !== true
                                                                ? "select"
                                                                : removedDocumentType
                                                                ? allDocumentTypes[document["mediaAssetType"]]
                                                                : document["mediaAssetType"]
                                                        }
                                                        onChange={(e) => handleSelectDocumentType(x, e)}
                                                        classes={{
                                                            select: classes.bsSelect,
                                                            root: classes.bsSelectRoot,
                                                        }}
                                                        className={classes.bsSelect}
                                                        style={{ display: props.adminListReady ? "none" : "" }}
                                                    >
                                                        <MenuItem value="" disabled className={classes.menuCategory}>
                                                            Required
                                                        </MenuItem>
                                                        {documentTypes &&
                                                            Object.keys(documentTypes).map((j, k) => {
                                                                if (
                                                                    !usedDocTypes.includes(j) &&
                                                                    documentTypes[j]["required"] &&
                                                                    document["mediaAssetType"]
                                                                ) {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-required-${i}-${k}`}
                                                                            value={j}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {documentTypes[j]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-required-${i}-${k}`}
                                                                            value={j}
                                                                            style={{ display: "none" }}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {documentTypes[j]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            })}
                                                        {documentTypes &&
                                                            Object.keys(documentTypes).map((j, k) => {
                                                                if (
                                                                    usedDocTypes.includes(j) &&
                                                                    documentTypes[j]["required"] &&
                                                                    documentTypes[j]["multipleAllowed"]
                                                                ) {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-required-${i}-${k}`}
                                                                            value={j}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {documentTypes[j]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-required-${i}-${k}`}
                                                                            value={j}
                                                                            style={{ display: "none" }}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {documentTypes[j]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            })}
                                                        <Divider />
                                                        <MenuItem value="" disabled className={classes.menuCategory}>
                                                            Optional
                                                        </MenuItem>
                                                        {/* THIS ALLOWS FOR DOCUMENT TYPE TO DISPLAY IF THE TYPE IS NO LONGER ALLOWED */}
                                                        {documentTypes && removedDocumentType ? (
                                                            <MenuItem
                                                                key={`doc-type-removed-1`}
                                                                value={allDocumentTypes[document["mediaAssetType"]]}
                                                                className={classes.menuItem}
                                                            >
                                                                {allDocumentTypes[document["mediaAssetType"]]
                                                                    ? allDocumentTypes[document["mediaAssetType"]][
                                                                          "display"
                                                                      ]
                                                                    : "Other"}
                                                            </MenuItem>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {documentTypes &&
                                                            Object.keys(documentTypes).map((r, q) => {
                                                                if (
                                                                    !usedDocTypes.includes(r) &&
                                                                    !documentTypes[r]["required"] &&
                                                                    !documentTypes[r]["multipleAllowed"]
                                                                ) {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-${i}-${q}`}
                                                                            value={r}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {documentTypes[r]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                } else if (r === "OTHER" && disallowOtherOption) {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-required-${i}-${q}`}
                                                                            value={r}
                                                                            style={{ display: "none" }}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {documentTypes[r]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                } else if (
                                                                    !documentTypes[r]["required"] &&
                                                                    documentTypes[r]["multipleAllowed"]
                                                                ) {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-${i}-${q}`}
                                                                            value={r}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {documentTypes[r]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <MenuItem
                                                                            key={`doc-type-required-${i}-${q}`}
                                                                            value={r}
                                                                            style={{ display: "none" }}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {documentTypes[r]["display"]}
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            })}
                                                    </Select>
                                                )}
                                            </TableCell>
                                            {role === "portlandBrokerAdmin" && (
                                                <TableCell classes={classes}>
                                                    <div className={classes.approvedBy}>
                                                        {get(document, "approvedBy", "")}
                                                    </div>
                                                    <div>
                                                        {utils.operations.formatDate("MM-DD-YYYY")(
                                                            get(document, "approvedByTimestamp", "")
                                                        )}
                                                    </div>
                                                </TableCell>
                                            )}
                                            <TableCell
                                                style={{ minWidth: "24px", textAlign: "center", position: "relative" }}
                                                classes={classes}
                                            >
                                                {get(elementsSaving, `documents.${x}`) === true ? (
                                                    <div className={classes.deleteProgress}>
                                                        <CircularProgress color="primary" size={20}></CircularProgress>
                                                    </div>
                                                ) : (
                                                    <IconButton
                                                        style={{
                                                            position: "absolute",
                                                            top: "22%",
                                                            left: "28%",
                                                            padding: "5px",
                                                        }}
                                                        disabled={
                                                            restrictPreview
                                                                ? true
                                                                : options
                                                                ? includes(canView, document["mediaAssetType"]) ||
                                                                  includes(canDelete, document["mediaAssetType"])
                                                                    ? false
                                                                    : true
                                                                : false
                                                        }
                                                        aria-label="More"
                                                        aria-owns={
                                                            Boolean(document["anchorEl"]) ? `long-menu-${x}-${i}` : null
                                                        }
                                                        aria-haspopup="true"
                                                        onClick={(e) => handleOpenDocumentMenu(x, e.currentTarget)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                )}
                                                <Menu
                                                    id={`long-menu-${x}-${i}`}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleMenuClose}
                                                    PaperProps={{
                                                        style: {
                                                            width: 200,
                                                            boxShadow:
                                                                "0px 4px 6px 1px rgba(0, 0, 0, 0.05), 0px 4px 11px 1px rgba(0, 0, 0, 0.05), 7px 4px 6px -3px rgba(0, 0, 0, 0.05)",
                                                        },
                                                    }}
                                                >
                                                    {(!options ||
                                                        includes(
                                                            canView,
                                                            get(savedDocuments, `${docMenuKey}.mediaAssetType`)
                                                        )) && (
                                                        <MenuItem
                                                            onClick={() =>
                                                                handlePreviewDocument(
                                                                    get(document, "url", null),
                                                                    docMenuKey
                                                                )
                                                            }
                                                            className={classes.menuItem}
                                                        >
                                                            Open in a new tab
                                                        </MenuItem>
                                                    )}
                                                    {(!options ||
                                                        includes(
                                                            canView,
                                                            get(savedDocuments, `${docMenuKey}.mediaAssetType`)
                                                        )) && (
                                                        <MenuItem
                                                            onClick={() => handleCopyDocumentUrl(docMenuKey)}
                                                            className={classes.menuItem}
                                                        >
                                                            Copy Link
                                                        </MenuItem>
                                                    )}
                                                    {role === "portlandBrokerAdmin" && (
                                                        <MenuItem
                                                            id={`doc-approve-${x}-${i}`}
                                                            disabled={docApproved}
                                                            onClick={() => handleApproveDocument(docMenuKey)}
                                                            className={classes.menuItem}
                                                        >
                                                            Approve
                                                        </MenuItem>
                                                    )}
                                                    {(!options ||
                                                        includes(
                                                            canDelete,
                                                            get(savedDocuments, `${docMenuKey}.mediaAssetType`)
                                                        )) && (
                                                        <MenuItem
                                                            onClick={() =>
                                                                handleRemoveDocument(
                                                                    docMenuKey,
                                                                    get(savedDocuments, `${docMenuKey}.mediaAssetType`)
                                                                )
                                                            }
                                                            className={classes.menuItem}
                                                        >
                                                            Delete
                                                        </MenuItem>
                                                    )}
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                            {documentsUploading
                                ? Object.keys(documentsUploading).map((x, i) => {
                                      if (get(documentsUploading, x)) {
                                          return (
                                              <React.Fragment>
                                                  <TableRow style={{ height: "30px" }}>
                                                      <TableCell
                                                          classes={classes}
                                                          colSpan={5}
                                                          style={{
                                                              paddingBottom: "0px",
                                                              borderBottom: "none",
                                                              position: "relative",
                                                          }}
                                                      >
                                                          <div style={{ position: "absolute" }}>{x}</div>
                                                      </TableCell>
                                                  </TableRow>
                                                  <TableRow style={{ height: "30px" }}>
                                                      <TableCell
                                                          classes={classes}
                                                          colSpan={5}
                                                          style={{ paddingTop: "0px" }}
                                                      >
                                                          <div>
                                                              <LinearProgress />
                                                          </div>
                                                      </TableCell>
                                                  </TableRow>
                                              </React.Fragment>
                                          );
                                      } else {
                                          return null;
                                      }
                                  })
                                : null}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default withStyles(styles)(DocumentList);
