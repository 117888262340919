import React, { useState } from "react";
import { connect } from "react-redux";
import operations from "./ducks/operations";
import { Button, CircularProgress } from "@material-ui/core";
import AssetServicesSnapshotModal from "./AssetServicesSnapshotModal";

const AssetServicesSnapshotModalContainer = (props) => {
    const [modal, toggleModal] = useState(false);
    const { propertyKey } = props;
    return props.saving ? (
        <CircularProgress color="primary" size={20} style={{ marginRight: "40px" }} />
    ) : (
        <React.Fragment>
            <Button variant="contained" color="primary" onClick={() => toggleModal(true)}>
                Archive Record
            </Button>
            <AssetServicesSnapshotModal open={modal} toggleModal={toggleModal} propertyKey={propertyKey} {...props} />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        saving: state.utilsReducer.isSaving,
        propertyManagementKey: state.propertyReducer.propertyManagement.propertyManagementKey,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        archiveAssetServicesRecord: (propertyManagementKey, record, propertyKey) => {
            dispatch(operations.archiveAssetServicesRecord(propertyManagementKey, record, propertyKey));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetServicesSnapshotModalContainer);
