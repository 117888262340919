import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ROLES, audit_tabs } from "./constants";
import operations from "./ducks/operations";

const AuditTabs = (props) => {
    const { activeTab, handleChangeTabs, role } = props;

    const { auditorSearch, auditorList } = audit_tabs;
    const { auditor } = ROLES;
    useEffect(() => {
        if (role === auditor) {
            (() => handleChangeTabs)(auditorList);
        }
    }, []);
    return (
        <Grid container justify="space-between" alignItems="center" spacing={0}>
            <Grid item>
                <Tabs indicatorColor="primary" textColor="primary" value={activeTab} onChange={handleChangeTabs}>
                    {role !== auditor && <Tab value={auditorSearch} label="Auditor Search" />}
                    <Tab value={auditorList} label="Auditor List" />
                </Tabs>
            </Grid>
        </Grid>
    );
};

const styles = (theme) => ({});
const mapStateToProps = (state) => ({
    activeTab: state.auditReducer.activeTab,
    role: state.authReducer.userProfile.role,
});
const mapDispatchToProps = (dispatch) => ({
    handleChangeTabs: (e, tab) => {
        dispatch(operations.handleChangeTabs(tab));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AuditTabs));
