export const assetTypes = [
    {
        value: "Association",
        label: "Association",
    },
    {
        value: "Industrial",
        label: "Industrial",
    },
    {
        value: "Land/Parking",
        label: "Land/Parking",
    },
    {
        value: "Medical Office/Life Science",
        label: "Medical Office/Life Science",
    },
    {
        value: "Office",
        label: "Office",
    },
    {
        value: "Other",
        label: "Other",
    },
    {
        value: "Retail",
        label: "Retail",
    },
];
