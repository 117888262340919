import React from "react";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import schema from "../admin/userListingsTable";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import get from "lodash/get";
import CircularProgress from "@material-ui/core/CircularProgress";
import BSInput from "../../../common/js/components/BSInput";
import includes from "lodash/includes";
import Today from "@material-ui/icons/Today";

class UserHistoryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: null,
            date: null,
            type: null,
            userAccountKey: null,
            allowSave: false,
        };
    }

    handleUpdateDate = (key, date, type, userAccountKey) => {
        this.setState({
            key: key,
            date: date,
            type: type,
            userAccountKey: userAccountKey,
            allowSave: false,
        });
        setTimeout(() => {
            this.submitDateChange();
        }, 1000);
    };

    submitDateChange = () => {
        this.props.handleOfficeDateChange(this.state.key, this.state.date, this.state.type, this.state.userAccountKey);
        this.setState({
            key: null,
            date: null,
            type: null,
            userAccountKey: null,
            allowSave: true,
        });
    };

    saveHistory = () => {
        this.props.handleSaveHistory(this.props.userAccountKey);
        this.setState({ allowSave: false });
    };

    render() {
        const { handleShowModal, showModal, classes, userOfficeHistoryData, name, isLoading, userAccountKey } =
            this.props;

        return (
            <Modal aria-labelledby="user-history-modal" aria-describedby="view-user-history" open={showModal}>
                <div style={getModalStyle()} className={classes.paper}>
                    <h4 className="modal-title" style={{ paddingBottom: "25px", textAlign: "center" }}>
                        Office History for: {name}
                    </h4>
                    <Grid container spacing={0}>
                        <Grid item xs style={{ overflowX: "hidden", minHeight: "25vh" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {schema.viewHeaders["userOfficeHistory"].map((x, i) => (
                                            <TableCell classes={classes} key={`thc-${i}`}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "165px",
                                                    }}
                                                >
                                                    <span className={classes.sortable} style={{ minWidth: "150px" }}>
                                                        {x.name}
                                                    </span>
                                                </div>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading ? (
                                        <div className={classes.loadingSpinner} style={{ marginTop: "25px" }}>
                                            <CircularProgress color="primary" />
                                        </div>
                                    ) : userOfficeHistoryData ? (
                                        userOfficeHistoryData.map((x, i) =>
                                            row(
                                                x,
                                                i,
                                                classes,
                                                isLoading,
                                                this.handleUpdateDate,
                                                userAccountKey,
                                                this.state.key,
                                                this.state.type,
                                                this.state.date
                                            )
                                        )
                                    ) : (
                                        ""
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Button
                                disabled={!this.state.allowSave}
                                color="primary"
                                variant="contained"
                                style={{ marginTop: "25px" }}
                                onClick={() => this.saveHistory(userAccountKey)}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="outlined"
                                style={{ marginTop: "25px" }}
                                onClick={() => handleShowModal(false)}
                            >
                                Exit
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        );
    }
}

const row = (x, i, classes, isLoading, handleUpdateDate, userAccountKey, updatingKey, updatingType, updatingDate) => {
    return isLoading ? null : (
        <TableRow key={`tr-${i}`} selectable="false">
            {schema.viewHeaders["userOfficeHistory"].map((y, k) => (
                <TableCell classes={{ root: classes.body }} key={`trc-${k}`} style={{ width: "165px" }}>
                    {y.name === "End Date" && get(x, "currentOfficeFlg", false) === true ? (
                        <BSInput id="currentEndDate" value="Current" type="text" disabled={true} />
                    ) : includes(["End Date", "Start Date"], y.name) ? (
                        <BSInput
                            id="officeDate"
                            value={
                                get(x, "userOfficeHistoryKey", null) === updatingKey && y.prop === updatingType
                                    ? updatingDate
                                    : y.transform
                                    ? y.transform(x)
                                    : get(x, y.prop, "N/A")
                            }
                            onChange={(e) =>
                                handleUpdateDate(
                                    get(x, "userOfficeHistoryKey", null),
                                    e.target.value,
                                    y.prop,
                                    userAccountKey
                                )
                            }
                            type="date"
                            startAdornment={<Today />}
                            disabled={false}
                        />
                    ) : y.name === "Office" ? (
                        get(x, "office.officeMapping", null)
                    ) : y.transform ? (
                        y.transform(x)
                    ) : (
                        get(x, y.prop, "N/A")
                    )}
                </TableCell>
            ))}
        </TableRow>
    );
};

function getModalStyle() {
    const top = 25;
    const left = 40;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        minWidth: "650px",
    };
}

const styles = (theme) => ({
    loadingSpinner: {
        position: "absolute",
        left: "50%",
        top: "60%",
        transform: "translate(-50%, -50%)",
    },
});

export default withStyles(styles)(UserHistoryModal);
