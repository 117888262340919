import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import { Comments, Doc, Landvision } from "../../../common/js/icons";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import { booleanToYN, aggregateBrokers, toDollarAmount, toSqft } from "../utils/operations";
import config from "../../../config";
import "./Listings.css";
import ReactGA from "react-ga4";

const styles = (theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    root: {
        marginTop: "2em",
        padding: "0 20px",
    },
    lb: {
        textDecoration: "none",
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "4em",
        paddingBottom: "2em",
    },
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    chip: {
        root: {
            height: "24px",
        },
    },
    tableHeaderArea: {
        paddingTop: "17px",
    },
    paperPadding: {
        padding: "0 20px 20px 20px",
        border: "1px solid #f1f1f1",
        marginBottom: "15px",
    },
    paperHeading: {
        fontSize: "18px",
        margin: "0",
        borderColor: "#f1f1f1",
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
    paperHeading2: {
        fontSize: "14px",
        margin: "0",
        borderColor: "#f1f1f1",
        fontWeight: 600,
    },
    paperSubHeading: {
        fontSize: 12,
    },
    hr: {
        margin: "0 0px 20px -20px",
        borderTop: "1px solid #f1f1f1",
    },
    defTerm: {
        fontSize: "13px",
        textTransform: "uppercase",
        color: "#9E9E9E",
        fontWeight: 600,
    },
    defData: {
        fontSize: "14px",
        color: "#000",
    },
    lightButton: {
        fontWeight: "400",
        marginLeft: "0px",
        marginRight: "0px",
    },
    lightButtonIcon: {
        paddingRight: "3px",
        paddingTop: "6px",
        marginRight: "3px",
    },
});

const ListingDataPreview = (props) => {
    const {
        classes,
        listing,
        openCloseDealDialog,
        navigateToRequestUpdate,
        currentPath,
        canClose,
        handleCheckboxClick,
        selectedListings,
        listingTab,
    } = props;

    const navigateToLandvision = () => {
        ReactGA.event("Navigate To Landvision", {
            property: get(listing, "property.propertyKey"),
        });
        window.open(`${config.LANDVISION_URL}?resource=property&key=${get(listing, "property.propertyKey")}`, "_blank");
    };

    return (
        <div className={classes.paperPadding}>
            <Grid container alignItems="center" justify="space-between" spacing={16}>
                <Grid container xs direction="row">
                    {listingTab === "broker_open" ? (
                        <Grid
                            item
                            className="propertySmallImageContainer"
                            style={{
                                width: "100px",
                                height: "94px",
                                marginTop: "20px",
                                backgroundImage: `url(${get(listing, "primaryPhotoUrl", null)})`,
                            }}
                        ></Grid>
                    ) : (
                        ""
                    )}
                    <Grid
                        item
                        direction="column"
                        justify="center"
                        className={classes.tableHeaderArea}
                        style={{ height: "100px", paddingTop: "17px", padding: "20px", display: "flex" }}
                    >
                        <Grid item xs style={{ position: "relative" }}>
                            {listingTab === "all_km_listings" && (
                                <div className="listingCheckboxDiv">
                                    <Checkbox
                                        className={
                                            selectedListings[listing.listingKey] === true
                                                ? "listingCheckboxSelected"
                                                : "listingCheckbox"
                                        }
                                        checked={selectedListings[listing.listingKey] === true}
                                        value={selectedListings[listing.listingKey] === true}
                                        onChange={() => handleCheckboxClick(listing.listingKey)}
                                        color="primary"
                                    />
                                </div>
                            )}
                            <Link
                                to={`/listing/${listing.listingKey}`}
                                style={
                                    listing.expired
                                        ? { color: "red", textDecoration: "none" }
                                        : { color: "#000", textDecoration: "none" }
                                }
                            >
                                <h3
                                    className={classes.paperHeading}
                                    style={listingTab === "all_km_listings" ? { marginLeft: "8px" } : {}}
                                >
                                    {`${get(listing, "listingName", "")}`}
                                </h3>
                            </Link>
                            <h3
                                className={classes.paperHeading2}
                                style={listingTab === "all_km_listings" ? { marginLeft: "8px" } : {}}
                            >
                                {`No. ${get(listing, "listingKey", "") === null ? "" : get(listing, "listingKey", "")}`}
                            </h3>
                        </Grid>
                        <Grid item xs>
                            <div
                                className={classes.paperSubHeading}
                                style={listingTab === "all_km_listings" ? { marginLeft: "8px" } : {}}
                            >
                                {`${
                                    get(listing, "property.primaryAddress.address1") === null
                                        ? ""
                                        : get(listing, "property.primaryAddress.address1")
                                }${get(listing, "property.primaryAddress.city.city") ? ", " : ""}${
                                    get(listing, "property.primaryAddress.city.city") === null
                                        ? ""
                                        : get(listing, "property.primaryAddress.city.city")
                                }${get(listing, "property.primaryAddress.city.state.stateCode") ? ", " : ""}${
                                    get(listing, "property.primaryAddress.city.state.stateCode") === null
                                        ? ""
                                        : get(listing, "property.primaryAddress.city.state.stateCode")
                                }${
                                    get(listing, "property.primaryAddress.zipCode.zipCode", null) !== null ? " • " : ""
                                }${
                                    get(listing, "property.primaryAddress.zipCode.zipCode", null) === null
                                        ? ""
                                        : get(listing, "property.primaryAddress.zipCode.zipCode", null)
                                }`}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {listing.flyerUrl && (
                        <Button
                            onClick={() => window.open(listing.flyerUrl, "_blank")}
                            color="primary"
                            className={classes.lightButton}
                        >
                            <span>
                                <Doc style={{ marginRight: "5px" }} fill="#2C99D2" />
                            </span>
                            Flyer
                        </Button>
                    )}
                    <Button
                        onClick={() => navigateToRequestUpdate(listing.listingKey, currentPath)}
                        color="primary"
                        className={classes.lightButton}
                    >
                        <span className={classes.lightButtonIcon}>
                            <Comments fill="#2C99D2" />
                        </span>
                        Request Update
                    </Button>

                    <Button onClick={() => navigateToLandvision()} color="primary" className={classes.lightButton}>
                        <span style={{ marginRight: "5px" }}>
                            <Landvision fill="#2C99D2" />
                        </span>
                        LandVision
                    </Button>

                    {listing.nonKmListingFlg !== true && canClose && (
                        <Button variant="contained" color="primary" onClick={() => openCloseDealDialog(listing)}>
                            Close Deal
                        </Button>
                    )}
                </Grid>
            </Grid>

            {listingTab === "broker_open" ? (
                <div></div>
            ) : (
                <React.Fragment>
                    <hr className={classes.hr} />
                    <Grid container alignItems="flex-start" justify="space-evenly" spacing={16}>
                        <Grid item xs>
                            <span className={classes.defTerm}>Date Avail</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>{listing.dateAvailable}</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>SF Div</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>
                                {listing.listingType === "Sale" ? "N/A" : toSqft(get(listing, "sfDivisibleTo"))}
                            </span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>
                                {listing.listingType === "Sale" ? "List Price" : "Monthly Rate"}
                            </span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>
                                {listing.listingType === "Sale"
                                    ? `${toDollarAmount(get(listing, "listPrice", ""))}`
                                    : `${toDollarAmount(get(listing, "askingRentMax", ""))}`}
                            </span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>
                                {listing.listingType === "Sale" ? "Floors" : "Annual Rate"}
                            </span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>
                                {listing.listingType === "Sale"
                                    ? `${get(listing, "floor", "")}`
                                    : `${toDollarAmount(get(listing, "askingRentMax", "") * 12)}`}
                            </span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>Broker</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>
                                {get(listing, "listingAgent", null) !== null
                                    ? get(listing, "listingAgent", null)
                                    : aggregateBrokers(get(listing, "listingBrokers", []), "brokerAgentKey.person")}
                            </span>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start" justify="space-evenly" spacing={16}>
                        <Grid item xs>
                            <span className={classes.defTerm}>Type</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>
                                {get(listing, "attributes.useType", null) !== null
                                    ? `${capitalize(get(listing, "attributes.useType"))} ${capitalize(
                                          listing.listingType
                                      )}`
                                    : capitalize(listing.listingType)}
                            </span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>
                                {listing.listingType === "Sale" ? "Sale SF" : "SF Avail"}
                            </span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>{toSqft(get(listing, "sfAvail"))}</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>NNN Rate</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>{toDollarAmount(get(listing, "monthlyCamNNN"))}</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>Sublet</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>{booleanToYN(listing.subleaseFlg)}</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>Company</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>
                                {get(listing, "listingCompany", null) !== null
                                    ? get(listing, "listingCompany", "")
                                    : get(listing, "listingBrokers[0].brokerAgentKey.person.company.displayName")}
                            </span>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </div>
    );
};

export default withStyles(styles)(ListingDataPreview);
