import React from "react";
import { connect } from "react-redux";
import EVPDashboard from "./EVPDashboard";
import operations from "./ducks/operations";
import listingOperations from "../listing/ducks/operations";
import compOperations from "../comp/ducks/operations";
import searchOperations from "../search/ducks/operations";
import toUpper from "lodash/toUpper";
import { push } from "connected-react-router";

class EVPDashboardContainer extends React.Component {
    componentDidMount() {
        this.props.resetDashboardParams();
        this.props.getEVPDashboardData();
        this.props.getBrokerDashboardData();
    }

    render() {
        return this.props.auth.userProfile.role === "evp" ? <EVPDashboard {...this.props} /> : null;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        evpValues: state.dashboardReducer.evpValues,
        brokerValues: state.dashboardReducer.brokerValues,
        requestModal: state.listingReducer.requestModal,
        modalData: state.compReducer.modalData,
        errors: state.utilsReducer.errors,
        searchResults: state.utilsReducer.searchResults,
        autocompleteResults: state.searchReducer.autocompleteResults,
        dashboardParams: state.dashboardReducer.dashboardParams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEVPDashboardData: () => {
            dispatch(operations.getEVPDashboardData());
        },
        getBrokerDashboardData: () => {
            dispatch(operations.getBrokerDashboardData());
        },
        handleChangeRegion: (e) => {
            dispatch(operations.getEVPDashboardData("Closed", e.target.value));
        },
        toggleRequestModal: (spaceType, transactionType) => {
            dispatch(listingOperations.toggleRequestModal(spaceType, transactionType));
        },
        addNewDealFromOutsideCompModal: (transactionType, propertyType, newProperty) => {
            dispatch(compOperations.addOutsideComp(transactionType, propertyType, newProperty, "Draft"));
        },
        searchProperty: (term) => {
            dispatch(searchOperations.searchPropertyAutocomplete(term));
        },
        handleDisplayActiveListings: () => {
            dispatch(push("/listings?tab=broker_open"));
        },
        handleCompInput: (path, value) => {
            dispatch(compOperations.handleCompInput(path, value));
        },
        selectProperty: (property) => {
            dispatch(compOperations.selectPropertyFromAutocomplete(property));
        },
        handleDashboardInput: (e) => {
            dispatch(operations.handleEvpDashboardInput(e.target.id, e.target.value));
        },
        resetDashboardParams: () => {
            dispatch(operations.resetDashboardParams());
        },
        updateSearchTerm: (value, searchType) => {
            dispatch(searchOperations.updateFilter("searchTerm", value, searchType));
        },
        handleSearchSubmit: () => {
            dispatch(searchOperations.executeSearch("property_modal"));
            dispatch(searchOperations.storeSearchRun(true, toUpper("property_modal")));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EVPDashboardContainer);
