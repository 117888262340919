import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import set from "lodash/set";
import includes from "lodash/includes";
import schema from "./dealsTables";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import StatusChip from "../../../common/js/components/StatusChip";
import Button from "@material-ui/core/Button";
import { Sorty, Search, ExportIcon } from "../../../common/js/icons";
import BSInput from "../../../common/js/components/BSInput";
import OutsideCompModal from "../comp/OutsideCompModal";
import { aggregateBrokers, buildAddress } from "../utils/operations";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import ExcelExport from "../search/ExcelExport";
import { deal_search_export_column_mappings } from "../search/constants";
import Scroll from "react-scroll";

class BrokerDeals extends Component {
    render() {
        const {
            role,
            user,
            classes,
            columnToSort,
            deals,
            dealsExport,
            dealView,
            filterQuery,
            handleSelectNewTab,
            handleSort,
            searchProperty,
            sortDirection,
            addNewDealFromOutsideCompModal,
            elementsSaving,
            toggleReviewedBy,
            errors,
            getDealsForExport,
        } = this.props;

        let dealz = deals[dealView];
        const sortFunction = get(
            get(schema, `viewHeaders.${dealView}`).find((x) => x.prop === columnToSort),
            "sortFunction"
        );

        if (sortFunction && dealz) {
            dealz = dealz.map((deal) => {
                set(deal, columnToSort, sortFunction(get(deal, columnToSort)));
                return deal;
            });
        }

        const data =
            filterQuery && dealz
                ? orderBy(
                      dealz.filter((x) => schema.filterByQuery(x, schema.brokerColumnsToQuery, filterQuery)),
                      columnToSort,
                      sortDirection
                  )
                : columnToSort === "batchDate"
                ? orderBy(dealz, [(x) => x["batchDate"] || x["overrideBatchDate"]], sortDirection)
                : orderBy(dealz, columnToSort, sortDirection);

        let Element = Scroll.Element;

        return (
            <React.Fragment>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.pageToolbar}
                    spacing={16}
                >
                    <Grid item xs>
                        <h2 className="page-title">Deals</h2>
                        <div className="page-subtitle">{`Showing ${dealz ? dealz.length : 0} ${
                            dealz ? (dealz.length === 1 ? "deal" : "deals") : "deals"
                        } `}</div>
                    </Grid>
                    <Grid item className={classes.leftIcon}>
                        {includes(["portland_broker_admin_closed", "arizona_broker_admin_closed"], dealView) ? (
                            <Button
                                disabled={get(dealsExport, "exportInProgress", false) === true}
                                onClick={() => getDealsForExport(role, user)}
                                variant="outlined"
                                color="primary"
                            >
                                <ExportIcon />
                                {get(dealsExport, "exportInProgress", false) === true ? "In Progress" : "Export"}
                            </Button>
                        ) : (
                            ""
                        )}
                        {(get(dealsExport, "portland_broker_admin_closed", false) ||
                            get(dealsExport, "arizona_broker_admin_closed", false)) && (
                            <ExcelExport
                                data={
                                    get(dealsExport, "portland_broker_admin_closed", null) != null
                                        ? dealsExport.portland_broker_admin_closed
                                        : dealsExport.arizona_broker_admin_closed
                                }
                                sheetName="Closed Deals"
                                filename="deals_export"
                                fileExtension="xlsx"
                                columnDefinitions={deal_search_export_column_mappings}
                                {...this.props}
                            ></ExcelExport>
                        )}
                    </Grid>
                    <Grid item>
                        <OutsideCompModal
                            {...this.props}
                            errors={errors}
                            searchFunction={searchProperty}
                            addOutsideComp={addNewDealFromOutsideCompModal}
                            internal={true}
                        />
                    </Grid>
                </Grid>

                <Paper className={classes.paperSpacing}>
                    <Grid container justify="space-between">
                        <Grid item style={{ position: "relative" }}>
                            {includes(["broker", "evp", "debtEquity"], role) && (
                                <Tabs
                                    className={classes.tabs}
                                    value={this.props.dealView}
                                    onChange={this.props.switchVoucherView}
                                >
                                    <Tab className={classes.tab} value="broker_open" label="Open Deals" />
                                    <Tab className={classes.tab} value="broker_closed" label="Closed Deals" />
                                </Tabs>
                            )}
                            {includes(["portlandBrokerAdmin"], role) && (
                                <Tabs
                                    className={classes.tabs}
                                    value={this.props.dealView}
                                    onChange={this.props.switchVoucherView}
                                >
                                    <Tab className={classes.tab} value="broker_open" label="My Open Deals" />
                                    <Tab className={classes.tab} value="broker_closed" label="My Closed Deals" />
                                    <Tab
                                        className={classes.tab}
                                        value="portland_broker_admin_sale"
                                        label="Draft Oregon Sale Deals"
                                    />
                                    <Tab
                                        className={classes.tab}
                                        value="portland_broker_admin"
                                        label="Oregon Deal Review"
                                    />
                                    <Tab
                                        className={classes.tab}
                                        value="portland_broker_admin_closed"
                                        label="Oregon Closed Deals"
                                    />
                                </Tabs>
                            )}

                            {includes(["arizonaBrokerAdmin"], role) && (
                                <Tabs
                                    className={classes.tabs}
                                    value={this.props.dealView}
                                    onChange={this.props.switchVoucherView}
                                >
                                    <Tab className={classes.tab} value="broker_open" label="My Open Deals" />
                                    <Tab className={classes.tab} value="broker_closed" label="My Closed Deals" />
                                    <Tab
                                        className={classes.tab}
                                        value="arizona_broker_admin_review"
                                        label="Arizona Deal Review"
                                    />
                                    <Tab
                                        className={classes.tab}
                                        value="arizona_broker_admin_closed"
                                        label="Arizona Closed Deals"
                                    />
                                </Tabs>
                            )}
                        </Grid>

                        <Grid item className={classes.filterInput}>
                            <BSInput
                                extraInputStyles={{ backgroundColor: "#fafafa", border: "none", marginTop: "10px" }}
                                value={filterQuery}
                                placeholder="Search deals..."
                                startAdornment={<Search />}
                                startAdornmentStyles={{ color: "#777777" }}
                                onChange={(event) => {
                                    this.props.handleFilter(event.target.value);
                                }}
                                extraFormControlStyles={{ minWidth: "16em" }}
                            />
                        </Grid>
                    </Grid>

                    {get(elementsSaving, dealView) ? (
                        <div className={classes.loadingPlaceholder}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : (
                        <Element style={{ height: "675px", overflow: "scroll" }}>
                            <React.Fragment>
                                <Divider classes={{ root: classes.divider }} />
                                <Table>
                                    <colgroup>
                                        {schema.viewHeaders[dealView].map((x, i) =>
                                            x.width ? <col style={{ width: x.width }} /> : <col />
                                        )}
                                    </colgroup>
                                    <TableHead>
                                        <TableRow>
                                            {schema.viewHeaders[dealView].map((x, i) => (
                                                <TableCell classes={classes} numeric={x.numeric} key={`thc-${i}`}>
                                                    <div
                                                        style={{ alignItems: "center" }}
                                                        onClick={() => handleSort(x.sort ? x.sort : x.prop)}
                                                    >
                                                        <div style={{ display: "inline-block" }}>
                                                            <span className={classes.sortable}>{x.name}</span>
                                                            {x.name && (
                                                                <div style={{ display: "inline-block" }}>
                                                                    <Sorty />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    {dealz && dealz.length > 0 ? (
                                        <TableBody>
                                            {data.map((x, i) =>
                                                row(
                                                    x,
                                                    i,
                                                    dealView,
                                                    handleSelectNewTab,
                                                    classes,
                                                    elementsSaving,
                                                    toggleReviewedBy,
                                                    role
                                                )
                                            )}
                                        </TableBody>
                                    ) : (
                                        ""
                                    )}
                                </Table>
                                {dealz && dealz.length === 0 ? (
                                    <div className={classes.alternateContent}>No deals</div>
                                ) : (
                                    ""
                                )}
                            </React.Fragment>
                        </Element>
                    )}
                </Paper>
            </React.Fragment>
        );
    }
}

const row = (x, index, dealView, handleSelectNewTab, classes, elementsSaving, toggleReviewedBy, role) => {
    return elementsSaving[dealView] ? null : (
        <TableRow key={`tr-${index}`} selectable="false">
            {schema.viewHeaders[dealView].map((y, k) =>
                y.name === "Deal" ? (
                    <TableCell classes={classes} key={`trc-${k}`}>
                        {dealView === "portland_broker_admin_sale" ? (
                            <React.Fragment>
                                {includes(["Portland Terminated", "AZ Terminated"], get(x, "dealStatus")) &&
                                role === "broker" ? (
                                    <Link to={`/comp/${x.dealKey}`} className={classes.terminate}>
                                        {`Deal No. ${get(x, "dealKey")}`}
                                        <span className="l" style={{ fontWeight: 200 }}>
                                            {get(x, "listingName", null) !== null
                                                ? ` | ${get(x, "listingName")}`
                                                : get(x, "listing.listingName", null) !== null
                                                ? ` | ${get(x, "listing.listingName")}`
                                                : get(x, "consultingDealName", null) !== null
                                                ? ` | ${get(x, "consultingDealName")}`
                                                : ""}
                                        </span>
                                    </Link>
                                ) : (
                                    <Link to={`/comp/${x.dealKey}`} className={classes.lb}>
                                        {`Deal No. ${get(x, "dealKey")}`}
                                        <span className="l" style={{ fontWeight: 200 }}>
                                            {get(x, "listingName", null) !== null
                                                ? ` | ${get(x, "listingName")}`
                                                : get(x, "listing.listingName", null) !== null
                                                ? ` | ${get(x, "listing.listingName")}`
                                                : get(x, "consultingDealName", null) !== null
                                                ? ` | ${get(x, "consultingDealName")}`
                                                : ""}
                                        </span>
                                    </Link>
                                )}
                                <div>
                                    {get(x, "propertyAddress1", null) != null
                                        ? get(x, "propertyAddress1", null)
                                        : get(x, "propertyAddress", null) != null
                                        ? buildAddress(get(x, "propertyAddress", {}))
                                        : get(x, "listing.property.primaryAddress", null) != null
                                        ? buildAddress(get(x, "listing.property.primaryAddress", {}))
                                        : buildAddress(get(x, "property.primaryAddress", {}))}
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Link to={`/comp/${x.dealKey}`} className={classes.lb}></Link>
                                {includes(["Portland Terminated", "AZ Terminated"], get(x, "dealStatus")) &&
                                role === "broker" ? (
                                    <span
                                        className={classes.listingIdLinkTerminate}
                                        onClick={() => handleSelectNewTab(x, x.calculatedValueB)}
                                    >
                                        {`Deal No. ${get(x, "dealKey")}`}
                                        <span className="l" style={{ fontWeight: 200 }}>
                                            {get(x, "listingName", null) !== null
                                                ? ` | ${get(x, "listingName")}`
                                                : get(x, "listing.listingName", null) !== null
                                                ? ` | ${get(x, "listing.listingName")}`
                                                : get(x, "consultingDealName", null) !== null
                                                ? ` | ${get(x, "consultingDealName")}`
                                                : ""}
                                        </span>
                                    </span>
                                ) : (
                                    <span
                                        className={classes.listingIdLink}
                                        onClick={() => handleSelectNewTab(x, x.calculatedValueB)}
                                    >
                                        {`Deal No. ${get(x, "dealKey")}`}
                                        <span className="l" style={{ fontWeight: 200 }}>
                                            {get(x, "listingName", null) !== null
                                                ? ` | ${get(x, "listingName")}`
                                                : get(x, "listing.listingName", null) !== null
                                                ? ` | ${get(x, "listing.listingName")}`
                                                : get(x, "consultingDealName", null) !== null
                                                ? ` | ${get(x, "consultingDealName")}`
                                                : ""}
                                        </span>
                                    </span>
                                )}
                                <div>
                                    {get(x, "propertyAddress1", null) != null
                                        ? get(x, "propertyAddress1", null)
                                        : get(x, "propertyAddress", null) != null
                                        ? buildAddress(get(x, "propertyAddress", {}))
                                        : get(x, "listing.property.primaryAddress", null) != null
                                        ? buildAddress(get(x, "listing.property.primaryAddress", {}))
                                        : buildAddress(get(x, "property.primaryAddress", {}))}
                                </div>
                            </React.Fragment>
                        )}
                    </TableCell>
                ) : y.name === "Installments" ? (
                    <TableCell classes={classes} key={`trc-${k}`}>
                        {`${
                            get(x, `currentInstallmentNumber`, null) !== null
                                ? get(x, `currentInstallmentNumber`, null)
                                : get(x, `currentInstallment.installmentNumber`, 0)
                        } of ${
                            get(x, "totalInstallments", null) !== null
                                ? get(x, "totalInstallments")
                                : get(x, "installments", null) !== null
                                ? get(x, "installments").length
                                : ""
                        }`}
                    </TableCell>
                ) : y.name === "Next Payment Date" ? (
                    <TableCell classes={classes} key={`trc-${k}`}>
                        {get(x, `currentInstallment.installmentNumber`, get(x, "totalInstallments")) ===
                        get(x, "totalInstallments", null)
                            ? "Paid"
                            : get(x, y.prop)}
                    </TableCell>
                ) : y.prop === "brokerAgents" ? (
                    <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                        <span className={classes.tooltip} data-tip={get(x, "brokerNames", "")}>
                            {aggregateBrokers(get(x, "brokerAgents", []), "person")}
                        </span>
                        <ReactTooltip place="top" type="dark" effect="solid" />
                    </TableCell>
                ) : y.transform ? (
                    y.prop === "batchDate" ? (
                        <TableCell classes={classes} key={`trc-${k}`} numeric={y.numeric}>
                            {y.prop != null ? y.transform(get(x, y.prop)) : y.transform(get(x, "overrideBatchDate"))}
                        </TableCell>
                    ) : (
                        <TableCell classes={classes} key={`trc-${k}`} numeric={y.numeric}>
                            {y.transform(get(x, y.prop))}
                        </TableCell>
                    )
                ) : y.prop === "dealStatus" &&
                  includes(
                      ["Portland Termination Review Required", "AZ Termination Review Required"],
                      get(x, "dealStatus", null)
                  ) ? (
                    <TableCell classes={classes}>
                        <StatusChip status="Termination Review Required" />
                    </TableCell>
                ) : y.prop === "dealStatus" ? (
                    <TableCell classes={classes}>
                        <StatusChip
                            status={
                                includes(["Portland Terminated", "AZ Terminated"], get(x, "dealStatus", null))
                                    ? "Terminated"
                                    : get(x, "dealStatus", null)
                                    ? get(x, "dealStatus", null)
                                    : get(x, "dealStage.status", null)
                            }
                        />
                    </TableCell>
                ) : y.name === "Reviewed By" ? (
                    <TableCell classes={classes} key={`trc-${k}`} numeric={y.numeric}>
                        {get(x, y.prop) ? (
                            <React.Fragment>
                                <div style={{ fontWeight: 600 }}>{get(x, y.prop)}</div>
                                <span>{moment(get(x, "reviewedDate")).format("M-D-YYYY h:mm a")}</span>
                                <span
                                    onClick={() => toggleReviewedBy(true, get(x, "dealKey"), dealView)}
                                    className={classes.undoProcess}
                                >
                                    (undo)
                                </span>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <span
                                    className={classes.reviewLink}
                                    onClick={() => toggleReviewedBy(false, get(x, "dealKey"), dealView)}
                                >
                                    Review
                                </span>
                            </React.Fragment>
                        )}
                    </TableCell>
                ) : y.name === "Tenant/Buyer" && get(x, "transactionType", null).toLowerCase() === "consulting" ? (
                    <TableCell classes={classes} key={`trc-${k}`} numeric={y.numeric}>
                        {""}
                    </TableCell>
                ) : (
                    <TableCell classes={classes} key={`trc-${k}`} numeric={y.numeric}>
                        {get(x, y.prop)}
                    </TableCell>
                )
            )}
        </TableRow>
    );
};

const styles = (theme) => ({
    root: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.06) !important",
    },
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    tabOne: {
        marginRight: "20px",
    },
    connectorActive: {
        "& $connectorLine": {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        "& $connectorLine": {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        "& $connectorLine": {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create("border-color"),
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    listingIdLinkTerminate: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#D63636",
        fontWeight: 600,
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 700,
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
    menuRequestListing: {
        paddingBottom: "0px",
        "&>a": {
            paddingBottom: "0px !important",
        },
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    noData: {
        borderBottom: "none",
    },
    loadingPlaceholder: {
        marginTop: "3em",
        marginBottom: "1.5em",
        textAlign: "center",
        width: "100%",
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    divider: {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        margin: "0 -20px 0 -20px",
    },
    tabs: {
        borderBottom: "none",
        marginRight: "20px",
        position: "absolute",
        bottom: 0,
        width: "50em",
    },
    tab: {
        borderBottom: "none",
        marginRight: "20px",
    },
    paperSpacing: {
        padding: "10px 20px",
        fontSize: 14,
        marginBottom: "15px",
    },
    leftIcon: {
        marginLeft: "25px",
    },
    filterInput: {
        height: "65px",
        marginBottom: "0px",
        minWidth: "10%",
    },
    reviewLink: {
        "&:hover": {
            cursor: "pointer",
            color: "#2C99D2",
        },
        color: "#595959",
        textDecoration: "underline",
    },
    undoProcess: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#595959",
        fontSize: "80%",
        marginLeft: "3px",
    },
    terminate: {
        color: "#D63636",
    },
});

export default withStyles(styles)(BrokerDeals);
