import React, { Component } from "react";

import { connect } from "react-redux";
import operations from "./ducks/operations";
import searchOperations from "../search/ducks/operations";
import CompanyToolbar from "./CompanyToolbar";
import CompanyForm from "./CompanyForm";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AutosaveSuccess from "@material-ui/icons/CheckCircle";
import Divider from "@material-ui/core/Divider";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import get from "lodash/get";
import utils from "../utils";
import comments_operations from "../comments/ducks/operations";
import ReactGA from "react-ga4";

class CompanyContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            parentCompanyTypeFilter: ["BUSINESS"],
        };
    }

    componentDidMount() {
        console.log("hit componentDidMount in companyContainer---------");
        const { id } = this.props.match.params;
        if (id) {
            this.props.getCompany(id);
        } else {
            console.log("id not found in componentDidMount - this.props.match below");
            console.log(this.props.match);
            this.props.clearCompany();
        }
        ReactGA.send({ hitType: "pageview", page: "/company/*", title: `Company Admin: ${id}` });
    }

    componentWillUnmount() {}

    handleChangeParentCompanyType = (value) => {
        this.setState({ parentCompanyTypeFilter: value.split() });
    };

    render() {
        const { id } = this.props.match.params;

        return (
            <div>
                <CompanyToolbar {...this.props} id={id} />

                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <ExpansionPanel defaultExpanded>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <AutosaveSuccess className="waterloo" /> Company Information
                            </ExpansionPanelSummary>
                            <Divider />
                            <ExpansionPanelDetails>
                                <CompanyForm
                                    {...this.props}
                                    parentCompanyTypeFilter={this.state.parentCompanyTypeFilter}
                                    handleChangeParentCompanyType={(value) => this.handleChangeParentCompanyType(value)}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.authReducer.userProfile,
        companyType: get(state.adminReducer, "company.companyType", null),
        company: get(state.adminReducer, "company", null),
        autocompleteResults: get(state.searchReducer, "autocompleteResults", null),
        errors: state.utilsReducer.errors ? state.utilsReducer.errors : {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProperty: (propertyKey) => {
            dispatch(operations.getProperty(propertyKey));
        },

        handleSubmit: (save, callback) => {
            dispatch(utils.operations.toggleConfirm());
            // if (save)
            dispatch(operations.handleSubmitPropertyForm(save, callback));
        },
        handleApprovePropertyRequestAsDataEntry: (save, callback) => {
            dispatch(utils.operations.toggleConfirm());
            dispatch(operations.handleApprovePropertyRequestAsDataEntry(save, callback));
        },
        handleInputChange: (field, value) => {
            dispatch(operations.handleInputChange(field, value));
        },

        toggleSnackBar: (open, variant, message) => {
            dispatch(utils.operations.snackbar(open, variant, message));
        },
        toggleConfirm: (onSubmit) => {
            dispatch(utils.operations.toggleConfirm(onSubmit));
        },
        handleDocumentWarning: (open, proceed, docs) => {
            dispatch(operations.handleDocumentWarning(open, proceed, docs));
        },
        handleConfirmWarning: () => {
            dispatch(operations.handleDocumentWarning(false, true, []));
        },
        toggleComments: () => {
            dispatch(comments_operations.toggleComments());
        },
        handleAddArrayElement: (arrayPath) => {
            dispatch(operations.addArrayElement(arrayPath));
        },
        handleDeleteArrayElement: (arrayPath, index) => {
            dispatch(operations.deleteArrayElement(arrayPath, index));
        },
        handleUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.handleInputChange(arrayPath + "[" + index + "]." + field, value));
        },
        clearPropertyState: () => {
            dispatch(operations.clearPropertyDetailState());
        },
        handleChooseDisplayDeleteDialog: (isOpen, propertyKey) => {
            dispatch(operations.displayDeleteDialog(isOpen, propertyKey));
        },
        handleDeleteCompany: (companyKey) => {
            dispatch(operations.handleDeleteCompany(companyKey));
        },
        handleSaveCompany: () => {
            dispatch(operations.handleSaveCompany());
        },
        handleSaveCompanyAndExit: () => {
            dispatch(operations.handleSaveCompanyAndExit());
        },
        getCompany: (id) => {
            dispatch(operations.getCompany(id));
        },
        clearCompany: () => {
            dispatch(operations.clearCompany());
        },
        handleSearchParentCompany: (term, type) => {
            dispatch(searchOperations.searchParentCompany(term, type));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContainer);
