import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { docTypeIcons } from "./documentTypes";
import documentTypes from "./documentTypes";
import get from "lodash/get";

const styles = {
    attachmentCell: {
        fontSize: 14,
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    download: {
        cursor: "pointer",
        float: "right",
    },
    missingDocs: {
        color: "red",
    },
};

const DocumentPropertyManagementSnapshot = (props) => {
    const { viewDocument, classes, savedDocuments } = props;

    const [docMenuKey, setDocMenuKey] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenDocumentMenu = (docKey, anchorEl) => {
        setAnchorEl(anchorEl);
        setDocMenuKey(docKey);
    };

    const handlePreviewDocument = (url, docKey) => {
        setAnchorEl(null);
        viewDocument(url, docKey);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const columnDefs = [
        { display: "Name", prop: "mediaAssetName" },
        { display: "Document Type", prop: "mediaAssetType" },
        { display: "" },
    ];
    return (
        <Table>
            <TableHead>
                <TableRow>
                    {columnDefs.map((x, i) => (
                        <TableCell key={`doc-head-${i}`} classes={classes}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <span className={classes.sortable}>{x.display}</span>
                            </div>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(savedDocuments).map((x, i) => {
                    const document = savedDocuments[x];
                    const docType = get(document, "mediaAssetType", "");
                    const docTypeName = get(documentTypes.propertyManagement[docType], "display", "");
                    return (
                        <TableRow key={`tr-doc-${i}`}>
                            <TableCell className={classes.attachmentCell}>
                                {get(
                                    docTypeIcons,
                                    get(document, "mediaAssetName", " ").split(".").pop(),
                                    docTypeIcons["default"]
                                )()}
                                <span>{document["mediaAssetName"]}</span>
                            </TableCell>
                            <TableCell>
                                <div>{docTypeName}</div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    <IconButton
                                        disabled={false}
                                        aria-label="More"
                                        aria-owns={Boolean(document["anchorEl"]) ? `long-menu-${x}-${i}` : null}
                                        aria-haspopup="true"
                                        onClick={(e) => handleOpenDocumentMenu(document.mediaAssetKey, e.currentTarget)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={() => handleMenuClose()}
                                        PaperProps={{
                                            style: {
                                                width: 200,
                                            },
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() =>
                                                handlePreviewDocument(get(document, "url", null), docMenuKey)
                                            }
                                        >
                                            Open in a new tab
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default withStyles(styles)(DocumentPropertyManagementSnapshot);
