import React from "react";
import { cloneElement } from "react";
import { connect } from "react-redux";
import { PERMISSIONS } from "../constants";

const hasPermission = ({ permissions, scopes }) => {
    const scopesMap = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });
    return permissions.some((permission) => scopesMap[permission]);
};

const PermissionsGate = (props) => {
    const { role, children, scopes = [], errorProps, hide } = props;
    const permissions = PERMISSIONS[role];
    const permissionGranted = hasPermission({ permissions, scopes });

    if (!permissionGranted && hide) return null;
    if (!permissionGranted && !errorProps) return <span>DO NOT PASS</span>;
    if (!permissionGranted && errorProps) return cloneElement(children, { ...errorProps });

    return children;
};

const mapStateToProps = (state) => ({
    role: state.authReducer.userProfile.role,
});

export default connect(mapStateToProps)(PermissionsGate);
