import React from "react";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BSInput from "../../../common/js/components/BSInput";

class ReligiousForm extends React.Component {
    handleAttributeUpdate = (event) => {
        this.props.onChange(event.target.id, event.target.value);
    };

    render() {
        const { data, errors, disableInputs, context } = this.props;

        return (
            <div>
                {context === "listing" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="classroomCount"
                                    label="Classrooms"
                                    placeholder="Enter # Classrooms"
                                    errorText="ex. 12"
                                    error={errors.classroomCount}
                                    value={data.classroomCount}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="auditoriumSeatsCount"
                                    label="Auditorium Seats"
                                    placeholder="Enter # Auditorium Seats"
                                    errorText="ex. 120"
                                    error={errors.auditoriumSeatsCount}
                                    value={data.auditoriumSeatsCount}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.sharedFlg ? true : false}
                                            onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                            value="sharedFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Shared"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.kitchenFlg ? true : false}
                                            onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                            value="kitchenFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Kitchen"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.fellowshipHallFlg ? true : false}
                                            onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                            value="fellowshipHallFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Fellowship Hall"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.developmentSiteFlg ? true : false}
                                            onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                            value="developmentSiteFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Development Site"
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
            </div>
        );
    }
}

export default ReligiousForm;
