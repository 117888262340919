const LOAD_USER_ACCOUNT = "LOAD_USER_ACCOUNT";
const INPUT_CHANGE = "INPUT_CHANGE";
const ADD_ARRAY_ELEMENT = "ADD_ARRAY_ELEMENT_ADMIN";
const DELETE_ARRAY_ELEMENT = "DELETE_ARRAY_ELEMENT_ADMIN";
const SET_PROPERTY_ARRAY = "SET_PROPERTY_ARRAY";
const LOAD_BROKER = "LOAD_BROKER";
const LOAD_USERS_FOR_TAB = "LOAD_USERS_FOR_TAB";
const LOAD_COMPANIES_FOR_TAB = "LOAD_COMPANIES_FOR_TAB";
const SWITCH_TAB = "SWITCH_TAB";
const SWITCH_COMPANY_TAB = "SWITCH_COMPANY_TAB";
const FILTER_USERS = "FILTER_USERS";
const FILTER_COMPANIES = "FILTER_COMPANIES";
const SORT_USERS = "SORT_USERS";
const LOAD_LICENSES = "LOAD_LICENSES";
const LOAD_EXHIBIT_AS = "LOAD_EXHIBIT_AS";
const LOAD_COMPANY = "LOAD_COMPANY";
const SET_USER_SEARCH_TYPE = "SET_USER_SEARCH_TYPE";
const SET_COMPANY_SEARCH_TYPE = "SET_COMPANY_SEARCH_TYPE";
const SET_USER_OFFICE_HISTORY = "SET_USER_OFFICE_HISTORY";
const UPDATE_DELETE_DIALOG = "UPDATE_DELETE_DIALOG";
const CLEAR_COMPANY = "CLEAR_COMPANY";
const UPDATE_SELECTED_APPROVED_COMPANIES = "UPDATE_SELECTED_APPROVED_COMPANIES";
const UPDATE_SELECTED_UNAPPROVED_COMPANIES = "UPDATE_SELECTED_UNAPPROVED_COMPANIES";
const CLEAR_CHECKBOXES = "CLEAR_CHECKBOXES";

export default {
    LOAD_USER_ACCOUNT,
    INPUT_CHANGE,
    ADD_ARRAY_ELEMENT,
    DELETE_ARRAY_ELEMENT,
    SET_PROPERTY_ARRAY,
    LOAD_BROKER,
    LOAD_USERS_FOR_TAB,
    LOAD_COMPANIES_FOR_TAB,
    SWITCH_TAB,
    SWITCH_COMPANY_TAB,
    FILTER_USERS,
    FILTER_COMPANIES,
    SORT_USERS,
    LOAD_LICENSES,
    LOAD_EXHIBIT_AS,
    LOAD_COMPANY,
    SET_USER_SEARCH_TYPE,
    SET_COMPANY_SEARCH_TYPE,
    SET_USER_OFFICE_HISTORY,
    UPDATE_DELETE_DIALOG,
    CLEAR_COMPANY,
    UPDATE_SELECTED_APPROVED_COMPANIES,
    UPDATE_SELECTED_UNAPPROVED_COMPANIES,
    CLEAR_CHECKBOXES,
};
