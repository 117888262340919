import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import get from "lodash/get";
import includes from "lodash/includes";
import "./Listings.css";
import schema from "./reviewListingsTables";
import GoogleMap from "../../../common/js/components/GoogleMaps";
import { Sorty } from "../../../common/js/icons";
import { NO_CONTENT_PLACEHOLDER } from "./constants";
import { formatPrice, markerLabelContent } from "../utils/operations";

const styles = (theme) => ({
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    lb: {
        textDecoration: "none",
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: "600",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    gridWrapper: {
        fontFamily: "IBM Plex Sans, sans-serif",
        marginRight: "10px",
        overflowX: "auto",
    },
    gridHeaderRow: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid rgb(212, 212, 212)",
        color: "#9E9E9E",
        fontSize: "12px",
        fontWeight: "bold",
        padding: "1.5em 0",
        overflow: "hidden",
        scrollbarGutter: "stable",
    },
    gridBodyRow: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid rgb(212, 212, 212)",
        fontSize: "13px",
        height: "fit-content",
        padding: "0.75em 0",
    },
    gridBodyWrapper: {
        display: "block",
        maxHeight: "50vh",
        minHeight: "425px",
        overflowY: "auto",
        scrollbarGutter: "stable",
    },
});

class ListingsWithMap extends Component {
    render() {
        const {
            selectedListings,
            classes,
            listingTab,
            handleGridSort,
            isLoading,
            searchResponse,
            loadingSearch,
            handleDragEnd,
            handleSelectAll,
            handleCheckboxClick,
            selectAll,
            searchRun,
            searchCleared,
            updateSearchAreaFilter,
            handleSearchCleared,
            mapCrumbCleared,
            searchPolygon,
            updateSearchPolygon,
            saveMapSearchSettings,
            mapSearchSettings,
            selectedListingsGroup,
            selectAllGroup,
            handleSelectAllGroup,
        } = this.props;

        const listings = get(this.props.listings, listingTab, []);
        const hasListings = Object.keys(listings).length > 0;
        const disableDraw = listingTab === "listings_group" ? true : false;
        const headers = schema.viewHeaders["all_km_listings_with_map"];
        const loadRows = !loadingSearch && !searchCleared && hasListings;

        const getListStyle = (isDraggingOver) => ({
            padding: "",
        });

        return (
            <div>
                <Grid container spacing={0}>
                    <Grid item xs={5} className={classes.gridWrapper}>
                        <div style={{ minWidth: "500px" }}>
                            {/* Grid Header */}
                            <Grid container spacing={12} className={classes.gridHeaderRow}>
                                {headers.map((header, i) => {
                                    return (
                                        <Grid
                                            item
                                            xs={header.name === "Address" ? 4 : header.name === "SQFT" ? 2 : 3}
                                            key={`gh-c${i}`}
                                            numeric={header.numeric}
                                            onClick={() => handleGridSort(header.name, listingTab)}
                                            style={{ paddingRight: "7px" }}
                                        >
                                            {header.name === "Property" ? (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    {listingTab === "listings_group" ? (
                                                        <Checkbox
                                                            className={
                                                                selectAllGroup
                                                                    ? "listingCheckboxAllSelected"
                                                                    : "listingCheckboxAll"
                                                            }
                                                            checked={selectAllGroup}
                                                            value={selectAllGroup}
                                                            onChange={() => handleSelectAllGroup()}
                                                            color="primary"
                                                        />
                                                    ) : (
                                                        <Checkbox
                                                            className={
                                                                selectAll
                                                                    ? "listingCheckboxAllSelected"
                                                                    : "listingCheckboxAll"
                                                            }
                                                            checked={selectAll}
                                                            value={selectAll}
                                                            onChange={() => handleSelectAll()}
                                                            color="primary"
                                                        />
                                                    )}
                                                    <span style={{ paddingLeft: "7px" }}>
                                                        {header.name.toUpperCase()}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ paddingRight: "7px" }}>
                                                        {header.name.toUpperCase()}
                                                    </span>
                                                    <Sorty />
                                                </div>
                                            )}
                                        </Grid>
                                    );
                                })}
                            </Grid>

                            {/* Grid Body */}
                            <DragDropContext onDragEnd={(result) => handleDragEnd(result, listings, listingTab)}>
                                <Droppable droppableId="droppable" style={{ maxWidth: "100%" }}>
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            <Grid container spacing={12} className={classes.gridBodyWrapper}>
                                                {loadRows
                                                    ? listings.map((listing, i) =>
                                                          row(
                                                              listing,
                                                              i,
                                                              headers,
                                                              listingTab,
                                                              classes,
                                                              handleCheckboxClick,
                                                              selectedListings,
                                                              selectedListingsGroup
                                                          )
                                                      )
                                                    : null}

                                                {/* Circular progress wheel, no content notifications, etc. */}
                                                {alternateContent(
                                                    listingTab,
                                                    loadingSearch,
                                                    hasListings,
                                                    searchRun,
                                                    classes
                                                )}
                                            </Grid>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </Grid>

                    <Grid xs={7} style={{ flexBasis: "auto", flexGrow: 1, marginTop: "10px" }}>
                        <GoogleMap
                            disableDraw={disableDraw}
                            tab={listingTab === "listings_group" ? "group" : "search"}
                            places={
                                listingTab === "all_km_listings"
                                    ? searchResponse
                                        ? searchResponse.results
                                        : []
                                    : listings
                                    ? listings
                                    : []
                            }
                            requestId={searchResponse && searchResponse.requestId ? searchResponse.requestId : null}
                            isLoading={isLoading}
                            searchCleared={searchCleared}
                            searchRun={searchRun}
                            searchByArea={updateSearchAreaFilter}
                            markerLabelContentFunc={markerLabelContent}
                            handleSearchCleared={handleSearchCleared}
                            mapCrumbCleared={mapCrumbCleared}
                            searchPolygon={searchPolygon}
                            updateSearchPolygon={updateSearchPolygon}
                            saveMapSearchSettings={saveMapSearchSettings}
                            mapSearchSettings={mapSearchSettings}
                            savedMapCoords={this.props.savedMapCoords}
                            isListing={true}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const row = (
    listing,
    i,
    headers,
    listingTab,
    classes,
    handleCheckboxClick,
    selectedListings,
    selectedListingsGroup
) => {
    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging ? "#C5C5C5" : "",
        userSelect: "none",
        width: "100%",
        ...draggableStyle,
    });

    return (
        <Draggable
            draggableId={listing.listingKey}
            index={i}
            style={{ width: "100%", height: "fit-content" }}
            key={`gb-r${i}`}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <Grid container spacing={12} className={classes.gridBodyRow}>
                        {headers.map((header, j) => {
                            const propertyPhoto =
                                get(listing, "listing_photo", null) !== null
                                    ? get(listing, "listing_photo", null)
                                    : get(listing, "propertyPhotoUrl", null);
                            const isKmManaged = get(listing, "property.kmManagedFlg", false);

                            return (
                                <Grid
                                    item
                                    key={`gb-r${i}-c${j}`}
                                    xs={header.name === "Address" ? 4 : header.name === "SQFT" ? 2 : 3}
                                    style={{ paddingRight: "7px" }}
                                >
                                    {
                                        /* --- Property --- */
                                        header.name === "Property" ? (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                {listingTab === "listings_group" ? (
                                                    <Checkbox
                                                        className={
                                                            selectedListingsGroup.get(listing.listingKey) === true
                                                                ? "listingCheckboxAllSelected"
                                                                : "listingCheckboxAll"
                                                        }
                                                        checked={selectedListingsGroup.get(listing.listingKey) === true}
                                                        value={selectedListingsGroup.get(listing.listingKey) === true}
                                                        onChange={() =>
                                                            handleCheckboxClick(listing.listingKey, listingTab)
                                                        }
                                                        color="primary"
                                                    />
                                                ) : (
                                                    <Checkbox
                                                        className={
                                                            selectedListings.get(listing.listingKey) === true
                                                                ? "listingCheckboxAllSelected"
                                                                : "listingCheckboxAll"
                                                        }
                                                        checked={selectedListings.get(listing.listingKey) === true}
                                                        value={selectedListings.get(listing.listingKey) === true}
                                                        onChange={() =>
                                                            handleCheckboxClick(listing.listingKey, listingTab)
                                                        }
                                                        color="primary"
                                                    />
                                                )}
                                                <div
                                                    className="propertySmallImageContainer"
                                                    style={{
                                                        backgroundImage: `url(${propertyPhoto})`,
                                                        marginLeft: "7px",
                                                        width: "85px",
                                                        minWidth: "85px",
                                                        height: "85px",
                                                    }}
                                                >
                                                    {isKmManaged && <span className="listingTag">KM Managed</span>}
                                                </div>
                                            </div>
                                        ) : /* --- Address --- */
                                        header.name === "Address" ? (
                                            <div>
                                                <Link
                                                    to={`/listing/${listing.listingKey}`}
                                                    target="_blank"
                                                    className={classes.lb}
                                                >
                                                    <span
                                                        className={classes.listingIdLink}
                                                        style={
                                                            listing.expired ? { color: "red" } : { color: "#2C99D2" }
                                                        }
                                                    >
                                                        {get(listing, "listingName").trim()}
                                                    </span>
                                                </Link>
                                                <div>{`${get(listing, "property.primaryAddress.address1", {})}`}</div>
                                                <div>{`${get(listing, "property.primaryAddress.city.city", {})}, ${get(
                                                    listing,
                                                    "property.primaryAddress.city.state.stateCode",
                                                    {}
                                                )}, ${get(
                                                    listing,
                                                    "property.primaryAddress.zipCode.zipCode",
                                                    {}
                                                )}`}</div>
                                            </div>
                                        ) : /* --- Rate / Price --- */
                                        header.name === "Rate/Price" ? (
                                            <div numeric={header.numeric}>
                                                {(get(listing, header.prop) === null ||
                                                    get(listing, header.prop) === 0) &&
                                                (get(listing, "listPrice", null) === null ||
                                                    get(listing, "listPrice", null) === 0) ? (
                                                    "Call Broker"
                                                ) : get(listing, "listingType").toLowerCase() === "lease" ? (
                                                    includes(
                                                        ["Office", "Retail", "Healthcare", "Life Science"],
                                                        get(listing, "useType")
                                                    ) &&
                                                    (includes(
                                                        ["WA", "OR", "AZ"],
                                                        get(listing, "property.primaryAddress.city.state.stateCode")
                                                    ) ||
                                                        get(listing, "property.primaryAddress.city.city", {}) ===
                                                            "San Francisco") ? (
                                                        `$${get(listing, "baseRentPerYear").toFixed(2)} /SF/YR`
                                                    ) : (
                                                        `$${get(listing, header.prop).toFixed(2)} /SF/MTH`
                                                    )
                                                ) : (
                                                    <div>{`$${
                                                        get(listing, "listPrice", null) !== null
                                                            ? formatPrice(get(listing, "listPrice", 0))
                                                            : NO_CONTENT_PLACEHOLDER
                                                    }`}</div>
                                                )}
                                            </div>
                                        ) : /* --- Square Feet --- */
                                        header.name === "SQFT" ? (
                                            <div numeric={header.numeric}>
                                                {get(listing, header.prop) !== null
                                                    ? header.transform(get(listing, header.prop))
                                                    : NO_CONTENT_PLACEHOLDER}
                                            </div>
                                        ) : null
                                    }
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            )}
        </Draggable>
    );
};

const alternateContent = (listingTab, loadingSearch, hasListings, searchRun, classes) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                {listingTab === "all_km_listings" &&
                    (loadingSearch ? (
                        <div className={classes.alternateContent}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : !hasListings ? (
                        searchRun ? (
                            <div className={classes.alternateContent}>No Listings</div>
                        ) : (
                            <div className={classes.alternateContent}>Please apply search criteria</div>
                        )
                    ) : null)}

                {listingTab === "listings_group" &&
                    (loadingSearch ? (
                        <div className={classes.alternateContent}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : !hasListings ? (
                        <div className={classes.alternateContent}>No Listings</div>
                    ) : null)}
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(ListingsWithMap);
