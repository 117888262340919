import get from "lodash/get";
import each from "lodash/each";
import { user_role_type_display_name } from "../auth/constants";

const viewHeaders = {
    default_headers: [
        {
            name: "NAME",
            prop: "name",
            type: "plaintext",
        },
        {
            name: "User Type",
            prop: "role",
            type: "plaintext",
            transform: (user) => {
                return get(user_role_type_display_name, get(user, "role", "ROLE_GENERAL_USER"), "");
            },
        },
        {
            name: "Office",
            prop: "officeMapping",
            type: "plaintext",
        },
        {
            name: "Phone Number",
            prop: "phoneNumber",
            type: "plaintext",
        },
        {
            name: "Email",
            prop: "email",
            type: "plaintext",
        },
        {
            name: "Office History",
            prop: "",
            type: "plaintext",
            width: "180px",
        },
    ],
    userOfficeHistory: [
        {
            name: "Office",
            prop: "currentOfficeName",
            type: "plaintext",
        },
        {
            name: "Start Date",
            prop: "officeStartDate",
            type: "plaintext",
        },
        {
            name: "End Date",
            prop: "officeEndDate",
            type: "plaintext",
        },
    ],
};

const columnsToQueryFilter = ["name", "role", "roleDescription", "officeMapping", "email", "phoneNumber"];

const filterByQuery = (data, columnsToQuery, query) => {
    let included = false;
    each(columnsToQuery, (column) => {
        if (get(data, column)) {
            if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                included = true;
            }
        }
    });
    return included;
};

export default {
    viewHeaders,
    columnsToQueryFilter,
    filterByQuery,
};
