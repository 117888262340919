import React from "react";
import { connect } from "react-redux";
import BrokerDashboard from "./BrokerDashboard";
import operations from "./ducks/operations";
import listingOperations from "../listing/ducks/operations";
import compOperations from "../comp/ducks/operations";
import searchOperations from "../search/ducks/operations";
import toUpper from "lodash/toUpper";
import { push } from "connected-react-router";

class BrokerDashboardContainer extends React.Component {
    componentDidMount() {
        this.props.resetDashboardParams();
        this.props.getBrokerDashboardData();
    }

    render() {
        return <BrokerDashboard {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        role: state.authReducer.userProfile.role,
        userProfile: state.authReducer.userProfile,
        brokerValues: state.dashboardReducer.brokerValues,
        requestModal: state.listingReducer.requestModal,
        modalData: state.compReducer.modalData,
        errors: state.utilsReducer.errors,
        searchResults: state.utilsReducer.searchResults,
        autocompleteResults: state.searchReducer.autocompleteResults,
        dashboardParams: state.dashboardReducer.dashboardParams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBrokerDashboardData: () => {
            dispatch(operations.getBrokerDashboardData());
        },

        closeDeal: (listing) => {
            dispatch(listingOperations.closeDeal(listing));
        },
        toggleRequestModal: (spaceType, transactionType) => {
            dispatch(listingOperations.toggleRequestModal(spaceType, transactionType));
        },
        addNewDealFromOutsideCompModal: (transactionType, propertyType, newProperty) => {
            dispatch(compOperations.addOutsideComp(transactionType, propertyType, newProperty, "Draft"));
        },
        searchProperty: (term) => {
            dispatch(searchOperations.searchPropertyAutocomplete(term));
        },
        handleDisplayActiveListings: () => {
            dispatch(push("/listings?tab=broker_open"));
        },
        handleCompInput: (path, value) => {
            dispatch(compOperations.handleCompInput(path, value));
        },
        selectProperty: (property) => {
            dispatch(compOperations.selectPropertyFromAutocomplete(property));
        },
        handleDashboardInput: (e) => {
            dispatch(operations.handleDashboardInput(e.target.id, e.target.value));
        },
        resetDashboardParams: () => {
            dispatch(operations.resetDashboardParams());
        },
        updateSearchTerm: (value, searchType) => {
            dispatch(searchOperations.updateFilter("searchTerm", value, searchType));
        },
        handleSearchSubmit: () => {
            dispatch(searchOperations.executeSearch("property_modal"));
            dispatch(searchOperations.storeSearchRun(true, toUpper("property_modal")));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrokerDashboardContainer);
