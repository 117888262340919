import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ResearchSnapshotRow from "./ResearchSnapshotRow";
import includes from "lodash/includes";

const ResearchSnapshotTable = (props) => {
    const { snapshots, classes, role, editResponse } = props;
    const columns = [
        { header: "Notes", field: "notes", group: "notes", editable: false },
        { header: "Year", field: "year", group: "default", editable: false },
        { header: "Quarter", field: "quarter", group: "default", editable: false },
        { header: "Status", field: "buildingStatus", group: "default", editable: false },
        { header: "Class", field: "buildingClass", group: "default", editable: false },
        { header: "Sub-Type", field: "subtype", group: "specialtyType", editable: false },
        { header: "Market", field: "market", group: "default", editable: false },
        { header: "Submarket", field: "submarket", group: "default", editable: false },
        { header: "Micro Market", field: "micromarket", group: "default", editable: false },
        { header: "RBA (SF)", field: "totalBuildingSf", group: "sqFt", editable: true },
        { header: "Avail %", field: "availablePercentage", group: "percentage", editable: true },
        { header: "Total Avail (SF)", field: "totalSfAvailable", group: "sqFt", editable: true },
        { header: "Direct Avail (SF)", field: "directSfAvailable", group: "sqFt", editable: true },
        { header: "Sublease Avail (SF)", field: "slAvailable", group: "sqFt", editable: true },
        { header: "Vacant %", field: "vacantPercentage", group: "percentage", editable: true },
        { header: "Total Vacant (SF)", field: "totalVacantSf", group: "sqFt", editable: true },
        { header: "Direct Vacant (SF)", field: "directVacant", group: "sqFt", editable: true },
        { header: "Sublease Vacant (SF)", field: "subleaseVacant", group: "sqFt", editable: true },
        { header: "Lease Rate", field: "leaseRate", group: "lease", editable: true },
        { header: "Net Absorption (SF)", field: "netAbsorption", group: "sqFt", editable: true },
        { header: "Edit", field: "edit", group: "edit", editable: false },
    ];
    return (
        <div className={classes.plainGrid}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <div className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column, i) => {
                                    const { header, field } = column;
                                    return field === "edit" &&
                                        !includes(["research", "researchAdmin", "dataAdmin"], role) ? null : (
                                        <TableCell
                                            key={column + i}
                                            align={"center"}
                                            className={classes.cellHeader}
                                            style={{ minWidth: "fit-content" }}
                                        >
                                            {column.case === "sqFt" ? `${header} (SF)` : header}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {snapshots.map((snapshot) => (
                                <ResearchSnapshotRow
                                    role={role}
                                    snapshot={snapshot}
                                    columns={columns}
                                    editResponse={editResponse}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </div>
    );
};

const styles = (theme) => ({
    plainGrid: {
        margin: "10px 0px 5px 0px",
    },
    tableContainer: {
        overflowX: "auto",
        width: "100%",
    },
    cellHeader: {
        backgroundColor: "#257692",
        color: "#ffffff",
        whiteSpace: "nowrap",
    },
    tableRow: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#e7e6e6",
        },
    },
    tableCell: {
        whiteSpace: "nowrap",
    },
});

export default withStyles(styles)(ResearchSnapshotTable);
