import React from "react";
import BSInput from "../../../common/js/components/BSInput";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Delete from "../../../../node_modules/@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import BSSelect from "../../../common/js/components/BSSelect";
import { us_states_options } from "../utils/constants";
import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
    iconOnly: {
        marginLeft: 0,
        marginBottom: "10px",
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
});
class LicenseDetail extends React.Component {
    render() {
        const { classes, element, elementIndex, errors, handleUpdateArrayElement, disableInputs, onDelete } =
            this.props;

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="licenseName"
                            label="LICENSE NAME"
                            placeholder="Enter license name"
                            value={element ? element.licenseName : null}
                            error={get(errors, `licenses.${elementIndex}.licenseName`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("licenses", elementIndex, e.target.id, e.target.value)
                            }
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <BSInput
                            id="licenseNumber"
                            label="LICENSE NUMBER"
                            placeholder="Enter license number"
                            value={element ? element.licenseNumber : null}
                            error={get(errors, `licenses.${elementIndex}.licenseNumber`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("licenses", elementIndex, e.target.id, e.target.value)
                            }
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {disableInputs !== true && (
                            <Button
                                style={{ marginTop: "18px", color: "#9e9e9e" }}
                                onClick={() => onDelete(elementIndex)}
                                className={classes.iconOnly}
                            >
                                <Delete />
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="licenseeName"
                            label="LICENSEE NAME"
                            placeholder="Enter licensee name"
                            value={element ? element.licenseeName : null}
                            error={get(errors, `licenses.${elementIndex}.licenseeName`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("licenses", elementIndex, e.target.id, e.target.value)
                            }
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="licenseType"
                            label="LICENSE TYPE"
                            placeholder="Enter license type"
                            value={element ? element.licenseType : null}
                            error={get(errors, `licenses.${elementIndex}.licenseType`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("licenses", elementIndex, e.target.id, e.target.value)
                            }
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={8}>
                    <Grid item xs={6}>
                        <BSSelect
                            id="licenseStateCode"
                            label="STATE"
                            value={element ? element.licenseStateCode : null}
                            error={get(errors, `licenses.${elementIndex}.licenseStateCode`, null)}
                            options={us_states_options}
                            onChange={(e) =>
                                handleUpdateArrayElement("licenses", elementIndex, e.target.id, e.target.value)
                            }
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="expirationDate"
                            label="EXPIRATION DATE (OPTIONAL)"
                            placeholder="Enter expiration date"
                            value={element ? element.expirationDate : null}
                            error={get(errors, `licenses.${elementIndex}.expirationDate`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("licenses", elementIndex, e.target.id, e.target.value)
                            }
                            disabled={disableInputs === true}
                            type="date"
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="center" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="licenseUrl"
                            label="LICENSE URL"
                            placeholder="Enter license url"
                            value={element ? element.licenseUrl : null}
                            error={get(errors, `licenses.${elementIndex}.licenseUrl`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("licenses", elementIndex, e.target.id, e.target.value)
                            }
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="center" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="comments"
                            label="COMMENTS"
                            placeholder="Enter comments"
                            value={element ? element.comments : null}
                            error={get(errors, `licenses.${elementIndex}.comments`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("licenses", elementIndex, e.target.id, e.target.value)
                            }
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>

                <Divider />
                <br />
                <br />
                {/*<Grid item xs={6}>*/}
                {/*{disableInputs !== true &&*/}
                {/*<Button onClick={() => onDelete(elementIndex)} className={classes.iconOnly}><Delete /></Button>*/}
                {/*}*/}
                {/*</Grid>*/}
            </div>
        );
    }
}

export default withStyles(styles)(LicenseDetail);
