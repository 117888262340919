/**
 * @deprecated Do not use deprecated constants moving forward.  Use
 *  ContactContainer and ContactForm moving forward. The code above still being used
 * in some code until it can be changed to use ContactContainer and ContactForm
 */

/**
 * @deprecated see notes
 */
const UPDATE_CONTACT = "UPDATE_CONTACT";
/**
 * @deprecated see notes
 */
const ADD_CONTACT = "ADD_CONTACT";
/**
 * @deprecated see notes
 */
const UPDATE_CONTACT_COMPANY = "UPDATE_CONTACT_COMPANY";
/**
 * @deprecated see notes
 */
const DELETE_CONTACT = "DELETE_CONTACT";
/**
 * @deprecated see notes
 */
const INIT_CONTACTS = "INIT_CONTACTS";

const ADD_CONTACT_ELEMENT = "ADD_CONTACT_ELEMENT";
const UPDATE_CONTACT_ELEMENT = "UPDATE_CONTACT_ELEMENT";
const DELETE_CONTACT_ELEMENT = "DELETE_CONTACT_ELEMENT";
const INITIALIZE_CONTACTS = "INITIALIZE_CONTACTS";
const UPDATE_CONTACT_ELEMENT_COMPANY = "UPDATE_CONTACT_ELEMENT_COMPANY";
export default {
    ADD_CONTACT,
    UPDATE_CONTACT,
    DELETE_CONTACT,
    UPDATE_CONTACT_COMPANY,
    INIT_CONTACTS,
    ADD_CONTACT_ELEMENT,
    UPDATE_CONTACT_ELEMENT,
    DELETE_CONTACT_ELEMENT,
    INITIALIZE_CONTACTS,
    UPDATE_CONTACT_ELEMENT_COMPANY,
};
