import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import schema from "./dealsTables";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import StatusChip from "../../../common/js/components/StatusChip";
import includes from "lodash/includes";
import BSInput from "../../../common/js/components/BSInput";
import { Sorty, Search } from "../../../common/js/icons";
import "../../styles/voucherDashboard.css";
import { aggregateBrokersByPersonObjectForTooltip, buildAddress } from "../utils/operations";
import ReactTooltip from "react-tooltip";
import Scroll from "react-scroll";

class DataAdminDeals extends Component {
    render() {
        const {
            classes,
            columnToSort,
            deals,
            dealView,
            filterQuery,
            handleSelect,
            handleSort,
            elementsSaving,
            sortDirection,
        } = this.props;

        const dealz = deals[dealView];

        const data = orderBy(
            filterQuery && dealz
                ? dealz.filter((x) => schema.filterByQuery(x, schema.dataAdminColumnsToQuery, filterQuery))
                : dealz,
            columnToSort,
            sortDirection
        );

        let Element = Scroll.Element;

        return (
            <React.Fragment>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.pageToolbar}
                    spacing={16}
                >
                    <Grid item xs>
                        <h2 className="page-title">Deals</h2>
                        <div className="page-subtitle">{`Showing ${dealz ? dealz.length : 0} ${
                            dealz ? (dealz.length === 1 ? "deal" : "deals") : "deals"
                        } `}</div>
                    </Grid>
                </Grid>

                <Paper className={classes.paperSpacing}>
                    <Grid container justify="space-between">
                        <Grid item style={{ position: "relative" }}>
                            <Tabs
                                className={classes.tabs}
                                value={this.props.dealView}
                                onChange={this.props.switchVoucherView}
                                indicatorColor="primary"
                            >
                                <Tab
                                    className={classes.tab}
                                    value="data_admin_needs_final_review"
                                    label="Needs Final Review"
                                />
                                <Tab className={classes.tab} value="data_admin_closed" label="Closed Deals" />
                            </Tabs>
                        </Grid>

                        <Grid item className={classes.filterInput}>
                            <BSInput
                                extraInputStyles={{ backgroundColor: "#fafafa", border: "none", marginTop: "10px" }}
                                value={filterQuery}
                                placeholder="Search deals..."
                                startAdornment={<Search />}
                                startAdornmentStyles={{ color: "#777777" }}
                                onChange={(event) => {
                                    this.props.handleFilter(event.target.value);
                                }}
                                extraFormControlStyles={{ minWidth: "16em" }}
                            />
                        </Grid>
                    </Grid>

                    {get(elementsSaving, dealView) ? (
                        <div className={classes.loadingPlaceholder}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : (
                        <Element style={{ height: "675px", overflow: "scroll" }}>
                            <React.Fragment>
                                <Divider classes={{ root: classes.divider }} />
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {schema.viewHeaders[dealView].map((x, i) => (
                                                <TableCell classes={classes} numeric={x.numeric} key={`thc-${i}`}>
                                                    <div
                                                        style={{
                                                            alignItems: "center",
                                                        }}
                                                        onClick={() => handleSort(x.sort ? x.sort : x.prop)}
                                                    >
                                                        <div style={{ display: "inline-block" }}>
                                                            <span className={classes.sortable}>{x.name}</span>
                                                            {x.name && (
                                                                <div style={{ display: "inline-block" }}>
                                                                    <Sorty />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            ))}
                                            <TableCell classes={classes}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {dealz && dealz.length > 0 ? (
                                        <TableBody>
                                            {data.map((x, i) =>
                                                row(x, i, dealView, handleSelect, classes, elementsSaving)
                                            )}
                                        </TableBody>
                                    ) : (
                                        <TableCell colSpan={8} className={classes.noData}>
                                            <div className={classes.alternateContent}>No deals</div>
                                        </TableCell>
                                    )}
                                </Table>
                            </React.Fragment>
                        </Element>
                    )}
                </Paper>
            </React.Fragment>
        );
    }
}

const row = (x, index, dealView, handleSelect, classes, elementsSaving) => {
    return elementsSaving[dealView] ? null : (
        <TableRow key={`tr-${index}`} selectable="false">
            {schema.viewHeaders[dealView].map((y, k) =>
                y.name === "Deal" ? (
                    <TableCell classes={classes} key={`trc-${k}`}>
                        <span
                            className={
                                includes(["Portland Terminated", "AZ Terminated"], get(x, "dealStatus", null))
                                    ? classes.listingIdLinkTerminate
                                    : classes.listingIdLink
                            }
                            onClick={() => handleSelect(x)}
                        >
                            {get(x, "dealKey")}
                            <span style={{ fontWeight: 200 }} onClick={() => handleSelect(x)}>
                                {get(x, "listing.listingName", null) !== null
                                    ? ` | ${get(x, "listing.listingName")}`
                                    : get(x, "consultingDealName", null) !== null
                                    ? ` | ${get(x, "consultingDealName")}`
                                    : ""}
                            </span>
                        </span>
                        <div>{get(x, y.prop)}</div>
                        <div>
                            {get(x, "transactionType", null) !== "Consulting"
                                ? buildAddress(get(x, "listing.property.primaryAddress", {}))
                                : buildAddress(get(x, "consultingDealAddress", {}))}
                        </div>
                    </TableCell>
                ) : y.name === "Installments" ? (
                    <TableCell classes={classes} key={`trc-${k}`}>
                        {`${get(x, `currentInstallment.installmentNumber`)} of ${
                            get(x, "totalInstallments", null) !== null
                                ? get(x, "totalInstallments")
                                : get(x, "installments", null) !== null
                                ? get(x, "installments").length
                                : ""
                        }`}
                    </TableCell>
                ) : y.name === "Next Payment Date" ? (
                    <TableCell classes={classes} key={`trc-${k}`}>
                        {get(x, `currentInstallment.installmentNumber`) === get(x, "totalInstallments")
                            ? "Paid"
                            : get(x, y.prop)}
                    </TableCell>
                ) : y.name === "Brokers" ? (
                    <TableCell classes={classes} key={`trc-${k}`}>
                        <span
                            className={classes.tooltip}
                            data-tip={aggregateBrokersByPersonObjectForTooltip(get(x, y.prop), "person")}
                        >
                            {y.transform(get(x, y.prop))}
                        </span>
                        <ReactTooltip place="top" type="dark" effect="solid" />
                    </TableCell>
                ) : y.transform ? (
                    y.name === "Payment Attached Date" ? (
                        <TableCell classes={classes} key={`trc-${k}`} numeric={y.numeric}>
                            {get(x, y.prop) ? y.transform(get(x, y.prop)) : "Not Submitted"}
                        </TableCell>
                    ) : (
                        <TableCell classes={classes} key={`trc-${k}`} numeric={y.numeric}>
                            {y.transform(get(x, y.prop))}
                        </TableCell>
                    )
                ) : y.prop === "dealStatus" ? (
                    <TableCell>
                        <StatusChip status={get(x, y.prop)} />
                    </TableCell>
                ) : (
                    <TableCell classes={classes} key={`trc-${k}`} numeric={y.numeric}>
                        {get(x, y.prop)}
                    </TableCell>
                )
            )}
            <TableCell classes={classes}>
                <Button variant="outlined" color="primary" onClick={() => handleSelect(x)}>
                    View
                </Button>
            </TableCell>
        </TableRow>
    );
};

const styles = (theme) => ({
    root: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.06) !important",
    },
    label: {
        textTransform: "none",
    },
    leftIcon: {
        marginLeft: "25px",
    },
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    searchInput: {
        padding: "0",
        margin: 0,
        border: "none",
        background: "#fafafa",
        fontSize: "12px",
    },
    MuiInputBase: {
        root: {
            color: "red",
            borderColor: "red",
            borderRadius: 0,
        },
    },
    connectorActive: {
        "& $connectorLine": {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        "& $connectorLine": {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        "& $connectorLine": {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create("border-color"),
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    listingIdLinkTerminate: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#D63636",
        fontWeight: 600,
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    divider: {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        margin: "0 -20px 0 -20px",
    },
    tabs: {
        borderBottom: "none",
        marginRight: "20px",
        position: "absolute",
        bottom: 0,
        width: "30em",
    },
    tab: {
        borderBottom: "none",
        marginRight: "20px",
    },
    filterInput: {
        height: "65px",
        marginBottom: "0px",
        minWidth: "10%",
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "10em",
        marginBottom: "3em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    noData: {
        borderBottom: "none",
    },
    loadingPlaceholder: {
        marginTop: "3em",
        marginBottom: "1.5em",
        textAlign: "center",
        width: "100%",
    },
    paperSpacing: {
        padding: "10px 20px",
        fontSize: 14,
        marginBottom: "15px",
    },
});

export default withStyles(styles)(DataAdminDeals);
