import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";

import { Sorty, Search } from "../../../common/js/icons";
import BSInput from "../../../common/js/components/BSInput";
import StatusChip from "../../../common/js/components/StatusChip";
import { formatDate, toDollarAmount, buildAddress } from "../utils/operations";
import constants from "./constants";

import get from "lodash/get";
import orderBy from "lodash/orderBy";

class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDelete: {
                open: false,
                transactionId: null,
                transactionIndex: null,
            },
        };
    }

    render() {
        const {
            classes,
            transactions,
            columnToSort,
            sortDirection,
            handleSort,
            handleFilter,
            filterQuery,
            elementsSaving,
            // deleteContact,
            switchTab,
            transactionsTab,
        } = this.props;

        const transactionz = transactions[transactionsTab];

        const data = orderBy(
            filterQuery && transactionz
                ? transactionz.filter((x) => constants.filterByQuery(x, "transactions", filterQuery))
                : transactionz,
            columnToSort,
            sortDirection
        );

        return (
            <Paper className={classes.paperSpacing}>
                <Grid container justify="space-between">
                    <Grid item style={{ position: "relative" }}>
                        <Tabs className={classes.tabs} value={transactionsTab} onChange={switchTab}>
                            <Tab className={classes.tab} value="open" label="Open Transactions" />
                            <Tab className={classes.tab} value="closed" label="Closed Transactions" />
                        </Tabs>
                    </Grid>
                    <Grid item className={classes.filterInput}>
                        <BSInput
                            extraInputStyles={{ backgroundColor: "#fafafa", border: "none", marginTop: "10px" }}
                            value={filterQuery}
                            placeholder="Search transactions..."
                            startAdornment={<Search />}
                            startAdornmentStyles={{ color: "#777777" }}
                            onChange={(event) => {
                                handleFilter(event.target.value);
                            }}
                            extraFormControlStyles={{ minWidth: "16em" }}
                        />
                    </Grid>
                </Grid>
                {get(elementsSaving, `transactions.${transactionsTab}`) ? (
                    <div className={classes.loadingPlaceholder}>
                        <CircularProgress color="primary" />
                    </div>
                ) : (
                    <React.Fragment>
                        <Divider classes={{ root: classes.divider }} />
                        <Fade in={true}>
                            <Table classes={{ root: classes.table }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell classes={classes} style={{ width: "28%", padding: 0 }}>
                                            <div
                                                style={{ display: "flex", alignItems: "center" }}
                                                onClick={() => handleSort("property.propertyAttributes.propertyName")}
                                            >
                                                <span className={classes.sortable}>Property Name</span>
                                                <div>
                                                    <Sorty />
                                                </div>
                                            </div>
                                        </TableCell>

                                        <TableCell classes={classes} style={{ width: "15%", padding: 0 }}>
                                            <div
                                                style={{ display: "flex", alignItems: "center" }}
                                                onClick={() => handleSort("createdBy.person.firstName")}
                                            >
                                                <span className={classes.sortable}>Created By</span>
                                                <div>
                                                    <Sorty />
                                                </div>
                                            </div>
                                        </TableCell>

                                        <TableCell classes={classes} style={{ width: "12%", padding: 0 }}>
                                            <div
                                                style={{ display: "flex", alignItems: "center", position: "relative" }}
                                                onClick={() => handleSort("completionDate")}
                                            >
                                                <span className={classes.sortable}>Completion Date</span>
                                                <div>
                                                    <Sorty />
                                                </div>
                                            </div>
                                        </TableCell>

                                        <TableCell className={classes.numericHeader} numeric={true}>
                                            <div onClick={() => handleSort("purchasePrice")}>
                                                <div style={{ display: "inline-block" }}>
                                                    <span className={classes.sortable}>Purchase Price</span>
                                                    <div style={{ display: "inline-block" }}>
                                                        <Sorty />
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>

                                        <TableCell className={classes.numericHeader} numeric={true}>
                                            <div onClick={() => handleSort("loanAmount")}>
                                                <div style={{ display: "inline-block" }}>
                                                    <span className={classes.sortable}>Loan Amount</span>
                                                    <div style={{ display: "inline-block" }}>
                                                        <Sorty />
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>

                                        <TableCell classes={classes} style={{ width: "13%", paddingLeft: "5%" }}>
                                            <div
                                                style={{ display: "flex", alignItems: "center", position: "relative" }}
                                                onClick={() => handleSort("status")}
                                            >
                                                <span className={classes.sortable}>Status</span>
                                                <div>
                                                    <Sorty />
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {transactionz && transactionz.length > 0 ? (
                                    <TableBody>
                                        {data.map((transaction, index) => (
                                            <TableRow selectable="false" className="tableRow">
                                                <TableCell
                                                    className={classes.leftColumn}
                                                    classes={classes}
                                                    style={{ padding: 0 }}
                                                >
                                                    <Link
                                                        className={classes.idLink}
                                                        to={`/transactions/${get(transaction, "transactionKey", "")}`}
                                                    >
                                                        <div>
                                                            {get(
                                                                transaction,
                                                                "property.propertyAttributes.propertyName",
                                                                ""
                                                            )}
                                                        </div>
                                                    </Link>
                                                    <div>
                                                        {buildAddress(get(transaction, "property.primaryAddress", ""))}
                                                    </div>
                                                </TableCell>

                                                <TableCell classes={classes} style={{ padding: 0 }}>
                                                    {`${get(transaction, "createdBy.person.firstName", "")} ${get(
                                                        transaction,
                                                        "createdBy.person.lastName",
                                                        ""
                                                    )}`}
                                                </TableCell>

                                                <TableCell classes={classes} style={{ padding: 0 }}>
                                                    {formatDate("MM/DD/YYYY")(get(transaction, "completionDate", ""))}
                                                </TableCell>

                                                <TableCell numeric className={classes.numericCell}>
                                                    {toDollarAmount(get(transaction, "purchasePrice", ""))}
                                                </TableCell>

                                                <TableCell numeric className={classes.numericCell}>
                                                    {toDollarAmount(get(transaction, "loanAmount", ""))}
                                                </TableCell>

                                                <TableCell classes={classes} style={{ paddingLeft: "5%" }}>
                                                    <StatusChip status={get(transaction, "status")} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                ) : (
                                    <TableCell colSpan={6} className={classes.noData}>
                                        <div className={classes.alternateContent}>No transactions.</div>
                                    </TableCell>
                                )}
                            </Table>
                        </Fade>
                    </React.Fragment>
                )}
            </Paper>
        );
    }
}

const styles = {
    root: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.06) !important",
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    idLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
        textDecoration: "none",
    },
    leftColumn: {
        paddingLeft: "0px",
    },
    paperSpacing: {
        padding: "10px 20px",
        fontSize: 14,
        marginBottom: "15px",
    },
    filterInput: {
        height: "70px",
        marginBottom: "0px",
        minWidth: "10%",
    },
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: "0 18px 0 0",
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    divider: {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        margin: "0 -20px 0 -20px",
    },
    loadingPlaceholder: {
        marginTop: "3em",
        marginBottom: "1.5em",
        textAlign: "center",
        width: "100%",
    },
    dialog: {
        width: "20em",
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "10em",
        marginBottom: "3em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    noData: {
        borderBottom: "none",
    },
    tabOne: {
        marginRight: "20px",
        borderBottom: "none",
    },
    tabs: {
        borderBottom: "none",
        marginRight: "20px",
        position: "absolute",
        bottom: 0,
        width: "20em",
    },
    tab: {
        borderBottom: "none",
        marginRight: "20px",
    },
    numericHeader: {
        width: "15%",
        paddingRight: "24px",
        paddingTop: "3px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    },
    numericCell: {
        paddingRight: "24px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    },
};

export default withStyles(styles)(Transactions);
