import types from "./types";

const INITIAL_STATE = {
    stateDocumentRequirements: {},
    documentType: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.UPDATE_STATE_DOCUMENT_REQUIREMENTS:
            return {
                ...state,
                stateDocumentRequirements: action.stateDocumentRequirements,
            };

        case types.SORT_COLUMN:
            return Object.assign({}, state, {
                columnToSort: action.columnToSort,
                sortDirection: action.sortDirection,
            });

        case types.FILTER_DOCUMENTS:
            return Object.assign({}, state, {
                filterQuery: action.filterQuery,
            });

        case types.GET_DOCUMENT_TYPE:
            return {
                ...state,
                documentType: action.documentType,
            };
        case types.CLEAR_REQUEST_DOCUMENT_UPDATE_ERRORS:
            return {
                ...state,
                errors: {},
            };

        case types.ADD_DOCUMENT_TYPE_UPDATE_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.field]: action.error,
                },
            };
        default:
            return state;
    }
};
