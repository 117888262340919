import React, { Component } from "react";
import { buttonActions } from "./reviewListingsTables";
import { Link } from "react-router-dom";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { toDollarAmount, buildAddress, toSqft, aggregateBrokersByPersonObjectForTooltip } from "../utils/operations";
import { ListingCircle, Sorty, Search, ExportIcon, Map, List } from "../../../common/js/icons";
import { withStyles } from "@material-ui/core/styles";
import BSInput from "../../../common/js/components/BSInput";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import ListingFilters from "./ListingFilters";
import orderBy from "lodash/orderBy";
import schema from "./reviewListingsTables";
import StatusChip from "../../../common/js/components/StatusChip";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import MUITooltip from "@material-ui/core/Tooltip";
import ReportTitleModal from "./ReportTitleModal";
import includes from "lodash/includes";
import ListingsWithMap from "./ListingsWithMap";
import ListingsWithoutMap from "./ListingsWithoutMap";
import "./Listings.css";
import ReactTooltip from "react-tooltip";
import SavedSearchesModal from "../search/SavedSearchesModal";
import NameSearchModal from "../search/NameSearchModal";
import ListingImportModal from "./ListingImportModal";
import Snackbar from "../../../common/js/components/KMSnackbar";
import ReactGA from "react-ga4";

const styles = (theme) => ({
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    tabOne: {
        marginRight: "20px",
    },
    connectorActive: {
        "& $connectorLine": {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        "& $connectorLine": {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        "& $connectorLine": {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create("border-color"),
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 800,
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
    menuRequestListing: {
        paddingBottom: "0px",
        "&>a": {
            paddingBottom: "0px !important",
        },
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    lb: {
        textDecoration: "none",
    },
    toplessInput: {
        marginTop: 0,
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    listingIdLink: {
        color: "black",
        fontWeight: 600,
    },
    listingNumber: {
        fontSize: "14px",
        margin: "0",
        borderColor: "#f1f1f1",
        color: "#2C99D2",
        fontWeight: 600,
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
    loadingProgress: {
        marginRight: "5px",
        color: "grey",
    },
    muiToolTip: {
        marginRight: "100px",
    },
});

class ReviewListings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numListings: 0,
            selectAll: true,
            selectAllGroup: true,
            mapDisplay: true,
            reportDropdown: false,
            reportStyle: null,
            exportDropdown: false,
            modalOpen: false,
            snackbarOpen: false,
            snackbarMessage: "",
            snackbarVariant: "",
        };
    }

    handleSelectAll = () => {
        const checked = this.state.selectAll;
        this.props.handleSelectAll(!checked);
        this.setState({ selectAll: !checked });
    };

    handleSelectAllGroup = () => {
        const checked = this.state.selectAllGroup;
        this.props.handleSelectAllGroup(!checked);
        this.setState({ selectAllGroup: !checked });
    };

    handleClickOpen = (listing) => {
        this.setState({ closeDealDialogOpen: true, listingToClose: listing });
    };

    handleCloseAndStart = () => {
        this.props.closeDeal(this.state.listingToClose);
        this.setState({ closeDealDialogOpen: false, listingToClose: null });
    };

    handleClose = () => {
        this.setState({ closeDealDialogOpen: false, listingToClose: null });
    };

    toggleMap = (mapView) => {
        this.setState({ mapDisplay: mapView });
    };

    handleSelectReport = (reportStyle) => {
        this.setState({ reportStyle: reportStyle });
        this.props.toggleReportTitleModal(true);
    };

    handleReportDropdown = (open) => {
        this.setState({ reportDropdown: open });
    };

    handleExportDropdown = (open) => {
        this.setState({ exportDropdown: open });
    };

    handleOpenModal = () => {
        this.setState({ modalOpen: true });
    };

    handleCloseModal = () => {
        this.setState({ modalOpen: false });
    };

    handleImportResponse = (result) => {
        if (result === true) {
            this.setState({
                snackbarMessage: "Success: Your file will now be processed.",
                snackbarVariant: "success",
                snackbarOpen: true,
            });
        } else if (result === false) {
            this.setState({
                snackbarMessage: "Failed: Columns must match Standard Import Template.",
                snackbarVariant: "error",
                snackbarOpen: true,
            });
        }
    };

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: "/listings", title: "Admin Listings" });
    }

    render() {
        const {
            role,
            user,
            errors,
            listings,
            classes,
            listingTab,
            handleSort,
            handleFilter,
            isLoading,
            switchTab,
            filterQuery,
            columnToSort,
            numListings,
            sortDirection,
            updateFilter,
            handleSizeRadioClick,
            propertySearchFilters,
            handleStatusRadioClick,
            handleSelectAllPropertyType,
            handleListingTypeRadioClick,
            handleListingsSearchSubmit,
            clearFilters,
            searchCriteria,
            togglePropertyTypeExpansionPanel,
            propertyTypeExpansionPanels,
            searchResponse,
            toggleMarketFilterExpansionPanel,
            marketFilterExpansionPanels,
            handleSelectMarket,
            handleSelectSubmarket,
            handleSelectMicromarket,
            elementsSaving,
            dataExport,
            handleDataExport,
            loadedSearchCrumbs,
            handleDeleteCrumb,
            selectedListings,
            updateSearchAreaFilter,
            searchCleared,
            handleSearchCleared,
            searchPolygon,
            updateSearchPolygon,
            searchRun,
            viewSavedModal,
            nameSaveModal,
            addSelectedToGroup,
            deleteSelectedFromGroup,
            clearCurrentGroup,
            selectedListingsGroup,
            listingCurrentGroup,
        } = this.props;

        const { selectAll, selectAllGroup, mapDisplay } = this.state;

        const listingCount = numListings && !elementsSaving[listingTab] ? numListings[listingTab] : 0;
        const listingz = listings[listingTab] ? listings[listingTab] : {};
        const tab = includes(
            ["data_team_unassigned", "data_team_open", "all_km_listings", "listings_group"],
            listingTab
        )
            ? listingTab
            : "data_team_unassigned";
        let disableDeleteFromGroup = true;
        const disclaimerText = `© 2019 - ${new Date().getFullYear()} Kidder Mathews Inc and it’s subsidiaries and/or assigns. The information supplied herein is from sources we deem reliable. It is provided without any representation, warranty, or guarantee, expressed or implied as to its accuracy. Prospective Buyer or Tenant should conduct an independent investigation and verification of all matters deemed to be material, including, but not limited to, statements of income and expenses. Consult your attorney, accountant, or other professional advisor. All information is proprietary to Kidder Mathews and intended for the sole purpose of providing consultancy services to clients and should not be reproduced, distributed or used for any other purpose nor divulged to third party data collection agencies as outlined by corporate policy.`;

        //sets disableDeleteFromGroup to false for Delete from Group button if no listings are selected
        let list = [];
        for (var [key, value] of selectedListingsGroup) {
            if (value && !list.includes(key)) {
                list.push(key);
            }
        }
        if (list.length !== 0) {
            disableDeleteFromGroup = false;
        }

        return (
            <div>
                <Collapse
                    in={listingTab === "all_km_listings"}
                    unmountOnExit
                    style={{ marginLeft: -20, marginRight: -20 }}
                >
                    <ListingFilters
                        role={role}
                        updateFilter={updateFilter}
                        classes={classes}
                        searchSubmit={handleListingsSearchSubmit}
                        propertySearchFilters={propertySearchFilters}
                        handleSelectAllPropertyType={handleSelectAllPropertyType}
                        clearFilters={clearFilters}
                        searchCriteria={searchCriteria}
                        handleStatusRadioClick={handleStatusRadioClick}
                        handleSizeRadioClick={handleSizeRadioClick}
                        handleListingTypeRadioClick={handleListingTypeRadioClick}
                        errors={errors}
                        togglePropertyTypeExpansionPanel={togglePropertyTypeExpansionPanel}
                        propertyTypeExpansionPanels={propertyTypeExpansionPanels}
                        toggleMarketFilterExpansionPanel={toggleMarketFilterExpansionPanel}
                        marketFilterExpansionPanels={marketFilterExpansionPanels}
                        handleSelectMarket={handleSelectMarket}
                        handleSelectSubmarket={handleSelectSubmarket}
                        handleSelectMicromarket={handleSelectMicromarket}
                        handleSearchCleared={handleSearchCleared}
                        loadedSearchCrumbs={loadedSearchCrumbs}
                        handleDeleteCrumb={handleDeleteCrumb}
                        searchCleared={searchCleared}
                    />
                </Collapse>

                <Snackbar
                    open={this.state.snackbarOpen}
                    message={this.state.snackbarMessage}
                    variant={this.state.snackbarVariant}
                    vertical="top"
                    horizontal="center"
                    autoHideDuration={3000}
                    onClose={() => {
                        this.setState({ snackbarOpen: false });
                    }}
                />

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.pageToolbar}
                    spacing={16}
                >
                    <Grid item>
                        <h2 className="page-title">Listings</h2>
                        <div className="page-subtitle">
                            {listingTab !== "all_km_listings" &&
                                listingTab !== "listings_group" &&
                                `Showing ${listingCount} ${listingCount === 1 ? "listing" : "listings"} `}
                            {filterQuery > "" && listingTab !== "all_km_listings" && "(Filtered)"}
                            {listingTab === "all_km_listings" &&
                                searchResponse &&
                                searchResponse.results &&
                                `Showing  ${searchResponse.results.length} ${
                                    searchResponse.results.length === 1
                                        ? "listing"
                                        : `listings (${searchResponse.totalResultCount} TOTAL)`
                                }`}

                            {listingTab === "listings_group" &&
                                listingCurrentGroup &&
                                `You have ${listingCurrentGroup.length} ${
                                    listingCurrentGroup.length === 1
                                        ? "listing in your current list"
                                        : `listings in your current list`
                                }`}
                        </div>
                    </Grid>
                    <Grid item>
                        {listingTab === "all_km_listings" ? (
                            <Button
                                disabled={false}
                                variant="contained"
                                color="primary"
                                onClick={(e) => addSelectedToGroup()}
                            >
                                Add to List
                            </Button>
                        ) : listingTab === "listings_group" ? (
                            <React.Fragment>
                                <Button
                                    disabled={disableDeleteFromGroup}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => deleteSelectedFromGroup()}
                                >
                                    Delete From List
                                </Button>
                                <Button
                                    disabled={disableDeleteFromGroup}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => clearCurrentGroup()}
                                >
                                    Clear List
                                </Button>
                            </React.Fragment>
                        ) : (
                            ""
                        )}

                        {listingTab === "all_km_listings" &&
                        includes(["dataAdmin", "dataEntry", "research", "researchAdmin"], role) ? (
                            <React.Fragment>
                                <Button
                                    disabled={false}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.handleOpenModal()}
                                >
                                    Import
                                </Button>
                                <ListingImportModal
                                    modalOpen={this.state.modalOpen}
                                    closeModal={this.handleCloseModal}
                                    importResponse={this.handleImportResponse}
                                />
                            </React.Fragment>
                        ) : (
                            ""
                        )}

                        {(listingTab === "all_km_listings" || listingTab === "listings_group") &&
                            (get(dataExport, "exportInProgress", false) === true ? (
                                <Button disabled={true} variant="outlined" color="primary">
                                    <ExportIcon /> In Progress
                                </Button>
                            ) : (
                                <React.Fragment>
                                    <MUITooltip
                                        title={disclaimerText}
                                        placement="left-start"
                                        classes={{ tooltip: classes.muiToolTip }}
                                    >
                                        <DropdownButton
                                            disabled={Object.keys(listingz).length === 0}
                                            title={"Export"}
                                            onClick={(e) => this.handleExportDropdown(true)}
                                            pullRight
                                        >
                                            {this.state.exportDropdown && (
                                                <React.Fragment>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => handleDataExport("selected", listingTab)}
                                                    >
                                                        Selected Listings Only
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => handleDataExport("all", listingTab)}
                                                    >
                                                        {listingTab === "listings_group"
                                                            ? "Entire Group"
                                                            : "Entire Data Set"}
                                                    </MenuItem>
                                                </React.Fragment>
                                            )}
                                        </DropdownButton>
                                    </MUITooltip>
                                </React.Fragment>
                            ))}

                        {(listingTab === "all_km_listings" || listingTab === "listings_group") &&
                            includes(["dataAdmin", "dataEntry"], role) &&
                            (elementsSaving.generateListingReport ? (
                                <React.Fragment>
                                    <Button disabled={true} variant="contained" color="secondary">
                                        <CircularProgress className={classes.loadingProgress} size={15} />
                                        Generating
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <MUITooltip
                                        title={disclaimerText}
                                        placement="left-start"
                                        classes={{ tooltip: classes.muiToolTip }}
                                    >
                                        <DropdownButton
                                            disabled={Object.keys(listingz).length === 0}
                                            title={"Generate Report"}
                                            onClick={(e) => this.handleReportDropdown(true)}
                                            pullRight
                                        >
                                            {this.state.reportDropdown && (
                                                <React.Fragment>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => this.handleSelectReport("Standard")}
                                                    >
                                                        Standard
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => this.handleSelectReport("MapOverview")}
                                                    >
                                                        Map Overview
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => this.handleSelectReport("HalfMap-HalfProperty")}
                                                    >
                                                        Half Map / Half Property List
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => this.handleSelectReport("IndividualDetail")}
                                                    >
                                                        Individual Listing Page Detail
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => this.handleSelectReport("Reference")}
                                                    >
                                                        Property Reference
                                                    </MenuItem>
                                                </React.Fragment>
                                            )}
                                        </DropdownButton>
                                    </MUITooltip>
                                </React.Fragment>
                            ))}
                    </Grid>
                </Grid>

                <Grid container alignItems="center" spacing={16} style={{ maxWidth: "100%" }}>
                    <Grid item xs>
                        <div className="simPaper">
                            <Grid container justify="space-between" alignItems="center" spacing={0}>
                                <Grid item>
                                    <Tabs
                                        classes={classes}
                                        value={tab}
                                        onChange={(event, value) => switchTab(event, value, user)}
                                    >
                                        <Tab
                                            classes={classes.tabOne}
                                            value="data_team_unassigned"
                                            label="Unassigned Listings"
                                        />
                                        <Tab classes={classes} value="data_team_open" label="Open Listings" />
                                        <Tab classes={classes} value="all_km_listings" label="All Listings" />
                                        <Tab
                                            classes={classes}
                                            value="listings_group"
                                            label={`Current List (${
                                                listingCurrentGroup ? listingCurrentGroup.length : "0"
                                            })`}
                                        />
                                    </Tabs>
                                </Grid>
                                {listingTab !== "all_km_listings" && listingTab !== "listings_group" && (
                                    <Grid item>
                                        <BSInput
                                            value={filterQuery}
                                            placeholder="Search listings"
                                            className={classes.toplessInput}
                                            errorPlaceholder="hideIt"
                                            startAdornment={<Search />}
                                            onChange={(event) => {
                                                handleFilter(event.target.value);
                                            }}
                                        />
                                    </Grid>
                                )}

                                {(listingTab === "all_km_listings" || listingTab === "listings_group") && (
                                    <Grid item>
                                        <Grid container>
                                            {mapDisplay ? (
                                                <Grid item style={{}}>
                                                    <Button
                                                        style={{ minWidth: "40px" }}
                                                        onClick={() => this.toggleMap(false)}
                                                    >
                                                        <List />
                                                    </Button>
                                                </Grid>
                                            ) : (
                                                <Grid style={{}}>
                                                    <Button
                                                        style={{ minWidth: "40px" }}
                                                        onClick={() => this.toggleMap(true)}
                                                    >
                                                        <Map />
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            {listingTab === "all_km_listings" || listingTab === "listings_group" ? (
                                mapDisplay ? (
                                    <ListingsWithMap
                                        {...this.props}
                                        selectAll={selectAll}
                                        selectAllGroup={selectAllGroup}
                                        searchRun={searchRun}
                                        searchCleared={listingTab === "listings_group" ? false : searchCleared}
                                        handleSelectAll={this.handleSelectAll}
                                        handleSelectAllGroup={this.handleSelectAllGroup}
                                        updateSearchAreaFilter={updateSearchAreaFilter}
                                        selectedListings={selectedListings}
                                        listingz={listingz}
                                        handleSearchCleared={handleSearchCleared}
                                        searchPolygon={searchPolygon}
                                        updateSearchPolygon={updateSearchPolygon}
                                    />
                                ) : (
                                    <ListingsWithoutMap
                                        {...this.props}
                                        selectAll={selectAll}
                                        selectAllGroup={selectAllGroup}
                                        searchRun={searchRun}
                                        searchCleared={listingTab === "listings_group" ? false : searchCleared}
                                        handleSelectAll={this.handleSelectAll}
                                        handleSelectAllGroup={this.handleSelectAllGroup}
                                        selectedListings={selectedListings}
                                        listingz={listingz}
                                        handleSearchCleared={handleSearchCleared}
                                    />
                                )
                            ) : (
                                ""
                            )}

                            {listingTab !== "all_km_listings" && listingTab !== "listings_group" && (
                                <Grid container spacing={0}>
                                    <Grid item xs>
                                        {elementsSaving[listingTab] ? (
                                            <div className={classes.alternateContent}>
                                                <CircularProgress color="primary" />
                                            </div>
                                        ) : (
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {schema.viewHeaders[listingTab].map((x, i) => (
                                                            <TableCell classes={classes} key={`thc-${i}`}>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                    onClick={() => handleSort(x.sort ? x.sort : x.prop)}
                                                                >
                                                                    <span className={classes.sortable}>{x.name}</span>
                                                                    {x.name && (
                                                                        <div>
                                                                            <Sorty />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                        ))}
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Object.keys(listingz).map((propKey, i) =>
                                                        row(
                                                            propKey,
                                                            role,
                                                            listingTab,
                                                            classes,
                                                            isLoading,
                                                            columnToSort,
                                                            sortDirection,
                                                            listingz,
                                                            filterQuery
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {listingTab !== "all_km_listings" &&
                            listingTab !== "listings_group" &&
                            Object.keys(listingz).length === 0 ? (
                                <div className={classes.alternateContent}>No listings</div>
                            ) : null}
                        </div>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.closeDealDialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="close-deal"
                    aria-describedby="close-deal-dialog"
                >
                    <DialogTitle id="close-deal">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Closing this deal will start the invoice process for this transaction and move the listing
                            to a closed status. Portland Listings will be closed after the Comp for this Deal has been
                            Submitted and Approved.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={this.handleCloseAndStart} color="secondary" autoFocus variant="contained">
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>

                <ReportTitleModal
                    {...this.props}
                    reportType="Listing"
                    tab={listingTab}
                    reportStyle={this.state.reportStyle}
                />
                <SavedSearchesModal {...this.props} searchType="listings" tab={listingTab} showModal={viewSavedModal} />
                <NameSearchModal {...this.props} searchType="listings" tab={listingTab} showModal={nameSaveModal} />
            </div>
        );
    }
}

const row = (propKey, role, listingTab, classes, isLoading, columnToSort, sortDirection, listingz, filterQuery) => {
    const sortedData = orderBy(
        filterQuery && listingz && listingz[propKey]
            ? listingz[propKey].filter((x) => schema.filterByQuery(x, schema.columnsToQueryFilter, filterQuery))
            : listingz[propKey],
        columnToSort,
        sortDirection
    );

    return isLoading ? null : (
        <React.Fragment>
            {sortedData.length > 0 && (
                <TableRow>
                    <TableCell colSpan={8}>
                        <Grid container alignItems="center" spacing={8}>
                            <Grid item>
                                <ListingCircle />
                            </Grid>
                            <Grid item xs>
                                <div className="cluster">
                                    <span className="clusterHeader">
                                        {get(sortedData[0], "property.propertyAttributes.propertyName", null)
                                            ? get(sortedData[0], "property.propertyAttributes.propertyName", "")
                                            : get(sortedData[0], "property.primaryAddress.address1", "")}
                                    </span>
                                    <span className="clusterSubHeader">
                                        {`${sortedData.length} ${sortedData.length === 1 ? "listing" : "listings"}`}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            )}

            {sortedData.map((x, i) => {
                const buttonProperties = get(buttonActions, `${role}.${x.status}`, buttonActions.defaultAction);

                return (
                    <TableRow key={`tr-${i}`} selectable="false">
                        {schema.viewHeaders[listingTab].map((y, k) =>
                            y.name === "Address" ? (
                                <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                                    <Link to={`/listing/${x.listingKey}`} className={classes.lb}>
                                        <h4 className={classes.listingNumber}>{`No. ${get(x, "listingKey", "")}`}</h4>
                                        <span className={classes.listingIdLink}>{get(x, "listingName", "")}</span>
                                    </Link>
                                    <div>{buildAddress(get(x, "property.primaryAddress", {}))}</div>
                                </TableCell>
                            ) : y.name === "Brokers" ? (
                                <TableCell classes={classes} key={`trc-${k}`}>
                                    <span
                                        className={classes.tooltip}
                                        data-tip={aggregateBrokersByPersonObjectForTooltip(
                                            get(x, y.prop),
                                            "brokerAgentKey.person"
                                        )}
                                    >
                                        {y.transform(get(x, y.prop))}
                                    </span>
                                    <ReactTooltip place="top" type="dark" effect="solid" />
                                </TableCell>
                            ) : y.transform ? (
                                <TableCell classes={{ root: classes.body }} key={`trc-${k}`} align={y.align}>
                                    {y.transform(get(x, y.prop))}
                                </TableCell>
                            ) : y.prop === "status" && buttonProperties ? (
                                <TableCell>
                                    {/* Status */}
                                    <StatusChip
                                        status={
                                            buttonProperties.statusToDisplay && buttonProperties.statusToDisplay !== ""
                                                ? buttonProperties.statusToDisplay
                                                : x.status
                                        }
                                    />
                                </TableCell>
                            ) : y.prop === "sfAvail" ? (
                                <TableCell>
                                    {/* Rate */}
                                    {get(x, "listingType", null) === "Lease"
                                        ? get(x, "sfDivisibleTo", null)
                                            ? `${toSqft(get(x, "sfDivisibleTo"))} - ${toSqft(get(x, "sfAvail"))}`
                                            : `${toSqft(get(x, "sfAvail", "-"))}`
                                        : `${toSqft(get(x, "sfAvail", "-"))}`}
                                </TableCell>
                            ) : y.name === "Rate" ? (
                                <TableCell>
                                    {/* Rate */}
                                    {get(x, "listingType", null) === "Lease"
                                        ? `${toDollarAmount(get(x, "askingRentMax"))}`
                                        : `${toDollarAmount(get(x, "listPrice", "-"))}`}
                                </TableCell>
                            ) : (
                                <TableCell classes={{ root: classes.body }} key={`trc-${k}`} align={y.align}>
                                    {/* Type */}
                                    {get(x, y.prop)}
                                </TableCell>
                            )
                        )}

                        <TableCell classes={{ root: classes.body }} numeric="true">
                            <Link
                                to={
                                    buttonProperties.urlParam
                                        ? `/listing/${x.listingKey}?action=${buttonProperties.urlParam}`
                                        : `/listing/${x.listingKey}`
                                }
                                className={classes.lb}
                            >
                                <Button variant={buttonProperties.variant} color="primary" onClick={() => null}>
                                    {buttonProperties.text}
                                </Button>
                            </Link>
                        </TableCell>
                    </TableRow>
                );
            })}
        </React.Fragment>
    );
};

export default withStyles(styles)(ReviewListings);
