import React from "react";
import Grid from "@material-ui/core/Grid";
import BrokerCommissions from "./BrokerCommissions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormTitle from "../../../common/js/components/FormTitle";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { buildAddress } from "../utils/operations";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Today from "../../../../node_modules/@material-ui/icons/Today"; // used for date type input
import Installments from "../deal/Installments";
import get from "lodash/get";
import documentTypes from "./documentTypes";
import { consultingDealTypes } from "../types/consultingDealTypes";
import { propertyTypes } from "../types/propertyTypes";
import numeral from "numeral";
import includes from "lodash/includes";
import moment from "moment";
import PropertyUpdateModalContainer from "../property/PropertyUpdateModalContainer";

const ConsultingDeal = (props) => {
    const {
        userProfile,
        voucher,
        errors,
        updateSplit,
        handleInput,
        updateRunner,
        outsideSplits,
        searchResults,
        documentWarning,
        handleAddRunner,
        outsideSplitModal,
        brokerCommissions,
        handleSearchPerson,
        handleSearchBroker,
        updateBrokerPerson,
        handleSearchCompany,
        handleCheckboxClick,
        handleVoucherChange,
        handleConfirmWarning,
        handleDocumentWarning,
        handleSaveOutsideSplit,
        toggleOutsideSplitModal,
        determineOutsidePresent,
        handleDeleteOutsideSplit,
        handleAddBrokerCommission,
        handleCreateOutsideSplitDraft,
        handleRemoveCommissionOrRunner,
        autocompleteResults,
        handleAddArrayElement, // (path)
        handleDeleteArrayElement, // (path, value)
        handleUpdateArrayElement, // (arrayPath, index, field, value)
        disableInputs,
        handleReducerLevelInput,
        classes,
        togglePropertySearchModal,
        updatedProperty,
        setInitialPaymentMethod,
    } = props;

    const calculateTotalCommission = (event) => {
        switch (event.target.id) {
            case "totalKmCommission": {
                handleInput("deal.totalKmCommission", event.target.value);
                const totalKmCommission = parseFloat(numeral(event.target.value).format("0.00"));
                const outsideBrokerCommission = parseFloat(
                    numeral(get(voucher, "deal.outsideBrokerCommission", 0)).format("0.00")
                );
                handleInput(
                    "deal.totalCommission",
                    numeral(totalKmCommission + outsideBrokerCommission).format("0,000.00")
                );
                break;
            }
            case "outsideBrokerCommission": {
                handleInput("deal.outsideBrokerCommission", event.target.value);
                const outsideBrokerCommission = parseFloat(numeral(event.target.value).format("0.00"));
                const totalKmCommission = parseFloat(numeral(get(voucher, "deal.totalKmCommission", 0)).format("0.00"));
                handleInput(
                    "deal.totalCommission",
                    numeral(totalKmCommission + outsideBrokerCommission).format("0,000.00")
                );
                break;
            }
            default:
                break;
        }
    };

    if (get(voucher, "paymentMethod", null) === null) {
        setInitialPaymentMethod("CHECK");
    }

    const formDisabled = false;
    const role = get(userProfile, "role");

    return (
        <div>
            <form>
                <div className="simPaper">
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormTitle title="Consulting Information  " />
                        </Grid>
                    </Grid>
                    <hr className={classes.hrDivider} />
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <BSInput
                                id="consultingDealName"
                                label="Consulting Deal Name"
                                value={get(voucher, "deal.consultingDealName", "")}
                                error={errors.consultingDealName}
                                onChange={(e) => handleInput("deal.consultingDealName", e.target.value)}
                                placeholder="Enter consulting deal name"
                                disabled={formDisabled}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BSSelect
                                id="consultingDealType"
                                label="Type"
                                value={get(voucher, "deal.consultingDealType", "")}
                                error={errors.consultingDealType}
                                onChange={(e) => handleInput("deal.consultingDealType", e.target.value)}
                                placeholder="Select type"
                                options={consultingDealTypes}
                                disabled={formDisabled}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <BSSelect
                                id="propertyType"
                                label="Property Type"
                                value={get(voucher, "deal.propertyType", "")}
                                error={errors.propertyType}
                                onChange={(e) => handleInput("deal.propertyType", e.target.value)}
                                placeholder="Select type"
                                options={[
                                    {
                                        value: "",
                                        label: "Select",
                                        disabled: true,
                                    },
                                    ...propertyTypes,
                                ]}
                                disabled={formDisabled}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <BSInput
                                id="clientName"
                                label="Client Name"
                                value={get(voucher, "deal.clientName", "")}
                                error={errors.clientName}
                                onChange={(e) => handleInput("deal.clientName", e.target.value)}
                                placeholder="Enter client name"
                                disabled={formDisabled}
                            />
                        </Grid>
                    </Grid>
                    {includes(["dataAdmin", "dataEntry"], userProfile.role) ? (
                        <Grid container spacing={8}>
                            <Grid item xs={11}>
                                <BSInput
                                    id="propertyAddress"
                                    label="Property Address"
                                    value={buildAddress(
                                        get(
                                            updatedProperty,
                                            "primaryAddress",
                                            get(voucher, "deal.property.primaryAddress")
                                        )
                                    )}
                                    error={errors.propertyAddress}
                                    placeholder="Enter address"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <div
                                    className={classes.updateProperty}
                                    style={{ paddingTop: "30px", paddingLeft: "15px" }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        onClick={() => togglePropertySearchModal(true)}
                                    >
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
                                    </svg>
                                    <div>
                                        <PropertyUpdateModalContainer
                                            {...props}
                                            formType="consulting"
                                            consultingDealKey={get(voucher, "deal.dealKey")}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="propertyAddress"
                                    label="Property Address"
                                    value={buildAddress(
                                        get(
                                            updatedProperty,
                                            "primaryAddress",
                                            get(voucher, "deal.property.primaryAddress")
                                        )
                                    )}
                                    error={errors.propertyAddress}
                                    placeholder="Enter address"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <FormTitle title="Commission Distribution" />
                    <hr />
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <BSInput
                                id="submittedDate"
                                label="Date"
                                type={"date"}
                                helperText="Required"
                                errorText="Required"
                                startAdornment={<Today />}
                                value={voucher.submittedDate}
                                error={errors.submittedDate}
                                onChange={handleVoucherChange}
                                placeholder="Enter date"
                                disabled={formDisabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="dealKey"
                                label="Deal Id"
                                error={get(errors, "dealKey")}
                                value={get(voucher, "deal.dealKey")}
                                onChange={handleVoucherChange}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>

                    {includes(["dataAdmin", "accounting", "dataEntry"], role) && (
                        <React.Fragment>
                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="paymentAttachedDate"
                                        label="Submitted With Payment Date"
                                        value={
                                            voucher.paymentAttachedDate
                                                ? moment(voucher.paymentAttachedDate).format("MM/DD/YYYY h:mm A")
                                                : null
                                        }
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="acceptedDate"
                                        label="Processed Date"
                                        value={
                                            voucher.acceptedDate
                                                ? moment(voucher.acceptedDate).format("MM/DD/YYYY h:mm a")
                                                : null
                                        }
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="approvedDate"
                                        label="Batch Date"
                                        value={voucher.approvedDate}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="filedDate"
                                        label="Filed (Batch Override) Date"
                                        type={"date"}
                                        startAdornment={<Today />}
                                        value={voucher.filedDate}
                                        onChange={handleVoucherChange}
                                        disabled={false}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}

                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <BSInput
                                id="totalConsiderationAmount"
                                label="Total Consideration"
                                value={get(voucher, "deal.totalConsiderationAmount")}
                                error={errors.totalConsiderationAmount}
                                onChange={(e) => handleInput("deal.totalConsiderationAmount", e.target.value)}
                                startAdornment="$"
                                placeholder="Enter amount"
                                ß
                                numeric={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="totalKmCommission"
                                label="Total Commission Due to KM Only"
                                value={get(voucher, "deal.totalKmCommission")}
                                error={errors.totalKmCommission}
                                onChange={calculateTotalCommission}
                                startAdornment="$"
                                placeholder="Enter amount"
                                disabled={formDisabled}
                                numeric={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} style={{ marginBottom: "-30px" }}>
                        <Grid item xs={6}>
                            <BSInput
                                id="outsideBrokerCommission"
                                label="Total Commission To Outside Brokers"
                                value={get(voucher, "deal.outsideBrokerCommission")}
                                error={errors.outsideBrokerCommission}
                                onChange={calculateTotalCommission}
                                startAdornment="$"
                                placeholder="Enter amount"
                                disabled={formDisabled}
                                numeric={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="totalCommission"
                                label="Total Commission For Transaction"
                                value={get(voucher, "deal.totalCommission")}
                                error={errors.totalCommission}
                                startAdornment="$"
                                disabled
                                numeric={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start" spacing={8} style={{ marginBottom: "20px" }}>
                        <Grid item xs={12}>
                            <FormControl rowcomponent="fieldset">
                                <RadioGroup
                                    row
                                    aria-label="paymentMethod"
                                    name="paymentMethod"
                                    value={voucher.paymentMethod}
                                    disabled={formDisabled}
                                    onChange={handleVoucherChange}
                                >
                                    <FormLabel component="legend" style={{ marginBottom: "5px", paddingTop: "15px" }}>
                                        Payment Method
                                    </FormLabel>
                                    <FormControlLabel
                                        value="ACH"
                                        control={
                                            <Radio
                                                id="paymentMethod"
                                                color="primary"
                                                checked={voucher.paymentMethod === "ACH"}
                                            />
                                        }
                                        style={{ marginRight: "83px" }}
                                        label="ACH"
                                    />
                                    <FormControlLabel
                                        value="CHECK"
                                        control={
                                            <Radio
                                                id="paymentMethod"
                                                color="primary"
                                                checked={voucher.paymentMethod === "CHECK"}
                                            />
                                        }
                                        label="Check"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <div
                                style={{
                                    color: "#E53935",
                                    fontSize: "12px",
                                    marginBottom: "10px",
                                    fontStyle: "italic",
                                }}
                            >
                                Required
                            </div>
                        </Grid>
                    </Grid>
                    <Installments
                        {...props}
                        installments={get(voucher, "deal.installments", [])}
                        handleAddArrayElement={(path) =>
                            handleAddArrayElement("deal." + path, { installmentKey: null, amount: null, dueDate: null })
                        }
                        handleDeleteArrayElement={(path, index) => handleDeleteArrayElement("deal." + path, index)}
                        handleUpdateArrayElement={(arrayPath, index, field, value) =>
                            handleUpdateArrayElement("deal." + arrayPath, index, field, value)
                        }
                        errors={get(errors, "deal.installments", {})}
                        disableInputs={disableInputs}
                        onlyEditCurrentInstallment={false}
                        preventAddSubtract={get(voucher, "deal.installments", []).length > 0}
                        consultingDeal={true}
                    />
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(voucher, "deal.kmExclusiveFlg") ? true : false}
                                        onChange={(e) => handleCheckboxClick("deal.kmExclusiveFlg", e)}
                                        value={voucher.kmExclusiveFlg}
                                        color="primary"
                                        disabled={formDisabled}
                                    />
                                }
                                label="Is this listing KM Exclusive?"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(voucher, "deal.propertyManagementPaymentFlg") ? true : false}
                                        onChange={(e) => handleCheckboxClick("deal.propertyManagementPaymentFlg", e)}
                                        value={voucher.propertyManagementPaymentFlg}
                                        color="primary"
                                        disabled={formDisabled}
                                    />
                                }
                                label="Is this payment handled by Asset Services?"
                            />
                        </Grid>
                    </Grid>
                    <hr />
                    <BrokerCommissions
                        {...props}
                        voucher={voucher}
                        brokerCommissions={brokerCommissions}
                        outsideSplits={outsideSplits}
                        handleAddBrokerCommission={handleAddBrokerCommission}
                        handleCreateOutsideSplitDraft={handleCreateOutsideSplitDraft}
                        handleSaveOutsideSplit={handleSaveOutsideSplit}
                        handleDeleteOutsideSplit={handleDeleteOutsideSplit}
                        handleAddRunner={handleAddRunner}
                        handleRemoveCommissionOrRunner={handleRemoveCommissionOrRunner}
                        updateSplit={updateSplit}
                        updateRunner={updateRunner}
                        outsideSplitModal={outsideSplitModal}
                        toggleOutsideSplitModal={toggleOutsideSplitModal}
                        determineOutsidePresent={determineOutsidePresent}
                        handleSearchPerson={handleSearchPerson}
                        handleSearchCompany={handleSearchCompany}
                        searchResults={searchResults}
                        updateBrokerPerson={updateBrokerPerson}
                        errors={errors}
                        formDisabled={formDisabled}
                        autocompleteResults={autocompleteResults}
                        handleSearchBroker={handleSearchBroker}
                        handleInput={(path, value) => handleInput(path, value)}
                        handleReducerLevelInput={handleReducerLevelInput}
                    />
                </div>
            </form>
            <div>
                {/* not being used currently ... see operations -> validateRequiredDocuments */}
                <Dialog
                    open={documentWarning.open}
                    onClose={() => handleDocumentWarning(false, documentWarning.proceed, [])}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to submit?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are responsible for ensuring this deal has the necessary legal documents. The following
                            documents are still required for this deal to be compliant:
                            <ul>
                                {documentWarning.docs.map((x, i) => (
                                    <li>{documentTypes.lease[x]["display"]}</li>
                                ))}
                            </ul>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleDocumentWarning(false, false, [])} color="primary">
                            Add more docs
                        </Button>
                        <Button onClick={() => handleConfirmWarning()} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};

const styles = (theme) => ({
    hrDivider: {
        margin: "0 -20px 20px",
    },
    updateProperty: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
});

export default withStyles(styles)(ConsultingDeal);
