import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { leaseTypeFinancialTypes } from "../types/leaseTypeFinancialTypes";
import toLower from "lodash/toLower";
import get from "lodash/get";
import includes from "lodash/includes";

const styles = {};

const FinancialInformation = (props) => {
    const { listing, errors, disableInputs, suppressRequired, compStatus } = props;

    const displayRequired = !(suppressRequired === true);
    const handleInputChange = (event) => {
        props.handleInputChange("listing." + event.target.id, event.target.value);
    };
    const useType = get(listing, "attributes.useType");
    const disableForOutside = includes(["Outside Draft", "Outside"], compStatus) ? true : false;

    return (
        <React.Fragment>
            {(toLower(get(listing, "listingType")) === "lease" ||
                toLower(get(listing, "listingType")) === "for lease") && (
                <div>
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={6}>
                            <BSSelect
                                id="leaseType"
                                label={displayRequired ? "Lease Type*" : "Lease Type"}
                                helperText={disableForOutside ? "" : displayRequired ? "Required" : ""}
                                errorText={disableForOutside ? "" : "Required"}
                                value={get(listing, "leaseType")}
                                error={disableForOutside ? "" : get(errors, "leaseType")}
                                onChange={handleInputChange}
                                placeholder="Select lease type..."
                                disabled={disableInputs}
                                options={[
                                    {
                                        value: "",
                                        label: "Select",
                                        disabled: true,
                                    },
                                    ...leaseTypeFinancialTypes,
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="improvementAllowance"
                                label="Improvement Allowance"
                                value={get(listing, "improvementAllowance")}
                                error={get(errors, "improvementAllowance")}
                                onChange={handleInputChange}
                                placeholder="$_._/SF"
                                disabled={disableInputs}
                                numeric
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={6}>
                            <BSSelect
                                id="expenseType"
                                label="Expense Type"
                                value={get(listing, "expenseType")}
                                error={get(errors, "expenseType")}
                                onChange={handleInputChange}
                                placeholder="Select an expense type..."
                                disabled={disableInputs}
                                options={[
                                    {
                                        value: "",
                                        label: "Select an expense type...",
                                    },
                                    {
                                        value: "baseYear",
                                        label: "Base Year",
                                    },
                                    {
                                        value: "nnnExpenseType",
                                        label: "NNN",
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="expenseTypeValue"
                                value={get(listing, "expenseTypeValue")}
                                error={get(errors, "expenseTypeValue")}
                                onChange={handleInputChange}
                                placeholder={"Enter year"}
                                disabled={disableInputs || listing.expenseType !== "baseYear"}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={4}>
                            <BSInput
                                id="askingRentMax"
                                label={displayRequired ? "Base Rent per SF per Mth*" : "Base Rent per SF per Mth"}
                                helperText={disableForOutside ? "" : displayRequired ? "Required" : ""}
                                errorText={disableForOutside ? "" : "Required (ex. 981.01)"}
                                value={get(listing, "askingRentMax")}
                                error={disableForOutside ? "" : get(errors, "askingRentMax")}
                                onChange={handleInputChange}
                                placeholder='_._SF/MTH "0" entry = Call Broker'
                                startAdornment="$"
                                disabled={disableInputs}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BSInput
                                id="baseRentPerYear"
                                label={displayRequired ? "Base Rent per sf per year*" : "Base Rent per SF per Year"}
                                helperText={disableForOutside ? "" : displayRequired ? "Required" : ""}
                                errorText={disableForOutside ? "" : "Required (ex. 981.01)"}
                                value={get(listing, "baseRentPerYear")}
                                error={disableForOutside ? "" : get(errors, "baseRentPerYear")}
                                onChange={handleInputChange}
                                placeholder='_._SF/YR  "0" entry = Call Broker'
                                startAdornment="$"
                                disabled={disableInputs}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BSInput
                                id="askingRentPerMonth"
                                label={displayRequired ? "RENT PER MONTH*" : "RENT PER MONTH"}
                                helperText={disableForOutside ? "" : displayRequired ? "Required" : ""}
                                errorText={disableForOutside ? "" : "Required (ex. 981.01)"}
                                value={get(listing, "askingRentPerMonth")}
                                error={disableForOutside ? "" : get(errors, "askingRentPerMonth")}
                                onChange={handleInputChange}
                                placeholder="_._/MTH"
                                startAdornment="$"
                                disabled={disableInputs}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={6}>
                            <BSInput
                                id="nnnPerMonth"
                                label="Operating/CAM/NNN Cost/Mth"
                                value={get(listing, "nnnPerMonth")}
                                error={get(errors, "nnnPerMonth")}
                                onChange={handleInputChange}
                                placeholder="_._SF/MTH"
                                startAdornment="$"
                                disabled={disableInputs}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="nnnPerYear"
                                label="Operating/CAM/NNN Cost/Yr"
                                value={get(listing, "nnnPerYear")}
                                error={get(errors, "nnnPerYear")}
                                onChange={handleInputChange}
                                placeholder="_._SF/YR"
                                startAdornment="$"
                                disabled={disableInputs}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={6}>
                            <BSInput
                                id="totalMonthlyRent"
                                type="number"
                                label="Total Rent per SF per Mth"
                                errorText={disableForOutside ? "" : "Required (ex. 981.01)"}
                                value={get(listing, "totalMonthlyRent")}
                                error={disableForOutside ? "" : get(errors, "totalMonthlyRent")}
                                onChange={handleInputChange}
                                placeholder="_._SF/MTH"
                                startAdornment="$"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="totalRentPerYear"
                                type="number"
                                label="Total Rent per SF per Yr"
                                errorText={disableForOutside ? "" : "Required (ex. 981.01)"}
                                value={get(listing, "totalRentPerYear")}
                                error={disableForOutside ? "" : get(errors, "totalRentPerYear")}
                                onChange={handleInputChange}
                                placeholder="$_._SF/YR"
                                startAdornment="$"
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}

            {(toLower(get(listing, "listingType")) === "sale" ||
                toLower(get(listing, "listingType")) === "for sale") && (
                <div>
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={12}>
                            <BSInput
                                id="listPrice"
                                label={displayRequired ? "List Price*" : "List Price"}
                                helperText={displayRequired ? "Required" : ""}
                                errorText="Required (ex. 98101)"
                                value={get(listing, "listPrice")}
                                error={get(errors, "listPrice")}
                                onChange={handleInputChange}
                                startAdornment="$"
                                placeholder='Enter amount "0" entry = Call Broker'
                                disabled={disableInputs}
                                numeric
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={useType !== "Land" && useType !== "Hospitality" ? 6 : 12}>
                            <BSInput
                                id="capRate"
                                label="Cap Rate"
                                value={get(listing, "capRate")}
                                error={get(errors, "capRate")}
                                onChange={handleInputChange}
                                errorText="Must be a number (ex. 5.7)"
                                placeholder="Enter cap rate % (optional)"
                                disabled={disableInputs}
                                endAdornment="%"
                                numeric
                            />
                        </Grid>
                        {useType !== "Land" && useType !== "Hospitality" && (
                            <Grid item xs={6}>
                                <BSInput
                                    id="attributes.percentLeased"
                                    label="OCCUPANCY RATE"
                                    placeholder="Enter occupancy rate"
                                    error={get(errors, "attributes.percentLeased")}
                                    value={get(listing, "attributes.percentLeased")}
                                    onChange={handleInputChange}
                                    disabled={disableInputs}
                                    endAdornment="%"
                                    numeric
                                />
                            </Grid>
                        )}
                    </Grid>
                </div>
            )}

            <h3>Commission Structure</h3>
            <Grid container alignItems="flex-start" spacing={8}>
                <Grid item xs={12}>
                    <BSInput
                        id="procuringBrokerCommission"
                        label="Procuring Broker Commission %"
                        value={get(listing, "procuringBrokerCommission")}
                        error={get(errors, "procuringBrokerCommission")}
                        onChange={handleInputChange}
                        placeholder="Enter %"
                        disabled={disableInputs}
                        endAdornment="%"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default withStyles(styles)(FinancialInformation);
