import types from "./types";

const loadTransactionsForUser = (transactions, transactionsType) => {
    return {
        type: types.LOAD_TRANSACTIONS_FOR_USER,
        transactions: transactions,
        transactionsType: transactionsType,
    };
};

const loadTransaction = (transaction) => {
    return {
        type: types.LOAD_TRANSACTION,
        transaction: transaction,
    };
};

const handleInput = (field, value) => {
    return {
        type: types.ADD_DEBT_EQUITY_TRANSACTION_INPUT,
        field: field,
        value: value,
    };
};

const setDebtEquityArray = (pathToArray, array) => {
    return {
        type: types.SET_DEBT_EQUITY_ARRAY,
        pathToArray: pathToArray,
        array: array,
    };
};

const filter = (query) => {
    return {
        type: types.FILTER_DEBT_EQUITY_TRANSACTIONS,
        filterQuery: query,
    };
};

const sort = (columnName, sortDirection) => {
    return {
        type: types.SORT_DEBT_EQUITY_TRANSACTIONS,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

const switchTab = (tab) => {
    return {
        type: types.SWITCH_TRANSACTIONS_TAB,
        tab: tab,
    };
};

const countResults = (tab, numResults) => {
    return {
        type: types.COUNT_DEBT_EQUITY_RESULTS,
        numResults: numResults,
        tab: tab,
    };
};

export default {
    loadTransactionsForUser,
    loadTransaction,
    handleInput,
    setDebtEquityArray,
    filter,
    sort,
    switchTab,
    countResults,
};
