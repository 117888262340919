import React from "react";
import { connect } from "react-redux";
import DocumentPropertyManagementSnapshot from "./DocumentPropertyManagementSnapshot";
import operations from "./ducks/operations";

const DocumentPropertyManagementSnapshotContainer = (props) => {
    const { viewDocument, downloadDocument, snapshotMediaAssets } = props;
    return (
        <DocumentPropertyManagementSnapshot
            savedDocuments={snapshotMediaAssets}
            viewDocument={viewDocument}
            downloadDocument={downloadDocument}
        />
    );
};

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
    return {
        viewDocument: (url, key) => {
            dispatch(operations.viewDocument(url, key));
        },
        downloadDocument: (document) => {
            dispatch(operations.downloadDocument(document));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPropertyManagementSnapshotContainer);
