import validate from "validate.js";
import { constraints } from "../../utils/validation";
import mapValues from "lodash/mapValues";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";

const voucherValidationSchema = {
    submittedDate: {
        ...constraints.required,
        ...constraints.date,
    },
    "deal.dealKey": {
        ...constraints.required,
        ...constraints.id,
    },
    paymentMethod: {
        ...constraints.required,
    },
};
const installmentValidationSchema = {
    amount: {
        ...constraints.required,
        ...constraints.number,
    },
    dueDate: {
        ...constraints.required,
        ...constraints.date,
    },
};

const voucherValidationSchemaNoOutside = {
    submittedDate: {
        ...constraints.required,
        ...constraints.date,
    },
    "deal.dealKey": {
        ...constraints.required,
        ...constraints.id,
    },
    paymentMethod: {
        ...constraints.required,
    },
};

const splitValidationSchema = {
    firstName: {
        ...constraints.required,
    },
    netPercent: {
        ...constraints.required,
        ...constraints.number,
    },
    representationType: {
        ...constraints.required,
    },
    netAmount: {
        ...constraints.required,
        ...constraints.number,
    },
    department: {
        ...constraints.required,
    },
};

const outsideSplitValidationSchema = {
    firstName: {
        ...constraints.required,
    },
    netPercent: {
        ...constraints.required,
        ...constraints.number,
    },
    representationType: {
        ...constraints.required,
    },
    netAmount: {
        ...constraints.required,
        ...constraints.number,
    },
};

const runnerValidationSchema = {
    firstName: {
        ...constraints.required,
    },
    percentOfBrokerNet: {
        ...constraints.required,
        ...constraints.number,
    },
    amount: {
        ...constraints.required,
        ...constraints.number,
    },
};

const splitModalValidationSchema = {
    firstName: {
        ...constraints.required,
    },
    netPercent: {
        ...constraints.required,
        ...constraints.number,
    },
    netAmount: {
        ...constraints.required,
        ...constraints.number,
    },
    companyName: {
        ...constraints.required,
    },
    companyAddress: {
        ...constraints.required,
    },
    companyCity: {
        ...constraints.required,
    },
    companyState: {
        ...constraints.required,
    },
    companyZip: {
        ...constraints.required,
        ...constraints.id,
    },
    representationType: {
        ...constraints.required,
    },
};

const addlSplitModalValidationWhenFreeFormDisabledSchema = {
    commissionComment: {
        ...constraints.required,
    },
};

const validateVoucher = (voucher) => {
    return validate(voucher, voucherValidationSchema, { fullMessages: false });
};
const validateInstallment = (installment) => {
    return validate(installment, installmentValidationSchema, { fullMessages: false });
};

const validateVoucherNoOutside = (voucher) => {
    return validate(voucher, voucherValidationSchemaNoOutside, { fullMessages: false });
};

const validateSplit = (split) => {
    return validate(split, splitValidationSchema, { fullMessages: false });
};

const validateOutsideSplit = (split) => {
    return validate(split, outsideSplitValidationSchema, { fullMessages: false });
};
const validateRunner = (runner) => {
    return validate(runner, runnerValidationSchema, { fullMessages: false });
};

const validateSplitModal = (values, commissionType) => {
    let errors = { ...validate(values, splitModalValidationSchema, { fullMessages: false }) };
    if (commissionType === "REFERRAL") {
        errors = {
            ...errors,
            ...validate(values, addlSplitModalValidationWhenFreeFormDisabledSchema, { fullMessages: false }),
        };
    }
    return errors;
};

const validateInstallments = (installments, currentInstallment) => {
    let errors = {};
    console.log(installments);
    console.log(currentInstallment);
    if (installments) {
        for (let i = currentInstallment - 1; i < installments.length; i++) {
            const validationResponse = validateInstallment(installments[i]);
            console.log(validationResponse);
            if (isEmpty(validationResponse)) {
                continue;
            } else {
                set(errors, `${i}`, {
                    ...mapValues(validationResponse, (value, key) => {
                        return value[0];
                    }),
                });
            }
        }
        return isEmpty(errors) ? null : errors;
    } else {
        return null;
    }
};

export default {
    validateVoucher,
    validateVoucherNoOutside,
    validateSplit,
    validateRunner,
    validateSplitModal,
    validateInstallments,
    validateOutsideSplit,
};
