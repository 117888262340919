import React, { Component } from "react";
import { connect } from "react-redux";
import VoucherForm from "./VoucherForm";
import operations from "./ducks/operations";
import searchOperations from "../search/ducks/operations";
import actions from "./ducks/actions";
import Comments from "../comments/CommentsContainer";
import DocumentDrop from "../documents/DocumentDropContainer";
import VoucherToolbar from "./VoucherToolbar";
import Grid from "@material-ui/core/Grid";
import utils from "../utils/index";
import comments_operations from "../comments/ducks/operations";
import comments_constants from "../comments/constants";
import get from "lodash/get";
import toLower from "lodash/toLower";
import { documentDisabledStatuses } from "./constants";
import documentTypes from "../documents/documentTypes";
import ConsultingDealContainer from "./ConsultingDealContainer";
import map from "lodash/map";
import includes from "lodash/includes";

class VoucherContainer extends Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.getVoucher(id);
    }

    componentWillUnmount() {
        this.props.handleClearDealState();
    }

    render() {
        const { id } = this.props.match.params;
        const isBroker = this.props.userProfile.role === "broker";
        const isClosed = this.props.voucher.voucherStatus === "Closed";

        return this.props.voucher.voucherTransactionType === "Consulting" ? (
            <ConsultingDealContainer {...this.props} isBrokerAndClosedVoucher={isBroker && isClosed} />
        ) : (
            this.props.voucherAccessAllowed && (
                <div>
                    <VoucherToolbar {...this.props} />
                    <Grid container spacing={16}>
                        <Grid item xs={6}>
                            <VoucherForm {...this.props} />
                        </Grid>
                        <Grid item xs={6}>
                            <DocumentDrop
                                entityKey={id}
                                type="voucher"
                                documentTypes={toLower(get(this.props.voucher, "voucherTransactionType", null))}
                                voucherStatus={get(this.props.voucher, "voucherStatus", null)}
                                options={this.props.determineDocumentOptions}
                                formDisabled={get(
                                    documentDisabledStatuses,
                                    `${this.props.userProfile.role}.${this.props.voucher.voucherStatus}`,
                                    true
                                )}
                                errors={this.props.utilsErrors.documents}
                                parentType="VOUCHER"
                                stateCode={get(
                                    this.props.voucher.deal,
                                    "listing.property.primaryAddress.city.state.stateCode",
                                    null
                                )}
                                isBrokerAndClosedVoucher={isBroker && isClosed}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Comments
                                parentRecordType={comments_constants.VOUCHER_PARENT_RECORD_TYPE}
                                parentRecordId={id}
                            />
                        </Grid>
                    </Grid>
                </div>
            )
        );
    }
}

const determineDocumentOptions = (user, role, voucher, personKey, authRole) => {
    const allDocTypes = get(documentTypes, "allDocTypesList");
    const isCDT = includes(["dataEntry", "dataAdmin"], role);
    const isBroker = includes(["broker"], role);
    const isAcc = includes(["accounting"], role);

    let options = {
        canView: [],
        canDelete: [],
        canUpload: [],
    };

    let voucherDocTypes = [];
    if (includes(["lease", "for lease"], get(voucher, "voucherTransactionType", "").toLowerCase())) {
        voucherDocTypes = [
            "COMMISSION_PAYMENT",
            "FULLY_EXECUTED_LEASE",
            "INVOICE",
            "MASTER_LEASE_IF_SUBLEASE",
            "W9",
            "OTHER",
        ];
    } else {
        voucherDocTypes = ["COMMISSION_PAYMENT", "INVOICE", "W9", "OTHER"];
    }

    if (!includes(["Closed"], voucher.voucherStatus)) {
        options.canUpload = ["COMMISSION_PAYMENT"];
    }
    if (includes(["Awaiting Comp"], voucher.voucherStatus) && isCDT) {
        options.canView = [...allDocTypes];
        options.canDelete = [...voucherDocTypes];
        options.canUpload = [...voucherDocTypes];
    } else if (includes(["Awaiting Payment"], voucher.voucherStatus)) {
        if (isCDT || isAcc) {
            options.canView = [...allDocTypes];
            options.canDelete = [...voucherDocTypes];
            options.canUpload = [...voucherDocTypes];
        } else if (isBroker) {
            options.canView = [...allDocTypes];
            options.canDelete = [];
            options.canUpload = [...voucherDocTypes];
        } else {
            options.canView = [...allDocTypes];
            options.canDelete = [...voucherDocTypes];
            options.canUpload = [...voucherDocTypes];
        }
    } else if (includes(["Ready For Accounting"], voucher.voucherStatus)) {
        if (isCDT || isAcc) {
            options.canView = [...allDocTypes];
            options.canDelete = [...voucherDocTypes];
            options.canUpload = [...voucherDocTypes];
        } else if (isBroker) {
            options.canView = [...allDocTypes];
            options.canDelete = [];
            options.canUpload = [];
        } else {
            options.canView = [...allDocTypes];
            options.canDelete = [...voucherDocTypes];
            options.canUpload = [...voucherDocTypes];
        }
    } else if (includes(["Closed"], voucher.voucherStatus)) {
        if (authRole === "dataAdmin") {
            options.canView = [...allDocTypes];
            options.canDelete = [...allDocTypes];
            options.canUpload = [...voucherDocTypes];
        } else {
            options.canView = [...allDocTypes];
            options.canDelete = [];
            options.canUpload = [...voucherDocTypes];
        }
    } else {
        options = null;
    }

    return options;
};

const voucherAccessAllowed = (user, role, personKey, brokerCommissions, voucher) => {
    const brokerPersonKeys = map(brokerCommissions, (commission) => {
        return get(commission, "brokerAgent.person.personKey");
    });
    const isBrokerOnDeal = includes(brokerPersonKeys, personKey);
    const isCreatedByUser = user === voucher.auditCreatedBy;
    const isDataEntryOwner = voucher.dataEntryOwner && user === voucher.dataEntryOwner;
    const isLastModifiedByUser = user === voucher.auditLastModifiedBy;
    const isPortlandDeal = includes("portlandBrokerAdmin", role) && voucher.deal.portlandDeal;
    const isArizonaDeal = includes("arizonaBrokerAdmin", role) && voucher.deal.arizonaDeal;

    return (
        isBrokerOnDeal ||
        isCreatedByUser ||
        isDataEntryOwner ||
        isLastModifiedByUser ||
        isPortlandDeal ||
        isArizonaDeal ||
        includes(["dataEntry", "dataAdmin", "accounting", "evp"], role)
    );
};

const mapStateToProps = (state) => {
    return {
        userProfile: state.authReducer.userProfile,
        authenticatedUserProfile: state.authReducer.authenticatedUserProfile,
        valid: state.authReducer.validFormValues,
        snackbarOpen: state.authReducer.snackbarOpen,
        isSaving: state.utilsReducer.isSaving,
        saveError: state.voucherReducer.saveError ? state.voucherReducer.saveError : null,
        lastSaved: state.voucherReducer.lastSaved ? state.voucherReducer.lastSaved : null,
        brokerCommissions: state.voucherReducer.voucher.brokerCommissions,
        outsideSplits: state.voucherReducer.outsideSplits,
        outsideSplitModal: state.voucherReducer.outsideSplitModal,
        numComments: state.commentsReducer.comments.length,
        voucher: state.voucherReducer.voucher,
        errors: state.voucherReducer.errors,
        utilsErrors: state.utilsReducer.errors,
        outsideSplitsPresent: state.voucherReducer.outsideSplitsPresent,
        searchResults: state.utilsReducer.searchResults,
        autocompleteResults: state.searchReducer.autocompleteResults,
        snackbar: state.utilsReducer.snackbar,
        documentWarning: state.voucherReducer.documentWarning,
        confirmDialog: state.utilsReducer.confirmDialog,
        elementsSaving: state.utilsReducer.elementsSaving,
        usedDocTypes: state.documentsReducer.usedDocTypes,
        voucherAccessAllowed: voucherAccessAllowed(
            state.authReducer.userProfile.user,
            state.authReducer.userProfile.role,
            state.authReducer.userProfile.personKey,
            state.voucherReducer.voucher.brokerCommissions,
            state.voucherReducer.voucher
        ),
        determineDocumentOptions: determineDocumentOptions(
            state.authReducer.userProfile.user,
            state.authReducer.userProfile.role,
            get(state.voucherReducer, "voucher", {}),
            state.authReducer.userProfile.personKey,
            get(state.authReducer.authenticatedUserProfile, "role", null)
        ),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getVoucher: (voucherKey) => {
            dispatch(operations.handleGetVoucher(voucherKey));
        },
        handleSubmit: (save, callback, validateDocs = true) => {
            dispatch(utils.operations.toggleConfirm());
            dispatch(
                operations.handleSubmit(save, callback, "submit", true, {
                    validateDocs: validateDocs,
                    validateForm: true,
                    validateNonRequired: false,
                })
            );
        },
        handleSave: () => {
            dispatch(
                operations.handleSubmit(true, null, "saveAndContinue", false, {
                    validateDocs: false,
                    validateForm: false,
                    validateNonRequired: true,
                })
            );
        },
        handleSaveAndExit: () => {
            dispatch(
                operations.handleSubmit(true, null, "saveAndExit", true, {
                    validateDocs: false,
                    validateForm: false,
                    validateNonRequired: true,
                })
            );
        },
        handleSubmitToReadyForPayment: (save, callback) => {
            dispatch(
                operations.handleSubmit(save, callback, "submit", true, {
                    validateDocs: true,
                    validateForm: true,
                    validateNonRequired: false,
                })
            );
        },
        toggleSnackBar: (open, variant, message) => {
            dispatch(utils.operations.snackbar(open, variant, message));
        },
        handleAutosaveNotSuccess: (isSaving, err) => {
            dispatch(operations.handleAutosaveNotSuccess(isSaving, err));
        },
        handleAutosaveSuccess: () => {
            dispatch(operations.handleAutosaveSuccess());
        },
        handleAddBrokerCommission: () => {
            dispatch(operations.handleAddBrokerCommission());
        },
        handleCreateOutsideSplitDraft: (enableFreeFormSearch, commissionType) => {
            dispatch(operations.createOutsideSplitDraft(enableFreeFormSearch, commissionType));
        },
        handleSaveOutsideSplit: (key, split) => {
            dispatch(operations.saveOutsideSplit(key, split));
        },
        handleDeleteOutsideSplit: (splitKey) => {
            dispatch(operations.deleteOutsideSplit(splitKey));
        },
        toggleOutsideSplitModal: (options) => {
            dispatch(operations.toggleOutsideSplitModal(options));
        },
        handleAddRunner: (parent) => {
            dispatch(operations.handleAddRunner(parent));
        },
        handleRemoveCommissionOrRunner: (options) => {
            dispatch(operations.handleRemoveCommissionOrRunner(options.key, options.parent, options.outside));
        },
        updateSplit: (splitKey, field, value, source) => {
            dispatch(operations.updateSplit(splitKey, field, value, source));
        },
        updateRunner: (parent, key, field, value) => {
            dispatch(operations.updateRunner(parent, key, field, value));
        },
        toggleComments: () => {
            dispatch(comments_operations.toggleComments());
        },
        setInitialPaymentMethod: (value) => {
            dispatch(operations.voucherChange("paymentMethod", value));
        },
        handleVoucherChange: (event) => {
            dispatch(operations.voucherChange(event.target.id, event.target.value));
        },
        handleCheckboxClick: (name, event) => {
            dispatch(operations.handleInput("voucher." + name, event.target.checked));
        },
        handleVoucherChangeAndCalculateSplits: (km, event) => {
            dispatch(operations.voucherChangeAndCalculateSplits(event.target.id, event.target.value, km));
        },
        determineOutsidePresent: (isPresent) => {
            dispatch(actions.determineOutsidePresent(isPresent));
        },
        handleDeleteVoucher: (voucherKey) => {
            dispatch(operations.deleteVoucher(voucherKey));
        },
        handleSearchPerson: (inputValue) => {
            dispatch(operations.searchPerson(inputValue));
        },
        handleSearchOutsideBroker: (inputValue) => {
            dispatch(searchOperations.searchOutsideBroker(inputValue));
        },
        handleSearchCompany: (inputValue, companyType) => {
            dispatch(searchOperations.searchCompany(inputValue, companyType));
        },
        handleSearchBroker: (inputValue) => {
            dispatch(searchOperations.autoCompleteBroker(inputValue));
        },
        handleDocumentWarning: (open, proceed, docs) => {
            dispatch(operations.handleDocumentWarning(open, proceed, docs));
        },
        handleConfirmWarning: (callback) => {
            dispatch(operations.handleDocumentWarning(false, true, [], null));
            dispatch(
                operations.saveVoucher(callback, "submit", true, {
                    validateDocs: false,
                    validateForm: true,
                    validateNonRequired: false,
                })
            );
        },
        toggleConfirm: (onSubmit) => {
            dispatch(utils.operations.toggleConfirm(onSubmit));
        },
        handleInput: (path, value) => {
            dispatch(operations.handleInput("voucher." + path, value));
        },
        handleReducerLevelInput: (path, value) => {
            dispatch(operations.handleInput(path, value));
        },
        handleClearDealState: () => {
            dispatch(
                operations.handleInput("voucher", {
                    deal: {},
                    brokerCommissions: {},
                })
            );
        },
        handleAddArrayElement: (path) => {
            dispatch(operations.addArrayElement("voucher.deal." + path));
        },
        handleDeleteArrayElement: (path, index) => {
            dispatch(operations.deleteArrayElement("voucher.deal." + path, index));
        },
        handleUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.handleInput("voucher." + arrayPath + "[" + index + "]." + field, value));
        },
        handleCalculateSplits: () => {
            dispatch(operations.handleCalculateSplits());
        },
        handleSearchKmBrokers: (input) => {
            dispatch(searchOperations.searchKMBrokers(input));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherContainer);
