import actions from "./actions";
import utils from "../../utils/index";
import { push } from "connected-react-router";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import get from "lodash/get";
import set from "lodash/set";
import toLower from "lodash/toLower";
import isNull from "lodash/isNull";
import compact from "lodash/compact";
import validation from "./validation";
import listingValidationNonRequired from "./listingValidationNonRequired";
import propertyConstants from "../../property/constants";
import property_operations from "../../property/ducks/operations";
import propertyOperations from "../../property/ducks/operations";
import numeral from "numeral";
import uniq from "lodash/uniq";
import constants from "../constants";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import comments_operations from "../../comments/ducks/operations";
import document_operations from "../../documents/ducks/operations";
import searchOperations from "../../search/ducks/operations";
import searchActions from "../../search/ducks/actions";
import utilOperations, {
    convertForIntegerValidation,
    formatDollarPreserveNil,
    stripCommas,
} from "../../utils/operations";
import { request } from "../../utils/axios-wrapper";
import includes from "lodash/includes";
import ReactGA from "react-ga4";

const getInitialListings = (role, user) => {
    return (dispatch, getState) => {
        switch (role) {
            case "broker":
                dispatch(getListingsByStatus("broker_requested"));
                dispatch(getBrokerActiveListings("broker_open"));
                break;
            case "evp":
                dispatch(getListingsByStatus("broker_requested"));
                dispatch(getBrokerActiveListings("broker_open"));
                break;
            case "dataEntry":
                dispatch(getListingsByStatus("data_team_unassigned"));
                dispatch(getListingsByStatus("data_team_open"));
                break;
            case "dataAdmin":
                dispatch(getListingsByStatus("data_team_unassigned"));
                dispatch(getListingsByStatus("data_team_open"));
                break;
            case "portlandBrokerAdmin":
                dispatch(getListingsByStatus("broker_requested"));
                dispatch(getBrokerActiveListings("broker_open"));
                break;
            case "arizonaBrokerAdmin":
                dispatch(getListingsByStatus("broker_requested"));
                dispatch(getBrokerActiveListings("broker_open"));
                break;
            default:
                dispatch(getListingsByStatus("broker_requested"));
                dispatch(getBrokerActiveListings("broker_open"));
                break;
        }
    };
};

const clearMyListings = () => {
    return (dispatch, getState) => {
        dispatch(actions.clearMyListings());
    };
};

const inputChange = (field, value) => {
    return (dispatch, _) => {
        dispatch(actions.inputChange(field, value));
    };
};

const handleListingInputChange = (field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.listingInputChange(field, value));
        dispatch(actions.updateInputChange(recalcFinancialInfo(getState().listingReducer.listing, field, value)));
    };
};

const recalcFinancialInfo = (listing, changedField, value) => {
    let askingRentMax = null;
    let baseRentPerYear = null;
    let askingRentPerMonth = null;
    let nnnPerMonth = null;
    let nnnPerYear = null;
    let totalMonthlyRent = null;
    let totalRentPerYear = null;
    let updates = {};

    if (changedField === "listing.sfAvail") {
        const sf = convertForIntegerValidation(value); //parseInt(value.replace(/[^0-9]/g, ''));
        if (!isNaN(sf)) {
            if (!isNaN(listing.askingRentMax)) {
                askingRentPerMonth = listing.askingRentMax * sf;
            }
        } else {
            askingRentPerMonth = "";
        }
    } else if (changedField === "listing.askingRentPerMonth") {
        if (!isNull(listing.nnnPerYear) && !isNaN(listing.nnnPerYear)) {
            nnnPerYear = parseFloat(listing.nnnPerYear);
            nnnPerMonth = parseFloat(listing.nnnPerMonth);
        } else {
            nnnPerYear = 0;
            nnnPerMonth = 0;
        }
        if (!isNaN(value)) {
            const sf = convertForIntegerValidation(listing.sfAvail);
            if (!isNaN(listing.askingRentPerMonth) && !isNaN(sf)) {
                askingRentMax = value / sf;
                baseRentPerYear = (value * 12) / sf;
            }
            totalMonthlyRent = askingRentMax + nnnPerMonth;
            totalRentPerYear = baseRentPerYear + nnnPerYear;
        } else {
            baseRentPerYear = "";
            askingRentMax = "";
            if (nnnPerYear) {
                totalMonthlyRent = nnnPerMonth;
                totalRentPerYear = nnnPerYear;
            } else {
                totalMonthlyRent = "";
                totalRentPerYear = "";
            }
        }
    } else if (changedField === "listing.askingRentMax") {
        if (!isNull(listing.nnnPerYear) && listing.nnnPerYear) {
            nnnPerYear = parseFloat(listing.nnnPerYear);
            nnnPerMonth = parseFloat(listing.nnnPerMonth);
        } else {
            nnnPerYear = 0;
            nnnPerMonth = 0;
        }
        if (!isNaN(value) && value) {
            const sf = convertForIntegerValidation(listing.sfAvail);
            if (!isNaN(sf)) {
                askingRentPerMonth = value * sf;
            }
            baseRentPerYear = value * 12;
            totalMonthlyRent = parseFloat(value) + nnnPerMonth;
            totalRentPerYear = baseRentPerYear + nnnPerYear;
        } else {
            askingRentPerMonth = "";
            baseRentPerYear = "";
            if (!isNull(listing.nnnPerYear)) {
                totalMonthlyRent = nnnPerMonth;
                totalRentPerYear = nnnPerYear;
            } else {
                totalMonthlyRent = "";
                totalRentPerYear = "";
            }
        }
    } else if (changedField === "listing.baseRentPerYear") {
        if (!isNull(listing.nnnPerYear) && listing.nnnPerYear) {
            nnnPerYear = parseFloat(listing.nnnPerYear);
            nnnPerMonth = parseFloat(listing.nnnPerMonth);
        } else {
            nnnPerYear = 0;
            nnnPerMonth = 0;
        }
        if (!isNaN(value) && value) {
            const sf = convertForIntegerValidation(listing.sfAvail);
            askingRentMax = value / 12;
            totalMonthlyRent = askingRentMax + nnnPerMonth;
            totalRentPerYear = parseFloat(value) + nnnPerYear;

            if (!isNaN(sf)) {
                askingRentPerMonth = askingRentMax * sf;
            }
        } else {
            askingRentMax = "";
            askingRentPerMonth = "";
            if (nnnPerYear) {
                totalMonthlyRent = nnnPerMonth;
                totalRentPerYear = nnnPerYear;
            } else {
                totalMonthlyRent = "";
                totalRentPerYear = "";
            }
        }
    } else if (changedField === "listing.nnnPerMonth") {
        if (!isNull(value) && value) {
            nnnPerYear = value * 12;
            if (!isNaN(listing.askingRentMax)) {
                totalMonthlyRent = parseFloat(listing.askingRentMax) + parseFloat(value);
                totalRentPerYear = parseFloat(listing.baseRentPerYear) + nnnPerYear;
            }
        } else {
            if (!isNaN(listing.askingRentMax)) {
                totalMonthlyRent = listing.askingRentMax;
                totalRentPerYear = listing.baseRentPerYear;
            } else {
                totalMonthlyRent = "";
                totalRentPerYear = "";
            }
            nnnPerMonth = "";
            nnnPerYear = "";
        }
    } else if (changedField === "listing.nnnPerYear") {
        if (!isNull(value) && value) {
            nnnPerMonth = value / 12;
            if (!isNaN(listing.baseRentPerYear)) {
                totalMonthlyRent = parseFloat(listing.askingRentMax) + nnnPerMonth;
                totalRentPerYear = parseFloat(listing.baseRentPerYear) + parseFloat(value);
            }
        } else {
            if (!isNaN(listing.baseRentPerYear)) {
                totalMonthlyRent = listing.askingRentMax;
                totalRentPerYear = listing.baseRentPerYear;
            } else {
                totalMonthlyRent = "";
                totalRentPerYear = "";
            }
            nnnPerYear = "";
            nnnPerMonth = "";
        }
    }

    if (askingRentPerMonth != null && !isNaN(askingRentPerMonth)) {
        updates["askingRentPerMonth"] = askingRentPerMonth;
    }
    if (askingRentMax != null && !isNaN(askingRentMax)) {
        updates["askingRentMax"] = askingRentMax;
    }
    if (baseRentPerYear != null && !isNaN(baseRentPerYear)) {
        updates["baseRentPerYear"] = baseRentPerYear;
    }
    if (nnnPerMonth != null && !isNaN(nnnPerMonth)) {
        updates["nnnPerMonth"] = nnnPerMonth;
    }
    if (nnnPerYear != null && !isNaN(nnnPerYear)) {
        updates["nnnPerYear"] = nnnPerYear;
    }
    if (totalMonthlyRent != null && !isNaN(totalMonthlyRent)) {
        updates["totalMonthlyRent"] = totalMonthlyRent;
    }
    if (totalRentPerYear != null && !isNaN(totalRentPerYear)) {
        updates["totalRentPerYear"] = totalRentPerYear;
    }
    return updates;
};

const toggleRequestModal = (spaceType, transactionType) => {
    return (dispatch, getState) => {
        dispatch(actions.toggleRequestModal(spaceType, transactionType));
    };
};

const reorder = (data, startIndex, endIndex) => {
    const result = [...data];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const handleDragEnd = (dragResult, listings, tab) => {
    const { destination, source } = dragResult;
    if (!destination) {
        return (dispatch, getState) => {
            dispatch(actions.updateListingOrder(listings, tab));
        };
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return (dispatch, getState) => {
            dispatch(actions.updateListingOrder(listings, tab));
        };
    }

    const updatedListings = reorder(listings, dragResult.source.index, dragResult.destination.index);

    return (dispatch, getState) => {
        dispatch(updateSelectedListingOrder(updatedListings, tab));
        dispatch(actions.updateListingOrder(updatedListings, tab));

        if (tab === "listings_group") {
            let list = [];
            for (let i = 0; i < updatedListings.length; i++) {
                list.push(get(updatedListings[i], "listingKey", null));
            }
            dispatch(searchActions.setCurrentGroup("LISTINGS", list));
        }
    };
};

const updateSelectedListingOrder = (listings, tab) => {
    return (dispatch, getState) => {
        let selected = [];
        if (tab === "listings_group") {
            selected = getState().listingReducer.selectedListingsGroup;
        } else if (tab === "broker_open") {
            selected = getState().listingReducer.activeSelectedListings;
        } else {
            selected = getState().listingReducer.selectedListings;
        }
        let updatedSelected = new Map();

        listings.forEach((listing) => {
            let value = null;
            if (selected.get(listing.listingKey)) {
                value = true;
            } else {
                value = false;
            }
            updatedSelected.set(get(listing, "listingKey", null), value);
        });

        if (tab === "listings_group") {
            dispatch(actions.setGroupCheckedListings(updatedSelected));
        } else if (tab === "broker_open") {
            dispatch(actions.setActiveCheckedListings(updatedSelected));
        } else {
            dispatch(actions.setCheckedListings(updatedSelected));
        }
    };
};

const updateListingsForSort = (sortedData, tab) => {
    return (dispatch, getState) => {
        dispatch(actions.updateListingOrder(sortedData, tab));
        if (tab === "all_km_listings" || tab === "listings_group" || tab === "broker_open") {
            dispatch(updateSelectedListingOrder(sortedData, tab));
        }
    };
};

const selectAll = (value) => {
    return (dispatch, getState) => {
        let selected = cloneDeep(getState().listingReducer.selectedListings);
        let updateSelected = new Map();
        [...selected.keys()].forEach((key) => {
            updateSelected.set(key, value);
        });

        dispatch(actions.setCheckedListings(updateSelected));
    };
};

const selectAllGroup = (value) => {
    return (dispatch, getState) => {
        dispatch(setGroupCheckedListings(getState().listingReducer.listings["listings_group"], value));
    };
};

const setCheckedListings = (listings, value) => {
    return (dispatch, getState) => {
        let selected = new Map();
        listings.forEach((listing) => {
            selected.set(get(listing, "listingKey", null), value);
        });
        dispatch(actions.setCheckedListings(selected));
    };
};

const setGroupCheckedListings = (listings, value) => {
    return (dispatch, getState) => {
        let selected = new Map();
        listings.forEach((listing) => {
            selected.set(get(listing, "listingKey", null), value);
        });
        dispatch(actions.setGroupCheckedListings(selected));
    };
};

const setActiveCheckedListings = (listings, value) => {
    return (dispatch, getState) => {
        let selected = new Map();
        listings.forEach((listing) => {
            selected.set(get(listing, "listingKey", null), value);
        });
        dispatch(actions.setActiveCheckedListings(selected));
    };
};

const setSelectedFromSavedSearch = (listings) => {
    return (dispatch, getState) => {
        const selectedFromSave = getState().searchReducer.LISTINGS.selectedFromSave;
        let selected = new Map();
        let reorderedListings = [];

        // adds all the previously selected items to the reordered Array, and regenerates the map of selections for each listing key
        listings.forEach((listing) => {
            if (includes(selectedFromSave, get(listing, "listingKey", null))) {
                selected.set(get(listing, "listingKey", null), true);
                reorderedListings.push(listing);
            } else {
                selected.set(get(listing, "listingKey", null), false);
            }
        });
        // adds all listings that were not selected to the end of the reordered Array
        listings.forEach((listing) => {
            if (!includes(selectedFromSave, get(listing, "listingKey", null))) {
                reorderedListings.push(listing);
            }
        });

        dispatch(actions.setCheckedListings(selected));
        dispatch(actions.receiveListings(reorderedListings, "all_km_listings"));
    };
};

const searchAutocomplete = (input) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.search_property_autocomplete(input),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.searchResults(response.data));
            })
            .catch((err) => {
                dispatch(utilOperations.reportError(err, "Error in searchAutocomplete"));
            });
    };
};

const selectPropertyFromAutocomplete = (property) => {
    return (dispatch, getState) => {
        dispatch(actions.selectPropertyFromAutocomplete(property));
    };
};

const removeSelectedPropertyFromRequestModal = () => {
    return (dispatch, getState) => {
        dispatch(actions.removeSelectedPropertyFromRequestModal());
    };
};

const updateListingRequest = (field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateListingRequest(field, value));
    };
};

const requestListing = () => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        const state = getState().listingReducer;
        dispatch(actions.clearErrors());
        let validationResponse = validation.validateRequestListingFromExisting(
            get(state, "requestModal.selectedProperty")
        );
        if (validationResponse) {
            each(Object.keys(validationResponse), (e) => {
                dispatch(actions.addError(e, validationResponse[e][0]));
            });
            dispatch(utils.actions.loading(false));
            return false;
        } else {
            //save new listing as status draft
            saveListingCall(buildListingRequestFromModal(getState().listingReducer.requestModal, dispatch))
                .then((response) => {
                    dispatch(push("/listing/" + response.data.listingKey));
                })
                .catch((err) => {
                    dispatch(utils.actions.snackbar(true, "error", "Error trying to add listing: " + err));
                    dispatch(utilOperations.reportError(err, "listing:operations:requestListing - Error"));
                });
        }
        dispatch(actions.updateListingRequest("open", false));
    };
};

const getPropertyForListingCreation = (property) => {
    return (dispatch, _) => {
        request(
            {
                method: "get",
                url: propertyConstants.urls.get_property(property.property_id),
            },
            dispatch
        ).then((response) => {
            dispatch(selectPropertyFromAutocomplete(response.data));
        });
    };
};

const requestListingNewProperty = () => {
    return (dispatch, getState) => {
        const state = getState().listingReducer;
        dispatch(actions.clearErrors());
        let validationResponse = validation.validateRequestListingFromNew(get(state, "requestModal"));
        if (validationResponse) {
            each(Object.keys(validationResponse), (e) => {
                dispatch(actions.addError(e, validationResponse[e][0]));
            });
            return false;
        } else {
            //save new listing as status draft
            saveListingCall(buildListingRequestFromModal(getState().listingReducer.requestModal), dispatch)
                .then((response) => {
                    dispatch(push("/listing/" + response.data.listingKey));
                })
                .catch((err) => {
                    dispatch(utils.actions.snackbar(true, "error", "Error trying to add listing: " + err));
                    dispatch(utilOperations.reportError(err, "listing:operations:requestListingNewProperty Error"));
                });
            dispatch(actions.updateListingRequest("open", false));
        }
    };
};

const addListingGivenProperty = (property, useType, transactionType) => {
    return (dispatch, getState) => {
        const listing = {
            listingType: transactionType,
            attributes: {
                useType: useType,
            },
            status: "Draft",
            property: property,
        };
        saveListingCall(listing, dispatch)
            .then((response) => {
                dispatch(push("/listing/" + response.data.listingKey));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error trying to add listing: " + err));
                dispatch(utilOperations.reportError(err, "listing:operations:addListingGivenProperty Error"));
            });
    };
};

const initListing = (listingId, action) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        if (listingId) {
            dispatch(utils.actions.loading(false));
            dispatch(getListing(listingId));
            if (action === "duplicate") {
                dispatch(push("/listing/" + listingId));
                dispatch(actions.toggleDuplicateSuccessDialog(true));
            }
        } else if (!isEmpty(getState().listingReducer.requestModal)) {
            dispatch(initListingRequestFromRequestModal());
        }
    };
};

const initListingRequestFromRequestModal = () => {
    return (dispatch, getState) => {
        const listing = {
            listingType: get(getState().listingReducer.requestModal, "transactionType", null),
            attributes: {
                useType: get(getState().listingReducer.requestModal, "useType", null),
            },
            status: "Draft",
        };
        dispatch(actions.loadListing(listing));
        if (
            get(getState().listingReducer.requestModal, "tab") === "existing" &&
            get(getState().listingReducer.requestModal, "selectedProperty.propertyKey")
        ) {
            dispatch(
                property_operations.getProperty(
                    get(getState().listingReducer.requestModal, "selectedProperty.propertyKey")
                )
            );
            dispatch(
                handleListingInputChange(
                    "listing.property.propertyKey",
                    get(getState().listingReducer.requestModal, "selectedProperty.propertyKey")
                )
            );
        } else {
            let property = {};
            set(
                property,
                "propertyAttributes.propertyName",
                get(getState().listingReducer.requestModal, "newPropertyName", null)
            );
            set(property, "memberComments", get(getState().listingReducer.requestModal, "newPropertyComments", null));
            set(
                property,
                "primaryAddress.address1",
                get(getState().listingReducer.requestModal, "newPropertyAddress", null)
            );
            set(
                property,
                "primaryAddress.city.city",
                get(getState().listingReducer.requestModal, "newPropertyCity", null)
            );
            set(
                property,
                "primaryAddress.city.state.stateCode",
                get(getState().listingReducer.requestModal, "newPropertyState", null)
            );
            set(
                property,
                "primaryAddress.zipCode.zipCode",
                get(getState().listingReducer.requestModal, "newPropertyZip", null)
            );
            dispatch(handleListingInputChange("listing.property", property));
            dispatch(utils.actions.loading(false));
        }
    };
};

const buildListingRequestFromModal = (listingModalState) => {
    const listing = {
        listingType: get(listingModalState, "transactionType", null),
        attributes: {
            useType: get(listingModalState, "useType", null),
        },
        status: "Draft",
    };
    if (get(listingModalState, "tab") === "existing" && get(listingModalState, "selectedProperty.propertyKey")) {
        set(listing, "property", get(listingModalState, "selectedProperty"));
    } else {
        let property = {};
        set(property, "propertyAttributes.propertyName", get(listingModalState, "newPropertyName", null));
        set(property, "memberComments", get(listingModalState, "newPropertyComments", null));
        set(property, "primaryAddress.address1", get(listingModalState, "newPropertyAddress", null));
        set(property, "primaryAddress.city.city", get(listingModalState, "newPropertyCity", null));
        set(property, "primaryAddress.city.state.stateCode", get(listingModalState, "newPropertyState", null));
        set(property, "primaryAddress.zipCode.zipCode", get(listingModalState, "newPropertyZip", null));
        set(property, "propertyAttributes.propertyStatus", "Requested");
        set(listing, "property", property);
    }

    return listing;
};

const getListing = (key) => {
    return (dispatch, _) => {
        dispatch(utils.actions.loading(true));
        request(
            {
                url: constants.urls.get_listing(key),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(
                    actions.loadListing({
                        ...response.data,
                        listingBrokers: separateListingsBrokers(response.data),
                        isDivisible: get(response.data, "sfDivisibleTo") ? true : false,
                    })
                );
                dispatch(document_operations.getPropertyDocs(key));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utils.actions.snackbar(true, "error", "Error trying to retrieve listing: " + err));
                dispatch(utils.actions.loading(false));
                dispatch(utilOperations.reportError(err, "listing:operations:getListing Error"));
            });
    };
};

const duplicateListing = (key) => {
    return (dispatch, _) => {
        dispatch(utils.actions.loading(true));
        request(
            {
                url: constants.urls.duplicate_listing(key),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                if (response.data.listingKey) {
                    dispatch(actions.toggleDuplicateListingDialog(false));
                    dispatch(push("/listing/" + response.data.listingKey + "?action=duplicate"));
                    ReactGA.event("Listing Duplicated", {
                        listingKey: key,
                    });
                    window.location.reload();
                } else {
                    dispatch(utils.actions.snackbar(true, "error", "Error duplicating listing!"));
                }
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utils.actions.snackbar(true, "error", "Error duplicating listing!"));
                dispatch(actions.toggleDuplicateListingDialog(false));
                dispatch(utils.actions.loading(false));
                dispatch(utilOperations.reportError(err, "listing:operations:getListing Error"));
            });
    };
};

const clearListingState = () => {
    return (dispatch, _) => {
        dispatch(actions.loadListing({}));
        dispatch(utilOperations.resetConfirm());
    };
};

export const separateListingsBrokers = (listing) => {
    let kmListingBrokers = [];
    let outsideListingBrokers = [];
    if (get(listing, "listingBrokers") !== null) {
        each(get(listing, "listingBrokers", []), (broker) => {
            if (broker !== null) {
                if (get(broker, "brokerAgentKey.kmBrokerFlag") === true) {
                    kmListingBrokers.push(broker);
                } else {
                    outsideListingBrokers.push(broker);
                }
            }
        });
    }
    if (kmListingBrokers.length === 0) {
        kmListingBrokers.push({ listingKey: listing.listingKey, brokerAgentKey: { kmBrokerFlag: true } });
    }
    if (outsideListingBrokers.length === 0) {
        outsideListingBrokers.push({ listingKey: listing.listingKey, brokerAgentKey: { kmBrokerFlag: false } });
    }
    return {
        kmListingBrokers: kmListingBrokers,
        outsideListingBrokers: outsideListingBrokers,
    };
};

const processListingResponse = (response) => {
    return (dispatch, _) => {
        dispatch(
            actions.loadListing({
                ...response.data,
                listingBrokers: separateListingsBrokers(response.data),
            })
        );
    };
};

const saveListing = () => {
    return saveListingCommon(false, "listingSaveAndContinue");
};

const saveAndExit = () => {
    return saveListingCommon(true, "listingSaveAndExit");
};

const saveListingCommon = (shouldExit, elementSaving) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        dispatch(utils.actions.somethingSaving(true, elementSaving));

        const errors = listingValidationNonRequired.validateListing(getState().listingReducer.listing);
        if (isEmpty(errors) || isNil(errors)) {
            saveListingCall(getState().listingReducer.listing)
                .then((response) => {
                    dispatch(utils.actions.somethingSaving(false, elementSaving));
                    if (shouldExit) {
                        dispatch(push(`/listings`));
                    }
                    dispatch(utils.actions.snackbar(true, "success", "Success"));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(utils.actions.somethingSaving(false, elementSaving));
                    dispatch(utils.actions.snackbar(true, "error", "Could not save"));
                    dispatch(
                        utilOperations.reportError(err, "listing:operations:saveAndExit - Error trying to save listing")
                    );
                });
        } else {
            each(Object.keys(errors), (e) => {
                dispatch(utils.actions.addError("listing", errors));
            });
            dispatch(utils.actions.somethingSaving(false, elementSaving));
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                )
            );
        }
    };
};

const saveListingCall = (listing, dispatch) => {
    return request(
        {
            method: "post",
            url: constants.urls.create_or_update_listing(),
            data: buildListingRequest(listing),
            headers: { "Content-Type": "application/json" },
        },
        dispatch
    );
};

const fieldsToStripCommasFor = [
    "sfDivisibleTo",
    "sfAvail",
    "improvementAllowance",
    "askingRentMax",
    "askingRentPerMonth",
    "baseRentPerYear",
    "nnnPerMonth",
    "nnnPerYear",
    "listPrce",
    "capRate",
    "attributes.officeBreakdownSfRate",
    "attributes.blendedRate",
    "attributes.warehouseShellBreakdownRate",
    "attributes.auditoriumSeatsCount",
    "attributes.classroomCount",
    "attributes.anchorSf",
    "attributes.padSf",
    "attributes.shopSf",
];

const fieldsToFormatAsInt = [
    "attributes.parkingReservedSpaceCount",
    "attributes.largestContigSf",
    "attributes.industrialSf",
    "attributes.officeSf",
    "attributes.yardSf",
    "attributes.carportParkingSpaceCount",
    "attributes.parkingGarageSpaceCount",
    "attributes.surfaceParkingSpaceCount",
    "attributes.classroomCount",
    "attributes.auditoriumSeatsCount",
    "attributes.parkingTotal",
    "attributes.numBldg",
    "attributes.sfAvail",
    "attributes.anchorSf",
];

const buildListingRequest = (listing) => {
    let data = {
        ...listing,
        totalMonthlyRent: formatDollarPreserveNil(listing.totalMonthlyRent),
        totalRentPerYear: formatDollarPreserveNil(listing.totalRentPerYear),
        nnnPerMonth: formatDollarPreserveNil(listing.nnnPerMonth),
        nnnPerYear: formatDollarPreserveNil(listing.nnnPerYear),
        operatingExpensePerMonth: formatDollarPreserveNil(listing.operatingExpensePerMonth),
        operatingExpensePerYear: formatDollarPreserveNil(listing.operatingExpensePerYear),
        listPrice: formatDollarPreserveNil(listing.listPrice),
        capRate: formatDollarPreserveNil(listing.capRate),
        baseRentPerYear: formatDollarPreserveNil(listing.baseRentPerYear),
        askingRentMax: formatDollarPreserveNil(listing.askingRentMax),
        askingRentMin: formatDollarPreserveNil(listing.askingRentMin),
        askingRentPerMonth: formatDollarPreserveNil(listing.askingRentPerMonth),
        availabilityStatus: listing.availabilityStatus == null ? "Occupied" : listing.availabilityStatus,

        dateAvailable: utils.operations.serializeLocalDate(listing.dateAvailable),
        expirationDate: utils.operations.serializeLocalDate(listing.expirationDate),
        leaseExpirationDate: utils.operations.serializeLocalDate(listing.leaseExpirationDate),
        dateListed: utils.operations.serializeLocalDate(listing.dateListed),
        attributes: {
            ...listing.attributes,
            completionDate: utils.operations.serializeLocalDate(get(listing, "attributes.completionDate", null)),
            demolitionDate: utils.operations.serializeLocalDate(get(listing, "attributes.demolitionDate", null)),

            avgAnnualPsfRate: formatDollarPreserveNil(get(listing, "attributes.avgAnnualPsfRate", null)),
            avgMonthlyPsfRate: formatDollarPreserveNil(get(listing, "attributes.avgMonthlyPsfRate", null)),
            avgRevPar: formatDollarPreserveNil(get(listing, "attributes.avgRevPar", null)),
            blendedRate: formatDollarPreserveNil(get(listing, "attributes.blendedRate", null)),
            warehouseShellBreakdownRate: formatDollarPreserveNil(
                get(listing, "attributes.warehouseShellBreakdownRate", null)
            ),
            yardBreakdownSfRate: formatDollarPreserveNil(get(listing, "attributes.yardBreakdownSfRate", null)),
        },
        listingBrokers: [
            ...get(listing, "listingBrokers.kmListingBrokers", []),
            ...get(listing, "listingBrokers.outsideListingBrokers", []),
        ],
        property: propertyOperations.buildPropertyRequest(get(listing, "property")),
    };

    each(fieldsToStripCommasFor, (field) => {
        set(data, field, stripCommas(get(data, field)));
    });

    each(fieldsToFormatAsInt, (field) => {
        set(data, field, get(data, field) ? numeral(get(data, field)).format("00") : null);
    });

    return data;
};

const validateListing = (listing, documentState) => {
    return validation.validateListing(
        listing,
        get(listing, "attributes.useType"),
        get(listing, "listingType"),
        documentState
    );
};

const switchTab = (tab) => {
    return (dispatch, getState) => {
        dispatch(actions.switchTab(tab));
        if (tab === "broker_open") {
            dispatch(countArrayListings(getState().listingReducer.listings[tab], tab));
        } else {
            dispatch(countListings(getState().listingReducer.listings[tab], tab));
        }

        if (tab === "all_km_listings") {
            dispatch(searchActions.onSearchPage(true));
            dispatch(searchActions.onGroupPage(false));
        } else if (tab === "listings_group") {
            dispatch(searchActions.onGroupPage(true));
            dispatch(searchActions.onSearchPage(false));
        } else {
            dispatch(searchActions.onSearchPage(false));
            dispatch(searchActions.onGroupPage(false));
        }
    };
};

const getBrokerActiveListings = (tab) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, tab));
        dispatch(utils.actions.setLoader("LISTING", true));
        request(
            {
                url: constants.urls["broker_open"](getState().authReducer.userProfile.user),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(countArrayListings(response.data, tab));
                dispatch(actions.receiveListings(response.data, "broker_open"));
                dispatch(setActiveCheckedListings(getState().listingReducer.listings["broker_open"], true));
                dispatch(utils.actions.somethingSaving(false, tab));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utils.actions.somethingSaving(false, tab));
            });
    };
};

const getListingsByStatus = (tab) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, tab));
        dispatch(utils.actions.setLoader("LISTING", true));
        request(
            {
                url: constants.urls[tab](getState().authReducer.userProfile.user),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(countListings(response.data, tab));
                dispatch(bringBrokerNamesToTopGrouped(response.data, tab));
                dispatch(utils.actions.somethingSaving(false, tab));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utils.actions.somethingSaving(false, tab));
            });
    };
};

const checkForPropertiesWithoutListings = (propertyKey, tab) => {
    var unassigned = request({
        url: constants.urls.unassigned_listings_by_property(propertyKey),
        method: "get",
        crossDomain: true,
    })
        .then((response) => {
            if (!isEmpty(response.data)) {
                return true;
            } else {
                return false;
            }
        })
        .catch((err) => {
            console.log(err);
            return false;
        })
        .then((response) => {
            return response;
        });

    return unassigned;
};

const getUnassignedListingsByProperty = (propertyKey, tab) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, tab));

        request(
            {
                url: constants.urls.unassigned_listings_by_property(propertyKey),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(countListings(response.data, tab));
                dispatch(bringBrokerNamesToTop(response.data, tab));
                dispatch(utils.actions.somethingSaving(false, tab));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, tab));
            });
    };
};

const getOpenListingsByProperty = (propertyKey, tab) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, tab));

        request(
            {
                url: constants.urls.open_listings_by_property(propertyKey),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(countListings(response.data, tab));
                dispatch(bringBrokerNamesToTop(response.data, tab));
                dispatch(utils.actions.somethingSaving(false, tab));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, tab));
            });
    };
};

const handleSort = (columnName) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };
    return (dispatch, getState) => {
        let sortDirection =
            getState().listingReducer.columnToSort === columnName
                ? invertDirection[getState().listingReducer.sortDirection]
                : "asc";
        dispatch(actions.handleSort(columnName, sortDirection));
    };
};

const countListings = (data, tab) => {
    return (dispatch, getState) => {
        let numListings = 0;
        if (data) {
            each(Object.keys(data), (x) => {
                each(data[x], (y) => {
                    numListings += 1;
                });
            });
        }
        dispatch(actions.countListings(numListings, tab));
    };
};

const countArrayListings = (data, tab) => {
    return (dispatch, getState) => {
        let numListings = 0;
        if (data) {
            for (let i = 0; i < data.length; i++) {
                numListings++;
            }
        }
        dispatch(actions.countListings(numListings, tab));
    };
};

const filterListings = (query) => {
    return (dispatch, getState) => {
        if (typeof query === "string") query = query.toLowerCase();
        dispatch(actions.filterListings(query));
    };
};

const searchListings = (tab) => {
    return (dispatch, getState) => {
        dispatch(clearSearchedListings());
        dispatch(searchOperations.storeSearchCleared(false, "LISTINGS"));
        dispatch(searchActions.loadingSearch(true));
        dispatch(searchOperations.storeSearchRun(true, "LISTINGS"));
        dispatch(searchActions.setRetainSelectedItems(false, "LISTINGS"));
        dispatch(searchOperations.executeSearch("LISTINGS", loadSearchResults));
    };
};

const clearSearchedListings = () => {
    return (dispatch, getState) => {
        dispatch(searchActions.clearSearchResponse("LISTINGS"));
        dispatch(actions.clearListings());
    };
};

const loadSearchResults = () => {
    return (dispatch, getState) => {
        const results = getState().searchReducer.LISTINGS.searchResponse.results;
        dispatch(actions.receiveListings(results, "all_km_listings"));

        const retainSelections = getState().searchReducer.LISTINGS.retainSelectedFromSave;
        if (retainSelections) {
            dispatch(setSelectedFromSavedSearch(getState().searchReducer.LISTINGS.searchResponse.results));
        } else {
            dispatch(setCheckedListings(getState().searchReducer.LISTINGS.searchResponse.results, true));
        }
    };
};

const clearSearchResults = () => {
    return (dispatch, getState) => {
        dispatch(actions.receiveListings({}, "all_km_listings"));
        dispatch(actions.countListings(0, "all_km_listings"));
        dispatch(actions.setCheckedListings(new Map()));
    };
};

const bringBrokerNamesToTopGrouped = (data, tab) => {
    return (dispatch, getState) => {
        each(Object.keys(data), (propertyKey) => {
            const property = data[propertyKey];
            each(property, (listing) => {
                let brokerNames = "";
                if (get(listing, "listingBrokers")) {
                    each(get(listing, "listingBrokers", []), (agent) => {
                        brokerNames += get(agent, "brokerAgentKey.person.firstName", "");
                        brokerNames += get(agent, "brokerAgentKey.person.lastName", "");
                    });
                }
                listing.brokerNames = brokerNames;
            });
        });
        dispatch(actions.receiveListings(data, tab));
        dispatch(utils.actions.loading(false));
    };
};

const clearTabContents = (tab) => {
    return (dispatch, getState) => {
        dispatch(actions.receiveListings([], tab));
    };
};

const bringBrokerNamesToTop = (data, tab) => {
    return (dispatch, _) => {
        each(data, (listing) => {
            let brokerNames = "";
            if (get(listing, "listingBrokers")) {
                each(get(listing, "listingBrokers", []), (agent) => {
                    brokerNames += get(agent, "brokerAgentKey.person.firstName", "");
                    brokerNames += get(agent, "brokerAgentKey.person.lastName", "");
                });
            }
            listing.brokerNames = brokerNames;
        });
        dispatch(actions.receiveListings(data, tab));
        dispatch(utils.actions.loading(false));
    };
};

const handleSubmit = (save, statusChangeCallback, suppressConfirm, key = null) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "listingSubmit"));
        if (save) {
            dispatch(saveListingRequest(false, statusChangeCallback));
        } else {
            statusChangeCallback &&
                request(
                    {
                        url: statusChangeCallback,
                        method: "put",
                        crossDomain: true,
                    },
                    dispatch
                )
                    .then((response) => {
                        if (suppressConfirm !== true) {
                            dispatch(utils.actions.snackbar(true, "success", "Success"));
                        }

                        dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
                        if (key) {
                            dispatch(getListing(key));
                        } else {
                            dispatch(getListing(getState().listingReducer.listing.listingKey));
                        }
                    })
                    .catch((err) => {
                        if (suppressConfirm !== true) {
                        }
                        dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
                        dispatch(utils.actions.snackbar(true, "error", "Something went wrong. " + err));
                        if (key) {
                            dispatch(getListing(key));
                        } else {
                            dispatch(getListing(getState().listingReducer.listing.listingKey));
                        }
                        dispatch(
                            utilOperations.reportError(
                                err,
                                "listing:operations:handleSubmit - Error trying to submit listing"
                            )
                        );
                    });
        }
    };
};

const handleSubmitAndUpdateStatus = (newStatus, whatIsSaving, redirectTo, validate) => {
    //2.29.19 pdaniels
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, whatIsSaving));
        let listing = cloneDeep(getState().listingReducer.listing);
        listing = {
            ...listing,
            status: newStatus,
        };
        let errors = null;
        if (validate) {
            errors = validateListing(getState().listingReducer.listing);
        }

        console.log("listingOperations.handleSubmitAndUpdateStatus - errors");
        console.log(errors);

        if (isEmpty(errors) || isNil(errors)) {
            saveListingCall(listing, dispatch)
                .then((response) => {
                    dispatch(utils.actions.somethingSaving(false, whatIsSaving));
                    dispatch(utils.actions.snackbar(true, "success", "Success"));
                    dispatch(push(redirectTo));
                })
                .catch((err) => {
                    dispatch(utils.actions.somethingSaving(false, whatIsSaving));
                    dispatch(utils.actions.snackbar(true, "error", "Error trying to save: " + err));
                    dispatch(
                        utilOperations.reportError(
                            err,
                            "listing:operations:handleSubmitAndUpdateStatus - Error trying to update and submit listing"
                        )
                    );
                });
        } else {
            dispatch(utils.actions.addError("listing", errors));
            dispatch(utils.actions.setErrorPanelsToExpand(validation.getPanelsToExpand(errors)));
            dispatch(utils.actions.somethingSaving(false, whatIsSaving));
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                )
            );
        }
    };
};

const saveListingRequest = (alreadyWarnedAboutMissingDocs, statusChangeCallback) => {
    return (dispatch, getState) => {
        if (alreadyWarnedAboutMissingDocs) {
        } else {
            const listing = getState().listingReducer.listing;
            const errors = validateListing(listing);

            console.log("listingOperations.saveListingRequest errors");
            console.log(errors);

            if (isEmpty(errors) || isNil(errors)) {
                saveListingCall(listing, dispatch)
                    .then((response) => {
                        //if we want to change status after saving
                        statusChangeCallback
                            ? request(
                                  {
                                      url: statusChangeCallback,
                                      method: "put",
                                      crossDomain: true,
                                  },
                                  dispatch
                              )
                                  .then((response) => {
                                      dispatch(utils.actions.snackbar(true, "success", "Success"));
                                      dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
                                      dispatch(push(`/listings`));
                                  })
                                  .catch((err) => {
                                      //couldn't change status
                                      dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
                                      dispatch(
                                          utils.actions.snackbar(
                                              true,
                                              "error",
                                              "Listing saved, but the status change could not be saved. " + err
                                          )
                                      );
                                      dispatch(getListing(getState().listingReducer.listing.listingKey));
                                      dispatch(
                                          utilOperations.reportError(
                                              err,
                                              "listing:operations:saveListingRequest - Error trying to save listing request"
                                          )
                                      );
                                  })
                            : dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
                        dispatch(utils.actions.snackbar(true, "success", "Success"));
                    })
                    .catch((err) => {
                        //couldn't save listing
                        dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
                        dispatch(utils.actions.snackbar(true, "error", "Error trying to save: " + err));
                        dispatch(utilOperations.reportError(err, "listing:operations:saveListingRequest Error"));
                    });
            } else {
                //if there are validation errors
                dispatch(utils.actions.addError("listing", errors));
                dispatch(utils.actions.setErrorPanelsToExpand(validation.getPanelsToExpand(errors)));
                dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
                dispatch(
                    utils.actions.snackbar(
                        true,
                        "error",
                        "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                    )
                );
            }
        }
    };
};

const addArrayElement = (arrayPath, obj = {}) => {
    return (dispatch, getState) => {
        dispatch(actions.addArrayElement(arrayPath, get(getState().listingReducer, arrayPath, []).length), obj);
    };
};

const updateArrayElement = (arrayPath, index, field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateArrayElement(arrayPath, index, field, value));
    };
};

const deleteArrayElement = (arrayPath, index) => {
    return (dispatch, getState) => {
        dispatch(actions.deleteArrayElement(arrayPath, index));
    };
};

const closeDeal = (listing) => {
    //actually creates deal and redirects to deal summary page of new deal
    return (dispatch, getState) => {
        request(
            {
                method: "get",
                url: constants.urls.get_listing_status(listing.listingKey),
            },
            dispatch
        ).then((response) => {
            if (response.data === "Closed") {
                dispatch(
                    utils.actions.snackbar(
                        true,
                        "warning",
                        "A deal has already been started for this listing. It is likely that this happened recently and the system has not picked up the change yet."
                    )
                );
            } else {
                request(
                    {
                        method: "post",
                        url: constants.urls.close_deal(toLower(listing.listingType)),
                        data: buildDeal(listing),
                        headers: { "Content-Type": "application/json" },
                    },
                    dispatch
                )
                    .then((response) => {
                        dispatch(push(`/deal/${response.data.dealKey}`));
                        ReactGA.event("Close Listing", {
                            listingId: listing.listingKey,
                            dealId: response.data.dealKey,
                        });
                    })
                    .catch((err) => {
                        //couldn't close deal
                        dispatch(
                            utilOperations.reportError(err, "listing:operations:closeDeal - Error trying to closeDeal")
                        );
                    });
            }
        });
    };
};

const buildDeal = (listing) => {
    const deal = {};
    set(deal, "dealStatus", "Draft");
    set(
        deal,
        "transactionType",
        toLower(listing.listingType) === "lease" ? "Lease" : toLower(listing.listingType) === "sale" ? "Sale" : "Sale"
    );
    set(deal, "dealKey", null);
    set(deal, "listing.listingKey", listing.listingKey);
    set(deal, "propertyType", get(listing, "attributes.useType", get(listing, "useType", null)));
    set(deal, "fromListing", true);

    return deal;
};

const getFlyer = (key) => {
    return (dispatch, getState) => {
        request(
            {
                method: "get",
                url: constants.urls.get_flyer(key),
                headers: { "Content-Type": "application/json" },
            },
            dispatch
        ).then((response) => {
            const fetchedUrl = response.data;
            window.open(fetchedUrl, "_blank");
        });
    };
};

const navigateToRequestUpdate = (key, redirectTo) => {
    return (dispatch, getState) => {
        dispatch(push(`/listing/${key}`));
        dispatch(comments_operations.setStateForRequestUpdate(redirectTo));
    };
};

const brokerSubmitListingRequest = (validate) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "listingSubmit"));
        const listing = getState().listingReducer.listing;
        let errors = null;
        if (validate) {
            errors = validateListing(getState().listingReducer.listing, getState().documentsReducer);
        }

        console.log("listingOperations.brokerSubmitListingRequest errors");
        console.log(errors);

        if (isEmpty(errors) || isNil(errors)) {
            saveListingCall(listing, dispatch)
                .then((response) => {
                    request(
                        {
                            url: constants.urls.broker_submit(listing.listingKey),
                            method: "put",
                            crossDomain: true,
                        },
                        dispatch
                    )
                        .then((resp) => {
                            dispatch(push(`/listings`));
                            dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
                            dispatch(utils.actions.snackbar(true, "success", "Success"));
                            ReactGA.event("Broker Submit Listing Request", {
                                listingKey: listing.listingKey,
                            });
                        })
                        .catch((err) => {
                            dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
                            dispatch(utils.actions.snackbar(true, "error", "Error trying to submit: " + err));
                            dispatch(
                                utilOperations.reportError(
                                    err,
                                    "listing:operations:brokerSubmitListingRequest(inner catch) - Error trying to submit broker listing request"
                                )
                            );
                        });
                })
                .catch((err) => {
                    dispatch(utils.actions.snackbar(true, "error", "Error trying to save: " + err));
                    dispatch(utilOperations.reportError(err, "Error trying to save listing and update status"));
                    dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
                    dispatch(
                        utilOperations.reportError(
                            err,
                            "listing:operations:brokerSubmitListingRequest(outer catch) - Error trying to submit broker listing request"
                        )
                    );
                });
        } else {
            //if there are validation errors
            dispatch(utils.actions.addError("listing", errors));
            dispatch(utils.actions.setErrorPanelsToExpand(validation.getPanelsToExpand(errors)));
            dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                )
            );
        }
    };
};

const addArrayElementNEW = (pathToArray, obj = {}) => {
    return (dispatch, getState) => {
        let array = cloneDeep(get(getState().listingReducer, pathToArray, []));
        array.push(obj);
        dispatch(actions.setListingArray(pathToArray, array));
    };
};

const deleteArrayElementNEW = (pathToArray, index) => {
    return (dispatch, getState) => {
        let array = cloneDeep(get(getState().listingReducer, pathToArray, []));
        array.splice(index, 1);
        dispatch(actions.setListingArray(pathToArray, array));
    };
};

const toggleReportTitleModal = (isOpen) => {
    return (dispatch, getState) => {
        dispatch(actions.titleReportModalStatus(isOpen));
    };
};

const toggleIncludeBrokerInfo = (include) => {
    return (dispatch, getState) => {
        dispatch(actions.includeBrokerInfoReport(include));
    };
};

const setReportTitle = (value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateReportTitle(value));
    };
};

const generateListingReport = (tab, includeBrokerInfo, reportTitle, reportStyle, mapView) => {
    return (dispatch, getState) => {
        let listings = [];
        if (tab === "all_km_listings") {
            listings = getState().listingReducer.selectedListings;
        } else if (tab === "listings_group") {
            listings = getState().listingReducer.selectedListingsGroup;
        } else if (tab === "broker_open") {
            listings = getState().listingReducer.activeSelectedListings;
        }

        let selectedListings = [];
        for (var [key, value] of listings) {
            if (value) {
                selectedListings.push(key);
            }
        }

        let reportInfo = {
            includeBrokerInfo: includeBrokerInfo,
            reportTitle: reportTitle,
            reportStyle: reportStyle,
            mapView: mapView,
            selected: selectedListings,
        };

        selectedListings = compact(selectedListings);
        selectedListings = uniq(selectedListings);
        dispatch(utils.actions.somethingSaving(true, "generateListingReport"));
        request(
            {
                url: constants.urls.generate_listing_report(),
                method: "post",
                crossDomain: true,
                data: reportInfo,
            },
            dispatch
        )
            .then((response) => {
                window.open(response.data, "_blank");
                dispatch(utils.actions.somethingSaving(false, "generateListingReport"));
                ReactGA.event("Report Generated", {
                    type: "listing",
                    reportStyle: reportStyle,
                });
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error trying to generate report: " + err));
                dispatch(utils.actions.somethingSaving(false, "generateListingReport"));
                dispatch(utilOperations.reportError(err, "listing:operations:generateListingReport Error"));
            });
    };
};

const checkboxClick = (key, type) => {
    return (dispatch, getState) => {
        if (type === "listings_group") {
            let selected = getState().listingReducer.selectedListingsGroup;
            const current = selected.get(key);
            dispatch(actions.groupCheckboxClick(key, !current));
        } else if (type === "broker_open") {
            let selected = getState().listingReducer.activeSelectedListings;
            const current = selected.get(key);
            dispatch(actions.activeCheckBoxClick(key, !current));
        } else {
            let selected = getState().listingReducer.selectedListings;
            const current = selected.get(key);
            dispatch(actions.checkboxClick(key, !current));
        }
    };
};

const displayInactivateDialog = (isOpen) => {
    return (dispatch, getState) => {
        dispatch(actions.updateInactivateDialog(isOpen));
    };
};

const handleInactivateListing = (listingKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        dispatch(utils.actions.somethingSaving(true, "listingInactivate"));
        dispatch(actions.updateInactivateDialog(false));
        request(
            {
                method: "put",
                url: constants.urls.inactivate_listing(listingKey),
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.somethingSaving(false, "listingInactivate"));
                dispatch(push(`/listings`));
                dispatch(utils.actions.snackbar(true, "success", "Success"));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, "listingInactivate"));
                dispatch(utils.actions.snackbar(true, "error", "Could not inactivate"));
                console.log(err);
                dispatch(
                    utilOperations.reportError(
                        err,
                        "listing:operations:handleInactivateListing - Error trying to inactivate listing"
                    )
                );
            });
    };
};

const displayDeleteDialog = (isOpen) => {
    return (dispatch, getState) => {
        dispatch(actions.updateDeleteDialog(isOpen));
    };
};

const toggleDuplicateListingDialog = (isOpen) => {
    return (dispatch, getState) => {
        dispatch(actions.toggleDuplicateListingDialog(isOpen));
    };
};

const toggleDuplicateSuccessDialog = (isOpen) => {
    return (dispatch, getState) => {
        dispatch(actions.toggleDuplicateSuccessDialog(isOpen));
    };
};

const handleDeleteListing = (listingKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        dispatch(utils.actions.somethingSaving(true, "listingDelete"));
        dispatch(actions.updateDeleteDialog(false));
        request(
            {
                method: "delete",
                url: constants.urls.delete_listing(listingKey),
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.somethingSaving(false, "listingDelete"));
                dispatch(push(`/listings`));
                dispatch(utils.actions.snackbar(true, "success", "Success"));
                ReactGA.event("Listing Deleted", {
                    listingKey: listingKey,
                });
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, "listingDelete"));
                dispatch(utils.actions.snackbar(true, "error", "Unable to delete this listing: " + err.data));
                console.log(err);
                dispatch(
                    utilOperations.reportError(
                        err,
                        "listing:operations:handleDeleteListing - Error trying to delete listing"
                    )
                );
            });
    };
};

const updatePropertyForListing = (listingKey, propertyKey, user) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        const state = getState().listingReducer;
        // const compState = getState().compReducer;
        dispatch(actions.clearErrors());
        let validationResponse = validation.validateRequestListingFromExisting(
            get(state, "requestModal.selectedProperty")
        );
        if (validationResponse) {
            each(Object.keys(validationResponse), (e) => {
                dispatch(actions.addError(e, validationResponse[e][0]));
            });
            dispatch(utils.actions.loading(false));
            return false;
        } else {
            request(
                {
                    url: constants.urls.update_listing_property(listingKey, propertyKey, user),
                    method: "put",
                    crossDomain: true,
                },
                dispatch
            )
                .then((response) => {
                    dispatch(propertyOperations.togglePropertySearchModal(false));
                    dispatch(propertyOperations.toggleUpdateDialog(false));
                    dispatch(utils.actions.snackbar(true, "success", "Property Updated"));
                    dispatch(utils.actions.loading(false));
                    dispatch(utils.actions.isSaving(false));
                    dispatch(processListingResponse(response));
                    dispatch(push("/comments/listing/" + listingKey));
                    dispatch(push("/listing/" + listingKey));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(
                        utils.actions.snackbar(
                            true,
                            "error",
                            "Error during update procedure. Verify that property has been updated on Listing." + err
                        )
                    );
                    dispatch(utils.actions.isSaving(false));
                    dispatch(utilOperations.reportError(err, "listing:operations:saveListing Error"));
                });
        }
    };
};

const setCurrentListingsGroup = (listings, order) => {
    // When method is called from savedSearch, order is a json string that needs to be parsed into an array. In all other cases it is either null or already an array.
    return (dispatch, getState) => {
        if (listings != null) {
            if (order != null) {
                let groupOrder;
                if (typeof order == "string") {
                    groupOrder = JSON.parse(order);
                } else {
                    groupOrder = order;
                }
                let orderedListings = [];
                for (let i = 0; i < groupOrder.length; i++) {
                    for (let j = 0; j < listings.length; j++) {
                        if (groupOrder[i] === get(listings[j], "listingKey", null)) {
                            orderedListings.push(listings[j]);
                        }
                    }
                }
                dispatch(actions.setCurrentListingsGroup(orderedListings));
                dispatch(setGroupCheckedListings(orderedListings, true));
            } else {
                dispatch(actions.setCurrentListingsGroup(listings));
                dispatch(setGroupCheckedListings(listings, true));
            }
        } else {
            dispatch(actions.clearCurrentListingsGroup());
        }
    };
};

const selectAllActiveListings = (value) => {
    return (dispatch, getState) => {
        let selected = cloneDeep(getState().listingReducer.activeSelectedListings);
        let updateSelected = new Map();
        [...selected.keys()].forEach((key) => {
            updateSelected.set(key, value);
        });

        dispatch(actions.setActiveCheckedListings(updateSelected));
    };
};

const uploadListingFile = (file) => {
    let formData = new FormData();
    formData.append("file", file);

    return request({
        url: constants.urls.upload_listing_file(),
        method: "post",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
        .then((response) => {
            return response.status;
        })
        .catch((response) => {
            return response.status;
        });
};

export default {
    getListing,
    inputChange,
    updatePropertyForListing,
    updateListingRequest,
    toggleRequestModal,
    searchAutocomplete,
    selectPropertyFromAutocomplete,
    getPropertyForListingCreation,
    removeSelectedPropertyFromRequestModal,
    requestListing,
    requestListingNewProperty,
    switchTab,
    getListingsByStatus,
    getOpenListingsByProperty,
    handleSort,
    filterListings,
    handleSubmit,
    handleListingInputChange,
    addArrayElement,
    deleteArrayElement,
    saveListing,
    closeDeal,
    initListing,
    getFlyer,
    navigateToRequestUpdate,
    brokerSubmitListingRequest,
    saveAndExit,
    searchListings,
    addListingGivenProperty,
    clearListingState,
    clearTabContents,
    updateArrayElement,
    addArrayElementNEW,
    deleteArrayElementNEW,
    handleSubmitAndUpdateStatus,
    getInitialListings,
    generateListingReport,
    setCheckedListings,
    checkboxClick,
    selectAll,
    selectAllGroup,
    displayInactivateDialog,
    handleInactivateListing,
    handleDeleteListing,
    displayDeleteDialog,
    getUnassignedListingsByProperty,
    checkForPropertiesWithoutListings,
    toggleReportTitleModal,
    toggleIncludeBrokerInfo,
    setReportTitle,
    duplicateListing,
    toggleDuplicateListingDialog,
    toggleDuplicateSuccessDialog,
    clearSearchedListings,
    handleDragEnd,
    updateListingsForSort,
    clearSearchResults,
    validateListing,
    clearMyListings,
    loadSearchResults,
    setCurrentListingsGroup,
    setActiveCheckedListings,
    selectAllActiveListings,
    uploadListingFile,
    buildListingRequestFromModal,
};
