import types from "./types";

const changeTabs = (tab) => {
    return {
        type: types.CHANGE_TABS,
        activeTab: tab,
    };
};

const changeHeaders = (header) => {
    return {
        type: types.CHANGE_HEADER,
        activeHeader: header,
    };
};

const receiveComps = (data) => {
    return {
        type: types.RECEIVE_COMPS,
        comps: data,
    };
};

const countComps = (count) => {
    return {
        type: types.COUNT_COMPS,
        numComps: count,
    };
};

const clearAllComps = () => {
    return {
        type: types.CLEAR_ALL_COMPS,
    };
};

const updateCompOrder = (data) => {
    return {
        type: types.UPDATE_COMP_ORDER,
        comps: data,
    };
};

const updateAuditorListOrder = (data) => {
    return {
        type: types.UPDATE_AUDITOR_LIST_ORDER,
        auditorList: data,
    };
};

const receiveApprovedComps = (comps) => {
    return {
        type: types.RECEIVE_APPROVED_COMPS,
        auditorList: [...comps],
    };
};

const toggleCompAuditFlag = (dealKey) => {
    return {
        type: types.TOGGLE_COMP_AUDIT_FLAG,
        dealKey: dealKey,
    };
};

const toggleAllCompsAuditFlags = (isChecked) => {
    return {
        type: types.TOGGLE_ALL_COMPS_AUDIT_FLAGS,
        checked: isChecked,
    };
};

const toggleAuditorListAuditFlag = (dealKey) => {
    return {
        type: types.TOGGLE_AUDITOR_LIST_AUDIT_FLAG,
        dealKey: dealKey,
    };
};

const toggleAllAuditorListAuditFlag = (isChecked) => {
    return {
        type: types.TOGGLE_ALL_AUDITOR_LIST_AUDIT_FLAG,
        checked: isChecked,
    };
};

const toggleAllAuditorListCheckbox = (isChecked) => {
    return {
        type: types.TOGGLE_ALL_AUDITOR_LIST_CHECKBOX,
        checked: isChecked,
    };
};

export default {
    changeTabs,
    changeHeaders,
    receiveComps,
    countComps,
    clearAllComps,
    updateCompOrder,
    receiveApprovedComps,
    toggleCompAuditFlag,
    toggleAuditorListAuditFlag,
    toggleAllAuditorListAuditFlag,
    toggleAllCompsAuditFlags,
    toggleAllAuditorListCheckbox,
    updateAuditorListOrder,
};
