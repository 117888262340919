import React from "react";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { use_type_options } from "../utils/constants";

class OtherForm extends React.Component {
    handleAttributeUpdate = (event) => {
        this.props.onChange(event.target.id, event.target.value);
    };

    render() {
        const { data, errors, disableInputs, context } = this.props;

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="sfAvail"
                            label="OTHER SF AVAILABLE"
                            placeholder="Enter SF"
                            errorText="ex. 98101"
                            error={errors.sfAvail}
                            value={data.sfAvail}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="largestContigSf"
                            label="OTHER CONTIGUOUS SF AVAILABLE"
                            placeholder="Enter SF"
                            errorText="ex. 98101"
                            error={errors.largestContigSf}
                            value={data.largestContigSf}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        {context === "property" && (
                            <BSInput
                                id="suite"
                                label="OTHER SUITE NUMBER"
                                placeholder="Enter number"
                                error={errors.suite}
                                value={data.suite}
                                onChange={this.handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        )}
                    </Grid>
                    {context === "listing" && (
                        <div>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.divisibleFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="divisibleFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Office Space Divisible"
                                        style={{ marginBottom: "10px" }}
                                    />
                                </FormGroup>
                            </Grid>
                        </div>
                    )}
                </Grid>
                {context === "listing" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="secondarySfAvailable"
                                    label="SECONDARY SF AVAILABLE"
                                    errorText="ex. 98101"
                                    value={data.secondarySfAvailable}
                                    error={errors.secondarySfAvailable}
                                    onChange={this.handleAttributeUpdate}
                                    placeholder="Enter SF"
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSSelect
                                    id="secondarySfType"
                                    label="SECONDARY SF TYPE"
                                    value={data.secondarySfType}
                                    error={errors.secondarySfType}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                    options={use_type_options}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
            </div>
        );
    }
}

export default OtherForm;
