import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Help from "@material-ui/icons/Help";

import ReactTooltip from "react-tooltip";

const styles = (theme) => ({
    statBox: {
        padding: "4px 0",
        marginBottom: "12px",
    },
    avatar: {
        background: "#2C99D2",
        margin: 16,
    },
    defTerm: {
        fontSize: "13px",
        textTransform: "uppercase",
        color: "#9E9E9E",
        fontWeight: 600,
        display: "inline-block",
        padding: "0 0 8px",
    },
    defData: {
        fontSize: 22,
        fontWeight: 700,
    },
});
class KMStatBox extends React.Component {
    render() {
        const { classes, icon, tooltip, id } = this.props;

        return (
            <Paper className={classes.statBox}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>{icon}</Avatar>
                    </Grid>
                    <Grid item>
                        <span className={classes.defTerm}>
                            {this.props.title}
                            {tooltip && (
                                <React.Fragment>
                                    <Help
                                        style={{ fontSize: "110%", marginLeft: "4px", color: "grey" }}
                                        data-tip={tooltip}
                                        data-for={id}
                                    />
                                    <ReactTooltip
                                        id={id}
                                        place="top"
                                        type="dark"
                                        effect="solid"
                                        className="statBoxTooltip"
                                    />
                                </React.Fragment>
                            )}
                        </span>
                        <br />
                        <span className={classes.defData}>{this.props.number}</span>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(KMStatBox);
