import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import LandVision from "@material-ui/icons/Map";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import OutsideCompModal from "../comp/OutsideCompModal";
import KMTools from "../../../common/js/components/KMTools";
import KMStatBox from "../../../common/js/components/KMStatBox";
import BSSelect from "../../../common/js/components/BSSelect";
import { Sorty } from "../../../common/js/icons";
import { regions } from "../types/regions";
import { MenuItem, DropdownButton } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { Divider } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import RequestModalContainer from "../listing/RequestModalContainer";
import { aggregateFromArrayForDisplay, toDollarAmount, formatDate } from "../utils/operations";
import join from "lodash/join";
import map from "lodash/map";
import classnames from "classnames";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import isNil from "lodash/isNil";
import numeral from "numeral";
import config from "../../../config";
import "./dashboard.css";
import BSInput from "../../../common/js/components/BSInput";
import Today from "@material-ui/core/SvgIcon/SvgIcon";
import ReactGA from "react-ga4";

const styles = (theme) => ({
    pageToolbar: {
        margin: "35px 0px",
    },
    iconInButton: {
        marginRight: "5px",
    },
    paperSpacing: {
        padding: "10px 20px",
        marginBottom: "15px",
        fontSize: "105%",
    },
    pieSpacing: {
        padding: "10px 20px",
        fontSize: 14,
        marginBottom: "15px",
    },
    pieContainer: {
        margin: "20px 0px",
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    fullWidth: {
        marginLeft: "-20px",
        marginRight: "-20px",
    },
    alignRightCell: {
        display: "flex",
        alignItems: "center",
        position: "relative",
    },
    idLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
        textDecoration: "none",
    },
    loadingPlaceholder: {
        marginTop: "3em",
        marginBottom: "1.5em",
        textAlign: "center",
        width: "100%",
    },
    leftColumn: {
        paddingLeft: "0px",
    },
    lvButton: {
        flexGrow: "inherit",
        padding: "8px",
    },
    regionSelector: {
        flexGrow: "inherit",
        width: "12em",
        marginBottom: "-22px",
        marginLeft: "25px",
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 800,
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
    dateFilterSelector: {
        flexGrow: "inherit",
        width: "11em",
    },
});

class EVPDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    handleShowModal = (spaceType, transactionType) => {
        this.setState({ showModal: !this.state.showModal });
        this.props.toggleRequestModal(spaceType, transactionType);
    };

    render() {
        const {
            classes,
            errors,
            loading,
            evpValues,
            handleSort,
            sort,
            searchProperty,
            addNewDealFromOutsideCompModal,
            handleDashboardInput,
            dashboardParams,
            brokerValues,
        } = this.props;

        const commissionBreakdownByTypeSorted = orderBy(
            get(evpValues, "commissionBreakdownByType", []),
            (x) => {
                return isNil(x.calculatedValueA) ? 0 : x.calculatedValueA;
            },
            "desc"
        );
        const commissionBreakdownByTypeData = map(commissionBreakdownByTypeSorted, (val) => {
            return get(val, "calculatedValueA", 0);
        });
        const commissionBreakdownByTypeLabels = map(commissionBreakdownByTypeSorted, (val) => {
            return get(val, "propertyType", "Unknown");
        });
        const topKmClosedDealsSortColumn = get(sort, "topKmClosedDeals.columnName", "calculatedValueA");
        const topKmClosedDealsSortDirection = get(sort, "topKmClosedDeals.sortDirection", "desc");
        const topKmBrokersSortColumn = get(sort, "topKmBrokers.columnName", "calculatedValueB");
        const topKmBrokersSortDirection = get(sort, "topKmBrokers.sortDirection", "desc");
        const commissionBreakdownByOfficeSortColumn = get(
            sort,
            "commissionBreakdownByOffice.columnName",
            "calculatedValueC"
        );
        const commissionBreakdownByOfficeSortDirection = get(sort, "commissionBreakdownByOffice.sortDirection", "desc");

        const sumDealCount = () => {
            let totalDealCount = 0;
            evpValues.commissionBreakdownByOffice.forEach((office) => {
                totalDealCount = totalDealCount + office.calculatedValueA;
            });
            return totalDealCount;
        };

        const sumTotalCommissionByOffice = () => {
            let totalCommission = 0;
            evpValues.commissionBreakdownByOffice.forEach((office) => {
                totalCommission += office.calculatedValueC;
            });
            return totalCommission;
        };

        const sumTotalConsiderationByOffice = () => {
            let totalConsideration = 0;
            evpValues.commissionBreakdownByOffice.forEach((office) => {
                totalConsideration += office.calculatedValueB;
            });
            return totalConsideration;
        };

        return (
            <div>
                <Grid container alignItems="center" justify="space-between" className={classes.pageToolbar}>
                    <Grid item>
                        <h2 className="page-title">Dashboard</h2>
                    </Grid>
                    <Grid
                        container
                        xs={10}
                        alignItems="center"
                        spacing={0}
                        justify="flex-end"
                        style={{ maxWidth: "90%", flexBasis: "90%" }}
                    >
                        <Grid item className={classes.dateFilterSelector}>
                            <BSInput
                                id="startDate"
                                label="Dashboard Date Range"
                                value={get(dashboardParams, "startDate")}
                                type={"date"}
                                startAdornment={<Today />}
                                style={{ backgroundColor: "white" }}
                                onChange={handleDashboardInput}
                            />
                        </Grid>
                        <Grid item align="center">
                            <div style={{ paddingTop: "0px", paddingLeft: "5px", paddingRight: "5px", color: "grey" }}>
                                &mdash;
                            </div>
                        </Grid>
                        <Grid item className={classes.dateFilterSelector}>
                            <BSInput
                                id="endDate"
                                value={get(dashboardParams, "endDate")}
                                type={"date"}
                                startAdornment={<Today />}
                                onChange={handleDashboardInput}
                            />
                        </Grid>
                        <Grid item className={classes.regionSelector}>
                            <BSSelect
                                id="region"
                                value={get(dashboardParams, "region")}
                                error={get(errors, `dashboardParams.region`, null)}
                                extraInputStyles={{ marginTop: "0px", marginRight: "5px" }}
                                onChange={handleDashboardInput}
                                options={[{ label: "All Regions", value: "all" }, ...regions]}
                            />
                        </Grid>
                        <Grid item className={classes.lvButton} style={{ paddingLeft: "12px" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                href={config.LANDVISION_URL}
                                className={classes.button}
                                onClick={() => ReactGA.event("Navigate To Landvision from Dashboard")}
                            >
                                <LandVision className={classes.iconInButton} /> Go to LandVision
                            </Button>
                        </Grid>
                        <Grid item className={classes.lvButton}>
                            <DropdownButton id="dropdown-basic-button" title="Request Listing" pullRight>
                                <MenuItem disabled className={classes.menuCategory}>
                                    Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Office", "Lease")}
                                >
                                    Office Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Industrial", "Lease")}
                                >
                                    Industrial Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Retail", "Lease")}
                                >
                                    Retail Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Land", "Lease")}
                                >
                                    Land Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Multifamily", "Lease")}
                                >
                                    Multifamily Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Life Science", "Lease")}
                                >
                                    Life Science Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Healthcare", "Lease")}
                                >
                                    Healthcare Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Religious", "Lease")}
                                >
                                    Religious Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Educational", "Lease")}
                                >
                                    Educational Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Other", "Lease")}
                                >
                                    Other Lease
                                </MenuItem>
                                <MenuItem disabled className={classes.menuCategory}>
                                    Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Office", "Sale")}
                                >
                                    Office Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Industrial", "Sale")}
                                >
                                    Industrial Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Retail", "Sale")}
                                >
                                    Retail Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Land", "Sale")}
                                >
                                    Land Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Hospitality", "Sale")}
                                >
                                    Hospitality Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Multifamily", "Sale")}
                                >
                                    Multifamily Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Life Science", "Sale")}
                                >
                                    Life Science Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Healthcare", "Sale")}
                                >
                                    Healthcare Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Religious", "Sale")}
                                >
                                    Religious Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Educational", "Sale")}
                                >
                                    Educational Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Other", "Sale")}
                                >
                                    Other Sale
                                </MenuItem>
                            </DropdownButton>
                            <RequestModalContainer
                                {...this.props}
                                handleShowModal={this.handleShowModal}
                                showModal={this.state.showModal}
                                modalType={"listing"}
                            />
                        </Grid>
                        <Grid item className={classnames(classes.lvButton, "dashboard")}>
                            <OutsideCompModal
                                {...this.props}
                                errors={errors}
                                searchFunction={searchProperty}
                                addOutsideComp={addNewDealFromOutsideCompModal}
                                internal={true}
                                dashboard={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start" justify="space-between" spacing={24}>
                        <Grid item xs={8}>
                            <Paper className={classes.paperSpacing}>
                                <h3>Commission Breakdown By Office</h3>
                                {loading.commissionBreakdownByOffice ? (
                                    <div className={classes.loadingPlaceholder}>
                                        <CircularProgress color="primary" />
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <Divider />
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell classes={classes} style={{ width: "20%", padding: 0 }}>
                                                        <div
                                                            style={{ display: "flex", alignItems: "center" }}
                                                            onClick={() =>
                                                                handleSort("commissionBreakdownByOffice", "officeName")
                                                            }
                                                        >
                                                            <span className={classes.sortable}>Office</span>
                                                            <div>
                                                                <Sorty />
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell classes={classes} style={{ width: "20%", padding: 0 }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                position: "relative",
                                                            }}
                                                            onClick={() =>
                                                                handleSort(
                                                                    "commissionBreakdownByOffice",
                                                                    "calculatedValueA"
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                style={{ right: "6px", position: "absolute" }}
                                                                className={classes.sortable}
                                                            >
                                                                Number Of Deals
                                                            </div>
                                                            <div style={{ right: "0px", position: "absolute" }}>
                                                                <Sorty />
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell
                                                        numeric
                                                        classes={classes}
                                                        style={{ width: "30%", padding: 0 }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                position: "relative",
                                                            }}
                                                            onClick={() =>
                                                                handleSort(
                                                                    "commissionBreakdownByOffice",
                                                                    "calculatedValueB"
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                style={{ right: "6px", position: "absolute" }}
                                                                className={classes.sortable}
                                                            >
                                                                Total Consideration Amt
                                                            </div>
                                                            <div style={{ right: "0px", position: "absolute" }}>
                                                                <Sorty />
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell classes={classes} style={{ width: "30%", padding: 0 }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                position: "relative",
                                                            }}
                                                            onClick={() =>
                                                                handleSort(
                                                                    "commissionBreakdownByOffice",
                                                                    "calculatedValueC"
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                style={{ right: "6px", position: "absolute" }}
                                                                className={classes.sortable}
                                                            >
                                                                Total KM Commission Amount
                                                            </div>
                                                            <div style={{ right: "0px", position: "absolute" }}>
                                                                <Sorty />
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderBy(
                                                    evpValues.commissionBreakdownByOffice,
                                                    commissionBreakdownByOfficeSortColumn,
                                                    commissionBreakdownByOfficeSortDirection
                                                ).map((office, i) => (
                                                    <TableRow selectable="false" className="tableRow">
                                                        <TableCell
                                                            className={classes.leftColumn}
                                                            style={{ padding: 0 }}
                                                        >
                                                            {get(office, `officeName`)}
                                                        </TableCell>

                                                        {/* number of deals */}
                                                        <TableCell numeric style={{ padding: 0 }}>
                                                            {get(office, `calculatedValueA`)}
                                                        </TableCell>

                                                        {/* total consideration amount */}
                                                        <TableCell numeric style={{ padding: 0 }}>
                                                            {toDollarAmount(get(office, `calculatedValueB`))}
                                                        </TableCell>

                                                        {/* total commission amount */}
                                                        <TableCell numeric style={{ padding: 0 }}>
                                                            {toDollarAmount(get(office, `calculatedValueC`))}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow selectable="false" className="tableRow">
                                                    <TableCell
                                                        className={classes.leftColumn}
                                                        style={{ fontWeight: "bold", padding: 0 }}
                                                    >
                                                        Total
                                                    </TableCell>

                                                    {/* number of deals */}
                                                    <TableCell numeric style={{ fontWeight: "bold", padding: 0 }}>
                                                        {sumDealCount()}
                                                    </TableCell>

                                                    {/* total consideration amount */}
                                                    <TableCell numeric style={{ fontWeight: "bold", padding: 0 }}>
                                                        {numeral(sumTotalConsiderationByOffice(), 0, "--").format(
                                                            "$ 0,0[.]00"
                                                        )}
                                                    </TableCell>

                                                    {/* total commission amount */}
                                                    <TableCell numeric style={{ fontWeight: "bold", padding: 0 }}>
                                                        {numeral(sumTotalCommissionByOffice(), 0, "--").format(
                                                            "$ 0,0[.]00"
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </React.Fragment>
                                )}
                            </Paper>
                            <Paper className={classes.paperSpacing}>
                                <h3>Top Brokers</h3>
                                {loading.topKmBrokers ? (
                                    <div className={classes.loadingPlaceholder}>
                                        <CircularProgress color="primary" />
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <Divider />
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell classes={classes} style={{ width: "35%", padding: 0 }}>
                                                        <div
                                                            style={{ display: "flex", alignItems: "center" }}
                                                            onClick={() => handleSort("topKmBrokers", "firstName")}
                                                        >
                                                            <span className={classes.sortable}>Broker</span>
                                                            <div>
                                                                <Sorty />
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell classes={classes} style={{ width: "20%", padding: 0 }}>
                                                        <div
                                                            style={{ display: "flex", alignItems: "center" }}
                                                            onClick={() => handleSort("topKmBrokers", "region")}
                                                        >
                                                            <span className={classes.sortable}>Region</span>
                                                            <div>
                                                                <Sorty />
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell classes={classes} style={{ width: "15%", padding: 0 }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                position: "relative",
                                                            }}
                                                            onClick={() =>
                                                                handleSort("topKmBrokers", "calculatedValueB")
                                                            }
                                                        >
                                                            <div
                                                                style={{ right: "6px", position: "absolute" }}
                                                                className={classes.sortable}
                                                            >
                                                                Closed Deals
                                                            </div>
                                                            <div style={{ right: "0px", position: "absolute" }}>
                                                                <Sorty />
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell classes={classes} style={{ width: "30%", padding: 0 }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                position: "relative",
                                                            }}
                                                            onClick={() =>
                                                                handleSort("topKmBrokers", "calculatedValueA")
                                                            }
                                                        >
                                                            <div
                                                                style={{ right: "6px", position: "absolute" }}
                                                                className={classes.sortable}
                                                            >
                                                                Total Commission Amount
                                                            </div>
                                                            <div style={{ right: "0px", position: "absolute" }}>
                                                                <Sorty />
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderBy(
                                                    evpValues.topKmBrokers,
                                                    topKmBrokersSortColumn,
                                                    topKmBrokersSortDirection
                                                ).map((deal, i) => (
                                                    <TableRow selectable="false" className="tableRow">
                                                        <TableCell
                                                            className={classes.leftColumn}
                                                            style={{ padding: 0 }}
                                                        >
                                                            {`${get(deal, `firstName`)} ${get(deal, `lastName`)}`}
                                                        </TableCell>

                                                        <TableCell style={{ padding: 0 }}>
                                                            {get(deal, `region`)}
                                                        </TableCell>

                                                        {/* closed deals */}
                                                        <TableCell numeric style={{ padding: 0 }}>
                                                            {get(deal, `calculatedValueB`)}
                                                        </TableCell>

                                                        {/* total commission amount */}
                                                        <TableCell numeric style={{ padding: 0 }}>
                                                            {toDollarAmount(get(deal, `calculatedValueA`))}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </React.Fragment>
                                )}
                            </Paper>
                            <Paper className={classes.paperSpacing}>
                                <h3>Top Closed Deals</h3>
                                {loading.topKmClosedDeals ? (
                                    <div className={classes.loadingPlaceholder}>
                                        <CircularProgress color="primary" />
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <Divider />
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell classes={classes} style={{ width: "35%", padding: 0 }}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <span className={classes.sortable}>Deal</span>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell classes={classes} style={{ width: "15%", padding: 0 }}>
                                                        <div
                                                            style={{ display: "flex", alignItems: "center" }}
                                                            onClick={() => handleSort("topKmClosedDeals", "region")}
                                                        >
                                                            <span className={classes.sortable}>Region</span>
                                                            <div>
                                                                <Sorty />
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell classes={classes} style={{ width: "13%", padding: 0 }}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <span className={classes.sortable}>Broker</span>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell classes={classes} style={{ width: "15%", padding: 0 }}>
                                                        <div
                                                            style={{ display: "flex", alignItems: "center" }}
                                                            onClick={() => handleSort("topKmClosedDeals", "batchDate")}
                                                        >
                                                            <span className={classes.sortable}>Batch Date</span>
                                                            <div>
                                                                <Sorty />
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell
                                                        numeric
                                                        classes={classes}
                                                        style={{ width: "15%", padding: 0 }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                position: "relative",
                                                            }}
                                                            onClick={() =>
                                                                handleSort("topKmClosedDeals", "calculatedValueA")
                                                            }
                                                        >
                                                            <div
                                                                style={{ right: "6px", position: "absolute" }}
                                                                className={classes.sortable}
                                                            >
                                                                Total KM Commission Amount
                                                            </div>
                                                            <div style={{ right: "0px", position: "absolute" }}>
                                                                <Sorty />
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderBy(
                                                    evpValues.topKmClosedDeals,
                                                    topKmClosedDealsSortColumn,
                                                    topKmClosedDealsSortDirection
                                                ).map((deal, i) => (
                                                    <TableRow selectable="false" className="tableRow">
                                                        <TableCell
                                                            className={classes.leftColumn}
                                                            style={{ padding: 0 }}
                                                        >
                                                            <Link
                                                                className={classes.idLink}
                                                                to={`/deal/${get(deal, "dealKey", "")}`}
                                                            >
                                                                <div>
                                                                    {`Deal No. ${get(deal, "dealKey")}`}
                                                                    <span className="l" style={{ fontWeight: 200 }}>
                                                                        {get(deal, "listingName", null) !== null
                                                                            ? ` | ${get(deal, "listingName")}`
                                                                            : ""}
                                                                    </span>
                                                                </div>
                                                            </Link>
                                                            <div>
                                                                {get(deal, "address1")} {get(deal, "address2")}{" "}
                                                                {get(deal, "city")} {get(deal, "state")}{" "}
                                                                {get(deal, "zip")}
                                                            </div>
                                                        </TableCell>

                                                        <TableCell style={{ padding: 0 }}>
                                                            {get(deal, `region`)}
                                                        </TableCell>

                                                        <TableCell style={{ padding: 0 }}>
                                                            <span data-tip={join(get(deal, "calculatedValueE"), ", ")}>
                                                                {aggregateFromArrayForDisplay(
                                                                    get(deal, "calculatedValueE"),
                                                                    "Brokers"
                                                                )}
                                                            </span>
                                                            <ReactTooltip place="top" type="dark" effect="solid" />
                                                        </TableCell>

                                                        <TableCell style={{ padding: 0 }}>
                                                            {get(deal, `batchDate`)}
                                                        </TableCell>

                                                        {/* total km commission amount */}
                                                        <TableCell numeric style={{ padding: 0 }}>
                                                            {toDollarAmount(get(deal, `calculatedValueA`))}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </React.Fragment>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.pieSpacing}>
                                <h3>Commission Breakdown By Type</h3>
                                <Divider className={classes.fullWidth} />
                                <div className={classes.pieContainer}>
                                    <Doughnut
                                        data={{
                                            datasets: [
                                                {
                                                    data: commissionBreakdownByTypeData,
                                                    backgroundColor: [
                                                        "rgba(224, 68, 3)", //km orange
                                                        "rgba(198, 178, 111)", //aztec gold? //todo
                                                        "rgba(151, 209, 220)", //harbor sky
                                                        "rgba(45, 135, 154)", //cosmic dust
                                                        "rgba(27, 16, 16)", //raven black
                                                        "rgba(95, 93, 94)", //ash
                                                        "rgba(175, 171, 172)", //sterling
                                                        "rgba(235, 238, 239)", //frost grey
                                                    ],
                                                },
                                            ],
                                            labels: commissionBreakdownByTypeLabels,
                                        }}
                                        options={{
                                            legend: {
                                                position: "right",
                                                display: true,
                                                generateLabels: function (chart) {
                                                    console.log(chart);
                                                    var data = chart.data;
                                                    console.log(data);
                                                    return [];
                                                },
                                            },
                                            tooltips: {
                                                callbacks: {
                                                    label: (tooltipItem, data) => {
                                                        const value = commissionBreakdownByTypeData[tooltipItem.index];
                                                        return toDollarAmount(value);
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </Paper>
                            {loading.brokerLicenseExpiration ? (
                                <div className={classes.loadingPlaceholder}>
                                    <CircularProgress color="primary" />
                                </div>
                            ) : (
                                <KMStatBox
                                    icon={<AccountCircleIcon />}
                                    title="License Exp Date"
                                    number={formatDate(
                                        "MM/DD/YYYY",
                                        "--"
                                    )(get(brokerValues, `brokerLicenseExpiration.expirationDate`, ""))}
                                />
                            )}
                            <KMTools />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(EVPDashboard);
