import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import Today from "@material-ui/icons/Today";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { Search } from "../../../common/js/icons";
import FormTitle from "../../../common/js/components/FormTitle";
import Autocomplete from "../../../common/js/components/Autocomplete";
import { us_states_options } from "../utils/constants";
import KMSwitch from "../../../common/js/components/KMSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import searchConstants from "../search/constants";
import { get, isNil, debounce } from "lodash";
import { IconButton } from "@material-ui/core";

const styles = {
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    idLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "none",
        },
        color: "#2C99D2",
        fontWeight: 600,
        textDecoration: "none",
    },
    loadingPlaceholder: {
        marginTop: "3em",
        marginBottom: "1.5em",
        textAlign: "center",
        width: "100%",
    },
    leftColumn: {
        paddingLeft: "0px",
    },
    paperSpacing: {
        padding: "10px 20px",
        fontSize: 14,
        marginBottom: "15px",
    },
    filterInput: {
        height: "70px",
        maringBottom: "0px,",
    },
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    reminderSwitch: {
        float: "right",
        margin: "0px",
    },
    theCounter: {
        border: "1px solid #2C99D2",
        borderRadius: "50%",
        display: "inline-block",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 600,
        width: "22px",
        height: "20px",
        color: "#2C99D2",
        paddingTop: "2px",
    },
    clearLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
            color: "rgba(0, 0, 0, 0.54)",
        },
        color: "rgba(0, 0, 0, 0.34)",
        textTransform: "capitalize",
    },
    clearButton: {
        "&:hover": {
            backgroundColor: "transparent",
            color: "black",
        },
        padding: "0px",
    },
    clearButtonIcon: {
        width: "8px",
        height: "8px",
    },
};

class AddContact extends React.Component {
    selectCompany = (company) => {
        this.props.handleInput(`contact.company`, company);
    };

    clearCompany = () => {
        this.props.handleInput(`contact.company`, {});
    };

    handleDeleteReminder = (index) => {
        this.props.deleteArrayElement("contact.reminders", index);
    };

    handleAddReminder = () => {
        this.props.addArrayElement("contact.reminders", {
            contactKey: get(this.props, "contact.contactKey"),
            owner: { username: this.props.auth.userProfile.user },
        });
    };

    debounceSearchCompany = debounce((handleSearchCompany, term) => {
        handleSearchCompany(term, "CONTACT");
    }, searchConstants["DEBOUNCE_SEARCH_DELAY"]);
    render() {
        const {
            errors,
            classes,
            contact,
            autocompleteResults,
            handleSearchCompany,
            disableInputs,
            handleInput,
            reminders,
            remindersActive,
            updateArrayElement,
        } = this.props;

        return (
            <Grid container spacing={16}>
                <Grid item xs={6}>
                    <Paper className={classes.paperSpacing}>
                        <React.Fragment>
                            <Grid container alignItems="center" spacing={8}>
                                <Grid item xs>
                                    <FormTitle title="General Information" />
                                </Grid>
                            </Grid>

                            <hr style={{ margin: "0px -20px 25px", borderTop: "1px solid #eeeeee" }} />

                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="firstName"
                                        label="First Name"
                                        value={get(contact, `person.firstName`, "")}
                                        error={get(errors, `person.firstName`, null)}
                                        onChange={(e) => handleInput("contact.person.firstName", e.target.value)}
                                        placeholder="Enter first name"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <BSInput
                                        id="lastName"
                                        label="Last Name"
                                        value={get(contact, `person.lastName`, "")}
                                        error={get(errors, `person.lastName`, null)}
                                        onChange={(e) => handleInput("contact.person.lastName", e.target.value)}
                                        placeholder="Enter last name"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="phoneNumber"
                                        label="Phone"
                                        value={get(contact, `phoneNumber.phoneNumber`, "")}
                                        error={get(errors, `phoneNumber.phoneNumber`, null)}
                                        onChange={(e) => handleInput("contact.phoneNumber.phoneNumber", e.target.value)}
                                        errorText="Invalid"
                                        placeholder="Enter contact phone"
                                        disabled={disableInputs === true}
                                        phone={true}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <BSInput
                                        id="emailAddress"
                                        label="Email"
                                        value={get(contact, `emailAddress`, "")}
                                        error={get(errors, `emailAddress`, null)}
                                        onChange={(e) => handleInput("contact.emailAddress", e.target.value)}
                                        placeholder="Enter contact email"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="company"
                                        label="Company"
                                        error={get(errors, `company.companyKey`, null)}
                                        initialInputValue={`${get(contact, `company.displayName`, "")}`}
                                        itemToString={(i) => (i == null ? "" : get(i, "displayName", ""))}
                                        onSelect={(p, b) => this.selectCompany(p)}
                                        placeholder="Enter company name"
                                        searchFunction={(input) =>
                                            this.debounceSearchCompany(handleSearchCompany, input)
                                        }
                                        startAdornment={<Search />}
                                        endAdornment={
                                            !isNil(get(contact, `company.companyKey`, null)) ? (
                                                <IconButton
                                                    onClick={this.clearCompany.bind(this)}
                                                    classes={{ root: classes.clearButton }}
                                                >
                                                    <HighlightOff style={{ fontSize: "16px" }} />
                                                </IconButton>
                                            ) : null
                                        }
                                        suggestions={autocompleteResults}
                                        disabled={
                                            disableInputs === true || !isNil(get(contact, `company.companyKey`, null))
                                        }
                                        options={{
                                            key: "companyKey",
                                            display: (suggestion) => {
                                                return get(suggestion, "displayName", "");
                                            },
                                            displaySub: (suggestion) => {
                                                return get(suggestion, "mainAddress.address1", "");
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    &nbsp;
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <BSInput
                                        id="address1"
                                        label="Address"
                                        value={get(contact, `address.address1`, "")}
                                        error={get(errors, `address.address1`, null)}
                                        onChange={(e) => handleInput("contact.address.address1", e.target.value)}
                                        errorText="Required"
                                        placeholder="Enter address"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="city"
                                        label="City"
                                        value={get(contact, `address.city.city`, "")}
                                        error={get(errors, `address.city.city`, null)}
                                        onChange={(e) => handleInput("contact.address.city.city", e.target.value)}
                                        errorText="Required"
                                        placeholder="Enter city"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSSelect
                                        id="state"
                                        label="State"
                                        value={get(contact, `address.city.state.stateCode`, "")}
                                        error={get(errors, `address.city.state.stateCode`, null)}
                                        errorText="Required"
                                        onChange={(e) =>
                                            handleInput("contact.address.city.state.stateCode", e.target.value)
                                        }
                                        options={us_states_options}
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="zipCode"
                                        label="Zip Code"
                                        value={get(contact, `address.zipCode.zipCode`, "")}
                                        error={get(errors, `address.zipCode.zipCode`, null)}
                                        onChange={(e) => handleInput("contact.address.zipCode.zipCode", e.target.value)}
                                        errorText="Invalid"
                                        placeholder="Enter zip code"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                            </Grid>

                            <hr style={{ borderTop: "1px solid #eeeeee" }} />

                            <Grid container justify="space-between">
                                <Grid item xs>
                                    <h4>Set a Reminder</h4>
                                </Grid>
                                <Grid item xs>
                                    <FormControlLabel
                                        className={classes.reminderSwitch}
                                        control={
                                            <KMSwitch
                                                checked={remindersActive ? true : false}
                                                onChange={(e) =>
                                                    handleInput("contact.remindersActiveFlg", e.target.checked)
                                                }
                                                value={remindersActive}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            {remindersActive && (
                                <React.Fragment>
                                    {reminders &&
                                        reminders.map((reminder, index) => {
                                            return (
                                                <React.Fragment>
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        spacing={8}
                                                        style={
                                                            index === 0
                                                                ? { height: "90px" }
                                                                : { height: "90px", marginTop: "-30px" }
                                                        }
                                                    >
                                                        <Grid item xs={1} style={{ marginLeft: "15px" }}>
                                                            <span className={classes.theCounter}>
                                                                {parseInt(index) + 1}
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <BSInput
                                                                id="date"
                                                                errorText="Required"
                                                                placeholder="Enter date"
                                                                value={get(reminder, "date")}
                                                                onChange={(e) =>
                                                                    updateArrayElement(
                                                                        "contact.reminders",
                                                                        index,
                                                                        e.target.id,
                                                                        e.target.value
                                                                    )
                                                                }
                                                                disabled={disableInputs}
                                                                startAdornment={<Today />}
                                                                type="date"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} style={{ flexBasis: 0, width: "22px" }}>
                                                            <span style={{}}>at</span>
                                                        </Grid>
                                                        <Grid item xs={4} style={{}}>
                                                            <BSInput
                                                                id="time"
                                                                type="time"
                                                                errorText="Required"
                                                                placeholder="Enter time"
                                                                value={get(reminder, "time")}
                                                                onChange={(e) =>
                                                                    updateArrayElement(
                                                                        "contact.reminders",
                                                                        index,
                                                                        e.target.id,
                                                                        e.target.value
                                                                    )
                                                                }
                                                                disabled={disableInputs}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} className={classes.centerCell}>
                                                            {disableInputs !== true && (
                                                                <Button
                                                                    onClick={() => this.handleDeleteReminder(index)}
                                                                    className={classes.shrinkButton}
                                                                >
                                                                    <Delete />
                                                                </Button>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container alignItems="center" spacing={8}>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{ marginTop: "-19px", marginLeft: "15px" }}
                                                        ></Grid>

                                                        <Grid item xs={10}>
                                                            <BSInput
                                                                id="reminderNotes"
                                                                label="Notes"
                                                                value={get(reminder, "reminderNotes")}
                                                                onChange={(e) =>
                                                                    updateArrayElement(
                                                                        "contact.reminders",
                                                                        index,
                                                                        e.target.id,
                                                                        e.target.value
                                                                    )
                                                                }
                                                                placeholder="Enter notes"
                                                                disabled={disableInputs === true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            );
                                        })}
                                    <Button
                                        className={classes.moveIt}
                                        color="primary"
                                        onClick={() => this.handleAddReminder()}
                                    >
                                        <Add fill="#4b94c1" /> Add Reminder
                                    </Button>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paperSpacing}>
                        <Grid container alignItems="center" spacing={8}>
                            <Grid item xs>
                                <FormTitle title="Contact Notes" />
                            </Grid>
                        </Grid>

                        <hr style={{ margin: "0px -20px", borderTop: "1px solid #eeeeee" }} />

                        <Grid item xs={12}>
                            <BSInput
                                id="contactNotes"
                                placeholder="Enter your notes here."
                                value={get(contact, "contactNotes", null)}
                                multiline={true}
                                rows={8}
                                onChange={(e) => handleInput("contact.contactNotes", e.target.value)}
                                disabled={disableInputs}
                                extraInputStyles={{ marginBottom: "0px" }}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(AddContact);
