import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { us_states_options } from "../../utils/constants";
import {
    marketAreaOptions,
    micromarketOptions,
    propertyTypeFilterOptions,
    specialtyTypeFilterOptions,
    submarketOptions,
} from "../constants";
import Parcel from "./ParcelBeta";
import BSInput from "../../../../common/js/components/BSInput";
import BSSelect from "../../../../common/js/components/BSSelect";
import ErrorBoundary from "../../../../common/js/components/ErrorBoundary";
import MultiAddInput from "../../../../common/js/components/MultiAddInput";
import "./Property.css";

const PropertyForm = (props) => {
    const [flexContainerClass, setFlexContainerClass] = useState("");

    const {
        attributes,
        errors,
        disableInputs,
        handleAddArrayElement,
        handleDeleteArrayElement,
        handleInputChange,
        handleUpdateArrayElement,
        location,
        property,
        propertyType,
    } = props;

    useEffect(() => {
        setFlexContainerClass(
            location.pathname.includes("property") ? "propertyContainerPropertyView" : "propertyContainerListView"
        );
    }, []);

    const handlePropertyAttributesChange = (event) => {
        handleInputChange("property.propertyAttributes." + event.target.id, event.target.value);
    };

    const handlePropertyChange = (event) => {
        handleInputChange("property." + event.target.id, event.target.value);
    };

    const handlePropertyTypeChange = (event) => {
        handlePropertyChange(event);
        handleInputChange("property.propertyAttributes.specialtyType", "");
        handleInputChange("property.propertyAttributes.marketArea", "");
        handleInputChange("property.propertyAttributes.submarketArea", "");
        handleInputChange("property.propertyAttributes.micromarketArea", "");
    };

    const handleMarketAreaChange = (event) => {
        let marketWithState = event.target.value;
        let marketArea = marketWithState.substring(5, marketWithState.length);

        if (marketArea === "") {
            handleInputChange("property.propertyAttributes.marketArea", "");
        } else {
            handleInputChange("property.propertyAttributes.marketArea", marketArea);
        }

        handleInputChange("property.propertyAttributes.submarketArea", "");
        handleInputChange("property.propertyAttributes.micromarketArea", "");
    };

    const handleSubMarketChange = (event) => {
        handlePropertyAttributesChange(event);
        handleInputChange("property.propertyAttributes.micromarketArea", "");
    };

    const stateCode = get(property, "primaryAddress.city.state.stateCode", "");

    return (
        <div>
            <div className={flexContainerClass}>
                <div className="propertyFlexField">
                    <BSInput
                        id="propertyName"
                        label="PROPERTY NAME"
                        placeholder="Enter property name"
                        helperText={!disableInputs && "Required"}
                        errorText="Required"
                        error={get(errors, "propertyAttributes.propertyName")}
                        value={attributes.propertyName}
                        onChange={handlePropertyAttributesChange}
                        disabled={disableInputs === true}
                    />
                </div>
                <div className="propertyFlexField">
                    <BSInput
                        id="primaryAddress.address1"
                        label="LOCATION ADDRESS"
                        placeholder="Enter location address"
                        helperText={!disableInputs && "Required"}
                        errorText="Required"
                        error={get(errors, "primaryAddress.address1", null)}
                        value={get(property, "primaryAddress.address1", null)}
                        onChange={handlePropertyChange}
                        disabled={disableInputs === true}
                    />
                </div>
            </div>
            <div className={flexContainerClass}>
                <div className="propertyFlexContainerUnresponsive propertyFlexField">
                    <div className="propertyFlexField">
                        <BSInput
                            id="primaryAddress.city.city"
                            label="CITY"
                            error={get(errors, "primaryAddress.city.city", null)}
                            value={get(property, "primaryAddress.city.city", null)}
                            helperText={!disableInputs && "Required"}
                            errorText="Required"
                            onChange={handlePropertyChange}
                            placeholder="Enter city"
                            disabled={disableInputs === true}
                        />
                    </div>
                    <div className="propertyFlexField">
                        <BSSelect
                            id="primaryAddress.city.state.stateCode"
                            label="STATE"
                            error={get(errors, "primaryAddress.city.state.stateCode", null)}
                            value={get(property, "primaryAddress.city.state.stateCode", null)}
                            helperText={!disableInputs && "Required"}
                            errorText="Required"
                            onChange={handlePropertyChange}
                            options={us_states_options}
                            disabled={disableInputs === true}
                        />
                    </div>
                    <div className="propertyFlexField">
                        <BSInput
                            id="primaryAddress.zipCode.zipCode"
                            label="ZIP CODE"
                            error={get(errors, "primaryAddress.zipCode.zipCode", null)}
                            value={get(property, "primaryAddress.zipCode.zipCode", null)}
                            helperText={!disableInputs && "Required"}
                            errorText="Required"
                            onChange={handlePropertyChange}
                            placeholder="Enter zip code"
                            disabled={disableInputs === true}
                        />
                    </div>
                </div>
                <div className="propertyFlexField">
                    <BSInput
                        id="secondaryAddress"
                        label="2ND ADDRESS"
                        placeholder="Enter 2nd address"
                        error={get(errors, "secondaryAddress", null)}
                        value={get(property, "secondaryAddress", null)}
                        onChange={handlePropertyChange}
                        disabled={disableInputs === true}
                    />
                </div>
            </div>
            <div className={flexContainerClass}>
                <div className="propertyFlexContainerUnresponsive propertyFlexField">
                    <div className="propertyFlexField">
                        <BSInput
                            id="primaryAddress.county.name"
                            label="PROPERTY COUNTY"
                            placeholder="Enter county"
                            error={get(errors, "primaryAddress.county.name", null)}
                            value={get(property, "primaryAddress.county.name", null)}
                            onChange={handlePropertyChange}
                            disabled={disableInputs === true}
                        />
                    </div>
                    <div className="propertyFlexField">
                        <BSInput
                            id="propertyKey"
                            label="KM PROPERTY ID"
                            error={errors.propertyKey}
                            value={get(property, "propertyKey", null)}
                            disabled={Boolean(true)}
                        />
                    </div>
                </div>
                <div className="propertyFlexField">
                    <BSInput
                        id="websiteUrl"
                        label="WEBSITE URL"
                        placeholder="Enter website url"
                        error={get(errors, "websiteUrl", null)}
                        value={get(property, "websiteUrl", null)}
                        onChange={handlePropertyChange}
                        disabled={disableInputs === true}
                    />
                </div>
            </div>
            <div className={flexContainerClass}>
                <div className="propertyFlexContainerUnresponsive propertyFlexField">
                    <div className="propertyFlexField">
                        <BSSelect
                            id="propertyType"
                            label="Property Type"
                            helperText={!disableInputs && "Required"}
                            errorText="Required"
                            error={get(errors, "propertyType")}
                            value={propertyType}
                            onChange={handlePropertyTypeChange}
                            options={propertyTypeFilterOptions()}
                            disabled={disableInputs === true}
                        />
                    </div>
                    <div className="propertyFlexField">
                        <BSSelect
                            id="specialtyType"
                            label="Sub-Type"
                            value={attributes.specialtyType}
                            error={get(errors, "propertyAttributes.specialtyType")}
                            onChange={handlePropertyAttributesChange}
                            options={specialtyTypeFilterOptions(propertyType)}
                            disabled={disableInputs === true}
                        />
                    </div>
                </div>
                <div className="propertyFlexContainerUnresponsive propertyFlexField">
                    <div className="propertyFlexField">
                        <BSSelect
                            id="marketArea"
                            label="MARKET AREA"
                            value={
                                attributes.marketArea
                                    ? `${stateCode} - ${attributes.marketArea}`
                                    : attributes.marketArea
                            }
                            error={get(errors, "propertyAttributes.marketArea")}
                            onChange={handleMarketAreaChange}
                            options={marketAreaOptions(
                                propertyType,
                                get(property, "primaryAddress.city.state.stateCode", "")
                            )}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="propertyFlexField">
                        <BSSelect
                            id="submarketArea"
                            label="SUB-MARKET"
                            error={get(errors, "propertyAttributes.submarketArea")}
                            value={attributes.submarketArea}
                            onChange={handleSubMarketChange}
                            options={submarketOptions(propertyType, attributes.marketArea)}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="propertyFlexField">
                        <BSSelect
                            id="micromarketArea"
                            label="MICRO MARKET"
                            error={get(errors, "propertyAttributes.micromarketArea")}
                            value={attributes.micromarketArea}
                            onChange={handlePropertyAttributesChange}
                            options={micromarketOptions(propertyType, attributes.marketArea, attributes.submarketArea)}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
            </div>
            <div className="propertyFlexContainerUnresponsive propertyFlexField">
                <div className="propertyFlexField">
                    <ErrorBoundary>
                        <MultiAddInput
                            addButtonText="Add Parcel"
                            disableInputs={disableInputs}
                            displayFirstWhenEmpty={true}
                            elements={attributes.parcels}
                            onAdd={() => handleAddArrayElement("property.propertyAttributes.parcels")}
                            onUpdate={(e, index) =>
                                handleUpdateArrayElement(
                                    "property.propertyAttributes.parcels",
                                    index,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            render={(element, elementIndex) => (
                                <Parcel
                                    element={element}
                                    elementIndex={elementIndex}
                                    onDelete={(index) =>
                                        handleDeleteArrayElement("property.propertyAttributes.parcels", index)
                                    }
                                    {...props}
                                />
                            )}
                        />
                    </ErrorBoundary>
                </div>
            </div>
        </div>
    );
};

export default PropertyForm;
