import validate from "validate.js";
import { constraints } from "../../utils/validation";
import includes from "lodash/includes";
import each from "lodash/each";
import set from "lodash/set";
import get from "lodash/get";
import forIn from "lodash/forIn";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import mapValues from "lodash/mapValues";

const outsideListingBroker = {
    "brokerAgentKey.person.firstName": {},
    "brokerAgentKey.person.lastName": {},
    "company.displayName": {},
};

const mixedUseContraints = {
    sf: {
        ...constraints.number,
    },
    unitCount: {
        ...constraints.id,
    },
};

const portfolioConstraints = {
    propertyId: {
        ...constraints.id,
    },
};

const condoConstraints = {
    condoNumber: {},
    condoSf: {
        ...constraints.number,
    },
    condoParcelId: {},
};

const listingExpirationConstraint = {
    expirationDate: {
        ...constraints.date,
    },
};

const listingConstraints = {
    Office: {
        //verified
        "attributes.suite": {},
        "attributes.sfAvail": {},
        "attributes.largestContigSf": {},
        "attributes.specialtyType": {},
    },
    Industrial: {
        "attributes.sfAvail": {
            ...constraints.id,
        },
        "attributes.largestContigSf": {},
        "attributes.officeSf": {
            ...constraints.id,
        },
        "attributes.yardSf": {
            ...constraints.id,
        },
        "attributes.officeBreakdownSfRate": {
            ...constraints.number,
        },
        "attributes.warehouseShellBreakdownRate": {
            ...constraints.number,
        },
        "attributes.blendedRate": {
            ...constraints.number,
        },
        "attributes.voltsNum": {},
        "attributes.ampsNum": {},
        "attributes.phaseNum": {},
        "attributes.gradeLevelDoorsNum": {
            ...constraints.id,
        },
        "attributes.dockHighDoorsNum": {
            ...constraints.id,
        },
        "attributes.railDoorsNum": {
            ...constraints.id, //int(11) in DB
        },
        "attributes.clearHeightMin": {
            ...constraints.id,
        },
        "attributes.clearHeightMax": {
            ...constraints.number,
        },
        "attributes.cranesQty": {
            ...constraints.id,
        },
        "attributes.craneNotes": {},
        "attributes.yardNotes": {},
        "attributes.specialtyType": {},
        "attributes.industrialSf": {
            ...constraints.id,
        },
    },
    Retail: {
        //verified
        "attributes.specialtyType": {},
    },
    Land: {
        //verified
        "attributes.sfAvail": {
            ...constraints.id,
        },
        "attributes.totalAcres": {
            ...constraints.number,
        },
        "attributes.specialtyType": {},
    },
    Multifamily: {
        //verified
        "attributes.multifamilyUnitCount": {
            ...constraints.id,
        },
        "attributes.multifamilySf": {
            ...constraints.number,
        },
        "attributes.unitMix": {},
        "attributes.wetlandsPct": {
            ...constraints.number,
        },
        "attributes.specialtyType": {},
    },
    Hospitality: {
        //listing-specific
        "attributes.hospitalitySf": {
            ...constraints.id,
        },
        "attributes.hospitalityUnitCount": {
            ...constraints.id,
        },
        "attributes.roomCount": {
            ...constraints.id,
        },
        "attributes.specialtyType": {},
    },
    Other: {
        "attributes.sfAvail": {},
        "attributes.largestContigSf": {},
        "attributes.suite": {},
        "attributes.secondarySfAvailable": {},
        "attributes.specialtyType": {},
    },
    Educational: {}, //TODO : Add validation when forms are created
    Religious: {},
    Healthcare: {
        //verified
        "attributes.suite": {},
        "attributes.sfAvail": {},
        "attributes.largestContigSf": {},
        "attributes.specialtyType": {},
    },
    listingInformation: {
        //building information?
        listingName: {},
        dateListed: {
            ...constraints.date,
        },
        dateAvailable: {
            ...constraints.date,
        },
        expirationDate: {
            ...constraints.date,
        },
        leaseExpirationDate: {
            ...constraints.date,
        },
        "attributes.totalUnits": {
            ...constraints.number,
        },
        "attributes.numBldg": {
            ...constraints.number,
        },
        "attributes.lotAcres": {
            ...constraints.number,
        },
        "attributes.lotSf": {
            ...constraints.number,
        },
        "attributes.amenities": {},
        "attributes.tourInstructions": {},
        sfAvail: {
            ...constraints.number,
        },
        sfDivisibleTo: {
            ...constraints.number,
        },
    },
    parkingInformation: {
        "attributes.parkingTotal": {
            ...constraints.number,
        },
        "attributes.parkingRatio": {},
        "attributes.surfaceParkingSpaceCount": {
            ...constraints.number,
        },
        "attributes.parkingGarageSpaceCount": {
            ...constraints.number,
        },
        "attributes.parkingReservedSpaceCount": {
            ...constraints.number,
        },
        "attributes.carportParkingSpaceCount": {
            ...constraints.number,
        },
        "attributes.parkingNotes": {},
    },
    listingFinancial: {
        leaseType: {},
        improvementAllowance: {
            ...constraints.number,
        },
        askingRentMax: {
            ...constraints.number,
        },
        askingRentPerMonth: {
            ...constraints.number,
        },
        baseRentPerYear: {
            ...constraints.number,
        },
        expenseType: {},
        expenseTypeValue: {},
        nnnPerMonth: {
            ...constraints.number,
        },
        nnnPerYear: {
            ...constraints.number,
        },
        totalMonthlyRent: {
            ...constraints.number,
        },
        totalRentPerYear: {
            ...constraints.number,
        },
        procuringBrokerCommission: {},
    },
    Lease: {
        //general information
        //required fields
    },
    Sale: {
        //required fields
        listPrice: {
            ...constraints.required,
        },
    },
};

const validateListingBroker = (listingBroker) => {
    return validate(listingBroker, outsideListingBroker, { fullMessages: false });
};

const validateMixedUse = (use) => {
    return validate(use, mixedUseContraints, { fullMessages: false });
};

const validatePortfolio = (portfolio) => {
    return validate(portfolio, portfolioConstraints, { fullMessages: false });
};

const validateCondo = (condo) => {
    return validate(condo, condoConstraints, { fullMessages: false });
};

const validateSingleValue = (field, value, constraint) => {
    return validate({ [field]: value }, constraint, { fullMessages: false });
};

const validateListingBrokers = (listingBrokers) => {
    if (!listingBrokers) {
        return { "listingBrokers.eitherLogic": ["One of either km broker or outside broker must be present"] };
    } else {
        let listingBrokerErrors = {};
        let kmEmpty = true;
        let outsideEmpty = true;
        each(get(listingBrokers, "kmListingBrokers", []), (l, i) => {
            if (get(l, "brokerAgentKey.brokerAgentKey")) {
                kmEmpty = false;
            }
        });
        let counter = 0;
        each(get(listingBrokers, "outsideListingBrokers", []), (l, i) => {
            if (
                get(l, "brokerAgentKey.person.firstName") ||
                get(l, "brokerAgentKey.person.lastName") ||
                get(l, "company.displayName")
            ) {
                outsideEmpty = false;
                const validationResponse = validateListingBroker(l);
                if (isEmpty(validationResponse)) {
                    return;
                } else {
                    set(listingBrokerErrors, `outside.${counter}`, {
                        ...mapValues(validationResponse, (value, key) => {
                            return value[0];
                        }),
                    });
                }
            }
            counter += 1;
        });
        if (kmEmpty && outsideEmpty) {
            set(listingBrokerErrors, `eitherLogic`, "One of either km broker or outside broker must be present");
        }
        return isEmpty(listingBrokerErrors) ? {} : { listingBrokers: listingBrokerErrors };
    }
};

const validateListingBrokersWhenCombined = (listingBrokers) => {
    if (!listingBrokers) {
        return { "listingBrokers.eitherLogic": ["One of either km broker or outside broker must be present"] };
    } else {
        let listingBrokerErrors = {};
        let kmEmpty = true;
        let outsideEmpty = true;
        let counter = 0;
        each(listingBrokers, (l, i) => {
            if (get(l, "brokerAgentKey.kmBrokerFlag")) {
                if (get(l, "brokerAgentKey.brokerAgentKey")) {
                    kmEmpty = false;
                }
            } else {
                if (
                    get(l, "brokerAgentKey.person.firstName") ||
                    get(l, "brokerAgentKey.person.lastName") ||
                    get(l, "company.displayName")
                ) {
                    outsideEmpty = false;
                    const validationResponse = validateListingBroker(l);
                    if (isEmpty(validationResponse)) {
                        return;
                    } else {
                        set(listingBrokerErrors, `outside.${counter}`, {
                            ...mapValues(validationResponse, (value, key) => {
                                return value[0];
                            }),
                        });
                    }
                }
                counter += 1;
            }
        });
        if (kmEmpty && outsideEmpty) {
            set(listingBrokerErrors, `eitherLogic`, "One of either km broker or outside broker must be present");
        }
        return isEmpty(listingBrokerErrors) ? {} : { "listing.listingBrokers": listingBrokerErrors };
    }
};

const validateMixedUses = (mixedUses) => {
    let mixedUseErrors = {};
    let counter = 0;
    each(mixedUses, (m, i) => {
        const validationResponse = validateMixedUse(m);
        if (isEmpty(validationResponse)) {
            return;
        } else {
            set(mixedUseErrors, `${counter}`, {
                ...mapValues(validationResponse, (value, key) => {
                    return value[0];
                }),
            });
        }
        counter += 1;
    });
    return isEmpty(mixedUseErrors) ? {} : { "attributes.mixedUses": mixedUseErrors };
};

const validatePortfolioProperties = (properties) => {
    let errors = {};
    let counter = 0;
    each(properties, (p, i) => {
        const validationResponse = validatePortfolio(p);
        if (isEmpty(validationResponse)) {
            return;
        } else {
            set(errors, `${counter}`, {
                ...mapValues(validationResponse, (value, key) => {
                    return value[0];
                }),
            });
        }
        counter += 1;
    });
    return isEmpty(errors) ? {} : { "attributes.portfolioProperties": errors };
};

const validateCondos = (condos) => {
    let errors = {};
    let counter = 0;
    each(condos, (c, i) => {
        const validationResponse = validateCondo(c);
        if (isEmpty(validationResponse)) {
            return;
        } else {
            set(errors, `${counter}`, {
                ...mapValues(validationResponse, (value, key) => {
                    return value[0];
                }),
            });
        }
        counter += 1;
    });
    return isEmpty(errors) ? {} : { "attributes.condos": errors };
};

const validateAutoRenew = (listing) => {
    if (get(listing, "autoRenewFlg") !== true) {
        const errors = validateSingleValue(
            "expirationDate",
            get(listing, "expirationDate"),
            listingExpirationConstraint
        );
        return isEmpty(errors) ? {} : { expirationDate: true };
    }
    return {};
};

const validateListing = (listing, useType, transactionType, documentState) => {
    let whatToValidate = [];
    switch (transactionType) {
        case "Lease":
            whatToValidate = [useType, transactionType, "listingInformation", "parkingInformation", "listingFinancial"];
            break;
        case "Sale":
            whatToValidate = [useType, transactionType, "listingInformation", "parkingInformation"];
            break;
        default:
            break;
    }
    let combinedConstraints = {};
    each(whatToValidate, (section) => {
        combinedConstraints = { ...combinedConstraints, ...listingConstraints[section] };
    });
    let setErrors = {};
    let errors = {
        ...mapValues(validate(listing, combinedConstraints, { fullMessages: false }), (error) => {
            return error[0];
        }),
        ...validateMixedUses(get(listing, "attributes.mixedUses")),
        ...validatePortfolioProperties(get(listing, "attributes.portfolioProperties")),
        ...validateCondos(get(listing, "attributes.condos")),
    };

    forIn(errors, (value, key) => {
        set(setErrors, key, value);
    });

    return isEmpty(setErrors) ? null : setErrors;
};

const getPanelsToExpand = (errors) => {
    let listingInformationConstraints = {};
    each(["useType", "transactionType", "listingInformation", "parkingInformation"], (section) => {
        listingInformationConstraints = { ...listingInformationConstraints, ...listingConstraints[section] };
    });

    let panelsToExpand = [];
    forIn(errors, (value, key) => {
        if (includes(Object.keys(listingConstraints.listingFinancial), key)) {
            panelsToExpand.push("listingFinancial");
        } else if (includes(Object.keys(listingInformationConstraints), key)) {
            panelsToExpand.push("listingInformation");
        }
    });

    panelsToExpand = uniqBy(panelsToExpand, function (e) {
        return e.id;
    });

    return panelsToExpand;
};

export default {
    validateListingBrokers,
    validateListingBrokersWhenCombined,
    validateAutoRenew,
    validateMixedUses,
    validatePortfolioProperties,
    validateCondos,
    validateListing,
    getPanelsToExpand,
};
