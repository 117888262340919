import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import operations from "./ducks/operations";
import propertyOperations from "../property/ducks/operations";
import ResearchSnapshot from "./ResearchSnapshot";
import TrackResearchModal from "./TrackResearchModal";
import includes from "lodash/includes";
import loadProperty from "../property/ducks/actions";

class ResearchTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            newProperty: "",
            awaitingTrack: false,
            trackModalOpen: false,
        };
    }

    componentDidMount() {
        this.loadData();
        this.setState({
            newProperty: this.props.property,
        });
    }

    loadData = async () => {
        const data = await operations.getResearchByProperty(this.props.propertyKey);
        this.setState({
            data: data,
        });
    };

    changeTracked = async () => {
        const { loadProperty } = this.props;
        await this.setState({
            newProperty: {
                ...this.props.property,
                trackedFlg: !this.state.newProperty.trackedFlg,
            },
            awaitingTrack: true,
        });
        const response = await propertyOperations.handleSubmitPropertyForm(true, this.state.newProperty, null);
        loadProperty(response.data);
        this.setState({
            awaitingTrack: false,
        });
    };

    handleOpenTrackModal = () => {
        this.setState({ trackModalOpen: true });
    };

    handleCloseTrackModal = () => {
        this.setState({ trackModalOpen: false });
    };

    render() {
        const { classes, role } = this.props;

        return (
            <div className={classes.container}>
                {this.state.data != null ? (
                    <div>
                        {this.state.data === "error" ? (
                            <div className={classes.alternateContentRed}>
                                A connection error occurred. Please refresh the page.
                            </div>
                        ) : (
                            <div>
                                <div style={{ display: "inline-block", width: "100%" }}>
                                    <div style={{ float: "left" }}>
                                        <h3 className={classes.paperHeading} style={{ marginBottom: "2px" }}>
                                            Research
                                        </h3>
                                        <p className="page-subtitle" style={{ marginBottom: "16px" }}>{`SHOWING ${
                                            this.state.data.length
                                        } ${this.state.data.length === 1 ? "ENTRY" : "ENTRIES"} `}</p>
                                    </div>
                                    {includes(["research", "researchAdmin", "dataAdmin", "dataEntry"], role) ? (
                                        <div style={{ float: "right", marginTop: "12px" }}>
                                            {this.state.awaitingTrack === false ? (
                                                <div>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={
                                                                    this.state.newProperty.trackedFlg === true
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={() => this.handleOpenTrackModal()}
                                                                color="primary"
                                                                disabled={includes(["dataEntry"], role)}
                                                            />
                                                        }
                                                        label="Tracked"
                                                    />
                                                    <TrackResearchModal
                                                        modalOpen={this.state.trackModalOpen}
                                                        closeModal={this.handleCloseTrackModal}
                                                        confirm={this.changeTracked}
                                                        trackedValue={this.state.newProperty.trackedFlg}
                                                    />
                                                </div>
                                            ) : (
                                                <div className={classes.loadWheel}>
                                                    <CircularProgress size={20} />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {this.state.data.length > 0 ? (
                                    <div>
                                        {
                                            <div className={classes.rsContainer}>
                                                <ResearchSnapshot {...this.props} snapshot={this.state.data} />
                                            </div>
                                        }
                                    </div>
                                ) : (
                                    <div className={classes.alternateContent}>
                                        No Research Snapshots exist for this property.
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={classes.alternateContent}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}

const styles = (theme) => ({
    container: {
        margin: "0 auto",
        position: "relative",
        alignItems: "center",
        paddingBottom: "20px",
    },
    rsContainer: {
        marginBottom: "20px",
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "3em",
        marginBottom: "3em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    alternateContentRed: {
        margin: "auto",
        textAlign: "center",
        marginTop: "3em",
        marginBottom: "3em",
        color: theme.palette.error.dark,
        fontSize: "20px",
    },
    defTerm: {
        fontSize: "13px",
        textTransform: "uppercase",
        color: "#9E9E9E",
        fontWeight: 600,
        lineHeight: "26px",
        whiteSpace: "nowrap",
    },
    paperHeading: {
        fontSize: "18px",
        margin: "14px 0",
    },
    customButtons: {
        display: "inline-block",
        float: "right",
        marginTop: "27px",
    },
    customButtonsBelow: {
        display: "inline-block",
        float: "right",
        marginTop: "0px",
    },
    customButton: {
        backgroundColor: "rgba(0, 0, 255, 0.0)",
        borderWidth: "1px",
        borderRadius: "4px",
        marginRight: "10px",
        height: "30px",
        width: "30px",
    },
    pageNumber: {
        padding: 0,
        marginRight: "10px",
        fontSize: "14px",
        display: "inline-block",
    },
    resultsLabel: {
        marginRight: "5px",
        fontSize: "16px",
    },
    resultsSelector: {
        marginRight: "10px",
        height: "30px",
        width: "40px",
        fontSize: "14px",
    },
    loadWheel: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
        marginTop: "15px",
    },
});

export default withStyles(styles)(connect(null, loadProperty)(ResearchTab));
