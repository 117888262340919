import React from "react";
import Dropzone from "react-dropzone";
import { cloneDeep, forEach, get, includes } from "lodash";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AdminDocumentTypeList from "../AdminDocumentTypeList";
import { docTypeIcons } from "../documentTypes";
import utils from "../../utils/index";
import "./DocumentListRefactor.css";

const styles = {
    root: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    },
    paperPadding: {
        padding: "0 20px 20px",
        border: "none",
        marginBottom: "20px",
        boxShadow: "0 1px 2px rgba(34, 54, 70, 0.1)",
    },
    paperHeading: {
        fontSize: "18px",
        margin: "14px 0",
    },
    hr: {
        margin: "0 -20px 20px -20px",
    },
    hrDivider: {
        margin: "0 -20px",
        borderTop: "1px solid rgba(0, 0, 0, 0.06)",
    },
    uploadButton: {
        color: "#757575",
        fill: "#757575",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },

    approvedBy: {
        fontWeight: 600,
    },
    bsSelect: {
        fontSize: "14px",
        padding: "9px 6px 9px 6px",
        boxSizing: "border-box",
        height: "37px",
        width: "100%",
        "&:focus": {
            backgroundColor: "transparent",
            borderBottom: "none",
        },
        "&::before": {
            borderBottom: "none !important",
        },
        "&::after": {
            borderBottom: "none !important",
        },
    },
    menuInstruction: {
        height: "12px",
        fontSize: "14px",
    },
    menuCategory: {
        height: "12px",
        fontSize: "11px",
        fontStyle: "italic",
        textTransform: "uppercase",
    },
    menuItem: {
        height: "12px",
        fontSize: "14px",
    },
    deleteProgress: {
        width: "24px",
        paddingRight: "10px",
    },
    table: {
        tableLayout: "fixed",
    },
    docIcon: {
        float: "left",
    },
    docName: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "block",
        textOverflow: "ellipsis",
        paddingTop: "2px",
        paddingLeft: "5px",
    },
};

const DocumentList = (props) => {
    const {
        allDocumentTypes,
        alternateTitle,
        anchorEl,
        approveDocument,
        classes,
        copyDocUrl,
        docApproved,
        docMenuKey,
        documentsUploading,
        elementsSaving,
        formDisabled,
        handleAddDocuments,
        handleMenuClose,
        handlePreviewDocument,
        handleOpenDocumentMenu,
        header,
        noHeader,
        open,
        options,
        restrictPreview,
        role,
        savedDocuments,
        selectDocumentType,
        terminationDocument,
        toggleDeleteDialog,
        toggleTerminationDoc,
        type,
        usedDocTypes,
        voucherStatus,
    } = props;

    const handleSelectDocumentType = (key, event) => {
        selectDocumentType(key, event.target.value);
    };

    const handleRemoveDocument = (key, documentType) => {
        toggleDeleteDialog(true, key);

        if (documentType === "TERMINATION_DOCUMENT") {
            toggleTerminationDoc(false);
        }
    };

    const handleApproveDocument = (key) => {
        approveDocument(key);
    };

    const handleCopyDocumentUrl = (key) => {
        copyDocUrl(key);
        handleMenuClose();
    };

    let canView = [];
    let canDelete = [];
    let brokerPaymentDocTypes = cloneDeep(props.documentTypes);
    let documentTypes = cloneDeep(props.documentTypes);
    let disallowOtherOption = false;
    let voucherAwaitingPayment = voucherStatus === "Awaiting Payment" ? true : false;
    let voucherReadyForAccounting = voucherStatus === "Ready For Accounting" ? true : false;
    let dropzoneRef;

    if (options) {
        canView = options.canView;
        canDelete = options.canDelete;
        if (get(options, "canUpload", []).length > 0 && documentTypes !== undefined) {
            forEach(Object.keys(documentTypes), (key) => {
                if (!includes(options.canUpload, key)) {
                    delete documentTypes[key];
                }
            });
        }
    }

    if (role === "broker") {
        forEach(Object.keys(documentTypes), (key) => {
            if (!includes(["OTHER", "COMMISSION_PAYMENT"], key)) {
                delete brokerPaymentDocTypes[key];
            }
        });
    }

    disallowOtherOption = get(options, "disallowOtherOption", false);

    return (
        <Paper className={classes.paperPadding}>
            <div className="attachedDocumentsUnresponsive">
                <div>
                    <h3 className={classes.paperHeading}>
                        {noHeader ? "" : alternateTitle ? alternateTitle : "Attached Documents"}
                    </h3>
                </div>
                <div>
                    <Dropzone
                        accept="image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ref={(node) => {
                            dropzoneRef = node;
                        }}
                        onDrop={(accepted, rejected) => {
                            handleAddDocuments(accepted, rejected, type);
                        }}
                        style={{ display: "none" }}
                    ></Dropzone>
                    <Button
                        disabled={formDisabled}
                        component="span"
                        onClick={() => {
                            dropzoneRef.open();
                        }}
                        className={classes.uploadButton}
                    >
                        <CloudUploadIcon />
                        &nbsp;&nbsp;Upload
                    </Button>
                </div>
            </div>
            <div>
                <div>
                    <hr className={classes.hrDivider} />
                    <div className={classes.table}>
                        <div className="attachedDocumentsContainerHeaderRow">
                            <div className="attachedDocumentsContainerHeader">
                                {header.map((x) => (
                                    <div className={x.extraHeaderClassName}>
                                        <span className="attachedDocumentsContainerHeaderText">{x.name}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="attachedDocumentsContainerHeader hideForSmallerDisplays">
                                {header.map((x) => (
                                    <div className={x.extraHeaderClassName}>
                                        <span className="attachedDocumentsContainerHeaderText">{x.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <hr className={classes.hrDivider} />
                        <div className="attachedDocumentsContainer">
                            {savedDocuments &&
                                Object.keys(savedDocuments).map((x, i) => {
                                    const document = savedDocuments[x];
                                    if (document["parentEntityType"] && props.parentEntityType) {
                                        if (document["parentEntityType"]["name"] !== props.parentEntityType) {
                                            return null;
                                        }
                                    }
                                    let removedDocumentType = false;
                                    if (
                                        document.mediaAssetType === "TERMINATION_DOCUMENT" &&
                                        get(terminationDocument, "upload", null) === false
                                    ) {
                                        console.log("toggle termination");
                                        console.log(props.terminationDocument);
                                        console.log(document.mediaAssetType);
                                        toggleTerminationDoc(true);
                                    }
                                    if (documentTypes !== undefined) {
                                        removedDocumentType = !documentTypes.hasOwnProperty(
                                            get(document, "mediaAssetType")
                                        );
                                    }
                                    return (
                                        <div key={`doc-${i}`} className="attachedDocument">
                                            <div className="attachmentIconAndNameContainer">
                                                <div
                                                    className={
                                                        role === "portlandBrokerAdmin"
                                                            ? "attachmentIconAndNamewApproval"
                                                            : "attachmentIconAndName"
                                                    }
                                                >
                                                    <div className={classes.docIcon}>
                                                        {get(
                                                            docTypeIcons,
                                                            get(document, "mediaAssetName", " ")
                                                                .split(".")
                                                                .pop(),
                                                            docTypeIcons["default"]
                                                        )()}
                                                    </div>

                                                    <div className={classes.docName}>{document["mediaAssetName"]}</div>
                                                </div>
                                                <div
                                                    className={
                                                        role === "portlandBrokerAdmin"
                                                            ? "attachmentDocumentTypewApproval"
                                                            : "attachmentDocumentType"
                                                    }
                                                >
                                                    <AdminDocumentTypeList
                                                        {...props}
                                                        stateCode={props.stateCode}
                                                        parentEntityType={props.parentEntityType}
                                                        preexistingValue={document}
                                                        style={{
                                                            display: props.adminListReady ? "" : "none",
                                                        }}
                                                        mediaAssetKey={x}
                                                        documentTypesEnumList={allDocumentTypes}
                                                        updateDocumentFromAdminList={props.updateDocumentFromAdminList}
                                                        savedDocuments={savedDocuments}
                                                        voucherStatus={voucherStatus}
                                                        formDisabled={formDisabled}
                                                        options={options}
                                                        role={role}
                                                    />
                                                    {voucherAwaitingPayment &&
                                                    role === "broker" &&
                                                    includes(
                                                        ["OTHER", "COMMISSION_PAYMENT"],
                                                        document["mediaAssetType"]
                                                    ) ? (
                                                        //adjusted document dropdown on vouchers for brokers to ONLY add commission payments when voucher is Awaiting Payment
                                                        <Select
                                                            disabled={
                                                                options
                                                                    ? !includes(
                                                                          options.canView,
                                                                          document["mediaAssetType"]
                                                                      ) && document["updated"] === true
                                                                    : formDisabled
                                                            }
                                                            name={`docType-select-${i}`}
                                                            value={
                                                                document["updated"] !== true
                                                                    ? "select"
                                                                    : document["mediaAssetType"]
                                                            }
                                                            onChange={(e) => handleSelectDocumentType(x, e)}
                                                            classes={{ select: classes.bsSelect }}
                                                            className={classes.bsSelect}
                                                            style={{
                                                                display: props.adminListReady ? "none" : "",
                                                            }}
                                                        >
                                                            {documentTypes &&
                                                                Object.keys(brokerPaymentDocTypes).map((r, q) => {
                                                                    if (
                                                                        !usedDocTypes.includes(r) &&
                                                                        !brokerPaymentDocTypes[r]["required"] &&
                                                                        !brokerPaymentDocTypes[r]["multipleAllowed"]
                                                                    ) {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-${i}-${q}`}
                                                                                value={r}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {brokerPaymentDocTypes[r]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    } else if (r === "OTHER" && disallowOtherOption) {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-required-${i}-${q}`}
                                                                                value={r}
                                                                                style={{ display: "none" }}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {brokerPaymentDocTypes[r]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    } else if (
                                                                        !brokerPaymentDocTypes[r]["required"] &&
                                                                        brokerPaymentDocTypes[r]["multipleAllowed"]
                                                                    ) {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-${i}-${q}`}
                                                                                value={r}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {brokerPaymentDocTypes[r]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-required-${i}-${q}`}
                                                                                value={r}
                                                                                style={{ display: "none" }}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {brokerPaymentDocTypes[r]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                })}
                                                        </Select>
                                                    ) : (
                                                        <Select
                                                            disabled={
                                                                (voucherAwaitingPayment || voucherReadyForAccounting) &&
                                                                role === "broker"
                                                                    ? true
                                                                    : options && formDisabled
                                                                    ? !(
                                                                          includes(
                                                                              options.canUpload,
                                                                              document["mediaAssetType"]
                                                                          ) && document["updated"] !== true
                                                                      )
                                                                    : formDisabled
                                                            }
                                                            name={`docType-select-${i}`}
                                                            value={
                                                                document["updated"] !== true
                                                                    ? "select"
                                                                    : removedDocumentType
                                                                    ? allDocumentTypes[document["mediaAssetType"]]
                                                                    : document["mediaAssetType"]
                                                            }
                                                            onChange={(e) => handleSelectDocumentType(x, e)}
                                                            classes={{
                                                                select: classes.bsSelect,
                                                                root: classes.bsSelectRoot,
                                                            }}
                                                            className={classes.bsSelect}
                                                            style={{
                                                                display: props.adminListReady ? "none" : "",
                                                            }}
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                disabled
                                                                className={classes.menuCategory}
                                                            >
                                                                Required
                                                            </MenuItem>
                                                            {documentTypes &&
                                                                Object.keys(documentTypes).map((j, k) => {
                                                                    if (
                                                                        !usedDocTypes.includes(j) &&
                                                                        documentTypes[j]["required"] &&
                                                                        document["mediaAssetType"]
                                                                    ) {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-required-${i}-${k}`}
                                                                                value={j}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {documentTypes[j]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-required-${i}-${k}`}
                                                                                value={j}
                                                                                style={{ display: "none" }}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {documentTypes[j]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                })}
                                                            {documentTypes &&
                                                                Object.keys(documentTypes).map((j, k) => {
                                                                    if (
                                                                        usedDocTypes.includes(j) &&
                                                                        documentTypes[j]["required"] &&
                                                                        documentTypes[j]["multipleAllowed"]
                                                                    ) {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-required-${i}-${k}`}
                                                                                value={j}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {documentTypes[j]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-required-${i}-${k}`}
                                                                                value={j}
                                                                                style={{ display: "none" }}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {documentTypes[j]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                })}
                                                            <Divider />
                                                            <MenuItem
                                                                value=""
                                                                disabled
                                                                className={classes.menuCategory}
                                                            >
                                                                Optional
                                                            </MenuItem>
                                                            {/* THIS ALLOWS FOR DOCUMENT TYPE TO DISPLAY IF THE TYPE IS NO LONGER ALLOWED */}
                                                            {documentTypes && removedDocumentType ? (
                                                                <MenuItem
                                                                    key={`doc-type-removed-1`}
                                                                    value={allDocumentTypes[document["mediaAssetType"]]}
                                                                    className={classes.menuItem}
                                                                >
                                                                    {allDocumentTypes[document["mediaAssetType"]]
                                                                        ? allDocumentTypes[document["mediaAssetType"]][
                                                                              "display"
                                                                          ]
                                                                        : "Other"}
                                                                </MenuItem>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {documentTypes &&
                                                                Object.keys(documentTypes).map((r, q) => {
                                                                    if (
                                                                        !usedDocTypes.includes(r) &&
                                                                        !documentTypes[r]["required"] &&
                                                                        !documentTypes[r]["multipleAllowed"]
                                                                    ) {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-${i}-${q}`}
                                                                                value={r}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {documentTypes[r]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    } else if (r === "OTHER" && disallowOtherOption) {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-required-${i}-${q}`}
                                                                                value={r}
                                                                                style={{ display: "none" }}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {documentTypes[r]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    } else if (
                                                                        !documentTypes[r]["required"] &&
                                                                        documentTypes[r]["multipleAllowed"]
                                                                    ) {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-${i}-${q}`}
                                                                                value={r}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {documentTypes[r]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <MenuItem
                                                                                key={`doc-type-required-${i}-${q}`}
                                                                                value={r}
                                                                                style={{ display: "none" }}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                {documentTypes[r]["display"]}
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                })}
                                                        </Select>
                                                    )}
                                                </div>
                                                {role === "portlandBrokerAdmin" && (
                                                    <div className="attachedDocumentApprover">
                                                        <div className={classes.approvedBy}>
                                                            {get(document, "approvedBy", "")}
                                                        </div>
                                                        <div>
                                                            {utils.operations.formatDate("MM-DD-YYYY")(
                                                                get(document, "approvedByTimestamp", "")
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="attachedDocumentsDropdownOptionsContainer">
                                                <div className="attachedDocumentsDropdownOptions">
                                                    {get(elementsSaving, `documents.${x}`) === true ? (
                                                        <div className={classes.deleteProgress}>
                                                            <CircularProgress size={20}></CircularProgress>
                                                        </div>
                                                    ) : (
                                                        <IconButton
                                                            style={{ display: "flex" }}
                                                            disabled={
                                                                restrictPreview
                                                                    ? true
                                                                    : options
                                                                    ? includes(canView, document["mediaAssetType"]) ||
                                                                      includes(canDelete, document["mediaAssetType"])
                                                                        ? false
                                                                        : true
                                                                    : false
                                                            }
                                                            aria-label="More"
                                                            aria-owns={
                                                                Boolean(document["anchorEl"])
                                                                    ? `long-menu-${x}-${i}`
                                                                    : null
                                                            }
                                                            aria-haspopup="true"
                                                            onClick={(e) => handleOpenDocumentMenu(x, e.currentTarget)}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    )}
                                                    <Menu
                                                        id={`long-menu-${x}-${i}`}
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleMenuClose}
                                                        PaperProps={{
                                                            style: {
                                                                width: 200,
                                                                boxShadow:
                                                                    "0px 4px 6px 1px rgba(0, 0, 0, 0.05), 0px 4px 11px 1px rgba(0, 0, 0, 0.05), 7px 4px 6px -3px rgba(0, 0, 0, 0.05)",
                                                            },
                                                        }}
                                                    >
                                                        {(!options ||
                                                            includes(
                                                                canView,
                                                                get(savedDocuments, `${docMenuKey}.mediaAssetType`)
                                                            )) && (
                                                            <MenuItem
                                                                onClick={() =>
                                                                    handlePreviewDocument(
                                                                        get(document, "url", null),
                                                                        docMenuKey
                                                                    )
                                                                }
                                                                className={classes.menuItem}
                                                            >
                                                                Open in a new tab
                                                            </MenuItem>
                                                        )}
                                                        {(!options ||
                                                            includes(
                                                                canView,
                                                                get(savedDocuments, `${docMenuKey}.mediaAssetType`)
                                                            )) && (
                                                            <MenuItem
                                                                onClick={() => handleCopyDocumentUrl(docMenuKey)}
                                                                className={classes.menuItem}
                                                            >
                                                                Copy Link
                                                            </MenuItem>
                                                        )}
                                                        {role === "portlandBrokerAdmin" && (
                                                            <MenuItem
                                                                id={`doc-approve-${x}-${i}`}
                                                                disabled={docApproved}
                                                                onClick={() => handleApproveDocument(docMenuKey)}
                                                                className={classes.menuItem}
                                                            >
                                                                Approve
                                                            </MenuItem>
                                                        )}
                                                        {(!options ||
                                                            includes(
                                                                canDelete,
                                                                get(savedDocuments, `${docMenuKey}.mediaAssetType`)
                                                            )) && (
                                                            <MenuItem
                                                                onClick={() =>
                                                                    handleRemoveDocument(
                                                                        docMenuKey,
                                                                        get(
                                                                            savedDocuments,
                                                                            `${docMenuKey}.mediaAssetType`
                                                                        )
                                                                    )
                                                                }
                                                                className={classes.menuItem}
                                                            >
                                                                Delete
                                                            </MenuItem>
                                                        )}
                                                    </Menu>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                            {documentsUploading
                                ? Object.keys(documentsUploading).map((x) => {
                                      if (get(documentsUploading, x)) {
                                          return (
                                              <div className="uploadProgressBar">
                                                  <LinearProgress style={{ width: "80%" }} />
                                              </div>
                                          );
                                      } else {
                                          return null;
                                      }
                                  })
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </Paper>
    );
};

export default withStyles(styles)(DocumentList);
