export const ROLES = {
    accounting: "accounting",
    anonymous: "anonymous",
    appraiser: "appraiser",
    arizonaBrokerAdmin: "arizonaBrokerAdmin",
    auditor: "auditor",
    broker: "broker",
    dataAdmin: "dataAdmin",
    dataEntry: "dataEntry",
    debtEquity: "debtEquity",
    evp: "evp",
    general: "general",
    officeManager: "officeManager",
    portlandBrokerAdmin: "portlandBrokerAdmin",
    propertyManagement: "propertyManagement",
    research: "research",
    researchAdmin: "researchAdmin",
    transition: "transition",
};

export const SCOPES = {
    canViewAudit: "can-view-audit",
    canViewFilters: "can-view-filters",
    canAddToList: "can-add-to-list",
};

export const PERMISSIONS = {
    [ROLES.accounting]: [],
    [ROLES.anonymous]: [],
    [ROLES.appraiser]: [],
    [ROLES.arizonaBrokerAdmin]: [],
    [ROLES.auditor]: [SCOPES.canViewAudit],
    [ROLES.broker]: [],
    [ROLES.dataAdmin]: [SCOPES.canViewAudit, SCOPES.canViewFilters, SCOPES.canAddToList],
    [ROLES.dataEntry]: [SCOPES.canViewAudit, SCOPES.canViewFilters, SCOPES.canAddToList],
    [ROLES.evp]: [],
    [ROLES.general]: [],
    [ROLES.officeManager]: [],
    [ROLES.portlandBrokerAdmin]: [],
    [ROLES.propertyManagement]: [],
    [ROLES.research]: [],
    [ROLES.researchAdmin]: [],
    [ROLES.transition]: [],
};

export const audit_tabs = {
    auditorSearch: "auditor_search",
    auditorList: "auditor_list",
};

const ADMIN_URL = "admin/";

export const urls = {
    get_approved_audit_list: () => ADMIN_URL + "audit-list",
    update_deals_audit_flags: () => ADMIN_URL + "update-audit",
};
