import actions from "./actions";
import utilsActions from "../../utils/actions";
import constants from "../constants";
import get from "lodash/get";
import { request } from "../../utils/axios-wrapper";
import moment from "moment";

/**
 *
 * evp operations
 *
 */
const getEVPDashboardData = () => {
    return (dispatch, getState) => {
        const status = "Closed";
        const region = get(getState().dashboardReducer.dashboardParams, "region");
        let startDate = get(getState().dashboardReducer.dashboardParams, "startDate", null);
        let endDate = get(getState().dashboardReducer.dashboardParams, "endDate", null);

        if (startDate == null) {
            const currentDate = new Date();
            if (currentDate.getFullYear() === 2019) {
                startDate = moment("01/17/2019", "MM/DD/YYYY").format("YYYY-MM-DD");
            } else {
                startDate = moment().dayOfYear(1).format("YYYY-MM-DD");
            }

            dispatch(actions.changeDashboardParam("startDate", startDate));
        }

        if (endDate == null) {
            endDate = moment().format("YYYY-MM-DD");
            dispatch(actions.changeDashboardParam("endDate", endDate));
        }

        dispatch(topKmClosedDeals(status, region, startDate, endDate));
        dispatch(topKmBrokers(status, region, startDate, endDate));
        dispatch(commissionBreakdownByOffice(status, region, startDate, endDate));
        dispatch(commissionBreakdownByType(status, region, startDate, endDate));
        dispatch(ytdTotalConsideration(status, region, startDate, endDate));
        dispatch(ytdTotalCommission(status, region, startDate, endDate));
        dispatch(avgTimeOnMarket(region));
        dispatch(noncompliantDeals(status, region));
    };
};

const topKmClosedDeals = (statusGroup, region, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("topKmClosedDeals", true));
        request(
            {
                method: "get",
                url: constants.urls.top_km_deals(statusGroup, region, startDate, endDate),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setEvpDataComponent("topKmClosedDeals", response.data));
                dispatch(utilsActions.setLoader("topKmClosedDeals", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("topKmClosedDeals", false));
            });
    };
};

const topKmBrokers = (statusGroup, region, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("topKmBrokers", true));
        request(
            {
                method: "get",
                url: constants.urls.top_km_brokers(statusGroup, region, startDate, endDate),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setEvpDataComponent("topKmBrokers", response.data));
                dispatch(utilsActions.setLoader("topKmBrokers", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("topKmBrokers", false));
            });
    };
};

const commissionBreakdownByOffice = (statusGroup, region, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("commissionBreakdownByOffice", true));
        request(
            {
                method: "get",
                url: constants.urls.commission_breakdown_by_office(statusGroup, region, startDate, endDate),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setEvpDataComponent("commissionBreakdownByOffice", response.data));
                dispatch(utilsActions.setLoader("commissionBreakdownByOffice", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("commissionBreakdownByOffice", false));
            });
    };
};

const commissionBreakdownByType = (statusGroup, region, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("commissionBreakdownByType", true));
        request(
            {
                method: "get",
                url: constants.urls.commission_breakdown_by_type(statusGroup, region, startDate, endDate),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setEvpDataComponent("commissionBreakdownByType", response.data));
                dispatch(utilsActions.setLoader("commissionBreakdownByType", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("commissionBreakdownByType", false));
            });
    };
};

const ytdTotalConsideration = (statusGroup, region, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("ytdTotalConsideration", true));
        request(
            {
                method: "get",
                url: constants.urls.ytd_total_consideration(statusGroup, region, startDate, endDate),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setEvpDataComponent("ytdTotalConsideration", response.data));
                dispatch(utilsActions.setLoader("ytdTotalConsideration", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("ytdTotalConsideration", false));
            });
    };
};

const ytdTotalCommission = (statusGroup, region, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("ytdTotalCommission", true));
        request(
            {
                method: "get",
                url: constants.urls.ytd_total_commission(statusGroup, region, startDate, endDate),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setEvpDataComponent("ytdTotalCommission", response.data));
                dispatch(utilsActions.setLoader("ytdTotalCommission", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("ytdTotalCommission", false));
            });
    };
};

const avgTimeOnMarket = (region) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("avgTimeOnMarket", true));
        request(
            {
                method: "get",
                url: constants.urls.avg_time_on_market(region),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setEvpDataComponent("avgTimeOnMarket", response.data));
                dispatch(utilsActions.setLoader("avgTimeOnMarket", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("avgTimeOnMarket", false));
            });
    };
};

const noncompliantDeals = (statusGroup, region) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("noncompliantDeals", true));
        request(
            {
                method: "get",
                url: constants.urls.noncompliant_deals(statusGroup, region),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setEvpDataComponent("noncompliantDeals", response.data));
                dispatch(utilsActions.setLoader("noncompliantDeals", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("noncompliantDeals", false));
            });
    };
};

const handleEvpDashboardInput = (name, value) => {
    return (dispatch, getState) => {
        dispatch(actions.changeDashboardParam(name, value));
        dispatch(getEVPDashboardData());
    };
};
const handleDashboardInput = (name, value) => {
    return (dispatch, getState) => {
        dispatch(actions.changeDashboardParam(name, value));
        dispatch(getBrokerDashboardData());
    };
};

const resetDashboardParams = () => {
    return (dispatch, getState) => {
        dispatch(actions.changeDashboardParam("startDate", null));
        dispatch(actions.changeDashboardParam("endDate", null));
    };
};
/**
 *
 * broker operations
 *
 */

const getBrokerDashboardData = (status = "Open") => {
    return (dispatch, getState) => {
        const userProfile = get(getState().authReducer, "userProfile", null);
        let startDate = get(getState().dashboardReducer.dashboardParams, "startDate", null);
        let endDate = get(getState().dashboardReducer.dashboardParams, "endDate", null);

        if (startDate == null) {
            const currentDate = new Date();
            if (get(userProfile, "officeRegion", null) === "Pacific Northwest") {
                let tmpMoment = moment("07/01/" + currentDate.getFullYear(), "MM/DD/YYYY");
                if (currentDate.getMonth() + 1 < 7) {
                    tmpMoment = tmpMoment.subtract(1, "years");
                }
                startDate = tmpMoment.format("YYYY-MM-DD");
                endDate = moment().format("YYYY-MM-DD");
                dispatch(actions.changeDashboardParam("startDate", startDate));
                dispatch(actions.changeDashboardParam("endDate", endDate));
            } else {
                if (currentDate.getFullYear() === 2019) {
                    startDate = moment("01/17/2019", "MM/DD/YYYY").format("YYYY-MM-DD");
                } else {
                    startDate = moment().dayOfYear(1).format("YYYY-MM-DD");
                }
                dispatch(actions.changeDashboardParam("startDate", startDate));
            }
        }

        if (endDate == null) {
            endDate = moment().format("YYYY-MM-DD");
            dispatch(actions.changeDashboardParam("endDate", endDate));
        }

        dispatch(recentOpenListings(status));
        dispatch(recentOpenDeals(status));
        dispatch(brokerYTDCommission("Closed", startDate, endDate));
        dispatch(brokerYTDListings(status, startDate, endDate));
        dispatch(brokerAvgTimeOnMarket());
        dispatch(brokerYTDClosedDeals(startDate, endDate));
        dispatch(brokerLicenseExpiration());
    };
};

const recentOpenListings = (statusGroup) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("recentOpenListings", true));
        request(
            {
                method: "get",
                url: constants.urls.recent_open_listings(statusGroup),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setBrokerDataComponent("recentOpenListings", response.data));
                dispatch(utilsActions.setLoader("recentOpenListings", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("recentOpenListings", false));
            });
    };
};

const recentOpenDeals = (statusGroup) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("recentOpenDeals", true));
        request(
            {
                method: "get",
                url: constants.urls.recent_open_deals(statusGroup),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setBrokerDataComponent("recentOpenDeals", response.data));
                dispatch(utilsActions.setLoader("recentOpenDeals", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("recentOpenDeals", false));
            });
    };
};

const brokerYTDCommission = (statusGroup, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("brokerYTDCommission", true));
        request(
            {
                method: "get",
                url: constants.urls.broker_ytd_commission(statusGroup, startDate, endDate),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setBrokerDataComponent("brokerYTDCommission", response.data));
                dispatch(utilsActions.setLoader("brokerYTDCommission", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("brokerYTDCommission", false));
            });
    };
};

const brokerYTDListings = (statusGroup, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("brokerYTDListings", true));
        request(
            {
                method: "get",
                url: constants.urls.broker_ytd_listings(statusGroup, startDate, endDate),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setBrokerDataComponent("brokerYTDListings", response.data));
                dispatch(utilsActions.setLoader("brokerYTDListings", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("brokerYTDListings", false));
            });
    };
};

const brokerAvgTimeOnMarket = () => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("brokerAvgTimeOnMarket", true));
        request(
            {
                method: "get",
                url: constants.urls.broker_avg_time_on_market(),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setBrokerDataComponent("brokerAvgTimeOnMarket", response.data));
                dispatch(utilsActions.setLoader("brokerAvgTimeOnMarket", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("brokerAvgTimeOnMarket", false));
            });
    };
};

const brokerYTDClosedDeals = (startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("brokerYTDClosedDeals", true));
        request(
            {
                method: "get",
                url: constants.urls.broker_ytd_closed_deals(startDate, endDate),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setBrokerDataComponent("brokerYTDClosedDeals", response.data));
                dispatch(utilsActions.setLoader("brokerYTDClosedDeals", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("brokerYTDClosedDeals", false));
            });
    };
};

const brokerLicenseExpiration = () => {
    return (dispatch, getState) => {
        dispatch(utilsActions.setLoader("brokerLicenseExpiration", true));
        request(
            {
                method: "get",
                url: constants.urls.broker_license_exp(),
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setBrokerDataComponent("brokerLicenseExpiration", response.data));
                dispatch(utilsActions.setLoader("brokerLicenseExpiration", false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utilsActions.setLoader("brokerLicenseExpiration", false));
            });
    };
};

/**
 *
 * shared functions
 *
 */
const handleSort = (component, columnName) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };
    return (dispatch, getState) => {
        let sortDirection =
            get(getState(), `dashboardReducer.sort.${component}.columnName`) === columnName
                ? invertDirection[get(getState(), `dashboardReducer.sort.${component}.sortDirection`)]
                : "asc";
        dispatch(actions.handleSort(component, columnName, sortDirection));
    };
};

export default {
    getEVPDashboardData,
    getBrokerDashboardData,
    topKmClosedDeals,
    topKmBrokers,
    commissionBreakdownByOffice,
    ytdTotalConsideration,
    ytdTotalCommission,
    avgTimeOnMarket,
    noncompliantDeals,
    handleSort,
    handleDashboardInput,
    resetDashboardParams,
    handleEvpDashboardInput,
};
