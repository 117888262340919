const LOAD_CONTACTS_FOR_USER = "LOAD_CONTACTS_FOR_USER";
const ADD_CRM_CONTACT_INPUT = "ADD_CRM_CONTACT_INPUT";
const SET_CRM_ARRAY = "SET_CRM_ARRAY";
const DELETE_CRM_ARRAY_ELEMENT = "DELETE_CRM_ARRAY_ELEMENT";
const LOAD_CONTACT = "LOAD_CONTACT";
const FILTER_CRM_CONTACTS = "FILTER_CRM_CONTACTS";
const SORT_CRM_CONTACTS = "SORT_CRM_CONTACTS";

export default {
    LOAD_CONTACTS_FOR_USER,
    ADD_CRM_CONTACT_INPUT,
    SET_CRM_ARRAY,
    DELETE_CRM_ARRAY_ELEMENT,
    LOAD_CONTACT,
    FILTER_CRM_CONTACTS,
    SORT_CRM_CONTACTS,
};
