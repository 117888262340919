import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import BSInput from "../../../common/js/components/BSInput";
import Button from "@material-ui/core/Button";
import Delete from "../../../../node_modules/@material-ui/icons/Delete";
import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
    iconOnly: {
        marginLeft: 0,
        marginBottom: "10px",
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
    centerCell: {
        textAlign: "center",
        fill: "#9e9e9e",
    },
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
});
class PortfolioPropertyDetail extends React.Component {
    render() {
        const {
            classes,
            dataPathPrefix,
            element,
            elementIndex,
            errors,
            handleUpdateArrayElement,
            disableInputs,
            onDelete,
        } = this.props;
        return (
            <div>
                <Grid
                    container
                    alignItems="center"
                    spacing={8}
                    style={elementIndex === 0 ? { height: "90px" } : { height: "90px", marginTop: "-30px" }}
                >
                    <Grid item xs>
                        <BSInput
                            id="propertyId"
                            label={elementIndex === 0 ? "Property ID" : null}
                            errorText="(ex. 98101)"
                            placeholder="Enter property ID"
                            value={element ? element.propertyId : null}
                            error={get(errors, `portfolioProperties.${elementIndex}.propertyId`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement(
                                    dataPathPrefix + ".portfolioProperties",
                                    elementIndex,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.centerCell}>
                        {elementIndex > 0 && disableInputs !== true && (
                            <Button onClick={() => onDelete(elementIndex)} className={classes.shrinkButton}>
                                <Delete />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(PortfolioPropertyDetail);
