import React, { Component } from "react";
import DocumentAdminLanding from "./DocumentAdminLanding";
import { connect } from "react-redux";
import operations from "./ducks/operations";
import { includes } from "lodash";
import ReactGA from "react-ga4";

class DocumentAdminLandingContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: "/document-type", title: "Document Admin Landing" });
    }

    render() {
        const { role } = this.props;

        if (includes(["dataAdmin"], role)) {
            return (
                <div>
                    <DocumentAdminLanding {...this.props} />
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        columnToSort: state.stateDocumentRequirementsReducer.columnToSort,
        sortDirection: state.stateDocumentRequirementsReducer.sortDirection,
        role: state.authReducer.userProfile.role,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleSort: (columnName) => {
            dispatch(operations.handleSort(columnName));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentAdminLandingContainer);
