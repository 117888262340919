import React from "react";
import Dropzone from "react-dropzone";
import find from "lodash/find";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { acceptedFileTypes } from "../../utils/constants";
import DocumentListRefactor from "./DocumentListRefactorBeta";
import DocumentListViewOnly from "../DocumentListViewOnly";
import "./DocumentDropComponentBeta.css";

const styles = {
    documentDropContainer: {
        padding: 0,
        marginBottom: "20px",
    },
    dropzone: {
        borderRadius: "3px",
        border: "2px dashed #dbdbdb",
    },
    dropzoneUnderneath: {
        borderRadius: "3px",
        border: "2px dashed #dbdbdb",
        marginTop: "10px",
    },
    dropzoneComponent: {
        width: "100%",
        "& > input": {
            width: "100%",
        },
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    dropzoneComponentUnderneath: {
        width: "100%",
        "& > input": {
            width: "100%",
        },
        textAlign: "center",
        minHeight: "10vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    icon: {
        width: "70px",
        height: "70px",
        color: "#9E9E9E",
    },
    iconSmall: {
        width: "40px",
        height: "40px",
        color: "#9E9E9E",
    },
    dropzoneSmallTextWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "20px",
    },
};

const standardHeaders = [
    {
        name: "Name",
        prop: "documentName",
        type: "plaintext",
        extraHeaderClassName: "standardHeaderCol1",
    },
    {
        name: "Document Type",
        prop: "documentType",
        type: "plaintext",
        extraHeaderClassName: "standardHeaderCol2",
    },
    {
        name: "",
        prop: "",
        type: "plaintext",
        extraHeaderClassName: "standardHeaderCol3",
    },
];

const approvalHeaders = [
    {
        name: "",
        prop: "",
        type: "plaintext",
        extraHeaderClassName: "approvalHeaderCol1",
    },
    {
        name: "Name",
        prop: "documentName",
        type: "plaintext",
        extraHeaderClassName: "approvalHeaderCol2",
    },
    {
        name: "Document Type",
        prop: "documentType",
        type: "plaintext",
        extraHeaderClassName: "approvalHeaderCol3",
    },
    {
        name: "Approved By",
        prop: "",
        type: "plaintext",
        extraHeaderClassName: "approvalHeaderCol4",
    },
];

class DocumentDrop extends React.Component {
    state = {
        anchorEl: null,
        docKey: null,
        adminListReady: false,
        adminListRendered: false,
        missingDocs: [],
    };

    componentDidMount() {
        this.adminDocumentTypeListAvailable();
    }

    componentDidUpdate(prevProps) {
        if (
            !isEqual(this.props.savedDocuments, prevProps.savedDocuments) ||
            !isEqual(this.props.requiredDocs, prevProps.requiredDocs)
        ) {
            this.determineMissingRequiredDocuments();
        }
    }

    componentWillUnmount() {
        this.props.resetRequiredMissingDocuments();
    }

    handleOpenDocumentMenu = (x, anchorEl) => {
        this.setState({ anchorEl: anchorEl });
        this.setState({ docKey: x });
    };

    toggleDeleteDialog = (open, docKey) => {
        if (open) {
            this.handleMenuClose();
            this.setState({ deleteDialog: true, docToDelete: docKey });
        } else {
            this.setState({ deleteDialog: false, docToDelete: null });
        }
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    deleteAndMenuClose = () => {
        this.setState({ anchorEl: null });
        this.props.removeDocument(this.state.docToDelete);
        this.toggleDeleteDialog(false);
    };

    previewAndMenuClose = (docKey, url) => {
        console.log("reaching here");
        this.setState({ anchorEl: null });
        this.props.viewDocument(docKey, url);
    };

    handleAddDocuments = (accepted, rejected, ownerType) => {
        this.props.addDocuments(this.props.entityKey, this.props.type, accepted, ownerType);
        if (rejected.length > 0) {
            this.props.handleRejectedDocs(rejected);
        }
    };

    handleApproveDocumentAndClose = (docKey) => {
        this.setState({ anchorEl: null });
        this.props.approveDocument(docKey);
    };

    adminDocumentTypeListAvailable = () => {
        if (this.props.parentType && !this.state.adminListRendered) {
            this.setState({
                adminListRendered: true,
            });
            this.props.setAdminDocumentTypeListAvailable();
            this.setState({
                adminListReady: true,
            });
        }
    };

    determineMissingRequiredDocuments = () => {
        const docTypeRequiredList = get(this.props, "requiredDocs", []);

        const fulfilledDocs = docTypeRequiredList
            .map((requiredDocument) => {
                const savedDocumentMatch = find(this.props.savedDocuments, (savedDoc) => {
                    return requiredDocument.documentTypeKey === get(savedDoc, "documentType.documentTypeKey");
                });

                return savedDocumentMatch ? get(savedDocumentMatch, "documentType.documentTypeKey") : null;
            })
            .filter((doc) => doc);

        const missingDocs = docTypeRequiredList.filter((document) => !fulfilledDocs.includes(document.documentTypeKey));
        this.setState({
            missingDocs: missingDocs,
            missingDocsSubset: missingDocs.slice(0, 8),
        });
    };

    renderHelperMessage = (type) => {
        return (
            <React.Fragment>
                {type === "comp" && (
                    <div className="helperText">For best results, the Marketing Flyer Photo should be 1224x440px.</div>
                )}
                {type === "voucher" && (
                    <div className="helperText">
                        You will be paid fastest if you attach your Commission Payment or Equivalent
                    </div>
                )}
                {type === "property" && (
                    <div className="helperText">
                        Preferred specs for Primary Photos:
                        <div className="helperTextFlexBox">
                            <div>4:3 Aspect Ratio</div>
                            <div>1440 x 1080 Pixels (Resolution)</div>
                        </div>
                    </div>
                )}
                {this.state.missingDocs.length > 0 && (
                    <div className="helperText missingDocumentsText">
                        <h4>Missing required documents for submission:</h4>
                        <div className="helperTextFlexBox">
                            {this.state.missingDocs.map((doc) => (
                                <div>{doc["name"]}</div>
                            ))}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    };

    render() {
        const {
            allDocumentTypes,
            classes,
            columnToSort,
            disableUploads,
            documentTypes,
            documentsUploading,
            downloadDocument,
            downloadView,
            errors,
            formDisabled,
            handleDocumentSort,
            includeSource,
            loading,
            noHeader,
            role,
            savedDocuments,
            sortDirection,
            toggleTerminationDoc,
            terminationDocument,
            type,
            updateDocument, // (key, mediaAssetType)
            usedDocTypes,
            viewOnly,
        } = this.props;

        const { anchorEl, docKey } = this.state;
        const open = Boolean(anchorEl);

        return viewOnly ? (
            <DocumentListViewOnly
                {...this.props}
                open={open}
                anchorEl={anchorEl}
                docMenuKey={docKey}
                documentTypes={documentTypes}
                handlePreviewDocument={this.previewAndMenuClose}
                handleDownloadDocument={downloadDocument}
                handleMenuClose={this.handleMenuClose}
                handleOpenDocumentMenu={this.handleOpenDocumentMenu}
                downloadView={downloadView === true}
                includeSource={includeSource}
                type={type}
            />
        ) : (
            <div className={classes.documentDropContainer}>
                {loading ? (
                    <CircularProgress size={20} />
                ) : // No documents are attached
                Object.keys(savedDocuments).length === 0 && Object.keys(documentsUploading).length === 0 ? (
                    <React.Fragment>
                        <div className={classes.dropzone}>
                            <Dropzone
                                accept={acceptedFileTypes}
                                disabled={disableUploads}
                                onDrop={(accepted, rejected) => {
                                    this.handleAddDocuments(accepted, rejected, type);
                                }}
                                className={classes.dropzoneComponent}
                            >
                                <div className={classes.dropzoneSmallTextWrapper}>
                                    <CloudUploadIcon className={classes.iconSmall} />
                                    <div className="dropzoneTextContainer">
                                        <div className="dropzoneUploadHeaderText">
                                            Drop files on page to attach or browse here
                                        </div>
                                        <div className="dropzoneUploadText">
                                            (.csv, .doc, .docx, .jpg, .msg, .pdf, and .png files only)
                                        </div>
                                    </div>
                                </div>
                            </Dropzone>
                        </div>

                        {this.renderHelperMessage(type)}
                    </React.Fragment>
                ) : (
                    // Documents are attached
                    <React.Fragment>
                        <DocumentListRefactor
                            {...this.props}
                            handleAddDocuments={this.handleAddDocuments}
                            errors={errors}
                            header={
                                type === "user"
                                    ? standardHeaders
                                    : role === "portlandBrokerAdmin"
                                    ? approvalHeaders
                                    : standardHeaders
                            }
                            handleSort={handleDocumentSort}
                            columnToSort={columnToSort}
                            sortDirection={sortDirection}
                            selectDocumentType={updateDocument}
                            allDocumentTypes={allDocumentTypes}
                            documentTypes={documentTypes}
                            usedDocTypes={usedDocTypes}
                            handleOpenDocumentMenu={this.handleOpenDocumentMenu}
                            handleMenuClose={this.handleMenuClose}
                            toggleDeleteDialog={this.toggleDeleteDialog}
                            handlePreviewDocument={this.previewAndMenuClose}
                            anchorEl={anchorEl}
                            open={open}
                            docMenuKey={docKey}
                            savedDocuments={savedDocuments}
                            documentsUploading={documentsUploading}
                            formDisabled={formDisabled}
                            role={role}
                            approveDocument={this.handleApproveDocumentAndClose}
                            docApproved={Boolean(get(savedDocuments, docKey + ".approvedBy", null) != null)}
                            toggleTerminationDoc={toggleTerminationDoc}
                            terminationDocument={terminationDocument}
                            parentEntityType={this.props.parentType}
                            stateCode={this.props.stateCode}
                            adminListReady={this.state.adminListReady}
                            noHeader={noHeader}
                        />

                        <div className={classes.dropzoneUnderneath}>
                            <Dropzone
                                disabled={disableUploads}
                                onDrop={(accepted, rejected) => {
                                    this.handleAddDocuments(accepted, rejected, type);
                                }}
                                className={classes.dropzoneComponentUnderneath}
                            >
                                <div className={classes.dropzoneSmallTextWrapper}>
                                    <CloudUploadIcon className={classes.iconSmall} />
                                    <div className="dropzoneTextContainer">
                                        <div className="dropzoneUploadHeaderText">
                                            Drop files on page to attach or browse here
                                        </div>
                                        <div className="dropzoneUploadText">
                                            (.csv, .doc, .docx, .jpg, .msg, .pdf, and .png files only)
                                        </div>
                                    </div>
                                </div>
                            </Dropzone>
                        </div>

                        {this.renderHelperMessage(type)}
                    </React.Fragment>
                )}
                <Dialog open={this.state.deleteDialog} maxWidth="sm" aria-labelledby="confirmation-dialog-title">
                    <DialogTitle style={{ textAlign: "center" }} id="confirmation-dialog-title">
                        Delete Document?
                    </DialogTitle>
                    <DialogContent style={{ textAlign: "center" }}>
                        <div>Are you sure you want to delete this document? </div>
                        <div>This change cannot be undone </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleDeleteDialog(false, this.state.docToDelete)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.deleteAndMenuClose()} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(DocumentDrop);
