import validate from "validate.js";
import { constraints } from "../../utils/validation";

const dealBasicInfoValidationSchema = {
    transactionDate: {
        ...constraints.required,
        ...constraints.date,
    },
    listingNumber: {
        ...constraints.id,
    },
    totalKmCommission: {
        ...constraints.required,
        ...constraints.number,
    },
    representationType: {
        ...constraints.required,
    },
    useType: {
        ...constraints.required,
    },
};

const leaseDealValidationSchema = {
    spaceType: {
        ...constraints.required,
    },
    leaseType: {
        ...constraints.required,
    },
};

const saleDealValidationSchema = {
    closedDate: {
        ...constraints.required,
        ...constraints.date,
    },
};

const dealInstallmentValidationSchema = {
    amount: {
        ...constraints.required,
        ...constraints.number,
    },
    dueDate: {
        ...constraints.required,
        ...constraints.date,
    },
};

const dealTotalConsiderationValidationSchema = {
    squareFeet: {
        ...constraints.required,
        ...constraints.number,
    },
    termNumMonths: {
        ...constraints.required,
        ...constraints.number,
    },
    commencementDate: {
        ...constraints.required,
        ...constraints.date,
    },
    expirationDate: {
        ...constraints.required,
        ...constraints.date,
    },
    escalationTerm: {
        ...constraints.number,
    },
    escalationRate: {
        ...constraints.number,
    },
    ratePerSqftPerMonth: {
        ...constraints.required,
        ...constraints.number,
    },
    ratePerSqftPerYear: {
        ...constraints.required,
        ...constraints.number,
    },
    ratePerMonth: {
        ...constraints.required,
    },
};

const participantInformationValidationSchema = {
    firstName: {
        ...constraints.required,
    },
    lastName: {
        ...constraints.required,
    },
    contactType: {
        ...constraints.required,
    },
    address1: {
        ...constraints.required,
    },
    city: {
        ...constraints.required,
    },
    state: {
        ...constraints.required,
    },
    phoneNumber: {
        ...constraints.required,
    },
    emailAddress: {
        ...constraints.required,
    },
    zipCode: {
        ...constraints.required,
    },
};

const validateDeal = (state) => {
    let errors = {};
    return errors;
};

const validateBasicDealInfo = (deal) => {
    return validate(deal, dealBasicInfoValidationSchema, { fullMessages: false });
};

const validateLeaseDealInfo = (deal) => {
    return validate(deal, leaseDealValidationSchema, { fullMessages: false });
};

const validateSaleDealInfo = (deal) => {
    return validate(deal, saleDealValidationSchema, { fullMessages: false });
};

const validateInstallments = (installments) => {
    return validate(installments, dealInstallmentValidationSchema, { fullMessages: false });
};

const validateTotalConsideration = (totalConsideration) => {
    return validate(totalConsideration, dealTotalConsiderationValidationSchema, { fullMessages: false });
};

const validateParticipants = (participants) => {
    return validate(participants, participantInformationValidationSchema, { fullMessages: false });
};

export default {
    validateDeal,
    validateBasicDealInfo,
    validateInstallments,
    validateTotalConsideration,
    validateParticipants,
    validateLeaseDealInfo,
    validateSaleDealInfo,
};
