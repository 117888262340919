import React from "react";
import toLower from "lodash/toLower";
import { FormControlLabel, Checkbox } from "@material-ui/core/";
import BSInput from "../../../../common/js/components/BSInput";
import "./Hospitality.css";

const HospitalityForm = (props) => {
    const { context, data, disableInputs, errors, listingStatus, onChange, transactionType } = props;

    const handleAttributeUpdate = (event) => {
        onChange(event.target.id, event.target.value);
    };

    return (
        <div>
            {context === "property" && (
                <div>
                    <div className="hospitalityFlexContainerUnresponsive">
                        <div className="hospitalityFlexField">
                            <BSInput
                                id="condoUnitCount"
                                label="CONDO UNITS"
                                errorText="ex. 98"
                                placeholder="Enter number"
                                error={errors.condoUnitCount}
                                value={data.condoUnitCount}
                                onChange={handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </div>
                        <div className="hospitalityFlexField">
                            <BSInput
                                id="avgRevPar"
                                label="AVERAGE REVPAR"
                                errorText="ex. 98.10"
                                error={errors.avgRevPar}
                                value={data.avgRevPar}
                                onChange={handleAttributeUpdate}
                                startAdornment="$"
                                disabled={disableInputs}
                            />
                        </div>
                    </div>
                    <div className="hospitalityFlexContainerUnresponsive">
                        <div className="hospitalityFlexField">
                            <BSInput
                                id="officeSf"
                                label="OFFICE SPACE"
                                placeholder="Enter SF"
                                errorText="ex. 98101"
                                error={errors.officeSf}
                                value={data.officeSf}
                                onChange={handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </div>
                        <div className="hospitalityFlexField">
                            <BSInput
                                id="retailSf"
                                label="RETAIL SPACE"
                                placeholder="Enter SF"
                                errorText="ex. 98101"
                                error={errors.retailSf}
                                value={data.retailSf}
                                onChange={handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </div>
                    </div>
                    <div className="hospitalityFlexContainerUnresponsive">
                        <div className="hospitalityFlexField">
                            <BSInput
                                id="penthouseUnitCount"
                                label="PENTHOUSE UNIT COUNT"
                                placeholder="Enter number"
                                error={errors.penthouseUnitCount}
                                value={data.penthouseUnitCount}
                                onChange={handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </div>
                        <div className="hospitalityFlexField">
                            <BSInput
                                id="multifamilyUnitCount"
                                label="MULTIFAMILY UNIT COUNT"
                                placeholder="Enter count"
                                errorText="ex. 98"
                                error={errors.multifamilyUnitCount}
                                value={data.multifamilyUnitCount}
                                onChange={handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </div>
                    </div>
                </div>
            )}

            {context === "listing" && (
                <div>
                    <div className="hospitalityFlexContainer">
                        <div className="hospitalityFlexContainerUnresponsive">
                            <div className="hospitalityFlexField">
                                <BSInput
                                    id="hospitalityUnitCount"
                                    label="HOSPITALITY UNIT COUNT"
                                    placeholder="Enter count"
                                    errorText="ex. 98"
                                    error={errors.hospitalityUnitCount}
                                    value={data.hospitalityUnitCount}
                                    onChange={handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </div>
                            <div className="hospitalityFlexField">
                                <BSInput
                                    id="hospitalitySf"
                                    label="HOSPITALITY SF"
                                    placeholder="Enter SF"
                                    errorText="ex. 98101"
                                    error={errors.hospitalitySf}
                                    value={data.hospitalitySf}
                                    onChange={handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </div>
                        </div>
                        <div className="hospitalityFlexContainerUnresponsive hospitalityAlignCheckbox">
                            <div className="hospitalityFlexField">
                                <BSInput
                                    id="roomCount"
                                    label="Room Count"
                                    placeholder="Enter room count"
                                    errorText="ex. 98"
                                    error={errors.roomCount}
                                    value={data.roomCount}
                                    onChange={handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </div>
                            <div className="hospitalityCheckbox">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.affordableHousingFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="affordableHousingFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Affordable Housing"
                                />
                            </div>
                        </div>
                    </div>
                    {toLower(transactionType) === "sale" && listingStatus === "Appraisal" && (
                        <div className="hospitalityFlexContainer">
                            <div className="hospitalityFlexContainerUnresponsive">
                                <div className="hospitalityFlexField">
                                    <BSInput
                                        id="commercialSf"
                                        label="Commercial SF"
                                        placeholder="Enter commercial sf"
                                        error={errors.commercialSf}
                                        value={data.commercialSf}
                                        onChange={handleAttributeUpdate}
                                        disabled={disableInputs}
                                        integer
                                    />
                                </div>
                                <div className="hospitalityFlexField">
                                    <BSInput
                                        id="avgRevPar"
                                        label="REVPAR"
                                        placeholder="Enter revpar"
                                        errorText="ex. 98"
                                        error={errors.avgRevPar}
                                        value={data.avgRevPar}
                                        onChange={handleAttributeUpdate}
                                        disabled={disableInputs}
                                        numeric
                                    />
                                </div>
                            </div>
                            <div className="hospitalityFlexContainerUnresponsive">
                                <div className="hospitalityFlexField">
                                    <BSInput
                                        id="adr"
                                        label="ADR"
                                        placeholder="Enter room count"
                                        error={errors.adr}
                                        value={data.adr}
                                        onChange={handleAttributeUpdate}
                                        disabled={disableInputs}
                                    />
                                </div>
                                <div className="hospitalityFlexField">
                                    <BSInput
                                        id="rating"
                                        label="Rating"
                                        placeholder="Enter rating"
                                        error={errors.rating}
                                        value={data.rating}
                                        onChange={handleAttributeUpdate}
                                        disabled={disableInputs}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div>
                <BSInput
                    id="unitMix"
                    label="Room Mix Breakdown"
                    placeholder="Enter room mix breakdown..."
                    error={errors.unitMix}
                    value={data.unitMix}
                    onChange={handleAttributeUpdate}
                    disabled={disableInputs}
                    multiline={true}
                    rows={4}
                />
            </div>
        </div>
    );
};

export default HospitalityForm;
