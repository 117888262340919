const ADD_DOCUMENTS = "ADD_DOCUMENTS";
const ADD_DOCUMENT = "ADD_DOCUMENT";
const SORT_DOCUMENTS = "SORT_DOCUMENTS";
const SELECT_DOC_TYPE = "SELECT_DOC_TYPE";
const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
const OPEN_DOC_MENU = "OPEN_DOC_MENU";
const RECEIVE_DOCUMENTS = "RECEIVE_DOCUMENTS";
const RECEIVE_PROPERTY_DOCUMENTS = "RECEIVE_PROPERTY_DOCUMENTS";
const STASH_DOCUMENT_URL = "STASH_DOCUMENT_URL";
const CLOSE_DOC_MENU = "CLOSE_DOC_MENU";
const DOCUMENTS_LOADING = "DOCUMENTS_LOADING";
const DOCUMENT_UPLOADING = "DOCUMENT_UPLOADING";
const SET_DOCUMENT_UPDATED_FLAG = "SET_DOCUMENT_UPDATED_FLAG";
const REMOVE_FROM_UPLOADING_QUEUE = "REMOVE_FROM_UPLOADING_QUEUE";
const APPROVE_DOCUMENT = "APPROVE_DOCUMENT";
const RECEIVE_MISSING_REQUIRED_DOCUMENTS = "RECEIVE_MISSING_REQUIRED_DOCUMENTS";
const GET_DOCUMENT_REQUIREMENTS_BY_PARENT_ENTITY_TYPE = "GET_DOCUMENT_REQUIREMENTS_BY_PARENT_ENTITY_TYPE";
const GET_PARENT_ENTITY_TYPE_KEY = "GET_PARENT_ENTITY_TYPE_KEY";
const SET_ADMIN_DOCUMENT_TYPE_LIST_AVAILABLE = "SET_ADMIN_DOCUMENT_TYPE_LIST_AVAILABLE";
const RESET_REQUIRED_MISSING_DOCUMENTS = "RESET_REQUIRED_MISSING_DOCUMENTS";

export default {
    ADD_DOCUMENTS,
    ADD_DOCUMENT,
    SORT_DOCUMENTS,
    SELECT_DOC_TYPE,
    REMOVE_DOCUMENT,
    OPEN_DOC_MENU,
    RECEIVE_DOCUMENTS,
    STASH_DOCUMENT_URL,
    CLOSE_DOC_MENU,
    DOCUMENTS_LOADING,
    DOCUMENT_UPLOADING,
    SET_DOCUMENT_UPDATED_FLAG,
    REMOVE_FROM_UPLOADING_QUEUE,
    APPROVE_DOCUMENT,
    RECEIVE_PROPERTY_DOCUMENTS,
    RECEIVE_MISSING_REQUIRED_DOCUMENTS,
    GET_DOCUMENT_REQUIREMENTS_BY_PARENT_ENTITY_TYPE,
    GET_PARENT_ENTITY_TYPE_KEY,
    SET_ADMIN_DOCUMENT_TYPE_LIST_AVAILABLE,
    RESET_REQUIRED_MISSING_DOCUMENTS,
};
