import types from "./types";

const INITIAL_STATE = {
    deals: [],
    dealsExport: {
        portland_broker_admin_closed: null,
        arizona_broker_admin_closed: null,
    },
    columnToSort: "transactionDate",
    sortDirection: "desc",
    listingModal: {
        isOpen: false,
    },
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.RECEIVE_DEALS:
            return Object.assign({}, state, {
                deals: {
                    ...state.deals,
                    [action.tab]: action.deals,
                },
            });
        case types.RECEIVE_DEALS_EXPORT:
            return Object.assign({}, state, {
                dealsExport: {
                    ...state.dealsExport,
                    [action.tab]: action.dealsExport,
                    exportInProgress: false,
                },
            });
        case types.INITIATE_DEALS_EXPORT:
            return Object.assign({}, state, {
                dealsExport: {
                    ...state.dealsExport,
                    [action.tab]: action.dealsExport,
                    exportInProgress: true,
                },
            });
        case types.CLEAR_DEALS_EXPORT:
            return Object.assign({}, state, {
                dealsExport: {
                    ...state.dealsExport,
                    portland_broker_admin_closed: null,
                    arizona_broker_admin_closed: null,
                    exportInProgress: false,
                },
            });
        case types.SWITCH_DEAL_VIEW:
            return Object.assign({}, state, {
                dealView: action.dealView,
            });
        case types.SORT_DEALS:
            return Object.assign({}, state, {
                columnToSort: action.columnToSort,
                sortDirection: action.sortDirection,
            });
        case types.FILTER_DEALS:
            return Object.assign({}, state, {
                filterQuery: action.filterQuery,
            });
        case types.SELECT_ALL:
            return Object.assign({}, state, {
                selected: action.selected,
            });
        case types.CHECKBOX_CLICK:
            return Object.assign({}, state, {
                selected: action.selected,
            });

        case types.AUTOSAVE_NOT_SUCCESS:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
                saveError: action.saveError,
            });
        case types.AUTOSAVE_SUCCESS:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
                lastSaved: action.lastSaved,
            });
        case types.SET_DEAL_PROGRESS:
            return {
                ...state,
                deals: {
                    ...state.deals,
                    [action.tab]: action.deals,
                },
            };
        case types.CLEAR_DEALS_STATE:
            return {
                ...state,
                deals: {},
            };
        case "RESET_STATE":
            return INITIAL_STATE;
        default:
            return state;
    }
};
