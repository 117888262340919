import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PortfolioPropertyDetail from "./PortfolioPropertyDetailBeta";
import BSInput from "../../../../common/js/components/BSInput";
import ErrorBoundary from "../../../../common/js/components/ErrorBoundary";
import MultiAddInput from "../../../../common/js/components/MultiAddInput";
import "./Portfolio.css";

const styles = () => ({
    switchTitle: {
        fontSize: 14,
        fontWeight: 600,
    },
    theSwitch: {
        marginRight: 0,
    },
});

const PortfolioForm = (props) => {
    const {
        classes,
        data,
        dataPathPrefix,
        disableInputs,
        errors,
        handleAddArrayElement,
        handleDeleteArrayElement,
        handleUpdateArrayElement,
        onChange,
    } = props;

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                    <span className="switchTitle">Portfolio Information</span>
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={data.portfolioFlg === true ? true : false}
                                onChange={(e, checked) => onChange(e.target.value, checked)}
                                color="primary"
                                value="portfolioFlg"
                                disabled={disableInputs}
                                className={classes.theSwitch}
                            />
                        }
                    />
                </div>
            </div>
            {data.portfolioFlg && (
                <div>
                    <BSInput
                        id="portfolioName"
                        label="Portfolio"
                        disabled={disableInputs}
                        placeholder="Enter name"
                        error={errors.portfolioName}
                        value={data.portfolioName}
                        onChange={(e) => onChange(e.target.id, e.target.value)}
                    />
                    <ErrorBoundary>
                        <MultiAddInput
                            addButtonText="Add Portfolio Property"
                            disableInputs={disableInputs}
                            displayFirstWhenEmpty={true}
                            elements={data.portfolioProperties}
                            onAdd={() => handleAddArrayElement(dataPathPrefix + ".portfolioProperties")}
                            onUpdate={(e, index) =>
                                handleUpdateArrayElement(
                                    dataPathPrefix + ".portfolioProperties",
                                    index,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            render={(element, elementIndex) => (
                                <PortfolioPropertyDetail
                                    element={element}
                                    elementIndex={elementIndex}
                                    onDelete={(index) =>
                                        handleDeleteArrayElement(dataPathPrefix + ".portfolioProperties", index)
                                    }
                                    {...props}
                                />
                            )}
                        />
                    </ErrorBoundary>
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(PortfolioForm);
