import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Comments from "../comments/CommentsContainer";
import DocumentDrop from "../documents/DocumentDropContainer";
import ListingRequest from "./ListingRequest";
import ListingToolbar from "./ListingToolbar";
import operations from "./ducks/operations";
import constants from "./constants";
import utils from "../utils/index";
import comments_operations from "../comments/ducks/operations";
import comments_constants from "../comments/constants";
import document_operations from "../documents/ducks/operations";
import get from "lodash/get";
import includes from "lodash/includes";
import Property from "../property/Property";
import property_operations from "../property/ducks/operations";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "../../../../node_modules/@material-ui/icons/ExpandMore";
import AutosaveSuccess from "../../../../node_modules/@material-ui/icons/CheckCircle";
import Divider from "@material-ui/core/Divider";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import contactOperations from "../contact/ducks/operations";
import contactConstants from "../contact/constants";
import searchOperations from "../search/ducks/operations";
import CircularProgress from "@material-ui/core/CircularProgress";
import queryString from "query-string";
import { Address } from "../../../common/js/icons";
import PropertyUpdateModalContainer from "../property/PropertyUpdateModalContainer";
import ReactGA from "react-ga4";

class ListingRequestContainer extends Component {
    constructor() {
        super();
        this.state = {
            showRequestUpdate: false,
            showSubmit: false,
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        const queryParams = queryString.parse(this.props.location.search);
        const action = get(queryParams, "action", null);
        if (id) {
            if (
                action === "start" &&
                (this.props.userProfile.role === "dataAdmin" || this.props.userProfile.role === "dataEntry")
            ) {
                this.props.handleSubmit(false, constants.urls.assign_listing_for_review(id), true, id);
            } else {
                this.props.initListing(id, action);
            }
        }
        ReactGA.send({ hitType: "pageview", page: "/listing/*", title: `Listing Request: ${id}` });
    }

    componentWillUnmount() {
        this.props.clearListingState();
        this.props.clearUtilsErrors();
    }

    shouldDisableListingInputs() {
        let disableListingInput = this.props.disableInputs;
        if (get(this.props.listing, "nonKmListingFlg", false) === true) {
            // is outside listing
            if (
                get(this.props.listing, "status") === "Update Requested" &&
                includes(["dataEntry", "dataAdmin"], get(this.props.userProfile, "role"))
            ) {
                disableListingInput = false;
            } else if (get(this.props.listing, "status") === "Active") {
                // Requirement 6.10.19 anyone can edit active outside listings
                disableListingInput = false;
            } else {
                disableListingInput =
                    get(this.props.listing, "auditCreatedBy", null) !== get(this.props.userProfile, "user");
            }
        } else if (this.props.listing.status === "Draft" || this.props.listing.status === "Broker Action Required") {
            disableListingInput =
                get(this.props.listing, "auditCreatedBy", null) !== get(this.props.userProfile, "user");
        }
        return disableListingInput;
    }

    render() {
        const { id } = this.props.match.params;
        const disableListingInput = this.shouldDisableListingInputs();

        const setRequestUpdateAndSubmit = (listing, userProfile) => {
            const activeStatus = ["Update Requested", "Active Broker Action Required", "Active", "Pending"].includes(
                listing.status
            );
            const dataAdminOrDataEntry = ["dataAdmin", "dataEntry"].includes(userProfile.role);
            const kmListing = !listing.nonKmListingFlg;

            if (activeStatus && dataAdminOrDataEntry && kmListing) {
                this.setState({ showRequestUpdate: false });
                this.setState({ showSubmit: true });
            } else if (activeStatus && kmListing) {
                this.setState({ showRequestUpdate: true });
                this.setState({ showSubmit: false });
            } else if (activeStatus && !kmListing) {
                this.setState({ showRequestUpdate: true });
                this.setState({ showSubmit: true });
            } else {
                this.setState({ showRequestUpdate: false });
                this.setState({ showSubmit: true });
            }
        };

        return (
            <div>
                <div>
                    <ListingToolbar {...this.props} disableInputs={disableListingInput} />
                    <Grid container spacing={16}>
                        <Grid item xs={6}>
                            <ExpansionPanel>
                                {(get(this.props.userProfile, "role") === "dataAdmin" &&
                                    get(this.props.listing, "status") === "Active") ||
                                (includes(["dataEntry", "dataAdmin"], get(this.props.userProfile, "role")) &&
                                    includes(["Active", "Inactive"], get(this.props.listing, "status"))) ||
                                disableListingInput === false ? (
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <AutosaveSuccess className="waterloo" style={{ paddingTop: "4px" }} />
                                        <Grid item xs={5} style={{ maxWidth: "225px" }}>
                                            <div style={{ paddingTop: "4px", paddingLeft: "30px" }}>
                                                Property Information
                                            </div>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <div
                                                style={{ paddingTop: "6px", paddingLeft: "0px" }}
                                                onClick={() => this.props.togglePropertySearchModal(true)}
                                            >
                                                <Address />
                                            </div>
                                        </Grid>
                                    </ExpansionPanelSummary>
                                ) : (
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <AutosaveSuccess className="waterloo" style={{ paddingTop: "4px" }} />
                                        <div style={{ paddingTop: "4px", paddingLeft: "30px" }}>
                                            Property Information
                                        </div>
                                    </ExpansionPanelSummary>
                                )}
                                <Divider />
                                <ExpansionPanelDetails>
                                    {this.props.isLoading ? ( // Spinner
                                        <Grid container justify="center">
                                            <CircularProgress color="primary" />
                                        </Grid>
                                    ) : (
                                        <Property
                                            {...this.props}
                                            attributes={this.props.propertyAttributes}
                                            handleInputChange={this.props.handlePropertyInputChange}
                                            handleAddArrayElement={this.props.handlePropertyAddArrayElement}
                                            handleUpdateArrayElement={this.props.handlePropertyUpdateArrayElement}
                                            handleDeleteArrayElement={this.props.handlePropertyDeleteArrayElement}
                                            disableInputs={true}
                                            userProfile={this.props.userProfile}
                                        />
                                    )}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ListingRequest
                                {...this.props}
                                autocompleteResults={this.props.autocompleteResults}
                                handleSearchCompany={this.props.handleSearchCompany}
                                handleDeleteArrayElement={this.props.handleListingDeleteArrayElement}
                                handleAddArrayElement={this.props.handleAddArrayElement}
                                handleDeleteArrayElementNEW={this.props.handleDeleteArrayElementNEW}
                                handleUpdateArrayElement={this.props.handleListingUpdateArrayElement}
                                handleListingUpdateArrayElement={this.props.handleUpdateArrayElement}
                                data={this.props.listing}
                                property={this.props.property}
                                disableInputs={disableListingInput}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {this.props.listing.listingKey ? (
                                <DocumentDrop
                                    entityKey={id}
                                    type={"LISTING"}
                                    documentTypes={
                                        get(this.props.listing, "nonKmListingFlg")
                                            ? "listingFormOutside"
                                            : "listingForm"
                                    }
                                    options={this.props.determineDocumentOptions}
                                    formDisabled={disableListingInput}
                                    errors={get(this.props.errors, "listing.documents")}
                                    parentType={
                                        get(this.props.listing, "nonKmListingFlg") ? "OUTSIDE LISTING" : "LISTING"
                                    }
                                    stateCode={
                                        get(this.props.listing, "attributes.address.city.state.stateCode", null)
                                            ? get(this.props.listing, "attributes.address.city.state.stateCode")
                                            : get(this.props.property, "primaryAddress.city.state.stateCode", null)
                                    }
                                />
                            ) : (
                                <CircularProgress style={{ left: "75%", top: "50%", position: "absolute" }} />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Comments
                                {...this.props}
                                showRequestUpdateListing={this.state.showRequestUpdate}
                                showSubmitListing={this.state.showSubmit}
                                setRequestUpdateAndSubmit={setRequestUpdateAndSubmit}
                                parentRecordType={comments_constants.LISTNG_REQUEST_PARENT_RECORD_TYPE}
                                parentRecordId={id}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <PropertyUpdateModalContainer {...this.props} formType="listing" />
                </div>
            </div>
        );
    }
}

const determineDocumentOptions = (user, role, listing, personKey) => {
    const isCDT = includes(["dataEntry", "dataAdmin"], role);
    const isEVP = includes(["evp"], role);
    const isCreatedByUser = user === listing.auditCreatedBy;
    let isBroker = false;
    listing.listingBrokers &&
        listing.listingBrokers.kmListingBrokers.forEach((x) => {
            if (get(x, "brokerAgentKey.person.personKey", null) != null) {
                if (personKey === x.brokerAgentKey.person.personKey) {
                    isBroker = true;
                }
            }
        });

    const listingDocTypes = [
        "LISTING_AGREEMENT",
        "AMENDED_LISTING_AGREEMENT",
        "PHOTO",
        "FLOOR_PLAN",
        "PRIMARY_PHOTO",
        "FLYER",
        "SECONDARY_FLYER",
        "OTHER",
        "AMENITIES",
    ];
    const officeManagerDocTypes = [
        "PHOTO",
        "FLOOR_PLAN",
        "PRIMARY_PHOTO",
        "FLYER",
        "SECONDARY_FLYER",
        "OTHER",
        "AMENITIES",
    ];

    const allDocTypes = [
        "COMMISSION_PAYMENT",
        "INVOICE",
        "W9",
        "AMENITIES",
        "FLOOR_PLAN",
        "FLYER",
        "PHOTO",
        "PRIMARY_PHOTO",
        "AIR_PROPERTY_INFORMATION",
        "ANY_OFFER",
        "AMENDMENTS",
        "COMMISSION_STATEMENT",
        "CORRESPONDENCE",
        "DEPOSIT_HISTORY",
        "EARTHQUAKE_DISCLOSURE",
        "EXHIBITS",
        "FULLY_EXECUTED_LEASE",
        "MASTER_LEASE_IF_SUBLEASE",
        "RELEVANT_CORRESPONDENCE",
        "CLOSING_STATEMENT_BUYERS",
        "CLOSING_STATEMENT_BUYER_NOT_REPRESENTED",
        "CLOSING_STATEMENT_SELLERS",
        "CLOSING_STATEMENT_SELLERS_NOT_REPRESENTED",
        "CONTINGENCY_REMOVAL",
        "EARNEST_MONEY_OR_EQUIVALENT",
        "ESCROW_INSTRUCTIONS",
        "ESCROW_RECEIPT",
        "FIRPTA",
        "PRELIM_TITLE_REPORT",
        "PURCHASE_AND_SALE_AGREEMENT",
        "SELLERS_DISCLOSURE",
        "TERMINATION_DOCUMENT",
        "TERMITE_INSPECTION_REPORT",
        "BANK_STATEMENT",
        "INSURANCE",
        "LLC_INFORMATION",
        "PROPERTY_NUMBERS",
        "TAXES",
        "THIRD_PARTY_REPORT",
        "TITLE",
        "LISTING_AGREEMENT",
        "AMENDED_LISTING_AGREEMENT",
        "PHOTO",
        "FLOOR_PLAN",
        "PRIMARY_PHOTO",
        "FLYER",
        "SECONDARY_FLYER",
        "MARKETING_FLYER_PHOTO",
        "EXCLUSIVE",
        "OFFERING_MEMORANDUM",
        "OTHER",
        "AMENITIES",
    ];

    let options = {
        canView: [],
        canDelete: [],
        canUpload: [],
    };

    /**
     * Requirement from 5.23.19:
     *
     * For Listings in Draft: Only creator can upload, view delete
     * For Active Listings: Anyone can upload, anyone can delete PRIMARY_PHOTO and FLYER
     * For Inactive Listings: View only, LISTING_AGREEMENT, EXCLUSIVE, OTHER only by creator, dataEntry/dataAdmin
     *
     * Document types: LISTING_AGREEMENT, EXCLUSIVE, OTHER can only ever be viewed/modified by creator or dataEntry/dataAdmin
     *
     */

    // Needs to be the user who created the listing
    if (includes(["Draft", "Broker Action Required"], listing.status)) {
        if (isCreatedByUser) {
            options.canView = [...allDocTypes];
            options.canDelete = [...listingDocTypes, "EXCLUSIVE"];
            options.canUpload = [...listingDocTypes];
        } else if (isEVP) {
            options.canView = [...allDocTypes];
        } else if (isBroker) {
            options.canView = [...allDocTypes];
            options.canDelete = [...listingDocTypes];
            options.canUpload = [...listingDocTypes];
        } else {
            options = null;
        }
    } else if (includes(["Needs Review", "In Review", "Admin Action Required", "Final Review"], listing.status)) {
        if (isCreatedByUser || isCDT) {
            options.canView = [...allDocTypes];
            options.canDelete = [...listingDocTypes, "EXCLUSIVE"];
            options.canUpload = [...listingDocTypes];
        } else if (isEVP) {
            options.canView = [...allDocTypes];
        } else if (isBroker) {
            options.canView = [...listingDocTypes, "EXCLUSIVE"];
            options.canDelete = [...listingDocTypes, "EXCLUSIVE"];
            options.canUpload = [...listingDocTypes];
        } else {
            options = null;
        }
    } else if (includes(["Active", "Available", "Inactive", "Update Requested"], listing.status)) {
        if (isCreatedByUser || isCDT) {
            options.canView = [...allDocTypes];
            options.canDelete = [...listingDocTypes, "EXCLUSIVE"];
            options.canUpload = [...listingDocTypes];
        } else if (isEVP) {
            options.canView = [...allDocTypes];
        } else if (isBroker) {
            options.canView = [...allDocTypes];
            options.canDelete = ["PRIMARY_PHOTO", "FLYER", "SECONDARY_FLYER", "AMENITIES"];
            options.canUpload = [...listingDocTypes];
            options.disallowOtherOption = true;
        } else if (role === "officeManager") {
            options.canView = [...allDocTypes];
            options.canDelete = [...officeManagerDocTypes];
            options.canUpload = [...officeManagerDocTypes];
            options.disallowOtherOption = true;
        } else {
            options.canView = ["PHOTO", "FLOOR_PLAN", "PRIMARY_PHOTO", "FLYER", "SECONDARY_FLYER", "AMENITIES"];
            options.canDelete = ["PRIMARY_PHOTO", "FLYER", "SECONDARY_FLYER", "AMENITIES"];
            options.canUpload = [...listingDocTypes];
            options.disallowOtherOption = true;
        }
    } else {
        options = null;
    }
    return options;
};

const mapStateToProps = (state) => {
    return {
        userProfile: state.authReducer.userProfile,
        errors: state.utilsReducer.errors,
        expandedErrorPanels: state.utilsReducer.panelsToExpand,
        checkErrorPanels: state.utilsReducer.checkErrorPanels,
        snackbar: state.utilsReducer.snackbar,
        numComments: state.commentsReducer.comments.length,
        confirmDialog: state.utilsReducer.confirmDialog,
        inactivateListingDialog: state.listingReducer.inactivateListingDialog,
        usedDocTypes: state.documentsReducer.usedDocTypes,
        usedPropDocTypes: state.documentsReducer.usedPropDocTypes,
        deleteListingDialog: state.listingReducer.deleteListingDialog,
        duplicateListingDialog: state.listingReducer.listingDuplicateDialog,
        duplicateSuccessDialog: state.listingReducer.duplicateSuccessDialog,
        panelsExpanded: get(state.listingReducer, "panelsExpanded", {}),
        requestModal: get(state.listingReducer, "requestModal", {}),
        property: get(state.listingReducer, "listing.property", {}),
        propertyAttributes: get(state.listingReducer, "listing.property.propertyAttributes", {}),
        listing: get(state.listingReducer, "listing", {}),
        listingAttributes: get(state.listingReducer, "listing.attributes", {}),
        listingKey: get(state.listingReducer, "listing.listingKey", {}),
        listingType: get(state.listingReducer, "listing.listingType", {}),
        listingContacts: get(state.listingReducer, "listing.attributes.contacts", []),
        searchResults: state.dealReducer.searchResults ? state.dealReducer.searchResults : {},
        companyInformation: state.dealReducer.companyInformation ? state.dealReducer.companyInformation : {},
        isLoading: state.utilsReducer.isLoading,
        autocompleteResults: state.searchReducer.autocompleteResults,
        listingBrokers: get(state.listingReducer, "listing.listingBrokers", [{}]),
        disableInputs: get(
            constants.disabledStatuses,
            `${state.authReducer.userProfile.role}.${state.listingReducer.listing.status}`,
            get(constants.disabledStatuses.default, state.listingReducer.listing.status)
        ),
        elementsSaving: state.utilsReducer.elementsSaving,
        determineDocumentOptions: determineDocumentOptions(
            state.authReducer.userProfile.user,
            state.authReducer.userProfile.role,
            get(state.listingReducer, "listing", {}),
            state.authReducer.userProfile.personKey
        ),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListing: (listingId) => {
            dispatch(operations.getListing(listingId));
        },
        initListing: (listingId, action) => {
            dispatch(operations.initListing(listingId, action));
        },
        handleInputChange: (field, value) => {
            dispatch(operations.handleListingInputChange(field, value));
        },
        handlePropertyInputChange: (field, value) => {
            dispatch(operations.handleListingInputChange("listing." + field, value));
        },
        toggleSnackBar: (open, variant, message) => {
            dispatch(utils.operations.snackbar(open, variant, message));
        },
        handleSave: () => {
            dispatch(operations.saveListing());
        },
        handleSaveAndExit: () => {
            dispatch(operations.saveAndExit());
        },
        toggleComments: () => {
            dispatch(comments_operations.toggleComments());
        },
        handleSubmit: (save, callback, confirmIsOpen, key) => {
            if (!confirmIsOpen) {
                dispatch(utils.operations.toggleConfirm());
            }
            dispatch(operations.handleSubmit(save, callback, confirmIsOpen, key));
        },
        toggleConfirm: (onSubmit) => {
            dispatch(utils.operations.toggleConfirm(onSubmit));
        },
        handlePropertyAddArrayElement: (arrayPath) => {
            dispatch(operations.addArrayElement("listing." + arrayPath));
        },
        handlePropertyDeleteArrayElement: (arrayPath, index) => {
            dispatch(operations.deleteArrayElement("listing." + arrayPath, index));
        },
        handlePropertyUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.handleListingInputChange("listing." + arrayPath + "[" + index + "]." + field, value));
        },
        handleListingAddArrayElement: (arrayPath, obj) => {
            dispatch(operations.addArrayElementNEW(arrayPath, obj));
        },
        handleListingDeleteArrayElement: (arrayPath, index) => {
            dispatch(operations.deleteArrayElementNEW(arrayPath, index));
        },
        handleListingUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.updateArrayElement(arrayPath, index, field, value));
        },
        handleUpdateContactInfo: (index, field, value) => {
            dispatch(contactOperations.updateContactElement(contactConstants.LISTING_CONTACTS, index, field, value));
        },
        handleAddContactInfo(contactType) {
            dispatch(contactOperations.addContactElement(contactType));
        },
        handleDeleteContactInfo(contactType, index) {
            dispatch(contactOperations.deleteContactElement(contactType, index));
        },
        handleContactInfoCompanyUpdate: (contactType, key, field, value) => {
            dispatch(contactOperations.updateContactElementCompany(contactType, key, field, value));
        },
        brokerSubmitListingRequest: (bypassConfirm) => {
            if (!bypassConfirm) {
                dispatch(utils.operations.toggleConfirm());
            }
            dispatch(operations.brokerSubmitListingRequest(true));
        },
        clearListingState: () => {
            dispatch(operations.clearListingState());
        },
        handleSearchPerson: (inputValue) => {
            dispatch(searchOperations.searchKMPersonAutocomplete(inputValue));
        },
        handleSearchKMBrokers: (inputValue) => {
            dispatch(searchOperations.searchKMBrokers(inputValue));
        },
        handleSearchCompany: (inputValue, companyType) => {
            dispatch(searchOperations.searchCompany(inputValue, companyType));
        },
        handleSearchOutsideBrokers: (inputValue) => {
            dispatch(searchOperations.searchOutsideBroker(inputValue));
        },
        handleDeleteArrayElement: (path, index) => {
            dispatch(operations.deleteArrayElement("listing." + path, index));
        },
        handleAddArrayElement: (path, obj) => {
            dispatch(operations.addArrayElementNEW("listing." + path, obj));
        },
        handleDeleteArrayElementNEW: (path, index) => {
            dispatch(operations.deleteArrayElementNEW("listing." + path, index));
        },
        handleUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.handleListingInputChange("listing." + arrayPath + "[" + index + "]." + field, value));
        },
        clearUtilsErrors: () => {
            dispatch(utils.actions.clearErrors());
        },
        handleSubmitAndUpdateStatus: (newStatus, whatIsSaving, redirectTo) => {
            dispatch(operations.handleSubmitAndUpdateStatus(newStatus, whatIsSaving, redirectTo));
        },
        handleDisplayInactivateDialog: (isOpen) => {
            dispatch(operations.displayInactivateDialog(isOpen));
        },
        handleInactivateListing: (listingKey) => {
            dispatch(operations.handleInactivateListing(listingKey));
        },
        handleDisplayDeleteDialog: (isOpen) => {
            dispatch(operations.displayDeleteDialog(isOpen));
        },
        handleDeleteListing: (listingKey) => {
            dispatch(operations.handleDeleteListing(listingKey));
        },
        handleEditProperty: (propertyKey) => {
            dispatch(operations.handleEditProperty());
        },
        toggleRequestModal: (spaceType, transactionType) => {
            dispatch(operations.toggleRequestModal(spaceType, transactionType));
        },
        togglePropertySearchModal: (isOpen) => {
            dispatch(property_operations.togglePropertySearchModal(isOpen));
        },
        handleDuplicateListing: (listingKey) => {
            dispatch(operations.duplicateListing(listingKey));
        },
        handleListingDuplicateDialog: (isOpen) => {
            dispatch(operations.toggleDuplicateListingDialog(isOpen));
        },
        handleDuplicateSuccessDialog: (isOpen) => {
            dispatch(operations.toggleDuplicateSuccessDialog(isOpen));
        },
        getPropertyDocTypes: (listingId) => {
            dispatch(document_operations.getPropertyDocTypes(listingId));
        },
        copyPropertyPhotoToListing: (listingId) => {
            dispatch(document_operations.copyPropertyPhotoToListing(listingId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingRequestContainer);
