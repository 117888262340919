import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import documentTypes from "../documents/documentTypes";
import DealForm from "../deal/DealForm";
import Property from "../property/Property";
import TotalConsiderationForm from "../deal/TotalConsiderationForm";
import InvoiceDetail from "../deal/InvoiceDetail";
import AutosaveSuccess from "../../../../node_modules/@material-ui/icons/CheckCircle";
import ContactInformation from "../contact/ContactContainer";
import { comp_contact_options } from "../contact/constants";
import LeaseDetailForm from "./LeaseDetailForm";
import DocumentDrop from "../documents/DocumentDropContainer";
import comments_constants from "../comments/constants";
import Comments from "../comments/CommentsContainer";
import IndustrialForm from "../property/IndustrialForm";
import RetailForm from "../property/RetailForm";
import LandForm from "../property/LandForm";
import MultifamilyForm from "../property/MultifamilyForm";
import ListingForm from "../listing/ListingForm";
import FinancialInformation from "../listing/FinancialInformation";
import HospitalityForm from "../property/HospitalityForm";
import ReligiousForm from "../property/ReligiousForm";
import EducationalForm from "../property/EducationalForm";
import MixedUseForm from "../property/MixedUseForm";
import CondoForm from "../property/CondoForm";
import ParkingInfoForm from "../property/ParkingInfoForm";
import PortfolioForm from "../property/PortfolioForm";
import FormTitle from "../../../common/js/components/FormTitle";
import BSInput from "../../../common/js/components/BSInput";
import { Address } from "../../../common/js/icons";
import PropertyUpdateModalContainer from "../property/PropertyUpdateModalContainer";
import toLower from "lodash/toLower";
import get from "lodash/get";
import isNil from "lodash/isNil";
import includes from "lodash/includes";
import { formatDollarPreserveNil } from "../utils/operations";
import numeral from "numeral";

const styles = (theme) => ({
    root: {},
    progress: {
        paddingRight: "100px",
        paddingLeft: "100px",
    },
    readOnly: {
        color: "black",
        background: "#fafafa",
        lineHeight: "20px",
        paddingTop: "5px",
        paddingBottom: "5px",
    },
    paperPadding: {
        padding: "0 20px 20px",
        border: "none",
        marginBottom: "20px",
        boxShadow: "0 1px 2px rgba(34, 54, 70, 0.1)",
    },
    paperHeading: {
        fontSize: "18px",
        margin: "14px 0",
    },
    hr: {
        margin: "0 -20px 20px -20px",
    },
    hrDivider: {
        margin: "0 -20px",
    },
    uploadButton: {
        color: "#757575",
        fill: "#757575",
    },
    attachmentCell: {
        fontSize: 14,
    },
});

class AppraisalCompComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panels: {
                listingFinancial: includes(["Appraisal Draft"], this.props.deal.compStatus)
                    ? true
                    : includes(this.props.expandedErrorPanels, "listingFinancial"),
                listingInformation: includes(["Appraisal Draft"], this.props.deal.compStatus)
                    ? true
                    : includes(this.props.expandedErrorPanels, "listingInformation"),
                property: includes(this.props.expandedErrorPanels, "property"),
            },
            checkErrorPanels: true,
        };
    }

    calculateRatioDeprecated = (dividendVal, divisorVal, quotientProp) => {
        let dividend = dividendVal;
        if (dividend && isNaN(dividend)) {
            dividend = parseFloat(dividend.replace(/,/g, ""));
        }
        let divisor = divisorVal;
        if (divisor && isNaN(divisor)) {
            divisor = parseFloat(divisor.replace(/,/g, ""));
        }
        let quotient = null;
        if (!isNaN(dividend) && !isNaN(divisor) && divisor > 0) {
            quotient = dividend / divisor;
        }
        return quotient;
    };

    calculateRatio = (dividendVal, divisorVal) => {
        let dividend = numeral(dividendVal);
        let divisor = numeral(divisorVal);
        let quotient = null;
        if (
            dividend.value() &&
            divisor.value() &&
            !isNaN(dividend.value()) &&
            !isNaN(divisor.value()) &&
            divisor.value() > 0
        ) {
            quotient = dividend.divide(divisor.value());
        }
        return quotient;
    };

    handleInput = (id, value) => {
        this.props.handleInput(id, value);
        if (id === "listing.attributes.roomCount" || id === "listing.attributes.multifamilyUnitCount") {
            this.calculateSalePricePerUnit(get(this.props.deal, "totalConsiderationAmount", null), value);
        }
    };

    handleDealChange = (e) => {
        this.props.handleDealChange(e);
        switch (e.target.id) {
            case "totalConsiderationAmount":
                this.handleTotalConsiderationChange(e);
                break;
            case "squareFeet":
                this.handleBuildingSfChange(e);
                break;
            case "transactionLandSf":
                this.handleLandSfChange(e);
                break;
            case "saleAdjustment":
                this.calculateAnalysisPrice(get(this.props.deal, "totalConsiderationAmount", null), e.target.value);
                break;
            case "noi":
                this.calculateNoiPerSf(e.target.value, get(this.props.deal, "squareFeet"));
                break;
            default:
                break;
        }
    };

    handleLandSfChange = (e) => {
        this.calculateLandPricePerSf(get(this.props.deal, "totalConsiderationAmount", null), e.target.value);
        this.calculateLandToBldgRatio(e.target.value, get(this.props.deal, "squareFeet", null));
    };

    handleTotalConsiderationChange = (e) => {
        this.calculateBldgPricePerSf(e.target.value, get(this.props.deal, "squareFeet", null));
        this.calculateLandPricePerSf(e.target.value, get(this.props.deal, "transactionLandSf", null));
        this.calculateAnalysisPrice(e.target.value, get(this.props.deal, "saleAdjustment", null));

        if (get(this.props.deal, "propertyType", null) === "Hospitality") {
            this.calculateSalePricePerUnit(e.target.value, get(this.props.deal, "listing.attributes.roomCount", null));
        } else if (get(this.props.deal, "propertyType", null) === "Multifamily") {
            this.calculateSalePricePerUnit(
                e.target.value,
                get(this.props.deal, "listing.attributes.multifamilyUnitCount", null)
            );
        } else {
            this.props.handleInput("salePricePerUnit", "");
        }
    };

    handleBuildingSfChange = (e) => {
        this.handleAutocalculateTiSf(e);
        this.calculateLandToBldgRatio(get(this.props.deal, "transactionLandSf", null), e.target.value);
        this.calculateBldgPricePerSf(get(this.props.deal, "totalConsiderationAmount", null), e.target.value);
        this.calculateNoiPerSf(get(this.props.deal, "noi"), e.target.value);
    };

    calculateAnalysisPrice = (price, adjustmentAmount) => {
        if (toLower(get(this.props.deal, "transactionType", "")) === "sale") {
            let analysisPrice = numeral(price);
            let adjustment = numeral(adjustmentAmount);
            if (!isNaN(analysisPrice.value()) && !isNaN(adjustment.value())) {
                analysisPrice = analysisPrice.value() + adjustment.value();
            } else if (!isNaN(analysisPrice.value())) {
                analysisPrice = analysisPrice.value();
            } else {
                analysisPrice = null;
            }
            this.props.handleInput("analysisPrice", formatDollarPreserveNil(analysisPrice));
        }
    };

    calculateLandToBldgRatio = (landSf, bldgSf) => {
        let landToBldgRatio = this.calculateRatio(landSf, bldgSf);
        this.props.handleInput("landToBldgRatio", numeral(landToBldgRatio).format("0.00"));
    };

    calculateBldgPricePerSf = (price, sf) => {
        if (toLower(get(this.props.deal, "transactionType", "")) === "sale") {
            let bldgSalePricePerSf = this.calculateRatio(price, sf);
            this.props.handleInput("bldgSalePricePerSf", formatDollarPreserveNil(bldgSalePricePerSf));
        }
    };

    calculateLandPricePerSf = (price, sf) => {
        if (toLower(get(this.props.deal, "transactionType", "")) === "sale") {
            let landSalePricePerSf = this.calculateRatio(price, sf);
            this.props.handleInput("landSalePricePerSf", formatDollarPreserveNil(landSalePricePerSf));
        }
    };

    calculateSalePricePerUnit = (price, units) => {
        if (toLower(get(this.props.deal, "transactionType", "")) === "sale") {
            let salePricePerUnit = this.calculateRatio(price, units);
            this.props.handleInput(
                "salePricePerUnit",
                salePricePerUnit ? formatDollarPreserveNil(salePricePerUnit) : ""
            );
        }
    };

    calculateNoiPerSf = (noi, sf) => {
        let noiPerSf = this.calculateRatio(noi, sf);
        this.props.handleInput("noiPerSf", noiPerSf ? formatDollarPreserveNil(noiPerSf) : "");
    };

    handleAutocalculateTiSf = (e) => {
        if (toLower(get(this.props.deal, "transactionType", "")) === "lease") {
            let tiTotal;
            let sf;
            switch (e.target.id) {
                case "tiRate":
                    sf = get(this.props, "totalConsideration.squareFeet", 0);
                    if (isNaN(sf)) {
                        sf = parseInt(sf.replace(/[^0-9]/g, ""));
                    }
                    tiTotal = sf * e.target.value;
                    this.props.handleInput("tiRate", e.target.value);
                    if (!isNaN(e.target.value)) {
                        this.props.handleInput("tiTotal", tiTotal);
                    } else {
                        this.props.handleInput("tiTotal", "");
                    }
                    break;
                case "squareFeet":
                    sf = e.target.value;
                    if (isNaN(sf)) {
                        sf = parseInt(sf.replace(/[^0-9]/g, ""));
                    }
                    tiTotal = this.props.deal.tiRate * sf;
                    this.props.handleTotalConsiderationChange("squareFeet", e.target.value);
                    if (!isNaN(sf)) {
                        this.props.handleInput("tiTotal", tiTotal);
                    } else {
                        this.props.handleInput("tiTotal", "");
                    }
                    break;
                default:
                    break;
            }
        }
    };

    togglePanel = (panel, open, checkErrorPanels) => {
        let panels = { ...this.state.panels };
        panels[panel] = open;
        this.setState({ panels: panels });
        if (!isNil(checkErrorPanels)) {
            this.props.toggleCheckErrorPanels(checkErrorPanels);
        }
    };

    openGroupOfPanels = (panels) => {
        this.setState({
            panels: panels,
        });
    };

    render() {
        const {
            classes,
            deal,
            disableInputs,
            documentWarning,
            errors,
            handleAddArrayElement, // (path)
            handleDeleteArrayElement,
            handleUpdateArrayElement,
            handleConfirmWarning,
            handleDocumentWarning,
            handleInput,
            handleObjectPropertyChange,
            id,
            listingAttributes,
            property,
            expandedErrorPanels,
            checkErrorPanels,
            restrictViewDocuments,
            toggleTerminationDoc,
        } = this.props;

        const fromListing = get(deal, "fromListing", false);

        if (checkErrorPanels === true) {
            let panels = {};
            if (includes(expandedErrorPanels, "property") && this.state.panels.property === false) {
                panels = { ...panels, property: true };
            }
            if (fromListing || includes(expandedErrorPanels, "listingInformation")) {
                panels = { ...panels, listingInformation: true };
            }
            if (fromListing || includes(expandedErrorPanels, "listingFinancial")) {
                panels = { ...panels, listingFinancial: true };
            }

            this.openGroupOfPanels(panels);
            this.props.toggleCheckErrorPanels(false);
        }

        return (
            <div>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <div>
                            <ExpansionPanel
                                expanded={this.state.panels.property}
                                onChange={() => this.togglePanel("property", !this.state.panels.property, false)}
                                style={{ marginBottom: "35px" }}
                            >
                                {(get(this.props.userProfile, "role") === "dataAdmin" &&
                                    get(this.props.listing, "status") === "Active") ||
                                disableInputs === false ? (
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <AutosaveSuccess className="waterloo" style={{ paddingTop: "4px" }} />
                                        <Grid item xs={5} style={{ maxWidth: "225px" }}>
                                            <div style={{ paddingTop: "4px", paddingLeft: "30px" }}>
                                                Property Information
                                            </div>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <div
                                                style={{ paddingTop: "6px", paddingLeft: "0px" }}
                                                onClick={() => this.props.togglePropertySearchModal(true)}
                                            >
                                                <Address />
                                            </div>
                                        </Grid>
                                    </ExpansionPanelSummary>
                                ) : (
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <AutosaveSuccess className="waterloo" /> Property Information
                                    </ExpansionPanelSummary>
                                )}
                                <Divider />
                                <ExpansionPanelDetails>
                                    <Property
                                        attributes={get(deal, "listing.property.propertyAttributes", {})}
                                        property={get(deal, "listing.property", {})}
                                        handleInputChange={(f, v) => handleInput("listing." + f, v)}
                                        errors={errors}
                                        handleAddArrayElement={(path) =>
                                            this.props.handleAddArrayElement("listing." + path)
                                        }
                                        handleDeleteArrayElement={(path, index) =>
                                            this.props.handleDeleteArrayElement("listing." + path, index)
                                        }
                                        handleUpdateArrayElement={(arrayPath, index, field, value) =>
                                            this.props.handleUpdateArrayElement(
                                                "listing." + arrayPath,
                                                index,
                                                field,
                                                value
                                            )
                                        }
                                        deal={deal}
                                        prefix="listing."
                                        disableInputs={get(deal, "listing.property.propertyKey", null) !== null}
                                        propertyType={
                                            get(deal, "listing.property.propertyType", null) !== null
                                                ? get(deal, "listing.property.propertyType", null)
                                                : get(deal, "propertyType", null)
                                        }
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <div>
                                <PropertyUpdateModalContainer {...this.props} formType="comp" />
                            </div>
                        </div>

                        {/*
                        Listing Information 
                        */}

                        {get(deal, "listing.listingName") !== undefined && (
                            <React.Fragment>
                                <ExpansionPanel
                                    expanded={this.state.panels.listingInformation}
                                    onChange={() =>
                                        this.togglePanel(
                                            "listingInformation",
                                            !this.state.panels.listingInformation,
                                            false
                                        )
                                    }
                                >
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <AutosaveSuccess className="waterloo" /> Listing Information
                                    </ExpansionPanelSummary>
                                    <Divider />
                                    <ExpansionPanelDetails>
                                        <ListingForm
                                            {...this.props}
                                            isOutside={includes(
                                                ["Outside Draft", "Outside"],
                                                get(deal, "compStatus", "")
                                            )}
                                            disabled={disableInputs}
                                            listing={get(deal, "listing", {})}
                                            listingBrokers={get(deal, "listing.listingBrokers", [{}])}
                                            propertyType={get(deal, "listing.attributes.useType", null)}
                                            errors={get(errors, "listing", {})}
                                            showListingExpiration={false}
                                            handleInputChange={(field, value) => handleInput(field, value)}
                                            handleAddArrayElement={(path, obj) =>
                                                handleAddArrayElement("listing." + path, obj)
                                            }
                                            handleDeleteArrayElement={(path, index) =>
                                                handleDeleteArrayElement(path, index)
                                            }
                                            handleUpdateArrayElement={(path, index, field, value) =>
                                                handleUpdateArrayElement(path, index, field, value)
                                            }
                                            compStatus={get(deal, "compStatus", null)}
                                        />
                                        <Divider />
                                        <MixedUseForm
                                            {...this.props}
                                            onChange={(field, value) =>
                                                handleInput("listing.attributes." + field, value)
                                            }
                                            data={listingAttributes}
                                            dataPathPrefix="listing.attributes"
                                            errors={get(errors, "attributes", {})}
                                        />
                                        <Divider />
                                        <PortfolioForm
                                            {...this.props}
                                            onChange={(field, value) =>
                                                handleInput("listing.attributes." + field, value)
                                            }
                                            data={listingAttributes}
                                            dataPathPrefix="listing.attributes"
                                            errors={get(errors, "attributes", {})}
                                        />
                                        <Divider />
                                        <CondoForm
                                            {...this.props}
                                            onChange={(field, value) =>
                                                handleInput("listing.attributes." + field, value)
                                            }
                                            data={listingAttributes}
                                            dataPathPrefix="listing.attributes"
                                            errors={get(errors, "attributes", {})}
                                        />
                                        <Divider />
                                        <FormTitle title="Parking Information" />
                                        <ParkingInfoForm
                                            {...this.props}
                                            context="listing"
                                            data={listingAttributes}
                                            onChange={(field, value) =>
                                                handleInput("listing.attributes." + field, value)
                                            }
                                            errors={get(errors, "listing.attributes", {})}
                                        />
                                        {toLower(deal.propertyType) === "industrial" && (
                                            <div>
                                                <Divider />
                                                <FormTitle title="Industrial Information" />
                                                <IndustrialForm
                                                    {...this.props}
                                                    context="listing"
                                                    onChange={(field, value) =>
                                                        handleInput("listing.attributes." + field, value)
                                                    }
                                                    data={listingAttributes}
                                                    errors={get(errors, "listing.attributes", {})}
                                                    listingStatus={get(deal, "listing.status", null)}
                                                    transactionType={deal.transactionType}
                                                />
                                            </div>
                                        )}
                                        {toLower(deal.propertyType) === "land" && (
                                            <div>
                                                <Divider />
                                                <FormTitle title="Land Information" />
                                                <LandForm
                                                    {...this.props}
                                                    context="listing"
                                                    onChange={(field, value) =>
                                                        handleInput("listing.attributes." + field, value)
                                                    }
                                                    data={listingAttributes}
                                                    errors={get(errors, "listing.attributes", {})}
                                                    listingStatus={get(deal, "listing.status", null)}
                                                    transactionType={deal.transactionType}
                                                />
                                            </div>
                                        )}
                                        {toLower(deal.propertyType) === "multifamily" && (
                                            <div>
                                                <Divider />
                                                <FormTitle title="Multifamily Information" />
                                                <MultifamilyForm
                                                    {...this.props}
                                                    context="listing"
                                                    onChange={(field, value) =>
                                                        this.handleInput("listing.attributes." + field, value)
                                                    }
                                                    data={listingAttributes}
                                                    errors={get(errors, "listing.attributes", {})}
                                                    listingStatus={get(deal, "listing.status", null)}
                                                    transactionType={deal.transactionType}
                                                />
                                            </div>
                                        )}
                                        {toLower(deal.propertyType) === "retail" && (
                                            <div>
                                                <Divider />
                                                <FormTitle title="Retail Information" />
                                                <RetailForm
                                                    {...this.props}
                                                    context="listing"
                                                    onChange={(field, value) =>
                                                        handleInput("listing.attributes." + field, value)
                                                    }
                                                    data={listingAttributes}
                                                    errors={get(errors, "listing.attributes", {})}
                                                    dataPathPrefix="listing.attributes"
                                                    listingStatus={get(deal, "listing.status", null)}
                                                    transactionType={deal.transactionType}
                                                />
                                            </div>
                                        )}
                                        {toLower(deal.propertyType) === "hospitality" && (
                                            <div>
                                                <Divider />
                                                <FormTitle title="Hospitality Information" />
                                                <HospitalityForm
                                                    {...this.props}
                                                    context="listing"
                                                    onChange={(field, value) =>
                                                        this.handleInput("listing.attributes." + field, value)
                                                    }
                                                    data={listingAttributes}
                                                    errors={get(errors, "listing.attributes", {})}
                                                    listingStatus={get(deal, "listing.status", null)}
                                                    transactionType={deal.transactionType}
                                                />
                                            </div>
                                        )}
                                        {toLower(deal.propertyType) === "religious" && (
                                            <div>
                                                <Divider />
                                                <FormTitle title="Religious Information" />
                                                <ReligiousForm
                                                    {...this.props}
                                                    context="listing"
                                                    onChange={(field, value) =>
                                                        this.handleInput("listing.attributes." + field, value)
                                                    }
                                                    data={listingAttributes}
                                                    errors={get(errors, "listing.attributes", {})}
                                                    listingStatus={get(deal, "listing.status", null)}
                                                    transactionType={deal.transactionType}
                                                />
                                            </div>
                                        )}
                                        {toLower(deal.propertyType) === "educational" && (
                                            <div>
                                                <Divider />
                                                <FormTitle title="Educational Information" />
                                                <EducationalForm
                                                    {...this.props}
                                                    context="listing"
                                                    onChange={(field, value) =>
                                                        this.handleInput("listing.attributes." + field, value)
                                                    }
                                                    data={listingAttributes}
                                                    errors={get(errors, "listing.attributes", {})}
                                                    listingStatus={get(deal, "listing.status", null)}
                                                    transactionType={deal.transactionType}
                                                />
                                            </div>
                                        )}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>

                                {/*
                        Listing Contact Information
                        */}

                                {/*
                        Financial Information 
                        */}

                                <ExpansionPanel
                                    expanded={this.state.panels.listingFinancial}
                                    onChange={() =>
                                        this.togglePanel("listingFinancial", !this.state.panels.listingFinancial, false)
                                    }
                                >
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <AutosaveSuccess className="waterloo" /> Listing Financial Information
                                    </ExpansionPanelSummary>
                                    <Divider />
                                    <ExpansionPanelDetails>
                                        <FinancialInformation
                                            {...this.props}
                                            disableInputs={disableInputs}
                                            listing={deal.listing}
                                            errors={get(errors, "listing", {})}
                                            handleInputChange={(field, value) => handleInput(field, value)}
                                            compStatus={get(deal, "compStatus", null)}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </React.Fragment>
                        )}

                        {/*
                        General Information 
                        */}

                        <ExpansionPanel defaultExpanded>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                General Information
                            </ExpansionPanelSummary>
                            <Divider />
                            <ExpansionPanelDetails>
                                <DealForm
                                    {...this.props}
                                    disableInputs={disableInputs}
                                    transactionType={deal.transactionType}
                                    handleInput={(field, value) => handleInput(field, value)}
                                    errors={errors}
                                    handleDealChange={this.handleDealChange}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                        {/*
                        Total Consideration 
                        */}

                        {toLower(deal.transactionType) !== "sale" && (
                            <ExpansionPanel defaultExpanded>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    {/* <AutosaveSuccess className="waterloo" />  */}
                                    Total Consideration
                                </ExpansionPanelSummary>
                                <Divider />
                                <ExpansionPanelDetails>
                                    <TotalConsiderationForm
                                        {...this.props}
                                        disableInputs={disableInputs}
                                        transactionType={deal.transactionType}
                                        handleAutocalculateTiSf={this.handleDealChange}
                                        handleTotalConsiderationAmountChange={(value) =>
                                            handleInput("totalConsiderationAmount", value)
                                        }
                                    />
                                    <InvoiceDetail {...this.props} disableInputs={disableInputs} />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )}
                        {toLower(deal.transactionType) === "lease" && (
                            <ExpansionPanel defaultExpanded>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    Lease Details
                                </ExpansionPanelSummary>
                                <Divider />
                                {deal.transactionType && (
                                    <ExpansionPanelDetails>
                                        <LeaseDetailForm
                                            {...this.props}
                                            onChange={(event) => handleObjectPropertyChange("deal", event)}
                                            leaseDetail={deal}
                                            disableInputs={disableInputs}
                                            transactionType={deal.transactionType}
                                            useType={deal.propertyType}
                                            errors={errors}
                                            handleAutocalculateTiSf={this.handleAutocalculateTiSf}
                                        />
                                    </ExpansionPanelDetails>
                                )}
                            </ExpansionPanel>
                        )}

                        {/*
                        Comp Contact Information
                        */}

                        <ExpansionPanel defaultExpanded>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                {/* <AutosaveSuccess className="waterloo" />  */}
                                Comp Contact Information
                            </ExpansionPanelSummary>
                            <Divider />
                            <ExpansionPanelDetails>
                                <ContactInformation
                                    {...this.props}
                                    handleChange={(field, value, index) =>
                                        handleInput(`propertyAttributes.contacts[${index}].${field}`, value)
                                    } //todo
                                    handleAdd={() => handleAddArrayElement("propertyAttributes.contacts")}
                                    handleDelete={(index) =>
                                        handleDeleteArrayElement("propertyAttributes.contacts", index)
                                    }
                                    contacts={get(deal, "propertyAttributes.contacts", [])}
                                    contactTypeOptions={comp_contact_options}
                                    addButtonText="Add Contact Information"
                                    includeInvoiceRecipientCheckbox={false}
                                    maxContacts={5}
                                    displayWhenEmpty={true}
                                    companyFieldAvailable={true}
                                    errors={errors}
                                    hideTitle={true}
                                    freeFormCompany={true}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel defaultExpanded expanded>
                            <ExpansionPanelSummary>Comp Comments</ExpansionPanelSummary>
                            <Divider />
                            <ExpansionPanelDetails>
                                <Grid container alignItems="flex-start" spacing={8}>
                                    <Grid item xs={12}>
                                        <BSInput
                                            id="compNotes"
                                            label="Public Comments"
                                            placeholder="Comments entered here are PUBLIC and will be VISIBLE on reports and excel exports."
                                            value={get(deal, "compNotes", null)}
                                            multiline={true}
                                            rows={5}
                                            onChange={(e) => handleInput("compNotes", e.target.value)}
                                            disabled={disableInputs}
                                            className={classes.readOnly}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel defaultExpanded>
                            <ExpansionPanelSummary>Listing Comments</ExpansionPanelSummary>
                            <Divider />
                            <ExpansionPanelDetails>
                                <Grid container alignItems="flex-start" spacing={8}>
                                    <Grid item xs={12}>
                                        <BSInput
                                            id="listingCommentsMember"
                                            label="Internal Comments"
                                            placeholder="Comments entered here are only visible inside KMConnect."
                                            error={errors.listingCommentsMember}
                                            multiline={true}
                                            rows={5}
                                            onChange={(e) => handleInput("listing." + e.target.id, e.target.value)}
                                            className={classes.readOnly}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel defaultExpanded>
                            <ExpansionPanelSummary>Property Comments</ExpansionPanelSummary>
                            <Divider />
                            <ExpansionPanelDetails>
                                <Grid container alignItems="flex-start" spacing={8}>
                                    <Grid item xs={12}>
                                        <BSInput
                                            id="memberComments"
                                            label="Internal Comments"
                                            placeholder="Comments entered here are only visible inside KMConnect."
                                            value={get(property, "memberComments", null)}
                                            multiline={true}
                                            rows={5}
                                            onChange={(e) =>
                                                handleInput("listing.property." + e.target.id, e.target.value)
                                            }
                                            disabled={get(property, "propertyKey", null) !== null}
                                            className={classes.readOnly}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>

                    <Comments parentRecordType={comments_constants.COMP_PARENT_RECORD_TYPE} parentRecordId={id} />

                    <Grid item xs={6}>
                        <DocumentDrop
                            entityKey={id}
                            type="comp"
                            documentTypes="comp"
                            formDisabled={disableInputs}
                            errors={errors.documents}
                            restrictPreview={restrictViewDocuments}
                            toggleTerminationDoc={toggleTerminationDoc}
                            parentType="COMP"
                            stateCode={get(deal, "listing.property.primaryAddress.city.state.stateCode", null)}
                        />
                    </Grid>
                </Grid>

                <Dialog
                    open={documentWarning.open}
                    onClose={() => handleDocumentWarning(false, documentWarning.proceed, [])}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to submit?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are responsible for ensuring this deal has the necessary legal documents. The following
                            documents are still required for this deal to be compliant:
                            <ul>
                                {documentWarning.docs.map((x, i) => (
                                    <li>{documentTypes["comp"][x]["display"]}</li>
                                ))}
                            </ul>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleDocumentWarning(false, false, [])} color="primary">
                            Add more docs
                        </Button>
                        <Button onClick={() => handleConfirmWarning()} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AppraisalCompComponent);
