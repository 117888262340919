import React from "react";
import get from "lodash/get";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import BSInput from "../../../../common/js/components/BSInput";
import "./Portfolio.css";

const styles = () => ({
    iconOnly: {
        marginLeft: 0,
        marginBottom: "10px",
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
    centerCell: {
        textAlign: "center",
        fill: "#9e9e9e",
    },
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
});
const PortfolioPropertyDetail = (props) => {
    const {
        classes,
        dataPathPrefix,
        disableInputs,
        element,
        elementIndex,
        errors,
        handleUpdateArrayElement,
        onDelete,
    } = props;

    return (
        <div>
            <div className={elementIndex === 0 ? "portfolioFlexContainer" : "portfolioFlexContainerWElementIndex"}>
                <BSInput
                    id="propertyId"
                    label={elementIndex === 0 ? "Property ID" : null}
                    errorText="(ex. 98101)"
                    placeholder="Enter property ID"
                    value={element ? element.propertyId : null}
                    error={get(errors, `portfolioProperties.${elementIndex}.propertyId`, null)}
                    onChange={(e) =>
                        handleUpdateArrayElement(
                            dataPathPrefix + ".portfolioProperties",
                            elementIndex,
                            e.target.id,
                            e.target.value
                        )
                    }
                    disabled={disableInputs}
                />
                <div className={classes.centerCell}>
                    {elementIndex > 0 && disableInputs !== true && (
                        <Button onClick={() => onDelete(elementIndex)} className={classes.shrinkButton}>
                            <Delete />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(PortfolioPropertyDetail);
