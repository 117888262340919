import React from "react";
import BSInput from "../../../common/js/components/BSInput";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Delete from "../../../../node_modules/@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
    iconOnly: {
        marginLeft: 0,
        marginBottom: "10px",
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
    defTerm: {
        fontSize: "13px",
        textTransform: "uppercase",
        fontWeight: 600,
        display: "inline-block",
        padding: "0 0 8px",
        color: "rgba(0, 0, 0, 0.54)",
        fontFamily: 'IBM Plex Sans", sans-serif',
    },
});
class ExhibitADetail extends React.Component {
    render() {
        const { classes, element, elementIndex, errors, handleUpdateArrayElement, disableInputs, onDelete } =
            this.props;

        if (get(element, "exhibitAType", null) == null) {
            handleUpdateArrayElement("exhibitAs", elementIndex, "exhibitAType", "STANDARD");
        }

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={11}>
                        <FormControl rowcomponent="fieldset">
                            <RadioGroup
                                row
                                aria-label="exhibitAType"
                                name="exhibitAType"
                                value={element ? element.exhibitAType : null}
                                onChange={(e) =>
                                    handleUpdateArrayElement("exhibitAs", elementIndex, e.target.id, e.target.value)
                                }
                            >
                                <FormControlLabel
                                    value="STANDARD"
                                    control={
                                        <Radio
                                            id="exhibitAType"
                                            color="primary"
                                            checked={element.exhibitAType === "STANDARD"}
                                        />
                                    }
                                    style={{ marginRight: "83px" }}
                                    label="Standard"
                                />
                                <FormControlLabel
                                    value="PREFERENTIAL"
                                    control={
                                        <Radio
                                            id="exhibitAType"
                                            color="primary"
                                            checked={element.exhibitAType === "PREFERENTIAL"}
                                        />
                                    }
                                    label="Preferential"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        {disableInputs !== true && (
                            <Button
                                style={{ marginTop: "10px", color: "#9e9e9e" }}
                                onClick={() => onDelete(elementIndex)}
                                className={classes.iconOnly}
                            >
                                <Delete />
                            </Button>
                        )}
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8} style={{ margin: "10px 0px", width: "100%" }}>
                    <Grid item xs={2}>
                        <BSInput
                            id="baseSplitPercentage"
                            label="BASE SPLIT %"
                            value={element ? element.baseSplitPercentage : null}
                            error={get(errors, `exhibitAs.${elementIndex}.baseSplitPercentage`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("exhibitAs", elementIndex, e.target.id, e.target.value)
                            }
                            errorText="Required"
                            endAdornment="%"
                            numeric
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <BSInput
                            id="annualGrossMinimum"
                            label="ANNUAL GROSS MINIMUM $"
                            value={element.annualGrossMinimum !== 0 ? element.annualGrossMinimum : 0}
                            error={get(errors, `exhibitAs.${elementIndex}.annualGrossMinimum`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("exhibitAs", elementIndex, e.target.id, e.target.value)
                            }
                            errorText="Required"
                            startAdornment="$"
                            numeric
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <BSInput
                            id="adjustedPercentage"
                            label="ADJUSTED %"
                            value={element ? element.adjustedPercentage : null}
                            error={get(errors, `exhibitAs.${elementIndex}.adjustedPercentage`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("exhibitAs", elementIndex, e.target.id, e.target.value)
                            }
                            errorText="Required"
                            endAdornment="%"
                            numeric
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8} style={{ marginTop: "-29px", width: "100%" }}>
                    <Grid item xs={6}>
                        <BSInput
                            id="exhibitAStartDate"
                            label="START DATE"
                            placeholder="Enter start date"
                            value={element ? element.exhibitAStartDate : null}
                            error={get(errors, `exhibitAs.${elementIndex}.exhibitAStartDate`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("exhibitAs", elementIndex, e.target.id, e.target.value)
                            }
                            disabled={disableInputs === true}
                            type="date"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <BSInput
                            id="exhibitAExpirationDate"
                            label="EXPIRATION DATE"
                            placeholder="Enter expiration date"
                            value={element ? element.exhibitAExpirationDate : null}
                            error={get(errors, `exhibitAs.${elementIndex}.exhibitAExpirationDate`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement("exhibitAs", elementIndex, e.target.id, e.target.value)
                            }
                            disabled={disableInputs === true}
                            type="date"
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="center" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="comments"
                            label="COMMENTS"
                            placeholder="Enter comments"
                            value={element ? element.comments : null}
                            multiline={true}
                            rows={8}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            onChange={(e) =>
                                handleUpdateArrayElement("exhibitAs", elementIndex, e.target.id, e.target.value)
                            }
                        />
                    </Grid>
                </Grid>

                <Divider />
                <br />
            </div>
        );
    }
}

export default withStyles(styles)(ExhibitADetail);
