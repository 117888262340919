import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, InputLabel, Typography } from "@material-ui/core";
import ActionChips from "../compliance/ActionChips";
import ConditionChips from "../compliance/ConditionChips";

const ComplianceRulesByDocumentType = (props) => {
    const { classes, rule } = props;
    const { ruleName } = rule;

    return (
        <React.Fragment>
            <Grid container direction="column" spacing={24} style={{ margin: "10px 0px" }}>
                <Grid item xs={2} style={{ padding: "0px" }}>
                    <InputLabel shrink className={classes.bootstrapFormLabel}>
                        {ruleName}
                    </InputLabel>
                </Grid>
                <Grid item>
                    <Grid container direction="column" spacing={8} style={{ marginLeft: "10px" }}>
                        <Grid item>
                            <Typography>Action</Typography>
                        </Grid>
                        <Grid item>
                            <ActionChips rule={rule} />
                        </Grid>
                        <Grid item>
                            <Typography>Conditions</Typography>
                        </Grid>
                        <Grid item>
                            <ConditionChips rule={rule} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const styles = () => ({});

export default withStyles(styles)(ComplianceRulesByDocumentType);
