import validate from "validate.js";
import { constraints } from "../../utils/validation";

const requestNewDocumentType = {
    name: {
        ...constraints.required,
    },
    parentEntityTypeKey: {
        ...constraints.required,
    },
};

const validateRequestNewDocumentType = (values) => {
    return validate(values, requestNewDocumentType, { fullMessages: false });
};

export default {
    validateRequestNewDocumentType,
};
