import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { red, amber } from "@material-ui/core/colors";
import get from "lodash/get";
import includes from "lodash/includes";
import { SplitButton } from "react-bootstrap";
import { SaveAndExit } from "../../../common/js/icons";

const styles = (theme) => ({
    root: {},
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    leftIcon: {},
    progress: {
        paddingRight: "100px",
        paddingLeft: "100px",
    },
    progressDiv: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
    },
    warning: {
        backgroundColor: amber[700],
    },
    deleteButton: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800],
        },
    },
});

const CompanyToolbar = (props) => {
    const {
        userProfile,
        classes,
        disableInputs,
        company,
        handleDeleteCompany,
        handleSaveCompany,
        handleSaveCompanyAndExit,
        id,
    } = props;

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
            <Grid item>
                <h2 className="page-title">{get(company, "companyKey", null) == null ? "Create" : "Edit"} Company</h2>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" spacing={0}>
                    <Grid item>
                        <SplitButton
                            title="Save"
                            bsStyle="default"
                            disabled={disableInputs}
                            onClick={handleSaveCompany}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSaveCompanyAndExit}
                                disabled={disableInputs}
                            >
                                <SaveAndExit />
                                Save & Exit
                            </Button>
                        </SplitButton>
                    </Grid>
                    {includes(["dataAdmin", "dataEntry"], userProfile.role) && id ? (
                        <Grid item>
                            <Button
                                variant="outlined"
                                className={classes.deleteButton}
                                onClick={() => handleDeleteCompany(company.companyKey)}
                            >
                                Delete
                            </Button>
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(CompanyToolbar);
