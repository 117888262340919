import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Comments from "@material-ui/icons/Comment";
import KMSwitch from "../../../common/js/components/KMSwitch";
import get from "lodash/get";
import includes from "lodash/includes";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { red } from "@material-ui/core/colors";
import { SplitButton } from "react-bootstrap";
import { SaveAndExit } from "../../../common/js/icons";

const styles = (theme) => ({
    progressDiv: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
    },
    pageToolbar: {
        padding: "10px 0px",
        backgroundColor: "#fafafa",
        position: "sticky",
        top: "62px",
        zIndex: 1,
    },
    leftIcon: {
        marginLeft: "25px",
    },
    progress: {
        paddingRight: "100px",
        paddingLeft: "100px",
    },
    menuCategory: {
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    deleteButton: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800],
        },
    },
});

const AppraisalCompToolbar = (props) => {
    const {
        userProfile,
        classes,
        deal,
        disableInputs,
        elementsSaving,
        numComments,
        submitOutsideOrAppraisalComp,
        saveOutsideOrAppraisalComp,
        saveAndExitOutsideOrAppraisalComp,
        toggleComments,
        handleDisplayDeleteDialog,
        handleDeleteOutsideComp,
        deleteOutsideCompDialog,
    } = props;

    const handleConfidential = (e) => {
        props.handleConfidential("confidentialFlg", e.target.checked);
    };

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
            <Grid item>
                <h2 className="page-title">
                    {deal.compStatus === "Appraisal Draft" ? "Create Appraisal Comp" : "Appraisal Comp"}
                </h2>
                <p className="page-subtitle">
                    {deal.propertyType} {deal.transactionType}
                </p>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" spacing={0}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <KMSwitch
                                    checked={deal.confidentialFlg ? true : false}
                                    onChange={(e) => handleConfidential(e)}
                                    value={deal.confidentialFlg}
                                    disabled={disableInputs}
                                />
                            }
                            label="Confidential"
                        />
                    </Grid>
                    {(includes(
                        ["dataAdmin", "dataEntry", "researchAdmin", "research", "appraiser"],
                        userProfile.role
                    ) ||
                        userProfile.user === get(deal, "auditCreatedBy", null)) && (
                        <Grid item>
                            {elementsSaving.outsideCompDelete ? (
                                <div className={classes.progressDiv}>
                                    <CircularProgress size={20} />
                                </div>
                            ) : (
                                <Button
                                    variant="contained"
                                    className={classes.deleteButton}
                                    onClick={() => handleDisplayDeleteDialog(true)}
                                >
                                    Delete Comp
                                </Button>
                            )}
                        </Grid>
                    )}
                    {deal.compStatus === "Appraisal Draft" ? null : (
                        <Grid item>
                            <Button disabled={Boolean(!deal.dealKey)} onClick={toggleComments}>
                                <Comments /> Comments ({numComments})
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        {elementsSaving.saveAndExit ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : (
                            <SplitButton
                                title="Save"
                                bsStyle="default"
                                disabled={disableInputs}
                                onClick={() => saveOutsideOrAppraisalComp("appraisal")}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => saveAndExitOutsideOrAppraisalComp("appraisal")}
                                    disabled={disableInputs}
                                >
                                    <SaveAndExit />
                                    Save & Exit
                                </Button>
                            </SplitButton>
                        )}
                    </Grid>
                    <Grid item>
                        {deal.compStatus === "Appraisal Draft" &&
                            (elementsSaving.submit ? (
                                <div className={classes.progressDiv}>
                                    <CircularProgress size={20} color="primary" />
                                </div>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => submitOutsideOrAppraisalComp(null, false, "Appraisal")}
                                    disabled={disableInputs}
                                >
                                    Submit
                                </Button>
                            ))}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={deleteOutsideCompDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Delete Appraisal Comp?</DialogTitle>
                <DialogContent>
                    Are you sure you want to permanently delete this appraisal comp? Appraisal comps cannot be recovered
                    once they have been deleted.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDisplayDeleteDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteOutsideComp(deal.dealKey)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default withStyles(styles)(AppraisalCompToolbar);
