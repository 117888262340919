import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Delete from "@material-ui/icons/Delete";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";

import { Sorty, Search } from "../../../common/js/icons";
import BSInput from "../../../common/js/components/BSInput";
import { formatPhoneNumber } from "../utils/operations";
import constants from "./constants";

import get from "lodash/get";
import orderBy from "lodash/orderBy";

class CrmContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDelete: {
                open: false,
                contactId: null,
                contactIndex: null,
            },
        };
    }

    handleDeleteContact = () => {
        this.props.deleteContact(this.state.confirmDelete.contactId, this.state.confirmDelete.contactIndex);
        this.handleClose();
    };

    handleOpen = (contactId, contactIndex) => {
        let confirmDelete = this.state.confirmDelete;
        confirmDelete.open = true;
        confirmDelete.contactId = contactId;
        confirmDelete.contactIndex = contactIndex;
        this.setState({ confirmDelete: confirmDelete });
    };

    handleClose = () => {
        let confirmDelete = this.state.confirmDelete;
        confirmDelete.open = false;
        confirmDelete.contactId = null;
        confirmDelete.contactIndex = null;
        this.setState({ confirmDelete: confirmDelete });
    };

    render() {
        const {
            classes,
            contacts,
            columnToSort,
            sortDirection,
            handleSort,
            handleFilter,
            filterQuery,
            elementsSaving,
        } = this.props;

        const { confirmDelete } = this.state;

        const data = orderBy(
            filterQuery && contacts
                ? contacts.filter((x) => constants.filterByQuery(x, "contacts", filterQuery))
                : contacts,
            columnToSort,
            sortDirection
        );

        return (
            <Paper className={classes.paperSpacing}>
                <Grid container justify="flex-end">
                    <Grid item className={classes.filterInput}>
                        <BSInput
                            extraInputStyles={{ backgroundColor: "#fafafa", border: "none", marginTop: "10px" }}
                            value={filterQuery}
                            placeholder="Search contacts..."
                            startAdornment={<Search />}
                            startAdornmentStyles={{ color: "#777777" }}
                            onChange={(event) => {
                                handleFilter(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                {elementsSaving.crmContacts ? (
                    <div className={classes.loadingPlaceholder}>
                        <CircularProgress color="primary" />
                    </div>
                ) : (
                    <React.Fragment>
                        <Divider classes={{ root: classes.divider }} />
                        <Fade in={true}>
                            <Table classes={{ root: classes.table }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell classes={classes} style={{ width: "20%", padding: 0 }}>
                                            <div
                                                style={{ display: "flex", alignItems: "center" }}
                                                onClick={() => handleSort("contactFirstName")}
                                            >
                                                <span className={classes.sortable}>Name</span>
                                                <div>
                                                    <Sorty />
                                                </div>
                                            </div>
                                        </TableCell>

                                        <TableCell classes={classes} style={{ width: "35%", padding: 0 }}>
                                            <div
                                                style={{ display: "flex", alignItems: "center" }}
                                                onClick={() => handleSort("contactCompanyDisplayName")}
                                            >
                                                <span className={classes.sortable}>Company</span>
                                                <div>
                                                    <Sorty />
                                                </div>
                                            </div>
                                        </TableCell>

                                        <TableCell numeric classes={classes} style={{ width: "20%", padding: 0 }}>
                                            <div
                                                style={{ display: "flex", alignItems: "center", position: "relative" }}
                                                onClick={() => handleSort("contactPhoneNumber")}
                                            >
                                                <span className={classes.sortable}>Phone Number</span>
                                            </div>
                                        </TableCell>

                                        <TableCell numeric classes={classes} style={{ width: "15%", padding: 0 }}>
                                            <div
                                                style={{ display: "flex", alignItems: "center", position: "relative" }}
                                                onClick={() => handleSort("contactEmailAddress")}
                                            >
                                                <span className={classes.sortable}>Email</span>
                                            </div>
                                        </TableCell>

                                        <TableCell classes={classes} style={{ width: "10%", padding: 0 }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                {contacts && contacts.length > 0 ? (
                                    <TableBody>
                                        {data.map((contact, index) => (
                                            <TableRow selectable="false" className="tableRow">
                                                <TableCell
                                                    className={classes.leftColumn}
                                                    classes={classes}
                                                    style={{ padding: 0 }}
                                                >
                                                    <Link
                                                        className={classes.idLink}
                                                        to={`/contacts/${get(contact, "contactKey", "")}`}
                                                    >
                                                        <div>{`${get(contact, "contactFirstName", "")} ${get(
                                                            contact,
                                                            "contactLastName",
                                                            ""
                                                        )}`}</div>
                                                    </Link>
                                                </TableCell>

                                                <TableCell classes={classes} style={{ padding: 0 }}>
                                                    {get(contact, "contactCompanyDisplayName", "")}
                                                </TableCell>

                                                <TableCell classes={classes} style={{ padding: 0 }}>
                                                    {formatPhoneNumber(get(contact, "contactPhoneNumber", ""))}
                                                </TableCell>

                                                <TableCell classes={classes} style={{ padding: 0 }}>
                                                    {get(contact, "contactEmailAddress", "")}
                                                </TableCell>

                                                <TableCell numeric classes={classes} style={{ padding: 0 }}>
                                                    <Button
                                                        className={classes.shrinkButton}
                                                        onClick={() =>
                                                            this.handleOpen(get(contact, "contactKey"), index)
                                                        }
                                                    >
                                                        <Delete />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                ) : (
                                    <TableCell colSpan={6} className={classes.noData}>
                                        <div className={classes.alternateContent}>No Contacts</div>
                                    </TableCell>
                                )}
                            </Table>
                        </Fade>
                    </React.Fragment>
                )}
                <Dialog
                    open={confirmDelete.open}
                    onClose={this.handleClose}
                    classes={{ paper: classes.dialog }}
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-crm-contact"
                >
                    <DialogContent>Delete this contact?</DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDeleteContact} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        );
    }
}

const styles = {
    root: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    idLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
        textDecoration: "none",
    },
    leftColumn: {
        paddingLeft: "0px",
    },
    paperSpacing: {
        padding: "10px 20px",
        fontSize: 14,
        marginBottom: "15px",
    },
    filterInput: {
        height: "70px",
        maringBottom: "0px,",
    },
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: "0 18px 0 0",
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    divider: {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        margin: "0 -20px 0 -20px",
    },
    loadingPlaceholder: {
        marginTop: "3em",
        marginBottom: "1.5em",
        textAlign: "center",
        width: "100%",
    },
    dialog: {
        width: "20em",
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "10em",
        marginBottom: "3em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    noData: {
        borderBottom: "none",
    },
};

export default withStyles(styles)(CrmContacts);
