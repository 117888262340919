const DEAL_CONTACTS = "DEAL_CONTACTS";
const PROPERTY_CONTACTS = "PROPERTY_CONTACTS";
const LISTING_CONTACTS = "LISTING_CONTACTS";

export const contact_options = [
    {
        value: "Buyer",
        label: "Buyer",
    },
    {
        value: "Tenant",
        label: "Tenant",
    },
    {
        value: "Landlord",
        label: "Landlord",
    },
    {
        value: "Outside Brokerage Company",
        label: "Outside Brokerage Company",
    },
    {
        value: "Building Owner",
        label: "Building Owner",
    },
    {
        value: "Property Manager",
        label: "Property Manager",
    },
];

export const comp_contact_options = [
    {
        value: "Buyer",
        label: "Buyer",
    },
    {
        value: "Tenant",
        label: "Tenant",
    },
    {
        value: "Landlord",
        label: "Landlord",
    },
    {
        value: "Outside Brokerage Company",
        label: "Outside Brokerage Company",
    },
    {
        value: "Building Owner",
        label: "Building Owner",
    },
    {
        value: "Property Manager",
        label: "Property Manager",
    },
    {
        value: "Procuring Broker",
        label: "Procuring Broker",
    },
    {
        value: "Listing Broker",
        label: "Listing Broker",
    },
];

export default {
    DEAL_CONTACTS,
    LISTING_CONTACTS,
    PROPERTY_CONTACTS,
};
