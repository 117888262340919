import React from "react";
import { DropdownButton, MenuItem } from "react-bootstrap";
import RequestModalContainer from "../listing/RequestModalContainer";

class CreateCompModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createDealTypeSelectorOpen: false,
            showModal: false,
        };
    }

    handleShowModal = (spaceType, transactionType) => {
        this.setState({ showModal: !this.state.showModal });
        this.props.toggleRequestModal(spaceType, transactionType);
    };

    handleOpenDropdown = () => {
        this.setState({ createDealTypeSelectorOpen: true });
    };

    render() {
        const { classes, internal } = this.props;
        return (
            <React.Fragment>
                <DropdownButton title={"Create Appraisal Comp"} onClick={(e) => this.handleOpenDropdown()} pullRight>
                    {(this.state.createDealTypeSelectorOpen || !internal) && (
                        <React.Fragment>
                            {internal && (
                                <React.Fragment>
                                    <MenuItem disableAutoFocusItem className={classes.menuCategory}>
                                        Consulting (No Listing)
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Consulting", null)}
                                    >
                                        Consulting Deal
                                    </MenuItem>
                                </React.Fragment>
                            )}
                            <MenuItem disabled disableAutoFocusItem className={classes.menuCategory}>
                                {internal ? "Lease (No Listing)" : "Lease"}
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Office", "Lease")}
                            >
                                Office Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Industrial", "Lease")}
                            >
                                Industrial Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Retail", "Lease")}
                            >
                                Retail Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Land", "Lease")}
                            >
                                Land Lease
                            </MenuItem>
                            {/* <MenuItem className={classes.menuSubItem} onClick={() => this.handleShowModal('Hospitality', 'Lease')} >Hospitality Lease</MenuItem> */}
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Multifamily", "Lease")}
                            >
                                Multifamily Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Life Science", "Lease")}
                            >
                                Life Science Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Healthcare", "Lease")}
                            >
                                Healthcare Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Religious", "Lease")}
                            >
                                Religious Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Educational", "Lease")}
                            >
                                Educational Lease
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Other", "Lease")}
                            >
                                Other Lease
                            </MenuItem>
                            <MenuItem disabled disableAutoFocusItem className={classes.menuCategory}>
                                {internal ? "Sale (No Listing)" : "Sale"}
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Office", "Sale")}
                            >
                                Office Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Industrial", "Sale")}
                            >
                                Industrial Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Retail", "Sale")}
                            >
                                Retail Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Land", "Sale")}
                            >
                                Land Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Hospitality", "Sale")}
                            >
                                Hospitality Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Multifamily", "Sale")}
                            >
                                Multifamily Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Life Science", "Sale")}
                            >
                                Life Science Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Healthcare", "Sale")}
                            >
                                Healthcare Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Religious", "Sale")}
                            >
                                Religious Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Educational", "Sale")}
                            >
                                Educational Sale
                            </MenuItem>
                            <MenuItem
                                className={classes.menuSubItem}
                                onClick={() => this.handleShowModal("Other", "Sale")}
                            >
                                Other Sale
                            </MenuItem>
                        </React.Fragment>
                    )}
                </DropdownButton>
                <RequestModalContainer
                    {...this.props}
                    handleShowModal={this.handleShowModal}
                    showModal={this.state.showModal}
                    modalType="appraisal"
                />
            </React.Fragment>
        );
    }
}

export default CreateCompModal;
