import React, { Component } from "react";
import { connect } from "react-redux";
import Transactions from "./Transactions";
import TransactionsToolbar from "./TransactionsToolbar";
import operations from "./ducks/operations";
import actions from "./ducks/actions";
import includes from "lodash/includes";
import ReactGA from "react-ga4";

class TransactionsContainer extends Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.getTransactionsForUser(id);
        ReactGA.send({ hitType: "pageview", page: "/transactions", title: "Transactions" });
    }

    componentWillUnmount() {
        this.props.flushTransactionsState();
    }

    render() {
        return includes(
            ["appraiser", "broker", "debtEquity", "propertyManagement"],
            this.props.auth.userProfile.role
        ) ? (
            <React.Fragment>
                <TransactionsToolbar {...this.props} />
                <Transactions {...this.props} />
            </React.Fragment>
        ) : null;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        transactions: state.debtEquityReducer.transactions,
        elementsSaving: state.utilsReducer.elementsSaving,
        columnToSort: state.debtEquityReducer.columnToSort,
        sortDirection: state.debtEquityReducer.sortDirection,
        filterQuery: state.debtEquityReducer.filterQuery,
        transactionsTab: state.debtEquityReducer.transactionsTab,
        numTransactions: state.debtEquityReducer.numResults,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleSort: (column) => {
            dispatch(operations.sort(column));
        },
        handleFilter: (query) => {
            dispatch(operations.filter(query));
        },
        getTransactionsForUser: (user) => {
            dispatch(operations.getTransactionsForUser(user));
        },
        deleteTransaction: (transactionId, index) => {
            dispatch(operations.deleteTransaction(transactionId, index));
        },
        flushTransactionsState: () => {
            dispatch(actions.handleInput("transactions", []));
        },
        switchTab: (event, value) => {
            dispatch(operations.switchTab(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsContainer);
