import configs from "../../../config";
import toLower from "lodash/toLower";

const workflow = {
    statuses: {
        Draft: {
            broker: {
                list_action: "edit",
            },
            debtEquity: {
                list_action: "edit",
            },
            evp: {
                list_action: "edit",
            },
            portlandBrokerAdmin: {
                list_action: "edit",
            },
            arizonaBrokerAdmin: {
                list_action: "edit",
            },
            color: "primary",
            progress: 0,
        },
        "Broker Action Required": {
            broker: {
                list_action: "edit",
            },
            debtEquity: {
                list_action: "edit",
            },
            evp: {
                list_action: "edit",
            },
            portlandBrokerAdmin: {
                list_action: "edit",
            },
            arizonaBrokerAdmin: {
                list_action: "edit",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "view",
            },
            color: "secondary",
            progress: 1,
        },
        "Portland Review Required": {
            portlandBrokerAdmin: {
                list_action: "start",
            },
            color: "secondary",
            progress: 1,
        },
        "AZ Review Required": {
            arizonaBrokerAdmin: {
                list_action: "start",
            },
            color: "secondary",
            progress: 1,
        },
        "Needs Review": {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            portlandBrokerAdmin: {
                list_action: "view",
            },
            arizonaBrokerAdmin: {
                list_action: "view",
            },
            data_entry: {
                list_action: "start",
            },
            color: "secondary",
            progress: 1,
        },
        "In Review": {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            portlandBrokerAdmin: {
                list_action: "view",
            },
            arizonaBrokerAdmin: {
                list_action: "view",
            },
            data_entry: {
                list_action: "edit",
            },
            progress: 1,
        },
        "Final Review": {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "review",
            },
            progress: 1,
        },
        "Admin Action Required": {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            portlandBrokerAdmin: {
                list_action: "view",
            },
            arizonaBrokerAdmin: {
                list_action: "view",
            },
            data_entry: {
                list_action: "review",
            },
            data_admin: {
                list_action: "view",
            },
            progress: 1,
        },
        "Ready For Accounting": {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            portlandBrokerAdmin: {
                list_action: "view",
            },
            arizonaBrokerAdmin: {
                list_action: "view",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "view",
            },
            progress: 1,
        },
        Closed: {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            portlandBrokerAdmin: {
                list_action: "view",
            },
            arizonaBrokerAdmin: {
                list_action: "view",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "view",
            },
            color: "secondary",
            progress: 2,
        },
        Executed: {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            portlandBrokerAdmin: {
                list_action: "view",
            },
            arizonaBrokerAdmin: {
                list_action: "view",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "view",
            },
            color: "secondary",
            progress: 0,
        },
    },
};

const role = {
    broker: {
        init_tab: "all",
    },
    evp: {
        init_tab: "all",
    },
    portlandBrokerAdmin: {
        init_tab: "all",
    },
    arizonaBrokerAdmin: {
        init_tab: "all",
    },
    data_entry: {
        init_tab: "unassigned_data_entry",
    },
    data_admin: {
        init_tab: "needs_final_review",
    },
    accounting: {
        init_tab: "awaiting_payment_accounting",
    },
};

const HOST = configs.HOST_URL + "/";

const DEAL_URL = HOST + "deals/";
const VOUCHER_URL = HOST + "vouchers/";
const ADMIN_URL = HOST + "admin/";
const LISTING_URL = HOST + "listings/";
const REQUIRED_DOCUMENTS_URL = "/required-documents/";

const urls = {
    save_deal: (type, key) => {
        return DEAL_URL + toLower(type) + "/" + (key ? key : "");
    },
    execute_invoice: (invoiceKey) => {
        return DEAL_URL + "invoice/execute/" + invoiceKey;
    },
    generate_invoice: (dealKey) => {
        return DEAL_URL + "invoice/generate/" + dealKey;
    },
    update_deal: (dealKey) => {
        return DEAL_URL + dealKey;
    },
    get_document: (documentKey) => {
        return DEAL_URL + "document/" + documentKey;
    },
    save_document: (dealKey) => {
        return DEAL_URL + "document/" + dealKey;
    },
    delete_document: (documentId) => {
        return DEAL_URL + "document/" + documentId;
    },
    update_document: (documentKey) => {
        return DEAL_URL + "document/" + documentKey;
    },
    get_deal: (dealKey) => {
        return DEAL_URL + dealKey;
    },
    get_deal_document_list: (dealKey) => {
        return DEAL_URL + "documentlist/" + dealKey;
    },
    download_deal_documents: (dealKey) => {
        return DEAL_URL + "download/documents/" + dealKey;
    },
    get_deal_documents: (dealKey) => {
        return DEAL_URL + "documentlist/" + dealKey;
    },
    get_vouchers_by_deal: (dealKey) => {
        return VOUCHER_URL + "deal/" + dealKey;
    },
    get_name_for_username: (username) => {
        return ADMIN_URL + "nameforusername/" + username;
    },
    calculate_deal_consideration: () => {
        return DEAL_URL + "invoice/calculate";
    },
    find_companies_with_name_starting_with: (input) => {
        return ADMIN_URL + "company/search?term=" + input;
    },
    get_commission_by_agent_and_voucher: (brokeragentKey, voucherKey) => {
        return VOUCHER_URL + "commission/" + brokeragentKey + "/" + voucherKey;
    },
    get_listing_agent: (listingKey) => {
        return LISTING_URL + "agents/" + listingKey;
    },
    get_voucher: (voucherKey) => {
        return VOUCHER_URL + voucherKey;
    },
    create_voucher_installment: (dealKey) => {
        return VOUCHER_URL + "installment/" + dealKey;
    },
    start_deal: (dealKey) => {
        return DEAL_URL + "start/" + dealKey;
    },
    delete_deal: (dealKey) => {
        return DEAL_URL + "delete/" + dealKey;
    },
    request_deal_termination: (dealKey) => {
        return DEAL_URL + "comp/terminaterequest/" + dealKey;
    },
    process_portland_terminate: (dealId) => {
        return DEAL_URL + "comp/portlandterminate/" + dealId;
    },
    process_arizona_terminate: (dealId) => {
        return DEAL_URL + "comp/arizonaterminate/" + dealId;
    },
    missing_required_documents: (dealId) => {
        return REQUIRED_DOCUMENTS_URL + "deal/" + dealId + "/missing";
    },
};

export const lease_invoice_contact_type_options = [
    // {
    //     value: '',
    //     label: 'Select type ...',
    //     disabled : true
    // },
    {
        value: "BUYER_TENANT",
        label: "Tenant",
    },
    {
        value: "SELLER_LANDLORD",
        label: "Landlord",
    },
    {
        value: "OUTSIDE_BROKER",
        label: "Outside Brokerage Company",
    },
];

export const sale_invoice_contact_type_options = [
    // {
    //     value: '',
    //     label: 'Select type ...',
    //     disabled : true
    // },
    {
        value: "BUYER_TENANT",
        label: "Buyer",
    },
    {
        value: "SELLER_LANDLORD",
        label: "Seller",
    },
    {
        value: "OUTSIDE_BROKER",
        label: "Outside Brokerage Company",
    },
];

export const lease_comp_contact_type_options = [
    {
        value: "BUILDING_OWNER",
        label: "Building Owner",
    },
    {
        value: "PROPERTY_MANAGER",
        label: "Property Manager",
    },
];

export const sale_representation_options = [
    {
        value: "",
        label: "Select a representation type ...",
        disabled: true,
    },
    {
        value: "BUYER",
        label: "Buyer",
    },
    {
        value: "SELLER",
        label: "Seller",
    },
    {
        value: "BOTH",
        label: "Both",
    },
];

export const lease_representation_options = [
    {
        value: "",
        label: "Select a representation type ...",
        disabled: true,
    },
    {
        value: "TENANT",
        label: "Tenant",
    },
    {
        value: "LANDLORD",
        label: "Landlord",
    },
    {
        value: "BOTH",
        label: "Both",
    },
];

export const prop_management_contact_options = [
    {
        value: "",
        label: "Select a representation type ...",
        disabled: true,
    },
    {
        value: "LANDLORD",
        label: "Landlord",
    },
    {
        value: "TENANT",
        label: "Tenant",
    },
    {
        value: "EMERGENCY_CONTACT",
        label: "Emergency Contact",
    },
    {
        value: "LEGAL_FORMAL_CONTACT",
        label: "Legal Or Formal Contact",
    },
    {
        value: "ACCOUNTING_CONTACT",
        label: "Accounting Contact",
    },
    {
        value: "ASSET_MANAGER",
        label: "Asset Manager",
    },
    {
        value: "OTHER",
        label: "Other",
    },
];

export const investor_type_options = [
    {
        value: "",
        label: "Select an investor type ...",
        disabled: true,
    },
    {
        value: "PRIVATE",
        label: "Private",
    },
    {
        value: "INSTITUTION",
        label: "Institution",
    },
    {
        value: "REIT",
        label: "REIT",
    },
];

export const interest_type_options = [
    {
        value: "",
        label: "Select an interest type ...",
        disabled: true,
    },
    {
        value: "FEE SIMPLE",
        label: "Fee Simple",
    },
    {
        value: "LEASED FEE",
        label: "Leased Fee",
    },
    {
        value: "LEASEHOLD",
        label: "Leasehold",
    },
    {
        value: "CONDO",
        label: "Condo",
    },
];

export const cap_rate_basis_options = [
    {
        value: "",
        label: "Select a cap rate basis ...",
        disabled: true,
    },
    {
        value: "TRAILING",
        label: "Trailing",
    },
    {
        value: "FORWARD LOOKING",
        label: "Forward Looking",
    },
];

export const topography_options = [
    {
        value: "",
        label: "Select a topography ...",
        disabled: true,
    },
    {
        value: "LEVEL",
        label: "Level",
    },
    {
        value: "SLOPING",
        label: "Sloping",
    },
];
export const lease_renewal_options = [
    {
        value: "",
        label: "Select new lease or renewal ...",
        disabled: true,
    },
    {
        value: false,
        label: "New Lease",
    },
    {
        value: true,
        label: "Renewal",
    },
];

export default {
    workflow,
    role,
    urls,
    lease_invoice_contact_type_options,
    sale_invoice_contact_type_options,
    sale_representation_options,
    lease_representation_options,
    lease_comp_contact_type_options,
    prop_management_contact_options,
    investor_type_options,
    interest_type_options,
    cap_rate_basis_options,
    topography_options,
    lease_renewal_options,
};
