import { push } from "connected-react-router";
import includes from "lodash/includes";
import actions from "./actions";
import constants from "../constants";
import utils from "../../utils/index";
import validation from "./validation";
import dealActions from "../../deal/ducks/actions";
import dealsActions from "../../deals/ducks/actions";
import dealOperations from "../../deal/ducks/operations";
import listingOperations, { separateListingsBrokers } from "../../listing/ducks/operations";
import searchActions from "../../search/ducks/actions";
import searchOperations from "../../search/ducks/operations";
import get from "lodash/get";
import each from "lodash/each";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import forIn from "lodash/forIn";
import compact from "lodash/compact";
import uniq from "lodash/uniq";
import { request } from "../../utils/axios-wrapper";
import contactUtils from "../../contact/utils";
import contactConstants from "../../contact/constants";
import compValidation from "./compValidation";
import compValidationNotRequired from "./compValidationNotRequired";
import outsideCompValidation from "./outsideOrAppraisalCompValidation";
import outsideCompValidationNonRequired from "./outsideOrAppraisalCompValidationNonRequired";
import utilOperations, { getFromAttributeValues } from "../../utils/operations";
import propertyOperations from "../../property/ducks/operations";
import listingActions from "../../listing/ducks/actions";
import listingValidation from "../../listing/ducks/validation";
import documentOperations from "../../documents/ducks/operations";
import ReactGA from "react-ga4";

const handleSubmit = (save, callback) => {
    return (dispatch, getState) => {
        if (save) {
            dispatch(saveComp(callback));
        } else {
            callback &&
                request(
                    {
                        url: callback,
                        method: "put",
                        crossDomain: true,
                    },
                    dispatch
                )
                    .then((response) => {
                        dispatch(utils.actions.snackbar(true, "success", "Status changed successfully."));
                        dispatch(dealActions.getDeal(response));
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(
                            utils.actions.snackbar(true, "error", "Something went wrong trying to submit this comp")
                        );
                        dispatch(utilOperations.reportError(err, "comp:operations:handleSubmit Error"));
                    });
        }
    };
};

const clearAllComps = () => {
    return (dispatch, getState) => {
        dispatch(searchActions.clearSearchResponse("COMPS"));
        dispatch(actions.clearAllComps());
    };
};

const saveComp = (callback) => {
    return (dispatch, getState) => {
        dispatch(validateComp());
        if (isEmpty(getState().dealReducer.errors)) {
            dispatch(dealOperations.saveDeal(callback));
        } else {
            dispatch(
                utils.actions.snackbar(true, "error", "Errors must be resolved before this comp can be submitted")
            );
        }
    };
};

const validateComp = () => {
    return (dispatch, getState) => {
        dispatch(dealActions.clearErrors());
        dispatch(validatePropertyDetail(getState().dealReducer.deal));
        if (getState().dealReducer.deal.transactionType === "LEASE") {
            dispatch(validateLeaseDetail(getState().dealReducer.deal));
        }
        if (getState().dealReducer.deal.transactionType === "SALE") {
            dispatch(validateSaleDetail(getState().dealReducer.deal));
        }
        dispatch(validateContacts(contactUtils.getContacts(contactConstants.PROPERTY_CONTACTS, getState())));
    };
};

const validatePropertyDetail = (deal) => {
    return (dispatch, getState) => {
        dispatch(
            processSimpleFieldValidationResponse(
                validation.validatePropertyDetailRequiredForAll(deal.propertyAttributes)
            )
        );
        dispatch(
            processSimpleFieldValidationResponse(validation.validatePropertyDetailOptional(deal.propertyAttributes))
        );
        if (deal.useType !== "LAND") {
            dispatch(
                processSimpleFieldValidationResponse(
                    validation.validatePropertyDetailRequiredForAllExceptLand(deal.propertyAttributes)
                )
            );
        }
        if (deal.useType === "OFFICE") {
            dispatch(
                processSimpleFieldValidationResponse(
                    validation.validatePropertyDetailRequiredForOffice(deal.propertyAttributes)
                )
            );
        }
    };
};

const validateLeaseDetail = (deal) => {
    return (dispatch, getState) => {
        dispatch(processSimpleFieldValidationResponse(validation.validateLeaseDetailRequiredForAll(deal)));
        dispatch(processSimpleFieldValidationResponse(validation.validateLeaseDetailRequiredForAllExceptLand(deal)));
        if (deal.useType !== "LAND") {
            dispatch(processSimpleFieldValidationResponse(validation.validateLeaseDetailOptional(deal)));
        }
    };
};

const validateSaleDetail = (saleDetail) => {
    return (dispatch, getState) => {
        dispatch(processSimpleFieldValidationResponse(validation.validateSaleDetailRequiredForAll(saleDetail)));
    };
};

const validateContacts = (contacts) => {
    return (dispatch, getState) => {
        each(Object.keys(contacts), (i) => {
            const validationResponse = contactUtils.validateContact(contacts[i]);
            dispatch(processArrayFieldTypeValidationResponse(validationResponse, "contactInfo", i));
        });
    };
};

const processSimpleFieldValidationResponse = (validationResponse) => {
    return (dispatch, getState) => {
        if (validationResponse) {
            each(Object.keys(validationResponse), (e) => {
                dispatch(dealActions.addError(`errors.${e}`, validationResponse[e][0]));
            });
        }
    };
};

const processArrayFieldTypeValidationResponse = (validationResponse, identifier, position) => {
    return (dispatch, getState) => {
        if (validationResponse) {
            each(Object.keys(validationResponse), (e) => {
                dispatch(dealActions.addError(`errors.${identifier}.${position}.${e}`, validationResponse[e][0]));
            });
        }
    };
};

const handleDocumentWarning = (open, proceed, docs) => {
    return (dispatch, getState) => {
        dispatch(actions.documentWarning(open, proceed, docs));
    };
};

const saveAndExit = () => {
    return (dispatch, getState) => {
        const deal = getState().dealReducer.deal;
        const url = constants.urls.save_deal(deal.transactionType, deal.dealKey);
        request(
            {
                url: url,
                method: "post",
                crossDomain: true,
                data: dealOperations.buildRequestFromState(getState()),
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.snackbar(true, "success", "Success"));
                dispatch(dealActions.getDeal(response));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error occured while trying to save " + err));
                dispatch(utilOperations.reportError(err, "comp:operations:saveAndExitError"));
            });
    };
};

const switchTab = (tab) => {
    return (dispatch, getState) => {
        dispatch(actions.switchTab(tab));
        if (!(tab === "all_km_comps" || tab === "comps_group" || tab === "broker_comps")) {
            dispatch(searchActions.onSearchPage(false));
            dispatch(searchActions.onGroupPage(false));
            if (!getState().compReducer.comps[tab]) {
                dispatch(getComps(tab));
            } else {
                dispatch(countComps(getState().compReducer.comps[tab]));
            }
        } else if (tab === "all_km_comps") {
            dispatch(searchActions.onGroupPage(false));
            dispatch(searchActions.onSearchPage(true));
        } else {
            dispatch(searchActions.onSearchPage(false));
            dispatch(searchActions.onGroupPage(true));
        }
    };
};

const getComps = (tab) => {
    return (dispatch, getState) => {
        if (tab === "broker_comps") {
            dispatch(utils.actions.loading(true));
            request(
                {
                    url: constants.urls[tab](getState().authReducer.userProfile.user),
                    method: "get",
                    crossDomain: true,
                },
                dispatch
            )
                .then((response) => {
                    dispatch(countComps(response.data));
                    dispatch(actions.receiveComps(response.data, tab));
                    dispatch(setBrokerCheckedComps(response.data, true));
                    dispatch(utils.actions.loading(false));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(utils.actions.loading(false));
                });
        }
    };
};

const countComps = (data) => {
    return (dispatch, _) => {
        let count = 0;

        data.forEach((comp) => {
            count += 1;
        });
        dispatch(actions.countComps(count));
    };
};

const addOutsideComp = (transactionType, propertyType, newProperty, initialStatus) => {
    //actually creates deal and redirects to deal summary page of new deal
    return (dispatch, getState) => {
        //deal with property first
        const state = getState().listingReducer;
        let propertyData = {};
        let validationResponse = false;
        if (newProperty) {
            dispatch(utils.actions.clearErrors());
            validationResponse = validation.validateRequestListingFromNew(get(state, "requestModal"));
            if (validationResponse) {
                each(Object.keys(validationResponse), (e) => {
                    dispatch(utils.actions.addError(e, validationResponse[e][0]));
                });
                return false;
            } else {
                propertyData = {
                    propertyKey: null,
                    primaryAddress: {
                        address1: get(state, "requestModal.newPropertyAddress"),
                        city: {
                            city: get(state, "requestModal.newPropertyCity"),
                            state: {
                                stateCode: get(state, "requestModal.newPropertyState"),
                            },
                        },
                        zipCode: {
                            zipCode: get(state, "requestModal.newPropertyZip"),
                        },
                    },
                    memberComments: get(state, "requestModal.newPropertyComments"),
                    propertyAttributes: {
                        propertyName: get(state, "requestModal.newPropertyName"),
                        propertyStatus: "Requested",
                    },
                };
                dispatch(handleCompInput("requestModal", propertyData));
            }
        } else {
            dispatch(utils.actions.clearErrors());
            validationResponse = validation.validateRequestListingFromExisting(
                get(state, "requestModal.selectedProperty")
            );
            if (validationResponse) {
                each(Object.keys(validationResponse), (e) => {
                    dispatch(utils.actions.addError(e, validationResponse[e][0]));
                });
            } else {
                const modalData = listingOperations.buildListingRequestFromModal(
                    getState().listingReducer.requestModal,
                    dispatch
                );
                propertyData = {
                    ...modalData.property,
                    propertyAttributes: {
                        ...get(modalData, "property.propertyAttributes"),
                        propertyStatus: "Requested",
                    },
                };
            }
            dispatch(handleCompInput("requestModal", propertyData));
        }

        if (!validationResponse) {
            let url;
            switch (transactionType) {
                case "Lease":
                    url = constants.urls.create_deal("lease");
                    break;
                case "Sale":
                    url = constants.urls.create_deal("sale");
                    break;
                case "Consulting":
                    url = constants.urls.create_deal("consulting");
                    break;
                default:
                    url = constants.urls.create_deal("lease");
            }
            if (transactionType === "Consulting") {
                request(
                    {
                        method: "post",
                        url: url,
                        data: {
                            transactionType: transactionType,
                            compStatus: "Closed", //4.18.19
                            dealStatus: initialStatus,
                            dealKey: null,
                            property: propertyData,
                        },
                        headers: { "Content-Type": "application/json" },
                    },
                    dispatch
                ).then((response) => {
                    dispatch(handleCompInput(`modalData.property`, {}));
                    dispatch(push(`/voucher/${response.data.voucherKey}`));
                });
            } else {
                request(
                    {
                        url: url,
                        method: "post",
                        data: {
                            transactionType: transactionType,
                            propertyType: propertyType,
                            compStatus: initialStatus,
                            dealStatus: initialStatus,
                            dealKey: null,
                            fromListing: false,
                            listing: {
                                listingKey: null,
                                property: propertyData,
                                listingType: transactionType,
                                attributes: {
                                    propertyAttributesKey: null,
                                    useType: propertyType,
                                },
                            },
                        },
                        headers: { "Content-Type": "application/json" },
                    },
                    dispatch
                ).then((response) => {
                    dispatch(handleCompInput(`modalData.property`, {}));
                    dispatch(push(`/comp/${response.data.dealKey}`));
                });
            }
        }
    };
};

//deal + comp refactor
const getDeal = (key) => {
    return (dispatch, getState) => {
        dispatch(dealActions.getDeal({}));
        dispatch(handleInput("invoice", {}));
        dispatch(handleInput("totalConsideration", {}));
        request(
            {
                url: constants.urls.get_deal(key),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                const deal = response.data;
                dispatch(handleInput("totalConsideration", dealOperations.unpackTotalConsideration(deal)));
                dispatch(handleInput("invoice", deal.invoice));
                dispatch(
                    dealActions.getDeal({
                        ...deal,
                        listing: {
                            ...deal.listing,
                            listingBrokers: separateListingsBrokers(response.data.listing),
                        },
                    })
                );
                request(
                    {
                        url: constants.urls.get_brokers_on_deal(key),
                        method: "get",
                        crossDomain: true,
                    },
                    dispatch
                )
                    .then((response) => {
                        const brokers = response.data;
                        dispatch(handleInput("brokersOnDeal", brokers));
                    })
                    .catch((err) => {
                        dispatch(
                            utils.actions.snackbar(
                                true,
                                "error",
                                "Error while attempting to retrieve brokers on deal: " + err
                            )
                        );
                    });
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error while attempting to retrieve deal: " + err));
            });
    };
};

const handleInput = (path, value) => {
    return (dispatch, _) => {
        dispatch(dealActions.updateCompValue(path, value));
    };
};

const handleCompInput = (path, value) => {
    return (dispatch, _) => {
        dispatch(actions.updateCompValue(path, value));
    };
};

const addArrayElement = (pathToArray, obj = {}) => {
    return (dispatch, getState) => {
        let array = cloneDeep(get(getState().dealReducer, pathToArray, []));
        array.push(obj);
        dispatch(dealActions.setCompArray(pathToArray, array));
    };
};

const deleteArrayElement = (pathToArray, index) => {
    return (dispatch, getState) => {
        let array = cloneDeep(get(getState().dealReducer, pathToArray, []));
        array.splice(index, 1);
        dispatch(dealActions.setCompArray(pathToArray, array));
    };
};

const handlePreOutsideOrAppraisalCompSubmissionTasks = (options, compType) => {
    return (dispatch, getState) => {
        dispatch(dealActions.clearErrors());

        const { skipRequiredValidation, elementSaving, callback, submitStatus, shouldExit } = options;
        const deal = dealOperations.buildCompRequestFromState(getState());
        const documents = getState().documentsReducer;
        let errors = {};
        if (skipRequiredValidation) {
            errors = outsideCompValidationNonRequired.validateNonRequired(
                deal,
                deal.propertyType,
                deal.transactionType,
                documents,
                compType
            );
        } else {
            errors = outsideCompValidation.validateOutsideOrAppraisalComp(
                deal,
                deal.propertyType,
                deal.transactionType,
                documents,
                compType
            );
        }
        const panelsToExpand = outsideCompValidation.getPanelsToExpand(errors, deal.propertyType, deal.transactionType);

        console.log("Form errors:", errors);
        if (isEmpty(errors) || isNil(errors)) {
            dispatch(
                submitOutsideOrAppraisalComp({
                    callback: callback,
                    elementSaving: elementSaving,
                    deal: deal,
                    submitStatus: submitStatus,
                    shouldExit: shouldExit,
                })
            );
        } else {
            dispatch(utils.actions.setErrorPanelsToExpand(panelsToExpand));
            dispatch(utils.actions.toggleCheckErrorPanels(true));
            forIn(errors, (value, key) => {
                dispatch(dealActions.addError(key, value));
            });
            if (skipRequiredValidation) {
                dispatch(
                    utils.actions.snackbar(
                        true,
                        "error",
                        "You have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before saving"
                    )
                );
            } else {
                dispatch(
                    utils.actions.snackbar(
                        true,
                        "error",
                        "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                    )
                );
            }
        }
    };
};

const handlePreCompSubmissionTasks = (options) => {
    return (dispatch, getState) => {
        dispatch(dealActions.clearErrors());

        const { skipRequiredValidation, callback, elementSaving, shouldExit } = options;
        const deal = dealOperations.buildCompRequestFromState(getState());
        const documents = getState().documentsReducer;
        let errors = {};
        if (skipRequiredValidation) {
            errors = compValidationNotRequired.validateNonRequired(
                deal,
                deal.propertyType,
                deal.transactionType,
                documents
            );
        } else {
            errors = compValidation.validateComp(deal, deal.propertyType, deal.transactionType, documents);
        }
        const panelsToExpand = compValidation.getPanelsToExpand(errors, deal.propertyType, deal.transactionType);

        console.log("compOperations.handlePreCompSubmissionTasks Form errors:", errors);
        if (isEmpty(errors) || isNil(errors)) {
            dispatch(
                submitComp({ callback: callback, elementSaving: elementSaving, deal: deal, shouldExit: shouldExit })
            );
        } else {
            dispatch(utils.actions.setErrorPanelsToExpand(panelsToExpand));
            dispatch(utils.actions.toggleCheckErrorPanels(true));
            forIn(errors, (value, key) => {
                dispatch(dealActions.addError(key, value));
            });
            if (skipRequiredValidation) {
                dispatch(
                    utils.actions.snackbar(
                        true,
                        "error",
                        "You have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before saving"
                    )
                );
            } else {
                dispatch(
                    utils.actions.snackbar(
                        true,
                        "error",
                        "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                    )
                );
            }
        }
    };
};

const submitOutsideOrAppraisalComp = (options) => {
    return (dispatch, getState) => {
        const { elementSaving, deal, submitStatus, shouldExit } = options;
        dispatch(utils.actions.somethingSaving(true, elementSaving));

        let compStatus = deal.compStatus;
        let dealStatus = deal.dealStatus;
        if (elementSaving === "submit") {
            compStatus = submitStatus;
            dealStatus = submitStatus;
        }

        dealOperations
            .saveDealNEW(deal, dispatch)
            .then((response) => {
                dispatch(utils.actions.snackbar(true, "success", "Success."));
                request(
                    {
                        method: "put",
                        url: constants.urls.save_deal(response.data.dealKey),
                        data: { compStatus: compStatus, dealStatus: dealStatus },
                        headers: { "Content-Type": "application/json" },
                    },
                    dispatch
                )
                    .then((response) => {
                        dispatch(dealsActions.clearDealsState());
                        if (shouldExit) {
                            dispatch(push("/comps"));
                        }
                        dispatch(utils.actions.somethingSaving(false, elementSaving));
                    })
                    .catch((err) => {
                        dispatch(utils.actions.snackbar(true, "error", "Error occured while trying to change status"));
                        dispatch(utils.actions.somethingSaving(false, elementSaving));
                        console.log("Error changing outside/appraisal comp status:", err);
                        dispatch(
                            utilOperations.reportError(err, "comp:operations:submitOutsideOrAppraisalComp(inner) Error")
                        );
                    });
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error occured while trying to submit this comp"));
                dispatch(utils.actions.somethingSaving(false, elementSaving));
                console.log("Error submitting outside/appraisal comp:", err);
                dispatch(utilOperations.reportError(err, "comp:operations:submitOutsideOrAppraisalComp(outer) Error"));
            });
    };
};

//2.11.19
const submitComp = (options) => {
    return (dispatch, getState) => {
        const { callback, elementSaving, deal, shouldExit } = options;
        dispatch(utils.actions.somethingSaving(true, elementSaving));

        dealOperations
            .saveDealNEW(deal)
            .then((response) => {
                if (callback) {
                    request(
                        {
                            method: "put",
                            url: callback,
                            headers: { "Content-Type": "application/json" },
                        },
                        dispatch
                    )
                        .then((response) => {
                            dispatch(utils.actions.snackbar(true, "success", "Success."));
                            dispatch(dealsActions.clearDealsState());
                            dispatch(utils.actions.somethingSaving(false, elementSaving));
                            if (shouldExit) {
                                dispatch(push("/deals"));
                            }
                        })
                        .catch((err) => {
                            dispatch(
                                utils.actions.snackbar(
                                    true,
                                    "error",
                                    "Error occured while trying to change status: " + err
                                )
                            );
                            dispatch(utils.actions.somethingSaving(false, elementSaving));
                            console.log("Error changing comp status:", err);
                            dispatch(utilOperations.reportError(err, "comp:operations:submitComp(inner) Error"));
                        });
                } else {
                    dispatch(utils.actions.somethingSaving(false, elementSaving));
                    dispatch(utils.actions.snackbar(true, "success", "Success."));
                    dispatch(dealsActions.clearDealsState());
                    if (shouldExit) {
                        dispatch(push("/deals"));
                    }
                }
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error occured while trying to save this comp"));
                dispatch(utils.actions.somethingSaving(false, elementSaving));
                console.log("Error submitting outside comp:", err);
                dispatch(utilOperations.reportError(err, "comp:operations:submitComp(outer) Error"));
            });
    };
};

const selectPropertyFromAutocomplete = (property) => {
    return (dispatch, getState) => {
        dispatch(actions.selectPropertyFromAutocomplete(property));
    };
};

const searchComps = () => {
    return (dispatch, getState) => {
        dispatch(clearAllComps());
        dispatch(searchOperations.storeSearchCleared(false, "COMPS"));
        dispatch(searchActions.loadingSearch(true));
        dispatch(searchOperations.storeSearchRun(true, "COMPS"));
        dispatch(searchOperations.executeSearch("COMPS", loadSearchResults));
    };
};

const loadSearchResults = () => {
    return (dispatch, getState) => {
        dispatch(actions.receiveComps(getState().searchReducer.COMPS.searchResponse.results, "all_km_comps"));
        dispatch(actions.countComps(getState().searchReducer.COMPS.searchResponse.totalResultCount));

        const retainSelections = getState().searchReducer.COMPS.retainSelectedFromSave;
        if (retainSelections) {
            dispatch(setSelectedFromSavedSearch(getState().searchReducer.COMPS.searchResponse.results));
        } else {
            dispatch(setCheckedComps(getState().searchReducer.COMPS.searchResponse.results, true));
        }
    };
};

const clearSearchResults = () => {
    return (dispatch, getState) => {
        let comps = {};
        dispatch(actions.receiveComps(comps, "all_km_comps"));
        dispatch(actions.countComps(0));
        dispatch(actions.setCheckedComps(new Map()));
    };
};

const setCheckedComps = (comps, value) => {
    return (dispatch, getState) => {
        let selected = new Map();
        if (comps !== undefined) {
            comps.forEach((comp) => {
                selected.set(get(comp, "dealkey", null), value);
            });
        }
        dispatch(actions.setCheckedComps(selected));
    };
};

const setGroupCheckedComps = (comps, value) => {
    return (dispatch, getState) => {
        let selected = new Map();
        if (comps) {
            comps.forEach((comp) => {
                selected.set(get(comp, "dealkey", null), value);
            });
            dispatch(actions.setGroupCheckedComps(selected));
        }
    };
};

const setBrokerCheckedComps = (comps, value) => {
    return (dispatch, getState) => {
        let selected = new Map();
        if (comps) {
            comps.forEach((comp) => {
                selected.set(get(comp, "dealKey", null), value);
            });
        }
        dispatch(actions.setBrokerCheckedComps(selected));
    };
};

const setSelectedFromSavedSearch = (comps) => {
    return (dispatch, getState) => {
        const selectedFromSave = getState().searchReducer.COMPS.selectedFromSave;
        let selected = new Map();
        let reorderedComps = [];

        // adds all the previously selected items to the reordered Array, and regenerates the map of selections for each listing key
        comps.forEach((comp) => {
            if (includes(selectedFromSave, get(comp, "dealkey", null))) {
                selected.set(get(comp, "dealkey", null), true);
                reorderedComps.push(comp);
            } else {
                selected.set(get(comp, "dealkey", null), false);
            }
        });
        // adds all listings that were not selected to the end of the reordered Array
        comps.forEach((comp) => {
            if (!includes(selectedFromSave, get(comp, "dealkey", null))) {
                reorderedComps.push(comp);
            }
        });
        dispatch(actions.setCheckedComps(selected));
        dispatch(actions.receiveComps(reorderedComps, "all_km_comps"));
    };
};

const checkboxClick = (key, compTab) => {
    return (dispatch, getState) => {
        if (compTab === "comps_group") {
            let selected = getState().compReducer.selectedCompsGroup;
            const current = selected.get(key);
            dispatch(actions.groupCheckboxClick(key, !current));
        } else if (compTab === "broker_comps") {
            let selected = getState().compReducer.brokerComps;
            const current = selected.get(key);
            dispatch(actions.brokerCompCheckboxClick(key, !current));
        } else {
            let selected = getState().compReducer.selectedComps;
            const current = selected.get(key);
            dispatch(actions.checkboxClick(key, !current));
        }
    };
};

const selectAll = (value) => {
    return (dispatch, getState) => {
        dispatch(setCheckedComps(getState().compReducer.comps["all_km_comps"], value));
    };
};

const selectAllGroup = (value) => {
    return (dispatch, getState) => {
        dispatch(setGroupCheckedComps(getState().compReducer.comps["comps_group"], value));
    };
};

const selectAllBrokerComps = (value) => {
    return (dispatch, getState) => {
        dispatch(setBrokerCheckedComps(getState().compReducer.comps["broker_comps"], value));
    };
};

const getCompHistoryByPropertyKey = (key) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "comp_history_for_property"));
        dispatch(utils.actions.loading(true));
        request(
            {
                url: constants.urls.get_comp_history_for_property(key),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(countComps(response.data));
                dispatch(actions.receiveComps(response.data, "comp_history_for_property"));
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, "comp_history_for_property"));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error while attempting to retrieve comps: " + err));
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, "comp_history_for_property"));
            });
    };
};

const generateCompReport = (
    tab,
    includeBrokerInfo,
    reportTitle,
    reportStyle,
    mapView,
    dealParty = null,
    timeOnMarket = null,
    croppedPhotoUrl
) => {
    return (dispatch, getState) => {
        let comps = [];
        if (tab === "all_km_comps") {
            comps = getState().compReducer.selectedComps;
        } else if (tab === "comps_group") {
            comps = getState().compReducer.selectedCompsGroup;
        } else if (tab === "broker_comps") {
            comps = getState().compReducer.brokerComps;
        }
        let selectedComps = [];
        for (var [key, value] of comps) {
            if (value) {
                selectedComps.push(key);
            }
        }
        selectedComps = compact(selectedComps);
        selectedComps = uniq(selectedComps);

        let reportInfo = {
            includeBrokerInfo: includeBrokerInfo,
            reportTitle: reportTitle,
            reportStyle: reportStyle,
            mapView: mapView,
            selected: selectedComps,
            dealParty: dealParty,
            timeOnMarket: timeOnMarket,
            croppedPhotoUrl: croppedPhotoUrl,
        };

        dispatch(utils.actions.somethingSaving(true, "generateCompReport"));
        request(
            {
                url: constants.urls.generate_comp_report(),
                method: "post",
                crossDomain: true,
                data: reportInfo,
            },
            dispatch
        )
            .then((response) => {
                window.open(response.data, "_blank");
                dispatch(utils.actions.somethingSaving(false, "generateCompReport"));
                ReactGA.event("Report Generated", {
                    type: "comp",
                    reportStyle: reportStyle,
                });
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error while attempting to generate report: " + err));
                dispatch(utils.actions.somethingSaving(false, "generateCompReport"));
                dispatch(utilOperations.reportError(err, "comp:operations:generateCompReport Error"));
            });
    };
};

const generateCompMetroReport = (tab, includeBrokerInfo, reportTitle, propType) => {
    return (dispatch, getState) => {
        let comps = [];
        if (tab === "all_km_comps") {
            comps = getState().compReducer.selectedComps;
        } else if (tab === "comps_group") {
            comps = getState().compReducer.selectedCompsGroup;
        } else if (tab === "broker_comps") {
            comps = getState().compReducer.brokerComps;
        }
        let selectedComps = [];
        for (var [key, value] of comps) {
            if (value) {
                selectedComps.push(key);
            }
        }

        selectedComps = compact(selectedComps);
        selectedComps = uniq(selectedComps);

        let reportInfo = {
            includeBrokerInfo: includeBrokerInfo,
            reportTitle: reportTitle,
            propType: propType,
            selected: selectedComps,
        };
        dispatch(utils.actions.somethingSaving(true, "generateCompReport"));
        request(
            {
                url: constants.urls.generate_metro_comp_report(),
                method: "post",
                crossDomain: true,
                data: reportInfo,
            },
            dispatch
        )
            .then((response) => {
                window.open(response.data, "_blank");
                dispatch(utils.actions.somethingSaving(false, "generateCompReport"));
                ReactGA.event("Report Generated", {
                    type: "comp",
                    reportStyle: "CompMetroReport",
                });
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error while attempting to generate report: " + err));
                dispatch(utils.actions.somethingSaving(false, "generateCompReport"));
                dispatch(utilOperations.reportError(err, "comp:operations:generateCompMetroReport Error"));
            });
    };
};

const updatePropertyForComp = (dealKey, propertyKey, user) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        const state = getState().listingReducer;
        dispatch(listingActions.clearErrors());
        let validationResponse = listingValidation.validateRequestListingFromExisting(
            get(state, "requestModal.selectedProperty")
        );
        if (validationResponse) {
            each(Object.keys(validationResponse), (e) => {
                dispatch(listingActions.addError(e, validationResponse[e][0]));
            });
            dispatch(utils.actions.loading(false));
            return false;
        } else {
            request(
                {
                    url: constants.urls.update_deal_property(dealKey, propertyKey, user),
                    method: "put",
                    crossDomain: true,
                },
                dispatch
            )
                .then((response) => {
                    dispatch(propertyOperations.togglePropertySearchModal(false));
                    dispatch(propertyOperations.toggleUpdateDialog(false));
                    dispatch(utils.actions.snackbar(true, "success", "Property Updated"));
                    dispatch(utils.actions.loading(false));
                    dispatch(utils.actions.isSaving(false));
                    dispatch(dealActions.getDeal(response.data));
                    dispatch(push("/comments/comp/" + dealKey));
                    dispatch(push("/comp/" + dealKey));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(
                        utils.actions.snackbar(
                            true,
                            "error",
                            "Error during update procedure. Verify that property has been updated on Comp." + err
                        )
                    );
                    dispatch(utils.actions.isSaving(false));
                    dispatch(utilOperations.reportError(err, "comp:operations:saveListing Error"));
                });
            dispatch(listingActions.updateListingRequest("open", false));
        }
    };
};

const reorder = (data, startIndex, endIndex) => {
    const result = [...data];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const handleDragEnd = (dragResult, comps, tab) => {
    const { destination, source } = dragResult;
    if (!destination) {
        return (dispatch, getState) => {
            dispatch(actions.updateCompOrder(comps, tab));
        };
    }
    //editor says this will never get hit - but it does
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return (dispatch, getState) => {
            dispatch(actions.updateCompOrder(comps, tab));
        };
    }

    const updatedComps = reorder(comps, dragResult.source.index, dragResult.destination.index);

    return (dispatch, getState) => {
        dispatch(updateSelectedCompOrder(updatedComps, tab));
        dispatch(actions.updateCompOrder(updatedComps, tab));

        if (tab === "comps_group") {
            let list = [];
            for (let i = 0; i < updatedComps.length; i++) {
                list.push(get(updatedComps[i], "dealkey", null));
            }
            dispatch(searchActions.setCurrentGroup("COMPS", list));
        }
    };
};

const updateSelectedCompOrder = (comps, tab) => {
    return (dispatch, getState) => {
        let selected = [];
        if (tab === "all_km_comps") {
            selected = getState().compReducer.selectedComps;
        } else if (tab === "comps_group") {
            selected = getState().compReducer.selectedCompsGroup;
        } else if (tab === "broker_comps") {
            selected = getState().compReducer.brokerComps;
        }
        let updatedSelected = new Map();

        comps.forEach((comp) => {
            let value = null;
            if (selected.get(getFromAttributeValues(comp, ["dealkey", "dealKey"], false))) {
                // if (selected[comp.dealkey]){
                value = true;
            } else {
                value = false;
            }
            // if no dealkey do dealKey
            updatedSelected.set(getFromAttributeValues(comp, ["dealkey", "dealKey"], null), value);
        });

        if (tab === "all_km_comps") {
            dispatch(actions.setCheckedComps(updatedSelected));
        } else if (tab === "comps_group") {
            dispatch(actions.setGroupCheckedComps(updatedSelected));
        } else if (tab === "broker_comps") {
            dispatch(actions.setBrokerCheckedComps(updatedSelected));
        }
    };
};

const updateCompsForSort = (sortedData, tab) => {
    return (dispatch, getState) => {
        dispatch(actions.updateCompOrder(sortedData, tab));
        if (tab === "all_km_comps" || tab === "comps_group" || tab === "broker_comps") {
            dispatch(updateSelectedCompOrder(sortedData, tab));
        }
    };
};

const displayDeleteDialog = (open) => {
    return (dispatch, getState) => {
        dispatch(actions.updateDeleteDialog(open));
    };
};

const handleDeleteOutsideComp = (dealKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        dispatch(utils.actions.somethingSaving(true, "outsideCompDelete"));
        dispatch(actions.updateDeleteDialog(false));
        request(
            {
                method: "put",
                url: constants.urls.delete_outside_comp(dealKey),
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.somethingSaving(false, "outsideCompDelete"));
                dispatch(push(`/comps`));
                dispatch(utils.actions.snackbar(true, "success", "Success"));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, "outsideCompDelete"));
                dispatch(utils.actions.snackbar(true, "error", "Unable to delete this outside comp: " + err.data));
                console.log(err);
                dispatch(
                    utilOperations.reportError(
                        err,
                        "outsideComp:operations:handleDeleteOutsideComp - Error trying to delete outside comp"
                    )
                );
            });
    };
};

const setCurrentCompsGroup = (comps, order) => {
    //When method is called from savedSearch, order prop is a json string that needs to be parsed into an array. In all other cases it is either null or already an array.
    return (dispatch, getState) => {
        if (comps != null) {
            if (order != null) {
                let groupOrder;
                if (typeof order == "string") {
                    groupOrder = JSON.parse(order);
                } else {
                    groupOrder = order;
                }
                let orderedComps = [];
                for (let i = 0; i < groupOrder.length; i++) {
                    for (let j = 0; j < comps.length; j++) {
                        if (groupOrder[i] === get(comps[j], "dealkey", null)) {
                            orderedComps.push(comps[j]);
                        }
                    }
                }
                dispatch(actions.setCurrentCompsGroup(orderedComps));
                dispatch(setGroupCheckedComps(orderedComps, true));
            } else {
                dispatch(actions.setCurrentCompsGroup(comps));
                dispatch(setGroupCheckedComps(comps, true));
            }
        } else {
            dispatch(actions.setCurrentCompsGroup(null));
        }
    };
};

const toggleModal = (isOpen) => {
    return (dispatch) => {
        dispatch(actions.toggleModal(isOpen));
    };
};

const setDealParty = (dealParty) => {
    return (dispatch) => {
        dispatch(actions.setDealParty(dealParty));
    };
};

const timeOnMarketInput = (input) => {
    return (dispatch) => {
        dispatch(actions.timeOnMarketInput(input));
    };
};

const toggleModalAndGetPhotos = (owners, nonIndustrialPropertyInSelection) => {
    return (dispatch) => {
        if (owners.length > 1) {
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "Please select only a single comp at a time to generate a report."
                )
            );
        } else if (owners.length < 1) {
            dispatch(utils.actions.snackbar(true, "error", "Please select a comp to generate a report."));
        } else if (nonIndustrialPropertyInSelection) {
            dispatch(utils.actions.snackbar(true, "error", "Please select an Industrial comp."));
        } else {
            // Currently retrieving all property photos for the given comp until photo approval flow implemented
            // Different key name depending on DAO or ES result
            const propertyKey = get(owners[0], "propertyKey") || get(owners[0], "property_id");

            // Fallback to marketing flyer photo
            // Different key name depending on DAO or ES result
            const compId = get(owners[0], "dealKey") || get(owners[0], "dealkey");

            dispatch(getMarketingFlyerPhoto(compId));
            dispatch(
                documentOperations.getImagesForOwner("PROPERTY", propertyKey, actions.getMarketingFlyerDropdownPhotos)
            );
            dispatch(toggleModal(true));
        }
    };
};

const getMarketingFlyerPhoto = (ownerId) => {
    return (dispatch) => {
        dispatch(utils.actions.somethingSaving(true, "gettingPhoto"));
        request(
            {
                url: constants.urls.get_document_by_media_asset_type(ownerId),
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                },
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.getMarketingFlyerPhoto(response.data));
                dispatch(utils.actions.somethingSaving(false, "gettingPhoto"));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(utils.actions.somethingSaving(false, "gettingPhoto"));
            });
    };
};

const resetPhoto = () => {
    return (dispatch) => {
        dispatch(actions.resetPhoto());
    };
};

const setMarketingFlyerPhoto = (photo) => {
    return (dispatch) => {
        dispatch(actions.setMarketingFlyerPhoto(photo));
    };
};

export default {
    saveComp,
    saveAndExit,
    handleDocumentWarning,
    handleSubmit,
    switchTab,
    getComps,
    addOutsideComp,
    submitOutsideOrAppraisalComp,
    getDeal,
    handleInput,
    addArrayElement,
    deleteArrayElement,
    handleCompInput,
    selectPropertyFromAutocomplete,
    searchComps,
    getCompHistoryByPropertyKey,
    submitComp,
    handlePreOutsideOrAppraisalCompSubmissionTasks,
    handlePreCompSubmissionTasks,
    checkboxClick,
    generateCompReport,
    selectAll,
    selectAllGroup,
    selectAllBrokerComps,
    updatePropertyForComp,
    handleDragEnd,
    updateCompsForSort,
    generateCompMetroReport,
    displayDeleteDialog,
    handleDeleteOutsideComp,
    clearAllComps,
    clearSearchResults,
    loadSearchResults,
    setCurrentCompsGroup,
    toggleModal,
    setDealParty,
    timeOnMarketInput,
    toggleModalAndGetPhotos,
    resetPhoto,
    setMarketingFlyerPhoto,
};
