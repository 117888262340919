import React from "react";
import { connect } from "react-redux";
import ReviewListings from "./ReviewListings";
import BrokerListings from "./BrokerListings";
import operations from "./ducks/operations";
import searchActions from "../search/ducks/actions";
import searchOperations from "../search/ducks/operations";
import searchConstants from "../search/constants";
import constants from "./constants";
import includes from "lodash/includes";
import queryString from "query-string";
import get from "lodash/get";
import toUpper from "lodash/toUpper";
import ReactGA from "react-ga4";

class ReviewListingsContainer extends React.Component {
    componentDidMount() {
        let url = this.props.location.search;
        let params = queryString.parse(url);
        if (get(params, "tab", null) !== null) {
            this.props.switchTab(null, get(params, "tab"));
        }
        this.props.getInitialListings(this.props.auth.userProfile.role, this.props.auth.userProfile.user);
        ReactGA.send({ hitType: "pageview", page: "/listings", title: "Listings" });
    }

    componentWillUnmount() {
        this.props.clearMyListings();
        this.props.handleOnSearchPage(false);
        this.props.handleOnGroupPage(false);
        this.props.switchTab(null, "broker_requested");
    }

    render() {
        if (
            includes(
                [
                    "broker",
                    "portlandBrokerAdmin",
                    "arizonaBrokerAdmin",
                    "evp",
                    "propertyManagement",
                    "debtEquity",
                    "accounting",
                    "general",
                    "officeManager",
                    "research",
                    "researchAdmin",
                    "transition",
                    "appraiser",
                ],
                this.props.role
            )
        ) {
            return <BrokerListings {...this.props} />;
        } else if (includes(["dataEntry", "dataAdmin"], this.props.role)) {
            return <ReviewListings {...this.props} />;
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        role: state.authReducer.userProfile.role,
        user: state.authReducer.userProfile.user,
        listings: state.listingReducer.listings,
        valid: state.authReducer.validFormValues,
        snackbarOpen: state.authReducer.snackbarOpen,
        listingTab: state.listingReducer.listingTab
            ? state.listingReducer.listingTab
            : constants.roles[state.authReducer.userProfile.role]
            ? constants.roles[state.authReducer.userProfile.role]["initialTab"]
            : "broker_requested",
        columnToSort: state.listingReducer.columnToSort,
        sortDirection: state.listingReducer.sortDirection,
        filterQuery: state.listingReducer.filterQuery,
        selected: state.listingReducer.selected,
        selectedListings: state.listingReducer.selectedListings,
        isLoading: state.utilsReducer.isLoading,
        loadingSearch: state.searchReducer.loadingSearch,
        elementsSaving: state.utilsReducer.elementsSaving,
        requestModal: state.listingReducer.requestModal,
        numListings: state.listingReducer.numListings,
        searchResults: state.searchReducer.autocompleteResults,
        autocompleteResults: state.utilsReducer.searchResults,
        errors: state.utilsReducer.errors,
        panelsExpanded: state.listingReducer.panelsExpanded,
        numComments: state.commentsReducer.comments.length,
        propertySearchFilters: state.propertyReducer.propertySearchFilters,
        searchCriteria: state.searchReducer.LISTINGS.searchCriteria,
        searchCleared: state.searchReducer.LISTINGS.searchCleared,
        searchRun: state.searchReducer.LISTINGS.searchRun,
        searchResponse: state.searchReducer.LISTINGS.searchResponse,
        searchCrumbs: state.searchReducer.searchCrumbs.LISTINGS,
        mapSearchSettings: state.searchReducer.LISTINGS.mapSearchSettings,
        loadedSearchCrumbs: state.searchReducer.loadedSearchCrumbs.LISTINGS,
        mapCrumbCleared: state.searchReducer.LISTINGS.mapCrumbCleared,
        searchPolygon: state.searchReducer.LISTINGS.polygon,
        savedMapCoords: state.searchReducer.LISTINGS.savedMapCoords,
        propertyTypeExpansionPanels: state.propertyReducer.propertyTypeExpansionPanels,
        marketFilterExpansionPanels: state.propertyReducer.marketFilterExpansionPanels,
        reportTitleModal: state.listingReducer.reportTitleModal,
        brokerInfoReport: state.listingReducer.brokerInfoReport,
        dataExport: get(state.searchReducer, searchConstants.LISTING_SEARCH_TYPE + ".dataExport", {}),
        viewSavedModal: state.searchReducer.viewSavedModal,
        savedSearchData: state.searchReducer.LISTINGS.savedSearches,
        savedGroupData: state.searchReducer.LISTINGS.savedGroups,
        nameSaveModal: state.searchReducer.nameSaveModal,
        savedSearchName: state.searchReducer.savedSearchName,
        saveSelectedOnly: state.searchReducer.LISTINGS.retainSelectedFromSave,
        selectedListingsGroup: state.listingReducer.selectedListingsGroup,
        listingCurrentGroup: state.searchReducer.LISTINGS.currentGroup,
        activeSelectedListings: state.listingReducer.activeSelectedListings,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchTab: (event, value) => {
            if (value === "listings_group") {
                dispatch(searchOperations.runUpdateCurrentGroup("LISTINGS"));
            }
            dispatch(operations.switchTab(value));
        },
        handleSort: (columnName) => {
            if (columnName === "dealProgress") {
                dispatch(operations.handleSort("progress"));
            } else {
                dispatch(operations.handleSort(columnName));
            }
        },
        handleGridSort: (columnName, tab) => {
            dispatch(searchOperations.sortGridTableData(columnName, tab, "LISTINGS"));
        },
        createNewDeal: () => {
            dispatch(operations.createNewDeal());
        },
        handleSelect: (dealKey) => {
            dispatch(operations.selectDeal(dealKey));
        },
        handleFilter: (query) => {
            dispatch(operations.filterListings(query));
        },
        getListingsByStatus: (tab) => {
            dispatch(operations.getListingsByStatus(tab));
        },
        toggleRequestModal: (spaceType, transactionType) => {
            dispatch(operations.toggleRequestModal(spaceType, transactionType));
        },
        updateSearchTerm: (value, searchType) => {
            dispatch(searchOperations.updateFilter("searchTerm", value, searchType));
        },
        handleSearchSubmit: () => {
            dispatch(searchOperations.executeSearch("property_modal"));
            dispatch(searchOperations.storeSearchRun(true, toUpper("property_modal")));
        },
        searchProperty: (term) => {
            dispatch(searchOperations.searchPropertyAutocomplete(term));
        },
        selectProperty: (property) => {
            dispatch(operations.selectPropertyFromAutocomplete(property));
        },
        removeSelectedProperty: () => {
            dispatch(operations.removeSelectedPropertyFromRequestModal());
        },
        updateListingRequest: (field, value) => {
            dispatch(operations.updateListingRequest(field, value));
        },
        requestListing: (propertyKey) => {
            dispatch(operations.requestListing(propertyKey));
        },
        requestListingNewProperty: () => {
            dispatch(operations.requestListingNewProperty());
        },
        handleDragEnd: (results, listings, tab) => {
            dispatch(operations.handleDragEnd(results, listings, tab));
        },
        handleStatusRadioClick: (field, checked) => {
            dispatch(searchOperations.handleStatusRadioClick(field, checked, "LISTINGS"));
        },
        handleSizeRadioClick: (field, checked) => {
            dispatch(searchOperations.handleSizeRadioClick(field, checked, "LISTINGS"));
        },
        handleListingTypeRadioClick: (field, checked) => {
            dispatch(searchOperations.handleListingTypeRadioClick(field, checked, "LISTINGS"));
        },
        clearSearchFilters: () => {
            dispatch(searchOperations.clearSearchFilters(searchConstants.PROPERTY_SEARCH_TYPE));
        },
        updateFilter: (field, value) => {
            dispatch(searchOperations.updateFilter(field, value, "LISTINGS"));
        },
        handleSelectAllPropertyType: (field, value) => {
            dispatch(searchOperations.selectAllPropertyType(field, value, "LISTINGS"));
        },
        handleSearchCleared: (cleared) => {
            dispatch(searchOperations.storeSearchCleared(cleared, "LISTINGS"));
            dispatch(operations.clearSearchResults());
        },
        handleSearchRun: (run) => {
            dispatch(searchOperations.storeSearchRun(run, "LISTINGS"));
        },
        clearFilters: () => {
            dispatch(searchOperations.clearFilters("LISTINGS"));
        },
        togglePropertyTypeExpansionPanel: (panelName) => {
            dispatch(searchOperations.togglePropertyTypeExpansionPanel(panelName));
        },
        closeDeal: (listing) => {
            dispatch(operations.closeDeal(listing));
        },
        getFlyer: (listingKey) => {
            dispatch(operations.getFlyer(listingKey));
        },
        navigateToRequestUpdate: (listingKey, redirectTo) => {
            dispatch(operations.navigateToRequestUpdate(listingKey, redirectTo));
        },
        handleListingsSearchSubmit: () => {
            dispatch(operations.searchListings("all_km_listings"));
        },
        loadMoreSearchResults: () => {
            dispatch(searchOperations.loadMoreResults("LISTINGS"));
        },
        getInitialListings: (role, user) => {
            dispatch(operations.getInitialListings(role, user));
        },
        toggleMarketFilterExpansionPanel: (panelName) => {
            dispatch(searchOperations.toggleMarketFilterExpansionPanel(panelName));
        },
        handleSelectMarket: (field, value, subMarketChecked) => {
            dispatch(searchOperations.updateMarketFilter(field, value, "LISTINGS"));
        },
        handleSelectSubmarket: (market, submarket, value, microMarketChecked) => {
            dispatch(searchOperations.updateSubmarketFilter(market, submarket, value, "LISTINGS"));
        },
        handleSelectMicromarket: (market, submarket, micromarket, value) => {
            dispatch(searchOperations.updateMicromarketFilter(market, submarket, micromarket, value, "LISTINGS"));
        },
        handleCheckboxClick: (key, type) => {
            dispatch(operations.checkboxClick(key, type));
        },
        handleGroupCheckboxClick: (key) => {
            dispatch(operations.groupCheckboxClick(key));
        },
        handleSelectAll: (value) => {
            dispatch(operations.selectAll(value));
        },
        handleSelectAllGroup: (value) => {
            dispatch(operations.selectAllGroup(value));
        },
        generateListingReport: (tab, includeBrokerInfo, reportTitle, reportStyle, mapView) => {
            dispatch(operations.generateListingReport(tab, includeBrokerInfo, reportTitle, reportStyle, mapView));
        },

        toggleReportTitleModal: (open) => {
            dispatch(operations.toggleReportTitleModal(open));
        },
        toggleIncludeBrokerInfo: (include) => {
            dispatch(operations.toggleIncludeBrokerInfo(include));
        },
        setReportTitle: (title) => {
            dispatch(operations.setReportTitle(title));
        },
        clearExport: () => {
            dispatch(searchOperations.clearDataExport(searchConstants.LISTING_SEARCH_TYPE));
        },
        handleDataExport: (dataSetType, listingTab) => {
            dispatch(searchOperations.handleDataExport(searchConstants.LISTING_SEARCH_TYPE, dataSetType, listingTab));
        },
        handleDeleteCrumb: (criteria, value) => {
            dispatch(searchOperations.deleteCrumb(criteria, value, "LISTINGS"));
        },
        handleClearAllCrumbs: () => {
            dispatch(searchOperations.clearCrumbsState());
        },
        updateSearchAreaFilter: (latLngs) => {
            dispatch(searchOperations.updateSearchAreaFilter(latLngs, searchConstants.LISTING_SEARCH_TYPE));
            dispatch(operations.searchListings());
        },
        updateSearchPolygon: (polygon) => {
            dispatch(searchOperations.updateSearchPolygon(polygon, "LISTINGS"));
        },
        clearMyListings: () => {
            dispatch(operations.clearMyListings());
        },
        saveMapSearchSettings: (settings) => {
            dispatch(searchOperations.saveMapSearchSettings(settings, "LISTINGS"));
        },
        toggleSavedSearchesModal: (open) => {
            dispatch(searchOperations.toggleSavedSearchesModal(open));
        },
        handleDeleteSavedSearch: (savedSearchId) => {
            dispatch(searchOperations.deleteSavedSearch("LISTINGS", savedSearchId));
        },
        handleOnSearchPage: (onSearch) => {
            dispatch(searchActions.onSearchPage(onSearch));
        },
        handleOnGroupPage: (onGroup) => {
            dispatch(searchActions.onGroupPage(onGroup));
        },
        toggleNameSearchModal: (open) => {
            dispatch(searchOperations.toggleNameSearchModal(open));
        },
        setSearchName: (name) => {
            dispatch(searchOperations.setSearchName(name));
        },
        setSearchSelectedToggle: (checked) => {
            dispatch(searchOperations.setSearchSelectedToggle(checked, "LISTINGS"));
        },
        handleSaveSearch: () => {
            dispatch(searchOperations.handleSaveSearch("LISTINGS"));
        },
        runSavedSearch: (searchKey) => {
            dispatch(searchOperations.runSavedSearch(searchKey, "LISTINGS"));
        },
        addSelectedToGroup: (listingTab) => {
            dispatch(searchOperations.addSelectedToGroup("LISTINGS", listingTab));
        },
        deleteSelectedFromGroup: () => {
            dispatch(searchOperations.deleteSelectedFromGroup("LISTINGS"));
        },
        clearCurrentGroup: () => {
            dispatch(searchOperations.clearCurrentGroup("LISTINGS"));
        },
        handleSaveGroup: () => {
            dispatch(searchOperations.handleSaveGroup("LISTINGS"));
        },
        runSavedGroup: (groupKey, groupOrder) => {
            dispatch(searchOperations.runSavedGroup(groupKey, "LISTINGS", groupOrder));
        },
        handleDeleteSavedGroup: (savedGroupId) => {
            dispatch(searchOperations.deleteSavedGroup(savedGroupId, "LISTINGS"));
        },
        selectAllActiveListings: (value) => {
            dispatch(operations.selectAllActiveListings(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewListingsContainer);
