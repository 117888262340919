import React from "react";
import { withStyles } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const KMSwitch = (props) => {
    const { value, label, classes, checked, disabled, onChange } = props;

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    classes={{
                        switchBase: classes.iOSSwitchBase,
                        bar: classes.iOSBar,
                        icon: classes.iOSIcon,
                        iconChecked: classes.iOSIconChecked,
                        checked: classes.iOSChecked,
                    }}
                />
            }
            label={label}
        />
    );
};

const styles = (theme) => ({
    iOSSwitchBase: {
        "&$iOSChecked": {
            color: theme.palette.common.white,
            "& + $iOSBar": {
                backgroundColor: "#2C99D2",
            },
        },
        transitionProperty: "transform",
        transitionDuration: "150ms",
        transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
        transitionDelay: "0ms",
    },
    iOSChecked: {
        transform: "translateX(15px)",
        "& + $iOSBar": {
            opacity: 1,
        },
    },
    iOSBar: {
        borderRadius: 13,
        width: 33,
        height: 18,
        marginTop: -10,
        marginLeft: -17,
        border: "solid 1px",
        borderColor: "#9e9e9e",
        backgroundColor: "#9e9e9e",
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    iOSIcon: {
        width: 18,
        height: 18,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
    },
});

export default withStyles(styles)(KMSwitch);
