const prod = {
    CLIENT_HOST_URL: "https://connect.kidder.com",
    HOST_URL: "https://services.kidder.com",
    LANDVISION_URL: "https://dc1.spatialstream.com/sso/saml/kiddermathews/cre",
    SSO_REDIRECT:
        "https://auth.kidder.com/oauth2/v1/authorize?client_id=0oax5cxoouaHOlaxf696&response_type=code&scope=openid&redirect_uri=https%3A%2F%2Fconnect.kidder.com%2Flogin&state=state-777htcvb0-m4m5-4962-ae1a-d0e2fd9db899",
    GA_ANALYTICS_TAG: "G-SNS52PM4HB",
    s3: {
        BUCKET: "connect.kidder.com",
    },
    profile: "prod",
};

const prod_with_profile_switch = {
    ...prod,
    profile: "prod-with-profile-switch",
};

const dev = {
    CLIENT_HOST_URL: "http://localhost:3000",
    HOST_URL: "http://localhost:8080",
    LANDVISION_URL: "https://dc1.spatialstream.com/sso/saml/testsso/lve",
    SSO_REDIRECT:
        "https://auth.kiddermathews.com/adfs/oauth2/authorize?resource=https://ndpdev.kiddermathews.com/login&client_id=e74ef628-f2d3-4295-8c71-6a7ab0bc88f1&redirect_uri=https://ndpdev.kiddermathews.com/login&response_type=code",
    GA_ANALYTICS_TAG: "G-TNE6G72W13",
    s3: {
        BUCKET: "YOUR_DEV_S3_UPLOADS_BUCKET_NAME",
    },
    profile: "dev",
};

const test = {
    CLIENT_HOST_URL: "https://test-connect.kidder.com",
    HOST_URL: "https://test-services.kidder.com",
    LANDVISION_URL: "https://dc1.spatialstream.com/sso/saml/testsso/lve",
    SSO_REDIRECT:
        "https://auth.kidder.com/oauth2/v1/authorize?client_id=0oax4da6eqTK3okhb696&response_type=code&scope=openid&redirect_uri=https%3A%2F%2Ftest-connect.kidder.com%2Flogin&state=state-777htcvb0-m3m3-4962-ae1a-d0e2fd9db492",
    GA_ANALYTICS_TAG: "G-TNE6G72W13",
    s3: {
        BUCKET: "YOUR_DEV_S3_UPLOADS_BUCKET_NAME",
    },
    profile: "test",
};

const test_with_profile_switch = {
    ...test,
    profile: "test-with-profile-switch",
};

const local_sso = {
    CLIENT_HOST_URL: "http://localhost:3000",
    HOST_URL: "https://localhost:8443",
    LANDVISION_URL: "https://dc1.spatialstream.com/sso/saml/testsso/lve",
    SSO_REDIRECT:
        "https://auth.kidder.com/oauth2/v1/authorize?client_id=0oax4da6eqTK3okhb696&response_type=code&scope=openid&redirect_uri=https%3A%2F%2Flocalhost%3A443%2Flogin&state=state-777htcvb0-m3m3-4962-ae1a-d0e2fd9db492",
    GA_ANALYTICS_TAG: "G-TNE6G72W13",
    s3: {
        BUCKET: "YOUR_DEV_S3_UPLOADS_BUCKET_NAME",
    },
    profile: "local-sso",
};

const local = {
    CLIENT_HOST_URL: "http://localhost:3000",
    HOST_URL: "http://localhost:8080",
    LANDVISION_URL: "https://dc1.spatialstream.com/sso/saml/testsso/lve",
    // LANDVISION_URL:'https://dc1.spatialstream.com/sso/saml/kiddermathews/cre', // uncomment to test Landvision locally
    SSO_REDIRECT:
        "https://auth.kiddermathews.com/adfs/oauth2/authorize?resource=https://ndpdev.kiddermathews.com/login&client_id=e74ef628-f2d3-4295-8c71-6a7ab0bc88f1&redirect_uri=https://ndpdev.kiddermathews.com/login&response_type=code",
    GA_ANALYTICS_TAG: "G-TNE6G72W13",
    s3: {
        BUCKET: "YOUR_DEV_S3_UPLOADS_BUCKET_NAME",
    },
    profile: "local",
};

const config =
    window._env_.REACT_APP_ENV === "prod"
        ? prod
        : window._env_.REACT_APP_ENV === "test"
        ? test
        : window._env_.REACT_APP_ENV === "dev"
        ? dev
        : window._env_.REACT_APP_ENV === "prod-with-profile-switch"
        ? prod_with_profile_switch
        : window._env_.REACT_APP_ENV === "test-with-profile-switch"
        ? test_with_profile_switch
        : window._env_.REACT_APP_ENV === "local-sso"
        ? local_sso
        : local;

// const config = process.env.REACT_APP_ENV === 'test'
//     ? test
//     : dev;

export default {
    // Add common config values here
    ...config,
};
