import React from "react";
import operations from "./ducks/operations";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "../../../../node_modules/@material-ui/icons/ExpandMore";
import AutosaveSuccess from "../../../../node_modules/@material-ui/icons/CheckCircle";
import Divider from "@material-ui/core/Divider";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import AdminToolbar from "./AdminToolbar";
import UserAccountDetail from "./UserAccountDetail";
import LicenseInformation from "./LicenseInformation";
import searchOperations from "../search/ducks/operations";
import dealOperations from "../deal/ducks/operations";
import DocumentDrop from "../documents/DocumentDropContainer";
import { push } from "connected-react-router";
import get from "lodash/get";
import includes from "lodash/includes";
import ExhibitAInformation from "./ExhibitAInformation";
import ReactGA from "react-ga4";

class AdminContainer extends React.Component {
    componentDidMount() {
        const id = this.props.match.params.id;
        if (id) {
            this.props.getUserAccount(id);
        }
        ReactGA.send({ hitType: "pageview", page: "/admin/user/*", title: `User Account Admin: ${id}` });
    }

    componentWillUnmount() {
        this.props.resetUserAccountState();
    }

    render() {
        if (includes(["dataAdmin"], get(this.props.userProfile, "role", null))) {
            return (
                <div>
                    <AdminToolbar {...this.props} />
                    <Grid container spacing={16}>
                        <Grid item xs={6}>
                            <ExpansionPanel defaultExpanded>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <AutosaveSuccess className="waterloo" /> General Information
                                </ExpansionPanelSummary>
                                <Divider />
                                <ExpansionPanelDetails>
                                    <UserAccountDetail {...this.props} />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        {/* All users can now add License info and docs */}
                        <Grid item xs={6}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} style={{ padding: "0 8px 8px 8px" }}>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <AutosaveSuccess className="waterloo" /> License Information
                                        </ExpansionPanelSummary>
                                        <Divider />
                                        <ExpansionPanelDetails>
                                            <LicenseInformation {...this.props} />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0 8px 8px 8px" }}>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <AutosaveSuccess className="waterloo" /> Exhibit A Information
                                        </ExpansionPanelSummary>
                                        <Divider />
                                        <ExpansionPanelDetails>
                                            <ExhibitAInformation {...this.props} />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                                {get(this.props.userAccount, "userAccountKey", null) != null && (
                                    <Grid item xs={12} style={{ padding: "8px" }}>
                                        <DocumentDrop
                                            entityKey={get(this.props.userAccount, "userAccountKey", null)}
                                            type="user"
                                            documentTypes="user"
                                            formDisabled={false}
                                            errors={this.props.errors.documents}
                                            restrictPreview={false}
                                            toggleTerminationDoc={this.props.toggleTerminationDoc}
                                            parentType={"USER"}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        valid: state.authReducer.validFormValues,
        snackbarOpen: state.authReducer.snackbarOpen,
        userAccount: state.adminReducer.userAccount,
        userProfile: state.authReducer.userProfile,
        errors: state.utilsReducer.errors ? state.utilsReducer.errors : {},
        confirmDialog: state.utilsReducer.confirmDialog,
        licenses: state.adminReducer.licenses,
        exhibitAs: state.adminReducer.exhibitAs,
        broker: state.adminReducer.broker,
        searchResults: state.searchReducer.autocompleteResults,
        filterQuery: state.adminReducer.filterQuery,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleInputChange: (field, value) => {
            dispatch(operations.handleInputChange(field, value));
        },
        handleAddArrayElement: (arrayPath) => {
            dispatch(operations.addArrayElement(arrayPath));
        },
        handleDeleteArrayElement: (arrayPath, index) => {
            dispatch(operations.deleteArrayElement(arrayPath, index));
        },
        handleUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.handleInputChange(arrayPath + "[" + index + "]." + field, value));
        },
        handleChangeAndValidate: (event) => {
            dispatch(operations.handleChangeAndValidate(event.target.id, event.target.value));
        },
        hideSnackbar: () => {
            dispatch(operations.hideSnackbar());
        },
        getUserAccount: (userAccountKey) => {
            dispatch(operations.getUserAccount(userAccountKey));
        },
        handleSaveAccountUpdates: () => {
            dispatch(operations.handleSaveAccountUpdates());
        },
        handleSearchUsers: (term) => {
            dispatch(searchOperations.searchUsers(term));
        },
        handleCancel: () => {
            dispatch(push("/admin/users"));
        },
        resetUserAccountState: () => {
            dispatch(operations.handleInputChange("userAccount", {}));
            dispatch(operations.handleInputChange("broker", {}));
            dispatch(operations.handleInputChange("licenses", {}));
            dispatch(operations.handleInputChange("exhibitAs", {}));
        },
        toggleTerminationDoc: (attached) => {
            dispatch(dealOperations.terminationDocUpload(attached));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer);
