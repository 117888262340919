export const user_role_display_name = {
    broker: "Broker",
    dataEntry: "CDT",
    dataAdmin: "Admin",
    portlandBrokerAdmin: "Portland Broker Admin",
    arizonaBrokerAdmin: "AZ Broker Admin",
    accounting: "Accounting",
    propertyManagement: "Asset Services",
    evp: "RP",
    general: "",
    officeManager: "Office Manager",
    researchAdmin: "Research Admin",
    research: "Research",
    transition: "Transition Specialist",
    anonymous: "Anonymous",
    debtEquity: "Debt & Equity",
    appraiser: "Appraiser",
    auditor: "Auditor",
};

export const user_role_type_display_name = {
    ROLE_ACCOUNTING: "Accounting",
    ROLE_ADMIN: "Admin",
    ROLE_ARIZONA_BROKER_ADMIN: "Arizona Broker Admin",
    ROLE_APPRAISER: "Appraiser",
    ROLE_BROKER: "Broker",
    ROLE_PROPERTY_DATA_ENTRY: "CDT",
    ROLE_DEBT_EQUITY: "Debt & Equity",
    ROLE_GENERAL_USER: "General User",
    ROLE_OFFICE_MANAGER: "Office Manager",
    ROLE_PORTLAND_ADMIN: "Portland Broker Admin",
    ROLE_PROPERTY_MANAGEMENT: "Asset Services",
    ROLE_RESEARCH_ADMIN: "Research Admin",
    ROLE_RESEARCH: "Research",
    ROLE_EVP: "RP",
    ROLE_TRANSITION_SPECIALIST: "Transition Specialist",
};

export const all_user_types = [
    "Broker",
    "CDT",
    "Admin",
    "Portland Broker Admin",
    "Accounting",
    "Asset Services",
    "RP",
    "General User",
    "Officer Manager",
    "Research Admin",
    "Research",
    "Transition Specialist",
    "Debt & Equity",
    "Arizona Broker Admin",
    "Office Manager",
    "Appraiser",
    "Auditor",
];
