import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Comments from "@material-ui/icons/Comment";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { DropdownButton, SplitButton } from "react-bootstrap";
import { red, amber } from "@material-ui/core/colors";
import constants from "./constants";
import get from "lodash/get";
import includes from "lodash/includes";
import { SaveAndExit } from "../../../common/js/icons";

const styles = (theme) => ({
    pageToolbar: {
        padding: "10px 0px",
        backgroundColor: "#fafafa",
        position: "sticky",
        top: "62px",
        zIndex: 1,
    },
    leftIcon: {},
    progress: {
        paddingRight: "100px",
        paddingLeft: "100px",
    },
    progressDiv: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
    },
    warning: {
        backgroundColor: amber[700],
    },
    deleteButton: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800],
        },
    },
});

const PropertyToolbar = (props) => {
    const {
        property,
        userProfile,
        classes,
        numComments,
        handleSubmit,
        handleApprovePropertyRequestAsDataEntry,
        toggleComments,
        confirmDialog,
        toggleConfirm,
        handleSave,
        handleSaveAndExit,
        disableInputs,
        elementsSaving,
        handleChooseDisplayDeleteDialog,
        displayDeleteDialog,
        handleDeleteProperty,
    } = props;

    const submitActions = {
        broker: {
            Draft: {
                buttonText: "Submit",
                submit: (callback) => {
                    handleSubmit(true, null);
                },
                variant: "contained",
            },
            "Broker Action Required": {
                buttonText: "Submit",
                submit: (callback) => {
                    handleSubmit(true, null);
                },
                variant: "contained",
            },
        },
        dataEntry: {
            Requested: {
                buttonText: "Approve",
                submit: () => {
                    handleApprovePropertyRequestAsDataEntry(false, constants.urls.reassign_comp_to_broker);
                }, //change to true
                variant: "contained",
            },
            "Update Requested": {
                buttonText: "Update",
                submit: () => {
                    handleApprovePropertyRequestAsDataEntry(false, constants.urls.reassign_comp_to_broker);
                }, //change to true
                variant: "contained",
            },
        },
        dataAdmin: {
            Requested: {
                buttonText: "Approve",
                submit: () => {
                    handleApprovePropertyRequestAsDataEntry(false, constants.urls.reassign_comp_to_broker);
                }, //change to true
                variant: "contained",
            },
            "Update Requested": {
                buttonText: "Update",
                submit: () => {
                    handleApprovePropertyRequestAsDataEntry(false, constants.urls.reassign_comp_to_broker);
                }, //change to true
                variant: "contained",
            },
        },
    };

    const handleCancel = () => {
        toggleConfirm(null);
    };
    const mainAction = get(
        submitActions,
        `${userProfile.role}.${get(property, "propertyAttributes.propertyStatus", "")}`,
        null
    );
    const confirm = (url) => {};

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
            <Grid item>
                <h2 className="page-title">Property Request Form</h2>
            </Grid>

            <Grid item>
                <Grid container alignItems="center" spacing={0}>
                    {includes(
                        ["dataAdmin", "dataEntry", "general", "officeManager", "transition", "appraiser"],
                        userProfile.role
                    ) ? (
                        <Grid item>
                            <Button
                                variant="outlined"
                                className={classes.deleteButton}
                                onClick={() => handleChooseDisplayDeleteDialog(true, property.propertyKey)}
                            >
                                Delete
                            </Button>
                        </Grid>
                    ) : (
                        ""
                    )}
                    <Grid item className={classes.leftIcon}>
                        <Button disabled={Boolean(!property.propertyKey)} onClick={toggleComments}>
                            <Comments /> Comments ({numComments})
                        </Button>
                    </Grid>
                    <Grid item>
                        {elementsSaving.propertySaveAndExit ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : (
                            <SplitButton title="Save" bsStyle="default" disabled={disableInputs} onClick={handleSave}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSaveAndExit}
                                    disabled={disableInputs}
                                >
                                    <SaveAndExit />
                                    Save & Exit
                                </Button>
                            </SplitButton>
                        )}
                        {elementsSaving.propertySubmit ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : mainAction && mainAction.options ? (
                            <DropdownButton title={get(mainAction, "buttonText")}>
                                {Object.keys(get(mainAction, "options", {})).map((x, i) => {
                                    let url = get(mainAction, `options.${x}`);
                                    console.log(url);
                                    return <MenuItem onClick={() => confirm(url)}>{x}</MenuItem>;
                                })}
                            </DropdownButton>
                        ) : mainAction !== null ? (
                            <Button variant="contained" color="secondary" onClick={() => toggleConfirm()}>
                                {mainAction.buttonText}
                            </Button>
                        ) : (
                            property && (
                                <Button disabled variant="contained">
                                    {get(property, "propertyAttributes.propertyStatus", "")}
                                </Button>
                            )
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={confirmDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Continue?</DialogTitle>
                <DialogContent>Are you sure you want to submit this status change?</DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => mainAction["submit"](confirmDialog.onSubmit)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={displayDeleteDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Delete Property Request?</DialogTitle>
                <DialogContent>
                    Are you sure you want to permanently delete this property request? The property request cannot be
                    recovered once it has been deleted.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleChooseDisplayDeleteDialog(false, property.propertyKey)}
                        color="primary"
                    >
                        No
                    </Button>
                    <Button onClick={() => handleDeleteProperty(property.propertyKey)} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default withStyles(styles)(PropertyToolbar);
