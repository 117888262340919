import configs from "../../../config";
import get from "lodash/get";
import each from "lodash/each";

const HOST = configs.HOST_URL + "/";
const DEBT_EQUITY_URL = HOST + "debt-equity/";

const urls = {
    get_transactions_for_user: (user) => {
        return DEBT_EQUITY_URL + "transactions/user/" + user;
    },
    get_open_transactions_for_user: (user) => {
        return DEBT_EQUITY_URL + "transactions/user/" + user + "/open";
    },
    get_closed_transactions_for_user: (user) => {
        return DEBT_EQUITY_URL + "transactions/user/" + user + "/closed";
    },
    get_transaction: (transactionId) => {
        return DEBT_EQUITY_URL + "transactions/" + transactionId;
    },
    delete_transaction: (transactionId) => {
        return DEBT_EQUITY_URL + "transactions/" + transactionId;
    },
    create_or_save_transaction: () => {
        return DEBT_EQUITY_URL + "transactions";
    },
};

const queryGroups = {
    transactions: [
        "createdBy.person.firstName",
        "createdBy.person.lastName",
        ["createdBy.person.firstName", null, "createdBy.person.lastName"],
        "property.propertyAttributes.propertyName",
        // "status",
        ["property.primaryAddress.address1", null, "property.primaryAddress.address2"],
        "property.primaryAddress.city.city",
        "property.primaryAddress.city.state.stateName",
        "property.primaryAddress.city.state.stateCode",
    ],
};

const filterByQuery = (data, group, query) => {
    let included = false;
    each(queryGroups[group], (column) => {
        if (Array.isArray(column)) {
            let complexQuery = column
                .map((part) => {
                    return get(data, part, " ");
                })
                .join("");
            if (complexQuery.toString().toLowerCase().includes(query)) {
                included = true;
            }
        } else {
            if (get(data, column)) {
                if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                    included = true;
                }
            }
        }
    });
    return included;
};

export default {
    urls,
    filterByQuery,
};
