import each from "lodash/each";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import utils from "../utils/operations";
import { countCommaSeparated } from "../utils/operations";

const viewHeaders = {
    broker_comps: [
        {
            name: "Property",
            prop: "photo",
            type: "plaintext",
            extraStyles: {
                width: "20%",
            },
        },
        {
            name: "Address",
            prop: "property",
            type: "plaintext",
            extraStyles: {
                width: "15%",
            },
        },
        {
            name: "Brokers",
            prop: "brokers",
            type: "aggregate",
            extraStyles: {
                width: "6%",
            },
            transform: (brokers) => {
                return countCommaSeparated(brokers).length > 1
                    ? countCommaSeparated(brokers).length + " Brokers"
                    : brokers;
            },
        },
        {
            name: "Prop Type",
            prop: "propertyType",
            type: "plaintext",
            extraStyles: {
                width: "8%",
            },
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
            extraStyles: {
                width: "8%",
            },
        },
        {
            name: "Date Signed",
            prop: "transactionDate",
            type: "plaintext",
            transform: utils.formatDate("MM/DD/YYYY"),
        },
        {
            name: "Rate/Price",
            prop: "rate_per_square_foot_per_month",
            type: "plaintext",
            extraStyles: {
                width: "8%",
            },
        },
        {
            name: "SQFT",
            prop: "calculatedValueC",
            type: "plaintext",
            extraStyles: {
                width: "9%",
            },
            transform: (value) => {
                return value ? utils.toSqft(value) : "";
            },
        },
        {
            name: "Links",
            prop: "",
            type: "plaintext",
        },
    ],

    all_km_comps_with_map: [
        {
            name: "Property",
            prop: "photo",
            type: "plaintext",
            extraStyles: {
                width: "20%",
            },
        },
        {
            name: "Address",
            prop: "property",
            type: "plaintext",
            extraStyles: {
                width: "20%",
            },
        },
        {
            name: "SQFT",
            prop: "total_transaction_size_sf",
            type: "plaintext",
            transform: (value) => {
                return value ? utils.toSqft(value) : "";
            },
            extraStyles: {
                width: "20%",
            },
        },
        {
            name: "Rate/Price",
            prop: "rate_per_square_foot_per_month",
            type: "plaintext",
            extraStyles: {
                width: "8%",
            },
        },
    ],

    all_km_comps: [
        {
            name: "Property",
            prop: "photo",
            type: "plaintext",
        },
        {
            name: "Address",
            prop: "property",
            type: "plaintext",
            extraStyles: {
                width: "22%",
            },
        },
        {
            name: "Brokers",
            prop: "brokers",
            type: "aggregate",
            transform: (brokers) => {
                return countCommaSeparated(brokers).length > 1
                    ? countCommaSeparated(brokers).length + " Brokers"
                    : brokers;
            },
            extraStyles: {
                width: "10%",
            },
        },
        {
            name: "Prop Type",
            prop: "property_type",
            type: "plaintext",
            transform: (property_type) => {
                return capitalize(property_type);
            },
            extraStyles: {
                width: "8%",
            },
        },
        {
            name: "Deal Type",
            prop: "transaction_type",
            type: "plaintext",
            transform: (type) => {
                return utils.parseTransactionType(type);
            },
            extraStyles: {
                width: "8%",
            },
        },
        {
            name: "Date Signed",
            prop: "transaction_date",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Rate/Price",
            prop: "rate_per_square_foot_per_month",
            type: "plaintext",
            extraStyles: {
                width: "8%",
            },
        },
        {
            name: "SQFT",
            prop: "total_transaction_size_sf",
            type: "plaintext",
            transform: (value) => {
                return value ? utils.toSqft(value) : "";
            },
            extraStyles: {
                width: "9%",
            },
        },
        {
            name: "Links",
            prop: "",
            type: "plaintext",
        },
    ],

    comp_history_for_property: [
        {
            name: "Address",
            prop: "property",
            type: "plaintext",
        },
        {
            name: "Date Signed",
            prop: "transactionDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Approved Date",
            prop: "approvedDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
            transform: (value) => {
                return capitalize(value);
            },
        },
        {
            name: "Space Type",
            prop: "propertyType",
            type: "plaintext",
            transform: (value) => {
                return capitalize(value);
            },
        },
    ],
};

const defaultButtonActions = {
    Draft: {
        text: "View",
        variant: "outlined",
        statusToDisplay: "",
    },
    "Needs Review": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Awaiting Admin Action",
    },
    "In Review": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "In Review",
    },
    "Final Review": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "In Review",
    },
    Closed: {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Closed",
    },
    "Outside Draft": {
        text: "Finish",
        variant: "contained",
        statusToDisplay: "Draft",
    },
    Outside: {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Closed",
    },
    "Broker Action Required": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Action Required",
    },
    "Awaiting Comp Submission": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Awaiting Comp",
    },
    "In Progress": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Awaiting Comp",
    },
    "Appraisal Draft": {
        text: "Finish",
        variant: "contained",
        statusToDisplay: "Draft",
    },
    Appraisal: {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Closed",
    },
};
const brokerButtonActions = {
    Draft: {
        text: "Edit",
        variant: "contained",
        statusToDisplay: "",
    },
    "Needs Review": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Awaiting Admin Action",
    },
    "In Review": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "In Review",
    },
    "Final Review": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "In Review",
    },
    Closed: {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Closed",
    },
    "Broker Action Required": {
        text: "Edit",
        variant: "contained",
        statusToDisplay: "Action Required",
    },
    "Awaiting Comp Submission": {
        text: "Edit",
        variant: "contained",
        statusToDisplay: "Awaiting Comp",
    },
    "In Progress": {},
    "Outside Draft": {
        text: "Finish",
        variant: "contained",
    },
    Outside: {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Closed",
    },
};
export const buttonActions = {
    broker: {
        ...brokerButtonActions,
    },
    general: {
        ...brokerButtonActions,
    },
    officeManager: {
        ...brokerButtonActions,
    },
    appraiser: {
        ...defaultButtonActions,
    },
    debtEquity: {
        ...brokerButtonActions,
    },
    evp: {
        ...brokerButtonActions,
    },
    portlandBrokerAdmin: {
        ...brokerButtonActions,
        "Portland Review Required": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "Portland Review Required",
        },
        "Portland Termination Review Required": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "Termination Review Required",
        },
    },
    arizonaBrokerAdmin: {
        ...brokerButtonActions,
        "AZ Review Required": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "AZ Review Required",
        },
        "AZ Termination Review Required": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "Termination Review Required",
        },
    },
    dataEntry: {
        Draft: {},
        "Needs Review": {
            text: "Start",
            variant: "contained",
            statusToDisplay: "Needs Review",
        },
        "In Review": {},
        Closed: {},
        "Broker Action Required": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "Awaiting Broker Action",
        },
        "Admin Action Required": {
            text: "Review",
            variant: "contained",
            statusToDisplay: "Action Required",
        },
        "In Progress": {},
    },
    dataAdmin: {
        Draft: {},
        "Needs Review": {
            text: "Start",
            variant: "contained",
            statusToDisplay: "Needs Review",
        },
        "In Review": {},
        Closed: {},
        "Broker Action Required": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "Awaiting Broker Action",
        },
        "Admin Action Required": {
            text: "Review",
            variant: "contained",
            statusToDisplay: "Action Required",
        },
        "In Progress": {},
    },
};

const columnsToQueryFilter = [
    "address1",
    "brokers",
    "calculatedValueA",
    "calculatedValueB",
    "calculatedValueC",
    "state",
    "listingName",
    "city",
    "zip",
    "sfAvail",
    "propertyType",
    "transactionType",
    "transactionDate",
    "ratePerSquareFootPerMonth",
    "ratePerSquareFootPerYear",
    "brokers",
    "salePrice",
];

const filterByQuery = (data, columnsToQuery, query) => {
    let included = false;
    each(columnsToQuery, (column) => {
        if (get(data, column)) {
            if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                included = true;
            }
        }
    });
    return included;
};

export const getButtonActionPropertiesForRoleAndStatus = (role, status) => {
    let buttonProperties = null;
    if (role && status) {
        if (buttonActions[role] && buttonActions[role][status]) {
            buttonProperties = buttonActions[role][status];
        } else {
            buttonProperties = defaultButtonActions[status];
        }
    } else {
        buttonProperties = defaultButtonActions["Closed"];
    }
    return buttonProperties;
};
export default {
    viewHeaders,
    columnsToQueryFilter,
    filterByQuery,
    getButtonActionPropertiesForRoleAndStatus,
};
