import React, { Component } from "react";
import { connect } from "react-redux";
import Comments from "./Comments";
import operations from "./ducks/operations";

class CommentsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
    }

    componentDidMount() {
        if (this.props.parentRecordType && this.props.parentRecordId) {
            this.props.handleGetComments(this.props.parentRecordType, this.props.parentRecordId);
        }
    }

    render() {
        return (
            <div>
                <Comments {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.commentsReducer.commentsDrawerOpen,
        comments: state.commentsReducer.comments,
        commentDraft: state.commentsReducer.commentDraft,
        requestUpdateState: state.commentsReducer.requestUpdateState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetComments: (parentRecordType, parentRecordId) => {
            dispatch(operations.getComments(parentRecordType, parentRecordId));
        },
        handleSubmitComment: (parentRecordType, parentRecordId) => {
            dispatch(operations.submitComment(parentRecordType, parentRecordId));
        },
        handleClearComment: () => {
            dispatch(operations.clearComment());
            dispatch(operations.closeCommentDrawer());
        },

        handleChange: (event) => {
            dispatch(operations.handleChange(event.target.id, event.target.value));
        },
        toggleComments: () => {
            dispatch(operations.toggleComments());
        },
        handleRequestUpdate: (parentRecordType, parentRecordId) => {
            dispatch(operations.requestUpdate(parentRecordType, parentRecordId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsContainer);
