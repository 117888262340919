import React from "react";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class ParkingInfoForm extends React.Component {
    handleParkingInfoChange = (event) => {
        this.props.onChange(event.target.id, event.target.value);
    };

    render() {
        const { data, errors, disableInputs, useType, context } = this.props;

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="parkingTotal"
                            label="TOTAL PARKING SPACE COUNT"
                            placeholder="Enter count"
                            errorText="(ex. 98)"
                            error={errors.parkingTotal}
                            value={data.parkingTotal}
                            onChange={this.handleParkingInfoChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                    {useType !== "Religious" && useType !== "Educational" && (
                        <Grid item xs={6}>
                            <BSInput
                                id="parkingRatio"
                                label="PARKING RATIO (/1,000 SF)"
                                placeholder="Enter parking ratio"
                                errorText="(ex. 98.01)"
                                error={errors.parkingRatio}
                                value={data.parkingRatio}
                                onChange={this.handleParkingInfoChange}
                                disabled={disableInputs}
                            />
                        </Grid>
                    )}
                </Grid>
                {useType !== "Religious" && useType !== "Educational" && (
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={6}>
                            <BSInput
                                id="surfaceParkingSpaceCount"
                                label="SURFACE PARKING COUNT"
                                errorText="(ex. 981)"
                                placeholder="Enter count"
                                error={errors.surfaceParkingSpaceCount}
                                value={data.surfaceParkingSpaceCount}
                                onChange={this.handleParkingInfoChange}
                                disabled={disableInputs}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="parkingGarageSpaceCount"
                                label="PARKING GARAGE SPACE COUNT"
                                errorText="(ex. 981)"
                                placeholder="Enter count"
                                error={errors.parkingGarageSpaceCount}
                                value={data.parkingGarageSpaceCount}
                                onChange={this.handleParkingInfoChange}
                                disabled={disableInputs}
                            />
                        </Grid>
                    </Grid>
                )}
                {useType !== "Religious" && useType !== "Educational" && (
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={6}>
                            <BSInput
                                id="parkingReservedSpaceCount"
                                label="RESERVED SPACE COUNT"
                                errorText="(ex. 98)"
                                placeholder="Enter count"
                                error={errors.parkingReservedSpaceCount}
                                value={data.parkingReservedSpaceCount}
                                onChange={this.handleParkingInfoChange}
                                disabled={disableInputs}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="carportParkingSpaceCount"
                                label="CARPORT PARKING COUNT"
                                errorText="(ex. 98)"
                                placeholder="Enter count"
                                error={errors.carportParkingSpaceCount}
                                value={data.carportParkingSpaceCount}
                                onChange={this.handleParkingInfoChange}
                                disabled={disableInputs}
                            />
                        </Grid>
                    </Grid>
                )}
                {context === "listing" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="parkingNotes"
                                    label="Parking Notes"
                                    placeholder="Enter parking notes"
                                    error={errors.parkingNotes}
                                    value={data.parkingNotes}
                                    onChange={this.handleParkingInfoChange}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
                {useType !== "Religious" && useType !== "Educational" && (
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.valetParkingFlg ? true : false}
                                            onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                            value="valetParkingFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Valet Parking"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.parkingVehicleElevatorFlg ? true : false}
                                            onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                            value="parkingVehicleElevatorFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Parking Vehicle Elevator"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.parkingVehicleChargingStationsFlg ? true : false}
                                            onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                            value="parkingVehicleChargingStationsFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Electric Vehicle Charging Stations"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
}

export default ParkingInfoForm;
