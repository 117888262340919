import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const LeftChevron = (props) => {
    const { fill } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill={fill} d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
};

export const RightChevron = (props) => {
    const { fill } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill={fill} d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
};

export const UpChevron = (props) => {
    const { fill } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path fill={fill} d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
};

export const DownChevron = (props) => {
    const { fill } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path fill={fill} d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
            <path fill="none" d="M0 0h24v24H0V0z" />
        </svg>
    );
};

export const Add = (props) => {
    const { fill } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill={fill} d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
};

export const Comments = (props) => {
    const { fill } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
                d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"
                fill={fill}
            />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
};

export const Doc = (props) => {
    const { fill } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"
                fill={fill}
            />
        </svg>
    );
};

export const AutosaveSuccess = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
);

export function Search(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                <path d="M0 0h24v24H0z" fill="none" />
            </svg>
        </SvgIcon>
    );
}

export const Subdirectory = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M19 15l-6 6-1.42-1.42L15.17 16H4V4h2v10h9.17l-3.59-3.58L13 9l6 6z" />
    </svg>
);

export const CloudUpload = (props) => {
    const { fill } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                fill={fill}
            />
        </svg>
    );
};

export const StepperCircle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="8" fill="#9e9e9e" />
    </svg>
);

export const StepperCircleComplete = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="8" fill="#2C99D2" />
    </svg>
);

export const StepperIcon = (props) => {
    const { text, fill } = props;
    return (
        <svg
            fill={fill}
            display="block"
            focusable="false"
            viewBox="0 0 24 24"
            width="1em"
            height="1em"
            aria-hidden="true"
            font-size="24px"
            role="presentation"
        >
            <circle cx="12" cy="12" r="12"></circle>
            <text fill="#fff" font-size="0.75rem" class="j252" font-family="Arial" x="12" y="16" text-anchor="middle">
                {text}
            </text>
        </svg>
    );
};

export const StepperIconComplete = () => (
    <svg
        fill="#2196f3"
        display="block"
        focusable="false"
        viewBox="0 0 24 24"
        width="1em"
        height="1em"
        aria-hidden="true"
        font-size="24px"
        role="presentation"
    >
        <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2 17l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z"></path>
    </svg>
);

export const Play = (props) => {
    const { fill } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M8 5v14l11-7z" fill={fill} />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
};

export const Close = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const ExportIcon = () => (
    <svg width="17px" height="16px" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" className="exportIcon">
        <g id="03-Corporate-Data-Team-****" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="C-/-Properties-/-All" transform="translate(-1183.000000, -150.000000)" fill="#2C99D2">
                <g id="Group" transform="translate(220.000000, 132.000000)">
                    <g id="Group-3" transform="translate(951.000000, 8.000000)">
                        <g id="noun_excel_1194546" transform="translate(12.000000, 10.000000)">
                            <g id="Shape">
                                <path
                                    d="M9.58664572,13.6059544 L9.58664572,0.103707777 L8.49644933,0.103707777 C5.69633936,0.609599372 2.90381775,1.0775491 0.093589945,1.56320503 L0.093589945,14.3344383 C2.85828751,14.8226237 5.65839749,15.3108091 8.44080126,15.8167007 L9.57905734,15.8167007 L9.57905734,13.6059544 L9.58664572,13.6059544 Z M5.46868814,10.803315 C5.11991838,10.0575469 4.81419541,9.29239496 4.55302435,8.51162608 C4.30007855,9.25275727 3.95101335,9.95088767 3.6651846,10.6768421 C3.26553024,10.6768421 2.86587588,10.654077 2.46622152,10.6389002 C2.93417125,9.72070699 3.38694423,8.79492537 3.8700707,7.8843205 C3.46029851,6.94336214 3.00752553,6.02263943 2.58510605,5.08926944 L3.79418696,5.02097408 C4.06483896,5.73428123 4.363315,6.44 4.58843676,7.17101335 C4.84138256,6.39446976 5.1879183,5.65333857 5.49651218,4.91473684 C5.90965698,4.88606965 6.32364493,4.86077507 6.73847604,4.8388531 C6.25282011,5.83714585 5.76294842,6.83796805 5.26886096,7.84131972 C5.77475255,8.85310291 6.28064415,9.87753339 6.78653574,10.8994344 C6.34641005,10.864022 5.90881383,10.836198 5.46868814,10.803315 Z"
                                    fill-rule="nonzero"
                                ></path>
                                <path
                                    d="M10.0470071,1.85409269 L10.0470071,2.8228751 L11.9820424,2.8228751 L11.9820424,4.25201885 L10.0470071,4.25201885 L10.0470071,5.03615082 L11.9820424,5.03615082 L11.9820424,6.46529458 L10.0470071,6.46529458 L10.0470071,7.24942655 L11.9820424,7.24942655 L11.9820424,8.67604085 L10.0470071,8.67604085 L10.0470071,9.46017282 L11.9820424,9.46017282 L11.9820424,10.8766693 L10.0470071,10.8766693 L10.0470071,11.7063315 L11.9820424,11.7063315 L11.9820424,13.089945 L10.0470071,13.089945 L10.0470071,14.0587274 L16.729835,14.0587274 L16.729835,1.85409269 L10.0470071,1.85409269 Z M15.255161,13.1000628 L12.4424038,13.1000628 L12.4424038,11.7164493 L15.255161,11.7164493 L15.255161,13.1000628 Z M15.255161,10.8867871 L12.4424038,10.8867871 L12.4424038,9.45764336 L15.255161,9.45764336 L15.255161,10.8867871 Z M15.255161,8.67351139 L12.4424038,8.67351139 L12.4424038,7.24689709 L15.255161,7.24689709 L15.255161,8.67351139 Z M15.255161,6.46023566 L12.4424038,6.46023566 L12.4424038,5.03362137 L15.255161,5.03362137 L15.255161,6.46023566 Z M15.255161,4.24695994 L12.4424038,4.24695994 L12.4424038,2.8228751 L15.255161,2.8228751 L15.255161,4.24695994 Z"
                                    fill-rule="nonzero"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const UncheckedBox = () => (
    <svg width="24" height="24">
        <rect
            ry="2"
            rx="2"
            stroke="#dddddd"
            id="svg_2"
            height="16.19604"
            width="16.19604"
            y="3.74558"
            x="30.0891"
            opacity="0.85"
            stroke-linecap="null"
            stroke-linejoin="round"
            fill="none"
        />
    </svg>
);

export const Sorty = (props) => {
    const { fill } = props;
    return (
        <svg width="6px" height="12px" viewBox="0 0 6 12">
            <defs>
                <path
                    d="M8,3.88666667 L10.1133333,6 L11.0533333,5.06 L8,2 L4.94,5.06 L5.88666667,6 L8,3.88666667 L8,3.88666667 Z M8,12.1133333 L5.88666667,10 L4.94666667,10.94 L8,14 L11.06,10.94 L10.1133333,10 L8,12.1133333 L8,12.1133333 Z"
                    id="path-1"
                ></path>
            </defs>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Artboard" transform="translate(-64.000000, -61.000000)">
                    <g id="sorty-carets" transform="translate(59.000000, 59.000000)">
                        <mask id="mask-2" fill="white">
                            <use href="#path-1"></use>
                        </mask>
                        <g id="carets" mask="url(#mask-2)" fill={fill ? fill : "#9e9e9e"} fill-rule="evenodd">
                            <g transform="translate(-8.666667, -8.666667)" id="color">
                                <polygon points="0 0 33 0 33 33 0 33"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const ListingCircle = (props) => {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" id="skyline-circle">
            <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <circle id="Oval" fill="#9E9E9E" cx="12" cy="12" r="12"></circle>
                <g id="baseline-location_city-24px-(1)" transform="translate(4.000000, 3.000000)">
                    <path
                        d="M10,8.15789474 L10,4.05263158 L8,2 L6,4.05263158 L6,5.42105263 L2,5.42105263 L2,15 L14,15 L14,8.15789474 L10,8.15789474 Z M4.66666667,13.6315789 L3.33333333,13.6315789 L3.33333333,12.2631579 L4.66666667,12.2631579 L4.66666667,13.6315789 Z M4.66666667,10.8947368 L3.33333333,10.8947368 L3.33333333,9.52631579 L4.66666667,9.52631579 L4.66666667,10.8947368 Z M4.66666667,8.15789474 L3.33333333,8.15789474 L3.33333333,6.78947368 L4.66666667,6.78947368 L4.66666667,8.15789474 Z M8.66666667,13.6315789 L7.33333333,13.6315789 L7.33333333,12.2631579 L8.66666667,12.2631579 L8.66666667,13.6315789 Z M8.66666667,10.8947368 L7.33333333,10.8947368 L7.33333333,9.52631579 L8.66666667,9.52631579 L8.66666667,10.8947368 Z M8.66666667,8.15789474 L7.33333333,8.15789474 L7.33333333,6.78947368 L8.66666667,6.78947368 L8.66666667,8.15789474 Z M8.66666667,5.42105263 L7.33333333,5.42105263 L7.33333333,4.05263158 L8.66666667,4.05263158 L8.66666667,5.42105263 Z M12.6666667,13.6315789 L11.3333333,13.6315789 L11.3333333,12.2631579 L12.6666667,12.2631579 L12.6666667,13.6315789 Z M12.6666667,10.8947368 L11.3333333,10.8947368 L11.3333333,9.52631579 L12.6666667,9.52631579 L12.6666667,10.8947368 Z"
                        id="Shape"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                    ></path>
                    <polygon id="Path" points="0 0 16 0 16 16 0 16"></polygon>
                </g>
            </g>
        </svg>
    );
};

export const Landvision = (props) => {
    const { fill } = props;
    return (
        <svg width="24" height="24" viewBox="0 0 24 24">
            <path
                fill={fill}
                d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM15 19l-6-2.11V5l6 2.11V19z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
};

export const Address = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
        </svg>
    );
};

export const Download = (props) => {
    return (
        <svg width="14px" height="17px" viewBox="0 0 14 17" version="1.1">
            <title>Download</title>
            <defs>
                <path
                    d="M14,6 L10,6 L10,0 L4,0 L4,6 L0,6 L7,13 L14,6 L14,6 Z M0,15 L0,17 L14,17 L14,15 L0,15 L0,15 Z"
                    id="path-2"
                ></path>
            </defs>
            <g id="03-Corporate-Data-Team" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="02-Icons/ic_file_download">
                    <mask id="mask-2" fill="white">
                        <use href="#path-2"></use>
                    </mask>
                    <use id="Mask" fill="#000000" href="#path-2"></use>
                </g>
            </g>
        </svg>
    );
};

export const List = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"
                fill="#2C99D2"
            />
        </svg>
    );
};

export const Map = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M14 6l-3.75 5 2.85 3.8-1.6 1.2C9.81 13.75 7 10 7 10l-6 8h22L14 6z" fill="#2C99D2" />
        </svg>
    );
};

export const DrawMap = (props) => {
    return (
        <svg class="MuiSvgIcon-root jss561" focusable="false" width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="#2C99D2"
                d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            ></path>
        </svg>
    );
};

export const SaveAndExit = (props) => {
    return (
        <svg
            className="save-and-exit-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="2em"
            height="2em"
            viewBox="0 0 24 24"
        >
            <path
                fill="currentColor"
                d="m15.84 10.2l-1.01 1.01l-2.07-2.03l1.01-1.02c.2-.21.54-.22.78 0l1.29 1.25c.21.21.22.55 0 .79M8 13.91l4.17-4.19l2.07 2.08l-4.16 4.2H8zM13 2v2c4.39.54 7.5 4.53 6.96 8.92A8.014 8.014 0 0 1 13 19.88v2c5.5-.6 9.45-5.54 8.85-11.03C21.33 6.19 17.66 2.5 13 2m-2 0c-1.96.18-3.81.95-5.33 2.2L7.1 5.74c1.12-.9 2.47-1.48 3.9-1.68v-2M4.26 5.67A9.8 9.8 0 0 0 2.05 11h2c.19-1.42.75-2.77 1.64-3.9zM2.06 13c.2 1.96.97 3.81 2.21 5.33l1.42-1.43A8 8 0 0 1 4.06 13zm5 5.37l-1.39 1.37A10 10 0 0 0 11 22v-2a8 8 0 0 1-3.9-1.63z"
            />
        </svg>
    );
};
