import React from "react";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import KMCounter from "../../../common/js/components/KMCounter";
import { yes_no_options } from "../utils/constants";
import constants from "./constants";
import get from "lodash/get";
import { round } from "../utils/operations";

class BuildingForm extends React.Component {
    handleBuildingDetailChange = (e) => {
        this.props.onChange(e.target.id, e.target.value);
    };

    handleBuildingCounter = (field, value) => {
        this.props.onChange(field, value);
    };

    handleLotSizeAutoCalc = (field, value) => {
        switch (field) {
            case "lotAcres":
                const lotSf = value * 43560;
                this.props.onChange("lotAcres", value);
                this.props.onChange("lotSf", lotSf);
                break;
            case "lotSf":
                const lotAcres = round(value / 43560, 2);
                this.props.onChange("lotSf", value);
                this.props.onChange("lotAcres", lotAcres);
                break;
            default:
                break;
        }
    };

    render() {
        const { data, errors, disableInputs } = this.props;

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="buildingName"
                            label="BUILDING NAME"
                            placeholder="Enter building name"
                            error={errors.buildingName}
                            value={data.buildingName}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="businessParkName"
                            label="INDUSTRIAL PARK / OFFICE PARK / RETAIL CENTER BUILDING NAME"
                            placeholder="Enter industrial park, office park, or retail center building name"
                            error={errors.businessParkName}
                            value={data.businessParkName}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSSelect
                            id="bldgStatus"
                            label="BUILDING STATUS"
                            options={constants.optionsBuilder(constants.buildingStatuses)}
                            error={errors.bldgStatus}
                            value={data.bldgStatus}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSSelect
                            id="bldgClass"
                            label="BUILDING CLASS"
                            options={constants.optionsBuilder(constants.buildingClasses)}
                            error={errors.bldgClass}
                            value={data.bldgClass}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="yearBuilt"
                            label="YEAR BUILT"
                            placeholder="Enter year built"
                            errorText="ex. 1974"
                            error={errors.yearBuilt}
                            value={data.yearBuilt}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="yearRemodeled"
                            label="YEAR RENOVATED"
                            placeholder="Enter year renovated"
                            errorText="ex. 1974"
                            error={errors.yearRemodeled}
                            value={data.yearRemodeled}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="totalBldgSf"
                            label="Total Building Sf"
                            placeholder="Enter building sf"
                            errorText="ex. 98101"
                            error={errors.totalBldgSf}
                            value={data.totalBldgSf}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                            numeric
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KMCounter
                            id="numFloors"
                            label="Number of Floors"
                            placeholder="Enter number of floors"
                            errorText="ex. 9"
                            error={errors.numFloors}
                            value={get(data, "numFloors", "")}
                            onChange={this.handleBuildingCounter}
                            min={1}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="numBldg"
                            label="BUILDING COUNT"
                            placeholder="Enter bulding count"
                            errorText="ex. 1"
                            error={errors.numBldg}
                            value={data.numBldg}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="buildingNumber"
                            label="BUILDING NUMBER"
                            placeholder="Enter building number"
                            errorText="ex. 14"
                            error={errors.buildingNumber}
                            value={data.buildingNumber}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="lotAcres"
                            label="LOT SIZE (ACRES)"
                            placeholder="Enter land acreage"
                            errorText="ex. 98.1"
                            error={errors.lotAcres}
                            value={data.lotAcres}
                            type="number"
                            onChange={(e) => this.handleLotSizeAutoCalc(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="lotSf"
                            label="LOT SIZE (SF)"
                            placeholder="Enter SF"
                            errorText="ex. 98101"
                            error={errors.lotSf}
                            value={data.lotSf}
                            type="number"
                            onChange={(e) => this.handleLotSizeAutoCalc(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSSelect
                            id="constructionType"
                            label="CONSTRUCTION TYPE"
                            error={errors.constructionType}
                            value={data.constructionType}
                            onChange={this.handleBuildingDetailChange}
                            options={constants.optionsBuilder(constants.constructionTypes)}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="elevators"
                            label="ELEVATOR COUNT"
                            placeholder="Enter elevator count"
                            errorText="ex. 9"
                            error={errors.elevators}
                            value={data.elevators}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSSelect
                            id="leedCertLevel"
                            label="LEED CERTIFICATION LEVEL"
                            options={constants.optionsBuilder(constants.leedCertLevels)}
                            error={errors.leedCertLevel}
                            value={data.leedCertLevel}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <BSInput
                            id="totalUnits"
                            label="TOTAL UNITS"
                            error={errors.totalUnits}
                            value={data.totalUnits}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="deliveredQtr"
                            label="DELIVERED QUARTER"
                            placeholder="Enter delivered quarter"
                            error={errors.deliveredQtr}
                            errorText="ex. 98.2"
                            value={data.deliveredQtr}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="totalBldgNra"
                            label="NET RENTABLE AREA"
                            placeholder="Enter rentable area"
                            error={errors.totalBldgNra}
                            value={data.totalBldgNra}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSSelect
                            id="occupancyType"
                            label="TENANCY"
                            options={constants.optionsBuilder(constants.tenancyTypes)}
                            error={errors.occupancyType}
                            value={data.occupancyType}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSSelect
                            id="energyStarCert"
                            label="ENERGY STAR RATING"
                            options={yes_no_options}
                            error={errors.energyStarCert}
                            value={data.energyStarCert}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="freightElevatorCount"
                            label="FREIGHT ELEVATOR COUNT"
                            placeholder="Enter elevator count"
                            errorText="ex. 9"
                            error={errors.freightElevatorCount}
                            value={data.freightElevatorCount}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="zoningType"
                            label="ZONING TYPE"
                            placeholder="Enter zoning type"
                            error={errors.zoningType}
                            value={data.zoningType}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="zoningCode"
                            label="ZONING CODE"
                            placeholder="Enter zoning code"
                            error={errors.zoningCode}
                            value={data.zoningCode}
                            onChange={this.handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default BuildingForm;
