import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import Typography from "@material-ui/core/Typography";

export default class ConfidentialCompModal extends React.Component {
    render() {
        const { handleConfirm, handleReject } = this.props;

        return (
            <Dialog
                open={this.props.confidentialCompModalOpen}
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
            >
                <DialogContent>
                    <Typography variant="body1">
                        <h4 className="modal-title">
                            There are <span style={{ color: "red", fontWeight: "bold" }}>Confidential</span> Comps
                            selected in this report. Are you sure you would like to continue?
                        </h4>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outline" onClick={() => handleReject()} color="primary">
                        No
                    </Button>
                    <Button variant="contained" onClick={() => handleConfirm()} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
