import validate from "validate.js";
import moment from "moment";
import isNil from "lodash/isNil";
import { convertForNumberValidation } from "./operations";

validate.extend(validate.validators.datetime, {
    // The value is guaranteed not to be null or undefined but otherwise it
    // could be anything.
    parse: function (value, options) {
        return +moment.utc(value);
    },
    // Input is a unix timestamp
    format: function (value, options) {
        var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
        console.log(moment.utc(value).format(format));
        return moment.utc(value).format(format);
    },
});

validate.validators.idregex = function (value, options, key, attributes) {
    const valid = options.allowEmpty ? isNil(value) || idPattern.test(value) : idPattern.test(value);
    return valid ? null : options.message;
};

validate.validators.numberRegex = function (value, options, key, attributes) {
    if (value === "") value = null;
    const valid = options.allowEmpty
        ? isNil(value) || numericPattern.test(convertForNumberValidation(value))
        : numericPattern.test(convertForNumberValidation(value));
    return valid ? null : options.message;
};

validate.validators.decimalRegex = (value, options) => {
    if (value === "") value = null;
    const valid = options.allowEmpty ? isNil(value) || decimalPattern.test(value) : decimalPattern.test(value);
    return valid ? null : options.message;
};

validate.validators.dateregex = function (value, options, key, attributes) {
    const valid = options.allowEmpty ? isNil(value) || datePattern.test(value) : datePattern.test(value);
    return valid ? null : options.message;
};

validate.extend(validate.validators.numericality, {
    parse: function (value, options) {
        value = value || null;
        return value;
    },
});

validate.validators.integerRegex = (value, options) => {
    if (value === "") value = null;
    const valid = options.allowEmpty ? isNil(value) || intPattern.test(value) : intPattern.test(value);
    return valid ? null : options.message;
};

validate.validators.positiveNumberOrEmpty = (value, options) => {
    const validEmptyValues = [null, undefined, ""];
    if (validEmptyValues.includes(value)) return null;
    const numericVal = parseInt(value);
    return numericVal >= 0 ? null : options.message;
};

validate.validators.interval = (value, options) => {
    const validEmptyValues = [null, undefined, ""];
    if (validEmptyValues.includes(value)) return null;
    const numericVal = Number(value);
    return numericVal >= options.greaterThanOrEqualTo && numericVal <= options.lessThanOrEqualTo
        ? null
        : options.message;
};

const datePattern =
    /^(19|20\d{2}(-|\/)\d{1,2}(-|\/)(\d{1,2}|\d{2}))(T?\d{2}?:?\d{2}?:?\d{2}?)?|^(\d{1,2}(-|\/)\d{1,2}(-|\/)(((19|20)\d{2})|(\d{2}))( \d{2}:\d{2}:\d{2} \w{1,})?)$/; // For datepicker
const decimalPattern = /^(0(\.\d+)?|1(\.0+)?)$/; // Matches decimal numbers between 0.0 and 1.0
const idPattern = /^$|^"\d+"$|^(\d+)$/;
const intPattern = /^(\+|-)?\d+$/;
const numericPattern = /^-?\d+(\.\d{1,2})?$/;
const yearPattern = /\d{4}/;

export const constraints = {
    required: {
        presence: { allowEmpty: false, message: "Required" },
    },
    number: {
        numberRegex: {
            allowEmpty: true,
            message: "Field must be a number",
        },
    },
    positiveNumber: {
        numericality: {
            greaterThanOrEqualTo: 0,
            message: "Field must be a number greater than or equal to zero",
        },
    },
    positiveNumberOrEmpty: {
        positiveNumberOrEmpty: {
            message: "Field must be a positive number",
        },
    },
    between1And12: {
        interval: {
            greaterThanOrEqualTo: 1,
            lessThanOrEqualTo: 12,
            message: `Invalid integer`,
        },
    },
    between0And100: {
        interval: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 100,
            message: `Invalid integer`,
        },
    },
    date: {
        dateregex: {
            allowEmpty: true,
            message: "Invalid date format",
        },
    },
    id: {
        idregex: {
            allowEmpty: true,
            message: "Invalid",
        },
    },
    year: {
        format: {
            pattern: yearPattern,
            flags: "i",
            message: "Invalid year",
        },
    },
    integer: {
        integerRegex: {
            allowEmpty: true,
            message: "Field must be an integer",
        },
    },
    decimal: {
        decimalRegex: {
            allowEmpty: true,
            message: "Field must be a decimal value between 0.0 and 1.0",
        },
    },
};
