import types from "./types";

const toggleRequestModal = (useType, transactionType) => {
    return {
        type: types.TOGGLE_REQUEST_LISTING_MODAL,
        useType: useType,
        transactionType: transactionType,
    };
};

const storeNewPropertyInfo = (info) => {
    return {
        type: types.STORE_NEW_PROPERTY_INFO_FOR_LISTING_REQUEST,
        info: info,
    };
};

const selectPropertyFromAutocomplete = (property) => {
    return {
        type: types.SELECT_PROPERTY_FROM_AUTOCOMPLETE,
        property: property,
    };
};

const removeSelectedPropertyFromRequestModal = () => {
    return {
        type: types.REMOVE_SELECTED_PROPERTY_FROM_LISTING_REQUEST_MODAL,
    };
};

const updateListingRequest = (field, value) => {
    return {
        type: types.UPDATE_LISTING_REQUEST,
        field: field,
        value: value,
    };
};

const addError = (field, error) => {
    console.log(field);
    return {
        type: types.ADD_REQUEST_LISTING_MODAL_ERROR,
        field: field,
        error: error,
    };
};

const clearErrors = () => {
    return {
        type: types.CLEAR_REQUEST_LISTING_MODAL_ERRORS,
    };
};

const inputChange = (field, value) => {
    return {
        type: types.LISTING_REQUEST_INPUT_CHANGE,
        field: field,
        value: value,
    };
};

const updateInputChange = (updates) => {
    return {
        type: types.LISTING_REQUEST_INPUT_UPDATE,
        updates: updates,
    };
};

const listingInputChange = (field, value) => {
    return {
        type: types.LISTING_INPUT_CHANGE,
        field: field,
        value: value,
    };
};

const togglePanel = (panel, expanded) => {
    return {
        type: types.TOGGLE_LISTING_REQUEST_PANEL,
        panel: panel,
        expanded: expanded,
    };
};

const updateFilter = (path, value) => {
    return {
        type: "UPDATE_SEARCH_CRITERIA",
        path: path,
        value: value,
    };
};

const clearFilters = () => {
    return {
        type: types.CLEAR_LISTING_SEARCH_FILTERS,
    };
};

const togglePropertyTypeExpansionPanel = (panelName, open) => {
    return {
        type: "TOGGLE_PROPERTY_TYPE_EXPANSION_PANEL",
        panelName: panelName,
        open: open,
    };
};

const switchTab = (tab) => {
    return {
        type: types.SWITCH_REVIEW_LISTINGS_TAB,
        tab: tab,
    };
};

const receiveListings = (data, tab) => {
    return {
        type: types.RECEIVE_REVIEW_LISTINGS,
        listings: data,
        tab: tab,
    };
};

const clearMyListings = () => {
    console.log("actions = clearMyListings");
    return {
        type: types.CLEAR_MY_LISTINGS,
    };
};

const handleSort = (columnName, sortDirection) => {
    return {
        type: types.SORT_REVIEW_LISTINGS,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

const countListings = (count, tab) => {
    return {
        type: types.COUNT_REVIEW_LISTINGS,
        count: count,
        tab: tab,
    };
};

const filterListings = (query) => {
    return {
        type: types.FILTER_LISTINGS,
        filterQuery: query,
    };
};

const getListing = (data) => {
    return {
        type: types.GET_LISTING,
        data: data,
    };
};

const loadListing = (listing) => {
    return {
        type: types.LOAD_LISTING,
        listing: listing,
    };
};

const clearListings = () => {
    return {
        type: types.CLEAR_LISTINGS,
    };
};

const addArrayElement = (arrayPath, index, object) => {
    return {
        type: types.ADD_LISTING_ARRAY_ELEMENT,
        path: arrayPath,
        index: index,
        object: object,
    };
};

const updateArrayElement = (arrayPath, index, field, value) => {
    return {
        type: types.UPDATE_ARRAY_ELEMENT,
        path: arrayPath,
        index: index,
        field: field,
        value: value,
    };
};

const deleteArrayElement = (arrayPath, index) => {
    return {
        type: types.DELETE_ARRAY_ELEMENT,
        path: arrayPath,
        index: index,
    };
};

const setListingArray = (pathToArray, array) => {
    return {
        type: types.SET_LISTING_ARRAY,
        pathToArray: pathToArray,
        array: array,
    };
};

const setCheckedListings = (listings) => {
    return {
        type: types.SET_CHECKED_LISTINGS,
        listings: listings,
    };
};
const checkboxClick = (key, newValue) => {
    return {
        type: types.TOGGLE_LISTINGS_FOR_REPORT,
        key: key,
        newValue: newValue,
    };
};

const groupCheckboxClick = (key, newValue) => {
    return {
        type: types.UPDATE_SELECTED_LISTINGS_GROUP,
        key: key,
        newValue: newValue,
    };
};

const activeCheckBoxClick = (key, newValue) => {
    return {
        type: types.CLICK_ACTIVE_CHECKBOX,
        key: key,
        newValue: newValue,
    };
};

const updateInactivateDialog = (isOpen) => {
    return {
        type: types.UPDATE_INACTIVATE_DIALOG,
        open: isOpen,
    };
};

const updateDeleteDialog = (isOpen) => {
    return {
        type: types.UPDATE_DELETE_DIALOG,
        open: isOpen,
    };
};

const toggleDuplicateListingDialog = (isOpen) => {
    return {
        type: types.UPDATE_DUPLICATE_DIALOG,
        open: isOpen,
    };
};

const toggleDuplicateSuccessDialog = (isOpen) => {
    return {
        type: types.DUPLICATE_SUCCESS_DIALOG,
        open: isOpen,
    };
};

const updateEditProperty = (isOpen) => {
    return {
        type: types.UPDATE_EDIT_PROPERTY,
        open: isOpen,
    };
};

const titleReportModalStatus = (open) => {
    return {
        type: types.TOGGLE_REPORT_MODAL_STATUS,
        open: open,
    };
};

const includeBrokerInfoReport = (include) => {
    return {
        type: types.TOGGLE_BROKER_INFO_REPORT,
        include: include,
    };
};

const updateReportTitle = (title) => {
    return {
        type: types.SET_REPORT_TITLE,
        title: title,
    };
};

const updateListingOrder = (data, tab) => {
    return {
        type: types.UPDATE_LISTING_ORDER,
        listings: data,
        tab: tab,
    };
};

const setCurrentListingsGroup = (listings) => {
    return {
        type: types.SET_LISTING_GROUP,
        listings_group: listings,
    };
};

const clearCurrentListingsGroup = () => {
    return {
        type: types.CLEAR_LISTING_GROUP,
        listings_group: [],
    };
};
const setGroupCheckedListings = (listings) => {
    return {
        type: types.UPDATE_GROUP_SELECTED,
        selectedListingsGroup: listings,
    };
};

const setActiveCheckedListings = (listings) => {
    return {
        type: types.UPDATE_ACTIVE_SELECTED_LISTINGS,
        activeSelectedListings: listings,
    };
};

export default {
    getListing,
    updateInputChange,
    inputChange,
    selectPropertyFromAutocomplete,
    toggleRequestModal,
    updateListingRequest,
    removeSelectedPropertyFromRequestModal,
    addError,
    clearErrors,
    storeNewPropertyInfo,
    togglePanel,
    updateFilter,
    clearFilters,
    togglePropertyTypeExpansionPanel,
    switchTab,
    receiveListings,
    handleSort,
    countListings,
    filterListings,
    listingInputChange,
    loadListing,
    addArrayElement,
    updateArrayElement,
    deleteArrayElement,
    setListingArray,
    setCheckedListings,
    checkboxClick,
    updateInactivateDialog,
    updateDeleteDialog,
    updateEditProperty,
    titleReportModalStatus,
    includeBrokerInfoReport,
    updateReportTitle,
    toggleDuplicateListingDialog,
    toggleDuplicateSuccessDialog,
    updateListingOrder,
    clearListings,
    clearMyListings,
    setCurrentListingsGroup,
    clearCurrentListingsGroup,
    groupCheckboxClick,
    setGroupCheckedListings,
    setActiveCheckedListings,
    activeCheckBoxClick,
};
