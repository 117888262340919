import types from "./types";

const apiResponse = (response) => {
    return {
        type: types.API_RESPONSE,
        payload: response.data,
        status: response.status,
    };
};

const loading = (isLoading) => {
    return {
        type: types.PAGE_LOADING,
        isLoading: isLoading,
    };
};

const loading2 = (isLoading) => {
    return {
        type: types.PAGE_LOADING_2,
        isLoading: isLoading,
    };
};

export const snackbar = (open, variant, message) => {
    return {
        type: types.SNACKBAR,
        open: open,
        message: message,
        variant: variant,
    };
};

export const notification = (open, message) => {
    return {
        type: types.NOTIFICATION,
        open: open,
        message: message,
    };
};

const searchResults = (results) => {
    return {
        type: types.SEARCH_RESULTS,
        results: results,
    };
};

const toggleConfirm = (open, onSubmit) => {
    return {
        type: types.TOGGLE_CONFIRM,
        open: open,
        onSubmit: onSubmit,
    };
};

const resetConfirm = () => {
    return {
        type: types.RESET_CONFIRM,
    };
};

const isSaving = (isSaving) => {
    return {
        type: types.IS_SAVING,
        isSaving: isSaving,
    };
};

const somethingSaving = (isSaving, element) => {
    return {
        type: types.SOMETHING_SAVING,
        isSaving: isSaving,
        element: element,
    };
};

const addError = (field, error) => {
    console.log(field);
    return {
        type: types.ADD_ERROR,
        field: field,
        error: error,
    };
};

const clearErrorByField = (field) => {
    return {
        type: types.CLEAR_ERROR_BY_FIELD,
        field: field,
    };
};

const clearErrors = () => {
    return {
        type: types.CLEAR_ERRORS,
    };
};

const setLoader = (name, isLoading) => {
    return {
        type: types.LOADING,
        name: name,
        isLoading: isLoading,
    };
};

const addArrayElement = (pathToArray, action, array) => {
    return {
        type: action,
        array: array,
        pathToArray: pathToArray,
    };
};

const setErrorPanelsToExpand = (panelsToExpand) => {
    return {
        type: types.SET_ERROR_PANELS_EXPANDED,
        panelsToExpand: panelsToExpand,
    };
};

const toggleCheckErrorPanels = (value) => {
    console.log(value);
    return {
        type: types.TOGGLE_CHECK_ERROR_PANELS,
        value: value,
    };
};

export default {
    apiResponse,
    loading,
    loading2,
    snackbar,
    notification,
    searchResults,
    toggleConfirm,
    isSaving,
    somethingSaving,
    addError,
    clearErrorByField,
    clearErrors,
    setLoader,
    addArrayElement,
    resetConfirm,
    setErrorPanelsToExpand,
    toggleCheckErrorPanels,
};
