import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { RightChevron } from "../../../common/js/icons";
import moment from "moment";

const styles = {
    paper: {
        padding: "0 20px",
        maxWidth: "26rem",
        display: "block",
    },
    commentHeader: {
        paddingTop: "20px",
    },
    exit: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    textField: {
        minWidth: "400px",
        fontSize: "14px !important",
    },
    commentContainer: {
        padding: "20px 0 0",
        marginTop: "15px",
        marginBottom: "12px",
        borderTop: "1px solid #ededed",
    },
    commentName: {
        fontSize: 12,
        paddingBottom: "20px",
    },
    commentDate: {
        fontSize: 12,
        paddingBottom: "20px",
    },
    comment: {
        fontSize: 14,
        lineHeight: "20px",
    },
};

const Comments = (props) => {
    const {
        classes,
        commentDraft,
        handleSubmitComment,
        handleClearComment,
        handleChange,
        toggleComments,
        comments,
        open,
        parentRecordType,
        parentRecordId,
        handleRequestUpdate,
        disabled,
        showRequestUpdateListing,
        showSubmitListing,
        setRequestUpdateAndSubmit,
        listing,
        userProfile,
    } = props;

    useEffect(() => {
        if (setRequestUpdateAndSubmit) setRequestUpdateAndSubmit(listing, userProfile);
    }, [listing]);

    const showRequestUpdateButton =
        showRequestUpdateListing !== undefined
            ? showRequestUpdateListing
            : parentRecordType === "property"
            ? true
            : false;
    const showSubmitButton = showSubmitListing !== undefined ? showSubmitListing : true;

    return (
        <Drawer anchor="right" open={open} onClose={toggleComments} classes={classes}>
            <Grid container justify="space-between" alignItems="center">
                <Grid item className={classes.commentHeader}>
                    <h3>Comments ({comments.length})</h3>
                </Grid>
                <Grid item>
                    <div role="button" onClick={toggleComments} className={classes.exit}>
                        <RightChevron />
                    </div>
                </Grid>
                {showRequestUpdateButton || showSubmitButton ? (
                    <div style={{ fontSize: "14px" }}>
                        After typing your comment,{" "}
                        {showRequestUpdateButton && 'click "Request Update" to send to the Corporate Data Team'}
                        {showRequestUpdateButton && showSubmitButton && " or "}
                        {showSubmitButton && 'click "Submit" to add a standard comment'}.
                    </div>
                ) : (
                    ""
                )}
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-textarea"
                        placeholder="Write your comment here..."
                        multiline
                        fullWidth
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={handleChange}
                        value={commentDraft}
                    />
                </Grid>
            </Grid>
            <Grid container justify="flex-end">
                <Grid item>
                    <Button onClick={handleClearComment} variant="outlined" color="primary">
                        Cancel
                    </Button>
                </Grid>
                {showRequestUpdateButton && (
                    <Grid item>
                        <Button
                            onClick={(e) => handleRequestUpdate(parentRecordType, parentRecordId)}
                            variant="contained"
                            color="secondary"
                            disabled={disabled}
                        >
                            Request Update
                        </Button>
                    </Grid>
                )}
                {showSubmitButton && (
                    <Grid item>
                        <Button
                            onClick={(e) => handleSubmitComment(parentRecordType, parentRecordId)}
                            variant="contained"
                            color="primary"
                            disabled={disabled}
                        >
                            Submit
                        </Button>
                    </Grid>
                )}
            </Grid>
            {comments.map((c, i) => (
                <div className={classes.commentContainer} key={`${parentRecordType}-comment-${i}`}>
                    <Grid container justify="space-between" classes={classes}>
                        <Grid item>
                            <div className={classes.commentName}>{c.auditCreatedBy}</div>
                        </Grid>
                        <Grid item>
                            <div className={classes.commentDate}>
                                posted on {moment(c.auditCreatedTimestamp).format("MM/DD/YY h:mm A")}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start">
                        <Grid item>
                            <div className={classes.comment} key={`comment-${i}`}>
                                {c.comment}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            ))}
            <br />
            &nbsp;
        </Drawer>
    );
};

export default withStyles(styles)(Comments);
