import React, { useEffect, useState } from "react";
import get from "lodash/get";
import Button from "@material-ui/core/Button";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Delete, Search } from "@material-ui/icons";
import Autocomplete from "../../../../common/js/components/Autocomplete";
import AutocompleteFreeform from "../../../../common/js/components/AutocompleteFreeform";
import BSInput from "../../../../common/js/components/BSInput";
import BSSelect from "../../../../common/js/components/BSSelect";
import FormTitle from "../../../../common/js/components/FormTitle";
import KMSwitch from "../../../../common/js/components/KMSwitch";
import { us_states_options } from "../../utils/constants";
import "./ContactFormBeta.css";

const ContactForm = (props) => {
    const {
        allowDelete,
        autocompleteResults,
        companyFieldAvailable,
        contact,
        contactIndex,
        contactTypeOptions,
        disableInputs,
        errors,
        freeFormCompany,
        handleDelete,
        handleInput,
        handleSearchCompany,
        hideTitle,
        includeInvoiceRecipientCheckbox,
        isSnapshot,
        key,
        location,
        title,
    } = props;

    const [disableContactTypeInput, setDisableContactTypeInput] = useState(true);

    useEffect(() => {
        if (!contactTypeOptions.map((type) => type.value).includes(get(contact, "contactType"))) {
            setDisableContactTypeInput(false);
        }
    }, []);

    const selectCompany = (company) => {
        handleInput(`company`, company);
    };

    const clearCompany = () => {
        handleInput(`company`, {});
    };

    const freeformUpdateCompany = (value) => {
        if (get(contact, `company.companyKey`, null)) {
            clearCompany();
        }
        handleInput("company.displayName", value);
        handleInput("company.companyTypes", ["CONTACT"]);
        handleInput("company.approvedFlg", true);
    };

    const handleContactTypeInput = (e) => {
        if (e.target.value === "OTHER") {
            setDisableContactTypeInput(false);
        } else {
            setDisableContactTypeInput(true);
        }
        handleInput(e.target.id, e.target.value);
    };

    return (
        <form key={key}>
            {!hideTitle && (
                <div className="flexContainer">
                    <div>
                        <FormTitle title={title} />
                    </div>
                    <div className={"deleteButton"}>
                        {allowDelete && disableInputs !== true && (
                            <Button onClick={() => handleDelete(contactIndex)}>
                                <Delete />
                            </Button>
                        )}
                    </div>
                </div>
            )}

            <div className="flexContainer">
                <div className={"flexContainer flexItemHalfWidth"}>
                    <BSSelect
                        id="contactType"
                        label="Contact Type"
                        value={get(contact, "contactType")}
                        error={get(errors, `contact.${contactIndex}.contactType`, null)}
                        onChange={(e) => handleContactTypeInput(e)}
                        options={contactTypeOptions}
                        disabled={disableInputs === true}
                    />
                    {location.pathname.includes("propdetail") && (
                        <div style={{ display: disableContactTypeInput ? "none" : "" }}>
                            <BSInput
                                id="contactType"
                                label="Custom Contact Type"
                                placeholder="Enter contact type"
                                value={get(contact, "contactType")}
                                error={get(errors, `contact.${contactIndex}.contactType`, null)}
                                onChange={(e) => handleInput(e.target.id, e.target.value)}
                                disabled={disableContactTypeInput || disableInputs === true}
                            />
                        </div>
                    )}
                    {includeInvoiceRecipientCheckbox && (
                        <div className="flexItemHalfWidth">
                            <FormControlLabel
                                style={{ marginLeft: "15px", marginTop: "15px" }}
                                control={
                                    <KMSwitch
                                        checked={
                                            get(contact, "invoiceRecipientFlg")
                                                ? get(contact, "invoiceRecipientFlg")
                                                : false
                                        }
                                        onChange={(e) => handleInput("invoiceRecipientFlg", e.target.checked)}
                                        value={get(contact, "invoiceRecipientFlg")}
                                    />
                                }
                                label="Invoice Recipient?"
                            />
                        </div>
                    )}
                </div>
                <div className={"flexContainer flexAuto"}>
                    <BSInput
                        id="title"
                        label="Title"
                        value={isSnapshot ? get(contact, "title") : get(contact, `contact.person.title`, "")}
                        error={get(errors, `contact.${contactIndex}.person.title`, null)}
                        onChange={(e) => handleInput("contact.person.title", e.target.value)}
                        placeholder={isSnapshot ? "N/A" : "Enter title"}
                        disabled={disableInputs === true}
                    />
                    <BSInput
                        id="firstName"
                        label="First Name"
                        value={isSnapshot ? get(contact, "firstName") : get(contact, `contact.person.firstName`, "")}
                        error={get(errors, `contact.${contactIndex}.contact.person.firstName`, null)}
                        onChange={(e) => handleInput("contact.person.firstName", e.target.value)}
                        placeholder={isSnapshot ? "N/A" : "Enter first name"}
                        disabled={disableInputs === true}
                    />
                    <BSInput
                        id="lastName"
                        label="Last Name"
                        value={isSnapshot ? get(contact, "lastName") : get(contact, `contact.person.lastName`, "")}
                        error={get(errors, `contact.${contactIndex}.contact.person.lastName`, null)}
                        onChange={(e) => handleInput("contact.person.lastName", e.target.value)}
                        placeholder={isSnapshot ? "N/A" : "Enter last name"}
                        disabled={disableInputs === true}
                    />
                </div>
            </div>

            <div className="flexContainer">
                {isSnapshot ? (
                    <div className="flexItemHalfWidth">
                        <BSInput
                            id="company"
                            label="Company Name (Common)"
                            placeholder="N/A"
                            value={get(contact, "companyName")}
                            disabled
                        />
                    </div>
                ) : freeFormCompany ? (
                    <div className="flexItemHalfWidth">
                        <AutocompleteFreeform
                            id="company"
                            label={"Company Name (Common)"}
                            error={get(errors, `contact.company.companyKey`, null)}
                            initialInputValue={`${get(contact, `company.displayName`, "")}`}
                            itemToString={(i) => (i == null ? "" : get(i, "displayName", ""))}
                            onSelect={(company) => selectCompany(company)}
                            placeholder="Enter company name (common)"
                            searchFunction={(input) => handleSearchCompany(input, "CONTACT")}
                            startAdornment={<Search />}
                            suggestions={autocompleteResults}
                            disabled={disableInputs === true}
                            options={{
                                key: "companyKey",
                                display: (suggestion) => {
                                    return get(suggestion, "displayName", "");
                                },
                                displaySub: (suggestion) => {
                                    return get(suggestion, "mainAddress.address1", "");
                                },
                            }}
                            freeformUpdate={(value) => freeformUpdateCompany(value)}
                        />
                    </div>
                ) : (
                    companyFieldAvailable && (
                        <div className="flexItemHalfWidth">
                            <Autocomplete
                                id="company"
                                label={"Company Name (Common)"}
                                error={get(errors, `contact.company.companyKey`, null)}
                                initialInputValue={`${get(contact, `company.displayName`, "")}`}
                                itemToString={(i) => (i == null ? "" : get(i, "displayName", ""))}
                                onSelect={(company) => selectCompany(company)}
                                placeholder="Enter company name (common)"
                                searchFunction={(input) => handleSearchCompany(input, "CONTACT")}
                                startAdornment={<Search />}
                                suggestions={autocompleteResults}
                                disabled={disableInputs === true}
                                options={{
                                    key: "companyKey",
                                    display: (suggestion) => {
                                        return get(suggestion, "displayName", "");
                                    },
                                    displaySub: (suggestion) => {
                                        return get(suggestion, "mainAddress.address1", "");
                                    },
                                }}
                            />
                        </div>
                    )
                )}
                <BSInput
                    id="emailAddress"
                    label="Email"
                    value={isSnapshot ? get(contact, "email") : get(contact, `contact.emailAddress`, "")}
                    error={get(errors, `contact.${contactIndex}.contact.emailAddress`, null)}
                    onChange={(e) => handleInput("contact.emailAddress", e.target.value)}
                    placeholder={isSnapshot ? "N/A" : "Enter contact email"}
                    disabled={disableInputs === true}
                />
            </div>

            <div className="flexContainer">
                <div className="flexContainer flexItemHalfWidth">
                    <div className="flexItemHalfWidth">
                        <BSInput
                            id="preferredPhoneNumber"
                            label="Preferred Phone"
                            value={
                                isSnapshot
                                    ? get(contact, "preferredPhone")
                                    : get(contact, `contact.phoneNumber.phoneNumber`, "")
                            }
                            error={get(errors, `contact.${contactIndex}.contact.phoneNumber.phoneNumber`, null)}
                            onChange={(e) => handleInput("contact.phoneNumber.phoneNumber", e.target.value)}
                            placeholder={isSnapshot ? "N/A" : "Enter preferred contact number"}
                            disabled={disableInputs === true}
                            phone={true}
                        />
                    </div>
                    <div className="flexItemHalfWidth">
                        <RadioGroup
                            aria-labelledby="phone-type-radio-buttons-group-label"
                            value={
                                isSnapshot
                                    ? get(contact, "preferredPhoneType")
                                    : get(contact, `contact.phoneNumber.phoneNumberType`, "")
                            }
                            error={get(errors, `contact.${contactIndex}.contact.phoneNumber.phoneNumberType`, null)}
                            onChange={(e) => handleInput("contact.phoneNumber.phoneNumberType", e.target.value)}
                            name="Type"
                            row
                        >
                            <FormControlLabel
                                disabled={disableInputs === true}
                                value="mobile"
                                control={<Radio color="primary" />}
                                label="Mobile"
                            />
                            <FormControlLabel
                                disabled={disableInputs === true}
                                value="office"
                                control={<Radio color="primary" />}
                                label="Office"
                            />
                            <FormControlLabel
                                disabled={disableInputs === true}
                                value="other"
                                control={<Radio color="primary" />}
                                label="Other"
                            />
                        </RadioGroup>
                    </div>
                </div>
                <div className="flexContainer flexAuto">
                    <div className="flexItemHalfWidth">
                        <BSInput
                            id="alternatePhoneNumber"
                            label="Alternate Phone"
                            value={
                                isSnapshot
                                    ? get(contact, "alternatePhone")
                                    : get(contact, `contact.alternatePhoneNumber.phoneNumber`, "")
                            }
                            error={get(
                                errors,
                                `contact.${contactIndex}.contact.alternatePhoneNumber.phoneNumber`,
                                null
                            )}
                            onChange={(e) => handleInput("contact.alternatePhoneNumber.phoneNumber", e.target.value)}
                            placeholder={isSnapshot ? "N/A" : "Enter alternative contact number"}
                            disabled={disableInputs === true}
                            phone={true}
                        />
                    </div>
                    <RadioGroup
                        aria-labelledby="alternate-phone-type-radio-buttons-group-label"
                        value={
                            isSnapshot
                                ? get(contact, "alternatePhoneType")
                                : get(contact, `contact.alternatePhoneNumber.phoneNumberType`, null)
                        }
                        error={get(
                            errors,
                            `contact.${contactIndex}.contact.alternatePhoneNumber.phoneNumberType`,
                            null
                        )}
                        onChange={(e) => handleInput("contact.alternatePhoneNumber.phoneNumberType", e.target.value)}
                        name="Type"
                        row
                    >
                        <FormControlLabel
                            disabled={disableInputs === true}
                            value="mobile"
                            control={<Radio color="primary" />}
                            label="Mobile"
                        />
                        <FormControlLabel
                            disabled={disableInputs === true}
                            value="office"
                            control={<Radio color="primary" />}
                            label="Office"
                        />
                        <FormControlLabel
                            disabled={disableInputs === true}
                            value="other"
                            control={<Radio color="primary" />}
                            label="Other"
                        />
                    </RadioGroup>
                </div>
            </div>

            <div className="flexContainer">
                <div className="flexItemHalfWidth">
                    <BSInput
                        id="address1"
                        label="Address"
                        value={isSnapshot ? get(contact, "address") : get(contact, `contact.address.address1`, "")}
                        error={get(errors, `contact.${contactIndex}.contact.address.address1`, null)}
                        onChange={(e) => handleInput("contact.address.address1", e.target.value)}
                        placeholder={isSnapshot ? "N/A" : "Enter address"}
                        disabled={disableInputs === true}
                    />
                </div>
                <div className={"flexContainer flexAuto"}>
                    <BSInput
                        id="city"
                        label="City"
                        value={isSnapshot ? get(contact, "city") : get(contact, `contact.address.city.city`, "")}
                        error={get(errors, `contact.${contactIndex}.contact.address.city.city`, null)}
                        onChange={(e) => handleInput("contact.address.city.city", e.target.value)}
                        placeholder={isSnapshot ? "N/A" : "Enter city"}
                        disabled={disableInputs === true}
                    />
                    <BSSelect
                        id="state"
                        label="State"
                        value={
                            isSnapshot
                                ? get(contact, "state")
                                : get(contact, `contact.address.city.state.stateCode`, "")
                        }
                        error={get(errors, `contact.${contactIndex}.contact.address.city.state.stateCode`, null)}
                        onChange={(e) => handleInput("contact.address.city.state.stateCode", e.target.value)}
                        options={us_states_options}
                        disabled={disableInputs === true}
                    />
                    <BSInput
                        id="zipCode"
                        label="Zip Code"
                        value={
                            isSnapshot ? get(contact, "zipCode") : get(contact, `contact.address.zipCode.zipCode`, "")
                        }
                        error={get(errors, `contact.${contactIndex}.contact.address.zipCode.zipCode`, null)}
                        onChange={(e) => handleInput("contact.address.zipCode.zipCode", e.target.value)}
                        placeholder={isSnapshot ? "N/A" : "Enter zip code"}
                        disabled={disableInputs === true}
                    />
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
