import types from "./types";

const updateSearchCriteria = (path, value) => {
    return {
        type: types.UPDATE_SEARCH_CRITERIA,
        // key : searchTypeKey,
        // field : field,
        value: value,
        path: path,
    };
};

const replaceSearchCriteria = (key, searchCriteria) => {
    return {
        type: types.REPLACE_SEARCH_CRITERIA,
        key: key,
        searchCriteria: searchCriteria,
    };
};

const storeSavedCoordinates = (coords, key) => {
    return {
        type: types.STORE_SAVED_COORDS,
        key: key,
        savedMapCoords: coords,
    };
};

const storeSelectedArrayFromSave = (key, selected) => {
    return {
        type: types.SELECTED_ARRAY_FROM_SAVE,
        key: key,
        selectedFromSave: selected,
    };
};

const setRetainSelectedItems = (retain, key) => {
    return {
        type: types.RETAIN_SELECTED_ITEMS,
        key: key,
        retainSelectedFromSave: retain,
    };
};

const replaceSearchCrumbs = (key, searchCrumbs) => {
    return {
        type: types.REPLACE_SEARCH_CRUMBS,
        key: key,
        searchCrumbs: searchCrumbs,
    };
};

const mapCrumbClear = (clear, key) => {
    return {
        type: types.MAP_CRUMB_CLEAR,
        key: key,
        mapCrumbCleared: clear,
    };
};

const storeSearchCleared = (clear, key) => {
    return {
        type: types.STORE_SEARCH_CLEARED,
        key: key,
        searchCleared: clear,
    };
};

const storeSearchRun = (run, key) => {
    return {
        type: types.STORE_SEARCH_RUN,
        key: key,
        searchRun: run,
    };
};

const updateSearchPolygon = (polygon, key) => {
    return {
        type: types.UPDATE_SEARCH_POLYGON,
        key: key,
        polygon: polygon,
    };
};

const updatePropertyType = (searchTypeKey, propertyType, value) => {
    return {
        type: types.UPDATE_PROPERTY_TYPE,
        key: searchTypeKey,
        propertyType: propertyType,
        value: value,
    };
};

const updateSpecialtyTypeCriteria = (searchTypeKey, propertyType, field, value) => {
    return {
        type: types.UPDATE_SPECIALTY_TYPE_CRITERIA,
        key: searchTypeKey,
        propertyType: propertyType,
        field: field,
        value: value,
    };
};

const updateTenancyTypeCriteria = (searchTypeKey, field, value) => {
    return {
        type: types.UPDATE_TENANCY_TYPE_CRITERIA,
        key: searchTypeKey,
        field: field,
        value: value,
    };
};

const updateBuildingClassCriteria = (searchTypeKey, propertyType, field, value) => {
    return {
        type: types.UPDATE_BUILDING_CLASS_CRITERIA,
        key: searchTypeKey,
        propertyType: propertyType,
        field: field,
        value: value,
    };
};

const loadSearchResponse = (searchType, searchResponse) => {
    return {
        type: types.LOAD_SEARCH_RESPONSE,
        key: searchType,
        response: searchResponse,
    };
};

const addSearchResults = (searchType, searchResponse) => {
    return {
        type: types.APPEND_SEARCH_RESULTS,
        key: searchType,
        response: searchResponse,
    };
};

const clearSearchFilters = (searchType) => {
    return {
        type: types.RESET_SEARCH_CRITERIA,
        key: searchType,
    };
};

const clearFilters = (filter) => {
    return {
        type: types.CLEAR_FILTERS,
        filter: filter,
    };
};
const clearCrumbs = (searchType) => {
    return {
        type: types.CLEAR_CRUMBS,
        searchType: searchType,
    };
};
const clearLoadedCrumbs = (searchType) => {
    return {
        type: types.CLEAR_LOADED_CRUMBS,
        searchType: searchType,
    };
};
const initiateDataExport = (searchType) => {
    return {
        type: types.INITIATE_DATA_EXPORT,
        key: searchType,
    };
};

const loadDataExport = (searchType, results) => {
    return {
        type: types.LOAD_DATA_EXPORT,
        key: searchType,
        results: results,
    };
};

const clearDataExport = (searchType) => {
    return {
        type: types.CLEAR_DATA_EXPORT,
        key: searchType,
    };
};

const togglePropertyTypeExpansionPanel = (panelName, open) => {
    return {
        type: "TOGGLE_PROPERTY_TYPE_EXPANSION_PANEL",
        panelName: panelName,
        open: open,
    };
};

const toggleMarketFilterExpansionPanel = (panelName, open) => {
    return {
        type: "TOGGLE_MARKET_FILTER_EXPANSION_PANEL",
        panelName: panelName,
        open: open,
    };
};

const updateFilter = (path, value) => {
    return {
        type: "UPDATE_SEARCH_CRITERIA",
        path: path,
        value: value,
    };
};

const updateCrumb = (crumb, searchType, field, value) => {
    return {
        type: types.UPDATE_CRUMB,
        searchType: searchType,
        crumb: crumb,
        field: field,
        value: value,
    };
};

const updateMapCrumb = (crumb, searchType, value) => {
    return {
        type: types.UPDATE_CRUMB,
        searchType: searchType,
        crumb: crumb,
        field: "filterCriteria.areaPoints",
        value: value,
    };
};

const updateRangeCrumb = (crumb, searchType, field1, field2, valueMin, valueMax, value, unit) => {
    if (unit != null) {
        return {
            type: types.UPDATE_RANGE_CRUMB,
            searchType: searchType,
            crumb: crumb,
            field1: field1,
            field2: field2,
            valueMin,
            valueMax,
            value: value,
            unit: unit,
        };
    } else {
        return {
            type: types.UPDATE_RANGE_CRUMB,
            searchType: searchType,
            crumb: crumb,
            field1: field1,
            field2: field2,
            valueMin,
            valueMax,
            value: value,
        };
    }
};

const setLoadedSearchCrumbs = (crumbs, searchType) => {
    return {
        type: types.LOAD_CRUMBS,
        crumbs: crumbs,
        searchType: searchType,
    };
};

const filterTableData = (query) => {
    return {
        type: types.FILTER_TABLE_DATA,
        filterQuery: query,
    };
};

const sortTableData = (columnName, sortDirection) => {
    return {
        type: types.SORT_TABLE_DATA,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

const searchResults = (results) => {
    return {
        type: types.SEARCH_RESULTS_X,
        results: results,
    };
};

const toggleViewSavedModal = (open) => {
    return {
        type: types.VIEW_SAVED_MODAL,
        open: open,
    };
};

const toggleRunSavedSearch = (saved, key) => {
    return {
        type: types.SEARCH_FROM_SAVE,
        key: key,
        savedSearchRun: saved,
    };
};

const toggleNameSaveModal = (open) => {
    return {
        type: types.NAME_SAVE_MODAL,
        open: open,
    };
};

const setSearchName = (name) => {
    return {
        type: types.SET_SEARCH_NAME,
        name: name,
    };
};

const loadSavedSearches = (savedSearches, key) => {
    return {
        type: types.LOAD_SAVED_SEARCHES,
        key: key,
        savedSearches: savedSearches,
    };
};

const loadSavedGroups = (savedGroups, key) => {
    return {
        type: types.LOAD_SAVED_GROUPS,
        key: key,
        savedGroups: savedGroups,
    };
};

const clearSavedSearches = (searchType) => {
    return {
        type: types.CLEAR_SAVED_SEARCHES,
        key: searchType,
    };
};

const onSearchPage = (onSearch) => {
    return {
        type: types.ON_SEARCH_PAGE,
        onSearchPage: onSearch,
    };
};

const onGroupPage = (onGroup) => {
    return {
        type: types.ON_GROUP_PAGE,
        onGroupPage: onGroup,
    };
};

const autocompleteResults = (results) => {
    return {
        type: types.AUTOCOMPLETE_RESULTS,
        results: results,
    };
};

const autocompleteSearchTerm = (term) => {
    return {
        type: types.AUTOCOMPLETE_SEARCH_TERM,
        term: term,
    };
};

const loadingSearch = (loading) => {
    return {
        type: types.LOADING_SEARCH_COMPS,
        loadingSearch: loading,
    };
};

const clearSearchResponse = (key) => {
    return {
        type: types.CLEAR_SEARCH_RESPONSE,
        key: key,
    };
};

const saveMapSearchSettings = (settings, key) => {
    return {
        type: types.SAVE_MAP_SEARCH_SETTINGS,
        key: key,
        settings: settings,
    };
};

const setCurrentGroup = (searchType, currentGroup) => {
    return {
        type: types.SET_CURRENT_GROUP,
        key: searchType,
        currentGroup: currentGroup,
    };
};

const clearCurrentGroup = (searchType) => {
    return {
        type: types.SET_CURRENT_GROUP,
        key: searchType,
        currentGroup: [],
    };
};

const setUsersSavedGroups = (searchType, savedGroups) => {
    return {
        type: types.SET_SAVED_GROUPS,
        key: searchType,
        savedGroups: savedGroups,
    };
};

export default {
    updateSearchCriteria,
    updatePropertyType,
    updateSpecialtyTypeCriteria,
    updateBuildingClassCriteria,
    loadSearchResponse,
    addSearchResults,
    updateTenancyTypeCriteria,
    clearSearchFilters,
    clearFilters,
    clearDataExport,
    loadDataExport,
    initiateDataExport,

    togglePropertyTypeExpansionPanel,
    toggleMarketFilterExpansionPanel,
    updateFilter,
    filterTableData,
    sortTableData,

    searchResults,
    autocompleteResults,
    autocompleteSearchTerm,
    updateCrumb,
    updateMapCrumb,
    updateRangeCrumb,
    clearCrumbs,
    clearLoadedCrumbs,
    setLoadedSearchCrumbs,
    loadingSearch,
    clearSearchResponse,
    replaceSearchCriteria,
    replaceSearchCrumbs,
    mapCrumbClear,
    storeSearchCleared,
    storeSearchRun,
    updateSearchPolygon,
    saveMapSearchSettings,
    toggleViewSavedModal,
    toggleNameSaveModal,
    onSearchPage,
    onGroupPage,
    loadSavedSearches,
    loadSavedGroups,
    setSearchName,
    clearSavedSearches,
    storeSelectedArrayFromSave,
    toggleRunSavedSearch,
    setRetainSelectedItems,
    storeSavedCoordinates,
    setCurrentGroup,
    clearCurrentGroup,
    setUsersSavedGroups,
};
