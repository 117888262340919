const UPDATE_STATE_DOCUMENT_REQUIREMENTS = "UPDATE_STATE_DOCUMENT_REQUIREMENTS";
const SORT_COLUMN = "SORT_COLUMN";
const FILTER_DOCUMENTS = "FILTER_DOCUMENTS";
const GET_DOCUMENT_TYPE = "GET_DOCUMENT_TYPE";
const GET_LOCATION_STATES = "GET_LOCATION_STATES";
const DOCUMENT_TYPE_INPUT_CHANGE = "DOCUMENT_TYPE_INPUT_CHANGE";
const CLEAR_REQUEST_DOCUMENT_UPDATE_ERRORS = "CLEAR_REQUEST_DOCUMENT_UPDATE_ERRORS";
const ADD_DOCUMENT_TYPE_UPDATE_ERRORS = "ADD_DOCUMENT_TYPE_UPDATE_ERRORS";

export default {
    UPDATE_STATE_DOCUMENT_REQUIREMENTS,
    SORT_COLUMN,
    FILTER_DOCUMENTS,
    GET_DOCUMENT_TYPE,
    GET_LOCATION_STATES,
    DOCUMENT_TYPE_INPUT_CHANGE,
    CLEAR_REQUEST_DOCUMENT_UPDATE_ERRORS,
    ADD_DOCUMENT_TYPE_UPDATE_ERRORS,
};
