import React from "react";
import { connect } from "react-redux";
import CorporateDataDealsContainer from "./CorporateDataDealsContainer";
import BrokerDealsContainer from "./BrokerDealsContainer";
import DataAdminDealsContainer from "./DataAdminDealsContainer";

class DealsContainer extends React.Component {
    render() {
        switch (this.props.role) {
            case "broker": {
                return <BrokerDealsContainer user={this.props.user} role={this.props.role} />;
            }
            case "debtEquity": {
                return <BrokerDealsContainer user={this.props.user} role={this.props.role} />;
            }
            case "evp": {
                return <BrokerDealsContainer user={this.props.user} role={this.props.role} />;
            }
            case "portlandBrokerAdmin": {
                return <BrokerDealsContainer user={this.props.user} role={this.props.role} />;
            }
            case "arizonaBrokerAdmin": {
                return <BrokerDealsContainer user={this.props.user} role={this.props.role} />;
            }
            case "dataEntry": {
                return <CorporateDataDealsContainer user={this.props.user} />;
            }
            case "dataAdmin": {
                return <DataAdminDealsContainer user={this.props.user} />;
            }
            default: {
                return null;
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        role: state.authReducer.userProfile.role,
        user: state.authReducer.userProfile.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DealsContainer);
