import axios from "axios";
import actions from "./actions";
import { push } from "connected-react-router";
import { request } from "../../utils/axios-wrapper";
import configs from "../../../../config";
import https from "https";
import constants from "./constants";
import utilOperations from "../../utils/operations";
import get from "lodash/get";
import utils from "../../utils/index";
import sortBy from "lodash/sortBy";
import includes from "lodash/includes";
import ReactGA from "react-ga4";

const loadUserProfile = (data, currentPath) => {
    return (dispatch, getState) => {
        if (data.token) {
            localStorage.setItem("token", data.token);
        }
        let userAccount = {};
        let userSession = {};
        const userAccountKey = data.authenticatedUser.userAccountKey;
        request({
            method: "get",
            url: "/admin/account/" + userAccountKey,
        })
            .then((response) => {
                userAccount = response.data;
                userSession = {
                    userProfile: {
                        ...data.user,
                        role: convertRole(data.user.role),
                        allowedRoles: convertRoles(data.user.allowedRoles),
                    },
                    authenticatedUserProfile: {
                        ...data.authenticatedUser,
                        role: convertRole(data.authenticatedUser.role),
                        allowedRoles: convertRoles(data.authenticatedUser.allowedRoles),
                        allowedUserProfiles: sortBy(userAccount.allowedProxies, (proxy) => {
                            return proxy.name;
                        }),
                    },
                };
                dispatch(actions.resetState());
                dispatch(actions.loadUserProfile(userSession));
                ReactGA.set({ userId: userSession.authenticatedUserProfile.personKey });
                ReactGA.event("Role Logged In: " + userSession.userProfile.role, {
                    role: userSession.userProfile.role,
                });

                console.log("authOperations.loadUserProfile(),currentPath=" + currentPath);
                if (currentPath && currentPath.length > 0) {
                    console.log("authOperations.loadUserProfile(),restoring currentPath=" + currentPath);
                    dispatch(push(currentPath));
                } else {
                    console.log("authOperations.loadUserProfile(),no currentPath,load default URL based on role");
                    const role = get(userSession, "userProfile.role", null);
                    if (includes(["broker", "evp", "debtEquity", "portlandBrokerAdmin", "arizonaBrokerAdmin"], role)) {
                        dispatch(push("/dashboard"));
                    } else if (includes(["debtEquity", "propertyManagement", "transition"], role)) {
                        dispatch(push("/properties"));
                    } else if (includes(["accounting"], role)) {
                        dispatch(push("/vouchers"));
                    } else if (includes(["appraiser"], role)) {
                        dispatch(push("/comps"));
                    } else if (includes(["auditor"], role)) {
                        dispatch(push("/audit"));
                    } else {
                        dispatch(push("/listings"));
                    }
                }
            })
            .catch((err) => {
                console.log("couldnt get user account " + err);
                dispatch(utilOperations.reportError(err, "auth:operations:loadUserProfile Error"));
            });
    };
};

const getUserProfile = (currentPath) => {
    return (dispatch, getState) => {
        request(
            {
                method: "get",
                url: "/user/profile",
            },
            dispatch
        )
            .then((response) => {
                dispatch(loadUserProfile(response.data, currentPath));
            })
            .catch((error) => {
                console.error("getUserInfo,failed to retrieve auth info");
                console.error(error);
            });
    };
};

const redirectToLogin = () => {
    return (dispatch, getState) => {
        dispatch(actions.redirectToLogin());
    };
};

const login = (code) => {
    return (dispatch, getState) => {
        const agent = new https.Agent({
            rejectUnauthorized: false,
        });
        let url = configs.HOST_URL + "/login?code=" + code; //'https://ndpservice.kiddermathews.com:8443/login?code='+code;
        axios
            .get(url, {
                httpsAgent: agent,
                crossDomain: true,
            })
            .then((response) => {
                const savedLocation = localStorage.getItem("savedUrl");
                localStorage.removeItem("savedUrl");
                console.log("authOperations.login(),LocalStorage.savedUrl=" + savedLocation);
                dispatch(loadUserProfile(response.data, savedLocation));
            })
            .catch((err) => {
                console.log("login failure");
                dispatch(push("/"));
                dispatch(utilOperations.reportError(err, "auth:operations:login Error"));
            });
    };
};

const hideSnackbar = actions.hideSnackbar;

const changeProfile = (value) => {
    return (dispatch, _) => {
        dispatch(actions.changeProfile(value));
    };
};

const switchUserProfile = (userAccountKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "switchUser"));
        request(
            {
                method: "post",
                url: constants.urls.switch_user_profile(userAccountKey),
            },
            dispatch
        )
            .then((response) => {
                localStorage.setItem("token", response.data.token);
                dispatch(loadUserProfile(response.data));
                dispatch(push("/"));
                dispatch(utils.actions.somethingSaving(false, "switchUser"));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utils.actions.somethingSaving(false, "switchUser"));
            });
    };
};

const changeUser = (value) => {
    return (dispatch, _) => {
        request(
            {
                method: "get",
                url: constants.urls.get_user_details(value),
            },
            dispatch
        )
            .then((response) => {
                dispatch(
                    actions.changeUser({
                        ...response.data,
                        role: convertRole(response.data.roles[0]),
                        user: response.data.username,
                    })
                );
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

const convertRoles = (roles) => {
    const convertedRoles = [];
    if (roles) {
        roles.forEach((role) => {
            convertedRoles.push(convertRole(role));
        });
    }
    return convertedRoles;
};

const convertRole = (role) => {
    switch (role) {
        case "ROLE_BROKER": {
            return "broker";
        }
        case "ROLE_BROKER_ADMIN": {
            return "broker";
        }
        case "ROLE_VOUCHER_DATA_ENTRY": {
            return "dataEntry";
        }
        case "ROLE_PROPERTY_DATA_ENTRY": {
            return "dataEntry";
        }
        case "ROLE_ADMIN": {
            return "dataAdmin";
        }
        case "ROLE_PORTLAND_ADMIN": {
            return "portlandBrokerAdmin";
        }
        case "ROLE_ARIZONA_BROKER_ADMIN": {
            return "arizonaBrokerAdmin";
        }
        case "ROLE_PORTLAND_VOUCHER_ADMIN": {
            return "portlandBrokerAdmin";
        }
        case "ROLE_ACCOUNTING": {
            return "accounting";
        }
        case "ROLE_PROPERTY_MANAGEMENT": {
            return "propertyManagement";
        }
        case "ROLE_EVP": {
            return "evp";
        }
        case "ROLE_GENERAL_USER": {
            return "general";
        }
        case "ROLE_OFFICE_MANAGER": {
            return "officeManager";
        }
        case "ROLE_RESEARCH": {
            return "research";
        }
        case "ROLE_RESEARCH_ADMIN": {
            return "researchAdmin";
        }
        case "ROLE_TRANSITION_SPECIALIST": {
            return "transition";
        }
        case "ROLE_DEBT_EQUITY": {
            return "debtEquity";
        }
        case "ROLE_APPRAISER": {
            return "appraiser";
        }
        case "ROLE_AUDITOR": {
            return "auditor";
        }
        default:
            return "anonymous";
    }
};

export default {
    hideSnackbar,
    changeProfile,
    getUserProfile,
    login,
    changeUser,
    redirectToLogin,
    switchUserProfile,
};
