import configs from "../../../config";
import get from "lodash/get";
import each from "lodash/each";

const HOST = configs.HOST_URL + "/";
const CRM_URL = HOST + "crm/";

const urls = {
    get_contacts_for_user: (user) => {
        return CRM_URL + "contacts/user/" + user;
    },
    get_contact: (contactId) => {
        return CRM_URL + "contacts/" + contactId;
    },
    delete_contact: (contactId) => {
        return CRM_URL + "contacts/" + contactId;
    },
    create_or_save_contact: () => {
        return CRM_URL + "contacts";
    },
};

const queryGroups = {
    contacts: [
        "contactCompanyDisplayName",
        "contactEmailAddress",
        "contactPhoneNumber",
        ["contactFirstName", null, "contactLastName"],
    ],
};

const filterByQuery = (data, group, query) => {
    let included = false;
    each(queryGroups[group], (column) => {
        if (Array.isArray(column)) {
            let complexQuery = column
                .map((part) => {
                    return get(data, part, " ");
                })
                .join("");
            if (complexQuery.toString().toLowerCase().includes(query)) {
                included = true;
            }
        } else {
            if (get(data, column)) {
                if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                    included = true;
                }
            }
        }
    });
    return included;
};

export default {
    urls,
    filterByQuery,
};
