import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";

const DeleteConfirmationModal = (props) => {
    const { classes, deleteRule, toggleModal, parentEntityTypeKey, modal } = props;

    const { ruleKey } = modal.data;

    return (
        <React.Fragment>
            <div className={classes.paper}>
                <Typography className={classes.modalHeader} variant="h6">
                    Delete Rule?
                </Typography>
                <span style={{ marginBottom: "20px" }}>Are you sure you want to delete this rule?</span>
                <span>
                    <Button variant="outlined" onClick={() => toggleModal(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => deleteRule(ruleKey, parentEntityTypeKey)}
                        className={classes.deleteButton}
                    >
                        Delete
                    </Button>
                </span>
            </div>
        </React.Fragment>
    );
};

const styles = (theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        background: "white",
        top: "50%",
        left: "50%",
        width: "300px",
        height: "125px",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        borderRadius: "3px",
        boxShadow: " 0 3px 7px rgba(0, 0, 0, 0.3)",
        padding: "20px",
        alignItems: "center",
    },
    modalHeader: {
        fontWeight: "600",
        marginBottom: "20px",
    },
    deleteButton: {
        color: "#fff",
        backgroundColor: "#d32f2f",
        "&:hover": {
            backgroundColor: "#c62828",
        },
    },
});

export default withStyles(styles)(DeleteConfirmationModal);
