import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";

const styles = {
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    leftIcon: {
        marginLeft: "25px",
    },
    button: {
        textDecoration: "none",
    },
};

const TransactionsToolbar = (props) => {
    const { classes, filterQuery, addNewTransaction, transactionsTab, numTransactions } = props;

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
            <Grid item>
                <h2 className="page-title">Transactions</h2>
                <div style={{ fontSize: "14px" }}>
                    {`Showing ${get(numTransactions, transactionsTab, 0)} ${
                        numTransactions[transactionsTab] === 1 ? "transaction" : "transactions"
                    } `}
                    {filterQuery > "" && "(Filtered)"}
                </div>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" spacing={0}>
                    <Grid item className={classes.leftIcon}>
                        <Link
                            className={classes.button}
                            to={{
                                pathname: "/transactions/create",
                                state: { newTransaction: true },
                            }}
                        >
                            <Button variant="contained" color="primary" onClick={addNewTransaction}>
                                <Add /> Create New Transaction
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(TransactionsToolbar);
