const RECEIVE_COMPS = "RECEIVE_COMPS";
const COUNT_COMPS = "COUNT_COMPS";
const CLEAR_ALL_COMPS = "CLEAR_ALL_COMPS";
const UPDATE_COMP_ORDER = "UPDATE_COMP_ORDER";
const UPDATE_AUDITOR_LIST_ORDER = "UPDATE_AUDITOR_LIST_ORDER";
const CHANGE_TABS = "CHANGE_TABS";
const RECEIVE_APPROVED_COMPS = "RECEIVE_APPROVED_COMPS";
const CHANGE_HEADER = "CHANGE_HEADER";
const TOGGLE_COMP_AUDIT_FLAG = "TOGGLE_COMP_AUDIT_FLAG";
const TOGGLE_AUDITOR_LIST_AUDIT_FLAG = "TOGGLE_AUDITOR_LIST_AUDIT_FLAG";
const TOGGLE_ALL_AUDITOR_LIST_AUDIT_FLAG = "TOGGLE_ALL_AUDITOR_LIST_AUDIT_FLAG";
const TOGGLE_ALL_COMPS_AUDIT_FLAGS = "TOGGLE_ALL_COMPS_AUDIT_FLAGS";
const TOGGLE_ALL_AUDITOR_LIST_CHECKBOX = "TOGGLE_ALL_AUDITOR_LIST_CHECKBOX";
export default {
    RECEIVE_COMPS,
    COUNT_COMPS,
    CLEAR_ALL_COMPS,
    UPDATE_COMP_ORDER,
    UPDATE_AUDITOR_LIST_ORDER,
    CHANGE_TABS,
    RECEIVE_APPROVED_COMPS,
    CHANGE_HEADER,
    TOGGLE_COMP_AUDIT_FLAG,
    TOGGLE_AUDITOR_LIST_AUDIT_FLAG,
    TOGGLE_ALL_AUDITOR_LIST_AUDIT_FLAG,
    TOGGLE_ALL_COMPS_AUDIT_FLAGS,
    TOGGLE_ALL_AUDITOR_LIST_CHECKBOX,
};
