import React from "react";
import Modal from "@material-ui/core/Modal";
import Autocomplete from "../../../common/js/components/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Search, Close } from "../../../common/js/icons";
import { buildAddress } from "../utils/operations";

const PropertyUpdateModal = (props) => {
    const {
        classes,
        searchFunction,
        selectProperty,
        removeSelectedProperty,
        autocompleteResults,
        propertySearchModalStatus,
        togglePropertySearchModal,
        handleChooseDisplayUpdateDialog,
        displayUpdateDialog,
        updatePropertyForListing,
        updatePropertyForComp,
        dealKey,
        listingKey,
        listingPropertyModal,
        formType,
        user,
        updatePropertyForConsulting,
        consultingDealKey,
        errors,
    } = props;

    const handleUpdate = (value) => {
        handleChooseDisplayUpdateDialog(value);
    };

    const handleCancel = () => {
        togglePropertySearchModal(false);
        removeSelectedProperty();
    };

    const updatePropertyForForm = (formType) => {
        if (formType === "listing") {
            updatePropertyForListing(listingKey, get(listingPropertyModal, "selectedProperty.propertyKey"), user);
        } else if (formType === "comp") {
            updatePropertyForComp(dealKey, get(listingPropertyModal, "selectedProperty.propertyKey"), user);
        } else if (formType === "consulting") {
            updatePropertyForConsulting(
                consultingDealKey,
                get(listingPropertyModal, "selectedProperty.propertyKey"),
                user
            );
        }
        removeSelectedProperty();
    };

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
            <Modal
                aria-labelledby="property-update-modal"
                aria-describedby="update-listing-property"
                open={propertySearchModalStatus.open}
                onClose={removeSelectedProperty}
            >
                <div style={getModalStyle()} className={classes.paper}>
                    <h4 className="modal-title">Select a Property</h4>
                    <Grid container spacing={0}>
                        <Grid item xs>
                            <Divider />
                        </Grid>
                    </Grid>
                    <React.Fragment>
                        <div className={classes.instruction}>
                            <Typography variant="body1">
                                Search the KM database to find the property associated with your listing.
                            </Typography>
                        </div>
                        <Autocomplete
                            id="property"
                            label={"Property Name"}
                            error={get(errors, `propertyKey`, null)}
                            itemToString={(i) => (i == null ? "" : get(i, "propertyAttributes.propertyName", ""))}
                            onSelect={(p, b) => selectProperty(p)}
                            placeholder="Search by property name, address, etc."
                            searchFunction={(input) => searchFunction(input)}
                            startAdornment={<Search />}
                            suggestions={autocompleteResults}
                            options={{
                                key: "propertyKey",
                                display: (suggestion) => {
                                    return get(suggestion, "propertyAttributes.propertyName", "");
                                },
                                displaySub: (suggestion) => {
                                    return get(suggestion, "primaryAddress.address1", "");
                                },
                            }}
                        />
                        &nbsp;
                        {listingPropertyModal.selectedProperty.propertyKey ? (
                            <Card className={classes.cardSelected}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Grid container alignItems="center" justify="space-between" spacing={0}>
                                            <Grid item xs>
                                                <Typography component="h4" variant="h4">
                                                    {get(
                                                        listingPropertyModal,
                                                        "selectedProperty.propertyAttributes.propertyName"
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.propertyAddy}
                                                >
                                                    {buildAddress(
                                                        get(listingPropertyModal, "selectedProperty.primaryAddress", {})
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <div className={classes.close} onClick={removeSelectedProperty}>
                                                    <Close />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </div>
                            </Card>
                        ) : (
                            <Card className={classes.card}></Card>
                        )}
                    </React.Fragment>

                    <Grid container justify="flex-end">
                        <Grid item>
                            <Button variant="outlined" onClick={() => handleCancel()}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => handleUpdate(true)}>
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
            {formType === "consulting" ? (
                <Dialog open={displayUpdateDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                    <DialogTitle id="confirmation-dialog-title">
                        Are you sure you want to update the Property Address you have selected?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => handleUpdate(false)} color="primary">
                            No
                        </Button>
                        <Button onClick={() => updatePropertyForForm(formType)} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : formType === "listing" ? (
                <Dialog open={displayUpdateDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                    <DialogTitle id="confirmation-dialog-title">
                        Are you sure you want to move this Listing to the Property you have selected?
                    </DialogTitle>
                    <DialogContent>
                        If there is a Property Request that is only associated with this Listing, it will be deleted. If
                        the original Property associated with the Listing already exists, it will remain unchanged
                        outside of this update.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleUpdate(false)} color="primary">
                            No
                        </Button>
                        <Button onClick={() => updatePropertyForForm(formType)} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : (
                <Dialog open={displayUpdateDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                    <DialogTitle id="confirmation-dialog-title">
                        Are you sure you want to move this Comp to the Property you have selected?
                    </DialogTitle>
                    <DialogContent>
                        If there is a Property Request that is only associated with this Comp, it will be deleted. If
                        the original Property associated with the Comp already exists, it will remain unchanged outside
                        of this update.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleUpdate(false)} color="primary">
                            No
                        </Button>
                        <Button onClick={() => updatePropertyForForm(formType)} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Grid>
    );
};

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = (theme) => ({
    card: {
        display: "flex",
        marginBottom: "40px",
        border: "1px solid #dddddd",
        boxShadow: "none",
        minHeight: "74px",
        backgroundColor: "#f4f4f4",
    },
    cardSelected: {
        display: "flex",
        marginBottom: "40px",
        border: "1px solid #2C99D2",
        boxShadow: "none",
    },
    details: {
        width: "100%",
    },
    content: {
        flex: "1 0 auto",
        height: "100%",
        padding: "16px !important",
        // listed as important because in this modal, cards get 24px paddingBottom added to last-item
    },
    cover: {
        width: 151,
    },
    controls: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    instruction: {
        marginTop: "24px",
        marginBottom: "24px",
    },
    propertyType: {
        textTransform: "uppercase",
        fontSize: "13px",
        color: "#9e9e9e",
        marginTop: "-8px",
        marginBottom: "10px",
        fontWeight: "bold",
    },
    close: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    propertyAddy: {
        textTransform: "uppercase",
        fontSize: "13px",
    },
});

export default withStyles(styles)(PropertyUpdateModal);
