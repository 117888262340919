import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Chip, Typography } from "@material-ui/core";
import { chipColors } from "./constants";

const ConditionChips = (props) => {
    const { classes, noWrap } = props;
    const { conditions } = props.rule;
    return (
        <React.Fragment>
            <Typography noWrap={noWrap}>
                {conditions.map((condition, i) => {
                    const { conditionValue, conditionType, conditionOperator } = condition;
                    const label = conditionOperator
                        ? `${conditionType.displayName} ${conditionOperator.displayName} ${conditionValue}`
                        : conditionType.displayName;
                    return (
                        <Chip
                            variant="outlined"
                            className={classes.chipStyle}
                            style={{ border: `0.2em solid ${chipColors.hidden}` }}
                            key={i}
                            label={label}
                        />
                    );
                })}
            </Typography>
        </React.Fragment>
    );
};

const styles = () => ({
    chipStyle: {
        marginRight: "5px",
        marginBottom: "5px",
    },
});

export default withStyles(styles)(ConditionChips);
