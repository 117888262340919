import React from "react";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BSInput from "../../../common/js/components/BSInput";
import MultiAddInput from "../../../common/js/components/MultiAddInput";
import Anchor from "./Anchor";
import ErrorBoundary from "../../../common/js/components/ErrorBoundary";
import toLower from "lodash/toLower";

class RetailForm extends React.Component {
    handleAttributeUpdate = (event) => {
        this.props.onChange(event.target.id, event.target.value);
    };

    render() {
        const {
            errors,
            disableInputs,
            handleAddArrayElement,
            handleUpdateArrayElement,
            handleDeleteArrayElement,
            dataPathPrefix,
            data,
            listingStatus,
            transactionType,
        } = this.props;

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="singleTenantUse"
                            label="SINGLE TENANT PROPERTY USE"
                            placeholder="Enter single tenant property use"
                            error={errors.singleTenantUse}
                            value={data.singleTenantUse}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="multiTenantType"
                            label="MULTI TENANT PROPERTY TYPE"
                            placeholder="Enter multi tenant property type"
                            error={errors.multiTenantType}
                            value={data.multiTenantType}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                {toLower(transactionType) === "sale" && listingStatus === "Appraisal" && (
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={4}>
                            <BSInput
                                id="anchorSf"
                                label="Anchor SF"
                                placeholder="Enter anchor sf"
                                error={errors.anchorSf}
                                value={data.anchorSf}
                                onChange={this.handleAttributeUpdate}
                                disabled={disableInputs}
                                integer
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BSInput
                                id="padSf"
                                label="Pad SF"
                                placeholder="Enter pad sf"
                                error={errors.padSf}
                                value={data.padSf}
                                onChange={this.handleAttributeUpdate}
                                disabled={disableInputs}
                                integer
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BSInput
                                id="shopSf"
                                label="Shop SF"
                                placeholder="Enter shop sf"
                                error={errors.shopSf}
                                value={data.shopSf}
                                onChange={this.handleAttributeUpdate}
                                disabled={disableInputs}
                                integer
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container alignItems="flex-start" spacing={8} style={{ marginBottom: "10px" }}>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.liveWorkFlg ? true : false}
                                        onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                        value="liveWorkFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Live/Work"
                            />
                        </FormGroup>
                    </Grid>
                    {toLower(transactionType) === "sale" && listingStatus === "Appraisal" && (
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.shadowAnchoredFlg ? true : false}
                                            onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                            value="shadowAnchoredFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Shadow Anchored"
                                />
                            </FormGroup>
                        </Grid>
                    )}
                </Grid>
                <ErrorBoundary>
                    <MultiAddInput
                        addButtonText="Add Anchor"
                        disableInputs={disableInputs === true}
                        displayFirstWhenEmpty={Boolean(true)}
                        elements={data.anchors}
                        onAdd={() => handleAddArrayElement(dataPathPrefix + ".anchors")}
                        onUpdate={(e, index) =>
                            handleUpdateArrayElement(dataPathPrefix + ".anchors", index, e.target.id, e.target.value)
                        }
                        render={(element, elementIndex) => (
                            <Anchor
                                element={element}
                                elementIndex={elementIndex}
                                onDelete={(index) => handleDeleteArrayElement(dataPathPrefix + ".anchors", index)}
                                {...this.props}
                            />
                        )}
                    />
                </ErrorBoundary>
            </div>
        );
    }
}

export default RetailForm;
