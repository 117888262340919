import get from "lodash/get";
import includes from "lodash/includes";
import utils, { getFromAttributeValues, formatPrice } from "../utils/operations";

export const getCompInfo = (comp) => {
    const confidential = getFromAttributeValues(comp, ["confidentialFlg", "confidential_flg"], false)
        ? "Confidential "
        : "";
    const compStatus =
        getFromAttributeValues(comp, ["comp_status", "dealStatus"], "").toLowerCase() === "outside"
            ? "Outside"
            : getFromAttributeValues(comp, ["comp_status", "dealStatus"], "").toLowerCase() === "appraisal"
            ? "Appraisal"
            : "KM";

    const propertyType = getFromAttributeValues(comp, ["property_type", "propertyType"], "");
    const transactionType = utils.parseTransactionType(
        getFromAttributeValues(comp, ["transaction_type", "transactionType"], "")
    );

    return `${confidential} ${compStatus}, ${propertyType}, ${transactionType}`.trim();
};

export const getLeaseRate = (comp) => {
    const hasRatePerYear =
        getFromAttributeValues(comp, ["rate_per_square_foot_per_year", "ratePerSquareFootPerYear"], null) !== null;
    const hasRatePerMonth =
        getFromAttributeValues(comp, ["rate_per_square_foot_per_month", "ratePerSquareFootPerMonth"], null) !== null;
    const isOfficeRetailHealthcare = includes(
        ["Office", "Retail", "Healthcare"],
        getFromAttributeValues(comp, ["property_type", "propertyType"])
    );
    const isWaOrAzSf =
        includes(["WA", "OR", "AZ"], getFromAttributeValues(comp, ["state_code", "state"])) ||
        get(comp, "city") === "San Francisco";

    if (hasRatePerYear && isOfficeRetailHealthcare && isWaOrAzSf)
        return `$${getFromAttributeValues(
            comp,
            ["rate_per_square_foot_per_year", "ratePerSquareFootPerYear"],
            0
        ).toFixed(2)} /SF/YR`;
    if (hasRatePerMonth)
        return `$${getFromAttributeValues(
            comp,
            ["rate_per_square_foot_per_month", "ratePerSquareFootPerMonth"],
            0
        ).toFixed(2)} /SF/MTH`;
    return "Call Broker";
};

export const getSalePrice = (comp) => {
    if (getFromAttributeValues(comp, ["sale_price", "salePrice"], null) !== null) {
        return `$${formatPrice(getFromAttributeValues(comp, ["sale_price", "salePrice"], 0))}`;
    }
    return "Call Broker";
};
