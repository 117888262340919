import React from "react";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Delete from "../../../../node_modules/@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "../../../common/js/components/Autocomplete";
import Search from "../../../../node_modules/@material-ui/icons/Search";

const styles = (theme) => ({
    iconOnly: {
        marginLeft: 0,
        marginBottom: "10px",
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
});
class Proxy extends React.Component {
    handleUserProxySelection = (p, b, index) => {
        this.props.handleUpdateArrayElement("userAccount.allowedProxies", index, "proxyFor", p.username);
        this.props.handleUpdateArrayElement(
            "userAccount.allowedProxies",
            index,
            "name",
            p.firstName + " " + p.lastName
        );
    };

    handleSearchTest = (searchInput) => {
        this.props.onSearch(searchInput);
    };

    render() {
        const { classes, element, elementIndex, errors, disableInputs, onDelete, searchResults } = this.props;

        return (
            <div>
                <Grid container alignItems="center" spacing={8}>
                    <Grid item xs={11}>
                        <Autocomplete
                            id="proxyFor"
                            label={"Proxy For"}
                            error={get(errors, `proxies.${elementIndex}.proxyFor`, null)}
                            initialInputValue={
                                get(element, `proxyFor`, null) != null
                                    ? `${get(element, `name`, "")} (${get(element, `proxyFor`, "")})`
                                    : ""
                            }
                            itemToString={(i) => get(i, "firstName", null)}
                            onSelect={(p, b) => this.handleUserProxySelection(p, b, elementIndex)}
                            placeholder="Enter proxy"
                            searchFunction={this.handleSearchTest}
                            startAdornment={elementIndex !== 0 && <Search />}
                            suggestions={searchResults}
                            disabled={false}
                            options={{
                                key: "username",
                                display: (suggestion) => {
                                    return (
                                        get(suggestion, "firstName", "") +
                                        " " +
                                        get(suggestion, "lastName", "") +
                                        " (" +
                                        get(suggestion, "username") +
                                        ")"
                                    );
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {disableInputs !== true && (
                            <Button onClick={() => onDelete(elementIndex)} className={classes.iconOnly}>
                                <Delete />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Proxy);
