import actions from "./actions";
import constants from "../constants";
import utils from "../../utils/index";
import get from "lodash/get";
import each from "lodash/each";
import cloneDeep from "lodash/cloneDeep";
import toLower from "lodash/toLower";
import toUpper from "lodash/toUpper";
import { propertyTypeFilters } from "../../property/constants";
import { criteriaCrumbs } from "../constants";
import { request } from "../../utils/axios-wrapper";
import auditOperations from "../../audit/ducks/operations";
import compOperations from "../../comp/ducks/operations";
import listingOperations from "../../listing/ducks/operations";
import utilOperations, { getFromAttributeValues, parseTransactionType } from "../../utils/operations";
import orderBy from "lodash/orderBy";
import compact from "lodash/compact";
import uniq from "lodash/uniq";
import includes from "lodash/includes";
import ReactGA from "react-ga4";

const handlePropertyTypeCheckboxClick = (searchType, propertyType, checked) => {
    return (dispatch, getState) => {
        dispatch(actions.updatePropertyType(searchType, propertyType, checked));
    };
};

const handleSpecialtyTypeCheckboxClick = (searchType, propertyType, field, checked) => {
    return (dispatch, getState) => {
        dispatch(actions.updateSpecialtyTypeCriteria(searchType, propertyType, field, checked));
    };
};

const handleTenancyTypeCheckboxClick = (searchType, field, checked) => {
    return (dispatch, getState) => {
        dispatch(actions.updateTenancyTypeCriteria(searchType, field, checked));
    };
};

const handleUpdateSearchCriteria = (searchType, field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateSearchCriteria(searchType, field, value));
    };
};

const executeSearch = (searchType, dispatchCallback) => {
    return (dispatch, getState) => {
        dispatch(performSearch(searchType, dispatchCallback));
    };
};

const handleDataExport = (searchType, dataSetType, tab) => {
    return (dispatch, getState) => {
        if (get(getState().searchReducer[searchType], "dataExport.exportInProgress", false) === false) {
            ReactGA.event("Data Export", {
                searchType: searchType,
                dataType: dataSetType,
                tab: tab,
            });
            dispatch(actions.initiateDataExport(searchType));
            dispatch(executeSearchForExport(searchType, dataSetType, tab));
        }
    };
};

const handleSaveSearch = (searchType) => {
    return (dispatch, getState) => {
        ReactGA.event("Save Search", {
            searchType: searchType,
        });
        dispatch(actions.toggleNameSaveModal(false));
        request(
            {
                url: constants.urls.save_search(searchType),
                method: "post",
                crossDomain: true,
                data: buildSavedSearch(getState(), searchType),
            },
            dispatch
        )
            .then((response) => {
                dispatch(setSearchName(null));
                dispatch(utils.actions.snackbar(true, "success", "Search saved!"));
            })
            .catch((err) => {
                console.log("Search was not able to be saved due to error: " + err);
                dispatch(utils.actions.snackbar(true, "error", "Error saving search!"));
            });
        dispatch(actions.setRetainSelectedItems(false, searchType));
    };
};

const runSavedSearch = (searchKey, searchType) => {
    return (dispatch, getState) => {
        dispatch(storeSearchCleared(false, searchType));
        dispatch(toggleSavedSearchesModal(false));
        dispatch(actions.clearLoadedCrumbs(searchType));
        dispatch(actions.clearSearchResponse(searchType));
        dispatch(actions.loadingSearch(true));
        dispatch(storeSearchRun(true, searchType));
        dispatch(buildSavedCriteriaAndCrumbsAndSelectedItems(getState(), toUpper(searchType), searchKey));

        if (searchType === "LISTINGS") {
            dispatch(executeSearch("LISTINGS", listingOperations.loadSearchResults));
        } else if (searchType === "COMPS") {
            dispatch(compOperations.clearAllComps());
            dispatch(executeSearch("COMPS", compOperations.loadSearchResults));
        } else {
            dispatch(executeSearch("PROPERTY"));
        }
    };
};

const toggleSavedSearchesModal = (open) => {
    return (dispatch, getState) => {
        dispatch(actions.toggleViewSavedModal(open));
    };
};

const toggleNameSearchModal = (open) => {
    return (dispatch, getState) => {
        dispatch(actions.toggleNameSaveModal(open));
    };
};

const setSearchName = (name) => {
    return (dispatch, getState) => {
        dispatch(actions.setSearchName(name));
    };
};

const setSearchSelectedToggle = (checked, searchType) => {
    return (dispatch, getState) => {
        dispatch(actions.setRetainSelectedItems(checked, searchType));
    };
};

const addSelectedToGroup = (searchType, tabName) => {
    return (dispatch, getState) => {
        ReactGA.event("Add to List", {
            searchType: searchType,
            tab: tabName,
        });
        let list = [];
        let selected = new Map();
        if (searchType === "LISTINGS") {
            if (getState().searchReducer.LISTINGS.currentGroup) {
                list = getState().searchReducer.LISTINGS.currentGroup;
            }
            if (tabName === "broker_open") selected = getState().listingReducer.activeSelectedListings;
            else selected = getState().listingReducer.selectedListings;
        } else if (searchType === "COMPS") {
            if (getState().searchReducer.COMPS.currentGroup) {
                list = getState().searchReducer.COMPS.currentGroup;
            }
            if (tabName === "broker_comps") selected = getState().compReducer.brokerComps;
            else selected = getState().compReducer.selectedComps;
        }
        for (var [key, value] of selected) {
            if (value && !list.includes(key)) {
                list.push(key);
            }
        }
        dispatch(utils.actions.snackbar(true, "success", "Added to Current List!"));
        dispatch(actions.setCurrentGroup(searchType, list));
    };
};

const deleteSelectedFromGroup = (searchType) => {
    return (dispatch, getState) => {
        let list = [];
        if (searchType === "LISTINGS") {
            let selected = getState().listingReducer.selectedListingsGroup;
            let listings = getState().listingReducer.listings["listings_group"];
            let updatedListings = [];
            if (listings && selected) {
                for (let [key, value] of selected) {
                    if (!value && !list.includes(key)) {
                        list.push(key);
                    }
                }
                for (let i = 0; i < listings.length; i++) {
                    if (list.includes(get(listings[i], "listingKey", null))) {
                        updatedListings.push(listings[i]);
                    }
                }
                dispatch(listingOperations.setCurrentListingsGroup(updatedListings));
                dispatch(utils.actions.snackbar(true, "success", "Selected Listing(s) deleted from List."));
            } else {
                dispatch(utils.actions.snackbar(true, "error", "No Listings were selected."));
            }
        } else if (searchType === "COMPS") {
            let selected = getState().compReducer.selectedCompsGroup;
            let comps = getState().compReducer.comps["comps_group"];
            let updatedComps = [];
            if (comps && selected) {
                for (let [key, value] of selected) {
                    if (!value && !list.includes(key)) {
                        list.push(key);
                    }
                }
                for (let i = 0; i < comps.length; i++) {
                    if (list.includes(get(comps[i], "dealkey", null))) {
                        updatedComps.push(comps[i]);
                    }
                }
                dispatch(compOperations.setCurrentCompsGroup(updatedComps));
                dispatch(utils.actions.snackbar(true, "success", "Selected Comp(s) deleted from List."));
            } else {
                dispatch(utils.actions.snackbar(true, "error", "No Comps were selected."));
            }
        }
        dispatch(actions.setCurrentGroup(searchType, list));
    };
};

const clearCurrentGroup = (searchType) => {
    return (dispatch, getState) => {
        if (searchType === "LISTINGS") {
            dispatch(listingOperations.setCurrentListingsGroup(null));
        } else if (searchType === "COMPS") {
            dispatch(compOperations.setCurrentCompsGroup(null));
        }
        dispatch(actions.clearCurrentGroup(searchType));
        dispatch(utils.actions.snackbar(true, "success", "List successfully cleared."));
    };
};

const buildCurrentGroup = (data, searchType) => {
    return (dispatch, getState) => {
        let list = [];
        if (searchType === "LISTINGS") {
            let results = get(data, "results", null);
            for (let x = 0; x < results.length; x++) {
                list.push(get(results[x], "listingKey", null));
            }
        } else if (searchType === "COMPS") {
            let results = get(data, "results", null);
            for (let x = 0; x < results.length; x++) {
                list.push(get(results[x], "dealkey", null));
            }
        }
        dispatch(actions.setCurrentGroup(searchType, list));
    };
};

const handleSaveGroup = (searchType) => {
    return (dispatch, getState) => {
        dispatch(actions.toggleNameSaveModal(false));
        request(
            {
                url: constants.urls.save_group(),
                method: "post",
                crossDomain: true,
                data: buildSavedGroup(getState(), searchType),
            },
            dispatch
        )
            .then((response) => {
                dispatch(setSearchName(null));
                dispatch(utils.actions.snackbar(true, "success", "List saved!"));
            })
            .catch((err) => {
                console.log("List was not able to be saved due to error: " + err);
                dispatch(utils.actions.snackbar(true, "error", "Error saving List!"));
            });
        dispatch(actions.setRetainSelectedItems(false, searchType));
    };
};

const runUpdateCurrentGroup = (groupType) => {
    return (dispatch, getState) => {
        const keyList = getState().searchReducer[groupType].currentGroup;
        dispatch(actions.toggleViewSavedModal(false));
        if (keyList && keyList.length !== 0) {
            dispatch(actions.loadingSearch(true));
            request(
                {
                    url: constants.urls.load_group(groupType),
                    method: "post",
                    crossDomain: true,
                    data: keyList,
                },
                dispatch
            )
                .then((response) => {
                    if (groupType === "LISTINGS") {
                        dispatch(
                            listingOperations.setCurrentListingsGroup(get(response.data, "results", null), keyList)
                        );
                    } else if (groupType === "COMPS") {
                        dispatch(compOperations.setCurrentCompsGroup(get(response.data, "results", null), keyList));
                    }
                    dispatch(actions.loadingSearch(false));
                })
                .catch((err) => {
                    console.log("Current List was not able to be loaded due to error: " + err);
                    console.log(err);
                    dispatch(utils.actions.snackbar(true, "error", "Error loading current List!"));
                });
        }
    };
};

const runSavedGroup = (groupKey, groupType, groupOrder) => {
    return (dispatch, getState) => {
        dispatch(actions.toggleViewSavedModal(false));
        dispatch(actions.loadingSearch(true));
        request(
            {
                url: constants.urls.load_group(groupType),
                method: "post",
                crossDomain: true,
                data: JSON.parse(groupOrder),
            },
            dispatch
        )
            .then((response) => {
                dispatch(buildCurrentGroup(response.data, groupType));
                if (groupType === "LISTINGS") {
                    dispatch(listingOperations.setCurrentListingsGroup(get(response.data, "results"), groupOrder));
                } else if (groupType === "COMPS") {
                    dispatch(compOperations.setCurrentCompsGroup(get(response.data, "results", null), groupOrder));
                }
                dispatch(actions.loadingSearch(false));
                dispatch(utils.actions.snackbar(true, "success", "Saved List Loaded!"));
            })
            .catch((err) => {
                console.log("Saved List was not able to be loaded due to error: " + err);
                dispatch(utils.actions.snackbar(true, "error", "Error loading saved List!"));
            });
    };
};

const getSavedGroupsForUser = (userAccountKey, groupType) => {
    return (dispatch, getState) => {
        const modalShowing = getState().searchReducer.viewSavedModal;
        dispatch(utils.actions.loading(true));
        request(
            {
                url: constants.urls.load_users_groups(userAccountKey, groupType),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setUsersSavedGroups(groupType, response.data));
                // pre-sorts all savedGroups desc by Date Created
                dispatch(sortGridTableData("Date Created", "groups", groupType, "desc"));
                if (!modalShowing) {
                    dispatch(toggleSavedSearchesModal(true));
                }
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("Saved List was not able to be loaded due to error: " + err);
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.snackbar(true, "error", "Error loading saved Lists!"));
            });
    };
};

const deleteSavedGroup = (groupId, groupType) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.delete_saved_group(groupId),
                method: "delete",
            },
            dispatch
        )
            .then((response) => {
                const userAccountKey = getState().authReducer.userProfile.userAccountKey;
                dispatch(getSavedGroupsForUser(userAccountKey, groupType));
                dispatch(utils.actions.snackbar(true, "success", "Saved List deleted!"));
            })
            .catch((err) => {
                console.log("Saved List was not able to be deleted due to error: " + err);
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.snackbar(true, "error", "Error deleting saved List!"));
            });
    };
};

const buildSavedGroup = (state, searchType) => {
    let orderedSelected = [];
    const selected = get(state, `searchReducer.${searchType}.currentGroup`, {});
    if (searchType === "LISTINGS") {
        let listings = get(state, `listingReducer.listings.listings_group`);
        for (let i = 0; i < listings.length; i++) {
            if (selected.includes(get(listings[i], "listingKey", null))) {
                orderedSelected.push(get(listings[i], "listingKey", null));
            }
        }
    } else if (searchType === "COMPS") {
        let comps = get(state, `compReducer.comps.comps_group`);
        for (let i = 0; i < comps.length; i++) {
            if (selected.includes(get(comps[i], "dealkey", null))) {
                orderedSelected.push(get(comps[i], "dealkey", null));
            }
        }
    }

    let savedGroup = {
        selectedItems: JSON.stringify(orderedSelected),
        groupType: searchType,
        userAccountKey: state.authReducer.userProfile.userAccountKey,
        groupName: state.searchReducer.savedSearchName,
    };

    return savedGroup;
};

const clearSavedSearches = (searchType) => {
    return (dispatch, getState) => {
        dispatch(actions.clearSavedSearches(searchType));
    };
};

const viewSavedSearches = (searchType, userAccountKey) => {
    return (dispatch, getState) => {
        ReactGA.event("View Saved Searches", {
            searchType: searchType,
        });
        const modalShowing = getState().searchReducer.viewSavedModal;
        dispatch(utils.actions.loading(true));
        dispatch(clearSavedSearches(searchType));
        request(
            {
                url: constants.urls.get_saved_searches(searchType.toLowerCase(), userAccountKey),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.loadSavedSearches(response.data, searchType));
                let tab = searchType === "PROPERTY" ? "savedSearchesNoSelections" : "savedSearchesWithSelections";
                // pre-sorts all savedSearches desc by Date Created
                dispatch(sortGridTableData("Date Created", tab, searchType, "desc"));
                if (!modalShowing) {
                    dispatch(toggleSavedSearchesModal(true));
                }
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log("Searches did not load due to error: " + err);
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.snackbar(true, "error", "Error loading searches!"));
            });
    };
};

const deleteSavedSearch = (searchType, searchId) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.delete_saved_search(searchId),
                method: "delete",
            },
            dispatch
        )
            .then((response) => {
                const userAccountKey = getState().authReducer.userProfile.userAccountKey;
                dispatch(viewSavedSearches(searchType, userAccountKey));
                dispatch(utils.actions.snackbar(true, "success", "Saved search deleted!"));
            })
            .catch((err) => {
                console.log("Saved Search was not able to be deleted due to error: " + err);
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.snackbar(true, "error", "Error deleting saved search!"));
            });
    };
};

const buildSavedSearch = (state, searchType) => {
    const criteria = get(state, `searchReducer.${searchType}.searchCriteria`, {});
    let selected = [];
    if (searchType === "LISTINGS") {
        const listings = get(state, `listingReducer.selectedListings`, {});
        for (let [key, value] of listings) {
            if (value) {
                selected.push(key);
            }
        }
    } else if (searchType === "COMPS") {
        const comps = get(state, `compReducer.selectedComps`, {});
        for (let [key, value] of comps) {
            if (value) {
                selected.push(key);
            }
        }
    }
    const searchCrumbs = get(state, `searchReducer.searchCrumbs.${searchType}`, {});
    let savedSearch = {
        searchCriteria: JSON.stringify(criteria),
        selectedItems: JSON.stringify(selected),
        searchType: searchType,
        userAccountKey: state.authReducer.userProfile.userAccountKey,
        searchName: state.searchReducer.savedSearchName,
        saveSelectedOnly: state.searchReducer[searchType].retainSelectedFromSave,
        searchCrumbs: JSON.stringify(searchCrumbs),
    };
    return savedSearch;
};

const buildSavedCriteriaAndCrumbsAndSelectedItems = (state, searchType, searchKey) => {
    let savedSearches = get(state, `searchReducer.${searchType}.savedSearches`, {});
    let savedSearchCriteria;
    let searchCrumbs;
    let selectedArray;
    let selectedItems = new Map();
    return (dispatch, getState) => {
        for (let x = 0; x < savedSearches.length; x++) {
            if (searchKey === get(savedSearches[x], "savedSearchKey", null)) {
                searchCrumbs = JSON.parse(get(savedSearches[x], "searchCrumbs", null));
                savedSearchCriteria = JSON.parse(get(savedSearches[x], "searchCriteria", null));

                if (get(savedSearches[x], "saveSelectedOnly", false)) {
                    dispatch(actions.setRetainSelectedItems(true, searchType));
                } else {
                    dispatch(actions.setRetainSelectedItems(false, searchType));
                }
                selectedArray =
                    get(savedSearches[x], "selectedItems", null) != null
                        ? JSON.parse(get(savedSearches[x], "selectedItems", null))
                        : [];
                for (let y = 0; y < selectedArray.length; y++) {
                    selectedItems.set(selectedArray[y], true);
                }
            }
        }
        if (get(savedSearchCriteria, "filterCriteria.areaPoints", null) != null) {
            dispatch(
                actions.storeSavedCoordinates(get(savedSearchCriteria, "filterCriteria.areaPoints", null), searchType)
            );
        }
        dispatch(actions.storeSelectedArrayFromSave(searchType, selectedArray));
        dispatch(actions.replaceSearchCriteria(searchType, savedSearchCriteria));
        dispatch(actions.replaceSearchCrumbs(searchType, searchCrumbs));
    };
};

const executeSearchForExport = (searchType, dataSetType, tab) => {
    return (dispatch, getState) => {
        if (
            dataSetType === "all" &&
            tab !== "listings_group" &&
            tab !== "comps_group" &&
            tab !== "broker_open" &&
            tab !== "broker_comps"
        ) {
            request(
                {
                    url: constants.urls.retrieve_data_for_export(searchType),
                    method: "post",
                    crossDomain: true,
                    data: buildSearchRequest(getState(), toUpper(searchType)),
                },
                dispatch
            )
                .then((response) => {
                    dispatch(actions.loadDataExport(searchType, response.data.results));
                    if (searchType === "LISTINGS" || searchType === "COMPS") {
                        window.open(response.data, "_blank");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(actions.clearDataExport(searchType));
                });
        } else {
            let selected = [];
            if (toUpper(searchType) === "LISTINGS") {
                if (tab === "all_km_listings") {
                    const listings = getState().listingReducer.selectedListings;
                    for (let [key, value] of listings) {
                        if (value) {
                            selected.push(key);
                        }
                    }
                } else if (tab === "listings_group") {
                    const listings = getState().listingReducer.selectedListingsGroup;
                    if (dataSetType === "all") {
                        for (let keyVal of listings) {
                            const key = keyVal[0];
                            selected.push(key);
                        }
                    } else {
                        for (let [key, value] of listings) {
                            if (value) {
                                selected.push(key);
                            }
                        }
                    }
                } else if (tab === "broker_open") {
                    const activeListings = getState().listingReducer.activeSelectedListings;
                    if (dataSetType === "all") {
                        for (let keyVal of activeListings) {
                            const key = keyVal[0];
                            selected.push(key);
                        }
                    } else {
                        for (let [key, value] of activeListings) {
                            if (value) {
                                selected.push(key);
                            }
                        }
                    }
                }
            } else if (toUpper(searchType) === "COMPS") {
                if (tab === "all_km_comps") {
                    const comps = getState().compReducer.selectedComps;
                    for (let [key, value] of comps) {
                        if (value) {
                            selected.push(key);
                        }
                    }
                } else if (tab === "comps_group") {
                    const comps = getState().compReducer.selectedCompsGroup;
                    if (dataSetType === "all") {
                        for (let keyVal of comps) {
                            const key = keyVal[0];
                            selected.push(key);
                        }
                    } else {
                        for (let [key, value] of comps) {
                            if (value) {
                                selected.push(key);
                            }
                        }
                    }
                } else if (tab === "broker_comps") {
                    const comps = getState().compReducer.brokerComps;
                    if (dataSetType === "all") {
                        for (let keyVal of comps) {
                            const key = keyVal[0];
                            selected.push(key);
                        }
                    } else {
                        for (let [key, value] of comps) {
                            if (value) {
                                selected.push(key);
                            }
                        }
                    }
                }
            }

            selected = compact(selected);
            selected = uniq(selected);

            request(
                {
                    url: constants.urls.retrieve_selected_data_for_export(searchType),
                    method: "post",
                    crossDomain: true,
                    data: selected,
                },
                dispatch
            )
                .then((response) => {
                    dispatch(actions.loadDataExport(searchType, response.data.results));
                    if (searchType === "LISTINGS" || searchType === "COMPS") {
                        window.open(response.data, "_blank");
                    }
                })
                .catch((err) => {
                    dispatch(utils.actions.snackbar(true, "error", "Error trying to generate export: " + err));
                    dispatch(actions.clearDataExport(searchType));
                });
        }
    };
};

const performSearch = (searchType, dispatchCallback) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "elastic"));
        const searchRequest = buildSearchRequest(getState(), toUpper(searchType));
        const searchBySearchType = () =>
            constants.urls.search(toLower(searchType) === "property_modal" ? "property" : toLower(searchType));
        request(
            {
                url: searchBySearchType(),
                method: "post",
                crossDomain: true,
                data: searchRequest,
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.somethingSaving(false, "elastic"));
                if (searchType !== "property_modal") {
                    dispatch(setLoadedSearchCrumbs(searchType));
                } else {
                    dispatch(actions.autocompleteResults(response.data.results));
                }
                dispatch(actions.loadSearchResponse(toUpper(searchType), response.data));
                dispatch(actions.loadingSearch(false));
                if (dispatchCallback) {
                    dispatch(dispatchCallback());
                }
                ReactGA.event("Search Performed", {
                    searchPayload: searchRequest.searchCriteria.searchTerm,
                });
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, "elastic"));
                dispatch(utils.actions.snackbar(true, "error", "Something went wrong"));
                console.log(err);
            });
    };
};

const searchCall = (state, searchType, dispatch) => {
    const searchRequest = buildSearchRequest(state, toUpper(searchType));
    return request(
        {
            url: constants.urls.search(toLower(searchType)),
            method: "post",
            crossDomain: true,
            data: searchRequest,
        },
        dispatch
    );
};

const loadMoreResults = (searchType) => {
    return (dispatch, getState) => {
        const searchRequest = buildFetchMoreRequest(getState(), searchType);

        request(
            {
                url: constants.urls.search(searchType),
                method: "post",
                crossDomain: true,
                data: searchRequest,
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.addSearchResults(searchType, response.data));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

const buildFetchMoreRequest = (state, searchType) => {
    const currentResults = state.searchReducer[searchType].searchResponse.results.length;
    if (currentResults < state.searchReducer[searchType].searchResponse.totalResultCount) {
        return {
            searchCriteria: {
                ...state.searchReducer[searchType].searchCriteria,
                locationCriteria: buildLocationCriteria(state, searchType),
            },
            startIndex: currentResults,
            numResults: constants.DEFAULT_RESULT_SIZE,
        };
    }
};

const buildSearchRequest = (state, searchType) => {
    let searchCriteria = get(state, `searchReducer.${searchType}.searchCriteria`, {});

    return {
        searchCriteria: {
            ...searchCriteria,
            locationCriteria: buildLocationCriteria(state, searchType),
            offices: buildOffices(searchCriteria),
        },
        requestId: new Date().valueOf() + "",
        startIndex: 0,
        numResults: constants.DEFAULT_RESULT_SIZE,
    };
};

const buildOffices = (searchCriteria) => {
    let officeCriteria = get(searchCriteria, "officeCriteria", {});
    let officeArray = [];
    Object.keys(officeCriteria).forEach((office) => {
        if (officeCriteria[office] === true) officeArray.push(office);
    });
    return officeArray;
};

const buildLocationCriteria = (state, searchType) => {
    let marketCriteria = {};
    const marketFilters = get(state, `searchReducer.${searchType}.searchCriteria.locationCriteria.markets`, {});

    Object.keys(marketFilters).forEach((market) => {
        let submarketCriteria = {};
        const submarketFilters = get(
            state,
            `searchReducer.${searchType}.searchCriteria.locationCriteria.markets.${market}`,
            {}
        );
        Object.keys(submarketFilters).forEach((submarket) => {
            let micromarketCriteria = [];
            const micromarketFilters = get(
                state,
                `searchReducer.${searchType}.searchCriteria.locationCriteria.markets.${market}.${submarket}`,
                {}
            );
            Object.keys(micromarketFilters).forEach((micromarket) => {
                if (micromarketFilters[micromarket].selected) {
                    micromarketCriteria.push(micromarket);
                }
            });
            if (micromarketCriteria.length > 0 || submarketFilters[submarket].selected) {
                submarketCriteria = {
                    ...submarketCriteria,
                    [submarket]: micromarketCriteria,
                };
            }
        });
        if (submarketCriteria.size > 0 || marketFilters[market].selected) {
            marketCriteria = {
                ...marketCriteria,
                [market]: submarketCriteria,
            };
        }
    });

    const states = get(state, `searchReducer.${searchType}.searchCriteria.locationCriteria.states`, null);
    const cities = get(state, `searchReducer.${searchType}.searchCriteria.locationCriteria.cities`, null);
    const zips = get(state, `searchReducer.${searchType}.searchCriteria.locationCriteria.zips`, null);
    const counties = get(state, `searchReducer.${searchType}.searchCriteria.locationCriteria.counties`, null);
    return {
        states: states != null ? states.split(",") : null,
        zips: zips != null ? zips.split(",") : null,
        counties: counties != null ? counties.split(",") : null,
        cities: cities != null ? cities.split(",") : null,
        markets: marketCriteria,
    };
};

const clearSearchFilters = (searchType) => {
    return (dispatch, getState) => {
        dispatch(actions.clearSearchFilters(searchType));
    };
};

const clearFilters = (searchType) => {
    return (dispatch, getState) => {
        dispatch(actions.clearSearchFilters(searchType));
        dispatch(actions.clearFilters(searchType));
        dispatch(clearCrumbsState(searchType));
    };
};

const clearCrumbsState = (searchType) => {
    return (dispatch, getState) => {
        dispatch(actions.clearCrumbs(searchType));
        dispatch(actions.clearLoadedCrumbs(searchType));
    };
};

const clearDataExport = (searchType) => {
    return (dispatch, getState) => {
        dispatch(actions.clearDataExport(searchType));
    };
};

const updateFilter = (field, value, type) => {
    return (dispatch, getState) => {
        dispatch(actions.updateFilter(`${type}.searchCriteria.${field}`, value));
        dispatch(determineParentCheckbox(field, value, type));
        dispatch(updateSearchCrumbs(field, value, type));
    };
};

const updateFilterForRange = (field, value, type) => {
    return (dispatch, getState) => {
        dispatch(actions.updateFilter(`${type}.searchCriteria.${field}`, value));
        dispatch(determineParentCheckbox(field, value, type));
    };
};

const updateSearchCrumbs = (field, value, type) => {
    return (dispatch, getState) => {
        //builds a crumb as each filter is selected (for single input parameters)
        let criteria = [];
        let crumb;
        criteria = field.split(".");
        if (includes(criteria, "propertyTypes")) {
            if (includes(["specialtyTypeCriteria", "buildingTypeCriteria", "buildingClassCriteria"], criteria[2])) {
                let subTypes = cloneDeep(propertyTypeFilters[criteria[1]]["subs"][criteria[2]]["options"]);
                crumb = criteria[1] + "-" + subTypes[criteria[3]]["display"];
            } else {
                crumb = criteria[1];
            }
        } else if (includes(criteria, "dealTypeCriteria")) {
            if (
                !includes(
                    [
                        "minLeaseRate",
                        "maxLeaseRate",
                        "leaseRateType",
                        "minPrice",
                        "maxPrice",
                        "transactionDateFrom",
                        "transactionDateTo",
                        "batchDateFrom",
                        "batchDateTo",
                    ],
                    criteria[1]
                )
            ) {
                crumb = criteriaCrumbs[criteria[0]][criteria[1]]["display"];
            }
        } else if (includes(["statusCriteria", "tenancyCriteria"], criteria[0])) {
            crumb = criteriaCrumbs[criteria[0]][criteria[1]]["display"];
        } else if (includes(criteria, "locationCriteria")) {
            if (includes(criteria, "markets")) {
                //market area filter for markets, submarkets, and micromarkets
                if (criteria.length === 6) {
                    crumb = criteria[2] + "-" + criteria[3] + "-" + criteria[4];
                } else if (criteria.length === 5) {
                    crumb = criteria[2] + "-" + criteria[3];
                } else {
                    crumb = criteria[2];
                }
            } else {
                //location filter input
                if (value === "") {
                    //if empty string entered, crumb is removed/not added
                    value = null;
                }
                crumb = criteriaCrumbs[criteria[0]][criteria[1]]["display"];
            }
        } else if (includes(criteria, "officeCriteria")) {
            crumb = criteria[1] + " Office";
        } else if (includes(criteria, "listingTypeCriteria")) {
            if (!includes(["dateListedFrom", "dateListedTo"], criteria[1])) {
                crumb = criteriaCrumbs[criteria[0]][criteria[1]]["display"];
            }
        } else if (includes(criteria, "searchTerm")) {
            crumb = "Search";
        } else if (includes(criteria, "clientName")) {
            crumb = "True Client Name";
        } else if (criteria[0] === "buildingName") {
            crumb = "Building Name: ";
        } else if (criteria[0] === "kmManagedOnly") {
            crumb = "KM Managed Only";
        }

        if (crumb != null) {
            dispatch(actions.updateCrumb(crumb, type, field, value));
        }
    };
};

const deleteCrumb = (crumb, value, type) => {
    return (dispatch, getState) => {
        let crumbFieldArray = get(crumb, "field", "").split(".");
        if (crumbFieldArray[1] === "markets") {
            let crumbsClone = cloneDeep(getState().searchReducer.loadedSearchCrumbs[type]);
            //when deleting a submarket crumb, delete all associated micromarkets
            if (crumbFieldArray.length === 5) {
                Object.keys(crumbsClone).forEach((x) => {
                    let otherFieldArray = get(crumbsClone[x], "field", "").split(".");
                    if (
                        includes(otherFieldArray, crumbFieldArray[2]) &&
                        includes(otherFieldArray, crumbFieldArray[3]) &&
                        otherFieldArray.length === 6
                    ) {
                        dispatch(updateFilter(crumbsClone[x].field, false, type));
                    }
                });
            }
            //when deleting a market crumb, delete all associated submarkets and micromarkets
            else if (crumbFieldArray.length === 4) {
                Object.keys(crumbsClone).forEach((x) => {
                    let otherFieldArray = get(crumbsClone[x], "field", "").split(".");
                    if (includes(otherFieldArray, crumbFieldArray[2])) {
                        if (otherFieldArray.length === 6 && includes(otherFieldArray, crumbFieldArray[3])) {
                            dispatch(updateFilter(crumbsClone[x].field, false, type));
                        }
                        if (otherFieldArray.length === 5 && includes(otherFieldArray, crumbFieldArray[2])) {
                            dispatch(updateFilter(crumbsClone[x].field, false, type));
                        }
                    }
                });
            }
        }

        if (type === "PROPERTY") {
            if (crumbFieldArray[1] === "kmManagedOnly") {
                if (getState().searchReducer.PROPERTY.searchCriteria.searchByASSId) {
                    dispatch(updateFilter("searchTerm", null, "PROPERTY"));
                }
                dispatch(updateFilter("searchByASSId", false, "PROPERTY"));
            }
        }

        if (crumb.field) {
            dispatch(updateFilter(crumb.field, value, type));
        } else {
            dispatch(updateFilterForRange(crumb.field1, value, type));
            dispatch(updateFilterForRange(crumb.field2, value, type));
        }
        let crumbsClone = cloneDeep(getState().searchReducer.loadedSearchCrumbs[type]);
        let count = 0;
        Object.keys(crumbsClone).map((crumb) => {
            // This should be refactored to not use a map as it's not storing any data
            let status = crumbsClone[crumb].value;
            if (typeof status === "boolean" && status === true) {
                return count++;
            } else if (typeof status === "string" && status != null) {
                return count++;
            } else {
                return undefined; // placeholder
            }
        });
        //if crumb being deleted isn't the last one, search reruns
        if (count !== 1) {
            if (type === "COMPS") {
                if (crumb.field === "filterCriteria.areaPoints") {
                    dispatch(actions.updateMapCrumb("Custom Map Search", type, false));
                    dispatch(actions.storeSavedCoordinates([], type));
                    dispatch(actions.mapCrumbClear(true, type));
                    let searchCriteria = cloneDeep(getState().searchReducer.COMPS.searchCriteria);
                    delete searchCriteria.filterCriteria;
                    dispatch(actions.replaceSearchCriteria(type, searchCriteria));
                }
                dispatch(compOperations.searchComps());
            } else if (type === "AUDIT") {
                dispatch(auditOperations.searchComps());
            } else if (type === "LISTINGS") {
                if (crumb.field === "filterCriteria.areaPoints") {
                    dispatch(actions.updateMapCrumb("Custom Map Search", type, false));
                    dispatch(actions.storeSavedCoordinates([], type));
                    dispatch(actions.mapCrumbClear(true, type));
                    let searchCriteria = cloneDeep(getState().searchReducer.LISTINGS.searchCriteria);
                    delete searchCriteria.filterCriteria;
                    dispatch(actions.replaceSearchCriteria(type, searchCriteria));
                }
                dispatch(listingOperations.searchListings());
            } else {
                dispatch(executeSearch("PROPERTY"));
            }
            //if last crumb is deleted, search is cleared
        } else {
            if (type === "COMPS") {
                dispatch(compOperations.clearAllComps());
            } else if (type === "AUDIT") {
                dispatch(auditOperations.clearAllComps());
            } else if (type === "LISTINGS") {
                dispatch(listingOperations.clearSearchedListings());
            }
            if (crumb.field === "filterCriteria.areaPoints") {
                dispatch(actions.mapCrumbClear(true, type));
            }
            dispatch(clearFilters(type));
        }
    };
};

const addCustomMapCrumb = (type) => {
    return (dispatch, getState) => {
        dispatch(actions.updateMapCrumb("Custom Map Search", type, true));
    };
};

const buildAllRangeCrumbs = (type) => {
    //builds single crumbs for all multi-input search parameters
    return (dispatch, getState) => {
        let searchCriteria;
        if (type === "COMPS") {
            searchCriteria = cloneDeep(getState().searchReducer.COMPS.searchCriteria);
        } else if (type === "LISTINGS") {
            searchCriteria = cloneDeep(getState().searchReducer.LISTINGS.searchCriteria);
        } else if (type === "PROPERTY") {
            searchCriteria = cloneDeep(getState().searchReducer.PROPERTY.searchCriteria);
        }
        let sizeCriteria = get(searchCriteria, "sizeCriteria", null);
        let dealTypeCriteria = get(searchCriteria, "dealTypeCriteria", null);
        let priceCriteria = get(searchCriteria, "priceCriteria", null);

        let listingTypeCriteria = get(searchCriteria, "listingTypeCriteria", null);

        if (listingTypeCriteria != null) {
            let value;
            let crumb = "Date Listed";
            let valueMin =
                get(listingTypeCriteria, "dateListedFrom", "") !== ""
                    ? get(listingTypeCriteria, "dateListedFrom")
                    : "* ";
            let valueMax =
                get(listingTypeCriteria, "dateListedTo", "") !== "" ? get(listingTypeCriteria, "dateListedTo") : " *";
            let fieldMin = "listingTypeCriteria.dateListedFrom";
            let fieldMax = "listingTypeCriteria.dateListedTo";
            if (
                get(listingTypeCriteria, "dateListedFrom", null) != null ||
                get(listingTypeCriteria, "dateListedTo", null) != null
            ) {
                value = true;
            } else {
                value = false;
            }
            dispatch(actions.updateRangeCrumb(crumb, type, fieldMin, fieldMax, valueMin, valueMax, value, null));
        }

        if (dealTypeCriteria != null || priceCriteria != null) {
            let crumb;
            let fieldMin;
            let fieldMax;
            let unit;
            let value;
            let valueMin;
            let valueMax;
            let criteria = type === "COMPS" ? dealTypeCriteria : priceCriteria;
            //lease rate range crumb
            crumb = "Lease Rate";
            unit = "(" + get(criteria, "leaseRateType", "$/SF/Month") + ")";
            valueMin = get(criteria, "minLeaseRate", "") !== "" ? get(criteria, "minLeaseRate") : "* ";
            valueMax = get(criteria, "maxLeaseRate", "") !== "" ? get(criteria, "maxLeaseRate") : " *";
            fieldMin = type === "COMPS" ? "dealTypeCriteria.minLeaseRate" : "priceCriteria.minLeaseRate";
            fieldMax = type === "COMPS" ? "dealTypeCriteria.maxLeaseRate" : "priceCriteria.maxLeaseRate";
            if (get(criteria, "minLeaseRate", null) != null || get(criteria, "maxLeaseRate", null) != null) {
                value = true;
            } else {
                value = false;
            }
            dispatch(actions.updateRangeCrumb(crumb, type, fieldMin, fieldMax, valueMin, valueMax, value, unit));

            //sale price range crumb
            crumb = "Sale Price";
            valueMin = get(criteria, "minPrice", "") !== "" ? get(criteria, "minPrice") : "* ";
            valueMax = get(criteria, "maxPrice", "") !== "" ? get(criteria, "maxPrice") : " *";
            fieldMin = type === "COMPS" ? "dealTypeCriteria.minPrice" : "priceCriteria.minPrice";
            fieldMax = type === "COMPS" ? "dealTypeCriteria.maxPrice" : "priceCriteria.maxPrice";
            if (get(criteria, "minPrice", null) != null || get(criteria, "maxPrice", null) != null) {
                value = true;
            } else {
                value = false;
            }
            dispatch(actions.updateRangeCrumb(crumb, type, fieldMin, fieldMax, valueMin, valueMax, value, null));

            //batch date range crumb
            crumb = "Transaction Date";
            valueMin = get(criteria, "batchDateFrom", "") !== "" ? get(criteria, "batchDateFrom") : "* ";
            valueMax = get(criteria, "batchDateTo", "") !== "" ? get(criteria, "batchDateTo") : " *";
            fieldMin = "dealTypeCriteria.batchDateFrom";
            fieldMax = "dealTypeCriteria.batchDateTo";
            if (get(criteria, "batchDateFrom", null) != null || get(criteria, "batchDateTo", null) != null) {
                value = true;
            } else {
                value = false;
            }
            dispatch(actions.updateRangeCrumb(crumb, type, fieldMin, fieldMax, valueMin, valueMax, value, null));

            //date signed date range crumb
            crumb = "Date Signed";
            valueMin = get(criteria, "transactionDateFrom", "") !== "" ? get(criteria, "transactionDateFrom") : "* ";
            valueMax = get(criteria, "transactionDateTo", "") !== "" ? get(criteria, "transactionDateTo") : " *";
            fieldMin = "dealTypeCriteria.transactionDateFrom";
            fieldMax = "dealTypeCriteria.transactionDateTo";
            if (
                get(criteria, "transactionDateFrom", null) != null ||
                get(criteria, "transactionDateTo", null) != null
            ) {
                value = true;
            } else {
                value = false;
            }
            dispatch(actions.updateRangeCrumb(crumb, type, fieldMin, fieldMax, valueMin, valueMax, value, null));
        }

        //square footage (size) range crumb
        if (sizeCriteria != null) {
            let crumb;
            let fieldMin;
            let fieldMax;
            let value;
            let valueMin;
            let valueMax;

            Object.keys(sizeCriteria).forEach((x) => {
                if (includes(["min", "max"], x)) {
                    if (sizeCriteria[x]) {
                        if (x === "min") {
                            valueMin = sizeCriteria[x];
                        } else {
                            valueMax = sizeCriteria[x];
                        }
                    } else {
                        if (x === "min") {
                            valueMin = "* ";
                        } else {
                            valueMax = " *";
                        }
                    }
                }
            });
            Object.keys(sizeCriteria).forEach((x) => {
                if (!includes(["min", "max"], sizeCriteria[x])) {
                    if (type === "COMP") {
                        crumb = criteriaCrumbs["sizeCriteria"][x]["compDisplay"];
                    } else {
                        crumb = criteriaCrumbs["sizeCriteria"][x]["listingDisplay"];
                    }
                    if (sizeCriteria[x] === true) {
                        value = true;
                    } else {
                        value = false;
                    }

                    fieldMin = "sizeCriteria.min";
                    fieldMax = "sizeCriteria.max";
                    if (value === true) {
                        if (
                            (valueMin === undefined && valueMax === undefined) ||
                            (valueMin === "* " && valueMax === " *")
                        ) {
                            dispatch(
                                actions.updateRangeCrumb(
                                    crumb,
                                    type,
                                    fieldMin,
                                    fieldMax,
                                    valueMin,
                                    valueMax,
                                    false,
                                    null
                                )
                            );
                        } else {
                            dispatch(
                                actions.updateRangeCrumb(
                                    crumb,
                                    type,
                                    fieldMin,
                                    fieldMax,
                                    valueMin,
                                    valueMax,
                                    value,
                                    null
                                )
                            );
                        }
                    } else {
                        dispatch(actions.updateRangeCrumb(crumb, type, fieldMin, fieldMax, "* ", " *", value, null));
                    }
                }
            });
        }
    };
};

const setLoadedSearchCrumbs = (type) => {
    return (dispatch, getState) => {
        //multi-input crumbs are built when the search is run (single inputs are built as the filters are added)
        dispatch(buildAllRangeCrumbs(type));
        let criteriaClone = cloneDeep(getState().searchReducer[type].searchCriteria);

        if (get(criteriaClone, "filterCriteria", null) != null) {
            dispatch(addCustomMapCrumb(type));
        }
        //sets the added crumbs to a loaded state to be displayed when the search returns results
        let crumbsClone = cloneDeep(getState().searchReducer.searchCrumbs[type]);
        dispatch(actions.setLoadedSearchCrumbs(crumbsClone, type));
    };
};

const handleDealTypeRadioClick = (field, checked, type) => {
    return (dispatch, getState) => {
        const options = ["lease", "sublease", "sale", "investmentSaleFlg"];
        each(options, (o) => {
            if (field === o) {
                checked
                    ? dispatch(updateFilter(`dealTypeCriteria.${field}`, true, type))
                    : dispatch(updateFilter(`dealTypeCriteria.${field}`, false, type));
            } else {
                dispatch(updateFilter(`dealTypeCriteria.${o}`, false, type));
            }
        });
    };
};

const handleListingTypeRadioClick = (field, checked, type) => {
    return (dispatch, getState) => {
        const options = ["forSublease", "forLease", "forSale", "investmentSaleFlg"];
        each(options, (o) => {
            if (field === o) {
                checked
                    ? dispatch(updateFilter(`listingTypeCriteria.${field}`, true, type))
                    : dispatch(updateFilter(`listingTypeCriteria.${field}`, false, type));
            } else {
                dispatch(updateFilter(`listingTypeCriteria.${o}`, false, type));
            }
        });
    };
};

const handleStatusRadioClick = (field, checked, type) => {
    return (dispatch, getState) => {
        const options = ["existing", "proposed", "underConstruction", "underRenovation", "demolished", "land"];
        each(options, (o) => {
            if (field === o) {
                checked
                    ? dispatch(updateFilter(`statusCriteria.${field}`, true, type))
                    : dispatch(updateFilter(`statusCriteria.${field}`, false, type));
            } else {
                dispatch(updateFilter(`statusCriteria.${o}`, false, type));
            }
        });
    };
};

const handleSizeRadioClick = (field, checked, type) => {
    return (dispatch, getState) => {
        const options = ["availableSf", "totalSf", "acreage", "totalTransactionSf"];
        each(options, (o) => {
            if (field === o) {
                checked
                    ? dispatch(updateFilter(`sizeCriteria.${field}`, true, type))
                    : dispatch(updateFilter(`sizeCriteria.${field}`, false, type));
            } else {
                dispatch(updateFilter(`sizeCriteria.${o}`, false, type));
            }
        });
    };
};

const togglePropertyTypeExpansionPanel = (panelName) => {
    return (dispatch, getState) => {
        const open = getState().propertyReducer.propertyTypeExpansionPanels[panelName];
        dispatch(actions.togglePropertyTypeExpansionPanel(panelName, !open));
    };
};

const toggleMarketFilterExpansionPanel = (panelName) => {
    return (dispatch, getState) => {
        const open = getState().propertyReducer.marketFilterExpansionPanels[panelName];
        dispatch(actions.toggleMarketFilterExpansionPanel(panelName, !open));
    };
};

const selectAllPropertyType = (field, value, type) => {
    return (dispatch, getState) => {
        dispatch(updateFilter(`propertyTypes.${field}.selected`, value, type));
    };
};

const determineParentCheckbox = (field, value, type) => {
    return (dispatch, getState) => {
        const pathSegments = field.split(".");
        if (pathSegments[0] === "propertyTypes") {
            if (value === true) {
                dispatch(
                    actions.updateFilter(`${type}.searchCriteria.propertyTypes.${pathSegments[1]}.selected`, true)
                );
            } else {
                let anySelected = false;
                const section = getState().searchReducer[type]["searchCriteria"]["propertyTypes"][pathSegments[1]];
                each(Object.keys(section), (x) => {
                    each(Object.keys(section[x]), (y) => {
                        if (!(section[x][y] === pathSegments[3]) && section[x][y] === true) {
                            anySelected = true;
                        }
                    });
                });
                dispatch(
                    actions.updateFilter(
                        `${type}.searchCriteria.propertyTypes.${pathSegments[1]}.selected`,
                        anySelected
                    )
                );
            }
        }
    };
};

const filterTableData = (query) => {
    return (dispatch, getState) => {
        if (typeof query === "string") query = query.toLowerCase();
        dispatch(actions.filterTableData(query));
    };
};

const sortTableData = (columnName) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };
    return (dispatch, getState) => {
        let sortDirection =
            getState().searchReducer.columnToSort === columnName
                ? invertDirection[getState().searchReducer.sortDirection]
                : "asc";
        dispatch(actions.sortTableData(columnName, sortDirection));
    };
};

const sortGridTableData = (tableColumn, tab, type, direction) => {
    let columnName = "";

    if (tab === "all_km_comps" || tab === "comps_group" || tab === "broker_comps") {
        if (tableColumn === "Address") {
            columnName = "address1";
        } else if (tableColumn === "Brokers") {
            columnName = "brokers";
        } else if (tableColumn === "Prop Type") {
            if (tab === "broker_comps") columnName = "propertyType";
            else columnName = "property_type";
        } else if (tableColumn === "Deal Type") {
            if (tab === "broker_comps") columnName = "transactionType";
            else columnName = "transaction_type";
        } else if (tableColumn === "Comp Type") {
            columnName = "deal_status";
        } else if (tableColumn === "Date Signed") {
            if (tab === "broker_comps") columnName = "transactionDate";
            else columnName = "transaction_date";
        } else if (tableColumn === "SQFT") {
            if (tab === "broker_comps") columnName = "calculatedValueC";
            else columnName = "total_transaction_size_sf";
        } else if (tableColumn === "Rate/Price") {
            if (tab === "broker_comps") columnName = "sortableRatePrice";
            else columnName = "sortablerateprice";
        }
    } else if (tab === "all_km_listings" || tab === "listings_group" || tab === "broker_open") {
        if (tableColumn === "Address") {
            columnName = "property.primaryAddress.address1";
        } else if (tableColumn === "Brokers") {
            if (tab === "broker_open") columnName = "Brokers";
            else columnName = "listingAgent";
        } else if (tableColumn === "Type") {
            columnName = "listingType";
        } else if (tableColumn === "SQFT") {
            columnName = "sfAvail";
        } else if (tableColumn === "Rate/Price") {
            columnName = "sortableRatePrice";
        } else if (tableColumn === "Date Avail") {
            columnName = "dateAvailable";
        } else if (tableColumn === "Company") {
            columnName = "listingCompany";
        } else {
            columnName = tableColumn;
        }
    } else if (tab === "savedSearchesWithSelections" || tab === "savedSearchesNoSelections") {
        if (tableColumn === "Search Name") {
            columnName = "searchName";
        } else if (tableColumn === "Retain Selections?") {
            columnName = "saveSelectedOnly";
        } else if (tableColumn === "Date Created") {
            columnName = "savedDate";
        }
    } else if (tab === "auditor_search") {
        if (tableColumn === "Address") {
            columnName = "address1";
        } else if (tableColumn === "Brokers") {
            columnName = "brokers";
        } else if (tableColumn === "Deal Type") {
            columnName = "transaction_type";
        } else if (tableColumn === "Batch Date") {
            columnName = "batchDate";
        }
    } else if (tab === "auditor_list") {
        if (tableColumn === "Address") {
            columnName = "primaryAddress.address1";
        } else if (tableColumn === "Brokers") {
            columnName = "brokerAgents";
        } else if (tableColumn === "Deal Type") {
            columnName = "transactionType";
        } else if (tableColumn === "Batch Date") {
            columnName = "batchDate";
        }
    } else if (tab === "groups") {
        if (tableColumn === "List Name") {
            columnName = "groupName";
        } else if (tableColumn === "Date Created") {
            columnName = "savedDate";
        }
    } else {
        if (tableColumn === "Address") {
            columnName = "address1";
        } else if (tableColumn === "Brokers") {
            columnName = "brokers";
        } else if (tableColumn === "Prop Type") {
            columnName = "propertyType";
        } else if (tableColumn === "Deal Type") {
            columnName = "transactionType";
        } else if (tableColumn === "Comp Type") {
            columnName = "dealStatus";
        } else if (tableColumn === "Signed") {
            columnName = "transactionDate";
        } else if (tableColumn === "Square Feet") {
            columnName = "calculatedValueC";
        } else if (tableColumn === "My Amount") {
            columnName = "calculatedValueF";
        } else {
            columnName = tableColumn;
        }
    }

    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };

    return (dispatch, getState) => {
        let sortDirection = direction
            ? direction
            : getState().searchReducer.columnToSort === columnName
            ? invertDirection[getState().searchReducer.sortDirection]
            : "asc";

        if (tab === "all_km_comps" || tab === "comps_group" || tab === "broker_comps") {
            let sortedData;
            const unsortedData = getState().compReducer.comps[tab];

            if (tableColumn === "Rate/Price" || tableColumn === "SQFT") {
                sortedData = orderBy(unsortedData, [(x) => (!x[columnName] ? 0 : x[columnName])], sortDirection);
            } else if (tableColumn === "Deal Type") {
                sortedData = orderBy(unsortedData, [(x) => parseTransactionType(x[columnName])], sortDirection);
            } else if (tableColumn === "Brokers") {
                sortedData = unsortedData.sort((a, b) => brokerSortHelper(a, b, sortDirection, "comps", tab));
            } else {
                sortedData = orderBy(unsortedData, columnName, sortDirection);
            }
            dispatch(actions.sortTableData(columnName, sortDirection));
            dispatch(compOperations.updateCompsForSort(sortedData, tab));
        } else if (tab === "auditor_search") {
            let compz = getState().auditReducer.comps;
            const sortedData = orderBy(compz, columnName, sortDirection);
            dispatch(actions.sortTableData(columnName, sortDirection));
            dispatch(auditOperations.updateCompsForSort(sortedData));
        } else if (tab === "auditor_list") {
            let compz = getState().auditReducer.auditorList;
            let sortedData = null;
            sortedData = orderBy(compz, columnName, sortDirection);
            dispatch(actions.sortTableData(columnName, sortDirection));
            dispatch(auditOperations.updateAuditorListForSort(sortedData));
        } else if (tab === "all_km_listings" || tab === "listings_group" || tab === "broker_open") {
            let sortedData;
            const unsortedData = getState().listingReducer.listings[tab];

            if (columnName === "dateAvailable") {
                sortedData = orderBy(unsortedData, [(x) => new Date(x[columnName])], sortDirection);
            } else if (tableColumn === "Brokers") {
                sortedData = unsortedData.sort((a, b) => brokerSortHelper(a, b, sortDirection, "listings", tab));
            } else if (tableColumn === "Rate/Price" || tableColumn === "SQFT") {
                sortedData = orderBy(unsortedData, [(x) => (!x[columnName] ? 0 : x[columnName])], sortDirection);
            } else if (columnName === "listingType") {
                sortedData = orderBy(
                    unsortedData,
                    [
                        (x) =>
                            `${tab === "broker_open" ? get(x, "attributes.useType") : get(x, "useType")} ${get(
                                x,
                                "listingType"
                            )}`,
                    ],
                    sortDirection
                );
            } else {
                sortedData = orderBy(unsortedData, columnName, sortDirection);
            }
            dispatch(actions.sortTableData(columnName, sortDirection));
            dispatch(listingOperations.updateListingsForSort(sortedData, tab));
        } else if (tab === "savedSearchesWithSelections" || tab === "savedSearchesNoSelections") {
            let sortedData;
            let unsortedData = type ? getState().searchReducer[type].savedSearches : [];
            if (columnName === "searchName") {
                sortedData = orderBy(unsortedData, [(x) => x[columnName].toString().toLowerCase()], sortDirection);
            } else if (columnName === "savedDate") {
                sortedData = orderBy(unsortedData, [(x) => new Date(x[columnName])], sortDirection);
            } else {
                sortedData = orderBy(unsortedData, columnName, sortDirection);
            }

            dispatch(actions.sortTableData(columnName, sortDirection));
            dispatch(actions.loadSavedSearches(sortedData, type));
        } else if (tab === "groups") {
            let sortedData;
            const unsortedData = type ? getState().searchReducer[type].savedGroups : [];

            if (columnName === "groupName") {
                sortedData = orderBy(unsortedData, [(x) => x[columnName].toString().toLowerCase()], sortDirection);
            } else if (columnName === "savedDate") {
                sortedData = orderBy(unsortedData, [(x) => new Date(x[columnName])], sortDirection);
            }

            dispatch(actions.sortTableData(columnName, sortDirection));
            dispatch(actions.loadSavedGroups(sortedData, type));
        } else {
            let compz = getState().compReducer.comps["broker_comps"];

            const sortedData = orderBy(compz, columnName, sortDirection);

            dispatch(actions.sortTableData(columnName, sortDirection));
            dispatch(compOperations.updateCompsForSort(sortedData, tab));
        }
    };
};

const extractValForBrokerSortComps = (comp) => {
    let compVal = getFromAttributeValues(comp, ["listing_agents", "brokers"]);
    compVal = !compVal ? [] : compVal.split(",");
    return compVal.length === 1 ? compVal[0].toLowerCase() : compVal.length;
};

const extractValForBrokerSortListings = (listing, tab) => {
    let listingVal;
    if (tab === "broker_open") {
        listingVal = utilOperations
            .aggregateBrokersByPersonObjectForTooltip(listing["listingBrokers"], "brokerAgentKey.person")
            .split(","); // Returns an array of brokers
    } else {
        listingVal = getFromAttributeValues(listing, ["listingBrokers", "listingAgent"]); // Can return undefined, a string, or an array of brokers
        if (typeof listingVal === "string") listingVal = listingVal.split(",");
    }

    if (!listingVal) listingVal = [];
    return listingVal.length === 1 ? listingVal[0].toLowerCase() : listingVal.length;
};

const brokerSortHelper = (a, b, sortDirection, entityType, tab) => {
    // Extract value from a --- can either be 0, lowercase broker name, or brokers.length (greater than 1)
    const aBrokers = entityType === "comps" ? extractValForBrokerSortComps(a) : extractValForBrokerSortListings(a, tab);
    // Extract value from b --- can either be 0, lowercase broker name, or brokers.length (greater than 1)
    const bBrokers = entityType === "comps" ? extractValForBrokerSortComps(b) : extractValForBrokerSortListings(b, tab);

    let ordering;
    if (typeof aBrokers === "string" && typeof bBrokers === "string") {
        // If both a and b are of type string
        ordering = aBrokers.localeCompare(bBrokers);
    } else if (typeof aBrokers === "number" && typeof bBrokers === "number") {
        // If both a and b are of type number
        ordering = aBrokers - bBrokers;
    } else {
        // Mixed types
        if (typeof aBrokers === "number") {
            ordering = !aBrokers ? -1 : 1;
        } else {
            ordering = !bBrokers ? 1 : -1;
        }
    }

    // Reverse the ordering if the sort direction is descending
    return sortDirection === "desc" ? ordering * -1 : ordering;
};

const searchPropertyAutocomplete = (input) => {
    return (dispatch, getState) => {
        dispatch(actions.autocompleteSearchTerm(input));
        if (input && input.length > 2) {
            dispatch(actions.autocompleteResults([]));
            request(
                {
                    url: constants.urls.search_property_autocomplete(input),
                    method: "get",
                    crossDomain: true,
                },
                dispatch
            )
                .then((response) => {
                    if (
                        getState().searchReducer.autocompleteSearchTerm &&
                        getState().searchReducer.autocompleteSearchTerm.trim() === response.data.searchTerm.trim()
                    ) {
                        dispatch(actions.autocompleteResults(response.data.properties));
                    }
                })
                .catch((err) => {});
        } else {
            if (input && input.length !== 0) {
                dispatch(actions.autocompleteResults([]));
            }
        }
    };
};

const searchKMPersonAutocomplete = (input) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.search_km_person_autocomplete(input),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.autocompleteResults(response.data));
            })
            .catch((err) => {});
    };
};

const searchCompany = (input, companyType = null, excludeParentCompanies = false) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.find_companies_by_term_and_type(input, companyType, excludeParentCompanies),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                let data = response.data;
                dispatch(actions.autocompleteResults(data));
            })
            .catch((err) => {
                console.log("error occurred!" + err);
            });
    };
};

const searchParentCompany = (input, companyType = null) => {
    return (dispatch, getState) => {
        if (companyType) {
            companyType.join("&companyType=");
        }
        request(
            {
                url: constants.urls.find_parent_company_by_term(input, companyType),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                let data = response.data;
                dispatch(actions.autocompleteResults(data));
            })
            .catch((err) => {
                console.log("error occurred!" + err);
            });
    };
};

const autoCompleteBroker = (input) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.find_brokers_with_name_starting_with(input),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                let data = response.data;
                dispatch(actions.autocompleteResults(data));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Something went wrong. Unable to search."));
            });
    };
};

const searchOutsideBroker = (input) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.find_outside_brokers_with_name_starting_with(input),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                let data = response.data;
                dispatch(actions.autocompleteResults(data));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Something went wrong. Unable to search."));
            });
    };
};

const searchKMBrokers = (input) => {
    return (dispatch, getState) => {
        request({
            url: constants.urls.find_km_brokers_with_names_like(input),
            method: "get",
            crossDomain: true,
        })
            .then((response) => {
                let data = response.data;
                dispatch(actions.autocompleteResults(data));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Something went wrong. Unable to search."));
            });
    };
};

const searchUsers = (input) => {
    return (dispatch, getState) => {
        request({
            url: constants.urls.find_users_with_names_like(input),
            method: "get",
        })
            .then((response) => {
                let data = response.data;

                dispatch(actions.autocompleteResults(data));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Something went wrong. Unable to search."));
            });
    };
};

const handleSelectMarket = (checked, market, submarket, micromarket) => {
    return (dispatch, getState) => {
        if (market && submarket && micromarket) {
            if (checked) {
                // add micromarket
            } else {
                // remove micromarket
            }
        } else if (market && submarket) {
            if (checked) {
                // add submarket
            } else {
                // remove submarket
            }
        } else if (market) {
            if (checked) {
                // add market
            } else {
                // remove market
            }
        }
    };
};

const updateMarketFilter = (market, checked, type) => {
    return (dispatch, getState) => {
        dispatch(updateFilter("locationCriteria.markets." + market + ".selected", checked, type));
    };
};

const updateSubmarketFilter = (market, submarket, checked, type) => {
    return (dispatch, getState) => {
        dispatch(updateFilter("locationCriteria.markets." + market + "." + submarket + ".selected", checked, type));
        if (checked) {
            dispatch(updateMarketFilter(market, true, type, true));
        }
    };
};

const updateMicromarketFilter = (market, submarket, micromarket, checked, type) => {
    return (dispatch, getState) => {
        dispatch(
            updateFilter(
                "locationCriteria.markets." + market + "." + submarket + "." + micromarket + ".selected",
                checked,
                type
            )
        );
        if (checked) {
            dispatch(updateSubmarketFilter(market, submarket, true, type, true));
        }
    };
};

const updateSearchAreaFilter = (latLngs, type) => {
    return (dispatch, getState) => {
        dispatch(updateFilter("filterCriteria.areaPoints", latLngs, type));
        dispatch(actions.mapCrumbClear(false, type));
    };
};

const storeSearchCleared = (cleared, type) => {
    return (dispatch, getState) => {
        dispatch(actions.storeSearchCleared(cleared, type));
        dispatch(actions.clearSearchResponse(type));
        if (cleared) {
            dispatch(actions.storeSearchRun(false, type));
            dispatch(actions.storeSavedCoordinates({}, type));
        }
    };
};

const storeSearchRun = (run, type) => {
    return (dispatch, getState) => {
        dispatch(actions.storeSearchRun(run, type));
    };
};

const updateSearchPolygon = (polygon, type) => {
    return (dispatch, getState) => {
        dispatch(actions.updateSearchPolygon(polygon, type));
        if (polygon) {
            dispatch(actions.storeSavedCoordinates({}, type));
        }
    };
};

const saveMapSearchSettings = (settings, type) => {
    return (dispatch, getState) => {
        dispatch(actions.saveMapSearchSettings(settings, type));
    };
};

export default {
    handlePropertyTypeCheckboxClick,
    handleSpecialtyTypeCheckboxClick,
    handleUpdateSearchCriteria,
    executeSearch,
    loadMoreResults,
    handleTenancyTypeCheckboxClick,
    clearSearchFilters,
    clearFilters,
    handleDataExport,
    clearDataExport,
    updateFilter,
    handleDealTypeRadioClick,
    handleListingTypeRadioClick,
    togglePropertyTypeExpansionPanel,
    toggleMarketFilterExpansionPanel,
    selectAllPropertyType,
    handleSizeRadioClick,
    handleStatusRadioClick,
    filterTableData,
    sortTableData,
    searchPropertyAutocomplete,
    performSearch,
    searchCall,
    searchKMPersonAutocomplete,
    searchCompany,
    autoCompleteBroker,
    searchOutsideBroker,
    searchKMBrokers,
    searchUsers,
    handleSelectMarket,
    updateMarketFilter,
    updateSubmarketFilter,
    updateMicromarketFilter,
    updateSearchAreaFilter,
    sortGridTableData,
    deleteCrumb,
    clearCrumbsState,
    storeSearchCleared,
    storeSearchRun,
    updateSearchPolygon,
    saveMapSearchSettings,
    handleSaveSearch,
    deleteSavedSearch,
    viewSavedSearches,
    buildSavedSearch,
    toggleSavedSearchesModal,
    toggleNameSearchModal,
    setSearchName,
    setSearchSelectedToggle,
    runSavedSearch,
    addSelectedToGroup,
    deleteSelectedFromGroup,
    clearCurrentGroup,
    handleSaveGroup,
    runSavedGroup,
    getSavedGroupsForUser,
    deleteSavedGroup,
    runUpdateCurrentGroup,
    searchParentCompany,
};
