import React from "react";
import { connect } from "react-redux";
import CompsTable from "./CompsTable";
import searchOperations from "../search/ducks/operations";

class CompsTableContainer extends React.Component {
    render() {
        /*
         ** Must provide comp and compTab props from higher up
         */
        return <CompsTable {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        role: state.authReducer.userProfile.role,
        user: state.authReducer.userProfile.user,
        valid: state.authReducer.validFormValues,
        columnToSort: state.searchReducer.columnToSort,
        sortDirection: state.searchReducer.sortDirection,
        filterQuery: state.searchReducer.filterQuery,
        selected: state.compReducer.selected,
        isLoading: state.utilsReducer.isLoading,
        numComps: state.compReducer.numComps,
        errors: state.utilsReducer.errors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleSort: (columnName) => {
            dispatch(searchOperations.sortTableData(columnName));
        },
        handleFilter: (query) => {
            dispatch(searchOperations.filterTableData(query));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompsTableContainer);
