import React from "react";
import { buildAddress, toSqft, toDollarAmount, filterByQuery, filterActiveListings } from "../utils/operations";
import { LeftChevron, Add, Comments } from "../../../common/js/icons";
import { Link } from "react-router-dom";
import { us_states_options } from "../utils/constants";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { withStyles } from "@material-ui/core/styles";
import {
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Tab,
    Tabs,
} from "@material-ui/core";
import { get, orderBy, toLower, includes } from "lodash";
import { getSpecialtyTypeDisplayName } from "./constants";
import BuildingForm from "./beta/BuildingFormBeta";
import CompsTable from "../comp/CompsTableContainer";
import DocumentDrop from "../documents/DocumentDropContainer";
import FormTitle from "../../../common/js/components/FormTitle";
import Gallery from "../../../common/js/components/Gallery";
import HospitalityForm from "./beta/HospitalityFormBeta";
import IndustrialForm from "./IndustrialForm";
import KMSwitch from "../../../common/js/components/KMSwitch";
import LandForm from "./beta/LandFormBeta";
import ListingDataPreview from "../listing/ListingDataPreview";
import RequestModalContainer from "../listing/RequestModalContainer";
import MultifamilyForm from "./MultifamilyForm";
import OfficeForm from "./OfficeForm";
import HealthcareForm from "../property/HealthcareForm";
import OtherForm from "./OtherForm";
import ParkingInfoForm from "./beta/ParkingInfoFormBeta";
import RetailForm from "./beta/RetailFormBeta";
import ResearchTab from "../research/ResearchTab";
import Snackbar from "../../../common/js/components/KMSnackbar";
import AssetServicesTab from "./PropertyAssetServicesTab";
import AssetServicesSnapshotModalContainer from "./AssetServicesSnapshotModalContainer";
import SaveAndSyncContainer from "./SaveAndSyncContainer";

const styles = {
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    paperPadding: {
        padding: "5px 20px 20px",
        marginBottom: "24px",
    },
    paperHeading: {
        fontSize: "18px",
        margin: "14px 0",
    },
    hr: {
        margin: "0 -20px 20px -20px",
    },
    defTerm: {
        fontSize: "13px",
        textTransform: "uppercase",
        color: "#9E9E9E",
        fontWeight: 600,
        lineHeight: "26px",
        whiteSpace: "nowrap",
    },
    defData: {
        fontSize: "14px",
        color: "#000",
    },
    tabOne: {
        marginRight: "20px",
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "3em",
        marginBottom: "3em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    moveIt: {
        marginRight: "20px",
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
    inactiveSwitch: {},
    toplessInput: {
        marginTop: 0,
    },
    lightButton: {
        fontWeight: "400",
    },
    lightButtonIcon: {
        paddingRight: "3px",
        paddingTop: "6px",
        marginRight: "3px",
    },
    assetPortfolioHeader: {
        fontSize: "14px",
        fontWeight: "bold",
    },
    assetServicesButtonsContainer: {
        display: "flex",
        alignItems: "center",
    },
};

class PropertyDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numListings: 0,
            closeDealDialogOpen: false,
            inactiveListingsOnly: false,
            filterQuery: "",
            snackbarOpen: false,
            snackbarMessage: "",
            snackbarVariant: "",
        };
    }

    handleClickOpen = (listing) => {
        this.setState({ closeDealDialogOpen: true, listingToClose: listing });
    };

    handleClose = () => {
        this.props.closeDeal(this.state.listingToClose);
        this.setState({ closeDealDialogOpen: false, listingToClose: null });
    };

    sortTableData = (columnName) => {
        const invertDirection = {
            asc: "desc",
            desc: "asc",
        };

        let sortDirection = this.state.columnToSort === columnName ? invertDirection[this.state.sortDirection] : "asc";
        this.setState({ columnToSort: columnName, sortDirection: sortDirection });
    };

    handleFilter = (input) => {
        this.setState({ filterQuery: input });
    };

    handleAddListingMenuOpen = (anchorEl) => {
        this.setState({ addListingOpen: true, anchorEl: anchorEl });
    };

    handleAddListingMenuClose = () => {
        this.setState({ useType: null, transactionType: null, addListingOpen: false });
    };

    toggleInactiveListingsOnly = () => {
        this.setState({ inactiveListingsOnly: !this.state.inactiveListingsOnly });
    };

    handleEditResearchSnapshot = (result) => {
        if (result === true) {
            this.setState({
                snackbarMessage: "Success: Your edits have been applied.",
                snackbarVariant: "success",
                snackbarOpen: true,
            });
        } else if (result === false) {
            this.setState({
                snackbarMessage: "Failed: Your edit was not processed.",
                snackbarVariant: "error",
                snackbarOpen: true,
            });
        }
    };

    componentDidMount = () => {
        window.scrollTo(0, 0);
    };

    render() {
        const {
            role,
            classes,
            isLoading,
            property,
            propertyType,
            tab,
            switchTab,
            listings,
            getFlyer,
            navigateToRequestUpdate,
            navigateToRequestPropertyUpdate,
            compHistory,
            toggleRequestModal,
            propertyManagement,
            handleInput,
            addListingGivenProperty,
            errors,
            propertyKey,
            elementsSaving,
            navigateToEditProperty,
        } = this.props;

        const listingz =
            listings && listings instanceof Array
                ? listings
                      .filter((k) => filterActiveListings(k, this.state.inactiveListingsOnly))
                      .filter((x) => filterByQuery(x, listingColumnsTofilter, this.state.filterQuery))
                : listings;

        const compz =
            this.state.filterQuery && compHistory
                ? compHistory.filter((x) => filterByQuery(x, compColumnsTofilter, this.state.filterQuery))
                : compHistory;

        return (
            <React.Fragment>
                <Snackbar
                    open={this.state.snackbarOpen}
                    message={this.state.snackbarMessage}
                    variant={this.state.snackbarVariant}
                    vertical="top"
                    horizontal="center"
                    autoHideDuration={3000}
                    onClose={() => {
                        this.setState({ snackbarOpen: false });
                    }}
                />
                <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={8}>
                    <Grid item zeroMinWidth>
                        <Link to="/properties">
                            <LeftChevron />
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <h2 className="page-title">{get(property, "propertyAttributes.propertyName", "")}</h2>
                        <p className="page-subtitle">{buildAddress(get(property, "primaryAddress", {}))}</p>
                    </Grid>
                    <Grid item xs>
                        <h2 className="page-title text-right">
                            {`${toDollarAmount(get(property, "propertyAttributes.avgAnnualPsfRate", 0))} ${
                                get(property, "propertyAttributes.avgAnnualPsfRate", null) ? "-" : ""
                            } ${toDollarAmount(get(property, "propertyAttributes.blendedRate", 0))} USD/SF/YR`}
                        </h2>
                        <p className="page-subtitle text-right">{`${toSqft(
                            get(property, "propertyAttributes.totalBldgSf", 0)
                        )} | ${get(property, "propertyType", 0)}`}</p>
                    </Grid>
                </Grid>
                <Grid container className={classes.asdf} alignContent="stretch" spacing={24}>
                    <Grid item xs={5}>
                        <Gallery
                            propertyKey={propertyKey}
                            images={Object.keys(this.props.images).map((key, index) => {
                                return this.props.images[key].url;
                            })}
                        ></Gallery>
                    </Grid>
                    <Grid item xs={7}>
                        <Paper className={classes.paperPadding}>
                            <h3 className={classes.paperHeading}>General Information this is me here</h3>
                            <hr className={classes.hr} />
                            {isLoading === false && (
                                <React.Fragment>
                                    <Grid container spacing={8}>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Property ID</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>{property.propertyKey}</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Leed Certification</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {get(property, "propertyAttributes.leedCertFlg", false) === true
                                                    ? property.propertyAttributes.leedCertLevel
                                                    : "None"}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Property SF</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {toSqft(get(property, "propertyAttributes.totalBldgSf", {}))}
                                            </span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Property Type</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>{property.propertyType}</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>KM Managed SF</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {toSqft(get(propertyManagement, "grossSqFt", {}))}
                                            </span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Property Use</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {getSpecialtyTypeDisplayName(
                                                    get(property, "propertyType"),
                                                    get(property, "propertyAttributes.specialtyType", "-")
                                                )}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Unit Count</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {get(property, "propertyAttributes.totalUnits", "-")}
                                            </span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Condo Units</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {get(property, "propertyAttributes.condoFlg", false) === true
                                                    ? "Yes"
                                                    : "No"}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Room Count</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {get(property, "propertyAttributes.roomCount", "-")}
                                            </span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Market Area</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {get(property, "propertyAttributes.marketArea", "-")}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs>
                                            <span className={classes.defTerm}># of Floors</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {get(property, "propertyAttributes.numFloors", "-")}
                                            </span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Submarket Area</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {get(property, "propertyAttributes.submarketArea", "-")}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>PM Client Property ID</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {get(propertyManagement, "accountingSoftwareId", "")}
                                            </span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Micro Market</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {get(property, "propertyAttributes.micromarketArea", "-")}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Property Manager</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}>
                                                {get(property, "propertyManagerName", "")}
                                            </span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defTerm}></span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}></span>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs>
                                            <span className={classes.defTerm}>Appraiser</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}></span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defTerm}></span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className={classes.defData}></span>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
                <Paper className={classes.paperPadding}>
                    <Grid container justify="space-between" alignItems="center" spacing={0}>
                        <Grid item>
                            <Tabs classes={classes} value={tab} onChange={switchTab}>
                                <Tab classes={classes.tabOne} value="property" label="Property" />
                                <Tab classes={classes} value="property_listings" label="Listings" />
                                <Tab classes={classes} value="comp_history_for_property" label="Comp History" />
                                {includes(
                                    ["propertyManagement", "transition", "accounting", "dataEntry", "dataAdmin"],
                                    role
                                ) && <Tab classes={classes} value="property_management" label="Asset Services" />}
                                {includes(["research", "researchAdmin", "dataAdmin", "dataEntry"], role) && (
                                    <Tab classes={classes} value="research_tab" label="Research" />
                                )}
                            </Tabs>
                        </Grid>
                        {tab === "property_management" &&
                            propertyManagement &&
                            includes(["transition", "dataEntry", "dataAdmin"], role) && (
                                <Grid item className={classes.assetServicesButtonsContainer}>
                                    <AssetServicesSnapshotModalContainer propertyKey={propertyKey} />
                                    <SaveAndSyncContainer />
                                </Grid>
                            )}
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs>
                            <Divider />
                        </Grid>
                    </Grid>

                    {/*
                     *
                     * Listings
                     *
                     */}

                    {tab === "property_listings" && (
                        <React.Fragment>
                            <Grid container justify="space-between" spacing={0}>
                                <Grid item>
                                    <h3 className={classes.paperHeading} style={{ marginBottom: "2px" }}>
                                        Listings
                                    </h3>
                                    <div className="page-subtitle" style={{ marginBottom: "16px" }}>
                                        Showing {`${listingz.length} ${listingz.length === 1 ? "listing" : "listings"}`}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center" spacing={8}>
                                        <Grid item>
                                            <FormControlLabel
                                                label="Inactive Listings &nbsp;" // hack alert!
                                                labelPlacement="start"
                                                className={classes.inactiveSwitch}
                                                control={
                                                    <KMSwitch
                                                        checked={this.state.inactiveOnly}
                                                        onChange={this.toggleInactiveListingsOnly}
                                                        value={this.state.inactiveListingsOnly}
                                                    />
                                                }
                                            />
                                            <RequestModalContainer
                                                {...this.props}
                                                toggleRequestModal={toggleRequestModal}
                                                modalType="listing"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className={classes.moveIt}
                                                color="primary"
                                                onClick={(e) => this.handleAddListingMenuOpen(e.currentTarget)}
                                            >
                                                <Add fill="#4b94c1" />
                                                Add Listing
                                            </Button>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={this.state.anchorEl}
                                                open={this.state.addListingOpen}
                                                onClose={this.handleAddListingMenuClose}
                                                PaperProps={{
                                                    style: {
                                                        width: 200,
                                                    },
                                                }}
                                            >
                                                <MenuItem disabled className={classes.menuCategory}>
                                                    Lease
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => addListingGivenProperty(property, "Office", "Lease")}
                                                >
                                                    Office Lease
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Industrial", "Lease")
                                                    }
                                                >
                                                    Industrial Lease
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => addListingGivenProperty(property, "Retail", "Lease")}
                                                >
                                                    Retail Lease
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => addListingGivenProperty(property, "Land", "Lease")}
                                                >
                                                    Land Lease
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Hospitality", "Lease")
                                                    }
                                                >
                                                    Hospitality Lease
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Multifamily", "Lease")
                                                    }
                                                >
                                                    Multifamily Lease
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Life Science", "Lease")
                                                    }
                                                >
                                                    Life Science Lease
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Religious", "Lease")
                                                    }
                                                >
                                                    Religious Lease
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Educational", "Lease")
                                                    }
                                                >
                                                    Educational Lease
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => addListingGivenProperty(property, "Other", "Lease")}
                                                >
                                                    Other Lease
                                                </MenuItem>
                                                <MenuItem disabled className={classes.menuCategory}>
                                                    Sale
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => addListingGivenProperty(property, "Office", "Sale")}
                                                >
                                                    Office Sale
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Industrial", "Sale")
                                                    }
                                                >
                                                    Industrial Sale
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => addListingGivenProperty(property, "Retail", "Sale")}
                                                >
                                                    Retail Sale
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => addListingGivenProperty(property, "Land", "Sale")}
                                                >
                                                    Land Sale
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Hospitality", "Sale")
                                                    }
                                                >
                                                    Hospitality Sale
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Multifamily", "Sale")
                                                    }
                                                >
                                                    Multifamily Sale
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Life Science", "Sale")
                                                    }
                                                >
                                                    Life Science Sale
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Religious", "Sale")
                                                    }
                                                >
                                                    Religious Sale
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        addListingGivenProperty(property, "Educational", "Sale")
                                                    }
                                                >
                                                    Educational Sale
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => addListingGivenProperty(property, "Other", "Sale")}
                                                >
                                                    Other Sale
                                                </MenuItem>
                                            </Menu>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {elementsSaving.property_listings ? (
                                <div className={classes.alternateContent}>
                                    <CircularProgress color="primary" />
                                </div>
                            ) : listingz && listingz.length > 0 ? (
                                <React.Fragment>
                                    {orderBy(listingz, this.state.columnToSort, this.state.sortDirection).map(
                                        (listing, j) => (
                                            <ListingDataPreview
                                                listing={listing}
                                                openCloseDealDialog={this.handleClickOpen}
                                                getFlyer={getFlyer}
                                                navigateToRequestUpdate={navigateToRequestUpdate}
                                                currentPath={`/propdetail/${get(property, "propertyKey", null)}`}
                                                canClose={includes(
                                                    ["broker", "portlandBrokerAdmin", "arizonaBrokerAdmin", "evp"],
                                                    role
                                                )}
                                            />
                                        )
                                    )}
                                </React.Fragment>
                            ) : (
                                <div className={classes.alternateContent}>No listings</div>
                            )}
                        </React.Fragment>
                    )}

                    {/*
                     *
                     * Comp history
                     *
                     */}

                    {tab === "comp_history_for_property" && (
                        <React.Fragment>
                            <Grid container justify="space-between" spacing={0}>
                                <Grid item>
                                    <h3 className={classes.paperHeading} style={{ marginBottom: "2px" }}>
                                        Comps
                                    </h3>
                                    <div className="page-subtitle" style={{ marginBottom: "16px" }}>
                                        Showing {`${compz.length} ${compz.length === 1 ? "comp" : "comps"}`}
                                    </div>
                                </Grid>
                            </Grid>
                            {elementsSaving.comp_history_for_property ? (
                                <div className={classes.alternateContent}>
                                    <CircularProgress color="primary" />
                                </div>
                            ) : compHistory && compHistory.length > 0 ? (
                                <CompsTable
                                    comps={orderBy(compz, this.state.columnToSort, this.state.sortDirection)}
                                    compTab="comp_history_for_property"
                                />
                            ) : (
                                <div className={classes.alternateContent}>Nothing to Show</div>
                            )}
                        </React.Fragment>
                    )}

                    {/*
                     *
                     * Property Management
                     *
                     */}

                    <AssetServicesTab {...this.props} />

                    {/*
                     *
                     * Property Information
                     *
                     */}

                    {tab === "property" && (
                        <React.Fragment>
                            <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                                spacing={8}
                                style={{ marginBottom: "10px" }}
                            >
                                <Grid item>
                                    <h3>Property Information</h3>
                                </Grid>
                                <Grid item>
                                    {includes(["dataEntry", "dataAdmin"], role) && (
                                        <Button
                                            onClick={() => navigateToEditProperty(get(property, "propertyKey", null))}
                                            variant="contained"
                                            color="primary"
                                            style={{ marginRight: "10px" }}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                    {!includes(["dataEntry", "dataAdmin"], role) && (
                                        <Button
                                            onClick={() =>
                                                navigateToRequestPropertyUpdate(
                                                    get(property, "propertyKey", null),
                                                    `/propdetail/${get(property, "propertyKey", null)}`
                                                )
                                            }
                                            color="primary"
                                            className={classes.lightButton}
                                            style={{ marginRight: "10px" }}
                                        >
                                            <span className={classes.lightButtonIcon}>
                                                <Comments fill="#2C99D2" />
                                            </span>
                                            Request Update
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <BSInput
                                                id="propertyName"
                                                label="Property Name"
                                                placeholder="Enter property name"
                                                value={get(property, "propertyAttributes.propertyName", "")}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <BSInput
                                                id="address1"
                                                label="Address"
                                                placeholder="Enter address"
                                                value={get(property, "primaryAddress.address1", "")}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs={4}>
                                            <BSInput
                                                id="city"
                                                label="City"
                                                placeholder="Enter city"
                                                value={get(property, "primaryAddress.city.city", "")}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BSSelect
                                                id="state"
                                                label="State"
                                                placeholder="Select state"
                                                value={get(property, "primaryAddress.city.state.stateCode", "")}
                                                disabled
                                                options={us_states_options}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BSInput
                                                id="zip"
                                                label="Zip"
                                                placeholder="Enter zip code"
                                                value={get(property, "primaryAddress.zipCode.zipCode", "")}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs={6}>
                                            <BSInput
                                                id="propertyCounty"
                                                label="Property County"
                                                placeholder="Select County"
                                                value={get(property, "primaryAddress.county.name", "")}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <BSInput
                                                id="propertyId"
                                                label="KM Property Id"
                                                placeholder="Enter id"
                                                value={get(property, "propertyKey", "")}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs={6}>
                                            <BSInput
                                                id="address2"
                                                label="2nd Address"
                                                placeholder="Enter address"
                                                value={get(property, "secondaryAddress", "")}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={8}>
                                        <Grid item xs={6}>
                                            <BSInput
                                                id="propertyType"
                                                label="Property Type"
                                                placeholder="Select type"
                                                value={get(property, "propertyType", "")}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <BSInput
                                                id="useType"
                                                label="Property Use"
                                                placeholder="Select use"
                                                value={getSpecialtyTypeDisplayName(
                                                    get(property, "propertyType"),
                                                    get(property, "propertyAttributes.specialtyType")
                                                )}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs={4}>
                                            <BSInput
                                                id="marketArea"
                                                label="Market Area"
                                                value={get(property, "propertyAttributes.marketArea", "")}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BSInput
                                                id="submarketArea"
                                                label="Submarket"
                                                value={get(property, "propertyAttributes.submarketArea", "")}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BSInput
                                                id="micromarketArea"
                                                label="Micro Market"
                                                value={get(property, "propertyAttributes.micromarketArea", "")}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    {get(property, "propertyAttributes.parcels", []).map((parcel, index) => (
                                        <React.Fragment>
                                            <Grid container spacing={8}>
                                                <Grid item xs={6}>
                                                    <BSInput
                                                        id="buildingName0"
                                                        label="Latitude"
                                                        value={get(parcel, "latitude", "")}
                                                        placeholder="Enter Latitude"
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <BSInput
                                                        id="buildingName0"
                                                        label="Longitude"
                                                        value={get(parcel, "longitude", "")}
                                                        placeholder="Enter Longitude"
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={8}>
                                                <Grid item xs>
                                                    <BSInput
                                                        id="buildingName0"
                                                        label="APN/Parcel Code/Parcel ID"
                                                        value={get(parcel, "apn", "")}
                                                        placeholder="Enter Parcel ID"
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </Grid>

                            <Divider />

                            <FormTitle title="Building Information" />
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <BuildingForm
                                        {...this.props}
                                        data={get(property, "propertyAttributes", {})}
                                        context="property"
                                        errors={{}}
                                        disableInputs={true}
                                        onChange={(field, value) =>
                                            handleInput("property.propertyAttributes." + field, value)
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Divider />

                            <FormTitle title="Parking Information" />
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <ParkingInfoForm
                                        {...this.props}
                                        data={get(property, "propertyAttributes", {})}
                                        context="property"
                                        errors={{}}
                                        disableInputs={true}
                                        onChange={(field, value) =>
                                            handleInput("property.propertyAttributes." + field, value)
                                        }
                                    />
                                </Grid>
                            </Grid>
                            {toLower(propertyType) === "office" && (
                                <React.Fragment>
                                    <Divider />
                                    <FormTitle title="Office Information" />
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            <OfficeForm
                                                {...this.props}
                                                data={get(property, "propertyAttributes", {})}
                                                context="property"
                                                errors={{}}
                                                disableInputs={true}
                                                onChange={(field, value) =>
                                                    handleInput("property.propertyAttributes." + field, value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                            {toLower(propertyType) === "healthcare" && (
                                <React.Fragment>
                                    <Divider />
                                    <FormTitle title="Healthcare Information" />
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            <HealthcareForm
                                                {...this.props}
                                                data={get(property, "propertyAttributes", {})}
                                                context="property"
                                                errors={{}}
                                                disableInputs={true}
                                                onChange={(field, value) =>
                                                    handleInput("property.propertyAttributes." + field, value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                            {toLower(propertyType) === "industrial" && (
                                <React.Fragment>
                                    <Divider />
                                    <FormTitle title="Industrial Information" />
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            <IndustrialForm
                                                {...this.props}
                                                data={get(property, "propertyAttributes", {})}
                                                context="property"
                                                errors={{ errors }}
                                                disableInputs={true}
                                                onChange={(field, value) =>
                                                    handleInput("property.propertyAttributes." + field, value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                            {toLower(propertyType) === "retail" && (
                                <React.Fragment>
                                    <Divider />
                                    <FormTitle title="Retail Information" />
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            <RetailForm
                                                {...this.props}
                                                data={get(property, "propertyAttributes", {})}
                                                context="property"
                                                errors={{}}
                                                disableInputs={true}
                                                onChange={(field, value) =>
                                                    handleInput("property.propertyAttributes." + field, value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                            {toLower(propertyType) === "land" && (
                                <React.Fragment>
                                    <Divider />
                                    <FormTitle title="Land Information" />
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            <LandForm
                                                {...this.props}
                                                data={get(property, "propertyAttributes", {})}
                                                context="property"
                                                errors={{}}
                                                disableInputs={true}
                                                onChange={(field, value) =>
                                                    handleInput("property.propertyAttributes." + field, value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                            {toLower(propertyType) === "multifamily" && (
                                <React.Fragment>
                                    <Divider />
                                    <FormTitle title="Multifamily Information" />
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            <MultifamilyForm
                                                {...this.props}
                                                data={get(property, "propertyAttributes", {})}
                                                context="property"
                                                errors={{}}
                                                disableInputs={true}
                                                onChange={(field, value) =>
                                                    handleInput("property.propertyAttributes." + field, value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                            {toLower(propertyType) === "hospitality" && (
                                <React.Fragment>
                                    <Divider />
                                    <FormTitle title="Hospitality Information" />
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            <HospitalityForm
                                                {...this.props}
                                                data={get(property, "propertyAttributes", {})}
                                                context="property"
                                                errors={{}}
                                                disableInputs={true}
                                                onChange={(field, value) =>
                                                    handleInput("property.propertyAttributes." + field, value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                            {toLower(propertyType) === "other" && (
                                <React.Fragment>
                                    <Divider />
                                    <FormTitle title="Other Information" />
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            <OtherForm
                                                {...this.props}
                                                data={get(property, "propertyAttributes", {})}
                                                context="property"
                                                errors={{}}
                                                disableInputs={true}
                                                onChange={(field, value) =>
                                                    handleInput("property.propertyAttributes." + field, value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                            <Divider />
                            <FormTitle title="Attached Documents" />
                            <DocumentDrop
                                entityKey={propertyKey}
                                type="property"
                                documentTypes="propertyDetail"
                                viewOnly={true}
                                parentType={"PROPERTY"}
                                stateCode={get(property, "primaryAddress.city.state.stateCode", null)}
                            />
                        </React.Fragment>
                    )}

                    {/*
                     *
                     * Research
                     *
                     */}

                    {tab === "research_tab" &&
                        includes(["research", "dataEntry", "dataAdmin", "researchAdmin"], role) && (
                            <ResearchTab
                                {...this.props}
                                propertyKey={propertyKey}
                                editResponse={this.handleEditResearchSnapshot}
                            />
                        )}
                </Paper>

                <Dialog
                    open={this.state.closeDealDialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="close-deal"
                    aria-describedby="close-deal-dialog"
                >
                    <DialogTitle id="close-deal">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Closing this deal will start the invoice process for this transaction and move the listing
                            to a closed status. Portland Listings will be closed after the Comp for this Deal has been
                            Submitted and Approved.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={this.handleClose} color="secondary" autoFocus variant="contained">
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const compColumnsTofilter = [
    "listing.property.primaryAddress.address1",
    "listing.property.secondaryAddress",
    "listing.property.propertyAttributes.propertyName",
    "listing.property.primaryAddress.city.city",
    "listing.property.primaryAddress.city.state.stateCode",
    "listing.property.primaryAddress.city.state.stateName",
    "listing.property.primaryAddress.zipCode.zipCode",
    "transactionDate",
    "transactionType",
    "totalCommission",
    "dealKey",
];

const listingColumnsTofilter = [
    "property.primaryAddress.address1",
    "property.secondaryAddress",
    "property.propertyAttributes.propertyName",
    "property.primaryAddress.city.city",
    "property.primaryAddress.city.state.stateCode",
    "property.primaryAddress.city.state.stateName",
    "property.primaryAddress.zipCode.zipCode",
    "listingType",
    "listingKey",
    "sfAvail",
    "status",
    "brokerNames",
];

export default withStyles(styles)(PropertyDetail);
