import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

class BSInputSmallNumeric extends React.Component {
    render() {
        const {
            id,
            label,
            value,
            error,
            classes,
            disabled,
            placeholder,
            endAdornment,
            startAdornment,
            onChange,
            multiline,
            rows,
        } = this.props;

        return (
            <FormControl fullWidth className={classes.margin}>
                {label && (
                    <InputLabel shrink htmlFor={id} className={classes.bootstrapFormLabel}>
                        {label}
                    </InputLabel>
                )}
                <Input
                    id={id}
                    value={value}
                    type="number"
                    placeholder={placeholder}
                    onChange={onChange}
                    disableUnderline={true}
                    autoComplete={"no"}
                    className={classes.bootstrapInput}
                    endAdornment={endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>}
                    startAdornment={
                        startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>
                    }
                    disabled={disabled}
                    multiline={multiline}
                    rows={rows}
                />
                {error && <FormHelperText id={`helper-text-${id}`}>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

const styles = (theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        background: "red",
        marginBottom: 0,
    },
    bootstrapRoot: {
        "label + &": {
            marginTop: theme.spacing.unit * 3,
        },
    },
    bootstrapInput: {
        fontSize: 14,
        padding: "4px 6px",
        marginTop: 0, // this is also done in index.js @ MuiInput: FormControl: label + &... but needed in case there is no label
        transition: theme.transitions.create(["border-color", "box-shadow"]),
    },
    bootstrapFormLabel: {},
    margin: {
        marginBottom: "12px",
    },
});

BSInputSmallNumeric.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BSInputSmallNumeric);
