import React from "react";

const KMSvg = () => (
    <svg width="120px" height="29px" viewBox="0 0 120 29">
        <g id="Marketing" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="New" transform="translate(-16.000000, -16.000000)">
                <g id="Logo" transform="translate(16.000000, 16.000000)">
                    <g>
                        <rect id="Rectangle" fill="#FFFFFF" x="2" y="0" width="26.1" height="18"></rect>
                        <path
                            d="M16.7603306,5.21652893 L14.261157,8.11239669 L16.7404959,12.0793388 L14.3801653,12.0793388 L11.9008264,8.11239669 L14.3801653,5.21652893 L16.7603306,5.21652893 Z M24.0099174,8.39008264 L24.0099174,12.0297521 L21.877686,12.0297521 L21.877686,8.28099174 C21.877686,7.47768595 21.6396694,7.05123967 20.8264463,7.05123967 C20.0132231,7.05123967 19.6165289,7.4677686 19.6165289,8.39008264 L19.6165289,12.0297521 L17.4545455,12.0297521 L17.4545455,5.23636364 L19.6165289,5.23636364 L19.5669421,6.28760331 C19.9191265,5.54641241 20.6703588,5.07786032 21.4909091,5.08760331 C22.3094365,5.04473525 23.0700243,5.50975785 23.4049587,6.25785124 C23.9428131,5.51959214 24.8023005,5.08431526 25.7157025,5.08760331 C26.7568291,5.0174495 27.7281074,5.61454681 28.1355372,6.57520661 L28.1355372,0 L11.2760331,0 L11.2760331,12.0297521 L9.14380165,12.0297521 L9.14380165,0 L0,0 L0,28.1355372 L28.1355372,28.1355372 L28.1355372,12.0297521 L26.2710744,12.0297521 L26.2710744,8.28099174 C26.2710744,7.47768595 26.0231405,7.05123967 25.2099174,7.05123967 C24.3966942,7.05123967 24.0099174,7.4677686 24.0099174,8.39008264 Z"
                            id="Shape"
                            fill="#E04403"
                            fill-rule="nonzero"
                        ></path>
                        <polygon
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                            points="35.0380165 0 37.5173554 0 37.5173554 5.67272727 43.5768595 0 47.0082645 0 41.4545455 4.99834711 47.3157025 12.0099174 43.953719 12.0099174 39.5206612 6.56528926 37.5173554 8.33057851 37.5173554 12.0099174 35.0380165 12.0099174"
                        ></polygon>
                        <polygon
                            id="Rectangle-path"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                            points="48.307438 3.23305785 50.5884298 3.23305785 50.5884298 12.0099174 48.307438 12.0099174"
                        ></polygon>
                        <path
                            d="M62.5983471,12.0099174 L60.307438,12.0099174 L60.307438,10.8 C60.138843,11.3256198 58.7702479,12.3371901 56.9454545,12.3371901 C55.6714812,12.4167275 54.4234214,11.9529799 53.5104965,11.0608463 C52.5975716,10.1687127 52.1051998,8.93166572 52.1553719,7.65619835 C52.1027917,6.38533204 52.5902014,5.15151713 53.4971344,4.25969967 C54.4040674,3.36788221 55.6458993,2.90127621 56.9157025,2.97520661 C58.2170385,2.86339787 59.4852319,3.42454536 60.277686,4.46280992 L60.277686,0 L62.568595,0 L62.5983471,12.0099174 Z M57.5206612,10.5818182 C58.3234324,10.6646147 59.1212901,10.3849746 59.6967214,9.81913392 C60.2721526,9.25329319 60.5651616,8.46024761 60.4958678,7.65619835 C60.3032681,6.17366838 59.0404427,5.06428822 57.5454545,5.06428822 C56.0504663,5.06428822 54.787641,6.17366838 54.5950413,7.65619835 C54.5135895,8.46751643 54.8007724,9.27252911 55.3773448,9.8491015 C55.9539172,10.4256739 56.7589298,10.7128567 57.5702479,10.631405 L57.5206612,10.5818182 Z"
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        ></path>
                        <path
                            d="M74.538843,12.0099174 L72.2479339,12.0099174 L72.2479339,10.8 C72.0793388,11.3256198 70.7107438,12.3371901 68.8958678,12.3371901 C67.6152571,12.4285058 66.3563797,11.9699979 65.4344652,11.0764791 C64.5125506,10.1829602 64.0148957,8.93903847 64.0661157,7.65619835 C64.0125224,6.37799153 64.5052139,5.13733616 65.4211197,4.2441388 C66.3370255,3.35094144 67.5896568,2.88954634 68.8661157,2.97520661 C70.1643797,2.86499834 71.4288762,3.42616542 72.2181818,4.46280992 L72.2181818,0 L74.5090909,0 L74.538843,12.0099174 Z M69.4214876,10.5818182 C70.2242588,10.6646147 71.0221166,10.3849746 71.5975478,9.81913392 C72.1729791,9.25329319 72.465988,8.46024761 72.3966942,7.65619835 C72.3966942,6.01303711 71.0646488,4.68099174 69.4214876,4.68099174 C67.7783264,4.68099174 66.446281,6.01303711 66.446281,7.65619835 C66.3648292,8.46751643 66.6520121,9.27252911 67.2285844,9.8491015 C67.8051568,10.4256739 68.6101695,10.7128567 69.4214876,10.631405 L69.4214876,10.5818182 Z"
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        ></path>
                        <path
                            d="M78.3966942,8.20165289 C78.6559854,9.40389464 79.5925261,10.3445351 80.7936238,10.6090753 C81.9947215,10.8736155 83.2397834,10.41347 83.9801653,9.43140496 L86.4595041,9.43140496 C85.5485854,11.3493078 83.5479588,12.5054885 81.431405,12.3371901 C77.4644628,12.3371901 76.0066116,10.0363636 76.0066116,7.70578512 C76.0066116,4.78016529 78.1785124,2.91570248 81.2826446,2.91570248 C85.1702479,2.91570248 86.6578512,5.30578512 86.6578512,7.87438017 L86.6578512,8.20165289 L78.3966942,8.20165289 Z M84.2975207,6.74380165 C84.0376212,5.33291332 82.7522991,4.34749969 81.322314,4.46280992 C79.8964672,4.36140881 78.6193647,5.34052069 78.3471074,6.74380165 L84.2975207,6.74380165 Z"
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        ></path>
                        <path
                            d="M88.2644628,3.24297521 L90.5454545,3.24297521 L90.5454545,4.95867769 C91.1861588,3.72479486 92.4676524,2.95743346 93.8578512,2.97520661 C94.1640246,2.97791829 94.4693537,3.00778744 94.7702479,3.06446281 L94.7702479,5.34545455 C94.2997937,5.19223914 93.8070938,5.11850173 93.3123967,5.12727273 C92.5801177,5.0679071 91.8571088,5.32251954 91.3236157,5.82763534 C90.7901226,6.33275113 90.4964108,7.04077849 90.5157025,7.77520661 L90.5157025,12.0099174 L88.2644628,12.0099174 L88.2644628,3.24297521 Z"
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        ></path>
                        <polygon
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                            points="35.0380165 15.9966942 38.6082645 15.9966942 42.7438017 25.1603306 46.8495868 15.9966942 50.3900826 15.9966942 50.4 28.1355372 48.1090909 28.1355372 48.1487603 18.0495868 48.1190083 18.0495868 43.7553719 28.1355372 41.6330579 28.1355372 37.3090909 18.0495868 37.2694215 18.0495868 37.368595 28.1355372 35.0578512 28.1355372"
                        ></polygon>
                        <path
                            d="M61.6859504,25.785124 C61.6655899,25.9218726 61.7112388,26.0601797 61.8090006,26.1579415 C61.9067624,26.2557034 62.0450695,26.3013523 62.1818182,26.2809917 C62.4306931,26.2849153 62.6794682,26.2683303 62.9256198,26.231405 L62.9256198,27.8380165 C62.3925744,27.9646357 61.8470264,28.031166 61.2991736,28.0363636 C59.8115702,28.0363636 59.6528926,27.2429752 59.5834711,26.9157025 C59.1371901,27.3421488 58.0264463,28.1157025 55.4677686,28.1157025 C53.1768595,28.1157025 51.8876033,27.1239669 51.8876033,25.368595 C51.8876033,22.9586777 54.3768595,22.7404959 55.8545455,22.6115702 C59.107438,22.3140496 59.5041322,22.2049587 59.5041322,21.5206612 C59.5041322,20.5289256 58.3438017,20.261157 57.014876,20.261157 C55.5173554,20.261157 54.7041322,20.7471074 54.6247934,21.7190083 L52.3338843,21.7190083 C52.6710744,19.4181818 54.3768595,18.7438017 56.9652893,18.7438017 C61.5471074,18.7438017 61.6859504,20.538843 61.6859504,21.7190083 L61.6859504,25.785124 Z M59.4049587,23.4247934 C58.4418684,23.7380158 57.4413201,23.9214496 56.4297521,23.9702479 C54.7041322,24.168595 54.2975207,24.5454545 54.2975207,25.2892562 C54.2975207,26.4495868 55.3487603,26.6677686 56.1322314,26.6677686 C57.3520661,26.6677686 59.4247934,26.2016529 59.4247934,24.2677686 L59.4049587,23.4247934 Z"
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        ></path>
                        <path
                            d="M64.6413223,20.8264463 L62.8264463,20.8264463 L62.8264463,19.1702479 L64.6413223,19.1702479 L64.6413223,16.4628099 L66.922314,16.4628099 L66.922314,19.1702479 L69.3123967,19.1702479 L69.3123967,20.8264463 L66.922314,20.8264463 L66.922314,24.7933884 C66.922314,25.785124 66.922314,26.3504132 67.7553719,26.3504132 C68.3107438,26.3504132 68.846281,26.3504132 69.3917355,26.3504132 L69.3917355,28.0066116 C68.8104946,28.0919375 68.2238339,28.1350255 67.6363636,28.1355372 C64.8595041,28.1355372 64.661157,27.1438017 64.661157,25.4975207 L64.6413223,20.8264463 Z"
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        ></path>
                        <path
                            d="M70.8694215,15.7983471 L73.1603306,15.7983471 L73.1603306,20.3603306 C74.0172976,19.3293008 75.3127619,18.7662155 76.6512397,18.8429752 C78.5355372,18.8429752 80.3305785,19.4876033 80.3305785,21.8181818 L80.3305785,28.0958678 L78.0495868,28.0958678 L78.0495868,22.5421488 C78.0495868,21.6 77.7322314,20.5586777 75.8479339,20.5586777 C75.1410985,20.4999922 74.4422744,20.7420131 73.9231334,21.2252862 C73.4039925,21.7085593 73.1126444,22.3882986 73.1206612,23.0975207 L73.1206612,28.1355372 L70.8396694,28.1355372 L70.8694215,15.7983471 Z"
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        ></path>
                        <path
                            d="M84.1884298,24 C84.2267615,24.7349077 84.5691985,25.4207156 85.1336113,25.892941 C85.698024,26.3651664 86.4334973,26.5812099 87.1636364,26.4892562 C88.2076427,26.6179679 89.2307736,26.1257799 89.7818182,25.2297521 L92.231405,25.2297521 C91.3298714,27.1227219 89.3648217,28.2742411 87.2727273,28.1355372 C83.3057851,28.1355372 81.8479339,25.8446281 81.8479339,23.5041322 C81.8479339,20.5785124 84.0099174,18.7140496 87.1239669,18.7140496 C91.0115702,18.7140496 92.4991736,21.1140496 92.4991736,23.6727273 L92.4991736,24 L84.1884298,24 Z M90.0793388,22.5520661 C89.7435906,21.1984664 88.5285022,20.2479488 87.1338843,20.2479488 C85.7392664,20.2479488 84.524178,21.1984664 84.1884298,22.5520661 L90.0793388,22.5520661 Z"
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        ></path>
                        <polygon
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                            points="92.8264463 19.0413223 95.3057851 19.0413223 97.5471074 25.4578512 97.5867769 25.4578512 99.6892562 19.0413223 102.069421 19.0413223 104.171901 25.4578512 104.201653 25.4578512 106.452893 19.0413223 108.932231 19.0413223 105.590083 28.1355372 103.01157 28.1355372 100.889256 21.7190083 100.859504 21.7190083 98.707438 28.1355372 96.1487603 28.1355372"
                        ></polygon>
                        <path
                            d="M111.957025,25.061157 C112.026446,26.3900826 113.543802,26.5884298 114.37686,26.5884298 C115.517355,26.5884298 116.757025,26.2909091 116.757025,25.4578512 C116.757025,24.7834711 116.370248,24.5454545 113.910744,24.2181818 C111.193388,23.8512397 109.775207,23.5438017 109.775207,21.5404959 C109.775207,19.0413223 112.690909,18.7140496 114.158678,18.7140496 C115.626446,18.7140496 118.770248,18.9123967 118.909091,21.6892562 L116.519008,21.6892562 C116.340496,20.3801653 114.82314,20.231405 114.039669,20.231405 C112.750413,20.231405 112.056198,20.7867769 112.056198,21.2231405 C112.056198,21.9371901 112.27438,22.0561983 114.476033,22.3636364 C117.352066,22.7801653 119.236364,23.0578512 119.236364,25.0909091 C119.236364,27.3322314 117.104132,28.0661157 114.386777,28.0661157 C112.522314,28.0661157 109.557025,27.6694215 109.428099,24.9917355 L111.957025,25.061157 Z"
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        ></path>
                        <path
                            d="M50.6280992,1.19008264 C50.6280824,1.67232503 50.3370433,2.10688659 49.8911212,2.29049255 C49.445199,2.4740985 48.9325546,2.37044914 48.5929874,2.02802851 C48.2534203,1.68560788 48.1540644,1.17211404 48.3413954,0.727743921 C48.5287265,0.283373799 48.9657082,-0.00401868625 49.4479339,-3.76191049e-16 C49.7626591,-1.11125907e-05 50.0643515,0.12568782 50.2859627,0.349161327 C50.507574,0.572634835 50.6307439,0.875368568 50.6280992,1.19008264"
                            id="Shape"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default KMSvg;
