import React from "react";
import { connect } from "react-redux";
import RequestModal from "./RequestModal";
import operations from "./ducks/operations";
import compOperations from "../comp/ducks/operations";
class RequestModalContainer extends React.Component {
    render() {
        return <RequestModal {...this.props} autocompleteResults={this.props.autocompleteResults} />;
    }
}

const mapStateToProps = (state) => {
    return {
        role: state.authReducer.userProfile.role,
        user: state.authReducer.userProfile.user,
        isLoading: state.utilsReducer.isLoading,
        searchResults: state.searchReducer.autocompleteResults,
        autocompleteResults: state.searchReducer.autocompleteResults,
        requestModal: state.listingReducer.requestModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectProperty: (property) => {
            dispatch(operations.getPropertyForListingCreation(property));
        },
        removeSelectedProperty: () => {
            dispatch(operations.removeSelectedPropertyFromRequestModal());
        },
        updateListingRequest: (field, value) => {
            dispatch(operations.updateListingRequest(field, value));
        },
        requestListing: (modalType, transactionType, propertyType, tab, role) => {
            switch (modalType) {
                case "listing":
                    if (tab === "existing") {
                        dispatch(operations.requestListing());
                    } else {
                        dispatch(operations.requestListingNewProperty());
                    }
                    break;
                case "appraisal":
                    dispatch(
                        compOperations.addOutsideComp(transactionType, propertyType, tab === "new", "Appraisal Draft")
                    );
                    break;
                case "outside-comp":
                    dispatch(
                        compOperations.addOutsideComp(transactionType, propertyType, tab === "new", "Outside Draft")
                    );
                    break;
                case "internal-comp":
                    dispatch(compOperations.addOutsideComp(transactionType, propertyType, tab === "new", "Draft"));
                    break;
                default:
                    break;
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestModalContainer);
