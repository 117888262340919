import validate from "validate.js";
import { constraints } from "../../utils/validation";
import each from "lodash/each";
import set from "lodash/set";
import forIn from "lodash/forIn";
import isEmpty from "lodash/isEmpty";
import mapValues from "lodash/mapValues";

const requestProperty = {
    propertyName: {
        ...constraints.required,
    },
    propertyAddress: {
        ...constraints.required,
    },
    propertyCity: {
        ...constraints.required,
    },
    propertyState: {
        ...constraints.required,
    },
    propertyZip: {
        ...constraints.required,
        ...constraints.id,
    },
    propertyType: {
        ...constraints.required,
    },
};

const propertyForm = {
    "propertyAttributes.propertyName": {
        ...constraints.required,
    },
    "primaryAddress.address1": {
        ...constraints.required,
    },
    "primaryAddress.city.city": {
        ...constraints.required,
    },
    "primaryAddress.city.state.stateCode": {
        ...constraints.required,
    },
    "primaryAddress.zipCode.zipCode": {
        ...constraints.required,
    },
    "primaryAddress.county.name": {
        ...constraints.required,
    },
    propertyKey: {
        ...constraints.id,
    },
    propertyType: {
        ...constraints.required,
    },
};

const propertyFormAttributes = {
    propertyName: {
        ...constraints.required,
    },
    specialtyType: {},
    marketArea: {},
};

const buildingForm = {
    buildingName: {},
    bldgStatus: {},
    bldgClass: {},
    yearBuilt: {},
    yearRemodeled: {},
    totalBldgSf: {
        ...constraints.number,
    },
    numFloors: {
        ...constraints.number,
    },
    totalUnits: {
        ...constraints.number,
    },
    numBldg: {
        ...constraints.number,
    },
    lotAcres: {
        ...constraints.number,
    },
    lotSf: {
        ...constraints.number,
    },
    elevators: {
        ...constraints.number,
    },
    deliveredQtr: {
        ...constraints.number,
    },
    totalBldgNra: {
        ...constraints.number,
    },
    percentLeased: {
        ...constraints.number,
    },
    freightElevatorCount: {
        ...constraints.number,
    },
};

const propertyManagementDetails = {
    landAcreage: {
        ...constraints.number,
        ...constraints.positiveNumberOrEmpty,
    },
    oaMemberCount: {
        ...constraints.integer,
        ...constraints.positiveNumberOrEmpty,
    },
    numberOfBuildings: {
        ...constraints.integer,
        ...constraints.positiveNumberOrEmpty,
    },
    numberOfTenants: {
        ...constraints.integer,
        ...constraints.positiveNumberOrEmpty,
    },
    grossSqFt: {
        ...constraints.integer,
        ...constraints.positiveNumberOrEmpty,
    },
    flatRateFee: {
        ...constraints.number,
        ...constraints.positiveNumberOrEmpty,
    },
    percentOfRevenue: {
        ...constraints.number,
        ...constraints.positiveNumberOrEmpty,
    },
    minimumAmount: {
        ...constraints.number,
        ...constraints.positiveNumberOrEmpty,
    },
    minMgtFee: {
        ...constraints.number,
        ...constraints.positiveNumberOrEmpty,
        ...constraints.between0And100,
    },
    maxMgtFee: {
        ...constraints.number,
        ...constraints.positiveNumberOrEmpty,
        ...constraints.between0And100,
    },
    effectiveMonthIncrease: {
        ...constraints.number,
        ...constraints.between1And12,
    },
};

const propertyConstraints = {
    Office: {
        "propertyAttributes.suite": {},
        "propertyAttributes.sfAvail": {
            ...constraints.id,
        },
        "propertyAttributes.largestContigSf": {},
        "propertyAttributes.specialtyType": {},
    },
    Industrial: {
        "propertyAttributes.voltsNum": {},
        "propertyAttributes.ampsNum": {},
        "propertyAttributes.phaseNum": {},
        "propertyAttributes.gradeLevelDoorsNum": {
            ...constraints.id,
        },
        "propertyAttributes.dockHighDoorsNum": {
            ...constraints.id,
        },
        "propertyAttributes.railDoorsNum": {
            ...constraints.id, //int(11) in DB
        },
        "propertyAttributes.clearHeightMin": {
            ...constraints.id,
        },
        "propertyAttributes.clearHeightMax": {
            ...constraints.id,
        },
        "propertyAttributes.cranesQty": {
            ...constraints.id,
        },
        "propertyAttributes.craneNotes": {},
        "propertyAttributes.yardNotes": {},
        "propertyAttributes.specialtyType": {},
    },
    Retail: {
        "propertyAttributes.specialtyType": {},
    },
    Land: {
        "propertyAttributes.specialtyType": {},
    },
    Multifamily: {
        "propertyAttributes.officeSf": {
            ...constraints.id,
        },
        "propertyAttributes.retailSf": {
            ...constraints.id,
        },
        "propertyAttributes.unitMix": {},
        "propertyAttributes.specialtyType": {},
    },
    Hospitality: {
        "propertyAttributes.condoUnitCount": {
            ...constraints.id,
        },
        "propertyAttributes.avgRevPar": {
            ...constraints.number,
        },
        "propertyAttributes.officeSf": {
            ...constraints.id,
        },
        "propertyAttributes.retailSf": {
            ...constraints.id,
        },
        "propertyAttributes.penthouseUnitCount": {
            ...constraints.id,
        },
        "propertyAttributes.multifamilyUnitCount": {
            ...constraints.id,
        },
        "propertyAttributes.specialtyType": {},
    },
    Other: {
        "propertyAttributes.sfAvail": {
            ...constraints.id,
        },
        "propertyAttributes.largestContigSf": {
            ...constraints.id,
        },
        "propertyAttributes.suite": {},
        "propertyAttributes.specialtyType": {},
    },
    Educational: {}, //TODO : Add validation when forms are created
    Religious: {},
    Healthcare: {
        "propertyAttributes.suite": {},
        "propertyAttributes.sfAvail": {
            ...constraints.id,
        },
        "propertyAttributes.largestContigSf": {},
        "propertyAttributes.specialtyType": {},
    },
    propertyForm: {
        "propertyAttributes.propertyName": {
            ...constraints.required,
        },
        "primaryAddress.address1": {
            ...constraints.required,
        },
        "primaryAddress.city.city": {
            ...constraints.required,
        },
        "primaryAddress.city.state.stateCode": {
            ...constraints.required,
        },
        "primaryAddress.zipCode.zipCode": {
            ...constraints.required,
        },
        "primaryAddress.county.name": {
            // ...constraints.required,
        },
        propertyKey: {
            ...constraints.id,
        },
        propertyType: {
            ...constraints.required,
        },
        "propertyAttributes.marketArea": {},
    },
    buildingForm: {
        "propertyAttributes.buildingName": {},
        "propertyAttributes.bldgStatus": {},
        "propertyAttributes.bldgClass": {},
        "propertyAttributes.yearBuilt": {},
        "propertyAttributes.yearRemodeled": {},
        "propertyAttributes.totalBldgSf": {
            ...constraints.number,
        },
        "propertyAttributes.numFloors": {
            ...constraints.number,
        },
        "propertyAttributes.totalUnits": {
            ...constraints.number,
        },
        "propertyAttributes.numBldg": {
            ...constraints.number,
        },
        "propertyAttributes.lotAcres": {
            ...constraints.number,
        },
        "propertyAttributes.lotSf": {
            ...constraints.number,
        },
        "propertyAttributes.elevators": {
            ...constraints.number,
        },
        "propertyAttributes.deliveredQtr": {
            ...constraints.number,
        },
        "propertyAttributes.totalBldgNra": {
            ...constraints.number,
        },
        "propertyAttributes.percentLeased": {
            ...constraints.number,
        },
        "propertyAttributes.freightElevatorCount": {
            ...constraints.number,
        },
    },
    parkingInformation: {
        "propertyAttributes.parkingTotal": {
            ...constraints.number,
        },
        "propertyAttributes.parkingRatio": {},
        "propertyAttributes.surfaceParkingSpaceCount": {
            ...constraints.number,
        },
        "propertyAttributes.parkingGarageSpaceCount": {
            ...constraints.number,
        },
        "propertyAttributes.parkingReservedSpaceCount": {
            ...constraints.number,
        },
        "propertyAttributes.carportParkingSpaceCount": {
            ...constraints.number,
        },
        "propertyAttributes.parkingNotes": {},
    },
};

const validateRequestProperty = (values) => {
    return validate(values, requestProperty, { fullMessages: false });
};

const validatePropertyForm = (values) => {
    return validate(values, propertyForm, { fullMessages: false });
};
const validatePropertyFormAttributes = (values) => {
    return validate(values, propertyFormAttributes, { fullMessages: false });
};
const validateBuildingForm = (values) => {
    return validate(values, buildingForm, { fullMessages: false });
};

const validatePropertyManagementDetails = (values) => {
    if (values.softwareLicenseReimbursedAmount || values.softwareLicenseReimbursedFrequency) {
        propertyManagementDetails.softwareLicenseReimbursedAmount = { ...constraints.required };
        propertyManagementDetails.softwareLicenseReimbursedFrequency = { ...constraints.required };
    }

    if (values.realEstateTaxesPaidBy === "Other") {
        propertyManagementDetails.taxOwnershipInformation = { ...constraints.required };
    }

    const validationResponse = validate(values, propertyManagementDetails, { fullMessages: false });
    delete propertyManagementDetails.softwareLicenseReimbursedAmount;
    delete propertyManagementDetails.softwareLicenseReimbursedFrequency;
    delete propertyManagementDetails.taxOwnershipInformation;
    return validationResponse;
};

const validateProperty = (property, propertyType) => {
    let whatToValidate = [propertyType, "propertyForm", "buildingForm", "parkingInformation"];

    let combinedConstraints = {};
    each(whatToValidate, (section) => {
        combinedConstraints = { ...combinedConstraints, ...propertyConstraints[section] };
    });
    let setErrors = {};
    let errors = {
        ...mapValues(validate(property, combinedConstraints, { fullMessages: false }), (error) => {
            return error[0];
        }),
    };
    forIn(errors, (value, key) => {
        set(setErrors, key, value);
    });
    return isEmpty(setErrors) ? null : setErrors;
};

export default {
    validateRequestProperty,
    validatePropertyForm,
    validatePropertyFormAttributes,
    validateBuildingForm,
    validatePropertyManagementDetails,
    validateProperty,
};
