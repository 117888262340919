import React from "react";
import { get, debounce, includes, isNil, orderBy } from "lodash";
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Delete, Search, Today } from "@material-ui/icons";
import PublicSiteDisplaySettings from "./PublicSiteDisplaySettingsBeta";
import Autocomplete from "../../../../common/js/components/Autocomplete";
import AutocompleteFreeform from "../../../../common/js/components/AutocompleteFreeform";
import BSInput from "../../../../common/js/components/BSInput";
import BSSelect from "../../../../common/js/components/BSSelect";
import FormTitle from "../../../../common/js/components/FormTitle";
import { Add } from "../../../../common/js/icons";
import searchConstants from "../../search/constants";
import { specialtyTypeFilterOptions } from "../../property/constants";
import { round } from "../../utils/operations";

import "./ListingForm.css";

const ListingForm = (props) => {
    const {
        autocompleteResults,
        classes,
        compStatus,
        disableInputs,
        errors,
        handleAddArrayElement,
        handleDeleteArrayElement,
        handleInputChange,
        handleSearchCompany,
        handleSearchKMBrokers,
        handleSearchOutsideBrokers,
        handleUpdateArrayElement,
        isOutside,
        listing,
        showListingExpiration,
        suppressRequired,
        transactionType,
    } = props;

    const handleListingAttributeChange = (field, value) => {
        handleInputChange("listing.attributes." + field, value);
    };

    const handleListingChange = (field, value) => {
        handleInputChange("listing." + field, value);
    };

    const handleDateChangewFlg = (field, value) => {
        handleInputChange("listing." + field, value);
        if (field === "autoRenewFlg" && value === true) {
            handleInputChange("listing.expirationDate", null);
        }
        if (field === "nonKmListingFlg" && value === true) {
            handleInputChange("listing.dateListed", null);
        }
    };

    const handleLotSizeAutoCalc = (field, value) => {
        if (field === "lotAcres") {
            if (isNaN(value)) {
                handleInputChange("listing.attributes.lotSf", "");
                handleInputChange("listing.attributes.lotAcres", "");
            } else {
                const lotSf = value * 43560;
                handleInputChange("listing.attributes.lotAcres", value);
                handleInputChange("listing.attributes.lotSf", lotSf);
            }
        } else if (field === "lotSf") {
            if (isNaN(value)) {
                handleInputChange("listing.attributes.lotSf", "");
                handleInputChange("listing.attributes.lotAcres", "");
            } else {
                const lotAcres = round(value / 43560, 2);
                handleInputChange("listing.attributes.lotSf", value);
                handleInputChange("listing.attributes.lotAcres", lotAcres);
            }
        }
    };

    const handleKmBrokerPersonSelect = (broker, index) => {
        //(arrayPath, index, field, value)
        handleUpdateArrayElement(
            "listing.listingBrokers.kmListingBrokers",
            index,
            "brokerAgentKey.person.firstName",
            broker.firstName
        );
        handleUpdateArrayElement(
            "listing.listingBrokers.kmListingBrokers",
            index,
            "brokerAgentKey.person.lastName",
            broker.lastName
        );
        handleUpdateArrayElement(
            "listing.listingBrokers.kmListingBrokers",
            index,
            "brokerAgentKey.brokerAgentKey",
            broker.brokerAgentKey
        );
    };

    const handleOutsideBrokerPersonSelect = (broker, index) => {
        //(arrayPath, index, field, value)
        handleUpdateArrayElement(
            "listing.listingBrokers.outsideListingBrokers",
            index,
            "brokerAgentKey.person.firstName",
            broker.firstName
        );
        handleUpdateArrayElement(
            "listing.listingBrokers.outsideListingBrokers",
            index,
            "brokerAgentKey.person.lastName",
            broker.lastName
        );
        handleUpdateArrayElement(
            "listing.listingBrokers.outsideListingBrokers",
            index,
            "brokerAgentKey.brokerAgentKey",
            broker.brokerAgentKey
        );
    };

    const handleOutsideBrokerCompanySelect = (company, index) => {
        //(arrayPath, index, field, value)
        handleUpdateArrayElement("listing.listingBrokers.outsideListingBrokers", index, "company", company);
    };

    const handleOutsideBrokerPersonFreeform = (value, index) => {
        //(arrayPath, index, field, value)
        handleUpdateArrayElement(
            "listing.listingBrokers.outsideListingBrokers",
            index,
            "brokerAgentKey.person.firstName",
            value
        );
        handleUpdateArrayElement(
            "listing.listingBrokers.outsideListingBrokers",
            index,
            "brokerAgentKey.brokerAgentKey",
            null
        );
    };

    const handleCompanyFreeform = (value, index) => {
        //(arrayPath, index, field, value)
        handleUpdateArrayElement("listing.listingBrokers.outsideListingBrokers", index, "company", {
            displayName: value,
            companyKey: null,
            companyTypes: ["REAL_ESTATE"],
            approvedFlg: false,
        });
    };

    const getCompanyFieldLabel = (field, companyType) => {
        let labelText = "";
        let buyerTenantText = companyType === "SELLER_LANDLORD" ? "Landlord" : "Tenant";
        if (transactionType === "Sale") {
            buyerTenantText = companyType === "SELLER_LANDLORD" ? "Seller" : "Buyer";
        }

        if (field === "legalName") {
            labelText = buyerTenantText + " Name (Legal)*";
        } else if (field === "displayName") {
            labelText = buyerTenantText + " Name (Actual Owner)*";
        } else if (field === "parentCompany") {
            labelText = buyerTenantText + " Name (Parent Company/Institutional Owner)";
        }

        return labelText;
    };

    const debounceSearchCompany = debounce((handleSearchCompany, term) => {
        handleSearchCompany(term, "REAL_ESTATE");
    }, searchConstants["DEBOUNCE_SEARCH_DELAY"]);

    const getCompanyPlaceholderText = (field, companyType) => {
        let placeholderText = "";
        let buyerTenantText = companyType === "SELLER_LANDLORD" ? "landlord" : "tenant";
        if (transactionType === "Sale") {
            buyerTenantText = companyType === "SELLER_LANDLORD" ? "building owner" : "buyer";
        }

        if (field === "legalName") {
            placeholderText = "Enter " + buyerTenantText + " name (legal)";
        } else if (field === "displayName") {
            placeholderText = "Enter " + buyerTenantText + " name (actual)";
        } else if (field === "parentCompany") {
            placeholderText = "Enter " + buyerTenantText + " name (parent company/institutional owner)";
        }

        return placeholderText;
    };

    const updateDivisible = (e, val) => {
        if (!val) {
            handleListingChange("sfDivisibleTo", null);
        }
        handleListingChange(e, val);
    };

    let attributes = get(listing, "attributes", {});
    if (attributes == null) {
        attributes = {};
    }

    const department = (suggestion) => {
        if (
            get(suggestion, "department", null) !== null &&
            get(suggestion, "department", null) !== "" &&
            departmentNames[get(suggestion, "department", "N/A")] !== undefined
        ) {
            return departmentNames[get(suggestion, "department", "N/A")]["display"];
        } else {
            return departmentNames["N/A"]["display"];
        }
    };

    const departmentNames = searchConstants["departments"];
    const displayRequired = !(suppressRequired === true);

    return (
        <div>
            <div
                className="listingFormFlexContainerUnresponsive"
                style={{ alignItems: "flex-start", justifyContent: "space-between" }}
            >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={get(listing, "subleaseFlg", false) ? true : false}
                                onChange={(e, checked) => handleListingChange(e.target.value, checked)}
                                value="subleaseFlg"
                                disabled={disableInputs === true}
                                color="primary"
                            />
                        }
                        label="Sublease"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={get(listing, "attributes.excessSurplusLandFlg", false)}
                                onChange={(e, checked) =>
                                    handleListingAttributeChange(
                                        e.target.value,
                                        !get(listing, "attributes.excessSurplusLandFlg", false)
                                    )
                                }
                                value="excessSurplusLandFlg"
                                disabled={disableInputs === true}
                                color="primary"
                            />
                        }
                        label="Excess/Surplus Land"
                    />
                    {!includes(["For Lease", "LEASE", "Lease"], get(listing, "listingType")) && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={get(listing, "importedFlg", false) ? true : false}
                                    onChange={(e, checked) => {
                                        handleListingChange(e.target.value, checked);
                                    }}
                                    value="importedFlg"
                                    disabled={true}
                                    color="primary"
                                />
                            }
                            label="Imported"
                        />
                    )}
                </FormGroup>
                <div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={get(listing, "autoRenewFlg", false) ? true : false}
                                    onChange={(e, checked) => handleDateChangewFlg(e.target.value, checked)}
                                    value="autoRenewFlg"
                                    disabled={disableInputs === true || !showListingExpiration}
                                    color="primary"
                                />
                            }
                            label="Auto Renew Listing"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={get(listing, "attributes.ownerOccupiedFlg", false) ? true : false}
                                    onChange={(e, checked) => handleListingAttributeChange(e.target.value, checked)}
                                    value="ownerOccupiedFlg"
                                    disabled={disableInputs === true}
                                    color="primary"
                                />
                            }
                            label="Owner Occupied"
                        />
                    </FormGroup>
                </div>
                <div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={get(listing, "nonKmListingFlg", false) ? true : false}
                                    onChange={(e, checked) => handleDateChangewFlg(e.target.value, checked)}
                                    value="nonKmListingFlg"
                                    disabled={disableInputs === true || get(listing, "status", "Draft") !== "Draft"}
                                    color="primary"
                                />
                            }
                            label="Non KM Listing"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={get(listing, "attributes.retailInvestmentNNNFlg", false) ? true : false}
                                    onChange={(e, checked) => handleListingAttributeChange(e.target.value, checked)}
                                    value="retailInvestmentNNNFlg"
                                    disabled={disableInputs === true}
                                    color="primary"
                                />
                            }
                            label="Retail Investment NNN"
                        />
                    </FormGroup>
                </div>
                <div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={get(listing, "binswangerDealFlg", false) ? true : false}
                                    onChange={(e, checked) => handleListingChange(e.target.value, checked)}
                                    value="binswangerDealFlg"
                                    disabled={disableInputs === true}
                                    color="primary"
                                />
                            }
                            label="Binswanger Deal"
                        />
                        {!includes(["For Lease", "LEASE", "Lease"], get(listing, "listingType")) && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(listing, "attributes.investmentPropertyFlg", true)}
                                        onChange={(e) =>
                                            handleListingAttributeChange(
                                                e.target.value,
                                                !get(listing, "attributes.investmentPropertyFlg", false)
                                            )
                                        }
                                        value="investmentPropertyFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Investment Property Sale"
                            />
                        )}
                        {includes(["For Lease", "LEASE", "Lease"], get(listing, "listingType")) && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            get(listing, "availabilityStatus", false) === "Occupied" ||
                                            get(listing, "availabilityStatus", false) === null
                                                ? false
                                                : true
                                        }
                                        onChange={(e, checked) => {
                                            handleListingChange(
                                                e.target.value,
                                                checked ? (checked = "Vacant") : (checked = "Occupied")
                                            );
                                        }}
                                        value="availabilityStatus"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Vacant"
                            />
                        )}
                    </FormGroup>
                </div>
                <div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={get(listing, "jdsDealFlg", false) ? true : false}
                                    onChange={(e, checked) => handleListingChange(e.target.value, checked)}
                                    value="jdsDealFlg"
                                    disabled={disableInputs === true}
                                    color="primary"
                                />
                            }
                            label="JDS Deal"
                        />

                        {!includes(["For Lease", "LEASE", "Lease"], get(listing, "listingType")) && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            get(listing, "availabilityStatus", false) === "Occupied" ||
                                            get(listing, "availabilityStatus", false) === null
                                                ? false
                                                : true
                                        }
                                        onChange={(e, checked) => {
                                            handleListingChange(
                                                e.target.value,
                                                checked ? (checked = "Vacant") : (checked = "Occupied")
                                            );
                                        }}
                                        value="availabilityStatus"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Vacant"
                            />
                        )}
                        {includes(["For Lease", "LEASE", "Lease"], get(listing, "listingType")) && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(listing, "importedFlg", false) ? true : false}
                                        onChange={(e, checked) => {
                                            handleListingChange(e.target.value, checked);
                                        }}
                                        value="importedFlg"
                                        disabled={true}
                                        color="primary"
                                    />
                                }
                                label="Imported"
                            />
                        )}
                    </FormGroup>
                </div>
            </div>
            {/* Public Site Display Settings */}
            {!listing.nonKmListingFlg && (
                <PublicSiteDisplaySettings
                    handleListingChange={handleListingChange}
                    listing={listing}
                    disableInputs={disableInputs}
                />
            )}
            <Grid container spacing={8}>
                <Grid item>&nbsp;</Grid>
            </Grid>

            <div className="listingFormFlexContainer" style={{ paddingBottom: "10px" }}>
                {displayRequired && (
                    <div className="flexGrowSingle">
                        <BSInput
                            id="listingName"
                            label="Listing Name*"
                            helperText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                            errorText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                            error={
                                !includes(["Outside Draft", "Outside"], compStatus) ? get(errors, "listingName") : ""
                            }
                            value={listing.listingName}
                            onChange={(e) => handleListingChange(e.target.id, e.target.value)}
                            placeholder="Enter listing name"
                            disabled={disableInputs}
                        />
                    </div>
                )}
                <div className="listingFormFlexContainerUnresponsive flexGrowSingle">
                    <div className="flexGrowSingle">
                        <BSInput
                            id="suite"
                            label="SUITE"
                            placeholder="Enter suite"
                            error={get(errors, "attributes.suite")}
                            value={attributes.suite}
                            onChange={(e) => handleListingAttributeChange(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </div>
                    {attributes.useType !== "Religious" && (
                        <div className="flexGrowSingle">
                            <BSSelect
                                id="specialtyType"
                                label="Sub-Type*"
                                helperText="Required"
                                errorText="Required"
                                error={get(errors, "attributes.specialtyType")}
                                value={attributes.specialtyType}
                                onChange={(e) => handleListingAttributeChange(e.target.id, e.target.value)}
                                options={specialtyTypeFilterOptions(attributes.useType)}
                                disabled={disableInputs}
                            />
                        </div>
                    )}
                </div>
            </div>

            <small style={{ fontSize: "15px", color: "#E53935", weight: "700" }}>
                {" "}
                A KM Broker or Outside Broker must be added
            </small>
            <div className="listingFormFlexContainer" style={{ alignItems: "start" }}>
                <div className="flexGrowSingle fullWidthOnSmallDisplays">
                    <FormTitle display="inline-block" title="KM Listing Broker" />

                    {orderBy(
                        get(listing, "listingBrokers.kmListingBrokers"),
                        "brokerAgentKey.kmBrokerFlag",
                        "desc"
                    ).map((broker, index) => {
                        return (
                            <div style={{ alignItems: "center" }}>
                                <div className="kmListingBrokers">
                                    <Autocomplete
                                        id="firstName"
                                        label={index === 0 ? "Km Broker" : ""}
                                        initialInputValue={`${get(broker, `brokerAgentKey.person.firstName`, "")}${
                                            get(broker, `brokerAgentKey.person`, null) ? " " : ""
                                        }${get(broker, `brokerAgentKey.person.lastName`, "")}`}
                                        itemToString={(i) => get(i, `firstName`, null)}
                                        onSelect={(broker) => handleKmBrokerPersonSelect(broker, index)}
                                        placeholder="Enter broker"
                                        searchFunction={handleSearchKMBrokers}
                                        startAdornment={<Search />}
                                        suggestions={autocompleteResults}
                                        disabled={disableInputs}
                                        options={{
                                            key: "brokerAgentKey",
                                            display: (suggestion) => {
                                                let brokerName =
                                                    get(suggestion, "firstName", "") +
                                                    " " +
                                                    get(suggestion, "lastName", "");
                                                let departmentType = department(suggestion);
                                                let brokerOffice = get(suggestion, "officeName", "");
                                                if (brokerOffice === null) brokerOffice = "N/A";
                                                return brokerName + " - " + departmentType + " - " + brokerOffice;
                                            },
                                        }}
                                    />
                                    {!disableInputs && (
                                        <Button
                                            className={classes.shrinkButton}
                                            style={index === 0 ? { marginTop: "0px" } : { marginTop: "-20px" }}
                                            onClick={() =>
                                                handleDeleteArrayElement(
                                                    "listing.listingBrokers.kmListingBrokers",
                                                    index
                                                )
                                            }
                                        >
                                            <Delete />
                                        </Button>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    {!disableInputs && (
                        <Button
                            className={classes.addRow}
                            color="primary"
                            onClick={() =>
                                handleAddArrayElement("listingBrokers.kmListingBrokers", {
                                    listingKey: listing.listingKey,
                                    brokerAgentKey: { kmBrokerFlag: true },
                                })
                            }
                        >
                            <Add fill="#4b94c1" className={classes.icon} />
                            Add KM Listing Broker
                        </Button>
                    )}
                </div>
                <div className="flexGrowSingle fullWidthOnSmallDisplays">
                    <FormTitle title="Outside Listing Broker" />
                    {orderBy(
                        get(listing, "listingBrokers.outsideListingBrokers"),
                        "brokerAgentKey.kmBrokerFlag",
                        "desc"
                    ).map((broker, index) => {
                        return (
                            <div className="listingFormFlexContainerUnresponsive" style={{ alignItems: "center" }}>
                                <div>
                                    <AutocompleteFreeform
                                        id="company"
                                        label={index === 0 ? "Company Name (Common)" : ""}
                                        error={get(
                                            errors,
                                            `listingBrokers.outside[${index}]["company.displayName"]`,
                                            ""
                                        )}
                                        errorText="Required"
                                        initialInputValue={get(broker, `company.displayName`, "")}
                                        itemToString={(i) => get(i, `displayName`, null)}
                                        onSelect={(company) => handleOutsideBrokerCompanySelect(company, index)}
                                        placeholder="Enter company"
                                        searchFunction={(term) => debounceSearchCompany(handleSearchCompany, term)}
                                        startAdornment={<Search />}
                                        suggestions={autocompleteResults}
                                        freeformUpdate={(value) => handleCompanyFreeform(value, index)}
                                        disabled={disableInputs || !isNil(get(broker, "company.companyKey"))}
                                        options={{
                                            key: "companyKey",
                                            display: (suggestion) => {
                                                return get(suggestion, "displayName", "");
                                            },
                                        }}
                                    />
                                </div>
                                <div className="flexGrowSingle">
                                    <AutocompleteFreeform
                                        id="firstName"
                                        label={index === 0 ? "First Name" : ""}
                                        error={get(
                                            errors,
                                            `listingBrokers.outside[${index}]["brokerAgentKey.person.firstName"]`,
                                            ""
                                        )}
                                        errorText="Required"
                                        initialInputValue={get(broker, `brokerAgentKey.person.firstName`, "")}
                                        itemToString={(i) => get(i, `firstName`, null)}
                                        onSelect={(broker) => handleOutsideBrokerPersonSelect(broker, index)}
                                        placeholder="Enter broker"
                                        searchFunction={handleSearchOutsideBrokers}
                                        startAdornment={<Search />}
                                        suggestions={autocompleteResults}
                                        freeformUpdate={(value) => handleOutsideBrokerPersonFreeform(value, index)}
                                        disabled={disableInputs || !isNil(get(broker, "brokerAgentKey.brokerAgentKey"))}
                                        options={{
                                            key: "brokerAgentKey",
                                            display: (suggestion) => {
                                                return (
                                                    get(suggestion, "firstName", "") +
                                                    " " +
                                                    get(suggestion, "lastName", "")
                                                );
                                            },
                                        }}
                                    />
                                </div>
                                <div className="flexGrowSingle">
                                    <BSInput
                                        id="lastName"
                                        label={index === 0 ? "Last Name" : null}
                                        error={get(
                                            errors,
                                            `listingBrokers.outside[${index}]["brokerAgentKey.person.lastName"]`,
                                            ""
                                        )}
                                        errorText="Required"
                                        value={get(broker, `brokerAgentKey.person.lastName`, "")}
                                        onChange={(e) =>
                                            handleUpdateArrayElement(
                                                "listing.listingBrokers.outsideListingBrokers",
                                                index,
                                                "brokerAgentKey.person.lastName",
                                                e.target.value
                                            )
                                        }
                                        placeholder="Enter last name"
                                        disabled={disableInputs || !isNil(get(broker, "brokerAgentKey.brokerAgentKey"))}
                                        extraInputStyles={index === 0 ? {} : { marginTop: "0px" }}
                                    />
                                </div>
                                {!disableInputs && (
                                    <div>
                                        <Button
                                            className={classes.shrinkButton}
                                            style={index === 0 ? { marginTop: "0px" } : { marginTop: "-20px" }}
                                            onClick={() =>
                                                handleDeleteArrayElement(
                                                    "listing.listingBrokers.outsideListingBrokers",
                                                    index
                                                )
                                            }
                                        >
                                            <Delete />
                                        </Button>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {!disableInputs && (
                        <Button
                            className={classes.addRow}
                            color="primary"
                            onClick={() =>
                                handleAddArrayElement("listingBrokers.outsideListingBrokers", {
                                    listingKey: listing.listingKey,
                                    brokerAgentKey: { kmBrokerFlag: false },
                                })
                            }
                        >
                            <Add fill="#4b94c1" className={classes.icon} />
                            Add Outside Listing Broker
                        </Button>
                    )}
                </div>
            </div>
            <Divider className={classes.dividerMargin} />

            {isOutside ? (
                <div className="listingFormFlexContainer">
                    <div>
                        <BSInput
                            id="dateAvailable"
                            label="Date Space is Available"
                            error={get(errors, "dateAvailable")}
                            value={listing.dateAvailable}
                            onChange={(e) => handleListingChange(e.target.id, e.target.value)}
                            placeholder="Enter date available"
                            type="date"
                            startAdornment={<Today />}
                            disabled={disableInputs}
                        />
                    </div>
                    <div>
                        <BSInput
                            id="leaseExpirationDate"
                            label="Master Lease Expiration Date (Sublease)"
                            error={get(errors, "leaseExpirationDate")}
                            value={listing.leaseExpirationDate}
                            onChange={(e) => handleListingChange(e.target.id, e.target.value)}
                            placeholder="Enter master lease expiration date"
                            type="date"
                            startAdornment={<Today />}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
            ) : (
                <div className="listingFormFlexContainer">
                    <div className="listingFormFlexContainerUnresponsive flexGrowSingle">
                        {showListingExpiration && (
                            <BSInput
                                id="dateListed"
                                label="Date to Post on Kidder.com"
                                toolTipText='This is the date your listing will be posted on kidder.com<br />
                                    as well as the date your listing will be searchable in KM Connect.<br />
                                    Checking "Opt Out: kidder.com" will hide the listing from kidder.com and KMConnect search'
                                error={get(errors, "dateListed")}
                                helperText={
                                    get(listing, "attributes.optOutFlg", false)
                                        ? `Opt out of kidder.com selected.`
                                        : null
                                }
                                value={listing.dateListed}
                                onChange={(e) => handleListingChange(e.target.id, e.target.value)}
                                placeholder="Enter date listed"
                                type="date"
                                disabled={
                                    disableInputs ||
                                    listing.nonKmListingFlg === true ||
                                    get(listing, "attributes.optOutFlg", false) === true
                                }
                            />
                        )}
                        <BSInput
                            id="dateAvailable"
                            label="Date Space is Available"
                            error={get(errors, "dateAvailable")}
                            value={listing.dateAvailable}
                            onChange={(e) => handleListingChange(e.target.id, e.target.value)}
                            placeholder="Enter date available"
                            type="date"
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="listingFormFlexContainerUnresponsive flexGrowSingle">
                        {showListingExpiration && (
                            <BSInput
                                id="expirationDate"
                                label={displayRequired ? "Listing Expiration Date*" : "Listing Expiration Date"}
                                helperText={displayRequired ? "Required if Auto Renew not selected" : ""}
                                errorText="Required if Auto Renew not selected"
                                error={get(errors, "expirationDate")}
                                value={listing.expirationDate}
                                onChange={(e) => handleListingChange(e.target.id, e.target.value)}
                                placeholder="Enter expiration date"
                                type="date"
                                disabled={disableInputs || listing.autoRenewFlg === true}
                            />
                        )}
                        <BSInput
                            id="leaseExpirationDate"
                            label="Master Lease Expiration Date (Sublease)"
                            error={get(errors, "leaseExpirationDate")}
                            value={listing.leaseExpirationDate}
                            onChange={(e) => handleListingChange(e.target.id, e.target.value)}
                            placeholder="Enter master lease expiration date"
                            type="date"
                            disabled={disableInputs}
                        />
                    </div>
                </div>
            )}

            {/* seller company fields start here */}
            <div className="listingFormFlexContainer">
                <div className="listingFormFlexContainerUnresponsive flexGrowSingle">
                    <BSInput
                        id="sellerLandlordLegalCompanyName"
                        label={getCompanyFieldLabel("legalName", "SELLER_LANDLORD")}
                        error={get(errors, "sellerLandlordLegalCompanyName", null)}
                        errorText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                        helperText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                        value={get(listing, "sellerLandlordLegalCompanyName", null)}
                        onChange={(event) => handleListingChange("sellerLandlordLegalCompanyName", event.target.value)}
                        disabled={disableInputs === true}
                        placeholder={getCompanyPlaceholderText("legalName", "SELLER_LANDLORD")}
                    />
                    <BSInput
                        id="sellerLandlordCommonCompanyName"
                        label={getCompanyFieldLabel("displayName", "SELLER_LANDLORD")}
                        error={get(errors, "sellerLandlordCommonCompanyName", null)}
                        errorText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                        helperText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                        value={get(listing, "sellerLandlordCommonCompanyName", "")}
                        onChange={(event) => handleListingChange("sellerLandlordCommonCompanyName", event.target.value)}
                        disabled={disableInputs === true}
                        placeholder={getCompanyPlaceholderText("displayName", "SELLER_LANDLORD")}
                    />
                </div>
                <div className="flexGrowSingle">
                    <BSInput
                        id="sellerLandlordInstitutionalOwner"
                        label={getCompanyFieldLabel("parentCompany", "SELLER_LANDLORD")}
                        error={get(errors, "sellerLandlordInstitutionalOwner", null)}
                        value={get(listing, "sellerLandlordInstitutionalOwner", "")}
                        onChange={(event) =>
                            handleListingChange("sellerLandlordInstitutionalOwner", event.target.value)
                        }
                        disabled={disableInputs === true}
                        placeholder={getCompanyPlaceholderText("parentCompany", "SELLER_LANDLORD")}
                    />
                </div>
            </div>

            {/* seller company fields end here */}

            <div className="listingFormFlexContainer">
                <div className="listingFormFlexContainerUnresponsive flexGrowSingle">
                    <BSInput
                        id="numBldg"
                        label="BUILDING COUNT"
                        placeholder="Enter building count"
                        errorText="(ex. 98)"
                        error={get(errors, "attributes.numBldg")}
                        value={attributes.numBldg}
                        onChange={(e) => handleListingAttributeChange(e.target.id, e.target.value)}
                        disabled={disableInputs}
                    />
                    <BSInput
                        id="buildingNumber"
                        label="BUILDING NUMBER"
                        placeholder="Enter building number"
                        errorText="(ex. 3)"
                        error={get(errors, "attributes.buildingNumber")}
                        value={attributes.buildingNumber}
                        onChange={(e) => handleListingAttributeChange(e.target.id, e.target.value)}
                        disabled={disableInputs}
                    />
                    <BSInput
                        id="floor"
                        label="FLOORS"
                        placeholder="Enter floors"
                        error={get(errors, "attributes.floor")}
                        value={attributes.floor}
                        onChange={(e) => handleListingAttributeChange(e.target.id, e.target.value)}
                        disabled={disableInputs}
                    />
                </div>
            </div>

            <div className="listingFormFlexContainer">
                <div className="listingFormFlexContainerUnresponsive flexGrowSingle">
                    <BSInput
                        id="lotAcres"
                        label="LOT SIZE (ACRES)"
                        placeholder="Enter land acreage"
                        errorText="(ex. 8.10)"
                        error={get(errors, "attributes.lotAcres")}
                        value={attributes.lotAcres}
                        type="number"
                        onChange={(e) => handleLotSizeAutoCalc(e.target.id, e.target.value)}
                        disabled={disableInputs}
                    />
                    <BSInput
                        id="lotSf"
                        label="LOT SIZE (SF)"
                        placeholder="Enter SF"
                        errorText="(ex. 98101)"
                        error={get(errors, "attributes.lotSf")}
                        value={attributes.lotSf}
                        type="number"
                        onChange={(e) => handleLotSizeAutoCalc(e.target.id, e.target.value)}
                        disabled={disableInputs}
                    />
                </div>
                <div className="flexGrowSingle">
                    <BSInput
                        id="amenities"
                        label="AMENITIES"
                        placeholder="Enter amenities"
                        error={get(errors, "attributes.amenities")}
                        value={attributes.amenities}
                        onChange={(e) => handleListingAttributeChange(e.target.id, e.target.value)}
                        disabled={disableInputs}
                    />
                </div>
            </div>
            <div className="listingFormFlexContainer">
                <div className="flexGrowSingle">
                    <BSInput
                        id="websiteUrl"
                        label="WEBSITE URL"
                        placeholder="Enter url"
                        error={get(errors, "websiteUrl")}
                        value={listing.websiteUrl}
                        onChange={(e) => handleListingChange(e.target.id, e.target.value)}
                        disabled={disableInputs}
                    />
                </div>
                <div className="flexGrowSingle">
                    <BSInput
                        id="tourInstructions"
                        label="TOUR INSTRUCTIONS"
                        placeholder="Enter instructions"
                        errorPlaceholder="hideIt"
                        error={get(errors, "attributes.tourInstructions")}
                        value={attributes.tourInstructions}
                        onChange={(e) => handleListingAttributeChange(e.target.id, e.target.value)}
                        disabled={disableInputs}
                    />
                </div>
            </div>
            <div className="listingFormFlexContainer">
                <div
                    className="listingFormFlexContainerUnresponsive flexGrowSingle"
                    style={{ marginTop: "15px", justifyContent: "flex start" }}
                >
                    {listing.listingType === "Lease" && (
                        <div>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={listing.isDivisible}
                                            onChange={(e, checked) => updateDivisible(e.target.value, checked)}
                                            value="isDivisible"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Space is Divisible"
                                />
                            </FormGroup>
                        </div>
                    )}
                    <div>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listing.callListingBrokerFlg ? true : false}
                                        onChange={(e, checked) => handleListingChange(e.target.value, checked)}
                                        value="callListingBrokerFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Call Listing Broker"
                            />
                        </FormGroup>
                    </div>

                    <div>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listing.viewWithDiscretionFlg ? true : false}
                                        onChange={(e, checked) => handleListingChange(e.target.value, checked)}
                                        value="viewWithDiscretionFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="View with Discretion"
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="listingFormFlexContainerUnresponsive flexGrowSingle">
                    <div className="flexGrowSingle">
                        <BSInput
                            id="sfAvail"
                            type="number"
                            label={
                                displayRequired
                                    ? listing.listingType === "Lease"
                                        ? "SF Available*"
                                        : "Sale SF*"
                                    : listing.listingType === "Lease"
                                    ? "SF Available"
                                    : "Sale SF"
                            }
                            helperText={
                                includes(["Outside Draft", "Outside"], compStatus)
                                    ? ""
                                    : displayRequired
                                    ? "Required (ex. 98010)"
                                    : "(ex. 98010)"
                            }
                            errorText={includes(["Outside Draft", "Outside"], compStatus) ? "" : ""}
                            placeholder="Enter SF"
                            error={!includes(["Outside Draft", "Outside"], compStatus) ? get(errors, "sfAvail") : ""}
                            value={listing.sfAvail}
                            onChange={(e) => handleListingChange(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </div>
                    {listing.listingType === "Lease" && listing.isDivisible && (
                        <div className="flexGrowSingle">
                            <BSInput
                                id="sfDivisibleTo"
                                type="number"
                                label="SF Divisible"
                                helperText="Required (ex. 98010)"
                                errorText={includes(["Outside Draft", "Outside"], compStatus) ? "" : ""}
                                placeholder="Enter SF"
                                error={
                                    !includes(["Outside Draft", "Outside"], compStatus)
                                        ? get(errors, "sfDivisibleTo")
                                        : ""
                                }
                                value={listing.sfDivisibleTo}
                                onChange={(e) => handleListingChange(e.target.id, e.target.value)}
                                disabled={disableInputs}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const styles = () => ({
    addRow: {
        padding: 0,
        margin: 0,
        color: "#2C99D2",
        "&:hover": {
            backgroundColor: "transparent",
        },
        marginBottom: "20px",
    },
    shrinkButton: {
        padding: 0,
        margin: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    dividerMargin: {
        marginTop: "10px",
        marginBottom: "30px",
    },
});

export default withStyles(styles)(ListingForm);
