import configs from "../../../config";

const HOST = configs.HOST_URL + "/";
const DASHBOARDS_URL = HOST + "dashboards/";
const urls = {
    //evp dashboard endpoints
    top_km_deals: (statusGroup, region, startDate, endDate) => {
        return (
            DASHBOARDS_URL +
            "evp/top-closed-deals?status=" +
            statusGroup +
            "&region=" +
            region +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate
        );
    },
    top_km_brokers: (statusGroup, region, startDate, endDate) => {
        return (
            DASHBOARDS_URL +
            "evp/top-brokers?status=" +
            statusGroup +
            "&region=" +
            region +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate
        );
    },
    commission_breakdown_by_office: (statusGroup, region, startDate, endDate) => {
        return (
            DASHBOARDS_URL +
            "evp/commission-office?status=" +
            statusGroup +
            "&region=" +
            region +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate
        );
    },
    commission_breakdown_by_type: (statusGroup, region, startDate, endDate) => {
        return (
            DASHBOARDS_URL +
            "evp/commission-type?status=" +
            statusGroup +
            "&region=" +
            region +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate
        );
    },
    ytd_total_consideration: (statusGroup, region, startDate, endDate) => {
        return (
            DASHBOARDS_URL +
            "evp/ytd-consideration?status=" +
            statusGroup +
            "&region=" +
            region +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate
        );
    },
    ytd_total_commission: (statusGroup, region, startDate, endDate) => {
        return (
            DASHBOARDS_URL +
            "evp/ytd-commission?status=" +
            statusGroup +
            "&region=" +
            region +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate
        );
    },
    avg_time_on_market: (region) => {
        return DASHBOARDS_URL + "evp/time-on-market?region=" + region;
    },
    noncompliant_deals: (statusGroup, region) => {
        return DASHBOARDS_URL + "evp/non-compliant?status=" + statusGroup + "&region=" + region;
    },

    //broker dashboard endpoints
    recent_open_listings: (statusGroup) => {
        return DASHBOARDS_URL + "broker/recent-listings?status=" + statusGroup;
    },
    recent_open_deals: (statusGroup) => {
        return DASHBOARDS_URL + "broker/recent-deals?status=" + statusGroup;
    },
    broker_ytd_commission: (statusGroup, startDate, endDate) => {
        return (
            DASHBOARDS_URL +
            "broker/ytd-commission?status=" +
            statusGroup +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate
        );
    },
    broker_ytd_listings: (statusGroup, startDate, endDate) => {
        return (
            DASHBOARDS_URL +
            "broker/ytd-listings?status=" +
            statusGroup +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate
        );
    },
    broker_avg_time_on_market: () => {
        return DASHBOARDS_URL + "broker/time-on-market";
    },
    broker_ytd_closed_deals: (startDate, endDate) => {
        return DASHBOARDS_URL + "broker/ytd-closed-deals?startDate=" + startDate + "&endDate=" + endDate;
    },
    broker_license_exp: () => {
        return DASHBOARDS_URL + "broker/license-exp";
    },
};

export default {
    urls,
};
