import configs from "../../../config.js";

const workflow = {
    statuses: {
        draft: {
            broker: {
                list_action: "edit",
            },
        },
        broker_action_required: {
            broker: {
                list_action: "edit",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "view",
            },
        },
        needs_approval: {
            broker: {
                list_action: "view",
            },
            data_entry: {
                list_action: "start",
            },
        },
        in_review: {
            broker: {
                list_action: "view",
            },
            data_entry: {
                list_action: "edit",
            },
        },
        final_review: {
            broker: {
                list_action: "view",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "review",
            },
        },
        admin_action_required: {
            broker: {
                list_action: "view",
            },
            data_entry: {
                list_action: "review",
            },
            data_admin: {
                list_action: "view",
            },
        },
        ready_for_accounting: {
            broker: {
                list_action: "view",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "view",
            },
        },
        closed: {
            broker: {
                list_action: "view",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "view",
            },
        },
    },
};

const role = {
    accounting: {
        init_tab: "accounting_awaiting_payment",
    },
};
const HOST = configs.HOST_URL + "/";
const VOUCHER_URL = HOST + "vouchers/";

const urls = {
    accounting_closed: () => {
        return VOUCHER_URL + "accounting/batched";
    },
    accounting_closed_date_range: (startDate, endDate) => {
        return VOUCHER_URL + "accounting/batched?startDate=" + startDate + "&endDate=" + endDate;
    },
    accounting_ready_payment: () => {
        return VOUCHER_URL + "accounting/payment";
    },
    accounting_awaiting_payment: () => {
        return VOUCHER_URL + "accounting/awaiting-payment";
    },
    send_to_batch: () => {
        return VOUCHER_URL + "accounting/batch";
    },
    reopen_vouchers: () => {
        return VOUCHER_URL + "accounting/reopen";
    },
    toggleProcess: (voucherId) => {
        return VOUCHER_URL + "process/" + voucherId;
    },
    setPrintedBy: (voucherId) => {
        return VOUCHER_URL + "fields/" + voucherId;
    },
    generatePdf: (voucherId) => {
        return VOUCHER_URL + "report/" + voucherId;
    },
};

export default {
    workflow,
    role,
    urls,
};
