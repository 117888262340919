import { combineReducers } from "redux";
import authReducer from "./app/js/auth/ducks/reducers";
import voucherReducer from "./app/js/voucher/ducks/reducers";
import vouchersReducer from "./app/js/vouchers/ducks/reducers";
import commentsReducer from "./app/js/comments/ducks/reducers";
import documentsReducer from "./app/js/documents/ducks/reducers";
import utilsReducer from "./app/js/utils/reducers";
import { routerReducer } from "react-router-redux";
import dealReducer from "./app/js/deal/ducks/reducers";
import dealsReducer from "./app/js/deals/ducks/reducers";
import contactsReducer from "./app/js/contact/ducks/reducers";
import listingReducer from "./app/js/listing/ducks/reducers";
import dashboardReducer from "./app/js/dashboards/ducks/reducers";

import compReducer from "./app/js/comp/ducks/reducers";
import auditReducer from "./app/js/audit/ducks/reducers";
import propertyReducer from "./app/js/property/ducks/reducers";
import searchReducer from "./app/js/search/ducks/reducers";
import adminReducer from "./app/js/admin/ducks/reducers";
import crmReducer from "./app/js/crm/ducks/reducers";
import debtEquityReducer from "./app/js/debt-equity/ducks/reducers";
import stateDocumentRequirementsReducer from "./app/js/document-type/ducks/reducers";
import complianceReducer from "./app/js/compliance/ducks/reducers";

export default combineReducers({
    authReducer,
    voucherReducer,
    vouchersReducer,
    dealReducer,
    dealsReducer,
    utilsReducer,
    commentsReducer,
    documentsReducer,
    contactsReducer,
    listingReducer,
    compReducer,
    auditReducer,
    propertyReducer,
    searchReducer,
    dashboardReducer,
    adminReducer,
    crmReducer,
    debtEquityReducer,
    stateDocumentRequirementsReducer,
    complianceReducer,
    router: routerReducer,
});
