import types from "./types";

export default (
    state = {
        commentsDrawerOpen: false,
        comments: [],
    },
    action
) => {
    switch (action.type) {
        case types.GET_COMMENTS:
            return Object.assign({}, state, {
                comments: action.comments,
            });
        case types.TOGGLE_COMMENTS_DRAWER:
            return Object.assign({}, state, {
                commentsDrawerOpen: action.open,
            });
        case types.MAIN_COMMENT_CHANGE:
            return Object.assign({}, state, {
                commentDraft: action.commentDraft,
            });
        case types.CLEAR_COMMENT:
            return Object.assign({}, state, {
                commentDraft: action.commentDraft,
            });
        case types.SET_STATE_FOR_REQUEST_UPDATE:
            return {
                ...state,
                requestUpdateState: action.requestUpdateState,
                redirectTo: action.redirectTo,
            };
        default:
            return state;
    }
};
