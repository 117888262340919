import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import ContactForm from "./ContactFormBeta";

const ContactContainer = (props) => {
    const {
        addButtonText,
        autocompleteResults,
        classes,
        companyFieldAvailable,
        contacts,
        disableInputs,
        displayWhenEmpty,
        handleAdd,
        handleChange,
        handleDelete,
        handleSearchCompany,
        isSnapshot,
        freeFormCompany,
        maxContacts,
    } = props;

    useEffect(() => {
        const numContacts = contacts ? contacts.length : 0;
        if (numContacts === 0 && disableInputs !== true && displayWhenEmpty === true) {
            handleAdd();
        }
    }, []);

    const handleInput = (field, value, i) => {
        if (handleChange) handleChange(field, value, i);
    };

    return (
        <div>
            {isSnapshot && (
                <div>
                    <h3>Contact Information</h3>
                </div>
            )}
            {contacts &&
                Object.keys(contacts).map((key, i) => {
                    const contact = contacts[key];
                    return (
                        <div>
                            <ContactForm
                                {...props}
                                handleSearchCompany={handleSearchCompany}
                                autocompleteResults={autocompleteResults}
                                companyFieldAvailable={companyFieldAvailable}
                                contactIndex={i}
                                contact={contact}
                                allowDelete={Boolean(true)}
                                handleDelete={handleDelete}
                                handleInput={(field, value) => handleInput(field, value, i)}
                                title="Contact Information"
                                key={`contact-${i}`}
                                disableInputs={disableInputs}
                                freeFormCompany={freeFormCompany}
                                hideTitle={isSnapshot}
                            />
                        </div>
                    );
                })}
            {(!contacts || Object.keys(contacts).length < maxContacts) && disableInputs !== true && (
                <Button className={classes.root} onClick={handleAdd}>
                    <Add className={classes.icon} />
                    {addButtonText}
                </Button>
            )}
        </div>
    );
};

const styles = () => ({
    root: {
        border: "2px dashed #2C99D2",
        color: "#2C99D2",
        margin: "10px 0 20px",
        padding: "11px 16px",
        width: "100%",
        justifyContent: "center",
        "&hover": {
            backgroundColor: "rgba(44, 153, 210, 0.08)",
        },
    },
});

export default withStyles(styles)(ContactContainer);
