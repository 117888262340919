const GET_DEAL = "GET_DEAL";
const GET_DEAL_CREATOR = "GET DEAL CREATOR";
const DEAL_CHANGE = "DEAL_CHANGE";
const ADD_DEAL_ERROR = "ADD_DEAL_ERROR";
const CLEAR_DEAL_ERRORS = "CLEAR_DEAL_ERRORS";
const ADD_INSTALLMENT = "ADD_INSTALLMENT";
const DELETE_INSTALLMENT = "DELETE_INSTALLMENT";
const UPDATE_INSTALLMENT = "UPDATE_INSTALLMENT";
const UPDATE_CONTACT_INFO = "UPDATE_CONTACT_INFO";
const ADD_CONTACT_INFO = "ADD_CONTACT_INFO";
const UPDATE_TOTAL_CONSIDERATION_CALCULATION = "UPDATE_TOTAL_CONSIDERATION_CALCULATION";
const TOTAL_CONSIDERATION_CHANGE = "TOTAL_CONSIDERATION_CHANGE";
const PERIOD_MONTH_CHANGE = "PERIOD_MONTH_CHANGE";
const UPDATE_PERIOD_SUMMARY = "UPDATE_PERIOD_SUMMARY";
const UPDATE_TOTAL_CONSIDERATION_SUMMARY = "UPDATE_TOTAL_CONSIDERATION_SUMMARY";
const TOTAL_CONSIDERATION_INPUT_UPDATE = "TOTAL_CONSIDERATION_INPUT_UPDATE";
const UPDATE_PROPERTY_INFO = "UPDATE_PROPERTY_INFO";
const SET_FLAG = "SET_FLAG";
const SEARCH_RESULTS = "SEARCH_RESULTS";
const DEAL_SNACKBAR = "DEAL_SNACKBAR";
const UPDATE_COMPANY_PROPERTY = "UPDATE_COMPANY_PROPERTY";
const DELETE_CONTACT_INFO = "DELETE_CONTACT_INFO";
const TOGGLE_BOOLEAN_PROPERTY = "TOGGLE_BOOLEAN_PROPERTY";
const UPDATE_CONTACT_INFO_COMPANY = "UPDATE_CONTACT_INFO_COMPANY";
const GET_DEAL_SUMMARY = "GET_DEAL_SUMMARY";
const GET_DEAL_SUMMARY_VOUCHERS = "GET_DEAL_SUMMARY_VOUCHERS";
const STATE_OBJECT_PROPERTY_CHANGE = "STATE_OBJECT_PROPERTY_CHANGE";
const BROKER_COMMISSION_MY_AMOUNT = "BROKER_COMMISSION_MY_AMOUNT";
const SORT_DEAL_SUMMARY_VOUCHERS = "SORT_DEAL_SUMMARY_VOUCHERS";
const GET_DEAL_SUMMARY_LISTING_AGENT = "GET_DEAL_SUMMARY_LISTING_AGENT";
const ADD_VOUCHER_INSTALLMENT = "ADD_VOUCHER_INSTALLMENT";
const UPDATE_COMP_VALUE = "UPDATE_COMP_VALUE";
const SET_COMP_ARRAY = "SET_COMP_ARRAY";
const DELETE_COMP_ARRAY_ELEMENT = "DELETE_COMP_ARRAY_ELEMENT";
const GET_DEAL_REFACTOR = "GET_DEAL_REFACTOR";
const CLEAR_TOTAL_CONSIDERATION = "CLEAR_TOTAL_CONSIDERATION";
const TOGGLE_TERMINATE_DIALOG = "TOGGLE_TERMINATE_DIALOG";
const TOGGLE_CONFIRM_TERMINATE_DIALOG = "TOGGLE_CONFIRM_TERMINATE_DIALOG";
const TERMINATION_DOCUMENT_UPLOADED = "TERMINATION_DOCUMENT_UPLOADED";
const LOAD_DEAL_DOCUMENT_LIST = "LOAD_DEAL_DOCUMENT_LIST";
const SET_DEAL_SUMMARY_BROKER_COMMISSIONS = "SET_DEAL_SUMMARY_BROKER_COMMISSIONS";
const CLEAR_TOTAL_CONSIDERATION_CALCULATION = "CLEAR_TOTAL_CONSIDERATION_CALCULATION";
const PERIOD_CHANGE = "PERIOD_CHANGE";

export default {
    GET_DEAL,
    GET_DEAL_CREATOR,
    DEAL_CHANGE,
    ADD_DEAL_ERROR,
    CLEAR_DEAL_ERRORS,
    ADD_INSTALLMENT,
    DELETE_INSTALLMENT,
    UPDATE_INSTALLMENT,
    UPDATE_CONTACT_INFO,
    ADD_CONTACT_INFO,
    UPDATE_TOTAL_CONSIDERATION_CALCULATION,
    TOTAL_CONSIDERATION_CHANGE,
    PERIOD_MONTH_CHANGE,
    UPDATE_PERIOD_SUMMARY,
    UPDATE_TOTAL_CONSIDERATION_SUMMARY,
    TOTAL_CONSIDERATION_INPUT_UPDATE,
    UPDATE_PROPERTY_INFO,
    SET_FLAG,
    SEARCH_RESULTS,
    DEAL_SNACKBAR,
    UPDATE_COMPANY_PROPERTY,
    DELETE_CONTACT_INFO,
    TOGGLE_BOOLEAN_PROPERTY,
    UPDATE_CONTACT_INFO_COMPANY,
    GET_DEAL_SUMMARY,
    GET_DEAL_SUMMARY_VOUCHERS,
    STATE_OBJECT_PROPERTY_CHANGE,
    BROKER_COMMISSION_MY_AMOUNT,
    SORT_DEAL_SUMMARY_VOUCHERS,
    GET_DEAL_SUMMARY_LISTING_AGENT,
    ADD_VOUCHER_INSTALLMENT,
    UPDATE_COMP_VALUE,
    SET_COMP_ARRAY,
    DELETE_COMP_ARRAY_ELEMENT,
    GET_DEAL_REFACTOR,
    CLEAR_TOTAL_CONSIDERATION,
    TOGGLE_TERMINATE_DIALOG,
    TOGGLE_CONFIRM_TERMINATE_DIALOG,
    TERMINATION_DOCUMENT_UPLOADED,
    LOAD_DEAL_DOCUMENT_LIST,
    SET_DEAL_SUMMARY_BROKER_COMMISSIONS,
    CLEAR_TOTAL_CONSIDERATION_CALCULATION,
    PERIOD_CHANGE,
};
