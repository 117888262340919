import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import BSInput from "../../../common/js/components/BSInput";
import KMCounter from "../../../common/js/components/KMCounter";
import { formatLocalDate } from "../utils/operations";
import get from "lodash/get";
import Today from "../../../../node_modules/@material-ui/icons/Today";

const styles = (theme) => ({
    switchTitle: {
        fontSize: 14,
        fontWeight: 600,
    },
    theSwitch: {
        marginRight: 0,
    },
    radioRow: {
        paddingTop: "28px",
    },
});
class ManagementFees extends React.Component {
    render() {
        const { data, errors, disableInputs, handleInput, sideBySide } = this.props;

        return (
            <Grid container justify="space-between" spacing={24}>
                <Grid item xs={sideBySide ? 6 : 12}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <KMCounter
                                id="flatRateFee"
                                label="Fixed Fee"
                                value={get(data, "flatRateFee", null)}
                                error={get(errors, "flatRateFee", null)}
                                errorText={"Field must be a positive number"}
                                onChange={handleInput}
                                min={0}
                                step={0.01}
                                disabled={disableInputs}
                                startAdornment="$"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <KMCounter
                                id="percentOfRevenue"
                                label="% of Revenue"
                                value={get(data, "percentOfRevenue", "")}
                                error={get(errors, "percentOfRevenue", null)}
                                errorText={"Field must be a positive number"}
                                onChange={handleInput}
                                min={0}
                                max={100}
                                step={0.01}
                                disabled={disableInputs}
                                startAdornment="%"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KMCounter
                                id="minimumAmount"
                                label="Minimum"
                                value={get(data, "minimumAmount", "")}
                                error={get(errors, "minimumAmount", null)}
                                errorText={"Field must be a positive number"}
                                onChange={handleInput}
                                min={0}
                                step={0.01}
                                disabled={disableInputs}
                                startAdornment="$"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <KMCounter
                                id="effectiveMonthIncrease"
                                label="Effective Month Increase"
                                placeholder="Enter effective month increase"
                                value={get(data, "effectiveMonthIncrease", "")}
                                error={get(errors, "effectiveMonthIncrease", null)}
                                errorText={"Field must be a positive number between 1 and 12"}
                                onChange={handleInput}
                                min={1}
                                max={12}
                                disabled={disableInputs}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {
                    //right column
                }
                <Grid item xs={sideBySide ? 6 : 12}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <BSInput
                                id="cpi"
                                label="CPI"
                                placeholder="Enter CPI"
                                value={get(data, "cpi", "")}
                                error={get(errors, "cpi", null)}
                                onChange={(e) => handleInput(e.target.id, e.target.value)}
                                disabled={disableInputs}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <KMCounter
                                id="minMgtFee"
                                label="MINIMUM (%)"
                                placeholder="Enter minimum"
                                value={get(data, "minMgtFee", "")}
                                error={get(errors, "minMgtFee", null)}
                                errorText={"Field must be a number between 0 and 100"}
                                onChange={handleInput}
                                min={0}
                                max={100}
                                disabled={disableInputs}
                                startAdornment="%"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <KMCounter
                                id="maxMgtFee"
                                label="MAXIMUM (%)"
                                placeholder="Enter maximum"
                                value={get(data, "maxMgtFee", "")}
                                error={get(errors, "maxMgtFee", null)}
                                errorText={"Field must be a number between 0 and 100"}
                                onChange={handleInput}
                                min={0}
                                max={100}
                                disabled={disableInputs}
                                startAdornment="%"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BSInput
                                id="dateOfIncrease"
                                label="Date Of Increase"
                                placeholder="Enter date of increase"
                                value={get(data, "dateOfIncrease", "")}
                                error={get(errors, "dateOfIncrease", null)}
                                onChange={(e) => handleInput(e.target.id, formatLocalDate(e.target.value))}
                                disabled={disableInputs}
                                startAdornment={<Today />}
                                type="date"
                            />
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs>
                                <BSInput
                                    id="salaryReimbursementsComment"
                                    label="Salary Reimbursement"
                                    placeholder="Enter salary reimbursement data/comments"
                                    value={get(data, "salaryReimbursementsComment", "")}
                                    error={get(errors, "salaryReimbursementsComment", null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={!get(data, "salaryReimbursementsFlg", false) || disableInputs}
                                    checkbox={true}
                                    checkboxVal={"salaryReimbursementsFlg"}
                                    disableCheckbox={disableInputs}
                                    onChangeCheckbox={() =>
                                        handleInput(
                                            "salaryReimbursementsFlg",
                                            !get(data, "salaryReimbursementsFlg", false)
                                        )
                                    }
                                    checked={get(data, "salaryReimbursementsFlg", false)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ManagementFees);
