export const propertyManagementTypes = [
    {
        value: "Accounting Only",
        label: "Accounting Only",
    },
    {
        value: "Asset Management and Accounting Only",
        label: "Asset Management and Accounting Only",
    },
    {
        value: "Accounting and Engineering Only",
        label: "Accounting and Engineering Only",
    },
    {
        value: "Accounting, Engineering, & Facilities Management",
        label: "Accounting, Engineering, & Facilities Management",
    },
    {
        value: "Accounting & Facilities Management",
        label: "Accounting & Facilities Management",
    },
    {
        value: "Consulting",
        label: "Consulting",
    },
    {
        value: "Engineering Only",
        label: "Engineering Only",
    },
    {
        value: "Full Property Management (PM, Accounting, & Eng)",
        label: "Full Property Management (PM, Accounting, & Eng)",
    },
    {
        value: "Property Management Only",
        label: "Property Management Only",
    },
    {
        value: "Property Management, Accounting, Engineering, & Facilities Management",
        label: "Property Management, Accounting, Engineering, & Facilities Management",
    },
    {
        value: "Property Management, Accounting  & Facilities Management",
        label: "Property Management, Accounting  & Facilities Management",
    },
    {
        value: "Property Management & Engineering",
        label: "Property Management & Engineering",
    },
    {
        value: "Property Management & Facilities Management",
        label: "Property Management & Facilities Management",
    },
    {
        value: "Property Management & Consulting",
        label: "Property Management & Consulting",
    },
    {
        value: "Property Management & Accounting",
        label: "Property Management & Accounting",
    },
];
