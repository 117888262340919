import each from "lodash/each";
import get from "lodash/get";
import utils from "../utils/operations";
import { aggregateBrokers, countCommaSeparated, countCommaSeparatedBrokerObj } from "../utils/operations";

const viewHeaders = {
    data_team_unassigned: [
        {
            name: "Address",
            prop: "property",
            type: "plaintext",
            transform: (property) => {
                return (
                    get(property, "primaryAddress.address1", "") + " " + get(property, "primaryAddress.address2", "")
                );
            },
        },
        {
            name: "Brokers",
            prop: "listingBrokers",
            type: "aggregate",
            transform: (listingBrokers) => {
                return aggregateBrokers(listingBrokers, "brokerAgentKey.person");
            },
        },
        {
            name: "Modified",
            prop: "auditLastModifiedTimestamp",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Type",
            prop: "listingType",
            type: "plaintext",
        },
        {
            name: "Rate",
            prop: "dataEntryOwner",
            type: "plaintext",
        },
        {
            name: "SQFT",
            prop: "sfAvail",
            type: "plaintext",
        },
        {
            name: "Status",
            prop: "status",
            type: "plaintext",
        },
    ],
    data_team_open: [
        {
            name: "Address",
            prop: "property",
            type: "plaintext",
            transform: (property) => {
                return (
                    get(property, "primaryAddress.address1", "") + " " + get(property, "primaryAddress.address2", "")
                );
            },
        },
        {
            name: "Modified",
            prop: "auditLastModifiedTimestamp",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Type",
            prop: "listingType",
            type: "plaintext",
            // transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Rate",
            prop: "dataEntryOwner",
            type: "plaintext",
            numeric: true,
            // transform: (dataEntryOwner) => { return get(dataEntryOwner, 'person.firstName', '') + ' ' + get(dataEntryOwner, 'person.lastName', '') }
        },
        {
            name: "SQFT",
            prop: "sfAvail",
            type: "plaintext",
            //transform: (value) => { return value ? utils.toSqft(value) : '-' },
        },
        {
            name: "Status",
            prop: "status",
            type: "plaintext",
        },
    ],
    all_km_listings_with_map: [
        {
            name: "Property",
            prop: "photo",
            type: "plaintext",
            extraStyles: {
                width: "20%",
            },
        },
        {
            name: "Address",
            prop: "property",
            type: "plaintext",
            transform: (property) => {
                return (
                    get(property, "primaryAddress.address1", "") + " " + get(property, "primaryAddress.address2", "")
                );
            },
        },
        {
            name: "SQFT",
            prop: "sfAvail",
            type: "plaintext",
            transform: (value) => {
                return value ? utils.toSqft(value) : "-";
            },
        },
        {
            name: "Rate/Price",
            prop: "askingRentMax",
            type: "plaintext",
            numeric: true,
        },
    ],
    broker_open: [
        {
            name: "Property",
            prop: "primaryPhotoUrl",
            type: "plaintext",
            extraStyles: {
                width: "20%",
            },
        },
        {
            name: "Address",
            prop: "property",
            type: "plaintext",
            transform: (property) => {
                return (
                    get(property, "primaryAddress.address1", "") + " " + get(property, "primaryAddress.address2", "")
                );
            },
        },
        {
            name: "Brokers",
            prop: "listingBrokers",
            type: "aggregate",
            transform: (brokers) => {
                let test;
                if (typeof brokers == "string") {
                    test =
                        countCommaSeparatedBrokerObj(brokers).length > 1
                            ? countCommaSeparatedBrokerObj(brokers).length + " Brokers"
                            : brokers;
                } else {
                    test =
                        countCommaSeparatedBrokerObj(brokers).length > 1
                            ? countCommaSeparatedBrokerObj(brokers).length + " Brokers"
                            : "";
                }
                return test;
            },
        },
        {
            name: "Type",
            prop: "listingType",
            type: "plaintext",
        },
        {
            name: "Rate/Price",
            prop: "askingRentMax",
            type: "plaintext",
            numeric: true,
        },
        {
            name: "SQFT",
            prop: "sfAvail",
            type: "plaintext",
            transform: (value) => {
                return value ? utils.toSqft(value) : "-";
            },
        },
        {
            name: "Date Avail",
            prop: "dateAvailable",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Links",
            prop: "",
            type: "plaintext",
        },
    ],
    all_km_listings: [
        {
            name: "Property",
            prop: "photo",
            type: "plaintext",
            extraStyles: {
                width: "20%",
            },
        },
        {
            name: "Address",
            prop: "property",
            type: "plaintext",
            transform: (property) => {
                return (
                    get(property, "primaryAddress.address1", "") + " " + get(property, "primaryAddress.address2", "")
                );
            },
        },
        {
            name: "Brokers",
            prop: "listingBrokers",
            type: "aggregate",
            transform: (brokers) => {
                return countCommaSeparated(brokers).length > 1
                    ? countCommaSeparated(brokers).length + " Brokers"
                    : brokers;
            },
        },
        {
            name: "Company",
            prop: "listingCompany",
            type: "plaintext",
        },
        {
            name: "Type",
            prop: "listingType",
            type: "plaintext",
        },
        {
            name: "Rate/Price",
            prop: "askingRentMax",
            type: "plaintext",
            numeric: true,
        },
        {
            name: "SQFT",
            prop: "sfAvail",
            type: "plaintext",
            transform: (value) => {
                return value ? utils.toSqft(value) : "-";
            },
        },
        {
            name: "Date Avail",
            prop: "dateAvailable",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Links",
            prop: "",
            type: "plaintext",
        },
    ],
    broker_requested: [
        {
            name: "Address",
            prop: "property",
            type: "plaintext",
            transform: (property) => {
                return (
                    get(property, "primaryAddress.address1", "") + " " + get(property, "primaryAddress.address2", "")
                );
            },
        },
        {
            name: "Date Avail",
            prop: "dateAvailable",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Type",
            prop: "listingType",
            type: "plaintext",
        },
        {
            name: "Rate",
            prop: "dataEntryOwner",
            type: "plaintext",
            numeric: true,
        },
        {
            name: "SQFT",
            prop: "sfAvail",
            type: "plaintext",
        },
        {
            name: "Status",
            prop: "status",
            type: "plaintext",
        },
    ],
};

const brokerButtonActions = {
    Draft: {
        text: "Edit",
        variant: "contained",
        statusToDisplay: "Draft",
    },
    "Needs Review": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Awaiting Admin Action",
    },
    "In Review": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "In Review",
    },
    Closed: {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Closed",
    },
    "Broker Action Required": {
        text: "Edit",
        variant: "contained",
        statusToDisplay: "Action Required",
    },
    "Awaiting Comp Submission": {
        text: "Edit",
        variant: "contained",
        statusToDisplay: "Awaiting Comp",
    },
    "In Progress": {},
};
export const buttonActions = {
    defaultAction: {
        text: "View",
        variant: "contained",
        statusToDisplay: "",
    },
    broker: {
        ...brokerButtonActions,
    },
    debtEquity: {
        ...brokerButtonActions,
    },
    evp: {
        ...brokerButtonActions,
    },
    portlandBrokerAdmin: {
        ...brokerButtonActions,
    },
    arizonaBrokerAdmin: {
        ...brokerButtonActions,
    },
    dataEntry: {
        Draft: {},
        "Needs Review": {
            text: "Start",
            variant: "contained",
            statusToDisplay: "Needs Review",
            urlParam: "start",
        },
        "In Review": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "In Review",
        },
        Closed: {
            text: "View",
            variant: "outlined",
            statusToDisplay: "Closed",
        },
        "Broker Action Required": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "Awaiting Broker Action",
        },
        "Admin Action Required": {
            text: "Review",
            variant: "contained",
            statusToDisplay: "Admin Action Required",
        },
        "In Progress": {},
    },
    dataAdmin: {
        Draft: {},
        "Needs Review": {
            text: "Start",
            variant: "contained",
            statusToDisplay: "Needs Review",
            urlParam: "start",
        },
        "In Review": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "In Review",
        },
        Closed: {
            text: "View",
            variant: "outlined",
            statusToDisplay: "Closed",
        },
        "Broker Action Required": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "Awaiting Broker Action",
        },
        "Admin Action Required": {
            text: "Review",
            variant: "contained",
            statusToDisplay: "Action Required",
        },
        "In Progress": {},
    },
};

const columnsToQueryFilter = [
    "property.primaryAddress.address1",
    "property.secondaryAddress.address1",
    "property.propertyAttributes.propertyName",
    "property.primaryAddress.city.city",
    "property.primaryAddress.city.state.stateCode",
    "property.primaryAddress.city.state.stateName",
    "property.primaryAddress.zipCode.zipCode",
    "listingType",
    "listingKey",
    "sfAvail",
    "status",
    "brokerNames",
];

const filterByQuery = (data, columnsToQuery, query) => {
    let included = false;
    each(columnsToQuery, (column) => {
        if (get(data, column)) {
            if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                included = true;
            }
        }
    });
    return included;
};

export default {
    viewHeaders,
    columnsToQueryFilter,
    filterByQuery,
};
