import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Modal from "@material-ui/core/Modal";
import Autocomplete from "../../../common/js/components/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import toUpper from "lodash/toUpper";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { Close, Search } from "../../../common/js/icons";
import { us_states_options } from "../utils/constants";
import { buildAddress } from "../utils/operations";
import ReactGA from "react-ga4";

const RequestModal = (props) => {
    const {
        classes,
        requestModal,
        updateListingRequest,
        selectProperty,
        removeSelectedProperty,
        showModal,
        handleShowModal,
        autocompleteResults,
        updateSearchTerm,
        handleSearchSubmit,
        modalType,
    } = props;

    const errors = requestModal.errors;

    const handleUpdate = (event) => {
        updateListingRequest(event.target.id, event.target.value);
    };

    const sendEventAndRequestListing = (modalType, transactionType, useType, tab, role) => {
        ReactGA.event("Request Sent", {
            requestType: modalType,
            propertyType: useType,
            transactionType: transactionType,
            newOrExisting: tab,
            userRole: role,
        });
        props.requestListing(modalType, transactionType, useType, tab, role);
    };

    return (
        <Modal
            aria-labelledby="listing-request-modal"
            aria-describedby="request-listing"
            open={showModal}
            onClose={console.log()}
        >
            <div style={getModalStyle()} className={classes.paper}>
                <h4 className="modal-title">Select a Property</h4>
                <Typography variant="subtitle1" color="textSecondary" className={classes.propertyType}>
                    {requestModal.useType} {requestModal.transactionType}
                </Typography>
                <Tabs value={requestModal.tab} onChange={(e, v) => updateListingRequest("tab", v)}>
                    <Tab value="existing" label="From Existing" />
                    <Tab value="new" label="Request New Property" />
                </Tabs>
                <Grid container spacing={0}>
                    <Grid item xs>
                        <Divider />
                    </Grid>
                </Grid>
                {requestModal.tab === "existing" ? (
                    <React.Fragment>
                        <div className={classes.instruction}>
                            <Typography variant="body1">
                                Search the KM database to find the property associated with your listing.
                            </Typography>
                        </div>
                        <Autocomplete
                            id="property"
                            label={"Property Name"}
                            error={get(errors, `property_id`, null)}
                            itemToString={(i) => (i == null ? "" : get(i, "propertyAttributes.propertyName", ""))}
                            onSelect={(p, b) => selectProperty(p)}
                            placeholder="Search by property name, address, etc."
                            searchFunction={(input) => {
                                if (input !== null && input !== undefined && input.length > 0) {
                                    updateSearchTerm(input, toUpper("property_modal"));
                                    handleSearchSubmit("property_modal");
                                }
                            }}
                            startAdornment={<Search />}
                            suggestions={autocompleteResults}
                            options={{
                                key: "property_id",
                                display: (suggestion) => {
                                    return get(suggestion, "property_name", "");
                                },
                                displaySub: (suggestion) => {
                                    return get(suggestion, "property_address", "");
                                },
                            }}
                        />
                        &nbsp;
                        {requestModal.selectedProperty.propertyKey ? (
                            <Card className={classes.cardSelected}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Grid container alignItems="center" justify="space-between" spacing={0}>
                                            <Grid item xs>
                                                <Typography component="h4" variant="h4">
                                                    {get(
                                                        requestModal,
                                                        "selectedProperty.propertyAttributes.propertyName"
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={classes.propertyAddy}
                                                >
                                                    {buildAddress(
                                                        get(requestModal, "selectedProperty.primaryAddress", {})
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <div className={classes.close} onClick={removeSelectedProperty}>
                                                    <Close />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </div>
                                {/* picture to go here  */}
                            </Card>
                        ) : (
                            <Card className={classes.card}></Card>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Grid>
                            &nbsp;
                            <BSInput
                                id="newPropertyName"
                                label="Property Name"
                                value={requestModal.newPropertyName}
                                error={get(errors, `newPropertyName`, null)}
                                onChange={handleUpdate}
                                placeholder="Enter property name"
                                disabled={false}
                            />
                        </Grid>
                        <Grid>
                            <BSInput
                                id="newPropertyAddress"
                                label="Address"
                                value={requestModal.newPropertyAddress}
                                error={get(errors, `newPropertyAddress`, null)}
                                onChange={handleUpdate}
                                placeholder="Enter address"
                                disabled={false}
                            />
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={4}>
                                <BSInput
                                    id="newPropertyCity"
                                    label="City"
                                    value={requestModal.newPropertyCity}
                                    error={get(errors, `newPropertyCity`, null)}
                                    onChange={handleUpdate}
                                    placeholder="Enter city"
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BSSelect
                                    id="newPropertyState"
                                    label="State"
                                    value={requestModal.newPropertyState}
                                    error={get(errors, `newPropertyState`, null)}
                                    onChange={handleUpdate}
                                    placeholder="Select a state"
                                    options={us_states_options}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BSInput
                                    id="newPropertyZip"
                                    label="Zip Code"
                                    value={requestModal.newPropertyZip}
                                    error={get(errors, `newPropertyZip`, null)}
                                    onChange={handleUpdate}
                                    placeholder="Enter zip code"
                                    disabled={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid>
                            <BSInput
                                id="newPropertyComments"
                                label="Internal Comments"
                                value={requestModal.newPropertyComments}
                                error={get(errors, `newPropertyComments`, null)}
                                onChange={handleUpdate}
                                placeholder="Comments entered here are only visible inside KMConnect."
                                disabled={false}
                            />
                        </Grid>
                    </React.Fragment>
                )}
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                handleShowModal(!showModal);
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        {requestModal.tab === "existing" ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    sendEventAndRequestListing(
                                        modalType,
                                        requestModal.transactionType,
                                        requestModal.useType,
                                        requestModal.tab,
                                        props.role
                                    )
                                }
                            >
                                Start
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    sendEventAndRequestListing(
                                        modalType,
                                        requestModal.transactionType,
                                        requestModal.useType,
                                        requestModal.tab,
                                        props.role
                                    )
                                }
                            >
                                Start
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = (theme) => ({
    card: {
        display: "flex",
        marginBottom: "40px",
        border: "1px solid #dddddd",
        boxShadow: "none",
        minHeight: "74px",
        backgroundColor: "#f4f4f4",
    },
    cardSelected: {
        display: "flex",
        marginBottom: "40px",
        border: "1px solid #2C99D2",
        boxShadow: "none",
    },
    details: {
        width: "100%",
    },
    content: {
        flex: "1 0 auto",
        height: "100%",
        padding: "16px !important",
        // listed as important because in this modal, cards get 24px paddingBottom added to last-item
    },
    cover: {
        width: 151,
    },
    controls: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    instruction: {
        marginTop: "24px",
        marginBottom: "24px",
    },
    propertyType: {
        textTransform: "uppercase",
        fontSize: "13px",
        color: "#9e9e9e",
        marginTop: "-8px",
        marginBottom: "10px",
        fontWeight: "bold",
    },
    close: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    propertyAddy: {
        textTransform: "uppercase",
        fontSize: "13px",
    },
});

export default withStyles(styles)(RequestModal);
