import React from "react";
import orderBy from "lodash/orderBy";
import schema from "./companyTable";
import includes from "lodash/includes";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BSInput from "../../../common/js/components/BSInput";
import { Search, Sorty } from "../../../common/js/icons";
import BSSelect from "../../../common/js/components/BSSelect";
import { company_type_options } from "./constants";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CompanyForm from "./CompanyForm";

const styles = (theme) => ({
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    tabOne: {
        marginRight: "20px",
    },
    connectorActive: {
        "& $connectorLine": {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        "& $connectorLine": {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        "& $connectorLine": {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create("border-color"),
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 800,
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
    menuRequestListing: {
        paddingBottom: "0px",
        "&>a": {
            paddingBottom: "0px !important",
        },
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
    },
    lb: {
        textDecoration: "none",
        color: "#2C99D2",
        fontWeight: 600,
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    table: {
        minWidth: "100%",
    },
    toplessInput: {
        marginTop: 0,
    },
    loadingSpinner: {
        position: "absolute",
        left: "50%",
        top: "60%",
        transform: "translate(-50%, -50%)",
    },
    companyCheckbox: {
        "& svg": {
            fontSize: "17px",
            color: "rgba(0, 0, 0, 0.34) !important",
        },
    },
    companyCheckboxSelected: {
        "& svg": {
            fontSize: "17px",
        },
    },
});

class Companies extends React.Component {
    state = {
        searchRun: false,
        errorDialogOpen: false,
        mergeDialogOpen: false,
        companyFormDialogOpen: false,
        selectedCompanies: [],
        parentCompanyTypeFilter: ["BUSINESS"],
    };

    onKeyPress = (event) => {
        if (event.key === "Enter") {
            this.setState({ searchRun: true });
            this.props.handleSearchCompanies();
            this.props.handleClearCheckboxes();
        }
    };

    showHideErrorDialog = (currState) => {
        this.setState({ errorDialogOpen: !currState });
    };

    showHideMergeDialog = (currState) => {
        this.setState({ mergeDialogOpen: !currState });
    };

    switchToCompanyFormDialog = () => {
        this.setState({ mergeDialogOpen: false });
        this.setState({ companyFormDialogOpen: true });
    };

    showHideCompanyFormDialog = (currState) => {
        this.setState({ companyFormDialogOpen: !currState });
    };

    handleMergeCompanies = (data, mergeDialogOpen, errorDialogOpen) => {
        const selectedCompanies = [];
        let counter = 0;
        data.forEach((company) => {
            if (this.props.companyTab === "approved_companies") {
                if (this.props.selectedApprovedCompanies.get(company.companyKey)) {
                    selectedCompanies.push(company.companyKey);
                    counter++;
                }
            } else if (this.props.companyTab === "unapproved_companies") {
                if (this.props.selectedUnapprovedCompanies.get(company.companyKey)) {
                    selectedCompanies.push(company.companyKey);
                    counter++;
                }
            }
            if (counter === 1) {
                this.props.getCompany(company.companyKey);
            }
        });

        this.setState({ selectedCompanies: selectedCompanies });

        if (selectedCompanies.length < 2) {
            this.showHideErrorDialog(errorDialogOpen);
        } else {
            this.showHideMergeDialog(mergeDialogOpen);
        }
    };

    handleChangeParentCompanyType = (value) => {
        this.setState({ parentCompanyTypeFilter: value.split() });
    };

    handleSubmit = () => {
        this.props.handleInputChange("company.companyKey", null);
        this.props.handleSubmit(this.state.selectedCompanies);
        this.setState({ companyFormDialogOpen: false });
    };

    render() {
        const {
            classes,
            columnToSort,
            filterQuery,
            handleFilter,
            handleSort,
            isLoading,
            sortDirection,
            switchTab,
            userProfile,
            companyTab,
            companySearchType,
            handleChangeCompanySearchType,
            addCompany,
            companies,
            handleCheckboxClick,
            selectedApprovedCompanies,
            selectedUnapprovedCompanies,
        } = this.props;

        const { searchRun, mergeDialogOpen, errorDialogOpen } = this.state;

        const sortedData = orderBy(
            filterQuery && companies
                ? companies.filter((x) => schema.filterByQuery(x, schema.columnsToQueryFilter, filterQuery))
                : companies,
            columnToSort,
            sortDirection
        );

        if (!includes(["dataAdmin", "dataEntry"], get(userProfile, "role", null))) {
            return null;
        }

        return (
            <div>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.pageToolbar}
                    spacing={16}
                >
                    <Grid item>
                        <h2 className="page-title">Company Admin</h2>
                    </Grid>
                    <div>
                        <Button
                            onClick={() => this.handleMergeCompanies(sortedData, mergeDialogOpen, errorDialogOpen)}
                            variant="outlined"
                            color="primary"
                        >
                            Merge Duplicates
                        </Button>
                        <Button onClick={() => addCompany()} variant="contained" color="primary">
                            <Add fill="#ffffff" />
                            Add Company
                        </Button>
                    </div>
                </Grid>
                <Grid container alignItems="center" spacing={16}>
                    <Grid item xs>
                        <div className="simPaper">
                            <Grid container justify="space-between" spacing={0}>
                                <Grid item>
                                    <Tabs classes={classes} value={companyTab} onChange={switchTab}>
                                        <Tab
                                            classes={classes}
                                            value="unapproved_companies"
                                            label="Unapproved Companies"
                                        />
                                        <Tab classes={classes} value="approved_companies" label="Approved Companies" />
                                    </Tabs>
                                </Grid>
                                <Grid container justify="flex-end">
                                    <Grid item>
                                        <BSInput
                                            value={filterQuery}
                                            placeholder="Search Companies"
                                            className={classes.toplessInput}
                                            errorPlaceholder="hideIt"
                                            startAdornment={<Search />}
                                            onChange={(event) => {
                                                handleFilter(event.target.value);
                                            }}
                                            onKeyPress={(e) => this.onKeyPress(e)}
                                        />
                                    </Grid>
                                    <Grid item style={{ marginRight: "10px" }}>
                                        <BSSelect
                                            label="Company Type"
                                            extraInputStyles={{ marginTop: "0px", width: "150px" }}
                                            id="companySearchType"
                                            value={companySearchType}
                                            options={company_type_options}
                                            onChange={(e) => handleChangeCompanySearchType(e.target.value)}
                                            helperText={"*Type Required"}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs style={{ minHeight: "60vh" }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    {schema.viewHeaders["default_headers"].map((x, i) => (
                                                        <TableCell classes={classes} key={`thc-${i}`}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width:
                                                                        x.name === "Some Company Header" ? "185px" : "",
                                                                }}
                                                                onClick={() => handleSort(x.sort ? x.sort : x.prop)}
                                                            >
                                                                <span className={classes.sortable}>{x.name}</span>
                                                                {x.name && (
                                                                    <div>
                                                                        <Sorty />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {isLoading && searchRun ? (
                                                    <div
                                                        className={classes.loadingSpinner}
                                                        style={{ marginTop: "100px" }}
                                                    >
                                                        <CircularProgress color="primary" />
                                                    </div>
                                                ) : (
                                                    sortedData.map((company, i) =>
                                                        row(
                                                            company,
                                                            i,
                                                            companyTab,
                                                            classes,
                                                            isLoading,
                                                            handleCheckboxClick,
                                                            selectedApprovedCompanies,
                                                            selectedUnapprovedCompanies
                                                        )
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                        {!searchRun ? (
                                            <Grid item xs>
                                                <div className={classes.alternateContent}>
                                                    Please apply search criteria
                                                </div>
                                            </Grid>
                                        ) : (
                                            ""
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.errorDialogOpen}
                    onClose={this.showHideErrorDialog}
                    aria-labelledby="merge-error"
                    aria-describedby="merge-error-dialog"
                >
                    <DialogTitle id="merge-error">{"Cannot Merge"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            At least two companies must be selected in order to perform a merge
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.showHideErrorDialog} color="default">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.mergeDialogOpen}
                    onClose={this.showHideMergeDialog}
                    aria-labelledby="merge-prompt"
                    aria-describedby="merge-prompt-dialog"
                >
                    <DialogTitle id="merge-prompt">{"Merge duplicate companies?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to merge the selected companies?
                            {<br />}
                            The merged companies cannot be recovered once they have been deleted.
                            {<br />}
                            {<br />}
                            Note that institutional owners and companies associated with multiple company types will be
                            bypassed during the merge.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.showHideMergeDialog} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            onClick={this.switchToCompanyFormDialog}
                            color="secondary"
                            autoFocus
                            variant="contained"
                        >
                            Start
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.companyFormDialogOpen}
                    onClose={this.showHideCompanyFormDialog}
                    aria-labelledby="merge-form"
                    aria-describedby="merge-form-dialog"
                >
                    <DialogTitle id="merge-form">{"Company Information"}</DialogTitle>
                    <DialogContent>
                        <br></br>
                        <CompanyForm
                            {...this.props}
                            parentCompanyTypeFilter={this.state.parentCompanyTypeFilter}
                            handleChangeParentCompanyType={(value) => this.handleChangeParentCompanyType(value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.showHideCompanyFormDialog} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color="secondary" autoFocus variant="contained">
                            Start
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const row = (
    x,
    i,
    companyTab,
    classes,
    isLoading,
    handleCheckboxClick,
    selectedApprovedCompanies,
    selectedUnapprovedCompanies
) => {
    return isLoading ? null : (
        <TableRow key={`tr-${i}`} selectable="false">
            <TableCell>
                <Checkbox
                    className={
                        selectedApprovedCompanies.get(x.companyKey) === true ||
                        selectedUnapprovedCompanies.get(x.companyKey)
                            ? classes.companyCheckboxSelected
                            : classes.companyCheckbox
                    }
                    checked={
                        selectedApprovedCompanies.get(x.companyKey) === true ||
                        selectedUnapprovedCompanies.get(x.companyKey) === true
                    }
                    value={
                        selectedApprovedCompanies.get(x.companyKey) === true ||
                        selectedUnapprovedCompanies.get(x.companyKey) === true
                    }
                    color="primary"
                    onChange={() => handleCheckboxClick(x.companyKey, companyTab)}
                />
            </TableCell>
            {schema.viewHeaders["default_headers"].map((y, k) => (
                <TableCell
                    classes={{ root: classes.body }}
                    key={`trc-${k}`}
                    style={{ width: y.name === "Some Company Header" ? "185px" : "" }}
                >
                    {y.name === "LEGAL NAME" ? (
                        <Link to={`/company/${x.companyKey}`} className={classes.lb}>
                            {get(x, "legalName", "")}
                        </Link>
                    ) : y.name === "TYPE" ? (
                        y.transform(x).map((type, index) => {
                            return (
                                <span key={index}>
                                    {type}
                                    <br />
                                </span>
                            );
                        })
                    ) : y.transform ? (
                        y.transform(x)
                    ) : (
                        get(x, y.prop, "")
                    )}
                </TableCell>
            ))}
        </TableRow>
    );
};

export default withStyles(styles)(Companies);
