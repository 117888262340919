import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "../../../../node_modules/@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import AutosaveSuccess from "../../../../node_modules/@material-ui/icons/CheckCircle";
import FinancialInformation from "./FinancialInformation";
import ListingForm from "./ListingForm";
import get from "lodash/get";
import MixedUseForm from "../property/MixedUseForm";
import FormTitle from "../../../common/js/components/FormTitle";
import ParkingInfoForm from "../property/ParkingInfoForm";
import IndustrialForm from "../property/IndustrialForm";
import RetailForm from "../property/RetailForm";
import LandForm from "../property/LandForm";
import MultifamilyForm from "../property/MultifamilyForm";
import HospitalityForm from "../property/HospitalityForm";
import ReligiousForm from "../property/ReligiousForm";
import EducationalForm from "../property/EducationalForm";
import PortfolioForm from "../property/PortfolioForm";
import CondoForm from "../property/CondoForm";
import ContactContainer from "../contact/ContactContainer";
import { contact_options } from "../contact/constants";
import BSInput from "../../../common/js/components/BSInput";
import isEmpty from "lodash/isEmpty";

const styles = (theme) => ({
    readOnly: {
        color: "black",
        background: "#fafafa",
        lineHeight: "20px",
        paddingTop: "5px",
        paddingBottom: "5px",
    },
});

const ListingRequest = (props) => {
    const {
        classes,
        data,
        errors,
        handleAddArrayElement,
        handleInputChange,
        handleUpdateArrayElement,
        property,
        handleSearchCompany,
        autocompleteResults,
        handleListingUpdateArrayElement,
        disableInputs,
        handleDeleteArrayElementNEW,
    } = props;

    const useType = get(data, "attributes.useType", false);

    let attributes = get(data, "attributes", {});
    if (attributes == null) {
        attributes = {};
    }

    return (
        <div>
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <AutosaveSuccess className="waterloo" /> Listing Information
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                    <ListingForm
                        {...props}
                        showListingExpiration={true}
                        disabled={false}
                        listing={data}
                        errors={get(errors, "listing", {})}
                        handleInputChange={handleInputChange}
                        propertyType={get(property, "propertyType", null)}
                    />
                    <Divider />
                    <MixedUseForm
                        {...props}
                        handleDeleteArrayElement={handleDeleteArrayElementNEW}
                        handleUpdateArrayElement={handleListingUpdateArrayElement}
                        onChange={(field, value) => handleInputChange("listing.attributes." + field, value)}
                        data={attributes}
                        dataPathPrefix="attributes"
                        errors={get(errors, "listing.attributes", {})}
                    />
                    <Divider />
                    <PortfolioForm
                        {...props}
                        handleDeleteArrayElement={handleDeleteArrayElementNEW}
                        handleUpdateArrayElement={handleListingUpdateArrayElement}
                        onChange={(field, value) => handleInputChange("listing.attributes." + field, value)}
                        data={attributes}
                        dataPathPrefix="attributes"
                        errors={get(errors, "listing.attributes", {})}
                    />
                    <Divider />
                    <CondoForm
                        {...props}
                        handleDeleteArrayElement={handleDeleteArrayElementNEW}
                        handleUpdateArrayElement={handleListingUpdateArrayElement}
                        onChange={(field, value) => handleInputChange("listing.attributes." + field, value)}
                        data={attributes}
                        dataPathPrefix="attributes"
                        errors={get(errors, "listing.attributes", {})}
                    />
                    <Divider />
                    <FormTitle title="Parking Information" />
                    <ParkingInfoForm
                        {...props}
                        errors={get(errors, "listing.attributes", {})}
                        context="listing"
                        data={attributes}
                        useType={useType}
                        onChange={(field, value) => handleInputChange("listing.attributes." + field, value)}
                    />
                    {useType === "Retail" && (
                        <div>
                            <Divider />
                            <FormTitle title="Retail Information" />
                            <RetailForm
                                {...props}
                                handleUpdateArrayElement={handleListingUpdateArrayElement}
                                handleDeleteArrayElement={handleDeleteArrayElementNEW}
                                context="listing"
                                onChange={(field, value) => handleInputChange("listing.attributes." + field, value)}
                                data={attributes}
                                errors={get(errors, "listing.attributes", {})}
                                dataPathPrefix="attributes"
                            />
                        </div>
                    )}
                    {useType === "Industrial" && (
                        <div>
                            <Divider />
                            <FormTitle title="Industrial Information" />
                            <IndustrialForm
                                {...props}
                                errors={get(errors, "listing.attributes", {})}
                                context="listing"
                                onChange={(field, value) => handleInputChange("listing.attributes." + field, value)}
                                data={attributes}
                            />
                        </div>
                    )}
                    {useType === "Land" && (
                        <div>
                            <Divider />
                            <FormTitle title="Land Information" />
                            <LandForm
                                {...props}
                                errors={get(errors, "listing.attributes", {})}
                                context="listing"
                                onChange={(field, value) => handleInputChange("listing.attributes." + field, value)}
                                data={attributes}
                            />
                        </div>
                    )}
                    {useType === "Multifamily" && (
                        <div>
                            <Divider />
                            <FormTitle title="Multifamily Information" />
                            <MultifamilyForm
                                {...props}
                                errors={get(errors, "listing.attributes", {})}
                                context="listing"
                                onChange={(field, value) => handleInputChange("listing.attributes." + field, value)}
                                data={attributes}
                            />
                        </div>
                    )}
                    {useType === "Hospitality" && (
                        <div>
                            <Divider />
                            <FormTitle title="Hospitality Information" />
                            <HospitalityForm
                                {...props}
                                errors={get(errors, "listing.attributes", {})}
                                context="listing"
                                onChange={(field, value) => handleInputChange("listing.attributes." + field, value)}
                                data={attributes}
                            />
                        </div>
                    )}
                    {useType === "Religious" && (
                        <div>
                            <Divider />
                            <FormTitle title="Religious Information" />
                            <ReligiousForm
                                {...props}
                                errors={get(errors, "listing.attributes", {})}
                                context="listing"
                                onChange={(field, value) => handleInputChange("listing.attributes." + field, value)}
                                data={attributes}
                            />
                        </div>
                    )}
                    {useType === "Educational" && (
                        <div>
                            <Divider />
                            <FormTitle title="Educational Information" />
                            <EducationalForm
                                {...props}
                                errors={get(errors, "listing.attributes", {})}
                                context="listing"
                                onChange={(field, value) => handleInputChange("listing.attributes." + field, value)}
                                data={attributes}
                            />
                        </div>
                    )}
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <AutosaveSuccess className="waterloo" /> Listing Financial Information
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                    <FinancialInformation
                        {...props}
                        disableInputs={disableInputs}
                        listing={data}
                        errors={get(errors, "listing", {})}
                        handleInputChange={handleInputChange}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                        Contact Information{" "}
                        <small style={{ fontSize: "13px", color: "#999", fontWeight: "600" }}>
                            (Building Owner, Property Manager, Outside Broker, etc.)
                        </small>
                    </div>
                </ExpansionPanelSummary>
                <Divider />
                {disableInputs && isEmpty(get(data, "attributes.contacts", [])) && (
                    <div style={{ textAlign: "center", paddingTop: "15px" }}>No Listing Contacts Provided</div>
                )}
                {(!disableInputs || !isEmpty(get(data, "attributes.contacts", []))) && (
                    <ExpansionPanelDetails>
                        <ContactContainer
                            {...props}
                            autocompleteResults={autocompleteResults}
                            handleSearchCompany={handleSearchCompany}
                            handleChange={(field, value, index) =>
                                handleUpdateArrayElement("listing.attributes.contacts", index, field, value)
                            }
                            handleAdd={() => handleAddArrayElement("attributes.contacts")}
                            handleDelete={(index) => handleDeleteArrayElementNEW("attributes.contacts", index)}
                            contacts={get(data, "attributes.contacts", [])}
                            contactTypeOptions={contact_options}
                            addButtonText="Add Contact Information"
                            includeInvoiceRecipientCheckbox={false}
                            maxContacts={5}
                            displayWhenEmpty={true}
                            companyFieldAvailable={true}
                            errors={errors}
                            disableInputs={disableInputs}
                            hideTitle={true}
                            freeFormCompany={true}
                        />
                    </ExpansionPanelDetails>
                )}
            </ExpansionPanel>
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Listing Comments</ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={12}>
                            <BSInput
                                id="listingCommentsMember"
                                label="Internal Comments"
                                placeholder="Comments entered here are only visible inside KMConnect."
                                error={errors.listingCommentsMember}
                                value={data.listingCommentsMember}
                                disabled={disableInputs}
                                multiline={true}
                                rows={5}
                                onChange={(e) => handleInputChange("listing." + e.target.id, e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={12}>
                            <BSInput
                                id="listingCommentsPublic"
                                label="Public Comments"
                                placeholder="Comments entered here PUBLIC and will be VISIBLE on kidder.com, reports, and excel exports."
                                error={errors.listingCommentsPublic}
                                value={data.listingCommentsPublic}
                                multiline={true}
                                disabled={disableInputs}
                                rows={5}
                                onChange={(e) => handleInputChange("listing." + e.target.id, e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <FormTitle title="Property Comments" />
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={12}>
                            <BSInput
                                id="memberComments"
                                label="Internal Comments"
                                placeholder="Comments entered here are only visible inside KMConnect."
                                value={get(property, "memberComments", null)}
                                multiline={true}
                                rows={5}
                                disabled={get(property, "propertyKey", null) !== null}
                                className={classes.readOnly}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={12}>
                            <BSInput
                                id="publicComments"
                                label="Public Comments"
                                placeholder="Comments entered here PUBLIC and will be VISIBLE on reports and excel exports."
                                value={get(property, "publicComments", null)}
                                multiline={true}
                                rows={5}
                                disabled={get(property, "propertyKey", null) !== null}
                                className={classes.readOnly}
                            />
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};

export default withStyles(styles)(ListingRequest);
