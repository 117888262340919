import React from "react";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BSInput from "../../../common/js/components/BSInput";

class HealthcareForm extends React.Component {
    handleAttributeUpdate = (event) => {
        this.props.onChange(event.target.id, event.target.value);
    };

    render() {
        const { data, errors, disableInputs, context } = this.props;

        return (
            <div>
                <Grid container alignItems="center" spacing={8}>
                    {context === "property" && (
                        <Grid item xs={6}>
                            <BSInput
                                id="suite"
                                label="OFFICE SUITE NUMBER"
                                placeholder="Enter number"
                                error={errors.suite}
                                value={data.suite}
                                onChange={this.handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.divisibleFlg ? true : false}
                                        onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                        value="divisibleFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Office Space Divisible"
                                style={{ marginBottom: "10px" }}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="officeSf"
                            label="OFFICE SF AVAILABLE"
                            placeholder="Enter SF"
                            errorText="ex. 98101"
                            error={errors.officeSf}
                            value={data.officeSf}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="largestContigSf"
                            label="OFFICE CONTIGUOUS SF AVAILABLE"
                            placeholder="Enter SF"
                            error={errors.largestContigSf}
                            value={data.largestContigSf}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default HealthcareForm;
