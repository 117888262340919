const COMPLIANCE_URL = "compliance/";
const LOCATIONS_URL = "locations/";
const PARENT_ENTITIES_URL = "parent-entities/";

export const urls = {
    get_action_types: () => COMPLIANCE_URL + "action-types",
    get_document_types: () => "document-types",
    get_condition_types: () => COMPLIANCE_URL + "condition-types",
    get_states: () => LOCATIONS_URL + "states",
    get_rules_by_parent_entity_type_key: () => COMPLIANCE_URL + "rules/parent-entity-type",
    add_rule: () => COMPLIANCE_URL + "rules",
    update_rule: (ruleKey) => COMPLIANCE_URL + `rules/${ruleKey}`,
    delete_rule: (ruleKey) => COMPLIANCE_URL + `rules/${ruleKey}`,
    update_priority: () => COMPLIANCE_URL + "rules/priority",
    get_rules_by_document_type_key: () => COMPLIANCE_URL + "rules/document-type",
    get_all_parent_entities: () => PARENT_ENTITIES_URL,
};

export const chipColors = {
    required: "#E8254A",
    optional: "#0772B9",
    hidden: "rgba(0, 0, 0, 0.26)",
};
