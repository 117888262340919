import types from "./types";

const getDeal = (deal) => {
    return {
        type: types.GET_DEAL_REFACTOR,
        deal: deal,
    };
};

const getDealSummary = (deal) => {
    return {
        type: types.GET_DEAL_SUMMARY,
        deal: deal,
    };
};

const getDealCreator = (user) => {
    return {
        type: types.GET_DEAL_CREATOR,
        creator: user,
    };
};

const getDealSummaryVouchers = (vouchers) => {
    return {
        type: types.GET_DEAL_SUMMARY_VOUCHERS,
        vouchers: vouchers,
    };
};

const handleSort = (columnName, sortDirection) => {
    return {
        type: types.SORT_DEAL_SUMMARY_VOUCHERS,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

const setDealId = (id) => {
    return {
        type: types.SET_DEAL_ID,
        deal: id,
    };
};

const dealChange = (field, value) => {
    return {
        type: types.DEAL_CHANGE,
        field: field,
        value: value,
    };
};

const addError = (field, error) => {
    return {
        type: types.ADD_DEAL_ERROR,
        field: field,
        error: error,
    };
};

const clearErrors = () => {
    return {
        type: types.CLEAR_DEAL_ERRORS,
    };
};

const checkboxClick = (newSelected) => {
    return {
        type: types.CHECKBOX_CLICK,
        selected: newSelected,
    };
};

const addInstallment = (installment) => {
    return {
        type: types.ADD_INSTALLMENT,
        installment: installment,
        key: Object.keys(installment)[0],
    };
};

const updateInstallment = (key, field, value) => {
    return {
        type: types.UPDATE_INSTALLMENT,
        key: key,
        field: field,
        value: value,
    };
};

const deleteInstallment = (installmentKey) => {
    return {
        type: types.DELETE_INSTALLMENT,
        key: installmentKey,
    };
};

const updatePropertyInfo = (field, value) => {
    return {
        type: types.UPDATE_PROPERTY_INFO,
        field: field,
        value: value,
    };
};

const totalConsiderationChange = (field, value) => {
    return {
        type: types.TOTAL_CONSIDERATION_CHANGE,
        field: field,
        value: value,
    };
};

const updateTotalConsiderationCalculation = (invoice) => {
    return {
        type: types.UPDATE_TOTAL_CONSIDERATION_CALCULATION,
        invoice: invoice,
    };
};

const clearTotalConsiderationCalculation = () => {
    return {
        type: types.CLEAR_TOTAL_CONSIDERATION_CALCULATION,
    };
};

const periodMonthChange = (period, month, field, value) => {
    return {
        type: types.PERIOD_MONTH_CHANGE,
        period: period,
        month: month,
        field: field,
        value: value,
    };
};

const periodChange = (period, field, value) => {
    return {
        type: types.PERIOD_CHANGE,
        period: period,
        field: field,
        value: value,
    };
};

const updatePeriodSummary = (periodNumber, summary) => {
    return {
        type: types.UPDATE_PERIOD_SUMMARY,
        period: periodNumber,
        summary: summary,
    };
};

const updateTotalConsiderationSummary = (summary) => {
    return {
        type: types.UPDATE_TOTAL_CONSIDERATION_SUMMARY,
        summary: summary,
    };
};

const totalConsiderationInputUpdates = (updates) => {
    return {
        type: types.TOTAL_CONSIDERATION_INPUT_UPDATE,
        updates: updates,
    };
};

const setFlag = (field, value) => {
    return {
        type: types.SET_FLAG,
        field: field,
        value: value,
    };
};

const searchResults = (results) => {
    return {
        type: types.SEARCH_RESULTS,
        results: results,
    };
};

const updateCompanyProperty = (companyType, property, value) => {
    return {
        type: types.UPDATE_COMPANY_PROPERTY,
        key: companyType,
        field: property,
        value: value,
    };
};

const buildCompany = (company) => {
    return {
        companyKey: company.companyKey ? company.companyKey : null,
        legalName: company.legalName ? company.legalName : null,
        displayName: company.displayName ? company.displayName : null,
        parentCompany: company.parentCompany ? buildCompany(company.parentCompany) : null,
    };
};

export const dealSnackbar = (open, variant, message) => {
    return {
        type: types.DEAL_SNACKBAR,
        open: open,
        message: message,
        variant: variant,
    };
};

export const toggleBooleanProperty = (field) => {
    return {
        type: types.TOGGLE_BOOLEAN_PROPERTY,
        field: field,
    };
};

const updateStateObjectProperty = (stateObjectProperty, field, value) => {
    return {
        type: types.STATE_OBJECT_PROPERTY_CHANGE,
        key: stateObjectProperty,
        field: field,
        value: value,
    };
};

const getBrokerCommissionAmounts = (voucherKey, data) => {
    return {
        type: types.BROKER_COMMISSION_MY_AMOUNT,
        voucherKey: voucherKey,
        myAmount: data.netAmount,
    };
};

const setDealSummaryBrokerCommissionAmounts = (brokerCommissions) => {
    return {
        type: types.SET_DEAL_SUMMARY_BROKER_COMMISSIONS,
        brokerCommissions: brokerCommissions,
    };
};

const getListingAgent = (broker) => {
    return {
        type: types.GET_DEAL_SUMMARY_LISTING_AGENT,
        broker: broker,
    };
};

const loading = (isLoading) => {
    return {
        type: types.PAGE_LOADING,
        isLoading: isLoading,
    };
};

const vouchersLoading = (isLoading) => {
    return {
        type: types.PAGE_LOADING_2,
        isLoading: isLoading,
    };
};

const addVoucherInstallment = (voucher) => {
    return {
        type: types.ADD_VOUCHER_INSTALLMENT,
        voucherInstallment: voucher,
    };
};

const updateCompValue = (path, value) => {
    return {
        type: types.UPDATE_COMP_VALUE,
        path: path,
        value: value,
    };
};

const setCompArray = (pathToArray, array) => {
    return {
        type: types.SET_COMP_ARRAY,
        pathToArray: pathToArray,
        array: array,
    };
};

const deleteCompArrayElement = (arrayPath, index) => {
    return {
        type: types.DELETE_COMP_ARRAY_ELEMENT,
        path: arrayPath,
        index: index,
    };
};

const clearTotalConsideration = () => {
    return {
        type: types.CLEAR_TOTAL_CONSIDERATION,
    };
};

const toggleTerminateDialog = (open) => {
    return {
        type: types.TOGGLE_TERMINATE_DIALOG,
        open: open,
    };
};

const toggleConfirmTerminateDialog = (open) => {
    return {
        type: types.TOGGLE_CONFIRM_TERMINATE_DIALOG,
        open: open,
    };
};

const toggleTerminationDocument = (upload) => {
    return {
        type: types.TERMINATION_DOCUMENT_UPLOADED,
        upload: upload,
    };
};

export default {
    getDeal,
    getDealSummary,
    getDealSummaryVouchers,
    getDealCreator,
    handleSort,
    setDealId,
    dealChange,
    addError,
    clearErrors,
    checkboxClick,
    addInstallment,
    deleteInstallment,
    updateInstallment,
    totalConsiderationChange,
    updateTotalConsiderationCalculation,
    periodMonthChange,
    updateTotalConsiderationSummary,
    updatePeriodSummary,
    totalConsiderationInputUpdates,
    updatePropertyInfo,
    setFlag,
    searchResults,
    dealSnackbar,
    updateCompanyProperty,
    toggleBooleanProperty,
    updateStateObjectProperty,
    loading,
    vouchersLoading,
    getBrokerCommissionAmounts,
    getListingAgent,
    addVoucherInstallment,
    updateCompValue,
    setCompArray,
    deleteCompArrayElement,
    clearTotalConsideration,
    toggleTerminationDocument,
    toggleTerminateDialog,
    toggleConfirmTerminateDialog,
    setDealSummaryBrokerCommissionAmounts,
    clearTotalConsiderationCalculation,
    periodChange,
};
