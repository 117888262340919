import React from "react";
import BSInput from "../../../common/js/components/BSInput";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Delete from "../../../../node_modules/@material-ui/icons/Delete";

class Anchor extends React.Component {
    render() {
        const { element, elementIndex, errors, isDisabled, onDelete, handleUpdateArrayElement, dataPathPrefix } =
            this.props;

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8} style={{ height: "80px" }}>
                    <Grid item xs={6}>
                        <BSInput
                            id="anchorName"
                            label="ANCHOR NAME"
                            placeholder="Enter anchor name"
                            value={element ? element.anchorName : null}
                            error={get(errors, `anchors.${elementIndex}.anchorName`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement(
                                    dataPathPrefix + ".anchors",
                                    elementIndex,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: "22px" }}>
                        {elementIndex > 0 && isDisabled !== true && (
                            <Button
                                onClick={() => onDelete(elementIndex)}
                                style={{ color: "#9e9e9e", "&:hover": { backgroundColor: "transparent" } }}
                            >
                                <Delete />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Anchor;
