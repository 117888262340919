import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import { ExpansionPanelSummary, ExpansionPanelDetails, FormControlLabel } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import each from "lodash/each";
import get from "lodash/get";

import { propertyTypeFilters } from "./constants";

const PropertyTypeFilter = (props) => {
    const {
        classes,
        updateFilter,
        handleSelectAllPropertyType,
        handleSearchSubmit,
        searchCriteria,
        togglePropertyTypeExpansionPanel,
        propertyTypeExpansionPanels,
    } = props;

    const updatePropertyTypeFilter = (field, value) => {
        updateFilter(field, value);
    };

    const propertyTypeCriteria = searchCriteria.propertyTypes;

    const countPropertyTypeSelectedSubtype = (searchCriteria) => {
        let count = 0;
        each(Object.keys(get(searchCriteria, "specialtyTypeCriteria", {})), (key) => {
            if (get(searchCriteria, `specialtyTypeCriteria.${key}`, false) === true) {
                count += 1;
            }
        });
        each(Object.keys(get(searchCriteria, "buildlingClassCriteria", {})), (key) => {
            if (get(searchCriteria, `buildlingClassCriteria.${key}`, false) === true) {
                count += 1;
            }
        });
        return count;
    };

    const checkForLeaseOnly = () => {
        console.log(searchCriteria);
        if (
            (!get(searchCriteria, "dealTypeCriteria.sublease") &&
                !get(searchCriteria, "dealTypeCriteria.investmentSaleFlg") &&
                !get(searchCriteria, "dealTypeCriteria.sale") &&
                get(searchCriteria, "dealTypeCriteria.lease")) ||
            (!get(searchCriteria, "listingTypeCriteria.forSublease") &&
                !get(searchCriteria, "listingTypeCriteria.investmentSaleFlg") &&
                !get(searchCriteria, "listingTypeCriteria.forSale") &&
                get(searchCriteria, "listingTypeCriteria.forLease"))
        ) {
            return true;
        }
        return false;
    };

    return (
        <Grid container>
            {Object.keys(propertyTypeFilters).map(
                (type, i) =>
                    !(type === "Hospitality" && checkForLeaseOnly()) && (
                        <Grid item xs={12}>
                            <ExpansionPanel
                                expanded={get(propertyTypeExpansionPanels, type, false)}
                                classes={{
                                    root: classes.expansionPanel,
                                    expanded: classes.expansionPanelExpanded,
                                }}
                            >
                                <ExpansionPanelSummary
                                    classes={{
                                        root: classes.expansionPanelSummary,
                                        expanded: classes.expansionPanelSummaryExpanded,
                                        focused: classes.expansionPanelSummaryFocused,
                                        content: classes.expansionPanelSummaryContent,
                                    }}
                                    expandIcon={
                                        <ExpandMoreIcon
                                            onClick={() => {
                                                togglePropertyTypeExpansionPanel(type);
                                            }}
                                        />
                                    }
                                >
                                    <Grid container>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={get(propertyTypeCriteria, `${type}.selected`, false)}
                                                        onChange={(e) =>
                                                            handleSelectAllPropertyType(type, e.target.checked)
                                                        }
                                                        // value={get(propertySearchFilters, `${type}.checked`, false)}
                                                        color="primary"
                                                    />
                                                }
                                                label={`${propertyTypeFilters[type]["display"]} ${
                                                    countPropertyTypeSelectedSubtype(
                                                        get(propertyTypeCriteria, type, {})
                                                    ) > 0
                                                        ? `(${countPropertyTypeSelectedSubtype(
                                                              get(propertyTypeCriteria, type, {})
                                                          )})`
                                                        : ""
                                                }`}
                                            />
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails
                                    classes={{
                                        root: classes.expansionPanelDetails,
                                    }}
                                >
                                    <Grid container>
                                        {Object.keys(propertyTypeFilters[type]["subs"]).map((sub, j) => {
                                            const subType = propertyTypeFilters[type]["subs"][sub];
                                            return (
                                                <React.Fragment>
                                                    <Grid item xs={12} className={classes.gridItemCheckbox}>
                                                        <h4 className={classes.menuSectionTitle}>{subType.display}</h4>
                                                    </Grid>
                                                    {Object.keys(subType.options).map((opt, k) => {
                                                        const option = subType["options"][opt];
                                                        return (
                                                            <Grid item xs={6} className={classes.gridItemCheckbox}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={get(
                                                                                propertyTypeCriteria,
                                                                                `${type}.${sub}.${opt}`,
                                                                                false
                                                                            )}
                                                                            onChange={(e) =>
                                                                                updatePropertyTypeFilter(
                                                                                    `propertyTypes.${type}.${sub}.${opt}`,
                                                                                    e.target.checked
                                                                                )
                                                                            }
                                                                            // value={get(propertySearchFilters, `${type}.${sub}.${opt}`, false)}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label={option.display}
                                                                />
                                                            </Grid>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            );
                                        })}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    )
            )}
            <Grid container spacing={16} justify="flex-end">
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearchSubmit}
                        className={classes.menuSearchButton}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const styles = (theme) => ({
    menuSectionTitle: {
        color: "#9E9E9E",
        fontSize: "13px",
        fontWeight: 600,
        textTransform: "uppercase",
        marginBottom: "5px",
    },

    expansionPanel: {
        margin: 0,
        borderTop: "1px solid #eeeeee",
    },
    expansionPanelExpanded: {},

    expansionPanelSummary: {
        minHeight: "0 !important",
    },

    expansionPanelSummaryExpanded: {
        margin: "0 !important",
        background: "#fafafa",
        // borderBottom: '1px solid #eeeeee', doesn't quite work, applies to other divs too
    },
    expansionPanelSummaryFocused: {},
    expansionPanelSummaryContent: {
        margin: 0,
    },

    expansionPanelDetails: {
        padding: "0 20px",
        borderTop: "1px solid #eeeeee",
    },

    menuSearchButton: {
        margin: "15px",
    },

    // root: {
    //   '&:hover': {
    //     cursor: 'pointer',
    //   },
    //   backgroundColor: 'white',
    //   marginBottom: '0px',

    // },
    // propertyTypeContainer: {
    //   width: '32em',
    // },

    // expPanel: {
    //   root: {
    //     marginBottom: '0px',
    //   },
    // },
    // expPanelSummary: {
    //   root: {
    //     height: '2.65em',
    //     fontSize: '0.875rem',
    //     fontWeight: 400,
    //     marginBottom: '0px',
    //     '&:hover': {
    //       cursor: 'default',
    //     }
    //   },
    //   content: {
    //     height: '100%',
    //     '&:hover': {
    //       cursor: 'default',
    //     }
    //   },
    //   expanded: {
    //     height: '100%',
    //   }
    // },
    // expPanelDetails: {
    //   paddingTop: '0px',
    // },

    // checkbox: {
    //   border: '1px dotted'
    // },
    // gridItemCheckbox: {
    //   height: '2em',
    // },
    // expPanelTitle: {
    //   fontWeight: 500,
    // },
    // statusContainer: {
    //   width: '22em',
    //   height: '12em',
    // },
});

export default withStyles(styles)(PropertyTypeFilter);
