import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import constants from "./constants";
import BSInput from "../../../common/js/components/BSInput";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Grid } from "@material-ui/core";
import BSSelect from "../../../common/js/components/BSSelect";

const MarketingFlyerModalBody = (props) => {
    const { setDealParty, timeOnMarketInput, modalContent, selectedCompId, handleSelectPhotoFromDropdown } = props;

    const { dealParty, timeOnMarket, marketingFlyerPhotos, marketingFlyerPhoto } = modalContent;

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <DealPartyFormControl dealParty={dealParty} setDealParty={setDealParty} />
            </Grid>
            <Grid item xs={6}>
                <MarketingFlyerPhotoInput
                    selectedCompId={selectedCompId}
                    marketingFlyerPhoto={marketingFlyerPhoto}
                    marketingFlyerPhotos={marketingFlyerPhotos}
                    handleSelectPhotoFromDropdown={handleSelectPhotoFromDropdown}
                />
            </Grid>
            <Grid item xs={6}>
                <TimeOnMarketInput timeOnMarket={timeOnMarket} timeOnMarketInput={timeOnMarketInput} />
            </Grid>
            <Grid item xs={2} />
        </React.Fragment>
    );
};

const DealPartyFormControl = (props) => {
    const { dealParty, setDealParty } = props;
    return (
        <React.Fragment>
            <FormControl rowcomponent="fieldset">
                <RadioGroup
                    row
                    aria-label="include"
                    name="include"
                    value={dealParty}
                    onChange={(e) => setDealParty(e.target.value)}
                >
                    <FormLabel>Deal Party</FormLabel>
                    <FormControlLabel
                        value="BUYER_TENANT"
                        control={<Radio id="include" color="primary" style={{ marginRight: "5px" }} />}
                        label="Buyer/Tenant"
                    />
                    <FormControlLabel
                        value="SELLER_LANDLORD"
                        control={<Radio id="include" color="primary" />}
                        label="Seller/Landlord"
                    />
                    <FormControlLabel value="BOTH" control={<Radio id="include" color="primary" />} label="Both" />
                </RadioGroup>
            </FormControl>
        </React.Fragment>
    );
};

const MarketingFlyerPhotoInput = (props) => {
    const { marketingFlyerPhoto, marketingFlyerPhotos, handleSelectPhotoFromDropdown } = props;
    const [selectedPhotoId, setSelectedPhotoId] = useState(0);

    const handleChange = (event) => {
        const photoId = parseInt(event.target.value);

        setSelectedPhotoId(photoId);
        handleSelectPhotoFromDropdown(photoId);
    };

    return marketingFlyerPhotos.length !== 0 ? (
        <BSSelect
            id="marketing-flyer-photo-dropdown"
            label="Marketing Flyer Photo"
            value={selectedPhotoId}
            onChange={handleChange}
            toolTipText="Photos must be .jpg or .png. To add a photo, please contact Corporate Data."
            options={[
                {
                    value: 0,
                    label: "Select",
                    disabled: true,
                },
                ...marketingFlyerPhotos.map((photo) => ({
                    value: photo.mediaAssetKey,
                    label: `${photo.ownerType} - ${photo.mediaAssetType} - ${photo.mediaAssetName}`,
                })),
            ]}
        />
    ) : (
        <BSInput
            id="marketingFlyerPhoto"
            label="Marketing Flyer Photo"
            value={marketingFlyerPhoto.length !== 0 ? "Attached" : "Missing"}
            defaultValue=""
            noFullWidth={true}
            helperText={
                marketingFlyerPhoto.length !== 0 ? null : (
                    <span>
                        Marketing Flyer Photo is missing. Please upload an image for this <HrefToComp {...props} />.
                    </span>
                )
            }
            extraFormControlStyles={{ width: "100%" }}
            disabled
        />
    );
};

const HrefToComp = (props) => {
    const { selectedCompId } = props;
    const hrefToComp = constants.urls.get_individual_comp(selectedCompId);
    return (
        <React.Fragment>
            <a href={hrefToComp} target="_blank" rel="noopener noreferrer">
                comp
            </a>
        </React.Fragment>
    );
};

const TimeOnMarketInput = (props) => {
    const { timeOnMarket, timeOnMarketInput } = props;
    return (
        <React.Fragment>
            <BSInput
                id="inputData"
                label="Time on Market"
                value={timeOnMarket}
                onChange={(e) => timeOnMarketInput(e.target.value)}
                placeholder="e.g. 6 months, 2 years, 30 days, etc"
                defaultValue=""
                noFullWidth={true}
                noHelperText={true}
                extraFormControlStyles={{ width: "100%" }}
            />
        </React.Fragment>
    );
};

const styles = (theme) => ({});

export default withStyles(styles)(MarketingFlyerModalBody);
