import { Component } from "react";

class ExternalRedirect extends Component {
    componentDidMount() {
        console.log("ExternalRedirect-redirectUrl=" + this.props.redirectUrl);
        window.location.assign(this.props.redirectUrl);
    }

    render() {
        return null;
    }
}

export default ExternalRedirect;
