import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Comments from "@material-ui/icons/Comment";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { DropdownButton, MenuItem, SplitButton } from "react-bootstrap";
import { red } from "@material-ui/core/colors";
import constants from "./constants";
import get from "lodash/get";
import includes from "lodash/includes";
import { buildAddress } from "../utils/operations";
import { SaveAndExit } from "../../../common/js/icons";

const styles = (theme) => ({
    pageToolbar: {
        padding: "10px 0px",
        backgroundColor: "#fafafa",
        position: "sticky",
        top: "62px",
        zIndex: 1,
    },
    leftIcon: {
        marginLeft: "25px",
    },
    progress: {
        paddingRight: "100px",
        paddingLeft: "100px",
    },
    progressDiv: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
    },
    menuCategory: {
        "&>a:focus": {
            outline: "none",
        },
    },
    deleteButton: {
        paddingLeft: "28px",
        paddingRight: "28px",
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800],
        },
    },
});

const VoucherToolbar = (props) => {
    const {
        userProfile,
        voucher,
        classes,
        numComments,
        handleSubmit,
        handleSave,
        handleSaveAndExit,
        handleDeleteVoucher,
        toggleComments,
        toggleConfirm,
        confirmDialog,
        elementsSaving,
    } = props;

    const [deleteVoucherDialog, setDeleteVoucherDialog] = useState(false);
    const installment = props.location.pathname.slice(9, 20) === "installment";

    const handleCancel = () => {
        toggleConfirm(null);
    };

    const toggleDeleteVoucherDialog = (showDialog) => {
        setDeleteVoucherDialog(showDialog);
    };

    const brokerSubmitActions = {
        Draft: {
            buttonText: "Submit",
            submit: (callback) => {
                handleSubmit(true, constants.urls.broker_submit(voucher.voucherKey), true);
            },
            variant: "contained",
            confirm: false,
            url: constants.urls.broker_submit,
        },
        "Broker Action Required": {
            buttonText: "Submit",
            submit: (callback) => {
                handleSubmit(true, constants.urls.broker_submit(voucher.voucherKey), true);
            },
            variant: "contained",
            confirm: false,
            url: constants.urls.broker_submit,
        },
    };

    const submitActions = {
        broker: {
            ...brokerSubmitActions,
        },
        evp: {
            ...brokerSubmitActions,
        },
        portlandBrokerAdmin: {
            ...brokerSubmitActions,
            "Portland Review Required": {
                buttonText: "Portland Review Required",
                submit: (callback) => {
                    handleSubmit(true, callback, true);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_voucher_to_broker,
                    Approve: constants.urls.portland_approval,
                },
            },
        },
        arizonaBrokerAdmin: {
            ...brokerSubmitActions,
            "AZ Review Required": {
                buttonText: "AZ Review Required",
                submit: (callback) => {
                    handleSubmit(true, callback, true);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_voucher_to_broker,
                    Approve: constants.urls.arizona_approval,
                },
            },
        },
        dataEntry: {
            "Needs Review": {
                buttonText: "Start",
                submit: () => {
                    handleSubmit(false, constants.urls.assign_voucher_for_review(voucher.voucherKey), true);
                },
                confirm: false,
                url: constants.urls.assign_voucher_for_review,
            },
            "In Review": {
                buttonText: "In Review",
                submit: (callback) => {
                    handleSubmit(true, callback, true);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_voucher_to_broker,
                    "Final Review": constants.urls.approve_voucher,
                },
            },
            "Admin Action Required": {
                buttonText: "Edit",
                submit: () => {
                    handleSubmit(false, constants.urls.assign_voucher_for_review(voucher.voucherKey), true);
                },
                confirm: false,
                url: constants.urls.assign_voucher_for_review,
            },
        },
        dataAdmin: {
            "Final Review": {
                buttonText: "In Review",
                submit: (callback) => {
                    handleSubmit(true, callback, true);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_voucher_to_broker,
                    "Admin Action Required": constants.urls.reassign_voucher_to_dataentry,
                    Approve: constants.urls.approve_voucher,
                },
            },
        },
        accounting: {
            "Awaiting Payment": {
                buttonText: "Ready For Payment",
                submit: (callback) => {
                    handleSubmit(true, constants.urls.ready_for_payment(voucher.voucherKey), false);
                },
                variant: "contained",
                confirm: false,
                url: constants.urls.ready_for_payment,
            },
        },
    };

    const mainAction = get(submitActions, `${userProfile.role}.${voucher.voucherStatus}`, null);

    const confirm = (url) => {
        toggleConfirm(url(voucher.voucherKey));
    };

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
            <Grid item>
                {installment ? (
                    <h2 className="page-title">Add Installment</h2>
                ) : (
                    <h2 className="page-title">{voucher.voucherKey && `Voucher ${voucher.voucherKey}`}</h2>
                )}
                <p className="page-subtitle">
                    {buildAddress(get(voucher, "deal.listing.property.primaryAddress"))}
                    {get(voucher, "voucherKey", null) ? " | " : " "}
                    {get(voucher, "deal.propertyType")} {get(voucher, "deal.transactionType")}
                </p>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" spacing={0}>
                    <Grid item className={classes.leftIcon}>
                        <Button onClick={toggleComments}>
                            <Comments /> Comments ({numComments})
                        </Button>
                    </Grid>
                    {(userProfile.role === "dataAdmin" &&
                        includes(["Draft", "Awaiting Payment", "Awaiting Comp"], voucher.voucherStatus)) ||
                    (userProfile.user === get(voucher, "auditCreatedBy") &&
                        includes(["Draft", "Awaiting Payment"], voucher.voucherStatus)) ? (
                        <Grid item>
                            <Button className={classes.deleteButton} onClick={() => toggleDeleteVoucherDialog(true)}>
                                Delete
                            </Button>
                        </Grid>
                    ) : (
                        ""
                    )}
                    <Grid item>
                        {elementsSaving.saveAndExit ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : (
                            <SplitButton title="Save" bsStyle="default" onClick={handleSave}>
                                <Button variant="contained" color="secondary" onClick={handleSaveAndExit}>
                                    <SaveAndExit />
                                    Save & Exit
                                </Button>
                            </SplitButton>
                        )}
                    </Grid>
                    <Grid item>
                        {elementsSaving.submit ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : mainAction && mainAction.options ? (
                            <DropdownButton title={get(mainAction, "buttonText")} pullRight>
                                {Object.keys(get(mainAction, "options", {})).map((x, i) => {
                                    let url = get(mainAction, `options.${x}`);
                                    return (
                                        <MenuItem className={classes.menuCategory} onClick={() => confirm(url)}>
                                            {x}
                                        </MenuItem>
                                    );
                                })}
                            </DropdownButton>
                        ) : mainAction !== null ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => confirm(get(mainAction, `url`))}
                            >
                                {mainAction.buttonText}
                            </Button>
                        ) : (
                            voucher && (
                                <Button disabled variant="contained" color="secondary">
                                    {voucher.voucherStatus}
                                </Button>
                            )
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={confirmDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Continue?</DialogTitle>
                <DialogContent>Are you sure you want to submit this status change?</DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => mainAction["submit"](confirmDialog.onSubmit)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteVoucherDialog} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Delete?</DialogTitle>
                <DialogContent>
                    You will not be able to recover this voucher after deletion. Are you sure you want to continue?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleDeleteVoucherDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteVoucher(voucher.voucherKey)} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default withStyles(styles)(VoucherToolbar);
