import types from "./types";

const handleChange = (field, value) => {
    return {
        type: "HANDLE_CHANGE",
        field: field,
        value: value,
    };
};

const validateLogin = (username, password, valid) => {
    return {
        type: "VALIDATE_NON_BLANK",
        valid: valid,
    };
};

const loginSuccess = (response) => {
    return {
        type: "LOGIN_SUCCESS",
        payload: response.data,
        status: response.status,
        authenticated: true,
    };
};

const loginFailure = (err) => {
    return {
        type: "LOGIN_FAILED",
        payload: err,
        status: err.response.status,
        authenticated: false,
        snackbarOpen: true,
    };
};

const hideSnackbar = () => {
    return {
        type: "HIDE_SNACKBAR",
        status: false,
    };
};
const changeProfile = (value) => {
    return {
        type: types.CHANGE_TEST_PROFILE,
        value: value,
    };
};
const changeUser = (userData) => {
    return {
        type: types.CHANGE_USER,
        userData: userData,
    };
};

const loadUserProfile = (userSession) => {
    console.log("user session in auth operations loadUserProfile below ");
    console.log(userSession);
    console.log("-----------------");
    return {
        type: types.LOAD_USER_PROFILE,
        //role: authInfo.role,
        //authenticated: authInfo.authenticated,
        //userAccount: authInfo.userAccount
        userProfile: userSession.userProfile,
        authenticatedUserProfile: userSession.authenticatedUserProfile,
    };
};
const redirectToLogin = () => {
    return {
        type: types.REDIRECT_TO_LOGIN,
    };
};

const clearAuthenticationInfo = () => {
    return {
        type: types.CLEAR_AUTHENTICATION_INFO,
    };
};

const resetState = () => {
    return {
        type: types.RESET_STATE,
    };
};

export default {
    handleChange,
    validateLogin,
    loginSuccess,
    loginFailure,
    hideSnackbar,
    changeProfile,
    loadUserProfile,
    redirectToLogin,
    clearAuthenticationInfo,
    changeUser,
    resetState,
};
