import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Add from "../../../../node_modules/@material-ui/icons/Add";

const styles = (theme) => ({
    moveIt: {
        margin: "0 0 10px",
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },

    root: {
        border: "2px dashed #2C99D2",
        color: "#2C99D2",
        margin: "10px 0 20px",
        padding: "11px 16px",
        width: "100%",
        justifyContent: "start",
        "&hover": {
            backgroundColor: "rgba(44, 153, 210, 0.08)",
        },
    },
});

class MultiAddInput extends React.Component {
    // this is risky and can cause infinite recursion if the path for updating the array is wrong.
    componentDidMount() {
        if (this.props.elements) {
            if (Object.prototype.toString.call(this.props.elements) === "[object Array]") {
                if (this.props.displayFirstWhenEmpty === true && !this.props.disableInputs) {
                    const numParcels = this.props.elements ? this.props.elements.length : 0;
                    if (numParcels === 0 && this.props.disableInputs !== true) {
                        this.props.onAdd();
                    }
                }
            } else {
                if (this.props.displayFirstWhenEmpty === true && !this.props.disableInputs) {
                    const numParcels = this.props.elements ? Object.keys(this.props.elements).length : 0;
                    if (numParcels === 0 && this.props.disableInputs !== true) {
                        this.props.onAdd();
                    }
                }
            }
        } else {
            if (this.props.displayFirstWhenEmpty === true && !this.props.disableInputs) {
                console.log(this.props.elements);
                this.props.onAdd();
            }
        }
    }

    render() {
        const { classes, disableInputs, elements, onAdd, addButtonText, buttonType } = this.props;

        return (
            <div>
                {Array.isArray(elements)
                    ? elements &&
                      elements.length > 0 &&
                      elements.map((e, i) => {
                          return <React.Fragment>{this.props.render(e, i)}</React.Fragment>;
                      })
                    : elements &&
                      Object.keys(elements).length > 0 &&
                      Object.keys(elements).map((c, i) => {
                          const element = elements[c];
                          return <React.Fragment>{this.props.render(element, c)}</React.Fragment>;
                      })}
                {buttonType === "card" ? (
                    <Button disabled={disableInputs} className={classes.root} onClick={() => onAdd()}>
                        <Add className={classes.icon} />
                        {addButtonText}
                    </Button>
                ) : (
                    <Button disabled={disableInputs} className={classes.moveIt} color="primary" onClick={() => onAdd()}>
                        <Add fill="#4b94c1" /> {addButtonText}
                    </Button>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(MultiAddInput);
