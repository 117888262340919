import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
/**
 * HOC that Handles whether or not the user is allowed to see the page.
 * @param {array} allowedRoles - user roles that are allowed to see the page.
 * @returns {Component}
 */
export default function Authorization(allowedRoles) {
    return (WrappedComponent) => {
        class WithAuthorization extends Component {
            static propTypes = {
                user: PropTypes.object,
            };
            render() {
                const { user } = this.props;
                if (allowedRoles.includes(user.roles[0].authority)) {
                    return <WrappedComponent {...this.props} />;
                } else {
                    return <h1>No page for you!</h1>;
                }
            }
        }
        return connect((state) => {
            return { user: state.authReducer.payload };
        })(WithAuthorization);
    };
}
