import types from "./types";

const loadUserAccount = (userAccount) => {
    return {
        type: types.LOAD_USER_ACCOUNT,
        userAccount: userAccount,
    };
};

const loadLicenses = (licenses) => {
    return {
        type: types.LOAD_LICENSES,
        licenses: licenses,
    };
};

const loadExhibitAs = (exhibitAs) => {
    return {
        type: types.LOAD_EXHIBIT_AS,
        exhibitAs: exhibitAs,
    };
};

const loadCompany = (company) => {
    return {
        type: types.LOAD_COMPANY,
        company: company,
    };
};

const clearCompany = () => {
    return {
        type: types.CLEAR_COMPANY,
    };
};

const updateDeleteCompanyDialog = (isOpen) => {
    return {
        type: types.UPDATE_DELETE_DIALOG,
        open: isOpen,
    };
};

const loadBroker = (broker) => {
    return {
        type: types.LOAD_BROKER,
        broker: broker,
    };
};

const inputChange = (field, value) => {
    return {
        type: types.INPUT_CHANGE,
        field: field,
        value: value,
    };
};

const addArrayElement = (arrayPath, index) => {
    console.log("addArrayElement in admin actions");
    console.log(arrayPath);
    console.log(index);
    return {
        type: types.ADD_ARRAY_ELEMENT,
        path: arrayPath,
        index: index,
    };
};

const setPropertyArray = (pathToArray, array) => {
    return {
        type: types.SET_PROPERTY_ARRAY,
        pathToArray: pathToArray,
        array: array,
    };
};

const deleteArrayElement = (arrayPath, index) => {
    return {
        type: types.DELETE_ARRAY_ELEMENT,
        path: arrayPath,
        index: index,
    };
};

const loadUsersForTab = (tab, users) => {
    return {
        type: types.LOAD_USERS_FOR_TAB,
        tab: tab,
        users: users,
    };
};

const loadCompaniesForTab = (tab, companies) => {
    return {
        type: types.LOAD_COMPANIES_FOR_TAB,
        tab: tab,
        companies: companies,
    };
};

const switchTab = (tab) => {
    return {
        type: types.SWITCH_TAB,
        tab: tab,
    };
};

const switchCompanyTab = (tab) => {
    return {
        type: types.SWITCH_COMPANY_TAB,
        tab: tab,
    };
};

const filterUsers = (query) => {
    return {
        type: types.FILTER_USERS,
        filterQuery: query,
    };
};

const filterCompanies = (query) => {
    return {
        type: types.FILTER_COMPANIES,
        filterQuery: query,
    };
};

const handleSort = (columnName, sortDirection) => {
    return {
        type: types.SORT_USERS,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

const setUserSearchType = (searchType) => {
    return {
        type: types.SET_USER_SEARCH_TYPE,
        searchType: searchType,
    };
};

const setCompanySearchType = (companySearchType) => {
    return {
        type: types.SET_COMPANY_SEARCH_TYPE,
        companySearchType: companySearchType,
    };
};

const setUserOfficeHistory = (userAccountKey, history) => {
    return {
        type: types.SET_USER_OFFICE_HISTORY,
        userAccountKey: userAccountKey,
        history: history,
    };
};

const approvedCompanyCheckboxClick = (key, newValue) => {
    return {
        type: types.UPDATE_SELECTED_APPROVED_COMPANIES,
        key: key,
        newValue: newValue,
    };
};

const unapprovedCompanyCheckboxClick = (key, newValue) => {
    return {
        type: types.UPDATE_SELECTED_UNAPPROVED_COMPANIES,
        key: key,
        newValue: newValue,
    };
};

const clearCheckboxes = () => {
    return {
        type: types.CLEAR_CHECKBOXES,
    };
};

export default {
    loadUserAccount,
    inputChange,
    addArrayElement,
    setPropertyArray,
    deleteArrayElement,
    loadBroker,
    loadUsersForTab,
    loadCompaniesForTab,
    switchTab,
    switchCompanyTab,
    filterUsers,
    filterCompanies,
    handleSort,
    loadLicenses,
    loadExhibitAs,
    loadCompany,
    setUserSearchType,
    setUserOfficeHistory,
    setCompanySearchType,
    updateDeleteCompanyDialog,
    clearCompany,
    approvedCompanyCheckboxClick,
    unapprovedCompanyCheckboxClick,
    clearCheckboxes,
};
