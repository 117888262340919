import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import createHistory from "history/createBrowserHistory";
import * as listingActionCreators from "./app/js/listing/ducks/actions";
import * as compActionCreators from "./app/js/comp/ducks/actions";
import * as searchActionCreators from "./app/js/search/ducks/actions";
import * as adminActionCreators from "./app/js/admin/ducks/actions";
import * as dealActionCreators from "./app/js/deal/ducks/actions";
import * as propertyActionCreators from "./app/js/property/ducks/actions";
import config from "./config";

import { connectRouter, routerMiddleware } from "connected-react-router";

const actionCreators = [
    listingActionCreators,
    compActionCreators,
    searchActionCreators,
    adminActionCreators,
    dealActionCreators,
    propertyActionCreators,
];

export const history = createHistory();
const rtrMiddleware = routerMiddleware(history);

const composeEnhancers = composeWithDevTools({
    actionCreators,
    trace: true,
    traceLimit: 25,
});

export default function configureStore() {
    return createStore(
        connectRouter(history)(rootReducer),
        config.profile === "local"
            ? composeEnhancers(applyMiddleware(thunk, rtrMiddleware))
            : composeWithDevTools(applyMiddleware(thunk, rtrMiddleware))
    );
}
