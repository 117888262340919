import types from "./types";

export default (
    state = {
        comps: {},
        auditorList: [],
        numComps: 0,
        activeTab: 0,
        activeHeader: "audit_search",
        selectAllFromAuditorList: false,
        selectAllFromAuditorSearch: false,
    },
    action
) => {
    switch (action.type) {
        case types.RECEIVE_APPROVED_COMPS:
            return {
                ...state,
                auditorList: [...action.auditorList],
            };
        case types.TOGGLE_AUDITOR_LIST_AUDIT_FLAG:
            return {
                ...state,
                auditorList: state.auditorList.map((comp) =>
                    comp.dealKey === action.dealKey ? { ...comp, auditFlag: !comp.auditFlag } : comp
                ),
            };
        case types.TOGGLE_ALL_AUDITOR_LIST_CHECKBOX:
            return {
                ...state,
                selectAllFromAuditorList: !action.checked,
            };
        case types.TOGGLE_ALL_AUDITOR_LIST_AUDIT_FLAG:
            return {
                ...state,
                auditorList: state.auditorList.map((comp) => ({ ...comp, auditFlag: action.checked })),
            };
        case types.TOGGLE_ALL_COMPS_AUDIT_FLAGS:
            return {
                ...state,
                selectAllFromAuditorSearch: action.checked,
                comps: state.comps.map((comp) => ({ ...comp, audit_flg: action.checked })),
            };
        case types.TOGGLE_COMP_AUDIT_FLAG:
            return {
                ...state,
                comps: state.comps.map((comp) =>
                    comp.dealkey === action.dealKey ? { ...comp, audit_flg: !comp.audit_flg } : comp
                ),
            };

        case types.CHANGE_TABS:
            return {
                ...state,
                activeTab: action.activeTab,
            };

        case types.CHANGE_HEADER:
            return {
                ...state,
                activeHeader: action.activeHeader,
            };

        case types.RECEIVE_COMPS:
            return Object.assign({}, state, {
                ...state,
                comps: action.comps,
            });

        case types.COUNT_COMPS:
            return Object.assign({}, state, {
                ...state,
                numComps: action.numComps,
            });

        case types.CLEAR_ALL_COMPS:
            return {
                ...state,
                comps: {},
            };

        case types.UPDATE_COMP_ORDER:
            return {
                ...state,
                comps: action.comps,
            };

        case types.UPDATE_AUDITOR_LIST_ORDER:
            return {
                ...state,
                auditorList: action.auditorList,
            };
        default:
            return state;
    }
};
