import React from "react";
import { withStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import get from "lodash/get";

const StatusChip = (props) => {
    const { status, classes } = props;

    const displayStatus = status === "Awaiting Payment" ? "Broker Action Required" : status;
    return (
        <Chip
            classes={classes}
            // label={displayStatus === 'Draft' ? 'In Progress' : displayStatus === 'Outside Draft' ? 'In Progress': displayStatus === 'Outside' ? 'Closed' :  displayStatus === 'Admin Action Required' ? 'CDT Action Required' : displayStatus}
            label={
                get(statusMetadata, `${displayStatus}.displayText`, null) !== null
                    ? get(statusMetadata, `${displayStatus}.displayText`)
                    : displayStatus
            }
            variant="outlined"
            color={get(statusMetadata, `${displayStatus}.line`, "black")}
            style={{
                backgroundColor: get(statusMetadata, `${displayStatus}.fill`, "transparent"),
                borderColor: get(statusMetadata, `${displayStatus}.line`, "primary"),
                color: get(statusMetadata, `${displayStatus}.line`, "primary"),
            }}
        ></Chip>
    );
};

const statusMetadata = {
    Draft: {
        line: "#0772B9", //blue
        fill: "#E4F4FF",
        displayText: "In Progress",
    },
    Outside: {
        line: "#37A057", //green
        fill: "#E2FCEA",
        displayText: "Closed",
    },
    "Outside Draft": {
        line: "#0772B9", //blue
        fill: "#E4F4FF",
        displayText: "In Progress",
    },
    Executed: {
        line: "#0772B9", //blue
        fill: "#E4F4FF",
    },
    "Needs Approval": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    "Needs Review": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    "Update Requested": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    "In Review": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    "Final Review": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    Closed: {
        line: "#37A057", //green
        fill: "#E2FCEA",
    },
    "Portland Review Required": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    "AZ Review Required": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    "Termination Review Required": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    Terminated: {
        line: "#e8254a", //red
        fill: "#ffeaee",
    },
    "Broker Action Required": {
        line: "#e8254a", //red
        fill: "#ffeaee",
    },
    "Awaiting Broker Action": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    "Awaiting Admin Action": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    "Admin Action Required": {
        //CDT Action Required
        line: "#e8254a", //red
        fill: "#ffeaee",
        displayText: "CDT Action Required",
    },
    "Action Required": {
        line: "#e8254a", //red
        fill: "#ffeaee",
    },
    "In Progress": {
        line: "#0772B9", //blue
        fill: "#E4F4FF",
    },
    "Awaiting Payment": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    "Awaiting Comp": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    "Ready For Accounting": {
        line: "#37A057", //green
        fill: "#E2FCEA",
        displayText: "Received By Accounting",
    },
    "Awaiting Installment": {
        line: "#E49B00", //orange
        fill: "#FFFAD4",
    },
    Paid: {
        line: "#37A057", //green
        fill: "#E2FCEA",
    },
};

const styles = (theme) => ({
    root: {
        height: "22px",
        fontSize: "0.7325rem",
    },
    outlined: {
        // borderColor: "#FFBA26",
    },
});

export default withStyles(styles)(StatusChip);
