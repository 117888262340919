import React from "react";
import { Grid, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { get, includes } from "lodash";
import PropertyManagementInformation from "./PropertyManagementInformation";
import MasterPropertyForm from "./MasterPropertyForm";
import ContactInformation from "../contact/ContactContainer";
import AccountingForm from "./AccountingForm";
import ManagementFees from "./ManagementFees";
import BSInput from "../../../common/js/components/BSInput";
import DocumentDrop from "../documents/DocumentDropContainer";
import DocumentPropertyManagementSnapshotContainer from "../documents/DocumentPropertyManagementSnapshotContainer";
import PropertyManagementInformationSnapshot from "./PropertyManagementInformationSnapshot";

const PropertyManagementComponent = (props) => {
    const {
        role,
        snapshotContacts,
        snapshotMediaAssets,
        snapshotPersonnel,
        classes,
        property,
        propertyManagement,
        autocompleteResults,
        searchKMPerson,
        handleInput,
        handleAddArrayElement,
        handleDeleteArrayElement,
        handleUpdateArrayElement,
        prop_management_contact_options,
        handleSearchCompany,
        errors,
        isSnapshot,
        hasPortfolioProperties,
    } = props;

    return (
        <React.Fragment>
            <Grid container spacing={8}>
                <Grid item xs={hasPortfolioProperties ? 10 : 12}>
                    <h3>Master Property Information</h3>
                </Grid>
                {hasPortfolioProperties ? (
                    <Grid item xs={2}>
                        <p className={classes.assetPortfolioHeader}>
                            Linked Properties
                            <br />
                            (by Accounting Software ID)
                        </p>
                    </Grid>
                ) : null}
            </Grid>
            <MasterPropertyForm
                {...props}
                data={propertyManagement}
                disableInputs={!includes(["transition", "dataAdmin", "dataEntry"], role) || isSnapshot}
                handleInput={(field, value) => handleInput("propertyManagement." + field, value)}
                hasPortfolioProperties={hasPortfolioProperties}
                isSnapshot={isSnapshot}
            />

            {isSnapshot ? (
                <PropertyManagementInformationSnapshot snapshotPersonnel={snapshotPersonnel} classes={classes} />
            ) : (
                <PropertyManagementInformation
                    data={propertyManagement}
                    autocompleteResults={autocompleteResults}
                    searchKMPerson={searchKMPerson}
                    handleInput={(field, value) => handleInput("propertyManagement." + field, value)}
                    handleAddArrayElement={(path) => handleAddArrayElement("propertyManagement." + path)}
                    handleDeleteArrayElement={(path, index) =>
                        handleDeleteArrayElement("propertyManagement." + path, index)
                    }
                    handleUpdateArrayElement={(path, index, field, value) =>
                        handleUpdateArrayElement("propertyManagement." + path, index, field, value)
                    }
                    errors={errors}
                    disableInputs={!includes(["transition", "dataAdmin", "dataEntry"], role)}
                />
            )}

            <Divider className={classes.dividerMasterPropertyInformation} />

            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <ContactInformation
                        {...props}
                        handleChange={(field, value, index) =>
                            handleInput(`propertyManagement.contacts[${index}].${field}`, value)
                        }
                        handleAdd={() => handleAddArrayElement("propertyManagement.contacts")}
                        handleDelete={(index) => handleDeleteArrayElement("propertyManagement.contacts", index)}
                        contacts={isSnapshot ? snapshotContacts : get(propertyManagement, "contacts", [])}
                        contactTypeOptions={prop_management_contact_options}
                        addButtonText="Contact Information"
                        includeInvoiceRecipientCheckbox={false}
                        maxContacts={5}
                        displayWhenEmpty={false}
                        companyFieldAvailable={true}
                        errors={{}}
                        handleSearchCompany={handleSearchCompany}
                        disableInputs={!includes(["transition", "dataAdmin", "dataEntry"], role) || isSnapshot}
                        isSnapshot={isSnapshot}
                    />
                </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={8}>
                <Grid item xs>
                    <h3>Accounting</h3>
                </Grid>
            </Grid>

            <AccountingForm
                {...props}
                data={propertyManagement}
                handleInput={(field, value) => handleInput("propertyManagement." + field, value)}
                sideBySide={true}
                errors={errors}
                disableInputs={!includes(["transition", "dataAdmin", "dataEntry"], role) || isSnapshot}
            />

            <Divider />

            <Grid container spacing={8}>
                <Grid item xs>
                    <h3>Management Fees</h3>
                </Grid>
            </Grid>

            <ManagementFees
                {...props}
                data={propertyManagement}
                handleInput={(field, value) => handleInput("propertyManagement." + field, value)}
                sideBySide={true}
                disableInputs={!includes(["transition", "dataAdmin", "dataEntry"], role) || isSnapshot}
                isSnapshot={isSnapshot}
            />

            <Divider className={classes.dividerComments} />

            <Grid container spacing={8}>
                <Grid item xs>
                    <h3>Comments</h3>
                </Grid>
            </Grid>

            <Grid container spacing={8}>
                <BSInput
                    id="comments"
                    placeholder="Enter comments"
                    value={get(propertyManagement, "comments", "")}
                    onChange={(e) => handleInput("propertyManagement.comments", e.target.value)}
                    disabled={!includes(["transition", "dataAdmin", "dataEntry"], role) || isSnapshot}
                    multiline={true}
                    rows={10}
                />
            </Grid>

            <Divider />

            <Grid container spacing={8}>
                <Grid item xs>
                    <h3>Attached Documents</h3>
                </Grid>
            </Grid>
            {isSnapshot ? (
                <DocumentPropertyManagementSnapshotContainer snapshotMediaAssets={snapshotMediaAssets} />
            ) : (
                <DocumentDrop
                    entityKey={get(propertyManagement, "propertyManagementKey", null)}
                    type="PROPERTY_MANAGEMENT"
                    documentTypes="propertyManagement"
                    viewOnly={!includes(["transition", "dataAdmin", "dataEntry"], role) || isSnapshot}
                    parentType={"PROPERTY_MANAGEMENT"}
                    stateCode={get(property, "primaryAddress.city.state.stateCode", null)}
                    noHeader={true}
                    isSnapshot={isSnapshot}
                />
            )}
        </React.Fragment>
    );
};

const styles = {
    dividerComments: {
        marginBottom: "20px",
    },
    dividerMasterPropertyInformation: {
        marginBottom: "10px",
    },
    assetPortfolioHeader: {
        fontSize: "14px",
        fontWeight: "bold",
    },
};
export default withStyles(styles)(PropertyManagementComponent);
