import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MultiAddInput from "../../../common/js/components/MultiAddInput";
import React from "react";
import BSInput from "../../../common/js/components/BSInput";
import PortfolioPropertyDetail from "./PortfolioPropertyDetail";
import { withStyles } from "@material-ui/core/styles";
import ErrorBoundary from "../../../common/js/components/ErrorBoundary";

const styles = (theme) => ({
    switchTitle: {
        fontSize: 14,
        fontWeight: 600,
    },
    theSwitch: {
        marginRight: 0,
    },
});

class PortfolioForm extends React.Component {
    render() {
        const {
            classes,
            data,
            dataPathPrefix,
            disableInputs,
            errors,
            handleAddArrayElement,
            handleDeleteArrayElement,
            handleUpdateArrayElement,
        } = this.props;

        return (
            <div>
                <Grid container alignItems="center" justify="space-between" spacing={8}>
                    <Grid item xs>
                        <span className={classes.switchTitle}>Portfolio Information</span>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.portfolioFlg === true ? true : false}
                                    onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                    color="primary"
                                    value="portfolioFlg"
                                    disabled={disableInputs}
                                    className={classes.theSwitch}
                                />
                            }
                        />
                    </Grid>
                </Grid>
                {data.portfolioFlg && (
                    <div>
                        <Grid container alignItems="center" spacing={8} style={{ height: "90px" }}>
                            <Grid item xs>
                                <BSInput
                                    id="portfolioName"
                                    label="Portfolio"
                                    disabled={disableInputs}
                                    placeholder="Enter name"
                                    error={errors.portfolioName}
                                    value={data.portfolioName}
                                    onChange={(e) => this.props.onChange(e.target.id, e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <ErrorBoundary>
                            <MultiAddInput
                                addButtonText="Add Portfolio Property"
                                disableInputs={disableInputs}
                                displayFirstWhenEmpty={true}
                                elements={data.portfolioProperties}
                                onAdd={() => handleAddArrayElement(dataPathPrefix + ".portfolioProperties")}
                                onUpdate={(e, index) =>
                                    handleUpdateArrayElement(
                                        dataPathPrefix + ".portfolioProperties",
                                        index,
                                        e.target.id,
                                        e.target.value
                                    )
                                }
                                render={(element, elementIndex) => (
                                    <PortfolioPropertyDetail
                                        element={element}
                                        elementIndex={elementIndex}
                                        onDelete={(index) =>
                                            handleDeleteArrayElement(dataPathPrefix + ".portfolioProperties", index)
                                        }
                                        {...this.props}
                                    />
                                )}
                            />
                        </ErrorBoundary>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(PortfolioForm);
