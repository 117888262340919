import React, { Component } from "react";
import { MenuItem, DropdownButton } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getFromAttributeValues } from "../utils/operations";
import MUITooltip from "@material-ui/core/Tooltip";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReportTitleModal from "../listing/ReportTitleModal";
import { Search, ExportIcon, Map, List } from "../../../common/js/icons";
import CompFilters from "./CompFilters";
import OutsideCompModal from "./OutsideCompModal";
import CreateCompModal from "./CreateCompModal";
import BSInput from "../../../common/js/components/BSInput";
import CompsWithMap from "./CompsWithMap";
import CompsWithoutMap from "./CompsWithoutMap";
import SavedSearchesModal from "../search/SavedSearchesModal";
import NameSearchModal from "../search/NameSearchModal";
import ConfidentialCompModal from "./ConfidentialCompModal";
import GenerateImageEditorModalContainer from "./GenerateImageEditorModalContainer";
import "./Comp.css";
import { compact, uniq, get, includes, head, filter } from "lodash/";

const styles = (theme) => ({
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    tabOne: {
        marginRight: "20px",
    },
    connectorActive: {
        "& $connectorLine": {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        "& $connectorLine": {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        "& $connectorLine": {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create("border-color"),
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 800,
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px ",
        },
    },
    menuRequestListing: {
        paddingBottom: "0px",
        "&>a": {
            paddingBottom: "0px !important",
        },
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    lb: {
        textDecoration: "none",
        display: "table-cell",
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    tooltip: {
        "&>a:hover": {
            cursor: "default",
        },
    },
    muiToolTip: {
        marginRight: "100px",
    },
    progressDiv: {
        textAlign: "center",
        width: "9.3em",
        display: "inline-block",
    },
    lvIcon: {
        display: "table-cell",
        verticalAlign: "middle",
        position: "absolute",
        left: "40px",
        top: "8px",
        textAlign: "center",
        fontSize: "12px",
    },
    lvText: {
        textDecoration: "none",
        color: "grey",
    },
    loadingProgress: {
        marginRight: "5px",
        color: "grey",
    },
});

class ReviewComps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numComps: 0,
            selectAll: true,
            selectAllGroup: true,
            selectAllBrokerComps: true,
            reportDropdown: false,
            exportDropdown: false,
            metroReport: false,
            reportStyle: null,
            mapDisplay: true,
            confidentialCompModalOpen: false,
            reportType: null,
            toolTipOpen: false,
        };
    }

    handleSelectAll = () => {
        const checked = this.state.selectAll;
        this.props.handleSelectAll(!checked);
        this.setState({ selectAll: !checked });
    };

    handleSelectAllGroup = () => {
        const checked = this.state.selectAllGroup;
        this.props.handleSelectAllGroup(!checked);
        this.setState({ selectAllGroup: !checked });
    };

    handleSelectAllBrokerComps = () => {
        const checked = this.state.selectAllBrokerComps;
        this.props.handleSelectAllBrokerComps(!checked);
        this.setState({ selectAllBrokerComps: !checked });
    };

    toggleMap = (mapView) => {
        this.setState({ mapDisplay: mapView });
    };

    handleClickOpen = (reportStyle) => {
        if (reportStyle === "Metro") {
            this.setState({ metroReport: true });
        } else {
            this.setState({ metroReport: false });
        }
        this.setState({ reportStyle: reportStyle });
        this.props.toggleReportTitleModal(true);
    };

    getSelectedCompIds = () => {
        const { selectedComps, brokerComps, compTab } = this.props;
        let compIds = [];
        if (compTab === "all_km_comps") {
            for (const [key, value] of selectedComps) {
                if (value) {
                    compIds.push(key);
                }
            }
        } else {
            for (const [key, value] of brokerComps) {
                if (value) {
                    compIds.push(key);
                }
            }
        }
        compIds = compact(compIds);
        compIds = uniq(compIds);
        return compIds;
    };

    getSelectedCompObjects = () => {
        const { compTab, comps } = this.props;
        const selectedCompIds = this.getSelectedCompIds();

        return filter(comps[compTab], (comp) => {
            const dealKey = compTab === "all_km_comps" ? comp.dealkey : comp.dealKey;
            return includes(selectedCompIds, dealKey);
        });
    };

    handleMarketingFlyerModalOpen = (nonIndustrialPropertyInSelection) => {
        const { toggleModalAndGetPhotos } = this.props;
        const comps = this.getSelectedCompObjects();
        toggleModalAndGetPhotos(comps, nonIndustrialPropertyInSelection);
    };

    handleReportDropdown = (open) => {
        this.setState({ reportDropdown: open });
    };

    handleExportDropdown = (open) => {
        this.setState({ exportDropdown: open });
        this.setState({ toolTipOpen: false });
    };

    confidentialComps = (reportType, tab) => {
        if (reportType === "selected") {
            let selected = null;
            if (tab === "broker_comps") {
                selected = this.props.brokerComps;
            } else if (tab === "comps_group") {
                selected = this.props.selectedCompsGroup;
            } else if (tab === "all_km_comps") {
                selected = this.props.selectedComps;
            }
            if (selected !== null) {
                for (const [k, v] of selected.entries()) {
                    if (v === true && this.checkConfidentiality(k, tab)) {
                        return true;
                    }
                }
            }
        } else if (reportType === "all") {
            let comps;
            if (tab === "comps_group") {
                comps = this.props.comps.comps_group;
            } else if (tab === "broker_comps") {
                comps = this.props.comps["broker_comps"];
            } else if (tab === "all_km_comps") {
                comps = this.props.comps["all_km_comps"];
            }

            for (let comp of comps) {
                //get from attribute values
                if (this.checkConfidentiality(comp.dealKey, tab) || this.checkConfidentiality(comp.dealkey, tab)) {
                    return true;
                }
            }
        }

        return false;
    };

    checkConfidentiality = (key, tab) => {
        let comp, compKey, listComps;
        if (tab === "broker_comps") {
            comp = this.props.comps["broker_comps"].find((comp) => comp.dealKey === key);
        } else if (tab === "comps_group") {
            listComps = this.props.comps["comps_group"];
            if (listComps !== null && listComps !== undefined) comp = listComps.find((comp) => comp.dealkey === key);
        } else if (tab === "all_km_comps") {
            compKey = Object.keys(this.props.comps.all_km_comps).find(
                (arrayKey) => this.props.comps.all_km_comps[arrayKey].dealkey === key
            );
            comp = this.props.comps.all_km_comps[compKey];
        }

        if (comp && getFromAttributeValues(comp, ["confidentialFlg", "confidential_flg"], null)) {
            return true;
        } else {
            return false;
        }
    };

    confidentialExportModal = (reportType) => {
        this.setState({
            confidentialCompModalOpen: true,
            reportType: reportType,
        });
    };

    handleConfirmConfidential = () => {
        this.setState({ confidentialCompModalOpen: false });
        this.props.handleDataExport(this.state.reportType, this.props.compTab);
    };

    handleRejectConfidential = () => {
        this.setState({ confidentialCompModalOpen: false });
    };

    render() {
        const {
            classes,
            clearFilters,
            errors,
            filterQuery,
            handleDealTypeRadioClick,
            handleFilter,
            handleSearchSubmit,
            handleSelectAllPropertyType,
            handleSizeRadioClick,
            handleSort,
            handleStatusRadioClick,
            comps,
            compTab,
            numComps,
            propertySearchFilters,
            propertyTypeExpansionPanels,
            role,
            user,
            userProfile,
            searchCriteria,
            searchProperty,
            switchTab,
            togglePropertyTypeExpansionPanel,
            updateFilter,
            addOutsideComp,
            addAppraisalComp,
            toggleMarketFilterExpansionPanel,
            marketFilterExpansionPanels,
            handleSelectMarket,
            handleSelectSubmarket,
            handleSelectMicromarket,
            searchResponse,
            generateCompReport,
            elementsSaving,
            dataExport,
            handleDataExport,
            loadedSearchCrumbs,
            handleDeleteCrumb,
            loadingSearch,
            toggleLoadingSearch,
            mapCrumbCleared,
            handleSearchCleared,
            searchCleared,
            searchPolygon,
            updateSearchPolygon,
            searchRun,
            viewSavedModal,
            nameSaveModal,
            addSelectedToGroup,
            deleteSelectedFromGroup,
            clearCurrentGroup,
            selectedCompsGroup,
            compCurrentGroup,
            handleSearchCompsSubmit,
            modalContent,
            toggleModal,
            timeOnMarketInput,
            setDealParty,
            handleCloseModal,
            setMarketingFlyerPhoto,
            resetPhoto,
        } = this.props;

        const { selectAll, selectAllGroup, selectAllBrokerComps, mapDisplay } = this.state;

        const compz = comps[compTab] ? comps[compTab] : [];
        let disableDeleteFromGroup = true;

        //sets disableDeleteFromGroup to false for Delete from Group button if no comps are selected
        let list = [];
        for (let [key, value] of selectedCompsGroup) {
            if (value && !list.includes(key)) {
                list.push(key);
            }
        }

        if (list.length !== 0) {
            disableDeleteFromGroup = false;
        }

        let selectedList = this.getSelectedCompIds();
        let nonIndustrialPropertyInSelection = false;
        if (selectedList.length === 1 && Array.isArray(comps[compTab])) {
            comps[compTab].forEach((comp) => {
                if (comp.dealKey === selectedList[0] && comp.propertyType !== "Industrial") {
                    nonIndustrialPropertyInSelection = true;
                }
            });
        }

        if (compTab === "all_km_comps" && loadingSearch === true && Object.keys(compz).length !== 0) {
            toggleLoadingSearch(false);
        }

        const handleSearchRunStatus = () => {
            this.setState({ selectAll: true });
        };

        const isBrokerOnDeal = (deal) => {
            const brokerName = userProfile.firstName + " " + userProfile.lastName;

            if (
                (deal.listing_agent_users && includes(deal.listing_agent_users.split(","), user)) ||
                (deal.broker_users && includes(deal.broker_users.split(","), user)) ||
                (deal.audit_created_by && deal.audit_created_by === user) ||
                deal.procuring_broker === brokerName ||
                deal.listing_broker === brokerName
            ) {
                return true;
            } else {
                return false;
            }
        };

        const disclaimerText = `© 2019 - ${new Date().getFullYear()} Kidder Mathews Inc and it’s subsidiaries and/or assigns. The information supplied herein is from sources we deem reliable. It is provided without any representation, warranty, or guarantee, expressed or implied as to its accuracy. Prospective Buyer or Tenant should conduct an independent investigation and verification of all matters deemed to be material, including, but not limited to, statements of income and expenses. Consult your attorney, accountant, or other professional advisor. All information is proprietary to Kidder Mathews and intended for the sole purpose of providing consultancy services to clients and should not be reproduced, distributed or used for any other purpose nor divulged to third party data collection agencies as outlined by corporate policy.`;

        return (
            <div>
                <Collapse in={compTab === "all_km_comps"} unmountOnExit style={{ marginLeft: -20, marginRight: -20 }}>
                    <CompFilters
                        updateFilter={updateFilter}
                        classes={classes}
                        searchSubmit={handleSearchCompsSubmit}
                        propertySearchFilters={propertySearchFilters}
                        handleSelectAllPropertyType={handleSelectAllPropertyType}
                        clearFilters={clearFilters}
                        searchCriteria={searchCriteria}
                        handleStatusRadioClick={handleStatusRadioClick}
                        handleSizeRadioClick={handleSizeRadioClick}
                        handleDealTypeRadioClick={handleDealTypeRadioClick}
                        errors={errors}
                        togglePropertyTypeExpansionPanel={togglePropertyTypeExpansionPanel}
                        propertyTypeExpansionPanels={propertyTypeExpansionPanels}
                        toggleMarketFilterExpansionPanel={toggleMarketFilterExpansionPanel}
                        marketFilterExpansionPanels={marketFilterExpansionPanels}
                        handleSelectMarket={handleSelectMarket}
                        handleSelectSubmarket={handleSelectSubmarket}
                        handleSelectMicromarket={handleSelectMicromarket}
                        handleSearchRunStatus={handleSearchRunStatus}
                        handleSearchCleared={handleSearchCleared}
                        loadedSearchCrumbs={loadedSearchCrumbs}
                        handleDeleteCrumb={handleDeleteCrumb}
                        role={role}
                    />
                </Collapse>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.pageToolbar}
                    spacing={16}
                >
                    <Grid item>
                        <h2 className="page-title">Comps</h2>
                        <div className="page-subtitle">
                            {compTab !== "all_km_comps" &&
                                compTab !== "comps_group" &&
                                `Showing ${numComps} ${numComps === 1 ? "comp" : "comps"} `}

                            {filterQuery > "" && compTab === "broker_comps" && "(Filtered)"}
                            {compTab === "all_km_comps" &&
                                searchResponse &&
                                searchResponse.results &&
                                `Showing ${searchResponse.results.length} ${
                                    searchResponse.results.length === 1
                                        ? "comp"
                                        : `comps (${searchResponse.totalResultCount} TOTAL)`
                                }`}
                            {compTab === "comps_group" &&
                                compCurrentGroup &&
                                `You have ${compCurrentGroup.length} ${
                                    compCurrentGroup.length === 1
                                        ? "comp in your current list"
                                        : `comps in your current list`
                                }`}
                        </div>
                    </Grid>
                    <Grid item className={classes.leftIcon}>
                        {compTab === "all_km_comps" || compTab === "broker_comps" ? (
                            <Button
                                disabled={false}
                                variant="contained"
                                color="primary"
                                onClick={(e) => addSelectedToGroup(compTab)}
                            >
                                Add to List
                            </Button>
                        ) : compTab === "comps_group" ? (
                            <React.Fragment>
                                <Button
                                    disabled={disableDeleteFromGroup}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => deleteSelectedFromGroup()}
                                >
                                    Delete From List
                                </Button>
                                <Button
                                    disabled={disableDeleteFromGroup}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => clearCurrentGroup()}
                                >
                                    Clear List
                                </Button>
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                        {(compTab === "all_km_comps" || compTab === "comps_group" || compTab === "broker_comps") &&
                            (get(dataExport, "exportInProgress", false) === true ? (
                                <Button disabled={true} variant="outlined" color="primary">
                                    <ExportIcon /> In Progress
                                </Button>
                            ) : (
                                <MUITooltip
                                    title={disclaimerText}
                                    placement="left-start"
                                    classes={{ tooltip: classes.muiToolTip }}
                                >
                                    <DropdownButton
                                        disabled={Object.keys(compz).length === 0}
                                        title={"Export"}
                                        onClick={(e) => this.handleExportDropdown(true)}
                                    >
                                        {this.state.exportDropdown && (
                                            <React.Fragment>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        this.confidentialComps("selected", compTab)
                                                            ? this.confidentialExportModal("selected")
                                                            : handleDataExport("selected", compTab)
                                                    }
                                                >
                                                    Selected Comps Only
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        this.confidentialComps("all", compTab)
                                                            ? this.confidentialExportModal("all")
                                                            : handleDataExport("all", compTab)
                                                    }
                                                >
                                                    {compTab === "comps_group" ? "Entire Group" : "Entire Data Set"}
                                                </MenuItem>
                                            </React.Fragment>
                                        )}
                                    </DropdownButton>
                                </MUITooltip>
                            ))}
                        {role !== "anonymous" &&
                            (compTab === "all_km_comps" || compTab === "comps_group" || compTab === "broker_comps") &&
                            (elementsSaving.generateCompReport ? (
                                <Button disabled={true} variant="contained" color="secondary">
                                    <CircularProgress className={classes.loadingProgress} size={15} />
                                    Generating
                                </Button>
                            ) : (
                                <MUITooltip
                                    title={disclaimerText}
                                    placement="left-start"
                                    classes={{ tooltip: classes.muiToolTip }}
                                >
                                    <DropdownButton
                                        disabled={Object.keys(compz).length === 0}
                                        title={"Generate Report"}
                                        onClick={(e) => this.handleReportDropdown(true)}
                                        pullRight
                                    >
                                        {this.state.reportDropdown && (
                                            <React.Fragment>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => this.handleClickOpen("Standard")}
                                                >
                                                    Standard
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => this.handleClickOpen("Metro")}
                                                >
                                                    Metro Lease
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => this.handleClickOpen("MapOverview")}
                                                >
                                                    Map Overview
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => this.handleClickOpen("HalfMap-HalfProperty")}
                                                >
                                                    Half Map / Half Property List
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => this.handleClickOpen("IndividualDetail")}
                                                >
                                                    Individual Comp Page Detail
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() => this.handleClickOpen("Reference")}
                                                >
                                                    Property Reference
                                                </MenuItem>
                                                <MenuItem
                                                    className={classes.menuSubItem}
                                                    onClick={() =>
                                                        this.handleMarketingFlyerModalOpen(
                                                            nonIndustrialPropertyInSelection
                                                        )
                                                    }
                                                >
                                                    Marketing Flyer
                                                </MenuItem>
                                            </React.Fragment>
                                        )}
                                    </DropdownButton>
                                </MUITooltip>
                            ))}
                        {role !== "anonymous" && compTab !== "comps_group" && (
                            <OutsideCompModal
                                {...this.props}
                                searchFunction={searchProperty}
                                addOutsideComp={addOutsideComp}
                            />
                        )}
                        {role === "appraiser" && (
                            <CreateCompModal
                                {...this.props}
                                searchFunction={searchProperty}
                                createComp={addAppraisalComp}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={16} style={{ maxWidth: "100%" }}>
                    <Grid item xs>
                        <div className="simPaper">
                            <Grid container justify="space-between" alignItems="center" spacing={0}>
                                <Grid item>
                                    <Tabs
                                        classes={classes}
                                        value={compTab}
                                        onChange={(event, value) => switchTab(event, value, user)}
                                    >
                                        {includes(
                                            [
                                                "broker",
                                                "evp",
                                                "portlandBrokerAdmin",
                                                "arizonaBrokerAdmin",
                                                "general",
                                                "officeManager",
                                                "appraiser",
                                                "research",
                                                "researchAdmin",
                                                "propertyManagement",
                                                "transition",
                                                "accounting",
                                                "dataEntry",
                                                "dataAdmin",
                                                "debtEquity",
                                            ],
                                            role
                                        ) && <Tab classes={classes.tabOne} value="broker_comps" label="My Comps" />}
                                        <Tab classes={classes} value="all_km_comps" label="All Comps" />
                                        <Tab
                                            classes={classes}
                                            value="comps_group"
                                            label={`Current List (${compCurrentGroup ? compCurrentGroup.length : "0"})`}
                                        />
                                    </Tabs>
                                </Grid>
                                {compTab === "broker_comps" && (
                                    <Grid item style={{ marginBottom: "-40px" }}>
                                        <BSInput
                                            value={filterQuery}
                                            placeholder="Search Comps"
                                            startAdornment={<Search />}
                                            onChange={(event) => {
                                                handleFilter(event.target.value);
                                            }}
                                        />
                                    </Grid>
                                )}
                                {/* Map icons */}
                                {(compTab === "all_km_comps" || compTab === "comps_group") && (
                                    <Grid item>
                                        <Grid container>
                                            {mapDisplay ? (
                                                <Grid item style={{}}>
                                                    <Button
                                                        style={{ minWidth: "40px" }}
                                                        onClick={() => this.toggleMap(false)}
                                                    >
                                                        <List />
                                                    </Button>
                                                </Grid>
                                            ) : (
                                                <Grid style={{}}>
                                                    <Button
                                                        style={{ minWidth: "40px" }}
                                                        onClick={() => this.toggleMap(true)}
                                                    >
                                                        <Map />
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                            {(compTab === "all_km_comps" || compTab === "comps_group") && mapDisplay ? (
                                <CompsWithMap
                                    {...this.props}
                                    places={compz}
                                    selectAll={selectAll}
                                    selectAllGroup={selectAllGroup}
                                    searchRun={searchRun}
                                    searchCleared={compTab === "comps_group" ? false : searchCleared}
                                    handleSelectAll={this.handleSelectAll}
                                    handleSelectAllGroup={this.handleSelectAllGroup}
                                    handleSearchCleared={handleSearchCleared}
                                    handleSearchSubmit={handleSearchSubmit}
                                    mapCrumbCleared={mapCrumbCleared}
                                    searchPolygon={searchPolygon}
                                    updateSearchPolygon={updateSearchPolygon}
                                    searchResponse={searchResponse}
                                    isBrokerOnDeal={isBrokerOnDeal}
                                />
                            ) : (
                                <CompsWithoutMap
                                    {...this.props}
                                    selectAll={selectAll}
                                    selectAllGroup={selectAllGroup}
                                    selectAllBrokerComps={selectAllBrokerComps}
                                    handleSelectAll={this.handleSelectAll}
                                    handleSelectAllGroup={this.handleSelectAllGroup}
                                    handleSelectAllBrokerComps={this.handleSelectAllBrokerComps}
                                    filterQuery={filterQuery}
                                    isBrokerOnDeal={isBrokerOnDeal}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.closeDealDialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="close-deal"
                    aria-describedby="close-deal-dialog"
                >
                    <DialogTitle id="close-deal">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Closing this deal will start the invoice process for this transaction and move the comp to a
                            closed status.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" variant="outlined">
                            Disagree
                        </Button>
                        <Button onClick={this.handleClose} color="secondary" autoFocus variant="contained">
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
                <ReportTitleModal
                    {...this.props}
                    reportType="Comp"
                    selectedComps={selectedList}
                    nonIndustrialPropertyInSelection={nonIndustrialPropertyInSelection}
                    tab={compTab}
                    metroReport={this.state.metroReport}
                    reportStyle={this.state.reportStyle}
                    hasConfidentialComps={this.confidentialComps("selected", compTab)}
                />
                <SavedSearchesModal
                    {...this.props}
                    handleGridSort={handleSort}
                    tab={compTab}
                    searchType="comps"
                    showModal={viewSavedModal}
                />
                <NameSearchModal {...this.props} searchType="comps" tab={compTab} showModal={nameSaveModal} />
                <ConfidentialCompModal
                    {...this.props}
                    confidentialCompModalOpen={this.state.confidentialCompModalOpen}
                    handleConfirm={this.handleConfirmConfidential}
                    handleReject={this.handleRejectConfidential}
                />
                <GenerateImageEditorModalContainer
                    reportName={"Marketing Flyer Comp"}
                    nonIndustrialPropertyInSelection={nonIndustrialPropertyInSelection}
                    editorDimensions={{ width: "1224px", height: "440px" }}
                    setDealParty={setDealParty}
                    timeOnMarketInput={timeOnMarketInput}
                    toggleModal={toggleModal}
                    elementsSaving={elementsSaving}
                    modalContent={modalContent}
                    handleCloseModal={handleCloseModal}
                    setMarketingFlyerPhoto={setMarketingFlyerPhoto}
                    resetPhoto={resetPhoto}
                    selectedCompId={head(this.getSelectedCompIds())}
                    generateCompReport={generateCompReport}
                    compTab={compTab}
                />
            </div>
        );
    }
}

export default withStyles(styles)(ReviewComps);
