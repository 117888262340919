import React, { Component } from "react";
import { connect } from "react-redux";
import AddContact from "./AddContact";
import AddContactToolbar from "./AddContactToolbar";
import operations from "./ducks/operations";
import actions from "./ducks/actions";
import get from "lodash/get";
import searchOperations from "../search/ducks/operations";
import ReactGA from "react-ga4";

class AddContactContainer extends Component {
    componentDidMount() {
        if (!(get(this.props, "location.state.newContact") === true)) {
            const { id } = this.props.match.params;
            if (id) {
                this.props.getContact(id);
            }
            ReactGA.send({ hitType: "pageview", page: "/contacts/*", title: `Edit Contact: ${id}` });
        } else {
            ReactGA.send({ hitType: "pageview", page: "/contacts/create", title: "Create New Contact" });
        }
    }

    componentWillUnmount() {
        this.props.flushContactState();
    }

    render() {
        if (
            get(this.props, "auth.userProfile.user") === get(this.props, "contact.owner.username") ||
            get(this.props, "location.state.newContact")
        ) {
            return (
                <React.Fragment>
                    <AddContactToolbar {...this.props} newContact={get(this.props, "location.state.newContact")} />
                    <AddContact {...this.props} newContact={get(this.props, "location.state.newContact")} />
                </React.Fragment>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        errors: state.utilsReducer.errors.contact,
        contacts: state.crmReducer.contacts,
        contact: state.crmReducer.contact,
        reminders: state.crmReducer.contact.reminders,
        remindersActive: state.crmReducer.contact.remindersActiveFlg,
        autocompleteResults: state.searchReducer.autocompleteResults,
        elementsSaving: state.utilsReducer.elementsSaving,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getContact: (contactId) => {
            dispatch(operations.getContact(contactId));
        },
        handleInput: (field, value) => {
            dispatch(actions.handleInput(field, value));
        },
        addArrayElement: (path, obj) => {
            dispatch(operations.addArrayElement(path, obj));
        },
        deleteArrayElement: (path, index) => {
            dispatch(operations.deleteArrayElement(path, index));
        },
        updateArrayElement: (path, index, field, value) => {
            dispatch(actions.handleInput(path + "[" + index + "]." + field, value));
        },
        handleSearchCompany: (searchTerm, companyType) => {
            dispatch(searchOperations.searchCompany(searchTerm, companyType));
        },
        createContact: () => {
            ReactGA.event("New Contact Created");
            dispatch(operations.createContact());
        },
        updateContact: () => {
            ReactGA.event("Existing Contact Updated");
            dispatch(operations.updateContact());
        },
        cancelChanges: () => {
            dispatch(operations.cancelChanges());
        },
        flushContactState: () => {
            dispatch(actions.handleInput("contact", {}));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContactContainer);
