import React from "react";
import { connect } from "react-redux";
import ReviewComps from "./ReviewComps";
import operations from "./ducks/operations";
import searchOperations from "../search/ducks/operations";
import searchActions from "../search/ducks/actions";
import listingOperations from "../listing/ducks/operations";
import constants from "./constants";
import includes from "lodash/includes";
import get from "lodash/get";
import searchConstants from "../search/constants";
import toUpper from "lodash/toUpper";
import ReactGA from "react-ga4";

class ReviewCompsContainer extends React.Component {
    componentDidMount() {
        this.props.switchTab(null, this.props.compTab);
        this.props.getComps(this.props.compTab);
        ReactGA.send({ hitType: "pageview", page: "/comps", title: "Comps" });
    }

    componentWillUnmount() {
        this.props.handleOnSearchPage(false);
        this.props.handleOnGroupPage(false);
    }

    render() {
        return includes(
            [
                "accounting",
                "appraiser",
                "broker",
                "dataAdmin",
                "dataEntry",
                "debtEquity",
                "evp",
                "general",
                "officeManager",
                "research",
                "researchAdmin",
                "portlandBrokerAdmin",
                "arizonaBrokerAdmin",
                "propertyManagement",
                "transition",
            ],
            this.props.role
        ) ? (
            <ReviewComps {...this.props} />
        ) : null;
    }
}

const mapStateToProps = (state) => {
    return {
        role: state.authReducer.userProfile.role,
        user: state.authReducer.userProfile.user,
        userProfile: state.authReducer.userProfile,
        comps: state.compReducer.comps,
        valid: state.authReducer.validFormValues,
        compTab: state.compReducer.compTab
            ? state.compReducer.compTab
            : constants.roles[state.authReducer.userProfile.role]["initialTab"],
        columnToSort: state.searchReducer.columnToSort,
        sortDirection: state.searchReducer.sortDirection,
        filterQuery: state.searchReducer.filterQuery,
        selected: state.compReducer.selected,
        selectedComps: state.compReducer.selectedComps,
        brokerComps: state.compReducer.brokerComps,
        isLoading: state.utilsReducer.isLoading,
        loadingSearch: state.searchReducer.loadingSearch,
        isSearching: state.utilsReducer.isSaving,
        numComps: state.compReducer.numComps,
        searchCleared: state.searchReducer.COMPS.searchCleared,
        searchRun: state.searchReducer.COMPS.searchRun,
        searchResults: state.searchReducer.searchResults,
        searchResponse: state.searchReducer.COMPS.searchResponse,
        searchCrumbs: state.searchReducer.searchCrumbs.COMPS,
        mapSearchSettings: state.searchReducer.COMPS.mapSearchSettings,
        loadedSearchCrumbs: state.searchReducer.loadedSearchCrumbs.COMPS,
        mapCrumbCleared: state.searchReducer.COMPS.mapCrumbCleared,
        searchPolygon: state.searchReducer.COMPS.polygon,
        savedMapCoords: state.searchReducer.COMPS.savedMapCoords,
        autocompleteResults: state.searchReducer.autocompleteResults,
        errors: state.utilsReducer.errors,
        panelsExpanded: state.compReducer.panelsExpanded,
        numComments: state.commentsReducer.comments.length,
        propertySearchFilters: state.propertyReducer.propertySearchFilters,
        searchCriteria: state.searchReducer.COMPS.searchCriteria,
        propertyTypeExpansionPanels: state.propertyReducer.propertyTypeExpansionPanels,
        marketFilterExpansionPanels: state.propertyReducer.marketFilterExpansionPanels,
        modalData: state.compReducer.modalData,
        elementsSaving: state.utilsReducer.elementsSaving,
        reportTitleModal: state.listingReducer.reportTitleModal,
        brokerInfoReport: state.listingReducer.brokerInfoReport,
        dataExport: get(state.searchReducer, searchConstants.COMP_SEARCH_TYPE + ".dataExport", {}),
        viewSavedModal: state.searchReducer.viewSavedModal,
        savedSearchData: state.searchReducer.COMPS.savedSearches,
        savedGroupData: state.searchReducer.COMPS.savedGroups,
        nameSaveModal: state.searchReducer.nameSaveModal,
        savedSearchName: state.searchReducer.savedSearchName,
        saveSelectedOnly: state.searchReducer.COMPS.retainSelectedFromSave,
        selectedCompsGroup: state.compReducer.selectedCompsGroup,
        compCurrentGroup: state.searchReducer.COMPS.currentGroup,
        requestModal: state.listingReducer.requestModal,
        modalContent: state.compReducer.modalContent,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchTab: (event, value) => {
            if (value === "comps_group") {
                dispatch(searchOperations.runUpdateCurrentGroup("COMPS"));
            }
            dispatch(operations.switchTab(value));
        },
        handleCompInput: (path, value) => {
            dispatch(operations.handleCompInput(path, value));
        },
        handleSort: (columnName, tab) => {
            dispatch(searchOperations.sortGridTableData(columnName, tab, "COMPS"));
        },
        handleFilter: (query) => {
            dispatch(searchOperations.filterTableData(query));
        },
        getComps: (tab) => {
            dispatch(operations.getComps(tab));
        },
        searchProperty: (term) => {
            dispatch(searchOperations.searchPropertyAutocomplete(term));
        },
        selectProperty: (property) => {
            dispatch(operations.selectPropertyFromAutocomplete(property));
        },
        removeSelectedProperty: () => {
            dispatch(operations.removeSelectedPropertyFromRequestModal());
        },
        handleStatusRadioClick: (field, checked) => {
            dispatch(searchOperations.handleStatusRadioClick(field, checked, "COMPS"));
        },
        handleSizeRadioClick: (field, checked) => {
            dispatch(searchOperations.handleSizeRadioClick(field, checked, "COMPS"));
        },
        handleDealTypeRadioClick: (field, checked) => {
            dispatch(searchOperations.handleDealTypeRadioClick(field, checked, "COMPS"));
        },
        clearSearchFilters: () => {
            dispatch(searchOperations.clearSearchFilters("COMPS"));
        },
        updateFilter: (field, value) => {
            dispatch(searchOperations.updateFilter(field, value, "COMPS"));
        },
        handleSelectAllPropertyType: (field, value) => {
            dispatch(searchOperations.selectAllPropertyType(field, value, "COMPS"));
        },
        handleSearchCleared: (cleared) => {
            dispatch(searchOperations.storeSearchCleared(cleared, "COMPS"));
            dispatch(operations.clearSearchResults());
        },
        handleSearchRun: (run) => {
            dispatch(searchOperations.storeSearchRun(run, "COMPS"));
        },
        clearFilters: () => {
            dispatch(searchOperations.clearFilters("COMPS"));
        },
        togglePropertyTypeExpansionPanel: (panelName) => {
            dispatch(searchOperations.togglePropertyTypeExpansionPanel(panelName));
        },
        addOutsideComp: (transactionType, propertyType, newProperty) => {
            dispatch(operations.addOutsideComp(transactionType, propertyType, newProperty, "Outside Draft"));
        },
        addAppraisalComp: (transactionType, propertyType, newProperty) => {
            dispatch(operations.addOutsideComp(transactionType, propertyType, newProperty, "Appraisal Draft"));
        },
        handleSearchCompsSubmit: () => {
            dispatch(operations.searchComps());
        },
        updateSearchTerm: (value, searchType) => {
            dispatch(searchOperations.updateFilter("searchTerm", value, searchType));
        },
        handleSearchSubmit: () => {
            dispatch(searchOperations.executeSearch("property_modal"));
            dispatch(searchOperations.storeSearchRun(true, toUpper("property_modal")));
        },
        toggleRequestModal: (spaceType, transactionType) => {
            dispatch(listingOperations.toggleRequestModal(spaceType, transactionType));
        },
        toggleMarketFilterExpansionPanel: (panelName) => {
            dispatch(searchOperations.toggleMarketFilterExpansionPanel(panelName));
        },
        handleSelectMarket: (field, value) => {
            dispatch(searchOperations.updateMarketFilter(field, value, "COMPS"));
        },
        handleSelectSubmarket: (market, submarket, value) => {
            dispatch(searchOperations.updateSubmarketFilter(market, submarket, value, "COMPS"));
        },
        handleSelectMicromarket: (market, submarket, micromarket, value) => {
            dispatch(searchOperations.updateMicromarketFilter(market, submarket, micromarket, value, "COMPS"));
        },
        handleCheckboxClick: (key, type) => {
            dispatch(operations.checkboxClick(key, type));
        },
        handleSelectAll: (value) => {
            dispatch(operations.selectAll(value));
        },
        handleSelectAllGroup: (value) => {
            dispatch(operations.selectAllGroup(value));
        },
        handleSelectAllBrokerComps: (value) => {
            console.log("handle select all broker comps in review comps container + value :  " + value);
            dispatch(operations.selectAllBrokerComps(value));
        },
        generateCompMetroReport: (tab, includeBrokerInfo, reportTitle, propType) => {
            dispatch(operations.generateCompMetroReport(tab, includeBrokerInfo, reportTitle, propType));
        },
        toggleReportTitleModal: (open) => {
            dispatch(listingOperations.toggleReportTitleModal(open));
        },
        toggleIncludeBrokerInfo: (include) => {
            dispatch(listingOperations.toggleIncludeBrokerInfo(include));
        },
        setReportTitle: (title) => {
            dispatch(listingOperations.setReportTitle(title));
        },
        clearExport: () => {
            dispatch(searchOperations.clearDataExport(searchConstants.COMP_SEARCH_TYPE));
        },
        handleDataExport: (dataSetType, tab) => {
            dispatch(searchOperations.handleDataExport(searchConstants.COMP_SEARCH_TYPE, dataSetType, tab));
        },
        handleDragEnd: (results, comps, tab) => {
            dispatch(operations.handleDragEnd(results, comps, tab));
        },
        handleDeleteCrumb: (criteria, value) => {
            dispatch(searchOperations.deleteCrumb(criteria, value, "COMPS"));
        },
        handleClearAllCrumbs: () => {
            dispatch(searchOperations.clearCrumbsState());
        },
        toggleLoadingSearch: (loading) => {
            dispatch(searchActions.loadingSearch(loading));
        },
        updateSearchAreaFilter: (latLngs) => {
            dispatch(searchOperations.updateSearchAreaFilter(latLngs, searchConstants.COMP_SEARCH_TYPE));
            dispatch(operations.searchComps());
        },
        updateSearchPolygon: (polygon) => {
            dispatch(searchOperations.updateSearchPolygon(polygon, "COMPS"));
        },
        saveMapSearchSettings: (settings) => {
            dispatch(searchOperations.saveMapSearchSettings(settings, "COMPS"));
        },
        handleOnSearchPage: (onSearch) => {
            dispatch(searchActions.onSearchPage(onSearch));
        },
        handleOnGroupPage: (onGroup) => {
            dispatch(searchActions.onGroupPage(onGroup));
        },
        toggleSavedSearchesModal: (open) => {
            dispatch(searchOperations.toggleSavedSearchesModal(open));
        },
        handleDeleteSavedSearch: (savedSearchId) => {
            dispatch(searchOperations.deleteSavedSearch("COMPS", savedSearchId));
        },
        toggleNameSearchModal: (open) => {
            dispatch(searchOperations.toggleNameSearchModal(open));
        },
        setSearchName: (name) => {
            dispatch(searchOperations.setSearchName(name));
        },
        setSearchSelectedToggle: (checked) => {
            dispatch(searchOperations.setSearchSelectedToggle(checked, "COMPS"));
        },
        handleSaveSearch: () => {
            dispatch(searchOperations.handleSaveSearch("COMPS"));
        },
        runSavedSearch: (searchKey) => {
            dispatch(searchOperations.runSavedSearch(searchKey, "COMPS"));
        },
        addSelectedToGroup: (tabName) => {
            dispatch(searchOperations.addSelectedToGroup("COMPS", tabName));
        },
        deleteSelectedFromGroup: () => {
            dispatch(searchOperations.deleteSelectedFromGroup("COMPS"));
        },
        clearCurrentGroup: () => {
            dispatch(searchOperations.clearCurrentGroup("COMPS"));
        },
        handleSaveGroup: () => {
            dispatch(searchOperations.handleSaveGroup("COMPS"));
        },
        runSavedGroup: (groupKey, groupOrder) => {
            dispatch(searchOperations.runSavedGroup(groupKey, "COMPS", groupOrder));
        },
        handleDeleteSavedGroup: (savedGroupId) => {
            dispatch(searchOperations.deleteSavedGroup(savedGroupId, "COMPS"));
        },
        toggleModal: (isOpen) => {
            dispatch(operations.toggleModal(isOpen));
        },
        handleCloseModal: () => {
            dispatch(operations.toggleModal(false));
            dispatch(operations.setDealParty(""));
            dispatch(operations.timeOnMarketInput(""));
        },
        toggleModalAndGetPhotos: (owners, nonIndustrialPropertyInSelection) => {
            dispatch(operations.toggleModalAndGetPhotos(owners, nonIndustrialPropertyInSelection));
        },
        setDealParty: (dealParty) => {
            dispatch(operations.setDealParty(dealParty));
        },
        timeOnMarketInput: (input) => {
            dispatch(operations.timeOnMarketInput(input));
        },
        generateCompReport: (
            tab,
            includeBrokerInfo,
            reportTitle,
            reportStyle,
            mapView,
            dealParty = null,
            timeOnMarket = null,
            croppedPhotoUrl
        ) => {
            dispatch(
                operations.generateCompReport(
                    tab,
                    includeBrokerInfo,
                    reportTitle,
                    reportStyle,
                    mapView,
                    dealParty,
                    timeOnMarket,
                    croppedPhotoUrl
                )
            );
        },
        setMarketingFlyerPhoto: (photo) => {
            dispatch(operations.setMarketingFlyerPhoto(photo));
        },
        resetPhoto: () => {
            dispatch(operations.resetPhoto());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCompsContainer);
