export const regions = [
    {
        value: "Pacific Northwest",
        label: "Pacific Northwest",
    },
    {
        value: "NorCal",
        label: "NorCal",
    },
    {
        value: "SoCal",
        label: "SoCal",
    },
    {
        value: "Southwest",
        label: "Southwest",
    },
];
