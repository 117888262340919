import React from "react";
import { connect } from "react-redux";
import PropertyUpdateModal from "./PropertyUpdateModal";
import operations from "./ducks/operations";
import searchOperations from "../search/ducks/operations";
import listingOperations from "../listing/ducks/operations";
import compOperations from "../comp/ducks/operations";
import get from "lodash/get";
import voucherOperations from "../voucher/ducks/operations";

class PropertyUpdateModalContainer extends React.Component {
    render() {
        return <PropertyUpdateModal {...this.props} autocompleteResults={this.props.autocompleteResults} />;
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.utilsReducer.isLoading,
        searchResults: state.searchReducer.autocompleteResults,
        autocompleteResults: state.searchReducer.autocompleteResults,
        displayUpdateDialog: state.propertyReducer.displayUpdateDialog,
        propertySearchModalStatus: state.propertyReducer.propertySearchModal,
        listingPropertyModal: state.listingReducer.requestModal,
        listing: get(state.listingReducer, "listing", {}),
        dealKey: get(state.dealReducer, "deal.dealKey", {}),
        user: get(state.authReducer, "userProfile.user", {}),
        errors: state.utilsReducer.errors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchFunction: (term) => {
            dispatch(searchOperations.searchPropertyAutocomplete(term));
        },
        togglePropertySearchModal: (isOpen) => {
            dispatch(operations.togglePropertySearchModal(isOpen));
        },
        selectProperty: (property) => {
            dispatch(listingOperations.selectPropertyFromAutocomplete(property));
        },
        removeSelectedProperty: () => {
            dispatch(listingOperations.removeSelectedPropertyFromRequestModal());
        },
        updatePropertyForListing: (listingKey, propertyKey, user) => {
            dispatch(listingOperations.updatePropertyForListing(listingKey, propertyKey, user));
        },
        updatePropertyForComp: (listingKey, propertyKey, user) => {
            dispatch(compOperations.updatePropertyForComp(listingKey, propertyKey, user));
        },
        handleChooseDisplayUpdateDialog: (isOpen) => {
            dispatch(operations.toggleUpdateDialog(isOpen));
        },
        updatePropertyForConsulting: (dealKey, propertyKey, user) => {
            dispatch(voucherOperations.updatePropertyForConsulting(dealKey, propertyKey, user));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyUpdateModalContainer);
