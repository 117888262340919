import types from "./types";

const loadContactsForUser = (contacts) => {
    return {
        type: types.LOAD_CONTACTS_FOR_USER,
        contacts: contacts,
    };
};

const loadContact = (contact) => {
    return {
        type: types.LOAD_CONTACT,
        contact: contact,
    };
};

const handleInput = (field, value) => {
    return {
        type: types.ADD_CRM_CONTACT_INPUT,
        field: field,
        value: value,
    };
};

const setCrmArray = (pathToArray, array) => {
    return {
        type: types.SET_CRM_ARRAY,
        pathToArray: pathToArray,
        array: array,
    };
};

const filter = (query) => {
    return {
        type: types.FILTER_CRM_CONTACTS,
        filterQuery: query,
    };
};

const sort = (columnName, sortDirection) => {
    return {
        type: types.SORT_CRM_CONTACTS,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

export default {
    loadContactsForUser,
    loadContact,
    handleInput,
    setCrmArray,
    filter,
    sort,
};
