import React, { Component } from "react";
import schema, { buttonActions } from "./reviewListingsTables";
import { Link } from "react-router-dom";
import { ExportIcon, List, ListingCircle, Map, Search, Sorty } from "../../../common/js/icons";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { buildAddress, toDollarAmount, toSqft } from "../utils/operations";
import { withStyles } from "@material-ui/core/styles";
import BSInput from "../../../common/js/components/BSInput";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import ListingFilters from "./ListingFilters";
import RequestModalContainer from "../listing/RequestModalContainer";
import orderBy from "lodash/orderBy";
import includes from "lodash/includes";
import StatusChip from "../../../common/js/components/StatusChip";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import ReportTitleModal from "./ReportTitleModal";
import "./Listings.css";
import SavedSearchesModal from "../search/SavedSearchesModal";
import NameSearchModal from "../search/NameSearchModal";
import ListingsWithMap from "./ListingsWithMap";
import ListingsWithoutMap from "./ListingsWithoutMap";
import MUITooltip from "@material-ui/core/Tooltip";
import ListingImportModal from "./ListingImportModal";
import Snackbar from "../../../common/js/components/KMSnackbar";
import ReactGA from "react-ga4";

const styles = (theme) => ({
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    tabOne: {
        marginRight: "20px",
    },
    connectorActive: {
        "& $connectorLine": {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        "& $connectorLine": {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        "& $connectorLine": {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create("border-color"),
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 800,
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
    menuRequestListing: {
        paddingBottom: "0px",
        "&>a": {
            paddingBottom: "0px !important",
        },
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    lb: {
        textDecoration: "none",
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    table: {
        minWidth: "100%",
    },
    toplessInput: {
        marginTop: 0,
    },
    progressDiv: {
        textAlign: "center",
        width: "9.3em",
        display: "inline-block",
    },
    listingNumber: {
        fontSize: "14px",
        margin: "0",
        borderColor: "#f1f1f1",
        color: "#2C99D2",
        fontWeight: 600,
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
    loadingProgress: {
        marginRight: "5px",
        color: "grey",
    },
    muiToolTip: {
        marginRight: "100px",
    },
});

class BrokerListings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numListings: 0,
            closeDealDialogOpen: false,
            listingToClose: {},
            selectAll: true,
            selectAllGroup: true,
            selectAllActive: true,
            mapDisplay: true,
            reportStyle: null,
            reportDropdown: false,
            exportDropdown: false,
            showModal: false,
        };
    }

    handleShowModal = (spaceType, transactionType) => {
        this.setState({ showModal: !this.state.showModal });
        this.props.toggleRequestModal(spaceType, transactionType);
    };

    handleSelectAll = () => {
        const checked = this.state.selectAll;
        this.props.handleSelectAll(!checked);
        this.setState({ selectAll: !checked });
    };

    handleClickOpen = (listing) => {
        this.setState({ closeDealDialogOpen: true, listingToClose: listing });
    };

    handleCloseAndStart = () => {
        this.props.closeDeal(this.state.listingToClose);
        this.setState({ closeDealDialogOpen: false, listingToClose: {} });
    };

    handleClose = () => {
        this.setState({ closeDealDialogOpen: false, listingToClose: {} });
    };

    toggleMap = (mapView) => {
        this.setState({ mapDisplay: mapView });
    };

    handleSelectReport = (reportStyle) => {
        this.setState({ reportStyle: reportStyle });
        this.props.toggleReportTitleModal(true);
    };

    handleReportDropdown = (open) => {
        this.setState({ reportDropdown: open });
    };

    handleExportDropdown = (open) => {
        this.setState({ exportDropdown: open });
    };

    handleSelectAllGroup = () => {
        const checked = this.state.selectAllGroup;
        this.props.handleSelectAllGroup(!checked);
        this.setState({ selectAllGroup: !checked });
    };

    handleSelectAllActive = () => {
        const checked = this.state.selectAllActive;
        this.props.selectAllActiveListings(!checked);
        this.setState({
            selectAllActive: !checked,
        });
    };

    handleOpenModal = () => {
        this.setState({ modalOpen: true });
    };

    handleCloseModal = () => {
        this.setState({ modalOpen: false });
    };

    handleImportResponse = (result) => {
        if (result === true) {
            this.setState({
                snackbarMessage: "Success: Your file will now be processed.",
                snackbarVariant: "success",
                snackbarOpen: true,
            });
        } else if (result === false) {
            this.setState({
                snackbarMessage: "Failed: Columns must match Standard Import Template.",
                snackbarVariant: "error",
                snackbarOpen: true,
            });
        }
    };

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: "/listings", title: "Broker Listings" });
    }

    render() {
        const {
            classes,
            clearFilters,
            columnToSort,
            errors,
            filterQuery,
            handleListingTypeRadioClick,
            handleFilter,
            handleListingsSearchSubmit,
            handleSelectAllPropertyType,
            handleSizeRadioClick,
            handleSort,
            handleStatusRadioClick,
            isLoading,
            listings,
            selectedListings,
            listingTab,
            numListings,
            propertySearchFilters,
            propertyTypeExpansionPanels,
            role,
            searchCriteria,
            sortDirection,
            switchTab,
            togglePropertyTypeExpansionPanel,
            updateFilter,
            searchResponse,
            toggleMarketFilterExpansionPanel,
            marketFilterExpansionPanels,
            handleSelectMarket,
            handleSelectSubmarket,
            handleSelectMicromarket,
            elementsSaving,
            dataExport,
            handleDataExport,
            loadedSearchCrumbs,
            handleDeleteCrumb,
            mapCrumbCleared,
            searchCleared,
            handleSearchCleared,
            searchPolygon,
            updateSearchPolygon,
            searchRun,
            viewSavedModal,
            nameSaveModal,
            addSelectedToGroup,
            deleteSelectedFromGroup,
            clearCurrentGroup,
            selectedListingsGroup,
            listingCurrentGroup,
            activeSelectedListings,
        } = this.props;

        const { selectAll, selectAllGroup, mapDisplay, selectAllActive } = this.state;

        const listingCount = numListings && !elementsSaving[listingTab] ? numListings[listingTab] : 0;
        const listingz = listings[listingTab] ? listings[listingTab] : {};
        let disableDeleteFromGroup = true;
        const disclaimerText = `© 2019 - ${new Date().getFullYear()} Kidder Mathews Inc and it’s subsidiaries and/or assigns. The information supplied herein is from sources we deem reliable. It is provided without any representation, warranty, or guarantee, expressed or implied as to its accuracy. Prospective Buyer or Tenant should conduct an independent investigation and verification of all matters deemed to be material, including, but not limited to, statements of income and expenses. Consult your attorney, accountant, or other professional advisor. All information is proprietary to Kidder Mathews and intended for the sole purpose of providing consultancy services to clients and should not be reproduced, distributed or used for any other purpose nor divulged to third party data collection agencies as outlined by corporate policy.`;

        //sets disableDeleteFromGroup to false for Delete from Group button if no listings are selected
        let list = [];
        for (var [key, value] of selectedListingsGroup) {
            if (value && !list.includes(key)) {
                list.push(key);
            }
        }
        if (list.length !== 0) {
            disableDeleteFromGroup = false;
        }

        return (
            <div>
                <Collapse
                    in={listingTab === "all_km_listings"}
                    unmountOnExit
                    style={{ marginLeft: -20, marginRight: -20 }}
                >
                    <ListingFilters
                        role={role}
                        updateFilter={updateFilter}
                        classes={classes}
                        searchSubmit={handleListingsSearchSubmit}
                        propertySearchFilters={propertySearchFilters}
                        handleSelectAllPropertyType={handleSelectAllPropertyType}
                        clearFilters={clearFilters}
                        searchCriteria={searchCriteria}
                        handleStatusRadioClick={handleStatusRadioClick}
                        handleSizeRadioClick={handleSizeRadioClick}
                        handleListingTypeRadioClick={handleListingTypeRadioClick}
                        errors={errors}
                        togglePropertyTypeExpansionPanel={togglePropertyTypeExpansionPanel}
                        propertyTypeExpansionPanels={propertyTypeExpansionPanels}
                        toggleMarketFilterExpansionPanel={toggleMarketFilterExpansionPanel}
                        marketFilterExpansionPanels={marketFilterExpansionPanels}
                        handleSelectMarket={handleSelectMarket}
                        handleSelectSubmarket={handleSelectSubmarket}
                        handleSelectMicromarket={handleSelectMicromarket}
                        handleSearchCleared={handleSearchCleared}
                        loadedSearchCrumbs={loadedSearchCrumbs}
                        handleDeleteCrumb={handleDeleteCrumb}
                    />
                </Collapse>

                <Snackbar
                    open={this.state.snackbarOpen}
                    message={this.state.snackbarMessage}
                    variant={this.state.snackbarVariant}
                    vertical="top"
                    horizontal="center"
                    autoHideDuration={3000}
                    onClose={() => {
                        this.setState({ snackbarOpen: false });
                    }}
                />

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.pageToolbar}
                    spacing={16}
                >
                    <Grid item>
                        <h2 className="page-title">Listings</h2>
                        <div className="page-subtitle">
                            {listingTab !== "all_km_listings" &&
                                listingTab !== "listings_group" &&
                                `Showing ${listingCount} ${listingCount === 1 ? "listing" : "listings"} `}
                            {filterQuery > "" && listingTab !== "all_km_listings" && "(Filtered)"}
                            {listingTab === "all_km_listings" &&
                                searchResponse &&
                                searchResponse.results &&
                                `Showing  ${searchResponse.results.length} ${
                                    searchResponse.results.length === 1
                                        ? "listing"
                                        : `listings (${searchResponse.totalResultCount} TOTAL)`
                                }`}

                            {listingTab === "listings_group" &&
                                listingCurrentGroup &&
                                `You have ${listingCurrentGroup.length} ${
                                    listingCurrentGroup.length === 1
                                        ? "listing in your current list"
                                        : `listings in your current list`
                                }`}
                        </div>
                    </Grid>
                    <Grid item>
                        {listingTab === "all_km_listings" || listingTab === "broker_open" ? (
                            <Button
                                disabled={false}
                                variant="contained"
                                color="primary"
                                onClick={(e) => addSelectedToGroup(listingTab)}
                            >
                                Add to List
                            </Button>
                        ) : listingTab === "listings_group" ? (
                            <React.Fragment>
                                <Button
                                    disabled={disableDeleteFromGroup}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => deleteSelectedFromGroup()}
                                >
                                    Delete From List
                                </Button>
                                <Button
                                    disabled={disableDeleteFromGroup}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => clearCurrentGroup()}
                                >
                                    Clear List
                                </Button>
                            </React.Fragment>
                        ) : (
                            ""
                        )}

                        {listingTab === "all_km_listings" &&
                        includes(["dataAdmin", "dataEntry", "research", "researchAdmin"], role) ? (
                            <React.Fragment>
                                <Button
                                    disabled={false}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.handleOpenModal()}
                                >
                                    Import
                                </Button>
                                <ListingImportModal
                                    modalOpen={this.state.modalOpen}
                                    closeModal={this.handleCloseModal}
                                    importResponse={this.handleImportResponse}
                                />
                            </React.Fragment>
                        ) : (
                            ""
                        )}

                        {(listingTab === "all_km_listings" ||
                            listingTab === "listings_group" ||
                            listingTab === "broker_open") &&
                            (get(dataExport, "exportInProgress", false) === true ? (
                                <Button disabled={true} variant="outlined" color="primary">
                                    <ExportIcon /> In Progress
                                </Button>
                            ) : (
                                <React.Fragment>
                                    <MUITooltip
                                        title={disclaimerText}
                                        placement="left-start"
                                        classes={{ tooltip: classes.muiToolTip }}
                                    >
                                        <DropdownButton
                                            disabled={Object.keys(listingz).length === 0}
                                            title={"Export"}
                                            onClick={(e) => this.handleExportDropdown(true)}
                                            pullRight
                                        >
                                            {this.state.exportDropdown && (
                                                <React.Fragment>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => handleDataExport("selected", listingTab)}
                                                    >
                                                        Selected Listings Only
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => handleDataExport("all", listingTab)}
                                                    >
                                                        Entire Data Set
                                                    </MenuItem>
                                                </React.Fragment>
                                            )}
                                        </DropdownButton>
                                    </MUITooltip>
                                </React.Fragment>
                            ))}

                        {(listingTab === "all_km_listings" ||
                            listingTab === "listings_group" ||
                            listingTab === "broker_open") &&
                            (elementsSaving.generateListingReport ? (
                                <React.Fragment>
                                    <Button disabled={true} variant="contained" color="secondary">
                                        <CircularProgress className={classes.loadingProgress} size={15} />
                                        Generating
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <MUITooltip
                                        title={disclaimerText}
                                        placement="left-start"
                                        classes={{ tooltip: classes.muiToolTip }}
                                    >
                                        <DropdownButton
                                            disabled={Object.keys(listingz).length === 0}
                                            title={"Generate Report"}
                                            onClick={(e) => this.handleReportDropdown(true)}
                                            pullRight
                                        >
                                            {this.state.reportDropdown && (
                                                <React.Fragment>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => this.handleSelectReport("Standard")}
                                                    >
                                                        Standard
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => this.handleSelectReport("MapOverview")}
                                                    >
                                                        Map Overview
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => this.handleSelectReport("HalfMap-HalfProperty")}
                                                    >
                                                        Half Map / Half Property List
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => this.handleSelectReport("IndividualDetail")}
                                                    >
                                                        Individual Listing Page Detail
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.menuSubItem}
                                                        onClick={() => this.handleSelectReport("Reference")}
                                                    >
                                                        Property Reference
                                                    </MenuItem>
                                                </React.Fragment>
                                            )}
                                        </DropdownButton>
                                    </MUITooltip>
                                </React.Fragment>
                            ))}

                        {includes(
                            [
                                "research",
                                "researchAdmin",
                                "broker",
                                "portlandBrokerAdmin",
                                "arizonaBrokerAdmin",
                                "evp",
                                "accounting",
                                "propertyManagement",
                                "transition",
                                "general",
                                "officeManager",
                                "appraiser",
                            ],
                            role
                        ) && (
                            <DropdownButton id="dropdown-basic-button" title="Request Listing" pullRight>
                                <MenuItem disabled className={classes.menuCategory}>
                                    Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Office", "Lease")}
                                >
                                    Office Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Industrial", "Lease")}
                                >
                                    Industrial Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Retail", "Lease")}
                                >
                                    Retail Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Land", "Lease")}
                                >
                                    Land Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Multifamily", "Lease")}
                                >
                                    Multifamily Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Life Science", "Lease")}
                                >
                                    Life Science Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Healthcare", "Lease")}
                                >
                                    Healthcare Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Religious", "Lease")}
                                >
                                    Religious Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Educational", "Lease")}
                                >
                                    Educational Lease
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Other", "Lease")}
                                >
                                    Other Lease
                                </MenuItem>
                                <MenuItem disabled className={classes.menuCategory}>
                                    Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Office", "Sale")}
                                >
                                    Office Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Industrial", "Sale")}
                                >
                                    Industrial Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Retail", "Sale")}
                                >
                                    Retail Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Land", "Sale")}
                                >
                                    Land Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Hospitality", "Sale")}
                                >
                                    Hospitality Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Multifamily", "Sale")}
                                >
                                    Multifamily Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Life Science", "Sale")}
                                >
                                    Life Science Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Healthcare", "Sale")}
                                >
                                    Healthcare Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Religious", "Sale")}
                                >
                                    Religious Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Educational", "Sale")}
                                >
                                    Educational Sale
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuSubItem}
                                    onClick={() => this.handleShowModal("Other", "Sale")}
                                >
                                    Other Sale
                                </MenuItem>
                            </DropdownButton>
                        )}
                        <RequestModalContainer
                            {...this.props}
                            handleShowModal={this.handleShowModal}
                            showModal={this.state.showModal}
                            modalType="listing"
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="center" spacing={16} style={{ maxWidth: "100%" }}>
                    <Grid item xs>
                        <div className="simPaper">
                            <Grid container justify="space-between" spacing={0}>
                                <Grid item>
                                    {includes(["broker", "portlandBrokerAdmin", "arizonaBrokerAdmin", "evp"], role) ? (
                                        <Tabs classes={classes} value={listingTab} onChange={switchTab}>
                                            <Tab
                                                classes={classes}
                                                value="broker_requested"
                                                label="My Listing Requests"
                                            />
                                            <Tab
                                                classes={classes.tabOne}
                                                value="broker_open"
                                                label="My Active Listings"
                                            />
                                            <Tab classes={classes} value="all_km_listings" label="All Listings" />
                                            <Tab
                                                classes={classes}
                                                value="listings_group"
                                                label={`Current List (${
                                                    listingCurrentGroup ? listingCurrentGroup.length : "0"
                                                })`}
                                            />
                                        </Tabs>
                                    ) : includes(
                                          [
                                              "general",
                                              "officeManager",
                                              "research",
                                              "researchAdmin",
                                              "propertyManagement",
                                              "transition",
                                              "accounting",
                                              "appraiser",
                                          ],
                                          role
                                      ) ? (
                                        <Tabs classes={classes} value={listingTab} onChange={switchTab}>
                                            <Tab
                                                classes={classes}
                                                value="broker_requested"
                                                label="My Listing Requests"
                                            />
                                            <Tab classes={classes} value="all_km_listings" label="All Listings" />
                                            <Tab
                                                classes={classes}
                                                value="listings_group"
                                                label={`Current List (${
                                                    listingCurrentGroup ? listingCurrentGroup.length : "0"
                                                })`}
                                            />
                                        </Tabs>
                                    ) : (
                                        <Tabs classes={classes} value={listingTab} onChange={switchTab}>
                                            <Tab classes={classes} value="all_km_listings" label="All Listings" />
                                            <Tab
                                                classes={classes}
                                                value="listings_group"
                                                label={`Current List (${
                                                    listingCurrentGroup ? listingCurrentGroup.length : "0"
                                                })`}
                                            />
                                        </Tabs>
                                    )}
                                </Grid>
                                {listingTab === "broker_requested" && (
                                    <Grid item>
                                        <BSInput
                                            value={filterQuery}
                                            placeholder="Search listings"
                                            className={classes.toplessInput}
                                            errorPlaceholder="hideIt"
                                            startAdornment={<Search />}
                                            onChange={(event) => {
                                                handleFilter(event.target.value);
                                            }}
                                        />
                                    </Grid>
                                )}

                                {(listingTab === "all_km_listings" || listingTab === "listings_group") && (
                                    <Grid item>
                                        <Grid container>
                                            {mapDisplay ? (
                                                <Grid item style={{}}>
                                                    <Button
                                                        style={{ minWidth: "40px" }}
                                                        onClick={() => this.toggleMap(false)}
                                                    >
                                                        <List />
                                                    </Button>
                                                </Grid>
                                            ) : (
                                                <Grid style={{}}>
                                                    <Button
                                                        style={{ minWidth: "40px" }}
                                                        onClick={() => this.toggleMap(true)}
                                                    >
                                                        <Map />
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                            {listingTab === "all_km_listings" || listingTab === "listings_group" ? (
                                mapDisplay ? (
                                    <ListingsWithMap
                                        {...this.props}
                                        selectAll={selectAll}
                                        selectAllGroup={selectAllGroup}
                                        searchRun={searchRun}
                                        searchCleared={listingTab === "listings_group" ? false : searchCleared}
                                        handleSelectAll={this.handleSelectAll}
                                        handleSelectAllGroup={this.handleSelectAllGroup}
                                        handleSearchCleared={handleSearchCleared}
                                        handleSearchSubmit={handleListingsSearchSubmit}
                                        selectedListings={selectedListings}
                                        mapCrumbCleared={mapCrumbCleared}
                                        searchPolygon={searchPolygon}
                                        updateSearchPolygon={updateSearchPolygon}
                                    />
                                ) : (
                                    <ListingsWithoutMap
                                        {...this.props}
                                        selectAll={selectAll}
                                        selectAllGroup={selectAllGroup}
                                        searchRun={searchRun}
                                        searchCleared={listingTab === "listings_group" ? false : searchCleared}
                                        handleSelectAll={this.handleSelectAll}
                                        handleSelectAllGroup={this.handleSelectAllGroup}
                                        handleSearchCleared={handleSearchCleared}
                                        handleSearchSubmit={handleListingsSearchSubmit}
                                        selectedListings={selectedListings}
                                        openCloseDealDialog={this.handleClickOpen}
                                    />
                                )
                            ) : listingTab === "broker_open" ? (
                                <ListingsWithoutMap
                                    {...this.props}
                                    selectAll={selectAllActive}
                                    searchRun={searchRun}
                                    searchCleared={searchCleared}
                                    handleSelectAll={this.handleSelectAllActive}
                                    handleSearchCleared={handleSearchCleared}
                                    handleSearchSubmit={handleListingsSearchSubmit}
                                    selectedListings={activeSelectedListings}
                                    openCloseDealDialog={this.handleClickOpen}
                                />
                            ) : (
                                ""
                            )}

                            {listingTab === "broker_requested" && (
                                <div>
                                    <Grid container spacing={0}>
                                        <Grid item xs>
                                            <Table>
                                                <TableHead>
                                                    <TableRow style={{ borderBottom: "1px solid rgb(212, 212, 212)" }}>
                                                        {schema.viewHeaders[listingTab].map((x, i) => (
                                                            <TableCell classes={classes} key={`thc-${i}`}>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                    onClick={() => handleSort(x.sort ? x.sort : x.prop)}
                                                                >
                                                                    <span className={classes.sortable}>{x.name}</span>
                                                                    {x.name && (
                                                                        <div>
                                                                            <Sorty />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>

                                                {!isLoading && (
                                                    <TableBody>
                                                        {Object.keys(listingz).map((propKey, i) =>
                                                            row(
                                                                propKey,
                                                                role,
                                                                listingTab,
                                                                classes,
                                                                isLoading,
                                                                columnToSort,
                                                                sortDirection,
                                                                listingz,
                                                                filterQuery
                                                            )
                                                        )}
                                                    </TableBody>
                                                )}
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}

                            {listingTab === "broker_requested" ? (
                                elementsSaving[listingTab] ? (
                                    <div className={classes.alternateContent}>
                                        <CircularProgress color="primary" />
                                    </div>
                                ) : Object.keys(listingz).length === 0 ? (
                                    <div className={classes.alternateContent}>No listings</div>
                                ) : (
                                    ""
                                )
                            ) : (
                                ""
                            )}
                        </div>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.closeDealDialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="close-deal"
                    aria-describedby="close-deal-dialog"
                >
                    <DialogTitle id="close-deal">{"Are you sure?"}</DialogTitle>
                    {this.state.listingToClose.status === "Closed" ? (
                        <React.Fragment>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    A deal has already been started for this listing. It is likely that this happened
                                    recently and the system has not picked up the change yet.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary" variant="outlined">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Closing this deal will start the invoice process for this transaction and move the
                                    listing to a closed status. Portland Listings will be closed after the Comp for this
                                    Deal has been Submitted and Approved.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary" variant="outlined">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={this.handleCloseAndStart}
                                    color="secondary"
                                    autoFocus
                                    variant="contained"
                                >
                                    Continue
                                </Button>
                            </DialogActions>
                        </React.Fragment>
                    )}
                </Dialog>

                <ReportTitleModal
                    {...this.props}
                    reportType="Listing"
                    tab={listingTab}
                    reportStyle={this.state.reportStyle}
                />
                <SavedSearchesModal {...this.props} searchType="listings" tab={listingTab} showModal={viewSavedModal} />
                <NameSearchModal {...this.props} searchType="listings" tab={listingTab} showModal={nameSaveModal} />
            </div>
        );
    }
}
const row = (propKey, role, listingTab, classes, isLoading, columnToSort, sortDirection, listingz, filterQuery) => {
    const sortedData = orderBy(
        filterQuery && listingz && listingz[propKey]
            ? listingz[propKey].filter((x) => schema.filterByQuery(x, schema.columnsToQueryFilter, filterQuery))
            : listingz[propKey],
        columnToSort,
        sortDirection
    );

    return isLoading ? null : (
        <React.Fragment>
            {sortedData.length > 0 && (
                <TableRow>
                    <TableCell colSpan={7}>
                        <Grid container alignItems="center" spacing={8}>
                            <Grid item>
                                <ListingCircle />
                            </Grid>
                            <Grid item xs>
                                <div className="cluster">
                                    <span className="clusterHeader">
                                        {get(sortedData[0], "property.propertyAttributes.propertyName", null)
                                            ? get(sortedData[0], "property.propertyAttributes.propertyName", "")
                                            : get(sortedData[0], "property.primaryAddress.address1", "")}
                                    </span>
                                    <span className="clusterSubHeader">
                                        {`${sortedData.length} ${sortedData.length === 1 ? "listing" : "listings"}`}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            )}
            {sortedData.map((x, i) => {
                const buttonProperties = get(buttonActions, `${role}.${x.status}`, buttonActions.defaultAction);

                return (
                    <TableRow key={`tr-${i}`} selectable="false">
                        {schema.viewHeaders[listingTab].map((y, k) =>
                            y.name === "Address" ? (
                                <TableCell
                                    classes={{ root: classes.body }}
                                    key={`trc-${k}`}
                                    style={{ position: "relative" }}
                                >
                                    <Link to={`/listing/${x.listingKey}`} className={classes.lb}>
                                        <h4 className={classes.listingNumber}>{`No. ${get(x, "listingKey", "")}`}</h4>
                                        <span className={classes.listingIdLink}>{get(x, "listingName", "")}</span>
                                    </Link>
                                    <div>
                                        <span className={classes.clusterSubHeader}>
                                            {buildAddress(get(x, "property.primaryAddress", {}))}
                                        </span>
                                    </div>
                                </TableCell>
                            ) : y.transform ? (
                                <TableCell classes={{ root: classes.body }} key={`trc-${k}`} numeric={y.numeric}>
                                    {y.transform(get(x, y.prop))}
                                </TableCell>
                            ) : y.prop === "status" ? (
                                <TableCell>
                                    <StatusChip
                                        status={
                                            buttonProperties.statusToDisplay && buttonProperties.statusToDisplay !== ""
                                                ? buttonProperties.statusToDisplay
                                                : x.status
                                        }
                                    />
                                </TableCell>
                            ) : y.name === "Rate" ? (
                                <TableCell>
                                    {get(x, "listingType", null) === "Lease"
                                        ? `${toDollarAmount(get(x, "askingRentMax"))}`
                                        : `${toDollarAmount(get(x, "listPrice", "-"))}`}
                                </TableCell>
                            ) : y.name === "SQFT" ? (
                                <TableCell>
                                    {get(x, "listingType", null) === "Lease"
                                        ? get(x, "sfDivisibleTo", null)
                                            ? `${toSqft(get(x, "sfDivisibleTo"))} - ${toSqft(get(x, "sfAvail"))}`
                                            : `${toSqft(get(x, "sfAvail", "-"))}`
                                        : `${toSqft(get(x, "sfAvail", "-"))}`}
                                </TableCell>
                            ) : (
                                <TableCell classes={{ root: classes.body }} key={`trc-${k}`} numeric={y.numeric}>
                                    {get(x, y.prop)}
                                </TableCell>
                            )
                        )}

                        <TableCell classes={{ root: classes.body }}>
                            <Link to={`/listing/${x.listingKey}`} className={classes.lb}>
                                <Button variant={buttonProperties.variant} color="primary" onClick={() => null}>
                                    {buttonProperties.text}
                                </Button>
                            </Link>
                        </TableCell>
                    </TableRow>
                );
            })}
        </React.Fragment>
    );
};

export default withStyles(styles)(BrokerListings);
