import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ArrowForward from "@material-ui/icons/ArrowForward";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import ReactGA from "react-ga4";

const styles = (theme) => ({
    listTitle: {
        margin: 0,
        paddingLeft: 16,
        fontSize: "17px",
        color: "white",
    },
    helpTitle: {
        margin: 0,
        paddingLeft: 10,
        fontSize: "17px",
        color: "white",
    },
    linkText: {
        color: "white",
        fontWeight: 400,
        fontSize: "13px",
    },
    helpMsg: {
        color: "white",
        fontWeight: 400,
        fontSize: "13px",
        margin: 0,
        padding: 10,
    },
    link: {
        textDecoration: "none",
        "&:focus": {
            textDecoration: "none",
        },
    },
    paper: {
        marginBottom: "12px",
        width: "84%",
        marginLeft: "8%",
        backgroundColor: "rgba(255, 255, 255, 0.13)",
        marginTop: 16,
        borderRadius: "6px",
    },
    arrow: {
        color: "white",
        "&:hover": {
            cursor: "pointer",
        },
    },
    arrowIcon: {
        marginRight: "6px",
    },
});
class MoreDrawerLinks extends React.Component {
    handleLinkClick(title, url) {
        ReactGA.event("KM Toolbox Click", {
            destination: title,
        });
        window.open(url);
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <List disablePadding component="nav">
                        <ListItem divider disableGutters>
                            <h3 className={classes.listTitle}>KM Toolbox</h3>
                            <Divider />
                        </ListItem>

                        <Link to="/training-content" className={classes.link}>
                            <ListItem
                                divider
                                button
                                disableGutters
                                onClick={() => ReactGA.event("KM Toolbox Click", { destination: "Training Content" })}
                            >
                                <ListItemText classes={{ primary: classes.linkText }} primary="Training Content" />
                                <ListItemSecondaryAction>
                                    <ListItemIcon classes={{ root: classes.arrowIcon }}>
                                        <ArrowForward className={classes.arrow} />
                                    </ListItemIcon>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Link>

                        <ListItem
                            divider
                            button
                            disableGutters
                            onClick={() =>
                                this.handleLinkClick(
                                    "FAQ",
                                    "https://s3.us-east-2.amazonaws.com/connect.kidder.com/downloads/KM+Connect+Q+%26+A.docx"
                                )
                            }
                        >
                            <ListItemText classes={{ primary: classes.linkText }} primary="FAQ" />
                            <ListItemSecondaryAction>
                                <ListItemIcon classes={{ root: classes.arrowIcon }}>
                                    <ArrowForward className={classes.arrow} />
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem
                            divider
                            button
                            disableGutters
                            onClick={() =>
                                this.handleLinkClick(
                                    "Research Reports",
                                    "http://www.kiddermathews.com/research.php",
                                    "_blank"
                                )
                            }
                        >
                            <ListItemText classes={{ primary: classes.linkText }} primary="Research Reports" />
                            <ListItemSecondaryAction>
                                <ListItemIcon classes={{ root: classes.arrowIcon }}>
                                    <ArrowForward className={classes.arrow} />
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem
                            divider
                            button
                            disableGutters
                            onClick={() =>
                                this.handleLinkClick(
                                    "PowerBI Reports",
                                    "https://app.powerbi.com/sharedwithme",
                                    "_blank"
                                )
                            }
                        >
                            <ListItemText classes={{ primary: classes.linkText }} primary="Power BI Reports" />
                            <ListItemSecondaryAction>
                                <ListItemIcon classes={{ root: classes.arrowIcon }}>
                                    <ArrowForward className={classes.arrow} />
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem
                            divider
                            button
                            disableGutters
                            onClick={() =>
                                this.handleLinkClick(
                                    "Helpful Links",
                                    "https://s3.us-east-2.amazonaws.com/connect.kidder.com/downloads/KM+Connect+Helpful+Links.xlsx",
                                    "_blank"
                                )
                            }
                        >
                            <ListItemText classes={{ primary: classes.linkText }} primary="Helpful Links" />
                            <ListItemSecondaryAction>
                                <ListItemIcon classes={{ root: classes.arrowIcon }}>
                                    <ArrowForward className={classes.arrow} />
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Paper>
                <Paper className={classes.paper}>
                    <List disablePadding component="nav">
                        <ListItem divider disableGutters>
                            <h3 className={classes.helpTitle}>Need Assistance?</h3>
                            <Divider />
                        </ListItem>
                    </List>
                    <div className={classes.helpMsg}>
                        Please call the KM Connect helpline at
                        <br />
                        206-248-3825 or email the support team at{" "}
                        <a className={classes.linkText} href="mailto:KM_Connect@kidder.com">
                            KM_Connect@kidder.com
                        </a>
                    </div>
                </Paper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(MoreDrawerLinks);
