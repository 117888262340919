import React, { useEffect } from "react";
import { connect } from "react-redux";
import operations from "./ducks/operations";
import ComplianceRule from "./ComplianceRule";
import ComplianceRuleModalContainer from "./ComplianceRuleModalContainer";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Scroll from "react-scroll";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import ReactGA from "react-ga4";

const ComplianceRuleContainer = (props) => {
    const {
        classes,
        rules,
        addRule,
        saveNewRule,
        updateRule,
        toggleModal,
        modal,
        getConditions,
        handleDragEnd,
        updatePriorities,
        isLoading,
        getRulesByParentEntityTypeKey,
        revertDropdownDisplays,
        parentEntityTypeKey,
        resetRules,
        parentEntityTypes,
        getAllParentEntityTypes,
        elementsSaving,
        resetPriorities,
    } = props;

    useEffect(() => {
        const { id } = props.match.params;
        ReactGA.send({ hitType: "pageview", page: "/parent-type/*", title: `Document Compliance Parent Type: ${id}` });
        getRulesByParentEntityTypeKey(id);
        getConditions(id);
        if (parentEntityTypes.length === 0) {
            getAllParentEntityTypes();
        }
        return () => {
            resetRules();
        };
    }, []);

    const { id } = props.match.params;
    const parentTypeHeader =
        parentEntityTypes.length !== 0
            ? parentEntityTypes.find((parentType) => parentType.parentEntityTypeKey === Number(id)).name
            : "";
    const formattedParentTypeHeader = startCase(toLower(parentTypeHeader));
    const { modalHeader } = modal;
    const handleClose = () => {
        toggleModal(false);
        revertDropdownDisplays();
    };
    let Element = Scroll.Element;
    const getListStyle = (isDraggingOver) => ({
        padding: "",
    });

    return (
        <React.Fragment>
            <Grid container style={{ margin: "25px 0px" }} justify={"space-between"}>
                <Grid item>
                    <h2 className="page-title">{`${formattedParentTypeHeader} Document Compliance`}</h2>
                    <div className="page-subtitle">{`Entity Type Rule Configuration`}</div>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => {
                            addRule();
                        }}
                        variant="outlined"
                        color="primary"
                        disabled={props.conditions.length <= 1}
                    >
                        + Add Rule
                    </Button>
                    <Button
                        onClick={() => {
                            updatePriorities(parentEntityTypeKey);
                        }}
                        variant="contained"
                        color="primary"
                        disabled={props.rules.length === 0}
                    >
                        Save Priority
                    </Button>
                    <Button
                        onClick={() => {
                            resetPriorities();
                        }}
                        variant="contained"
                        color="secondary"
                        disabled={props.rules.length === 0}
                    >
                        Reset Priority
                    </Button>
                </Grid>
            </Grid>
            {!isLoading && !elementsSaving.getRules ? (
                <div>
                    {props.conditions.length <= 1 ? (
                        <p>
                            Rules cannot be created here because no applicable Conditions exist for{" "}
                            {formattedParentTypeHeader} yet. Please contact the KM Connect IT team to have Conditions
                            created.
                        </p>
                    ) : (
                        <div className={classes.paper}>
                            <Element className={classes.element} style={{ height: "inherit", overflowY: "scroll" }}>
                                <React.Fragment>
                                    {props.rules.length === 0 ? (
                                        <p>
                                            No Rules created for {formattedParentTypeHeader} yet. Create a Rule by
                                            clicking the Add Rule button above.
                                        </p>
                                    ) : (
                                        <DragDropContext onDragEnd={(result) => handleDragEnd(result, rules)}>
                                            <Droppable droppableId="droppable" style={{ maxWidth: "100%" }}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                    >
                                                        {props.rules.map((rule, index) => {
                                                            return (
                                                                <ComplianceRule {...props} rule={rule} index={index} />
                                                            );
                                                        })}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    )}
                                </React.Fragment>
                            </Element>
                        </div>
                    )}
                </div>
            ) : (
                <CircularProgress style={{ position: "fixed", top: "50%", left: "50%" }} />
            )}
            <ComplianceRuleModalContainer
                handleClose={handleClose}
                handleSaveRule={(ruleKey) => {
                    ruleKey ? updateRule(ruleKey, parentEntityTypeKey) : saveNewRule(parentEntityTypeKey);
                }}
                modalHeader={modalHeader}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.utilsReducer.isLoading,
    rules: state.complianceReducer.rules,
    modal: state.complianceReducer.modal,
    conditions: state.complianceReducer.conditions.conditionTypes,
    parentEntityTypeKey: state.complianceReducer.conditions.parentEntityTypeKey,
    elementsSaving: state.utilsReducer.elementsSaving,
    parentEntityTypes: state.complianceReducer.parentEntityTypes,
});

const mapDispatchToProps = (dispatch) => ({
    getRulesByParentEntityTypeKey: (parentEntityTypeKey) => {
        dispatch(operations.getRulesByParentEntityTypeKey(parentEntityTypeKey));
    },
    addRule: () => {
        dispatch(operations.addRule());
    },
    editRule: (ruleKey) => {
        dispatch(operations.editRule(ruleKey));
    },
    saveNewRule: (parentEntityTypeKey) => {
        dispatch(operations.saveNewRule(parentEntityTypeKey));
    },
    updateRule: (ruleKey, parentEntityTypeKey) => {
        dispatch(operations.updateRule(ruleKey, parentEntityTypeKey));
    },
    toggleModal: (toggle) => {
        dispatch(operations.toggleModal(toggle));
    },
    getConditions: (parentEntityTypeKey, ruleKey) => {
        dispatch(operations.getConditions(parentEntityTypeKey, ruleKey));
    },
    handleDragEnd: (results, comps) => {
        dispatch(operations.handleDragEnd(results, comps));
    },
    updatePriorities: (parentEntityTypeKey) => {
        dispatch(operations.updatePriorities(parentEntityTypeKey));
    },
    revertDropdownDisplays: () => {
        dispatch(operations.revertDropdownDisplays());
    },
    resetPriorities: () => {
        dispatch(operations.resetPriorities());
    },
    resetRules: () => {
        dispatch(operations.resetRules());
    },
    getAllParentEntityTypes: () => {
        dispatch(operations.getAllParentEntityTypes());
    },
});

const styles = (theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
        display: "flex",
        flexDirection: "column",
        width: "65%",
        height: "75vh",
    },
    element: {
        "&::-webkit-scrollbar": {
            width: "12px",
        },

        "&::-webkit-scrollbar-track": {
            background: "#ffffff",
        },

        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#dbdbdb",
            borderRadius: "10px",
            border: "3px solid #ffffff",
        },
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ComplianceRuleContainer));
