import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const GenerateImageEditorButtons = (props) => {
    const { classes, handleGenerateClick, handleCloseModal, shouldDisableButtons } = props;
    return (
        <React.Fragment>
            <Button className={classes.actionButton} onClick={handleCloseModal} variant="outlined">
                Cancel
            </Button>
            <Button
                className={classes.actionButton}
                onClick={handleGenerateClick}
                variant="contained"
                color="primary"
                disabled={shouldDisableButtons}
            >
                Generate
            </Button>
        </React.Fragment>
    );
};

const styles = (theme) => ({
    actionButton: {
        padding: "7px 16px",
    },
});

export default withStyles(styles)(GenerateImageEditorButtons);
