import React, { Component } from "react";
import { connect } from "react-redux";
import CrmContacts from "./CrmContacts";
import CrmToolbar from "./CrmToolbar";
import operations from "./ducks/operations";
import actions from "./ducks/actions";
import includes from "lodash/includes";
import ReactGA from "react-ga4";

class CrmContactsContainer extends Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.getContactsForUser(id);
        ReactGA.send({ hitType: "pageview", page: "/contacts", title: "Contacts" });
    }

    componentWillUnmount() {
        this.props.flushContactsState();
    }

    render() {
        return includes(
            [
                "broker",
                "portlandBrokerAdmin",
                "arizonaBrokerAdmin",
                "propertyManagement",
                "debtEquity",
                "appraiser",
                "general",
                "officeManager",
                "research",
                "researchAdmin",
                "evp",
                "transition",
                "accounting",
                "dataEntry",
                "dataAdmin",
            ],
            this.props.auth.userProfile.role
        ) ? (
            <React.Fragment>
                <CrmToolbar {...this.props} />
                <CrmContacts {...this.props} />
            </React.Fragment>
        ) : null;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        contacts: state.crmReducer.contacts,
        elementsSaving: state.utilsReducer.elementsSaving,
        columnToSort: state.crmReducer.columnToSort,
        sortDirection: state.crmReducer.sortDirection,
        filterQuery: state.crmReducer.filterQuery,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleSort: (column) => {
            dispatch(operations.sort(column));
        },
        handleFilter: (query) => {
            dispatch(operations.filter(query));
        },
        getContactsForUser: (user) => {
            dispatch(operations.getContactsForUser(user));
        },
        deleteContact: (contactId, index) => {
            dispatch(operations.deleteContact(contactId, index));
        },
        flushContactsState: () => {
            dispatch(actions.handleInput("contacts", []));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CrmContactsContainer);
