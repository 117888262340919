const LOAD_TRANSACTIONS_FOR_USER = "LOAD_TRANSACTIONS_FOR_USER";
const ADD_DEBT_EQUITY_TRANSACTION_INPUT = "ADD_DEBT_EQUITY_TRANSACTION_INPUT";
const SET_DEBT_EQUITY_ARRAY = "SET_DEBT_EQUITY_ARRAY";
const DELETE_DEBT_EQUITY_ARRAY_ELEMENT = "DELETE_DEBT_EQUITY_ARRAY_ELEMENT";
const LOAD_TRANSACTION = "LOAD_TRANSACTION";
const FILTER_DEBT_EQUITY_TRANSACTIONS = "FILTER_DEBT_EQUITY_TRANSACTIONS";
const SORT_DEBT_EQUITY_TRANSACTIONS = "SORT_DEBT_EQUITY_TRANSACTIONS";
const SWITCH_TRANSACTIONS_TAB = "SWITCH_TRANSACTIONS_TAB";
const COUNT_DEBT_EQUITY_RESULTS = "COUNT_DEBT_EQUITY_RESULTS";

export default {
    LOAD_TRANSACTIONS_FOR_USER,
    ADD_DEBT_EQUITY_TRANSACTION_INPUT,
    SET_DEBT_EQUITY_ARRAY,
    DELETE_DEBT_EQUITY_ARRAY_ELEMENT,
    LOAD_TRANSACTION,
    FILTER_DEBT_EQUITY_TRANSACTIONS,
    SORT_DEBT_EQUITY_TRANSACTIONS,
    SWITCH_TRANSACTIONS_TAB,
    COUNT_DEBT_EQUITY_RESULTS,
};
