import React from "react";
import MarkerClusterer from "./googlemaps/markerclustererplus";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({});

const clusterMarkerStyles = {
    url: "https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/circlePin.svg",
    height: "26",
    width: "26",
    textColor: "white",
    className: "cluster-icon",
    fontWeight: "bold",
};

const clusterCalculator = (markers, numStyles) => {
    let index = 0;
    let count = 0;

    markers.forEach((marker) => {
        count += marker.content.places.length;
    });

    let dv = count;
    while (dv !== 0) {
        dv = parseInt(dv / 10, 10);
        index++;
    }

    index = Math.min(index, numStyles);
    return {
        text: count,
        index: index,
    };
};

const mapOptions = {
    styles: [clusterMarkerStyles],
    gridSize: 15,
    maxZoom: 16,
    averageCenter: true,
    calculator: clusterCalculator,
    batchSize: 300,
};

class GoogleMap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            map: null,
            maps: null,
            clear: false,
            mapTypeId: null,
            drawingManager: null,
            savedPolygon: new window.google.maps.Polygon({
                paths: [],
                fillColor: "black",
                fillOpacity: 0.15,
                strokeWeight: 4,
                clickable: true,
                editable: true,
                zIndex: 1,
            }),
            savedRectangle: new window.google.maps.Rectangle({
                strokeWeight: 4,
                fillColor: "black",
                fillOpacity: 0.15,
                clickable: true,
                editable: true,
                bounds: {},
            }),
        };
    }

    polygonRef = this.props.searchPolygon;
    savedMapCoords = this.props.savedMapCoords;

    static defaultProps = {
        center: {
            lat: 39.96,
            lng: -115.19,
        },
        zoom: 5,
        mapTypeId: "satellite",
    };

    componentDidMount = (props) => {
        const map = new window.google.maps.Map(document.getElementById("map"), this.getMapOptions());
        this.disableDraw = this.props.disableDraw;
        this.polygonRef = this.props.searchPolygon;
        this.initMapState(map, window.google.maps);
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.disableDraw && !prevProps.disableDraw) {
            this.state.drawingManager.setOptions({
                drawingControl: false,
            });
            this.state.drawingManager.setMap(this.state.map);
        } else if (!this.props.disableDraw && prevProps.disableDraw) {
            this.state.drawingManager.setOptions({
                drawingControl: true,
            });
            this.state.drawingManager.setMap(this.state.map);
        }

        let savedPolygon = this.state.savedPolygon;
        let savedRectangle = this.state.savedRectangle;
        let savedMapCoords = this.props.savedMapCoords;

        if (this.props.searchCleared !== prevProps.searchCleared && this.props.searchCleared === true) {
            this.state.map.setOptions(this.getMapOptions());
        }
        if (
            (this.props.searchCleared !== prevProps.searchCleared && this.props.searchCleared === true) ||
            this.props.mapCrumbCleared
        ) {
            if (this.polygonRef != null && Object.keys(this.polygonRef).length !== 0) {
                this.polygonRef.setMap(null);
                this.setPolygon(null);
            }
            savedPolygon.setMap(null);
            savedRectangle.setMap(null);
        }

        this.renderClusterMarkers(this.state.map, window.google.maps);

        if (!this.props.disableDraw) {
            if (savedMapCoords != null && Object.keys(savedMapCoords).length !== 0) {
                if (this.polygonRef != null && Object.keys(this.polygonRef).length !== 0) {
                    // Removed drawn polygon when a saved search is run
                    this.polygonRef.setMap(null);
                    this.setPolygon(null);
                }
                if (Object.keys(savedMapCoords).length > 0 && Object.keys(savedMapCoords).length !== 2) {
                    const points = [];
                    for (let i = 0; i < savedMapCoords.length; i++) {
                        points.push({
                            lat: savedMapCoords[i]["lat"],
                            lng: savedMapCoords[i]["lon"],
                        });
                    }
                    // Reconstruct the saved polygon.
                    savedRectangle.setMap(null);
                    savedPolygon.setPath(points);
                    savedPolygon.setMap(this.state.map);
                } else if (Object.keys(savedMapCoords).length === 2) {
                    const bounds = new window.google.maps.LatLngBounds(
                        new window.google.maps.LatLng(savedMapCoords[0]["lat"], savedMapCoords[0]["lon"]),
                        new window.google.maps.LatLng(savedMapCoords[1]["lat"], savedMapCoords[1]["lon"])
                    );
                    // Reconstruct the saved rectangle.
                    savedPolygon.setMap(null);
                    savedRectangle.setBounds(bounds);
                    savedRectangle.setMap(this.state.map);
                }
            } else {
                savedPolygon.setMap(null);
                savedRectangle.setMap(null);
            }
        } else {
            if (this.polygonRef != null && Object.keys(this.polygonRef).length !== 0) {
                this.polygonRef.setMap(null);
            }
            savedPolygon.setMap(null);
            savedRectangle.setMap(null);
        }
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.places === nextProps.places && this.state.map === nextState.map) {
            return false;
        }
        return true;
    };

    componentWillUnmount() {
        const settings = {
            zoom: this.state.map.getZoom(),
            center: { lat: -this.state.map.getCenter().lat(), lng: this.state.map.getCenter().lng() },
            bounds: this.state.map.getBounds(),
            mapTypeId: this.state.map.getMapTypeId(),
            requestId: this.props.requestId,
            polygon: this.polygonRef,
        };
        this.props.saveMapSearchSettings(settings);
    }

    setPolygon = (polygon) => {
        this.polygonRef = polygon;
        this.props.updateSearchPolygon(polygon);
    };

    clearSelection(shape) {
        if (shape != null) {
            shape.setEditable(false);
            shape.setMap(null);
        }
    }

    handleSearchByPolygon(polygon, searchByAreaFunc) {
        const latLngs = polygon
            .getPath()
            .getArray()
            .map((latLng, i) => {
                return { lat: latLng.lat(), lon: latLng.lng() };
            });

        if (latLngs.length > 2) {
            searchByAreaFunc(latLngs);
        }
    }

    handleSearchByRectangle(rectangle, searchByAreaFunc) {
        const bounds = rectangle.getBounds();
        const latLngs = [
            { lon: bounds.getSouthWest().lng(), lat: bounds.getNorthEast().lat() },
            { lon: bounds.getNorthEast().lng(), lat: bounds.getSouthWest().lat() },
        ];
        if (latLngs.length === 2 && latLngs[0].lat !== latLngs[1].lat && latLngs[0].lon !== latLngs[1].lon) {
            searchByAreaFunc(latLngs);
        }
    }

    setSelection(shape, clearSelection, selectedShape) {
        clearSelection(shape, selectedShape);
        selectedShape = shape;
        selectedShape.setEditable(true);
    }

    saveMapType = (mapType) => {
        this.setState({ mapTypeId: mapType });
    };

    saveDrawingManager = (drawingManager) => {
        this.setState({ drawingManager: drawingManager });
    };

    initMapState = (map, maps) => {
        this.state.savedRectangle.setMap(map);
        let selectedShape;
        const setSelection = this.setSelection;
        const clearSelection = this.clearSelection;
        const setPolygonRef = this.setPolygon;
        const handleSearchCleared = this.props.handleSearchCleared;
        const disableDraw = this.props.disableDraw;
        const saveDrawingManager = this.saveDrawingManager;

        this.setState({
            map: map,
            maps: maps,
        });

        let shapeOptions = {
            fillColor: "black",
            fillOpacity: 0.15,
            strokeWeight: 4,
            clickable: true,
            editable: true,
            zIndex: 1,
        };

        let drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingControl: disableDraw ? false : true,
            drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ["polygon", "rectangle"],
            },
            polygonOptions: shapeOptions,
            rectangleOptions: shapeOptions,
            circleOptions: shapeOptions,
        });

        drawingManager.setMap(map);
        saveDrawingManager(drawingManager);

        const searchByAreaFunc = this.props.searchByArea;
        const handleSearchByPolygon = this.handleSearchByPolygon;

        this.markerCluster = new MarkerClusterer(map, null, mapOptions);
        const restoreSettings = this.restoreMapSettingsifNecessary;
        maps.event.addListener(this.markerCluster, "clusteringend", function (mc) {
            restoreSettings();
        });

        window.google.maps.event.addListener(drawingManager, "polygoncomplete", function (polygon) {
            setPolygonRef(polygon);
            handleSearchByPolygon(polygon, searchByAreaFunc);
            handleSearchCleared(false);
            if (drawingManager.getDrawingMode() === null) {
                setSelection(polygon, clearSelection, selectedShape);
            }
            drawingManager.setDrawingMode(null);
            window.google.maps.event.addListener(polygon.getPath(), "set_at", function () {
                handleSearchByPolygon(polygon, searchByAreaFunc);
            });
            window.google.maps.event.addListener(polygon.getPath(), "insert_at", function () {
                handleSearchByPolygon(polygon, searchByAreaFunc);
            });
            window.google.maps.event.addListener(polygon.getPath(), "remove_at", function () {
                handleSearchByPolygon(polygon, searchByAreaFunc);
            });
            window.google.maps.event.addListener(drawingManager, "polygoncomplete", function (newPolygon) {
                clearSelection(polygon);
            });
            window.google.maps.event.addListener(drawingManager, "rectanglecomplete", function (newRectangle) {
                clearSelection(polygon);
            });
        });

        const handleSearchByRectangle = this.handleSearchByRectangle;
        window.google.maps.event.addListener(drawingManager, "rectanglecomplete", function (rectangle) {
            setPolygonRef(rectangle);
            handleSearchByRectangle(rectangle, searchByAreaFunc);
            handleSearchCleared(false);
            if (drawingManager.getDrawingMode() === null) {
                setSelection(rectangle, clearSelection, selectedShape);
            }
            drawingManager.setDrawingMode(null);
            window.google.maps.event.addListener(rectangle, "bounds_changed", function (setatobj, obj2) {
                handleSearchByRectangle(rectangle, searchByAreaFunc);
            });
            window.google.maps.event.addListener(drawingManager, "polygoncomplete", function (newPolygon) {
                clearSelection(rectangle);
            });
            window.google.maps.event.addListener(drawingManager, "rectanglecomplete", function (newRectangle) {
                clearSelection(rectangle);
            });
        });

        const saveMapType = this.saveMapType;
        window.google.maps.event.addListener(map, "maptypeid_changed", function () {
            saveMapType({ mapTypeId: map.getMapTypeId() });
        });
    };

    restoreMapSettingsifNecessary = () => {
        if (
            this.state.map &&
            this.props.requestId &&
            this.props.mapSearchSettings &&
            this.props.mapSearchSettings.requestId &&
            this.props.mapSearchSettings.requestId != null &&
            this.props.mapSearchSettings.requestId === this.props.requestId
        ) {
            this.state.map.setMapTypeId(this.props.mapSearchSettings.mapTypeId);
            this.state.map.fitBounds(this.props.mapSearchSettings.bounds);
            this.state.map.setZoom(this.props.mapSearchSettings.zoom);
            this.props.saveMapSearchSettings(null);
            this.markerCluster.repaint();
        }
    };

    getMapOptions = () => {
        return {
            center: {
                lat: 39.96,
                lng: -115.19,
            },
            zoom: 5,
            streetViewControl: false,
            scaleControl: true,
            fullscreenControl: true,
            styles: [
                {
                    featureType: "poi.business",
                    elementType: "labels",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
            ],
            gestureHandling: "greedy",
            disableDoubleClickZoom: false,
            minZoom: 0,
            maxZoom: 26,
            mapTypeControl: true,
            mapTypeId: this.state.mapTypeId ? this.state.mapTypeId : window.google.maps.MapTypeId.ROADMAP,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [
                    window.google.maps.MapTypeId.ROADMAP,
                    window.google.maps.MapTypeId.SATELLITE,
                    window.google.maps.MapTypeId.HYBRID,
                ],
            },
            zoomControl: true,
            clickableIcons: false,
        };
    };

    renderClusterMarkers(map, maps) {
        if (this.polygonRef != null && Object.keys(this.polygonRef).length !== 0) {
            this.polygonRef.setMap(map);
        }

        if (this.markers) {
            this.markers.forEach((marker) => {
                marker.setMap(null);
            });
        }

        this.markers = [];
        if (this.markerCluster) {
            this.markerCluster.clearMarkers();
        }

        if (!this.props.searchCleared && this.props.places && Object.keys(this.props.places).length !== 0 && maps) {
            const mapHoverPin = {
                url: "https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/MarkerWhiteBorder.svg",
                height: "32",
                width: "23",
                textColor: "white",
                labelOrigin: { x: 12, y: 12 },
                anchor: new maps.Point(26, 32),
            };
            const mapPin = {
                url: "https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/MarkerBlackBorder.svg",
                height: "32",
                width: "23",
                textColor: "white",
                labelOrigin: { x: 12, y: 12 },
                anchor: new maps.Point(26, 32),
            };

            this.infowindow = new maps.InfoWindow();
            const locationMap = new Map();
            this.props.places.forEach((place) => {
                if (place.latitude && place.longitude) {
                    const latLng = place.latitude + "," + place.longitude;
                    if (locationMap.has(latLng)) {
                        const locations = locationMap.get(latLng);
                        locations.push(place);
                    } else {
                        locationMap.set(latLng, [place]);
                    }
                }
            });

            locationMap.forEach((entryValue, entryKey) => {
                const marker = new maps.Marker({
                    position: new maps.LatLng(
                        entryValue[0].latitude, //place.latitude,
                        entryValue[0].longitude //place.longitude,
                    ),
                    map: map,
                    icon: mapPin,
                    title: entryValue[0].property_name,
                    content: {
                        places: entryValue,
                        markup: this.props.markerLabelContentFunc(entryValue, this.props.isListing), //this.infowindowContent(place),
                    },

                    label: {
                        text: entryValue.length > 1 ? `${entryValue.length}` : null,
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "bold",
                    },
                });

                this.markers.push(marker);
                marker.addListener("mouseover", function (e) {
                    marker.setIcon(mapHoverPin);
                });

                marker.addListener("mouseout", function (e) {
                    marker.setIcon(mapPin);
                });

                this.toggleInfoWindow(map, maps, marker, this.infowindow);
            });

            if (this.markerCluster) {
                this.markerCluster.addMarkers(this.markers);
            }
            if (this.polygonRef == null || Object.keys(this.polygonRef).length === 0) {
                this.centerMap(map, maps);
            }
        }
    }

    toggleInfoWindow(map, maps, marker, infowindow) {
        maps.event.addListener(marker, "click", function () {
            infowindow.setContent(marker.content.markup);
            infowindow.open(map, marker);
        });

        maps.event.addListener(map, "click", function (event) {
            if (infowindow) {
                infowindow.close(map, infowindow);
            }
        });
    }

    centerMap(map, maps) {
        const bounds = new maps.LatLngBounds();

        this.markers.forEach((marker) => {
            bounds.extend(marker.getPosition());
        });

        if (this.markers.length === 1) {
            map.setCenter(bounds.getCenter());
        } else {
            map.fitBounds(bounds);
        }
    }

    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{ width: "100%", height: "100%", minHeight: "425px" }} id={"map"}></div>
        );
    }
}

export default withStyles(styles)(GoogleMap);
