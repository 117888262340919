import React from "react";
import { connect } from "react-redux";
import EVPDashboardContainer from "./EVPDashboardContainer";
import BrokerDashboardContainer from "./BrokerDashboardContainer";
import operations from "./ducks/operations";
import includes from "lodash/includes";
import ReviewPropertiesContainer from "../property/ReviewPropertiesContainer";
import ReactGA from "react-ga4";

class DashboardContainer extends React.Component {
    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: "/dashboard", title: "Dashboard" });
    }

    render() {
        return (this.props.authenticatedUserProfile.role === "dataEntry" ||
            this.props.authenticatedUserProfile.role === "dataAdmin" ||
            this.props.authenticatedUserProfile.user === this.props.userProfile.user) &&
            includes(["broker", "portlandBrokerAdmin", "arizonaBrokerAdmin"], this.props.userProfile.role) ? (
            <BrokerDashboardContainer {...this.props} />
        ) : (this.props.authenticatedUserProfile.role === "dataEntry" ||
              this.props.authenticatedUserProfile.role === "dataAdmin" ||
              this.props.authenticatedUserProfile.user === this.props.userProfile.user) &&
          includes(["evp"], this.props.userProfile.role) ? (
            <EVPDashboardContainer {...this.props} />
        ) : (
            <ReviewPropertiesContainer {...this.props} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.authReducer.userProfile,
        authenticatedUserProfile: state.authReducer.authenticatedUserProfile,
        loading: state.utilsReducer.loading,
        sort: state.dashboardReducer.sort,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleSort: (component, column) => {
            dispatch(operations.handleSort(component, column));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
