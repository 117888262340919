import React, { Component } from "react";
import { connect } from "react-redux";
import Property from "./beta/PropertyBeta";
import operations from "./ducks/operations";
import constants from "./constants";
import PropertyToolbar from "./PropertyToolbar";
import get from "lodash/get";
import DocumentDrop from "../documents/DocumentDropContainer";
import Comments from "../comments/CommentsContainer";
import comments_constants from "../comments/constants";
import utils from "../utils";
import comments_operations from "../comments/ducks/operations";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "../../../../node_modules/@material-ui/icons/ExpandMore";
import AutosaveSuccess from "../../../../node_modules/@material-ui/icons/CheckCircle";
import Divider from "@material-ui/core/Divider";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import BSInput from "../../../common/js/components/BSInput";
import ReactGA from "react-ga4";
import "./beta/Property.css";

class PropertyContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.props.getProperty(id);
        }
        ReactGA.send({ hitType: "pageview", page: "/property/*", title: `Property Summary: ${id}` });
    }

    componentWillUnmount() {
        this.props.clearPropertyState();
    }

    render() {
        const { id } = this.props.match.params;

        return (
            <div>
                <PropertyToolbar {...this.props} />
                <div className="propertyContainerListView">
                    <div className="propertyFlexField">
                        <ExpansionPanel defaultExpanded>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <AutosaveSuccess className="waterloo" /> Property Information
                            </ExpansionPanelSummary>
                            <Divider />
                            <ExpansionPanelDetails>
                                <Property {...this.props} errors={get(this.props.errors, "property", {})} />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel defaultExpanded>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                Property Comments
                            </ExpansionPanelSummary>
                            <Divider />
                            <ExpansionPanelDetails>
                                <div>
                                    <BSInput
                                        id="memberComments"
                                        label="Internal Comments"
                                        placeholder="Comments entered here are only visible inside KMConnect."
                                        error={this.props.errors.memberComments}
                                        value={this.props.property.memberComments}
                                        multiline={true}
                                        disabled={this.props.disableInputs}
                                        rows={5}
                                        onChange={(e) =>
                                            this.props.handleInputChange("property." + e.target.id, e.target.value)
                                        }
                                    />
                                </div>
                                <div>
                                    <BSInput
                                        id="publicComments"
                                        label="Public Comments"
                                        placeholder="Comments entered here are PUBLIC and will be VISIBLE on excel exports."
                                        error={this.props.errors.publicComments}
                                        value={this.props.property.publicComments}
                                        disabled={this.props.disableInputs}
                                        multiline={true}
                                        rows={5}
                                        onChange={(e) =>
                                            this.props.handleInputChange("property." + e.target.id, e.target.value)
                                        }
                                    />
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <div className="propertyFlexField">
                        <DocumentDrop
                            entityKey={id}
                            type="property"
                            documentTypes="propertyForm"
                            formDisabled={this.props.disableInputs}
                            parentType={"PROPERTY"}
                            stateCode={get(this.props.property, "primaryAddress.city.state.stateCode", null)}
                        />
                    </div>
                    <div>
                        <Comments
                            parentRecordType={comments_constants.PROPERTY_PARENT_RECORD_TYPE}
                            parentRecordId={id}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.authReducer.userProfile,
        property: get(state, "propertyReducer.property", false),
        propertyType: get(state.propertyReducer, "property.propertyType", null),
        attributes: get(state.propertyReducer, "property.propertyAttributes", {}),
        parcels: get(state.propertyReducer, "property.propertyAttributes.parcels", []),
        errors: state.utilsReducer.errors ? state.utilsReducer.errors : {},
        searchResults: state.propertyReducer.searchResults,
        classes: state.propertyReducer.classes,
        isSaving: state.propertyReducer.isSaving ? state.propertyReducer.isSaving : null,
        saveError: state.propertyReducer.saveError ? state.propertyReducer.saveError : null,
        lastSaved: state.propertyReducer.lastSaved ? state.propertyReducer.lastSaved : null,
        numComments: state.commentsReducer.comments.length,
        snackbar: state.utilsReducer.snackbar,
        confirmDialog: state.utilsReducer.confirmDialog,
        disableInputs: get(
            constants.disabledStatuses,
            `${state.authReducer.userProfile.role}.${get(
                state.propertyReducer,
                "property.propertyAttributes.propertyStatus"
            )}`,
            true
        ),
        elementsSaving: state.utilsReducer.elementsSaving,
        numResults: state.propertyReducer.numResults,
        displayDeleteDialog: state.propertyReducer.displayDeleteDialog,
        displayNoDeleteDialog: state.propertyReducer.displayNoDeleteDialog,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProperty: (propertyKey) => {
            dispatch(operations.getProperty(propertyKey));
        },
        handleSave: () => {
            dispatch(operations.handleSave());
        },
        handleSaveAndExit: () => {
            dispatch(operations.handleSaveAndExit());
        },
        handleSubmit: (save, callback) => {
            dispatch(utils.operations.toggleConfirm());
            // if (save)
            dispatch(operations.handleSubmitPropertyForm(save, callback));
        },
        handleApprovePropertyRequestAsDataEntry: (save, callback) => {
            dispatch(utils.operations.toggleConfirm());
            dispatch(operations.handleApprovePropertyRequestAsDataEntry(save, callback));
        },
        handleInputChange: (field, value) => {
            dispatch(operations.handleInputChange(field, value));
        },

        toggleSnackBar: (open, variant, message) => {
            dispatch(utils.operations.snackbar(open, variant, message));
        },
        toggleConfirm: (onSubmit) => {
            dispatch(utils.operations.toggleConfirm(onSubmit));
        },
        handleDocumentWarning: (open, proceed, docs) => {
            dispatch(operations.handleDocumentWarning(open, proceed, docs));
        },
        handleConfirmWarning: () => {
            dispatch(operations.handleDocumentWarning(false, true, []));
        },
        toggleComments: () => {
            dispatch(comments_operations.toggleComments());
        },
        handleAddArrayElement: (arrayPath) => {
            dispatch(operations.addArrayElement(arrayPath));
        },
        handleDeleteArrayElement: (arrayPath, index) => {
            dispatch(operations.deleteArrayElement(arrayPath, index));
        },
        handleUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.handleInputChange(arrayPath + "[" + index + "]." + field, value));
        },
        clearPropertyState: () => {
            dispatch(operations.clearPropertyDetailState());
        },
        handleChooseDisplayDeleteDialog: (isOpen, propertyKey) => {
            dispatch(operations.displayDeleteDialog(isOpen, propertyKey));
        },
        handleDeleteProperty: (propertyKey) => {
            dispatch(operations.handleDeleteProperty(propertyKey));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyContainer);
