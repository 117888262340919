import React from "react";
import toLower from "lodash/toLower";
import { Divider, FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import BSInput from "../../../../common/js/components/BSInput";
import BSSelect from "../../../../common/js/components/BSSelect";
import { topography_options } from "../../deal/constants";
import "./LandForm.css";

const LandForm = (props) => {
    const { context, data, disableInputs, errors, listingStatus, onChange, transactionType } = props;

    const handleAttributeUpdate = (event) => {
        onChange(event.target.id, event.target.value);
    };

    return (
        <React.Fragment>
            {context === "property" && (
                <div>
                    <div className="landFlexContainerUnresponsive">
                        <div className="landFlexField">
                            <BSInput
                                id="landType"
                                label="LAND TYPE"
                                placeholder="Enter land type"
                                error={errors.landType}
                                value={data.landType}
                                onChange={handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </div>
                        <div className="landFlexField">
                            <BSInput
                                id="visibility"
                                label="VISIBILITY"
                                placeholder="Enter visibility"
                                error={errors.visibility}
                                value={data.visibility}
                                onChange={handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </div>
                    </div>
                    <div>
                        <BSInput
                            id="frontageRoad"
                            label="ROAD FRONTAGE ON"
                            placeholder="Enter road name"
                            error={errors.frontageRoad}
                            value={data.frontageRoad}
                            onChange={handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </div>
                    <Divider />
                    <div className="landExistingStructureContainer">
                        <span className="landExistingStructureText">Existing Structure</span>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.existingStructureFlg === true ? true : false}
                                    onChange={(e, checked) => onChange(e.target.value, checked)}
                                    value="existingStructureFlg"
                                    color="primary"
                                    disabled={disableInputs}
                                />
                            }
                        />
                    </div>
                </div>
            )}

            {context === "property" && data.existingStructureFlg && (
                <div>
                    <div style={{ display: "flex", gap: "1vw" }}>
                        <div style={{ flex: "1 1 0", height: "90px" }}>
                            <BSInput
                                id="existingStructureNotes"
                                label="EXISTING STRUCTURE NOTES"
                                placeholder="Enter notes"
                                error={errors.existingStructureNotes}
                                value={data.existingStructureNotes}
                                onChange={handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </div>
                        <div style={{ flex: "1 1 0", height: "90px" }}>
                            <BSInput
                                id="demolitionDate"
                                label="DEMOLITION DATE"
                                placeholder="Enter date"
                                error={errors.demolitionDate}
                                value={data.demolitionDate}
                                onChange={handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </div>
                    </div>
                </div>
            )}

            {context === "listing" && (
                <div>
                    <div className="landFlexContainerUnresponsive">
                        <div className="landFlexField">
                            <BSInput
                                id="sfAvail"
                                label="SF AVAIL"
                                placeholder="Enter SF"
                                errorText="ex. 98101"
                                error={errors.sfAvail}
                                value={data.sfAvail}
                                onChange={handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </div>
                        <div className="landFlexField">
                            <BSInput
                                id="totalAcres"
                                label="ACRES AVAILABLE"
                                placeholder="Enter acreage"
                                errorText="ex. 98.01"
                                error={errors.totalAcres}
                                value={data.totalAcres}
                                onChange={handleAttributeUpdate}
                                disabled={disableInputs}
                            />
                        </div>
                    </div>
                    {toLower(transactionType) === "sale" && listingStatus === "Appraisal" && (
                        <React.Fragment>
                            <div className="landFlexContainerUnresponsive">
                                <div className="landFlexField">
                                    <BSSelect
                                        id="topography"
                                        label="Topography"
                                        placeholder="Select Topography"
                                        error={errors.topography}
                                        value={data.topography}
                                        onChange={handleAttributeUpdate}
                                        disabled={disableInputs}
                                        options={topography_options}
                                    />
                                </div>
                                <div className="landFlexField">
                                    <BSInput
                                        id="zoning"
                                        label="Zoning"
                                        placeholder="Enter zoning"
                                        error={errors.zoning}
                                        value={data.zoning}
                                        onChange={handleAttributeUpdate}
                                        disabled={disableInputs}
                                    />
                                </div>
                            </div>
                            <div className="landFlexContainerUnresponsive">
                                <BSInput
                                    id="entitlements"
                                    label="Entitlements"
                                    placeholder="Enter entitlements"
                                    error={errors.entitlements}
                                    value={data.entitlements}
                                    onChange={handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </div>
                            <div className="landFlexContainerUnresponsive">
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.wetlandsFlg ? true : false}
                                                onChange={(e, checked) => onChange(e.target.value, checked)}
                                                value="wetlandsFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Wetlands"
                                    />
                                </div>
                                <div>
                                    <BSInput
                                        id="wetlandsPct"
                                        placeholder="Enter wetlands %"
                                        error={errors.wetlandsPct}
                                        value={data.wetlandsPct}
                                        onChange={handleAttributeUpdate}
                                        disabled={disableInputs}
                                        numeric
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                        extraInputStyles={{ marginTop: "0px" }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
            {(context === "listing" || (context === "property" && data.existingStructureFlg)) && (
                <React.Fragment>
                    <div className="landCheckboxFlexContainer">
                        <div className={context === "listing" ? "landCheckboxes" : "landCheckboxesColumn"}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.electricFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="electricFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Electric"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.curbCutsFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="curbCutsFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Curb Cuts"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.cornerLocationFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="cornerLocationFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Corner Lot"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.waterfrontFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="waterfrontFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Waterfront"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.railServiceFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="railServiceFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Rail Service"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.easementsFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="easementsFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Easements"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.divisibleFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="divisibleFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Divisible"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.visibilityFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="visibilityFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Visibility"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.clearedFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="clearedFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Cleared"
                                />
                            </FormGroup>
                        </div>
                        <div className={context === "listing" ? "landCheckboxes" : "landCheckboxesColumn"}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.pavedFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="pavedFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Paved"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.parkingLotFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="parkingLotFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Parking Lot"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.sewerFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="sewerFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Sewer"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.waterFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="waterFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Water"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.stormSewerFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="stormSewerFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Storm Sewer"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.gasAvailFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="gasAvailFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Gas Line"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.techFlg ? true : false}
                                            onChange={(e, checked) => onChange(e.target.value, checked)}
                                            value="techFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Tech"
                                />
                                <div className="landOtherCheckbox">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.otherFlg ? true : false}
                                                onChange={(e, checked) => onChange(e.target.value, checked)}
                                                value="otherFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Other"
                                    />
                                    <BSInput
                                        id="otherFlgDesc"
                                        placeholder="Enter other"
                                        error={errors.otherFlgDesc}
                                        value={data.otherFlgDesc}
                                        onChange={handleAttributeUpdate}
                                        disabled={disableInputs}
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                        extraInputStyles={{ marginTop: "0px" }}
                                    />
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default LandForm;
