import configs from "../../../config.js";

const DEFAULT_RESULT_SIZE = 300;
const PROPERTY_SEARCH_TYPE = "PROPERTY";
const LISTING_SEARCH_TYPE = "LISTINGS";
const COMP_SEARCH_TYPE = "COMPS";
const DEAL_SEARCH_TYPE = "DEAL";
const DEBOUNCE_SEARCH_DELAY = 1000;

const HOST = configs.HOST_URL;
const SEARCH_URL = HOST + "/search";
const PROPERTY_URL = HOST + "/properties";
const DATA_EXPORT_URL = HOST + "/search/export";
const ADMIN_URL = HOST + "/admin";

export const property_search_export_column_mappings = [
    { label: "Property ID", name: "property_id", width: { wch: 14 } },
    { label: "Property Name", name: "property_name", width: { wch: 50 } },
    { label: "Property Address", name: "property_address", width: { wch: 40 } },
    { label: "City", name: "city", width: { wch: 14 } },
    { label: "State", name: "state_code", width: { wch: 14 } },
    { label: "Num Listings", name: "num_listings", width: { wch: 14 } },
    { label: "Total SF", name: "total_bldg_sf", width: { wch: 14 } },
    { label: "Use Type", name: "property_type", width: { wch: 16 } },
    { label: "KM Managed?", name: "km_managed_flg", width: { wch: 16 } },
    { label: "Year Built", name: "year_built", width: { wch: 14 } },
    { label: "Building Status", name: "bldg_status", width: { wch: 16 } },
    { label: "Total Acres", name: "total_acres", width: { wch: 14 } },
];

export const deal_search_export_column_mappings = [
    { label: "Deal ID", name: "dealKey" },
    { label: "Voucher IDs", name: "voucherIds" },
    { label: "Property Address", name: "propertyAddress" },
    { label: "Property Name", name: "propertyName" },
    { label: "Deal Property Type", name: "propertyType" },
    { label: "Deal Sub-Type", name: "subType" },
    { label: "Brokers On Transaction", name: "brokerNames" },
    { label: "Transaction Type", name: "transactionType" },
    { label: "Seller/Landlord", name: "landlordSellerName" },
    { label: "Buyer/Tenant", name: "buyerTenantName" },
    { label: "Deal Status", name: "dealStatus" },
    { label: "Total Consideration", name: "totalConsiderationAmount" },
    { label: "Deal Summary URL", name: "dealSummaryURL" },
];

export const criteriaCrumbs = {
    listingTypeCriteria: {
        forLease: {
            display: "Lease",
        },
        forSale: {
            display: "Sale",
        },
        forSublease: {
            display: "Sublease Only",
        },
        excludeSubleases: {
            display: "Exclude Subleases",
        },
        investmentSaleFlg: {
            display: "Investment Only",
        },
        availableBefore: {
            display: "Available Before",
        },
        kmListingsOnly: {
            display: "KM Listings Only",
        },
        expiredOnly: {
            display: "Expired Only",
        },
    },
    priceCriteria: {
        minPrice: {
            display: "Min Price",
        },
        maxPrice: {
            display: "Max Price",
        },
        minLeaseRate: {
            display: "Min Lease Rate",
        },
        maxLeaseRate: {
            display: "Max Lease Rate",
        },
        leaseRateType: {
            display: "Lease Rate Type",
        },
    },
    dealTypeCriteria: {
        lease: {
            display: "Lease",
        },
        sale: {
            display: "Sale",
        },
        sublease: {
            display: "Sublease Only",
        },
        excludeSubleases: {
            display: "Exclude Subleases",
        },
        investmentSaleFlg: {
            display: "Investment Only",
        },
        minLeaseRate: {
            display: "Min Lease Rate",
        },
        maxLeaseRate: {
            display: "Max Lease Rate",
        },
        minPrice: {
            display: "Min Price",
        },
        maxPrice: {
            display: "Max Price",
        },
        leaseRateType: {
            display: "Lease Rate Type",
        },
        transactionDateFrom: {
            display: "Date Signed From",
        },
        transactionDateTo: {
            display: "Date Signed To",
        },
        validatedCompsOnly: {
            display: "Validated Comps Only",
        },
        kmCompsOnly: {
            display: "KM Comps Only",
        },
        appraisalCompsOnly: {
            display: "Appraisal Comps Only",
        },
        outsideCompsOnly: {
            display: "Outside Comps Only",
        },
        confidentialComps: {
            display: "Confidential Comps",
        },
    },
    statusCriteria: {
        existing: {
            display: "Existing",
        },
        proposed: {
            display: "Proposed",
        },
        kmManagedOnly: {
            display: "KM Managed Only",
        },
        underConstruction: {
            display: "Under Construction",
        },
        underRenovation: {
            display: "Under Renovation",
        },
        demolished: {
            display: "Demolished",
        },
        land: {
            display: "Land",
        },
    },
    tenancyCriteria: {
        single: {
            display: "Single",
        },
        multi: {
            display: "Multi",
        },
    },
    locationCriteria: {
        cities: {
            display: "Cities",
        },
        counties: {
            display: "Counties",
        },
        states: {
            display: "States",
        },
        zips: {
            display: "ZIPs",
        },
    },
    sizeCriteria: {
        totalTransactionSf: {
            compDisplay: "Total SF",
            listingDisplay: "Leased/Sold SF",
        },
        totalSf: {
            compDisplay: "Total SF",
            listingDisplay: "Total SF",
        },
        acreage: {
            compDisplay: "Total Acreage",
            listingDisplay: "Total Acreage",
        },
        availableSf: {
            compDisplay: "Leased/Sold SF",
            listingDisplay: "Available SF",
        },
        min: {
            display: "Min",
        },
        max: {
            display: "Max",
        },
    },
};

const kmOffices = {
    Bellevue: {
        display: "Bellevue",
    },
    Boise: {
        display: "Boise",
    },
    Carlsbad: {
        display: "Carlsbad",
    },
    "El Segundo": {
        display: "El Segundo",
    },
    Irvine: {
        display: "Irvine",
    },
    "Los Angeles": {
        display: "Los Angeles",
    },
    Olympia: {
        display: "Olympia",
    },
    Ontario: {
        display: "Ontario",
    },
    Pasadena: {
        display: "Pasadena",
    },
    Phoenix: {
        display: "Phoenix",
    },
    Pleasanton: {
        display: "Pleasanton",
    },
    Portland: {
        display: "Portland",
    },
    "Redwood City": {
        display: "Redwood City",
    },
    Reno: {
        display: "Reno",
    },
    Roseville: {
        display: "Roseville",
    },
    Sacramento: {
        display: "Sacramento",
    },
    "San Diego": {
        display: "San Diego",
    },
    "San Francisco": {
        display: "San Francisco",
    },
    "San Fernando Valley": {
        display: "San Fernando Valley",
    },
    "San Jose": {
        display: "San Jose",
    },
    Seattle: {
        display: "Seattle",
    },
    Tacoma: {
        display: "Tacoma",
    },
    Tukwila: {
        display: "Tukwila",
    },
    "West LA": {
        display: "West LA",
    },
};

const activeKmOffices = {
    Bellevue: {
        display: "Bellevue",
    },
    Boise: {
        display: "Boise",
    },
    Carlsbad: {
        display: "Carlsbad",
    },
    "El Segundo": {
        display: "El Segundo",
    },
    Irvine: {
        display: "Irvine",
    },
    "Los Angeles": {
        display: "Los Angeles",
    },
    Olympia: {
        display: "Olympia",
    },
    Pasadena: {
        display: "Pasadena",
    },
    Phoenix: {
        display: "Phoenix",
    },
    Pleasanton: {
        display: "Pleasanton",
    },
    Portland: {
        display: "Portland",
    },
    "Redwood City": {
        display: "Redwood City",
    },
    Reno: {
        display: "Reno",
    },
    Roseville: {
        display: "Roseville",
    },
    Sacramento: {
        display: "Sacramento",
    },
    "San Diego": {
        display: "San Diego",
    },
    "San Francisco": {
        display: "San Francisco",
    },
    "San Fernando Valley": {
        display: "San Fernando Valley",
    },
    "San Jose": {
        display: "San Jose",
    },
    Seattle: {
        display: "Seattle",
    },
    Tacoma: {
        display: "Tacoma",
    },
    Tukwila: {
        display: "Tukwila",
    },
    "West LA": {
        display: "West LA",
    },
};

const departments = {
    Brokerage: {
        display: "Broker",
    },
    "Valuation Advisory Services": {
        display: "VAS",
    },
    "Brokerage Admin Support": {
        display: "BS",
    },
    Corporate: {
        display: "Corp",
    },
    "Corporate Marketing": {
        display: "Corp",
    },
    "Corporate IT": {
        display: "Corp",
    },
    "Corporate Accounting": {
        display: "Corp",
    },
    "Corporate Research": {
        display: "Corp",
    },
    "Corporate Data": {
        display: "Corp",
    },
    "Corporate HR": {
        display: "Corp",
    },
    "Kidder Mathews KM": {
        display: "Corp",
    },
    "Property Management": {
        display: "Asset Services",
    },
    "Asset Services": {
        display: "Asset Services",
    },

    "N/A": {
        display: "N/A",
    },
};

export const searchPropertyCategory = [
    {
        value: "GENERAL",
        label: "General",
    },
    {
        value: "ACCT_SSID",
        label: "Accounting Software ID",
    },
];

const urls = {
    search: (type) => {
        return SEARCH_URL + "/" + type.toLowerCase();
    },
    search_by_assid: () => {
        return SEARCH_URL + "/assid";
    },
    search_by_map: (type) => {
        return SEARCH_URL + "/map/" + type.toLowerCase();
    },
    retrieve_data_for_export: (type) => {
        return DATA_EXPORT_URL + "/" + type.toLowerCase();
    },
    retrieve_selected_data_for_export: (type) => {
        return DATA_EXPORT_URL + "/selected/" + type.toLowerCase();
    },
    search_property_autocomplete: (term) => {
        return PROPERTY_URL + "/search?term=" + term;
    },
    search_km_person_autocomplete: (term) => {
        return ADMIN_URL + "/person/km/search?term=" + term;
    },
    find_companies_with_name_starting_with: (input) => {
        return ADMIN_URL + "/company/search?term=" + input;
    }, //shouldn't be used anymore
    find_companies_by_term_and_type: (term, companyType, excludeParentCompanies) => {
        return (
            ADMIN_URL +
            "/company/search/attributes?term=" +
            encodeURIComponent(term) +
            "&companyType=" +
            companyType +
            "&approved=true&excludeParentCompanies=" +
            excludeParentCompanies
        );
    },
    find_brokers_with_name_starting_with: (input) => {
        return ADMIN_URL + "/search/broker/km?term=" + input;
    },
    find_outside_brokers_with_name_starting_with: (input) => {
        return ADMIN_URL + "/search/broker/outside?term=" + input;
    },
    find_km_brokers_with_names_like: (input) => {
        return ADMIN_URL + "/search/broker/km?term=" + input;
    },
    find_users_with_names_like: (input) => {
        return ADMIN_URL + "/search/user?term=" + input;
    },
    get_saved_searches: (searchType, userAccountKey) => {
        return SEARCH_URL + "/find/saved/" + searchType.toLowerCase() + "/" + userAccountKey;
    },
    delete_saved_search: (searchId) => {
        return SEARCH_URL + "/delete/saved/" + searchId;
    },
    save_search: () => {
        return SEARCH_URL + "/save";
    },
    save_group: () => {
        return SEARCH_URL + "/save/group";
    },
    load_group: (searchType) => {
        return SEARCH_URL + "/load/group/" + searchType;
    },
    load_users_groups: (userAccountKey, searchType) => {
        return SEARCH_URL + "/find/groups/" + userAccountKey + "/" + searchType;
    },
    delete_saved_group: (groupId) => {
        return SEARCH_URL + "/delete/saved/group/" + groupId;
    },
    find_parent_company_by_id: (id) => {
        return ADMIN_URL + "/parentCompany/" + id;
    },
    find_parent_company_by_term: (term, type) => {
        return ADMIN_URL + "/company/search?term=" + encodeURIComponent(term) + "&parentFlg=true&companyType=" + type;
    },
};

export default {
    DEFAULT_RESULT_SIZE,
    PROPERTY_SEARCH_TYPE,
    DEAL_SEARCH_TYPE,
    LISTING_SEARCH_TYPE,
    COMP_SEARCH_TYPE,
    DEBOUNCE_SEARCH_DELAY,
    urls,
    departments,
    kmOffices,
    activeKmOffices,
    searchPropertyCategory,
};
