import React from "react";
import get from "lodash/get";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import BSInput from "../../../../common/js/components/BSInput";
import "./Property.css";

const styles = () => ({
    iconOnly: {
        marginLeft: 0,
        marginBottom: "10px",
        padding: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
});

const Parcel = (props) => {
    const { classes, disableInputs, element, elementIndex, errors, handleUpdateArrayElement, onDelete } = props;

    return (
        <div>
            <div className="propertyFlexContainerUnresponsive">
                <div className="propertyFlexField">
                    <BSInput
                        id="latitude"
                        label="LATITUDE"
                        placeholder="Enter latitude"
                        value={element ? element.latitude : null}
                        error={get(errors, `parcels.${elementIndex}.latitude`, null)}
                        onChange={(e) =>
                            handleUpdateArrayElement(
                                "property.propertyAttributes.parcels",
                                elementIndex,
                                e.target.id,
                                e.target.value
                            )
                        }
                        disabled={disableInputs}
                    />
                </div>
                <div className="propertyFlexField">
                    <BSInput
                        id="longitude"
                        label="LONGITUDE"
                        placeholder="Enter longitude"
                        value={element ? element.longitude : null}
                        error={get(errors, `parcels.${elementIndex}.longitude`, null)}
                        onChange={(e) =>
                            handleUpdateArrayElement(
                                "property.propertyAttributes.parcels",
                                elementIndex,
                                e.target.id,
                                e.target.value
                            )
                        }
                        disabled={disableInputs}
                    />
                </div>
                <div className="propertyFlexField">
                    <BSInput
                        id="apn"
                        label="APN/PARCEL CODE/PARCEL ID"
                        placeholder="Enter parcel number"
                        value={element ? element.apn : null}
                        error={get(errors, `parcels.${elementIndex}.parcelNumber`, null)}
                        onChange={(e) =>
                            handleUpdateArrayElement(
                                "property.propertyAttributes.parcels",
                                elementIndex,
                                e.target.id,
                                e.target.value
                            )
                        }
                        disabled={disableInputs}
                    />
                </div>
                {elementIndex > 0 && disableInputs !== true && (
                    <div style={{ alignContent: "center" }}>
                        <Button onClick={() => onDelete(elementIndex)} className={classes.iconOnly}>
                            <Delete />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withStyles(styles)(Parcel);
