import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import PropertyForm from "./PropertyForm";
import FormTitle from "../../../common/js/components/FormTitle";
import BuildingForm from "./BuildingForm";
import MixedUseForm from "./MixedUseForm";
import ParkingInfoForm from "./ParkingInfoForm";
import get from "lodash/get";
import toLower from "lodash/toLower";
import OfficeForm from "./OfficeForm";
import HealthcareForm from "../property/HealthcareForm";
import IndustrialForm from "./IndustrialForm";
import RetailForm from "./RetailForm";
import LandForm from "./LandForm";
import MultifamilyForm from "./MultifamilyForm";
import HospitalityForm from "./HospitalityForm";
import OtherForm from "./OtherForm";

const styles = {};

const Property = (props) => {
    const { attributes, errors, handleInputChange, property, propertyType } = props;

    return (
        <div>
            <PropertyForm {...props} propertyType={get(property, "propertyType", null)} />
            <Divider />
            <FormTitle title="Building Information" />
            <BuildingForm
                {...props}
                errors={get(errors, "propertyAttributes", {})}
                data={attributes}
                context="property"
                onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
            />
            <Divider />
            <MixedUseForm
                {...props}
                data={attributes}
                onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                dataPathPrefix={`property.propertyAttributes`}
            />
            <Divider />
            <FormTitle title="Parking Information" />
            <ParkingInfoForm
                {...props}
                errors={get(errors, "propertyAttributes", {})}
                data={attributes}
                context="property"
                onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
            />
            {toLower(propertyType) === "office" && (
                <div>
                    <Divider />
                    <FormTitle title="Office Information" />
                    <OfficeForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "healthcare" && (
                <div>
                    <Divider />
                    <FormTitle title="Healthcare Information" />
                    <HealthcareForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "industrial" && (
                <div>
                    <Divider />
                    <FormTitle title="Industrial Information" />
                    <IndustrialForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "life science" && (
                <div>
                    <Divider />
                    <FormTitle title="Life Science Information" />
                    <IndustrialForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "retail" && (
                <div>
                    <Divider />
                    <FormTitle title="Retail Information" />
                    <RetailForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                        dataPathPrefix={`property.propertyAttributes`}
                    />
                </div>
            )}
            {toLower(propertyType) === "land" && (
                <div>
                    <Divider />
                    <FormTitle title="Land Information" />
                    <LandForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "multifamily" && (
                <div>
                    <Divider />
                    <FormTitle title="Multifamily Information" />
                    <MultifamilyForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "hospitality" && (
                <div>
                    <Divider />
                    <FormTitle title="Hospitality Information" />
                    <HospitalityForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "other" && (
                <div>
                    <Divider />
                    <FormTitle title="Other Information" />
                    <OtherForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(Property);
