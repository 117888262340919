import React from "react";
import MarketingFlyerModalBody from "./MarketingFlyerModalBody";
import GenerateImageEditorButtons from "./GenerateImageEditorButtons";
import { Grid } from "@material-ui/core";

const MarketingFlyerModalBodyContainer = (props) => {
    const {
        classes,
        handleCloseModal,
        handleGenerateMarketingFlyerReport,
        handleSelectPhotoFromDropdown,
        shouldDisableButtons,
    } = props;

    return (
        <React.Fragment>
            <MarketingFlyerModalBody {...props} />
            <Grid item xs={4} style={{ paddingTop: "22px", textAlign: "end" }}>
                <GenerateImageEditorButtons
                    classes={classes}
                    handleCloseModal={handleCloseModal}
                    handleGenerateClick={handleGenerateMarketingFlyerReport}
                    handleSelectPhotoFromDropdown={handleSelectPhotoFromDropdown}
                    shouldDisableButtons={shouldDisableButtons}
                />
            </Grid>
        </React.Fragment>
    );
};

export default MarketingFlyerModalBodyContainer;
