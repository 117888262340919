import React, { useState } from "react";
import PropertyHistory from "./PropertyHistory";

const PropertyHistoryContainer = (props) => {
    const { snapshots } = props;
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <React.Fragment>
            {snapshots ? (
                <div>
                    {snapshots
                        .sort((snapshotA, snapshotB) => {
                            const terminationDateA = new Date(snapshotA.assetServicesSnapshot.terminationDate);
                            const terminationDateB = new Date(snapshotB.assetServicesSnapshot.terminationDate);
                            return terminationDateB - terminationDateA;
                        })
                        .map((snapshot, id) => {
                            const panelId = `panel${id}`;
                            const {
                                assetServicesSnapshot,
                                assetServicesContacts,
                                assetServicesPersonnel,
                                mediaAssets,
                            } = snapshot;
                            return (
                                <PropertyHistory
                                    panelId={panelId}
                                    snapshot={assetServicesSnapshot}
                                    contacts={assetServicesContacts}
                                    personnel={assetServicesPersonnel}
                                    mediaAssets={mediaAssets}
                                    handleChange={handleChange}
                                    expanded={expanded}
                                    {...props}
                                />
                            );
                        })}
                </div>
            ) : (
                ""
            )}
        </React.Fragment>
    );
};

export default PropertyHistoryContainer;
