import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import get from "lodash/get";
import includes from "lodash/includes";
import "./Comp.css";
import { NO_CONTENT_PLACEHOLDER } from "./constants";
import schema from "./reviewCompsTables";
import { getCompInfo, getLeaseRate, getSalePrice } from "./utils";
import { noPhotoUrl } from "../utils/constants";
import utils, { getFromAttributeValues, markerLabelContent } from "../utils/operations";
import GoogleMap from "../../../common/js/components/GoogleMaps";
import { Sorty } from "../../../common/js/icons";

const styles = (theme) => ({
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    lb: {
        textDecoration: "none",
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: "600",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    gridWrapper: {
        fontFamily: "IBM Plex Sans, sans-serif",
        marginRight: "10px",
        overflowX: "auto",
    },
    gridHeaderRow: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid rgb(212, 212, 212)",
        color: "#9E9E9E",
        fontSize: "12px",
        fontWeight: "bold",
        padding: "1.5em 0",
        overflow: "hidden",
        scrollbarGutter: "stable",
    },
    gridBodyRow: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid rgb(212, 212, 212)",
        fontSize: "13px",
        height: "fit-content",
        padding: "0.75em 0",
    },
    gridBodyWrapper: {
        display: "block",
        maxHeight: "50vh",
        minHeight: "425px",
        overflowY: "auto",
        scrollbarGutter: "stable",
    },
});

class CompsWithMap extends Component {
    constructor() {
        super();
        this.state = {
            selectAll: true,
        };
    }

    handleSelectAll = () => {
        const checked = this.state.selectAll;
        this.props.handleSelectAll(!checked);
        this.setState({ selectAll: !checked });
    };

    render() {
        const {
            classes,
            handleSort,
            isLoading,
            compTab,
            role,
            searchResponse,
            handleCheckboxClick,
            selectedComps,
            handleDragEnd,
            loadingSearch,
            toggleLoadingSearch,
            selectAll,
            selectAllGroup,
            searchRun,
            searchCleared,
            handleSelectAll,
            updateSearchAreaFilter,
            handleSearchCleared,
            mapCrumbCleared,
            searchPolygon,
            updateSearchPolygon,
            saveMapSearchSettings,
            mapSearchSettings,
            savedMapCoords,
            selectedCompsGroup,
            handleSelectAllGroup,
            isBrokerOnDeal,
        } = this.props;

        const comps = get(this.props.comps, compTab) || [];
        const hasComps = Object.keys(comps).length > 0;
        const disableDraw = compTab === "comps_group" ? true : false;
        const headers = schema.viewHeaders["all_km_comps_with_map"];
        const loadRows = !loadingSearch && !searchCleared && hasComps;

        const getListStyle = (isDraggingOver) => ({
            padding: "",
        });

        if (compTab === "all_km_comps" && loadingSearch && hasComps) {
            toggleLoadingSearch(false);
        }

        return (
            <div>
                <Grid container spacing={0}>
                    <Grid item xs={5} className={classes.gridWrapper}>
                        <div style={{ minWidth: "500px" }}>
                            {/* Grid Header */}
                            <Grid container spacing={12} className={classes.gridHeaderRow}>
                                {headers.map((header, i) => {
                                    return (
                                        <Grid
                                            item
                                            xs={header.name === "Address" ? 4 : header.name === "SQFT" ? 2 : 3}
                                            key={`gh-c${i}`}
                                            numeric={header.numeric}
                                            onClick={() => handleSort(header.name, compTab)}
                                            style={{ paddingRight: "7px" }}
                                        >
                                            {header.name === "Property" ? (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    {compTab === "comps_group" ? (
                                                        <Checkbox
                                                            className={
                                                                selectAllGroup
                                                                    ? "compCheckboxAllSelectedMap"
                                                                    : "compCheckboxAllMap"
                                                            }
                                                            checked={selectAllGroup}
                                                            value={selectAllGroup}
                                                            onChange={() => handleSelectAllGroup()}
                                                            color="primary"
                                                        />
                                                    ) : (
                                                        <Checkbox
                                                            className={
                                                                selectAll
                                                                    ? "compCheckboxAllSelectedMap"
                                                                    : "compCheckboxAllMap"
                                                            }
                                                            checked={selectAll}
                                                            value={selectAll}
                                                            onChange={() => handleSelectAll()}
                                                            color="primary"
                                                        />
                                                    )}
                                                    <span style={{ paddingLeft: "7px" }}>
                                                        {header.name.toUpperCase()}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ paddingRight: "7px" }}>
                                                        {header.name.toUpperCase()}
                                                    </span>
                                                    <Sorty />
                                                </div>
                                            )}
                                        </Grid>
                                    );
                                })}
                            </Grid>

                            {/* Grid Body */}
                            <DragDropContext onDragEnd={(result) => handleDragEnd(result, comps, compTab)}>
                                <Droppable droppableId="droppable" style={{ maxWidth: "100%" }}>
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            <Grid container spacing={12} className={classes.gridBodyWrapper}>
                                                {loadRows
                                                    ? comps.map((comp, i) =>
                                                          row(
                                                              comp,
                                                              i,
                                                              headers,
                                                              compTab,
                                                              role,
                                                              classes,
                                                              handleCheckboxClick,
                                                              selectedComps,
                                                              selectedCompsGroup,
                                                              isBrokerOnDeal
                                                          )
                                                      )
                                                    : null}

                                                {/* Circular progress wheel, no content notifications, etc. */}
                                                {alternateContent(compTab, loadingSearch, hasComps, searchRun, classes)}
                                            </Grid>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </Grid>

                    <Grid xs={7} style={{ flexBasis: "auto", flexGrow: 1, marginTop: "10px" }}>
                        <GoogleMap
                            disableDraw={disableDraw}
                            tab={compTab === "comps_group" ? "group" : "search"}
                            places={
                                compTab === "all_km_comps"
                                    ? searchResponse
                                        ? searchResponse.results
                                        : []
                                    : comps
                                    ? comps
                                    : []
                            }
                            requestId={searchResponse && searchResponse.requestId ? searchResponse.requestId : null}
                            savedMapCoords={savedMapCoords}
                            isLoading={isLoading}
                            searchCleared={searchCleared}
                            searchRun={searchRun}
                            markerLabelContentFunc={markerLabelContent}
                            searchByArea={updateSearchAreaFilter}
                            handleSearchCleared={handleSearchCleared}
                            mapCrumbCleared={mapCrumbCleared}
                            searchPolygon={searchPolygon}
                            updateSearchPolygon={updateSearchPolygon}
                            saveMapSearchSettings={saveMapSearchSettings}
                            mapSearchSettings={mapSearchSettings}
                            isListing={false}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const row = (
    comp,
    i,
    headers,
    compTab,
    role,
    classes,
    handleCheckboxClick,
    selectedComps,
    selectedCompsGroup,
    isBrokerOnDeal
) => {
    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging ? "#C5C5C5" : "",
        userSelect: "none",
        width: "100%",
        ...draggableStyle,
    });

    return (
        <Draggable
            draggableId={comp.dealkey}
            index={i}
            style={{ width: "100%", height: "fit-content" }}
            key={`gb-r${i}`}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <Grid container spacing={12} className={classes.gridBodyRow}>
                        {headers.map((header, j) => {
                            const compPhoto = get(comp, "listing_photo", null)
                                ? get(comp, "listing_photo", null)
                                : get(comp, "property_photo", null)
                                ? get(comp, "property_photo", null)
                                : noPhotoUrl;

                            return (
                                <Grid
                                    item
                                    key={`gb-r${i}-c${j}`}
                                    xs={header.name === "Address" ? 4 : header.name === "SQFT" ? 2 : 3}
                                    style={{ paddingRight: "7px" }}
                                >
                                    {
                                        /* --- Property --- */
                                        header.name === "Property" ? (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                {compTab === "comps_group" ? (
                                                    <Checkbox
                                                        className={
                                                            selectedCompsGroup.get(comp.dealkey) === true
                                                                ? "compCheckboxAllSelectedMap"
                                                                : "compCheckboxAllMap"
                                                        }
                                                        checked={selectedCompsGroup.get(comp.dealkey) === true}
                                                        value={selectedCompsGroup.get(comp.dealkey) === true}
                                                        onChange={() => handleCheckboxClick(comp.dealkey, compTab)}
                                                        color="primary"
                                                    />
                                                ) : (
                                                    <Checkbox
                                                        className={
                                                            selectedComps.get(comp.dealkey) === true
                                                                ? "compCheckboxAllSelectedMap"
                                                                : "compCheckboxAllMap"
                                                        }
                                                        checked={selectedComps.get(comp.dealkey) === true}
                                                        value={selectedComps.get(comp.dealkey) === true}
                                                        onChange={() => handleCheckboxClick(comp.dealkey, compTab)}
                                                        color="primary"
                                                    />
                                                )}
                                                <div
                                                    className="propertySmallImageContainer"
                                                    style={{
                                                        backgroundImage: `url(${compPhoto})`,
                                                        marginLeft: "7px",
                                                        width: "85px",
                                                        minWidth: "85px",
                                                        height: "85px",
                                                    }}
                                                ></div>
                                            </div>
                                        ) : /* --- Address --- */
                                        header.name === "Address" ? (
                                            <div>
                                                {!getFromAttributeValues(
                                                    comp,
                                                    ["confidentialFlg", "confidential_flg"],
                                                    false
                                                ) ||
                                                includes(["dataEntry", "dataAdmin"], role) ||
                                                isBrokerOnDeal(comp) ? (
                                                    <Link
                                                        to={`/comp/${comp.dealkey}`}
                                                        target="_blank"
                                                        className={classes.lb}
                                                    >
                                                        <span className={classes.listingIdLink}>
                                                            {/* Using a default value here doesn't work because comp.listing_name will sometimes return Null instead of undefined */}
                                                            {get(comp, "listing_name")
                                                                ? get(comp, "listing_name").trim()
                                                                : ""}
                                                        </span>
                                                    </Link>
                                                ) : (
                                                    <div style={{ color: "red", fontWeight: "bold" }}>Confidential</div>
                                                )}
                                                <div>{`${get(comp, "address1", {})}`}</div>
                                                <div>{`${get(comp, "city", {})}, ${get(comp, "state_code", "")}, ${get(
                                                    comp,
                                                    "zip_postal_code",
                                                    ""
                                                )}`}</div>
                                                <div>{getCompInfo(comp)}</div>
                                            </div>
                                        ) : /* --- Rate / Price --- */
                                        header.name === "Rate/Price" ? (
                                            getFromAttributeValues(
                                                comp,
                                                ["confidentialFlg", "confidential_flg"],
                                                false
                                            ) === true ? (
                                                NO_CONTENT_PLACEHOLDER
                                            ) : (
                                                <div numeric={header.numeric}>
                                                    {
                                                        /* - Lease - */
                                                        utils.parseTransactionType(
                                                            get(comp, "transaction_type", null)
                                                        ) === "Lease"
                                                            ? getLeaseRate(comp)
                                                            : /* - Sale - */
                                                              getSalePrice(comp)
                                                    }
                                                </div>
                                            )
                                        ) : /* --- Square Feet --- */
                                        header.name === "SQFT" ? (
                                            <div numeric={header.numeric}>
                                                {get(comp, header.prop)
                                                    ? header.transform(get(comp, header.prop))
                                                    : NO_CONTENT_PLACEHOLDER}
                                            </div>
                                        ) : null
                                    }
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            )}
        </Draggable>
    );
};

const alternateContent = (compTab, loadingSearch, hasComps, searchRun, classes) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                {compTab === "all_km_comps" &&
                    (loadingSearch ? (
                        <div className={classes.alternateContent}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : !hasComps ? (
                        searchRun ? (
                            <div className={classes.alternateContent}>No Comps</div>
                        ) : (
                            <div className={classes.alternateContent}>Please apply search criteria</div>
                        )
                    ) : null)}

                {compTab === "comps_group" &&
                    (loadingSearch ? (
                        <div className={classes.alternateContent}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : !hasComps ? (
                        <div className={classes.alternateContent}>No Comps</div>
                    ) : null)}
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(CompsWithMap);
