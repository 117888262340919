import React from "react";
import ContactForm from "./ContactForm";
import Button from "@material-ui/core/Button";
import Add from "../../../../node_modules/@material-ui/icons/Add";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

class ContactContainer extends React.Component {
    handleContactUpdate = (event, key) => {
        if (event.target.value === "invoiceRecipient") {
            this.handleInvoiceRecipientSelection(event, key);
        } else {
            this.props.handleChange(event.target.id, event.target.value, key);
        }
    };

    handleInvoiceRecipientSelection = (event, contactIndex) => {
        if (event.target.checked) {
            Object.keys(this.props.contacts).forEach((key, i) => {
                if (key === contactIndex) {
                    this.props.handleChange(key, "invoiceRecipient", true);
                } else {
                    this.props.handleChange(key, "invoiceRecipient", false);
                }
            });
        } else {
            this.props.handleChange(contactIndex, "invoiceRecipient", false);
        }
    };

    handleDeleteContactInfo = (contactKey) => {
        this.props.handleDelete(contactKey);
    };

    componentDidMount() {
        const numContacts = this.props.contacts ? this.props.contacts.length : 0;
        if (numContacts === 0 && this.props.disableInputs !== true && this.props.displayWhenEmpty === true) {
            this.props.handleAdd();
        }
    }

    handleInput = (field, value, i) => {
        if (this.props.handleChange) this.props.handleChange(field, value, i);
    };

    render() {
        const {
            classes,
            contacts,
            disableInputs,
            maxContacts,
            addButtonText,
            handleAdd,
            handleDelete,
            companyFieldAvailable,
            handleSearchCompany,
            autocompleteResults,
            freeFormCompany,
            isSnapshot,
        } = this.props;

        return (
            <div>
                {isSnapshot && (
                    <Grid container spacing={8}>
                        <Grid item xs>
                            <h3>Contact Information</h3>
                        </Grid>
                    </Grid>
                )}
                {contacts &&
                    Object.keys(contacts).map((key, i) => {
                        const contact = contacts[key];
                        return (
                            <div>
                                <ContactForm
                                    {...this.props}
                                    handleSearchCompany={handleSearchCompany}
                                    autocompleteResults={autocompleteResults}
                                    companyFieldAvailable={companyFieldAvailable}
                                    contactIndex={i}
                                    contact={contact}
                                    allowDelete={Boolean(true)}
                                    handleDelete={handleDelete}
                                    handleInput={(field, value) => this.handleInput(field, value, i)}
                                    title="Contact Information"
                                    key={`contact-${i}`}
                                    disableInputs={disableInputs}
                                    freeFormCompany={freeFormCompany}
                                    hideTitle={isSnapshot}
                                />
                            </div>
                        );
                    })}
                {(!contacts || Object.keys(contacts).length < maxContacts) && disableInputs !== true && (
                    <Button className={classes.root} onClick={handleAdd}>
                        <Add className={classes.icon} />
                        {addButtonText}
                    </Button>
                )}
            </div>
        );
    }
}

const styles = (theme) => ({
    root: {
        border: "2px dashed #2C99D2",
        color: "#2C99D2",
        margin: "10px 0 20px",
        padding: "11px 16px",
        width: "100%",
        justifyContent: "start",
        "&hover": {
            backgroundColor: "rgba(44, 153, 210, 0.08)",
        },
    },
});

export default withStyles(styles)(ContactContainer);
