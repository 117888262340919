import types from "./types";
import config from "../../../../config";

export default (
    state = {
        payload: {
            token: "",
            roles: [
                {
                    authority: "",
                },
            ],
        },
        userProfile: {},
        authenticatedUserProfile: {},
        role: "broker",
        user: "anonymousUser",
        authenticated: false,
        redirectUrl: null,
        userKey: 1050,
        failedLoginAttempts: 0,
    },
    action
) => {
    switch (action.type) {
        case types.HANDLE_CHANGE:
            if (action.field === "username") {
                return Object.assign({}, state, {
                    username: action.value,
                });
            } else if (action.field === "password") {
                return Object.assign({}, state, {
                    password: action.value,
                });
            }
            break;
        case types.VALIDATE_NON_BLANK:
            return Object.assign({}, state, {
                validFormValues: action.valid,
            });
        case types.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                authenticated: action.authenticated,
                payload: action.payload,
                status: action.status,
            });
        case types.LOGIN_FAILED:
            return Object.assign({}, state, {
                authenticated: action.authenticated,
                payload: action.payload,
                status: action.status,
                snackbarOpen: action.snackbarOpen,
                failedLoginAttempts: state.failedLoginAttempts + 1,
            });
        case types.HIDE_SNACKBAR:
            return Object.assign({}, state, {
                snackbarOpen: action.snackbarOpen,
            });
        case types.CHANGE_TEST_PROFILE:
            return Object.assign({}, state, {
                role: action.value,
            });

        case types.LOAD_USER_PROFILE:
            return {
                ...state,
                authenticated: true,
                userProfile: {
                    ...action.userProfile,
                },
                authenticatedUserProfile: {
                    ...action.authenticatedUserProfile,
                },
            };
        case types.CLEAR_AUTHENTICATION_INFO:
            return {
                ...state,
                role: null,
                authenticated: false,
                redirectUrl: null,
                user: null,
                userKey: null,
                firstName: null,
                lastName: null,
                userProfile: {},
                authenticatedUserProfile: {},
            };
        case types.REDIRECT_TO_LOGIN:
            return {
                ...state,
                role: null,
                authenticated: false,
                user: null,
                userKey: null,
                firstName: null,
                lastName: null,
                userProfile: {},
                authenticatedUserProfile: {},
                redirectUrl: config.SSO_REDIRECT, //'https://auth.kiddermathews.com/adfs/oauth2/authorize?resource=https://ndpdev.kiddermathews.com/login&client_id=e74ef628-f2d3-4295-8c71-6a7ab0bc88f1&redirect_uri=https://ndpdev.kiddermathews.com/login&response_type=code'
            };
        case types.CHANGE_USER:
            return {
                ...state,
                ...action.userData,
            };
        default:
            return state;
    }
};
