import configs from "../../../config";

const workflow = {
    statuses: {
        Draft: {
            broker: {
                list_action: "edit",
            },
            debtEquity: {
                list_action: "edit",
            },
            evp: {
                list_action: "edit",
            },
            portlandBrokerAdmin: {
                list_action: "edit",
            },
            arizonaBrokerAdmin: {
                list_action: "edit",
            },
            color: "primary",
            progress: 0,
        },
        "Broker Action Required": {
            broker: {
                list_action: "edit",
            },
            debtEquity: {
                list_action: "edit",
            },
            evp: {
                list_action: "edit",
            },
            portlandBrokerAdmin: {
                list_action: "edit",
            },
            arizonaBrokerAdmin: {
                list_action: "edit",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "view",
            },
            color: "secondary",
            progress: 1,
        },
        "Needs Review": {
            broker: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            portlandBrokerAdmin: {
                list_action: "view",
            },
            arizonaBrokerAdmin: {
                list_action: "view",
            },
            data_entry: {
                list_action: "start",
            },
            color: "secondary",
            progress: 1,
        },
        "In Review": {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            portlandBrokerAdmin: {
                list_action: "view",
            },
            arizonaBrokerAdmin: {
                list_action: "view",
            },
            data_entry: {
                list_action: "edit",
            },
            progress: 1,
        },
        "Final Review": {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            portlandBrokerAdmin: {
                list_action: "view",
            },
            arizonaBrokerAdmin: {
                list_action: "view",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "review",
            },
            progress: 1,
        },
        //still need to add portlandBrokerAdmin to the following
        "Admin Action Required": {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            data_entry: {
                list_action: "review",
            },
            data_admin: {
                list_action: "view",
            },
            progress: 1,
        },
        "Ready For Accounting": {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "view",
            },
            progress: 1,
        },
        Closed: {
            broker: {
                list_action: "view",
            },
            debtEquity: {
                list_action: "view",
            },
            evp: {
                list_action: "view",
            },
            data_entry: {
                list_action: "view",
            },
            data_admin: {
                list_action: "view",
            },
            progress: 2,
        },
    },
};

const role = {
    broker: {
        init_tab: "broker_open",
    },
    debtEquity: {
        init_tab: "broker_open",
    },
    evp: {
        init_tab: "broker_open",
    },
    portlandBrokerAdmin: {
        init_tab: "portland_broker_admin",
    },
    arizonaBrokerAdmin: {
        init_tab: "arizona_broker_admin_review",
    },
    dataEntry: {
        init_tab: "data_entry_unassigned",
    },
    dataAdmin: {
        init_tab: "data_admin_needs_final_review",
    },
    accounting: {
        init_tab: "accounting_awaiting_payment",
    },
};

const HOST = configs.HOST_URL + "/";
const VOUCHER_URL = HOST + "vouchers/";
const DEAL_URL = HOST + "deals/";

const urls = {
    broker_all: (brokerId) => {
        return VOUCHER_URL + "broker/" + brokerId;
    },
    broker_open: (brokerId) => {
        return DEAL_URL + "broker/open/" + brokerId;
    },
    broker_closed: (brokerId) => {
        return DEAL_URL + "broker/closed/" + brokerId;
    },
    data_entry_unassigned: () => {
        return DEAL_URL + "dataentry/unassigned";
    },
    data_entry_open: (username) => {
        return DEAL_URL + "dataentry/open/" + username;
    },
    data_entry_closed: (username) => {
        return DEAL_URL + "dataentry/closed/" + username;
    },
    data_admin_submitted: () => {
        return VOUCHER_URL + "admin/open";
    },
    data_admin_closed: () => {
        return DEAL_URL + "admin/closed";
    },
    data_admin_needs_final_review: () => {
        return DEAL_URL + "admin/review";
    },
    accounting_closed: () => {
        return VOUCHER_URL + "accounting/closed";
    }, //todo accounting
    accounting_ready_payment: () => {
        return VOUCHER_URL + "accounting/payment";
    },
    accounting_awaiting_payment: () => {
        return VOUCHER_URL + "accounting/payment";
    },
    assign_comp_for_review: (dealKey) => {
        return DEAL_URL + "comp/review/" + dealKey;
    },
    assign_voucher_for_review_by_deal_id: (dealId) => {
        return VOUCHER_URL + "workflow/review/deal/" + dealId;
    },
    portland_broker_admin: () => {
        return DEAL_URL + "portlandbrokeradmin/review";
    },
    portland_broker_admin_sale: () => {
        return DEAL_URL + "portlandbrokeradmin/sale";
    },
    portland_broker_admin_closed: () => {
        return DEAL_URL + "portlandbrokeradmin/closed";
    },
    portland_broker_admin_closed_export: () => {
        return DEAL_URL + "portlandbrokeradmin/closed/export";
    },
    arizona_broker_admin_review: () => {
        return DEAL_URL + "arizonabrokeradmin/review";
    },
    arizona_broker_admin_closed: () => {
        return DEAL_URL + "arizonabrokeradmin/closed";
    },
    arizona_broker_admin_closed_export: () => {
        return DEAL_URL + "arizonabrokeradmin/closed/export";
    },
    toggleReviewedBy: (dealKey) => {
        return DEAL_URL + dealKey;
    },
    broker_open_deals: (brokerId) => {
        return DEAL_URL + "broker/open/" + brokerId;
    },
};

export default {
    workflow,
    role,
    urls,
};
