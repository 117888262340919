import React, { Component } from "react";
import { connect } from "react-redux";
import AuditSearch from "./AuditSearch";
import searchOperations from "../search/ducks/operations";
import operations from "./ducks/operations";
import documentOperations from "../documents/ducks/operations";
import PermissionsGate from "./permissions/PermissionsGate";
import { SCOPES, ROLES, audit_tabs } from "./constants";
import ReactGA from "react-ga4";

class AuditSearchContainer extends Component {
    componentDidMount() {
        const { role, handleInitialTab, loadApprovedComps } = this.props;
        const { auditorSearch, auditorList } = audit_tabs;
        const { auditor } = ROLES;
        role === auditor ? handleInitialTab(auditorList) : handleInitialTab(auditorSearch);
        loadApprovedComps();
        ReactGA.send({ hitType: "pageview", page: "/audit", title: "Audit" });
    }

    render() {
        return (
            <PermissionsGate scopes={[SCOPES.canViewAudit]}>
                <AuditSearch {...this.props} />
            </PermissionsGate>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.authReducer.userProfile,
        role: state.authReducer.userProfile.role,
        searchCriteria: state.searchReducer.AUDIT.searchCriteria,
        loadedSearchCrumbs: state.searchReducer.loadedSearchCrumbs.AUDIT,
        searchResponse: state.searchReducer.AUDIT.searchResponse,
        activeTab: state.auditReducer.activeTab,
        activeHeader: state.auditReducer.activeHeader,
        isLoading: state.utilsReducer.isLoading,
        searchCleared: state.searchReducer.AUDIT.searchCleared,
        comps: state.auditReducer.comps,
        loadingSearch: state.searchReducer.loadingSearch,
        searchRun: state.searchReducer.AUDIT.searchRun,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateFilter: (field, value) => {
            dispatch(searchOperations.updateFilter(field, value, "AUDIT"));
        },
        handleDeleteCrumb: (criteria, value) => {
            dispatch(searchOperations.deleteCrumb(criteria, value, "AUDIT"));
        },
        clearFilters: () => {
            dispatch(searchOperations.clearFilters("AUDIT"));
        },
        handleSearchCleared: (cleared) => {
            dispatch(searchOperations.storeSearchCleared(cleared, "AUDIT"));
            dispatch(operations.clearSearchResults());
        },
        handleSearchCompsSubmit: (searchCriteria) => {
            dispatch(operations.searchComps());
        },
        handleSort: (columnName, tab) => {
            dispatch(searchOperations.sortGridTableData(columnName, tab, "AUDIT"));
        },
        handleDragEnd: (results, comps) => {
            dispatch(operations.handleDragEnd(results, comps));
        },
        viewDocument: (url, document) => {
            dispatch(documentOperations.viewDocument(url, document));
        },
        handleInitialTab: (tab) => {
            dispatch(operations.handleChangeTabs(tab));
        },
        loadApprovedComps: () => {
            dispatch(operations.loadApprovedComps());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditSearchContainer);
