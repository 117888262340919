import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import BSSelect from "../../../common/js/components/BSSelect";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import operations from "./ducks/operations";
import { quarters, buildingClasses, buildingStatuses } from "../utils/constants";
import {
    yearsForExportResearch,
    marketAreasForResearchSnapshot,
    submarketOptionsForResearch,
    micromarketOptionsForResearch,
    propertyTypeFilterOptionsForExportResearch,
    specialtyTypeFilterOptionsForExportResearch,
    endYearsForExportResearch,
    researchExportDateValidation,
} from "../property/constants";

class ResearchExportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            startYear: "Select",
            endYear: "Select",
            startQuarter: "Select",
            endQuarter: "Select",
            marketArea: "Select",
            subMarketArea: "All",
            microMarketArea: "All",
            propertyType: "Select",
            propertySubType: "All",
            status: "All",
            class: "All",
            tracking: "Tracked Properties Only",
        };
    }

    onExitModal = () => {
        this.setState({
            sending: false,
            startYear: "Select",
            endYear: "Select",
            startQuarter: "Select",
            endQuarter: "Select",
            marketArea: "Select",
            subMarketArea: "All",
            microMarketArea: "All",
            propertyType: "Select",
            propertySubType: "All",
            status: "All",
            class: "All",
            tracking: "Tracked Properties Only",
        });
        this.props.closeModal();
    };

    onSubmit = async () => {
        this.setState({ sending: true });
        const exportPayload = {
            searchCriteria: {
                researchCriteria: {
                    startQuarter: this.state.startQuarter,
                    startYear: this.state.startYear,
                    endQuarter: this.state.endQuarter,
                    endYear: this.state.endYear,
                },
                locationCriteria: {
                    states: null,
                    zips: null,
                    counties: null,
                    cities: null,
                    markets: {
                        [this.state.marketArea]: {
                            ...(this.state.subMarketArea !== "All" && {
                                [this.state.subMarketArea]: [
                                    ...(this.state.microMarketArea !== "All" ? [this.state.microMarketArea] : []),
                                ],
                            }),
                        },
                    },
                },
                sizeCriteria: {
                    availableSf: false,
                    totalSf: false,
                    acreage: false,
                    totalTransactionSf: false,
                },
                propertyTypes: {
                    [this.state.propertyType]: {
                        selected: true,
                        buildingClassCriteria: {
                            a: this.state.class === "All" || this.state.class === "A",
                            b: this.state.class === "All" || this.state.class === "B",
                            c: this.state.class === "All" || this.state.class === "C",
                            d: this.state.class === "All" || this.state.class === "D",
                            trophy: this.state.class === "All" || this.state.class === "Trophy",
                        },
                        specialtyTypeCriteria: {
                            ...(this.state.propertySubType !== "All" && {
                                [this.state.propertySubType]: true,
                            }),
                        },
                    },
                },
                statusCriteria: {
                    tracked: this.state.tracking === "Tracked Properties Only",
                    ...(this.state.status !== "All" && {
                        [this.state.status.toLowerCase()]: true,
                    }),
                },
                offices: [],
            },
            startIndex: 0,
            requestId: new Date().valueOf() + "",
            numResults: 300,
        };
        const response = await operations.exportResearch(exportPayload);
        if (response.status >= 200 && response.status < 300) {
            this.props.snapshotResponse(true);
            window.open(response.data, "_blank");
        } else {
            this.props.snapshotResponse(false);
        }
        this.onExitModal();
    };

    handleStartQuarterUpdate = (event) => {
        this.setState({
            startQuarter: event.target.value,
        });
    };

    handleStartYearUpdate = (event) => {
        this.setState({
            startYear: event.target.value,
        });
    };

    handleEndQuarterUpdate = (event) => {
        this.setState({
            endQuarter: event.target.value,
        });
    };

    handleEndYearUpdate = (event) => {
        this.setState({
            endYear: event.target.value,
        });
    };

    handleMarketAreaUpdate = (event) => {
        this.setState({
            marketArea: event.target.value,
            subMarketArea: "All",
            microMarketArea: "All",
        });
    };

    handleSubMarketAreaUpdate = (event) => {
        this.setState({
            subMarketArea: event.target.value,
            microMarketArea: "All",
        });
    };

    handleMicroMarketAreaUpdate = (event) => {
        this.setState({
            microMarketArea: event.target.value,
        });
    };

    handlePropertyTypeUpdate = (event) => {
        this.setState({
            propertyType: event.target.value,
            class: false,
        });
    };

    handlePropertySubTypeUpdate = (event) => {
        this.setState({
            propertySubType: event.target.value,
        });
    };

    handleStatusUpdate = (event) => {
        this.setState({
            status: event.target.value,
        });
    };

    handleClassUpdate = (event) => {
        this.setState({
            class: event.target.value,
        });
    };

    handleTrackingToggle = (event) => {
        this.setState({ tracking: event });
    };

    render() {
        const { classes } = this.props;

        return (
            <Modal
                open={this.props.modalOpen}
                onClose={() => this.onExitModal()}
                style={{
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10vh",
                }}
            >
                <div className={classes.container}>
                    <h4 className={classes.header}>Research Export</h4>
                    <div className={classes.selectionColumn}>
                        <Grid container spacing={2}>
                            <Grid item xs className={classes.widthFix}>
                                <BSSelect
                                    id="startQuarterSelect"
                                    label="Start Quarter"
                                    value={this.state.startQuarter}
                                    onChange={this.handleStartQuarterUpdate}
                                    placeholder="Select a quarter"
                                    options={quarters}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs className={classes.smallWidth}>
                                <BSSelect
                                    id="startYearSelect"
                                    label="Start Year"
                                    value={this.state.startYear}
                                    onChange={this.handleStartYearUpdate}
                                    placeholder="Select a start year"
                                    options={yearsForExportResearch()}
                                    disabled={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs className={classes.widthFix}>
                                <BSSelect
                                    id="endQuarterSelect"
                                    label="End Quarter"
                                    value={this.state.endQuarter}
                                    onChange={this.handleEndQuarterUpdate}
                                    placeholder="Select a end quarter"
                                    options={quarters}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs className={classes.smallWidth}>
                                <BSSelect
                                    id="endYearSelect"
                                    label="End Year"
                                    value={this.state.endYear}
                                    onChange={this.handleEndYearUpdate}
                                    placeholder="Select a end year"
                                    options={endYearsForExportResearch(this.state.startYear)}
                                    disabled={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs className={classes.smallWidth}>
                            <BSSelect
                                id="marketAreaSelect"
                                label="Market Area"
                                value={this.state.marketArea}
                                onChange={this.handleMarketAreaUpdate}
                                placeholder="Select a market area"
                                options={marketAreasForResearchSnapshot()}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs className={classes.smallWidth}>
                            <BSSelect
                                id="subMarketAreaSelect"
                                label="SubMarket"
                                value={this.state.subMarketArea}
                                onChange={this.handleSubMarketAreaUpdate}
                                placeholder="Select a submarket area"
                                options={submarketOptionsForResearch(this.state.marketArea)}
                                disabled={this.state.marketArea !== "Select" ? false : true}
                            />
                        </Grid>
                        <Grid item xs className={classes.smallWidth}>
                            <BSSelect
                                id="microMarketAreaSelect"
                                label="MicroMarket"
                                value={this.state.microMarketArea}
                                onChange={this.handleMicroMarketAreaUpdate}
                                placeholder="Select a micromarket area"
                                options={micromarketOptionsForResearch(this.state.marketArea, this.state.subMarketArea)}
                                disabled={this.state.subMarketArea !== "All" ? false : true}
                            />
                        </Grid>
                        <Grid item xs className={classes.smallWidth}>
                            <BSSelect
                                id="propertyTypeSelect"
                                label="Property Type"
                                value={this.state.propertyType}
                                onChange={this.handlePropertyTypeUpdate}
                                placeholder="Select a property type"
                                options={propertyTypeFilterOptionsForExportResearch()}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs className={classes.smallWidth}>
                            <BSSelect
                                id="propertySubTypeSelect"
                                label="Property SubType"
                                value={this.state.propertySubType}
                                onChange={this.handlePropertySubTypeUpdate}
                                placeholder="Select a property subtype"
                                options={specialtyTypeFilterOptionsForExportResearch(this.state.propertyType)}
                                disabled={this.state.propertyType !== "Select" ? false : true}
                            />
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs className={classes.widthFix}>
                                <BSSelect
                                    id="statusSelect"
                                    label="Status"
                                    value={this.state.status}
                                    onChange={this.handleStatusUpdate}
                                    placeholder="Select a Status"
                                    options={buildingStatuses}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs className={classes.smallWidth}>
                                <BSSelect
                                    id="classSelect"
                                    label="Class"
                                    value={this.state.class}
                                    onChange={this.handleClassUpdate}
                                    placeholder="Select a Class"
                                    options={buildingClasses}
                                    disabled={this.state.propertyType !== "Select" ? false : true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <FormControl rowcomponent="fieldset">
                                <FormLabel componenet="legend">TRACKING</FormLabel>
                                <RadioGroup
                                    className={classes.flexDirection}
                                    aria-label="tracking"
                                    name="tracking1"
                                    value={this.state.tracking}
                                    onChange={(e) => this.handleTrackingToggle(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="Tracked Properties Only"
                                        control={<Radio id="include" color="primary" style={{ marginRight: "5px" }} />}
                                        label="Tracked Properties Only"
                                    />
                                    <FormControlLabel
                                        value="Include Non-Tracked Properties"
                                        control={<Radio id="include" color="primary" />}
                                        label="Include Non-Tracked Properties"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </div>
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "end",
                            margin: "0vw 1vw 1vw 0vw",
                        }}
                    >
                        <Button
                            style={{ display: "inline-block", marginLeft: "10px" }}
                            variant="outlined"
                            onClick={() => this.onExitModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ display: "inline-block", marginLeft: "10px" }}
                            variant="contained"
                            color="primary"
                            disabled={
                                this.state.startQuarter !== "Select" &&
                                this.state.startYear !== "Select" &&
                                this.state.endQuarter !== "Select" &&
                                this.state.endYear !== "Select" &&
                                this.state.marketArea !== "Select" &&
                                this.state.propertyType !== "Select" &&
                                this.state.sending === false &&
                                researchExportDateValidation(
                                    this.state.startQuarter,
                                    this.state.endQuarter,
                                    this.state.startYear,
                                    this.state.endYear
                                )
                                    ? false
                                    : true
                            }
                            onClick={() => this.onSubmit()}
                        >
                            Export
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const styles = (theme) => ({
    header: {
        marginTop: "45px",
        textAlign: "left",
        fontSize: "18px",
        marginLeft: "24px",
    },
    container: {
        margin: "0 auto",
        marginTop: "10%",
        position: "relative",
        alignItems: "center",
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
        width: "525px",
        height: "675px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        overflowY: "auto",
    },
    selectionColumn: {
        margin: "24px 24px 0px 24px",
    },
    widthFix: {
        marginRight: "90px",
    },
    smallWidth: {
        marginRight: "0px",
    },
    flexDirection: {
        flexDirection: "row",
    },
});

export default withStyles(styles)(ResearchExportModal);
