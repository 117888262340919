const HANDLE_CHANGE = "HANDLE_CHANGE";
const VALIDATE_NON_BLANK = "VALIDATE_NON_BLANK";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILED = "LOGIN_FAILED";
const HIDE_SNACKBAR = "HIDE_SNACKBAR";
const CHANGE_TEST_PROFILE = "CHANGE_TEST_PROFILE";
const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";
const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
const CLEAR_AUTHENTICATION_INFO = "CLEAR_AUTHENTICATION_INFO";
const CHANGE_USER = "CHANGE_USER";

const RESET_STATE = "RESET_STATE";

export default {
    HANDLE_CHANGE,
    VALIDATE_NON_BLANK,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    HIDE_SNACKBAR,
    CHANGE_TEST_PROFILE,
    LOAD_USER_PROFILE,
    REDIRECT_TO_LOGIN,
    CLEAR_AUTHENTICATION_INFO,
    CHANGE_USER,
    RESET_STATE,
};
