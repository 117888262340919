import configs from "../../../config";

const HOST = configs.HOST_URL + "/";

const RESEARCH_URL = HOST + "research/";
const RESEARCH_EXPORT_URL = HOST + "search/export/research";

const urls = {
    capture_research: () => {
        return RESEARCH_URL + "capture";
    },
    get_research: (propertyKey) => {
        return RESEARCH_URL + propertyKey;
    },
    edit_snapshot: (researchSnapshotKey) => {
        return RESEARCH_URL + researchSnapshotKey;
    },
    export_research: () => {
        return RESEARCH_EXPORT_URL;
    },
};

export default {
    urls,
};
