import React from "react";
import { connect } from "react-redux";
import AuditSearchResults from "./AuditSearchResults";
import operations from "./ducks/operations";
import documentOperations from "../documents/ducks/operations";

const AuditSearchResultsContainer = (props) => {
    return <AuditSearchResults {...props} />;
};

const mapStateToProps = (state) => ({
    searchCleared: state.searchReducer.AUDIT.searchCleared,
    comps: state.auditReducer.comps,
    loadingSearch: state.searchReducer.loadingSearch,
    searchRun: state.searchReducer.AUDIT.searchRun,
    activeTab: state.auditReducer.activeTab,
    approvalList: state.auditReducer.approvalList,
    removalList: state.auditReducer.removalList,
});

const mapDispatchToProps = (dispatch) => ({
    handleDragEnd: (results, comps) => {
        dispatch(operations.handleDragEnd(results, comps));
    },
    viewDocument: (url, document) => {
        dispatch(documentOperations.viewDocument(url, document));
    },
    toggleCompAuditFlag: (dealKey) => {
        dispatch(operations.toggleCompAuditFlag(dealKey));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditSearchResultsContainer);
