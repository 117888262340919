import actions from "./actions";
import { push } from "connected-react-router";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import get from "lodash/get";
import constants from "../constants";
import utils from "../../utils/index";
import dealsTables from "../dealsTables";
import { request } from "../../utils/axios-wrapper";
import utilOperations from "../../utils/operations";
import compOperations from "../../comp/ducks/operations";
import set from "lodash/set";
import moment from "moment";

const getInitialDeals = (role, user) => {
    return (dispatch, getState) => {
        switch (role) {
            case "dataEntry":
                dispatch(getDeals(constants.urls.data_entry_unassigned(), "data_entry_unassigned"));
                dispatch(getDeals(constants.urls.data_entry_open(user), "data_entry_open"));
                dispatch(getDeals(constants.urls.data_entry_closed(user), "data_entry_closed"));
                break;
            case "broker":
                dispatch(getDeals(constants.urls.broker_open_deals(user), "broker_open"));
                dispatch(getDeals(constants.urls.broker_closed(user), "broker_closed"));
                break;
            case "debtEquity":
                dispatch(getDeals(constants.urls.broker_open_deals(user), "broker_open"));
                dispatch(getDeals(constants.urls.broker_closed(user), "broker_closed"));
                break;
            case "evp":
                dispatch(getDeals(constants.urls.broker_open(user), "broker_open"));
                dispatch(getDeals(constants.urls.broker_closed(user), "broker_closed"));
                break;
            case "dataAdmin":
                dispatch(getDeals(constants.urls.data_admin_needs_final_review(), "data_admin_needs_final_review"));
                dispatch(getDeals(constants.urls.data_admin_closed(), "data_admin_closed"));
                break;
            case "portlandBrokerAdmin":
                dispatch(getDeals(constants.urls.broker_open_deals(user), "broker_open"));
                dispatch(getDeals(constants.urls.broker_closed(user), "broker_closed"));
                dispatch(getDeals(constants.urls.portland_broker_admin(), "portland_broker_admin"));
                dispatch(getDeals(constants.urls.portland_broker_admin_sale(), "portland_broker_admin_sale"));
                dispatch(getDeals(constants.urls.portland_broker_admin_closed(), "portland_broker_admin_closed"));
                break;
            case "arizonaBrokerAdmin":
                dispatch(getDeals(constants.urls.broker_open_deals(user), "broker_open"));
                dispatch(getDeals(constants.urls.broker_closed(user), "broker_closed"));
                dispatch(getDeals(constants.urls.arizona_broker_admin_review(), "arizona_broker_admin_review"));
                dispatch(getDeals(constants.urls.arizona_broker_admin_closed(), "arizona_broker_admin_closed"));
                break;
            default:
                dispatch(getDeals(constants.urls.broker_open_deals(user), "broker_open"));
                dispatch(getDeals(constants.urls.broker_closed(user), "broker_closed"));
                break;
        }
    };
};

const getDeals = (url, tab) => {
    return (dispatch, _) => {
        dispatch(utils.actions.somethingSaving(true, tab));
        request(
            {
                url: url,
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.receiveVouchers(response.data, tab));
                dispatch(utils.actions.somethingSaving(false, tab));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, tab));
                dispatch(utilOperations.reportError(err, "deals:operations:getDeals(inner) Error"));
            });
    };
};

const getExport = (url, tab) => {
    return (dispatch, _) => {
        dispatch(utils.actions.somethingSaving(true, tab));
        dispatch(actions.initiateDealsExport(tab));
        request(
            {
                url: url,
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.receiveVouchersForExport(response.data, tab));
                dispatch(utils.actions.somethingSaving(false, tab));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, tab));
                dispatch(utilOperations.reportError(err, "deals:operations:getDealsForExport(inner) Error"));
            });
    };
};

const getDealsForExport = (role) => {
    return (dispatch, getState) => {
        switch (role) {
            case "portlandBrokerAdmin":
                dispatch(
                    getExport(constants.urls.portland_broker_admin_closed_export(), "portland_broker_admin_closed")
                );
                break;
            case "arizonaBrokerAdmin":
                dispatch(getExport(constants.urls.arizona_broker_admin_closed_export(), "arizona_broker_admin_closed"));
                break;
            default:
                break;
        }
    };
};

const clearDealsExport = () => {
    return (dispatch, getState) => {
        dispatch(actions.clearDealsExport());
    };
};

const handleSort = (columnName, sortDirection) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };

    return (dispatch, getState) => {
        if (!sortDirection) {
            sortDirection =
                getState().dealsReducer.columnToSort === columnName
                    ? invertDirection[getState().dealsReducer.sortDirection]
                    : "asc";
        }
        dispatch(actions.handleSort(columnName, sortDirection));
    };
};

// filter vouchers
const filterVouchers = (query) => {
    return (dispatch, getState) => {
        if (typeof query === "string") query = query.toLowerCase();
        dispatch(actions.filterVouchers(query));
    };
};

// select voucher
const selectVoucher = (voucherId) => {
    return (dispatch, getState) => {
        dispatch(push(`/voucher/${voucherId}`));
    };
};

const switchVoucherView = (tab) => {
    return (dispatch, getState) => {
        dispatch(actions.switchVoucherView(tab));
        if (!getState().dealsReducer.deals[tab] || dealsTables.refreshTabOnSwitch(tab)) {
            dispatch(getDeals(constants.urls[tab](getState().authReducer.userProfile.user), tab));
        }
    };
};

const switchTab = (tab) => {
    return (dispatch, getState) => {
        dispatch(actions.switchVoucherView(tab));
    };
};

const handleSelectAll = (checked, indeterminate) => {
    return (dispatch, getState) => {
        let newSelected = [];
        if (checked) {
            for (let i = 0; i < getState().dealsReducer.payload.length; i++) {
                newSelected.push(`ch-${i}`);
            }
            dispatch(actions.checkboxClick(newSelected));
            return;
        }
        dispatch(actions.checkboxClick(newSelected));
    };
};

const handleCheckboxClick = (id) => {
    return (dispatch, getState) => {
        const selected = getState().dealsReducer.selected;
        let newSelected = [];
        if (selected) {
            const selectedIndex = selected.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            }
        } else {
            newSelected.push(id);
        }
        dispatch(actions.checkboxClick(newSelected));
    };
};

const selectDeal = (x, voucherKey) => {
    return (dispatch, getState) => {
        if (x.transactionType === "Consulting") {
            if (voucherKey) {
                dispatch(push(`/voucher/${voucherKey}`));
            } else if (x.consultingVoucherId != null) {
                dispatch(push(`/voucher/${x.consultingVoucherId}`));
            }
        } else {
            dispatch(push(`/deal/${x.dealKey}`));
        }
    };
};

const selectDealNewTab = (x, voucherKey) => {
    return (dispatch, getState) => {
        if (x.transactionType === "Consulting") {
            if (voucherKey) {
                window.open(`/voucher/${voucherKey}`);
            } else if (x.consultingVoucherId != null) {
                window.open(`/voucher/${x.consultingVoucherId}`);
            }
        } else {
            window.open(`/deal/${x.dealKey}`);
        }
    };
};

const selectVoucherDeal = (voucher) => {
    return (dispatch, getState) => {
        if (voucher.transactionType === "Consulting") {
            if (voucher.voucherKey) {
                dispatch(push(`/voucher/${voucher.voucherKey}`));
            }
        } else {
            dispatch(push(`/deal/${voucher.dealKey}`));
        }
    };
};

const selectComp = (dealKey) => {
    return (dispatch, getState) => {
        dispatch(push(`/comp/${dealKey}`));
    };
};

const createNewDeal = () => {
    return (dispatch, getState) => {
        dispatch(push(`/invoice`));
    };
};

const handleStartReviewingAsCorporateData = (deal) => {
    return (dispatch, getState) => {
        const url =
            deal.compStatus === "Closed"
                ? constants.urls.assign_voucher_for_review_by_deal_id(deal.dealKey)
                : constants.urls.assign_comp_for_review(deal.dealKey);
        request(
            {
                method: "put",
                url: url,
            },
            dispatch
        )
            .then((response) => {
                /* 
                    This function is only called from inside /comp/:id. 
                    The samePath constant is used for the case where we need to update
                    the current page's state without redirecting the user. We do this by calling
                    the getDeal operation, which updates the deal reducer.
                */
                const path = getState().router.location.pathname;
                const samePath = path === `/comp/${deal.dealKey}`;
                if (deal.transactionType === "Consulting") {
                    dispatch(push(`/voucher/${deal.consultingVoucherId}`));
                } else if (deal.compStatus === "Closed") {
                    dispatch(push(`/voucher/${response.data.voucherKey}`));
                } else if (samePath) {
                    dispatch(compOperations.getDeal(deal.dealKey));
                } else {
                    dispatch(push(`/comp/${deal.dealKey}`));
                }
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Error occured while trying to start reviewing deal"));
            });
    };
};

const toggleReviewedBy = (processed, dealKey, tab) => {
    return (dispatch, getState) => {
        request(
            {
                method: "put",
                url: constants.urls.toggleReviewedBy(dealKey),
                data: processed
                    ? {
                          reviewedBy: null,
                          reviewedDate: null,
                      }
                    : {
                          reviewedBy: getState().authReducer.userProfile.user,
                          reviewedDate: moment().format("YYYY-MM-DD HH:mm"),
                      },
                headers: { "Content-Type": "application/json" },
            },
            dispatch
        )
            .then((response) => {
                dispatch(markAsReviewed(response.data, tab));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

const markAsReviewed = (data, tab) => {
    return (dispatch, getState) => {
        let deals = cloneDeep(getState().dealsReducer.deals[tab]);
        each(deals, (deal) => {
            if (deal.dealKey === get(data, "dealKey")) {
                set(deal, "reviewedBy", data.reviewedBy);
                set(deal, "reviewedDate", data.reviewedDate);
            }
        });
        dispatch(actions.receiveVouchers(deals, tab));
    };
};

export default {
    switchVoucherView,
    handleSort,
    selectVoucher,
    filterVouchers,
    handleCheckboxClick,
    handleSelectAll,
    getDeals,
    selectDeal,
    selectDealNewTab,
    createNewDeal,
    handleStartReviewingAsCorporateData,
    selectComp,
    getInitialDeals,
    getDealsForExport,
    switchTab,
    toggleReviewedBy,
    selectVoucherDeal,
    clearDealsExport,
};
