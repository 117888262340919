import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BSInput from "../../../common/js/components/BSInput";
import { Search, Sorty } from "../../../common/js/icons";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import schema from "../admin/userListingsTable";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import { Link } from "react-router-dom";
import includes from "lodash/includes";
import BSSelect from "../../../common/js/components/BSSelect";
import { user_search_options } from "./constants";
import UserHistoryModal from "./UserHistoryModal.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import AdImportModal from "./AdImportModal";
import Snackbar from "../../../common/js/components/KMSnackbar";

const styles = (theme) => ({
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    tabOne: {
        marginRight: "20px",
    },
    connectorActive: {
        "& $connectorLine": {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        "& $connectorLine": {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        "& $connectorLine": {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create("border-color"),
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 800,
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
    menuRequestListing: {
        paddingBottom: "0px",
        "&>a": {
            paddingBottom: "0px !important",
        },
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
    },
    lb: {
        textDecoration: "none",
        color: "#2C99D2",
        fontWeight: 600,
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    table: {
        minWidth: "100%",
    },
    toplessInput: {
        marginTop: 0,
    },
    loadingSpinner: {
        position: "absolute",
        left: "50%",
        top: "60%",
        transform: "translate(-50%, -50%)",
    },
});

class UserListings extends React.Component {
    state = {
        searchRun: false,
        historyModal: false,
        userAccountKeyForHistory: null,
        nameForHistory: null,
        snackBarOpen: false,
        snackbarMessage: "",
        snackbarVariant: "",
    };

    onKeyPress = (event) => {
        if (event.key === "Enter") {
            this.setState({ searchRun: true });
            this.props.handleSearchUsers();
        }
    };

    handleShowModal = (view, name, userAccountKey) => {
        this.setState({ historyModal: view });
        this.setState({ userAccountKeyForHistory: userAccountKey });
        this.setState({ nameForHistory: name });
        if (view) {
            this.props.handleGetUserOfficeHistory(userAccountKey);
        }
    };

    handleOpenModal = () => {
        this.setState({ modalOpen: true });
    };

    handleCloseModal = () => {
        this.setState({ modalOpen: false });
    };

    handleADImportResponse = (result) => {
        if (result === true) {
            this.setState({
                snackbarMessage: "Success: Import Process Started",
                snackbarVariant: "success",
                snackbarOpen: true,
            });
        } else {
            this.setState({
                snackbarMessage: "Failed: An Unknown Error Occurred",
                snackbarVariant: "error",
                snackbarOpen: true,
            });
        }
    };

    render() {
        const {
            classes,
            columnToSort,
            filterQuery,
            handleFilter,
            handleSort,
            isLoading,
            userTab,
            sortDirection,
            switchTab,
            users,
            userProfile,
            userSearchType,
            handleChangeUserSearchType,
            userOfficeHistory,
        } = this.props;

        const { searchRun, historyModal, userAccountKeyForHistory, nameForHistory } = this.state;

        const sortedData = orderBy(
            filterQuery && users
                ? users.filter((x) => schema.filterByQuery(x, schema.columnsToQueryFilter, filterQuery))
                : users,
            columnToSort,
            sortDirection
        );

        if (includes(["dataAdmin"], get(userProfile, "role", null))) {
            return (
                <div>
                    <Snackbar
                        open={this.state.snackbarOpen}
                        message={this.state.snackbarMessage}
                        variant={this.state.snackbarVariant}
                        vertical="top"
                        horizontal="center"
                        autoHideDuration={3000}
                        onClose={() => {
                            this.setState({ snackbarOpen: false });
                        }}
                    />
                    <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        className={classes.pageToolbar}
                        spacing={16}
                    >
                        <Grid item>
                            <h2 className="page-title">User Admin</h2>
                        </Grid>
                        <Button
                            disabled={false}
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleOpenModal()}
                        >
                            Run AD Import
                        </Button>
                        <AdImportModal
                            modalOpen={this.state.modalOpen}
                            closeModal={this.handleCloseModal}
                            adImportResponse={this.handleADImportResponse}
                        />
                    </Grid>
                    <Grid container alignItems="center" spacing={16}>
                        <Grid item xs>
                            <div className="simPaper">
                                <Grid container justify="space-between" spacing={0}>
                                    <Grid item>
                                        <Tabs classes={classes} value={userTab} onChange={switchTab}>
                                            <Tab classes={classes.tabOne} value="active_users" label="Active Users" />
                                            <Tab classes={classes} value="inactive_users" label="Inactive Users" />
                                        </Tabs>
                                    </Grid>
                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            <BSInput
                                                value={filterQuery}
                                                placeholder="Search Users"
                                                className={classes.toplessInput}
                                                errorPlaceholder="hideIt"
                                                startAdornment={<Search />}
                                                onChange={(event) => {
                                                    handleFilter(event.target.value);
                                                }}
                                                onKeyPress={(e) => this.onKeyPress(e)}
                                            />
                                        </Grid>
                                        <Grid item style={{ marginRight: "10px" }}>
                                            <BSSelect
                                                extraInputStyles={{ marginTop: "0px", width: "150px" }}
                                                id="userSearchType"
                                                value={userSearchType}
                                                options={user_search_options}
                                                onChange={(e) => handleChangeUserSearchType(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <div>
                                    <Grid container spacing={0}>
                                        <Grid item xs style={{ minHeight: "60vh" }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {schema.viewHeaders["default_headers"].map((x, i) => (
                                                            <TableCell classes={classes} key={`thc-${i}`}>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        width:
                                                                            x.name === "Office History" ? "185px" : "",
                                                                    }}
                                                                    onClick={() => handleSort(x.sort ? x.sort : x.prop)}
                                                                >
                                                                    <span className={classes.sortable}>{x.name}</span>

                                                                    {x.name && (
                                                                        <div>
                                                                            <Sorty />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {isLoading && searchRun && !historyModal ? (
                                                        <div
                                                            className={classes.loadingSpinner}
                                                            style={{ marginTop: "100px" }}
                                                        >
                                                            <CircularProgress color="primary" />
                                                        </div>
                                                    ) : (
                                                        sortedData.map((user, i) =>
                                                            row(user, i, classes, isLoading, this.handleShowModal)
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                            {!searchRun ? (
                                                <Grid item xs>
                                                    <div className={classes.alternateContent}>
                                                        Please apply search criteria
                                                    </div>
                                                </Grid>
                                            ) : (
                                                ""
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <UserHistoryModal
                        {...this.props}
                        handleShowModal={this.handleShowModal}
                        showModal={historyModal}
                        userOfficeHistoryData={get(userOfficeHistory, this.state.userAccountKeyForHistory, null)}
                        userAccountKey={userAccountKeyForHistory}
                        name={nameForHistory}
                    />
                </div>
            );
        }
        return <div></div>;
    }
}

const row = (x, i, classes, isLoading, handleShowModal) => {
    return isLoading ? null : (
        <React.Fragment>
            <TableRow key={`tr-${i}`} selectable="false">
                {schema.viewHeaders["default_headers"].map((y, k) => (
                    <TableCell
                        classes={{ root: classes.body }}
                        key={`trc-${k}`}
                        style={{ width: y.name === "Office History" ? "185px" : "" }}
                    >
                        {y.name === "NAME" ? (
                            <Link to={`/admin/user/${x.userAccountKey}`} className={classes.lb}>
                                {get(x, "name", "")}
                            </Link>
                        ) : y.transform ? (
                            y.transform(x)
                        ) : (
                            get(x, y.prop, "")
                        )}
                        {y.name === "Office History" ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    handleShowModal(
                                        true,
                                        `${get(x, "firstName", null)} ${get(x, "lastName", null)}`,
                                        get(x, "userAccountKey", null)
                                    )
                                }
                                style={{ marginRight: "25px", marginLeft: "0px" }}
                            >
                                View History
                            </Button>
                        ) : (
                            ""
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </React.Fragment>
    );
};

export default withStyles(styles)(UserListings);
