import React, { useState } from "react";
import { connect } from "react-redux";
import operations from "./ducks/operations";
import { Button, CircularProgress } from "@material-ui/core";
import SaveAndSyncModal from "./SaveAndSyncModal";
import { get } from "lodash";

const SaveAndSyncContainer = (props) => {
    const [modal, toggleModal] = useState(false);
    const { propertyKey, savePropertyManagementDetails, propertyManagement } = props;

    const saveOrSync = () => {
        if (get(propertyManagement, "assetPortfolioProperties", 0).length < 1) {
            savePropertyManagementDetails(get(propertyManagement, "propertyManagementKey", null));
        } else {
            toggleModal(true);
        }
    };

    const handleSubmit = (sync) => {
        savePropertyManagementDetails(get(propertyManagement, "propertyManagementKey", null), sync);
        toggleModal(false);
    };
    return props.saving ? (
        <CircularProgress color="primary" size={20} style={{ marginRight: "40px" }} />
    ) : (
        <React.Fragment>
            <Button variant="contained" color="primary" onClick={saveOrSync}>
                Save
            </Button>
            <SaveAndSyncModal
                isOpen={modal}
                toggleModal={toggleModal}
                propertyKey={propertyKey}
                handleSubmit={handleSubmit}
                {...props}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        propertyManagement: state.propertyReducer.propertyManagement,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        savePropertyManagementDetails: (key, sync) => {
            dispatch(operations.savePropertyManagementDetails(key, sync));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveAndSyncContainer);
