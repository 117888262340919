import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Switch, Radio, FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import BSInputSmallNumeric from "../../../common/js/components/BSInputSmallNumeric";
import { DownChevron } from "../../../common/js/icons";
import get from "lodash/get";
import each from "lodash/each";
import MarketFilters from "./MarketFilters";
import Chip from "@material-ui/core/Chip";
import PropertyTypeFilter from "./PropertyTypeFilter";
import LocationFilters from "./LocationFilters";

const styles = {
    menuSearchButton: {
        marginBottom: "15px",
    },
    root: {
        "&:hover": {
            cursor: "pointer",
        },
        backgroundColor: "white",
    },
    propertyTypeContainer: {
        width: "32em",
        maxHeight: "78vh",
        overflowY: "scroll",
    },
    marketFilterContainer: {
        width: "32em",
        maxHeight: "78vh",
        overflowY: "scroll",
    },
    checkbox: {
        border: "1px dotted",
    },
    statusContainer: {
        minWidth: "210px",
        padding: "0 15px",
    },
    locationFilterContainer: {
        minWidth: "350px",
        padding: "20px",
        maxHeight: "78vh",
        overflowY: "scroll",
    },
    labelThing: {
        marginRight: "0",
    },
    fakeSelect: {
        height: "39px",
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
        marginLeft: 0,
        fontWeight: 400,
        width: "100%",
        justifyContent: "flex-start",
        padding: "0 26px 0 6px",
        backgroundColor: "white",
        fontSize: "14px",
    },
    focusVisible: {
        border: "1px solid #2C99D2",
        backgroundColor: "white",
    },
    fakeSelectIcon: {
        position: "absolute",
        right: "6px",
        fontSize: "16px",
    },
    filterBar: {
        backgroundColor: "white",
        paddingTop: "15px",
        paddingRight: "20px",
        paddingLeft: "15px",
        borderBottom: "1px solid #eee",
    },
    statusSwitch: {
        marginRight: "-15px",
    },
    menuTitle: {
        fontSize: "14px",
    },
    hrDivider: {
        position: "absolute",
        width: "100%",
        left: 0,
        top: "25px",
    },
    bootstrapInput: {
        marginTop: "300px",
    },
    menuSectionTitle: {
        color: "#9E9E9E",
        fontSize: "13px",
        fontWeight: 600,
        textTransform: "uppercase",
        marginBottom: "5px",
    },
};

class PropertiesFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open: {},
            placement: null,
        };
    }

    componentDidMount = () => {
        this.initializeSizeCriteria();
    };

    initializeSizeCriteria = () => {
        if (
            !get(this.props.searchCriteria, "sizeCriteria.availableSf", false) &&
            !get(this.props.searchCriteria, "sizeCriteria.totalSf", false) &&
            !get(this.props.searchCriteria, "sizeCriteria.acreage", false)
        ) {
            this.props.handleSizeRadioClick("availableSf", true);
        }
    };

    handleClick = (placement) => (event, filter) => {
        const { currentTarget } = event;
        if (this.state.open[filter]) {
            this.handleClose();
        } else {
            this.setState((state) => ({
                anchorEl: currentTarget,
                open: {
                    [filter]: state.placement !== placement || !state.open[filter],
                },
                placement,
            }));
        }
    };

    handleClose = () => {
        console.log("close");
        this.setState({ open: {} });
    };

    onKeyPress = (event) => {
        if (event.key === "Enter") {
            this.props.searchSubmit();
            this.setState({ open: {} });
        }
    };

    handleClearFilters = () => {
        this.props.clearFilters();
        this.setState({ open: {} });
        this.props.handleSizeRadioClick("availableSf", true);
    };

    handleSearchSubmit = () => {
        this.props.searchSubmit();
        this.setState({ open: {} });
    };

    countPropertyTypeSelected = (searchCriteria) => {
        let count = 0;
        each(get(searchCriteria, "propertyTypes", []), (p) => {
            if (p.selected === true) {
                count += 1;
            }
        });
        return count;
    };

    countFilter = (searchCriteria, filterName) => {
        let count = 0;
        each(get(searchCriteria, filterName, []), (option) => {
            if (option === true) {
                count += 1;
            }
        });
        return count;
    };

    combineFilterCount = (searchCriteria, criteriaOne, criteriaTwo) => {
        return this.countFilter(searchCriteria, criteriaOne) + this.countFilter(searchCriteria, criteriaTwo);
    };

    render() {
        const {
            classes,
            updateFilter,
            handleSizeRadioClick,
            propertySearchFilters,
            handleSelectAllPropertyType,
            searchCriteria,
            togglePropertyTypeExpansionPanel,
            propertyTypeExpansionPanels,
            toggleMarketFilterExpansionPanel,
            marketFilterExpansionPanels,
            handleSelectMarket,
            handleSelectSubmarket,
            handleSelectMicromarket,
            loadedSearchCrumbs,
            handleDeleteCrumb,
        } = this.props;
        const { anchorEl, open, placement } = this.state;

        return (
            <div className={classes.filterBar}>
                <Grid container justify="space-between" alignItems="center" spacing={8}>
                    <Grid item x style={{ fontWeight: 400, maxWidth: "70px", marginLeft: "12px", marginRight: "5px" }}>
                        <div>Filters:</div>
                    </Grid>
                    <Grid item xs>
                        <Popper open={open["location"]} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.locationFilterContainer}>
                                            <LocationFilters
                                                updateFilter={updateFilter}
                                                searchCriteria={searchCriteria}
                                                onKeyPress={this.onKeyPress}
                                                hideFilters={false}
                                            ></LocationFilters>

                                            <Grid container spacing={16} justify="flex-end">
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSearchSubmit}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "location")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {"Location"}{" "}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs>
                        <Popper open={open["marketArea"]} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.marketFilterContainer}>
                                            <MarketFilters
                                                searchCriteria={searchCriteria}
                                                toggleMarketFilterExpansionPanel={toggleMarketFilterExpansionPanel}
                                                marketFilterExpansionPanels={marketFilterExpansionPanels}
                                                handleSelectMarket={handleSelectMarket}
                                                handleSelectSubmarket={handleSelectSubmarket}
                                                handleSelectMicromarket={handleSelectMicromarket}
                                            ></MarketFilters>

                                            <Grid
                                                container
                                                spacing={16}
                                                justify="flex-end"
                                                style={{ padding: "10px", paddingRight: "16px" }}
                                            >
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSearchSubmit}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "marketArea")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {`Market Area ${
                                this.countFilter(searchCriteria, "locationCriteria.markets") > 0
                                    ? `(${this.countFilter(searchCriteria, "locationCriteria.markets")})`
                                    : ""
                            }`}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs>
                        <Popper open={open["propertyType"]} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.propertyTypeContainer}>
                                            <PropertyTypeFilter
                                                updateFilter={updateFilter}
                                                propertySearchFilters={propertySearchFilters}
                                                handleSelectAllPropertyType={handleSelectAllPropertyType}
                                                searchCriteria={searchCriteria}
                                                handleSearchSubmit={this.handleSearchSubmit}
                                                togglePropertyTypeExpansionPanel={togglePropertyTypeExpansionPanel}
                                                propertyTypeExpansionPanels={propertyTypeExpansionPanels}
                                            />
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "propertyType")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {`Property Type ${
                                this.countPropertyTypeSelected(searchCriteria) > 0
                                    ? `(${this.countPropertyTypeSelected(searchCriteria)})`
                                    : ""
                            }`}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs>
                        <Popper open={get(open, "status", false)} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.statusContainer}>
                                            <Grid container justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <span className={classes.menuTitle}>KM Managed Only</span>
                                                    <hr className={classes.hrDivider} />
                                                </Grid>
                                                <Grid item>
                                                    <Switch
                                                        checked={get(
                                                            searchCriteria,
                                                            "statusCriteria.kmManagedOnly",
                                                            false
                                                        )}
                                                        onChange={(e) =>
                                                            updateFilter(
                                                                "statusCriteria.kmManagedOnly",
                                                                e.target.checked
                                                            )
                                                        }
                                                        value="kmManagedOnly"
                                                        className={classes.statusSwitch}
                                                        color="primary"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <h4 className={classes.menuSectionTitle}>Status </h4>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="existing"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.existing",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.existing",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Existing"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="proposed"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.proposed",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.proposed",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Proposed"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="underConstruction"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.underConstruction",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.underConstruction",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Under Construction"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="underRenovation"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.underRenovation",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.underRenovation",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Under Renovation"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="demolished"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.demolished",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.demolished",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Demolished"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="land"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.land",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.land",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Land"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row">
                                                <Grid item xs={12}>
                                                    <h4 className={classes.menuSectionTitle}>Tenancy </h4>
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "tenancyCriteria.single",
                                                                    false
                                                                )}
                                                                onChange={(e) =>
                                                                    updateFilter(
                                                                        "tenancyCriteria.single",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Single"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "tenancyCriteria.multi",
                                                                    false
                                                                )}
                                                                onChange={(e) =>
                                                                    updateFilter(
                                                                        "tenancyCriteria.multi",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Multi"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container justify="flex-end">
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSearchSubmit}
                                                        className={classes.menuSearchButton}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "status")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {`Status ${
                                this.combineFilterCount(searchCriteria, "statusCriteria", "tenancyCriteria") > 0
                                    ? `(${this.combineFilterCount(
                                          searchCriteria,
                                          "statusCriteria",
                                          "tenancyCriteria"
                                      )})`
                                    : ""
                            }`}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs>
                        <Popper open={open["size"]} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.statusContainer}>
                                            <Grid container justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <FormControlLabel
                                                        value="availableSf"
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "sizeCriteria.availableSf",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    handleSizeRadioClick(
                                                                        "availableSf",
                                                                        !get(
                                                                            searchCriteria,
                                                                            "sizeCriteria.availableSf",
                                                                            false
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Available SF"
                                                        className={classes.labelThing}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        value="totalSf"
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "sizeCriteria.totalSf",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    handleSizeRadioClick(
                                                                        "totalSf",
                                                                        !get(
                                                                            searchCriteria,
                                                                            "sizeCriteria.totalSf",
                                                                            false
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Total SF"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        value="acreage"
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "sizeCriteria.acreage",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    handleSizeRadioClick(
                                                                        "acreage",
                                                                        !get(
                                                                            searchCriteria,
                                                                            "sizeCriteria.acreage",
                                                                            false
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Total Acreage"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container justify="space-between" spacing={16}>
                                                <Grid item xs>
                                                    <BSInputSmallNumeric
                                                        placeholder="Min SF"
                                                        value={get(searchCriteria, "sizeCriteria.min", "")}
                                                        onChange={(e) =>
                                                            updateFilter("sizeCriteria.min", e.target.value)
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item align="center">
                                                    <div className="betweenInputs">&mdash;</div>
                                                </Grid>
                                                <Grid item xs>
                                                    <BSInputSmallNumeric
                                                        placeholder="Max SF"
                                                        value={get(searchCriteria, "sizeCriteria.max", "")}
                                                        onChange={(e) =>
                                                            updateFilter("sizeCriteria.max", e.target.value)
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={16} justify="flex-end">
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSearchSubmit}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "size")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {" "}
                            Size
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" onClick={this.handleClearFilters}>
                            Clear
                        </Button>
                    </Grid>
                </Grid>
                <Grid container justify="space-between" alignItems="center" spacing={8} style={{ marginTop: "10px" }}>
                    <Grid item>
                        {loadedSearchCrumbs
                            ? Object.keys(loadedSearchCrumbs).map((criteria) => {
                                  if (loadedSearchCrumbs[criteria].value === true) {
                                      if (Object.keys(loadedSearchCrumbs[criteria]).length === 2) {
                                          return (
                                              <Chip
                                                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                                                  color="primary"
                                                  label={criteria}
                                                  onDelete={() =>
                                                      handleDeleteCrumb(loadedSearchCrumbs[criteria], false)
                                                  }
                                              />
                                          );
                                      } else if (
                                          (loadedSearchCrumbs[criteria].valueMin !== "* " &&
                                              loadedSearchCrumbs[criteria].valueMin !== undefined) ||
                                          (loadedSearchCrumbs[criteria].valueMax !== " *" &&
                                              loadedSearchCrumbs[criteria].valueMax !== undefined)
                                      ) {
                                          return (
                                              <Chip
                                                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                                                  color="primary"
                                                  label={
                                                      criteria +
                                                      get(loadedSearchCrumbs[criteria], "unit", "") +
                                                      ": " +
                                                      get(loadedSearchCrumbs[criteria], "valueMin", "* ") +
                                                      "-" +
                                                      get(loadedSearchCrumbs[criteria], "valueMax", " *")
                                                  }
                                                  onDelete={() => handleDeleteCrumb(loadedSearchCrumbs[criteria], null)}
                                              />
                                          );
                                      }
                                  } else if (
                                      typeof loadedSearchCrumbs[criteria].value === "string" &&
                                      loadedSearchCrumbs[criteria].value != null
                                  ) {
                                      return (
                                          <Chip
                                              style={{ marginLeft: "10px", marginBottom: "10px" }}
                                              color="primary"
                                              label={criteria + ": " + loadedSearchCrumbs[criteria].value}
                                              onDelete={() => handleDeleteCrumb(loadedSearchCrumbs[criteria], null)}
                                          />
                                      );
                                  }
                                  return null;
                              })
                            : ""}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(PropertiesFilters);
