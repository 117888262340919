import types from "./types";

const setParentEntityTypeKey = (parentEntityTypeKey) => {
    return {
        type: types.SET_PARENT_ENTITY_TYPE_KEY,
        parentEntityTypeKey,
    };
};

const getActionTypes = (actionTypes) => {
    return {
        type: types.GET_ACTION_TYPES,
        actionTypes,
    };
};

const getDocumentTypes = (documentTypes) => {
    return {
        type: types.GET_DOCUMENT_TYPES,
        documentTypes,
    };
};

const getConditionTypes = (conditionTypes) => {
    return {
        type: types.GET_CONDITION_TYPES,
        conditionTypes,
    };
};

const getConditionOperators = (conditionOperators) => {
    return {
        type: types.GET_CONDITION_OPERATORS,
        conditionOperators,
    };
};

const getStates = (states) => {
    return {
        type: types.GET_STATES,
        states,
    };
};

const selectConditions = (field, value) => {
    return {
        type: types.SELECT_CONDITIONS,
        field,
        value,
    };
};

const selectConditionType = (conditionTypeKey) => {
    return {
        type: types.SELECT_CONDITION_TYPE,
        conditionTypeKey,
    };
};

const selectFromActions = (field, value) => {
    return {
        type: types.SELECT_FROM_ACTIONS,
        field,
        value,
    };
};

const getRulesByParentEntityTypeKey = (rules) => {
    return {
        type: types.GET_RULES_BY_PARENT_ENTITY_TYPE_KEY,
        rules,
    };
};

const getRulesByDocTypeKey = (rules) => {
    return {
        type: types.GET_RULES_BY_DOC_TYPE_KEY,
        rules,
    };
};

const addCondition = () => {
    return {
        type: types.ADD_CONDITION,
    };
};

const removeCondition = (conditionTypeKey) => {
    return {
        type: types.REMOVE_CONDITION,
        conditionTypeKey,
    };
};

const addAction = () => {
    return {
        type: types.ADD_ACTION,
    };
};

const removeAction = (documentTypeKey) => {
    return {
        type: types.REMOVE_ACTION,
        documentTypeKey,
    };
};

const handleRuleInputChange = (field, value) => {
    return {
        type: types.HANDLE_RULE_INPUT_CHANGE,
        field,
        value,
    };
};

const updateDocumentTypeDropdown = () => {
    return {
        type: types.UPDATE_DOCUMENT_TYPE_DROPDOWN,
    };
};

const updateConditionTypeDropdown = () => {
    return {
        type: types.UPDATE_CONDITION_TYPE_DROPDOWN,
    };
};

const toggleModal = (toggleModal) => {
    return {
        type: types.TOGGLE_MODAL,
        toggleModal,
    };
};

const addRule = () => {
    return {
        type: types.ADD_RULE,
    };
};

const editRule = (ruleKey) => {
    return {
        type: types.EDIT_RULE,
        ruleKey,
    };
};

const updateRuleOrder = (rules) => {
    return {
        type: types.UPDATE_RULE_ORDER,
        rules,
    };
};

const revertDropdownDisplays = () => {
    return {
        type: types.REVERT_DROPDOWN_DISPLAYS,
    };
};

const resetPriorities = () => {
    return {
        type: types.RESET_PRIORITIES,
    };
};

const getAllParentEntityTypes = (parentEntityTypes) => {
    return {
        type: types.GET_ALL_PARENT_ENTITY_TYPES,
        parentEntityTypes,
    };
};

export default {
    setParentEntityTypeKey,
    getActionTypes,
    getDocumentTypes,
    getConditionTypes,
    getConditionOperators,
    getStates,
    selectConditions,
    selectFromActions,
    getRulesByParentEntityTypeKey,
    addCondition,
    removeCondition,
    addAction,
    removeAction,
    handleRuleInputChange,
    updateDocumentTypeDropdown,
    selectConditionType,
    toggleModal,
    addRule,
    editRule,
    updateConditionTypeDropdown,
    updateRuleOrder,
    getRulesByDocTypeKey,
    revertDropdownDisplays,
    resetPriorities,
    getAllParentEntityTypes,
};
