import types from "./types";

const setEvpDataComponent = (component, data) => {
    return {
        type: types.SET_EVP_DATA_COMPONENT,
        component: component,
        data: data,
    };
};

const setBrokerDataComponent = (component, data) => {
    return {
        type: types.SET_BROKER_DATA_COMPONENT,
        component: component,
        data: data,
    };
};

const handleSort = (component, columnName, sortDirection) => {
    return {
        type: types.SORT_DASHBOARD_COLUMN,
        component: component,
        columnName: columnName,
        sortDirection: sortDirection,
    };
};

const changeDashboardParam = (name, value) => {
    return {
        type: types.SET_DASHBOARD_PARAM,
        name: name,
        value: value,
    };
};

export default {
    setEvpDataComponent,
    setBrokerDataComponent,
    handleSort,
    changeDashboardParam,
};
