export const propertyManagerTypes = [
    {
        value: "Property Manager",
        label: "Property Manager",
    },
    {
        value: "Property Admin",
        label: "Property Admin",
    },
    {
        value: "Accounting",
        label: "Accounting",
    },
    {
        value: "Senior Property Manager",
        label: "Senior Property Manager",
    },
    {
        value: "Associate Property Manager",
        label: "Associate Property Manager",
    },
    {
        value: "Operations Manager",
        label: "Operations Manager",
    },
    {
        value: "Market Leader",
        label: "Market Leader",
    },
    {
        value: "Other",
        label: "Other",
    },
];
