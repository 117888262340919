export const clientTypes = [
    {
        value: "Institutional",
        label: "Institutional",
    },
    {
        value: "Corporate",
        label: "Corporate",
    },
    {
        value: "Non-profit",
        label: "Non-profit",
    },
    {
        value: "Private Equity",
        label: "Private Equity",
    },
    {
        value: "Public Agency",
        label: "Public Agency",
    },
];
