import React, { Component } from "react";
import { Link } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import { Doc } from "../../../common/js/icons";
import ReactTooltip from "react-tooltip";
import { Draggable } from "react-beautiful-dnd";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import schema from "./AuditSearchTables";
import { getFromAttributeValues } from "../utils/operations";
import { noPhotoUrl } from "../utils/constants";

const styles = () => ({
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    documentLink: {
        color: "#2C99D2",
        cursor: "pointer",
        fontWeight: 600,
        textDecoration: "none",
    },
    linkDisabled: {
        color: "grey",
        cursor: "default",
        fontWeight: 600,
        textDecoration: "none",
    },
    centerItems: {
        alignItems: "center",
    },
    paddingLeft: {
        paddingLeft: "25px",
    },
});

class AuditSearchRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            sortedDocuments: [],
            isChecked: props.comp.audit_flg,
        };
    }

    componentDidMount() {
        this.loadDocuments(
            get(this.props.comp, "attachedRequiredDocuments"),
            get(this.props.comp, "missingRequiredDocuments")
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.comp.audit_flg !== prevProps.comp.audit_flg) {
            this.setState(() => ({ isChecked: this.props.comp.audit_flg }));
        }
    }

    loadDocuments = (attached, missing) => {
        let aggregatedDocs = undefined;

        if (attached && missing) {
            aggregatedDocs = [...attached, ...missing];
        } else if (attached) {
            aggregatedDocs = attached;
        } else if (missing) {
            aggregatedDocs = missing;
        }
        this.setState({
            sortedDocuments: sortBy(aggregatedDocs, ["documentTypeName", "documentTypeKey"]),
        });
    };

    render() {
        const { viewDocument, classes, comp, i, activeTab, toggleCompAuditFlag } = this.props;

        const { expanded, sortedDocuments, isChecked } = this.state;

        const setExpanded = () => {
            const tempStatus = this.state.expanded;
            this.setState({
                expanded: !tempStatus,
            });
        };

        const getItemStyle = (isDragging, draggableStyle) => ({
            userSelect: "none",
            // change background colour if dragging
            background: isDragging ? "#C5C5C5" : "",

            // styles we need to apply on draggables
            ...draggableStyle,
        });

        return (
            <Draggable draggableId={comp.dealkey ? comp.dealkey : comp.dealKey} index={i}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                        <Grid container key={`tr-${i}`} selectable="false" className={classes.centerItems}>
                            {schema.viewHeaders(activeTab)["all_comps"].map((y, k) => {
                                return y.name === "ToggleToList" ? (
                                    <Grid item xs={1} className={classes.paddingLeft}>
                                        <span>
                                            <Checkbox
                                                checked={isChecked}
                                                color="primary"
                                                onChange={() => {
                                                    toggleCompAuditFlag(comp.dealkey);
                                                }}
                                            />
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => setExpanded()}
                                            >
                                                {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </span>
                                    </Grid>
                                ) : y.name === "Property" ? (
                                    <Grid
                                        item
                                        xs={1}
                                        classes={{ root: classes.body }}
                                        key={`trc-${k}`}
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            minWidth: "110px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            className="propertySmallImageContainer"
                                            style={{
                                                width: "85px",
                                                height: "80px",
                                                margin: "10px 23px 10px 27px",
                                                backgroundImage: `url(${
                                                    get(comp, "listing_photo", null) !== null
                                                        ? get(comp, "listing_photo", null)
                                                        : get(comp, "property_photo", null) !== null
                                                        ? get(comp, "property_photo", null)
                                                        : noPhotoUrl
                                                })`,
                                            }}
                                        ></div>
                                    </Grid>
                                ) : y.name === "Address" ? (
                                    <Grid
                                        item
                                        xs={2}
                                        classes={{ root: classes.body }}
                                        key={`trc-${k}`}
                                        style={{
                                            position: "relative",
                                            fontSize: "13px",
                                            padding: `15px 10px 8px 24px`,
                                        }}
                                    >
                                        <Link
                                            to={`/deal/${comp.dealkey ? comp.dealkey : comp.dealKey}`}
                                            className={classes.lb}
                                        >
                                            <span className={classes.listingIdLink}>{get(comp, "listing_name")}</span>
                                        </Link>
                                        <div style={{ color: "red", fontWeight: "bold" }}>{`${
                                            getFromAttributeValues(comp, ["confidentialFlg", "confidential_flg"], false)
                                                ? "Confidential"
                                                : ""
                                        }`}</div>
                                        <div>{`${get(comp, "address1", {})},`}</div>
                                        <div>{`${get(comp, "city", {})}, ${get(comp, "state_code", "")} ${
                                            get(comp, "zip_postal_code", null) !== null
                                                ? get(comp, "zip_postal_code")
                                                : ""
                                        }`}</div>
                                        <div>{`${
                                            getFromAttributeValues(comp, ["confidentialFlg", "confidential_flg"], false)
                                                ? "Confidential "
                                                : ""
                                        } ${
                                            get(comp, "comp_status", false) === "OUTSIDE"
                                                ? "Outside"
                                                : get(comp, "comp_status", false) === "APPRAISAL"
                                                ? "Appraisal"
                                                : "KM"
                                        }, ${get(comp, "property_type")}`}</div>
                                    </Grid>
                                ) : y.transform ? (
                                    y.name === "Brokers" ? (
                                        <Grid
                                            item
                                            xs={1}
                                            key={`trc-${k}`}
                                            className={classes.tooltip}
                                            style={{
                                                fontSize: "13px",
                                                padding: `40px 10px 0px 0px`,
                                            }}
                                        >
                                            {get(comp, y.prop, " ") && (
                                                <div>
                                                    <span
                                                        className={classes.tooltip}
                                                        data-tip={
                                                            get(comp, y.prop, "").includes(",")
                                                                ? get(comp, y.prop, "")
                                                                : ""
                                                        }
                                                    >
                                                        {y.transform(get(comp, y.prop, ""))}
                                                    </span>
                                                    <ReactTooltip place="top" type="dark" effect="solid" />
                                                </div>
                                            )}
                                        </Grid>
                                    ) : (
                                        // deal type
                                        <Grid
                                            item
                                            xs={1}
                                            classes={{ root: classes.body }}
                                            key={`trc-${k}`}
                                            numeric={y.numeric}
                                            style={{
                                                fontSize: "13px",
                                                padding: `40px 10px 0px 0px`,
                                            }}
                                        >
                                            {y.transform(get(comp, y.prop))}
                                        </Grid>
                                    )
                                ) : y.name === "Documents" ? (
                                    <Grid
                                        item
                                        xs={5}
                                        style={{
                                            minWidth: "300px",
                                            paddingTop: "30px",
                                            display: "flex",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        {sortedDocuments.map((document, document_index) => (
                                            <span>
                                                <span data-tip data-for={`tr-${i}-doc-${document_index}`}>
                                                    <Button
                                                        disabled={get(document, "mediaAssetKey", null) === null}
                                                        onClick={() =>
                                                            viewDocument(null, get(document, "mediaAssetKey"))
                                                        }
                                                        color="primary"
                                                        className={classes.lightButton}
                                                    >
                                                        <Doc
                                                            fill={
                                                                get(document, "mediaAssetKey", null) !== null
                                                                    ? "#2C99D2"
                                                                    : "#9E9E9E"
                                                            }
                                                        />
                                                    </Button>
                                                </span>
                                                <ReactTooltip
                                                    id={`tr-${i}-doc-${document_index}`}
                                                    place="top"
                                                    type="dark"
                                                    effect="solid"
                                                    className="statBoxTooltip"
                                                >
                                                    {get(document, "documentTypeName", "")}
                                                </ReactTooltip>
                                            </span>
                                        ))}
                                    </Grid>
                                ) : y.name === "Batch Date" ? null : (
                                    <Grid
                                        item
                                        xs={1}
                                        classes={{ root: classes.body }}
                                        key={`trc-${k}`}
                                        numeric={y.numeric}
                                        style={{
                                            fontSize: "13px",
                                            padding: `40px 10px 0px 0px`,
                                        }}
                                    >
                                        {get(comp, y.prop)}
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Collapse in={expanded} timeout="auto">
                            <Grid style={{ borderTop: "1px solid rgb(212, 212, 212)" }}>
                                {sortedDocuments.map((document, document_index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontSize: "13px",
                                            padding: `10px 10px 0px 0px`,
                                            borderBottom: "1px solid rgb(212, 212, 212)",
                                        }}
                                    >
                                        <span data-tip data-for={`tr-${i}-expandedDoc-${document_index}`}>
                                            <Button
                                                disabled={get(document, "mediaAssetKey", null) === null}
                                                onClick={() => viewDocument(null, get(document, "mediaAssetKey"))}
                                                color="primary"
                                                className={classes.lightButton}
                                            >
                                                <Doc
                                                    fill={
                                                        get(document, "mediaAssetKey", null) !== null
                                                            ? "#2C99D2"
                                                            : "#9E9E9E"
                                                    }
                                                />
                                            </Button>
                                        </span>
                                        <ButtonBase
                                            disabled={get(document, "mediaAssetKey", null) === null}
                                            onClick={() => viewDocument(null, get(document, "mediaAssetKey"))}
                                            color="primary"
                                            className={classes.documentLink}
                                        >
                                            <span
                                                className={
                                                    get(document, "mediaAssetKey", null)
                                                        ? classes.documentLink
                                                        : classes.linkDisabled
                                                }
                                            >
                                                {get(document, "documentTypeName", "")}
                                            </span>
                                        </ButtonBase>
                                    </Grid>
                                ))}
                            </Grid>
                        </Collapse>
                    </div>
                )}
            </Draggable>
        );
    }
}

export default withStyles(styles)(AuditSearchRow);
