import constants from "../constants";
import { request } from "../../utils/axios-wrapper";

const uploadResearchCaptureJob = (year, quarter, marketArea, propertyType, subMarketArea, microMarketArea) => {
    return request({
        url: constants.urls.capture_research(),
        method: "post",
        data: JSON.stringify({
            year: year,
            quarter: quarter,
            marketArea: marketArea,
            propertyType: propertyType,
            subMarketArea: subMarketArea,
            microMarketArea: microMarketArea,
        }),
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response.status;
        })
        .catch((response) => {
            return response.status;
        });
};

const getResearchByProperty = (propertyKey) => {
    return request({
        url: constants.urls.get_research(propertyKey),
        method: "get",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            return "error";
        });
};

const editExistingSnapshot = (snapshotKey, snapshot) => {
    return request({
        url: constants.urls.edit_snapshot(snapshotKey),
        method: "put",
        data: snapshot,
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response;
        })
        .catch((response) => {
            return response;
        });
};

const exportResearch = (searchRequest) => {
    return request({
        url: constants.urls.export_research(),
        method: "post",
        data: searchRequest,
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response;
        })
        .catch((response) => {
            return response;
        });
};

export default { uploadResearchCaptureJob, getResearchByProperty, editExistingSnapshot, exportResearch };
