import types from "./types";
import cloneDeep from "lodash/cloneDeep";
import setWith from "lodash/setWith";
import clone from "lodash/clone";
import filter from "lodash/filter";

export default (
    state = {
        leaseDetails: {},
        propertyDetails: {},
        outsideSplitModalIsOpen: false,
        selected: [],
        usedDocTypes: [],
        documents: {},
        isSaving: false,
        snackbar: {
            open: false,
        },
        documentWarning: {
            open: false,
            proceed: true,
            docs: [],
        },

        comps: {
            comp_history_for_property: [],
        },
        numComps: 0,
        comp: {},
        deleteOutsideCompDialog: {
            open: false,
        },
        modalData: {},
        selectedComps: new Map(),
        selectedCompsGroup: new Map(),
        brokerComps: new Map(),
        modalContent: {
            originalPhoto: "",
            isOpen: false,
            dealParty: "",
            timeOnMarket: "",
            marketingFlyerPhoto: "",
            marketingFlyerPhotos: [],
        },
    },
    action
) => {
    switch (action.type) {
        case types.GET_VOUCHER:
            return Object.assign({}, state, {
                payload: action.payload,
                status: action.status,
                voucher: action.payload.voucherKey,
            });
        case types.DOCUMENT_WARNING:
            return {
                ...state,
                documentWarning: {
                    open: action.open,
                    proceed: action.proceed,
                    docs: action.docs,
                },
            };
        case types.COMP_VALID_DOCUMENTS:
            return {
                ...state,
                validDocuments: action.valid,
            };
        case types.COMP_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    open: action.open,
                    message: action.message,
                    variant: action.variant,
                },
            };

        case types.AUTOSAVE_NOT_SUCCESS:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
                saveError: action.saveError,
            });
        case types.AUTOSAVE_SUCCESS:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
                lastSaved: action.lastSaved,
            });
        case types.PROPERTY_DETAILS_CHANGE:
            return {
                ...state,
                propertyDetails: {
                    ...state.propertyDetails,
                    [action.field]: action.value,
                },
            };
        case types.STATE_OBJECT_PROPERTY_CHANGE:
            return {
                ...state,
                [action.key]: {
                    ...[action.key],
                    [action.field]: action.value,
                },
            };
        case types.SWITCH_REVIEW_COMPS_TAB:
            return Object.assign({}, state, {
                compTab: action.tab,
            });
        case types.RECEIVE_REVIEW_COMPS:
            return Object.assign({}, state, {
                comps: {
                    ...state.comps,
                    [action.tab]: action.comps,
                },
            });
        case types.COUNT_REVIEW_COMPS:
            return Object.assign({}, state, {
                ...state,
                numComps: action.numComps,
            });
        case types.ADD_OUTSIDE_COMP:
            return Object.assign({}, state, {
                comp: {
                    transactionType: action.transactionType,
                    propertyType: action.propertyType,
                    dealStatus: "Outside",
                },
            });
        case types.COMP_INPUT:
            return setWith(clone(state), `${action.path}`, action.value, clone);
        case types.SELECT_PROPERTY_FROM_AUTOCOMPLETE_OUTSIDE_COMP_MODAL:
            return {
                ...state,
                modalData: {
                    ...state.modalData,
                    property: action.property,
                },
            };
        case types.SET_CHECKED_COMPS:
            return {
                ...state,
                selectedComps: action.comps,
            };
        case types.UPDATE_COMP_ORDER:
            return {
                ...state,
                comps: {
                    ...state.comps,
                    [action.tab]: action.comps,
                },
            };

        case types.UPDATE_SELECTED_COMP_ORDER:
            return {
                ...state,
                selectedComps: action.comps,
            };
        case types.DELETE_OUTSIDE_COMP_DIALOG:
            return {
                ...state,
                deleteOutsideCompDialog: {
                    open: action.open,
                },
            };
        case types.CLEAR_ALL_COMPS:
            return {
                ...state,
                comps: {
                    ...state.comps,
                    comp_history_for_property: {},
                    all_km_comps: {},
                },
            };
        case types.TOGGLE_COMP_FOR_REPORT:
            //return setWith(clone(state), `selectedComps.${action.key}`, action.newValue, clone);
            const newState = cloneDeep(state);
            newState.selectedComps.set(action.key, action.newValue);
            return newState;

        case types.UPDATE_SELECTED_COMPS_GROUP:
            const newState2 = cloneDeep(state);
            newState2.selectedCompsGroup.set(action.key, action.newValue);
            return newState2;

        case types.SET_COMPS_GROUP:
            return {
                ...state,
                comps: {
                    ...state.comps,
                    comps_group: action.comps_group,
                },
            };
        case types.SET_GROUP_CHECKED_COMPS:
            return {
                ...state,
                selectedCompsGroup: action.selectedCompsGroup,
            };
        case types.SET_SELECTED_BROKER_COMPS:
            console.log(
                " setting broker comps to action.newValue  ---- in set_selected_broker_comps     " + action.newValue
            );
            return {
                ...state,
                brokerComps: action.newValue,
            };
        case types.UPDATE_SELECTED_BROKER_COMP:
            const newState3 = cloneDeep(state);
            newState3.brokerComps.set(action.key, action.newValue);
            return newState3;
        case types.TOGGLE_MODAL:
            return {
                ...state,
                modalContent: {
                    ...state.modalContent,
                    isOpen: action.isOpen,
                },
            };
        case types.SET_DEAL_PARTY:
            return {
                ...state,
                modalContent: {
                    ...state.modalContent,
                    dealParty: action.dealParty,
                },
            };
        case types.TIME_ON_MARKET_INPUT:
            return {
                ...state,
                modalContent: {
                    ...state.modalContent,
                    timeOnMarket: action.input,
                },
            };
        case types.GET_MARKETING_FLYER_PHOTO:
            return {
                ...state,
                modalContent: {
                    ...state.modalContent,
                    marketingFlyerPhoto: action.photo,
                    originalPhoto: action.photo,
                },
            };
        case types.GET_MARKETING_FLYER_PHOTOS:
            return {
                ...state,
                modalContent: {
                    ...state.modalContent,
                    marketingFlyerPhotos: filter(action.photos, (photo) => !photo.externalAssetFlg),
                },
            };
        case types.RESET_PHOTO:
            return {
                ...state,
                modalContent: {
                    ...state.modalContent,
                    marketingFlyerPhoto: state.modalContent.originalPhoto,
                },
            };
        case types.SET_MARKETING_FLYER_PHOTO:
            return {
                ...state,
                modalContent: {
                    ...state.modalContent,
                    marketingFlyerPhoto: action.photo,
                },
            };
        default:
            return state;
    }
};
