import React from "react";
import get from "lodash/get";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import BSInput from "../../../../common/js/components/BSInput";
import "./Condo.css";

const styles = () => ({
    centerCell: {
        textAlign: "center",
        fill: "#9e9e9e",
    },
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    responsiveFlexItem: {
        flex: "auto",
    },
});

const CondoDetail = (props) => {
    const {
        classes,
        dataPathPrefix,
        disableInputs,
        element,
        elementIndex,
        errors,
        handleUpdateArrayElement,
        onDelete,
    } = props;

    return (
        <div>
            <div className={elementIndex === 0 ? "condoFlexContainer" : "condoFlexContainerWElementIndex"}>
                <div className={classes.responsiveFlexItem}>
                    <BSInput
                        id="condoNumber"
                        label={elementIndex === 0 ? "Condo Number" : null}
                        placeholder="Enter condo number"
                        error={get(errors, `condos.${elementIndex}.condoNumber`, null)}
                        value={element ? element.condoNumber : null}
                        onChange={(e) =>
                            handleUpdateArrayElement(
                                dataPathPrefix + ".condos",
                                elementIndex,
                                e.target.id,
                                e.target.value
                            )
                        }
                        disabled={disableInputs}
                    />
                </div>
                <div className={classes.responsiveFlexItem}>
                    <BSInput
                        id="condoSf"
                        label={elementIndex === 0 ? "Condo Square Feet" : null}
                        errorText="(ex. 98101 or 981.01)"
                        placeholder="Enter SF"
                        value={element ? element.condoSf : null}
                        error={get(errors, `condos.${elementIndex}.condoSf`, null)}
                        onChange={(e) =>
                            handleUpdateArrayElement(
                                dataPathPrefix + ".condos",
                                elementIndex,
                                e.target.id,
                                e.target.value
                            )
                        }
                        disabled={disableInputs}
                    />
                </div>
                <div className={classes.responsiveFlexItem}>
                    <BSInput
                        id="condoParcelId"
                        label={elementIndex === 0 ? "Unit Count" : null}
                        placeholder="Enter count"
                        value={element ? element.condoParcelId : null}
                        error={get(errors, `condos.${elementIndex}.condoParcelId`, null)}
                        onChange={(e) =>
                            handleUpdateArrayElement(
                                dataPathPrefix + ".condos",
                                elementIndex,
                                e.target.id,
                                e.target.value
                            )
                        }
                        disabled={disableInputs}
                    />
                </div>
                <div className={`${classes.centerCell} ${elementIndex === 0 ? "condoParcelRightPadding" : ""}`}>
                    {elementIndex > 0 && disableInputs !== true && (
                        <Button onClick={() => onDelete(elementIndex)} className={classes.shrinkButton}>
                            <Delete />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(CondoDetail);
