import React from "react";
import get from "lodash/get";
import BSInput from "../../../../common/js/components/BSInput";
import BSSelect from "../../../../common/js/components/BSSelect";
import constants from "../constants";
import KMCounter from "../../../../common/js/components/KMCounter";
import { round } from "../../utils/operations";
import { yes_no_options } from "../../utils/constants";
import "./BuildingForm.css";

const BuildingForm = (props) => {
    const { data, disableInputs, errors, location, onChange } = props;

    const handleBuildingDetailChange = (e) => {
        onChange(e.target.id, e.target.value);
    };

    const handleBuildingCounter = (field, value) => {
        onChange(field, value);
    };

    const handleLotSizeAutoCalc = (field, value) => {
        switch (field) {
            case "lotAcres":
                const lotSf = value * 43560;
                onChange("lotAcres", value);
                onChange("lotSf", lotSf);
                break;
            case "lotSf":
                const lotAcres = round(value / 43560, 2);
                onChange("lotSf", value);
                onChange("lotAcres", lotAcres);
                break;
            default:
                break;
        }
    };

    const flexContainerClass = location.pathname.includes("listing")
        ? "buildingFormFlexContainer"
        : "buildingFormFlexContainerCompact";

    return (
        <div>
            <div className={flexContainerClass}>
                <div className="buildingFormFlexField">
                    <BSInput
                        id="buildingName"
                        label="BUILDING NAME"
                        placeholder="Enter building name"
                        error={errors.buildingName}
                        value={data.buildingName}
                        onChange={handleBuildingDetailChange}
                        disabled={disableInputs}
                    />
                </div>
                <div className="buildingFormFlexField">
                    <BSInput
                        id="businessParkName"
                        label="INDUSTRIAL PARK / OFFICE PARK / RETAIL CENTER BUILDING NAME"
                        placeholder="Enter industrial park, office park, or retail center building name"
                        error={errors.businessParkName}
                        value={data.businessParkName}
                        onChange={handleBuildingDetailChange}
                        disabled={disableInputs}
                    />
                </div>
            </div>

            <div className={flexContainerClass}>
                <div className="buildingFormFlexContainerUnresponsive">
                    <div className="buildingFormFlexField">
                        <BSSelect
                            id="bldgStatus"
                            label="BUILDING STATUS"
                            options={constants.optionsBuilder(constants.buildingStatuses)}
                            error={errors.bldgStatus}
                            value={data.bldgStatus}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="buildingFormFlexField">
                        <BSSelect
                            id="bldgClass"
                            label="BUILDING CLASS"
                            options={constants.optionsBuilder(constants.buildingClasses)}
                            error={errors.bldgClass}
                            value={data.bldgClass}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
                <div className="buildingFormFlexContainerUnresponsive">
                    <div className="buildingFormFlexField">
                        <BSInput
                            id="yearBuilt"
                            label="YEAR BUILT"
                            placeholder="Enter year built"
                            errorText="ex. 1974"
                            error={errors.yearBuilt}
                            value={data.yearBuilt}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="buildingFormFlexField">
                        <BSInput
                            id="yearRemodeled"
                            label="YEAR RENOVATED"
                            placeholder="Enter year renovated"
                            errorText="ex. 1974"
                            error={errors.yearRemodeled}
                            value={data.yearRemodeled}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
            </div>

            <div className={flexContainerClass}>
                <div className="buildingFormFlexContainerUnresponsive">
                    <div className="buildingFormFlexField">
                        <BSInput
                            id="totalBldgSf"
                            label="Total Building Sf"
                            placeholder="Enter building sf"
                            errorText="ex. 98101"
                            error={errors.totalBldgSf}
                            value={data.totalBldgSf}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                            numeric
                        />
                    </div>
                    <div className="buildingFormFlexField">
                        <KMCounter
                            id="numFloors"
                            label="Number of Floors"
                            placeholder="Enter number of floors"
                            errorText="ex. 9"
                            error={errors.numFloors}
                            value={get(data, "numFloors", "")}
                            onChange={handleBuildingCounter}
                            min={1}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
                <div className="buildingFormFlexContainerUnresponsive">
                    <div className="buildingFormFlexField">
                        <BSInput
                            id="numBldg"
                            label="BUILDING COUNT"
                            placeholder="Enter bulding count"
                            errorText="ex. 1"
                            error={errors.numBldg}
                            value={data.numBldg}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="buildingFormFlexField">
                        <BSInput
                            id="buildingNumber"
                            label="BUILDING NUMBER"
                            placeholder="Enter building number"
                            errorText="ex. 14"
                            error={errors.buildingNumber}
                            value={data.buildingNumber}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
            </div>
            <div className={flexContainerClass}>
                <div className="buildingFormFlexContainerUnresponsive">
                    <div className="buildingFormFlexField">
                        <BSInput
                            id="lotAcres"
                            label="LOT SIZE (ACRES)"
                            placeholder="Enter land acreage"
                            errorText="ex. 98.1"
                            error={errors.lotAcres}
                            value={data.lotAcres}
                            type="number"
                            onChange={(e) => handleLotSizeAutoCalc(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="buildingFormFlexField">
                        <BSInput
                            id="lotSf"
                            label="LOT SIZE (SF)"
                            placeholder="Enter SF"
                            errorText="ex. 98101"
                            error={errors.lotSf}
                            value={data.lotSf}
                            type="number"
                            onChange={(e) => handleLotSizeAutoCalc(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
                <div className="buildingFormFlexContainerUnresponsive">
                    <div className="buildingFormFlexField">
                        <BSSelect
                            id="constructionType"
                            label="CONSTRUCTION TYPE"
                            error={errors.constructionType}
                            value={data.constructionType}
                            onChange={handleBuildingDetailChange}
                            options={constants.optionsBuilder(constants.constructionTypes)}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="buildingFormFlexField">
                        <BSInput
                            id="elevators"
                            label="ELEVATOR COUNT"
                            placeholder="Enter elevator count"
                            errorText="ex. 9"
                            error={errors.elevators}
                            value={data.elevators}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
            </div>

            <div className={flexContainerClass}>
                <div className="buildingFormFlexContainerUnresponsive">
                    <div className="buildingFormFlexField">
                        <BSSelect
                            id="leedCertLevel"
                            label="LEED CERTIFICATION LEVEL"
                            options={constants.optionsBuilder(constants.leedCertLevels)}
                            error={errors.leedCertLevel}
                            value={data.leedCertLevel}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </div>

                    <div className="buildingFormFlexField">
                        <BSInput
                            id="totalUnits"
                            label="TOTAL UNITS"
                            error={errors.totalUnits}
                            value={data.totalUnits}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
                <div className="buildingFormFlexContainerUnresponsive">
                    <div className="buildingFormFlexField">
                        <BSInput
                            id="deliveredQtr"
                            label="DELIVERED QUARTER"
                            placeholder="Enter delivered quarter"
                            error={errors.deliveredQtr}
                            errorText="ex. 98.2"
                            value={data.deliveredQtr}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="buildingFormFlexField">
                        <BSInput
                            id="totalBldgNra"
                            label="NET RENTABLE AREA"
                            placeholder="Enter rentable area"
                            error={errors.totalBldgNra}
                            value={data.totalBldgNra}
                            onChange={handleBuildingDetailChange}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
            </div>
            <div className={flexContainerClass}>
                <div className="buildingFormFlexField">
                    <BSSelect
                        id="occupancyType"
                        label="TENANCY"
                        options={constants.optionsBuilder(constants.tenancyTypes)}
                        error={errors.occupancyType}
                        value={data.occupancyType}
                        onChange={handleBuildingDetailChange}
                        disabled={disableInputs}
                    />
                </div>
                <div className="buildingFormFlexField">
                    <BSSelect
                        id="energyStarCert"
                        label="ENERGY STAR RATING"
                        options={yes_no_options}
                        error={errors.energyStarCert}
                        value={data.energyStarCert}
                        onChange={handleBuildingDetailChange}
                        disabled={disableInputs}
                    />
                </div>
            </div>
            <BSInput
                id="freightElevatorCount"
                label="FREIGHT ELEVATOR COUNT"
                placeholder="Enter elevator count"
                errorText="ex. 9"
                error={errors.freightElevatorCount}
                value={data.freightElevatorCount}
                onChange={handleBuildingDetailChange}
                disabled={disableInputs}
            />
            <div className={flexContainerClass}>
                <div className="buildingFormFlexField">
                    <BSInput
                        id="zoningType"
                        label="ZONING TYPE"
                        placeholder="Enter zoning type"
                        error={errors.zoningType}
                        value={data.zoningType}
                        onChange={handleBuildingDetailChange}
                        disabled={disableInputs}
                    />
                </div>
                <div className="buildingFormFlexField">
                    <BSInput
                        id="zoningCode"
                        label="ZONING CODE"
                        placeholder="Enter zoning code"
                        error={errors.zoningCode}
                        value={data.zoningCode}
                        onChange={handleBuildingDetailChange}
                        disabled={disableInputs}
                    />
                </div>
            </div>
        </div>
    );
};

export default BuildingForm;
