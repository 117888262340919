import actions from "./actions";
import utils from "../../utils/index";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import get from "lodash/get";
import filter from "lodash/filter";
import constants from "../constants";
import { request } from "../../utils/axios-wrapper";
import { saveAs } from "file-saver";
import listing_operations from "../../listing/ducks/operations";
import deal_operations from "../../deal/ducks/operations";
import document_operations from "../../document-type/ducks/operations";

const saveDocument = (url, file, mediaType) => {
    return (dispatch) => {
        dispatch(actions.addDocumentUploading(true, file.name));
        const data = new FormData();
        data.append("file", file);
        data.append(
            "mediaAssetType",
            new Blob([JSON.stringify(mediaType)], {
                type: "application/json",
            })
        );
        request(
            {
                method: "post",
                url: url,
                data: data,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
            dispatch
        )
            .then((response) => {
                dispatch(removeDocumentFromUploadingQueue(file.name));
                dispatch(actions.addDocument(response.data));
            })
            .catch((err) => {
                dispatch(removeDocumentFromUploadingQueue(file.name));
                dispatch(documentSnackbar(true, "error", "Something went wrong. Unable to save document: " + err));
            });
    };
};

const saveDocumentCall = (url, file, mediaType) => {
    return (dispatch) => {
        const data = new FormData();
        data.append("file", file);
        data.append(
            "mediaAssetType",
            new Blob([JSON.stringify(mediaType)], {
                type: "application/json",
            })
        );
        return request(
            {
                method: "post",
                url: url,
                data: data,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
            dispatch
        )
            .then((response) => {
                console.log(response);
                dispatch(removeDocumentFromUploadingQueue(file.name));
                dispatch(actions.addDocument(response.data));
            })
            .catch((err) => {
                console.log(err);
                dispatch(removeDocumentFromUploadingQueue(file.name));
                dispatch(documentSnackbar(true, "error", "Something went wrong. Unable to save document: " + err));
            });
    };
};

function addDocument(url, documents, ownerType) {
    return async (dispatch) => {
        for (const file of documents) {
            dispatch(actions.addDocumentUploading(true, file.name));
        }
        for (const file of documents) {
            if (ownerType === "user") {
                await dispatch(saveDocumentCall(url, file, "LICENSE"));
            } else {
                await dispatch(saveDocumentCall(url, file, "OTHER"));
            }
        }
    };
}

const toggleTerminationDoc = (attached) => {
    return (dispatch, getState) => {
        dispatch(deal_operations.terminationDocUpload(attached));
    };
};

const removeDocumentFromDisplay = (documentKey) => {
    return (dispatch, getState) => {
        let newSavedDocuments = cloneDeep(getState().documentsReducer.savedDocuments);
        delete newSavedDocuments[documentKey];

        const docType = get(getState(), `documentsReducer.savedDocuments.${documentKey}.mediaAssetType`);
        const newUsedDocTypes = filter(getState().documentsReducer.usedDocTypes, (x) => {
            return x !== docType;
        });

        dispatch(actions.removeDocFromList(newSavedDocuments, newUsedDocTypes));
    };
};

const removeDocumentFromUploadingQueue = (name) => {
    return (dispatch, getState) => {
        let updatedUploadingList = cloneDeep(getState().documentsReducer.documentsUploading);
        delete updatedUploadingList[name];
        dispatch(actions.removeDocumentFromUploadingQueue(updatedUploadingList));
    };
};

const handleRejectedDocs = (rejected) => {
    return (dispatch, getState) => {
        let message = "Some files were rejected: ";
        each(rejected, (r) => {
            message += r.name + " ";
        });
        dispatch(documentSnackbar(true, "error", message));
    };
};

// sort documents
const handleDocumentSort = (columnName) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };
    return (dispatch, getState) => {
        let sortDirection =
            getState().documentsReducer.documentColumnToSort === columnName
                ? invertDirection[getState().documentsReducer.documentSortDirection]
                : "asc";
        dispatch(actions.documentSort(columnName, sortDirection));
    };
};

//set doc type
const handleSelectDocumentType = (key, type, documentDao) => {
    return (dispatch, getState) => {
        let newDocTypesUsed = cloneDeep(getState()["documentsReducer"]["usedDocTypes"]);
        let previousTypeIndex = getState().documentsReducer.usedDocTypes.indexOf(
            getState()["documentsReducer"]["savedDocuments"][key]["mediaAssetType"]
        );
        if (previousTypeIndex > -1) {
            newDocTypesUsed.splice(previousTypeIndex, 1);
        }
        newDocTypesUsed.push(type);
        dispatch(actions.selectDocumentType(key, type, newDocTypesUsed, documentDao));
    };
};

const handleRemoveDocument = (url, key) => {
    return (dispatch, getState) => {
        dispatch(removeDocument(url, key));
        dispatch(handleMenuClose);
    };
};

const handleOpenDocumentMenu = (key, anchorEl) => {
    return (dispatch, getState) => {
        dispatch(actions.openDocumentMenu(key, anchorEl));
    };
};

const handleMenuClose = () => {
    return (dispatch, _) => {
        dispatch(actions.closeMenu());
    };
};

const documentSnackbar = (open, variant, message) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.snackbar(open, variant, message));
    };
};
const viewDocument = (documentUrl, key) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, `documents.${key}`));
        let fetchedUrl;
        request(
            {
                method: "get",
                url: constants.urls.view_document(key),
            },
            dispatch
        )
            .then((response) => {
                fetchedUrl = response.data;

                let docWindow = window.open("", "_blank");
                docWindow.location.href = fetchedUrl;

                dispatch(actions.stashDocumentUrl(key, fetchedUrl));
                dispatch(utils.actions.somethingSaving(false, `documents.${key}`));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, `documents.${key}`));
                dispatch(documentSnackbar(true, "error", "Something went wrong. Unable to preview document"));
            });
    };
};

const removeDocument = (url, documentKey) => {
    return (dispatch, _) => {
        dispatch(utils.actions.somethingSaving(true, `documents.${documentKey}`));
        request(
            {
                method: "delete",
                url: url,
            },
            dispatch
        )
            .then(() => {
                dispatch(removeDocumentFromDisplay(documentKey));
                dispatch(utils.actions.somethingSaving(false, `documents.${documentKey}`));
            })
            .catch((err) => {
                dispatch(documentSnackbar(true, "error", "Something went wrong. Unable to remove document"));
                dispatch(utils.actions.somethingSaving(false, `documents.${documentKey}`));
            });
    };
};

const approveDocument = (url, documentKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, `documents.${documentKey}`));
        request({ method: "put", url: url }, dispatch)
            .then((response) => {
                console.log(response.data);
                dispatch(
                    actions.approveDocument(documentKey, response.data.approvedBy, response.data.approvedByTimestamp)
                );
                dispatch(utils.actions.somethingSaving(false, `documents.${documentKey}`));
            })
            .catch((err) => {
                dispatch(documentSnackbar(true, "error", "Something went wrong. Unable to approve document"));
                dispatch(utils.actions.somethingSaving(false, `documents.${documentKey}`));
            });
    };
};

const updateDocument = (url, documentKey, mediaAssetType) => {
    return (dispatch, _) => {
        dispatch(utils.actions.somethingSaving(true, `documents.${documentKey}`));
        request(
            {
                method: "put",
                url: url,
                data: {
                    mediaAssetType: mediaAssetType,
                },
                headers: {
                    "Content-Type": "application/json",
                },
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setUpdatedFlag(documentKey));
                dispatch(handleSelectDocumentType(documentKey, mediaAssetType));
                dispatch(utils.actions.somethingSaving(false, `documents.${documentKey}`));
            })
            .catch((err) => {
                dispatch(documentSnackbar(true, "error", "Something went wrong. Unable to update document"));
                dispatch(utils.actions.somethingSaving(false, `documents.${documentKey}`));
            });
    };
};

const updateDocumentFromAdminList = (
    url,
    documentKey,
    mediaAssetType,
    parentEntityTypeKey,
    documentTypeKey,
    documentDao
) => {
    return (dispatch, _) => {
        dispatch(utils.actions.somethingSaving(true, `documents.${documentKey}`));
        request(
            {
                method: "put",
                url: url,
                data: {
                    mediaAssetType: mediaAssetType,
                    parentEntityTypeKey: parentEntityTypeKey,
                    documentTypeKey: documentTypeKey,
                },
                headers: {
                    "Content-Type": "application/json",
                },
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setUpdatedFlag(documentKey));
                dispatch(handleSelectDocumentType(documentKey, mediaAssetType, documentDao));
                dispatch(utils.actions.somethingSaving(false, `documents.${documentKey}`));
            })
            .catch((err) => {
                dispatch(documentSnackbar(true, "error", "Something went wrong. Unable to update document"));
                dispatch(utils.actions.somethingSaving(false, `documents.${documentKey}`));
            });
    };
};

const getPropertyDocs = (listingId) => {
    return (dispatch, getState) => {
        request(
            {
                method: "get",
                url: constants.urls.get_property_docs(listingId),
            },
            dispatch
        )
            .then((response) => {
                let savedDocuments = {};
                let usedPropDocTypes = [];
                response.data.forEach((doc, i) => {
                    savedDocuments[doc["mediaAssetKey"]] = doc;
                    usedPropDocTypes.push(doc["mediaAssetType"]);
                    doc["updated"] = true;
                });
                dispatch(actions.receivePropertyDocuments(savedDocuments, usedPropDocTypes));
                dispatch(actions.loading(false));
            })
            .catch((err) => {
                dispatch(documentSnackbar(true, "error", "Unable to load documents."));
                dispatch(actions.loading(false));
            });
    };
};

const copyPropertyPhotoToListing = (listingId) => {
    return (dispatch, getState) => {
        console.log("copyPropertyPhotoListing");
        const errors = listing_operations.validateListing(
            getState().listingReducer.listing,
            getState().documentsReducer
        );
        console.log("listing errors - copy Property photo");
        console.log(errors);
        if (isEmpty(errors) || isNil(errors)) {
            request(
                {
                    url: constants.urls.copy_property_photo_to_listing(listingId),
                    method: "post",
                    data: {
                        mediaAssetType: "PRIMARY_PHOTO",
                    },
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
                dispatch
            )
                .then((response) => {
                    dispatch(utils.actions.isSaving(false));
                    dispatch(listing_operations.brokerSubmitListingRequest(true));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(
                        utils.actions.snackbar(
                            true,
                            "error",
                            "Failed to copy property photo to listing due to error: " + err
                        )
                    );
                    dispatch(utils.actions.isSaving(false));
                });
        } else {
            dispatch(utils.actions.addError("listing", errors));
            dispatch(utils.actions.somethingSaving(false, "listingSubmit"));
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                )
            );
        }
    };
};

const getDocuments = (url) => {
    return (dispatch, _) => {
        request({ method: "get", url: url }, dispatch)
            .then((response) => {
                let savedDocuments = {};
                let usedDocTypes = [];
                let missingRequiredDocuments = [];
                if (response.data["mediaAssets"]) {
                    response.data["mediaAssets"].forEach((asset, i) => {
                        savedDocuments[asset["mediaAssetKey"]] = asset;
                        usedDocTypes.push(asset["mediaAssetType"]);
                        asset["updated"] = true;
                    });
                    response.data["missingRequiredDocumentTypes"].forEach((document, i) => {
                        const { name, parentEntityType } = document;
                        const type = parentEntityType["name"];
                        missingRequiredDocuments.push({ name, type });
                    });
                } else {
                    response.data.forEach((doc, i) => {
                        savedDocuments[doc["mediaAssetKey"]] = doc;
                        usedDocTypes.push(doc["mediaAssetType"]);
                        doc["updated"] = true;
                    });
                }
                dispatch(actions.receiveDocuments(savedDocuments, usedDocTypes));
                dispatch(actions.receiveMissingRequiredDocuments(missingRequiredDocuments));
                dispatch(actions.loading(false));
            })
            .catch((err) => {
                dispatch(documentSnackbar(true, "error", "Unable to load documents."));
                dispatch(actions.loading(false));
            });
    };
};

const copyDocUrl = (key) => {
    return (dispatch, _) => {
        let fetchedUrl;
        request({ method: "get", url: constants.urls.view_document(key), dispatch })
            .then((response) => {
                fetchedUrl = response.data;
                const textarea = document.createElement("textarea");
                textarea.textContent = fetchedUrl;
                document.body.appendChild(textarea);

                const selection = document.getSelection();
                const range = document.createRange();
                range.selectNode(textarea);
                selection.removeAllRanges();
                selection.addRange(range);

                console.log("copy success", document.execCommand("copy"));
                selection.removeAllRanges();

                document.body.removeChild(textarea);
                dispatch(utils.actions.notification(true, "Copied!"));
            })
            .catch((err) => {
                dispatch(documentSnackbar(true, "error", "Something went wrong. Couldn't copy to clipboard."));
            });
    };
};

const downloadDocument = (mediaAsset) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.download_document(get(mediaAsset, "mediaAssetKey", null)),
                method: "get",
                crossDomain: true,
                responseType: "blob",
            },
            dispatch
        )
            .then((response) => {
                saveAs(new Blob([response.data]), mediaAsset["mediaAssetName"]);
            })
            .catch((err) => {
                dispatch(
                    documentSnackbar(
                        true,
                        "error",
                        "Something went wrong. Unable to download document " + mediaAsset["mediaAssetName"]
                    )
                );
            });
    };
};

const getDocumentRequirementsByParentEntityType = (parentEntityTypeName, parentKey) => {
    return async (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "getDocumentRequirements"));
        const parentEntityTypes = await document_operations.getAllParentEntityTypes();
        const parentEntityTypeKey = parentEntityTypes.find(
            (parentEntity) => parentEntity.name === parentEntityTypeName
        ).parentEntityTypeKey;
        request({
            url: constants.urls.get_document_requirements_by_parent_entity_type_key(),
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                parentEntityTypeKey,
                parentKey,
            },
        })
            .then((response) => {
                const formatData = response.data.map((docType) => {
                    const { documentTypeName, documentTypeKey, requiredFlag, multipleAllowed } = docType;
                    return {
                        name: documentTypeName,
                        documentTypeKey,
                        requiredFlag,
                        multipleAllowed,
                    };
                });
                dispatch(actions.getParentEntityTypeKey(parentEntityTypeKey));
                dispatch(actions.getDocumentRequirementsByParentEntityType(formatData));
                dispatch(utils.actions.somethingSaving(false, "getDocumentRequirements"));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, "getDocumentRequirements"));
                dispatch(documentSnackbar(true, "error", "Unable to load document requirements."));
            });
    };
};

const setAdminDocumentTypeListAvailable = () => {
    return (dispatch) => {
        dispatch(actions.setAdminDocumentTypeListAvailable());
    };
};

const resetRequiredMissingDocuments = () => {
    return (dispatch) => {
        dispatch(actions.resetRequiredMissingDocuments());
    };
};

const getImagesForOwner = (mediaOwnerType, ownerId, actionToDispatch) => {
    return (dispatch) => {
        dispatch(utils.actions.somethingSaving(true, "retrievingImagesForOwner"));
        request(
            {
                url: constants.urls.get_images_for_owner(mediaOwnerType, ownerId),
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                },
            },
            dispatch
        )
            .then((response) => {
                const mediaAssetArray = get(response, "data", []);
                const imageAssets = mediaAssetArray.map((mediaAsset) => ({
                    mediaAssetKey: get(mediaAsset, "mediaAssetKey"),
                    mediaAssetName: get(mediaAsset, "mediaAssetName"),
                    mediaAssetType: get(mediaAsset, "mediaAssetType"),
                    ownerType: get(mediaAsset, "ownerType"),
                    url: get(mediaAsset, "url", ""),
                    externalAssetFlg: get(mediaAsset, "externalAssetFlg", false),
                }));
                dispatch(actionToDispatch(imageAssets));
                dispatch(utils.actions.somethingSaving(false, "retrievingImagesForOwner"));
            })
            .catch((err) => {
                console.log("ERROR", err);
                dispatch(utils.actions.somethingSaving(false, "retrievingImagesForOwner"));
            });
    };
};

export default {
    handleDocumentSort,
    handleSelectDocumentType,
    handleRemoveDocument,
    handleOpenDocumentMenu,
    handleMenuClose,
    saveDocument,
    documentSnackbar,
    handleRejectedDocs,
    viewDocument,
    addDocument,
    removeDocument,
    updateDocument,
    getDocuments,
    approveDocument,
    copyDocUrl,
    downloadDocument,
    copyPropertyPhotoToListing,
    getPropertyDocs,
    toggleTerminationDoc,
    updateDocumentFromAdminList,
    getDocumentRequirementsByParentEntityType,
    setAdminDocumentTypeListAvailable,
    resetRequiredMissingDocuments,
    getImagesForOwner,
};
