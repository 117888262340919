const TOGGLE_REQUEST_LISTING_MODAL = "TOGGLE_REQUEST_LISTING_MODAL";
const SELECT_PROPERTY_FROM_AUTOCOMPLETE = "SELECT_PROPERTY_FROM_AUTOCOMPLETE";
const REMOVE_SELECTED_PROPERTY_FROM_LISTING_REQUEST_MODAL = "REMOVE_SELECTED_PROPERTY_FROM_LISTING_REQUEST_MODAL";
const UPDATE_LISTING_REQUEST = "UPDATE_LISTING_REQUEST";
const ADD_REQUEST_LISTING_MODAL_ERROR = "ADD_REQUEST_LISTING_MODAL_ERROR";
const CLEAR_REQUEST_LISTING_MODAL_ERRORS = "CLEAR_REQUEST_LISTING_MODAL_ERRORS";
const STORE_NEW_PROPERTY_INFO_FOR_LISTING_REQUEST = "STORE_NEW_PROPERTY_INFO_FOR_LISTING_REQUEST";
const LISTING_REQUEST_INPUT_CHANGE = "LISTING_REQUEST_INPUT_CHANGE";
const LISTING_REQUEST_INPUT_UPDATE = "LISTING_REQUEST_INPUT_UPDATE";
const TOGGLE_LISTING_REQUEST_PANEL = "TOGGLE_LISTING_REQUEST_PANEL";
const CLEAR_LISTING_SEARCH_FILTERS = "CLEAR_LISTING_SEARCH_FILTERS";

const SWITCH_REVIEW_LISTINGS_TAB = "SWITCH_REVIEW_LISTINGS_TAB";
const RECEIVE_REVIEW_LISTINGS = "RECEIVE_REVIEW_LISTINGS";
const SORT_REVIEW_LISTINGS = "SORT_REVIEW_LISTINGS";
const COUNT_REVIEW_LISTINGS = "COUNT_REVIEW_LISTINGS";
const FILTER_LISTINGS = "FILTER_LISTINGS";
const GET_LISTING = "GET_LISTING";
const LISTING_INPUT_CHANGE = "LISTING_INPUT_CHANGE";

const LOAD_LISTING = "LOAD_LISTING";
const ADD_LISTING_ARRAY_ELEMENT = "ADD_LISTING_ARRAY_ELEMENT";
const UPDATE_ARRAY_ELEMENT = "UPDATE_ARRAY_ELEMENT";
const DELETE_ARRAY_ELEMENT = "DELETE_ARRAY_ELEMENT";
const SET_LISTING_ARRAY = "SET_LISTING_ARRAY";
const TOGGLE_LISTINGS_FOR_REPORT = "TOGGLE_LISTINGS_FOR_REPORT";
const SET_CHECKED_LISTINGS = "SET_CHECKED_LISTINGS";
const UPDATE_INACTIVATE_DIALOG = "UPDATE_INACTIVATE_DIALOG";
const UPDATE_DELETE_DIALOG = "UPDATE_DELETE_DIALOG";
const UPDATE_EDIT_PROPERTY = "UPDATE_EDIT_PROPERTY";
const TOGGLE_REPORT_MODAL_STATUS = "TOGGLE_REPORT_MODAL_STATUS";
const TOGGLE_BROKER_INFO_REPORT = "TOGGLE_BROKER_INFO_REPORT";
const SET_REPORT_TITLE = "SET_REPORT_TITLE";
const UPDATE_DUPLICATE_DIALOG = "UPDATE_DUPLICATE_DIALOG";
const DUPLICATE_SUCCESS_DIALOG = "DUPLICATE_SUCCESS_DIALOG";
const UPDATE_LISTING_ORDER = "UPDATE_LISTING_ORDER";
const CLEAR_LISTINGS = "CLEAR_LISTINGS";
const CLEAR_MY_LISTINGS = "CLEAR_MY_LISTINGS";
const SET_LISTING_GROUP = "SET_LISTING_GROUP";
const CLEAR_LISTING_GROUP = "CLEAR_LISTING_GROUP";
const UPDATE_SELECTED_LISTINGS_GROUP = "UPDATE_SELECTED_LISTINGS_GROUP";
const UPDATE_GROUP_SELECTED = "UPDATE_GROUP_SELECTED";
const UPDATE_ACTIVE_SELECTED_LISTINGS = "UPDATE_ACTIVE_SELECTED_LISTINGS";
const CLICK_ACTIVE_CHECKBOX = "CLICK_ACTIVE_CHECKBOX";

export default {
    TOGGLE_REQUEST_LISTING_MODAL,
    UPDATE_LISTING_REQUEST,
    SELECT_PROPERTY_FROM_AUTOCOMPLETE,
    REMOVE_SELECTED_PROPERTY_FROM_LISTING_REQUEST_MODAL,
    ADD_REQUEST_LISTING_MODAL_ERROR,
    CLEAR_REQUEST_LISTING_MODAL_ERRORS,
    STORE_NEW_PROPERTY_INFO_FOR_LISTING_REQUEST,
    LISTING_REQUEST_INPUT_CHANGE,
    LISTING_REQUEST_INPUT_UPDATE,
    TOGGLE_LISTING_REQUEST_PANEL,
    CLEAR_LISTING_SEARCH_FILTERS,
    SWITCH_REVIEW_LISTINGS_TAB,
    RECEIVE_REVIEW_LISTINGS,
    SORT_REVIEW_LISTINGS,
    COUNT_REVIEW_LISTINGS,
    FILTER_LISTINGS,
    GET_LISTING,
    LISTING_INPUT_CHANGE,
    LOAD_LISTING,
    ADD_LISTING_ARRAY_ELEMENT,
    UPDATE_ARRAY_ELEMENT,
    DELETE_ARRAY_ELEMENT,
    SET_LISTING_ARRAY,
    TOGGLE_LISTINGS_FOR_REPORT,
    SET_CHECKED_LISTINGS,
    UPDATE_INACTIVATE_DIALOG,
    UPDATE_DELETE_DIALOG,
    UPDATE_EDIT_PROPERTY,
    TOGGLE_REPORT_MODAL_STATUS,
    TOGGLE_BROKER_INFO_REPORT,
    SET_REPORT_TITLE,
    UPDATE_DUPLICATE_DIALOG,
    DUPLICATE_SUCCESS_DIALOG,
    UPDATE_LISTING_ORDER,
    CLEAR_LISTINGS,
    CLEAR_MY_LISTINGS,
    SET_LISTING_GROUP,
    CLEAR_LISTING_GROUP,
    UPDATE_SELECTED_LISTINGS_GROUP,
    UPDATE_GROUP_SELECTED,
    UPDATE_ACTIVE_SELECTED_LISTINGS,
    CLICK_ACTIVE_CHECKBOX,
};
