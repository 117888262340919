import React from "react";
import get from "lodash/get";
import Button from "@material-ui/core/Button";
import Delete from "@material-ui/icons/Delete";
import BSInput from "../../../../common/js/components/BSInput";
import "./Retail.css";

const Anchor = (props) => {
    const { dataPathPrefix, element, elementIndex, errors, handleUpdateArrayElement, isDisabled, onDelete } = props;

    return (
        <div className="retailFlexContainer">
            <div className="retailFlexField">
                <BSInput
                    id="anchorName"
                    label="ANCHOR NAME"
                    placeholder="Enter anchor name"
                    value={element ? element.anchorName : null}
                    error={get(errors, `anchors.${elementIndex}.anchorName`, null)}
                    onChange={(e) =>
                        handleUpdateArrayElement(dataPathPrefix + ".anchors", elementIndex, e.target.id, e.target.value)
                    }
                />
            </div>
            {elementIndex > 0 && isDisabled !== true && (
                <div className="retailAlignIcon">
                    <Button
                        onClick={() => onDelete(elementIndex)}
                        style={{ color: "#9e9e9e", "&:hover": { backgroundColor: "transparent" } }}
                    >
                        <Delete />
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Anchor;
