import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import schema from "./reviewCompsTables";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { buildAddress } from "../utils/operations";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Sorty } from "../../../common/js/icons";
import ReactTooltip from "react-tooltip";

const styles = (theme) => ({
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    tabOne: {
        marginRight: "20px",
    },
    connectorActive: {
        "& $connectorLine": {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        "& $connectorLine": {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        "& $connectorLine": {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create("border-color"),
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 800,
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
    menuRequestListing: {
        paddingBottom: "0px",
        "&>a": {
            paddingBottom: "0px !important",
        },
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "4em",
        marginBottom: "1em",
    },
    lb: {
        textDecoration: "none",
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
});

class CompsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numComps: 0,
        };
    }

    render() {
        const { classes, handleSelect, handleSort, header, isLoading, comps, compTab, role } = this.props;

        let columnToSort = "transactionDate";
        let sortDirection = "asc";

        let compz = orderBy(comps, columnToSort, sortDirection);

        return (
            <div>
                <Grid container alignItems="center" spacing={16}>
                    <Grid item xs>
                        <div className="simPaper">
                            <Grid container spacing={0}>
                                <Grid item xs>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {schema.viewHeaders[compTab].map((x, i) => (
                                                    <TableCell classes={classes} key={`thc-${i}`}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                            onClick={() => handleSort(x.sort ? x.sort : x.prop)}
                                                        >
                                                            <span className={classes.sortable}>{x.name}</span>
                                                            {x.name && (
                                                                <div>
                                                                    <Sorty />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        {!isLoading && (
                                            <TableBody>
                                                {compz.map((x, i) =>
                                                    row(
                                                        x,
                                                        i,
                                                        role,
                                                        header,
                                                        compTab,
                                                        handleSelect,
                                                        classes,
                                                        isLoading,
                                                        compz
                                                    )
                                                )}
                                            </TableBody>
                                        )}
                                    </Table>
                                </Grid>
                            </Grid>
                            {isLoading ? (
                                <div className={classes.alternateContent}>
                                    <CircularProgress color="primary" />
                                </div>
                            ) : Object.keys(compz).length > 0 ? null : (
                                <div className={classes.alternateContent}>Nothing to show.</div>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.closeDealDialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="close-deal"
                    aria-describedby="close-deal-dialog"
                >
                    <DialogTitle id="close-deal">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Closing this deal will start the invoice process for this transaction and move the comp to a
                            closed status.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" variant="outlined">
                            Disagree
                        </Button>
                        <Button onClick={this.handleClose} color="secondary" autoFocus variant="contained">
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const row = (x, index, role, header, compTab, handleSelect, classes, isLoading, compz) => {
    return isLoading ? null : (
        <TableRow key={`tr-${index}`} selectable="false">
            {schema.viewHeaders[compTab].map((y, k) =>
                y.name === "Address" ? (
                    <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                        <Link to={`/comp/${x.dealKey}`} className={classes.lb}>
                            <span className={classes.listingIdLink}>{get(x, "listing.listingName", null)}</span>
                        </Link>
                        <div>{buildAddress(get(x, "listing.property.primaryAddress", {}))}</div>
                    </TableCell>
                ) : y.transform ? (
                    y.name === "Brokers" ? (
                        <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                            <span data-tip={get(x, y.prop)}>{y.transform(get(x, y.prop))}</span>
                            <ReactTooltip place="top" type="dark" effect="solid" />
                        </TableCell>
                    ) : (
                        <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                            {y.transform(get(x, y.prop))}
                        </TableCell>
                    )
                ) : (
                    //no transform function
                    <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                        {get(x, y.prop)}
                    </TableCell>
                )
            )}
        </TableRow>
    );
};

export default withStyles(styles)(CompsTable);
