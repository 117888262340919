import configs from "../../../config";

const HOST = configs.HOST_URL + "/";

const MEDIA_URL = HOST + "media/";
const LISTING_URL = HOST + "listings/";
const PROPERTY_URL = HOST + "properties/";
const DEAL_URL = HOST + "deals/";

export const listing_contact_type_options = [
    {
        value: "",
        label: "Select type ...",
        disabled: true,
    },

    {
        value: "SELLER_LANDLORD",
        label: "Seller Landlord",
    },
    {
        value: "BUYER_TENANT",
        label: "Buyer Tenant",
    },
    {
        value: "PROPERTY_MANAGER",
        label: "Property Manager",
    },
    {
        value: "OUTSIDE_BROKER",
        label: "Outside Broker",
    },
];

const roles = {
    broker: {
        initialTab: "broker_requested",
    },
    evp: {
        initialTab: "broker_requested",
    },
    portlandBrokerAdmin: {
        initialTab: "broker_requested",
    },
    arizonaBrokerAdmin: {
        initialTab: "broker_requested",
    },
    dataAdmin: {
        initialTab: "data_team_unassigned",
    },
    debtEquity: {
        initialTab: "all_km_listings",
    },
    accounting: {
        initialTab: "all_km_listings", //todo?
    },
    propertyManagement: {
        initialTab: "all_km_listings",
    },
    dataEntry: {
        initialTab: "data_team_unassigned",
    },
    anonymous: {
        initialTab: "broker_requested",
    },
    general: {
        initialTab: "broker_requested",
    },
    officeManager: {
        initialTab: "broker_requested",
    },
    research: {
        initialTab: "broker_requested",
    },
    researchAdmin: {
        initialTab: "broker_requested",
    },
    transition: {
        initialTab: "broker_requested",
    },
};
export const rentTypes = ["Withheld", "CallBroker", "NNN"];

export const rentTypeOptions = () => {
    let options = [{ label: "Select Rent Type", value: "", disabled: true }];
    options = options.concat(
        rentTypes.map((rentType) => {
            return { value: rentType, label: rentType };
        })
    );

    return options;
};

export const sprinklerTypes = [
    {
        label: "Select Type",
        value: "",
    },
    {
        label: "No",
        value: "No",
    },
    {
        label: "ESFR",
        value: "ESFR",
    },
    {
        label: "Wet",
        value: "Wet",
    },
    {
        label: "Dry",
        value: "Dry",
    },
    {
        label: "Other",
        value: "Other",
    },
];

const urls = {
    get_listing: (listingKey) => {
        return LISTING_URL + listingKey;
    },
    save_listing_request: (listingKey) => {
        return LISTING_URL + listingKey;
    },
    create_or_update_listing: () => {
        return LISTING_URL;
    },
    duplicate_listing: (listingKey) => {
        return LISTING_URL + listingKey + "/duplicate";
    },
    broker_submit: (listingKey) => {
        return LISTING_URL + "workflow/submit/" + listingKey;
    },
    assign_listing_for_review: (listingKey) => {
        return LISTING_URL + "workflow/review/" + listingKey;
    },
    approve_listing: (listingKey) => {
        return LISTING_URL + "workflow/approve/" + listingKey;
    },
    reassign_listing_to_broker: (listingKey) => {
        return LISTING_URL + "workflow/reassigntobroker/" + listingKey;
    },
    reassign_active_listing_to_broker: (listingKey) => {
        return LISTING_URL + "workflow/reassigntobroker/active/" + listingKey;
    },
    reassign_listing_to_dataentry: (listingKey) => {
        return LISTING_URL + "workflow/reassigntodataentry/" + listingKey;
    },
    inactivate_listing: (listingKey) => {
        return LISTING_URL + "workflow/inactivate/" + listingKey;
    },
    delete_listing: (listingKey) => {
        return LISTING_URL + listingKey;
    },
    pending_listing: (listingKey) => {
        return LISTING_URL + "workflow/pending/" + listingKey;
    },

    search_property_autocomplete: (term) => {
        return PROPERTY_URL + "search?term=" + term;
    },

    data_team_unassigned: () => {
        return LISTING_URL + "unassigned";
    },
    data_team_open: (username) => {
        return LISTING_URL + "datateamopen/" + username;
    },
    broker_open: (username) => {
        return LISTING_URL + "brokeropen/" + username;
    },
    broker_requested: (username) => {
        return LISTING_URL + "brokerrequests/" + username;
    },
    all_km_listings: () => {
        return LISTING_URL + "km";
    },

    close_deal: (type) => {
        return DEAL_URL + type;
    },

    get_listing_documents: (listingKey) => {
        return MEDIA_URL + "find/listing/" + listingKey;
    },
    save_listing_document: (listingKey) => {
        return MEDIA_URL + "listing/" + listingKey;
    },
    update_listing_document: (documentKey) => {
        return MEDIA_URL + documentKey;
    },
    delete_listing_document: (documentKey) => {
        return MEDIA_URL + documentKey;
    },
    save_listing: (listingKey) => {
        return LISTING_URL + (listingKey ? listingKey : "");
    },
    update_listing_status: (key) => {
        return LISTING_URL + "status/" + key;
    },
    get_listing_status: (key) => {
        return LISTING_URL + "status/" + key;
    },
    update_listing_property: (listingKey, propertyKey, user) => {
        return LISTING_URL + listingKey + "/" + propertyKey + "/" + user;
    },
    open_listings_by_property: (propertyKey) => {
        return LISTING_URL + "property/" + propertyKey;
    },
    unassigned_listings_by_property: (propertyKey) => {
        return LISTING_URL + "property/unassigned/" + propertyKey;
    },
    get_flyer: (listingKey) => {
        return MEDIA_URL + "find/listing/" + listingKey + "/flyer";
    },
    generate_listing_report: () => {
        return LISTING_URL + "report";
    },
    upload_listing_file: () => {
        return LISTING_URL + "import";
    },
};

export const disabledStatuses = {
    broker: {
        Draft: false,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "In Review": true,
        "Broker Action Required": false,
        Closed: true,
        "Admin Action Required": true,
        "Update Requested": true,
        "Needs Review": true,
        "Final Review": true,
        Active: true,
    },
    general: {
        Draft: false,
        "Broker Action Required": false,
        Active: true,
    },
    officeManager: {
        Draft: false,
        "Broker Action Required": false,
        Active: true,
    },
    appraiser: {
        Draft: false,
        "Broker Action Required": false,
        Active: true,
    },
    research: {
        Draft: false,
        "Broker Action Required": false,
        Active: true,
    },
    researchAdmin: {
        Draft: false,
        "Broker Action Required": false,
        Active: true,
    },
    accounting: {
        Draft: false,
        "Broker Action Required": false,
        Active: true,
    },
    propertyManagement: {
        Draft: false,
        "Broker Action Required": false,
        Active: true,
    },
    transition: {
        Draft: false,
        "Broker Action Required": false,
        Active: true,
    },
    evp: {
        Draft: false,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "In Review": true,
        "Broker Action Required": false,
        Closed: true,
        "Admin Action Required": true,
        "Update Requested": true,
        "Needs Review": true,
        "Final Review": true,
        Active: true,
    },
    portlandBrokerAdmin: {
        Draft: false,
        "Portland Review Required": false,
        "In Review": true,
        "Broker Action Required": false,
        Closed: true,
        "Admin Action Required": true,
        "Update Requested": true,
        "Needs Review": true,
        "Final Review": true,
        Active: true,
    },
    arizonaBrokerAdmin: {
        Draft: false,
        "AZ Review Required": false,
        "In Review": true,
        "Broker Action Required": false,
        Closed: true,
        "Admin Action Required": true,
        "Update Requested": true,
        "Needs Review": true,
        "Final Review": true,
        Active: true,
    },
    dataEntry: {
        Draft: false,
        "Portland Review Required": false,
        "AZ Review Required": false,
        "In Review": false,
        "Broker Action Required": false,
        Closed: false,
        "Admin Action Required": false,
        "Update Requested": false,
        "Needs Review": true,
        "Final Review": false,
        Active: false,
    },
    dataAdmin: {
        Draft: false,
        "Portland Review Required": false,
        "AZ Review Required": false,
        "In Review": false,
        "Broker Action Required": false,
        Closed: false,
        "Admin Action Required": false,
        "Update Requested": false,
        "Needs Review": true,
        "Final Review": false,
        Active: false,
    },
    default: {
        Draft: false,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "In Review": true,
        "Broker Action Required": false,
        Closed: true,
        "Admin Action Required": true,
        "Update Requested": true,
        "Needs Review": true,
        "Final Review": true,
        Active: true,
    },
};

export const NO_CONTENT_PLACEHOLDER = "-";

export default {
    urls,
    roles,
    disabledStatuses,
};
