import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, DropdownButton } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import LandVision from "@material-ui/icons/Map";
import RequestModalContainer from "../listing/RequestModalContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import ReceiptIcon from "@material-ui/icons/Receipt";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import includes from "lodash/includes";
import KMTools from "../../../common/js/components/KMTools";
import KMStatBox from "../../../common/js/components/KMStatBox";
import StatusChip from "../../../common/js/components/StatusChip";
import { Sorty } from "../../../common/js/icons";
import { Divider } from "@material-ui/core";
import { formatDate, toDollarAmount } from "../utils/operations";
import OutsideCompModal from "../comp/OutsideCompModal";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import classnames from "classnames";
import config from "../../../config";

import "./dashboard.css";
import BSInput from "../../../common/js/components/BSInput";
import Today from "@material-ui/core/SvgIcon/SvgIcon";
import ReactGA from "react-ga4";

const styles = (theme) => ({
    pageToolbar: {
        margin: "35px 0px",
    },
    iconInButton: {
        marginRight: "5px",
    },
    paperSpacing: {
        padding: "10px 20px",
        fontSize: 14,
        marginBottom: "15px",
    },
    pieSpacing: {
        padding: "10px 20px 30px 20px",
        fontSize: 14,
        marginBottom: "15px",
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    fullWidth: {
        marginLeft: "-20px",
        marginRight: "-20px",
    },
    alignRightCell: {
        display: "flex",
        alignItems: "center",
        position: "relative",
    },
    tooltip: {
        "&>a:hover": {
            cursor: "default",
        },
    },
    idLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
        textDecoration: "none",
    },
    loadingPlaceholder: {
        marginTop: "3em",
        marginBottom: "1.5em",
        textAlign: "center",
        width: "100%",
    },
    leftColumn: {
        paddingLeft: "0px",
    },
    goToArrow: {
        color: "#2C99D2",
        "&:hover": {
            cursor: "pointer",
            textDecoration: "none",
        },
    },
    lvButton: {
        right: "0px",
        bottom: "-19px",
        padding: "8px",
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 800,
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
    dateFilterSelector: {
        flexGrow: "inherit",
        width: "11em",
    },
});
class BrokerDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closeDealDialogOpen: false,
            showModal: false,
        };
    }

    handleShowModal = (spaceType, transactionType) => {
        this.setState({ showModal: !this.state.showModal });
        this.props.toggleRequestModal(spaceType, transactionType);
    };

    handleClickOpen = (listing) => {
        console.log(listing);
        this.setState({ closeDealDialogOpen: true, listingToClose: listing });
    };

    handleCloseAndStart = () => {
        this.props.closeDeal(this.state.listingToClose);
        this.setState({ closeDealDialogOpen: false, listingToClose: null });
    };

    handleClose = () => {
        this.setState({ closeDealDialogOpen: false, listingToClose: null });
    };

    render() {
        const {
            classes,
            errors,
            loading,
            brokerValues,
            handleSort,
            sort,
            role,
            searchProperty,
            addNewDealFromOutsideCompModal,
            handleDashboardInput,
            dashboardParams,
        } = this.props;

        const recentOpenDealsSortColumn = get(sort, "recentOpenDeals.columnName", "dealCreatedDate");
        const recentOpenDealsSortDirection = get(sort, "recentOpenDeals.sortDirection", "desc");
        const recentOpenListingsSortColumn = get(sort, "recentOpenListings.columnName", "dateAvailable");
        const recentOpenListingsSortDirection = get(sort, "recentOpenListings.sortDirection", "desc");

        return (
            <div>
                <Grid container alignItems="center" justify="space-between" className={classes.pageToolbar}>
                    <Grid item>
                        <h2 className="page-title">Dashboard</h2>
                    </Grid>
                    <Grid container xs={10} alignItems="center" spacing={0} justify="flex-end">
                        <Grid item className={classes.dateFilterSelector}>
                            <BSInput
                                id="startDate"
                                label="Dashboard Date Range"
                                value={get(dashboardParams, "startDate")}
                                type={"date"}
                                startAdornment={<Today />}
                                style={{ backgroundColor: "white" }}
                                onChange={handleDashboardInput}
                            />
                        </Grid>
                        <Grid item align="center">
                            <div style={{ paddingTop: "0px", paddingLeft: "5px", paddingRight: "5px", color: "grey" }}>
                                &mdash;
                            </div>
                        </Grid>
                        <Grid item className={classes.dateFilterSelector}>
                            <BSInput
                                id="endDate"
                                value={get(dashboardParams, "endDate")}
                                type={"date"}
                                startAdornment={<Today />}
                                onChange={handleDashboardInput}
                            />
                        </Grid>

                        <Grid item className={classes.lvButton} style={{ paddingLeft: "16px" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                href={config.LANDVISION_URL}
                                className={classes.button}
                                onClick={() => ReactGA.event("Navigate To Landvision from Dashboard")}
                            >
                                <LandVision className={classes.iconInButton} /> Go to LandVision
                            </Button>
                        </Grid>
                        <Grid item className={classes.lvButton}>
                            {includes(
                                [
                                    "broker",
                                    "portlandBrokerAdmin",
                                    "arizonaBrokerAdmin",
                                    "evp",
                                    "accounting",
                                    "propertyManagement",
                                    "transition",
                                    "general",
                                    "officeManager",
                                    "appraiser",
                                ],
                                role
                            ) && (
                                <DropdownButton id="dropdown-basic-button" title="Request Listing" pullRight>
                                    <MenuItem disabled className={classes.menuCategory}>
                                        Lease
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Office", "Lease")}
                                    >
                                        Office Lease
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Industrial", "Lease")}
                                    >
                                        Industrial Lease
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Retail", "Lease")}
                                    >
                                        Retail Lease
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Land", "Lease")}
                                    >
                                        Land Lease
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Multifamily", "Lease")}
                                    >
                                        Multifamily Lease
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Life Science", "Lease")}
                                    >
                                        Life Science Lease
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Healthcare", "Lease")}
                                    >
                                        Healthcare Lease
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Religious", "Lease")}
                                    >
                                        Religious Lease
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Educational", "Lease")}
                                    >
                                        Educational Lease
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Other", "Lease")}
                                    >
                                        Other Lease
                                    </MenuItem>
                                    <MenuItem disabled className={classes.menuCategory}>
                                        Sale
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Office", "Sale")}
                                    >
                                        Office Sale
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Industrial", "Sale")}
                                    >
                                        Industrial Sale
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Retail", "Sale")}
                                    >
                                        Retail Sale
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Land", "Sale")}
                                    >
                                        Land Sale
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Hospitality", "Sale")}
                                    >
                                        Hospitality Sale
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Multifamily", "Sale")}
                                    >
                                        Multifamily Sale
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Life Science", "Sale")}
                                    >
                                        Life Science Sale
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Healthcare", "Sale")}
                                    >
                                        Healthcare Sale
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Religious", "Sale")}
                                    >
                                        Religious Sale
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Educational", "Sale")}
                                    >
                                        Educational Sale
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuSubItem}
                                        onClick={() => this.handleShowModal("Other", "Sale")}
                                    >
                                        Other Sale
                                    </MenuItem>
                                </DropdownButton>
                            )}
                            <RequestModalContainer
                                {...this.props}
                                handleShowModal={this.handleShowModal}
                                showModal={this.state.showModal}
                                modalType="listing"
                            />
                        </Grid>
                        <Grid item className={classnames(classes.lvButton, "dashboard")}>
                            <OutsideCompModal
                                {...this.props}
                                errors={errors}
                                searchFunction={searchProperty}
                                addOutsideComp={addNewDealFromOutsideCompModal}
                                internal={true}
                                dashboard={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" justify="space-between" spacing={24}>
                    <Grid item xs={8}>
                        <Paper className={classes.paperSpacing}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                    <h3>Recent Open Deals</h3>
                                </Grid>
                                <Grid item style={{ position: "relative", width: "120px" }}>
                                    <Link to="/deals">
                                        <Grid container alignItems="center" spacing={0}>
                                            <Grid item>
                                                <div
                                                    className={classes.idLink}
                                                    style={{
                                                        display: "inline-block",
                                                        position: "absolute",
                                                        left: "2px",
                                                        top: "-6px",
                                                    }}
                                                >
                                                    See all deals
                                                </div>
                                                <div
                                                    style={{
                                                        display: "inline-block",
                                                        position: "absolute",
                                                        left: "92px",
                                                        top: "-9px",
                                                    }}
                                                >
                                                    <ArrowForward
                                                        className={classes.goToArrow}
                                                        style={{ display: "inline-block" }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Link>
                                </Grid>
                            </Grid>

                            {loading.recentOpenDeals ? (
                                <div className={classes.loadingPlaceholder}>
                                    <CircularProgress color="primary" />
                                </div>
                            ) : (
                                <React.Fragment>
                                    <Divider />
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell classes={classes} style={{ width: "55%", padding: 0 }}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <span className={classes.sortable}>Deal</span>
                                                    </div>
                                                </TableCell>

                                                <TableCell classes={classes} style={{ width: "20%", padding: 0 }}>
                                                    <div
                                                        style={{ display: "flex", alignItems: "center" }}
                                                        onClick={() => handleSort("recentOpenDeals", "dealStage")}
                                                    >
                                                        <span className={classes.sortable}>Step</span>
                                                        <div>
                                                            <Sorty />
                                                        </div>
                                                    </div>
                                                </TableCell>

                                                <TableCell
                                                    numeric
                                                    classes={classes}
                                                    style={{ width: "25%", padding: 0 }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            position: "relative",
                                                        }}
                                                        onClick={() => handleSort("recentOpenDeals", "dealStatus")}
                                                    >
                                                        <div
                                                            style={{ right: "6px", position: "absolute" }}
                                                            className={classes.sortable}
                                                        >
                                                            Status
                                                        </div>
                                                        <div style={{ right: "0px", position: "absolute" }}>
                                                            <Sorty />
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orderBy(
                                                brokerValues.recentOpenDeals,
                                                recentOpenDealsSortColumn,
                                                recentOpenDealsSortDirection
                                            ).map((deal, i) => (
                                                <TableRow selectable="false" className="tableRow">
                                                    <TableCell className={classes.leftColumn} style={{ padding: 0 }}>
                                                        <Link
                                                            className={classes.idLink}
                                                            to={`/deal/${get(deal, "dealKey", "")}`}
                                                        >
                                                            <div>
                                                                {`Deal No. ${get(deal, "dealKey")}`}
                                                                <span className="l" style={{ fontWeight: 200 }}>
                                                                    {get(deal, "consultingDealName", null) !== null
                                                                        ? ` | ${get(deal, "consultingDealName")}`
                                                                        : get(deal, "listingName", null) !== null
                                                                        ? ` | ${get(deal, "listingName")}`
                                                                        : ""}
                                                                </span>
                                                            </div>
                                                        </Link>
                                                        <div>
                                                            {get(deal, "address1")} {get(deal, "address2")}{" "}
                                                            {get(deal, "city")} {get(deal, "state")} {get(deal, "zip")}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell style={{ padding: 0 }}>
                                                        {displayDealStep(get(deal, "dealStage"))}
                                                    </TableCell>
                                                    <TableCell numeric style={{ padding: 0 }}>
                                                        <StatusChip status={get(deal, "dealStatus", "")} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </React.Fragment>
                            )}
                        </Paper>

                        <Paper className={classes.paperSpacing}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                    <h3>Recent Open Listings</h3>
                                </Grid>
                                <Grid item style={{ position: "relative", width: "120px" }}>
                                    <Link to="/listings">
                                        <Grid container alignItems="center" spacing={0}>
                                            <Grid item>
                                                <div
                                                    className={classes.idLink}
                                                    style={{
                                                        display: "inline-block",
                                                        position: "absolute",
                                                        left: "-10px",
                                                        top: "-6px",
                                                    }}
                                                >
                                                    See all listings
                                                </div>
                                                <div
                                                    style={{
                                                        display: "inline-block",
                                                        position: "absolute",
                                                        left: "92px",
                                                        top: "-9px",
                                                    }}
                                                >
                                                    <ArrowForward
                                                        className={classes.goToArrow}
                                                        style={{ display: "inline-block" }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Link>
                                </Grid>
                            </Grid>

                            {loading.recentOpenListings ? (
                                <div className={classes.loadingPlaceholder}>
                                    <CircularProgress color="primary" />
                                </div>
                            ) : (
                                <React.Fragment>
                                    <Divider />
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell classes={classes} style={{ width: "55%", padding: 0 }}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <span className={classes.sortable}>Deal</span>
                                                    </div>
                                                </TableCell>

                                                <TableCell classes={classes} style={{ width: "20%", padding: 0 }}>
                                                    <div
                                                        style={{ display: "flex", alignItems: "center" }}
                                                        onClick={() =>
                                                            handleSort("recentOpenListings", "dateAvailable")
                                                        }
                                                    >
                                                        <span className={classes.sortable}>Date Avail</span>
                                                        <div>
                                                            <Sorty />
                                                        </div>
                                                    </div>
                                                </TableCell>

                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orderBy(
                                                brokerValues.recentOpenListings,
                                                recentOpenListingsSortColumn === "dateAvailable"
                                                    ? [(x) => new Date(x[recentOpenListingsSortColumn])]
                                                    : recentOpenDealsSortColumn,
                                                recentOpenListingsSortDirection
                                            ).map((listing, i) => (
                                                <TableRow selectable="false" className="tableRow">
                                                    <TableCell className={classes.leftColumn} style={{ padding: 0 }}>
                                                        <Link
                                                            className={classes.idLink}
                                                            to={`/listing/${get(listing, "listingKey", "")}`}
                                                        >
                                                            <div>
                                                                {`Listing No. ${get(listing, "listingKey")}`}
                                                                <span className="l" style={{ fontWeight: 200 }}>
                                                                    {get(listing, "listingName", null) !== null
                                                                        ? ` | ${get(listing, "listingName")}`
                                                                        : ""}
                                                                </span>
                                                            </div>
                                                        </Link>
                                                        <div>
                                                            {get(listing, "address1")} {get(listing, "address2")}{" "}
                                                            {get(listing, "city")} {get(listing, "state")}{" "}
                                                            {get(listing, "zip")}
                                                        </div>
                                                    </TableCell>

                                                    <TableCell style={{ padding: "0px" }}>
                                                        {formatDate("MM-DD-YYYY")(get(listing, "dateAvailable", ""))}
                                                    </TableCell>

                                                    <TableCell numeric style={{ position: "relative" }}>
                                                        <Button
                                                            style={{
                                                                position: "absolute",
                                                                right: "0px",
                                                                bottom: "12px",
                                                            }}
                                                            color="primary"
                                                            onClick={() => this.handleClickOpen(listing)}
                                                            variant="contained"
                                                        >
                                                            Close Deal
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </React.Fragment>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        {loading.brokerYTDCommission ? (
                            <div className={classes.loadingPlaceholder}>
                                <CircularProgress color="primary" />
                            </div>
                        ) : (
                            <KMStatBox
                                icon={<MoneyIcon />}
                                title="Total Commission"
                                tooltip="Total amount of Commission Allocations you have received for the specified date range."
                                number={toDollarAmount(
                                    get(brokerValues, `brokerYTDCommission.${0}.calculatedValueA`),
                                    "--"
                                )}
                            />
                        )}
                        {loading.brokerYTDListings ? (
                            <div className={classes.loadingPlaceholder}>
                                <CircularProgress color="primary" />
                            </div>
                        ) : (
                            <KMStatBox
                                icon={<MeetingRoomIcon />}
                                title="Active Listings"
                                tooltip="Number of active listings on which you are included as a listing broker."
                                id="ytdListings"
                                number={get(brokerValues, `brokerYTDListings`, "--")}
                            />
                        )}
                        {loading.brokerYTDClosedDeals ? (
                            <div className={classes.loadingPlaceholder}>
                                <CircularProgress color="primary" />
                            </div>
                        ) : (
                            <KMStatBox
                                icon={<ReceiptIcon />}
                                title="Closed Deals"
                                tooltip="The number of Deals in which you have received a Commission Allocation during the specified date range."
                                number={get(brokerValues, `brokerYTDClosedDeals`, "--")}
                            />
                        )}
                        {loading.brokerLicenseExpiration ? (
                            <div className={classes.loadingPlaceholder}>
                                <CircularProgress color="primary" />
                            </div>
                        ) : (
                            <KMStatBox
                                icon={<AccountCircleIcon />}
                                title="License Exp Date"
                                number={formatDate(
                                    "MM/DD/YYYY",
                                    "--"
                                )(get(brokerValues, `brokerLicenseExpiration.expirationDate`, ""))}
                            />
                        )}
                        <KMTools />
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.closeDealDialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="close-deal"
                    aria-describedby="close-deal-dialog"
                >
                    <DialogTitle id="close-deal">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Closing this deal will start the invoice process for this transaction and move the comp to a
                            closed status.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" variant="outlined">
                            Disagree
                        </Button>
                        <Button onClick={this.handleCloseAndStart} color="secondary" autoFocus variant="contained">
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const displayDealStep = (intStep) => {
    switch (intStep) {
        case 1:
            return "Comp";
        case 2:
            return "Voucher";
        default:
            return "";
    }
};

export default withStyles(styles)(BrokerDashboard);
