import React from "react";
import { withStyles } from "@material-ui/core/styles";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { Search } from "../../../common/js/icons";
import FormTitle from "../../../common/js/components/FormTitle";
import AutocompleteFreeform from "../../../common/js/components/AutocompleteFreeform";
import { us_states_options } from "../utils/constants";
import DocumentDrop from "../documents/DocumentDropContainer";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { IconButton } from "@material-ui/core";

const styles = {
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    idLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "none",
        },
        color: "#2C99D2",
        fontWeight: 600,
        textDecoration: "none",
    },
    loadingPlaceholder: {
        marginTop: "3em",
        marginBottom: "1.5em",
        textAlign: "center",
        width: "100%",
    },
    leftColumn: {
        paddingLeft: "0px",
    },
    paperSpacing: {
        padding: "10px 20px",
        fontSize: 14,
        marginBottom: "15px",
    },
    filterInput: {
        height: "70px",
        maringBottom: "0px,",
    },
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    reminderSwitch: {
        float: "right",
        margin: "0px",
    },
    theCounter: {
        border: "1px solid #2C99D2",
        borderRadius: "50%",
        display: "inline-block",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 600,
        width: "22px",
        height: "20px",
        marginBottom: "10px",
        color: "#2C99D2",
        paddingTop: "2px",
    },
    clearLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
            color: "rgba(0, 0, 0, 0.54)",
        },
        color: "rgba(0, 0, 0, 0.34)",
        textTransform: "capitalize",
    },
    clearButton: {
        "&:hover": {
            backgroundColor: "transparent",
            color: "black",
        },
        padding: "0px",
    },
    clearButtonIcon: {
        width: "8px",
        height: "8px",
    },
    header: {
        paddingBottom: "0px",
        height: "6em",
    },
};

class EditTransaction extends React.Component {
    selectProperty = (property) => {
        this.props.handleInput(`transaction.property`, property);
    };

    clearProperty = () => {
        this.props.handleInput(`transaction.property`, {});
    };

    selectBorrowerCompany = (company) => {
        this.props.handleInput(`transaction.borrowerCompany`, company);
    };

    clearBorrowerCompany = () => {
        this.props.handleInput(`transaction.borrowerCompany`, {});
    };

    selectBorrowerParentCompany = (company) => {
        this.props.handleInput(`transaction.borrowerParentCompany`, company);
    };

    clearBorrowerParentCompany = () => {
        this.props.handleInput(`transaction.borrowerParentCompany`, {});
    };

    handlePropertyFreeform = (value) => {
        this.props.handleInput("transaction.property", { propertyAttributes: { propertyName: value } });
    };
    handleBorrowerCompanyFreeform = (value) => {
        this.props.handleInput("transaction.borrowerCompany", { legalName: value });
    };
    handleBorrowerParentCompanyFreeform = (value) => {
        this.props.handleInput("transaction.borrowerParentCompany", { displayName: value, legalName: value });
    };

    render() {
        const {
            errors,
            classes,
            transaction,
            autocompleteResults,
            searchProperty,
            disableInputs,
            handleInput,
            searchCompany,
        } = this.props;

        return (
            <React.Fragment>
                <Paper className={classes.paperSpacing}>
                    <Grid container spacing={40}>
                        <Grid item xs={12} className={classes.header}>
                            <Grid container alignItems="center" spacing={8}>
                                <Grid item xs>
                                    <FormTitle title="General Information" />
                                    <hr style={{ margin: "0px -20px 25px", borderTop: "1px solid #eeeeee" }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <BSInput
                                        id="transactionName"
                                        label="Transaction Name"
                                        value={get(transaction, `transactionName`, "")}
                                        error={get(errors, `transactionName`, null)}
                                        onChange={(e) => handleInput("transaction.transactionName", e.target.value)}
                                        placeholder="Enter transaction name"
                                        disabled={disableInputs === true}
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="firstName"
                                        label="Client First Name"
                                        value={get(transaction, `client.person.firstName`, "")}
                                        error={get(errors, `client.person.firstName`, null)}
                                        onChange={(e) =>
                                            handleInput("transaction.client.person.firstName", e.target.value)
                                        }
                                        placeholder="Enter first name"
                                        disabled={disableInputs === true}
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <BSInput
                                        id="lastName"
                                        label="Client Last Name"
                                        value={get(transaction, `client.person.lastName`, "")}
                                        error={get(errors, `client.person.lastName`, null)}
                                        onChange={(e) =>
                                            handleInput("transaction.client.person.lastName", e.target.value)
                                        }
                                        placeholder="Enter last name"
                                        disabled={disableInputs === true}
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="phoneNumber"
                                        label="Client Phone"
                                        value={get(transaction, `client.phoneNumber.phoneNumber`, "")}
                                        error={get(errors, `client.phoneNumber.phoneNumber`, null)}
                                        onChange={(e) =>
                                            handleInput("transaction.client.phoneNumber.phoneNumber", e.target.value)
                                        }
                                        errorText="Invalid"
                                        placeholder="Enter transaction phone"
                                        disabled={disableInputs === true}
                                        phone={true}
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <BSInput
                                        id="emailAddress"
                                        label="Client Email"
                                        value={get(transaction, `client.emailAddress`, "")}
                                        error={get(errors, `client.emailAddress`, null)}
                                        onChange={(e) => handleInput("transaction.client.emailAddress", e.target.value)}
                                        placeholder="Enter transaction email"
                                        disabled={disableInputs === true}
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <AutocompleteFreeform
                                        id="property"
                                        label="Property Name"
                                        error={get(errors, `property.propertyKey`, null)}
                                        initialInputValue={get(
                                            transaction,
                                            `property.propertyAttributes.propertyName`,
                                            ""
                                        )}
                                        itemToString={(i) =>
                                            i == null ? "" : get(i, "propertyAttributes.propertyName", "")
                                        }
                                        onSelect={(property, b) => this.selectProperty(property)}
                                        freeformUpdate={(value) => this.handlePropertyFreeform(value)}
                                        placeholder="Search or create property"
                                        searchFunction={(input) => searchProperty(input)}
                                        startAdornment={<Search />}
                                        endAdornment={
                                            !isNil(get(transaction, `property.propertyKey`, null)) ? (
                                                <IconButton
                                                    onClick={this.clearProperty.bind(this)}
                                                    classes={{ root: classes.clearButton }}
                                                >
                                                    <HighlightOff style={{ fontSize: "16px" }} />
                                                </IconButton>
                                            ) : null
                                        }
                                        suggestions={autocompleteResults}
                                        disabled={
                                            disableInputs === true ||
                                            !isNil(get(transaction, `property.propertyKey`, null))
                                        }
                                        options={{
                                            key: "propertyKey",
                                            display: (suggestion) => {
                                                return get(suggestion, "propertyAttributes.propertyName", "");
                                            },
                                            displaySub: (suggestion) => {
                                                return get(suggestion, "primaryAddress.address1", "");
                                            },
                                        }}
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <BSInput
                                        id="address1"
                                        label="Property Address"
                                        value={get(transaction, `property.primaryAddress.address1`, "")}
                                        error={get(errors, `property.primaryAddress.address1`, null)}
                                        onChange={(e) =>
                                            handleInput("transaction.property.primaryAddress.address1", e.target.value)
                                        }
                                        errorText="Required"
                                        placeholder="Enter address"
                                        disabled={
                                            disableInputs === true ||
                                            !isNil(get(transaction, `property.propertyKey`, null))
                                        }
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="city"
                                        label="City"
                                        value={get(transaction, `property.primaryAddress.city.city`, "")}
                                        error={get(errors, `property.primaryAddress.city.city`, null)}
                                        onChange={(e) =>
                                            handleInput("transaction.property.primaryAddress.city.city", e.target.value)
                                        }
                                        errorText="Required"
                                        placeholder="Enter city"
                                        disabled={
                                            disableInputs === true ||
                                            !isNil(get(transaction, `property.propertyKey`, null))
                                        }
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSSelect
                                        id="state"
                                        label="State"
                                        value={get(transaction, `property.primaryAddress.city.state.stateCode`, "")}
                                        error={get(errors, `property.primaryAddress.city.state.stateCode`, null)}
                                        errorText="Required"
                                        onChange={(e) =>
                                            handleInput(
                                                "transaction.property.primaryAddress.city.state.stateCode",
                                                e.target.value
                                            )
                                        }
                                        options={us_states_options}
                                        disabled={
                                            disableInputs === true ||
                                            !isNil(get(transaction, `property.propertyKey`, null))
                                        }
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="zipCode"
                                        label="Zip Code"
                                        value={get(transaction, `property.primaryAddress.zipCode.zipCode`, "")}
                                        error={get(errors, `property.primaryAddress.zipCode.zipCode`, null)}
                                        onChange={(e) =>
                                            handleInput(
                                                "transaction.property.primaryAddress.zipCode.zipCode",
                                                e.target.value
                                            )
                                        }
                                        errorText="Invalid"
                                        placeholder="Enter zip code"
                                        disabled={
                                            disableInputs === true ||
                                            !isNil(get(transaction, `property.propertyKey`, null))
                                        }
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <AutocompleteFreeform
                                        id="borrowerCompanyName"
                                        label="Borrower LLC Name"
                                        error={get(errors, `borrowerCompany.legalName`, null)}
                                        initialInputValue={get(transaction, `borrowerCompany.legalName`, "")}
                                        itemToString={(i) => (i == null ? "" : get(i, "legalName", ""))}
                                        onSelect={(company, b) => this.selectBorrowerCompany(company)}
                                        freeformUpdate={(value) => this.handleBorrowerCompanyFreeform(value)}
                                        placeholder="Search or create company"
                                        searchFunction={(input) => searchCompany(input)}
                                        startAdornment={<Search />}
                                        endAdornment={
                                            !isNil(get(transaction, `borrowerCompany.companyKey`, null)) ? (
                                                <IconButton
                                                    onClick={this.clearBorrowerCompany.bind(this)}
                                                    classes={{ root: classes.clearButton }}
                                                >
                                                    <HighlightOff style={{ fontSize: "16px" }} />
                                                </IconButton>
                                            ) : null
                                        }
                                        suggestions={autocompleteResults}
                                        disabled={
                                            disableInputs === true ||
                                            !isNil(get(transaction, `borrowerCompany.companyKey`, null))
                                        }
                                        options={{
                                            key: "companyKey",
                                            display: (suggestion) => {
                                                return get(suggestion, "legalName", "");
                                            },
                                        }}
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <BSInput
                                        id="borrowerCompanyName"
                                        label="Borrower Common Name"
                                        value={get(transaction, `borrowerCompany.displayName`, "")}
                                        error={get(errors, `borrowerCompany.displayName`, null)}
                                        onChange={(e) =>
                                            handleInput("transaction.borrowerCompany.displayName", e.target.value)
                                        }
                                        placeholder="Enter company common name"
                                        disabled={
                                            disableInputs === true ||
                                            !isNil(get(transaction, `borrowerCompany.companyKey`, null))
                                        }
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <AutocompleteFreeform
                                        id="borrowerParentCompany"
                                        label="Borrower Parent Company"
                                        error={get(errors, `borrowerParentCompany.displayName`, null)}
                                        initialInputValue={get(transaction, `borrowerParentCompany.displayName`, "")}
                                        itemToString={(i) => (i == null ? "" : get(i, "displayName", ""))}
                                        onSelect={(company, b) => this.selectBorrowerParentCompany(company)}
                                        freeformUpdate={(value) => this.handleBorrowerParentCompanyFreeform(value)}
                                        placeholder="Search or create company"
                                        searchFunction={(input) => searchCompany(input)}
                                        startAdornment={<Search />}
                                        endAdornment={
                                            !isNil(get(transaction, `borrowerParentCompany.companyKey`, null)) ? (
                                                <IconButton
                                                    onClick={this.clearBorrowerParentCompany.bind(this)}
                                                    classes={{ root: classes.clearButton }}
                                                >
                                                    <HighlightOff style={{ fontSize: "16px" }} />
                                                </IconButton>
                                            ) : null
                                        }
                                        suggestions={autocompleteResults}
                                        isOpen={autocompleteResults.length > 0}
                                        disabled={
                                            disableInputs === true ||
                                            !isNil(get(transaction, `borrowerParentCompany.companyKey`, null))
                                        }
                                        options={{
                                            key: "companyKey",
                                            display: (suggestion) => {
                                                return get(suggestion, "displayName", "");
                                            },
                                        }}
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="purchasePrice"
                                        label="Purchase Price"
                                        value={get(transaction, `purchasePrice`, "")}
                                        error={get(errors, `purchasePrice`, null)}
                                        onChange={(e) => handleInput("transaction.purchasePrice", e.target.value)}
                                        placeholder="Enter amount"
                                        disabled={disableInputs === true}
                                        startAdornment="$"
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                        numeric
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="refiValue"
                                        label="Value of REFI"
                                        value={get(transaction, `refiValue`, "")}
                                        error={get(errors, `refiValue`, null)}
                                        onChange={(e) => handleInput("transaction.refiValue", e.target.value)}
                                        placeholder="Enter amount"
                                        disabled={disableInputs === true}
                                        startAdornment="$"
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                        numeric
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="loanAmount"
                                        label="Loan Amount"
                                        value={get(transaction, `loanAmount`, "")}
                                        error={get(errors, `loanAmount`, null)}
                                        onChange={(e) => handleInput("transaction.loanAmount", e.target.value)}
                                        placeholder="Enter amount"
                                        disabled={disableInputs === true}
                                        startAdornment="$"
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                        numeric
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="refiBalance"
                                        label="Balance if REFI"
                                        value={get(transaction, `refiBalance`, "")}
                                        error={get(errors, `refiBalance`, null)}
                                        onChange={(e) => handleInput("transaction.refiBalance", e.target.value)}
                                        placeholder="Enter amount"
                                        disabled={disableInputs === true}
                                        startAdornment="$"
                                        extraFormControlStyles={{ marginBottom: "0px" }}
                                        numeric
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                <DocumentDrop
                    entityKey={get(transaction, "transactionKey")}
                    type="transaction"
                    documentTypes="transaction"
                    parentType={"TRANSACTION"}
                    formDisabled={disableInputs}
                    errors={get(errors, "documents")}
                    alternateTitle="Data Room"
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(EditTransaction);
