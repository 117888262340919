export const commencementDateTypes = [
    {
        value: "Effective Date",
        label: "Effective Date",
    },
    {
        value: "Estimated Close Date",
        label: "Estimated Close Date",
    },
];
