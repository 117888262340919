import React from "react";
import { connect } from "react-redux";
import AuditFilters from "./AuditFilters";
import Collapse from "@material-ui/core/Collapse";
import searchOperations from "../search/ducks/operations";
import operations from "./ducks/operations";
import { audit_tabs } from "./constants";

const AuditFiltersContainer = (props) => {
    const { auditorSearch } = audit_tabs;
    return (
        <React.Fragment>
            <Collapse in={true} unmountOnExit style={{ marginLeft: -20, marginRight: -20 }}>
                <AuditFilters
                    {...props}
                    searchSubmit={props.handleSearchCompsSubmit}
                    disabled={props.activeTab !== auditorSearch}
                />
            </Collapse>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    searchCriteria: state.searchReducer.AUDIT.searchCriteria,
    loadedSearchCrumbs: state.searchReducer.loadedSearchCrumbs.AUDIT,
    role: state.authReducer.userProfile.role,
    activeTab: state.auditReducer.activeTab,
});

const mapDispatchToProps = (dispatch) => ({
    updateFilter: (field, value) => {
        dispatch(searchOperations.updateFilter(field, value, "AUDIT"));
    },
    handleDeleteCrumb: (criteria, value) => {
        dispatch(searchOperations.deleteCrumb(criteria, value, "AUDIT"));
    },
    handleSearchCleared: (cleared) => {
        dispatch(searchOperations.storeSearchCleared(cleared, "AUDIT"));
        dispatch(operations.clearSearchResults());
    },
    clearFilters: () => {
        dispatch(searchOperations.clearFilters("AUDIT"));
    },
    handleSearchCompsSubmit: (searchCriteria) => {
        dispatch(operations.searchComps());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditFiltersContainer);
