// const HOST = 'http://ndp-env.rdqhh3zkjs.us-east-2.elasticbeanstalk.com/';
import configs from "../../../config";

const HOST = configs.HOST_URL + "/";
const COMMENT_URL = HOST + "comments/";
const LISTING_URL = HOST + "listings/";
const PROPERTY_URL = HOST + "properties/";

const VOUCHER_PARENT_RECORD_TYPE = "voucher";
const COMP_PARENT_RECORD_TYPE = "comp";
const DEAL_PARENT_RECORD_TYPE = "deal";
const LISTNG_REQUEST_PARENT_RECORD_TYPE = "listing";
const PROPERTY_PARENT_RECORD_TYPE = "property";

const urls = {
    get_comments: (parentRecordType, parentRecordKey) => {
        return COMMENT_URL + parentRecordType + "/" + parentRecordKey;
    },
    post_comment: (parentRecordType, parentRecordKey) => {
        return COMMENT_URL + parentRecordType + "/" + parentRecordKey;
    },
    reply_to_comment: (parentRecordType, parentRecordKey, parentCommentKey) => {
        return COMMENT_URL + parentRecordType + "/" + parentRecordKey + "/" + parentCommentKey;
    },

    assign_listing_for_review: (listingKey) => {
        return LISTING_URL + "workflow/submit/" + listingKey;
    },
    request_listing_update: (listingKey) => {
        return LISTING_URL + "workflow/request-update/" + listingKey;
    },
    update_property: (propertyKey) => {
        return PROPERTY_URL + propertyKey;
    },
    update_property_status: (propertyKey) => {
        return PROPERTY_URL + "status/" + propertyKey;
    },
};

export default {
    urls,
    VOUCHER_PARENT_RECORD_TYPE,
    COMP_PARENT_RECORD_TYPE,
    DEAL_PARENT_RECORD_TYPE,
    LISTNG_REQUEST_PARENT_RECORD_TYPE,
    PROPERTY_PARENT_RECORD_TYPE,
};
