const viewHeaders = {
    documents: [
        {
            name: "NAME",
            prop: "name",
            type: "plaintext",
        },
        {
            name: "DESCRIPTION",
            prop: "description",
            type: "plaintext",
        },
        {
            name: "PARENT TYPE",
            prop: "parentEntityType",
            type: "plaintext",
        },
    ],
    parentTypes: [
        {
            name: "NAME",
            prop: "name",
            type: "plaintext",
        },
    ],
};

export default {
    viewHeaders,
};
