import React from "react";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import { buildingClasses } from "./constants";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import toLower from "lodash/toLower";

class MultifamilyForm extends React.Component {
    handleAttributeUpdate = (event) => {
        this.props.onChange(event.target.id, event.target.value);
    };

    render() {
        const { data, errors, disableInputs, context, listingStatus, transactionType } = this.props;

        return (
            <div>
                {context === "property" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="officeSf"
                                    label="OFFICE SPACE"
                                    placeholder="Enter SF"
                                    errorText="ex. 98101"
                                    error={errors.officeSf}
                                    value={data.officeSf}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="retailSf"
                                    label="RETAIL SPACE"
                                    placeholder="Enter SF"
                                    errorText="ex. 98101"
                                    error={errors.retailSf}
                                    value={data.retailSf}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
                {context === "listing" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={4}>
                                <BSInput
                                    id="multifamilyUnitCount"
                                    label="MULTIFAMILY UNIT COUNT"
                                    placeholder="Enter count"
                                    errorText="ex. 98"
                                    error={errors.multifamilyUnitCount}
                                    value={data.multifamilyUnitCount}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BSInput
                                    id="multifamilySf"
                                    label="MULTIFAMILY SF"
                                    placeholder="Enter SF"
                                    errorText="ex. 98101"
                                    error={errors.multifamilySf}
                                    value={data.multifamilySf}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BSInput
                                    id="bldgClass"
                                    label="Multifamily Class"
                                    error={errors.bldgClass}
                                    value={data.bldgClass}
                                    onChange={this.handleAttributeUpdate}
                                    options={buildingClasses}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        {toLower(transactionType) === "sale" && listingStatus === "Appraisal" && (
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="commercialSf"
                                        label="COMMERCIAL SF"
                                        placeholder="Enter commerical sf"
                                        error={errors.commercialSf}
                                        value={data.commercialSf}
                                        onChange={this.handleAttributeUpdate}
                                        disabled={disableInputs}
                                        integer
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.affordableHousingFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="affordableHousingFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Affordable Housing"
                                    />
                                    {toLower(transactionType) === "sale" && listingStatus === "Appraisal" && (
                                        <React.Fragment>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={data.lowIncomeHousingTaxCreditFlg ? true : false}
                                                        onChange={(e, checked) =>
                                                            this.props.onChange(e.target.value, checked)
                                                        }
                                                        value="lowIncomeHousingTaxCreditFlg"
                                                        disabled={disableInputs === true}
                                                        color="primary"
                                                    />
                                                }
                                                label="Low Income Housing Tax Credit (LIHTC)"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={data.seniorHousingFlg ? true : false}
                                                        onChange={(e, checked) =>
                                                            this.props.onChange(e.target.value, checked)
                                                        }
                                                        value="seniorHousingFlg"
                                                        disabled={disableInputs === true}
                                                        color="primary"
                                                    />
                                                }
                                                label="Senior Housing"
                                            />
                                        </React.Fragment>
                                    )}
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.wetlandsFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="wetlandsFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Wetlands"
                                    />
                                    <BSInput
                                        id="wetlandsPct"
                                        placeholder="Enter wetlands %"
                                        errorText="ex. 98.01"
                                        error={errors.wetlandsPct}
                                        value={data.wetlandsPct}
                                        onChange={this.handleAttributeUpdate}
                                        disabled={disableInputs}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </div>
                )}
                <Grid container alignItems="flex-start" spacing={8}>
                    <BSInput
                        id="unitMix"
                        label="UNIT MIX"
                        placeholder="Enter unit mix"
                        error={errors.unitMix}
                        value={data.unitMix}
                        onChange={this.handleAttributeUpdate}
                        disabled={disableInputs}
                    />
                </Grid>
            </div>
        );
    }
}

export default MultifamilyForm;
