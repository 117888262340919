import React, { Component } from "react";
import { connect } from "react-redux";
import BrokerDeals from "./BrokerDeals";
import operations from "./ducks/operations";
import listingOperations from "../listing/ducks/operations";
import compOperations from "../comp/ducks/operations";
import searchOperations from "../search/ducks/operations";
import actions from "./ducks/actions";
import includes from "lodash/includes";
import toUpper from "lodash/toUpper";
import { push } from "connected-react-router";
import ReactGA from "react-ga4";

class BrokerDealsContainer extends Component {
    componentDidMount() {
        this.props.getInitialDeals(this.props.auth.userProfile.role, this.props.auth.userProfile.user);
        ReactGA.send({ hitType: "pageview", page: "/deals", title: "Broker Deals" });
    }

    render() {
        return <BrokerDeals {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        role: state.authReducer.userProfile.role,
        user: state.authReducer.userProfile.user,
        deals: state.dealsReducer.deals,
        dealsExport: state.dealsReducer.dealsExport,
        valid: state.authReducer.validFormValues,
        snackbarOpen: state.authReducer.snackbarOpen,
        dealView: includes(
            [
                "broker_open",
                "broker_closed",
                "portland_broker_admin",
                "portland_broker_admin_sale",
                "portland_broker_admin_closed",
                "arizona_broker_admin_review",
                "arizona_broker_admin_closed",
            ],
            state.dealsReducer.dealView
        )
            ? state.dealsReducer.dealView
            : "broker_open",
        columnToSort: state.dealsReducer.columnToSort,
        sortDirection: state.dealsReducer.sortDirection,
        filterQuery: state.dealsReducer.filterQuery,
        selected: state.dealsReducer.selected,
        isLoading: state.utilsReducer.isLoading,
        requestModal: state.listingReducer.requestModal,
        searchResults: state.utilsReducer.searchResults,
        elementsSaving: state.utilsReducer.elementsSaving,
        autocompleteResults: state.searchReducer.autocompleteResults,
        modalData: state.compReducer.modalData,
        errors: state.utilsReducer.errors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchVoucherView: (event, value) => {
            const columnToSort = value === "broker_closed" ? "batchDate" : "transactionDate";
            dispatch(operations.handleSort(columnToSort, "desc"));
            dispatch(operations.switchTab(value));
        },
        handleSort: (columnName, sortDirection) => {
            if (columnName === "dealProgress") {
                dispatch(operations.handleSort("progress"));
            } else {
                dispatch(operations.handleSort(columnName, sortDirection));
            }
        },
        createNewDeal: () => {
            dispatch(operations.createNewDeal());
        },
        handleSelect: (x, voucherKey) => {
            dispatch(operations.selectDeal(x, voucherKey));
        },
        handleSelectNewTab: (x, voucherKey) => {
            dispatch(operations.selectDealNewTab(x, voucherKey));
        },
        handleFilter: (query) => {
            dispatch(operations.filterVouchers(query));
        },

        getInitialDeals: (role, user) => {
            dispatch(operations.getInitialDeals(role, user));
        },
        getDealsForExport: (role, user) => {
            dispatch(operations.getDealsForExport(role, user));
        },
        clearExport: () => {
            dispatch(operations.clearDealsExport());
        },
        toggleRequestModal: (spaceType, transactionType) => {
            dispatch(listingOperations.toggleRequestModal(spaceType, transactionType));
        },
        updateListingRequest: (field, value) => {
            dispatch(listingOperations.updateListingRequest(field, value));
        },
        //for outside comp modal
        addNewDealFromOutsideCompModal: (transactionType, propertyType, newProperty) => {
            dispatch(compOperations.addOutsideComp(transactionType, propertyType, newProperty, "Draft"));
        },
        updateSearchTerm: (value, searchType) => {
            dispatch(searchOperations.updateFilter("searchTerm", value, searchType));
        },
        handleSearchSubmit: () => {
            dispatch(searchOperations.executeSearch("property_modal"));
            dispatch(searchOperations.storeSearchRun(true, toUpper("property_modal")));
        },
        handleCompInput: (path, value) => {
            dispatch(compOperations.handleCompInput(path, value));
        },
        searchProperty: (term) => {
            dispatch(searchOperations.searchPropertyAutocomplete(term));
        },
        selectProperty: (property) => {
            dispatch(compOperations.selectPropertyFromAutocomplete(property));
        },
        removeSelectedProperty: () => {
            dispatch(compOperations.removeSelectedPropertyFromRequestModal());
        },
        handleSelectComp: (dealKey) => {
            dispatch(operations.selectComp(dealKey));
        },
        handleSelectVoucher: (voucherKey) => {
            dispatch(operations.selectComp(voucherKey));
        },
        handleClearDealsState: () => {
            dispatch(actions.clearDealsState());
        },
        toggleReviewedBy: (processed, dealKey, tab) => {
            dispatch(operations.toggleReviewedBy(processed, dealKey, tab));
        },
        handleDisplayActiveListings: () => {
            dispatch(push("/listings?tab=broker_open"));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrokerDealsContainer);
