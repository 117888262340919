import React, { useState } from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Modal,
    Radio,
    RadioGroup,
    Typography,
    TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const AssetServicesSnapshotModal = (props) => {
    const currentDate = new Date();
    const formatDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;

    const { open, toggleModal, classes, archiveAssetServicesRecord, propertyManagementKey, propertyKey } = props;

    const [managementRetained, toggleManagementRetained] = useState("true");
    const [terminationDate, selectTerminationDate] = useState(formatDate);

    const toggleModalClose = () => {
        toggleModal(false);
    };

    const toggleRadio = (value) => {
        toggleManagementRetained(value);
    };

    const handleSubmit = (propertyManagementKey, propertyKey, managementRetained) => {
        const boolManagementRetained = managementRetained === "true" ? true : false;
        const record = {
            managementRetained: boolManagementRetained,
            terminationDate,
        };
        archiveAssetServicesRecord(propertyManagementKey, record, propertyKey);
        toggleModalClose();
    };

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="asset-services-snapshot-modal"
                aria-describedby="saves a new archive snapshot of the current property management information"
                open={open}
                onClose={toggleModalClose}
            >
                <div className={classes.paper}>
                    <Typography variant="h5" className={classes.modalHeader}>
                        Archive Asset Record?
                    </Typography>
                    <div className={classes.formContainer}>
                        <div>
                            <RetainManagementRadioGroup
                                classes={classes}
                                toggleRadio={toggleRadio}
                                managementRetained={managementRetained}
                            />
                            <DatePicker classes={classes} selectTerminationDate={selectTerminationDate} />
                        </div>
                        <FormSubmitButtons
                            classes={classes}
                            toggleModalClose={toggleModalClose}
                            handleSubmit={() => handleSubmit(propertyManagementKey, propertyKey, managementRetained)}
                        />
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

const RetainManagementRadioGroup = (props) => {
    const { classes, toggleRadio, managementRetained } = props;
    return (
        <FormControl className={classes.radioGroup}>
            <FormLabel id="retain-management">Retain management:</FormLabel>
            <RadioGroup
                aria-labelledby="retain-management"
                defaultValue={"true"}
                name="radio-buttons-group"
                className={classes.radioGroup}
                value={managementRetained}
                onChange={(e) => {
                    toggleRadio(e.target.value);
                }}
            >
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label="No" />
            </RadioGroup>
        </FormControl>
    );
};

const DatePicker = (props) => {
    const { classes, selectTerminationDate } = props;
    const today = new Date().toISOString().slice(0, 10);
    return (
        <form className={classes.datePickerContainer}>
            <FormLabel>Archive Date:</FormLabel>
            <TextField
                id="date"
                type="date"
                className={classes.datePicker}
                defaultValue={today}
                InputProps={{
                    disableUnderline: true,
                    inputProps: { max: today },
                }}
                onChange={(e) => {
                    selectTerminationDate(e.target.value);
                }}
            />
        </form>
    );
};

const FormSubmitButtons = (props) => {
    const { classes, toggleModalClose, handleSubmit } = props;
    return (
        <React.Fragment>
            <div className={classes.submitButtonsContainer}>
                <Button variant="contained" color="default" onClick={toggleModalClose}>
                    No
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Yes
                </Button>
            </div>
        </React.Fragment>
    );
};

const styles = (theme) => ({
    snapshotModal: {
        display: "flex",
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        background: "white",
        top: "50%",
        left: "50%",
        width: "400px",
        height: "250px",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        alignItems: "center",
        borderRadius: "3px",
        boxShadow: " 0 3px 7px rgba(0, 0, 0, 0.3)",
    },
    modalHeader: {
        alignSelf: "center",
        marginTop: "5%",
    },
    formContainer: {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        width: "80%",
        height: "90%",
    },
    radioGroup: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "center",
    },
    datePickerContainer: {
        display: "flex",
        alignItems: "center",
    },
    datePicker: {
        width: "100%",
    },
    submitButtonsContainer: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "end",
    },
});

export default withStyles(styles)(AssetServicesSnapshotModal);
