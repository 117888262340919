import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactTooltip from "react-tooltip";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import includes from "lodash/includes";
import "./Listings.css";
import { NO_CONTENT_PLACEHOLDER } from "./constants";
import schema from "./reviewListingsTables";
import { calculateDaysBetween, navigateToLandvision, formatPrice } from "../utils/operations";
import { Comments, Doc, Landvision, Sorty } from "../../../common/js/icons";

const styles = (theme) => ({
    lightButton: {
        fontWeight: "400",
        minWidth: "0px",
        margin: "0px",
        marginRight: "5px",
        padding: "5px 10px",
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    lb: {
        textDecoration: "none",
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: "600",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    tooltip: {
        "&>a:hover": {
            cursor: "default",
        },
    },
    loadingProgress: {
        marginRight: "5px",
        color: "grey",
    },
    gridWrapper: {
        fontFamily: "IBM Plex Sans, sans-serif",
        overflowX: "auto",
    },
    gridHeaderRow: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid rgb(212, 212, 212)",
        color: "#9E9E9E",
        fontSize: "12px",
        fontWeight: "bold",
        padding: "1.5em 0",
        overflow: "hidden",
        scrollbarGutter: "stable",
    },
    gridBodyRow: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid rgb(212, 212, 212)",
        fontSize: "13px",
        height: "fit-content",
        padding: "0.75em 0",
    },
    gridBodyWrapper: {
        display: "block",
        maxHeight: "50vh",
        minHeight: "425px",
        overflowY: "auto",
        scrollbarGutter: "stable",
    },
    alertNotificationText: {
        color: "red",
        fontSize: "13px",
        fontWeight: "bold",
    },
});

class ListingsWithoutMap extends Component {
    render() {
        const {
            role,
            selectedListings,
            classes,
            listingTab,
            handleGridSort,
            loadingSearch,
            handleDragEnd,
            handleCheckboxClick,
            navigateToRequestUpdate,
            currentPath,
            handleSelectAll,
            handleSelectAllGroup,
            selectAll,
            selectAllGroup,
            searchRun,
            searchCleared,
            openCloseDealDialog,
            selectedListingsGroup,
        } = this.props;

        const listings = get(this.props.listings, listingTab, {});
        const hasListings = Object.keys(listings).length > 0;
        const listingsTab = listingTab === "all_km_listings" || listingTab === "listings_group";
        const brokerOpenTab = listingTab === "broker_open";
        const headers = brokerOpenTab ? schema.viewHeaders["broker_open"] : schema.viewHeaders["all_km_listings"];
        const loadRows = brokerOpenTab
            ? !loadingSearch && hasListings
            : !loadingSearch && !searchCleared && hasListings;

        const getListStyle = (isDraggingOver) => ({
            padding: "",
        });

        return (
            <div>
                <Grid container spacing={0} className={classes.gridWrapper}>
                    <Grid item xs={12} style={{ minWidth: "1450px" }}>
                        {/* Grid Header */}
                        <Grid container spacing={12} className={classes.gridHeaderRow}>
                            {headers.map((header, i) => {
                                return (
                                    <Grid
                                        item
                                        xs={
                                            brokerOpenTab && (header.name === "Address" || header.name === "Links")
                                                ? 3
                                                : header.name === "Address" ||
                                                  header.name === "Company" ||
                                                  header.name === "Links"
                                                ? 2
                                                : 1
                                        }
                                        key={`gh-c${i}`}
                                        numeric={header.numeric}
                                        onClick={() => handleGridSort(header.name, listingTab)}
                                        style={{ paddingRight: "7px" }}
                                    >
                                        {header.name === "Property" ? (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                {listingTab === "listings_group" ? (
                                                    <Checkbox
                                                        className={
                                                            selectAllGroup
                                                                ? "listingCheckboxAllSelected"
                                                                : "listingCheckboxAll"
                                                        }
                                                        checked={selectAllGroup}
                                                        value={selectAllGroup}
                                                        onChange={() => handleSelectAllGroup()}
                                                        color="primary"
                                                    />
                                                ) : (
                                                    <Checkbox
                                                        className={
                                                            selectAll
                                                                ? "listingCheckboxAllSelected"
                                                                : "listingCheckboxAll"
                                                        }
                                                        checked={selectAll}
                                                        value={selectAll}
                                                        onChange={() => handleSelectAll()}
                                                        color="primary"
                                                    />
                                                )}
                                                <span style={{ paddingLeft: "7px" }}>{header.name.toUpperCase()}</span>
                                            </div>
                                        ) : header.name === "Links" ? (
                                            <div>{header.name.toUpperCase()}</div>
                                        ) : (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <span style={{ paddingRight: "7px" }}>{header.name.toUpperCase()}</span>
                                                <Sorty />
                                            </div>
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>

                        {/* Grid Body */}
                        <DragDropContext onDragEnd={(result) => handleDragEnd(result, listings, listingTab)}>
                            <Droppable droppableId="droppable" style={{ maxWidth: "100%" }}>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {
                                            <Grid container spacing={12} className={classes.gridBodyWrapper}>
                                                {/* All Km Listings */}
                                                {listingsTab && loadRows
                                                    ? listings.map((listing, i) =>
                                                          row(
                                                              listing,
                                                              i,
                                                              headers,
                                                              role,
                                                              listingTab,
                                                              classes,
                                                              handleCheckboxClick,
                                                              selectedListings,
                                                              navigateToRequestUpdate,
                                                              currentPath,
                                                              selectedListingsGroup,
                                                              navigateToLandvision,
                                                              brokerOpenTab
                                                          )
                                                      )
                                                    : // Broker Listings
                                                    brokerOpenTab && loadRows
                                                    ? listings.map((listing, i) =>
                                                          row(
                                                              listing,
                                                              i,
                                                              headers,
                                                              role,
                                                              listingTab,
                                                              classes,
                                                              handleCheckboxClick,
                                                              selectedListings,
                                                              navigateToRequestUpdate,
                                                              currentPath,
                                                              selectedListingsGroup,
                                                              navigateToLandvision,
                                                              brokerOpenTab,
                                                              openCloseDealDialog
                                                          )
                                                      )
                                                    : null}

                                                {/* Circular progress wheel, no content notifications, etc. */}
                                                {alternateContent(
                                                    listingTab,
                                                    loadingSearch,
                                                    hasListings,
                                                    searchRun,
                                                    classes
                                                )}
                                            </Grid>
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const displayAlertNotification = (listing, classes) => {
    const alert =
        get(listing, "status", null) === "Active Broker Action Required" ? (
            <div className={classes.alertNotificationText}>Update Action Required! (See Comments)</div>
        ) : get(listing, "status", null) === "Update Requested" ? (
            <div className={classes.alertNotificationText} style={{ color: "#FCBA03" }}>
                Update Requested
            </div>
        ) : (
            checkExpiration(listing, classes)
        );
    return alert;
};

const checkExpiration = (listing, classes) => {
    if (get(listing, "autoRenewFlg", false) !== true) {
        const daysBetween = calculateDaysBetween(new Date(), get(listing, "expirationDate", null));
        if (get(listing, "expired", false) === true) {
            return <div className={classes.alertNotificationText}>Expired</div>;
        } else if (daysBetween < 61) {
            return (
                <div className={classes.alertNotificationText}>
                    Expires in {daysBetween + 1} {daysBetween === 0 ? `day` : `days`}
                </div>
            );
        }
    }
};

const row = (
    listing,
    i,
    headers,
    role,
    listingTab,
    classes,
    handleCheckboxClick,
    selectedListings,
    navigateToRequestUpdate,
    currentPath,
    selectedListingsGroup,
    navigateToLandvision,
    brokerOpenTab,
    openCloseDealDialog
) => {
    const isKmListing = !get(listing, "nonKmListingFlg", null);
    const viewCloseButton =
        brokerOpenTab && isKmListing && includes(["broker", "portlandBrokerAdmin", "arizonaBrokerAdmin", "evp"], role);

    const brokersTooltip = (listingBrokers) => {
        return listingBrokers
            .filter((listingBroker) => get(listingBroker, "brokerAgentKey.person", null) !== null)
            .map(
                (listingBroker) =>
                    get(listingBroker, "brokerAgentKey.person.firstName", "") +
                    " " +
                    get(listingBroker, "brokerAgentKey.person.lastName", "")
            );
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging ? "#C5C5C5" : "",
        userSelect: "none",
        width: "100%",
        ...draggableStyle,
    });

    return (
        <Draggable
            draggableId={listing.listingKey}
            index={i}
            style={{ width: "100%", height: "fit-content" }}
            key={`gb-r${i}`}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <Grid container spacing={12} className={classes.gridBodyRow}>
                        {headers.map((header, j) => {
                            const propertyPhoto =
                                get(listing, "listing_photo", null) !== null
                                    ? get(listing, "listing_photo", null)
                                    : brokerOpenTab
                                    ? get(listing, "primaryPhotoUrl", null)
                                    : get(listing, "propertyPhotoUrl", null);
                            const isKmManaged = get(listing, "property.kmManagedFlg", false);

                            return (
                                <Grid
                                    item
                                    key={`gb-r${i}-c${j}`}
                                    xs={
                                        brokerOpenTab && (header.name === "Address" || header.name === "Links")
                                            ? 3
                                            : header.name === "Address" ||
                                              header.name === "Company" ||
                                              header.name === "Links"
                                            ? 2
                                            : 1
                                    }
                                    style={{ paddingRight: "7px" }}
                                >
                                    {
                                        /* --- Property --- */
                                        header.name === "Property" ? (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                {listingTab === "listings_group" ? (
                                                    <Checkbox
                                                        className={
                                                            selectedListingsGroup.get(listing.listingKey) === true
                                                                ? "listingCheckboxAllSelected"
                                                                : "listingCheckboxAll"
                                                        }
                                                        checked={selectedListingsGroup.get(listing.listingKey) === true}
                                                        value={selectedListingsGroup.get(listing.listingKey) === true}
                                                        onChange={() =>
                                                            handleCheckboxClick(listing.listingKey, listingTab)
                                                        }
                                                        color="primary"
                                                    />
                                                ) : (
                                                    <Checkbox
                                                        className={
                                                            selectedListings.get(listing.listingKey) === true
                                                                ? "listingCheckboxAllSelected"
                                                                : "listingCheckboxAll"
                                                        }
                                                        checked={selectedListings.get(listing.listingKey) === true}
                                                        value={selectedListings.get(listing.listingKey) === true}
                                                        onChange={() =>
                                                            handleCheckboxClick(listing.listingKey, listingTab)
                                                        }
                                                        color="primary"
                                                    />
                                                )}
                                                <div
                                                    className="propertySmallImageContainer"
                                                    style={{
                                                        backgroundImage: `url(${propertyPhoto})`,
                                                        marginLeft: "7px",
                                                        width: "85px",
                                                        minWidth: "85px",
                                                        height: "85px",
                                                    }}
                                                >
                                                    {isKmManaged && <span className="listingTag">KM Managed</span>}
                                                </div>
                                            </div>
                                        ) : /* --- Address --- */
                                        header.name === "Address" ? (
                                            <div>
                                                <Link
                                                    to={`/listing/${listing.listingKey}`}
                                                    target="_blank"
                                                    className={classes.lb}
                                                >
                                                    <span
                                                        className={classes.listingIdLink}
                                                        style={
                                                            listing.expired ? { color: "red" } : { color: "#2C99D2" }
                                                        }
                                                    >
                                                        {get(listing, "listingName").trim()}
                                                    </span>
                                                </Link>
                                                <div>{`${get(listing, "property.primaryAddress.address1", {})}`}</div>
                                                <div>{`${get(listing, "property.primaryAddress.city.city", {})}, ${get(
                                                    listing,
                                                    "property.primaryAddress.city.state.stateCode",
                                                    {}
                                                )}, ${get(
                                                    listing,
                                                    "property.primaryAddress.zipCode.zipCode",
                                                    {}
                                                )}`}</div>
                                                {brokerOpenTab && displayAlertNotification(listing, classes)}
                                            </div>
                                        ) : /* --- Brokers --- */
                                        header.name === "Brokers" ? (
                                            <div className={classes.tooltip}>
                                                {get(listing, header.prop, null) !== null ? (
                                                    get(listing, header.prop, []).length > 1 ? (
                                                        <span
                                                            className={classes.tooltip}
                                                            data-tip={
                                                                brokerOpenTab
                                                                    ? brokersTooltip(get(listing, header.prop))
                                                                    : get(listing, header.prop)
                                                            }
                                                        >
                                                            {header.transform(get(listing, header.prop))}
                                                        </span>
                                                    ) : (
                                                        <div>
                                                            {brokerOpenTab
                                                                ? brokersTooltip(get(listing, header.prop))
                                                                : get(listing, header.prop)}
                                                        </div>
                                                    )
                                                ) : (
                                                    <div>{get(listing, "listingAgent", "")}</div>
                                                )}
                                                <ReactTooltip
                                                    place="top"
                                                    type="dark"
                                                    effect="solid"
                                                    key={listing.listingKey}
                                                />
                                            </div>
                                        ) : /* --- Company --- */
                                        header.name === "Company" ? (
                                            <div numeric={header.numeric}>
                                                {get(listing, header.prop) !== (null || "")
                                                    ? get(listing, header.prop)
                                                    : "-"}
                                            </div>
                                        ) : /* --- Type --- */
                                        header.name === "Type" ? (
                                            <div numeric={header.numeric}>
                                                {`${
                                                    brokerOpenTab
                                                        ? get(listing, "attributes.useType")
                                                        : get(listing, "useType")
                                                } ${get(listing, header.prop)}`}
                                            </div>
                                        ) : /* --- Rate / Price --- */
                                        header.name === "Rate/Price" ? (
                                            <div numeric={header.numeric}>
                                                {(get(listing, header.prop) === null ||
                                                    get(listing, header.prop) === 0) &&
                                                (get(listing, "listPrice", null) === null ||
                                                    get(listing, "listPrice", null) === 0) ? (
                                                    "Call Broker"
                                                ) : get(listing, "listingType").toLowerCase() === "lease" ? (
                                                    includes(
                                                        ["Office", "Retail", "Healthcare", "Life Science"],
                                                        get(listing, "useType")
                                                    ) &&
                                                    (includes(
                                                        ["WA", "OR", "AZ"],
                                                        get(listing, "property.primaryAddress.city.state.stateCode")
                                                    ) ||
                                                        get(listing, "property.primaryAddress.city.city", {}) ===
                                                            "San Francisco") ? (
                                                        `$${get(listing, "baseRentPerYear").toFixed(2)} /SF/YR`
                                                    ) : (
                                                        `$${get(listing, header.prop).toFixed(2)} /SF/MTH`
                                                    )
                                                ) : (
                                                    <div>{`$${
                                                        get(listing, "listPrice", null) !== null
                                                            ? formatPrice(get(listing, "listPrice", 0))
                                                            : NO_CONTENT_PLACEHOLDER
                                                    }`}</div>
                                                )}
                                            </div>
                                        ) : /* --- Square Feet --- */
                                        header.name === "SQFT" ? (
                                            <div numeric={header.numeric}>
                                                {get(listing, header.prop) !== null
                                                    ? header.transform(get(listing, header.prop))
                                                    : NO_CONTENT_PLACEHOLDER}
                                            </div>
                                        ) : /* --- Date Available --- */
                                        header.name === "Date Avail" ? (
                                            <div numeric={header.numeric}>
                                                {get(listing, header.prop) !== null
                                                    ? header.transform(get(listing, header.prop))
                                                    : NO_CONTENT_PLACEHOLDER}
                                            </div>
                                        ) : /* --- Links --- */
                                        header.name === "Links" ? (
                                            <div style={{ whiteSpace: "nowrap" }}>
                                                <span data-tip data-for="flyer">
                                                    <Button
                                                        disabled={get(listing, "flyerUrl", null) === null}
                                                        onClick={() => window.open(listing.flyerUrl, "_blank")}
                                                        color="primary"
                                                        className={classes.lightButton}
                                                    >
                                                        <Doc
                                                            fill={
                                                                get(listing, "flyerUrl", null) !== null
                                                                    ? "#2C99D2"
                                                                    : "#9E9E9E"
                                                            }
                                                        />
                                                    </Button>
                                                </span>
                                                <ReactTooltip
                                                    id="flyer"
                                                    place="top"
                                                    type="dark"
                                                    effect="solid"
                                                    className="statBoxTooltip"
                                                >
                                                    Flyer
                                                </ReactTooltip>

                                                <span data-tip data-for="request_update">
                                                    <Button
                                                        onClick={() =>
                                                            navigateToRequestUpdate(listing.listingKey, currentPath)
                                                        }
                                                        color="primary"
                                                        className={classes.lightButton}
                                                    >
                                                        <Comments
                                                            fill={
                                                                !brokerOpenTab
                                                                    ? "#2C99D2"
                                                                    : get(listing, "status", null) ===
                                                                      "Active Broker Action Required"
                                                                    ? "red"
                                                                    : get(listing, "status", null) ===
                                                                      "Update Requested"
                                                                    ? "#FCBA03"
                                                                    : "#2C99D2"
                                                            }
                                                        />
                                                    </Button>
                                                </span>
                                                <ReactTooltip
                                                    id="request_update"
                                                    place="top"
                                                    type="dark"
                                                    effect="solid"
                                                    className="statBoxTooltip"
                                                >
                                                    Request Update
                                                </ReactTooltip>

                                                <span data-tip data-for="landvision">
                                                    <Button
                                                        onClick={() =>
                                                            navigateToLandvision(
                                                                get(listing, "property.propertyKey"),
                                                                listing.listingKey
                                                            )
                                                        }
                                                        color="primary"
                                                        className={classes.lightButton}
                                                    >
                                                        <Landvision fill="#2C99D2" />
                                                        <ReactTooltip place="top" type="dark" effect="solid" />
                                                    </Button>
                                                </span>
                                                <ReactTooltip
                                                    id="landvision"
                                                    place="top"
                                                    type="dark"
                                                    effect="solid"
                                                    className="statBoxTooltip"
                                                >
                                                    Landvision
                                                </ReactTooltip>

                                                {viewCloseButton && (
                                                    <React.Fragment>
                                                        <span data-tip data-for="closeDeal">
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                onClick={() => openCloseDealDialog(listing)}
                                                            >
                                                                Close
                                                            </Button>
                                                        </span>
                                                        <ReactTooltip
                                                            id="closeDeal"
                                                            place="top"
                                                            type="dark"
                                                            effect="solid"
                                                            className="statBoxTooltip"
                                                        >
                                                            Close Deal
                                                        </ReactTooltip>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        ) : null
                                    }
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            )}
        </Draggable>
    );
};

const alternateContent = (listingTab, loadingSearch, hasListings, searchRun, classes) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                {listingTab === "all_km_listings" &&
                    (loadingSearch ? (
                        <div className={classes.alternateContent}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : !hasListings ? (
                        searchRun ? (
                            <div className={classes.alternateContent}>No Listings</div>
                        ) : (
                            <div className={classes.alternateContent}>Please apply search criteria</div>
                        )
                    ) : null)}

                {(listingTab === "listings_group" || listingTab === "broker_open") &&
                    (loadingSearch ? (
                        <div className={classes.alternateContent}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : !hasListings ? (
                        <div className={classes.alternateContent}>No Listings</div>
                    ) : null)}
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(ListingsWithoutMap);
