import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import BSInput from "../../../common/js/components/BSInput";
import get from "lodash/get";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ArrowForward from "../../../../node_modules/@material-ui/icons/ArrowForward";

class InvoiceMonth extends React.Component {
    handleFreeRentCheckbox = (event, checked) => {
        this.props.handlePeriodMonthChange(
            this.props.periodNumber,
            this.props.monthDetail.monthInPeriod,
            event.target.value,
            checked
        );
    };

    handleApplyMonthDiscount = (event) => {
        this.props.handlePeriodMonthChange(
            this.props.periodNumber,
            this.props.monthDetail.monthInPeriod,
            event.target.id,
            event.target.value
        );
    };

    handleChangeMonthlyRate = (event) => {
        this.props.handlePeriodMonthChange(
            this.props.periodNumber,
            this.props.monthDetail.monthInPeriod,
            event.target.id,
            event.target.value
        );
    };

    hasDiscount = () => {
        return this.props.monthDetail.discountPercent && this.props.monthDetail.discountPercent > 0;
    };

    hasFreeRent = () => {
        return this.props.monthDetail.freeRent;
    };

    render() {
        const { classes, disableInputs, errors, monthDetail, periodNumber } = this.props;

        return (
            <TableRow>
                <TableCell>
                    <span className="monthCircle">{monthDetail.monthInPeriod + 1}</span>
                </TableCell>
                <TableCell>{monthDetail.startDate}</TableCell>
                <TableCell>
                    <ArrowForward />
                </TableCell>
                <TableCell>{monthDetail.endDate}</TableCell>
                <TableCell className={classes.centerDatum}>
                    <Checkbox
                        checked={monthDetail.freeRent}
                        onChange={(e, c) => this.handleFreeRentCheckbox(e, c)}
                        value="freeRent"
                        disabled={disableInputs === true}
                        color="primary"
                    />
                </TableCell>
                <TableCell numeric>
                    <BSInput
                        id="discountPercent"
                        value={monthDetail.discountPercent}
                        error={get(errors, `${periodNumber}-${monthDetail.monthInPeriod}.discountPercent`, null)}
                        onChange={(e) => this.handleApplyMonthDiscount(e)}
                        disabled={disableInputs === true || this.hasFreeRent()}
                        endAdornment="%"
                        numeric
                        extraFormControlStyles={{ width: "35%" }}
                    />
                </TableCell>
                <TableCell numeric>
                    <BSInput
                        id="rate"
                        value={monthDetail.rate}
                        error={get(errors, `${periodNumber}-${monthDetail.monthInPeriod}.rate`, null)}
                        onChange={(e) => this.handleChangeMonthlyRate(e)}
                        disabled={disableInputs === true || this.hasDiscount() || this.hasFreeRent()}
                        startAdornment="$"
                        numeric
                        extraFormControlStyles={{ width: "70%" }}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default InvoiceMonth;
