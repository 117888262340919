import React from "react";
import Grid from "@material-ui/core/Grid";
import MultiAddInput from "../../../common/js/components/MultiAddInput";
import ExhibitADetail from "./ExhibitADetail";

class ExhibitAInformation extends React.Component {
    render() {
        const { exhibitAs, disableInputs, handleAddArrayElement, handleDeleteArrayElement, handleUpdateArrayElement } =
            this.props;

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <MultiAddInput
                            addButtonText="Add Exhibit A"
                            buttonType={"card"}
                            disableInputs={disableInputs === true}
                            displayFirstWhenEmpty={false}
                            elements={exhibitAs}
                            onAdd={() => handleAddArrayElement("exhibitAs")}
                            onUpdate={(e, index) =>
                                handleUpdateArrayElement("exhibitAs", index, e.target.id, e.target.value)
                            }
                            render={(element, elementIndex) => (
                                <ExhibitADetail
                                    element={element}
                                    elementIndex={elementIndex}
                                    onDelete={(index) => handleDeleteArrayElement("exhibitAs", index)}
                                    {...this.props}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default ExhibitAInformation;
