import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import BSInput from "../../../common/js/components/BSInput";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { user_role_display_name } from "../../../app/js/auth/constants";
import includes from "lodash/includes";
import map from "lodash/map";
import get from "lodash/get";
import toUpper from "lodash/toUpper";
import classnames from "classnames";
import "../../styles/components/AppBar.css";
import BSSelect from "../../../common/js/components/BSSelect";
import { Search } from "../../../common/js/icons";
import { searchPropertyCategory } from "../../../app/js/search/constants";

const styles = {
    menuButton: {
        marginLeft: -12,
        padding: "4px",
        transition: "background-color 190ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
    },
    menuButtonHover: {
        marginLeft: -12,
        padding: "4px",
        transition: "background-color 190ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
        backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
    appBar: {
        boxShadow: "none",
        borderBottom: "1px solid #e0e0e0",
        alignContent: "center",
        paddingBottom: "5px",
    },
    toolBar: {
        maxHeight: "125px",
        color: "#9e9e9e",
    },
    searchAdornment: {
        color: "white",
        background: "#2C99D2",
        "&:hover": {
            cursor: "pointer",
        },
    },
    filterFormControl: {
        marginBottom: "-17px",
    },
    filterSearch: {
        marginTop: 0,
        marginBottom: 0,
    },
    userMenu: {
        width: "16em",
        boxShadow: "2px 2px 5px 2px rgba(34,54,70,.1)",
        marginTop: "18px",
        maxHeight: "80vh",
        overflowY: "scroll",
    },
    userMenuOption: {
        padding: "2px 16px",
        margin: "0px",
        fontSize: "14px",
    },
    userSwitchBack: {
        padding: "2px 16px",
        margin: "0px",
        marginTop: "8px",
        fontSize: "14px",
    },
    divider: {
        padding: "0px 16px",
        margin: "0px",
        marginTop: "8px",
        fontSize: "14px",
        borderTop: "1px solid #e5e5e5",
        height: "0px",
    },
    userMenuCategory: {
        padding: "2px 16px",
        margin: "0px",
        fontSize: "14px",
        color: "#a0a0a0",
        "&:hover": {
            backgroundColor: "white",
            cursor: "default",
        },
    },
    progressDiv: {
        textAlign: "center",
        width: "40px",
        padding: "4px",
        display: "inline-block",
    },
    searchByASSIdLabel: {
        fontSize: "10px",
    },
    searchByASSIdCheckbox: {
        zIndex: "10",
    },
    searchByASSIdContainer: {
        display: "flex",
        alignItems: "center",
        paddingTop: "20px",
    },
    searchBarRow: {
        display: "flex",
        height: "65px",
        flexDirection: "column",
    },
    searchTypeCategory: {
        marginTop: "-22px", //remove added MUI margin for label in index.css
        minWidth: "200px",
        padding: "4px 0px 4px 0px",
    },
};

class KMAppBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            switchUserMenuOpen: false,
            open: false,
            savedSearchesModal: false,
            searchCategory: "GENERAL",
            searchPlaceholder: "General Search...",
            searchType: "",
        };
    }

    componentDidUpdate() {
        const path = this.props.location.pathname;
        const searchType = path.includes("listings") ? "LISTINGS" : path.includes("comps") ? "COMPS" : "PROPERTY";
        if (searchType !== this.state.searchType) {
            this.updateSearchType(searchType);
            this.determinePlaceholderText(searchType, this.state.searchCategory);
        }
    }

    switchUserProfile = (event, user) => {
        this.props.switchUserProfile(user);
        this.handleClose(event);
    };

    handleToggle = () => {
        this.setState((state) => ({ open: !state.open }));
    };

    handleClose = (event) => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ open: false });
    };

    toggleSearchCategory = (e, searchCriteria) => {
        const { updateSearchTerm } = this.props;
        const { searchType } = this.state;
        this.determinePlaceholderText(searchType, e.target.value);
        this.setState({ searchCategory: e.target.value });

        if (e.target.value === "ACCT_SSID") {
            this.setState({ searchByASSIdChecked: true }, () => this.toggleFilters(true));
        } else {
            this.setState({ searchByASSIdChecked: false }, () => this.toggleFilters(false));
        }

        updateSearchTerm(searchCriteria.searchTerm, e.target.value, toUpper(searchType));
    };

    determinePlaceholderText = (searchType, searchCategory) => {
        let placeholderText;

        if (toUpper(searchType) === "PROPERTY") {
            if (searchCategory === "ACCT_SSID") {
                placeholderText = "Searching by Accounting Software System ID...";
            } else {
                placeholderText = "General Search...";
            }
        } else {
            placeholderText = "General Search...";
        }

        this.setState({ searchPlaceholder: placeholderText });
    };

    toggleFilters = (isToggled) => {
        const { updateFilter } = this.props;
        updateFilter("statusCriteria.kmManagedOnly", isToggled);
        updateFilter("searchByASSId", isToggled);
    };

    handleKeyPress = (key) => {
        const { handleSearchSubmit } = this.props;
        const { searchType } = this.state;
        if (key === "Enter") {
            handleSearchSubmit(searchType);
        }
    };

    updateSearchType = (searchType) => {
        this.setState({ searchType: searchType });
    };

    render() {
        const {
            classes,
            role,
            userProfile,
            authenticatedUserProfile,
            elementsSaving,
            viewSavedSearches,
            updateSearchTerm,
            handleSearchSubmit,
            onSearchPage,
            onGroupPage,
            propertySearchCriteria,
            listingSearchCriteria,
            compSearchCriteria,
            toggleNameSearchModal,
            listingSearchRun,
            compSearchRun,
            propSearchRun,
            getSavedGroupsForUser,
            listingCurrentGroup,
            compCurrentGroup,
        } = this.props;

        const { open, searchType } = this.state;

        const searchCriteria =
            searchType === "LISTINGS"
                ? listingSearchCriteria
                : searchType === "COMPS"
                ? compSearchCriteria
                : propertySearchCriteria;
        const searchRun =
            searchType === "LISTINGS" ? listingSearchRun : searchType === "COMPS" ? compSearchRun : propSearchRun;
        const currentGroup =
            searchType === "LISTINGS" ? listingCurrentGroup : searchType === "COMPS" ? compCurrentGroup : [];
        const showAssetServicesFilters = includes(
            ["propertyManagement", "transition", "accounting", "dataEntry", "dataAdmin"],
            role
        );

        return (
            <div style={{ position: "sticky", top: 0, zIndex: 2 }}>
                <AppBar position="static" className={classnames(classes.appBar)} color="white">
                    <Toolbar className={classnames(classes.toolBar)}>
                        <Grid container justify="space-between" spacing={8}>
                            <Grid item>
                                {onSearchPage ? (
                                    <Grid
                                        container
                                        spacing={8}
                                        style={{ minWidth: "1000px", height: "65px", marginTop: "5px" }}
                                    >
                                        {searchType === "PROPERTY" && showAssetServicesFilters && (
                                            <Grid
                                                item
                                                justify="right"
                                                className={[classes.searchBarRow, classes.searchTypeCategory].join(" ")}
                                            >
                                                <BSSelect
                                                    id="searchTermCategory"
                                                    value={this.state.searchCategory}
                                                    onChange={(e) => this.toggleSearchCategory(e, searchCriteria)}
                                                    options={searchPropertyCategory}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={5} className={classes.searchBarRow}>
                                            <BSInput
                                                id="searchTerm"
                                                noHelperText
                                                formcontrolClass={classes.filterFormControl}
                                                className={classes.filterSearch}
                                                value={searchCriteria.searchTerm}
                                                onChange={(e) =>
                                                    updateSearchTerm(
                                                        e.target.value,
                                                        searchType,
                                                        this.state.searchCategory
                                                    )
                                                }
                                                placeholder={this.state.searchPlaceholder}
                                                onKeyPress={(e) => this.handleKeyPress(e.key)}
                                                endAdornment={
                                                    <div className="filledSearch">
                                                        <Search
                                                            className={classes.searchAdornment}
                                                            onClick={() =>
                                                                handleSearchSubmit(
                                                                    searchType,
                                                                    this.state.searchCategory
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                }
                                            />
                                        </Grid>
                                        <Grid item justify="right" className={classes.searchBarRow}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled={searchRun ? false : true}
                                                onClick={() => toggleNameSearchModal(true)}
                                            >
                                                Save Search
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={() =>
                                                    viewSavedSearches(searchType, userProfile.userAccountKey)
                                                }
                                            >
                                                View Saved Searches
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : onGroupPage ? (
                                    <Grid
                                        container
                                        alignItems="center"
                                        spacing={8}
                                        style={{ minWidth: "700px", height: "49px" }}
                                    >
                                        <Grid item xs={5}>
                                            <BSInput
                                                disabled={true}
                                                id="searchTerm"
                                                formcontrolClass={classes.filterFormControl}
                                                className={classes.filterSearch}
                                                value={searchCriteria.searchTerm}
                                                onChange={(e) => updateSearchTerm(e.target.value, searchType)}
                                                placeholder="Search disabled in list view"
                                                endAdornment={
                                                    <div className="filledSearch">
                                                        <Search className={classes.searchAdornment} />
                                                    </div>
                                                }
                                            />
                                        </Grid>
                                        <Grid item justify="center">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled={currentGroup && currentGroup.length > 0 ? false : true}
                                                onClick={() => toggleNameSearchModal(true)}
                                            >
                                                Save List
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={() =>
                                                    getSavedGroupsForUser(searchType, userProfile.userAccountKey)
                                                }
                                            >
                                                View Saved Lists
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid
                                item
                                style={onSearchPage || onGroupPage ? { marginTop: "8px" } : { marginTop: "0px" }}
                            >
                                <div>
                                    <div style={{ float: "left" }}>
                                        {elementsSaving.switchUser ? (
                                            <div className={classes.progressDiv}>
                                                <CircularProgress size={20} color="primary" />
                                            </div>
                                        ) : (
                                            <IconButton
                                                className={open ? classes.menuButtonHover : classes.menuButton}
                                                color="inherit"
                                                aria-label="Menu"
                                                buttonRef={(node) => {
                                                    this.anchorEl = node;
                                                }}
                                                onClick={this.handleToggle}
                                            >
                                                <AccountCircle style={{ fontSize: "32px" }} />
                                            </IconButton>
                                        )}
                                        <Popper
                                            open={open}
                                            anchorEl={this.anchorEl}
                                            transition
                                            disablePortal
                                            placement="bottom-start"
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow {...TransitionProps} id="menu-list-grow">
                                                    <ClickAwayListener onClickAway={this.handleClose}>
                                                        <Paper className={classes.userMenu}>
                                                            <MenuList>
                                                                {authenticatedUserProfile.user !== userProfile.user && (
                                                                    <React.Fragment>
                                                                        <MenuItem
                                                                            className={classes.userSwitchBack}
                                                                            onClick={(e) =>
                                                                                this.switchUserProfile(
                                                                                    e,
                                                                                    authenticatedUserProfile.user
                                                                                )
                                                                            }
                                                                        >{`Switch back to ${authenticatedUserProfile.firstName} ${authenticatedUserProfile.lastName}`}</MenuItem>
                                                                        <MenuItem
                                                                            className={classes.divider}
                                                                            onClick={null}
                                                                        ></MenuItem>
                                                                    </React.Fragment>
                                                                )}
                                                                <MenuItem
                                                                    className={classes.userMenuCategory}
                                                                    onClick={null}
                                                                >
                                                                    Become Proxy For:
                                                                </MenuItem>
                                                                {map(
                                                                    get(
                                                                        authenticatedUserProfile,
                                                                        "allowedUserProfiles",
                                                                        []
                                                                    ),
                                                                    (user, i) => {
                                                                        return (
                                                                            <MenuItem
                                                                                className={classes.userMenuOption}
                                                                                onClick={(e) =>
                                                                                    this.switchUserProfile(
                                                                                        e,
                                                                                        user.proxyFor
                                                                                    )
                                                                                }
                                                                            >
                                                                                {user.name}
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </MenuList>
                                                        </Paper>
                                                    </ClickAwayListener>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </div>
                                    <div
                                        style={{
                                            float: "right",
                                            color: "black",
                                            paddingLeft: "8px",
                                            marginRight: "20px",
                                            minWidth: "12em",
                                        }}
                                    >
                                        <div
                                            style={
                                                authenticatedUserProfile.user === userProfile.user
                                                    ? { paddingTop: "10px", display: "block", fontSize: "14px" }
                                                    : { display: "block", fontSize: "14px" }
                                            }
                                        >
                                            <span style={{ marginRight: "6px" }}>
                                                {authenticatedUserProfile.firstName} {authenticatedUserProfile.lastName}
                                            </span>
                                            <span
                                                style={{
                                                    fontWeight: 500,
                                                    color: "#a0a0a0",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {user_role_display_name[authenticatedUserProfile.role]}
                                            </span>
                                        </div>
                                        {authenticatedUserProfile.user !== userProfile.user && (
                                            <div style={{ display: "block", fontSize: "14px" }}>
                                                <span style={{ marginRight: "6px" }}>
                                                    Proxy For: {userProfile.firstName} {userProfile.lastName}
                                                </span>
                                                <span
                                                    style={{
                                                        fontWeight: 500,
                                                        color: "#a0a0a0",
                                                        textTransform: "uppercase",
                                                    }}
                                                >
                                                    {user_role_display_name[userProfile.role]}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

KMAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KMAppBar);
