import types from "./types";

const receiveVouchers = (response, tab) => {
    return {
        type: types.RECEIVE_VOUCHERS,
        vouchers: response.data,
        tab: tab,
    };
};

// sort vouchers
const handleSort = (columnName, sortDirection) => {
    return {
        type: types.SORT_VOUCHERS,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

// filter vouchers
const filterVouchers = (query) => {
    return {
        type: types.FILTER_VOUCHERS,
        filterQuery: query,
    };
};

const switchVoucherView = (tab) => {
    return {
        type: types.SWITCH_VOUCHER_VIEW,
        voucherView: tab,
    };
};

const selectAll = () => {
    return {
        type: types.SELECT_ALL,
        selected: [],
    };
};

const checkboxClick = (newSelected, tab) => {
    return {
        type: types.CHECKBOX_CLICK,
        selected: newSelected,
        tab: tab,
    };
};

const updateVouchersSelected = (selected, tab) => {
    return {
        type: types.UPDATE_VOUCHERS_SELECTED,
        selected: selected,
        tab: tab,
    };
};

const addDocuments = (document) => {
    return {
        type: types.ADD_DOCUMENTS,
        preview: document.preview,
        name: document.name,
        docType: "Select ... ",
        document: document,
    };
};

// sort documents
const documentSort = (columnName, sortDirection) => {
    return {
        type: types.SORT_DOCUMENTS,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

// select doc type
const selectDocumentType = (index, docType, newDocTypesUsed) => {
    return {
        type: types.SELECT_DOC_TYPE,
        name: index,
        docType: docType,
        usedDocTypes: newDocTypesUsed,
    };
};

const removeDocument = (newDocuments, newDocTypesUsed) => {
    return {
        type: types.REMOVE_DOCUMENT,
        documents: newDocuments,
        usedDocTypes: newDocTypesUsed,
    };
};

const openDocumentMenu = (key, anchorEl) => {
    return {
        type: types.OPEN_DOC_MENU,
        name: key,
        anchorEl: anchorEl,
    };
};

const autosaveNotSuccess = (isSaving, saveError) => {
    return {
        type: types.AUTOSAVE_NOT_SUCCESS,
        isSaving: isSaving,
        saveError: saveError,
    };
};

const autosaveSuccess = () => {
    return {
        type: types.AUTOSAVE_SUCCESS,
        isSaving: false,
        lastSaved: new Date(),
    };
};

const tableDataLoading = (loading) => {
    return {
        type: types.TABLE_DATA_LOADING,
        tableDataLoading: loading,
    };
};

const vouchersDataChange = (path, value) => {
    return {
        type: types.VOUCHERS_DATA_CHANGE,
        path: path,
        value: value,
    };
};

const setAccountingInputParam = (name, value) => {
    return {
        type: types.SET_ACCOUNTING_INPUT_PARAM,
        name: name,
        value: value,
    };
};

export default {
    receiveVouchers,
    switchVoucherView,
    handleSort,
    filterVouchers,
    selectAll,
    checkboxClick,
    addDocuments,
    documentSort,
    selectDocumentType,
    removeDocument,
    openDocumentMenu,
    autosaveNotSuccess,
    autosaveSuccess,
    tableDataLoading,
    vouchersDataChange,
    updateVouchersSelected,
    setAccountingInputParam,
};
