import each from "lodash/each";
import get from "lodash/get";
import utils from "../utils/operations";
import { aggregateBrokers } from "../utils/operations";
import numeral from "numeral";

const viewHeaders = {
    accounting_awaiting_payment: [
        {
            name: "Deal",
            prop: "dealKey",
            type: "hyperlink",
        },
        {
            name: "Voucher Id",
            prop: "voucherKey",
            type: "plaintext",
        },
        {
            name: "Brokers",
            prop: "brokers",
            type: "plaintext",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "brokerAgent.person");
            },
        },
        // {
        //   name: "Submitted Date",
        //   // prop: "submittedDate",
        //   prop: "readyDate",
        //   type: "plaintext",
        //   transform: utils.formatDate("MM-DD-YYYY"),
        //   sortFunction: utils.formatDate("YYYY-MM-DD"),
        // },
        {
            name: "Installment Comm",
            prop: "installmentCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return value ? "$ " + numeral(value).format("0,0.00") : "-";
            },
        },
        {
            name: "Total Comm",
            prop: "totalCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return value ? "$ " + numeral(value).format("0,0.00") : "-";
            },
        },
        {
            name: "Status",
            prop: "voucherStatus",
            type: "plaintext",
        },
    ],
    accounting_ready_payment: [
        {
            name: "Deal",
            prop: "address1",
            type: "hyperlink",
        },
        {
            name: "Voucher Id",
            prop: "voucherKey",
            type: "plaintext",
        },
        {
            name: "Brokers",
            prop: "brokers",
            type: "plaintext",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "brokerAgent.person");
            },
        },
        {
            name: "Submitted With Payment",
            prop: "paymentAttachedDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
            sortFunction: utils.formatDate("YYYY-MM-DD"),
        },
        {
            name: "Ready For Payment",
            // prop: "submittedDate",
            prop: "readyDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
            sortFunction: utils.formatDate("YYYY-MM-DD"),
        },

        {
            name: "Processed By",
            prop: "acceptedBy",
            type: "plaintext",
        },
        {
            name: "Printed By",
            prop: "printedBy",
            type: "plaintext",
        },
        {
            name: "Installment Comm",
            prop: "installmentCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return value ? "$ " + numeral(value).format("0,0.00") : "-";
            },
        },
        {
            name: "Status",
            prop: "voucherStatus",
            type: "plaintext",
        },
    ],
    accounting_closed: [
        {
            name: "Deal",
            prop: "dealKey",
            type: "hyperlink",
        },
        {
            name: "Voucher Id",
            prop: "voucherKey",
            type: "plaintext",
        },
        {
            name: "Brokers",
            prop: "brokers",
            type: "plaintext",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "brokerAgent.person");
            },
        },
        {
            name: "Submitted With Payment",
            prop: "paymentAttachedDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
            sortFunction: utils.formatDate("YYYY-MM-DD"),
        },
        {
            name: "Ready For Payment",
            // prop: "submittedDate",
            prop: "readyDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
            sortFunction: utils.formatDate("YYYY-MM-DD"),
        },
        {
            name: "Batch Date",
            prop: "approvedDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Processed By",
            prop: "acceptedBy",
            type: "plaintext",
        },
        {
            name: "Printed By",
            prop: "printedBy",
            type: "plaintext",
        },
        {
            name: "Installment Comm",
            prop: "installmentCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return value ? "$ " + numeral(value).format("0,0.00") : "-";
            },
        },
        {
            name: "Status",
            prop: "voucherStatus",
            type: "plaintext",
        },
    ],
};

const columnsToQuery = [
    "address1",
    "paymentTotal",
    "totalCommission",
    "voucherKey",
    "dealKey",
    "voucherStatus",
    "dataEntryOwner",
    "brokers",
];

const filterByQuery = (data, columnsToQuery, query) => {
    let included = false;
    each(columnsToQuery, (column) => {
        if (get(data, column)) {
            if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                included = true;
            }
        }
    });
    return included;
};

export default {
    viewHeaders,
    columnsToQuery,
    filterByQuery,
};
