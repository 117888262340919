import types from "./types";

const receiveVouchers = (data, tab) => {
    return {
        type: types.RECEIVE_DEALS,
        deals: data,
        tab: tab,
    };
};

const receiveVouchersForExport = (data, tab) => {
    return {
        type: types.RECEIVE_DEALS_EXPORT,
        dealsExport: data,
        tab: tab,
    };
};

const initiateDealsExport = (tab) => {
    return {
        type: types.INITIATE_DEALS_EXPORT,
        tab: tab,
    };
};

const clearDealsExport = () => {
    return {
        type: types.CLEAR_DEALS_EXPORT,
        dealsExport: null,
    };
};

// sort vouchers
const handleSort = (columnName, sortDirection) => {
    return {
        type: types.SORT_DEALS,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

// filter vouchers
const filterVouchers = (query) => {
    return {
        type: types.FILTER_DEALS,
        filterQuery: query,
    };
};

const switchVoucherView = (tab) => {
    return {
        type: types.SWITCH_DEAL_VIEW,
        dealView: tab,
    };
};

const selectAll = () => {
    return {
        type: types.SELECT_ALL,
        selected: [],
    };
};

const checkboxClick = (newSelected) => {
    return {
        type: types.CHECKBOX_CLICK,
        selected: newSelected,
    };
};

const addDocuments = (document) => {
    return {
        type: types.ADD_DOCUMENTS,
        preview: document.preview,
        name: document.name,
        docType: "Select ... ",
        document: document,
    };
};

//sort documents
const documentSort = (columnName, sortDirection) => {
    return {
        type: types.SORT_DOCUMENTS,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

//select doc type
//unsure if we need documentDao here - already exists in equivalent method in documents/actions.js
const selectDocumentType = (index, docType, newDocTypesUsed, documentDao) => {
    return {
        type: types.SELECT_DOC_TYPE,
        name: index,
        docType: docType,
        usedDocTypes: newDocTypesUsed,
        documentDao: documentDao,
    };
};

const removeDocument = (newDocuments, newDocTypesUsed) => {
    return {
        type: types.REMOVE_DOCUMENT,
        documents: newDocuments,
        usedDocTypes: newDocTypesUsed,
    };
};

const openDocumentMenu = (key, anchorEl) => {
    return {
        type: types.OPEN_DOC_MENU,
        name: key,
        anchorEl: anchorEl,
    };
};

const autosaveNotSuccess = (isSaving, saveError) => {
    return {
        type: types.AUTOSAVE_NOT_SUCCESS,
        isSaving: isSaving,
        saveError: saveError,
    };
};

const autosaveSuccess = () => {
    return {
        type: types.AUTOSAVE_SUCCESS,
        isSaving: false,
        lastSaved: new Date(),
    };
};

const receiveVoucherDocument = (document) => {
    return {
        type: types.RECEIVE_VOUCHER_DOCUMENT,
        document: document,
    };
};

const setDealProgress = (vouchersWithProgress, tab) => {
    return {
        type: types.SET_DEAL_PROGRESS,
        deals: vouchersWithProgress,
        tab: tab,
    };
};

const loading = (isLoading) => {
    return {
        type: types.PAGE_LOADING,
        isLoading: isLoading,
    };
};

const clearDealsState = () => {
    return {
        type: types.CLEAR_DEALS_STATE,
    };
};

export default {
    receiveVouchers,
    receiveVouchersForExport,
    initiateDealsExport,
    clearDealsExport,
    switchVoucherView,
    handleSort,
    filterVouchers,
    selectAll,
    checkboxClick,
    addDocuments,
    documentSort,
    selectDocumentType,
    removeDocument,
    openDocumentMenu,
    autosaveNotSuccess,
    autosaveSuccess,
    receiveVoucherDocument,
    setDealProgress,
    loading,
    clearDealsState,
};
