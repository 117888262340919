import validate from "validate.js";
import { constraints } from "../../utils/validation";
import mapValues from "lodash/mapValues";
import get from "lodash/get";
import isNil from "lodash/isNil";

const contactValidationContraints = {
    "phoneNumber.phoneNumber": {
        ...constraints.number,
    },
    "address.zipCode.zipCode": {
        ...constraints.id,
    },
};

const validateAddress = (addr) => {
    if (addr) {
        const address = get(addr, "address1");
        const city = get(addr, "city.city");
        const state = get(addr, "city.state.stateCode");
        const zipCode = get(addr, "zipCode.zipCode");

        console.log(address);
        console.log(city);
        console.log(state);
        console.log(zipCode);
        console.log(zipCode);
        console.log(" ");

        if (!isNil(city) && city !== "") {
            if (isNil(state) || state === "") {
                return { "address.city.state.stateCode": "Required" };
            }
            if (isNil(address) || address === "") {
                return { "address.address1": "Required" };
            }
            if (isNil(zipCode) || zipCode === "") {
                return { "address.zipCode.zipCode": "Required" };
            }
        } else if (!isNil(state) && state !== "") {
            if (isNil(city) || city === "") {
                return { "address.city.city": "Required" };
            }
            if (isNil(address) || address === "") {
                return { "address.address1": "Required" };
            }
            if (isNil(zipCode) || zipCode === "") {
                return { "address.zipCode.zipCode": "Required" };
            }
        } else if (!isNil(address) && address !== "") {
            if (isNil(city) || city === "") {
                return { "address.city.city": "Required" };
            }
            if (isNil(state) || state === "") {
                return { "address.city.state.stateCode": "Required" };
            }
            if (isNil(zipCode) || zipCode === "") {
                return { "address.zipCode.zipCode": "Required" };
            }
        } else if (!isNil(zipCode) && zipCode !== "") {
            if (isNil(city) || city === "") {
                return { "address.city.city": "Required" };
            }
            if (isNil(address) || address === "") {
                return { "address.address1": "Required" };
            }
            if (isNil(state) || state === "") {
                return { "address.city.state.stateCode": "Required" };
            }
        }
    }
    return {};
};

const validateContact = (contact) => {
    let errors = {
        ...mapValues(validate(contact, contactValidationContraints, { fullMessages: false }), (error) => {
            return error[0];
        }),
        ...validateAddress(get(contact, "address")),
    };
    console.log(errors);
    return errors;
};

export default {
    validateContact,
};
