import types from "./types";
import setWith from "lodash/setWith";
import clone from "lodash/clone";

function SpecialtyTypeCriteria() {
    // Office Specialty Types
    this.government = false;
    this.mixedUse = false;
    this.generalOffice = false;
    this.executiveSpace = false;
    this.dataCallCenter = false;
    this.lowRiseOfficeBuilding = false;
    this.midRiseOfficeBuilding = false;
    this.highRiseOfficeBuilding = false;

    // Industrial Specialty Types
    this.highTechOrRaD = false;
    this.manufacturing = false;
    this.warehouseDistribution = false;
    this.warehouseOffice = false;
    this.selfStorage = false;

    // Land Specialty Types
    this.scraped = false;
    this.obsolete = false;
    this.singleFamilyLand = false;
    this.farmlandRanch = false;
    this.vacantLand = false;
    this.contractorYard = false;
    this.truckTerminal = false;
    this.agriculture = false;

    // Retail specialty types
    this.bigBox = false;
    this.neighborhoodCenter = false;
    this.anchored = false;
    this.lifestyleCenter = false;
    this.outdoorMall = false;
    this.padSite = false;
    this.restaurant = false;
    this.retailMixedUse = false;
    this.stripCenter = false;
    this.superRegionalCenter = false;

    // Multifamily types
    this.garden = false;
    this.loft = false;
    this.midrise = false;
    this.hirise = false;
    this.militaryHousing = false;
    this.seniorHousing = false;
    this.studentHousing = false;

    // Hospitality
    this.hotel = false;
    this.motel = false;
    this.resort = false;

    // Other Specialty types
    this.civic = false;
    this.library = false;
    this.museum = false;
    this.commercial = false;
    this.carDealership = false;
    this.event = false;
    this.amusementPark = false;
    this.arena = false;
    this.sportsFacility = false;
    this.theater = false;
    this.cityHall = false;
    this.manufacturedHousingAndRV = false;

    // Educational
    this.collegeUniversity = false;
    this.schoolK12 = false;
    this.preschoolDayCare = false;

    // Healthcare
    this.medicalDental = false;
    this.veterinary = false;
    this.land = false;
}

function BuildingClassCriteria() {
    this.trophy = false;
    this.a = false;
    this.b = false;
    this.c = false;
    this.d = false;
}

function BuildingTypeCriteria() {
    this.lowRise = false;
    this.midRise = false;
    this.highRise = false;
}

function PropertyTypeCriteria(type) {
    this.propertyType = type;
    this.selected = false;
    this.specialtyTypeCriteria = new SpecialtyTypeCriteria();
    this.buildingTypeCriteria = new BuildingTypeCriteria();
    this.buildingClassCriteria = new BuildingClassCriteria();
}

function SearchCriteria() {
    this.searchTerm = null;
    this.statusCriteria = {
        existing: false,
        proposed: false,
        underConstruction: false,
        kmManagedOnly: false,
    };
    this.tenancyCriteria = {
        single: false,
        multi: false,
    };
    this.sizeCriteria = {
        min: "",
        max: "",
        availableSf: false,
        totalSf: false,
        acreage: false,
    };
    this.propertyTypes = {
        OFFICE: new PropertyTypeCriteria("OFFICE"),
        INDUSTRIAL: new PropertyTypeCriteria("INDUSTRIAL"),
        LAND: new PropertyTypeCriteria("LAND"),
        RETAIL: new PropertyTypeCriteria("RETAIL"),
        MULTIFAMILY: new PropertyTypeCriteria("MULTIFAMILY"),
        HOSPITALITY: new PropertyTypeCriteria("HOSPITALITY"),
        EDUCATIONAL: new PropertyTypeCriteria("EDUCATIONAL"),
        HEALTHCARE: new PropertyTypeCriteria("HEALTHCARE"),
        RELIGIOUS: new PropertyTypeCriteria("RELIGIOUS"),
        OTHER: new PropertyTypeCriteria("OTHER"),
    };
}

export default (
    state = {
        PROPERTY: {
            searchCriteria: {},
            savedSearches: [],
            retainSelectedFromSave: false,
            searchRun: false,
            searchCleared: false,
            savedSearchRun: false,
            currentGroup: [],
        },
        PROPERTY_MODAL: {
            searchCriteria: {},
            savedSearches: [],
            retainSelectedFromSave: false,
            searchRun: false,
            searchCleared: false,
            savedSearchRun: false,
            currentGroup: [],
        },
        LISTINGS: {
            searchCriteria: {},
            selectedFromSave: [],
            retainSelectedFromSave: false,
            mapCrumbCleared: false,
            searchCleared: false,
            searchRun: false,
            polygon: {},
            mapSearchSettings: {},
            savedSearches: [],
            savedSearchRun: false,
            savedMapCoords: [],
            currentGroup: [],
            savedGroups: [],
        },
        COMPS: {
            searchCriteria: {},
            retainSelectedFromSave: false,
            mapCrumbCleared: false,
            searchCleared: false,
            searchRun: false,
            polygon: {},
            mapSearchSettings: {},
            savedSearches: [],
            savedSearchRun: false,
            savedMapCoords: [],
            currentGroup: [],
            savedGroups: [],
        },
        AUDIT: {
            searchCriteria: {},
            retainSelectedFromSave: false,
            searchCleared: false,
            searchRun: false,
            currentGroup: [],
        },

        autocompleteResults: [],
        autocomplateSearchTerm: null,
        lockSearchCrumbs: false,
        searchCrumbs: {
            LISTINGS: {},
            COMPS: {},
            PROPERTY: {},
        },
        loadingSearch: false,
        loadedSearchCrumbs: {
            LISTINGS: {},
            COMPS: {},
            PROPERTY: {},
            AUDIT: {},
        },
        viewSavedModal: false,
        nameSaveModal: false,
        savedSearchName: null,
        saveSelectedOnly: false,
        onSearchPage: false,
        onGroupPage: false,
    },
    action
) => {
    switch (action.type) {
        case types.REPLACE_SEARCH_CRITERIA:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchCriteria: action.searchCriteria,
                },
            };
        case types.SELECTED_ARRAY_FROM_SAVE:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    selectedFromSave: action.selectedFromSave,
                },
            };
        case types.RETAIN_SELECTED_ITEMS:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    retainSelectedFromSave: action.retainSelectedFromSave,
                },
            };
        case types.SEARCH_FROM_SAVE:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    savedSearchRun: action.savedSearchRun,
                },
            };
        case types.STORE_SAVED_COORDS:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    savedMapCoords: action.savedMapCoords,
                },
            };
        case types.REPLACE_SEARCH_CRUMBS:
            return {
                ...state,
                searchCrumbs: {
                    ...state.searchCrumbs,
                    [action.key]: action.searchCrumbs,
                },
            };
        case types.UPDATE_SEARCH_CRITERIA:
            return setWith(clone(state), `${action.path}`, action.value, clone);
        case types.UPDATE_PROPERTY_TYPE:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchCriteria: {
                        ...state[action.key]["searchCriteria"],
                        propertyTypes: {
                            ...state[action.key]["searchCriteria"]["propertyTypes"],
                            [action.propertyType]: {
                                ...state[action.key]["searchCriteria"]["propertyTypes"][action.propertyType],
                                selected: action.value,
                            },
                        },
                    },
                },
            };
        case types.UPDATE_SPECIALTY_TYPE_CRITERIA:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchCriteria: {
                        ...state[action.key]["searchCriteria"],
                        propertyTypes: {
                            ...state[action.key]["searchCriteria"]["propertyTypes"],
                            [action.propertyType]: {
                                ...state[action.key]["searchCriteria"]["propertyTypes"][action.propertyType],
                                specialtyTypeCriteria: {
                                    ...state[action.key]["searchCriteria"]["propertyTypes"][action.propertyType][
                                        "specialtyTypeCriteria"
                                    ],
                                    [action.field]: action.value,
                                },
                            },
                        },
                    },
                },
            };
        case types.UPDATE_BUILDING_CLASS_CRITERIA:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchCriteria: {
                        ...state[action.key]["searchCriteria"],
                        propertyTypes: {
                            ...state[action.key]["searchCriteria"]["propertyTypes"],
                            [action.propertyType]: {
                                ...state[action.key]["searchCriteria"]["propertyTypes"][action.propertyType],
                                buildingClassCriteria: {
                                    ...state[action.key]["searchCriteria"]["propertyTypes"][action.propertyType][
                                        "buildingClassCriteria"
                                    ],
                                    [action.field]: action.value,
                                },
                            },
                        },
                    },
                },
            };
        case types.UPDATE_TENANCY_TYPE_CRITERIA:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchCriteria: {
                        ...state[action.key]["searchCriteria"],
                        tenancyCriteria: {
                            ...state[action.key]["searchCriteria"]["tenancyCriteria"],
                            [action.field]: action.value,
                        },
                    },
                },
            };
        case types.LOAD_SEARCH_RESPONSE:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchResponse: {
                        ...action.response,
                    },
                },
            };
        case types.APPEND_SEARCH_RESULTS:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchResponse: {
                        ...state[action.key]["searchResponse"],
                        currentResultCount:
                            state[action.key]["searchResponse"]["results"].length + action.response.results.length,
                        results: state[action.key]["searchResponse"]["results"].concat(action.response.results),
                    },
                },
            };
        case types.RESET_SEARCH_CRITERIA:
            return {
                ...state,
                [action.key]: {
                    searchCriteria: new SearchCriteria(),
                    dataExport: { results: null, exportInProgress: false },
                    currentGroup: [...state[action.key].currentGroup],
                },
            };
        case types.CLEAR_FILTERS:
            return {
                ...state,
                [action.filter]: {
                    ...state[action.filter],
                    searchCriteria: {
                        searchTerm: "",
                    },
                },
            };
        case types.CLEAR_CRUMBS:
            return {
                ...state,
                searchCrumbs: {
                    ...state.searchCrumbs,
                    [action.searchType]: {},
                },
            };
        case types.MAP_CRUMB_CLEAR:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    mapCrumbCleared: action.mapCrumbCleared,
                },
            };
        case types.STORE_SEARCH_CLEARED:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchCleared: action.searchCleared,
                },
            };
        case types.STORE_SEARCH_RUN:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchRun: action.searchRun,
                },
            };
        case types.UPDATE_SEARCH_POLYGON:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    polygon: action.polygon,
                },
            };
        case types.CLEAR_LOADED_CRUMBS:
            return {
                ...state,
                loadedSearchCrumbs: {
                    ...state.loadedSearchCrumbs,
                    [action.searchType]: {},
                },
            };
        case types.INITIATE_DATA_EXPORT:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    dataExport: {
                        results: null,
                        exportInProgress: true,
                    },
                },
            };
        case types.LOAD_DATA_EXPORT:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    dataExport: {
                        results: action.results,
                        exportInProgress: false,
                    },
                },
            };
        case types.CLEAR_DATA_EXPORT:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    dataExport: {
                        results: null,
                        exportInProgress: false,
                    },
                },
            };
        case types.UPDATE_CRUMB:
            return {
                ...state,
                searchCrumbs: {
                    ...state.searchCrumbs,
                    [action.searchType]: {
                        ...state.searchCrumbs[action.searchType],
                        [action.crumb]: {
                            ...state[action.crumb],
                            value: action.value,
                            field: action.field,
                        },
                    },
                },
            };
        case types.UPDATE_RANGE_CRUMB:
            return {
                ...state,
                searchCrumbs: {
                    ...state.searchCrumbs,
                    [action.searchType]: {
                        ...state.searchCrumbs[action.searchType],
                        [action.crumb]: {
                            ...state[action.crumb],
                            value: action.value,
                            valueMin: action.valueMin,
                            valueMax: action.valueMax,
                            field1: action.field1,
                            field2: action.field2,
                            unit: action.unit,
                        },
                    },
                },
            };
        case types.LOAD_CRUMBS:
            return {
                ...state,
                loadedSearchCrumbs: {
                    ...state.loadedSearchCrumbs,
                    [action.searchType]: action.crumbs,
                },
            };
        case types.FILTER_TABLE_DATA:
            return Object.assign({}, state, {
                filterQuery: action.filterQuery,
            });
        case types.SORT_TABLE_DATA:
            return Object.assign({}, state, {
                columnToSort: action.columnToSort,
                sortDirection: action.sortDirection,
            });
        case types.SEARCH_RESULTS_X:
            return {
                ...state,
                searchResults: action.results,
            };
        case types.AUTOCOMPLETE_RESULTS:
            return {
                ...state,
                autocompleteResults: action.results,
            };
        case types.AUTOCOMPLETE_SEARCH_TERM:
            return {
                ...state,
                autocompleteSearchTerm: action.term,
            };
        case types.LOADING_SEARCH_COMPS:
            return {
                ...state,
                loadingSearch: action.loadingSearch,
            };
        case types.VIEW_SAVED_MODAL:
            return {
                ...state,
                viewSavedModal: action.open,
            };
        case types.NAME_SAVE_MODAL:
            return {
                ...state,
                nameSaveModal: action.open,
            };
        case types.SET_SEARCH_NAME:
            return {
                ...state,
                savedSearchName: action.name,
            };
        case types.SAVE_SELECTED_ONLY:
            return {
                ...state,
                saveSelectedOnly: action.checked,
            };
        case types.CLEAR_SEARCH_RESPONSE:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchResponse: {},
                    mapSearchSettings: {},
                },
            };
        case types.SAVE_MAP_SEARCH_SETTINGS:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    mapSearchSettings: action.settings,
                },
            };
        case types.LOAD_SAVED_SEARCHES:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    savedSearches: action.savedSearches,
                },
            };
        case types.LOAD_SAVED_GROUPS:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    savedGroups: action.savedGroups,
                },
            };
        case types.CLEAR_SAVED_SEARCHES:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    savedSearches: [],
                },
            };
        case types.ON_SEARCH_PAGE:
            return {
                ...state,
                onSearchPage: action.onSearchPage,
            };
        case types.ON_GROUP_PAGE:
            return {
                ...state,
                onGroupPage: action.onGroupPage,
            };
        case types.SET_CURRENT_GROUP:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    currentGroup: action.currentGroup,
                },
            };
        case types.SET_SAVED_GROUPS:
            console.log("searchReducer");
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    savedGroups: action.savedGroups,
                },
            };
        default:
            return state;
    }
};
