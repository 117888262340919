import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, InputLabel, Chip, Grid } from "@material-ui/core";
import { Edit, ArrowDropDown } from "@material-ui/icons";
import { Draggable } from "react-beautiful-dnd";
import ActionChips from "./ActionChips";
import ConditionChips from "./ConditionChips";
import { chipColors } from "./constants";

const ComplianceRule = (props) => {
    const { classes, rule, editRule, index } = props;

    const [isExpanded, setIsExpanded] = useState(false);
    const { ruleName, priority, ruleKey, initialPriority } = rule;
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        // change background colour if dragging
        background: isDragging ? "#C5C5C5" : "",
        borderRadius: "5px",
        // styles we need to apply on draggables
        ...draggableStyle,
    });

    return (
        <React.Fragment>
            <Draggable draggableId={ruleKey} index={index}>
                {(provided, snapshot) => {
                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                            <Grid container className={classes.ruleContainer} justify="space-between">
                                <Grid item xs={10}>
                                    <Grid container spacing={16} className={classes.containerContainer}>
                                        <Grid item>
                                            <Grid
                                                container
                                                className={classes.ruleNameContainer}
                                                onClick={() => setIsExpanded(!isExpanded)}
                                            >
                                                <Grid item>
                                                    <InputLabel shrink className={classes.bootstrapFormLabel}>
                                                        {ruleName}
                                                    </InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <ArrowDropDown
                                                        className={classes.arrowDropDown}
                                                        style={{ transform: !isExpanded ? null : "rotate(180deg)" }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ width: "inherit" }}>
                                            <Grid container>
                                                <Grid item xs={2} className={classes.labelContainer}>
                                                    <span className={classes.labelStyle}>Actions</span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10}
                                                    className={classes.conditionsActionsContainer}
                                                    style={{ flexWrap: !isExpanded ? "nowrap" : "wrap" }}
                                                >
                                                    <ActionChips rule={rule} noWrap={!isExpanded} showDocName={true} />
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={2} className={classes.labelContainer}>
                                                    <span className={classes.labelStyle}>Conditions</span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10}
                                                    className={classes.conditionsActionsContainer}
                                                    style={{ flexWrap: !isExpanded ? "nowrap" : "wrap" }}
                                                >
                                                    <ConditionChips rule={rule} noWrap={!isExpanded} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} className={classes.priorityAndEditContainer}>
                                    <Grid container spacing={16}>
                                        <Grid item>
                                            <span>
                                                <InputLabel shrink className={classes.bootstrapFormLabel}>
                                                    {"Priority"}
                                                </InputLabel>
                                            </span>
                                        </Grid>
                                        <Grid item>
                                            <span style={{ padding: "10px" }}>
                                                <Chip
                                                    variant="outlined"
                                                    className={classes.chipStyle}
                                                    label={priority}
                                                    style={
                                                        initialPriority !== priority
                                                            ? { border: `0.2em solid ${chipColors.optional}` }
                                                            : {}
                                                    }
                                                />
                                            </span>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <IconButton onClick={() => editRule(ruleKey)}>{<Edit />}</IconButton>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    );
                }}
            </Draggable>
        </React.Fragment>
    );
};

const styles = (theme) => ({
    ruleContainer: {
        border: "0.5px solid #c0c0c0",
        borderRadius: "5px",
        margin: "2px 0px",
        padding: "10px",
        display: "flex",
    },
    ruleNameContainer: {
        display: "flex",
        alignItems: "center",
    },
    containerContainer: {
        display: "flex",
        flexDirection: "column",
    },
    arrowDropDown: {
        display: "flex",
        alignItems: "center",
        color: "#9E9E9E",
        transition: "transform 0.4s ease-in-out",
    },
    labelStyle: {
        fontSize: "12px",
    },
    labelContainer: {
        display: "flex",
        alignItems: "center",
    },
    conditionsActionsContainer: {
        display: "flex",
    },
    priorityAndEditContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    chipStyle: {
        marginBottom: "5px",
        border: `0.1em solid ${chipColors.hidden}`,
    },
});

export default withStyles(styles)(ComplianceRule);
