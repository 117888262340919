import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MultiAddInput from "../../../common/js/components/MultiAddInput";
import CondoDetail from "./CondoDetail";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ErrorBoundary from "../../../common/js/components/ErrorBoundary";

const styles = (theme) => ({
    switchTitle: {
        fontSize: 14,
        fontWeight: 600,
    },
    theSwitch: {
        marginRight: 0,
    },
});
class CondoForm extends React.Component {
    render() {
        const {
            classes,
            data,
            dataPathPrefix,
            disableInputs,
            handleAddArrayElement,
            handleDeleteArrayElement,
            handleUpdateArrayElement,
        } = this.props;

        return (
            <div>
                <Grid container alignItems="center" justify="space-between" spacing={8}>
                    <Grid item xs>
                        <span className={classes.switchTitle}>Condo Information</span>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.condoFlg === true ? true : false}
                                    onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                    color="primary"
                                    value="condoFlg"
                                    disabled={disableInputs}
                                    className={classes.theSwitch}
                                />
                            }
                        />
                    </Grid>
                </Grid>
                {data.condoFlg && (
                    <ErrorBoundary>
                        <MultiAddInput
                            addButtonText="Add Condo"
                            disableInputs={disableInputs}
                            displayFirstWhenEmpty={true}
                            elements={data.condos}
                            onAdd={() => handleAddArrayElement(dataPathPrefix + ".condos")}
                            onUpdate={(e, index) =>
                                handleUpdateArrayElement(dataPathPrefix + ".condos", index, e.target.id, e.target.value)
                            }
                            render={(element, elementIndex) => (
                                <CondoDetail
                                    {...this.props}
                                    element={element}
                                    elementIndex={elementIndex}
                                    onDelete={(index) => handleDeleteArrayElement(dataPathPrefix + ".condos", index)}
                                    disableInputs={disableInputs}
                                />
                            )}
                        />
                    </ErrorBoundary>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(CondoForm);
