import types from "./types";

const addDocument = (file) => {
    return {
        type: types.ADD_DOCUMENT,
        key: file.mediaAssetKey,
        file: file,
        name: file.mediaAssetName,
    };
};

//sort documents
const documentSort = (columnName, sortDirection) => {
    return {
        type: types.SORT_DOCUMENTS,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

//select doc type
const selectDocumentType = (index, docType, newDocTypesUsed, documentDao = null) => {
    return {
        type: types.SELECT_DOC_TYPE,
        name: index,
        docType: docType,
        usedDocTypes: newDocTypesUsed,
        documentType: documentDao,
    };
};

const openDocumentMenu = (key, anchorEl) => {
    return {
        type: types.OPEN_DOC_MENU,
        key: key,
        anchorEl: anchorEl,
    };
};

const closeMenu = () => {
    return {
        type: types.CLOSE_DOC_MENU,
    };
};

const receiveDocuments = (documents, usedDocTypes) => {
    return {
        type: types.RECEIVE_DOCUMENTS,
        savedDocuments: documents,
        usedDocTypes: usedDocTypes,
    };
};

const receiveMissingRequiredDocuments = (documents) => {
    return {
        type: types.RECEIVE_MISSING_REQUIRED_DOCUMENTS,
        missingRequiredDocuments: documents,
    };
};

const receivePropertyDocuments = (documents, usedPropDocTypes) => {
    return {
        type: types.RECEIVE_PROPERTY_DOCUMENTS,
        savedPropDocuments: documents,
        usedPropDocTypes: usedPropDocTypes,
    };
};

const removeDocFromList = (newSavedDocs, newUsedDocTypes) => {
    return {
        type: types.REMOVE_DOCUMENT,
        newSavedDocs: newSavedDocs,
        usedDocTypes: newUsedDocTypes,
    };
};

const stashDocumentUrl = (key, url) => {
    return {
        type: types.STASH_DOCUMENT_URL,
        key: key,
        url: url,
    };
};

const loading = (isLoading) => {
    return {
        type: types.DOCUMENTS_LOADING,
        loading: isLoading,
    };
};

const addDocumentUploading = (loading, fileName) => {
    return {
        type: types.DOCUMENT_UPLOADING,
        fileName: fileName,
        loading: loading,
    };
};

const setUpdatedFlag = (key) => {
    return {
        type: types.SET_DOCUMENT_UPDATED_FLAG,
        key: key,
    };
};

const removeDocumentFromUploadingQueue = (documentsUploading) => {
    return {
        type: types.REMOVE_FROM_UPLOADING_QUEUE,
        documentsUploading: documentsUploading,
    };
};

const approveDocument = (key, approvedBy, approvedByTimestamp) => {
    return {
        type: types.APPROVE_DOCUMENT,
        key: key,
        approvedBy: approvedBy,
        approvedByTimestamp: approvedByTimestamp,
    };
};

const getDocumentRequirementsByParentEntityType = (requirements) => {
    return {
        type: types.GET_DOCUMENT_REQUIREMENTS_BY_PARENT_ENTITY_TYPE,
        requirements,
    };
};

const getParentEntityTypeKey = (parentEntityTypeKey) => {
    return {
        type: types.GET_PARENT_ENTITY_TYPE_KEY,
        parentEntityTypeKey,
    };
};

const setAdminDocumentTypeListAvailable = () => {
    return {
        type: types.SET_ADMIN_DOCUMENT_TYPE_LIST_AVAILABLE,
    };
};

const resetRequiredMissingDocuments = () => {
    return {
        type: types.RESET_REQUIRED_MISSING_DOCUMENTS,
    };
};

export default {
    addDocument,
    documentSort,
    selectDocumentType,
    openDocumentMenu,
    closeMenu,
    receiveDocuments,
    receiveMissingRequiredDocuments,
    removeDocFromList,
    stashDocumentUrl,
    loading,
    addDocumentUploading,
    setUpdatedFlag,
    removeDocumentFromUploadingQueue,
    approveDocument,
    receivePropertyDocuments,
    getDocumentRequirementsByParentEntityType,
    getParentEntityTypeKey,
    setAdminDocumentTypeListAvailable,
    resetRequiredMissingDocuments,
};
