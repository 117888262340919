import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dropzone from "react-dropzone";
import DocumentListRefactor from "./DocumentListRefactor";
import DocumentListViewOnly from "./DocumentListViewOnly";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import get from "lodash/get";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import { acceptedFileTypes } from "../utils/constants";

const styles = {
    documentDropContainer: {
        padding: 0,
    },
    dropzone: {
        borderRadius: "3px",
        border: "2px dashed #dbdbdb",
    },
    dropzoneUnderneath: {
        borderRadius: "3px",
        border: "2px dashed #dbdbdb",
        marginTop: "10px",
    },
    dropzoneComponent: {
        width: "100%",
        "& > input": {
            width: "100%",
        },
        textAlign: "center",
        paddingTop: "64px",
        paddingBottom: "64px",
        minHeight: "50vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    dropzoneComponentUnderneath: {
        width: "100%",
        "& > input": {
            width: "100%",
        },
        textAlign: "center",
        minHeight: "10vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    dropzoneList: {
        display: "inline-block !important",
    },
    dropzoneListElement: {
        textAlign: "left",
    },
    docListHeader: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 22px 0px 22px",
    },
    documentList: {
        backgroundColor: "white",
    },
    icon: {
        width: "70px",
        height: "70px",
        color: "#9E9E9E",
    },
    iconSmall: {
        width: "40px",
        height: "40px",
        color: "#9E9E9E",
    },
    dropzoneSmallTextWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "20px",
    },
};

const headers = [
    {
        name: "Name",
        prop: "documentName",
        type: "plaintext",
        extraHeaderStyles: {
            width: "40%",
        },
    },
    {
        name: "Document Type",
        prop: "documentType",
        type: "plaintext",
        extraHeaderStyles: {
            width: "50%",
        },
    },
    {
        name: "",
        prop: "",
        type: "plaintext",
        extraHeaderStyles: {
            width: "10%",
        },
    },
];

const approvalHeaders = [
    {
        name: "Name",
        prop: "documentName",
        type: "plaintext",
        extraHeaderStyles: {
            width: "40%",
        },
    },
    {
        name: "Document Type",
        prop: "documentType",
        type: "plaintext",
        extraHeaderStyles: {
            width: "30%",
        },
    },
    {
        name: "",
        prop: "",
        type: "plaintext",
        extraHeaderStyles: {
            width: "20%",
        },
    },
    {
        name: "Document Type",
        prop: "documentType",
        type: "plaintext",
        extraHeaderStyles: {
            width: "10%",
        },
    },
];

class DocumentDrop extends React.Component {
    state = {
        anchorEl: null,
        docKey: null,
        adminListReady: false,
        adminListRendered: false,
        missingDocs: [],
    };

    componentDidMount() {
        this.adminDocumentTypeListAvailable();
    }

    componentDidUpdate(prevProps) {
        if (
            !isEqual(this.props.savedDocuments, prevProps.savedDocuments) ||
            !isEqual(this.props.requiredDocs, prevProps.requiredDocs)
        ) {
            this.determineMissingRequiredDocuments();
        }
    }

    componentWillUnmount() {
        this.props.resetRequiredMissingDocuments();
    }

    handleOpenDocumentMenu = (x, anchorEl) => {
        this.setState({ anchorEl: anchorEl });
        this.setState({ docKey: x });
    };

    toggleDeleteDialog = (open, docKey) => {
        if (open) {
            this.handleMenuClose();
            this.setState({ deleteDialog: true, docToDelete: docKey });
        } else {
            this.setState({ deleteDialog: false, docToDelete: null });
        }
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    deleteAndMenuClose = () => {
        this.setState({ anchorEl: null });
        this.props.removeDocument(this.state.docToDelete);
        this.toggleDeleteDialog(false);
    };

    previewAndMenuClose = (docKey, url) => {
        this.setState({ anchorEl: null });
        this.props.viewDocument(docKey, url);
    };

    handleAddDocuments = (accepted, rejected, ownerType) => {
        this.props.addDocuments(this.props.entityKey, this.props.type, accepted, ownerType);
        if (rejected.length > 0) {
            this.props.handleRejectedDocs(rejected);
        }
    };

    handleApproveDocumentAndClose = (docKey) => {
        this.setState({ anchorEl: null });
        this.props.approveDocument(docKey);
    };

    adminDocumentTypeListAvailable = () => {
        if (this.props.parentType && !this.state.adminListRendered) {
            this.setState({
                adminListRendered: true,
            });
            this.props.setAdminDocumentTypeListAvailable();
            this.setState({
                adminListReady: true,
            });
        }
    };

    determineMissingRequiredDocuments = () => {
        const docTypeRequiredList = get(this.props, "requiredDocs", []);

        const fulfilledDocs = docTypeRequiredList
            .map((requiredDocument) => {
                const savedDocumentMatch = find(this.props.savedDocuments, (savedDoc) => {
                    return requiredDocument.documentTypeKey === get(savedDoc, "documentType.documentTypeKey");
                });

                return savedDocumentMatch ? get(savedDocumentMatch, "documentType.documentTypeKey") : null;
            })
            .filter((doc) => doc);

        const missingDocs = docTypeRequiredList.filter((document) => !fulfilledDocs.includes(document.documentTypeKey));
        this.setState({
            missingDocs: missingDocs,
        });
    };

    renderHelperMessage = (type) => {
        return (
            <React.Fragment>
                {type === "comp" && <p>For best results, the Marketing Flyer Photo should be 1224x440px.</p>}
                {type === "voucher" && (
                    <div style={{ fontSize: "13px", marginTop: "10px", fontStyle: "italic" }}>
                        You will be paid fastest if you attach your Commission Payment or Equivalent
                    </div>
                )}
                {type === "property" && (
                    <div style={{ fontSize: "13px", marginTop: "10px", fontStyle: "italic" }}>
                        Preferred specs for Primary Photos:
                        <ul style={{ marginTop: "2px", listStyleType: "none", paddingLeft: "20px" }}>
                            <li>4:3 Aspect Ratio</li>
                            <li>1440 x 1080 Pixels (Resolution)</li>
                        </ul>
                    </div>
                )}
                {this.state.missingDocs.length > 0 && (
                    <div style={{ color: "#E53935", fontSize: "13px", marginTop: "10px", fontStyle: "italic" }}>
                        Missing required documents for submission:
                        <ul style={{ marginTop: "2px", listStyleType: "none", paddingLeft: "20px" }}>
                            {this.state.missingDocs.map((doc) => (
                                <li>{doc["name"]}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </React.Fragment>
        );
    };

    render() {
        const {
            loading,
            classes,
            errors,
            savedDocuments,
            handleDocumentSort,
            documentsUploading,
            disableUploads,
            formDisabled,
            columnToSort,
            sortDirection,
            allDocumentTypes,
            documentTypes,
            usedDocTypes,
            noHeader,
            type,
            updateDocument, // (key, mediaAssetType)
            role,
            viewOnly,
            toggleTerminationDoc,
            terminationDocument,
            downloadView,
            includeSource,
            downloadDocument,
        } = this.props;

        const { anchorEl, docKey } = this.state;
        const open = Boolean(anchorEl);

        return viewOnly ? (
            <DocumentListViewOnly
                {...this.props}
                open={open}
                anchorEl={anchorEl}
                docMenuKey={docKey}
                documentTypes={documentTypes}
                handlePreviewDocument={this.previewAndMenuClose}
                handleDownloadDocument={downloadDocument}
                handleMenuClose={this.handleMenuClose}
                handleOpenDocumentMenu={this.handleOpenDocumentMenu}
                downloadView={downloadView === true}
                includeSource={includeSource}
                type={type}
            />
        ) : (
            <div className={classes.documentDropContainer}>
                {loading ? (
                    <CircularProgress size={20} color="primary" />
                ) : Object.keys(savedDocuments).length === 0 && Object.keys(documentsUploading).length === 0 ? (
                    <React.Fragment>
                        <div className={classes.dropzone}>
                            <Dropzone
                                accept={acceptedFileTypes}
                                disabled={disableUploads}
                                onDrop={(accepted, rejected) => {
                                    this.handleAddDocuments(accepted, rejected, type);
                                }}
                                className={classes.dropzoneComponent}
                            >
                                <div className="dragdrop-instructions">
                                    <CloudUploadIcon className={classes.icon} />
                                    <h4>Drop files on page to attach or browse here</h4>
                                    <p>(.csv, .doc, .docx, .jpg, .msg, .pdf, and .png files only)</p>
                                </div>
                            </Dropzone>
                        </div>

                        {this.renderHelperMessage(type)}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <DocumentListRefactor
                            {...this.props}
                            handleAddDocuments={this.handleAddDocuments}
                            errors={errors}
                            header={
                                type === "user" ? headers : role === "portlandBrokerAdmin" ? approvalHeaders : headers
                            }
                            handleSort={handleDocumentSort}
                            columnToSort={columnToSort}
                            sortDirection={sortDirection}
                            selectDocumentType={updateDocument}
                            allDocumentTypes={allDocumentTypes}
                            documentTypes={documentTypes}
                            usedDocTypes={usedDocTypes}
                            handleOpenDocumentMenu={this.handleOpenDocumentMenu}
                            handleMenuClose={this.handleMenuClose}
                            toggleDeleteDialog={this.toggleDeleteDialog}
                            handlePreviewDocument={this.previewAndMenuClose}
                            anchorEl={anchorEl}
                            open={open}
                            docMenuKey={docKey}
                            savedDocuments={savedDocuments}
                            documentsUploading={documentsUploading}
                            formDisabled={formDisabled}
                            role={role}
                            approveDocument={this.handleApproveDocumentAndClose}
                            docApproved={Boolean(get(savedDocuments, docKey + ".approvedBy", null) != null)}
                            toggleTerminationDoc={toggleTerminationDoc}
                            terminationDocument={terminationDocument}
                            parentEntityType={this.props.parentType}
                            stateCode={this.props.stateCode}
                            adminListReady={this.state.adminListReady}
                            noHeader={noHeader}
                        />

                        <div className={classes.dropzoneUnderneath}>
                            <Dropzone
                                disabled={disableUploads}
                                onDrop={(accepted, rejected) => {
                                    this.handleAddDocuments(accepted, rejected, type);
                                }}
                                className={classes.dropzoneComponentUnderneath}
                            >
                                <div className={classes.dropzoneSmallTextWrapper}>
                                    <CloudUploadIcon className={classes.iconSmall} />
                                    <div style={{ marginLeft: "20px" }}>
                                        <div style={{ fontWeight: "600" }}>
                                            Drop files on page to attach or browse here
                                        </div>
                                        <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                            (.csv, .doc, .docx, .jpg, .msg, .pdf, and .png files only)
                                        </div>
                                    </div>
                                </div>
                            </Dropzone>
                        </div>

                        {this.renderHelperMessage(type)}
                    </React.Fragment>
                )}
                <Dialog open={this.state.deleteDialog} maxWidth="sm" aria-labelledby="confirmation-dialog-title">
                    <DialogTitle style={{ textAlign: "center" }} id="confirmation-dialog-title">
                        Delete Document?
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you want to permanently delete this document? You will not be able to recover it.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleDeleteDialog(false, this.state.docToDelete)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.deleteAndMenuClose()} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(DocumentDrop);
