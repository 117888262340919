import get from "lodash/get";
import utils from "../utils/operations";
import each from "lodash/each";

const viewHeaders = {
    default_headers: [],
    savedSearchesWithSelections: [
        {
            name: "Search Name",
            prop: "searchName",
            type: "plaintext",
        },
        {
            name: "Retain Selections?",
            prop: "saveSelectedOnly",
            type: "plaintext",
        },
        {
            name: "Date Created",
            prop: "savedDate",
            // prop: "dateCreated",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Delete Button",
            prop: "",
            type: "plaintext",
        },
    ],
    savedSearchesNoSelections: [
        {
            name: "Search Name",
            prop: "searchName",
            type: "plaintext",
        },
        {
            name: "Date Created",
            prop: "savedDate",
            // prop: "dateCreated",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Delete Button",
            prop: "",
            type: "plaintext",
        },
    ],
    groups: [
        {
            name: "List Name",
            prop: "groupName",
            type: "plaintext",
        },
        {
            name: "Date Created",
            prop: "savedDate",
            // prop: "dateCreated",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Delete Button",
            prop: "",
            type: "plaintext",
        },
    ],
};

const columnsToQueryFilter = ["searchName", "searchType", "dateCreated"];

const filterByQuery = (data, columnsToQuery, query) => {
    let included = false;
    each(columnsToQuery, (column) => {
        if (get(data, column)) {
            if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                included = true;
            }
        }
    });
    return included;
};

export default {
    viewHeaders,
    columnsToQueryFilter,
    filterByQuery,
};
