import constants from "../constants";
import actions from "./actions";
import utils from "../../utils";
import utilOperations from "../../utils/operations";
import { request } from "../../utils/axios-wrapper";
import { push } from "connected-react-router";
import validation from "./validation";
import each from "lodash/each";

const getDocumentTypeRequirementByState = async (stateCode) => {
    return request({
        url: constants.urls.get_docs_by_state(stateCode),
        method: "get",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            return "error";
        });
};

const getAllParentEntityTypes = async () => {
    return request({
        url: constants.urls.get_all_parent_entities(),
        method: "get",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            return "error";
        });
};

const getAllDocumentTypes = async () => {
    return request({
        url: constants.urls.get_all_document_types(),
        method: "get",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            return "error";
        });
};

const updateDTSRsByState = async (updatedDTSRs) => {
    return request({
        url: constants.urls.get_save_updated_dtsr(),
        method: "put",
        data: updatedDTSRs,
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            utils.actions.snackbar(true, "error", `Couldn't save changes`);
            utilOperations.reportError(error, "admin:operations:handleSaveAccountUpdates Error");
            return "error";
        });
};

const updateDocumentType = (documentTypeKey, updatedDocumentType, shouldExit) => {
    return (dispatch) => {
        let validationResponse = validation.validateRequestNewDocumentType(updatedDocumentType);
        if (validationResponse) {
            each(Object.keys(validationResponse), (e) => {
                console.log(e, validationResponse[e][0]);
                dispatch(actions.addError(e, validationResponse[e][0]));
            });
            return false;
        } else {
            request(
                {
                    url: constants.urls.get_save_document_type(documentTypeKey),
                    method: "put",
                    data: updatedDocumentType,
                },
                dispatch
            )
                .then((response) => {
                    dispatch(utils.actions.snackbar(true, "success", "Document saved."));
                    if (shouldExit) {
                        dispatch(push("/document-type"));
                    }
                })
                .catch((error) => {
                    dispatch(utils.actions.snackbar(true, "error", `Couldn't save changes`));
                    dispatch(utilOperations.reportError(error, "document-type:operations:updateDocumentType Error"));
                    return "error";
                });
        }
    };
};

const deleteDocumentType = (documentTypeId) => {
    return (dispatch) => {
        request(
            {
                method: "delete",
                url: constants.urls.delete_document_type(documentTypeId),
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.snackbar(true, "success", "Document Type deleted"));
                dispatch(push("/document-type"));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", `Could not delete document type. Error: ` + err));
            });
    };
};

const alterStateDocumentRequirements = (payload) => {
    return (dispatch, _) => {
        dispatch(actions.updateStateDocumentRequirements(payload));
    };
};

const handleSort = (columnName) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };
    return (dispatch, getState) => {
        let sortDirection =
            getState().stateDocumentRequirementsReducer.columnToSort === columnName
                ? invertDirection[getState().stateDocumentRequirementsReducer.sortDirection]
                : "asc";
        dispatch(actions.handleSort(columnName, sortDirection));
    };
};

const getDocumentType = async (key) => {
    return request({
        url: constants.urls.get_save_document_type(key),
        method: "get",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return "ERROR";
        });
};

const handleInputChange = (field, value) => {
    return (dispatch) => {
        dispatch(actions.inputChange(field, value));
    };
};

const createNewDocumentType = (newDocumentType) => {
    return (dispatch) => {
        request(
            {
                method: "post",
                url: constants.urls.create_new_document_type(),
                data: newDocumentType,
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.snackbar(true, "success", "New document '" + response.data.name + "' created"));
                dispatch(push("/document-type/"));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", `Could not create new document type.`));
                dispatch(utilOperations.reportError(err, "document-type:operations:createNewDocumentType Error"));
            });
    };
};

export default {
    getDocumentTypeRequirementByState,
    getAllParentEntityTypes,
    getAllDocumentTypes,
    updateDTSRsByState,
    updateDocumentType,
    alterStateDocumentRequirements,
    getDocumentType,
    handleSort,
    deleteDocumentType,
    handleInputChange,
    createNewDocumentType,
};
