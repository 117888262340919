import types from "./types";
import cloneDeep from "lodash/cloneDeep";
import setWith from "lodash/setWith";
import clone from "lodash/clone";
import get from "lodash/get";

export default (
    state = {
        userAccount: {},
        broker: {},
        licenses: {},
        company: {},
        selectedApprovedCompanies: new Map(),
        selectedUnapprovedCompanies: new Map(),
        exhibitAs: [],
        tabs: {
            active_users: [],
            inactive_users: [],
            unapproved_companies: [],
            approved_companies: [],
        },
        userTab: "active_users",
        companyTab: "unapproved_companies",
        userSearchType: "name",
        companySearchType: "BUSINESS",
        userOfficeHistory: [],
        displayDeleteDialog: {
            open: false,
            onSubmit: null,
        },
    },
    action
) => {
    switch (action.type) {
        case types.LOAD_USER_ACCOUNT:
            return Object.assign({}, state, {
                userAccount: action.userAccount,
            });
        case types.LOAD_LICENSES:
            return Object.assign({}, state, {
                licenses: action.licenses,
            });
        case types.LOAD_EXHIBIT_AS:
            return Object.assign({}, state, {
                exhibitAs: action.exhibitAs,
            });
        case types.LOAD_COMPANY:
            return Object.assign({}, state, {
                company: action.company,
            });
        case types.CLEAR_COMPANY:
            return Object.assign({}, state, {
                company: {
                    parentFlg: false,
                    parentCompany: null,
                },
            });
        case types.LOAD_BROKER:
            return Object.assign({}, state, {
                broker: action.broker,
            });
        case types.INPUT_CHANGE:
            return setWith(cloneDeep(state), `${action.field}`, action.value, Object);

        case types.ADD_ARRAY_ELEMENT:
            return setWith(clone(state), `${action.path}[${action.index}]`, {}, clone);

        case types.DELETE_ARRAY_ELEMENT:
            let elements = cloneDeep(get(state, action.path, []));
            elements.splice(action.index, 1);
            const setObj = setWith(clone(state), `${action.path}`, elements, clone);
            return setObj;
        case types.SET_PROPERTY_ARRAY:
            return setWith(clone(state), `${action.pathToArray}`, action.array, Object);

        case types.LOAD_USERS_FOR_TAB:
            return setWith(cloneDeep(state), `tabs[${action.tab}]`, action.users, Object);

        case types.LOAD_COMPANIES_FOR_TAB:
            return setWith(cloneDeep(state), `tabs[${action.tab}]`, action.companies, Object);

        case types.SWITCH_TAB:
            return Object.assign({}, state, {
                userTab: action.tab,
            });
        case types.SWITCH_COMPANY_TAB:
            return Object.assign({}, state, {
                companyTab: action.tab,
            });
        case types.FILTER_USERS:
            return Object.assign({}, state, {
                filterQuery: action.filterQuery,
            });
        case types.FILTER_COMPANIES:
            return Object.assign({}, state, {
                filterQuery: action.filterQuery,
            });
        case types.SORT_USERS:
            return Object.assign({}, state, {
                columnToSort: action.columnToSort,
                sortDirection: action.sortDirection,
            });
        case types.SET_USER_SEARCH_TYPE:
            return Object.assign({}, state, {
                userSearchType: action.searchType,
            });
        case types.SET_COMPANY_SEARCH_TYPE:
            return Object.assign({}, state, {
                companySearchType: action.companySearchType,
            });
        case types.SET_USER_OFFICE_HISTORY:
            return {
                ...state,
                userOfficeHistory: {
                    ...state.userOfficeHistory,
                    [action.userAccountKey]: action.history,
                },
            };
        case types.UPDATE_DELETE_DIALOG:
            return {
                ...state,
                displayDeleteDialog: {
                    ...state.displayDeleteDialog,
                    open: action.open,
                },
            };
        case types.UPDATE_SELECTED_APPROVED_COMPANIES:
            const newState = cloneDeep(state);
            newState.selectedApprovedCompanies.set(action.key, action.newValue);
            return newState;

        case types.UPDATE_SELECTED_UNAPPROVED_COMPANIES:
            const newState2 = cloneDeep(state);
            newState2.selectedUnapprovedCompanies.set(action.key, action.newValue);
            return newState2;

        case types.CLEAR_CHECKBOXES:
            return {
                ...state,
                selectedApprovedCompanies: new Map(),
                selectedUnapprovedCompanies: new Map(),
            };
        default:
            return state;
    }
};
