import actions from "./actions";
import searchActions from "../../search/ducks/actions";
import searchOperations from "../../search/ducks/operations";
import { urls } from "../../audit/constants";
import { request } from "../../utils/axios-wrapper";
import utils from "../../utils/index";

const clearSearchResults = () => {
    return (dispatch) => {
        let comps = {};
        dispatch(actions.receiveComps(comps));
        dispatch(actions.countComps(0));
    };
};

const searchComps = () => {
    return (dispatch) => {
        dispatch(clearAllComps());
        dispatch(searchOperations.storeSearchCleared(false, "AUDIT"));
        dispatch(searchActions.loadingSearch(true));
        dispatch(searchOperations.storeSearchRun(true, "AUDIT"));
        dispatch(searchOperations.executeSearch("AUDIT", loadSearchResults));
    };
};

const clearAllComps = () => {
    return (dispatch) => {
        dispatch(searchActions.clearSearchResponse("AUDIT"));
        dispatch(actions.clearAllComps());
    };
};

const loadSearchResults = () => {
    return (dispatch, getState) => {
        const comps = getState().searchReducer.AUDIT.searchResponse.results;
        const approvalListDealKeys = {};
        comps.forEach((comp) => {
            approvalListDealKeys[comp.dealkey] = false;
        });
        dispatch(actions.receiveComps(getState().searchReducer.AUDIT.searchResponse.results));
        dispatch(actions.countComps(getState().searchReducer.AUDIT.searchResponse.totalResultCount));
    };
};

const handleDragEnd = (dragResult, comps) => {
    const { destination, source } = dragResult;
    if (!destination) {
        return (dispatch) => {
            dispatch(actions.updateCompOrder(comps));
        };
    }
    //editor says this will never get hit - but it does
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return (dispatch, getState) => {
            dispatch(actions.updateCompOrder(comps));
        };
    }

    const updatedComps = reorder(comps, dragResult.source.index, dragResult.destination.index);

    return (dispatch, getState) => {
        dispatch(actions.updateCompOrder(updatedComps));
    };
};

const reorder = (data, startIndex, endIndex) => {
    const result = [...data];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const updateCompsForSort = (sortedData) => {
    return (dispatch) => {
        dispatch(actions.updateCompOrder(sortedData));
    };
};

const updateAuditorListForSort = (sortedData) => {
    return (dispatch) => {
        dispatch(actions.updateAuditorListOrder(sortedData));
    };
};

const handleChangeTabs = (tab) => {
    return (dispatch) => {
        dispatch(actions.changeTabs(tab));
    };
};

const handleChangeHeaders = (tab) => {
    return (dispatch) => {
        dispatch(actions.changeHeaders(tab));
    };
};

const toggleCompAuditFlag = (dealKey) => {
    return (dispatch) => {
        dispatch(actions.toggleCompAuditFlag(dealKey));
    };
};

const toggleAllCompsAuditFlags = (toggle) => {
    return (dispatch) => {
        dispatch(actions.toggleAllCompsAuditFlags(toggle));
    };
};

const transformBatchDate = (comps) => {
    comps.data.forEach((comp) => {
        if (comp.batchDate) {
            comp.batchDate = new Date(comp.batchDate);
        }
    });
};

const loadApprovedComps = () => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "load_approved_comps"));
        dispatch(utils.actions.loading(true));
        request(
            {
                url: urls.get_approved_audit_list(),
                method: "get",
            },
            dispatch
        )
            .then((response) => {
                transformBatchDate(response);
                dispatch(actions.receiveApprovedComps(response.data));
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, "load_approved_comps"));
            })
            .catch((err) => {
                console.log("ERROR:", err);
                dispatch(utils.actions.snackbar(true, "error", "Unable to load list. Please try again."));
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.somethingSaving(false, "load_approved_comps"));
            });
    };
};

const updateDealAuditFlags = (deals, auditFlag) => {
    return (dispatch, getState) => {
        let dealKeys = [];
        if (deals.length !== undefined && deals.length !== null) {
            deals.forEach((comp) => {
                let auditFlagAlias = comp.auditFlag !== undefined ? comp.auditFlag : comp.audit_flg;
                let dealKeyAlias = comp.dealKey !== undefined ? comp.dealKey : comp.dealkey;
                if (auditFlagAlias === auditFlag) {
                    dealKeys.push(dealKeyAlias);
                }
            });
        }
        if (dealKeys.length < 1) {
            dispatch(utils.actions.snackbar(true, "error", "Nothing selected."));
        } else {
            dispatch(utils.actions.somethingSaving(true, "update_deal_audit_flags"));
            dispatch(utils.actions.loading(true));
            request(
                {
                    url: urls.update_deals_audit_flags(),
                    method: "put",
                    crossDomain: true,
                    params: { auditFlag },
                    data: dealKeys,
                    headers: { "Content-Type": "application/json" },
                },
                dispatch
            )
                .then((response) => {
                    if (auditFlag) {
                        dispatch(utils.actions.snackbar(true, "success", "Successfully added to Auditor List"));
                    } else {
                        dispatch(utils.actions.snackbar(true, "success", "Successfully removed from Auditor List"));
                    }
                    transformBatchDate(response);
                    dispatch(actions.receiveApprovedComps(response.data));
                    dispatch(utils.actions.loading(false));
                    dispatch(utils.actions.somethingSaving(false, "update_deal_audit_flags"));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(utils.actions.snackbar(true, "error", "Action unsuccessful. Please try again."));
                    dispatch(utils.actions.loading(false));
                    dispatch(utils.actions.somethingSaving(false, "update_deal_audit_flags"));
                });
        }
    };
};

const toggleAuditorListAuditFlag = (dealKey) => {
    return (dispatch, getState) => {
        dispatch(actions.toggleAuditorListAuditFlag(dealKey));
    };
};

const toggleAllAuditorListAuditFlag = (isChecked) => {
    return (dispatch, getState) => {
        dispatch(actions.toggleAllAuditorListCheckbox(isChecked));
        dispatch(actions.toggleAllAuditorListAuditFlag(isChecked));
    };
};

export default {
    clearSearchResults,
    searchComps,
    clearAllComps,
    handleDragEnd,
    updateCompsForSort,
    handleChangeTabs,
    toggleCompAuditFlag,
    handleChangeHeaders,
    toggleAllCompsAuditFlags,
    loadApprovedComps,
    updateDealAuditFlags,
    toggleAuditorListAuditFlag,
    toggleAllAuditorListAuditFlag,
    updateAuditorListForSort,
};
