import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Search from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import { get, debounce, startCase, toLower } from "lodash";
import isEmpty from "lodash/isEmpty";
import AutocompleteFreeform from "../../../common/js/components/AutocompleteFreeform";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { us_states_options } from "../utils/constants";
import searchConstants from "../search/constants";
import { representation_type_options } from "./constants";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = (theme) => ({
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 70,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    margin: {
        margin: 0,
        marginBottom: "10px",
    },
    cssFocused: {},
    cssUnderline: {
        "&:after": {},
    },
    bootstrapRoot: {
        "label + &": {
            marginTop: theme.spacing.unit * 3,
        },
    },
    bootstrapInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
    bootstrapFormLabel: {
        fontSize: 18,
    },
    clearLink: {
        textTransform: "none",
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
        },
    },
    company: {
        height: "99px",
    },
});

class OutsideSplitModal extends React.Component {
    handleChange = (event) => {
        this.props.updateSplit(this.props.splitId, event.target.id, event.target.value, "outsideSplitModal");
    };

    clearCompany = () => {
        this.props.updateSplit(this.props.splitId, "companyKey", "", "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "companyName", "", "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "companyAddress", "", "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "companyAddressKey", "", "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "companyCity", "", "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "companyCityKey", "", "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "companyState", "", "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "companyZip", "", "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "companyZipKey", "", "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "freeFormValue", "", "outsideSplitModal");
    };

    companyInputChange = (event) => {
        this.props.updateSplit(this.props.splitId, event.target.id, event.target.value, "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "companyTypes", ["INDIVIDUAL"], "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "approvedFlg", false, "outsideSplitModal");
        this.props.updateSplit(this.props.splitId, "companyKey", null, "outsideSplitModal");
    };

    handleCompanyChange = (company, splitKey, source) => {
        this.props.updateSplit(splitKey, "companyName", get(company, "displayName"), source);
        this.props.updateSplit(splitKey, "companyAddress", get(company, "mainAddress.address1", ""), source);
        this.props.updateSplit(splitKey, "companyAddressKey", get(company, "mainAddress.key", ""), source);
        this.props.updateSplit(splitKey, "companyCityKey", get(company, "mainAddress.city.key", ""), source);
        this.props.updateSplit(splitKey, "companyCity", get(company, "mainAddress.city.city", ""), source);
        this.props.updateSplit(splitKey, "companyState", get(company, "mainAddress.city.state.stateCode", ""), source);
        this.props.updateSplit(splitKey, "companyZip", get(company, "mainAddress.zipCode.zipCode", ""), source);
        this.props.updateSplit(splitKey, "companyZipKey", get(company, "mainAddress.zipCode.key", ""), source);
        this.props.updateSplit(splitKey, "companyKey", get(company, "companyKey", ""), source);
        this.props.updateSplit(splitKey, "approvedFlg", get(company, "approvedFlg", ""), source);
        this.props.updateSplit(splitKey, "companyTypes", get(company, "companyTypes", ""), source);
        this.props.updateSplit(splitKey, "freeFormValue", get(company, "displayName", ""), source);
        this.props.handleReducerLevelInput(
            `outsideSplitModal.brokerCommissions.${splitKey}.company.companyKey`,
            get(company, "companyKey"),
            source
        );
    };

    handleSaveOutsideSplit = (splitId, values) => {
        this.props.saveOutsideSplit(splitId, {
            ...values,
            commissionType: this.props.commissionType,
        });
    };

    handleUpdateCommissionComment = (event, splitId) => {
        const commissionComment = {
            ...this.props.values.commissionComment,
            comment: event.target.value,
            commentOwnerType: "BROKER_COMMISSION",
        };
        this.props.updateSplit(splitId, "commissionComment", commissionComment, "outsideSplitModal");
    };

    freeformUpdateCompany = (splitKey, value, source) => {
        if (get(this.props.values, `companyKey`, null)) {
            this.clearCompany();
            this.props.updateSplit(splitKey, "companyName", value, source);
        }
        this.props.updateSplit(splitKey, "companyName", value, source);
        this.props.updateSplit(splitKey, "companyTypes", ["REAL_ESTATE"], source);
        this.props.updateSplit(splitKey, "approvedFlg", false, source);
    };

    freeformGetCompanyAddress = (suggestion) => {
        let result = "";
        const address = get(suggestion, "mainAddress.address1", "");
        const city = get(suggestion, "mainAddress.city.city", "");
        const state = get(suggestion, "mainAddress.city.state.stateCode", "");
        const zipCode = get(suggestion, "mainAddress.zipCode.zipCode", "");

        if (address && city && state && zipCode) result = [address, city, state, zipCode].join(", ");

        return result;
    };

    handleChangePreserveExistingRecord = (event, id, source) => {
        this.handleChange(event);
        this.props.updateSplit(this.props.splitId, id, "", source);
        this.props.updateSplit(this.props.splitId, "companyKey", "", source);
        this.props.updateSplit(this.props.splitId, "companyAddressKey", "", source);
        this.props.updateSplit(this.props.splitId, "approvedFlg", false, source);
    };

    debounceSearchCompany = debounce((handleSearchCompany, term) => {
        handleSearchCompany(term, "REAL_ESTATE");
    }, searchConstants["DEBOUNCE_SEARCH_DELAY"]);

    render() {
        const {
            open,
            values,
            errors,
            splitId,
            classes,
            handleSearchOutsideBroker,
            handleSearchCompany,
            secondaryAction,
            toggleOutsideSplitModal,
            handleDeleteOutsideSplit,
            handleBrokerPersonChange,
            autocompleteResults,
            freeformUpdate,
            isFreeformSearchEnabled,
            commissionType,
        } = this.props;

        const headerText = commissionType === "REFERRAL" ? "OUTSIDE REFERRAL" : commissionType;

        return (
            <Modal
                aria-labelledby="outside-split-modal"
                aria-describedby="add-outside-split"
                open={open}
                onClose={toggleOutsideSplitModal}
            >
                <div style={getModalStyle()} className={classes.paper}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={6}>
                            <h4 className="modal-title">Allocation for {startCase(toLower(headerText))}</h4>
                        </Grid>
                        <Grid item>
                            {secondaryAction === "Delete" && (
                                <Button onClick={() => handleDeleteOutsideSplit(splitId)}>Delete this split</Button>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <AutocompleteFreeform
                                id="firstName"
                                label={commissionType === "REFERRAL" ? "Outside Referrer" : "Outside Broker"}
                                error={get(errors, `firstName`, null)}
                                initialInputValue={`${get(values, `firstName`, "")}${
                                    get(values, `firstName`, null) ? " " : ""
                                }${get(values, `lastName`, "")}`}
                                itemToString={(i) => get(i, `firstName`, null)}
                                onSelect={(p, b) => handleBrokerPersonChange(p, splitId, "outsideSplitModal")}
                                placeholder={"Enter name"}
                                searchFunction={handleSearchOutsideBroker}
                                startAdornment={<Search />}
                                suggestions={autocompleteResults}
                                disabled={false}
                                freeformUpdate={(value) => freeformUpdate(splitId, value, "outsideSplitModal")}
                                options={{
                                    key: "brokerAgentKey",
                                    display: (suggestion) => {
                                        return get(suggestion, "firstName", "") + " " + get(suggestion, "lastName", "");
                                    },
                                }}
                                errorText="Required"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <BSSelect
                                id="representationType"
                                label="Representation"
                                value={values.representationType}
                                error={get(errors, `representationType`, null)}
                                errorText="Required"
                                onChange={this.handleChange}
                                placeholder="Representation"
                                options={representation_type_options}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <BSInput
                                id="netPercent"
                                value={values.netPercent}
                                error={get(errors, `netPercent`, null)}
                                errorText="Required"
                                onChange={this.handleChange}
                                startAdornment="%"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <BSInput
                                id="netAmount"
                                value={values.netAmount}
                                error={get(errors, `netAmount`, null)}
                                errorText="Required"
                                onChange={this.handleChange}
                                startAdornment="$"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} className={classes.company}>
                        {/* Requirement 6.3.19 - Leave all fields editable (in case address is not populated when a company is selected)
                    All outside splits must be submitted with company and address, so all fields in modal must be filled out */}

                        <Grid item xs={12}>
                            {isFreeformSearchEnabled ? (
                                <AutocompleteFreeform
                                    id="companyName"
                                    label={"Company Name"}
                                    error={get(errors, `companyName`, null)}
                                    errorText="Required"
                                    initialInputValue={get(values, `companyName`, "")}
                                    itemToString={(i) => get(i, `companyName`, null)}
                                    onSelect={(p, b) => this.handleCompanyChange(p, splitId, "outsideSplitModal")}
                                    placeholder="Enter company"
                                    searchFunction={(input) => this.debounceSearchCompany(handleSearchCompany, input)}
                                    startAdornment={<Search />}
                                    suggestions={autocompleteResults}
                                    disabled={!isEmpty(get(values, `companyKey`, null))}
                                    freeformUpdate={(value) =>
                                        this.freeformUpdateCompany(splitId, value, "outsideSplitModal")
                                    }
                                    options={{
                                        key: "companyKey",
                                        display: (suggestion) => {
                                            return get(suggestion, "displayName", "");
                                        },
                                        displaySub: (suggestion) => {
                                            return this.freeformGetCompanyAddress(suggestion);
                                        },
                                    }}
                                />
                            ) : (
                                <BSInput
                                    id="companyName"
                                    label="Company Name"
                                    value={values.companyName}
                                    error={get(errors, `companyName`, null)}
                                    errorText="Required"
                                    onChange={this.companyInputChange}
                                    placeholder="Enter company"
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <BSInput
                                id="companyAddress"
                                label="Company Address"
                                value={get(values, "companyAddress", "")}
                                error={get(errors, `companyAddress`, null)}
                                errorText="Required"
                                onChange={(event) =>
                                    this.handleChangePreserveExistingRecord(
                                        event,
                                        "companyAddressKey",
                                        "outsideSplitModal"
                                    )
                                }
                                placeholder="Enter address"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <BSInput
                                id="companyCity"
                                label="City"
                                value={values.companyCity}
                                error={get(errors, `companyCity`, null)}
                                errorText="Required"
                                onChange={(event) =>
                                    this.handleChangePreserveExistingRecord(
                                        event,
                                        "companyCityKey",
                                        "outsideSplitModal"
                                    )
                                }
                                placeholder="Enter city"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <BSSelect
                                id="companyState"
                                label="State"
                                value={values.companyState}
                                error={get(errors, `companyState`, null)}
                                errorText="Required"
                                onChange={(event) =>
                                    this.handleChangePreserveExistingRecord(
                                        event,
                                        "companyCityKey",
                                        "outsideSplitModal"
                                    )
                                }
                                placeholder="Select"
                                options={us_states_options}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <BSInput
                                id="companyZip"
                                label="Zip"
                                value={values.companyZip}
                                error={get(errors, `companyZip`, null)}
                                errorText="Required (ex. 98101)"
                                onChange={(event) =>
                                    this.handleChangePreserveExistingRecord(event, "companyZipKey", "outsideSplitModal")
                                }
                                placeholder="Enter zip code"
                            />
                        </Grid>
                    </Grid>
                    {commissionType === "REFERRAL" && (
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="commissionComment"
                                    placeholder="Enter referral explanation"
                                    label="COMMENT"
                                    toolTipText="Please enter an explanation for why this referral<br/>should be paid."
                                    rows={5}
                                    onChange={(event) => this.handleUpdateCommissionComment(event, splitId)}
                                    error={get(errors, "commissionComment", null)}
                                    value={get(values, "commissionComment.comment", null)}
                                    errorText="Required"
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container justify="flex-end">
                        <Button onClick={toggleOutsideSplitModal} variant="outlined" color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => this.handleSaveOutsideSplit(splitId, values)}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                    </Grid>
                </div>
            </Modal>
        );
    }
}

OutsideSplitModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const OutsideSplitModalWrapped = withStyles(styles)(OutsideSplitModal);

export default OutsideSplitModalWrapped;
