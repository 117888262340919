import types from "./types";
import cloneDeep from "lodash/cloneDeep";
import setWith from "lodash/setWith";
import get from "lodash/get";
import clone from "lodash/clone";

export default (
    state = {
        deal: {},
        creator: {},
        searchResults: [],
        classes: {},
        installments: {},
        totalConsideration: {
            escalationRateType: "%",
        },
        companyInformation: {
            BUYER_TENANT: {},
            SELLER_LANDLORD: {},
            OUTSIDE_BROKER: {},
        },
        invoice: {},
        executeInvoiceModalOpen: false,
        snackbar: {
            open: false,
        },
        totalConsiderationCalculation: {},
        dealSummary: {
            vouchers: [],
            brokerCommissions: [],
        },
        propertyAttributes: {},
        terminateDialog: {
            open: false,
        },
        confirmTerminateDialog: {
            open: false,
        },
        terminationDocument: {
            upload: false,
        },
    },
    action
) => {
    switch (action.type) {
        case types.GET_DEAL_REFACTOR:
            console.log(action.deal);
            return {
                ...state,
                deal: action.deal,
            };
        case types.GET_DEAL:
            return Object.assign({}, state, {
                payload: action.payload,
                status: action.status,
                deal: {
                    ...state.deal,
                    ...action.deal,
                    ...action.payload,
                },
                installments: {
                    ...action.installments,
                },
                totalConsideration: {
                    ...action.totalConsideration,
                },
                invoice: {
                    ...action.invoice,
                },
                propertyAttributes: {
                    ...action.propertyAttributes,
                },
                companyInformation: {
                    ...action.companyInformation,
                },
            });
        case types.SET_DEAL_ID:
            return {
                ...state,
                deal: {
                    ...state.deal,
                    dealKey: action.deal,
                },
            };
        case types.STATE_OBJECT_PROPERTY_CHANGE:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    [action.field]: action.value,
                },
            };
        case types.DEAL_CHANGE:
            return {
                ...state,
                deal: {
                    ...state.deal,
                    [action.field]: action.value,
                },
            };
        case types.SET_FLAG:
            return {
                ...state,
                [action.field]: action.value,
            };
        case types.ADD_INSTALLMENT:
            return {
                ...state,
                installments: {
                    ...state.installments,
                    [action.key]: action.installment[action.key],
                },
            };
        case types.UPDATE_INSTALLMENT:
            return {
                ...state,
                installments: {
                    ...state.installments,
                    [action.key]: {
                        ...state.installments[action.key],
                        [action.field]: action.value,
                    },
                },
            };
        case types.DELETE_INSTALLMENT:
            let installments = cloneDeep(state.installments);
            delete installments[action.key];
            return {
                ...state,
                installments: {
                    ...installments,
                },
            };
        case types.UPDATE_COMPANY_PROPERTY:
            console.log("key=" + action.key + ",field=" + action.field + ",value=" + action.value);
            return {
                ...state,
                companyInformation: {
                    ...state.companyInformation,
                    [action.key]: {
                        ...state.companyInformation[action.key],
                        [action.field]: action.value,
                    },
                },
            };
        case types.UPDATE_CONTACT_INFO_COMPANY:
            return {
                ...state,
                contactInformation: {
                    ...state.contactInformation,
                    [action.key]: {
                        ...state.contactInformation[action.key],
                        company: {
                            ...state.contactInformation[action.key]["company"],
                            [action.field]: action.value,
                        },
                    },
                },
            };
        case types.UPDATE_TOTAL_CONSIDERATION_CALCULATION:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    ...action.invoice,
                },
            };
        case types.CLEAR_TOTAL_CONSIDERATION_CALCULATION:
            return {
                ...state,
                invoice: {},
            };
        case types.TOTAL_CONSIDERATION_CHANGE:
            return {
                ...state,
                totalConsideration: {
                    ...state.totalConsideration,
                    [action.field]: action.value,
                },
            };
        case types.PERIOD_MONTH_CHANGE:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    periods: {
                        ...state.invoice["periods"],
                        [action.period]: {
                            ...state.invoice["periods"][action.period],
                            months: {
                                ...state.invoice["periods"][action.period]["months"],
                                [action.month]: {
                                    ...state.invoice["periods"][action.period]["months"][action.month],
                                    [action.field]: action.value,
                                },
                            },
                        },
                    },
                },
            };
        case types.PERIOD_CHANGE:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    periods: {
                        ...state.invoice["periods"],
                        [action.period]: {
                            ...state.invoice["periods"][action.period],
                            [action.field]: action.value,
                        },
                    },
                },
            };
        case types.UPDATE_PERIOD_SUMMARY:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    periods: {
                        ...state.invoice["periods"],
                        [action.period]: {
                            ...state.invoice["periods"][action.period],
                            summary: action.summary,
                        },
                    },
                },
            };
        case types.UPDATE_TOTAL_CONSIDERATION_SUMMARY:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    summary: action.summary,
                },
            };
        case types.ADD_DEAL_ERROR:
            return setWith(clone(state), `errors.${action.field}`, action.error, clone);
        case types.TOTAL_CONSIDERATION_INPUT_UPDATE:
            return {
                ...state,
                totalConsideration: {
                    ...state.totalConsideration,
                    ...action.updates,
                },
            };
        case types.UPDATE_PROPERTY_INFO:
            return {
                ...state,
                propertyAttributes: {
                    ...state.propertyAttributes,
                    [action.field]: action.value,
                },
            };
        case types.SEARCH_RESULTS:
            return {
                ...state,
                searchResults: action.results,
            };
        case types.DEAL_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    open: action.open,
                    message: action.message,
                    variant: action.variant,
                },
            };
        case types.TOGGLE_BOOLEAN_PROPERTY:
            return {
                ...state,
                [action.field]: !state[action.field],
            };
        case types.CLEAR_DEAL_ERRORS:
            return {
                ...state,
                errors: {},
            };
        case types.GET_DEAL_SUMMARY:
            return {
                ...state,
                dealSummary: action.deal,
            };
        case types.GET_DEAL_SUMMARY_VOUCHERS:
            return {
                ...state,
                dealSummary: {
                    ...state.dealSummary,
                    vouchers: action.vouchers,
                },
            };
        case types.GET_DEAL_CREATOR:
            return {
                ...state,
                creator: action.creator,
            };
        case types.BROKER_COMMISSION_MY_AMOUNT:
            return {
                ...state,
                dealSummary: {
                    ...state.dealSummary,
                    myAmounts: {
                        ...state.dealSummary.myAmounts,
                        [action.voucherKey]: {
                            myAmount: action.myAmount,
                        },
                    },
                },
            };
        case types.SET_DEAL_SUMMARY_BROKER_COMMISSIONS:
            return {
                ...state,
                dealSummary: {
                    ...state.dealSummary,
                    brokerCommissions: action.brokerCommissions,
                },
            };
        case types.SORT_DEAL_SUMMARY_VOUCHERS:
            return {
                ...state,
                dealSummary: {
                    ...state.dealSummary,
                    columnToSort: action.columnToSort,
                    sortDirection: action.sortDirection,
                },
            };
        case types.GET_DEAL_SUMMARY_LISTING_AGENT:
            return {
                ...state,
                dealSummary: {
                    ...state.dealSummary,
                    listing: {
                        ...state.dealSummary.listing,
                        broker: action.broker,
                    },
                },
            };
        case types.TOGGLE_TERMINATE_DIALOG:
            return {
                ...state,
                terminateDialog: {
                    ...state.terminateDialog,
                    open: action.open,
                },
            };
        case types.TOGGLE_CONFIRM_TERMINATE_DIALOG:
            return {
                ...state,
                confirmTerminateDialog: {
                    ...state.confirmTerminateDialog,
                    open: action.open,
                },
            };
        case types.TERMINATION_DOCUMENT_UPLOADED:
            return {
                ...state,
                terminationDocument: {
                    ...state.terminationDocument,
                    upload: action.upload,
                },
            };
        case types.UPDATE_COMP_VALUE:
            return setWith(cloneDeep(state), `${action.path}`, action.value, Object);
        case types.SET_COMP_ARRAY:
            return setWith(cloneDeep(state), `${action.pathToArray}`, action.array, Object);
        case types.DELETE_COMP_ARRAY_ELEMENT:
            let elements = cloneDeep(get(state, action.path, []));
            elements.splice(action.index, 1);
            return setWith(cloneDeep(state), `${action.path}`, elements, clone);
        case types.CLEAR_TOTAL_CONSIDERATION:
            return {
                ...state,
                totalConsideration: {
                    escalationRateType: "%",
                },
            };
        case types.LOAD_DEAL_DOCUMENT_LIST:
            return {
                ...state,
                documentList: {
                    ...state.terminationDocument,
                    upload: action.upload,
                },
            };
        default:
            return state;
    }
};
