import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import get from "lodash/get";

const styles = (theme) => ({
    root: {},
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    leftIcon: {
        marginLeft: "25px",
    },
    progress: {
        paddingRight: "100px",
        paddingLeft: "100px",
    },
    inActiveText: {
        fontSize: "14px",
        paddingRight: "25px",
    },
});

const AdminToolbar = (props) => {
    const { classes, handleSaveAccountUpdates, userAccount, handleCancel, handleInputChange } = props;

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
            <Grid item>
                <h2 className="page-title">Edit User Account</h2>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" spacing={0}>
                    <Grid item>
                        {get(userAccount, "activeFlg", null) === false &&
                        get(userAccount, "inactiveDate", null) != null ? (
                            <div className={classes.inActiveText}>
                                Inactivated On: {get(userAccount, "inactiveDate", null)}
                            </div>
                        ) : (
                            ""
                        )}
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={userAccount.activeFlg ? true : false}
                                    onChange={(e) => handleInputChange("userAccount.activeFlg", e.target.checked)}
                                    color="primary"
                                    disabled={false}
                                />
                            }
                            label="Active"
                        />
                    </Grid>
                    <Grid item className={classes.leftIcon}>
                        <Button variant="outlined" color="primary" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={handleSaveAccountUpdates}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(AdminToolbar);
