import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";

function renderInput(inputProps) {
    const {
        classes,
        endAdornment,
        error,
        InputProps,
        label,
        ref,
        startAdornment,
        errorText,
        helperText,
        extraFormControlStyles,
    } = inputProps;

    return (
        <FormControl style={{ width: "100%", ...extraFormControlStyles }}>
            {label && (
                <InputLabel shrink className={classes.bootstrapFormLabel}>
                    {label}
                </InputLabel>
            )}
            <Input
                inputRef={ref}
                disableUnderline={true}
                autoComplete={"no"}
                className={classes.bootstrapInput}
                style={error ? { borderColor: "red" } : {}}
                endAdornment={endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>}
                startAdornment={startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>}
                {...InputProps}
            />
            {error ? (
                <FormHelperText classes={classes} error={true}>
                    {errorText}
                </FormHelperText>
            ) : (
                <FormHelperText classes={classes} required={true}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}

function renderSuggestion({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
    key,
    display,
    displaySub,
    displayType,
    classes,
}) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem ? (String(selectedItem[key]) || "").indexOf(suggestion[key]) > -1 : false;

    return (
        <MenuItem
            {...itemProps}
            key={key}
            selected={isHighlighted}
            component="div"
            className="waterloo"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            <div className={classes.menuItem}>
                <span className={classes.menuItemTitle}>{display(suggestion)}</span>
                <br />
                <span className={classes.menuItemSubtext}>{displaySub && displaySub(suggestion)}</span>
            </div>
            <div className={classes.resultType}>{displayType && <div>{displayType(suggestion)} Display Type</div>}</div>
        </MenuItem>
    );
}
renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.string,
    suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

const styles = (theme) => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #E0E0E0",
        fontSize: 14,
        padding: "4px", // the others are 4px 6px, but this makes room for addorment when next to a "shiftLeft" selects
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
    menuItemTitle: {
        fontSize: "14px",
        margin: 0,
        padding: 0,
    },
    menuItemSubtext: {
        fontSize: "12px",
        margin: 0,
        padding: 0,
    },
    menuItem: {
        width: "100%",
        lineHeight: "1em",
    },
    resultType: {
        float: "right",
        textTransform: "uppercase",
        fontSize: "13px",
        fontWeight: 600,
        color: "#9E9E9E",
    },
    paper: {
        position: "absolute",
        zIndex: 1,
        left: 0,
        right: 0,
        top: "61px",
        maxHeight: "210px",
        overflowY: "auto",
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
    container: {
        flexGrow: 1,
        position: "relative",
    },
    error: {
        color: "red",
        fontStyle: "italic",
    },
    required: {
        color: "dimgrey",
        fontStyle: "italic",
    },
});

class Autocomplete extends React.Component {
    render() {
        const {
            id,
            error,
            label,
            options,
            classes,
            onSelect,
            disabled,
            placeholder,
            suggestions,
            itemToString,
            endAdornment,
            freeFormValue,
            searchFunction,
            startAdornment,
            initialInputValue,
            errorText,
            helperText,
            extraFormControlStyles,
        } = this.props;

        return (
            <div className={classes.root}>
                <Downshift
                    id={id}
                    onSelect={onSelect}
                    itemToString={itemToString}
                    onInputValueChange={searchFunction}
                    initialInputValue={initialInputValue}
                >
                    {({
                        isOpen,
                        inputValue,
                        getItemProps,
                        getMenuProps,
                        selectedItem,
                        getInputProps,
                        highlightedIndex,
                    }) => {
                        return (
                            <div className={classes.container}>
                                {renderInput({
                                    fullWidth: true,
                                    classes,
                                    extraFormControlStyles: extraFormControlStyles,
                                    endAdornment: endAdornment,
                                    startAdornment: startAdornment,
                                    errorText: errorText,
                                    helperText: helperText,
                                    id: id,
                                    label: label,
                                    error: error,
                                    InputProps: getInputProps({
                                        placeholder: placeholder,
                                        value: isOpen ? inputValue : initialInputValue,
                                        disabled: disabled,
                                    }),
                                })}
                                <div {...getMenuProps()}>
                                    {isOpen ? (
                                        <Paper className={classes.paper} square>
                                            {suggestions &&
                                                suggestions.map((suggestion, index) =>
                                                    renderSuggestion({
                                                        suggestion,
                                                        index,
                                                        itemProps: getItemProps({ item: suggestion }),
                                                        highlightedIndex,
                                                        selectedItem: !freeFormValue ? null : selectedItem,
                                                        key: suggestion[options.key],
                                                        display: options.display,
                                                        displaySub: options.displaySub,
                                                        displayType: options.displayType,
                                                        classes: classes,
                                                    })
                                                )}
                                        </Paper>
                                    ) : null}
                                </div>
                            </div>
                        );
                    }}
                </Downshift>
            </div>
        );
    }
}

Autocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Autocomplete);
