import React from "react";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { us_states_options } from "../utils/constants";
import { user_role_type_display_name } from "../auth/constants";
import get from "lodash/get";
import MultiAddInput from "../../../common/js/components/MultiAddInput";
import Proxy from "./Proxy";
import { keyValueOptionsBuilder } from "../utils/operations";

class UserAccountDetail extends React.Component {
    render() {
        const {
            userAccount,
            errors,
            disableInputs,
            handleInputChange,
            handleAddArrayElement,
            handleDeleteArrayElement,
            handleUpdateArrayElement,
            handleSearchUsers,
        } = this.props;

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="firstName"
                            label="FIRST NAME"
                            value={get(userAccount, "person.firstName", null)}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="lastName"
                            label="LAST NAME"
                            value={get(userAccount, "person.lastName", null)}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput id="email" label="EMAIL" value={get(userAccount, "email", null)} disabled={true} />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="department"
                            label="DEPARTMENT"
                            value={get(userAccount, "department", null)}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput id="title" label="TITLE" value={get(userAccount, "title", null)} disabled={true} />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="speciality"
                            label="SPECIALITY"
                            value={get(userAccount, "speciality", null)}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="primaryPhoneNumber"
                            label="DIRECT PHONE"
                            value={get(userAccount, "primaryPhoneNumber", null)}
                            disabled={true}
                            phone={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="secondaryPhoneNumber"
                            label="MOBILE PHONE"
                            value={get(userAccount, "secondaryPhoneNumber", null)}
                            disabled={true}
                            phone={true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="address"
                            label="ADDRESS"
                            value={get(userAccount, "person.address.address1", null)}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={4}>
                        <BSInput
                            id="city"
                            label="CITY"
                            value={get(userAccount, "person.address.city.city", null)}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSSelect
                            id="stateCode"
                            label="STATE"
                            value={get(userAccount, "person.address.city.state.stateCode", null)}
                            options={us_states_options}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSInput
                            id="zipCode"
                            label="ZIP CODE"
                            value={get(userAccount, "person.address.zipCode.zipCode", null)}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="office"
                            label="OFFICE"
                            value={get(userAccount, "office.officeName", null)}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSSelect
                            id="role"
                            label="USER TYPE"
                            value={get(userAccount, "role", null)}
                            error={errors.role}
                            options={keyValueOptionsBuilder(user_role_type_display_name)}
                            onChange={(e) => handleInputChange("userAccount.role", e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <MultiAddInput
                            addButtonText="Add Proxy"
                            disableInputs={disableInputs === true}
                            displayFirstWhenEmpty={true}
                            elements={get(userAccount, "allowedProxies", [])}
                            onAdd={() => handleAddArrayElement("userAccount.allowedProxies")}
                            onUpdate={(e, index) =>
                                handleUpdateArrayElement(
                                    "userAccount.allowedProxies",
                                    index,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            render={(element, elementIndex) => (
                                <Proxy
                                    element={element}
                                    elementIndex={elementIndex}
                                    onSearch={(term) => handleSearchUsers(term)}
                                    onDelete={(index) => handleDeleteArrayElement("userAccount.allowedProxies", index)}
                                    {...this.props}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default UserAccountDetail;
