import validate from "validate.js";
import { constraints } from "../../utils/validation";

//
const propertyDetailRequiredForAll = {
    city: {
        ...constraints.required,
    },
    county: {
        ...constraints.required,
    },
    state: {
        ...constraints.required,
    },
    zip: {
        ...constraints.required,
    },
};
const propertyDetailRequiredForOffice = {
    bldgClass: {
        // ...constraints.required,
    },
};

const propertyDetailRequiredForAllExceptLand = {
    addressLine: {
        ...constraints.required,
    },
    propertyName: {
        ...constraints.required,
    },
};

const propertyDetailOptional = {
    numFloors: {
        ...constraints.number,
    },
    totalBldgSf: {
        ...constraints.number,
    },
    yearBuilt: {
        ...constraints.number,
    },
    parkingTotal: {
        ...constraints.number,
    },
    parkingRatio: {
        ...constraints.number,
    },
};

const leaseDetailRequiredForAll = {
    rateType: {
        ...constraints.required,
    },
    leaseType: {
        ...constraints.required,
    },
};
const leaseDetailRequiredForAllExceptLand = {
    movingAllowanceAmount: {
        ...constraints.required,
        ...constraints.number,
    },
    improvementAllowanceAmount: {
        ...constraints.required,
        ...constraints.number,
    },
};
const leaseDetailOptional = {
    currentSf: {
        ...constraints.number,
    },
    squareFeetChange: {
        ...constraints.number,
    },
};

const saleDetailRequiredForAll = {
    investmentSaleFlg: {
        ...constraints.required,
    },
    leaseBackFlg: {
        ...constraints.required,
    },
    portfolioFlg: {
        ...constraints.required,
    },
};

const requestListingFromNew = {
    newPropertyName: {
        ...constraints.required,
    },
    newPropertyAddress: {
        ...constraints.required,
    },
    newPropertyCity: {
        ...constraints.required,
    },
    newPropertyState: {
        ...constraints.required,
    },
    newPropertyZip: {
        ...constraints.required,
        ...constraints.id,
    },
};

const requestListingFromExisting = {
    propertyKey: {
        ...constraints.required,
    },
};

const validatePropertyDetailRequiredForAll = (properyDetail) => {
    return validate(properyDetail, propertyDetailRequiredForAll, { fullMessages: false });
};

const validatePropertyDetailRequiredForOffice = (properyDetail) => {
    return validate(properyDetail, propertyDetailRequiredForOffice, { fullMessages: false });
};

const validatePropertyDetailRequiredForAllExceptLand = (properyDetail) => {
    return validate(properyDetail, propertyDetailRequiredForAllExceptLand, { fullMessages: false });
};

const validatePropertyDetailOptional = (properyDetail) => {
    return validate(properyDetail, propertyDetailOptional, { fullMessages: false });
};

const validateLeaseDetailRequiredForAll = (leaseDetail) => {
    return validate(leaseDetail, leaseDetailRequiredForAll, { fullMessages: false });
};

const validateLeaseDetailOptional = (leaseDetail) => {
    return validate(leaseDetail, leaseDetailOptional, { fullMessages: false });
};

const validateLeaseDetailRequiredForAllExceptLand = (leaseDetail) => {
    return validate(leaseDetail, leaseDetailRequiredForAllExceptLand, { fullMessages: false });
};

const validateSaleDetailRequiredForAll = (saleDetail) => {
    return validate(saleDetail, saleDetailRequiredForAll, { fullMessages: false });
};

const validateRequestListingFromNew = (values) => {
    return validate(values, requestListingFromNew, { fullMessages: false });
};

const validateRequestListingFromExisting = (values) => {
    return validate(values, requestListingFromExisting, { fullMessages: false });
};
export default {
    validatePropertyDetailRequiredForAll,
    validatePropertyDetailRequiredForAllExceptLand,
    validatePropertyDetailRequiredForOffice,
    validatePropertyDetailOptional,
    validateLeaseDetailRequiredForAll,
    validateLeaseDetailOptional,
    validateSaleDetailRequiredForAll,
    validateLeaseDetailRequiredForAllExceptLand,
    validateRequestListingFromNew,
    validateRequestListingFromExisting,
};
