import validate from "validate.js";
import { constraints } from "../../utils/validation";
import listingValidation from "../../listing/ducks/validation";
import each from "lodash/each";
import get from "lodash/get";
import mapValues from "lodash/mapValues";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import forIn from "lodash/forIn";
import includes from "lodash/includes";
import set from "lodash/set";
import documentTypes from "../../documents/documentTypes";

const compConstraints = {
    Office: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Industrial: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
        "listing.attributes.sfAvail": {
            ...constraints.number,
        },
        "listing.attributes.largestContigSf": {
            ...constraints.number,
        },
        "listing.attributes.officeSf": {
            ...constraints.number,
        },
        "listing.attributes.yardSf": {
            ...constraints.number,
        },
        "listing.attributes.suite": {},
        "listing.attributes.officeBreakdownSfRate": {
            ...constraints.number,
        },
        "listing.attributes.warehouseShellBreakdownRate": {
            ...constraints.number,
        },
        "listing.attributes.blendedRate": {
            ...constraints.number,
        },
        "listing.attributes.voltsNum": {},
        "listing.attributes.ampsNum": {
            ...constraints.number,
        },
        "listing.attributes.phaseNum": {
            ...constraints.number,
        },
        "listing.attributes.gradeLevelDoorsNum": {
            ...constraints.number,
        },
        "listing.attributes.dockHighDoorsNum": {
            ...constraints.number,
        },
        "listing.attributes.railDoorsNum": {
            ...constraints.id,
        },
        "listing.attributes.clearHeightMin": {
            ...constraints.number,
        },
        "listing.attributes.clearHeightMax": {
            ...constraints.number,
        },
        "listing.attributes.cranesQty": {
            ...constraints.number,
        },
        "listing.attributes.craneNotes": {},
        "listing.attributes.yardNotes": {},
        "attributes.industrialSf": {
            ...constraints.id,
        },
    },
    "Life Sciences": {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Retail: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Land: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Multifamily: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Hospitality: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
        "listing.attributes.hospitalitySf": {
            ...constraints.id,
        },
        "listing.attributes.hospitalityUnitCount": {
            ...constraints.id,
        },
        "listing.attributes.roomCount": {
            ...constraints.id,
        },
    },
    Other: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Educational: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Religious: {},
    Healthcare: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    totalConsideration: {
        commencementDate: {
            ...constraints.required,
            ...constraints.date,
        },
        expirationDate: {
            ...constraints.required,
            ...constraints.date,
        },
        termNumMonths: {
            ...constraints.required,
            ...constraints.number,
        },
        squareFeet: {
            ...constraints.required,
            ...constraints.number,
        },
        ratePerSqftPerMonth: {
            ...constraints.required,
            ...constraints.number,
        },
        ratePerSqftPerYear: {
            ...constraints.required,
            ...constraints.number,
        },
        escalationRate: {
            ...constraints.required,
            ...constraints.number,
        },
        escalationTerm: {
            ...constraints.required,
            ...constraints.number,
        },
    },
    listingInformation: {
        "listing.dateListed": {
            ...constraints.date,
        },
        "listing.dateAvailable": {
            ...constraints.date,
        },
        "listing.expirationDate": {
            ...constraints.date,
        },
        "listing.leaseExpirationDate": {
            ...constraints.date,
        },
        "listing.attributes.totalUnits": {
            ...constraints.number,
        },
        "listing.attributes.numBldg": {
            ...constraints.number,
        },
        "listing.attributes.lotAcres": {
            ...constraints.number,
        },
        "listing.attributes.lotSf": {
            ...constraints.number,
        },
        "listing.attributes.amenities": {},
        "listing.attributes.tourInstructions": {},
    },
    listingInformationSf: {
        "listing.sfAvail": {
            ...constraints.required,
            ...constraints.number,
        },
    },
    leaseDetails: {
        tiRate: {
            ...constraints.number,
            ...constraints.required,
        },
        leaseType: {
            ...constraints.required,
        },
        movingAllowanceAmount: {
            ...constraints.number,
        },
        buyerTenantBusinessType: {},
        naicsCode: {
            ...constraints.id,
        },
        sicCode: {
            ...constraints.id,
        },
        previousLocation: {},
        currentSf: {
            ...constraints.number,
        },
        squareFeetChange: {
            ...constraints.number,
        },
    },
    Lease: {
        //general information
        //required fields
        "listing.listingName": {
            ...constraints.required,
        },
        transactionDate: {
            ...constraints.required,
            ...constraints.date,
        },
        totalKmCommission: {
            ...constraints.required,
            ...constraints.number,
        },
        representationType: {
            ...constraints.required,
        },
        spaceType: {
            ...constraints.required,
        },
        //other constraints
        buyerTenantCommonCompanyName: {
            ...constraints.required,
        },
        buyerTenantLegalCompanyName: {
            ...constraints.required,
        },
        buyerTenantInstitutionalOwner: {},
        outsideBrokerCommission: {
            ...constraints.number,
        },
        totalConsiderationAmount: {
            ...constraints.required,
            ...constraints.number,
        },
    },
    Sale: {
        "listing.listingName": {
            ...constraints.required,
        },
        //required fields
        transactionDate: {
            ...constraints.required,
            ...constraints.date,
        },
        closedDate: {
            ...constraints.required,
            ...constraints.date,
        },
        totalKmCommission: {
            ...constraints.required,
            ...constraints.number,
        },
        leaseBackFlg: {
            ...constraints.required,
        },
        representationType: {
            ...constraints.required,
        },
        totalConsiderationAmount: {
            ...constraints.required,
            ...constraints.number,
        },
        squareFeet: {
            ...constraints.required,
            ...constraints.number,
        },
        buyerTenantCommonCompanyName: {
            ...constraints.required,
        },
        buyerTenantLegalCompanyName: {
            ...constraints.required,
        },
    },
    saleSpaceType: {
        spaceType: {
            ...constraints.required,
        },
    },
    buildingInformation: {},
    parkingInformation: {
        "listing.attributes.parkingTotal": {
            ...constraints.number,
        },
        "listing.attributes.parkingRatio": {},
        "listing.attributes.surfaceParkingSpaceCount": {
            ...constraints.number,
        },
        "listing.attributes.parkingGarageSpaceCount": {
            ...constraints.number,
        },
        "listing.attributes.parkingReservedSpaceCount": {
            ...constraints.number,
        },
        "listing.attributes.carportParkingSpaceCount": {
            ...constraints.number,
        },
        "listing.attributes.parkingNotes": {},
    },
    listingFinancial: {
        "listing.leaseType": {
            ...constraints.required,
        },
        "listing.improvementAllowance": {
            ...constraints.number,
        },
        "listing.askingRentMax": {
            ...constraints.required,
            ...constraints.number,
        },
        "listing.askingRentPerMonth": {
            ...constraints.required,
            ...constraints.number,
        },
        "listing.baseRentPerYear": {
            ...constraints.required,
            ...constraints.number,
        },
        "listing.expenseType": {},
        "listing.expenseTypeValue": {},
        "listing.nnnPerMonth": {
            ...constraints.number,
        },
        "listing.nnnPerYear": {
            ...constraints.number,
        },
        "listing.totalMonthlyRent": {
            ...constraints.number,
        },
        "listing.totalRentPerYear": {
            ...constraints.number,
        },
        "listing.procuringBrokerCommission": {},
    },
    saleFinancial: {
        "listing.listPrice": {
            ...constraints.required,
            ...constraints.number,
        },
    },
    sellerLandlordCompany: {
        sellerLandlordCommonCompanyName: {
            ...constraints.required,
        },
        sellerLandlordLegalCompanyName: {
            ...constraints.required,
        },
    },
    companyAdminConstraints: {
        sellerLandlordCompany: {
            ...constraints.required,
        },
        buyerTenantCompany: {
            ...constraints.required,
        },
    },
};

const installmentConstraints = {
    amount: {
        ...constraints.required,
        ...constraints.number,
    },
    dueDate: {
        ...constraints.required,
        ...constraints.date,
    },
};

const validateComp = (comp, useType, transactionType, documents) => {
    let whatToValidate = [];
    switch (transactionType) {
        case "Lease":
            whatToValidate = [
                useType,
                transactionType,
                "leaseDetails",
                "totalConsideration",
                "buildingInformation",
                "parkingInformation",
            ];
            break;
        case "Sale":
            whatToValidate = [useType, transactionType, "buildingInformation", "parkingInformation"];

            if (get(comp, "leaseBackFlg") === true || get(comp, "leaseBackFlg") === "true") {
                whatToValidate.push("saleSpaceType");
            }
            break;
        default:
            break;
    }

    if (
        (get(comp, "dealStatus") === "In Review" || get(comp, "dealStatus") === "Final Review") &&
        (transactionType === "Lease" || transactionType === "Sale")
    ) {
        whatToValidate.push("companyAdminConstraints");
    }

    if (get(comp, "fromListing") === true) {
        whatToValidate.push("listingInformation");
        if (transactionType === "Lease") {
            whatToValidate.push("listingFinancial");
            whatToValidate.push("listingInformationSf");
        } else if (transactionType === "Sale") {
            whatToValidate.push("saleFinancial");
        }
    }

    let combinedConstraints = {};
    each(whatToValidate, (section) => {
        combinedConstraints = { ...combinedConstraints, ...compConstraints[section] };
    });

    let errors = {
        ...mapValues(validate(comp, combinedConstraints, { fullMessages: false }), (error) => {
            return error[0];
        }),
        ...listingValidation.validateListingBrokersCompany(get(comp, "listing.listingBrokers")),
        ...listingValidation.validateMixedUses(get(comp, "listing.attributes.mixedUses")),
        ...listingValidation.validatePortfolioProperties(get(comp, "listing.attributes.portfolioProperties")),
        ...listingValidation.validateCondos(get(comp, "listing.attributes.condos")),
        ...listingValidation.validateisDivisible(get(comp, "listing.isDivisible"), get(comp, "listing.sfDivisibleTo")),
        ...validateInstallments(get(comp, "installments")),
        ...validateRequiredDocuments(documents),
    };

    if (transactionType === "Lease" && get(comp, "fromListing") === true) {
        const autoRenew = listingValidation.validateAutoRenew(get(comp, "listing"));
        if (!isEmpty(autoRenew)) {
            errors = {
                ...errors,
                ...{ "listing.expirationDate": autoRenew },
            };
        }
    }
    console.log("Form Errors: ", errors);
    return errors;
};

const validateRequiredDocuments = (documents) => {
    let valid = true;
    each(Object.keys(documentTypes["comp"]), (key, index) => {
        if (!includes(documents.usedDocTypes, key) && documentTypes["comp"][key]["required"]) {
            valid = false;
        }
    });
    return valid ? {} : { documents: "required" };
};

const validateInstallments = (installments) => {
    let errors = {};
    if (installments) {
        for (let i = 0; i < installments.length; i++) {
            const validationResponse = validateInstallment(installments[i]);
            if (validationResponse) {
                set(errors, `${i}`, {
                    ...mapValues(validationResponse, (value, key) => {
                        return value[0];
                    }),
                });
            }
        }
    }
    return isEmpty(errors) ? {} : { installments: errors };
};

const validateInstallment = (installment) => {
    return validate(installment, installmentConstraints, { fullMessages: false });
};

const getPanelsToExpand = (errors, useType, transactionType) => {
    let listingInformationConstraints = {};
    each([useType, "listingInformation", "parkingInformation"], (section) => {
        listingInformationConstraints = { ...listingInformationConstraints, ...compConstraints[section] };
    });

    const otherListingInfo = [
        "attributes.condos",
        "attributes.mixedUses",
        "attributes.portfolioProperties",
        "listing.listingBrokers",
    ];
    const otherGeneralInfo = [
        "installments",
        "buyerTenantCommonCompanyName",
        "buyerTenantLegalCompanyName",
        "sellerLandlordCommonCompanyName",
        "sellerLandlordLegalCompanyName",
    ];

    let generalInformationConstraints = {};
    each([transactionType], (section) => {
        generalInformationConstraints = { ...generalInformationConstraints, ...compConstraints[section] };
    });

    let panelsToExpand = [];
    forIn(errors, (value, key) => {
        if (
            includes(
                [...Object.keys(compConstraints.listingFinancial), ...Object.keys(compConstraints.saleFinancial)],
                key
            )
        ) {
            panelsToExpand.push("listingFinancial");
        } else if (includes([...Object.keys(listingInformationConstraints), ...otherListingInfo], key)) {
            panelsToExpand.push("listingInformation");
        } else if (includes([...Object.keys(generalInformationConstraints), ...otherGeneralInfo], key)) {
            //ignore, general information opened and closed manually
            panelsToExpand.push("generalInformation");
        }
    });

    panelsToExpand = uniqBy(panelsToExpand, function (e) {
        return e;
    });

    return panelsToExpand;
};

export default {
    validateComp,
    getPanelsToExpand,
    validateInstallments,
};
