import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Comments from "@material-ui/icons/Comment";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { DropdownButton, SplitButton } from "react-bootstrap";
import ExecuteInvoiceModal from "../invoice/ExecuteInvoiceModal";
import constants from "./constants";
import get from "lodash/get";
import KMSwitch from "../../../common/js/components/KMSwitch";
import includes from "lodash/includes";
import { red } from "@material-ui/core/colors";
import { SaveAndExit } from "../../../common/js/icons";

const styles = (theme) => ({
    pageToolbar: {
        padding: "10px 0px",
        backgroundColor: "#fafafa",
        position: "sticky",
        top: "62px",
        zIndex: 1,
    },
    progressDiv: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
    },
    leftIcon: {
        marginLeft: "25px",
    },
    progress: {
        paddingRight: "100px",
        paddingLeft: "100px",
    },
    menuCategory: {
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    bypassCompApprovalSwitch: {
        marginRight: "80px",
    },
    deleteButton: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800],
        },
    },
});

const CompToolbar = (props) => {
    const {
        classes,
        confirmDialog,
        deal,
        disableInputs,
        elementsSaving,
        executeInvoiceModalOpen,
        handleCheckboxClick,
        handleExecuteInvoice,
        handleToggleBooleanProperty,
        numComments,
        toggleComments,
        toggleConfirm,
        saveInsideComp,
        saveAndExitInsideComp,
        submitInsideComp,
        userProfile,
        handleStartReviewingAsCorporateData,
        toggleTerminateDialog,
        terminateDialog,
        confirmTerminateDialog,
        handleTerminateDeal,
        terminationDocument,
        handleConfirmTerminateDeal,
    } = props;

    const handleCancel = () => {
        toggleConfirm(null);
    };

    const handleDeleteRequest = () => {
        handleTerminateDeal(deal.dealKey, terminationDocument.upload);
        toggleTerminateDialog(false);
    };

    const handleConfirmDelete = () => {
        handleConfirmTerminateDeal(deal.dealKey, terminationDocument.upload, deal.dealStatus);
        toggleTerminateDialog(false);
    };

    const brokerSubmitActions = {
        null: {
            buttonText: "Submit",
            submit: () => {
                submitInsideComp(constants.urls.broker_submit(deal.dealKey));
            },
            variant: "contained",
        },
        Draft: {
            buttonText: "Submit",
            submit: () => {
                submitInsideComp(constants.urls.broker_submit(deal.dealKey));
            },
            variant: "contained",
        },
        "Broker Action Required": {
            buttonText: "Submit",
            submit: () => {
                submitInsideComp(constants.urls.broker_submit(deal.dealKey));
            },
            variant: "contained",
        },
    };
    const submitActions = {
        broker: {
            ...brokerSubmitActions,
        },
        evp: {
            ...brokerSubmitActions,
        },
        debtEquity: {
            ...brokerSubmitActions,
        },
        portlandBrokerAdmin: {
            ...brokerSubmitActions,
            "Portland Review Required": {
                buttonText: "Portland Review Required",
                submit: (callback) => {
                    submitInsideComp(callback);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_comp_to_broker,
                    Approve: constants.urls.process_portland_approval,
                    "Revert to Draft": constants.urls.revert_comp_to_draft,
                },
            },
        },
        arizonaBrokerAdmin: {
            ...brokerSubmitActions,
            "AZ Review Required": {
                buttonText: "AZ Review Required",
                submit: (callback) => {
                    submitInsideComp(callback);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_comp_to_broker,
                    Approve: constants.urls.process_arizona_approval,
                },
            },
        },
        dataEntry: {
            "Needs Review": {
                buttonText: "Start",
                submit: () => {
                    handleStartReviewingAsCorporateData(deal);
                },
                enabled: true,
            },
            "In Review": {
                buttonText: "In Review",
                submit: (callback) => {
                    submitInsideComp(callback);
                }, //change to true
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_comp_to_broker,
                    "Final Review": constants.urls.approve_comp,
                },
            },
            "Admin Action Required": {
                buttonText: "In Review",
                submit: (callback) => {
                    submitInsideComp(callback);
                }, //change to true
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_comp_to_broker,
                    "Final Review": constants.urls.approve_comp,
                },
            },
        },
        dataAdmin: {
            "Final Review": {
                buttonText: "In Review",
                submit: (callback) => {
                    submitInsideComp(callback);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_comp_to_broker,
                    "Admin Action Required": constants.urls.reassign_comp_to_data_entry,
                    Approve: constants.urls.approve_comp,
                },
            },
        },
    };

    const mainAction = get(submitActions, `${userProfile.role}.${deal.compStatus}`, null);
    const role = get(userProfile, "role", null);
    const compStatus = get(deal, "compStatus", null);

    const confirm = (url) => {
        toggleConfirm(url(deal.dealKey));
    };

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
            <Grid item>
                {executeInvoiceModalOpen && (
                    <ExecuteInvoiceModal
                        toggleModal={() => handleToggleBooleanProperty("executeInvoiceModalOpen")}
                        open={executeInvoiceModalOpen}
                        handleExecuteInvoice={() => handleExecuteInvoice()}
                        saving={get(elementsSaving, "invoice")}
                    />
                )}
                <h2 className="page-title">{deal.compStatus === "Draft" ? "Create Comp" : "Comp"}</h2>
                <p className="page-subtitle">
                    {deal.propertyType} {deal.transactionType}
                </p>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" spacing={0}>
                    {includes(["dataAdmin", "dataEntry"], role) && (
                        <Grid item>
                            <FormControlLabel
                                className={classes.bypassCompApprovalSwitch}
                                control={
                                    <KMSwitch
                                        checked={deal.sendVouchersToAccountingFlg ? true : false}
                                        onChange={(e) => handleCheckboxClick("sendVouchersToAccountingFlg", e)}
                                        value={deal.sendVouchersToAccountingFlg}
                                        disabled={disableInputs}
                                    />
                                }
                                label="Send Voucher to Accounting"
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <FormControlLabel
                            control={
                                <KMSwitch
                                    checked={deal.confidentialFlg ? true : false}
                                    onChange={(e) => handleCheckboxClick("confidentialFlg", e)}
                                    value={deal.confidentialFlg}
                                    disabled={disableInputs}
                                />
                            }
                            label="Confidential"
                        />
                    </Grid>
                    {deal.compStatus === "Outside Draft" ? null : (
                        <Grid item className={classes.leftIcon}>
                            <Button disabled={Boolean(!deal.dealKey)} onClick={toggleComments}>
                                <Comments /> Comments ({numComments})
                            </Button>
                        </Grid>
                    )}
                    {(includes(["portlandBrokerAdmin", "arizonaBrokerAdmin"], userProfile.role) &&
                        includes(
                            ["Portland Termination Review Required", "AZ Termination Review Required"],
                            compStatus
                        )) ||
                    (userProfile.role === "broker" &&
                        includes(["brokerActionRequired", "Draft"], compStatus) &&
                        includes(["OR", "AZ"], get(deal, "listing.property.primaryAddress.city.state.stateCode"))) ? (
                        <Grid item>
                            <Button
                                variant="contained"
                                className={classes.deleteButton}
                                onClick={() => toggleTerminateDialog(true, deal.dealStatus)}
                                disabled={disableInputs}
                            >
                                Terminate
                            </Button>
                        </Grid>
                    ) : (
                        ""
                    )}
                    <Grid item>
                        {elementsSaving.saveAndExit ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : (
                            <SplitButton
                                title="Save"
                                bsStyle="default"
                                disabled={disableInputs && !(role === "portlandBrokerAdmin" && compStatus === "Draft")}
                                onClick={saveInsideComp}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={saveAndExitInsideComp}
                                    disabled={
                                        disableInputs && !(role === "portlandBrokerAdmin" && compStatus === "Draft")
                                    }
                                >
                                    <SaveAndExit />
                                    Save & Exit
                                </Button>
                            </SplitButton>
                        )}
                        {elementsSaving.executeInvoice ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleToggleBooleanProperty("executeInvoiceModalOpen")}
                            >
                                Generate Invoice
                            </Button>
                        )}
                        {mainAction && mainAction.options ? ( //if the action button had multiple options
                            elementsSaving.submit ? (
                                <div className={classes.progressDiv}>
                                    <CircularProgress size={20} color="primary" />
                                </div>
                            ) : (
                                <DropdownButton title={get(mainAction, "buttonText")} pullRight>
                                    {Object.keys(get(mainAction, "options", {})).map((x, i) => {
                                        let url = get(mainAction, `options.${x}`);
                                        return (
                                            <MenuItem className={classes.menuCategory} onClick={() => confirm(url)}>
                                                {x}
                                            </MenuItem>
                                        );
                                    })}
                                </DropdownButton>
                            )
                        ) : mainAction !== null ? (
                            elementsSaving.submit ? (
                                <div className={classes.progressDiv}>
                                    <CircularProgress size={20} color="primary" />
                                </div>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={mainAction.enabled ? false : disableInputs}
                                    onClick={() => toggleConfirm()}
                                >
                                    {mainAction.buttonText}
                                </Button>
                            )
                        ) : (
                            deal && (
                                <Button disabled variant="contained" color="secondary">
                                    {deal.compStatus}
                                </Button>
                            )
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={terminateDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Terminate Deal?</DialogTitle>
                <DialogContent>
                    Are you sure you want to terminate this Deal? If terminated, this Deal will be removed from the Deal
                    workflow, added to your Closed Deals for recording purposes, and you can no longer receive payment
                    for this deal. Please only select "Yes" if this Deal has been terminated by your client.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleTerminateDialog(false, deal.dealStatus)} color="primary">
                        No
                    </Button>
                    <Button onClick={() => handleDeleteRequest()} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={confirmTerminateDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Terminate Deal?</DialogTitle>
                <DialogContent>
                    Are you sure you want to terminate this Deal? If terminated, this Deal will be removed from the Deal
                    workflow and added to the Broker's Closed Deals for recording purposes. Please only select "Yes" if
                    this Deal has been terminated by the Broker's client.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleTerminateDialog(false, deal.dealStatus)} color="primary">
                        No
                    </Button>
                    <Button onClick={() => handleConfirmDelete()} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={confirmDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Continue?</DialogTitle>
                <DialogContent>Are you sure you want to submit this status change?</DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => mainAction["submit"](confirmDialog.onSubmit)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default withStyles(styles)(CompToolbar);
