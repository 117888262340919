import types from "./types";
import cloneDeep from "lodash/cloneDeep";
import setWith from "lodash/setWith";
import clone from "lodash/clone";

export default (
    state = {
        showDeleteDialog: {
            isOpen: false,
        },
        modals: {
            isOpen: true,
        },

        displayDeleteDialog: {
            open: false,
            onSubmit: null,
        },
        displayNoDeleteDialog: {
            open: false,
            onSubmit: null,
        },
        voucher: {
            deal: {},
            brokerCommissions: {
                runnerCommissions: {},
            },
        },
        errors: {
            deal: {},
        },

        outsideSplitModal: {
            isOpen: false,
        },
        searchResults: [],
        snackbar: {
            open: false,
        },
        documentWarning: {
            open: false,
            proceed: true,
            docs: [],
        },
        updatedProperty: {},
    },
    action
) => {
    switch (action.type) {
        case types.GET_VOUCHER:
            return Object.assign({}, state, {
                ...state,
                voucher: action.voucher,
            });
        case types.SET_VOUCHER_ID:
            return {
                ...state,
                voucher: {
                    ...state.voucher,
                    key: action.voucher,
                },
            };
        case types.ADD_BROKER_COMMISSION:
            return {
                ...state,
                voucher: {
                    ...state.voucher,
                    brokerCommissions: {
                        ...state.voucher.brokerCommissions,
                        [action.key]: action.draft[action.key],
                    },
                },
            };
        case types.ADD_RUNNER:
            return {
                ...state,
                voucher: {
                    ...state.voucher,
                    brokerCommissions: {
                        ...state.voucher.brokerCommissions,
                        [action.parent]: {
                            ...state.voucher.brokerCommissions[action.parent],
                            runnerCommissions: {
                                ...state.voucher.brokerCommissions[action.parent].runnerCommissions,
                                [action.key]: action.runner[action.key],
                            },
                        },
                    },
                },
            };
        case types.DELETE_OUTSIDE_SPLIT:
            return {
                ...state,
                voucher: {
                    ...state.voucher,
                    brokerCommissions: action.newSplits,
                    outsideSplitModal: {
                        key: null,
                        isOpen: false,
                    },
                },
            };
        case types.SAVE_OUTSIDE_SPLIT:
            console.log(action.split);
            return {
                ...state,
                voucher: {
                    ...state.voucher,
                    brokerCommissions: {
                        ...state.voucher.brokerCommissions,
                        // [action.key]: {
                        //     brokerCommissionKey: action.key,
                        //     brokerType: null,
                        //     netPercent: action.split.netPercent,
                        //     netAmount: action.split.netAmount,
                        //     brokerAgent: {
                        //         kmBrokerFlag: false,
                        //         person: {
                        //             firstName: action.split.firstName,
                        //             lastName: '',
                        //         }
                        //     }
                        // }
                        [action.key]: { ...action.split },
                    },
                },
            };
        case types.UPDATE_SPLIT:
            return {
                ...state,
                [action.source]: {
                    ...state[action.source],
                    brokerCommissions: {
                        ...state[action.source]["brokerCommissions"],
                        [action.key]: {
                            ...state[action.source]["brokerCommissions"][action.key],
                            [action.field]: action.value,
                        },
                    },
                },
            };
        // return setWith(state, action.path, action.value, Object);
        case types.UPDATE_RUNNER:
            return {
                ...state,
                voucher: {
                    ...state.voucher,
                    brokerCommissions: {
                        ...state.voucher.brokerCommissions,
                        [action.parent]: {
                            ...state.voucher.brokerCommissions[action.parent],
                            runnerCommissions: {
                                ...state.voucher.brokerCommissions[action.parent].runnerCommissions,
                                [action.key]: {
                                    ...state.voucher.brokerCommissions[action.parent].runnerCommissions[action.key],
                                    [action.field]: action.value,
                                },
                            },
                        },
                    },
                },
            };
        case types.REMOVE_COMMISSION:
            return {
                ...state,
                voucher: {
                    ...state.voucher,
                    brokerCommissions: action.newCommissions,
                },
            };
        case types.TOGGLE_OUTSIDE_SPLIT_MODAL:
            return {
                ...state,
                outsideSplitModal: {
                    ...state.outsideSplitModal,
                    isOpen: action.open,
                    key: action.key,
                    brokerCommissions: {
                        [action.key]: action.split,
                    },
                    secondaryAction: action.secondaryAction,
                    isFreeformSearchEnabled: action.isFreeformSearchEnabled,
                    commissionType: action.commissionType,
                },
            };
        case types.VOUCHER_CHANGE:
            return {
                ...state,
                voucher: {
                    ...state.voucher,
                    [action.field]: action.value,
                },
            };
        case types.ADD_VOUCHER_ERROR:
            return setWith(clone(state), `errors.${action.field}`, action.error, clone);
        case types.CLEAR_VOUCHER_ERRORS:
            return {
                ...state,
                errors: {},
            };
        case types.CLEAR_VOUCHER_MODAL_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    outsideSplitModal: {},
                },
            };
        case types.UPDATE_PROPERTY:
            return {
                ...state,
                updatedProperty: action.updatedProperty,
            };
        case types.CLEAR_UPDATE_PROPERTY:
            return {
                ...state,
                updatedProperty: {},
            };
        case types.VALID_VOUCHER:
            return {
                ...state,
                validVoucher: action.valid,
            };
        case types.DETERMINE_OUTSIDE_SPLIT_PRESENT:
            return {
                ...state,
                outsideSplitsPresent: action.isPresent,
            };

        case types.VALID_DOCUMENTS:
            return {
                ...state,
                validDocuments: action.valid,
            };
        case types.VOUCHER_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    open: action.open,
                    message: action.message,
                    variant: action.variant,
                },
            };
        case types.VOUCHER_INPUT:
            return setWith(cloneDeep(state), `${action.path}`, action.value, clone);
        case types.SET_VOUCHER_ARRAY:
            return setWith(cloneDeep(state), `${action.pathToArray}`, action.array, Object);
        case types.VOUCHER_DOCUMENT_WARNING:
            return {
                ...state,
                documentWarning: {
                    open: action.open,
                    proceed: action.proceed,
                    docs: action.docs,
                    callback: action.callback,
                },
            };
        case types.UPDATE_DELETE_DIALOG:
            return {
                ...state,
                displayDeleteDialog: {
                    ...state.displayDeleteDialog,
                    open: action.open,
                },
            };
        case types.UPDATE_NO_DELETE_DIALOG:
            return {
                ...state,
                displayNoDeleteDialog: {
                    ...state.displayNoDeleteDialog,
                    open: action.open,
                },
            };

        case types.OPEN_MODAL:
            return {
                ...state,
                modals: state.modals,
                isOpen: action.open,
            };
        case types.CLOSE_MODAL:
            return {
                ...state,
                modals: state.modals,
                isOpen: action.open,
            };

        default:
            return state;
    }
};
