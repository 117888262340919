import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { leaseTypeTypes } from "../types/leaseTypeTypes";

import get from "lodash/get";

const styles = (theme) => ({
    equals: {
        paddingTop: "35px",
        textAlign: "center",
    },
});

class LeaseDetailForm extends React.Component {
    handleLeaseDetailChange = (event) => {
        this.props.onChange(event);
    };

    render() {
        const { leaseDetail, classes, errors, disableInputs, handleAutocalculateTiSf } = this.props;

        return (
            <div>
                {/*************** All Starts Here ***************/}

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={5}>
                        <BSInput
                            id="tiRate"
                            label="TI($/SF)*"
                            errorText="Required (ex. 981.01)"
                            helperText="Required (ex. 981.01)"
                            placeholder="Enter $/sf"
                            error={errors.tiRate}
                            value={leaseDetail.tiRate}
                            onChange={handleAutocalculateTiSf}
                            startAdornment="$"
                            disabled={disableInputs === true}
                            numeric
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <div className={classes.equals}> = </div>
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="tiTotal"
                            placeholder="Total Amount"
                            error={errors.tiTotal}
                            value={leaseDetail.tiTotal}
                            onChange={this.handleLeaseDetailChange}
                            startAdornment="$"
                            disabled={true}
                            numeric
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSSelect
                            id="leaseType"
                            label="Lease Type*"
                            helperText="Required"
                            errorText="Required"
                            value={get(leaseDetail, "leaseType")}
                            error={errors.leaseType}
                            onChange={this.handleLeaseDetailChange}
                            placeholder="Select lease type..."
                            disabled={disableInputs}
                            options={[
                                {
                                    value: "",
                                    label: "Select",
                                    disabled: true,
                                },
                                ...leaseTypeTypes,
                            ]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="netEffectiveRent"
                            label="NER (Net Effective Rent)"
                            placeholder="Net Effective Rent"
                            error={errors.netEffectiveRent}
                            value={leaseDetail.netEffectiveRent}
                            onChange={this.handleLeaseDetailChange}
                            startAdornment="$"
                            disabled={disableInputs === true}
                            numeric
                        />
                    </Grid>
                </Grid>
                {/*************** All Ends Here ***************/}

                {/*************** All Except Land Starts Here ***************/}
                {this.props.useType !== "LAND" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="movingAllowanceAmount"
                                    type="number"
                                    label="Moving Allowance ($)"
                                    errorText="(ex. 981.01)"
                                    placeholder="Enter moving allowance"
                                    error={errors.movingAllowanceAmount}
                                    value={leaseDetail.movingAllowanceAmount}
                                    disabled={disableInputs === true}
                                    startAdornment="$"
                                    onChange={this.handleLeaseDetailChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="buyerTenantBusinessType"
                                    label="Tenant Business Type"
                                    placeholder="Enter tenant business type"
                                    error={errors.buyerTenantBusinessType}
                                    value={leaseDetail.buyerTenantBusinessType}
                                    disabled={disableInputs === true}
                                    onChange={this.handleLeaseDetailChange}
                                />
                            </Grid>
                        </Grid>

                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="naicsCode"
                                    type="number"
                                    label="BUSINESS NAICS CODE"
                                    helperText="Numeric only (ex. 98101)"
                                    helperTextNotRequired={true}
                                    errorText="Numeric only (ex. 98101)"
                                    placeholder="Enter business NAICS code"
                                    error={errors.naicsCode}
                                    value={leaseDetail.naicsCode}
                                    disabled={disableInputs === true}
                                    onChange={this.handleLeaseDetailChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="sicCode"
                                    type="number"
                                    label="BUSINESS SIC CODE"
                                    helperText="Numeric only (ex. 98101)"
                                    helperTextNotRequired={true}
                                    errorText="Numeric only (ex. 98101)"
                                    placeholder="Enter business SIC code"
                                    error={errors.sicCode}
                                    value={leaseDetail.sicCode}
                                    disabled={disableInputs === true}
                                    onChange={this.handleLeaseDetailChange}
                                />
                            </Grid>
                        </Grid>

                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="previousLocation"
                                    label="PREVIOUS LOCATION"
                                    placeholder="Enter previous location"
                                    error={errors.previousLocation}
                                    value={leaseDetail.previousLocation}
                                    onChange={this.handleLeaseDetailChange}
                                    disabled={disableInputs === true}
                                />
                            </Grid>
                        </Grid>

                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="currentSf"
                                    label="PREVIOUS SQUARE FEET"
                                    placeholder="Enter previous square feet"
                                    error={errors.currentSf}
                                    value={leaseDetail.currentSf}
                                    disabled={disableInputs === true}
                                    onChange={this.handleLeaseDetailChange}
                                    integer
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="squareFeetChange"
                                    label="SQUARE FEET CHANGE"
                                    placeholder="Square feet changed"
                                    error={errors.squareFeetChange}
                                    value={leaseDetail.squareFeetChange}
                                    disabled={disableInputs === true}
                                    onChange={this.handleLeaseDetailChange}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}

                {/*************** All Except Land Ends Here ***************/}
            </div>
        );
    }
}

export default withStyles(styles)(LeaseDetailForm);
