import React from "react";
import Grid from "@material-ui/core/Grid";
import BrokerCommissions from "./BrokerCommissions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormTitle from "../../../common/js/components/FormTitle";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";

import BSInput from "../../../common/js/components/BSInput";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Today from "../../../../node_modules/@material-ui/icons/Today"; //used for date type input
import Installments from "../deal/Installments";

import toLower from "lodash/toLower";
import get from "lodash/get";

import documentTypes from "../documents/documentTypes";
import { disabledStatuses } from "./constants";
import includes from "lodash/includes";
import moment from "moment";

const VoucherForm = (props) => {
    const {
        userProfile,
        voucher,
        errors,
        updateSplit,
        handleInput,
        updateRunner,
        outsideSplits,
        searchResults,
        documentWarning,
        handleAddRunner,
        outsideSplitModal,
        brokerCommissions,
        handleSearchPerson,
        handleSearchBroker,
        updateBrokerPerson,
        handleSearchCompany,
        handleCheckboxClick,
        handleVoucherChange,
        handleConfirmWarning,
        handleDocumentWarning,
        handleSaveOutsideSplit,
        toggleOutsideSplitModal,
        determineOutsidePresent,
        handleDeleteOutsideSplit,
        handleAddBrokerCommission,
        handleCreateOutsideSplitDraft,
        handleRemoveCommissionOrRunner,
        handleVoucherChangeAndCalculateSplits,
        autocompleteResults,

        handleAddArrayElement, //(path)
        handleUpdateArrayElement, //(arrayPath, index, field, value)
        handleReducerLevelInput,
        setInitialPaymentMethod,
        classes,
    } = props;

    const formDisabled = get(disabledStatuses, `${userProfile.role}.${voucher.voucherStatus}`, true);
    const role = get(userProfile, "role");

    if (get(voucher, "submittedDate", null) == null && get(voucher, "voucherStatus", null) === "Draft") {
        handleVoucherChange({ target: { id: "submittedDate", value: moment().format("YYYY-MM-DD") } });
    }

    if (get(voucher, "paymentMethod", null) === null) {
        setInitialPaymentMethod("CHECK");
    }

    console.log("voucher.paymentMethod=" + voucher.paymentMethod);

    return (
        <div>
            <form>
                <div className="simPaper">
                    <FormTitle title="Commission Distribution" />
                    <hr className={classes.hrDivider} />
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <BSInput
                                id="submittedDate"
                                label="Date*"
                                type={"date"}
                                startAdornment={<Today />}
                                value={voucher.submittedDate}
                                error={errors.submittedDate}
                                onChange={handleVoucherChange}
                                helperText="Required"
                                errorText="Required"
                                placeholder="Enter date"
                                disabled={formDisabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="dealKey"
                                label="Deal/Comp Id"
                                error={get(errors, "dealKey")}
                                value={get(voucher, "deal.dealKey")}
                                onChange={handleVoucherChange}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>

                    {includes(["dataAdmin", "accounting", "dataEntry"], role) && (
                        <React.Fragment>
                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="paymentAttachedDate"
                                        label="Submitted With Payment Date"
                                        value={
                                            voucher.paymentAttachedDate
                                                ? moment(voucher.paymentAttachedDate).format("MM/DD/YYYY h:mm A")
                                                : null
                                        }
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="acceptedDate"
                                        label="Processed Date"
                                        value={
                                            voucher.acceptedDate
                                                ? moment(voucher.acceptedDate).format("MM/DD/YYYY h:mm A")
                                                : null
                                        }
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="approvedDate"
                                        label="Batch Date"
                                        value={voucher.approvedDate}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="filedDate"
                                        label="Filed (Batch Override) Date"
                                        type={"date"}
                                        startAdornment={<Today />}
                                        value={voucher.filedDate}
                                        onChange={handleVoucherChange}
                                        disabled={false}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}

                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <BSInput
                                id="totalConsiderationAmount"
                                label="Total Consideration"
                                value={get(voucher, "deal.totalConsiderationAmount")}
                                error={errors.totalConsiderationAmount}
                                onChange={(e) => handleInput("deal.totalConsiderationAmount", e.target.value)}
                                startAdornment="$"
                                disabled={!(role === "accounting")}
                                numeric={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="totalKmCommission"
                                label="Total Commission Due to KM Only"
                                value={get(voucher, "deal.totalKmCommission")}
                                error={errors.totalKmCommission}
                                onChange={(e) => handleVoucherChangeAndCalculateSplits(true, e)}
                                startAdornment="$"
                                disabled={!(role === "accounting")}
                                numeric={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <BSInput
                                id="outsideBrokerCommission"
                                label="Total Commission To Outside Brokers"
                                value={get(voucher, "deal.outsideBrokerCommission")}
                                error={errors.outsideBrokerCommission}
                                onChange={(e) => handleVoucherChangeAndCalculateSplits(false, e)}
                                startAdornment="$"
                                disabled={!(role === "accounting")}
                                numeric={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="totalCommission"
                                label="Total Commission For Transaction"
                                value={get(voucher, "deal.totalCommission")}
                                error={errors.totalCommission}
                                onChange={handleVoucherChange}
                                startAdornment="$"
                                disabled
                                numeric={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-start" spacing={8} style={{ marginBottom: "20px" }}>
                        <Grid item xs={12}>
                            <FormControl rowcomponent="fieldset">
                                <RadioGroup
                                    row
                                    aria-label="paymentMethod"
                                    name="paymentMethod"
                                    value={voucher.paymentMethod}
                                    disabled={formDisabled}
                                    onChange={handleVoucherChange}
                                >
                                    <FormLabel component="legend" style={{ marginBottom: "5px", paddingTop: "15px" }}>
                                        Payment Method
                                    </FormLabel>
                                    <FormControlLabel
                                        value="ACH"
                                        control={
                                            <Radio
                                                id="paymentMethod"
                                                color="primary"
                                                disabled={formDisabled}
                                                checked={voucher.paymentMethod === "ACH"}
                                            />
                                        }
                                        style={{ marginRight: "83px" }}
                                        label="ACH"
                                    />
                                    <FormControlLabel
                                        value="CHECK"
                                        control={
                                            <Radio
                                                id="paymentMethod"
                                                color="primary"
                                                disabled={formDisabled}
                                                checked={voucher.paymentMethod === "CHECK"}
                                            />
                                        }
                                        label="Check"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <div
                                style={{
                                    color: "#E53935",
                                    fontSize: "12px",
                                    marginBottom: "10px",
                                    fontStyle: "italic",
                                }}
                            >
                                Required
                            </div>
                        </Grid>
                    </Grid>

                    <Installments
                        {...props}
                        installments={get(voucher, "deal.installments", [])}
                        handleAddArrayElement={handleAddArrayElement}
                        handleUpdateArrayElement={(arrayPath, index, field, value) =>
                            handleUpdateArrayElement("deal." + arrayPath, index, field, value)
                        }
                        errors={get(errors, "deal.installments", {})}
                        disableInputs={formDisabled}
                        onlyEditCurrentAndFuture={true}
                        preventAddSubtract={true}
                        voucher={voucher}
                    />

                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(voucher, "deal.kmExclusiveFlg") ? true : false}
                                        onChange={(e) => handleCheckboxClick("deal.kmExclusiveFlg", e)}
                                        value={voucher.kmExclusiveFlg}
                                        color="primary"
                                        disabled={formDisabled}
                                    />
                                }
                                label="Is this listing KM Exclusive?"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(voucher, "deal.propertyManagementPaymentFlg") ? true : false}
                                        onChange={(e) => handleCheckboxClick("deal.propertyManagementPaymentFlg", e)}
                                        value={voucher.propertyManagementPaymentFlg}
                                        color="primary"
                                        disabled={formDisabled}
                                    />
                                }
                                label="Is this payment handled by Asset Services?"
                            />
                        </Grid>
                    </Grid>

                    <hr />

                    <BrokerCommissions
                        {...props}
                        voucher={voucher}
                        brokerCommissions={brokerCommissions}
                        outsideSplits={outsideSplits}
                        handleAddBrokerCommission={handleAddBrokerCommission}
                        handleCreateOutsideSplitDraft={handleCreateOutsideSplitDraft}
                        handleSaveOutsideSplit={handleSaveOutsideSplit}
                        handleDeleteOutsideSplit={handleDeleteOutsideSplit}
                        handleAddRunner={handleAddRunner}
                        handleRemoveCommissionOrRunner={handleRemoveCommissionOrRunner}
                        updateSplit={updateSplit}
                        updateRunner={updateRunner}
                        outsideSplitModal={outsideSplitModal}
                        toggleOutsideSplitModal={toggleOutsideSplitModal}
                        determineOutsidePresent={determineOutsidePresent}
                        handleSearchPerson={handleSearchPerson}
                        handleSearchCompany={handleSearchCompany}
                        searchResults={searchResults}
                        updateBrokerPerson={updateBrokerPerson}
                        errors={errors}
                        formDisabled={formDisabled}
                        autocompleteResults={autocompleteResults}
                        handleSearchBroker={handleSearchBroker}
                        handleInput={(path, value) => handleInput(path, value)}
                        handleReducerLevelInput={handleReducerLevelInput}
                    />
                </div>
            </form>
            <div>
                <Dialog
                    open={documentWarning.open}
                    onClose={() => handleDocumentWarning(false, documentWarning.proceed, [])}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to submit?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are responsible for ensuring this deal has the necessary legal documents. The following
                            documents are still required for this deal to be compliant:
                            <ul>
                                {documentWarning.docs.map((x, i) => (
                                    <li>
                                        {get(
                                            documentTypes,
                                            `${toLower(get(voucher, "deal.transactionType", ""))}.${x}.display`,
                                            ""
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleDocumentWarning(false, false, [])} color="primary">
                            Add more docs
                        </Button>
                        <Button
                            onClick={() => handleConfirmWarning(documentWarning.callback)}
                            color="primary"
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};

const styles = (theme) => ({
    hrDivider: {
        margin: "0 -20px 20px",
    },
});

export default withStyles(styles)(VoucherForm);
