import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import toUpper from "lodash/toUpper";
import AddContactContainer from "./crm/AddContactContainer";
import AdminContainer from "./admin/AdminContainer";
import AppBar from "../../common/js/components/AppBar";
import Authorization from "./auth/Authorization";
import ConsultingDealContainer from "./voucher/ConsultingDealContainer";
import Comps from "./comp/ReviewCompsContainer";
import CrmContactsContainer from "./crm/CrmContactsContainer";
import DashboardContainer from "./dashboards/DashboardContainer";
import DealsContainer from "./deals/DealsContainer";
import DealSummaryContainer from "./deal/DealSummaryContainer";
import EditTransactionContainer from "./debt-equity/EditTransactionContainer";
import ExternalRedirect from "./utils/ExternalRedirect";
import Grid from "@material-ui/core/Grid";
import ListingRequestContainer from "./listing/ListingRequestContainer";
import ListingRequestContainerBeta from "./listing/beta/ListingRequestContainerBeta";
import NavDrawer from "../../common/js/components/NavDrawer";
import CompContainer from "./comp/CompContainer";
import ReviewPropertiesContainer from "./property/ReviewPropertiesContainer";
import PropertyContainer from "./property/PropertyContainer";
import PropertyDetailContainer from "./property/PropertyDetailContainer";
import ReviewListingsContainer from "./listing/ReviewListingsContainer";
import Snackbar from "../../common/js/components/KMSnackbar";
import DocumentAdminLandingContainer from "./document-type/DocumentAdminLandingContainer";
import DocumentTypeAdminContainer from "./document-type/DocumentTypeAdminContainer";
import CreateDocumentTypeContainer from "./document-type/CreateDocumentTypeContainer";
import Notification from "../../common/js/components/Notification";
import StyleGuideApp from "../../styleguide/StyleGuide";
import TrainingVideos from "./admin/TrainingVideos";
import TransactionsContainer from "./debt-equity/TransactionsContainer";
import UserListingsContainer from "./admin/UserListingsContainer";
import CompaniesContainer from "./admin/CompaniesContainer";
import CompanyContainer from "./admin/CompanyContainer";
import AuditSearchContainer from "./audit/AuditSearchContainer";
import Users from "./users/Users";
import VoucherContainer from "./voucher/VoucherContainer";
import VouchersContainer from "./vouchers/VouchersContainer";
import ComplianceRuleContainer from "./compliance/ComplianceRuleContainer";
import appProperties from "../../appProperties";
import authOperations from "./auth/ducks/operations";
import searchOperations from "./search/ducks/operations";
import compOperations from "./comp/ducks/operations";
import listingOperations from "./listing/ducks/operations";
import utils from "./utils/index";
import compact from "lodash/compact";
import "../../common/styles/components/Buttons.css";

const User = Authorization(["ROLE_USER"]);
let AuthorizedUsers;
let AuthorizedVoucher;

if (appProperties.profile === "dev") {
    AuthorizedUsers = Users;
    AuthorizedVoucher = VoucherContainer; // change to container later
} else {
    AuthorizedUsers = User(Users);
    AuthorizedVoucher = User(VoucherContainer); // change to container later
}

const App = (props) => {
    const {
        authenticated,
        getUserProfile,
        location,
        redirectUrl,
        notification,
        snackbar,
        toggleSnackBar,
        toggleNotification,
    } = props;

    useEffect(() => {
        getUserProfile(compact(location.pathname.split("/")));
    }, []);

    // in the styleguide
    // todo fix sub paths (i.e. /styleguide/button)... this could be done better
    // todo figure out if we don't have to have two routes here. 1 to catch the "root" of styleguide, the other to catch sub routes
    // Could be temporary. Unsure of how production will deploy the overall application.
    if (location.pathname.indexOf("/styleguide") >= 0) {
        return (
            <div className="styleguide">
                <Route path="/styleguide" exact component={StyleGuideApp} />
                <Route path="/styleguide/*" exact component={StyleGuideApp} />
            </div>
        );
    }

    const navDrawerWrapperStyles = {
        backgroundColor: "#233A47",
        maxHeight: "100vh",
        position: "sticky",
        top: 0,
    };

    return (
        <div>
            {authenticated && (
                <div className="App">
                    <Grid container spacing={0} style={{ flexWrap: "nowrap" }} className="exogrid">
                        <Grid item style={navDrawerWrapperStyles} xs={"200px"}>
                            <NavDrawer {...props} />
                        </Grid>
                        <Grid item xs>
                            <AppBar {...props} />
                            <Grid container spacing={0}>
                                <Grid item xs={6} sm={9} md={12}>
                                    <div
                                        className="appContainer"
                                        style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}
                                    >
                                        <Route path="/vouchers" component={VouchersContainer} />
                                        <Route path="/voucher/:id" component={AuthorizedVoucher} />
                                        <Route path="/comp/:id?" exact component={CompContainer} />
                                        <Route path="/comps" component={Comps} />
                                        <Route path="/users" component={AuthorizedUsers} />
                                        <Route path="/deals" component={DealsContainer} />
                                        <Route path="/deal/:id" component={DealSummaryContainer} />
                                        <Route path="/consulting/:id" component={ConsultingDealContainer} />
                                        <Route path="/properties" component={ReviewPropertiesContainer} />
                                        <Route path="/property/:id?" component={PropertyContainer} />
                                        <Route path="/propdetail/:id" component={PropertyDetailContainer} />
                                        <Route path="/listing/:id?" exact component={ListingRequestContainer} />
                                        <Route
                                            path="/listing-beta/:id?"
                                            exact
                                            component={ListingRequestContainerBeta}
                                        />
                                        <Route path="/listings" exact component={ReviewListingsContainer} />
                                        <Route path="/listings/review" exact component={ReviewListingsContainer} />
                                        <Route path="/dashboard" exact component={DashboardContainer} />
                                        <Route path="/admin/user/:id" exact component={AdminContainer} />
                                        <Route path="/admin/users" exact component={UserListingsContainer} />
                                        <Route path="/admin/companies" exact component={CompaniesContainer} />
                                        <Route path="/company/:id?" component={CompanyContainer} />
                                        <Route
                                            path={["/contacts/:id", "/contacts/create"]}
                                            exact
                                            component={AddContactContainer}
                                        />
                                        <Route path="/contacts" exact component={CrmContactsContainer} />
                                        <Route
                                            path={["/transactions/:id", "/transactions/create"]}
                                            exact
                                            component={EditTransactionContainer}
                                        />
                                        <Route path="/transactions" exact component={TransactionsContainer} />
                                        <Route path="/training-content" exact component={TrainingVideos} />
                                        <Route path="/document-type" exact component={DocumentAdminLandingContainer} />
                                        <Route
                                            path="/document-type/document/:id"
                                            component={DocumentTypeAdminContainer}
                                        />
                                        <Route path="/document-type/create" component={CreateDocumentTypeContainer} />
                                        <Route path="/audit/" component={AuditSearchContainer} />
                                        <Route path="/parent-type/:id" component={ComplianceRuleContainer} />
                                        {/* external links */}
                                        <Route path="/power-bi" />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Snackbar
                        open={snackbar.open}
                        message={snackbar.message}
                        variant={snackbar.variant}
                        vertical="top"
                        horizontal="center"
                        autoHideDuration={3000}
                        onClose={(event, reason) => {
                            if (reason === "clickaway") {
                                return;
                            }
                            toggleSnackBar(false, snackbar.variant, snackbar.message);
                        }}
                    />
                    <Notification
                        open={notification.open}
                        message={notification.message}
                        autoHideDuration={2000}
                        onClose={(event, reason) => {
                            if (reason === "clickaway") {
                                return;
                            }
                            toggleNotification(false, notification.message);
                        }}
                    />
                </div>
            )}
            {redirectUrl && <ExternalRedirect redirectUrl={redirectUrl} />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userProfile: state.authReducer.userProfile,
        authenticatedUserProfile: state.authReducer.authenticatedUserProfile,
        snackbar: state.utilsReducer.snackbar,
        notification: state.utilsReducer.notification,
        role: state.authReducer.userProfile.role,
        authenticated: state.authReducer.authenticated,
        redirectUrl: state.authReducer.redirectUrl,
        user: state.authReducer.userProfile.user,
        firstName: state.authReducer.userProfile.firstName,
        lastName: state.authReducer.userProfile.lastName,
        authState: state.authReducer,
        elementsSaving: state.utilsReducer.elementsSaving,
        onSearchPage: state.searchReducer.onSearchPage,
        onGroupPage: state.searchReducer.onGroupPage,
        compSearchCleared: state.searchReducer.COMPS.searchCleared,
        listingSearchCleared: state.searchReducer.LISTINGS.searchCleared,
        compSearchCriteria: state.searchReducer.COMPS.searchCriteria,
        propertySearchCriteria: state.searchReducer.PROPERTY.searchCriteria,
        listingSearchCriteria: state.searchReducer.LISTINGS.searchCriteria,
        listingSearchRun: state.searchReducer.LISTINGS.searchRun,
        compSearchRun: state.searchReducer.COMPS.searchRun,
        propSearchRun: state.searchReducer.PROPERTY.searchRun,
        listingCurrentGroup: state.searchReducer.LISTINGS.currentGroup,
        compCurrentGroup: state.searchReducer.COMPS.currentGroup,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackBar: (open, variant, message) => {
            dispatch(utils.operations.snackbar(open, variant, message));
        },
        toggleNotification: (open, message) => {
            dispatch(utils.actions.notification(open, message));
        },
        changeProfile: (value) => {
            dispatch(authOperations.changeProfile(value));
        },
        getUserProfile: (currentPath) => {
            dispatch(authOperations.getUserProfile(currentPath));
        },
        changeUser: (value) => {
            dispatch(authOperations.changeUser(value));
        },
        switchUserProfile: (userAccountKey) => {
            dispatch(authOperations.switchUserProfile(userAccountKey));
        },
        viewSavedSearches: (searchType, userAccountKey) => {
            dispatch(searchOperations.viewSavedSearches(searchType.toUpperCase(), userAccountKey));
        },
        handleSearchSubmit: (searchType) => {
            if (searchType === "COMPS") {
                dispatch(compOperations.searchComps());
            } else if (searchType === "LISTINGS") {
                dispatch(listingOperations.searchListings("all_km_listings"));
            } else if (searchType === "PROPERTY_MODAL") {
                dispatch(searchOperations.executeSearch("property_modal"));
            } else {
                dispatch(searchOperations.executeSearch("PROPERTY"));
            }
            dispatch(searchOperations.storeSearchRun(true, toUpper(searchType)));
        },
        updateSearchTerm: (value, searchType) => {
            dispatch(searchOperations.updateFilter("searchTerm", value, searchType));
        },
        updateFilter: (field, value) => {
            dispatch(searchOperations.updateFilter(field, value, "PROPERTY"));
        },
        getSavedGroupsForUser: (searchType, userAccountKey) => {
            dispatch(searchOperations.getSavedGroupsForUser(userAccountKey, searchType));
        },
        toggleNameSearchModal: (open) => {
            dispatch(searchOperations.toggleNameSearchModal(open));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
