import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Comments, SaveAndExit } from "../../../common/js/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DropdownButton, SplitButton } from "react-bootstrap";
import MenuItem from "@material-ui/core/MenuItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import constants from "./constants";
import get from "lodash/get";
import includes from "lodash/includes";
import { red } from "@material-ui/core/colors";

const styles = () => ({
    pageToolbar: {
        padding: "10px 0px",
        backgroundColor: "#fafafa",
        position: "sticky",
        top: "62px",
        margin: "0 0 20px 0",
        width: "100%",
        zIndex: 1,
    },
    progress: {
        paddingRight: "100px",
        paddingLeft: "100px",
    },
    progressDiv: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
    },
    deleteButton: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800],
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
});

const ListingToolbar = (props) => {
    const {
        userProfile,
        listing,
        classes,
        elementsSaving,
        numComments,
        handleSubmit,
        toggleConfirm,
        confirmDialog,
        toggleComments,
        handleSave,
        handleSaveAndExit,
        brokerSubmitListingRequest,
        disableInputs,
        handleDisplayInactivateDialog,
        inactivateListingDialog,
        handleInactivateListing,
        handleDisplayDeleteDialog,
        handleDeleteListing,
        deleteListingDialog,
        handleDuplicateListing,
        handleListingDuplicateDialog,
        duplicateListingDialog,
        duplicateSuccessDialog,
        handleDuplicateSuccessDialog,
        usedDocTypes,
        copyPropertyPhotoToListing,
        usedPropDocTypes,
    } = props;

    const handleCancel = () => {
        toggleConfirm(null);
    };

    const submitActions = {
        broker: {
            Draft: {
                buttonText: "Submit",
                submit: () => {
                    brokerSubmitListingRequest(false);
                },
                variant: "contained",
                confirm: true,
            },
            "Broker Action Required": {
                buttonText: "Submit",
                submit: (callback) => {
                    handleSubmit(true, constants.urls.broker_submit(listing.listingKey), true);
                },
                variant: "contained",
                confirm: false,
                url: constants.urls.broker_submit,
            },
        },
        dataEntry: {
            "Needs Review": {
                buttonText: "Start",
                submit: () => {
                    handleSubmit(false, constants.urls.assign_listing_for_review(listing.listingKey), true);
                },
                confirm: false,
                url: constants.urls.assign_listing_for_review,
            },
            "Update Requested": {
                buttonText: "Update",
                submit: (callback) => {
                    handleSubmit(true, callback);
                },
                confirm: true,
                options: {
                    "Update Action Required": constants.urls.reassign_active_listing_to_broker,
                    "Update & Approve": constants.urls.approve_listing,
                    "Update & Set As Pending": constants.urls.pending_listing,
                },
            },
            "In Review": {
                buttonText: "In Review",
                submit: (callback) => {
                    handleSubmit(true, callback);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_listing_to_broker,
                    Approve: constants.urls.approve_listing,
                },
            },
        },
        dataAdmin: {
            "Needs Review": {
                buttonText: "Start",
                submit: () => {
                    handleSubmit(false, constants.urls.assign_listing_for_review(listing.listingKey), true);
                },
                confirm: false,
                url: constants.urls.assign_listing_for_review,
            },
            "Update Requested": {
                buttonText: "Update",
                submit: (callback) => {
                    handleSubmit(true, callback);
                },
                confirm: true,
                options: {
                    "Update Action Required": constants.urls.reassign_active_listing_to_broker,
                    "Update & Approve": constants.urls.approve_listing,
                    "Update & Set As Pending": constants.urls.pending_listing,
                },
            },
            "In Review": {
                buttonText: "In Review",
                submit: (callback) => {
                    handleSubmit(true, callback);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_listing_to_broker,
                    Approve: constants.urls.approve_listing,
                },
            },
        },
    };

    const actionRole = includes(["dataEntry", "dataAdmin"], userProfile.role) ? userProfile.role : "broker";
    const mainAction = get(submitActions, `${actionRole}.${listing.status}`, null);

    const confirm = (url) => {
        toggleConfirm(url(listing.listingKey));
    };

    const brokerDraftMissingPriPhoto =
        !includes(usedDocTypes, "PRIMARY_PHOTO") && actionRole === "broker" && listing.status === "Draft";
    const propertyPhotoExist = includes(usedPropDocTypes, "PRIMARY_PHOTO");

    const canInactivate =
        includes(["Active", "Update Requested", "Pending"], get(listing, "status", false)) &&
        (get(listing, "nonKmListingFlg") ? true : includes(["dataAdmin", "dataEntry"], userProfile.role));

    const kmCanDelete =
        !includes(["Active"], get(listing, "status", null)) &&
        (includes(["dataAdmin", "dataEntry"], userProfile.role) ||
            userProfile.user === get(listing, "auditCreatedBy", null));
    const nonKmCanDelete =
        get(listing, "nonKmListingFlg", false) &&
        includes(["appraiser", "dataAdmin", "dataEntry", "research", "researchAdmin"], userProfile.role);

    return (
        <React.Fragment>
            <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
                <Grid item>
                    <h2 className="page-title">Listing Request Form</h2>
                    <p className="page-subtitle">
                        {get(listing, "attributes.useType")} {get(listing, "listingType")}
                    </p>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center" spacing={0}>
                        {canInactivate && (
                            <Grid item>
                                {elementsSaving.listingInactivate ? (
                                    <div className={classes.progressDiv}>
                                        <CircularProgress size={20} color="secondary" />
                                    </div>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleDisplayInactivateDialog(true)}
                                    >
                                        Inactivate
                                    </Button>
                                )}
                            </Grid>
                        )}
                        <Grid item>
                            {elementsSaving.listingDuplicate ? (
                                <div className={classes.progressDiv}>
                                    <CircularProgress size={20} color="primary" />
                                </div>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleListingDuplicateDialog(true)}
                                >
                                    Duplicate
                                </Button>
                            )}
                        </Grid>
                        {(kmCanDelete || nonKmCanDelete) && (
                            <Grid item>
                                {elementsSaving.listingDelete ? (
                                    <div className={classes.progressDiv}>
                                        <CircularProgress size={20} color="secondary" />
                                    </div>
                                ) : (
                                    <Button
                                        variant="contained"
                                        className={classes.deleteButton}
                                        onClick={() => handleDisplayDeleteDialog(true)}
                                    >
                                        Delete Listing
                                    </Button>
                                )}
                            </Grid>
                        )}
                        <Grid item className={classes.leftIcon}>
                            <Button onClick={toggleComments}>
                                <Comments />
                                &nbsp;Comments ({numComments})
                            </Button>
                        </Grid>
                        <Grid item>
                            {elementsSaving.listingSaveAndExit ? (
                                <div className={classes.progressDiv}>
                                    <CircularProgress size={20} color="primary" />
                                </div>
                            ) : (
                                <SplitButton
                                    title="Save"
                                    bsStyle="default"
                                    disabled={disableInputs}
                                    onClick={handleSave}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleSaveAndExit}
                                        disabled={disableInputs}
                                    >
                                        <SaveAndExit />
                                        Save & Exit
                                    </Button>
                                </SplitButton>
                            )}
                        </Grid>
                        <Grid item>
                            {elementsSaving.listingSubmit ? (
                                <div className={classes.progressDiv}>
                                    <CircularProgress size={20} color="primary" />
                                </div>
                            ) : mainAction && mainAction.options ? (
                                <DropdownButton title={get(mainAction, "buttonText")} pullRight>
                                    {Object.keys(get(mainAction, "options", {})).map((x, i) => {
                                        let url = get(mainAction, `options.${x}`);
                                        return (
                                            <MenuItem
                                                className={classes.menuSubItem}
                                                style={{ fontSize: "14px" }}
                                                onClick={() => confirm(url)}
                                            >
                                                {x}
                                            </MenuItem>
                                        );
                                    })}
                                </DropdownButton>
                            ) : mainAction !== null ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => (mainAction.confirm ? toggleConfirm() : mainAction["submit"]())}
                                >
                                    {mainAction.buttonText}
                                </Button>
                            ) : (
                                listing && (
                                    <Button disabled variant="contained" color="secondary">
                                        {listing.status}
                                    </Button>
                                )
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={confirmDialog.open && !brokerDraftMissingPriPhoto}
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
            >
                <DialogTitle id="confirmation-dialog-title">Continue?</DialogTitle>
                <DialogContent>Are you sure you want to submit this status change?</DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => mainAction["submit"](confirmDialog.onSubmit)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={confirmDialog.open && brokerDraftMissingPriPhoto && propertyPhotoExist}
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
            >
                <DialogTitle id="confirmation-dialog-title">Missing Primary Photo?</DialogTitle>
                <DialogContent>
                    Since you did not attach a Primary Photo, the Property Photo will be used by default. Click "OK" to
                    continue or "Back" to add a Primary Photo.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Back
                    </Button>
                    <Button onClick={() => copyPropertyPhotoToListing(listing.listingKey)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={confirmDialog.open && brokerDraftMissingPriPhoto && !propertyPhotoExist}
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
            >
                <DialogTitle id="confirmation-dialog-title">Continue?</DialogTitle>
                <DialogContent>
                    You did not attach a Primary Photo, and the Property associated with this Listing does not have a
                    photo. Click "OK" to have the Corporate Data Team add your Primary Photo or "Back" to add a Primary
                    Photo.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Back
                    </Button>
                    <Button onClick={() => mainAction["submit"](confirmDialog.onSubmit)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={inactivateListingDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Inactivate Listing?</DialogTitle>
                <DialogContent>Are you sure you want to permanently inactivate this listing?</DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDisplayInactivateDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleInactivateListing(listing.listingKey)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteListingDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Delete Listing?</DialogTitle>
                <DialogContent>
                    Are you sure you want to permanently delete this listing? The listing cannot be recovered once it
                    has been deleted.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDisplayDeleteDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteListing(listing.listingKey)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={duplicateListingDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Duplicate Listing?</DialogTitle>
                <DialogContent>
                    Are you sure you want to create a new Listing Request with the same information as this Listing?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDuplicateListing(listing.listingKey)} color="primary">
                        Yes
                    </Button>
                    <Button onClick={() => handleListingDuplicateDialog(false)} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={duplicateSuccessDialog.open} maxWidth="s" aria-labelledby="confirmation-dialog-title">
                <DialogContent>You are now viewing your new duplicated Listing.</DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDuplicateSuccessDialog(false)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default withStyles(styles)(ListingToolbar);
