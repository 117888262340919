import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
import { get, includes } from "lodash";
import {
    CircularProgress,
    Divider,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
} from "@material-ui/core";
import AutosaveSuccess from "@material-ui/icons/CheckCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import queryString from "query-string";
import EditIcon from "@material-ui/icons/Edit";
import Comments from "../../comments/CommentsContainer";
import comments_operations from "../../comments/ducks/operations";
import comments_constants from "../../comments/constants";
import constants from "../constants";
import contactConstants from "../../contact/constants";
import contactOperations from "../../contact/ducks/operations";
import document_operations from "../../documents/ducks/operations";
import DocumentDrop from "../../documents/beta/DocumentDropContainerBeta";
import ListingToolbar from "../ListingToolbar";
import ListingRequest from "./ListingRequestBeta";
import operations from "../ducks/operations";
import Property from "../../property/beta/PropertyBeta";
import property_operations from "../../property/ducks/operations";
import PropertyUpdateModalContainer from "../../property/PropertyUpdateModalContainer";
import searchOperations from "../../search/ducks/operations";
import utils from "../../utils/index";

const ListingRequestContainer = (props) => {
    const [showRequestUpdate, setShowRequestUpdate] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);

    useEffect(() => {
        const { id } = props.match.params;
        const queryParams = queryString.parse(props.location.search);
        const action = get(queryParams, "action", null);
        if (id) {
            if (
                action === "start" &&
                (props.userProfile.role === "dataAdmin" || props.userProfile.role === "dataEntry")
            ) {
                props.handleSubmit(false, constants.urls.assign_listing_for_review(id), true, id);
            } else {
                props.initListing(id, action);
            }
        }

        ReactGA.send({ hitType: "pageview", page: "/listing-beta/*", title: `Listing Request: ${id}` });

        return () => {
            props.clearListingState();
            props.clearUtilsErrors();
        };
    }, []);

    const shouldDisableListingInputs = () => {
        let disableListingInput = props.disableInputs;
        if (get(props.listing, "nonKmListingFlg", false) === true) {
            // is outside listing
            if (
                get(props.listing, "status") === "Update Requested" &&
                includes(["dataEntry", "dataAdmin"], get(props.userProfile, "role"))
            ) {
                disableListingInput = false;
            } else if (get(props.listing, "status") === "Active") {
                // Requirement 6.10.19 anyone can edit active outside listings
                disableListingInput = false;
            } else {
                disableListingInput = get(props.listing, "auditCreatedBy", null) !== get(props.userProfile, "user");
            }
        } else if (props.listing.status === "Draft" || props.listing.status === "Broker Action Required") {
            disableListingInput = get(props.listing, "auditCreatedBy", null) !== get(props.userProfile, "user");
        }
        return disableListingInput;
    };

    const { id } = props.match.params;
    const disableListingInput = shouldDisableListingInputs();

    const setRequestUpdateAndSubmit = (listing, userProfile) => {
        const activeStatus = ["Update Requested", "Active Broker Action Required", "Active", "Pending"].includes(
            listing.status
        );
        const dataAdminOrDataEntry = ["dataAdmin", "dataEntry"].includes(userProfile.role);
        const kmListing = !listing.nonKmListingFlg;

        if (activeStatus && dataAdminOrDataEntry && kmListing) {
            setShowRequestUpdate(false);
            setShowSubmit(true);
        } else if (activeStatus && kmListing) {
            setShowRequestUpdate(true);
            setShowSubmit(false);
        } else if (activeStatus && !kmListing) {
            setShowRequestUpdate(true);
            setShowSubmit(true);
        } else {
            setShowRequestUpdate(false);
            setShowSubmit(true);
        }
    };

    return (
        <div>
            <div style={{ minWidth: "800px" }}>
                <ListingToolbar {...props} disableInputs={disableListingInput} />
                <div>
                    <div>
                        <div>
                            {props.listing.listingKey ? (
                                <DocumentDrop
                                    entityKey={id}
                                    type={"LISTING"}
                                    documentTypes={
                                        get(props.listing, "nonKmListingFlg") ? "listingFormOutside" : "listingForm"
                                    }
                                    options={props.determineDocumentOptions}
                                    formDisabled={props.disableListingInput}
                                    errors={get(props.errors, "listing.documents")}
                                    parentType={get(props.listing, "nonKmListingFlg") ? "OUTSIDE LISTING" : "LISTING"}
                                    stateCode={
                                        get(props.listing, "attributes.address.city.state.stateCode", null)
                                            ? get(props.listing, "attributes.address.city.state.stateCode")
                                            : get(props.property, "primaryAddress.city.state.stateCode", null)
                                    }
                                />
                            ) : (
                                <div className="circularProgressCentered">
                                    <CircularProgress />
                                </div>
                            )}
                        </div>
                        <ExpansionPanel className="expansionPanelBorder">
                            {(get(props.userProfile, "role") === "dataAdmin" &&
                                get(props.listing, "status") === "Active") ||
                            (includes(["dataEntry", "dataAdmin"], get(props.userProfile, "role")) &&
                                includes(["Active", "Inactive"], get(props.listing, "status"))) ||
                            disableListingInput === false ? (
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <AutosaveSuccess className="waterloo" style={{ paddingTop: "4px" }} />
                                    <div style={{ maxWidth: "225px" }}>
                                        <div style={{ paddingTop: "4px", paddingLeft: "30px" }}>
                                            Property Information
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            style={{ paddingTop: "6px", paddingLeft: "0px" }}
                                            onClick={() => props.togglePropertySearchModal(true)}
                                        >
                                            <EditIcon style={{ width: "18px", height: "18px", fill: "#233A47" }} />
                                        </div>
                                    </div>
                                </ExpansionPanelSummary>
                            ) : (
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <AutosaveSuccess className="waterloo" style={{ paddingTop: "4px" }} />
                                    <div style={{ paddingTop: "4px", paddingLeft: "30px" }}>Property Information</div>
                                </ExpansionPanelSummary>
                            )}
                            <Divider />
                            <ExpansionPanelDetails>
                                {props.isLoading ? ( // Spinner
                                    <Grid container justify="center">
                                        <CircularProgress color="primary" />
                                    </Grid>
                                ) : (
                                    <Property
                                        {...props}
                                        attributes={props.propertyAttributes}
                                        handleInputChange={props.handlePropertyInputChange}
                                        handleAddArrayElement={props.handlePropertyAddArrayElement}
                                        handleUpdateArrayElement={props.handlePropertyUpdateArrayElement}
                                        handleDeleteArrayElement={props.handlePropertyDeleteArrayElement}
                                        disableInputs={true}
                                        userProfile={props.userProfile}
                                    />
                                )}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ListingRequest
                            {...props}
                            autocompleteResults={props.autocompleteResults}
                            handleSearchCompany={props.handleSearchCompany}
                            handleDeleteArrayElement={props.handleListingDeleteArrayElement}
                            handleAddArrayElement={props.handleAddArrayElement}
                            handleDeleteArrayElementNEW={props.handleDeleteArrayElementNEW}
                            handleUpdateArrayElement={props.handleListingUpdateArrayElement}
                            handleListingUpdateArrayElement={props.handleUpdateArrayElement}
                            data={props.listing}
                            property={props.property}
                            disableInputs={disableListingInput}
                        />
                    </div>

                    <div style={{ minWidth: "50%" }}>
                        <Comments
                            {...props}
                            showRequestUpdateListing={showRequestUpdate}
                            showSubmitListing={showSubmit}
                            setRequestUpdateAndSubmit={setRequestUpdateAndSubmit}
                            parentRecordType={comments_constants.LISTNG_REQUEST_PARENT_RECORD_TYPE}
                            parentRecordId={id}
                        />
                    </div>
                </div>
            </div>
            <div>
                <PropertyUpdateModalContainer {...props} formType="listing" />
            </div>
        </div>
    );
};

const determineDocumentOptions = (user, role, listing, personKey) => {
    const isCDT = includes(["dataEntry", "dataAdmin"], role);
    const isEVP = includes(["evp"], role);
    const isCreatedByUser = user === listing.auditCreatedBy;
    let isBroker = false;
    listing.listingBrokers &&
        listing.listingBrokers.kmListingBrokers.forEach((x) => {
            if (get(x, "brokerAgentKey.person.personKey", null) != null) {
                if (personKey === x.brokerAgentKey.person.personKey) {
                    isBroker = true;
                }
            }
        });

    const listingDocTypes = [
        "LISTING_AGREEMENT",
        "AMENDED_LISTING_AGREEMENT",
        "PHOTO",
        "FLOOR_PLAN",
        "PRIMARY_PHOTO",
        "FLYER",
        "SECONDARY_FLYER",
        "OTHER",
        "AMENITIES",
    ];
    const officeManagerDocTypes = [
        "PHOTO",
        "FLOOR_PLAN",
        "PRIMARY_PHOTO",
        "FLYER",
        "SECONDARY_FLYER",
        "OTHER",
        "AMENITIES",
    ];

    const allDocTypes = [
        "COMMISSION_PAYMENT",
        "INVOICE",
        "W9",
        "AMENITIES",
        "FLOOR_PLAN",
        "FLYER",
        "PHOTO",
        "PRIMARY_PHOTO",
        "AIR_PROPERTY_INFORMATION",
        "ANY_OFFER",
        "AMENDMENTS",
        "COMMISSION_STATEMENT",
        "CORRESPONDENCE",
        "DEPOSIT_HISTORY",
        "EARTHQUAKE_DISCLOSURE",
        "EXHIBITS",
        "FULLY_EXECUTED_LEASE",
        "MASTER_LEASE_IF_SUBLEASE",
        "RELEVANT_CORRESPONDENCE",
        "CLOSING_STATEMENT_BUYERS",
        "CLOSING_STATEMENT_BUYER_NOT_REPRESENTED",
        "CLOSING_STATEMENT_SELLERS",
        "CLOSING_STATEMENT_SELLERS_NOT_REPRESENTED",
        "CONTINGENCY_REMOVAL",
        "EARNEST_MONEY_OR_EQUIVALENT",
        "ESCROW_INSTRUCTIONS",
        "ESCROW_RECEIPT",
        "FIRPTA",
        "PRELIM_TITLE_REPORT",
        "PURCHASE_AND_SALE_AGREEMENT",
        "SELLERS_DISCLOSURE",
        "TERMINATION_DOCUMENT",
        "TERMITE_INSPECTION_REPORT",
        "BANK_STATEMENT",
        "INSURANCE",
        "LLC_INFORMATION",
        "PROPERTY_NUMBERS",
        "TAXES",
        "THIRD_PARTY_REPORT",
        "TITLE",
        "LISTING_AGREEMENT",
        "AMENDED_LISTING_AGREEMENT",
        "PHOTO",
        "FLOOR_PLAN",
        "PRIMARY_PHOTO",
        "FLYER",
        "SECONDARY_FLYER",
        "MARKETING_FLYER_PHOTO",
        "EXCLUSIVE",
        "OFFERING_MEMORANDUM",
        "OTHER",
        "AMENITIES",
    ];

    let options = {
        canView: [],
        canDelete: [],
        canUpload: [],
    };

    /**
     * Requirement from 5.23.19:
     *
     * For Listings in Draft: Only creator can upload, view delete
     * For Active Listings: Anyone can upload, anyone can delete PRIMARY_PHOTO and FLYER
     * For Inactive Listings: View only, LISTING_AGREEMENT, EXCLUSIVE, OTHER only by creator, dataEntry/dataAdmin
     *
     * Document types: LISTING_AGREEMENT, EXCLUSIVE, OTHER can only ever be viewed/modified by creator or dataEntry/dataAdmin
     *
     */

    // Needs to be the user who created the listing
    if (includes(["Draft", "Broker Action Required"], listing.status)) {
        if (isCreatedByUser) {
            options.canView = [...allDocTypes];
            options.canDelete = [...listingDocTypes, "EXCLUSIVE"];
            options.canUpload = [...listingDocTypes];
        } else if (isEVP) {
            options.canView = [...allDocTypes];
        } else if (isBroker) {
            options.canView = [...allDocTypes];
            options.canDelete = [...listingDocTypes];
            options.canUpload = [...listingDocTypes];
        } else {
            options = null;
        }
    } else if (includes(["Needs Review", "In Review", "Admin Action Required", "Final Review"], listing.status)) {
        if (isCreatedByUser || isCDT) {
            options.canView = [...allDocTypes];
            options.canDelete = [...listingDocTypes, "EXCLUSIVE"];
            options.canUpload = [...listingDocTypes];
        } else if (isEVP) {
            options.canView = [...allDocTypes];
        } else if (isBroker) {
            options.canView = [...listingDocTypes, "EXCLUSIVE"];
            options.canDelete = [...listingDocTypes, "EXCLUSIVE"];
            options.canUpload = [...listingDocTypes];
        } else {
            options = null;
        }
    } else if (includes(["Active", "Available", "Inactive", "Update Requested"], listing.status)) {
        if (isCreatedByUser || isCDT) {
            options.canView = [...allDocTypes];
            options.canDelete = [...listingDocTypes, "EXCLUSIVE"];
            options.canUpload = [...listingDocTypes];
        } else if (isEVP) {
            options.canView = [...allDocTypes];
        } else if (isBroker) {
            options.canView = [...allDocTypes];
            options.canDelete = ["PRIMARY_PHOTO", "FLYER", "SECONDARY_FLYER", "AMENITIES"];
            options.canUpload = [...listingDocTypes];
            options.disallowOtherOption = true;
        } else if (role === "officeManager") {
            options.canView = [...allDocTypes];
            options.canDelete = [...officeManagerDocTypes];
            options.canUpload = [...officeManagerDocTypes];
            options.disallowOtherOption = true;
        } else {
            options.canView = ["PHOTO", "FLOOR_PLAN", "PRIMARY_PHOTO", "FLYER", "SECONDARY_FLYER", "AMENITIES"];
            options.canDelete = ["PRIMARY_PHOTO", "FLYER", "SECONDARY_FLYER", "AMENITIES"];
            options.canUpload = [...listingDocTypes];
            options.disallowOtherOption = true;
        }
    } else {
        options = null;
    }
    return options;
};

const mapStateToProps = (state) => {
    return {
        userProfile: state.authReducer.userProfile,
        errors: state.utilsReducer.errors,
        expandedErrorPanels: state.utilsReducer.panelsToExpand,
        checkErrorPanels: state.utilsReducer.checkErrorPanels,
        snackbar: state.utilsReducer.snackbar,
        numComments: state.commentsReducer.comments.length,
        confirmDialog: state.utilsReducer.confirmDialog,
        inactivateListingDialog: state.listingReducer.inactivateListingDialog,
        usedDocTypes: state.documentsReducer.usedDocTypes,
        usedPropDocTypes: state.documentsReducer.usedPropDocTypes,
        deleteListingDialog: state.listingReducer.deleteListingDialog,
        duplicateListingDialog: state.listingReducer.listingDuplicateDialog,
        duplicateSuccessDialog: state.listingReducer.duplicateSuccessDialog,
        panelsExpanded: get(state.listingReducer, "panelsExpanded", {}),
        requestModal: get(state.listingReducer, "requestModal", {}),
        property: get(state.listingReducer, "listing.property", {}),
        propertyAttributes: get(state.listingReducer, "listing.property.propertyAttributes", {}),
        listing: get(state.listingReducer, "listing", {}),
        listingAttributes: get(state.listingReducer, "listing.attributes", {}),
        listingKey: get(state.listingReducer, "listing.listingKey", {}),
        listingType: get(state.listingReducer, "listing.listingType", {}),
        listingContacts: get(state.listingReducer, "listing.attributes.contacts", []),
        searchResults: state.dealReducer.searchResults ? state.dealReducer.searchResults : {},
        companyInformation: state.dealReducer.companyInformation ? state.dealReducer.companyInformation : {},
        isLoading: state.utilsReducer.isLoading,
        autocompleteResults: state.searchReducer.autocompleteResults,
        listingBrokers: get(state.listingReducer, "listing.listingBrokers", [{}]),
        disableInputs: get(
            constants.disabledStatuses,
            `${state.authReducer.userProfile.role}.${state.listingReducer.listing.status}`,
            get(constants.disabledStatuses.default, state.listingReducer.listing.status)
        ),
        elementsSaving: state.utilsReducer.elementsSaving,
        determineDocumentOptions: determineDocumentOptions(
            state.authReducer.userProfile.user,
            state.authReducer.userProfile.role,
            get(state.listingReducer, "listing", {}),
            state.authReducer.userProfile.personKey
        ),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListing: (listingId) => {
            dispatch(operations.getListing(listingId));
        },
        initListing: (listingId, action) => {
            dispatch(operations.initListing(listingId, action));
        },
        handleInputChange: (field, value) => {
            dispatch(operations.handleListingInputChange(field, value));
        },
        handlePropertyInputChange: (field, value) => {
            dispatch(operations.handleListingInputChange("listing." + field, value));
        },
        toggleSnackBar: (open, variant, message) => {
            dispatch(utils.operations.snackbar(open, variant, message));
        },
        handleSave: () => {
            dispatch(operations.saveListing());
        },
        handleSaveAndExit: () => {
            dispatch(operations.saveAndExit());
        },
        toggleComments: () => {
            dispatch(comments_operations.toggleComments());
        },
        handleSubmit: (save, callback, confirmIsOpen, key) => {
            if (!confirmIsOpen) {
                dispatch(utils.operations.toggleConfirm());
            }
            dispatch(operations.handleSubmit(save, callback, confirmIsOpen, key));
        },
        toggleConfirm: (onSubmit) => {
            dispatch(utils.operations.toggleConfirm(onSubmit));
        },
        handlePropertyAddArrayElement: (arrayPath) => {
            dispatch(operations.addArrayElement("listing." + arrayPath));
        },
        handlePropertyDeleteArrayElement: (arrayPath, index) => {
            dispatch(operations.deleteArrayElement("listing." + arrayPath, index));
        },
        handlePropertyUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.handleListingInputChange("listing." + arrayPath + "[" + index + "]." + field, value));
        },
        handleListingAddArrayElement: (arrayPath, obj) => {
            dispatch(operations.addArrayElementNEW(arrayPath, obj));
        },
        handleListingDeleteArrayElement: (arrayPath, index) => {
            dispatch(operations.deleteArrayElementNEW(arrayPath, index));
        },
        handleListingUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.updateArrayElement(arrayPath, index, field, value));
        },
        handleUpdateContactInfo: (index, field, value) => {
            dispatch(contactOperations.updateContactElement(contactConstants.LISTING_CONTACTS, index, field, value));
        },
        handleAddContactInfo(contactType) {
            dispatch(contactOperations.addContactElement(contactType));
        },
        handleDeleteContactInfo(contactType, index) {
            dispatch(contactOperations.deleteContactElement(contactType, index));
        },
        handleContactInfoCompanyUpdate: (contactType, key, field, value) => {
            dispatch(contactOperations.updateContactElementCompany(contactType, key, field, value));
        },
        brokerSubmitListingRequest: (bypassConfirm) => {
            if (!bypassConfirm) {
                dispatch(utils.operations.toggleConfirm());
            }
            dispatch(operations.brokerSubmitListingRequest(true));
        },
        clearListingState: () => {
            dispatch(operations.clearListingState());
        },
        handleSearchPerson: (inputValue) => {
            dispatch(searchOperations.searchKMPersonAutocomplete(inputValue));
        },
        handleSearchKMBrokers: (inputValue) => {
            dispatch(searchOperations.searchKMBrokers(inputValue));
        },
        handleSearchCompany: (inputValue, companyType) => {
            dispatch(searchOperations.searchCompany(inputValue, companyType));
        },
        handleSearchOutsideBrokers: (inputValue) => {
            dispatch(searchOperations.searchOutsideBroker(inputValue));
        },
        handleDeleteArrayElement: (path, index) => {
            dispatch(operations.deleteArrayElement("listing." + path, index));
        },
        handleAddArrayElement: (path, obj) => {
            dispatch(operations.addArrayElementNEW("listing." + path, obj));
        },
        handleDeleteArrayElementNEW: (path, index) => {
            dispatch(operations.deleteArrayElementNEW("listing." + path, index));
        },
        handleUpdateArrayElement: (arrayPath, index, field, value) => {
            dispatch(operations.handleListingInputChange("listing." + arrayPath + "[" + index + "]." + field, value));
        },
        clearUtilsErrors: () => {
            dispatch(utils.actions.clearErrors());
        },
        handleSubmitAndUpdateStatus: (newStatus, whatIsSaving, redirectTo) => {
            dispatch(operations.handleSubmitAndUpdateStatus(newStatus, whatIsSaving, redirectTo));
        },
        handleDisplayInactivateDialog: (isOpen) => {
            dispatch(operations.displayInactivateDialog(isOpen));
        },
        handleInactivateListing: (listingKey) => {
            dispatch(operations.handleInactivateListing(listingKey));
        },
        handleDisplayDeleteDialog: (isOpen) => {
            dispatch(operations.displayDeleteDialog(isOpen));
        },
        handleDeleteListing: (listingKey) => {
            dispatch(operations.handleDeleteListing(listingKey));
        },
        handleEditProperty: (propertyKey) => {
            dispatch(operations.handleEditProperty());
        },
        toggleRequestModal: (spaceType, transactionType) => {
            dispatch(operations.toggleRequestModal(spaceType, transactionType));
        },
        togglePropertySearchModal: (isOpen) => {
            dispatch(property_operations.togglePropertySearchModal(isOpen));
        },
        handleDuplicateListing: (listingKey) => {
            dispatch(operations.duplicateListing(listingKey));
        },
        handleListingDuplicateDialog: (isOpen) => {
            dispatch(operations.toggleDuplicateListingDialog(isOpen));
        },
        handleDuplicateSuccessDialog: (isOpen) => {
            dispatch(operations.toggleDuplicateSuccessDialog(isOpen));
        },
        getPropertyDocTypes: (listingId) => {
            dispatch(document_operations.getPropertyDocTypes(listingId));
        },
        copyPropertyPhotoToListing: (listingId) => {
            dispatch(document_operations.copyPropertyPhotoToListing(listingId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingRequestContainer);
