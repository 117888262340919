import React from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import Help from "@material-ui/icons/Help";
import numeral from "numeral";
import utils from "../../../app/js/utils/index";
import { emptyValues } from "../../../app/js/utils/constants";

class BSInput extends React.Component {
    componentDidMount() {
        this.handleOnBlur();
    }

    handleOnBlur = () => {
        if (emptyValues.has(this.props.value) || !this.props.onChange) return;

        const dummyEvent = {
            target: {
                id: this.props.id,
                value: this.formatValue(this.props.value),
            },
        };

        this.props.onChange(dummyEvent);
    };

    formatValue = (value) => {
        if (emptyValues.has(value)) return null;

        // Date
        if (this.props.type === "date") return utils.operations.formatDate("YYYY-MM-DD")(value);
        // Integer or Numeric
        if (this.props.integer || this.props.numeric) return this.formatNumber(value, this.props.integer);
        // Phone Number
        if (this.props.phone) return utils.operations.formatPhoneNumber(value);

        return value;
    };

    formatNumber = (value, isInteger = false) => {
        return isInteger ? numeral(value).format("0,000") : numeral(value).format("0,000.00");
    };

    handleChange = (e) => {
        if (emptyValues.has(e.target.value)) {
            const dummyEvent = { target: { id: this.props.id, value: null } };
            this.props.onChange(dummyEvent);
            return;
        }
        this.props.onChange(e);
    };

    render() {
        const {
            checked,
            checkbox,
            checkboxVal,
            disableCheckbox,
            classes,
            className,
            disabled,
            endAdornment,
            error,
            errorText,
            extraFormControlStyles,
            extraInputStyles,
            formcontrolClass,
            helperText,
            helperTextNotRequired,
            id,
            inputProps,
            label,
            multiline,
            noFullWidth,
            onClick,
            onChangeCheckbox,
            onFocus,
            onKeyPress,
            placeholder,
            rows,
            startAdornment,
            startAdornmentStyles,
            toolTipText,
            type,
            value,
        } = this.props;

        return (
            <FormControl
                fullWidth={!noFullWidth}
                className={formcontrolClass}
                classes={{ root: classes.formControl }}
                style={extraFormControlStyles}
            >
                {label && (
                    <InputLabel shrink htmlFor={id} className={classes.bootstrapFormLabel}>
                        {label}
                        {checkbox && (
                            <input
                                type="checkbox"
                                disabled={disableCheckbox}
                                onChange={onChangeCheckbox}
                                checked={checked}
                                value={checkboxVal}
                                className={classes.checkboxStyles}
                            />
                        )}
                        {toolTipText && (
                            <span className={classes.toolTipClass}>
                                <React.Fragment>
                                    <span data-tip data-for={`${id}-tool-tip`}>
                                        <Help style={{ fontSize: "110%" }} />
                                    </span>
                                    <ReactTooltip
                                        id={`${id}-tool-tip`}
                                        place="top"
                                        type="dark"
                                        effect="solid"
                                        className="statBoxTooltip"
                                        html={true}
                                    >
                                        {toolTipText}
                                    </ReactTooltip>
                                </React.Fragment>
                            </span>
                        )}
                    </InputLabel>
                )}
                <Input
                    id={id}
                    value={type === "date" ? utils.operations.formatDate("YYYY-MM-DD")(value) : value}
                    type={type}
                    placeholder={placeholder}
                    onChange={this.handleChange}
                    disableUnderline={true}
                    autoComplete={"no"}
                    style={error ? { borderColor: "red", ...extraInputStyles } : extraInputStyles}
                    className={[classes.bootstrapInput, className]}
                    endAdornment={endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>}
                    startAdornment={
                        startAdornment && (
                            <InputAdornment position="start" style={startAdornmentStyles}>
                                {startAdornment}
                            </InputAdornment>
                        )
                    }
                    disabled={disabled}
                    multiline={multiline}
                    rows={rows}
                    onKeyPress={onKeyPress}
                    onFocus={onFocus}
                    onBlur={this.handleOnBlur}
                    onClick={onClick}
                    inputProps={inputProps}
                />
                {
                    errorText && error && (
                        <FormHelperText classes={classes} error={true}>
                            {errorText}
                        </FormHelperText>
                    ) /* Error Text */
                }
                {
                    helperText && !error && (
                        <FormHelperText
                            style={helperTextNotRequired && { color: "dimgrey" }}
                            classes={classes}
                            required={true}
                        >
                            {helperText}
                        </FormHelperText>
                    ) /* Helper Text */
                }
            </FormControl>
        );
    }
}

const styles = (theme) => ({
    bootstrapInput: {
        fontSize: 14,
        padding: "4px 6px",
        marginTop: "22px", // this is also done in index.js @ MuiInput: FormControl: label + &... but needed in case there is no label
        transition: theme.transitions.create(["border-color", "box-shadow"]),
    },
    error: {
        color: "red",
        fontStyle: "italic",
    },
    required: {
        color: "red",
        fontStyle: "italic",
    },
    labelContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        margin: 0,
        padding: 0,
        width: "100%",
    },
    formControl: {
        marginBottom: "22px",
    },
    toolTipClass: {
        lineHeight: "1.5",
    },
});

BSInput.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BSInput);
