import React from "react";
import { DropdownButton, MenuItem } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Search from "@material-ui/icons/Search";
import { get, isNil } from "lodash";
import OutsideSplitModal from "./OutsideSplitModal";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { withStyles } from "@material-ui/core/styles";
import searchConstants from "../search/constants";
import Autocomplete from "../../../common/js/components/Autocomplete";
import { representation_type_options } from "./constants";
import { Add, Subdirectory } from "../../../common/js/icons";

class BrokerCommissions extends React.Component {
    handleChange = (splitId, event) => {
        this.props.updateSplit(splitId, event.target.id, event.target.value, "voucher");
    };

    handleRunnerChange = (splitId, runnerId, event) => {
        this.props.updateRunner(splitId, runnerId, event.target.id, event.target.value);
    };

    handleCommissionSum = (event) => {
        this.props.handleVoucherChange(event);
    };

    handleBrokerPersonChange = (person, splitKey, source) => {
        this.props.updateSplit(splitKey, "brokerAgentKey", person["brokerAgentKey"], source);
        this.props.updateSplit(splitKey, "firstName", person["firstName"], source);
        this.props.updateSplit(splitKey, "lastName", person["lastName"], source);
        this.props.updateSplit(splitKey, "personKey", person["personKey"], source);
    };

    handleRunnerPersonChange = (person, splitKey, runnerKey) => {
        this.props.updateRunner(splitKey, runnerKey, "brokerAgentKey", person["brokerAgentKey"]);
        this.props.updateRunner(splitKey, runnerKey, "firstName", person["firstName"]);
        this.props.updateRunner(splitKey, runnerKey, "lastName", person["lastName"]);
        this.props.updateRunner(splitKey, runnerKey, "personKey", person["personKey"]);
    };

    createOutsideSplitDraft = (isFreeformSearchEnabled, commissionType) => {
        this.props.handleCreateOutsideSplitDraft(isFreeformSearchEnabled, commissionType);
    };

    freeformUpdate = (splitKey, value, source = "voucher") => {
        const splitName = value.split(" ");
        if (splitName.length > 1) {
            const firstName = splitName
                .slice(0, splitName.length - 1)
                .join(" ")
                .trim();
            const lastName = splitName
                .slice(splitName.length - 1)
                .join(" ")
                .trim();
            this.props.updateSplit(splitKey, "brokerAgentKey", null, source);
            this.props.updateSplit(splitKey, "firstName", firstName, source);
            this.props.updateSplit(splitKey, "lastName", lastName, source);
            this.props.updateSplit(splitKey, "personKey", null, source);
        } else {
            this.props.updateSplit(splitKey, "brokerAgentKey", null, source);
            this.props.updateSplit(splitKey, "firstName", value, source);
            this.props.updateSplit(splitKey, "lastName", "", source);
            this.props.updateSplit(splitKey, "personKey", null, source);
        }
    };

    render() {
        const {
            classes,
            errors,
            formDisabled,
            updateSplit,
            searchResults,
            autocompleteResults,
            handleAddRunner,
            outsideSplitModal,
            brokerCommissions,
            handleSearchPerson,
            handleSearchCompany,
            handleSaveOutsideSplit,
            toggleOutsideSplitModal,
            handleDeleteOutsideSplit,
            handleAddBrokerCommission,
            handleRemoveCommissionOrRunner,
            handleSearchOutsideBroker,
            handleReducerLevelInput,
            handleSearchKmBrokers,
        } = this.props;

        const departmentNames = searchConstants["departments"];
        let kmCommission = 0;
        let outsideCommission = 0;

        Object.keys(brokerCommissions).forEach((c, i) => {
            const commission = brokerCommissions[c];
            if (
                get(commission, "company.legalName", false)
                    ? get(commission, "company.legalName", null).includes("Kidder Mathews")
                    : commission.kmBrokerFlag && get(commission, "netAmount", null) != null
            ) {
                if (get(commission, "netAmount", null) != null) {
                    kmCommission =
                        kmCommission + parseFloat(get(commission, "netAmount", 0).toString().replaceAll(",", ""));
                }
            } else if (
                get(commission, "company.legalName", false)
                    ? !get(commission, "company.legalName", null).includes("Kidder Mathews")
                    : !commission.kmBrokerFlag && get(commission, "netAmount", null) != null
            ) {
                if (get(commission, "netAmount", null) != null) {
                    outsideCommission =
                        outsideCommission + parseFloat(get(commission, "netAmount", 0).toString().replaceAll(",", ""));
                }
            }
        });

        kmCommission = kmCommission.toFixed(2);
        outsideCommission = outsideCommission.toFixed(2);

        const department = (suggestion) => {
            if (
                get(suggestion, "department", null) !== null &&
                get(suggestion, "department", null) !== "" &&
                departmentNames[get(suggestion, "department", "N/A")] !== undefined
            ) {
                return departmentNames[get(suggestion, "department", "N/A")]["display"];
            } else {
                return departmentNames["N/A"]["display"];
            }
        };

        return (
            <div>
                {outsideSplitModal.key && (
                    <OutsideSplitModal
                        open={outsideSplitModal.isOpen}
                        toggleOutsideSplitModal={toggleOutsideSplitModal}
                        updateSplit={updateSplit}
                        searchResults={searchResults}
                        handleReducerLevelInput={handleReducerLevelInput}
                        handleSearchCompany={handleSearchCompany}
                        handleDeleteOutsideSplit={handleDeleteOutsideSplit}
                        saveOutsideSplit={handleSaveOutsideSplit}
                        handleSearchPerson={handleSearchPerson}
                        handleSearchOutsideBroker={handleSearchOutsideBroker}
                        handleBrokerPersonChange={this.handleBrokerPersonChange}
                        handleCompanyChange={this.handleCompanyChange}
                        values={outsideSplitModal.brokerCommissions[outsideSplitModal.key]}
                        splitId={outsideSplitModal.key}
                        secondaryAction={outsideSplitModal.secondaryAction}
                        autocompleteResults={autocompleteResults}
                        errors={get(errors, "outsideSplitModal", {})}
                        freeformUpdate={this.freeformUpdate}
                        isFreeformSearchEnabled={outsideSplitModal.isFreeformSearchEnabled}
                        commissionType={outsideSplitModal.commissionType}
                    />
                )}
                <Grid container alignItems="center" justify="space-between" spacing={8}>
                    <Grid item>
                        <h3>Installment Commission Allocation</h3>
                    </Grid>
                    {!formDisabled && (
                        <Grid item>
                            <DropdownButton title="+ Add" pullRight disabled={formDisabled}>
                                <MenuItem onClick={handleAddBrokerCommission}>Add KM Broker </MenuItem>
                                <MenuItem onClick={() => this.createOutsideSplitDraft(true, "OUTSIDE BROKER")}>
                                    Add Outside Broker
                                </MenuItem>
                                <MenuItem onClick={() => this.createOutsideSplitDraft(false, "REFERRAL")}>
                                    Add Outside Referral
                                </MenuItem>
                            </DropdownButton>
                        </Grid>
                    )}
                </Grid>
                {Object.keys(brokerCommissions).map((c, i) => {
                    const commission = brokerCommissions[c];
                    let officeName = get(commission, `brokerAgent.office.officeName`, "");
                    if (
                        get(commission, "company.legalName", null) !== null
                            ? get(commission, "company.legalName", null).includes("Kidder Mathews")
                            : commission.kmBrokerFlag
                    ) {
                        return (
                            <div className="mb-10" key={`split-${i}`}>
                                <Grid
                                    container
                                    alignItems="flex-start"
                                    spacing={0}
                                    style={{ maxHeight: "105px", marginBottom: "10px" }}
                                >
                                    <Grid item xs={6}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={7}>
                                                <Autocomplete
                                                    id="firstName"
                                                    label={"Km Broker"}
                                                    error={get(errors, `${c}.firstName`, "")}
                                                    initialInputValue={`${get(commission, `firstName`, "")}${
                                                        get(commission, `brokerAgentKey`, null) ? " " : ""
                                                    }${get(commission, `lastName`, "")}`}
                                                    itemToString={(i) => get(i, `firstName`, null)}
                                                    onSelect={(p, b) => this.handleBrokerPersonChange(p, c, "voucher")}
                                                    placeholder="Enter broker"
                                                    errorText="Required"
                                                    helperText={officeName ? `KM Office: ${officeName}` : ""}
                                                    searchFunction={handleSearchKmBrokers}
                                                    startAdornment={i !== 0 && <Search />}
                                                    suggestions={autocompleteResults}
                                                    disabled={
                                                        (!isNil(get(commission, `brokerAgentKey`, null)) && i === 0) ||
                                                        formDisabled
                                                    }
                                                    options={{
                                                        key: "brokerAgentKey",
                                                        display: (suggestion) => {
                                                            let brokerName =
                                                                get(suggestion, "firstName", "") +
                                                                " " +
                                                                get(suggestion, "lastName", "");
                                                            let departmentType = department(suggestion);
                                                            let brokerOffice = get(suggestion, "officeName", "");
                                                            if (brokerOffice === null) brokerOffice = "N/A";
                                                            return (
                                                                brokerName +
                                                                " - " +
                                                                departmentType +
                                                                " - " +
                                                                brokerOffice
                                                            );
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <BSSelect
                                                    id="representationType"
                                                    combinedField="shiftLeft"
                                                    value={commission.representationType}
                                                    error={get(errors, `${c}.representationType`, null)}
                                                    onChange={(e) => this.handleChange(c, e)}
                                                    errorText="Required"
                                                    placeholder="Representation"
                                                    options={representation_type_options}
                                                    disabled={formDisabled}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={5} style={{ paddingLeft: "12px" }}>
                                                <BSSelect
                                                    id="department"
                                                    value={commission.department}
                                                    error={get(errors, `${c}.department`, null)}
                                                    onChange={(e) => this.handleChange(c, e)}
                                                    errorText="Required"
                                                    placeholder="Department"
                                                    options={[
                                                        {
                                                            value: "",
                                                            label: "Department",
                                                            disabled: true,
                                                        },
                                                        {
                                                            value: "Brokerage",
                                                            label: "Brokerage",
                                                        },
                                                        {
                                                            value: "Property Management",
                                                            label: "Asset Services",
                                                        },
                                                        {
                                                            value: "Appraisal",
                                                            label: "Appraisal",
                                                        },
                                                        {
                                                            value: "Debt & Equity",
                                                            label: "Debt/Equity",
                                                        },
                                                    ]}
                                                    disabled={formDisabled}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <BSInput
                                                    id="netPercent"
                                                    value={commission.netPercent}
                                                    error={get(errors, `${c}.netPercent`, null)}
                                                    onChange={(e) => this.handleChange(c, e)}
                                                    errorText="Required"
                                                    startAdornment="%"
                                                    disabled={formDisabled}
                                                />
                                            </Grid>
                                            <Grid item xs={formDisabled || i === 0 ? 4 : 3}>
                                                <BSInput
                                                    id="netAmount"
                                                    value={commission.netAmount}
                                                    error={get(errors, `${c}.netAmount`, null)}
                                                    onChange={(e) => this.handleChange(c, e)}
                                                    errorText="Required"
                                                    startAdornment="$"
                                                    disabled={formDisabled}
                                                    numeric
                                                />
                                            </Grid>
                                            <Grid item xs={1} className={classes.centerCell}>
                                                {i !== 0 && !formDisabled && (
                                                    <Button
                                                        className={classes.shrinkButton}
                                                        onClick={() => handleRemoveCommissionOrRunner({ key: c })}
                                                        disabled={formDisabled}
                                                    >
                                                        <Delete />
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {Object.keys(brokerCommissions[c].runnerCommissions).map((r, i) => {
                                    const runner = brokerCommissions[c]["runnerCommissions"][r];
                                    return (
                                        <div className="tight-rows" key={`splits-runner-${r}`}>
                                            <Grid container alignItems="flex-start" spacing={8}>
                                                <Grid item xs={1} className={classes.centerCell}>
                                                    <Subdirectory />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Autocomplete
                                                        id="firstName"
                                                        label={"Associate"}
                                                        error={get(errors, `${c}.runners.${r}.firstName`, null)}
                                                        errorText="Required"
                                                        initialInputValue={`${get(runner, `firstName`, "")}${
                                                            get(runner, `runner.personKey`, null) ? " " : ""
                                                        }${get(runner, `lastName`, "")}`}
                                                        itemToString={(i) => get(i, `firstName`, null)}
                                                        onSelect={(p, b) => this.handleRunnerPersonChange(p, c, r)}
                                                        placeholder="Enter associate"
                                                        searchFunction={handleSearchPerson}
                                                        startAdornment={i !== 0 && <Search />}
                                                        suggestions={searchResults}
                                                        disabled={formDisabled}
                                                        options={{
                                                            key: "brokerAgentKey",
                                                            display: (suggestion) => {
                                                                return (
                                                                    get(suggestion, "firstName", "") +
                                                                    " " +
                                                                    get(suggestion, "lastName", "") +
                                                                    " - " +
                                                                    department(suggestion)
                                                                );
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <BSInput
                                                        id="percentOfBrokerNet"
                                                        value={
                                                            runner.amount === "0.00" ? runner.percentOfBrokerNet : null
                                                        }
                                                        error={get(
                                                            errors,
                                                            `${c}.runners.${r}.percentOfBrokerNet`,
                                                            null
                                                        )}
                                                        onChange={(e) => this.handleRunnerChange(c, r, e)}
                                                        errorText="Required"
                                                        startAdornment="%"
                                                        disabled={
                                                            formDisabled
                                                                ? true
                                                                : runner.amount !== 0 && runner.amount !== null
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <BSInput
                                                        id="amount"
                                                        value={
                                                            runner.percentOfBrokerNet === 0 ||
                                                            runner.percentOfBrokerNet === null
                                                                ? runner.amount
                                                                : null
                                                        }
                                                        error={get(errors, `${c}.runners.${r}.amount`, null)}
                                                        onChange={(e) => this.handleRunnerChange(c, r, e)}
                                                        errorText="Required"
                                                        startAdornment="$"
                                                        disabled={
                                                            formDisabled
                                                                ? true
                                                                : runner.percentOfBrokerNet !== 0 &&
                                                                  runner.percentOfBrokerNet !== null
                                                                ? true
                                                                : false
                                                        }
                                                        numeric
                                                    />
                                                </Grid>
                                                {!formDisabled && (
                                                    <Grid item xs={1} className={classes.centerCell}>
                                                        <Button
                                                            className={classes.shrinkButton}
                                                            onClick={() =>
                                                                handleRemoveCommissionOrRunner({ key: r, parent: c })
                                                            }
                                                        >
                                                            <Delete />
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </div>
                                    );
                                })}
                                {!formDisabled && (
                                    <Button
                                        className={classes.addRunner}
                                        style={{ marginTop: "-20px" }}
                                        color="primary"
                                        onClick={() => handleAddRunner(c)}
                                    >
                                        <Add fill="#4b94c1" />
                                        Add Associate
                                    </Button>
                                )}
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
                {Object.keys(brokerCommissions).map((s, i) => {
                    const split = brokerCommissions[s];
                    const commissionType = get(split, "commissionType") === "REFERRAL" ? "REFERRAL" : "OUTSIDE BROKER";
                    const isFreeformSearchEnabled = commissionType === "REFERRAL" ? false : true;
                    const outsideCommissionHeader =
                        commissionType === "REFERRAL" ? "OUTSIDE REFERRAL" : "OUTSIDE BROKER";
                    const commissionComment = get(split, "commissionComment.comment");
                    if (
                        get(split, "company.legalName", null) !== null
                            ? !get(split, "company.legalName", null).includes("Kidder Mathews")
                            : !split.kmBrokerFlag
                    ) {
                        return (
                            <div key={`split-${i}`}>
                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={7}>
                                                <BSInput
                                                    id="firstName"
                                                    label={outsideCommissionHeader}
                                                    value={`${get(split, `firstName`, "")}${
                                                        get(split, `firstName`, null) ? " " : ""
                                                    }${get(split, `lastName`, "")}`}
                                                    errorText="Required"
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <BSSelect
                                                    id="representationType"
                                                    combinedField="shiftLeft"
                                                    value={split.representationType}
                                                    error={get(errors, `${s}.representationType`, null)}
                                                    errorText="Required"
                                                    onChange={(e) => this.handleChange(s, e)}
                                                    placeholder="Representation"
                                                    options={representation_type_options}
                                                    disabled={formDisabled}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={8} style={{ paddingLeft: "12px" }}>
                                            <Grid item xs={formDisabled ? 5 : 4}>
                                                <BSSelect
                                                    id="department"
                                                    value={split.department}
                                                    error={get(errors, `${s}.department`, null)}
                                                    onChange={(e) => this.handleChange(s, e)}
                                                    errorText="Required"
                                                    placeholder="Department"
                                                    options={[
                                                        {
                                                            value: "",
                                                            label: "Department",
                                                            disabled: true,
                                                        },
                                                    ]}
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <BSInput
                                                    id="netPercent"
                                                    value={split.netPercent}
                                                    error={get(errors, `${s}.netPercent`, null)}
                                                    onChange={(e) => this.handleChange(s, e)}
                                                    errorText="Required"
                                                    startAdornment="%"
                                                    disabled={formDisabled}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <BSInput
                                                    id="netAmount"
                                                    value={split.netAmount}
                                                    error={get(errors, `${s}.netAmount`, null)}
                                                    onChange={(e) => this.handleChange(s, e)}
                                                    errorText="Required"
                                                    startAdornment="$"
                                                    disabled={formDisabled}
                                                    numeric={true}
                                                />
                                            </Grid>
                                            {!formDisabled && (
                                                <Grid item xs={1} className={classes.centerCell}>
                                                    <Button
                                                        className={classes.shrinkButton}
                                                        onClick={() =>
                                                            toggleOutsideSplitModal({
                                                                key: s,
                                                                split: split,
                                                                secondaryAction: "Delete",
                                                                isFreeformSearchEnabled: isFreeformSearchEnabled,
                                                                commissionType: commissionType,
                                                            })
                                                        }
                                                        disabled={formDisabled}
                                                    >
                                                        <Edit />
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                        {commissionComment && (
                                            <Grid container justify="flex-start">
                                                <Grid item xs={11}>
                                                    <div className={classes.commissionComment}>
                                                        {" "}
                                                        {commissionComment}{" "}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
                <Grid container alignItems="center" justify="space-between" spacing={8}>
                    <Grid item xs={12}>
                        <h3>Installment Commission Totals</h3>
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="kmInstallmentComm"
                            label="KM Installment Commission"
                            value={kmCommission}
                            onChange={(e) => this.handleCommissionSum(e)}
                            startAdornment="$"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="outsideInstallmentComm"
                            label="Outside Installment Commission"
                            value={outsideCommission}
                            onChange={(e) => this.handleCommissionSum(e)}
                            startAdornment="$"
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const styles = (theme) => ({
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    addRunner: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#2C99D2",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    centerCell: {
        textAlign: "center",
        fill: "#9e9e9e",
        marginTop: "30px",
    },
    commissionComment: {
        fontSize: "13px",
        lineHeight: "26px",
        borderRadius: "3px",
        border: "1px solid #ccc",
        padding: "3px",
        marginTop: "-2em",
        marginLeft: "10px",
    },
});

export default withStyles(styles)(BrokerCommissions);
