import { request } from "../../utils/axios-wrapper";
import actions from "./actions";
import get from "lodash/get";
import utils from "../../utils";
import moment from "moment";
import constants from "../constants";
import isEmpty from "lodash/isEmpty";
import validation from "./validation";
import each from "lodash/each";
import utilOperations, { convertForNumberValidation, stripCommas } from "../../utils/operations";
import includes from "lodash/includes";
import numeral from "numeral";
import { push } from "connected-react-router";
import isNil from "lodash/isNil";
import userConstants from "../../../js/auth/ducks/constants";

const getUserAccount = (userAccountKey) => {
    return (dispatch, _) => {
        request(
            {
                method: "get",
                url: "/admin/account/" + userAccountKey,
            },
            dispatch
        )
            .then((response) => {
                dispatch(loadUserAccount(response.data));
                dispatch(loadLicenses(response.data));
                dispatch(loadExhibitAs(response.data));
                if (
                    includes(
                        [
                            "ROLE_EVP",
                            "ROLE_BROKER",
                            "ROLE_ARIZONA_BROKER_ADMIN",
                            "ROLE_PORTLAND_ADMIN",
                            "ROLE_DEBT_EQUITY",
                        ],
                        get(response.data, "role", null)
                    )
                ) {
                }
            })
            .catch((error) => {
                console.error("getUserAccount failed to retrieve UserAccount for user_account_key: " + userAccountKey);
                console.error(error);
            });
    };
};

const loadLicenses = (userAccount) => {
    return (dispatch, _) => {
        const licenses = get(userAccount, "person.licenses", null);
        dispatch(actions.loadLicenses(licenses));
    };
};

const loadExhibitAs = (userAccount) => {
    return (dispatch, _) => {
        dispatch(actions.loadExhibitAs([]));
        const exhibitAs = get(userAccount, "person.exhibitAs", null);
        dispatch(actions.loadExhibitAs(exhibitAs));
    };
};

const getUserAccountCall = (userAccountKey) => {
    return request({
        method: "get",
        url: "/admin/account/" + userAccountKey,
    });
};

const loadUserAccount = (userAccount) => {
    return (dispatch, _) => {
        dispatch(actions.loadUserAccount(userAccount));
    };
};

const handleSaveAccountUpdates = () => {
    return (dispatch, getState) => {
        dispatch(utils.actions.isSaving(true));
        dispatch(validateUserDetail());

        if (isEmpty(getState().utilsReducer.errors)) {
            const userAccountKey = getState().adminReducer.userAccount.userAccountKey;
            const exhibitAs = getState().adminReducer.exhibitAs;
            exhibitAs.forEach((exhibitA) => {
                if (exhibitA["annualGrossMinimum"])
                    exhibitA["annualGrossMinimum"] = numeral(stripCommas(exhibitA["annualGrossMinimum"])).format(
                        "00.00"
                    );
                if (exhibitA["baseSplitPercentage"])
                    exhibitA["baseSplitPercentage"] = convertForNumberValidation(exhibitA["baseSplitPercentage"]);
                if (exhibitA["adjustedPercentage"])
                    exhibitA["adjustedPercentage"] = convertForNumberValidation(exhibitA["adjustedPercentage"]);
            });

            const accountUpdate = {
                licenses: getState().adminReducer.licenses,
                exhibitAs: getState().adminReducer.exhibitAs,
                proxies: getState().adminReducer.userAccount.allowedProxies,
                role: getState().adminReducer.userAccount.role,
                activeFlg: getState().adminReducer.userAccount.activeFlg,
            };

            request(
                {
                    method: "post",
                    url: "/admin/account/" + userAccountKey,
                    data: accountUpdate,
                },
                dispatch
            )
                .then((response) => {
                    dispatch(utils.actions.snackbar(true, "success", "All changes saved"));
                    dispatch(getUserAccount(userAccountKey));
                })
                .catch((error) => {
                    dispatch(utils.actions.snackbar(true, "error", `Couldn't save changes`));
                    console.error(
                        "handleSaveUserAccount failed to save changes for user with account key: " + userAccountKey
                    );
                    console.error(error);
                    dispatch(utilOperations.reportError(error, "admin:operations:handleSaveAccountUpdates Error"));
                });
            dispatch(utils.actions.isSaving(false));
        }
    };
};

const validateUserDetail = () => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        dispatch(validateLicenses());
        dispatch(validateProxies());
        dispatch(validateExhibitAs());
        dispatch(
            processSimpleFieldValidationResponse(
                validation.validateUserAccountDetail(get(getState().adminReducer, "userAccount", {}))
            )
        );
    };
};

const validateLicenses = () => {
    return (dispatch, getState) => {
        const licenses = get(getState().adminReducer, "licenses", []);
        each(Object.keys(licenses), (i) => {
            const validationResponse = validation.validateLicenseDetail(licenses[i]);
            dispatch(processArrayFieldTypeValidationResponse(validationResponse, "licenses", i));
        });
    };
};

const validateExhibitAs = () => {
    return (dispatch, getState) => {
        const exhibitAs = get(getState().adminReducer, "exhibitAs", []);
        each(Object.keys(exhibitAs), (i) => {
            const validationResponse = validation.validateExhibitADetail(exhibitAs[i]);
            dispatch(processArrayFieldTypeValidationResponse(validationResponse, "exhibitAs", i));
        });
    };
};

const validateProxies = () => {
    return (dispatch, getState) => {
        const proxies = get(getState().adminReducer, "userAccount.allowedProxies", []);
        each(Object.keys(proxies), (i) => {
            const validationResponse = validation.validateUserProxyDetail(proxies[i]);
            dispatch(processArrayFieldTypeValidationResponse(validationResponse, "proxyFor", i));
        });
    };
};

const processSimpleFieldValidationResponse = (validationResponse) => {
    return (dispatch, getState) => {
        if (validationResponse) {
            each(Object.keys(validationResponse), (e) => {
                dispatch(utils.actions.addError(`${e}`, validationResponse[e][0]));
            });
        }
    };
};

const processArrayFieldTypeValidationResponse = (validationResponse, identifier, position) => {
    return (dispatch, getState) => {
        if (validationResponse) {
            each(Object.keys(validationResponse), (e) => {
                dispatch(utils.actions.addError(`${identifier}.${position}.${e}`, validationResponse[e][0]));
            });
        }
    };
};

const handleInputChange = (field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.inputChange(field, value));
    };
};

const addArrayElement = (arrayPath) => {
    return (dispatch, getState) => {
        dispatch(actions.addArrayElement(arrayPath, get(getState().adminReducer, arrayPath, []).length));
    };
};

const deleteArrayElement = (arrayPath, index) => {
    return (dispatch, getState) => {
        dispatch(actions.deleteArrayElement(arrayPath, index));
    };
};

// will need a separate switch tab function for companies
const switchTab = (tab) => {
    return (dispatch, getState) => {
        dispatch(actions.switchTab(tab));
        if ((getState().adminReducer, `tabs[${tab}]`, []).length === 0) {
            dispatch(getUsersByStatus(tab));
        }
    };
};

const switchCompanyTab = (tab) => {
    return (dispatch, getState) => {
        dispatch(actions.switchCompanyTab(tab));
        if ((getState().adminReducer, `tabs[${tab}]`, []).length === 0) {
            dispatch(getCompaniesByStatus(tab));
        }
    };
};

const getUsersByStatus = () => {
    return (dispatch, getState) => {
        const tab = get(getState().adminReducer, "userTab", null);
        dispatch(utils.actions.loading(true));
        const queryTerm = get(getState().adminReducer, "filterQuery", null);
        const searchType = get(getState().adminReducer, "userSearchType", null);
        if (!isEmpty(queryTerm)) {
            request(
                {
                    url: constants.urls[tab](queryTerm, searchType),
                    method: "get",
                },
                dispatch
            )
                .then((response) => {
                    dispatch(actions.loadUsersForTab(tab, response.data));
                    dispatch(utils.actions.loading(false));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(utils.actions.loading(false));
                });
        }
    };
};

const getCompaniesByStatus = () => {
    return (dispatch, getState) => {
        const tab = get(getState().adminReducer, "companyTab", null);
        const queryTerm = get(getState().adminReducer, "filterQuery", null);
        const searchType = get(getState().adminReducer, "companySearchType", null);
        if (!isEmpty(queryTerm)) {
            dispatch(utils.actions.loading(true));
            request(
                {
                    url: constants.urls[tab](queryTerm, searchType),
                    method: "get",
                },
                dispatch
            )
                .then((response) => {
                    dispatch(actions.loadCompaniesForTab(tab, response.data));
                    dispatch(utils.actions.loading(false));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(utils.actions.loading(false));
                });
        }
    };
};

const addCompany = () => {
    return (dispatch, _) => {
        dispatch(push(`/company`));
    };
};

const clearCompany = () => {
    return (dispatch, _) => {
        dispatch(actions.clearCompany());
    };
};

const filterUsers = (query) => {
    return (dispatch, getState) => {
        if (typeof query === "string") query = query.toLowerCase();
        dispatch(actions.filterUsers(query));
    };
};

const filterCompanies = (query) => {
    return (dispatch, getState) => {
        if (typeof query === "string") query = query.toLowerCase();
        dispatch(actions.filterCompanies(query));
    };
};

const handleSort = (columnName) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };
    return (dispatch, getState) => {
        let sortDirection =
            getState().adminReducer.columnToSort === columnName
                ? invertDirection[getState().adminReducer.sortDirection]
                : "asc";
        dispatch(actions.handleSort(columnName, sortDirection));
    };
};

const handleChangeUserSearchType = (searchType) => {
    return (dispatch, getState) => {
        dispatch(actions.setUserSearchType(searchType));
    };
};

const handleChangeCompanySearchType = (searchType) => {
    return (dispatch, getState) => {
        dispatch(actions.setCompanySearchType(searchType));
    };
};

const getUserOfficeHistory = (userAccountKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        request(
            {
                url: constants.urls["user_office_history"](userAccountKey),
                method: "get",
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setUserOfficeHistory(userAccountKey, response.data));
                dispatch(utils.actions.loading(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utils.actions.loading(false));
            });
    };
};

const updateOfficeDate = (historyId, value, dateType, userAccountKey) => {
    return (dispatch, getState) => {
        let history = getState().adminReducer.userOfficeHistory[userAccountKey];
        if (value) {
            for (let i = 0; i < history.length; i++) {
                if (history[i]["userOfficeHistoryKey"] === historyId) {
                    history[i][dateType] = moment(value).format("MM/DD/YYYY");
                }
            }
            dispatch(actions.setUserOfficeHistory(userAccountKey, history));
        }
    };
};

const saveOfficeHistory = (userAccountKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        request(
            {
                url: constants.urls["user_office_save"](userAccountKey),
                method: "put",
                crossDomain: true,
                data: getState().adminReducer.userOfficeHistory[userAccountKey],
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.setUserOfficeHistory(userAccountKey, response.data));
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.snackbar(true, "success", "Office History Updated!"));
            })
            .catch((err) => {
                console.log(err);
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.snackbar(true, "error", "Office History Updates were not able to be saved!"));
            });
    };
};

const handleDeleteCompany = (companyKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        dispatch(utils.actions.somethingSaving(true, "companyDelete"));
        request(
            {
                method: "delete",
                url: constants.urls.delete_company(companyKey),
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.somethingSaving(false, "companyDelete"));
                dispatch(push("../admin/companies"));
                dispatch(utils.actions.snackbar(true, "success", "Company Deleted"));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, "companyDelete"));
                dispatch(
                    utils.actions.snackbar(
                        true,
                        "error",
                        "Unable to delete this Company, it may be attached to a deal, listing, contact or broker."
                    )
                );
                console.log(err);
                dispatch(
                    utilOperations.reportError(
                        err,
                        "admin:operations:handleDeleteCompany - Error trying to delete company"
                    )
                );
            });
    };
};

// working loadCompany with single company type
const loadCompany = (company) => {
    return (dispatch, getState) => {
        const newCompany = {
            ...company,
            companyType: company.companyTypes && company.companyTypes.length > 0 ? company.companyTypes[0] : null,
        };
        dispatch(actions.loadCompany(newCompany));
    };
};

const getCompany = (id) => {
    return (dispatch, getState) => {
        request(
            {
                method: "get",
                url: constants.urls.find_company_by_id(id),
            },
            dispatch
        )
            .then((response) => {
                dispatch(loadCompany(response.data));
            })
            .catch((err) => {});
    };
};

const getParentCompany = (id) => {
    return (dispatch, getState) => {
        request(
            {
                method: "get",
                url: constants.urls.find_parent_company_by_id(id),
            },
            dispatch
        )
            .then((response) => {
                dispatch(loadCompany(response.data));
            })
            .catch((err) => {});
    };
};

const handleSaveCompany = () => {
    return saveCompanyCommon(false, "companySaveAndContinue");
};

const handleSaveCompanyAndExit = () => {
    return saveCompanyCommon(true, "companySaveAndExit");
};

const saveCompanyCommon = (shouldExit, elementSaving) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        dispatch(utils.actions.somethingSaving(true, elementSaving));
        const company = getState().adminReducer.company;
        const errors = validation.validateCompanyDetail(company);
        if (isEmpty(errors) || isNil(errors)) {
            request(
                {
                    method: "post",
                    url: constants.urls.create_company(),
                    data: getState().adminReducer.company,
                    headers: { "Content-Type": "application/json" },
                },
                dispatch
            )
                .then((response) => {
                    dispatch(loadCompany(response.data));
                    if (shouldExit) {
                        dispatch(push("/admin/companies"));
                    }
                    dispatch(utils.actions.somethingSaving(false, elementSaving));
                    dispatch(utils.actions.snackbar(true, "success", "Success."));
                })
                .catch((err) => {
                    dispatch(utils.actions.snackbar(true, "error", "Error trying to save: " + err));
                    dispatch(utilOperations.reportError(err, "admin:operations:handleSaveCompanyAndExit Error"));
                    dispatch(utils.actions.somethingSaving(false, elementSaving));
                });
        } else {
            console.log(errors);
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                )
            );
            dispatch(utils.actions.addError("company", errors));
            dispatch(utils.actions.somethingSaving(false, elementSaving));
        }
    };
};

const adImport = async () => {
    return request({
        url: userConstants.urls.ad_import(),
        method: "get",
    })
        .then((response) => {
            return response.status;
        })
        .catch((response) => {
            return response.status;
        });
};

const checkboxClick = (key, companyTab) => {
    return (dispatch, getState) => {
        if (companyTab === "approved_companies") {
            let selected = getState().adminReducer.selectedApprovedCompanies;
            const current = selected.get(key);
            dispatch(actions.approvedCompanyCheckboxClick(key, !current));
        } else {
            let selected = getState().adminReducer.selectedUnapprovedCompanies;
            const current = selected.get(key);
            dispatch(actions.unapprovedCompanyCheckboxClick(key, !current));
        }
    };
};

const clearCheckboxes = () => {
    return (dispatch, getState) => {
        dispatch(actions.clearCheckboxes());
    };
};

const mergeCompanies = (selectedCompanies) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        const mergeCompanies = {
            company: getState().adminReducer.company,
            selectedCompanies: selectedCompanies,
        };
        const errors = validation.validateCompanyDetail(mergeCompanies.company);
        if (isEmpty(errors) || isNil(errors)) {
            request(
                {
                    method: "post",
                    url: constants.urls.merge_companies(),
                    data: mergeCompanies,
                    headers: { "Content-Type": "application/json" },
                },
                dispatch
            )
                .then((response) => {
                    dispatch(utils.actions.snackbar(true, "success", "Merge successfully completed"));
                    dispatch(getCompaniesByStatus());
                    dispatch(clearCheckboxes());
                    dispatch(clearCompany());
                })
                .catch((err) => {
                    dispatch(utils.actions.snackbar(true, "error", "Error trying to merge: " + err));
                    dispatch(utilOperations.reportError(err, "admin:operations:mergeCompanies Error"));
                });
        } else {
            console.log(errors);
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                )
            );
            dispatch(utils.actions.addError("company", errors));
        }
    };
};

export default {
    getUserAccount,
    handleInputChange,
    deleteArrayElement,
    addArrayElement,
    handleSaveAccountUpdates,
    getUsersByStatus,
    getCompaniesByStatus,
    switchTab,
    switchCompanyTab,
    filterUsers,
    filterCompanies,
    handleSort,
    getUserAccountCall,
    handleChangeUserSearchType,
    handleChangeCompanySearchType,
    getUserOfficeHistory,
    updateOfficeDate,
    saveOfficeHistory,
    addCompany,
    handleDeleteCompany,
    handleSaveCompany,
    handleSaveCompanyAndExit,
    getCompany,
    getParentCompany,
    clearCompany,
    adImport,
    checkboxClick,
    clearCheckboxes,
    mergeCompanies,
};
