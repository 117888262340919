import React from "react";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import { topography_options } from "../deal/constants";
import toLower from "lodash/toLower";

class LandForm extends React.Component {
    handleAttributeUpdate = (event) => {
        this.props.onChange(event.target.id, event.target.value);
    };

    render() {
        const { data, errors, disableInputs, context, listingStatus, transactionType } = this.props;

        return (
            <div>
                {context === "property" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="landType"
                                    label="LAND TYPE"
                                    placeholder="Enter land type"
                                    error={errors.landType}
                                    value={data.landType}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="visibility"
                                    label="VISIBILITY"
                                    placeholder="Enter visibility"
                                    error={errors.visibility}
                                    value={data.visibility}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="frontageRoad"
                                    label="ROAD FRONTAGE ON"
                                    placeholder="Enter road name"
                                    error={errors.frontageRoad}
                                    value={data.frontageRoad}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Divider />

                        <Grid container alignItems="center" spacing={8} style={{ marginBottom: "14px" }}>
                            <Grid item xs={4}>
                                <span style={{ fontSize: "14px" }}>Existing Structure</span>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    style={{ paddingTop: "5px" }}
                                    control={
                                        <Switch
                                            checked={data.existingStructureFlg === true ? true : false}
                                            onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                            value="existingStructureFlg"
                                            color="primary"
                                            disabled={disableInputs}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}

                {context === "property" && data.existingStructureFlg && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6} style={{ height: "90px" }}>
                                <BSInput
                                    id="existingStructureNotes"
                                    label="EXISTING STRUCTURE NOTES"
                                    placeholder="Enter notes"
                                    error={errors.existingStructureNotes}
                                    value={data.existingStructureNotes}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ height: "90px" }}>
                                <BSInput
                                    id="demolitionDate"
                                    label="DEMOLITION DATE"
                                    placeholder="Enter date"
                                    error={errors.demolitionDate}
                                    value={data.demolitionDate}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}

                {context === "listing" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="sfAvail"
                                    label="SF AVAIL"
                                    placeholder="Enter SF"
                                    errorText="ex. 98101"
                                    error={errors.sfAvail}
                                    value={data.sfAvail}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="totalAcres"
                                    label="ACRES AVAILABLE"
                                    placeholder="Enter acreage"
                                    errorText="ex. 98.01"
                                    error={errors.totalAcres}
                                    value={data.totalAcres}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        {toLower(transactionType) === "sale" && listingStatus === "Appraisal" && (
                            <React.Fragment>
                                <Grid container alignItems="flex-start" spacing={8}>
                                    <Grid item xs={6}>
                                        <BSSelect
                                            id="topography"
                                            label="Topography"
                                            placeholder="Select Topography"
                                            error={errors.topography}
                                            value={data.topography}
                                            onChange={this.handleAttributeUpdate}
                                            disabled={disableInputs}
                                            options={topography_options}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <BSInput
                                            id="zoning"
                                            label="Zoning"
                                            placeholder="Enter zoning"
                                            error={errors.zoning}
                                            value={data.zoning}
                                            onChange={this.handleAttributeUpdate}
                                            disabled={disableInputs}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="flex-start" spacing={8}>
                                    <BSInput
                                        id="entitlements"
                                        label="Entitlements"
                                        placeholder="Enter entitlements"
                                        error={errors.entitlements}
                                        value={data.entitlements}
                                        onChange={this.handleAttributeUpdate}
                                        disabled={disableInputs}
                                    />
                                </Grid>
                                <Grid container alignItems="flex-start" spacing={8}>
                                    <Grid item xs={3} style={{ marginTop: "15px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={data.wetlandsFlg ? true : false}
                                                    onChange={(e, checked) =>
                                                        this.props.onChange(e.target.value, checked)
                                                    }
                                                    value="wetlandsFlg"
                                                    disabled={disableInputs === true}
                                                    color="primary"
                                                />
                                            }
                                            label="Wetlands"
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <BSInput
                                            id="wetlandsPct"
                                            placeholder="Enter wetlands %"
                                            label="Wetlands %"
                                            error={errors.wetlandsPct}
                                            value={data.wetlandsPct}
                                            onChange={this.handleAttributeUpdate}
                                            disabled={disableInputs}
                                            numeric
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </div>
                )}
                {(context === "listing" || (context === "property" && data.existingStructureFlg)) && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.electricFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="electricFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Electric"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.curbCutsFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="curbCutsFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Curb Cuts"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.cornerLocationFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="cornerLocationFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Corner Lot"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.waterfrontFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="waterfrontFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Waterfront"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.railServiceFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="railServiceFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Rail Service"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.easementsFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="easementsFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Easements"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.divisibleFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="divisibleFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Divisible"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.visibilityFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="visibilityFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Visibility"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.clearedFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="clearedFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Cleared"
                                    />

                                    {/*{listingStatus === 'Appraisal' &&*/}
                                    {/*<React.Fragment>*/}
                                    {/*<FormControlLabel*/}
                                    {/*control={*/}
                                    {/*<Checkbox*/}
                                    {/*checked={data.wetlandsFlg ? true : false}*/}
                                    {/*onChange={(e, checked) => this.props.onChange(e.target.value, checked)}*/}
                                    {/*value="wetlandsFlg"*/}
                                    {/*disabled={disableInputs === true}*/}
                                    {/*color="primary"*/}
                                    {/*/>*/}
                                    {/*}*/}
                                    {/*label="Wetlands"*/}
                                    {/*/>*/}

                                    {/*<BSInput*/}
                                    {/*id="wetlandsPct"*/}
                                    {/*placeholder="Enter wetlands %"*/}
                                    {/*error={errors.wetlandsPct}*/}
                                    {/*value={data.wetlandsPct}*/}
                                    {/*onChange={this.handleAttributeUpdate}*/}
                                    {/*disabled={disableInputs}*/}
                                    {/*numeric*/}
                                    {/*/>*/}
                                    {/*</React.Fragment>*/}
                                    {/*}*/}
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.pavedFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="pavedFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Paved"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.parkingLotFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="parkingLotFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Parking Lot"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.sewerFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="sewerFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Sewer"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.waterFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="waterFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Water"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.stormSewerFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="stormSewerFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Storm Sewer"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.gasAvailFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="gasAvailFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Gas Line"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.techFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="techFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Tech"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.otherFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="otherFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Other"
                                    />

                                    <BSInput
                                        id="otherFlgDesc"
                                        placeholder="Enter other"
                                        error={errors.otherFlgDesc}
                                        value={data.otherFlgDesc}
                                        onChange={this.handleAttributeUpdate}
                                        disabled={disableInputs}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </div>
        );
    }
}

export default LandForm;
