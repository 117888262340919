import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import {
    Sorty,
    UpChevron,
    DownChevron,
    StepperIcon,
    StepperIconComplete,
    LeftChevron,
    Add,
} from "../../../common/js/icons";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import numeral from "numeral";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import utils from "../utils/index";
import { red } from "@material-ui/core/colors";
import {
    booleanToYN,
    aggregateBrokers,
    buildAddress,
    toDollarAmountAcceptZero,
    isBrokerOnListing,
    isBrokerOnDeal,
    isPropertyInState,
    toSqft,
    minMaxSqftConcat,
} from "../utils/operations";
import StatusChip from "../../../common/js/components/StatusChip";
import includes from "lodash/includes";
import DocumentDrop from "../documents/DocumentDropContainer";

const styles = (theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    root: {
        marginTop: "2em",
        padding: "0 20px",
    },
    lb: {
        textDecoration: "none",
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "4em",
        paddingBottom: "2em",
    },
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    chip: {
        root: {
            height: "24px",
        },
    },
    progressDiv: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
    },
    paperPadding: {
        padding: "0 20px 20px",
    },
    paperHeading: {
        fontSize: "18px",
        margin: "14px 0",
    },
    hr: {
        margin: "0 -20px 20px -20px",
    },
    defTerm: {
        fontSize: "13px",
        textTransform: "uppercase",
        color: "#9E9E9E",
        fontWeight: 600,
    },
    defData: {
        fontSize: "14px",
        color: "#000",
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    idLink: {
        textDecoration: "none",
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    goBackLink: {
        textDecoration: "none",
        "&:hover": {
            cursor: "pointer",
            textDecoration: "none",
        },
    },
    deleteButton: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800],
        },
    },
});

class DealSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.loadDealDocumentList(id);
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDelete = () => {
        this.setState({ open: false });
        this.props.deleteDeal(this.props.values.dealKey);
    };

    showDocumentList() {
        const show =
            includes(["accounting", "evp", "dataAdmin", "dataEntry", "auditor"], this.props.userProfile.role) ||
            (includes(["portlandBrokerAdmin"], this.props.userProfile.role) &&
                isPropertyInState(get(this.props.values, "listing.property", null), "OR")) ||
            (includes(["arizonaBrokerAdmin"], this.props.userProfile.role) &&
                isPropertyInState(get(this.props.values, "listing.property", null), "AZ")) ||
            this.props.userProfile.username === get(this.props.values, "deal.auditCreatedBy", null) ||
            isBrokerOnListing(get(this.props.values, "listing.listingBrokers", []), this.props.userProfile.personKey) ||
            isBrokerOnDeal(get(this.props.values, "brokerCommissions", []), this.props.userProfile.personKey);

        return show;
    }

    showVoucherView() {
        const show =
            includes(["accounting", "evp", "dataAdmin", "dataEntry"], this.props.userProfile.role) ||
            (includes(["portlandBrokerAdmin"], this.props.userProfile.role) &&
                isPropertyInState(get(this.props.values, "listing.property", null), "OR")) ||
            (includes(["arizonaBrokerAdmin"], this.props.userProfile.role) &&
                isPropertyInState(get(this.props.values, "listing.property", null), "AZ")) ||
            this.props.userProfile.username === get(this.props.values, "deal.auditCreatedBy", null) ||
            isBrokerOnListing(get(this.props.values, "listing.listingBrokers", []), this.props.userProfile.personKey) ||
            isBrokerOnDeal(get(this.props.values, "brokerCommissions", []), this.props.userProfile.personKey);

        return show;
    }

    render() {
        const {
            addVoucherInstallment,
            userProfile,
            creator,
            classes,
            columnToSort,
            handleSort,
            isLoading,
            isLoading2,
            loading,
            sortDirection,
            startVoucher,
            values,
            startDeal,
            handleStartReviewingAsCorporateData,
            handleDownloadDealDocuments,
            role,
        } = this.props;
        const listing = values.listing;

        return isLoading ? null : (
            <div>
                {/* //toolbar */}
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.pageToolbar}
                    spacing={16}
                >
                    <Grid item zeroMinWidth>
                        <span className={classes.goBackLink} onClick={() => this.props.history.goBack()}>
                            <LeftChevron />
                        </span>
                    </Grid>
                    <Grid item xs>
                        <h2 className="page-title">
                            {get(listing, "listingName", null)
                                ? get(listing, "listingName", null)
                                : get(values, "dealKey")}
                        </h2>
                        <p className="page-subtitle">
                            {buildAddress(get(values, "listing.property.primaryAddress"))} |{" "}
                            {get(values, "propertyType")} {get(values, "transactionType")}
                        </p>
                    </Grid>
                    <Grid item container direction="row" alignItems="flex-end" xs={4}>
                        <Grid item xs={6}>
                            {(values.auditCreatedBy === userProfile.user ||
                                includes(["dataAdmin", "dataEntry"], this.props.userProfile.role)) &&
                                get(values, "vouchers", []).length === 0 && (
                                    <Button
                                        style={{ float: "right" }}
                                        variant="contained"
                                        color="primary"
                                        className={classes.deleteButton}
                                        onClick={this.handleOpen}
                                    >
                                        Delete Deal
                                    </Button>
                                )}
                        </Grid>
                        <Grid item xs={6}>
                            <p className="page-subtitle text-right">Total Consideration</p>
                            <h2 className="page-title text-right">
                                {utils.operations.toDollarAmount(get(values, "totalConsiderationAmount", 0))}
                            </h2>
                        </Grid>
                    </Grid>
                </Grid>

                {/* //heading */}
                <Paper className={classes.paperPadding}>
                    <Grid container alignItems="flex-start" spacing={16}>
                        <Grid item>
                            <h3 className={classes.paperHeading}>Listing Information</h3>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr} />
                    <Grid container alignItems="flex-start" justify="space-evenly" spacing={16}>
                        <Grid item xs>
                            <span className={classes.defTerm}>Date Avail</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>{values.dateAvailable}</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>Total SF</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>{get(values, "propertyAttributes.totalBldgSf")}</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>
                                {get(listing, "listingType", "") === "Sale" ? "List Price" : "Rate"}
                            </span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>
                                {get(listing, "listingType", "") === "Sale"
                                    ? `${
                                          !isNaN(get(listing, "listPrice", "-"))
                                              ? numeral(get(listing, "listPrice", 0)).format("0,0.00")
                                              : ""
                                      }`
                                    : `${
                                          !isNaN(get(listing, "askingRentMax", "-"))
                                              ? numeral(get(listing, "askingRentMax", 0)).format("0,0.00")
                                              : ""
                                      }`}
                            </span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>Floor</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>{get(listing, "attributes.numFloors", "")}</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>Broker</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>
                                {aggregateBrokers(get(listing, "broker", []), "brokerAgentKey.person")}
                            </span>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start" justify="space-evenly" spacing={16}>
                        <Grid item xs>
                            <span className={classes.defTerm}>Type</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>{values.transactionType}</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>
                                {get(listing, "listingType", "") === "Sale" ? "Sale SF" : "SF Avail"}
                            </span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>
                                {get(listing, "listingType", "") === "Sale"
                                    ? toSqft(get(listing, "sfAvail", 0))
                                    : minMaxSqftConcat(get(listing, "sfDivisibleTo", 0), get(listing, "sfAvail", 0))}
                            </span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>NNN Rate</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>{values.expenseNnnOpAmount}</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>Sublet</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>{booleanToYN(values.subleaseFlg)}</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defTerm}>Created By</span>
                        </Grid>
                        <Grid item xs>
                            <span className={classes.defData}>
                                {typeof creator === "string" ? creator.toString() : "N/A"}
                            </span>
                        </Grid>
                    </Grid>
                </Paper>

                {/* comp */}
                {
                    role !== "auditor" ? (
                        get(values, "compStatus") ? ( //startA * * * * *
                            <Paper classes={classes}>
                                <Grid container alignItems="center" spacing={24} justify="space-between">
                                    <Grid container item xs={5} alignItems="center" spacing={16}>
                                        <Grid item>
                                            {values.compStatus === "Closed" ? (
                                                <StepperIconComplete />
                                            ) : (
                                                <StepperIcon text="1" fill="#2196f3" />
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <h4>Comp / RE Compliance</h4>
                                        </Grid>
                                        <Grid item>
                                            {values.compStatus && (
                                                <StatusChip
                                                    status={
                                                        includes(
                                                            [
                                                                "AZ Termination Review Required",
                                                                "Portland Termination Review Required",
                                                            ],
                                                            values.compStatus
                                                        )
                                                            ? "Termination Review Required"
                                                            : includes(
                                                                  ["AZ Terminated", "Portland Terminated"],
                                                                  values.compStatus
                                                              )
                                                            ? "Terminated"
                                                            : values.compStatus
                                                    }
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {(get(values, "compStatus") === null ||
                                            get(values, "compStatus") === undefined) &&
                                        get(userProfile, "role", null) === "broker" ? (
                                            <Link className={classes.lb} to={`/comp/${values.dealKey}`}>
                                                <Button variant="contained" color="primary">
                                                    Start
                                                </Button>
                                            </Link>
                                        ) : get(
                                              compAction,
                                              `${userProfile.role}.${values.compStatus}.buttonText`,
                                              null
                                          ) === "Start" ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleStartReviewingAsCorporateData(values)}
                                            >
                                                Start
                                            </Button>
                                        ) : get(compAction, `${userProfile.role}.${values.compStatus}`, null) ? (
                                            <Link className={classes.lb} to={`/comp/${values.dealKey}`}>
                                                <Button
                                                    variant={get(
                                                        compAction,
                                                        `${userProfile.role}.${values.compStatus}.variant`,
                                                        null
                                                    )}
                                                    color="primary"
                                                >
                                                    {get(
                                                        compAction,
                                                        `${userProfile.role}.${values.compStatus}.buttonText`,
                                                        null
                                                    )}
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Link
                                                className={classes.lb}
                                                onClick={() => this.handleClearDocumentListOnClick()}
                                                to={`/comp/${values.dealKey}`}
                                            >
                                                <Button variant="outlined" color="primary">
                                                    View
                                                </Button>
                                            </Link>
                                        )}
                                    </Grid>
                                </Grid>
                            </Paper>
                        ) : (
                            <Paper classes={classes}>
                                <Grid container alignItems="center" spacing={24} justify="space-between">
                                    <Grid container item xs={5} alignItems="center" spacing={16}>
                                        <Grid item>
                                            <StepperIcon text="1" fill="#2196f3" />
                                        </Grid>
                                        <Grid item xs>
                                            <h4>Comp / RE Compliance</h4>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {userProfile.role === "broker" ? (
                                            <Button
                                                variant="contained"
                                                onClick={() => startDeal(values.dealKey)}
                                                color="primary"
                                            >
                                                Start
                                            </Button>
                                        ) : get(compAction, `${userProfile.role}.${values.compStatus}`, null) ? (
                                            <Link className={classes.lb} to={`/comp/${values.dealKey}`}>
                                                <Button
                                                    variant={get(
                                                        compAction,
                                                        `${userProfile.role}.${values.compStatus}.variant`,
                                                        null
                                                    )}
                                                    color="primary"
                                                >
                                                    {get(
                                                        compAction,
                                                        `${userProfile.role}.${values.compStatus}.buttonText`,
                                                        null
                                                    )}
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Link
                                                className={classes.lb}
                                                onClick={() => this.handleClearDocumentListOnClick()}
                                                to={`/comp/${values.dealKey}`}
                                            >
                                                <Button variant="outlined" color="primary">
                                                    View
                                                </Button>
                                            </Link>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item>
                                        <p>
                                            Complete your comp information here and it will appear in your comps
                                            section. As a company, we will use this information to better compete in the
                                            marketplace.
                                        </p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )
                    ) : null
                    //endA * * * * *
                }

                {/* voucher */}
                {role !== "auditor" ? (
                    get(values, "compStatus", null) !== "Draft" && get(values, "compStatus", null) !== null ? (
                        <Paper classes={classes}>
                            <Grid container alignItems="center" spacing={24} justify="space-between">
                                <Grid container item xs={5} alignItems="center" spacing={16}>
                                    <Grid item>
                                        {values.vouchers &&
                                        values.vouchers.length > 0 &&
                                        values.vouchers[values.vouchers.length - 1].voucherStatus === "Closed" ? (
                                            <StepperIconComplete />
                                        ) : (
                                            <StepperIcon text="2" fill="#2196f3" />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <h4>Voucher</h4>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    {includes(
                                        ["broker", "evp", "portlandBrokerAdmin", "arizonaBrokerAdmin"],
                                        userProfile.role
                                    ) &&
                                    (values.totalInstallments === 1 || values.totalInstallments === null) &&
                                    !isLoading2 ? (
                                        get(values, "vouchers", []).length > 0 ? null : (
                                            <Button
                                                disabled={
                                                    userProfile.user === get(values, "auditCreatedBy", null)
                                                        ? false
                                                        : true
                                                }
                                                variant="contained"
                                                color="primary"
                                                onClick={() => startVoucher(values.dealKey)}
                                            >
                                                Start
                                            </Button>
                                        )
                                    ) : isLoading2 ? null : (
                                        includes(
                                            ["broker", "evp", "portlandBrokerAdmin", "arizonaBrokerAdmin"],
                                            userProfile.role
                                        ) && (
                                            <React.Fragment>
                                                {loading.addVoucherInstallment ? (
                                                    <div className={classes.progressDiv}>
                                                        <CircularProgress size={20} color="primary" />
                                                    </div>
                                                ) : (
                                                    <Button
                                                        onClick={() => addVoucherInstallment(values.dealKey)}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={
                                                            isLoading2 ||
                                                            get(values, "vouchers", []).length >=
                                                                get(values, "totalInstallments")
                                                        }
                                                    >
                                                        <Add fill="#ffffff" />
                                                        Add Installment
                                                    </Button>
                                                )}
                                            </React.Fragment>
                                        )
                                    )}
                                </Grid>
                            </Grid>
                            {get(values, "vouchers", []).length > 0 || isLoading2 ? null : (
                                <Grid container>
                                    <Grid item>
                                        <p>
                                            Fill out your voucher information once you receive payment or proof of
                                            payment so you can get paid in a timely fashion. Vouchers submitted by noon
                                            on Tuesday are paid out the same week.
                                        </p>
                                    </Grid>
                                </Grid>
                            )}
                            {isLoading2
                                ? null
                                : this.showVoucherView() &&
                                  values.vouchers &&
                                  values.vouchers.length > 0 && (
                                      <Grid container>
                                          <Grid item xs>
                                              <Table>
                                                  <TableHead>
                                                      <TableRow>
                                                          <TableCell classes={classes}>
                                                              <div
                                                                  style={{
                                                                      display: "flex",
                                                                      alignItems: "center",
                                                                  }}
                                                                  onClick={() => handleSort("voucherKey")}
                                                              >
                                                                  <span>Voucher</span>
                                                              </div>
                                                          </TableCell>
                                                          <TableCell classes={classes}>
                                                              <div
                                                                  style={{
                                                                      display: "flex",
                                                                      alignItems: "center",
                                                                  }}
                                                                  onClick={() => handleSort("installmentNum")}
                                                              >
                                                                  <span className={classes.sortable}>Installment</span>
                                                                  <Sorty />
                                                                  {/* this table header is sortable */}
                                                              </div>
                                                          </TableCell>
                                                          <TableCell classes={classes}>
                                                              <div
                                                                  style={{
                                                                      display: "flex",
                                                                      alignItems: "center",
                                                                  }}
                                                                  onClick={() => handleSort("datePaid")}
                                                              >
                                                                  <span>Payment Date</span>
                                                              </div>
                                                          </TableCell>
                                                          <TableCell classes={classes}>
                                                              <div
                                                                  style={{
                                                                      display: "flex",
                                                                      alignItems: "center",
                                                                  }}
                                                                  onClick={() => handleSort("myAmount")}
                                                              >
                                                                  <span className={classes.sortable}>My Amount</span>
                                                                  <UpChevron />
                                                              </div>
                                                          </TableCell>
                                                          <TableCell classes={classes}>
                                                              <div
                                                                  style={{
                                                                      display: "flex",
                                                                      alignItems: "center",
                                                                  }}
                                                                  onClick={() => handleSort("voucherStatus")}
                                                              >
                                                                  <span className={classes.sortable}>Status</span>
                                                                  <DownChevron />
                                                              </div>
                                                          </TableCell>
                                                          <TableCell classes={classes} align="right"></TableCell>
                                                      </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                      {values.vouchers &&
                                                          orderBy(values.vouchers, columnToSort, sortDirection).map(
                                                              (x, i) => (
                                                                  <TableRow key={`tr-${i}`} selectable="false">
                                                                      <TableCell>
                                                                          <Link
                                                                              className={classes.idLink}
                                                                              to={`/voucher/${x.voucherKey}`}
                                                                          >
                                                                              {get(x, "voucherKey")}
                                                                          </Link>
                                                                      </TableCell>
                                                                      <TableCell>
                                                                          {!values.totalInstallments ||
                                                                          get(values, "totalInstallments", 1) === 1
                                                                              ? "1 of 1"
                                                                              : `${get(x, "installmentNum", "")} of ${
                                                                                    values.totalInstallments
                                                                                }`}
                                                                      </TableCell>
                                                                      <TableCell>
                                                                          {get(
                                                                              get(values, "installments", [{}])[
                                                                                  get(x, "installmentNum", 1) - 1
                                                                              ],
                                                                              "dueDate",
                                                                              ""
                                                                          )}
                                                                      </TableCell>
                                                                      <TableCell>
                                                                          {toDollarAmountAcceptZero(
                                                                              get(
                                                                                  values,
                                                                                  `myAmounts.${x.voucherKey}.myAmount`
                                                                              )
                                                                          )}
                                                                      </TableCell>
                                                                      <TableCell>
                                                                          <StatusChip
                                                                              status={get(x, "voucherStatus")}
                                                                          />
                                                                      </TableCell>
                                                                      <TableCell
                                                                          style={{
                                                                              textAlign: "right",
                                                                              paddingRight: "0px",
                                                                          }}
                                                                      >
                                                                          {get(
                                                                              compAction,
                                                                              `${userProfile.role}.${x.voucherStatus}.buttonText`,
                                                                              null
                                                                          ) === "Start" ? (
                                                                              <Button
                                                                                  variant="contained"
                                                                                  color="primary"
                                                                                  onClick={() =>
                                                                                      handleStartReviewingAsCorporateData(
                                                                                          values
                                                                                      )
                                                                                  }
                                                                              >
                                                                                  Start
                                                                              </Button>
                                                                          ) : get(
                                                                                voucherAction,
                                                                                `${userProfile.role}.${x.voucherStatus}`,
                                                                                null
                                                                            ) ? (
                                                                              <Link
                                                                                  className={classes.lb}
                                                                                  to={`/voucher/${x.voucherKey}`}
                                                                              >
                                                                                  <Button
                                                                                      variant={get(
                                                                                          voucherAction,
                                                                                          `${userProfile.role}.${x.voucherStatus}.variant`,
                                                                                          null
                                                                                      )}
                                                                                      color="primary"
                                                                                  >
                                                                                      {get(
                                                                                          compAction,
                                                                                          `${userProfile.role}.${x.voucherStatus}.buttonText`,
                                                                                          null
                                                                                      )}
                                                                                  </Button>
                                                                              </Link>
                                                                          ) : (
                                                                              <Link
                                                                                  className={classes.lb}
                                                                                  onClick={() =>
                                                                                      this.handleClearDocumentListOnClick()
                                                                                  }
                                                                                  to={`/voucher/${x.voucherKey}`}
                                                                              >
                                                                                  <Button
                                                                                      variant="outlined"
                                                                                      color="primary"
                                                                                  >
                                                                                      View
                                                                                  </Button>
                                                                              </Link>
                                                                          )}
                                                                      </TableCell>
                                                                  </TableRow>
                                                              )
                                                          )}
                                                  </TableBody>
                                              </Table>
                                          </Grid>
                                      </Grid>
                                  )}
                        </Paper>
                    ) : (
                        // no comp status
                        <div>
                            <Paper classes={classes}>
                                <Grid container alignItems="center" spacing={16}>
                                    <Grid item>
                                        <StepperIcon text="2" fill="#cccccc" />
                                    </Grid>
                                    <Grid item>
                                        <h4>Voucher</h4>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item>
                                        <p>
                                            Fill out your voucher information once you receive payment or proof of
                                            payment so you can get paid in a timely fashion. Vouchers submitted by noon
                                            on Tuesday are paid out the same week.
                                        </p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    )
                ) : null}

                <Dialog open={this.state.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                    <DialogContent>Are you sure you want to permanently delete this deal?</DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDelete} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.showDocumentList() && (
                    <Paper classes={classes}>
                        <Grid container alignItems="center" spacing={24} justify="space-between">
                            <Grid container item xs={5} alignItems="center" spacing={16}>
                                <Grid item>
                                    <h4>Documents</h4>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleDownloadDealDocuments(get(values, "dealKey"))}
                                >
                                    Download All
                                </Button>
                            </Grid>
                        </Grid>
                        <DocumentDrop
                            type="deal"
                            documentTypes="all"
                            viewOnly={true}
                            includeSource={true}
                            downloadView={true}
                            role={role}
                        />
                    </Paper>
                )}
            </div>
        );
    }
}

const compAction = {
    broker: {
        Draft: {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Broker Action Required": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
    },
    evp: {
        Draft: {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Broker Action Required": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
    },
    portlandBrokerAdmin: {
        Draft: {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Broker Action Required": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Portland Review Required": {
            buttonText: "Review",
            action: () => {},
            variant: "contained",
        },
    },
    arizonaBrokerAdmin: {
        Draft: {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Broker Action Required": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "AZ Review Required": {
            buttonText: "Review",
            action: () => {},
            variant: "contained",
        },
    },
    dataEntry: {
        "Needs Review": {
            buttonText: "Start",
            action: () => {},
            variant: "contained",
        },
        "In Review": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Admin Action Required": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        Closed: {
            buttonText: "View",
            action: () => {},
            variant: "outlined",
        },
    },
    dataAdmin: {
        "Final Review": {
            buttonText: "Review",
            action: () => {},
            variant: "contained",
        },
    },
};

const voucherAction = {
    broker: {
        Draft: {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Broker Action Required": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
    },
    evp: {
        Draft: {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Broker Action Required": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
    },
    portlandBrokerAdmin: {
        Draft: {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Broker Action Required": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Portland Review Required": {
            buttonText: "Review",
            action: () => {},
            variant: "contained",
        },
    },
    arizonaBrokerAdmin: {
        Draft: {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Broker Action Required": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "AZ Review Required": {
            buttonText: "Review",
            action: () => {},
            variant: "contained",
        },
    },
    dataEntry: {
        "Needs Review": {
            buttonText: "Start",
            action: () => {},
            variant: "contained",
        },
        "In Review": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        "Admin Action Required": {
            buttonText: "Edit",
            action: () => {},
            variant: "contained",
        },
        Closed: {
            buttonText: "View",
            action: () => {},
            variant: "outlined",
        },
    },
    dataAdmin: {
        "Final Review": {
            buttonText: "Review",
            action: () => {},
            variant: "contained",
        },
    },
};

export default withStyles(styles)(DealSummary);
