import React from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Help from "@material-ui/icons/Help";
import TextField from "@material-ui/core/TextField";

class BSSelect extends React.Component {
    render() {
        const {
            classes,
            combinedField,
            disabled,
            error,
            errorText,
            extraInputStyles,
            extraFormControlStyles,
            helperText,
            helperTextNotRequired,
            id,
            label,
            noFullWidth,
            onChange,
            onClick,
            options,
            placeholder,
            toolTipText,
            value,
        } = this.props;

        const accepted_values = new Set(options.map((option) => String(option["value"])));
        return (
            <FormControl
                fullWidth={!noFullWidth}
                classes={{ root: classes.formControl }}
                style={extraFormControlStyles}
            >
                {label && (
                    <InputLabel shrink htmlFor={id} className={classes.bootstrapFormLabel}>
                        {label}
                        {toolTipText && (
                            <React.Fragment>
                                <span data-tip data-for={`${id}-tool-tip`}>
                                    <Help style={{ fontSize: "110%" }} />
                                </span>
                                <ReactTooltip
                                    id={`${id}-tool-tip`}
                                    place="top"
                                    type="dark"
                                    effect="solid"
                                    className="statBoxTooltip"
                                >
                                    {toolTipText}
                                </ReactTooltip>
                            </React.Fragment>
                        )}
                    </InputLabel>
                )}
                <Grid container alignItems="flex-end" spacing={0}>
                    <Grid item xs>
                        <TextField
                            select
                            id={id}
                            value={value}
                            disabled={disabled}
                            onChange={onChange}
                            onClick={onClick}
                            className={classes.bootstrapInput}
                            placeholder={placeholder}
                            style={error ? { borderColor: "red", ...extraInputStyles } : extraInputStyles}
                            classes={
                                combinedField === "shiftLeft"
                                    ? { root: classes.combinedFieldShiftLeft }
                                    : combinedField === "labelLessShiftLeft"
                                    ? { root: classes.labelLessShiftLeft }
                                    : { root: classes.margin }
                            }
                            defaultValue=""
                            InputProps={{
                                disableUnderline: true,
                            }}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                                classes: {
                                    select: classes.bsSelect,
                                },
                                className: error ? classes.errorBorder : null,
                            }}
                        >
                            {accepted_values.has(String(value)) === false && value && (
                                <option key={this.props.value} value={this.props.value} disabled={true}>
                                    {this.props.value}
                                </option>
                            )}
                            {options.map((option) => (
                                <option key={option.value} value={option.value} disabled={option.disabled}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                {
                    errorText && error && (
                        <FormHelperText classes={classes} error={true}>
                            {errorText}
                        </FormHelperText>
                    ) /* Error Text */
                }
                {
                    helperText && !error && (
                        <FormHelperText
                            style={helperTextNotRequired && { color: "dimgrey" }}
                            classes={classes}
                            required={true}
                        >
                            {helperText}
                        </FormHelperText>
                    ) /* Helper Text */
                }
            </FormControl>
        );
    }
}

const styles = (theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    margin: {
        width: "100%",
    },
    bootstrapInput: {
        backgroundColor: theme.palette.common.white,
        fontSize: 14,
        minWidth: "40px",
        marginTop: "22px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
    },
    bsSelect: {
        fontSize: "14px",
        padding: "9px 6px",
        boxSizing: "border-box",
        height: "39px",
        width: "100%",
        "&:focus": {
            backgroundColor: "transparent",
        },
    },
    combinedFieldShiftLeft: {
        marginLeft: "-2px",
        borderRadius: "0 4px 4px 0",
        "& div": {
            borderRadius: "0 4px 4px 0",
        },
        width: "100%",
    },
    labelLessShiftLeft: {
        margin: "2px 0 0 -2px",
        "& div": {
            borderRadius: "0 4px 4px 0",
        },
    },
    error: {
        color: "red",
        fontStyle: "italic",
    },
    errorBorder: {
        borderColor: "red",
    },
    required: {
        color: "red",
        fontStyle: "italic",
    },
    formControl: {
        marginBottom: "22px",
    },
});

BSSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BSSelect);
