const GET_COMMENTS = "GET_COMMENTS";
const MAIN_COMMENT_CHANGE = "MAIN_COMMENT_CHANGE";
const TOGGLE_COMMENTS_DRAWER = "TOGGLE_COMMENTS_DRAWER";
const CLEAR_COMMENT = "CLEAR_COMMENT";
const SET_STATE_FOR_REQUEST_UPDATE = "SET_STATE_FOR_REQUEST_UPDATE";

export default {
    GET_COMMENTS,
    MAIN_COMMENT_CHANGE,
    TOGGLE_COMMENTS_DRAWER,
    CLEAR_COMMENT,
    SET_STATE_FOR_REQUEST_UPDATE,
};
