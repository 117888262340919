import types from "./types";

const togglePropertyRequestModal = (open) => {
    return {
        type: types.TOGGLE_PROPERTY_LISTING_MODAL,
        open: open,
    };
};

const updatePropertyRequest = (field, value) => {
    return {
        type: types.UPDATE_PROPERTY_REQUEST,
        field: field,
        value: value,
    };
};

const togglePropertySearchModal = (open) => {
    return {
        type: types.TOGGLE_PROPERTY_SEARCH_MODAL,
        open: open,
    };
};

const updatePropertyForListingDialog = (open) => {
    return {
        type: types.UPDATE_PROPERTY_FOR_LISTING_DIALOG,
        open: open,
    };
};

const addError = (field, error) => {
    return {
        type: types.ADD_REQUEST_PROPERTY_MODAL_ERROR,
        field: field,
        error: error,
    };
};

const updateDeletePropertyDialog = (isOpen) => {
    return {
        type: types.UPDATE_DELETE_DIALOG,
        open: isOpen,
    };
};

const updateNoDeletePropertyDialog = (isOpen) => {
    return {
        type: types.UPDATE_NO_DELETE_DIALOG,
        open: isOpen,
    };
};

const updateFilter = (path, value) => {
    return {
        type: "UPDATE_SEARCH_CRITERIA",
        path: path,
        value: value,
    };
};

const clearFilters = () => {
    return {
        type: types.CLEAR_PROPERTY_SEARCH_FILTERS,
    };
};

const togglePropertyTypeExpansionPanel = (panelName, open) => {
    return {
        type: types.TOGGLE_PROPERTY_TYPE_EXPANSION_PANEL,
        panelName: panelName,
        open: open,
    };
};

const toggleMarketFilterExpansionPanel = (panelName, open) => {
    return {
        type: types.TOGGLE_MARKET_FILTER_EXPANSION_PANEL,
        panelName: panelName,
        open: open,
    };
};

const loadProperty = (propertyData) => {
    return {
        type: types.LOAD_PROPERTY,
        property: propertyData,
    };
};

const loadPropertyImages = (images) => {
    return {
        type: types.LOAD_PROPERTY_IMAGES,
        images: images,
    };
};

const inputChange = (field, value) => {
    return {
        type: types.PROPERTY_INPUT_CHANGE,
        field: field,
        value: value,
    };
};
const addArrayElement = (arrayPath) => {
    return {
        type: types.ADD_ARRAY_ELEMENT,
        path: arrayPath,
    };
};
const setPropertyArray = (pathToArray, array) => {
    return {
        type: types.SET_PROPERTY_ARRAY,
        pathToArray: pathToArray,
        array: array,
    };
};

const deleteArrayElement = (arrayPath, index) => {
    return {
        type: types.DELETE_ARRAY_ELEMENT,
        path: arrayPath,
        index: index,
    };
};

const receiveProperties = (data, tab) => {
    return {
        type: types.RECEIVE_REVIEW_PROPERTIES,
        properties: data,
        tab: tab,
    };
};

const switchTab = (tab) => {
    return {
        type: types.SWITCH_REVIEW_PROPERTIES_TAB,
        tab: tab,
    };
};
const switchPropDetailTab = (tab) => {
    return {
        type: types.SWITCH_PROPERTY_DETAIL_TAB,
        tab: tab,
    };
};

const filterProperties = (query) => {
    return {
        type: types.FILTER_PROPERTIES,
        filterQuery: query,
    };
};

const handleSort = (columnName, sortDirection) => {
    return {
        type: types.SORT_REVIEW_PROPERTIES,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

const addPropertyManagementSection = (propManagementObject) => {
    return {
        type: types.ADD_PROPERTY_MANAGEMENT_SECTION,
        propManagementObject: propManagementObject,
    };
};

const resetState = () => {
    return {
        type: types.RESET,
    };
};

const countResults = (tab, numResults) => {
    return {
        type: types.COUNT_PROPERTY_RESULTS,
        numResults: numResults,
        tab: tab,
    };
};

export default {
    togglePropertyRequestModal,
    updatePropertyRequest,
    updateFilter,
    clearFilters,
    togglePropertyTypeExpansionPanel,
    loadProperty,
    addArrayElement,
    deleteArrayElement,
    receiveProperties,
    switchTab,
    filterProperties,
    handleSort,
    inputChange,
    switchPropDetailTab,
    addPropertyManagementSection,
    setPropertyArray,
    loadPropertyImages,
    resetState,
    countResults,
    toggleMarketFilterExpansionPanel,
    updateDeletePropertyDialog,
    updateNoDeletePropertyDialog,
    updatePropertyForListingDialog,
    togglePropertySearchModal,
    addError,
};
