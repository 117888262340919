export const propertyTypes = [
    {
        value: "Educational",
        label: "Educational",
    },
    {
        value: "Religious",
        label: "Religious",
    },
    {
        value: "Healthcare",
        label: "Healthcare",
    },
    {
        value: "Office",
        label: "Office",
    },
    {
        value: "Industrial",
        label: "Industrial",
    },
    {
        value: "Land",
        label: "Land",
    },
    {
        value: "Retail",
        label: "Retail",
    },
    {
        value: "Hospitality",
        label: "Hospitality",
    },
    {
        value: "Multifamily",
        label: "Multifamily",
    },
    {
        value: "Life Science",
        label: "Life Science",
    },
    {
        value: "Other",
        label: "Other",
    },
];

export const propertyTypeOptions = [
    {
        value: "",
        label: "Select",
        disabled: true,
    },
    ...propertyTypes,
];
