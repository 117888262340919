import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { LeftChevron } from "../../../common/js/icons";

const styles = {
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    leftIcon: {
        marginLeft: "25px",
    },
    progressDiv: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
    },
};

const AddContactToolbar = (props) => {
    const { classes, createContact, updateContact, cancelChanges, newContact, elementsSaving } = props;

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
            <Grid item zeroMinWidth style={{ paddingBottom: "2px", paddingRight: "0px" }}>
                <Link to="/contacts">
                    <LeftChevron />
                </Link>
            </Grid>

            <Grid item xs>
                <h2 className="page-title">{newContact ? "Add New Contact" : "Edit Contact"}</h2>
            </Grid>

            <Grid item xs>
                <Grid container alignItems="center" spacing={0} justify="flex-end">
                    <Grid item className={classes.leftIcon}>
                        <Button variant="outlined" color="primary" onClick={() => cancelChanges()}>
                            Cancel
                        </Button>
                        {elementsSaving.submit ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={newContact ? createContact : updateContact}
                            >
                                Save
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(AddContactToolbar);
