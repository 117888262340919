import actions from "./actions";
import contactUtils from "../utils";
import get from "lodash/get";

/**
 * @deprecated see notes below
 */
const addContact = (contactType) => {
    return (dispatch, getState) => {
        let numContacts = 0;
        if (getState().contactsReducer.contacts[contactType]) {
            numContacts = Object.keys(getState().contactsReducer.contacts[contactType]).length;
        }
        dispatch(actions.addContact(contactType, numContacts));
    };
};

/**
 * @deprecated see notes below
 */
const updateContact = (contactType, key, field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateContact(contactType, key, field, value));
    };
};

/**
 * @deprecated see notes below
 */
const updateContactCompany = (contactType, key, field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateContactCompany(contactType, key, field, value));
    };
};

/**
 * @deprecated see notes below
 */
const deleteContact = (contactType, key) => {
    return (dispatch, getState) => {
        dispatch(actions.deleteContact(contactType, key));
    };
};

/**
 * @deprecated see notes below
 */
const initContacts = (contactType, contacts) => {
    return (dispatch, getState) => {
        dispatch(actions.initContacts(contactType, contacts));
    };
};

/**
 * @deprecated all code ABOVE this is deprecated.  use functions below along with
 *  ContactContainer and ContactForm moving forward. The code above still being used
 * in some code until it can be changed to use ContactContainer and ContactForm
 */

const addContactElement = (contactType) => {
    return (dispatch, getState) => {
        dispatch(actions.addContactElement(contactType, get(getState().contactsReducer, contactType, []).length));
    };
};

const updateContactElement = (contactType, index, field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateContactElement(contactType, index, field, value));
    };
};

const deleteContactElement = (contactType, index) => {
    return (dispatch, getState) => {
        dispatch(actions.deleteContactElement(contactType, index));
    };
};

const initializeContacts = (contactType, attributeContacts) => {
    return (dispatch, _) => {
        const contacts = [];
        if (attributeContacts) {
            Object.keys(attributeContacts).forEach((attributeContact, _) => {
                contacts.push(contactUtils.unpack(attributeContacts[attributeContact]));
            });
        }
        dispatch(actions.initializeContacts(contactType, contacts));
    };
};

const updateContactElementCompany = (contactType, index, field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateContactElementCompany(contactType, index, field, value));
    };
};

export default {
    addContact,
    updateContactCompany,
    updateContact,
    deleteContact,
    initContacts,
    addContactElement,
    updateContactElement,
    deleteContactElement,
    initializeContacts,
    updateContactElementCompany,
};
