const SET_EVP_DATA_COMPONENT = "SET_EVP_DATA_COMPONENT";
const SET_BROKER_DATA_COMPONENT = "SET_BROKER_DATA_COMPONENT";
const SORT_DASHBOARD_COLUMN = "SORT_DASHBOARD_COLUMN";
const SET_DASHBOARD_PARAM = "SET_DASHBOARD_PARAM";

export default {
    SET_EVP_DATA_COMPONENT,
    SET_BROKER_DATA_COMPONENT,
    SORT_DASHBOARD_COLUMN,
    SET_DASHBOARD_PARAM,
};
