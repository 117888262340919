import React from "react";
import Grid from "@material-ui/core/Grid";
import LicenseDetail from "./LicenseDetail";
import MultiAddInput from "../../../common/js/components/MultiAddInput";

class LicenseInformation extends React.Component {
    render() {
        const { licenses, disableInputs, handleAddArrayElement, handleDeleteArrayElement, handleUpdateArrayElement } =
            this.props;

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <MultiAddInput
                            addButtonText="Add License"
                            buttonType={"card"}
                            disableInputs={disableInputs === true}
                            displayFirstWhenEmpty={false}
                            elements={licenses}
                            onAdd={() => handleAddArrayElement("licenses")}
                            onUpdate={(e, index) =>
                                handleUpdateArrayElement("licenses", index, e.target.id, e.target.value)
                            }
                            render={(element, elementIndex) => (
                                <LicenseDetail
                                    element={element}
                                    elementIndex={elementIndex}
                                    onDelete={(index) => handleDeleteArrayElement("licenses", index)}
                                    {...this.props}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default LicenseInformation;
