import React from "react";
import { connect } from "react-redux";
import { Grid, CircularProgress } from "@material-ui/core";
import Scroll from "react-scroll";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { withStyles } from "@material-ui/core/styles";
import operations from "./ducks/operations";
import documentOperations from "../documents/ducks/operations";
import AuditListRow from "./AuditorListRow";

const AuditListResults = (props) => {
    const {
        role,
        classes,
        auditorList,
        isLoading,
        viewDocument,
        handleDragEnd,
        activeTab,
        toggleAuditorListAuditFlag,
    } = props;

    let Element = Scroll.Element;
    const getListStyle = (isDraggingOver) => ({
        padding: "",
    });
    if (isLoading) {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.alternateContent}>
                            <CircularProgress color="primary" />
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
    if (auditorList.length < 1) {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.alternateContent}>Nothing to Show</div>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Element style={{ height: "600px", overflow: "scroll" }}>
                        <React.Fragment>
                            <DragDropContext onDragEnd={(result) => handleDragEnd(result, auditorList)}>
                                <Droppable droppableId="droppable" style={{ maxWidth: "100%" }}>
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {auditorList.map((comp, i) => {
                                                return (
                                                    <AuditListRow
                                                        role={role}
                                                        i={i}
                                                        comp={comp}
                                                        viewDocument={viewDocument}
                                                        activeTab={activeTab}
                                                        toggleAuditorListAuditFlag={toggleAuditorListAuditFlag}
                                                    />
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </React.Fragment>
                    </Element>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const styles = (theme) => ({
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
});

const mapStateToProps = (state) => ({
    activeTab: state.auditReducer.activeTab,
    auditorList: state.auditReducer.auditorList,
    isLoading: state.utilsReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    handleDragEnd: (results, comps) => {
        dispatch(operations.handleDragEnd(results, comps));
    },
    toggleAuditorListAuditFlag: (dealKey) => {
        dispatch(operations.toggleAuditorListAuditFlag(dealKey));
    },
    viewDocument: (url, document) => {
        dispatch(documentOperations.viewDocument(url, document));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AuditListResults));
