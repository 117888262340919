import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ArrowForward from "@material-ui/icons/ArrowForward";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
    defTerm: {
        fontSize: "13px",
        textTransform: "uppercase",
        color: "#9E9E9E",
        fontWeight: 600,
        display: "inline-block",
        padding: "0 0 8px",
    },
    defData: {
        fontSize: 22,
        fontWeight: 700,
    },
    listTitle: {
        margin: "8px 0",
        paddingLeft: 16,
    },
    link: {
        textDecoration: "none",
        "&:focus": {
            textDecoration: "none",
        },
    },
});
class KMTools extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Paper style={{ marginBottom: "12px" }}>
                <List disablePadding component="nav">
                    <ListItem divider disableGutters>
                        <h3 className={classes.listTitle}>KM Toolbox</h3>
                        <Divider />
                    </ListItem>

                    <Link to="/training-content" className={classes.link}>
                        <ListItem divider button disableGutters>
                            <ListItemText primary="Training Content" />
                            <ListItemSecondaryAction>
                                <ListItemIcon>
                                    <ArrowForward />
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Link>

                    <ListItem
                        divider
                        button
                        disableGutters
                        onClick={() =>
                            window.open(
                                "https://s3.us-east-2.amazonaws.com/connect.kidder.com/downloads/KM+Connect+Q+%26+A.docx",
                                "_blank"
                            )
                        }
                    >
                        <ListItemText primary="FAQ" />
                        <ListItemSecondaryAction>
                            <ListItemIcon>
                                <ArrowForward />
                            </ListItemIcon>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem
                        divider
                        button
                        disableGutters
                        onClick={() => window.open("http://www.kiddermathews.com/research.php", "_blank")}
                    >
                        <ListItemText primary="Research Reports" />
                        <ListItemSecondaryAction>
                            <ListItemIcon>
                                <ArrowForward />
                            </ListItemIcon>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem
                        divider
                        button
                        disableGutters
                        onClick={() => window.open("https://app.powerbi.com/sharedwithme", "_blank")}
                    >
                        <ListItemText primary="Power BI Reports" />
                        <ListItemSecondaryAction>
                            <ListItemIcon>
                                <ArrowForward />
                            </ListItemIcon>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem
                        divider
                        button
                        disableGutters
                        onClick={() =>
                            window.open(
                                "https://s3.us-east-2.amazonaws.com/connect.kidder.com/downloads/KM+Connect+Helpful+Links.xlsx",
                                "_blank"
                            )
                        }
                    >
                        <ListItemText primary="Helpful Links" />
                        <ListItemSecondaryAction>
                            <ListItemIcon>
                                <ArrowForward />
                            </ListItemIcon>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Paper>
        );
    }
}

export default withStyles(styles)(KMTools);
