import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import BSInput from "../../../common/js/components/BSInput";
import get from "lodash/get";
import React from "react";

const styles = {
    field: {
        width: "100%",
        height: "84px",
        margin: "0px",
    },
};

class LocationFilters extends React.Component {
    render() {
        const { updateFilter, searchCriteria, classes, onKeyPress, hideFilters } = this.props;

        return (
            <React.Fragment>
                <Grid container justify="space-between" spacing={16} className={classes.field}>
                    <BSInput
                        label="City"
                        placeholder="Cities (separated by comma(,))"
                        value={get(searchCriteria, "locationCriteria.cities", "")}
                        onChange={(e) => updateFilter("locationCriteria.cities", e.target.value)}
                        onKeyPress={onKeyPress}
                    />
                </Grid>

                <Grid container justify="space-between" spacing={16} className={classes.field}>
                    <BSInput
                        label="State"
                        placeholder="States (separated by comma(,))"
                        value={get(searchCriteria, "locationCriteria.states", "")}
                        onChange={(e) => updateFilter("locationCriteria.states", e.target.value)}
                        onKeyPress={onKeyPress}
                    />
                </Grid>
                <Grid container justify="space-between" spacing={16} className={classes.field}>
                    <BSInput
                        label={"Zip Code"}
                        placeholder="Zip Codes (separated by comma(,))"
                        value={get(searchCriteria, "locationCriteria.zips", "")}
                        onChange={(e) => updateFilter("locationCriteria.zips", e.target.value)}
                        onKeyPress={onKeyPress}
                    />
                </Grid>
                <Grid container justify="space-between" spacing={8} className={classes.field}>
                    <BSInput
                        label={"County"}
                        placeholder="Counties (separated by comma(,))"
                        value={get(searchCriteria, "locationCriteria.counties", "")}
                        onChange={(e) => updateFilter("locationCriteria.counties", e.target.value)}
                        onKeyPress={onKeyPress}
                    />
                </Grid>
                {!hideFilters && (
                    <Grid container justify="space-between" spacing={8} className={classes.field}>
                        <BSInput
                            label={"True Client Name"}
                            placeholder="True Client Name"
                            value={get(searchCriteria, "clientName", "")}
                            onChange={(e) => updateFilter("clientName", e.target.value)}
                            onKeyPress={onKeyPress}
                        />
                    </Grid>
                )}
                <Grid container justify="space-between" spacing={8} className={classes.field}>
                    <BSInput
                        label={"Building Name"}
                        placeholder="Building Name"
                        value={get(searchCriteria, "buildingName", "")}
                        onChange={(e) => updateFilter("buildingName", e.target.value)}
                        onKeyPress={onKeyPress}
                    />
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(LocationFilters);
