import each from "lodash/each";
import get from "lodash/get";
import utils from "../utils/operations";

const viewHeaders = {
    data_team_needs_review: [
        {
            name: "Property",
            prop: "propertyAttributes.propertyName",
            type: "plaintext",
            transform: (property) => {
                return (
                    get(property, "primaryAddress.address1", "") + " " + get(property, "primaryAddress.address2", "")
                );
            },
        },
        {
            name: "Brokers",
            prop: "auditCreatedBy",
            type: "aggregate",
            // transform: (auditCreatedBy) => { return aggregateBrokers(auditCreatedBy, 'brokerAgentKey.person') }
        },
        {
            name: "Modified",
            prop: "auditLastModifiedTimestamp",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Prop Type",
            prop: "propertyType",
            type: "plaintext",
            // transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Status",
            prop: "propertyStatus",
            type: "plaintext",
        },
    ],
};

const brokerButtonActions = {
    Draft: {
        text: "Edit",
        variant: "contained",
        statusToDisplay: "",
    },
    "Needs Review": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "Awaiting Admin Action",
    },
    "In Review": {
        text: "View",
        variant: "outlined",
        statusToDisplay: "In Review",
    },
    Closed: {},
    "Broker Action Required": {
        text: "Edit",
        variant: "contained",
        statusToDisplay: "Action Required",
    },
    "In Progress": {},
};
export const buttonActions = {
    broker: {
        ...brokerButtonActions,
    },
    evp: {
        ...brokerButtonActions,
    },
    portlandBrokerAdmin: {
        ...brokerButtonActions,
    },
    arizonaBrokerAdmin: {
        ...brokerButtonActions,
    },
    dataEntry: {
        Draft: {},
        "Needs Review": {
            text: "Start",
            variant: "contained",
            statusToDisplay: "Needs Review",
        },
        "In Review": {},
        Closed: {},
        "Broker Action Required": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "Awaiting Broker Action",
        },
        "Admin Action Required": {
            text: "Review",
            variant: "contained",
            statusToDisplay: "Action Required",
        },
        Requested: {
            text: "Review",
            variant: "contained",
            statusToDisplay: "Needs Review",
        },
        "Update Requested": {
            text: "Review",
            variant: "contained",
            statusToDisplay: "Update Requested",
        },
    },
    dataAdmin: {
        Draft: {},
        "Needs Review": {
            text: "Start",
            variant: "contained",
            statusToDisplay: "Needs Review",
        },
        "In Review": {},
        Closed: {},
        "Broker Action Required": {
            text: "View",
            variant: "outlined",
            statusToDisplay: "Awaiting Broker Action",
        },
        "Admin Action Required": {
            text: "Review",
            variant: "contained",
            statusToDisplay: "Action Required",
        },
        "In Progress": {},
        "Update Requested": {
            text: "Review",
            variant: "contained",
            statusToDisplay: "Update Requested",
        },
        Requested: {
            text: "Review",
            variant: "contained",
            statusToDisplay: "Needs Review",
        },
    },
};

const columnsToQueryFilter = [
    "primaryAddress.address1",
    "secondaryAddress",
    "propertyStatus",
    "propertyType",
    "auditCreatedBy",
    "propertyAttributes.propertyName",
];

const filterByQuery = (data, columnsToQuery, query) => {
    let included = false;
    each(columnsToQuery, (column) => {
        if (get(data, column)) {
            if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                included = true;
            }
        }
    });
    return included;
};

export default {
    viewHeaders,
    columnsToQueryFilter,
    filterByQuery,
};
