import React from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Today from "@material-ui/icons/Today";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

class TotalConsiderationForm extends React.Component {
    handleChangeEvent = (event) => {
        this.props.handleTotalConsiderationChange(event.target.id, event.target.value);
    };

    handleCheckboxEvent = (event, checked) => {
        this.props.handleTotalConsiderationChange(event.target.value, checked);
    };

    render() {
        const {
            classes,
            disableInputs,
            errors,
            handleCalculateTotalConsiderationDetails,
            totalConsideration,
            transactionType,
            handleAutocalculateTiSf,
            handleTotalConsiderationAmountChange,
            deal,
            invoice,
            handleClearRentSchedule,
        } = this.props;

        if (totalConsideration.escalationRateType == null) {
            this.props.handleTotalConsiderationChange("escalationRateType", "%");
        }

        return (
            <form>
                {transactionType !== "SALE" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <small
                                style={{
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                    marginBottom: "20px",
                                    fontSize: "13px",
                                    color: "dimgrey",
                                }}
                            >
                                {" "}
                                Please input the fields marked as required below. Generating your rent schedule is not
                                required. If you do generate your rent schedule, this information can be auto-populated
                                on a KM Invoice by clicking the "Generate Invoice" button at the top of this form. The
                                generated invoice will be in a word document, so you can make changes as needed.
                            </small>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={4}>
                                <Grid container alignItems="flex-start" spacing={8}>
                                    <Grid item xs={12}>
                                        <BSInput
                                            id="totalConsiderationAmount"
                                            helperText="Required"
                                            errorText="Required"
                                            label="TOTAL CONSID. (OFFICIAL)*"
                                            toolTipText="This is the official Total Consideration for the transaction.<br />
                                            This is a free form field, so it is not dependent on the optional calendar below.<br />
                                            If you would like, you can input this field with the additional required fields and<br />
                                            move forward without calculating the rent roll."
                                            value={get(deal, "totalConsiderationAmount", null)}
                                            error={errors.totalConsiderationAmount}
                                            onChange={(e) => handleTotalConsiderationAmountChange(e.target.value)}
                                            placeholder="Total Consideration Amount"
                                            disabled={disableInputs === true}
                                            startAdornment="$"
                                            numeric
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <BSInput
                                    id="commencementDate"
                                    label="Commencement Date*"
                                    helperText="Required"
                                    errorText="Required"
                                    value={totalConsideration.commencementDate}
                                    error={errors.commencementDate}
                                    onChange={this.handleChangeEvent}
                                    placeholder="Commencement Date"
                                    disabled={disableInputs === true}
                                    type="date"
                                    startAdornment={<Today />}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BSInput
                                    id="expirationDate"
                                    label="Expiration Date*"
                                    helperText="Required"
                                    errorText="Required"
                                    value={totalConsideration.expirationDate}
                                    error={errors.expirationDate}
                                    onChange={this.handleChangeEvent}
                                    placeholder="Expiration Date"
                                    disabled={disableInputs === true}
                                    type="date"
                                    startAdornment={<Today />}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="termNumMonths"
                                    label="Term (Months)*"
                                    helperText="Required"
                                    errorText="Required (ex. 9)"
                                    error={errors.termNumMonths}
                                    value={totalConsideration.termNumMonths}
                                    onChange={this.handleChangeEvent}
                                    disabled={disableInputs === true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="squareFeet"
                                    label="Square Feet*"
                                    helperText="Required"
                                    errorText="Required (ex. 98101)"
                                    error={errors.squareFeet}
                                    value={totalConsideration.squareFeet}
                                    onChange={handleAutocalculateTiSf}
                                    placeholder="Enter square feet"
                                    disabled={disableInputs === true}
                                    integer
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <Grid container alignItems="flex-start" spacing={0}>
                                    <Grid item xs={12}>
                                        <BSInput
                                            id="preTermFreeMonths"
                                            error={errors.preTermFreeMonths}
                                            value={totalConsideration.preTermFreeMonths}
                                            label="STARTING FREE MONTHS (OUTSIDE TERM)"
                                            toolTipText="If your lease has starting free months outside of the term, please input them here.<br />
                                                        If your lease only has free months inside the term, please disregard this field and<br />
                                                        input your free months by expanding the term that is generated by the calculator below."
                                            onChange={this.handleChangeEvent}
                                            disabled={disableInputs === true}
                                            placeholder="Number free months before term"
                                            integer
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container alignItems="flex-start" spacing={0}>
                                    <Grid item xs={12}>
                                        <BSInput
                                            id="postTermFreeMonths"
                                            error={errors.postTermFreeMonths}
                                            value={totalConsideration.postTermFreeMonths}
                                            label="ENDING FREE MONTHS (OUTSIDE TERM)"
                                            toolTipText="If your lease has ending free months outside of the term, please input them here.<br />
                                                        If your lease only has free months inside the term, please disregard this field and<br />
                                                        input your free months by expanding the term that is generated by the calculator below."
                                            onChange={this.handleChangeEvent}
                                            placeholder="Number free months after term"
                                            disabled={disableInputs === true}
                                            integer
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={4}>
                                <BSInput
                                    id="ratePerSqftPerMonth"
                                    label="Rent Per SF Per Month*"
                                    helperText="Required"
                                    errorText="Required (ex. 981.01)"
                                    error={errors.ratePerSqftPerMonth}
                                    value={totalConsideration.ratePerSqftPerMonth}
                                    onChange={this.handleChangeEvent}
                                    startAdornment="$"
                                    disabled={disableInputs === true}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BSInput
                                    id="ratePerSqftPerYear"
                                    label="Rent Per SF Per Year*"
                                    helperText="Required"
                                    errorText="Required (ex. 981.01)"
                                    error={errors.ratePerSqftPerYear}
                                    value={totalConsideration.ratePerSqftPerYear}
                                    onChange={this.handleChangeEvent}
                                    startAdornment="$"
                                    disabled={disableInputs === true}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BSInput
                                    id="ratePerMonth"
                                    type="number"
                                    label="Rent Per Month"
                                    helperText="Required"
                                    error={errors.ratePerMonth}
                                    value={totalConsideration.ratePerMonth}
                                    onChange={this.handleChangeEvent}
                                    startAdornment="$"
                                    disabled={disableInputs === true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <Grid container spacing={0}>
                                    <Grid item xs>
                                        <BSInput
                                            id="escalationRate"
                                            label="Escalation Rate*"
                                            helperText="Required"
                                            errorText="Required (ex. 981.01)"
                                            value={totalConsideration.escalationRate}
                                            error={errors.escalationRate}
                                            onChange={this.handleChangeEvent}
                                            placeholder="Enter escalation rate"
                                            disabled={disableInputs === true}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BSSelect
                                            id="escalationRateType"
                                            fullWidth
                                            combinedField="shiftLeft"
                                            error={errors.escalationRateType}
                                            value={totalConsideration.escalationRateType}
                                            onChange={this.handleChangeEvent}
                                            disabled={disableInputs === true}
                                            options={[
                                                {
                                                    value: "%",
                                                    label: "%",
                                                },
                                                {
                                                    value: "$",
                                                    label: "$",
                                                },
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="escalationTerm"
                                    label="Escalation Frequency (Months)*"
                                    helperText="Required"
                                    errorText="Required"
                                    value={totalConsideration.escalationTerm}
                                    error={errors.escalationTerm}
                                    placeholder="Enter months"
                                    disabled={disableInputs === true}
                                    onChange={this.handleChangeEvent}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" alignContent="space-between" spacing={8}>
                            <Grid item xs>
                                <FormGroup className={classes.spacedOut}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color={"primary"}
                                                checked={totalConsideration.escalateAtYearStart ? true : false}
                                                onChange={(e, c) => this.handleCheckboxEvent(e, c)}
                                                value="escalateAtYearStart"
                                                disabled={disableInputs === true}
                                            />
                                        }
                                        label="Escalate at 1st of the year?"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item>
                                {disableInputs !== true &&
                                    (!invoice ||
                                        (invoice && !invoice["periods"]) ||
                                        (invoice && invoice["periods"] && isEmpty(invoice["periods"]))) && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleCalculateTotalConsiderationDetails}
                                        >
                                            Generate Rent Schedule (Optional)
                                        </Button>
                                    )}

                                {disableInputs !== true &&
                                    invoice &&
                                    invoice["periods"] &&
                                    !isEmpty(invoice["periods"]) && (
                                        <Button variant="contained" color="primary" onClick={handleClearRentSchedule}>
                                            Clear Rent Schedule
                                        </Button>
                                    )}
                            </Grid>
                        </Grid>
                    </div>
                )}
            </form>
        );
    }
}

export default TotalConsiderationForm;
