import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

class Users extends React.Component {
    constructor() {
        super();
        this.state = {
            users: [],
        };
    }

    componentDidMount() {
        fetch("http://localhost:8080/users")
            .then((results) => {
                return results.json();
            })
            .then((data) => {
                this.setState({ users: data });
            });
    }

    render() {
        return (
            <ListGroup>
                <ListGroupItem></ListGroupItem>
                <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
                <ListGroupItem>Morbi leo risus</ListGroupItem>
                <ListGroupItem>Porta ac consectetur ac</ListGroupItem>
                <ListGroupItem>Vestibulum at eros</ListGroupItem>
            </ListGroup>
        );
    }
}

export default Users;
