import axios from "axios";
import isEmpty from "lodash/isEmpty";
import authOperations from "../auth/ducks/operations";
import config from "../../../config";
import https from "https";

/*
 * Create an Axios Client with defaults
 */
const client = axios.create({
    baseURL: config.HOST_URL,
});

/**
 * Request Wrapper with default success/error actions
 */
export const request = function (options, dispatch) {
    const onSuccess = function (response) {
        return response;
    };

    const onError = function (error) {
        console.error("Request Failed:", error.config);

        if (isEmpty(localStorage.getItem("token")) || (error.response && error.response.status === 403)) {
            console.log("403 Response remove token and invoke redirectToLogin");
            localStorage.removeItem("token");
            localStorage.setItem("savedUrl", window.location.pathname);
            console.log("axios-wrapper,setting savedUrl=" + window.location.pathname);
            dispatch(authOperations.redirectToLogin());
            return;
        }
        return Promise.reject(error.response || error.message);
    };

    const agent = new https.Agent({
        rejectUnauthorized: false,
    });

    const token = localStorage.getItem("token");
    let requestOptions = {
        ...options,
        httpsAgent: agent,
        crossDomain: true,
    };

    if (!isEmpty(token)) {
        requestOptions = {
            ...requestOptions,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                ...options.headers,
            },
        };
    }

    return client(requestOptions).then(onSuccess).catch(onError);
};
