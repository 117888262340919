import React from "react";
import { connect } from "react-redux";
import operations from "./ducks/operations";
import { Modal } from "@material-ui/core";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const DeleteConfirmationModalContainer = (props) => {
    const { open, toggleModal } = props;

    return (
        <Modal open={open} onClose={() => toggleModal(false)}>
            <DeleteConfirmationModal {...props} />
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    parentEntityTypeKey: state.complianceReducer.conditions.parentEntityTypeKey,
    modal: state.complianceReducer.modal,
});

const mapDispatchToProps = (dispatch) => ({
    deleteRule: (ruleKey, parentEntityTypeKey) => {
        dispatch(operations.deleteRule(ruleKey, parentEntityTypeKey));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConfirmationModalContainer);
