import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import App from "./app/js/App";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./store";
import LoginContainer from "./app/js/auth/LoginContainer";

const Root = ({ store }) => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/login" component={LoginContainer} />
                <Route path="/:filter?" component={App} />
            </Switch>
        </ConnectedRouter>
    </Provider>
);

Root.propTypes = {
    store: PropTypes.object.isRequired,
};

export default Root;
