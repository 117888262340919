import React from "react";
import AddAssetServicesDataComponent from "./PropertyAddAssetServicesDataComponent";
import PropertyManagementComponent from "./PropertyManagementComponent";
import PropertyHistoryContainer from "./PropertyHistoryContainer";
import { prop_management_contact_options } from "../deal/constants";
import { Grid, Divider } from "@material-ui/core";

const PropertyAssetServicesTab = (props) => {
    const {
        role,
        classes,
        property,
        propertyManagement,
        autocompleteResults,
        searchKMPerson,
        handleInput,
        handleAddArrayElement,
        handleDeleteArrayElement,
        handleUpdateArrayElement,
        handleSearchCompany,
        errors,
        tab,
        addPropertyManagementSection,
        hasPortfolioProperties,
        propertyManagementSnapshots,
        location,
    } = props;

    return (
        <React.Fragment>
            {tab === "property_management" && (
                <React.Fragment>
                    {propertyManagement && propertyManagement.propertyManagementKey ? (
                        <PropertyManagementComponent
                            {...props}
                            role={role}
                            classes={classes}
                            property={property}
                            propertyManagement={propertyManagement}
                            autocompleteResults={autocompleteResults}
                            searchKMPerson={searchKMPerson}
                            handleInput={handleInput}
                            handleAddArrayElement={handleAddArrayElement}
                            handleDeleteArrayElement={handleDeleteArrayElement}
                            handleUpdateArrayElement={handleUpdateArrayElement}
                            prop_management_contact_options={prop_management_contact_options}
                            handleSearchCompany={handleSearchCompany}
                            isSnapshot={false}
                            hasPortfolioProperties={hasPortfolioProperties}
                            propertyManagementSnapshots={propertyManagementSnapshots}
                        />
                    ) : (
                        <AddAssetServicesDataComponent
                            role={role}
                            addPropertyManagementSection={addPropertyManagementSection}
                            classes={classes}
                            property={property}
                        />
                    )}
                    <Divider />
                    <Grid container spacing={8}>
                        <Grid item xs>
                            <h3>Property History</h3>
                        </Grid>
                    </Grid>
                    <PropertyHistoryContainer
                        role={role}
                        classes={classes}
                        property={property}
                        propertyManagement={propertyManagement}
                        autocompleteResults={autocompleteResults}
                        searchKMPerson={searchKMPerson}
                        handleInput={handleInput}
                        handleAddArrayElement={handleAddArrayElement}
                        handleDeleteArrayElement={handleDeleteArrayElement}
                        handleUpdateArrayElement={handleUpdateArrayElement}
                        prop_management_contact_options={prop_management_contact_options}
                        handleSearchCompany={handleSearchCompany}
                        errors={errors}
                        snapshots={propertyManagementSnapshots}
                        location={location}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PropertyAssetServicesTab;
