import React from "react";
import { connect } from "react-redux";
import operations from "./ducks/operations";
import { Modal } from "@material-ui/core";
import ComplianceRuleModal from "./ComplianceRuleModal";

const ComplianceRuleModalContainer = (props) => {
    const { handleClose, modal } = props;

    return (
        <Modal open={modal.open} onClose={handleClose}>
            <ComplianceRuleModal {...props} />
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.utilsReducer.isLoading,
    conditions: state.complianceReducer.conditions,
    conditionValues: state.complianceReducer.conditionValues,
    actionValues: state.complianceReducer.actionValues,
    modal: state.complianceReducer.modal,
});

const mapDispatchToProps = (dispatch) => ({
    getConditions: (parentEntityTypeKey, ruleKey) => {
        dispatch(operations.getConditions(parentEntityTypeKey, ruleKey));
    },
    selectConditions: (field, value) => {
        dispatch(operations.selectConditions(field, value));
    },
    selectConditionType: (conditionTypeKey) => {
        dispatch(operations.selectConditionType(Number(conditionTypeKey)));
    },
    handleActionsSelect: (field, value) => {
        dispatch(operations.selectFromActions(field, Number(value)));
    },
    addCondition: (ruleKey, condition) => {
        dispatch(operations.addCondition(ruleKey, condition));
    },
    addAction: () => {
        dispatch(operations.addAction());
    },
    handleRuleInputChange: (field, value) => {
        dispatch(operations.handleRuleInputChange(field, value));
    },
    removeCondition: (conditionTypeKey) => {
        dispatch(operations.removeCondition(conditionTypeKey));
    },
    removeAction: (ruleKey, documentTypeKey) => {
        dispatch(operations.removeAction(ruleKey, documentTypeKey));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceRuleModalContainer);
