import operations from "./ducks/operations";
import validation from "./ducks/validation";
import get from "lodash/get";

/**
 * @deprecated see notes below
 */
const unpackContact = (contact, invoiceRecipientId) => {
    return {
        personContactInfoKey: contact.personContactInfoKey,
        personKey: contact.person != null ? contact.person.personKey : null,
        firstName: contact.person != null ? contact.person.firstName : null,
        lastName: contact.person != null ? contact.person.lastName : null,
        contactInfoKey: contact.contactInfo != null ? contact.contactInfo.contactInfoKey : null,
        emailAddress: contact.emailAddress,
        phoneNumber: contact.phoneNumber != null ? contact.phoneNumber.phoneNumber : null,
        phoneNumberKey: contact.phoneNumber != null ? contact.phoneNumber.phoneNumberKey : null,
        address1: contact.address != null ? contact.address.address1 : null,
        addressKey: contact.address != null ? contact.address.key : null,
        zipCode: contact.address != null && contact.address.zipCode != null ? contact.address.zipCode.zipCode : null,
        zipCodeKey: contact.address != null && contact.address.zipCode != null ? contact.address.zipCode.key : null,
        city: contact.address != null && contact.address.city != null ? contact.address.city.city : null,
        cityKey: contact.address != null && contact.address.city != null ? contact.address.city.key : null,
        state:
            contact.address != null && contact.address.city != null && contact.address.city.state != null
                ? contact.address.city.state.stateCode
                : null,
        invoiceRecipient: invoiceRecipientId === contact.personContactInfoKey,
    };
};

/**
 * @deprecated see notes below
 */
const getContactForType = (contactType, contactKey, state) => {
    const contacts = getContacts(contactType, state);
    if (contacts && contactKey) {
        let keyProperties = Object.keys(contacts);
        for (var index in keyProperties) {
            if (contacts[index].contactKey === contactKey) {
                return contacts[index];
            }
        }
    }
    return null;
};

/**
 * @deprecated see notes below
 */
const initContactsFromDeal = (data) => {
    var contactInfo = {};
    const invoiceRecipientId =
        data.invoiceRecipient && data.invoiceRecipient.personContactInfoKey
            ? data.invoiceRecipient.personContactInfoKey
            : null;
    const tenant = data.buyerTenantContact != null ? unpackContact(data.buyerTenantContact, invoiceRecipientId) : null;
    const landlord =
        data.sellerLandlordContact != null ? unpackContact(data.sellerLandlordContact, invoiceRecipientId) : null;
    const outsideBroker =
        data.outsideBrokerContact != null ? unpackContact(data.outsideBrokerContact, invoiceRecipientId) : null;

    let contactIndex = 0;

    if (tenant != null) {
        contactInfo = {
            [contactIndex]: {
                contactKey: "BUYER_TENANT",
                ...tenant,
                company: data.buyerTenantCompany ? data.buyerTenantCompany : {},
            },
        };
        ++contactIndex;
    }
    if (landlord != null) {
        contactInfo = {
            ...contactInfo,
            [contactIndex]: {
                contactKey: "SELLER_LANDLORD",
                ...landlord,
                company: data.sellerLandlordCompany ? data.sellerLandlordCompany : {},
            },
        };
        ++contactIndex;
    }

    if (outsideBroker != null) {
        contactInfo = {
            ...contactInfo,
            [contactIndex]: {
                contactKey: "OUTSIDE_BROKER",
                ...outsideBroker,
                company: data.outsideBrokerCompany ? data.outsideBrokerCompany : {},
            },
        };
    }
    return contactInfo;
};

/**
 * @deprecated see notes below
 */
const initContactsFromPropertyAttributes = (propertyAttributes) => {
    var contactInfo = {};
    if (propertyAttributes) {
        const propertyManager =
            propertyAttributes.propertyManagerContact != null
                ? unpackContact(propertyAttributes.propertyManagerContact)
                : null;
        const buildingOwner =
            propertyAttributes.buildingOwnerContact != null
                ? unpackContact(propertyAttributes.buildingOwnerContact)
                : null;

        let contactIndex = 0;

        if (buildingOwner != null) {
            contactInfo = {
                [contactIndex]: {
                    contactKey: "BUILDING_OWNER",
                    ...buildingOwner,
                },
            };
            ++contactIndex;
        }
        if (propertyManager != null) {
            contactInfo = {
                ...contactInfo,
                [contactIndex]: {
                    contactKey: "PROPERTY_MANAGER",
                    ...propertyManager,
                },
            };
            ++contactIndex;
        }
    }
    return contactInfo;
};

/**
 * @deprecated see notes below
 */
const packContactForRequest = (contactInformation) => {
    return {
        personContactInfoKey: contactInformation.personContactInfoKey,
        person: {
            personKey: contactInformation.personKey,
            firstName: contactInformation.firstName,
            lastName: contactInformation.lastName,
        },
        emailAddress: contactInformation.emailAddress,
        phoneNumber: {
            phoneNumberKey: contactInformation.phoneNumberKey,
            phoneNumber: contactInformation.phoneNumber,
        },
        address: {
            key: contactInformation.addressKey,
            address1: contactInformation.address1,
            city: {
                key: contactInformation.cityKey,
                city: contactInformation.city,
                state: {
                    stateCode: contactInformation.state,
                },
            },
            zipCode: {
                key: contactInformation.zipCodeKey,
                zipCode: contactInformation.zipCode,
            },
        },
    };
};

/**
 * @deprecated see notes below
 */
const getContacts = (contactType, state) => {
    return get(state.contactsReducer.contacts, contactType, {});
};

/**
 * @deprecated see notes below
 */
const getInvoiceRecipient = (contactType, state) => {
    const contacts = getContacts(contactType, state);
    if (contacts) {
        let keys = Object.keys(contacts);
        for (var key in keys) {
            if (contacts[key].invoiceRecipient === true) {
                return contacts[key];
            }
        }
    }
    return null;
};

/**
 * @deprecated all code ABOVE this is deprecated.  use functions below along with
 *  ContactContainer and ContactForm moving forward. The code above still being used
 * in some code until it can be changed to use ContactContainer and ContactForm
 */

const validateContact = (contact) => {
    return validation.validateContact(contact);
};

const initContacts = (contactType, attributeContacts) => {
    let contacts = [];
    if (attributeContacts) {
        Object.keys(attributeContacts).forEach((attributeContact, i) => {
            contacts.push(unpack(attributeContact));
        });
    }
    operations.initContacts(contactType, contacts);
};

const unpack = (contact) => {
    return {
        key: contact.key,
        contactType: contact.contactType,
        invoiceRecipient: contact.invoiceRecipient,
        company: contact.company,
        personContactInfoKey: get(contact, "contact.personContactInfoKey", null),
        personKey: get(contact, "contact.person.personKey", null),
        firstName: get(contact, "contact.person.firstName", null),
        lastName: get(contact, "contact.person.lastName", null),
        emailAddress: get(contact, "contact.emailAddress", null),
        phoneNumberKey: get(contact, "contact.phoneNumber.phoneNumberKey", null),
        phoneNumber: get(contact, "contact.phoneNumber.phoneNumber", null),
        addressKey: get(contact, "contact.address.key", null),
        address1: get(contact, "contact.address.address1", null),
        cityKey: get(contact, "contact.address.city.key", null),
        city: get(contact, "contact.address.city.city", null),
        state: get(contact, "contact.address.city.state.stateCode", null),
        zipCodeKey: get(contact, "contact.address.zipCode.zipCodeKey", null),
        zipCode: get(contact, "contact.address.zipCode.zipCode", null),
    };
};

const pack = (contact) => {
    console.log("pack,contact=" + contact);
    return {
        key: contact.key,
        contactType: contact.contactType,
        invoiceRecipient: contact.invoiceRecipient,
        company: contact.company,
        contact: {
            personContactInfoKey: contact.personContactInfoKey,
            person: {
                personKey: contact.personKey,
                firstName: contact.firstName,
                lastName: contact.lastName,
            },
            emailAddress: contact.emailAddress,
            phoneNumber: {
                phoneNumberKey: contact.phoneNumberKey,
                phoneNumber: contact.phoneNumber,
            },
            address: {
                key: contact.addressKey,
                address1: contact.address1,
                city: {
                    key: contact.cityKey,
                    city: contact.city,
                    state: {
                        stateCode: contact.state,
                    },
                },
                zipCode: {
                    key: contact.zipCodeKey,
                    zipCode: contact.zipCode,
                },
            },
        },
    };
};

export default {
    unpackContact,
    getContactForType,
    initContactsFromDeal,
    initContactsFromPropertyAttributes,
    packContactForRequest,
    getInvoiceRecipient,
    getContacts,
    validateContact,
    initContacts,
    pack,
    unpack,
};
