import each from "lodash/each";
import get from "lodash/get";
import { countCommaSeparated } from "../utils/operations";
import utils from "../utils/operations";
import { audit_tabs } from "./constants";

const viewHeaders = (activeTab, role) => {
    const { auditorList } = audit_tabs;
    return {
        all_comps: [
            {
                name: "ToggleToList",
                prop: "toggle",
                type: "checkbox",
                gridSpacing: role === "auditor" ? 0 : 1,
            },
            {
                name: "Property",
                prop: "photo",
                type: "plaintext",
                gridSpacing: 1,
                extraStyles: {
                    width: "20%",
                },
            },
            {
                name: "Address",
                prop: "property",
                type: "plaintext",
                gridSpacing: 2,
                extraStyles: {
                    width: "15%",
                },
                sort: true,
            },
            {
                name: "Deal Type",
                prop: "transaction_type",
                type: "plaintext",
                gridSpacing: 1,
                sort: true,
                transform: (type) => {
                    return utils.parseTransactionType(type);
                },
            },
            {
                name: "Brokers",
                prop: "brokers",
                type: "aggregate",
                gridSpacing: 1,
                extraStyles: {
                    width: "6%",
                },
                sort: true,
                transform: (brokers) => {
                    return countCommaSeparated(brokers).length > 1
                        ? countCommaSeparated(brokers).length + " Brokers"
                        : brokers;
                },
            },
            {
                name: "Batch Date",
                prop: "batchDate",
                type: "plaintext",
                sort: true,
                gridSpacing: activeTab === auditorList ? 1 : 0,
            },
            {
                name: "Documents",
                prop: "attachedRequiredDocuments",
                type: "plaintext",
                gridSpacing: activeTab === auditorList ? 5 : 6,
            },
        ],
    };
};

const filterByQuery = (data, columnsToQuery, query) => {
    let included = false;
    each(columnsToQuery, (column) => {
        if (get(data, column)) {
            if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                included = true;
            }
        }
    });
    return included;
};

export default { viewHeaders, filterByQuery };
