import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import get from "lodash/get";
import PermissionsGate from "./permissions/PermissionsGate";
import { SCOPES, audit_tabs } from "./constants";
import operations from "./ducks/operations";

const AuditCompsTitle = (props) => {
    const { classes, searchResponse, activeTab, updateDealAuditFlags, comps, auditorListList } = props;
    const { auditorSearch, auditorList } = audit_tabs;
    return (
        <React.Fragment>
            <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={12}>
                <Grid item xs={3}>
                    <h2>Comps</h2>
                    <div className="page-subtitle">
                        {get(searchResponse, "results", null) &&
                            `Showing ${searchResponse.results.length} ${
                                searchResponse.results.length === 1
                                    ? "comp"
                                    : `comps (${searchResponse.totalResultCount} TOTAL)`
                            }`}
                    </div>
                </Grid>
                <PermissionsGate hide scopes={[SCOPES.canAddToList]}>
                    <Grid item xs={2} justify="flex-end" className={classes.flexDisplay}>
                        {activeTab === auditorSearch && (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => updateDealAuditFlags(comps, true)}
                            >
                                Add to Audit List
                            </Button>
                        )}
                        {activeTab === auditorList && (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => updateDealAuditFlags(auditorListList, false)}
                            >
                                Remove from Audit List
                            </Button>
                        )}
                    </Grid>
                </PermissionsGate>
            </Grid>
        </React.Fragment>
    );
};

const styles = (theme) => ({
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    flexDisplay: {
        display: "flex",
    },
});

const mapStateToProps = (state) => ({
    activeTab: state.auditReducer.activeTab,
    comps: state.auditReducer.comps,
    auditorListList: state.auditReducer.auditorList,
});
const mapDispatchToProps = (dispatch) => ({
    updateDealAuditFlags: (list, auditFlag) => {
        dispatch(operations.updateDealAuditFlags(list, auditFlag));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AuditCompsTitle));
