import React from "react";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import get from "lodash/get";
import Button from "@material-ui/core/Button";
import Delete from "../../../../node_modules/@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
    iconOnly: {
        marginLeft: 0,
        marginBottom: "10px",
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
    centerCell: {
        textAlign: "center",
        fill: "#9e9e9e",
    },
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
});

class CondoDetail extends React.Component {
    render() {
        const {
            classes,
            element,
            elementIndex,
            errors,
            disableInputs,
            handleUpdateArrayElement,
            onDelete,
            dataPathPrefix,
        } = this.props;

        return (
            <div>
                <Grid
                    container
                    alignItems="center"
                    spacing={8}
                    style={elementIndex === 0 ? { height: "90px" } : { height: "90px", marginTop: "-30px" }}
                >
                    <Grid item xs>
                        <BSInput
                            id="condoNumber"
                            label={elementIndex === 0 ? "Condo Number" : null}
                            placeholder="Enter condo number"
                            error={get(errors, `condos.${elementIndex}.condoNumber`, null)}
                            value={element ? element.condoNumber : null}
                            onChange={(e) =>
                                handleUpdateArrayElement(
                                    dataPathPrefix + ".condos",
                                    elementIndex,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs>
                        <BSInput
                            id="condoSf"
                            label={elementIndex === 0 ? "Condo Square Feet" : null}
                            errorText="(ex. 98101 or 981.01)"
                            placeholder="Enter SF"
                            value={element ? element.condoSf : null}
                            error={get(errors, `condos.${elementIndex}.condoSf`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement(
                                    dataPathPrefix + ".condos",
                                    elementIndex,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs>
                        <BSInput
                            id="condoParcelId"
                            label={elementIndex === 0 ? "Unit Count" : null}
                            placeholder="Enter count"
                            value={element ? element.condoParcelId : null}
                            error={get(errors, `condos.${elementIndex}.condoParcelId`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement(
                                    dataPathPrefix + ".condos",
                                    elementIndex,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.centerCell}>
                        {elementIndex > 0 && disableInputs !== true && (
                            <Button onClick={() => onDelete(elementIndex)} className={classes.shrinkButton}>
                                <Delete />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(CondoDetail);
