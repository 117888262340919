import React from "react";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import includes from "lodash/includes";

const reportHeaders = {
    Standard: {
        title: "Standard",
    },
    Metro: {
        title: "Metro",
    },
    MapOverview: {
        title: "Map Overview",
    },
    "HalfMap-HalfProperty": {
        title: "Half-Map Half-Property",
    },
    IndividualDetail: {
        title: "Individual Detail",
    },
    Reference: {
        title: "Reference Full Page",
    },
    MarketingFlyer: {
        title: "Marketing Flyer",
    },
};

class ReportTitleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brokerInfo: true,
            propType: "industrial",
            mapView: "standard",
            dealParty: "BUYER_TENANT",
            timeOnMarket: "",
            acknowledgedConfidential: false,
        };
    }

    handleGenerateReport = (tab) => {
        this.props.toggleReportTitleModal(false);
        this.props.setReportTitle(null);
        this.setState({ acknowledgedConfidential: false });
        if (this.props.reportType === "Listing") {
            if (this.props.reportStyle === "Standard") {
                this.props.generateListingReport(
                    tab,
                    this.props.brokerInfoReport.include,
                    this.props.reportTitleModal.title !== null
                        ? this.props.reportTitleModal.title
                        : "Listings Availability Report",
                    this.props.reportStyle,
                    null
                );
            } else if (includes(["HalfMap-HalfProperty", "MapOverview"], this.props.reportStyle)) {
                this.props.generateListingReport(
                    tab,
                    false,
                    this.props.reportTitleModal.title,
                    this.props.reportStyle,
                    this.state.mapView
                );
            } else {
                this.props.generateListingReport(
                    tab,
                    false,
                    this.props.reportTitleModal.title,
                    this.props.reportStyle,
                    null
                );
            }
        } else if (this.props.reportType === "Comp") {
            if (!this.props.metroReport) {
                if (this.props.reportStyle === "Standard") {
                    this.props.generateCompReport(
                        tab,
                        this.props.brokerInfoReport.include,
                        this.props.reportTitleModal.title !== null
                            ? this.props.reportTitleModal.title
                            : "Comparables Report",
                        this.props.reportStyle,
                        null
                    );
                } else if (includes(["HalfMap-HalfProperty", "MapOverview"], this.props.reportStyle)) {
                    this.props.generateCompReport(
                        tab,
                        false,
                        this.props.reportTitleModal.title,
                        this.props.reportStyle,
                        this.state.mapView
                    );
                } else if (this.props.reportStyle === "MarketingFlyer") {
                    this.props.generateCompReport(
                        tab,
                        false,
                        this.props.reportTitleModal.title,
                        this.props.reportStyle,
                        null,
                        this.state.dealParty,
                        this.state.timeOnMarket
                    );
                } else {
                    this.props.generateCompReport(
                        tab,
                        false,
                        this.props.reportTitleModal.title,
                        this.props.reportStyle,
                        null
                    );
                }
            } else {
                this.props.generateCompMetroReport(
                    tab,
                    this.props.brokerInfoReport.include,
                    this.props.reportTitleModal.title,
                    this.state.propType
                );
            }
        }
    };

    handleCancelReport = () => {
        this.props.toggleReportTitleModal(false);
        this.props.toggleIncludeBrokerInfo(true);
        this.props.setReportTitle(null);
        this.setState({
            propType: "industrial",
            acknowledgedConfidential: false,
        });
    };

    handleInfoToggle = (value) => {
        if (value === "true") {
            this.props.toggleIncludeBrokerInfo(true);
        } else {
            this.props.toggleIncludeBrokerInfo(false);
        }
        this.setState({ brokerInfo: value });
    };

    handlePropToggle = (value) => {
        this.setState({ propType: value });
    };

    handleMapViewToggle = (value) => {
        this.setState({ mapView: value });
    };

    handleDealPartyToggle = (value) => {
        this.setState({ dealParty: value });
    };

    handleConfidentialityAcknowledged = () => {
        this.setState({ acknowledgedConfidential: true });
    };

    handleTimeOnMarket = (value) => {
        this.setState({ timeOnMarket: value });
    };

    render() {
        const {
            classes,
            reportType,
            reportStyle,
            brokerInfoReport,
            reportTitleModal,
            setReportTitle,
            metroReport,
            tab,
            hasConfidentialComps,
        } = this.props;

        return (
            <Modal
                aria-labelledby="report-title-modal"
                aria-describedby="set-report-title"
                open={reportTitleModal.open}
            >
                <div style={getModalStyle()} className={classes.paper}>
                    {reportType === "Comp" && hasConfidentialComps && !this.state.acknowledgedConfidential && (
                        <div className={classes.instruction}>
                            <Typography variant="body1">
                                <h4 className="modal-title">
                                    There are <span style={{ color: "red", fontWeight: "bold" }}>Confidential</span>{" "}
                                    Comps selected in this report. Are you sure you would like to continue?
                                    <br />
                                    <br />
                                </h4>
                            </Typography>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    <Button variant="outlined" onClick={() => this.handleCancelReport()}>
                                        No
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.handleConfidentialityAcknowledged()}
                                    >
                                        Yes
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    )}

                    {(!hasConfidentialComps || this.state.acknowledgedConfidential) &&
                        (reportType === "Comp" && reportStyle === "Metro" ? (
                            <h4 className="modal-title" style={{ paddingBottom: "25px" }}>
                                Create A Metro Lease {reportType} Report
                            </h4>
                        ) : (
                            <h4 className="modal-title" style={{ paddingBottom: "25px" }}>
                                Create A{" "}
                                {reportHeaders[reportStyle] ? reportHeaders[reportStyle]["title"] : reportStyle}{" "}
                                {reportType} Report
                            </h4>
                        ))}

                    {(!hasConfidentialComps || this.state.acknowledgedConfidential) &&
                        reportStyle !== "MarketingFlyer" && (
                            <React.Fragment>
                                <div className={classes.instruction}>
                                    <Typography variant="body1">
                                        Enter an optional title for your report below.
                                    </Typography>
                                </div>
                                <BSInput
                                    id="reportTitle"
                                    value={reportTitleModal.title}
                                    onChange={(e) => setReportTitle(e.target.value)}
                                    placeholder="Enter report title..."
                                    extraInputStyles={{ marginBottom: "-20px", marginTop: "30px" }}
                                    defaultValue="Listings Report"
                                />
                            </React.Fragment>
                        )}

                    {(!hasConfidentialComps || this.state.acknowledgedConfidential) &&
                        (metroReport ? (
                            <FormControl rowcomponent="fieldset">
                                <RadioGroup
                                    row
                                    aria-label="include"
                                    name="include"
                                    value={this.state.propType}
                                    onChange={(e) => this.handlePropToggle(e.target.value)}
                                >
                                    <FormLabel component="legend" style={{ marginBottom: "5px", paddingTop: "15px" }}>
                                        Property Type
                                    </FormLabel>
                                    <FormControlLabel
                                        value="industrial"
                                        control={<Radio id="include" color="primary" />}
                                        style={{ marginRight: "83px" }}
                                        label="Industrial"
                                    />
                                    <FormControlLabel
                                        value="nonindustrial"
                                        control={<Radio id="include" color="primary" />}
                                        label="Non-Industrial"
                                    />
                                </RadioGroup>
                            </FormControl>
                        ) : (
                            ""
                        ))}

                    {(!hasConfidentialComps || this.state.acknowledgedConfidential) &&
                        (includes(["Standard", "Metro"], reportStyle) ? (
                            <FormControl rowcomponent="fieldset">
                                <RadioGroup
                                    row
                                    aria-label="include"
                                    name="include"
                                    value={brokerInfoReport.include}
                                    onChange={(e) => this.handleInfoToggle(e.target.value)}
                                    style={{ marginBottom: "25px" }}
                                >
                                    <FormLabel component="legend" style={{ marginBottom: "5px", paddingTop: "15px" }}>
                                        Broker Info
                                    </FormLabel>
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio id="include" color="primary" style={{ marginRight: "5px" }} />}
                                        label="Include Broker Info"
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio id="include" color="primary" />}
                                        label="Don't Include Broker Info"
                                    />
                                </RadioGroup>
                            </FormControl>
                        ) : (
                            ""
                        ))}

                    {(!hasConfidentialComps || this.state.acknowledgedConfidential) &&
                        (includes(["HalfMap-HalfProperty", "MapOverview"], reportStyle) ? (
                            <FormControl rowcomponent="fieldset">
                                <RadioGroup
                                    row
                                    aria-label="include"
                                    name="include"
                                    value={this.state.mapView}
                                    onChange={(e) => this.handleMapViewToggle(e.target.value)}
                                    style={{ marginBottom: "25px" }}
                                >
                                    <FormLabel component="legend" style={{ marginBottom: "5px", paddingTop: "15px" }}>
                                        Map View
                                    </FormLabel>
                                    <FormControlLabel
                                        value="standard"
                                        control={<Radio id="include" color="primary" style={{ marginRight: "5px" }} />}
                                        label="Standard"
                                    />
                                    <FormControlLabel
                                        value="aerial"
                                        control={<Radio id="include" color="primary" />}
                                        label="Aerial"
                                    />
                                </RadioGroup>
                            </FormControl>
                        ) : (
                            ""
                        ))}

                    {(!hasConfidentialComps || this.state.acknowledgedConfidential) && (
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Button variant="outlined" onClick={() => this.handleCancelReport()}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.handleGenerateReport(tab)}
                                >
                                    Go
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </div>
            </Modal>
        );
    }
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = (theme) => ({
    switchTitle: {
        fontSize: 14,
        fontWeight: 600,
    },
    theSwitch: {
        marginRight: 0,
    },
});

export default withStyles(styles)(ReportTitleModal);
