import React, { Component } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import AutosaveSuccess from "@material-ui/icons/CheckCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import operations from "./ducks/operations";
import { LeftChevron, SaveAndExit } from "../../../common/js/icons";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { red } from "@material-ui/core/colors";
import { get } from "lodash";
import ComplianceRulesByDocumentTypeContainer from "./ComplianceRulesByDocumentTypeContainer";
import { SplitButton } from "react-bootstrap";

const styles = () => ({
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    expansionSubheader: {
        color: "#9E9E9E",
        fontSize: "13px",
        fontWeight: 600,
        marginLeft: "8px",
        marginTop: "30px",
    },
    deleteButton: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800],
        },
    },
    complianceRules: {
        paddingTop: "0px",
    },
});

class DocumentTypeAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentEntityTypesList: [],
            parentEntityTypesToOptionsMap: [],
            parentEntityKey: undefined,
            dataLoaded: false,
            knownDocument: true,
            newDocumentName: "",
            documentType: {},
            isExisting: true,
        };
    }

    componentDidMount() {
        this.loadDTSRData();
    }

    loadDTSRData = async () => {
        this.setState({
            parentEntityTypesList: await operations.getAllParentEntityTypes(),
        });
        if (this.props.documentTypeId) {
            this.setState({
                documentType: await operations.getDocumentType(this.props.documentTypeId),
            });
        } else {
            let newDocumentType = {};
            newDocumentType.multipleAllowed = false;

            this.setState({
                documentType: newDocumentType,
                isExisting: false,
            });
        }

        this.setState({
            parentEntityTypesToOptionsMap: this.createParentEntityTypesToOptionsMap(),
            newDocumentName: this.state.documentType.name,
        });

        if (this.state.documentType === "ERROR") {
            this.setState({
                knownDocument: false,
            });
        }
        this.setState({
            dataLoaded: true,
        });
    };

    createParentEntityTypesToOptionsMap = () => {
        const inboundParentEntityTypesToOptionsMap = [];
        inboundParentEntityTypesToOptionsMap.push({
            value: "",
            label: "Select A Parent Type",
            disabled: true,
        });
        this.state.parentEntityTypesList.forEach((entity) => {
            inboundParentEntityTypesToOptionsMap.push({
                value: entity["parentEntityTypeKey"],
                label: entity["name"],
                disabled: false,
            });
        });
        return inboundParentEntityTypesToOptionsMap;
    };

    handleInputChange = (k, v) => {
        let newDocumentType = this.state.documentType;
        newDocumentType[k] = v;
        this.setState({ documentType: newDocumentType });
    };

    handleDocumentNameChange = (v) => {
        this.setState({ newDocumentName: v });
    };

    changeParentEntity = (e) => {
        let newParentEntity;

        newParentEntity = this.state.documentType.parentEntity;
        this.state.parentEntityTypesList.forEach((entity) => {
            if (entity.parentEntityTypeKey === Number(e.target.value)) {
                newParentEntity = entity;
            }
        });
        this.handleInputChange(e.target.id, newParentEntity);
    };

    handleDeleteCancelDocumentType = () => {
        this.props.handleDeleteCancelDocumentType(this.props.documentTypeId);
    };

    saveAllValues = (shouldExit) => {
        const documentType = this.state.documentType;
        const updatedDocumentType = {};

        updatedDocumentType.name = this.state.newDocumentName;
        updatedDocumentType.description = get(documentType, "description");
        updatedDocumentType.multipleAllowed = get(documentType, "multipleAllowed");
        updatedDocumentType.parentEntityTypeKey = get(documentType, "parentEntityType.parentEntityTypeKey");
        this.props.handleSaveAll(this.props.documentTypeId, updatedDocumentType, shouldExit);
    };

    render() {
        const { classes, errors, history } = this.props;

        const { documentType, isExisting } = this.state;
        const subHeaderText = isExisting ? "Edit Document" : "Create Document";
        const deleteCancelText = isExisting ? "Delete" : "Cancel";
        const parentEntityTypeKey = get(documentType, "parentEntityType.parentEntityTypeKey", "");

        return (
            <Grid container justify="space-between" className={classes.pageToolbar} spacing={16}>
                <Grid item zeroMinWidth>
                    <span className={classes.goBackLink} onClick={() => history.goBack()}>
                        <LeftChevron />
                    </span>
                </Grid>
                <Grid item xs>
                    <h2 className="page-title">{this.state.dataLoaded ? subHeaderText : "Document"}</h2>
                    <p className="page-subtitle">{get(documentType, "name", "")}</p>
                </Grid>
                <Grid container direction="row" alignItems="center" justify="flex-end" xs={4}>
                    <Grid item style={{ marginRight: "10%" }}>
                        <div>
                            {parentEntityTypeKey.length !== 0 && (
                                <Link to={`/parent-type/${parentEntityTypeKey}`} style={{ textDecoration: "none" }}>
                                    <Button variant="contained" color="secondary">
                                        Edit Rules
                                    </Button>
                                </Link>
                            )}
                            <SplitButton
                                title="Save"
                                bsStyle="default"
                                onClick={() => {
                                    this.saveAllValues(false);
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        this.saveAllValues(true);
                                    }}
                                >
                                    <SaveAndExit />
                                    Save & Exit
                                </Button>
                            </SplitButton>
                            {this.state.dataLoaded ? (
                                <Button
                                    variant="contained"
                                    className={classes.deleteButton}
                                    onClick={() => {
                                        this.handleDeleteCancelDocumentType();
                                    }}
                                >
                                    {deleteCancelText}
                                </Button>
                            ) : (
                                <span className={classes.loadingSpinner} style={{ marginLeft: "50px" }}>
                                    <CircularProgress color="primary" size={15} />
                                </span>
                            )}
                        </div>
                    </Grid>
                </Grid>
                {this.state.knownDocument ? (
                    <Grid container style={{ width: "70%", marginTop: "10px" }}>
                        <Table>
                            <TableBody style={{ flexDirection: "column" }}>
                                <TableRow>
                                    <ExpansionPanel defaultExpanded style={{ marginBottom: "10px" }}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <AutosaveSuccess className="waterloo" /> Document Information
                                        </ExpansionPanelSummary>
                                        <Divider />
                                        <ExpansionPanelDetails>
                                            <Table>
                                                <TableHead>
                                                    <BSInput
                                                        id="name"
                                                        label="Name"
                                                        placeholder="Enter document name..."
                                                        value={this.state.newDocumentName}
                                                        onChange={(e) => {
                                                            this.handleDocumentNameChange(e.target.value);
                                                        }}
                                                        errorText={"Required"}
                                                        error={get(errors, "name", "")}
                                                    />
                                                    <BSInput
                                                        id="description"
                                                        label="Description"
                                                        placeholder="Enter document description..."
                                                        value={this.state.documentType.description}
                                                        onChange={(e) => {
                                                            this.handleInputChange(e.target.id, e.target.value);
                                                        }}
                                                    />
                                                    <BSSelect
                                                        id="parentEntityType"
                                                        label="Parent Type"
                                                        value={get(
                                                            documentType,
                                                            "parentEntityType.parentEntityTypeKey",
                                                            ""
                                                        )}
                                                        onChange={(e) => {
                                                            this.changeParentEntity(e);
                                                        }}
                                                        options={this.state.parentEntityTypesToOptionsMap}
                                                        disabled={this.props.documentTypeId !== undefined}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    this.state.documentType.multipleAllowed
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e.target.value,
                                                                        e.target.checked
                                                                    )
                                                                }
                                                                value="multipleAllowed"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Multiple Attachments Allowed"
                                                    />
                                                </TableHead>
                                            </Table>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    {this.props.documentTypeId !== undefined ? (
                                        <ExpansionPanel defaultExpanded>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <AutosaveSuccess className="waterloo" />
                                                Compliance Rules Impacting Document Type
                                            </ExpansionPanelSummary>
                                            <Divider />
                                            <ExpansionPanelDetails className={classes.complianceRules}>
                                                <ComplianceRulesByDocumentTypeContainer {...this.props} />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    ) : null}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                ) : (
                    <Grid container style={{ width: "70%", marginTop: "20px" }}>
                        <p>Error: Document could not be found.</p>
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default withStyles(styles)(DocumentTypeAdmin);
