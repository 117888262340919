import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { decimalToPercent, round, toSqftWithoutAppend } from "../utils/operations";
import { getSpecialtyTypeDisplayNameNoPropertyType } from "../property/constants";
import operations from "./ducks/operations";
import includes from "lodash/includes";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import BSInput from "../../../common/js/components/BSInput";

const ResearchSnapshotRow = (props) => {
    const { snapshot, classes, columns, role, editResponse } = props;
    const [initialSnapshot, setInitialSnapshot] = useState({});
    const [newSnapshot, setNewSnapshot] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [disableCancel, setDisableCancel] = useState(false);
    const [disableSave, setDisableSave] = useState(false);
    const [open, setOpen] = useState(false);

    const cancelEditState = () => {
        setEditMode(false);
        setNewSnapshot(initialSnapshot);
    };
    const enterEditState = () => {
        setEditMode(true);
    };
    const exitEditState = () => {
        setEditMode(false);
    };
    const saveChanges = async () => {
        setDisableCancel(true);
        setDisableSave(true);
        const response = await operations.editExistingSnapshot(snapshot.researchSnapshotKey, newSnapshot);
        if (response.status >= 200 && response.status < 300) {
            editResponse(true);
            setInitialSnapshot(response.data);
            exitEditState();
        } else {
            editResponse(false);
        }
        setDisableCancel(false);
        setDisableSave(false);
    };
    const onChange = (e) => {
        // React funny business
        // https://stackoverflow.com/questions/44708518/event-target-is-null-inside-functional-setstate
        const { id, value } = e.target;
        setNewSnapshot((prevState) => ({ ...prevState, [id]: value }));
    };
    const cellValidation = (value, group) => {
        switch (group) {
            case "specialtyType":
                return getSpecialtyTypeDisplayNameNoPropertyType(value);
            case "lease":
                return value !== null ? round(value, 2) : "";
            case "percentage":
                return decimalToPercent(value, true);
            case "sqFt":
                return toSqftWithoutAppend(value);
            default:
                return value;
        }
    };

    useEffect(() => {
        setInitialSnapshot(snapshot);
        setNewSnapshot(snapshot);
    }, []);

    return (
        <React.Fragment>
            <TableRow className={classes.tableRow} sx={{ "& > *": { borderBottom: "unset" } }}>
                {columns.map((col) => {
                    const { group, field, editable } = col;
                    switch (group) {
                        case "notes":
                            return (
                                <TableCell className={classes.tableCell} align="center">
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </TableCell>
                            );
                        case "edit":
                            return (
                                <TableCell className={classes.tableCell} align="center">
                                    {editMode ? (
                                        <div>
                                            <IconButton
                                                disabled={disableSave}
                                                aria-label="save edit state"
                                                onClick={saveChanges}
                                            >
                                                <SaveIcon className={classes.iconButton} />
                                            </IconButton>
                                            <IconButton
                                                disabled={disableCancel}
                                                aria-label="cancel edit state"
                                                onClick={cancelEditState}
                                            >
                                                <CancelIcon className={classes.iconButton} />
                                            </IconButton>
                                        </div>
                                    ) : (
                                        <IconButton aria-label="enter exit state" size="small" onClick={enterEditState}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                            );

                        default:
                            const value = cellValidation(newSnapshot[field], group);
                            const cellValue =
                                value === null ||
                                value === undefined ||
                                (typeof value === "string" && value.length === 0)
                                    ? "-"
                                    : value;
                            return (
                                <TableCell className={classes.tableCell} align="center">
                                    {editMode &&
                                    editable &&
                                    includes(["research", "researchAdmin", "dataAdmin"], role) ? (
                                        <BSInput
                                            id={field}
                                            value={newSnapshot[field]}
                                            onChange={onChange}
                                            type={group === "sqFt" ? "number" : null}
                                            endAdornment={group === "percentage" ? "%" : null}
                                            extraInputStyles={{ borderColor: "#A0A6A0" }}
                                            noFullWidth={true}
                                        />
                                    ) : (
                                        cellValue
                                    )}
                                </TableCell>
                            );
                    }
                })}
            </TableRow>
            <TableCell className={`${classes.collapseContainer}  ${classes.tableRow}`} colSpan={50}>
                <Collapse in={open} timeout="auto">
                    {editMode ? (
                        <BSInput
                            id={"notes"}
                            value={newSnapshot["notes"]}
                            onChange={onChange}
                            className={`${classes.noteEditMode}`}
                        />
                    ) : (
                        <ul className={classes.notesContainer}>{newSnapshot["notes"]}</ul>
                    )}
                </Collapse>
            </TableCell>
        </React.Fragment>
    );
};

const styles = (theme) => ({
    tableRow: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#e7e6e6",
        },
        height: "50%",
    },
    tableCell: {
        whiteSpace: "nowrap",
    },
    collapseContainer: {
        paddingBottom: 0,
        paddingTop: 0,
        whiteSpace: "nowrap",
        border: "none",
    },
    notesContainer: {
        listStyleType: "circle",
        marginLeft: "5em",
        display: "list-item",
    },
    noteEditMode: {
        borderColor: "#A0A6A0",
    },
    iconButton: {
        fontSize: "smaller",
    },
});

export default withStyles(styles)(ResearchSnapshotRow);
