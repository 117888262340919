import React, { useRef, forwardRef, useState } from "react";
import { Typography, Grid, IconButton } from "@material-ui/core";
import CropIcon from "@material-ui/icons/Crop";
import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles } from "@material-ui/core/styles";
import Cropper from "react-cropper";
import get from "lodash/get";
import "cropperjs/dist/cropper.css";
import MarketingFlyerModalBodyContainer from "./MarketingFlyerModalBodyContainer";

const GenerateImageEditorModal = (props) => {
    const {
        classes,
        reportName,
        editorDimensions,
        modalContent,
        setMarketingFlyerPhoto,
        resetPhoto,
        handleCloseModal,
        timeOnMarketInput,
        setDealParty,
        compTab,
        generateCompReport,
        selectedCompId,
    } = props;

    const { dealParty, timeOnMarket, marketingFlyerPhoto, marketingFlyerPhotos } = modalContent;
    const cropperRef = useRef(null);

    const [selectedMarketingFlyerPhotoSrcUrl, setSelectedMarketingFlyerPhotoSrcUrl] = useState("");
    const [originalSelectedMarketingFlyerPhotoSrcUrl, setOriginalSelectedMarketingFlyerPhotoSrcUrl] = useState("");

    const getCropData = () => {
        if (typeof cropperRef.current.cropper !== "undefined") {
            const dataUrl = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
            setSelectedMarketingFlyerPhotoSrcUrl(dataUrl);

            // Fallback logic for Comp Photo
            setMarketingFlyerPhoto(dataUrl);
        }
    };

    const handleGenerateMarketingFlyerReport = () => {
        getCropData();
        const croppedPhoto = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
        const croppedPhotoSlice = croppedPhoto.split(",")[1];
        generateCompReport(compTab, false, null, "MarketingFlyer", null, dealParty, timeOnMarket, croppedPhotoSlice);
    };

    const handleSelectPhotoFromDropdown = (selectedPhotoId) => {
        const selectedPhoto = marketingFlyerPhotos.find((photo) => photo.mediaAssetKey === selectedPhotoId);
        setOriginalSelectedMarketingFlyerPhotoSrcUrl(get(selectedPhoto, "url", ""));
        setSelectedMarketingFlyerPhotoSrcUrl(get(selectedPhoto, "url", ""));
    };

    const handleResetPhoto = () => {
        setSelectedMarketingFlyerPhotoSrcUrl(originalSelectedMarketingFlyerPhotoSrcUrl);

        // Fallback logic for Comp Photo
        resetPhoto();
    };

    // Fallback logic for Comp Photo
    const shouldShowPreview =
        marketingFlyerPhotos.length === 0
            ? marketingFlyerPhoto.length > 0
            : selectedMarketingFlyerPhotoSrcUrl.length > 0;
    const shouldDisableButtons =
        marketingFlyerPhotos.length === 0
            ? marketingFlyerPhoto.length === 0
            : selectedMarketingFlyerPhotoSrcUrl.length === 0;

    return (
        <React.Fragment>
            <Grid container spacing={8} className={classes.paper} justify="center">
                <Grid item xs={12} className={classes.centerContent}>
                    <EditorView
                        classes={classes}
                        editorDimensions={editorDimensions}
                        marketingFlyerPhoto={marketingFlyerPhoto}
                        marketingFlyerPhotos={marketingFlyerPhotos}
                        selectedMarketingFlyerPhotoSrcUrl={selectedMarketingFlyerPhotoSrcUrl}
                        handleResetPhoto={handleResetPhoto}
                        ref={cropperRef}
                        getCropData={getCropData}
                        shouldDisableButtons={shouldDisableButtons}
                    />
                </Grid>
                <Grid item xs={12} className={classes.centerContent}>
                    <Grid container>
                        <Grid
                            item
                            xs={9}
                            container
                            style={{ padding: "5px", paddingRight: "15px", alignContent: "space-between" }}
                        >
                            <Grid item xs={12}>
                                <ModalHeader reportName={reportName} />
                            </Grid>
                            <MarketingFlyerModalBodyContainer
                                classes={classes}
                                handleCloseModal={handleCloseModal}
                                handleGenerateMarketingFlyerReport={handleGenerateMarketingFlyerReport}
                                handleSelectPhotoFromDropdown={handleSelectPhotoFromDropdown}
                                modalContent={modalContent}
                                setDealParty={setDealParty}
                                timeOnMarketInput={timeOnMarketInput}
                                selectedCompId={selectedCompId}
                                shouldDisableButtons={shouldDisableButtons}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                gutterBottom={true}
                                style={{ fontWeight: "bold", marginBottom: "25px" }}
                                variant="h6"
                            >{`Preview`}</Typography>
                            {shouldShowPreview ? <Preview classes={classes} /> : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const EditorView = forwardRef((props, ref) => {
    const {
        classes,
        editorDimensions,
        marketingFlyerPhoto,
        marketingFlyerPhotos,
        selectedMarketingFlyerPhotoSrcUrl,
        handleResetPhoto,
        getCropData,
        shouldDisableButtons,
    } = props;

    return (
        <React.Fragment>
            <div
                className={classes.editorView}
                style={{ height: editorDimensions.height, width: editorDimensions.width }}
            >
                <span className={classes.editorButtons}>
                    <IconButton disabled={shouldDisableButtons} color="primary">
                        <CropIcon onClick={() => getCropData()} />
                    </IconButton>
                    <IconButton disabled={shouldDisableButtons} color="primary">
                        <RefreshIcon onClick={handleResetPhoto} />
                    </IconButton>
                </span>
                <Cropper
                    ref={ref}
                    style={{ height: "100%", width: "100%" }}
                    zoomTo={0.5}
                    initialAspectRatio={153 / 55}
                    preview=".img-preview"
                    src={marketingFlyerPhotos.length === 0 ? marketingFlyerPhoto : selectedMarketingFlyerPhotoSrcUrl}
                    viewMode={0}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    minCanvasWidth={1224}
                    minCanvasHeight={440}
                    cropBoxResizable={false}
                    cropBoxMovable={false}
                    dragMode={"move"}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    toggleDragModeOnDblclick={false}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                />
            </div>
        </React.Fragment>
    );
});

const ModalHeader = (props) => {
    return (
        <React.Fragment>
            <Typography
                gutterBottom={true}
                style={{ fontWeight: "bold", marginBottom: "25px" }}
                variant="h6"
            >{`Create A ${props.reportName} Report`}</Typography>
        </React.Fragment>
    );
};

const Preview = (props) => {
    const { classes } = props;
    return (
        <React.Fragment>
            <div className={classes.preview}>
                <div className={`img-preview ${classes.imgPreview}`} style={{ width: "100%" }}></div>
            </div>
        </React.Fragment>
    );
};

const styles = (theme) => ({
    paper: {
        display: "flex",
        background: "white",
        top: "50%",
        left: "50%",
        width: "1248px",
        height: "auto",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        borderRadius: "3px",
        alignContent: "center",
        boxShadow: " 0 3px 7px rgba(0, 0, 0, 0.3)",
        padding: "5px",
    },
    editorView: {
        display: "flex",
        border: "1px solid black",
        borderRadius: "3px",
        position: "relative",
        justifyContent: "center",
    },
    editorButtons: {
        alignSelf: "flex-end",
        position: "absolute",
        bottom: "0%",
        right: "0%",
        zIndex: "100",
    },
    preview: {
        display: "flex",
        height: "100%",
        width: "100%",
        overflow: "hidden",
    },
    centerContent: {
        display: "flex",
        justifyContent: "center",
    },
    imgPreview: {
        border: "1px solid black",
        overflow: "hidden",
        borderRadius: "3px",
    },
});
export default withStyles(styles)(GenerateImageEditorModal);
