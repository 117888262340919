import React from "react";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { sprinklerTypes } from "../listing/constants";

class IndustrialForm extends React.Component {
    handleAttributeUpdate = (event) => {
        this.props.onChange(event.target.id, event.target.value);
    };

    render() {
        const { data, errors, disableInputs, context, listingStatus } = this.props;

        return (
            <div>
                {context === "listing" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="industrialSf"
                                    label="INDUSTRIAL SF AVAILABLE"
                                    errorText="ex. 98101"
                                    value={data.industrialSf}
                                    error={errors.industrialSf}
                                    onChange={this.handleAttributeUpdate}
                                    placeholder="Enter SF"
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="largestContigSf"
                                    label="INDUSTRIAL CONTIGUOUS SF AVAILABLE"
                                    errorText="ex. 98101"
                                    value={data.largestContigSf}
                                    error={errors.largestContigSf}
                                    onChange={this.handleAttributeUpdate}
                                    placeholder="Enter SF"
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="officeSf"
                                    label="Office Space Sf"
                                    placeholder="Enter SF"
                                    errorText="ex. 98101"
                                    error={errors.officeSf}
                                    value={data.officeSf}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="yardSf"
                                    label="INDUSTRIAL YARD SF"
                                    placeholder="Enter SF"
                                    errorText="ex. 98101"
                                    error={errors.yardSf}
                                    value={data.yardSf}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            {listingStatus === "Appraisal" && (
                                <Grid item xs={6}>
                                    <BSInput
                                        id="coldStorageSf"
                                        label="COLD STORAGE SF"
                                        placeholder="Enter SF"
                                        error={errors.coldStorageSf}
                                        value={data.coldStorageSf}
                                        onChange={this.handleAttributeUpdate}
                                        disabled={disableInputs}
                                        integer
                                    />
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <BSInput
                                    id="officeBreakdownSfRate"
                                    label="OFFICE RATE"
                                    errorText="ex. 98.10"
                                    value={data.officeBreakdownSfRate}
                                    error={errors.officeBreakdownSfRate}
                                    onChange={this.handleAttributeUpdate}
                                    placeholder="$_._SF/MTH"
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="warehouseShellBreakdownRate"
                                    label="WAREHOUSE/SHELL RATE"
                                    errorText="ex. 98.10"
                                    value={data.warehouseShellBreakdownRate}
                                    error={errors.warehouseShellBreakdownRate}
                                    onChange={this.handleAttributeUpdate}
                                    placeholder="$_._SF/MTH"
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="blendedRate"
                                    label="BLENDED ASKING RATE"
                                    errorText="ex. 98.10"
                                    value={data.blendedRate}
                                    error={errors.blendedRate}
                                    onChange={this.handleAttributeUpdate}
                                    placeholder="$_._SF/MTH"
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={4}>
                        <BSInput
                            id="voltsNum"
                            label="VOLTS"
                            placeholder="Enter volts"
                            error={errors.voltsNum}
                            value={data.voltsNum}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSInput
                            id="ampsNum"
                            label="AMPS"
                            placeholder="Enter amps"
                            error={errors.ampsNum}
                            value={data.ampsNum}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSInput
                            id="phaseNum"
                            label="PHASE"
                            error={errors.phaseNum}
                            value={data.phaseNum}
                            onChange={this.handleAttributeUpdate}
                            placeholder="Enter phase"
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="gradeLevelDoorsNum"
                            label="DOORS AT GRADE"
                            placeholder="Enter doors at grade"
                            errorText="ex. 9"
                            error={errors.gradeLevelDoorsNum}
                            value={data.gradeLevelDoorsNum}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="dockHighDoorsNum"
                            label="DOORS DOCK HIGH"
                            placeholder="Enter doors dock high"
                            errorText="ex. 9"
                            error={errors.dockHighDoorsNum}
                            value={data.dockHighDoorsNum}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={4}>
                        <BSInput
                            id="railDoorsNum"
                            label="RAIL DOORS"
                            placeholder="Enter # of rail doors"
                            error={errors.railDoorsNum}
                            value={data.railDoorsNum}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSInput
                            id="clearHeightMin"
                            label="CLEAR HEIGHT MIN"
                            placeholder="Enter min clear height in ft"
                            errorText="ex. 9"
                            error={errors.clearHeightMin}
                            value={data.clearHeightMin}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSInput
                            id="clearHeightMax"
                            label="CLEAR HEIGHT MAX"
                            placeholder="Enter max clear height in ft"
                            errorText="ex. 9"
                            error={errors.clearHeightMax}
                            value={data.clearHeightMax}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="cranesQty"
                            label="CRANE COUNT"
                            placeholder="Enter crane count"
                            errorText="ex. 9"
                            error={errors.cranesQty}
                            value={data.cranesQty}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.cranesBldgFlg ? true : false}
                                        onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                        value="cranesBldgFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Crane Served"
                                style={{ marginLeft: "5px" }}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="craneNotes"
                            label="CRANE NOTES"
                            placeholder="Enter crane notes"
                            error={errors.craneNotes}
                            value={data.craneNotes}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="yardNotes"
                            label="INDUSTRIAL YARD NOTES"
                            placeholder="Enter notes"
                            error={errors.yardNotes}
                            value={data.yardNotes}
                            onChange={this.handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.acFlg ? true : false}
                                        onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                        value="acFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="HVAC"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.electricStandardBldg ? true : false}
                                        onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                        value="electricStandardBldg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Electric Standard"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.electricHeavyBldgFlg ? true : false}
                                        onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                        value="electricHeavyBldgFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Electric Heavy"
                            />
                            {listingStatus === "Appraisal" && (
                                <React.Fragment>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.distributionFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="distributionFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Distribution"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.dataCenterFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="dataCenterFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Data Center"
                                    />
                                </React.Fragment>
                            )}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.labSpaceFlg ? true : false}
                                        onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                        value="labSpaceFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Lab Space"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.pavedFlg ? true : false}
                                        onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                        value="pavedFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Paved"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.fencedFlg ? true : false}
                                        onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                        value="fencedFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Fenced"
                            />
                            {listingStatus === "Appraisal" && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.manufacturingFlg ? true : false}
                                            onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                            value="manufacturingFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Manufacturing"
                                />
                            )}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.railServiceFlg ? true : false}
                                        onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                        value="railServiceFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Rail Served"
                            />
                            {listingStatus === "Appraisal" && (
                                <React.Fragment>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.coldStorageFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="coldStorageFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Cold Storage"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.flexFlg ? true : false}
                                                onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                                value="flexFlg"
                                                disabled={disableInputs === true}
                                                color="primary"
                                            />
                                        }
                                        label="Flex"
                                    />
                                </React.Fragment>
                            )}
                            <div style={{ width: "50%" }}>
                                <BSSelect
                                    id="sprinklers"
                                    label="Sprinklers"
                                    value={data.sprinklers}
                                    error={errors.sprinklers}
                                    onChange={this.handleAttributeUpdate}
                                    options={sprinklerTypes}
                                    disabled={disableInputs === true}
                                />
                            </div>
                        </FormGroup>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default IndustrialForm;
