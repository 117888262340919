import types from "./types";

export default (
    state = {
        conditions: {
            parentEntityTypeKey: 1,
            conditionTypes: [],
            documentTypes: [],
            states: [],
            actionTypes: [],
        },
        conditionValues: {
            conditionTypeKey: 0,
            conditionOperatorKey: null,
            conditionValue: null,
        },
        actionValues: {
            documentType: 0,
            actionType: 0,
        },
        rules: [],
        modal: {
            data: {
                ruleKey: "newRule",
                ruleName: "New Rule",
                ruleDescription: "",
                parentEntityTypeKey: 1,
                conditions: [],
                actions: [],
                priority: 0,
            },
            open: false,
            modalHeader: "Edit Rule",
        },
        parentEntityTypes: [],
    },
    action
) => {
    switch (action.type) {
        case types.TOGGLE_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    open: action.toggleModal,
                },
            };
        case types.SET_PARENT_ENTITY_TYPE_KEY:
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    parentEntityTypeKey: action.parentEntityTypeKey,
                },
            };
        case types.GET_ACTION_TYPES:
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    actionTypes: action.actionTypes,
                },
            };
        case types.GET_DOCUMENT_TYPES:
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    documentTypes: action.documentTypes,
                },
            };
        case types.GET_CONDITION_TYPES:
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    conditionTypes: action.conditionTypes,
                },
            };
        case types.GET_STATES:
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    states: action.states,
                },
            };
        case types.SELECT_CONDITIONS:
            return {
                ...state,
                conditionValues: {
                    ...state.conditionValues,
                    [action.field]: action.value,
                },
            };
        case types.SELECT_CONDITION_TYPE:
            const selectConditionType = state.conditions.conditionTypes.find((conType) => {
                return conType.value === action.conditionTypeKey;
            });
            return {
                ...state,
                conditionValues: {
                    ...state.conditionValues,
                    conditionTypeKey: selectConditionType.value,
                    conditionOperatorKey:
                        selectConditionType.conditionOperators.length === 0
                            ? null
                            : selectConditionType.conditionOperators[0].value,
                    conditionValue:
                        selectConditionType.conditionOperators.length === 0 ? null : state.conditions.states[0].value,
                },
            };
        case types.SELECT_FROM_ACTIONS:
            return {
                ...state,
                actionValues: {
                    ...state.actionValues,
                    [action.field]: action.value,
                },
            };
        case types.GET_RULES_BY_PARENT_ENTITY_TYPE_KEY:
            return {
                ...state,
                rules: action.rules,
            };
        case types.GET_RULES_BY_DOC_TYPE_KEY:
            return {
                ...state,
                rules: action.rules,
            };
        case types.ADD_CONDITION:
            const conditionType = state.conditions.conditionTypes.find(
                (conditionType) => conditionType.value === state.conditionValues.conditionTypeKey
            );
            const operators = conditionType.conditionOperators.find(
                (operator) => operator.value === state.conditionValues.conditionOperatorKey
            );
            const newCondition = {
                conditionValue: operators !== undefined ? state.conditionValues.conditionValue : null,
                conditionType: {
                    conditionTypeKey: state.conditionValues.conditionTypeKey,
                    displayName: conditionType.label,
                },
                conditionOperator: {
                    conditionOperatorKey: operators !== undefined ? state.conditionValues.conditionOperatorKey : null,
                    displayName: operators !== undefined ? operators.label : "",
                },
            };
            return {
                ...state,
                modal: {
                    ...state.modal,
                    data: {
                        ...state.modal.data,
                        conditions: [...state.modal.data.conditions, ...[newCondition]],
                    },
                },
                conditions: {
                    ...state.conditions,
                    conditionTypes: state.conditions.conditionTypes.map((conType) => {
                        if (conType.value === state.conditionValues.conditionTypeKey) {
                            conType.display = false;
                        }
                        return conType;
                    }),
                },
            };
        case types.REMOVE_CONDITION:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    data: {
                        ...state.modal.data,
                        conditions: state.modal.data.conditions.filter(
                            (condition) => condition.conditionType.conditionTypeKey !== action.conditionTypeKey
                        ),
                    },
                },
                conditions: {
                    ...state.conditions,
                    conditionTypes: state.conditions.conditionTypes.map((conType) => {
                        if (conType.value === action.conditionTypeKey) {
                            conType.display = true;
                        }
                        return conType;
                    }),
                },
            };
        case types.ADD_ACTION:
            const newAction = {
                documentTypeKey: state.actionValues.documentType,
                documentName: state.conditions.documentTypes.find(
                    (docType) => docType.value === state.actionValues.documentType
                ).label,
                actionType: {
                    actionTypeKey: state.actionValues.actionType,
                    displayName: state.conditions.actionTypes.find(
                        (actionType) => actionType.value === state.actionValues.actionType
                    ).label,
                },
            };
            return {
                ...state,
                modal: {
                    ...state.modal,
                    data: {
                        ...state.modal.data,
                        actions: [...state.modal.data.actions, ...[newAction]],
                    },
                },
                conditions: {
                    ...state.conditions,
                    documentTypes: state.conditions.documentTypes.map((docType) => {
                        if (docType.value === state.actionValues.documentType) {
                            docType.display = false;
                        }
                        return docType;
                    }),
                },
            };
        case types.REMOVE_ACTION:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    data: {
                        ...state.modal.data,
                        actions: state.modal.data.actions.filter(
                            (act) => act.documentTypeKey !== action.documentTypeKey
                        ),
                    },
                },
                conditions: {
                    ...state.conditions,
                    documentTypes: state.conditions.documentTypes.map((docType) => {
                        if (docType.value === action.documentTypeKey) {
                            docType.display = true;
                        }
                        return docType;
                    }),
                },
            };
        case types.UPDATE_DOCUMENT_TYPE_DROPDOWN:
            return {
                ...state,
                actionValues: {
                    ...state.actionValues,
                    documentType: state.conditions.documentTypes.find((docType) => {
                        return docType.display;
                    }).value,
                },
            };
        case types.UPDATE_CONDITION_TYPE_DROPDOWN:
            const updateConditionType = state.conditions.conditionTypes.find((conType) => {
                return conType.display;
            });
            return {
                ...state,
                conditionValues: {
                    ...state.conditionValues,
                    conditionTypeKey: updateConditionType.value,
                    conditionOperatorKey:
                        updateConditionType.conditionOperators.length === 0
                            ? null
                            : updateConditionType.conditionOperators[0].value,
                    conditionValue:
                        updateConditionType.conditionOperators.length === 0 ? null : state.conditions.states[0].value,
                },
            };
        case types.HANDLE_RULE_INPUT_CHANGE:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    data: {
                        ...state.modal.data,
                        [action.field]: action.value,
                    },
                },
            };
        case types.ADD_RULE:
            const newRule = {
                ruleKey: null,
                ruleName: "New Rule",
                ruleDescription: "",
                priority: state.rules.length + 1,
                parentEntityTypeKey: state.conditions.parentEntityTypeKey,
                conditions: [],
                actions: [],
            };
            const newDocTypes = state.conditions.documentTypes.map((docType) => {
                docType.display = true;
                return { ...docType };
            });
            const newConTypes = state.conditions.conditionTypes.map((conType) => {
                conType.display = true;
                return { ...conType };
            });
            return {
                ...state,
                modal: {
                    ...state.modal,
                    data: newRule,
                    modalHeader: "Create Rule",
                },
                conditions: {
                    ...state.conditions,
                    documentTypes: newDocTypes,
                    conditionTypes: newConTypes,
                },
                actionValues: {
                    documentType: newDocTypes[0].value,
                    actionType: state.conditions.actionTypes[0].value,
                },
                conditionValues: {
                    conditionTypeKey: newConTypes[0].value,
                    conditionOperatorKey: newConTypes[0].length > 0 ? newConTypes[0].conditionOperators[0] : null,
                    conditionValue: null,
                },
            };
        case types.EDIT_RULE:
            const ruleData = state.rules.find((rule) => rule.ruleKey === action.ruleKey);
            const docTypes = state.conditions.documentTypes.map((docType) => {
                ruleData.actions.forEach((action) => {
                    if (docType.value === action.documentTypeKey) {
                        docType.display = false;
                    }
                });
                return docType;
            });
            const conTypes = state.conditions.conditionTypes.map((conType) => {
                ruleData.conditions.forEach((con) => {
                    if (conType.value === con.conditionType.conditionTypeKey) {
                        conType.display = false;
                    }
                });
                return conType;
            });
            const conditionTypeData = conTypes.find((conType) => conType.display);
            return {
                ...state,
                actionValues: {
                    documentType: docTypes.find((docType) => docType.display).value,
                    actionType: state.conditions.actionTypes[0].value,
                },
                conditionValues: {
                    conditionTypeKey: conditionTypeData ? conditionTypeData.value : null,
                    conditionOperatorKey: conditionTypeData.length > 0 ? conditionTypeData.conditionOperators[0] : null,
                    conditionValue: null,
                },
                modal: {
                    ...state.modal,
                    data: ruleData,
                    modalHeader: "Edit Rule",
                },
                conditions: {
                    ...state.conditions,
                    documentTypes: docTypes,
                    conditionTypes: conTypes,
                },
            };
        case types.UPDATE_RULE_ORDER:
            return {
                ...state,
                rules: action.rules,
            };
        case types.REVERT_DROPDOWN_DISPLAYS:
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    conditionTypes: state.conditions.conditionTypes.map((type) => {
                        return { ...type, display: true };
                    }),
                    documentTypes: state.conditions.documentTypes.map((type) => {
                        return { ...type, display: true };
                    }),
                },
            };
        case types.GET_ALL_PARENT_ENTITY_TYPES:
            return {
                ...state,
                parentEntityTypes: action.parentEntityTypes,
            };
        case types.RESET_PRIORITIES:
            return {
                ...state,
                rules: [
                    ...state.rules
                        .map((rule) => {
                            const newPrio = { ...rule, priority: rule.initialPriority };
                            return newPrio;
                        })
                        .sort((rule1, rule2) => {
                            return rule1.priority - rule2.priority;
                        }),
                ],
            };
        default:
            return state;
    }
};
