import React from "react";
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import { accountingMethodTypes } from "../types/accountingMethodTypes";
import { accountingSoftwareSystemTypes } from "../types/accountingSoftwareSystemTypes";
import {
    realEstateTaxesPaidByOptions,
    taxOwnershipInformationOptions,
    softwareLicenseReimbursedFrequencyOptions,
} from "./constants_property_management";
import { dateOrdinalOptions } from "../utils/constants";
import get from "lodash/get";

const styles = (theme) => ({
    switchTitle: {
        fontSize: 14,
        fontWeight: 600,
    },
    theSwitch: {
        marginRight: 0,
    },
    tpt: {
        marginTop: "12px",
    },
});
class AccountingForm extends React.Component {
    constructor(props) {
        super();

        this.state = {
            softwareLicenseReimbursed: props.data.softwareLicenseReimbursedAmount ? true : false,
        };
    }

    render() {
        const { data, errors, disableInputs, handleInput, sideBySide, clearErrorByField, classes } = this.props;

        const handleRealEstateTaxesPaidBy = (e) => {
            handleInput(e.target.id, e.target.value);
            if (e.target.value === "Kidder") {
                handleInput("taxOwnershipInformation", "");
            }
        };

        const handleSoftwareLicenseReimbursed = (e) => {
            this.setState({ softwareLicenseReimbursed: !this.state.softwareLicenseReimbursed });
            if (!e.target.checked) {
                handleInput("softwareLicenseReimbursedAmount", "");
                handleInput("softwareLicenseReimbursedFrequency", undefined);
                clearErrorByField("softwareLicenseReimbursedAmount");
                clearErrorByField("softwareLicenseReimbursedFrequency");
            }
        };

        return (
            <React.Fragment>
                {/* Spacing is 24 because it spaces out the 2 columns better */}
                <Grid container justify="space-between" spacing={24}>
                    <Grid item xs={sideBySide ? 6 : 12}>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <BSSelect
                                    id="accountingMethod"
                                    label="Accounting Method"
                                    placeholder="Select"
                                    value={get(data, "accountingMethod", "")}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    options={[
                                        {
                                            value: "",
                                            label: "Select",
                                            disabled: true,
                                        },
                                        ...accountingMethodTypes,
                                    ]}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSSelect
                                    id="accountingSoftwareType"
                                    label="Accounting Software System"
                                    placeholder="Select"
                                    value={get(data, "accountingSoftwareType", "")}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    options={[
                                        {
                                            value: "",
                                            label: "Select",
                                            disabled: true,
                                        },
                                        ...accountingSoftwareSystemTypes,
                                    ]}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="chartOfAccounts"
                                    label="Chart Of Accounts"
                                    placeholder="Enter chart of accounts"
                                    value={get(data, "chartOfAccounts", "")}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="bankingInstitution"
                                    label="Banking Institution"
                                    placeholder="Enter banking institution"
                                    value={get(data, "bankingInstitution", "")}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="bankAccountId"
                                    label="Bank Account Id"
                                    placeholder="Enter bank account ID"
                                    value={get(data, "bankAccountId", "")}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* right column */}

                    <Grid item xs={sideBySide ? 6 : 12}>
                        <Grid container spacing={8}>
                            <Grid item xs={4}>
                                <BSSelect
                                    id="accountingCutoffDayOfMonth"
                                    label="Accounting Cutoff Date"
                                    placeholder="Enter date"
                                    value={get(data, "accountingCutoffDayOfMonth", "")}
                                    error={get(errors, "accountingCutoffDayOfMonth", null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                    options={dateOrdinalOptions}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BSSelect
                                    id="reportDueDayOfMonth"
                                    label="Monthly Report Due Date"
                                    placeholder="Select"
                                    value={get(data, "reportDueDayOfMonth", "")}
                                    error={get(errors, "reportDueDayOfMonth", null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    options={dateOrdinalOptions}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BSInput
                                    id="budgetDueMonthDay"
                                    label="Budget Due Date"
                                    placeholder="MM/DD"
                                    value={get(data, "budgetDueMonthDay", "")}
                                    error={get(errors, "budgetDueMonthDay", null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                    options={dateOrdinalOptions}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item xs={get(data, "realEstateTaxesPaidBy", "") === "Other" ? 6 : 12}>
                                <BSSelect
                                    id="realEstateTaxesPaidBy"
                                    label="Real Estate Taxes Paid By"
                                    value={get(data, "realEstateTaxesPaidBy", "")}
                                    error={get(errors, "realEstateTaxesPaidBy", null)}
                                    onChange={handleRealEstateTaxesPaidBy}
                                    disabled={disableInputs}
                                    options={realEstateTaxesPaidByOptions}
                                />
                            </Grid>
                            {get(data, "realEstateTaxesPaidBy", "") === "Other" && (
                                <Grid item xs={6}>
                                    <BSSelect
                                        id="taxOwnershipInformation"
                                        label="Tax Ownership Information"
                                        value={get(data, "taxOwnershipInformation", "")}
                                        error={get(errors, "taxOwnershipInformation", null)}
                                        onChange={(e) => handleInput(e.target.id, e.target.value)}
                                        disabled={disableInputs}
                                        options={taxOwnershipInformationOptions}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid className={classes.tpt} item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.tpt}
                                            onChange={(e, checked) => handleInput(e.target.value, checked)}
                                            value="tpt"
                                            disabled={disableInputs}
                                            color="primary"
                                        />
                                    }
                                    label="TPT"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BSInput
                                    id="softwareLicenseReimbursedAmount"
                                    label="S/W Lic. Reimbursed"
                                    placeholder="$"
                                    value={get(data, "softwareLicenseReimbursedAmount", null)}
                                    error={get(errors, `softwareLicenseReimbursedAmount`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={!this.state.softwareLicenseReimbursed || disableInputs}
                                    checkbox={true}
                                    checkboxVal={"softwareLicenseReimbursed"}
                                    disableCheckbox={disableInputs}
                                    onChangeCheckbox={handleSoftwareLicenseReimbursed}
                                    checked={this.state.softwareLicenseReimbursed}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <BSSelect
                                    id="softwareLicenseReimbursedFrequency"
                                    value={get(data, "softwareLicenseReimbursedFrequency", "")}
                                    error={get(errors, "softwareLicenseReimbursedFrequency", null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={!this.state.softwareLicenseReimbursed || disableInputs}
                                    options={softwareLicenseReimbursedFrequencyOptions}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <BSSelect
                                    id="kidderMathewsCompanyKey"
                                    label="Kidder Mathews Company"
                                    placeholder="Select a company"
                                    value={get(data, "kidderMathewsCompanyKey", "")}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs}
                                    options={[
                                        {
                                            value: "",
                                            label: "Select",
                                            disabled: false,
                                        },
                                        {
                                            value: "218011",
                                            label: "Kidder Mathews, LLC",
                                        },
                                        {
                                            value: "415674",
                                            label: "Kidder Mathews of California, Inc.",
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AccountingForm);
