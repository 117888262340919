const LEASE = {
    W9: {
        required: false,
        display: "W9 (New client or company)",
        available: false,
    },
    COMMISSION_PAYMENT: {
        required: false,
        display: "Commission payment or equivalent",
        available: false,
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
    },
};

const SALE = {
    W9: {
        required: false,
        display: "W9 (New client or company)",
        available: false,
    },
    COMMISSION_PAYMENT: {
        required: false,
        display: "Commission payment or equivalent",
        available: false,
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
    },
};

export default {
    LEASE,
    SALE,
};
