import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core/";
import BSInput from "../../../../common/js/components/BSInput";
import "./Educational.css";

const EducationalForm = (props) => {
    const { context, data, disableInputs, errors, onChange } = props;

    const handleAttributeUpdate = (event) => {
        onChange(event.target.id, event.target.value);
    };

    return (
        <div>
            {context === "listing" && (
                <div className="educationalFlexContainer">
                    <div className="educationalFlexField">
                        <BSInput
                            id="classroomCount"
                            label="Classrooms"
                            placeholder="Enter # Classrooms"
                            errorText="ex. 12"
                            error={errors.classroomCount}
                            value={data.classroomCount}
                            onChange={handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="educationalFlexField">
                        <BSInput
                            id="auditoriumSeatsCount"
                            label="Auditorium Seats"
                            placeholder="Enter # Auditorium Seats"
                            errorText="ex. 120"
                            error={errors.auditoriumSeatsCount}
                            value={data.auditoriumSeatsCount}
                            onChange={handleAttributeUpdate}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="educationalFlexContainerUnresponsive">
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.sharedFlg ? true : false}
                                        onChange={(e, checked) => onChange(e.target.value, checked)}
                                        value="sharedFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Shared"
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.kitchenFlg ? true : false}
                                        onChange={(e, checked) => onChange(e.target.value, checked)}
                                        value="kitchenFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Kitchen"
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.developmentSiteFlg ? true : false}
                                        onChange={(e, checked) => onChange(e.target.value, checked)}
                                        value="developmentSiteFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Development Site"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EducationalForm;
