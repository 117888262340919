import types from "./types";
import moment from "moment";

export default (
    state = {
        brokerValues: {
            recentOpenDeals: [],
            recentOpenListings: [],
        },
        evpValues: {
            topKmClosedDeals: [],
            topKmBrokers: [],
            commissionBreakdownByOffice: [],
            commissionBreakdownByType: [],
            ytdTotalConsideration: [],
            ytdTotalCommission: [],
            avgTimeOnMarket: [],
            noncompliantDeals: [],
        },
        evpInputParams: {
            startDate: moment().dayOfYear(1).format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            region: "all",
        },
        dashboardParams: {
            // startDate: moment().dayOfYear(1).format('YYYY-MM-DD'),
            // endDate: moment().format('YYYY-MM-DD'),
            region: "all",
        },
    },
    action
) => {
    switch (action.type) {
        case types.SET_EVP_DATA_COMPONENT:
            return {
                ...state,
                evpValues: {
                    ...state.evpValues,
                    [action.component]: action.data,
                },
            };
        case types.SET_BROKER_DATA_COMPONENT:
            return {
                ...state,
                brokerValues: {
                    ...state.brokerValues,
                    [action.component]: action.data,
                },
            };
        case types.SORT_DASHBOARD_COLUMN:
            return {
                ...state,
                sort: {
                    ...state.sort,
                    [action.component]: {
                        columnName: action.columnName,
                        sortDirection: action.sortDirection,
                    },
                },
            };
        case types.SET_DASHBOARD_PARAM:
            return {
                ...state,
                dashboardParams: {
                    ...state.dashboardParams,
                    [action.name]: action.value,
                },
            };
        default:
            return state;
    }
};
