import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Checkbox } from "@material-ui/core";
import schema from "./AuditSearchTables";
import { Sorty } from "../../../common/js/icons";
import { audit_tabs } from "./constants";
import operations from "./ducks/operations";
import PermissionsGate from "./permissions/PermissionsGate";
import { SCOPES } from "./constants";

const AuditSearchHeaders = (props) => {
    const {
        classes,
        handleSort,
        activeTab,
        toggleAllAuditorListAuditFlag,
        selectAllFromAuditorListCheckbox,
        selectAllFromAuditorSearch,
        toggleAllCompsAuditFlags,
        role,
    } = props;

    const { auditorList } = audit_tabs;
    const [selectAllAuditorList, toggleSelectAllAuditorList] = useState(selectAllFromAuditorListCheckbox);
    const [selectAllAuditorSearch, toggleSelectAllAuditorSearch] = useState(selectAllFromAuditorSearch);

    useEffect(() => {
        toggleSelectAllAuditorList(selectAllFromAuditorListCheckbox);
    }, [selectAllFromAuditorListCheckbox]);

    useEffect(() => {
        toggleSelectAllAuditorSearch(selectAllFromAuditorSearch);
    }, [selectAllFromAuditorSearch]);

    return (
        <Grid container style={{ position: "relative" }} spacing={0}>
            {schema.viewHeaders(activeTab, role)["all_comps"].map((header, i) => (
                <Grid
                    item
                    xs={header.gridSpacing}
                    classes={classes}
                    numeric={header.numeric}
                    key={`thc-${i}`}
                    style={{
                        borderBottom: "1px solid rgb(212, 212, 212)",
                        fontFamily: "IBM Plex Sans, sans-Serif",
                        fontSize: "12px",
                        color: "#9E9E9E",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                    }}
                    onClick={() => {
                        if (header.name !== "ToggleToList" || header.name !== "Property") {
                            handleSort(header.name, activeTab);
                        }
                    }}
                >
                    <div>
                        {header.name === "ToggleToList" ? (
                            <PermissionsGate hide scopes={[SCOPES.canAddToList]}>
                                <span className={`${classes.paddingLeft}`}>
                                    {activeTab === auditorList ? (
                                        <Checkbox
                                            checked={selectAllAuditorList}
                                            color="primary"
                                            onChange={() => {
                                                toggleAllAuditorListAuditFlag(selectAllAuditorList);
                                            }}
                                        />
                                    ) : (
                                        <Checkbox
                                            checked={selectAllAuditorSearch}
                                            color="primary"
                                            onChange={() => {
                                                toggleAllCompsAuditFlags(selectAllAuditorSearch);
                                            }}
                                        />
                                    )}
                                </span>
                            </PermissionsGate>
                        ) : header.name === "Property" ? (
                            <div>
                                <span className={`${classes.sortable} ${classes.headerDefault} ${classes.paddingLeft}`}>
                                    {header.name.toUpperCase()}
                                </span>
                            </div>
                        ) : header.name === "Address" ? (
                            <div>
                                <span className={`${classes.sortable} ${classes.headerDefault} ${classes.paddingLeft}`}>
                                    {header.name.toUpperCase()}
                                </span>
                                {header.name && (
                                    <div className={classes.sortingIcon}>
                                        <Sorty />
                                    </div>
                                )}
                            </div>
                        ) : header.name === "Deal Type" ? (
                            <div>
                                <span className={`${classes.sortable} ${classes.headerDefault}`}>
                                    {header.name.toUpperCase()}
                                </span>
                                {header.name && (
                                    <div className={classes.sortingIcon}>
                                        <Sorty />
                                    </div>
                                )}
                            </div>
                        ) : header.name === "Brokers" ? (
                            <div>
                                <span className={`${classes.sortable} ${classes.headerDefault}`}>
                                    {header.name.toUpperCase()}
                                </span>
                                {header.name && (
                                    <div className={classes.sortingIcon}>
                                        <Sorty />
                                    </div>
                                )}
                            </div>
                        ) : header.name === "Batch Date" ? (
                            activeTab === auditorList ? (
                                <div>
                                    <span className={`${classes.sortable} ${classes.headerDefault}`}>
                                        {header.name.toUpperCase()}
                                    </span>
                                    {header.name && (
                                        <div className={classes.sortingIcon}>
                                            <Sorty />
                                        </div>
                                    )}
                                </div>
                            ) : null
                        ) : header.name === "Documents" ? (
                            <div>
                                <span className={`${classes.sortable} ${classes.headerDefault} ${classes.paddingLeft}`}>
                                    {header.name.toUpperCase()}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <span className={`${classes.sortable} ${classes.headerDefault}`}>
                                    {header.name.toUpperCase()}
                                </span>
                                {header.name && (
                                    <div className={classes.sortingIcon}>
                                        <Sorty />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};

const styles = (theme) => ({
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    headerDefault: {
        display: "inline-block",
        margin: "25px 0 20px 0",
    },
    paddingLeft: {
        paddingLeft: "25px",
    },
    sortingIcon: {
        display: "inline-block",
    },
});

const mapStateToProps = (state) => ({
    activeTab: state.auditReducer.activeTab,
    selectAllFromAuditorListCheckbox: state.auditReducer.selectAllFromAuditorList,
    selectAllFromAuditorSearch: state.auditReducer.selectAllFromAuditorSearch,
    role: state.authReducer.userProfile.role,
});

const mapDispatchToProps = (dispatch) => ({
    toggleAllAuditorListAuditFlag: (isChecked) => {
        dispatch(operations.toggleAllAuditorListAuditFlag(isChecked));
    },
    toggleAllCompsAuditFlags: (currentToggle) => {
        dispatch(operations.toggleAllCompsAuditFlags(!currentToggle));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AuditSearchHeaders));
