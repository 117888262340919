import configs from "../../../config";
import toLower from "lodash/toLower";

const HOST = configs.HOST_URL + "/";
const MEDIA_URL = HOST + "media/";
const COMP_URL = HOST + "comps/";
const DEAL_URL = HOST + "deals/";
const INDIVIDUAL_COMP_URL = configs.CLIENT_HOST_URL + "/comp/";

const urls = {
    get_comp_documents: (dealKey) => {
        return DEAL_URL + "documents/" + dealKey;
    },
    save_document: (dealKey) => {
        return DEAL_URL + "document/" + dealKey;
    },
    delete_document: (documentId) => {
        return DEAL_URL + "document/" + documentId;
    },
    update_comp_document: (documentId) => {
        return DEAL_URL + "document/" + documentId;
    },

    assign_comp_for_review: (dealId) => {
        return DEAL_URL + "comp/review/" + dealId;
    },
    reassign_comp_to_broker: (dealId) => {
        return DEAL_URL + "comp/reassigntobroker/" + dealId;
    },
    approve_comp: (dealId) => {
        return DEAL_URL + "comp/approve/" + dealId;
    },
    reassign_comp_to_data_entry: (dealId) => {
        return DEAL_URL + "comp/reassigntodataentry/" + dealId;
    },
    process_portland_approval: (dealId) => {
        return DEAL_URL + "comp/portlandapproval/" + dealId;
    },
    process_arizona_approval: (dealId) => {
        return DEAL_URL + "comp/arizonaapproval/" + dealId;
    },

    delete_outside_comp: (dealId) => {
        return DEAL_URL + "delete/" + dealId;
    },
    save_deal: (type, key) => {
        return DEAL_URL + toLower(type) + "/" + (key ? key : "");
    },
    get_deal: (dealKey) => {
        return DEAL_URL + dealKey;
    },
    get_brokers_on_deal: (dealKey) => {
        return DEAL_URL + "brokers/" + dealKey;
    },

    broker_comps: (username) => {
        return COMP_URL + username;
    },
    all_km_comps: (username) => {
        return COMP_URL + "km";
    },

    get_individual_comp: (dealId) => {
        return INDIVIDUAL_COMP_URL + dealId;
    },

    get_document_by_media_asset_type: (ownerId) => `${MEDIA_URL}find/COMP/${ownerId}/MARKETING_FLYER_PHOTO`,

    //currently not in use
    comps_group: (username) => {
        return COMP_URL + username;
    },

    create_deal: (type) => {
        return DEAL_URL + type;
    },
    update_deal: (dealKey) => {
        return DEAL_URL + dealKey;
    },
    update_deal_property: (dealKey, propertyKey, user) => {
        return DEAL_URL + dealKey + "/" + propertyKey + "/" + user;
    },
    broker_submit: (dealKey) => {
        return DEAL_URL + "comp/brokersubmit/" + dealKey;
    },

    get_comp_history_for_property: (propertyKey) => {
        return COMP_URL + "property/" + propertyKey;
    },
    // generate_comp_report: (includeBrokerInfo, reportTitle, reportStyle, mapView) => { return COMP_URL +'report?includeBrokerInfo=' + includeBrokerInfo + '&reportTitle=' + reportTitle + '&reportStyle=' + reportStyle + '&mapView=' + mapView},
    generate_comp_report: () => {
        return COMP_URL + "report";
    },
    generate_metro_comp_report: () => {
        return COMP_URL + "metroReport";
    },
    revert_comp_to_draft: (dealKey) => {
        return DEAL_URL + "comp/reverstatustodraft/" + dealKey;
    },
};

export const disabledStatuses = {
    //false is open to any user of that role while comp is in that status
    broker: {
        Draft: false,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "Portland Termination Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": true,
        Outside: true,
        "Outside Draft": true,
    },
    debtEquity: {
        Draft: false,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "Portland Termination Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": true,
        Outside: true,
        "Outside Draft": true,
    },
    evp: {
        Draft: true,
        Outside: true,
        "Outside Draft": true,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "Portland Termination Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": true,
    },
    portlandBrokerAdmin: {
        Draft: true,
        Outside: true,
        "Outside Draft": true,
        "Portland Review Required": true,
        "Portland Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": true,
    },
    arizonaBrokerAdmin: {
        Draft: true,
        Outside: true,
        "Outside Draft": true,
        "AZ Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": true,
    },
    dataEntry: {
        Draft: true,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "Portland Termination Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": false,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": false,
        "Needs Review": true,
        "Awaiting Comp": false,
        "Final Review": true,
        //todo outside ?
    },
    dataAdmin: {
        Draft: true,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "Portland Termination Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": false,
    },
    general: {
        Draft: true,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "Portland Termination Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": true,
        Outside: true,
        "Outside Draft": true,
    },
    officeManager: {
        Draft: true,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "Portland Termination Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": true,
        Outside: true,
        "Outside Draft": true,
    },
    appraiser: {
        Draft: true,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "Portland Termination Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": true,
        Outside: true,
        "Outside Draft": true,
    },
    research: {
        Draft: true,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "Portland Termination Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": true,
        Outside: false,
        "Outside Draft": false,
    },
    researchAdmin: {
        Draft: true,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "Portland Termination Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": true,
        Outside: false,
        "Outside Draft": false,
    },
    default: {
        Draft: true,
        "Portland Review Required": true,
        "AZ Review Required": true,
        "Portland Termination Review Required": true,
        "AZ Termination Review Required": true,
        "In Review": true,
        "Broker Action Required": true,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": true,
        "Final Review": true,
        Outside: true,
        "Outside Draft": true,
    },
};

const roles = {
    broker: {
        initialTab: "broker_comps",
    },
    evp: {
        initialTab: "broker_comps",
    },
    portlandBrokerAdmin: {
        initialTab: "broker_comps",
    },
    arizonaBrokerAdmin: {
        initialTab: "broker_comps",
    },
    dataEntry: {
        initialTab: "broker_comps",
    },
    dataAdmin: {
        initialTab: "broker_comps",
    },
    propertyManagement: {
        initialTab: "all_km_comps",
    },
    accounting: {
        initialTab: "broker_comps",
    },
    anonymous: {
        initialTab: "broker_comps",
    },
    general: {
        initialTab: "broker_comps",
    },
    officeManager: {
        initialTab: "broker_comps",
    },
    appraiser: {
        initialTab: "broker_comps",
    },
    research: {
        initialTab: "broker_comps",
    },
    researchAdmin: {
        initialTab: "broker_comps",
    },
    transition: {
        initialTab: "broker_comps",
    },
    debtEquity: {
        initialTab: "broker_comps",
    },
};

export const NO_CONTENT_PLACEHOLDER = "-";

export default {
    urls,
    roles,
};
