import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Divider, FormLabel, Grid, List, ListItemText, Typography } from "@material-ui/core";

const PropertyManagementInformationSnapshot = (props) => {
    const { snapshotPersonnel, classes } = props;
    const sortPersonnelByRole = (snapshot) => {
        const personnelByRole = {};
        const result = [];
        snapshot.forEach((personnel) => {
            const { role, firstName, lastName } = personnel;

            if (role in personnelByRole) {
                personnelByRole[role].push({
                    firstName,
                    lastName,
                });
            } else {
                personnelByRole[role] = [
                    {
                        firstName,
                        lastName,
                    },
                ];
            }
        });
        for (const key in personnelByRole) {
            result.push({ role: key, personnel: personnelByRole[key] });
        }
        const col1 = result.slice(0, result.length / 2);
        const col2 = result.slice(result.length / 2, result.length);
        return [col1, col2];
    };
    return (
        <div className={classes.managementInfoContainer}>
            <div className={classes.managementInfoContainerHeader}>
                <Grid container spacing={8}>
                    <Grid item xs>
                        <h3>Management Information</h3>
                    </Grid>
                </Grid>
            </div>
            <Grid container spacing={8}>
                {sortPersonnelByRole(snapshotPersonnel).map((data) => {
                    return (
                        <Grid item xs>
                            {data.map((personnelData, i) => {
                                const { role, personnel } = personnelData;
                                return (
                                    <div className={classes.managementInfoContainerRow}>
                                        {<FormLabel shrink>{role}</FormLabel>}
                                        <List dense alignItems="flex-start" className={classes.personnelContainer}>
                                            {personnel.map((personData) => {
                                                const { firstName, lastName } = personData;
                                                return (
                                                    firstName &&
                                                    lastName && (
                                                        <Grid container spacing={8} className={classes.personnelRow}>
                                                            <Grid item xs>
                                                                <ListItemText
                                                                    disableTypography
                                                                    className={classes.textAlign}
                                                                    primary={
                                                                        <Typography>
                                                                            {`${firstName} ${lastName}`}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                );
                                            })}
                                        </List>
                                        {i ? null : <Divider />}
                                    </div>
                                );
                            })}
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

const styles = (theme) => ({
    managementInfoContainer: {
        marginBottom: "20px",
        border: "1px solid #dbdbdb",
    },
    managementInfoContainerHeader: {
        padding: "10px 12px",
        marginTop: "15px",
        borderBottom: "1px solid #dbdbdb",
        textAlign: "start",
    },
    managementInfoContainerRow: {
        padding: "11px 12px",
        borderTop: "none",
        "&:last-of-type": {
            marginBottom: "8px",
        },
    },
    personnelContainer: {
        listStyle: "none",
        flexDirection: "column",
    },
    personnelRow: {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: "2em",
        color: "black",
    },
    textAlign: {
        textAlign: "start",
    },
});

export default withStyles(styles)(PropertyManagementInformationSnapshot);
