import get from "lodash/get";
import _ from "lodash";

const PROPERTY_URL = "/properties/";
const PROPERTY_MANAGEMENT_URL = "/prmanagement/";
const urls = {
    request_property: () => {
        return PROPERTY_URL + "request";
    },
    get_property: (propertyKey) => {
        return PROPERTY_URL + propertyKey;
    },
    save_property: (propertyKey) => {
        return PROPERTY_URL + (propertyKey ? propertyKey : "");
    },
    delete_property: (propertyKey) => {
        return PROPERTY_URL + "delete/" + propertyKey;
    },
    get_property_images: (propertyKey) => {
        return PROPERTY_URL + "images/" + propertyKey;
    },
    get_documents: (propertyKey) => {
        return PROPERTY_URL + "documents/" + propertyKey;
    },
    save_document: (propertyKey) => {
        return PROPERTY_URL + "document/" + propertyKey;
    },
    delete_document: (documentId) => {
        return PROPERTY_URL + "document/" + documentId;
    },
    update_document: (documentId) => {
        return PROPERTY_URL + "document/" + documentId;
    },

    data_team_needs_review: () => {
        return PROPERTY_URL + "requested";
    },
    property_management_details: (key) => {
        return PROPERTY_MANAGEMENT_URL + "property/" + key;
    },
    property_management_history: (key) => {
        return PROPERTY_MANAGEMENT_URL + "snapshot/" + key;
    },
    create_property_management_snapshot: (key) => {
        return PROPERTY_MANAGEMENT_URL + "snapshot/" + key;
    },
    update_property_management_details: (key) => {
        return PROPERTY_MANAGEMENT_URL + key;
    },
    property_management_personnel: (key) => {
        return PROPERTY_MANAGEMENT_URL + "personnel/" + key;
    },
    create_property_management: (key) => {
        return PROPERTY_MANAGEMENT_URL + "create/" + key;
    },
    update_property_status: (key) => {
        return PROPERTY_URL + "status/" + key;
    },
    archive_asset_services_record: (key) => {
        return PROPERTY_MANAGEMENT_URL + "snapshot/" + key;
    },
};

export const propertyTypeFilters = {
    Office: {
        subs: {
            specialtyTypeCriteria: {
                options: {
                    government: {
                        display: "Government",
                    },
                    mixedUse: {
                        display: "Mixed Use",
                    },
                    generalOffice: {
                        display: "General Office",
                    },
                    dataCallCenter: {
                        display: "Data Center/Call Center",
                    },
                    executiveSpace: {
                        display: "Executive Space",
                    },
                },
                display: "Sub-Type",
            },
            buildingTypeCriteria: {
                options: {
                    lowRise: {
                        display: "Low Rise",
                    },
                    midRise: {
                        display: "Mid Rise",
                    },
                    highRise: {
                        display: "High Rise",
                    },
                },
                display: "Office Building Type",
            },
            buildingClassCriteria: {
                options: {
                    trophy: {
                        display: "Trophy",
                    },
                    a: {
                        display: "A",
                    },
                    b: {
                        display: "B",
                    },
                    c: {
                        display: "C",
                    },
                },
                display: "Class",
            },
        },
        display: "Office",
    },
    Industrial: {
        subs: {
            specialtyTypeCriteria: {
                options: {
                    highTechOrRaD: {
                        display: "High-Tech/R&D",
                    },
                    manufacturing: {
                        display: "Manufacturing",
                    },
                    warehouseDistribution: {
                        display: "Warehouse/Distribution",
                    },
                    warehouseOffice: {
                        display: "Warehouse/Office",
                    },
                    selfStorage: {
                        display: "Self Storage",
                    },
                    coldStorage: {
                        display: "Cold Storage",
                    },
                    multiTempColdStorage: {
                        display: "Multi-Temp Cold Storage",
                    },
                },
                display: "Sub-Type",
            },
            buildingClassCriteria: {
                options: {
                    a: {
                        display: "A",
                    },
                    b: {
                        display: "B",
                    },
                    c: {
                        display: "C",
                    },
                },
                display: "Class",
            },
        },
        display: "Industrial",
    },
    Land: {
        subs: {
            specialtyTypeCriteria: {
                options: {
                    scraped: {
                        display: "Scraped",
                    },
                    obsolete: {
                        display: "Functionally Obsolete Structure",
                    },
                    singleFamilyLand: {
                        display: "Single Family Land",
                    },
                    vacantLand: {
                        display: "Vacant Land",
                    },
                    contractorYard: {
                        display: "Contractor Yard",
                    },
                    truckTerminal: {
                        display: "Truck Terminal",
                    },
                    agriculture: {
                        display: "Agriculture",
                    },
                },
                display: "Sub-Type",
            },
        },
        display: "Land",
    },
    Retail: {
        subs: {
            specialtyTypeCriteria: {
                options: {
                    bigBox: {
                        display: "Big Box",
                    },
                    neighborhoodCenter: {
                        display: "Neighborhood Center",
                    },
                    anchored: {
                        display: "Anchored",
                    },
                    lifestyleCenter: {
                        display: "Lifestyle Center",
                    },
                    outdoorMall: {
                        display: "Outdoor Mall",
                    },
                    padSite: {
                        display: "Pad Site",
                    },
                    restaurant: {
                        display: "Restaurant",
                    },
                    retailMixedUse: {
                        display: "Retail Mixed-use",
                    },
                    stripCenter: {
                        display: "Strip Center",
                    },
                    superRegionalCenter: {
                        display: "Super Regional Center",
                    },
                },
                display: "Sub-Type",
            },
        },
        display: "Retail",
    },
    Multifamily: {
        subs: {
            specialtyTypeCriteria: {
                options: {
                    garden: {
                        display: "Garden",
                    },
                    loft: {
                        display: "Loft",
                    },
                    midRise: {
                        display: "Mid Rise",
                    },
                    highRise: {
                        display: "High Rise",
                    },
                    militaryHousing: {
                        display: "Military Housing",
                    },
                    seniorHousing: {
                        display: "Senior Housing",
                    },
                    studentHousing: {
                        display: "Student Housing",
                    },
                },
                display: "Sub-Type",
            },
            buildingClassCriteria: {
                options: {
                    a: {
                        display: "A",
                    },
                    b: {
                        display: "B",
                    },
                    c: {
                        display: "C",
                    },
                    d: {
                        display: "D",
                    },
                },
                display: "Class",
            },
        },
        display: "Multifamily",
    },
    Hospitality: {
        subs: {
            specialtyTypeCriteria: {
                options: {
                    hotel: {
                        display: "Hotel",
                    },
                    motel: {
                        display: "Motel",
                    },
                    resort: {
                        display: "Resort",
                    },
                },
                display: "Sub-Type",
            },
        },
        display: "Hospitality",
    },
    "Life Science": {
        subs: {
            specialtyTypeCriteria: {
                options: {
                    biologyLab: {
                        display: "Biology Lab",
                    },
                    chemistryLab: {
                        display: "Chemistry Lab",
                    },
                    cliaLab: {
                        display: "CLIA Lab",
                    },
                    gmpManufacturing: {
                        display: "GMP Manufacturing",
                    },
                    tissueCellCulture: {
                        display: "Tissue/Cell Culture",
                    },
                    newSpecLab: {
                        display: "New Spec Lab",
                    },
                    secondGenerationLab: {
                        display: "Second Generation Lab",
                    },
                    vivarium: {
                        display: "Vivarium",
                    },
                },
            },
        },
        display: "Life Science",
    },
    Other: {
        subs: {
            specialtyTypeCriteria: {
                options: {
                    civic: {
                        display: "Civic",
                    },
                    library: {
                        display: "Library",
                    },
                    museum: {
                        display: "Museum",
                    },
                    commercial: {
                        display: "Commercial",
                    },
                    carDealership: {
                        display: "Car Dealership",
                    },
                    event: {
                        display: "Event",
                    },
                    amusementPark: {
                        display: "Amusement Park",
                    },
                    arena: {
                        display: "Arena",
                    },
                    sportsFacility: {
                        display: "Sports Facility",
                    },
                    theater: {
                        display: "Theater",
                    },
                    cityHall: {
                        display: "City Hall",
                    },
                    government: {
                        display: "Government",
                    },
                    parkingStructure: {
                        display: "Parking Structure",
                    },
                    manufacturedHousingAndRV: {
                        display: "Manufactured Housing & RV",
                    },
                    transportation: {
                        display: "Transportation",
                    },
                    misc: {
                        display: "Misc",
                    },
                },
                display: "Sub-Type",
            },
        },
        display: "Other",
    },
    Educational: {
        subs: {
            specialtyTypeCriteria: {
                options: {
                    collegeUniversity: {
                        display: "College/University",
                    },
                    schoolK12: {
                        display: "School K-12",
                    },
                    preschoolDayCare: {
                        display: "Preschool/Day Care",
                    },
                },
            },
        },
        display: "Educational",
    },
    Healthcare: {
        subs: {
            specialtyTypeCriteria: {
                options: {
                    medicalDental: {
                        display: "Medical/Dental",
                    },
                    veterinary: {
                        display: "Veterinary",
                    },
                    land: {
                        display: "Land",
                    },
                },
            },
        },
        display: "Healthcare",
    },
    Religious: {
        subs: {
            specialtyTypeCriteria: {
                options: {},
            },
        },
        display: "Religious",
    },
};

export const industrialMarkets = {
    "Bellevue/Eastside": {
        statecode: "WA",
        submarkets: {
            "520 Corridor": {
                micromarkets: [],
            },
            "Bellevue CBD": {
                micromarkets: [],
            },
            "Coal Creek/Issaquah": {
                micromarkets: [],
            },
            "East King County": {
                micromarkets: [],
            },
            "I-90 Corridor": {
                micromarkets: [],
            },
            Kirkland: {
                micromarkets: [],
            },
            Redmond: {
                micromarkets: [],
            },
            "Suburban Bellevue": {
                micromarkets: [],
            },
            Woodinville: {
                micromarkets: [],
            },
        },
    },
    Boise: {
        statecode: "ID",
        submarkets: {},
    },
    "Bay Area": {
        statecode: "CA",
        submarkets: {
            "East Bay": {
                micromarkets: [
                    "Alameda",
                    "Albany",
                    "Berkeley",
                    "Castro Valley",
                    "El Cerrito",
                    "Emeryville",
                    "Hayward",
                    "Newark",
                    "Oakland-Airport",
                    "Oakland-North",
                    "Oakland-South",
                    "Oakland-West",
                    "Oakland-Port/Jack London",
                    "Richmond",
                    "San Leandro",
                    "San Lorenzo",
                    "San Pablo",
                    "Union City",
                ],
            },
            Peninsula: {
                micromarkets: [
                    "Belmont",
                    "Brisbane/Daly City",
                    "Burlingame",
                    "Foster City/Redwood City",
                    "Menlo Park",
                    "Redwood City",
                    "San Bruno/Millbrae",
                    "San Carlos",
                    "San Mateo",
                    "South San Francisco",
                ],
            },
            "Silicon Valley": {
                micromarkets: [
                    "Campbell",
                    "Cupertino",
                    "De La Cruz",
                    "Evergreen",
                    "Gilroy",
                    "Los Altos",
                    "Los Gatos",
                    "Milpitas",
                    "Morgan Hill",
                    "Mountain View",
                    "Oak Creek",
                    "Palo Alto",
                    "San Benito",
                    "San Jose",
                    "San Jose - Downtown",
                    "San Jose - East",
                    "San Jose - South",
                    "San Jose - Winchester",
                    "Santa Clara",
                    "Saratoga",
                    "Sunnyvale",
                ],
            },
            "San Francisco": {
                micromarkets: [
                    "Bayview/Hunters Point",
                    "India Basin",
                    "Lower SOMA",
                    "Potrero East of 101",
                    "Potrero West of 101",
                    "Rincon/South Beach",
                    "Showplace Square",
                    "SOMA",
                    "Southern City",
                    "Yerba Buena",
                    "All Others",
                ],
            },
        },
    },
    "Los Angeles": {
        statecode: "CA",
        submarkets: {
            Central: {
                micromarkets: [
                    "Bell/Bell Gardens/Maywood",
                    "Commerce",
                    "Downtown LA",
                    "Huntington Park/Cudahy",
                    "Montebello/Monterey Park",
                    "Pico Rivera",
                    "South Gate",
                    "Vernon",
                ],
            },
            Downtown: {
                micromarkets: [],
            },
            "Mid-Cities": {
                micromarkets: [
                    "Artesia/Cerritos",
                    "Bellflower/Downey",
                    "Buena Park/La Palma",
                    "La Mirada",
                    "Norwalk",
                    "Paramount",
                    "Santa Fe Springs",
                    "Whittier",
                ],
            },
            "Mid-Wilshire": {
                micromarkets: [],
            },
            "San Fernando Valley": {
                micromarkets: [],
            },
            "San Gabriel Valley": {
                micromarkets: [
                    "Alhambra",
                    "Arcadia/Temple City",
                    "Azusa",
                    "Baldwin Park",
                    "City of Industry/DB/HH/RH",
                    "Covina/West Covina",
                    "Duarte",
                    "El Monte",
                    "Glendora/La Verne/ San Dimas",
                    "Irwindale",
                    "La Puente",
                    "Monrovia",
                    "Pomona/Claremont",
                    "Rosemead/San Gabriel",
                    "South El Monte",
                    "Walnut",
                ],
            },
            "Santa Clarita Valley": {
                micromarkets: [],
            },
            "South Bay": {
                micromarkets: [
                    "Carson",
                    "Compton",
                    "El Segundo",
                    "Gardena",
                    "Harbor City",
                    "Hawthorne",
                    "Inglewood",
                    "Lakewood/Hawaiian Gardens",
                    "Lawndale",
                    "Long Beach/Terminal Island",
                    "Lynwood",
                    "Rancho Dominguez",
                    "Redondo/Hermosa Beach",
                    "San Pedro",
                    "Signal Hill",
                    "Torrance",
                    "Wilmington",
                ],
            },
            Southeast: {
                micromarkets: [],
            },
            "Tri-Cities": {
                micromarkets: [],
            },
            West: {
                micromarkets: [],
            },
        },
    },
    "Inland Empire": {
        statecode: "CA",
        submarkets: {
            East: {
                micromarkets: [
                    "Banning",
                    "Beaumont",
                    "Bloomington",
                    "Colton",
                    "Corona",
                    "Eastvale",
                    "Grand Terrace",
                    "Highland",
                    "Jurupa Valley",
                    "Loma Linda",
                    "Mira Loma",
                    "Moreno Valley",
                    "Norco",
                    "Perris",
                    "Redlands",
                    "Rialto",
                    "Riverside",
                    "San Bernardino",
                ],
            },
            West: {
                micromarkets: ["Chino/Chino Hills", "Fontana", "Montclair", "Ontario", "Rancho Cucamonga", "Upland"],
            },
            South: {
                micromarkets: ["Hemet", "Lake Elsinore", "Menifee", "Murrieta", "San Jacinto", "Temecula", "Wildomar"],
            },
        },
    },
    Olympia: {
        statecode: "WA",
        submarkets: {
            "Downtown Olympia": {
                micromarkets: [],
            },
            Eastside: {
                micromarkets: [],
            },
            Lacey: {
                micromarkets: [],
            },
            "Outlying Thurston County": {
                micromarkets: [],
            },
            "Tumwater/South Olympia": {
                micromarkets: [],
            },
            Westside: {
                micromarkets: [],
            },
            "Yelm/N Yelm": {
                micromarkets: [],
            },
        },
    },
    "Orange County": {
        statecode: "CA",
        submarkets: {
            Airport: {
                micromarkets: ["Costa Mesa", "Fountain Valley", "Irvine", "Newport Beach", "Santa Ana", "Tustin"],
            },
            Central: {
                micromarkets: [],
            },
            North: {
                micromarkets: ["Anaheim", "Anaheim Hills", "Brea/La Habra", "Buena Park", "Fullerton", "Orange"],
            },
            South: {
                micromarkets: [
                    "Dana Point/San Juan/ San Clemente",
                    "Irvine Spectrum",
                    "Laguna Hills/Aliso Viejo",
                    "Laguna Niguel/Laguna Beach",
                    "Lake Forest/Foothill Ranch",
                    "Mission Viejo",
                ],
            },
            West: {
                micromarkets: [
                    "Cypress",
                    "Garden Grove",
                    "Huntington Beach",
                    "La Palma",
                    "Los Alamitos/Stanton",
                    "Seal Beach",
                    "Westminster",
                ],
            },
        },
    },
    Phoenix: {
        statecode: "AZ",
        submarkets: {
            Airport: {
                micromarkets: [
                    "North Airport",
                    "SC North of Salt River",
                    "SC South of Salt River",
                    "South Airport North of Roeser",
                    "South Airport South of Roeser",
                ],
            },
            "Central Corridor": {
                micromarkets: [],
            },
            East: {
                micromarkets: ["Midtown/Central Phoenix"],
            },
            "East Valley": {
                micromarkets: ["Chandler"],
            },
            North: {
                micromarkets: [],
            },
            Northwest: {
                micromarkets: [
                    "Deer Valley/Pinnacle Park",
                    "Grand Avenue",
                    "North Black Canyon",
                    "North Glendale/Sun City",
                    "West Phoenix North of Thomas Road",
                    "West Phoenix South of Thomas Road",
                ],
            },
            "Pinal County": {
                micromarkets: ["Pinal County"],
            },
            Scottsdale: {
                micromarkets: ["Scottsdale Airpark"],
            },
            Southeast: {
                micromarkets: [
                    "Chandler Airport",
                    "Chandler North/Gilbert",
                    "Falcon Fld/Apache Junction",
                    "Mesa",
                    "Tempe East",
                    "Tempe Northwest",
                    "Tempe Southwest",
                ],
            },
            Southwest: {
                micromarkets: [
                    "Goodyear",
                    "Southwest North of Buckeye Road",
                    "Southwest South of Buckeye Road",
                    "Tolleson",
                ],
            },
            West: {
                micromarkets: ["Glendale"],
            },
        },
    },
    Portland: {
        statecode: "OR",
        submarkets: {
            Downtown: {
                micromarkets: [],
            },
            CBD: {
                micromarkets: ["Guilds Lake", "NW Close-in"],
            },
            "Pearl District/Chinatown": {
                micromarkets: [],
            },
            Suburban: {
                micromarkets: [],
            },
            "Clark County": {
                micromarkets: [
                    "CBD/West Vancouver",
                    "Camas/Washougal",
                    "Cascade Park",
                    "Hazel Dell/Salmon Creek",
                    "Orchards",
                    "St.Johns/Central Vancouver",
                    "Vancouver Mall",
                ],
            },
            "I-5 Corridor": {
                micromarkets: [],
            },
            "Lloyd District": {
                micromarkets: [],
            },
            Northeast: {
                micromarkets: [
                    "NE Columbia Blvd/PDX",
                    "East Columbia Corridor",
                    "Gateway",
                    "Gresham",
                    "Hayden Isl/Swan Isl",
                    "NE Close-In",
                    "Rivergate",
                ],
            },
            Northwest: {
                micromarkets: [],
            },
            Southeast: {
                micromarkets: ["Clackamas/Milwaukee", "Mall 205", "Oregon City", "SE Close-In"],
            },
            Southwest: {
                micromarkets: [
                    "217 Corridor/Beaverton",
                    "Barbur Blvd/Capitol Hwy",
                    "Johns Landing",
                    "North Beaverton",
                    "SW Close-In",
                ],
            },
            Westside: {
                micromarkets: [],
            },
        },
    },
    Reno: {
        statecode: "NV",
        submarkets: {
            Airport: {
                micromarkets: [],
            },
            "Carson City County": {
                micromarkets: [],
            },
            "Central Reno": {
                micromarkets: [],
            },
            "Central/West Reno": {
                micromarkets: [],
            },
            "Douglas County": {
                micromarkets: [],
            },
            "1-80 West Corridor": {
                micromarkets: [],
            },
            "Lyon County": {
                micromarkets: [],
            },
            "North Valleys": {
                micromarkets: [],
            },
            "Outlying Washoe County": {
                micromarkets: [],
            },
            "South Reno": {
                micromarkets: [],
            },
            Sparks: {
                micromarkets: [],
            },
            "Storey County": {
                micromarkets: [],
            },
            "West Reno": {
                micromarkets: [],
            },
        },
    },
    Sacramento: {
        statecode: "CA",
        submarkets: {
            "Auburn/Newcastle": {
                micromarkets: [],
            },
            "Davis/Woodland": {
                micromarkets: [],
            },
            Downtown: {
                micromarkets: [],
            },
            "East Sacramento": {
                micromarkets: [],
            },
            "Elk Grove/Laguna": {
                micromarkets: [],
            },
            "Folsom/El Dorado": {
                micromarkets: [],
            },
            "Marysville/Yuba City": {
                micromarkets: [],
            },
            Mather: {
                micromarkets: [],
            },
            McClellan: {
                micromarkets: [],
            },
            "Natomas/Northgate": {
                micromarkets: [],
            },
            "NE Sacramento": {
                micromarkets: [],
            },
            "Power Inn": {
                micromarkets: [],
            },
            Richards: {
                micromarkets: [],
            },
            "Roseville/Rocklin": {
                micromarkets: [],
            },
            "South Sacramento": {
                micromarkets: [],
            },
            Sunrise: {
                micromarkets: [],
            },
            "West Sacramento": {
                micromarkets: [],
            },
        },
    },
    "San Diego": {
        statecode: "CA",
        submarkets: {
            Central: {
                micromarkets: [
                    "Kearny Mesa",
                    "Mira Mesa/ Miramar",
                    "Mission Gorge",
                    "PB/Rose Canyon/ Morena",
                    "Sorrento Mesa",
                    "Sorrento Valley",
                    "Sports Arena/ Airport",
                    "Torrey Pines",
                    "UTC",
                ],
            },
            Downtown: {
                micromarkets: [],
            },
            "East County": {
                micromarkets: ["East City", "El Cajon", "La Mesa/ Spring Valley", "Santee", "Southeast San Diego"],
            },
            "I-15 Corridor": {
                micromarkets: ["Poway", "Rancho Bernardo", "Scripps Ranch"],
            },
            "North County": {
                micromarkets: ["Carlsbad", "Escondido", "North Beach Cities", "Oceanside", "San Marcos", "Vista"],
            },
            Outlying: {
                micromarkets: ["Outlying SD County North", "Outlying SD County South"],
            },
            "South County": {
                micromarkets: ["Chula Vista", "National City", "Otay Mesa", "San Ysidro/ Imperial Beach"],
            },
            Uptown: {
                micromarkets: ["Park East", "Uptown East", "Uptown West/Park West"],
            },
        },
    },
    Seattle: {
        statecode: "WA",
        submarkets: {
            Downtown: {
                micromarkets: [
                    "Ballard",
                    "Belltown/Denny Regrade",
                    "Capital Hill/Central District",
                    "Lake Union",
                    "Pioneer Square/Waterfront",
                    "Queen Anne/Magnolia",
                    "Seattle CBD",
                    "South Seattle",
                ],
            },
            "Northend/Snohomish": {
                micromarkets: [
                    "Bothell/Kenmore",
                    "Edmonds/Lynnwood",
                    "Everett CBD",
                    "Mill Creek/North Creek",
                    "North Snohomish County",
                    "Northgate/North Seattle",
                    "South Everett/Harbor Point",
                    "South Snohomish County",
                ],
            },
            Southend: {
                micromarkets: ["Burien", "Kent", "Kent Valley", "Renton", "Seatac", "Tukwila"],
            },
        },
    },
    Tacoma: {
        statecode: "WA",
        submarkets: {
            Dupont: {
                micromarkets: [],
            },
            "East Pierce County": {
                micromarkets: [],
            },
            "East Tacoma": {
                micromarkets: [],
            },
            "Federal Way/Auburn": {
                micromarkets: [],
            },
            "Fort Lewis": {
                micromarkets: [],
            },
            "Gig Harbor/West Pierce County": {
                micromarkets: [],
            },
            Lakewood: {
                micromarkets: [],
            },
            "North End Tacoma": {
                micromarkets: [],
            },
            "Parkland/Spanaway": {
                micromarkets: [],
            },
            "Port of Tacoma/Fife": {
                micromarkets: [],
            },
            "Puyallup/South Hill": {
                micromarkets: [],
            },
            "Sumner/Bonney Lake": {
                micromarkets: [],
            },
            "Tacoma CBD": {
                micromarkets: [],
            },
            "Tacoma Suburban": {
                micromarkets: [],
            },
            "University Place": {
                micromarkets: [],
            },
        },
    },
};

export const officeMarkets = {
    Phoenix: {
        statecode: "AZ",
        submarkets: {
            Airport: {
                micromarkets: ["Airport Area", "South Tempe/Ahwatukee"],
            },
            "Central Corridor": {
                micromarkets: ["Downtown", "Midtown"],
            },
            East: {
                micromarkets: ["44th Street Corridor", "Camelback Corridor", "Midtown/Central Phoenix"],
            },
            "East Valley": {
                micromarkets: [
                    "Chandler",
                    "Gateway Airport/Loop 202",
                    "Mesa Downtown",
                    "Mesa East",
                    "Superstition Corridor",
                    "Tempe",
                ],
            },
            North: {
                micromarkets: ["N Phoenix/Cave Creek", "Paradise Valley", "Piestewa Peak Corridor"],
            },
            Northwest: {
                micromarkets: ["Arrowhead", "Deer Valley/Airport", "North I-17", "Northwest Phoenix"],
            },
            "Pinal County": {
                micromarkets: ["Pinal County"],
            },
            Scottsdale: {
                micromarkets: ["Central Scottsdale", "N Scottsdale/Carefree", "Scottsdale Airpark", "Scottsdale South"],
            },
            West: {
                micromarkets: ["Glendale", "Loop 303/Surprise", "Southwest Phoenix", "West I-10"],
            },
        },
    },

    "Bay Area": {
        statecode: "CA",
        submarkets: {
            "East Bay": {
                micromarkets: [
                    "Alameda",
                    "Albany",
                    "Berkeley",
                    "Castro Valley",
                    "Danville",
                    "Dublin",
                    "El Cerrito",
                    "Emeryville",
                    "Fremont",
                    "Hayward",
                    "Lafayette/Moraga/Orinda",
                    "Livermore",
                    "Newark",
                    "Oakland-Airport",
                    "Oakland-North",
                    "Oakland-South",
                    "Oakland-West",
                    "Oakland-Port/Jack London",
                    "Oakland CBD",
                    "Pleasanton",
                    "San Leandro",
                    "San Pablo",
                    "San Ramon",
                    "Union City",
                    "Walnut Creek",
                ],
            },
            Peninsula: {
                micromarkets: [
                    "Belmont/San Carlos",
                    "Brisbane",
                    "Burlingame",
                    "Daly City",
                    "Foster City",
                    "Menlo Park",
                    "Redwood City",
                    "Redwood Shores",
                    "San Bruno/Millbrae",
                    "San Mateo",
                    "South San Francisco",
                ],
            },
            "Silicon Valley": {
                micromarkets: [
                    "Campbell",
                    "Cupertino",
                    "De La Cruz",
                    "Evergreen",
                    "Gilroy",
                    "Los Altos",
                    "Los Gatos",
                    "Milpitas",
                    "Morgan Hill",
                    "Mountain View",
                    "Newark",
                    "Oak Creek",
                    "Palo Alto",
                    "San Benito",
                    "San Jose",
                    "San Jose - Downtown",
                    "San Jose - East",
                    "San Jose - South",
                    "San Jose - Winchester",
                    "Santa Clara",
                    "Saratoga",
                    "Sunnyvale",
                ],
            },
            "San Francisco": {
                micromarkets: [
                    "Civic Center",
                    "Financial District",
                    "Jackson Square",
                    "Mid-Market",
                    "Mission Bay/China Basin",
                    "Potrero",
                    "Rincon/South Beach",
                    "Showplace Square",
                    "SOMA",
                    "South Financial District",
                    "Union Square",
                    "Van Ness Corridor",
                    "Waterfront/North Beach",
                ],
            },
        },
    },

    "Inland Empire": {
        statecode: "CA",
        submarkets: {
            East: {
                micromarkets: ["Coachella Valley", "Corona", "East San Bernardino", "Riverside", "South Riverside"],
            },
            West: {
                micromarkets: ["Airport", "North San Bernardino", "West San Bernardino"],
            },
            South: {
                micromarkets: [],
            },
        },
    },

    "Los Angeles": {
        statecode: "CA",
        submarkets: {
            Central: {
                micromarkets: [],
            },
            "Tri-Cities": {
                micromarkets: [
                    "Burbank",
                    "Glendale",
                    "North Hollywood",
                    "Pasadena/Arcadia/Morovia",
                    "Studio/Universal Cities",
                ],
            },
            Downtown: {
                micromarkets: [],
            },
            "Mid-Cities": {
                micromarkets: [],
            },
            "Mid-Wilshire": {
                micromarkets: ["Hollywood/Silver Lake", "Mid-Wilshire", "Miracle Mile", "Park Mile", "West Hollywood"],
            },
            "San Fernando Valley": {
                micromarkets: [
                    "Eastern SFV",
                    "Encino",
                    "Sherman Oaks",
                    "Tarzana",
                    "Western SFV",
                    "Woodland Hills/Warner Ctr",
                ],
            },
            "San Gabriel Valley": {
                micromarkets: ["Eastern SGV", "Western SGV"],
            },
            "Santa Clarita Valley": {
                micromarkets: [],
            },
            "South Bay": {
                micromarkets: [
                    "190th Street Corridor",
                    "Beach Cities/Palos Verdes",
                    "Downtown Long Beach",
                    "El Segundo",
                    "Hawthorne/Gardena",
                    "LAX",
                    "Suburban Long Beach",
                    "Torrance",
                ],
            },
            Southeast: {
                micromarkets: [],
            },
            West: {
                micromarkets: [
                    "Beverly Hills",
                    "Brentwood",
                    "Calabasas/Westlake Village",
                    "Century City",
                    "Culver City",
                    "Marina Del Rey/Venice",
                    "Olympic Corridor",
                    "Pacific Palisades/Malibu",
                    "Santa Monica",
                    "Santa Monica Mountains",
                    "West Los Angeles",
                    "Westwood",
                ],
            },
        },
    },

    "Orange County": {
        statecode: "CA",
        submarkets: {
            Airport: {
                micromarkets: ["Costa Mesa", "Irvine", "Newport Beach", "South Santa Ana"],
            },
            Central: {
                micromarkets: [
                    "Civic Center Area",
                    "East Orange",
                    "Main Place",
                    "Parkcenter",
                    "Santa Ana",
                    "Stadium Area",
                    "The City",
                    "Tustin",
                ],
            },
            North: {
                micromarkets: [
                    "Anaheim Hills",
                    "Brea/La Habra",
                    "Buena Park/La Palma",
                    "Fullerton",
                    "North/East Anaheim",
                    "Placentia/Yorba Linda",
                ],
            },
            South: {
                micromarkets: [
                    "Dana Point/San Juan/ San Clemente",
                    "Irvine Spectrum",
                    "Laguna Hills/Aliso Viejo",
                    "Laguna Niguel/Laguna Beach",
                    "Lake Forest/Foothill Ranch",
                    "Mission Viejo",
                    "Westminster",
                ],
            },
            West: {
                micromarkets: [
                    "Cypress",
                    "Fountain Valley",
                    "Garden Grove",
                    "Huntington Beach",
                    "Los Alamitos/Stanton",
                    "Seal Beach",
                    "Westminster",
                ],
            },
        },
    },

    Sacramento: {
        statecode: "CA",
        submarkets: {
            "Auburn/Lincoln": {
                micromarkets: [],
            },
            "Campus Commons": {
                micromarkets: [],
            },
            "Carmichael/Fair Oaks": {
                micromarkets: [],
            },
            "Citrus Heights/Orangevale": {
                micromarkets: [],
            },
            "Davis/Woodland": {
                micromarkets: [],
            },
            "East Sacramento": {
                micromarkets: [],
            },
            "El Dorado": {
                micromarkets: [],
            },
            "Elk Grove": {
                micromarkets: [],
            },
            Folsom: {
                micromarkets: [],
            },
            "Highway 50 Corridor": {
                micromarkets: [],
            },
            "Howe Ave/Fulton Ave": {
                micromarkets: [],
            },
            Midtown: {
                micromarkets: [],
            },
            "North Natomas": {
                micromarkets: [],
            },
            "Point West": {
                micromarkets: [],
            },
            "Rio Linda/North Highlands": {
                micromarkets: [],
            },
            "Roseville/Rocklin": {
                micromarkets: [],
            },
            "South Natomas": {
                micromarkets: [],
            },
            "South Sacramento": {
                micromarkets: [],
            },
            "Watt Ave": {
                micromarkets: [],
            },
            "West Sacramento": {
                micromarkets: [],
            },
        },
    },

    "San Diego": {
        statecode: "CA",
        submarkets: {
            "North County": {
                micromarkets: ["Carlsbad", "Escondido", "Oceanside", "San Marcos", "Vista"],
            },
            Central: {
                micromarkets: [
                    "Kearny Mesa",
                    "Mira Mesa/ Miramar",
                    "Mission Gorge",
                    "Mission Valley",
                    "Old Town/Sports Arena/Pt. Loma",
                    "PB/Rose Canyon/Morena",
                    "Sorrento Mesa",
                    "Sorrento Valley",
                    "Torrey Pines",
                    "UTC",
                ],
            },
            "I-15 Corridor": {
                micromarkets: ["Poway", "Rancho Bernardo", "Scripps Ranch"],
            },
            "South County": {
                micromarkets: ["Chula Vista", "Coronado", "National City", "Otay Mesa", "San Ysidro/ Imperial Beach"],
            },
            "East County": {
                micromarkets: [
                    "College Area",
                    "East City",
                    "El Cajon",
                    "La Mesa/ Spring Valley",
                    "Santee",
                    "Southeast San Diego",
                ],
            },
            Uptown: {
                micromarkets: ["Park East", "Uptown East", "Uptown West/Park West"],
            },
            Downtown: {
                micromarkets: [],
            },
            "North Cities": {
                micromarkets: ["Del Mar Heights/Carmel Valley", "Governor Park", "La Jolla", "North Beach Cities"],
            },
        },
    },

    Boise: {
        statecode: "ID",
        submarkets: {},
    },

    Portland: {
        statecode: "OR",
        submarkets: {
            Downtown: {
                micromarkets: [],
            },
            CBD: {
                micromarkets: [],
            },
            "Pearl District/Chinatown": {
                micromarkets: [],
            },
            Suburban: {
                micromarkets: [],
            },
            "Clark County": {
                micromarkets: [],
            },
            "I-5 Corridor": {
                micromarkets: [],
            },
            "Lloyd District": {
                micromarkets: [],
            },
            Northeast: {
                micromarkets: [],
            },
            Northwest: {
                micromarkets: [],
            },
            Southeast: {
                micromarkets: [],
            },
            Southwest: {
                micromarkets: [],
            },
            Westside: {
                micromarkets: [],
            },
        },
    },

    Reno: {
        statecode: "NV",
        submarkets: {
            Airport: {
                micromarkets: [],
            },
            "Carson City County": {
                micromarkets: [],
            },
            "Central Reno": {
                micromarkets: [],
            },
            "Central/West Reno": {
                micromarkets: [],
            },
            "Douglas County": {
                micromarkets: [],
            },
            "1-80 West Corridor": {
                micromarkets: [],
            },
            "Lyon County": {
                micromarkets: [],
            },
            "North Valleys": {
                micromarkets: [],
            },
            "Outlying Washoe County": {
                micromarkets: [],
            },
            "South Reno": {
                micromarkets: [],
            },
            Sparks: {
                micromarkets: [],
            },
            "Storey County": {
                micromarkets: [],
            },
            "West Reno": {
                micromarkets: [],
            },
        },
    },

    "Bellevue/Eastside": {
        statecode: "WA",
        submarkets: {
            "520 Corridor": {
                micromarkets: [],
            },
            "Bellevue CBD": {
                micromarkets: [],
            },
            "Coal Creek/Issaquah": {
                micromarkets: [],
            },
            "East King County": {
                micromarkets: [],
            },
            "I-90 Corridor": {
                micromarkets: [],
            },
            Kirkland: {
                micromarkets: [],
            },
            "Mercer Island": {
                micromarkets: [],
            },
            Redmond: {
                micromarkets: [],
            },
            "Suburban Bellevue": {
                micromarkets: [],
            },
            Woodinville: {
                micromarkets: [],
            },
        },
    },

    Olympia: {
        statecode: "WA",
        submarkets: {
            "Downtown Olympia": {
                micromarkets: [],
            },
            Eastside: {
                micromarkets: [],
            },
            Lacey: {
                micromarkets: [],
            },
            "Outlying Thurston County": {
                micromarkets: [],
            },
            "Tumwater/South Olympia": {
                micromarkets: [],
            },
            Westside: {
                micromarkets: [],
            },
            "Yelm/N Yelm": {
                micromarkets: [],
            },
        },
    },

    Seattle: {
        statecode: "WA",
        submarkets: {
            Downtown: {
                micromarkets: [
                    "Ballard",
                    "Belltown/Denny Regrade",
                    "Capital Hill/Central District",
                    "Lake Union",
                    "Pioneer Square/Waterfront",
                    "Queen Anne/Magnolia",
                    "Seattle CBD",
                    "South Seattle",
                ],
            },
            "Northend/Snohomish": {
                micromarkets: [
                    "Bothell/Kenmore",
                    "Edmonds/Lynnwood",
                    "Everett CBD",
                    "Mill Creek/North Creek",
                    "North Snohomish County",
                    "Northgate/North Seattle",
                    "South Everett/Harbor Point",
                    "South Snohomish County",
                ],
            },
            Southend: {
                micromarkets: ["Burien", "Kent", "Kent Valley", "Renton", "Seatac", "Tukwila"],
            },
        },
    },
    Tacoma: {
        statecode: "WA",
        submarkets: {
            Dupont: {
                micromarkets: [],
            },
            "East Pierce County": {
                micromarkets: [],
            },
            "East Tacoma": {
                micromarkets: [],
            },
            "Federal Way/Auburn": {
                micromarkets: [],
            },
            "Fort Lewis": {
                micromarkets: [],
            },
            "Gig Harbor/West Pierce County": {
                micromarkets: [],
            },
            Lakewood: {
                micromarkets: [],
            },
            "North End Tacoma": {
                micromarkets: [],
            },
            "Parkland/Spanaway": {
                micromarkets: [],
            },
            "Port of Tacoma/Fife": {
                micromarkets: [],
            },
            "Puyallup/South Hill": {
                micromarkets: [],
            },
            "Sumner/Bonney Lake": {
                micromarkets: [],
            },
            "Tacoma CBD": {
                micromarkets: [],
            },
            "Tacoma Suburban": {
                micromarkets: [],
            },
            "University Place": {
                micromarkets: [],
            },
        },
    },
};

export const submarketFilters = (marketFilter) => {
    let filters = [];
    if (marketFilter) {
        if (industrialMarkets[marketFilter] && industrialMarkets[marketFilter].submarkets) {
            filters = filters.concat(Object.keys(industrialMarkets[marketFilter].submarkets));
        }
        if (officeMarkets[marketFilter] && officeMarkets[marketFilter].submarkets) {
            filters = filters.concat(
                Object.keys(officeMarkets[marketFilter].submarkets).filter(function (item) {
                    return filters.indexOf(item) < 0;
                })
            );
        }
    }
    return filters.sort();
};

export const micromarketFilters = (marketFilter, submarketFilter) => {
    let filters = [];
    if (marketFilter && submarketFilter) {
        if (
            industrialMarkets[marketFilter] &&
            industrialMarkets[marketFilter].submarkets &&
            industrialMarkets[marketFilter].submarkets[submarketFilter] &&
            industrialMarkets[marketFilter].submarkets[submarketFilter].micromarkets
        ) {
            filters = filters.concat(industrialMarkets[marketFilter].submarkets[submarketFilter].micromarkets);
        }
        if (
            officeMarkets[marketFilter] &&
            officeMarkets[marketFilter].submarkets &&
            officeMarkets[marketFilter].submarkets[submarketFilter] &&
            officeMarkets[marketFilter].submarkets[submarketFilter].micromarkets
        ) {
            filters = filters.concat(
                officeMarkets[marketFilter].submarkets[submarketFilter].micromarkets.filter(function (item) {
                    return filters.indexOf(item) < 0;
                })
            );
        }
    }
    return filters.sort();
};

const buildMarketFilters = () => {
    let markets = {};
    Object.keys(officeMarkets).forEach((market) => {
        let statecode = officeMarkets[market]["statecode"];
        if (!(statecode in markets)) {
            markets[statecode] = {};
        }
        markets[statecode][market] = {};
        const submarkets = submarketFilters(market);

        submarkets.forEach((submarket) => {
            markets[statecode][market][submarket] = micromarketFilters(market, submarket);
        });
    });
    return markets;
};

export const marketFilters = buildMarketFilters();

export const marketAreas = {
    Industrial: industrialMarkets,
    Office: officeMarkets,
    Land: officeMarkets,
    Multifamily: officeMarkets,
    Retail: officeMarkets,
    Hospitality: officeMarkets,
    "Life Science": officeMarkets,
    Educational: officeMarkets,
    Healthcare: officeMarkets,
    Religious: officeMarkets,
    Other: officeMarkets,
};

export const marketAreasForResearchSnapshot = () => {
    let options = _.map(industrialMarkets, (key, val) => {
        return {
            value: val,
            label: key.statecode + " - " + val,
        };
    });
    options.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
        }
        return 0;
    });
    options.unshift({ value: "Select", label: "Select" });
    return options;
};

export const researchExportDateValidation = (startQuarter, endQuarter, startYear, endYear) => {
    var startQ = startQuarter.slice(0, -1);
    var endQ = endQuarter.slice(0, -1);

    if (startYear === endYear && startQ <= endQ) {
        return true;
    }
    if (startYear < endYear) {
        return true;
    }
    if (startYear > endYear) {
        return false;
    }
};

export const yearsForResearchSnapshot = () => {
    var currentYear = new Date().getFullYear();
    return [
        {
            value: currentYear - 1,
            label: currentYear - 1,
        },
        {
            value: currentYear,
            label: currentYear,
        },
    ];
};

export const yearsForExportResearch = () => {
    var currentYear = new Date().getFullYear();
    var output = [{ value: "Select", label: "Select" }];
    for (let i = 2021; i <= currentYear; i++) {
        output.push({ value: i, label: i });
    }
    return output;
};

export const endYearsForExportResearch = (startYear) => {
    var currentYear = new Date().getFullYear();
    var output = [{ value: "Select", label: "Select" }];
    for (let i = startYear; i <= currentYear; i++) {
        output.push({ value: i, label: i });
    }
    return output;
};

export const marketAreaOptions = (propertyType, stateCode) => {
    let options = [{ label: "Select Market Area", value: "", disabled: false }];
    let marketAreaData = Object.keys(get(marketAreas, propertyType, {}));
    if (propertyType && marketAreas[propertyType] && stateCode) {
        marketAreaData.forEach((market) => {
            if (marketAreas[propertyType][market]["statecode"] === stateCode) {
                let responseOption = `${stateCode} - ${market}`;
                options.push({ label: responseOption, value: responseOption });
            }
        });
    }
    return options;
};

export const submarketOptions = (propertyType, marketArea) => {
    let options = [{ label: "Select Submarket", value: "", disabled: false }];
    if (propertyType && marketArea && get(marketAreas, `${propertyType}.${marketArea}.submarkets`, null) !== null) {
        options = options.concat(
            Object.keys(get(marketAreas, `${propertyType}.${marketArea}.submarkets`, {})).map((submarket) => {
                return { label: submarket, value: submarket };
            })
        );
    }
    return options;
};

export const submarketOptionsForResearch = (marketArea) => {
    let options = [{ label: "All", value: "All", disabled: false }];
    if (marketArea && get(officeMarkets, `${marketArea}.submarkets`, null) !== null) {
        options = options.concat(
            Object.keys(get(officeMarkets, `${marketArea}.submarkets`, {})).map((submarket) => {
                return { label: submarket, value: submarket };
            })
        );
    }
    return options;
};

export const micromarketOptions = (propertyType, marketArea, submarket) => {
    let options = [{ label: "Select Micromarket", value: "", disabled: false }];

    if (
        propertyType &&
        marketArea &&
        submarket &&
        get(marketAreas, `${propertyType}.${marketArea}.submarkets.${submarket}.micromarkets`, null) !== null
    ) {
        options = options.concat(
            get(marketAreas, `${propertyType}.${marketArea}.submarkets.${submarket}.micromarkets`, {}).map(
                (micromarket) => {
                    return { label: micromarket, value: micromarket };
                }
            )
        );
    }
    return options;
};

export const micromarketOptionsForResearch = (marketArea, submarket) => {
    let options = [{ label: "All", value: "All", disabled: false }];

    if (
        marketArea &&
        submarket &&
        get(officeMarkets, `${marketArea}.submarkets.${submarket}.micromarkets`, null) !== null
    ) {
        options = options.concat(
            get(officeMarkets, `${marketArea}.submarkets.${submarket}.micromarkets`, {}).map((micromarket) => {
                return { label: micromarket, value: micromarket };
            })
        );
    }
    return options;
};

export const propertyTypeFilterOptions = () => {
    const defaultOption = [{ label: "Select Property Type", value: "", disabled: true }];
    const options = Object.keys(propertyTypeFilters).map((propertyType) => {
        return { value: propertyType, label: get(propertyTypeFilters, `${propertyType}.display`, "") };
    });

    return defaultOption.concat(options);
};

export const propertyTypeFilterOptionsForResearchSnapshot = () => {
    const defaultOption = [{ label: "All", value: "All" }];
    const options = Object.keys(propertyTypeFilters).map((propertyType) => {
        return { value: propertyType, label: get(propertyTypeFilters, `${propertyType}.display`, "") };
    });

    return defaultOption.concat(options);
};

export const propertyTypeFilterOptionsForExportResearch = () => {
    const defaultOption = [{ label: "Select", value: "Select" }];
    const options = Object.keys(propertyTypeFilters).map((propertyType) => {
        return { value: propertyType, label: get(propertyTypeFilters, `${propertyType}.display`, "") };
    });

    return defaultOption.concat(options);
};

export const specialtyTypeFilterOptions = (propertyType) => {
    let options = [{ label: "Select Type", value: "", disabled: true }];

    if (propertyType) {
        options = options.concat(
            Object.keys(get(propertyTypeFilters, `${propertyType}.subs.specialtyTypeCriteria.options`, {})).map(
                (option, i) => {
                    return {
                        value: option,
                        label: get(
                            propertyTypeFilters,
                            `${propertyType}.subs.specialtyTypeCriteria.options.${option}.display`,
                            ""
                        ),
                    };
                }
            )
        );
    }
    return options;
};

export const specialtyTypeFilterOptionsForExportResearch = (propertyType) => {
    let options = [{ label: "All", value: "All", disabled: false }];

    if (propertyType) {
        options = options.concat(
            Object.keys(get(propertyTypeFilters, `${propertyType}.subs.specialtyTypeCriteria.options`, {})).map(
                (option, i) => {
                    return {
                        value: option,
                        label: get(
                            propertyTypeFilters,
                            `${propertyType}.subs.specialtyTypeCriteria.options.${option}.display`,
                            ""
                        ),
                    };
                }
            )
        );
    }
    return options;
};

export const getSpecialtyTypeDisplayName = (propertyType, specialtyType) => {
    return get(
        propertyTypeFilters,
        `${propertyType}.subs.specialtyTypeCriteria.options.${specialtyType}.display`,
        specialtyType
    );
};

export const getSpecialtyTypeDisplayNameNoPropertyType = (specialtyType) => {
    for (var key in propertyTypeFilters) {
        if (
            get(
                propertyTypeFilters,
                `${key}.subs.specialtyTypeCriteria.options.${specialtyType}.display`,
                "default"
            ) !== "default"
        ) {
            return get(propertyTypeFilters, `${key}.subs.specialtyTypeCriteria.options.${specialtyType}.display`, "");
        }
    }
};

export const leedCertLevels = ["Certified", "Silver", "Gold", "Platinum"];

export const buildingStatuses = [
    "Existing",
    "Proposed",
    "Under Construction",
    "Under Renovation",
    "Demolished",
    "Land",
];

export const buildingClasses = ["", "Trophy", "A", "B", "C", "D"];

export const constructionTypes = ["Concrete", "Brick", "Steel", "Wood", "Glass"];

export const tenancyTypes = ["Single Tenant", "Multi-Tenant"];

export const energyStarRatings = ["Rating 1", "Rating 2", "Rating 3", "Rating 4", "Rating 5"];
export const optionsBuilder = (values) => {
    let options = [{ label: "Select ...", value: "", disabled: true }];
    if (values) {
        options = options.concat(
            values.map((value) => {
                return { label: value, value: value };
            })
        );
    }
    return options;
};

export const disabledStatuses = {
    broker: {
        Draft: false,
        Active: true,
        Requested: true,
        "Update Requested": true,
        Inactive: true,
        Complete: true,
    },
    evp: {
        Draft: false,
        Active: true,
        Requested: true,
        "Update Requested": true,
        Inactive: true,
        Complete: true,
    },
    portlandBrokerAdmin: {
        Draft: false,
        Active: true,
        Requested: true,
        "Update Requested": true,
        Inactive: true,
        Complete: true,
    },
    arizonaBrokerAdmin: {
        Draft: false,
        Active: true,
        Requested: true,
        "Update Requested": true,
        Inactive: true,
        Complete: true,
    },
    dataEntry: {
        Draft: false,
        Active: false,
        Requested: false,
        "Update Requested": false,
        Inactive: false,
        Complete: false,
    },
    dataAdmin: {
        Draft: false,
        Active: false,
        Requested: false,
        "Update Requested": false,
        Inactive: false,
        Complete: false,
    },
    propertyManagement: {
        Draft: true,
        Active: false,
        Requested: true,
        "Update Requested": false,
        Inactive: false,
        Complete: false,
    },
};

export default {
    urls,
    leedCertLevels,
    optionsBuilder,
    constructionTypes,
    buildingClasses,
    buildingStatuses,
    tenancyTypes,
    energyStarRatings,
    disabledStatuses,
    getSpecialtyTypeDisplayName,
    getSpecialtyTypeDisplayNameNoPropertyType,
};
