const API_RESPONSE = "API_RESPONSE";
const PAGE_LOADING = "PAGE_LOADING";
const PAGE_LOADING_2 = "PAGE_LOADING_2";
const SNACKBAR = "SNACKBAR";
const SEARCH_RESULTS = "SEARCH_RESULTS";
const TOGGLE_CONFIRM = "TOGGLE_CONFIRM";
const IS_SAVING = "IS_SAVING";
const ADD_ERROR = "ADD_ERROR";
const CLEAR_ERROR_BY_FIELD = "CLEAR_ERROR_BY_FIELD";
const CLEAR_ERRORS = "CLEAR_ERRORS";
const LOADING = "LOADING";
const RESET_CONFIRM = "RESET_CONFIRM";
const SOMETHING_SAVING = "SOMETHING_SAVING";
const SET_ERROR_PANELS_EXPANDED = "SET_ERROR_PANELS_EXPANDED";
const TOGGLE_CHECK_ERROR_PANELS = "TOGGLE_CHECK_ERROR_PANELS";
const NOTIFICATION = "NOTIFICATION";

export default {
    API_RESPONSE,
    PAGE_LOADING,
    PAGE_LOADING_2,
    SNACKBAR,
    SEARCH_RESULTS,
    TOGGLE_CONFIRM,
    IS_SAVING,
    ADD_ERROR,
    CLEAR_ERROR_BY_FIELD,
    CLEAR_ERRORS,
    LOADING,
    RESET_CONFIRM,
    SOMETHING_SAVING,
    SET_ERROR_PANELS_EXPANDED,
    TOGGLE_CHECK_ERROR_PANELS,
    NOTIFICATION,
};
