export const leaseTypeTypes = [
    {
        value: "Full Service",
        label: "Full Service",
    },
    {
        value: "Gross",
        label: "Gross",
    },
    {
        value: "Gross +E",
        label: "Gross +E",
    },
    {
        value: "Gross +J",
        label: "Gross +J",
    },
    {
        value: "Gross +U",
        label: "Gross +U",
    },
    {
        value: "Gross +E+J",
        label: "Gross +E+J",
    },
    {
        value: "Gross +U+J",
        label: "Gross +U+J",
    },
    {
        value: "Industrial Gross",
        label: "Industrial Gross",
    },
    {
        value: "Modified Gross",
        label: "Modified Gross",
    },
    {
        value: "N",
        label: "N",
    },
    {
        value: "NN",
        label: "NN",
    },
    {
        value: "NNN",
        label: "NNN",
    },
    {
        value: "Absolute NNN",
        label: "Absolute NNN",
    },
    // {
    //   value: 'New',
    //   label: 'New',
    // },
    // {
    //   value: 'Renewal',
    //   label: 'Renewal',
    // },
];
