import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { specialtyTypeFilterOptions } from "../property/constants";
import BSSelect from "../../../common/js/components/BSSelect";
import Divider from "@material-ui/core/Divider";
import Today from "@material-ui/icons/Today";
import { get, debounce } from "lodash";
import { round } from "../utils/operations";
import FormTitle from "../../../common/js/components/FormTitle";
import Autocomplete from "../../../common/js/components/Autocomplete";
import AutocompleteFreeform from "../../../common/js/components/AutocompleteFreeform";
import Search from "@material-ui/icons/Search";
import orderBy from "lodash/orderBy";
import Button from "@material-ui/core/Button";
import { Add } from "../../../common/js/icons";
import { withStyles } from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import isNil from "lodash/isNil";
import includes from "lodash/includes";
import searchConstants from "../search/constants";
import PublicSiteDisplaySettings from "./PublicSiteDisplaySettings";

class ListingForm extends React.Component {
    handleListingAttributeChange = (field, value) => {
        this.props.handleInputChange("listing.attributes." + field, value);
    };

    handleListingChange = (field, value) => {
        this.props.handleInputChange("listing." + field, value);
    };

    handleDateChangewFlg = (field, value) => {
        this.props.handleInputChange("listing." + field, value);
        if (field === "autoRenewFlg" && value === true) {
            this.props.handleInputChange("listing.expirationDate", null);
        }
        if (field === "nonKmListingFlg" && value === true) {
            this.props.handleInputChange("listing.dateListed", null);
        }
    };

    handleLotSizeAutoCalc = (field, value) => {
        switch (field) {
            case "lotAcres":
                if (isNaN(value)) {
                    this.props.handleInputChange("listing.attributes.lotSf", "");
                    this.props.handleInputChange("listing.attributes.lotAcres", "");
                } else {
                    const lotSf = value * 43560;
                    this.props.handleInputChange("listing.attributes.lotAcres", value);
                    this.props.handleInputChange("listing.attributes.lotSf", lotSf);
                }
                break;
            case "lotSf":
                if (isNaN(value)) {
                    this.props.handleInputChange("listing.attributes.lotSf", "");
                    this.props.handleInputChange("listing.attributes.lotAcres", "");
                } else {
                    const lotAcres = round(value / 43560, 2);
                    this.props.handleInputChange("listing.attributes.lotSf", value);
                    this.props.handleInputChange("listing.attributes.lotAcres", lotAcres);
                }
                break;
            default:
                break;
        }
    };

    handleKmBrokerPersonSelect = (broker, index) => {
        //(arrayPath, index, field, value)
        this.props.handleUpdateArrayElement(
            "listing.listingBrokers.kmListingBrokers",
            index,
            "brokerAgentKey.person.firstName",
            broker.firstName
        );
        this.props.handleUpdateArrayElement(
            "listing.listingBrokers.kmListingBrokers",
            index,
            "brokerAgentKey.person.lastName",
            broker.lastName
        );
        this.props.handleUpdateArrayElement(
            "listing.listingBrokers.kmListingBrokers",
            index,
            "brokerAgentKey.brokerAgentKey",
            broker.brokerAgentKey
        );
    };

    handleOutsideBrokerPersonSelect = (broker, index) => {
        //(arrayPath, index, field, value)
        this.props.handleUpdateArrayElement(
            "listing.listingBrokers.outsideListingBrokers",
            index,
            "brokerAgentKey.person.firstName",
            broker.firstName
        );
        this.props.handleUpdateArrayElement(
            "listing.listingBrokers.outsideListingBrokers",
            index,
            "brokerAgentKey.person.lastName",
            broker.lastName
        );
        this.props.handleUpdateArrayElement(
            "listing.listingBrokers.outsideListingBrokers",
            index,
            "brokerAgentKey.brokerAgentKey",
            broker.brokerAgentKey
        );
    };

    handleOutsideBrokerCompanySelect = (company, index) => {
        //(arrayPath, index, field, value)
        this.props.handleUpdateArrayElement("listing.listingBrokers.outsideListingBrokers", index, "company", company);
    };

    handleOutsideBrokerPersonFreeform = (value, index) => {
        //(arrayPath, index, field, value)
        this.props.handleUpdateArrayElement(
            "listing.listingBrokers.outsideListingBrokers",
            index,
            "brokerAgentKey.person.firstName",
            value
        );
        this.props.handleUpdateArrayElement(
            "listing.listingBrokers.outsideListingBrokers",
            index,
            "brokerAgentKey.brokerAgentKey",
            null
        );
    };

    handleCompanyFreeform = (value, index) => {
        //(arrayPath, index, field, value)
        this.props.handleUpdateArrayElement("listing.listingBrokers.outsideListingBrokers", index, "company", {
            displayName: value,
            companyKey: null,
            companyTypes: ["REAL_ESTATE"],
            approvedFlg: false,
        });
    };

    getCompanyFieldLabel = (field, companyType) => {
        let labelText = "";
        let buyerTenantText = companyType === "SELLER_LANDLORD" ? "Landlord" : "Tenant";
        if (this.props.transactionType === "Sale") {
            buyerTenantText = companyType === "SELLER_LANDLORD" ? "Seller" : "Buyer";
        }

        switch (field) {
            case "legalName":
                labelText = buyerTenantText + " Name (Legal)*";
                break;
            case "displayName":
                labelText = buyerTenantText + " Name (Actual Owner)*";
                break;
            case "parentCompany":
                labelText = buyerTenantText + " Name (Parent Company/Institutional Owner)";
                break;
            default:
                break;
        }
        return labelText;
    };

    debounceSearchCompany = debounce((handleSearchCompany, term) => {
        handleSearchCompany(term, "REAL_ESTATE");
    }, searchConstants["DEBOUNCE_SEARCH_DELAY"]);

    getCompanyPlaceholderText = (field, companyType) => {
        let placeholderText = "";
        let buyerTenantText = companyType === "SELLER_LANDLORD" ? "landlord" : "tenant";
        if (this.props.transactionType === "Sale") {
            buyerTenantText = companyType === "SELLER_LANDLORD" ? "building owner" : "buyer";
        }

        switch (field) {
            case "legalName":
                placeholderText = "Enter " + buyerTenantText + " name (legal)";
                break;
            case "displayName":
                placeholderText = "Enter " + buyerTenantText + " name (actual)";
                break;
            case "parentCompany":
                placeholderText = "Enter " + buyerTenantText + " name (parent company/institutional owner)";
                break;
            default:
                break;
        }
        return placeholderText;
    };

    updateDivisible = (e, val) => {
        if (!val) {
            this.handleListingChange("sfDivisibleTo", null);
        }
        this.handleListingChange(e, val);
    };

    render() {
        const {
            listing,
            errors,
            disableInputs,
            classes,
            handleSearchKMBrokers,
            handleSearchCompany,
            handleSearchOutsideBrokers,
            autocompleteResults,
            handleAddArrayElement,
            handleDeleteArrayElement,
            isOutside,
            showListingExpiration,
            suppressRequired,
            compStatus,
        } = this.props;

        let attributes = get(listing, "attributes", {});
        if (attributes == null) {
            attributes = {};
        }

        const department = (suggestion) => {
            if (
                get(suggestion, "department", null) !== null &&
                get(suggestion, "department", null) !== "" &&
                departmentNames[get(suggestion, "department", "N/A")] !== undefined
            ) {
                return departmentNames[get(suggestion, "department", "N/A")]["display"];
            } else {
                return departmentNames["N/A"]["display"];
            }
        };

        const departmentNames = searchConstants["departments"];
        const displayRequired = !(suppressRequired === true);

        return (
            <div>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(listing, "subleaseFlg", false) ? true : false}
                                        onChange={(e, checked) => this.handleListingChange(e.target.value, checked)}
                                        value="subleaseFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Sublease"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(listing, "autoRenewFlg", false) ? true : false}
                                        onChange={(e, checked) => this.handleDateChangewFlg(e.target.value, checked)}
                                        value="autoRenewFlg"
                                        disabled={disableInputs === true || !showListingExpiration}
                                        color="primary"
                                    />
                                }
                                label="Auto Renew Listing"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(listing, "nonKmListingFlg", false) ? true : false}
                                        onChange={(e, checked) => this.handleDateChangewFlg(e.target.value, checked)}
                                        value="nonKmListingFlg"
                                        disabled={disableInputs === true || get(listing, "status", "Draft") !== "Draft"}
                                        color="primary"
                                    />
                                }
                                label="Non KM Listing"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(listing, "binswangerDealFlg", false) ? true : false}
                                        onChange={(e, checked) => this.handleListingChange(e.target.value, checked)}
                                        value="binswangerDealFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Binswanger Deal"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(listing, "jdsDealFlg", false) ? true : false}
                                        onChange={(e, checked) => this.handleListingChange(e.target.value, checked)}
                                        value="jdsDealFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="JDS Deal"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(listing, "attributes.excessSurplusLandFlg", false)}
                                        onChange={(e, checked) =>
                                            this.handleListingAttributeChange(
                                                e.target.value,
                                                !get(listing, "attributes.excessSurplusLandFlg", false)
                                            )
                                        }
                                        value="excessSurplusLandFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Excess/Surplus Land"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(listing, "attributes.ownerOccupiedFlg", false) ? true : false}
                                        onChange={(e, checked) =>
                                            this.handleListingAttributeChange(e.target.value, checked)
                                        }
                                        value="ownerOccupiedFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Owner Occupied"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            get(listing, "attributes.retailInvestmentNNNFlg", false) ? true : false
                                        }
                                        onChange={(e, checked) =>
                                            this.handleListingAttributeChange(e.target.value, checked)
                                        }
                                        value="retailInvestmentNNNFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Retail Investment NNN"
                            />
                        </FormGroup>
                    </Grid>

                    {!includes(["For Lease", "LEASE", "Lease"], get(listing, "listingType")) && (
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={get(listing, "attributes.investmentPropertyFlg", true)}
                                            onChange={(e) =>
                                                this.handleListingAttributeChange(
                                                    e.target.value,
                                                    !get(listing, "attributes.investmentPropertyFlg", false)
                                                )
                                            }
                                            value="investmentPropertyFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Investment Property Sale"
                                />
                            </FormGroup>
                        </Grid>
                    )}

                    {includes(["For Lease", "LEASE", "Lease"], get(listing, "listingType")) && (
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                get(listing, "availabilityStatus", false) === "Occupied" ||
                                                get(listing, "availabilityStatus", false) === null
                                                    ? false
                                                    : true
                                            }
                                            onChange={(e, checked) => {
                                                this.handleListingChange(
                                                    e.target.value,
                                                    checked ? (checked = "Vacant") : (checked = "Occupied")
                                                );
                                            }}
                                            value="availabilityStatus"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Vacant"
                                />
                            </FormGroup>
                        </Grid>
                    )}
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    {!includes(["For Lease", "LEASE", "Lease"], get(listing, "listingType")) && (
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                get(listing, "availabilityStatus", false) === "Occupied" ||
                                                get(listing, "availabilityStatus", false) === null
                                                    ? false
                                                    : true
                                            }
                                            onChange={(e, checked) => {
                                                this.handleListingChange(
                                                    e.target.value,
                                                    checked ? (checked = "Vacant") : (checked = "Occupied")
                                                );
                                            }}
                                            value="availabilityStatus"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Vacant"
                                />
                            </FormGroup>
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(listing, "importedFlg", false) ? true : false}
                                        onChange={(e, checked) => {
                                            this.handleListingChange(e.target.value, checked);
                                        }}
                                        value="importedFlg"
                                        disabled={true}
                                        color="primary"
                                    />
                                }
                                label="Imported"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                {/* Public Site Display Settings */}
                {!listing.nonKmListingFlg && (
                    <PublicSiteDisplaySettings
                        handleListingChange={this.handleListingChange}
                        listing={listing}
                        disableInputs={disableInputs}
                    />
                )}
                <Grid container spacing={8}>
                    <Grid item>&nbsp;</Grid>
                </Grid>
                {displayRequired && (
                    <Grid container alignItems="center" spacing={8}>
                        <BSInput
                            id="listingName"
                            label="Listing Name*"
                            helperText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                            errorText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                            error={
                                !includes(["Outside Draft", "Outside"], compStatus) ? get(errors, "listingName") : ""
                            }
                            value={listing.listingName}
                            onChange={(e) => this.handleListingChange(e.target.id, e.target.value)}
                            placeholder="Enter listing name"
                            disabled={disableInputs}
                        />
                    </Grid>
                )}

                <Grid container alignItems="flex-start" spacing={8}>
                    {attributes.useType !== "Religious" && (
                        <BSSelect
                            id="specialtyType"
                            label="Sub-Type*"
                            helperText="Required"
                            errorText="Required"
                            error={get(errors, "attributes.specialtyType")}
                            value={attributes.specialtyType}
                            onChange={(e) => this.handleListingAttributeChange(e.target.id, e.target.value)}
                            options={specialtyTypeFilterOptions(attributes.useType)}
                            disabled={disableInputs}
                        />
                    )}
                </Grid>

                <FormTitle display="inline-block" title="KM Listing Broker" />
                <small style={{ marginLeft: "6px", fontSize: "15px", color: "#E53935" }}>
                    {" "}
                    (Either a KM Broker or an Outside Broker must be added)
                </small>
                {orderBy(get(listing, "listingBrokers.kmListingBrokers"), "brokerAgentKey.kmBrokerFlag", "desc").map(
                    (broker, index) => {
                        return (
                            <Grid container alignItems="flex-start" spacing={8} style={{ marginBottom: "16px" }}>
                                <Grid item xs={11}>
                                    <Autocomplete
                                        id="firstName"
                                        label={index === 0 ? "Km Broker" : ""}
                                        initialInputValue={`${get(broker, `brokerAgentKey.person.firstName`, "")}${
                                            get(broker, `brokerAgentKey.person`, null) ? " " : ""
                                        }${get(broker, `brokerAgentKey.person.lastName`, "")}`}
                                        itemToString={(i) => get(i, `firstName`, null)}
                                        onSelect={(broker) => this.handleKmBrokerPersonSelect(broker, index)}
                                        placeholder="Enter broker"
                                        searchFunction={handleSearchKMBrokers}
                                        startAdornment={<Search />}
                                        suggestions={autocompleteResults}
                                        disabled={disableInputs}
                                        options={{
                                            key: "brokerAgentKey",
                                            display: (suggestion) => {
                                                let brokerName =
                                                    get(suggestion, "firstName", "") +
                                                    " " +
                                                    get(suggestion, "lastName", "");
                                                let departmentType = department(suggestion);
                                                let brokerOffice = get(suggestion, "officeName", "");
                                                if (brokerOffice === null) brokerOffice = "N/A";
                                                return brokerName + " - " + departmentType + " - " + brokerOffice;
                                            },
                                        }}
                                    />
                                </Grid>
                                {!disableInputs && (
                                    <Grid
                                        item
                                        xs={1}
                                        className={classes.centerCell}
                                        style={index === 0 ? { marginTop: "29px" } : { marginTop: "9px" }}
                                    >
                                        <Button
                                            className={classes.shrinkButton}
                                            onClick={() =>
                                                handleDeleteArrayElement(
                                                    "listing.listingBrokers.kmListingBrokers",
                                                    index
                                                )
                                            }
                                        >
                                            <Delete />
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        );
                    }
                )}
                {!disableInputs && (
                    <Button
                        className={classes.addRow}
                        color="primary"
                        onClick={() =>
                            handleAddArrayElement("listingBrokers.kmListingBrokers", {
                                listingKey: listing.listingKey,
                                brokerAgentKey: { kmBrokerFlag: true },
                            })
                        }
                    >
                        <Add fill="#4b94c1" className={classes.icon} />
                        Add KM Listing Broker
                    </Button>
                )}

                <FormTitle title="Outside Listing Broker" />
                {orderBy(
                    get(listing, "listingBrokers.outsideListingBrokers"),
                    "brokerAgentKey.kmBrokerFlag",
                    "desc"
                ).map((broker, index) => {
                    return (
                        <Grid
                            container
                            alignItems="flex-start"
                            spacing={8}
                            style={
                                index === 0
                                    ? { marginBottom: "16px", height: "85px" }
                                    : { marginBottom: "16px", height: "63px" }
                            }
                        >
                            <Grid item xs={4}>
                                <AutocompleteFreeform
                                    id="company"
                                    label={index === 0 ? "Company Name (Common)" : ""}
                                    error={get(errors, `listingBrokers.outside[${index}]['company.displayName']`, "")}
                                    errorText="Required"
                                    initialInputValue={get(broker, `company.displayName`, "")}
                                    itemToString={(i) => get(i, `displayName`, null)}
                                    onSelect={(company) => this.handleOutsideBrokerCompanySelect(company, index)}
                                    placeholder="Enter company"
                                    searchFunction={(term) => this.debounceSearchCompany(handleSearchCompany, term)}
                                    startAdornment={<Search />}
                                    suggestions={autocompleteResults}
                                    freeformUpdate={(value) => this.handleCompanyFreeform(value, index)}
                                    disabled={disableInputs || !isNil(get(broker, "company.companyKey"))}
                                    options={{
                                        key: "companyKey",
                                        display: (suggestion) => {
                                            return get(suggestion, "displayName", "");
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AutocompleteFreeform
                                    id="firstName"
                                    label={index === 0 ? "First Name" : ""}
                                    error={get(
                                        errors,
                                        `listingBrokers.outside[${index}]['brokerAgentKey.person.firstName']`,
                                        ""
                                    )}
                                    errorText="Required"
                                    initialInputValue={get(broker, `brokerAgentKey.person.firstName`, "")}
                                    itemToString={(i) => get(i, `firstName`, null)}
                                    onSelect={(broker) => this.handleOutsideBrokerPersonSelect(broker, index)}
                                    placeholder="Enter broker"
                                    searchFunction={handleSearchOutsideBrokers}
                                    startAdornment={<Search />}
                                    suggestions={autocompleteResults}
                                    freeformUpdate={(value) => this.handleOutsideBrokerPersonFreeform(value, index)}
                                    disabled={disableInputs || !isNil(get(broker, "brokerAgentKey.brokerAgentKey"))}
                                    options={{
                                        key: "brokerAgentKey",
                                        display: (suggestion) => {
                                            return (
                                                get(suggestion, "firstName", "") + " " + get(suggestion, "lastName", "")
                                            );
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <BSInput
                                    id="lastName"
                                    label={index === 0 ? "Last Name" : null}
                                    error={get(
                                        errors,
                                        `listingBrokers.outside[${index}]['brokerAgentKey.person.lastName']`,
                                        ""
                                    )}
                                    errorText="Required"
                                    value={get(broker, `brokerAgentKey.person.lastName`, "")}
                                    onChange={(e) =>
                                        this.props.handleUpdateArrayElement(
                                            "listing.listingBrokers.outsideListingBrokers",
                                            index,
                                            "brokerAgentKey.person.lastName",
                                            e.target.value
                                        )
                                    }
                                    placeholder="Enter last name"
                                    disabled={disableInputs || !isNil(get(broker, "brokerAgentKey.brokerAgentKey"))}
                                    extraInputStyles={index === 0 ? {} : { marginTop: "0px" }}
                                />
                            </Grid>
                            {!disableInputs && (
                                <Grid
                                    item
                                    xs={1}
                                    className={classes.centerCell}
                                    style={index === 0 ? {} : { marginTop: "9px" }}
                                >
                                    <Button
                                        className={classes.shrinkButton}
                                        onClick={() =>
                                            handleDeleteArrayElement(
                                                "listing.listingBrokers.outsideListingBrokers",
                                                index
                                            )
                                        }
                                    >
                                        <Delete />
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    );
                })}
                {!disableInputs && (
                    <Button
                        className={classes.addRow}
                        color="primary"
                        onClick={() =>
                            handleAddArrayElement("listingBrokers.outsideListingBrokers", {
                                listingKey: listing.listingKey,
                                brokerAgentKey: { kmBrokerFlag: false },
                            })
                        }
                    >
                        <Add fill="#4b94c1" className={classes.icon} />
                        Add Outside Listing Broker
                    </Button>
                )}
                <Divider className={classes.dividerMargin} />

                {isOutside ? (
                    <Grid container alignItems="center" spacing={8}>
                        <Grid item xs={6}>
                            <BSInput
                                id="dateAvailable"
                                label="Date Space is Available"
                                error={get(errors, "dateAvailable")}
                                value={listing.dateAvailable}
                                onChange={(e) => this.handleListingChange(e.target.id, e.target.value)}
                                placeholder="Enter date available"
                                type="date"
                                startAdornment={<Today />}
                                disabled={disableInputs}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSInput
                                id="leaseExpirationDate"
                                label="Master Lease Expiration Date (Sublease)"
                                error={get(errors, "leaseExpirationDate")}
                                value={listing.leaseExpirationDate}
                                onChange={(e) => this.handleListingChange(e.target.id, e.target.value)}
                                placeholder="Enter master lease expiration date"
                                type="date"
                                startAdornment={<Today />}
                                disabled={disableInputs}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container alignItems="flex-start" spacing={8}>
                        {showListingExpiration && (
                            <Grid item xs={6}>
                                <BSInput
                                    id="dateListed"
                                    label="Date to Post on Kidder.com"
                                    toolTipText='This is the date your listing will be posted on kidder.com<br />
                                    as well as the date your listing will be searchable in KM Connect.<br />
                                    Checking "Opt Out: kidder.com" will hide the listing from kidder.com and KMConnect search'
                                    error={get(errors, "dateListed")}
                                    helperText={
                                        get(listing, "attributes.optOutFlg", false)
                                            ? `Opt out of kidder.com selected.`
                                            : null
                                    }
                                    value={listing.dateListed}
                                    onChange={(e) => this.handleListingChange(e.target.id, e.target.value)}
                                    placeholder="Enter date listed"
                                    type="date"
                                    startAdornment={<Today />}
                                    disabled={
                                        disableInputs ||
                                        listing.nonKmListingFlg === true ||
                                        get(listing, "attributes.optOutFlg", false) === true
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <BSInput
                                id="dateAvailable"
                                label="Date Space is Available"
                                error={get(errors, "dateAvailable")}
                                value={listing.dateAvailable}
                                onChange={(e) => this.handleListingChange(e.target.id, e.target.value)}
                                placeholder="Enter date available"
                                type="date"
                                startAdornment={<Today />}
                                disabled={disableInputs}
                            />
                        </Grid>

                        {showListingExpiration && (
                            <Grid item xs={6}>
                                <BSInput
                                    id="expirationDate"
                                    label={displayRequired ? "Listing Expiration Date*" : "Listing Expiration Date"}
                                    helperText={displayRequired ? "Required if Auto Renew not selected" : ""}
                                    errorText="Required if Auto Renew not selected"
                                    error={get(errors, "expirationDate")}
                                    value={listing.expirationDate}
                                    onChange={(e) => this.handleListingChange(e.target.id, e.target.value)}
                                    placeholder="Enter expiration date"
                                    type="date"
                                    startAdornment={<Today />}
                                    disabled={disableInputs || listing.autoRenewFlg === true}
                                />
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <BSInput
                                id="leaseExpirationDate"
                                label="Master Lease Expiration Date (Sublease)"
                                error={get(errors, "leaseExpirationDate")}
                                value={listing.leaseExpirationDate}
                                onChange={(e) => this.handleListingChange(e.target.id, e.target.value)}
                                placeholder="Enter master lease expiration date"
                                type="date"
                                startAdornment={<Today />}
                                disabled={disableInputs}
                            />
                        </Grid>
                    </Grid>
                )}

                {/* seller company fields start here */}
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="sellerLandlordLegalCompanyName"
                            label={this.getCompanyFieldLabel("legalName", "SELLER_LANDLORD")}
                            error={get(errors, "sellerLandlordLegalCompanyName", null)}
                            errorText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                            helperText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                            value={get(listing, "sellerLandlordLegalCompanyName", null)}
                            onChange={(event) =>
                                this.handleListingChange("sellerLandlordLegalCompanyName", event.target.value)
                            }
                            disabled={disableInputs === true}
                            placeholder={this.getCompanyPlaceholderText("legalName", "SELLER_LANDLORD")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="sellerLandlordCommonCompanyName"
                            label={this.getCompanyFieldLabel("displayName", "SELLER_LANDLORD")}
                            error={get(errors, "sellerLandlordCommonCompanyName", null)}
                            errorText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                            helperText={!includes(["Outside Draft", "Outside"], compStatus) ? "Required" : ""}
                            value={get(listing, "sellerLandlordCommonCompanyName", "")}
                            onChange={(event) =>
                                this.handleListingChange("sellerLandlordCommonCompanyName", event.target.value)
                            }
                            disabled={disableInputs === true}
                            placeholder={this.getCompanyPlaceholderText("displayName", "SELLER_LANDLORD")}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="sellerLandlordInstitutionalOwner"
                            label={this.getCompanyFieldLabel("parentCompany", "SELLER_LANDLORD")}
                            error={get(errors, "sellerLandlordInstitutionalOwner", null)}
                            value={get(listing, "sellerLandlordInstitutionalOwner", "")}
                            onChange={(event) =>
                                this.handleListingChange("sellerLandlordInstitutionalOwner", event.target.value)
                            }
                            disabled={disableInputs === true}
                            placeholder={this.getCompanyPlaceholderText("parentCompany", "SELLER_LANDLORD")}
                        />
                    </Grid>
                </Grid>
                {/* seller company fields end here */}

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="numBldg"
                            label="BUILDING COUNT"
                            placeholder="Enter building count"
                            errorText="(ex. 98)"
                            error={get(errors, "attributes.numBldg")}
                            value={attributes.numBldg}
                            onChange={(e) => this.handleListingAttributeChange(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="buildingNumber"
                            label="BUILDING NUMBER"
                            placeholder="Enter building number"
                            errorText="(ex. 3)"
                            error={get(errors, "attributes.buildingNumber")}
                            value={attributes.buildingNumber}
                            onChange={(e) => this.handleListingAttributeChange(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="floor"
                            label="FLOORS"
                            placeholder="Enter floors"
                            error={get(errors, "attributes.floor")}
                            value={attributes.floor}
                            onChange={(e) => this.handleListingAttributeChange(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="suite"
                            label="SUITE"
                            placeholder="Enter suite"
                            error={get(errors, "attributes.suite")}
                            value={attributes.suite}
                            onChange={(e) => this.handleListingAttributeChange(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={6}>
                        <BSInput
                            id="lotAcres"
                            label="LOT SIZE (ACRES)"
                            placeholder="Enter land acreage"
                            errorText="(ex. 8.10)"
                            error={get(errors, "attributes.lotAcres")}
                            value={attributes.lotAcres}
                            type="number"
                            onChange={(e) => this.handleLotSizeAutoCalc(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BSInput
                            id="lotSf"
                            label="LOT SIZE (SF)"
                            placeholder="Enter SF"
                            errorText="(ex. 98101)"
                            error={get(errors, "attributes.lotSf")}
                            value={attributes.lotSf}
                            type="number"
                            onChange={(e) => this.handleLotSizeAutoCalc(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="amenities"
                            label="AMENITIES"
                            placeholder="Enter amenities"
                            error={get(errors, "attributes.amenities")}
                            value={attributes.amenities}
                            onChange={(e) => this.handleListingAttributeChange(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="websiteUrl"
                            label="WEBSITE URL"
                            placeholder="Enter url"
                            error={get(errors, "websiteUrl")}
                            value={listing.websiteUrl}
                            onChange={(e) => this.handleListingChange(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="tourInstructions"
                            label="TOUR INSTRUCTIONS"
                            placeholder="Enter instructions"
                            errorPlaceholder="hideIt"
                            error={get(errors, "attributes.tourInstructions")}
                            value={attributes.tourInstructions}
                            onChange={(e) => this.handleListingAttributeChange(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={8} style={{ marginBottom: "15px" }}>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listing.callListingBrokerFlg ? true : false}
                                        onChange={(e, checked) => this.handleListingChange(e.target.value, checked)}
                                        value="callListingBrokerFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Call Listing Broker"
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listing.viewWithDiscretionFlg ? true : false}
                                        onChange={(e, checked) => this.handleListingChange(e.target.value, checked)}
                                        value="viewWithDiscretionFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="View with Discretion"
                            />
                        </FormGroup>
                    </Grid>
                    {listing.listingType === "Lease" && (
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={listing.isDivisible}
                                            onChange={(e, checked) => this.updateDivisible(e.target.value, checked)}
                                            value="isDivisible"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Space is Divisible"
                                />
                            </FormGroup>
                        </Grid>
                    )}
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={listing.listingType === "Lease" && listing.isDivisible ? 6 : 12}>
                        <BSInput
                            id="sfAvail"
                            type="number"
                            label={
                                displayRequired
                                    ? listing.listingType === "Lease"
                                        ? "SF Available*"
                                        : "Sale SF*"
                                    : listing.listingType === "Lease"
                                    ? "SF Available"
                                    : "Sale SF"
                            }
                            helperText={
                                includes(["Outside Draft", "Outside"], compStatus)
                                    ? ""
                                    : displayRequired
                                    ? "Required (ex. 98010)"
                                    : "(ex. 98010)"
                            }
                            errorText={includes(["Outside Draft", "Outside"], compStatus) ? "" : ""}
                            placeholder="Enter SF"
                            error={!includes(["Outside Draft", "Outside"], compStatus) ? get(errors, "sfAvail") : ""}
                            value={listing.sfAvail}
                            onChange={(e) => this.handleListingChange(e.target.id, e.target.value)}
                            disabled={disableInputs}
                        />
                    </Grid>
                    {listing.listingType === "Lease" && listing.isDivisible && (
                        <Grid item xs={6}>
                            <BSInput
                                id="sfDivisibleTo"
                                type="number"
                                label="SF Divisible"
                                helperText="Required (ex. 98010)"
                                errorText={includes(["Outside Draft", "Outside"], compStatus) ? "" : ""}
                                placeholder="Enter SF"
                                error={
                                    !includes(["Outside Draft", "Outside"], compStatus)
                                        ? get(errors, "sfDivisibleTo")
                                        : ""
                                }
                                value={listing.sfDivisibleTo}
                                onChange={(e) => this.handleListingChange(e.target.id, e.target.value)}
                                disabled={disableInputs}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
}

const styles = (theme) => ({
    addRow: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#2C99D2",
        "&:hover": {
            backgroundColor: "transparent",
        },
        marginBottom: "20px",
    },
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    centerCell: {
        textAlign: "center",
        fill: "#9e9e9e",
        marginTop: "30px",
    },
    dividerMargin: {
        marginTop: "10px",
        marginBottom: "30px",
    },
});

export default withStyles(styles)(ListingForm);
