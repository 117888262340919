import configs from "../../../config";

const HOST = configs.HOST_URL + "/";
const MEDIA_URL = HOST + "media/";
const VOUCHER_URL = HOST + "vouchers/";
const ADMIN_URL = HOST + "admin/";
const DEAL_URL = HOST + "deals/";
const CONSULTING_URL = DEAL_URL + "consulting/";

const urls = {
    save_voucher: (voucherId) => {
        return VOUCHER_URL + voucherId;
    },
    broker_vouchers_open: (brokerId) => {
        return VOUCHER_URL + "broker/open/" + brokerId;
    },
    broker_vouchers_closed: (brokerId) => {
        return VOUCHER_URL + "broker/closed/" + brokerId;
    },
    data_entry_vouchers_unassigned: () => {
        return VOUCHER_URL + "dataentry/unassigned";
    },
    data_entry_vouchers_open: (username) => {
        return VOUCHER_URL + "dataentry/open/" + username;
    },
    data_admin_vouchers_open: () => {
        return VOUCHER_URL + "admin/open";
    },
    data_admin_vouchers_closed: () => {
        return VOUCHER_URL + "admin/closed";
    },
    accounting_vouchers_open: () => {
        return VOUCHER_URL + "admin/closed";
    }, //todo accounting
    get_document: (documentKey) => {
        return VOUCHER_URL + "document/" + documentKey;
    },
    get_voucher_documents: (voucherKey) => {
        return VOUCHER_URL + "documents/" + voucherKey;
    },
    save_document: (voucherKey) => {
        return VOUCHER_URL + "document/" + voucherKey;
    },
    delete_document: (documentId) => {
        return VOUCHER_URL + "document/" + documentId;
    },
    delete_voucher: (voucherKey) => {
        return VOUCHER_URL + voucherKey;
    },
    update_voucher_document: (documentKey) => {
        return VOUCHER_URL + "document/" + documentKey;
    },
    get_voucher: (voucherKey) => {
        return VOUCHER_URL + voucherKey;
    },
    get_voucher_comments: (voucherKey) => {
        return VOUCHER_URL + "comment/" + voucherKey;
    },
    post_voucher_comment: (voucherKey) => {
        return VOUCHER_URL + "comment/" + voucherKey;
    },
    get_media_url: (mediaKey) => {
        return MEDIA_URL + mediaKey;
    },
    delete_commission: (commissionId) => {
        return VOUCHER_URL + "commission/" + commissionId;
    },
    find_brokers_with_name_starting_with: (input) => {
        return ADMIN_URL + "broker/search?term=" + input;
    },
    find_companies_with_name_starting_with: (input) => {
        return ADMIN_URL + "company/search?term=" + input;
    },
    create_company: () => {
        return ADMIN_URL + `company`;
    },
    broker_submit: (voucherId) => {
        return VOUCHER_URL + "workflow/submit/" + voucherId;
    },
    ready_for_payment: (voucherId) => {
        return VOUCHER_URL + "workflow/readyforpayment/" + voucherId;
    },
    assign_voucher_for_review: (voucherId) => {
        return VOUCHER_URL + "workflow/review/" + voucherId;
    },
    approve_voucher: (voucherId) => {
        return VOUCHER_URL + "workflow/approve/" + voucherId;
    },
    reassign_voucher_to_broker: (voucherId) => {
        return VOUCHER_URL + "workflow/reassigntobroker/" + voucherId;
    },
    reassign_voucher_to_dataentry: (voucherId) => {
        return VOUCHER_URL + "workflow/reassigntodataentry/" + voucherId;
    },
    portland_approval: (voucherId) => {
        return VOUCHER_URL + "workflow/portlandapproval/" + voucherId;
    },
    arizona_approval: (voucherId) => {
        return VOUCHER_URL + "workflow/arizonaapproval/" + voucherId;
    },
    create_voucher: () => {
        return VOUCHER_URL;
    },
    delete_deal: (dealKey) => {
        return DEAL_URL + "delete/" + dealKey;
    },
    update_consulting_deal_property: (dealKey, propertyKey) => {
        return CONSULTING_URL + dealKey + "/" + propertyKey;
    },
};

export const disabledStatuses = {
    broker: {
        Draft: false,
        "In Review": true,
        "Broker Action Required": false,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": false,
        "Final Review": true,
    },
    evp: {
        Draft: false,
        "In Review": true,
        "Broker Action Required": false,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": false,
        "Final Review": true,
    },
    portlandBrokerAdmin: {
        Draft: false,
        "Portland Review Required": false,
        "In Review": true,
        "Broker Action Required": false,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": false,
        "Final Review": true,
    },
    arizonaBrokerAdmin: {
        Draft: false,
        "AZ Review Required": false,
        "In Review": true,
        "Broker Action Required": false,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": false,
        "Final Review": true,
    },
    dataEntry: {
        Draft: true,
        "In Review": false,
        "Broker Action Required": true,
        "Awaiting Payment": false,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": false,
        "Final Review": true,
    },
    dataAdmin: {
        Draft: true,
        "In Review": true,
        "Broker Action Required": true,
        "Awaiting Payment": false,
        "Ready For Accounting": true,
        Closed: true,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": false,
        "Final Review": false,
    },
    accounting: {
        Draft: true,
        "In Review": true,
        "Broker Action Required": true,
        "Awaiting Payment": false,
        "Ready For Accounting": false,
        Closed: false,
        "Admin Action Required": true,
        "Needs Review": true,
        "Awaiting Comp": false,
        "Final Review": true,
    },
};

export const documentDisabledStatuses = {
    ...disabledStatuses,
    broker: {
        ...disabledStatuses.broker,
        "Awaiting Payment": false,
    },
};

export const representation_type_options = [
    {
        value: "",
        label: "Representation",
    },
    {
        value: "TENANT",
        label: "Tenant",
    },
    {
        value: "LANDLORD",
        label: "Landlord",
    },
    {
        value: "LANDLORD_TENANT",
        label: "Landlord/Tenant",
    },
    {
        value: "BUYER",
        label: "Buyer",
    },
    {
        value: "BUYER_OWNER",
        label: "Buyer/Seller",
    },
    {
        value: "SELLER",
        label: "Seller",
    },
    {
        value: "OTHER",
        label: "Other",
    },
];

export default {
    urls,
    representation_type_options,
};
