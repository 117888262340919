import types from "./types";

const showDeleteDialog = (isOpen) => {
    alert("isopen=" + isOpen);
    return {
        type: types.UPDATE_DELETE_DIALOG,
        open: isOpen,
    };
};

const getVoucher = (voucher) => {
    return {
        type: types.GET_VOUCHER,
        voucher: voucher,
    };
};

const setVoucherId = (id) => {
    return {
        type: types.SET_VOUCHER_ID,
        voucher: id,
    };
};

const addBrokerCommission = (draft) => {
    return {
        type: types.ADD_BROKER_COMMISSION,
        draft: draft,
        key: Object.keys(draft)[0],
    };
};

const saveOutsideSplit = (key, split) => {
    return {
        type: types.SAVE_OUTSIDE_SPLIT,
        key: key,
        split: split,
    };
};

const deleteOutsideSplit = (newSplits) => {
    return {
        type: types.DELETE_OUTSIDE_SPLIT,
        newSplits: newSplits,
    };
};

const addRunner = (parent, runner) => {
    return {
        type: types.ADD_RUNNER,
        runner: runner,
        parent: parent,
        key: Object.keys(runner)[0],
    };
};

const updateSplit = (splitKey, field, value, source) => {
    return {
        type: types.UPDATE_SPLIT,
        value: value,
        key: splitKey,
        field: field,
        source: source,
        path: source + "." + splitKey + "." + field,
    };
};

const updateRunner = (parent, key, field, value) => {
    return {
        type: types.UPDATE_RUNNER,
        parent: parent,
        key: key,
        field: field,
        value: value,
    };
};

const removeCommission = (newCommissions) => {
    return {
        type: types.REMOVE_COMMISSION,
        newCommissions: newCommissions,
    };
};

const removeRunner = (newCommissions) => {
    return {
        type: types.REMOVE_RUNNER,
        newCommissions: newCommissions,
    };
};

const toggleOutsideSplitModal = (
    open,
    commissionKey,
    split,
    secondaryAction,
    isFreeformSearchEnabled,
    commissionType
) => {
    return {
        type: types.TOGGLE_OUTSIDE_SPLIT_MODAL,
        open: open,
        key: commissionKey,
        split: split,
        secondaryAction: secondaryAction,
        isFreeformSearchEnabled: isFreeformSearchEnabled,
        commissionType: commissionType,
    };
};

const voucherChange = (field, value) => {
    return {
        type: types.VOUCHER_CHANGE,
        field: field,
        value: value,
    };
};

const addError = (field, error) => {
    return {
        type: types.ADD_VOUCHER_ERROR,
        field: field,
        error: error,
    };
};

const clearErrors = () => {
    return {
        type: types.CLEAR_VOUCHER_ERRORS,
    };
};

const clearModalErrors = () => {
    return {
        type: types.CLEAR_VOUCHER_MODAL_ERRORS,
    };
};

const validVoucher = (valid) => {
    return {
        type: types.VALID_VOUCHER,
        valid: valid,
    };
};

const determineOutsidePresent = (isPresent) => {
    return {
        type: types.DETERMINE_OUTSIDE_SPLIT_PRESENT,
        isPresent: isPresent,
    };
};

const searchResults = (results) => {
    return {
        type: types.SEARCH_RESULTS,
        results: results,
    };
};

const validDocuments = (valid) => {
    return {
        type: types.VALID_DOCUMENTS,
        valid: valid,
    };
};

export const voucherSnackbar = (open, variant, message) => {
    return {
        type: types.VOUCHER_SNACKBAR,
        open: open,
        message: message,
        variant: variant,
    };
};

const voucherInput = (path, value) => {
    return {
        type: types.VOUCHER_INPUT,
        path: path,
        value: value,
    };
};

const setArray = (pathToArray, array) => {
    return {
        type: types.SET_VOUCHER_ARRAY,
        pathToArray: pathToArray,
        array: array,
    };
};

const documentWarning = (open, proceed, docs, statusChangeCallback) => {
    return {
        type: types.VOUCHER_DOCUMENT_WARNING,
        open: open,
        proceed: proceed,
        docs: docs,
        callback: statusChangeCallback,
    };
};

const openModal = (isOpen) => {
    return {
        type: types.OPEN_MODAL,
        isOpen: isOpen,
    };
};

const closeModal = (obj) => {
    return {
        type: types.CLOSE_MODAL,
    };
};

const updateDeleteDealDialog = (isOpen) => {
    return {
        type: types.UPDATE_DELETE_DIALOG,
        open: isOpen,
    };
};

const updateNoDeleteDealDialog = (isOpen) => {
    return {
        type: types.UPDATE_NO_DELETE_DIALOG,
        open: isOpen,
    };
};

const updateConsultingRequest = (isOpen) => {
    return {
        type: types.UPDATE_NO_UPDATE_DIALOG,
        open: isOpen,
    };
};

const loadNewConsultingDealProperty = (property) => {
    return {
        type: types.UPDATE_PROPERTY,
        updatedProperty: property,
    };
};

const clearPropertyUpdate = () => {
    return {
        type: types.UPDATE_PROPERTY,
        updatedProperty: {},
    };
};

export default {
    getVoucher,
    setVoucherId,
    addBrokerCommission,
    saveOutsideSplit,
    deleteOutsideSplit,
    addRunner,
    removeCommission,
    removeRunner,
    updateSplit,
    updateRunner,
    toggleOutsideSplitModal,
    voucherChange,
    addError,
    clearErrors,
    clearModalErrors,
    validVoucher,
    validDocuments,
    determineOutsidePresent,
    searchResults,
    voucherSnackbar,
    voucherInput,
    setArray,
    documentWarning,
    showDeleteDialog,
    openModal,
    closeModal,
    updateDeleteDealDialog,
    updateNoDeleteDealDialog,
    updateConsultingRequest,
    loadNewConsultingDealProperty,
    clearPropertyUpdate,
};
