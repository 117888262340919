import validate from "validate.js";
import { constraints } from "../../utils/validation";

const licenseValidation = {
    licenseName: {
        ...constraints.required,
    },
    licenseNumber: {
        ...constraints.required,
    },
    licenseStateCode: {
        ...constraints.required,
    },
};

const exhibitAValidation = {
    exhibitAExpirationDate: {
        ...constraints.required,
        ...constraints.date,
    },
    exhibitAType: {
        ...constraints.required,
    },
    annualGrossMinimum: {
        ...constraints.required,
    },
    adjustedPercentage: {
        ...constraints.required,
    },
    baseSplitPercentage: {
        ...constraints.required,
    },
    exhibitAStartDate: {
        ...constraints.date,
    },
};

const companyValidation = {
    // companyType: {
    //     ...constraints.required
    // },
    legalName: {
        ...constraints.required,
    },
    displayName: {
        ...constraints.required,
    },
    companyTypes: {
        ...constraints.required,
    },
};

const userAccountValidation = {
    role: {
        ...constraints.required,
    },
};

const userProxy = {
    proxyFor: {
        ...constraints.required,
    },
};
const validateLicenseDetail = (values) => {
    return validate(values, licenseValidation, { fullMessages: false });
};

const validateExhibitADetail = (values) => {
    return validate(values, exhibitAValidation, { fullMessages: false });
};

const validateUserAccountDetail = (values) => {
    return validate(values, userAccountValidation, { fullMessages: false });
};
const validateUserProxyDetail = (values) => {
    return validate(values, userProxy, { fullMessages: false });
};

const validateCompanyDetail = (values) => {
    return validate(values, companyValidation, { fullMessages: false });
};

export default {
    validateLicenseDetail,
    validateExhibitADetail,
    validateUserAccountDetail,
    validateUserProxyDetail,
    validateCompanyDetail,
};
