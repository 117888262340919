import types from "./types";
import cloneDeep from "lodash/cloneDeep";
import setWith from "lodash/setWith";

export default (
    state = {
        contacts: [],
        contact: {
            reminders: [],
        },
    },
    action
) => {
    switch (action.type) {
        case types.LOAD_CONTACTS_FOR_USER:
            return {
                ...state,
                contacts: action.contacts,
            };
        case types.LOAD_CONTACT:
            return {
                ...state,
                contact: action.contact,
            };
        case types.FILTER_CRM_CONTACTS:
            return Object.assign({}, state, {
                filterQuery: action.filterQuery,
            });
        case types.SORT_CRM_CONTACTS:
            return Object.assign({}, state, {
                columnToSort: action.columnToSort,
                sortDirection: action.sortDirection,
            });
        case types.ADD_CRM_CONTACT_INPUT:
            return setWith(cloneDeep(state), `${action.field}`, action.value, Object);
        case types.SET_CRM_ARRAY:
            return setWith(cloneDeep(state), `${action.pathToArray}`, action.array, Object);
        default:
            return state;
    }
};
