export const marketRegions = [
    {
        value: "1303 - BLDG ENG",
        label: "1303 - BLDG ENG",
    },
    {
        value: "1305 - BLDG ENG Tukwila",
        label: "1305 - BLDG ENG Tukwila",
    },
    {
        value: "1306 - BLDG ENG Tacoma",
        label: "1306 - BLDG ENG Tacoma",
    },
    {
        value: "1307 - BLDG ENG Seattle",
        label: "1307 - BLDG ENG Seattle",
    },
    {
        value: "1309 - BLDG ENG Bellevue",
        label: "1309 - BLDG ENG Bellevue",
    },
    {
        value: "1318 - Seattle Engineering",
        label: "1318 - Seattle Engineering",
    },
    {
        value: "1328 - Portland Engineering",
        label: "1328 - Portland Engineering",
    },
    {
        value: "1348 - Arizona Engineering",
        label: "1348 - Arizona Engineering",
    },
    {
        value: "1358 - Nevada Engineering",
        label: "1358 - Nevada Engineering",
    },
    {
        value: "1501 - Reno",
        label: "1501 - Reno",
    },
    {
        value: "1505 - Tukwila",
        label: "1505 - Tukwila",
    },
    {
        value: "1506 - Tacoma",
        label: "1506 - Tacoma",
    },
    {
        value: "1507 - Seattle",
        label: "1507 - Seattle",
    },
    {
        value: "1508 - Portland",
        label: "1508 - Portland",
    },
    {
        value: "1509 - Bellevue",
        label: "1509 - Bellevue",
    },
    {
        value: "1510 - Phoenix",
        label: "1510 - Phoenix",
    },
    {
        value: "2368 - Bay Area Engineering",
        label: "2368 - Bay Area Engineering",
    },
    {
        value: "2369 - Sacramento Engineering",
        label: "2369 - Sacramento Engineering",
    },
    {
        value: "2378 - San Diego Engineering",
        label: "2378 - San Diego Engineering",
    },
    {
        value: "2388 - Orange County Engineering",
        label: "2388 - Orange County Engineering",
    },
    {
        value: "2398 - LA Engineering",
        label: "2398 - LA Engineering",
    },
    {
        value: "2501 - NorCal",
        label: "2501 - NorCal",
    },
    {
        value: "2502 - San Diego",
        label: "2502 - San Diego",
    },
    {
        value: "2503 - Irvine/OC",
        label: "2503 - Irvine/OC",
    },
    {
        value: "2511 - Sacramento",
        label: "2511 - Sacramento",
    },
    {
        value: "2512 - Pasadena",
        label: "2512 - Pasadena",
    },
    {
        value: "2513 - El Segundo",
        label: "2513 - El Segundo",
    },
    {
        value: "2521 - San Francisco Region",
        label: "2521 - San Francisco Region",
    },
    {
        value: "2523 - San Jose Region",
        label: "2523 - San Jose Region",
    },
    {
        value: "2524 - Pleasanton Region",
        label: "2524 - Pleasanton Region",
    },
];
