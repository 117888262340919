export const accountingSoftwareSystemTypes = [
    {
        value: "Skyline",
        label: "Skyline",
    },
    {
        value: "MRI KM",
        label: "MRI KM",
    },
    {
        value: "MRI Client",
        label: "MRI Client",
    },
    {
        value: "Yardi KM",
        label: "Yardi KM",
    },
    {
        value: "Yardi Client",
        label: "Yardi Client",
    },
    {
        value: "Other",
        label: "Other",
    },

    {
        value: "N/A (Client System)",
        label: "N/A (Client System)",
    },
];
