import React, { Component } from "react";
import { connect } from "react-redux";
import operations from "./ducks/operations";
import DocumentDrop from "./DocumentDropComponent";
import constants from "./constants";
import documentTypes from "./documentTypes";
import CircularProgress from "@material-ui/core/CircularProgress";
import FullPageDocumentDropComponent from "./FullPageDocumentDropComponent";
import get from "lodash/get";

class DocumentDropContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
    }

    componentWillMount() {
        if (this.props.entityKey) {
            if (this.props.parentType) {
                this.props.getDocumentRequirementsByParentEntityType(this.props.parentType, this.props.entityKey);
            }
            this.props.getDocuments(this.props.entityKey, this.props.type);
        }
    }

    render() {
        const disableUploads = this.props.isBrokerAndClosedVoucher
            ? true
            : get(this.props.options, "canUpload", []).length > 0
            ? false
            : this.props.formDisabled;

        return (
            <div>
                {this.props.elementsSaving.getDocumentRequirements ? (
                    <CircularProgress style={{ left: "75%", top: "50%", position: "absolute" }} />
                ) : (
                    <React.Fragment>
                        <DocumentDrop
                            {...this.props}
                            disableUploads={disableUploads}
                            documentTypes={documentTypes[this.props.documentTypes]}
                            allDocumentTypes={documentTypes["all"]}
                        />
                        <FullPageDocumentDropComponent {...this.props} disableUploads={disableUploads} />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        role: state.authReducer.userProfile.role,
        snackbarOpen: state.authReducer.snackbarOpen,
        columnToSort: state.documentsReducer.documentColumnToSort,
        sortDirection: state.documentsReducer.documentSortDirection,
        used: state.documentsReducer.documentTypes,
        usedDocTypes: state.documentsReducer.usedDocTypes,
        savedDocuments: state.documentsReducer.savedDocuments,
        missingRequiredDocuments: state.documentsReducer.missingRequiredDocuments,
        anchorEl: state.documentsReducer.anchorEl,
        docKey: state.documentsReducer.docKey,
        loading: state.documentsReducer.loading,
        documentsUploading: state.documentsReducer.documentsUploading,
        elementsSaving: state.utilsReducer.elementsSaving,
        documentRequirements: state.documentsReducer.documentRequirements,
        parentEntityTypeKey: state.documentsReducer.parentEntityTypeKey,
        requiredDocs: state.documentsReducer.requiredDocs,
        optionalDocs: state.documentsReducer.optionalDocs,
        documentTypesForParentEntity: state.documentsReducer.documentTypesForParentEntity,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideSnackbar: () => {
            dispatch(operations.hideSnackbar());
        },
        handleDocumentSort: (columnName) => {
            dispatch(operations.handleDocumentSort(columnName));
        },
        handleRejectedDocs: (rejected) => {
            dispatch(operations.handleRejectedDocs(rejected));
        },
        handleOpenDocumentMenu: (key, anchorEl) => {
            dispatch(operations.handleOpenDocumentMenu(key, anchorEl));
        },
        handleMenuClose: () => {
            dispatch(operations.handleMenuClose());
        },
        handleSort: (columnName) => {
            dispatch(operations.handleDocumentSort(columnName));
        },
        viewDocument: (url, key) => {
            dispatch(operations.viewDocument(url, key));
        },
        downloadDocument: (document) => {
            dispatch(operations.downloadDocument(document));
        },
        addDocuments: (key, type, documents, ownerType) => {
            dispatch(operations.addDocument(constants.urls.add_document(key, type), documents, ownerType));
        },
        removeDocument: (key) => {
            dispatch(operations.removeDocument(constants.urls.remove_document(key), key));
        },
        approveDocument: (key) => {
            dispatch(operations.approveDocument(constants.urls.approve_document(key), key));
        },
        updateDocument: (key, mediaAssetType) => {
            dispatch(operations.updateDocument(constants.urls.update_document(key), key, mediaAssetType));
        },
        getDocuments: (key, type) => {
            dispatch(operations.getDocuments(constants.urls.get_documents(key, type)));
        },
        copyDocUrl: (key) => {
            dispatch(operations.copyDocUrl(key));
        },
        toggleTerminationDoc: (attached) => {
            dispatch(operations.toggleTerminationDoc(attached));
        },
        updateDocumentFromAdminList: (key, mediaAssetType, parentEntityTypeKey, documentTypeKey, documentDao) => {
            dispatch(
                operations.updateDocumentFromAdminList(
                    constants.urls.update_document(key),
                    key,
                    mediaAssetType,
                    parentEntityTypeKey,
                    documentTypeKey,
                    documentDao
                )
            );
        },
        getDocumentRequirementsByParentEntityType: (parentEntityTypeName, parentKey) => {
            dispatch(operations.getDocumentRequirementsByParentEntityType(parentEntityTypeName, parentKey));
        },
        setAdminDocumentTypeListAvailable: () => {
            dispatch(operations.setAdminDocumentTypeListAvailable());
        },
        resetRequiredMissingDocuments: () => {
            dispatch(operations.resetRequiredMissingDocuments());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDropContainer);
