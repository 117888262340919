import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ReactGA from "react-ga4";

const styles = {
    list: {
        listStyleType: "none",
        paddingLeft: "0px",
        marginTop: "7px",
    },
    listItem: {
        lineHeight: "140%",
        fontSize: "15px",
    },
    link: {
        color: "rgba(0, 0, 0, 0.72)",
    },
    title: {
        margin: "0px",
    },
    paper: {
        marginTop: "20px",
        padding: "16px",
    },
    secondaryInstruction: {
        textDecoration: "none",
        color: "black",
    },
};

const TrainingVideos = (props) => {
    const { classes } = props;

    ReactGA.send({ hitType: "pageview", page: "/training-content", title: "Training Content" });

    return (
        <Grid container>
            <Grid item xs={6}>
                <Paper className={classes.paper}>
                    <h3 className={classes.title}>KM Connect Training Materials</h3>
                    <ul className={classes.list}>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/1_KM+Connect+Introduction+Video+2.0.mp4"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                KM Connect Introduction
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/2_KM+Connect+Desktop+Link+Video.mp4"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                KM Connect Desktop Link Setup
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/2_KM+Connect+Proxy+Training+Video.mp4"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Proxy Training
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/3_KM+Connect+Listing+Training+Video.mp4"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Listing Training
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/4_KM+Connect+Deal+Training+Video+-+From+Listing.mp4"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Deal Training (From Listing, Landlord/Owner Rep)
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/5_KM+Connect+Deal+Training+Video+-+Without+Listing.mp4"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Deal Training (Without Listing, Tenant/Buyer Rep)
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/5_KM+Connect+Outside+Comp+Training+Video.mp4"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Outside Comp Training
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/7_KM+Connect+Invoice+Training+Video.mp4"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Invoice Training
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/8_KM+Connect+Total+Consideration+Calculator+Training+Video.mp4"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Total Consideration Calculator Training
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/10_KM+Connect+Flyer+Link+Training+Video.mp4"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Flyer Link Training
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/11_KM+Connect+-+Comp+%26+Voucher+Status+Definitions+(Read).pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Comp & Voucher Status Definitions
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Read)</span>
                        </li>
                    </ul>
                    <h3 className={classes.title}>User Guides</h3>
                    <ul className={classes.list}>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/KM+Deal+Comp+Workflow.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                KM Deal Comp Workflow
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Read)</span>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/KM+Deal+Voucher+Workflow.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                KM Deal Voucher Workflow
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Read)</span>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/Listing+Workflow.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Listing Workflow
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Read)</span>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/Outside+Comp+Workflow+(Non+KM).pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Outside Comp Workflow: Non-KM
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Read)</span>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/Property+Workflow.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Property Workflow
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Read)</span>
                        </li>
                    </ul>
                    <h3 className={classes.title}>LandVision Training Materials</h3>
                    <ul className={classes.list}>
                        <li className={classes.listItem}>
                            <a
                                href="https://support.digmap.com/onlinehelp/landvision/onlinetraining.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                LandVision Live Training Schedule
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Engage)</span>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://support.digmap.com/onlinehelp/landvision/videotutorials.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                LandVision Video Tutorials
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Watch)</span>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://support.digmap.com/onlinehelp/landvision/documentation.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                LandVision Document Tutorials
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Read)</span>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/LandVision+-+High+Res+Printing+training.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                LandVision High-Res Printing Instructions
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Read)</span>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://lightboxre.zoom.us/rec/share/HhnfnrjvZ37xKXcrPD8Xn6AK8ZXnF0aQ8xh7gUf-M8B93Wz8bF7h5ajxSqId6tZI.Aqw-Vp01C4fH5SAT"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                LandVision Essentials Training
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Video)</span>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://lightboxre.zoom.us/rec/share/5JooKE5cEeff1fHyrvOA9Ez0S-WuFOW5keydsE_aerb4V28rgT8KEVT-y3968VPc.kH0uxt6BQCWtmznF"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                LandVision Search Training
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Video)</span>
                        </li>
                    </ul>
                    <h3 className={classes.title}>Troubleshooting Documents</h3>
                    <ul className={classes.list}>
                        <li className={classes.listItem}>
                            <a
                                href="https://s3.us-east-2.amazonaws.com/connect.kidder.com/content/Troubleshooting+KM+Connect.docx"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Troubleshooting KM Connect "Access Denied" Error
                            </a>
                            <span className={classes.secondaryInstruction}>&nbsp;(Read)</span>
                        </li>
                    </ul>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(TrainingVideos);
