import React, { Component } from "react";
import { connect } from "react-redux";
import DealSummary from "./DealSummary";
import operations from "./ducks/operations";
import { push } from "connected-react-router";
import utils from "../utils/index";
import voucherOperations from "../voucher/ducks/operations";
import dealsOperations from "../deals/ducks/operations";
import documentOperations from "../documents/ducks/operations";
import constants from "./constants";
import documentActions from "../documents/ducks/actions";
import ReactGA from "react-ga4";

class DealContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.handleGetDealSummary(id);
        ReactGA.send({ hitType: "pageview", page: "/deal/*", title: `Deal Summary: ${id}` });
    }

    render() {
        return (
            <div>
                <DealSummary {...this.props} dealId={this.props.match.params} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.authReducer.userProfile,
        values: state.dealReducer.dealSummary,
        // values: mockData.mockData.dealSummary,
        loading: state.utilsReducer.loading,
        isLoading: state.utilsReducer.isLoading,
        isLoading2: state.utilsReducer.isLoading2,
        columnToSort: state.dealReducer.dealSummary.columnToSort,
        sortDirection: state.dealReducer.dealSummary.sortDirection,
        creator: state.dealReducer.creator,
        savedDocuments: state.documentsReducer.savedDocuments,
        missingRequiredDocuments: state.documentsReducer.missingRequiredDocuments,
        role: state.authReducer.userProfile.role,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetDealSummary: (dealId) => {
            dispatch(operations.getDealSummary(dealId));
        },
        handleSelectVoucher: (voucherKey) => {
            dispatch(push(`/voucher/${voucherKey}`));
        },
        handleSort: (columnName) => {
            dispatch(operations.handleSort(columnName));
        },
        addVoucherInstallment: (voucherKey, dealKey) => {
            dispatch(operations.addVoucherInstallment(voucherKey, dealKey));
        },
        addInvoiceInstallment: (dealKey) => {
            dispatch(operations.addInvoiceInstallment(dealKey));
        },
        setLoader: (name, isLoading) => {
            dispatch(utils.operations.setLoader(name, isLoading));
        },
        startVoucher: (dealKey) => {
            dispatch(voucherOperations.startVoucher(dealKey));
        },
        startDeal: (dealKey) => {
            dispatch(operations.startDeal(dealKey));
        },
        handleStartReviewingAsCorporateData: (deal) => {
            dispatch(dealsOperations.handleStartReviewingAsCorporateData(deal));
        },
        deleteDeal: (dealKey) => {
            dispatch(operations.deleteDeal(dealKey));
        },
        loadDealDocumentList: (dealKey) => {
            dispatch(documentOperations.getDocuments(constants.urls.get_deal_document_list(dealKey)));
            // dispatch(operations.loadDealDocumentList(dealKey));
        },
        handleDownloadDealDocuments: (dealKey) => {
            dispatch(operations.downloadDealDocuments(dealKey));
        },
        clearSavedDocuments: (savedDocuments, usedDocTypes) => {
            dispatch(documentActions.receiveDocuments(savedDocuments, usedDocTypes));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealContainer);
