import React from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import Installments from "./Installments";
import { propertyTypeOptions } from "../types/propertyTypes";
import {
    sale_representation_options,
    lease_representation_options,
    cap_rate_basis_options,
    interest_type_options,
    investor_type_options,
    lease_renewal_options,
} from "./constants";
import { yes_no_options } from "../utils/constants";
import searchConstants from "../search/constants";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Today from "@material-ui/icons/Today";
import { get, debounce } from "lodash";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import numeral from "numeral";
import { specialtyTypeFilterOptions } from "../property/constants";
import { sprinklerTypes } from "../listing/constants";
import moment from "moment";
import Help from "@material-ui/icons/Help";
import ReactTooltip from "react-tooltip";
import Autocomplete from "../../../common/js/components/Autocomplete";
import Search from "@material-ui/icons/Search";

const styles = (theme) => ({
    defTerm: {
        fontSize: "13px",
        textTransform: "uppercase",
        fontWeight: 600,
        display: "inline-block",
        padding: "0 0 8px",
        color: "rgba(0, 0, 0, 0.54)",
        fontFamily: 'IBM Plex Sans", sans-serif',
    },
    reducedWidth: {
        width: "50%",
    },
});

class DealForm extends React.Component {
    onStateChange = (changes, helpers, type, property) => {
        console.log(changes);
        if (changes.type === "__autocomplete_change_input__") {
            this.props.handleInput(`${type}.${property}`, changes.inputValue);
            this.props.handleSearchCompany(changes.inputValue);
        }

        if (changes.type === "__autocomplete_click_item__") {
            const company = changes.selectedItem;
            this.props.handleInput(`${type}.companyKey`, company["companyKey"]);
            this.props.handleInput(`${type}.legalName`, company["legalName"]);
            this.props.handleInput(`${type}.displayName`, company["displayName"]);
            this.props.handleInput(`${type}.parentCompany`, company["parentCompany"]);
        }
    };

    clearCompany = (type) => {
        this.props.handleInput(`${type}.companyKey`, null);
        this.props.handleInput(`${type}.legalName`, null);
        this.props.handleInput(`${type}.displayName`, null);
        this.props.handleInput(`${type}.parentCompany`, null);
    };

    getCompanyFieldLabel = (field, companyType) => {
        let labelText = "";
        let buyerTenantText = companyType === "SELLER_LANDLORD" ? "Landlord" : "Tenant";
        if (this.props.transactionType === "Sale") {
            buyerTenantText = companyType === "SELLER_LANDLORD" ? "Seller" : "Buyer";
        }
        if (this.isOutside()) {
            switch (field) {
                case "legalName":
                    labelText = buyerTenantText + " Name (Legal)";
                    break;
                case "displayName":
                    labelText = buyerTenantText + " Name (Actual Owner)";
                    break;
                case "parentCompany":
                    labelText = buyerTenantText + " Name (Parent Company/Institutional Owner)";
                    break;
                default:
                    break;
            }
        } else {
            switch (field) {
                case "legalName":
                    labelText = buyerTenantText + " Name (Legal)*";
                    break;
                case "displayName":
                    labelText = buyerTenantText + " Name (Actual Owner)*";
                    break;
                case "parentCompany":
                    labelText = buyerTenantText + " Name (Parent Company/Institutional Owner)";
                    break;
                default:
                    break;
            }
        }
        return labelText;
    };

    getCompanyPlaceholderText = (field, companyType) => {
        let placeholderText = "";
        let buyerTenantText = companyType === "SELLER_LANDLORD" ? "landlord" : "tenant";
        if (this.props.transactionType === "Sale") {
            buyerTenantText = companyType === "SELLER_LANDLORD" ? "building owner" : "buyer";
        }
        switch (field) {
            case "legalName":
                placeholderText = "Enter " + buyerTenantText + " name (legal)";
                break;
            case "displayName":
                placeholderText = "Enter " + buyerTenantText + " name (common)";
                break;
            case "parentCompany":
                placeholderText = "Enter " + buyerTenantText + " name (parent company/institutional owner)";
                break;
            default:
                break;
        }
        return placeholderText;
    };

    calculateTotalCommission = (event) => {
        switch (event.target.id) {
            case "totalKmCommission": {
                this.props.handleInput("totalKmCommission", event.target.value);
                const totalKmCommission = parseFloat(numeral(event.target.value).format("0.00"));
                const outsideBrokerCommission = parseFloat(
                    numeral(get(this.props.deal, "outsideBrokerCommission", 0)).format("0.00")
                );
                this.props.handleInput(
                    "totalCommission",
                    numeral(totalKmCommission + outsideBrokerCommission).format("0,000.00")
                );
                break;
            }
            case "outsideBrokerCommission": {
                this.props.handleInput("outsideBrokerCommission", event.target.value);
                const outsideBrokerCommission = parseFloat(numeral(event.target.value).format("0.00"));
                const totalKmCommission = parseFloat(
                    numeral(get(this.props.deal, "totalKmCommission", 0)).format("0.00")
                );
                this.props.handleInput(
                    "totalCommission",
                    numeral(totalKmCommission + outsideBrokerCommission).format("0,000.00")
                );
                break;
            }
            default:
                break;
        }
    };

    isOutside = () => {
        return includes(["Outside", "Outside Draft", "Appraisal", "Appraisal Draft"], this.props.deal.compStatus);
    };

    handleAppraisalOrOutsideCompCompany = (company, name, value, parentFlg = false) => {
        if (parentFlg) this.props.handleInput(company + ".parentFlg", true);
        this.props.handleInput(company + "." + name, value);
        if (get(this.props.deal, company + ".companyTypes", null) == null) {
            if (includes(["Outside", "Outside Draft"], this.props.deal.compStatus)) {
                console.log(" in handleAppraisalOrOutsideCompany in DealForm -- outside");
                console.log(company + " " + name + " " + value);
                this.props.handleInput(company + ".companyTypes", ["OUTSIDE_COMP"]);
                this.props.handleInput(company + ".approvedFlg", true);
            } else if (includes(["Appraisal", "Appraisal Draft"], this.props.deal.compStatus)) {
                console.log(" in handleAppraisalOrOutsideCompany in DealForm -- appraisal");
                console.log(company + " " + name + " " + value);
                this.props.handleInput(company + ".companyTypes", ["APPRAISAL"]);
                this.props.handleInput(company + ".approvedFlg", true);
            }
        }
    };

    debounceSearchCompany = debounce((handleSearchCompany, input) => {
        handleSearchCompany(input, "BUSINESS", true);
    }, searchConstants["DEBOUNCE_SEARCH_DELAY"]);

    render() {
        const {
            userProfile,
            classes,
            deal,
            disableInputs,
            errors,
            handleAddInstallment,
            handleCheckboxClick,
            handleDealChange,
            handleDeleteInstallment,
            transactionType,
            updateInstallment,
            handleInput,
            displaySellerLandlordCompData,
            handleSearchCompany,
            autocompleteResults,
        } = this.props;

        // Set transaction date to current date if not set
        if (get(deal, "transactionDate", null) == null && get(deal, "dealStatus", null) === "Draft") {
            this.props.handleInput("transactionDate", moment().format("YYYY-MM-DD"));
        }
        const representation_options =
            transactionType === "Sale" ? sale_representation_options : lease_representation_options;
        const appraisalSalePriceRowColSize =
            includes(["Appraisal", "Appraisal Draft"], get(deal, "compStatus", null)) &&
            toLower(transactionType) === "sale" &&
            includes(["Multifamily", "Hospitality"], get(deal, "propertyType", null))
                ? 3
                : 4;
        return (
            <div>
                <React.Fragment>
                    <Grid container alignItems="flex-start" spacing={8}>
                        <BSInput
                            id="dealName"
                            label={this.isOutside() ? "COMP NAME*" : "DEAL NAME*"}
                            toolTipText="This is the name under which the deal can be searched (e.g. Tenant Name, Landlord Name, Property Name, etc.)"
                            helperText="Required"
                            errorText="Required"
                            error={get(errors, "listing.listingName")}
                            value={get(deal, "listing.listingName")}
                            onChange={(e) => handleInput("listing.listingName", e.target.value)}
                            placeholder={this.isOutside() ? "Enter a comp name" : "Enter a deal name"}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid container alignItems="flex-start" spacing={8}>
                        {deal.propertyType !== "Religious" && (
                            <BSSelect
                                id="specialtyType"
                                label="Sub-Type*"
                                helperText="Required"
                                errorText="Required"
                                error={get(errors, "listing.attributes.specialtyType")}
                                value={get(deal, "listing.attributes.specialtyType")}
                                onChange={(e) => handleInput("listing.attributes.specialtyType", e.target.value)}
                                options={specialtyTypeFilterOptions(get(deal, "listing.attributes.useType"))}
                                disabled={disableInputs}
                            />
                        )}
                    </Grid>
                </React.Fragment>
                <Grid container alignItems="flex-start" spacing={8}>
                    <Grid item xs={4}>
                        <BSInput
                            id="transactionDate"
                            label="Date Signed*"
                            value={deal.transactionDate}
                            error={errors.transactionDate}
                            onChange={(e) => handleInput(e.target.id, e.target.value)}
                            helperText="Required"
                            errorText="Required (mm/dd/yyyy)"
                            placeholder="Date Signed"
                            disabled={disableInputs === true}
                            type="date"
                            startAdornment={<Today />}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSSelect
                            id="propertyType"
                            label="Property Type"
                            value={deal.propertyType}
                            error={errors.propertyType}
                            options={propertyTypeOptions}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSInput
                            id="listingKey"
                            label="Listing Number"
                            error={get(errors, "listing.listingKey")}
                            value={get(deal, "listing.listingKey")}
                            onChange={(e) => handleInput("listing." + e.target.id, e.target.value)}
                            disabled={true}
                            placeholder="Listing Number"
                        />
                    </Grid>
                </Grid>

                {toLower(transactionType) === "sale" && (
                    <Grid container alignItems="flex-start" spacing={8}>
                        <Grid item xs={4}>
                            <BSInput
                                id="closedDate"
                                label="Closed Date*"
                                value={deal.closedDate}
                                error={errors.closedDate}
                                onChange={(e) => handleInput(e.target.id, e.target.value)}
                                helperText="Required"
                                errorText="Required (mm/dd/yyyy)"
                                placeholder="Closed Date"
                                disabled={disableInputs === true}
                                type="date"
                                startAdornment={<Today />}
                            />
                        </Grid>
                    </Grid>
                )}

                {/*outside or appraisal section*/}
                {this.isOutside() && (
                    <React.Fragment>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="legalName"
                                    label={this.getCompanyFieldLabel("legalName", "BUYER_TENANT")}
                                    error={get(errors, "buyerTenantCompany.legalName", null)}
                                    errorText="Required"
                                    helperText={this.isOutside() ? "" : "Required"}
                                    value={
                                        get(deal, "buyerTenantCompany", null)
                                            ? get(deal, "buyerTenantCompany.legalName", "")
                                            : null
                                    }
                                    onChange={(event) =>
                                        this.handleAppraisalOrOutsideCompCompany(
                                            "buyerTenantCompany",
                                            "legalName",
                                            event.target.value
                                        )
                                    }
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("legalName", "BUYER_TENANT")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="displayName"
                                    label={this.getCompanyFieldLabel("displayName", "BUYER_TENANT")}
                                    error={get(errors, "buyerTenantCompany.displayName", null)}
                                    errorText="Required"
                                    helperText={this.isOutside() ? "" : "Required"}
                                    value={get(deal, "buyerTenantCompany.displayName", "")}
                                    onChange={(event) =>
                                        this.handleAppraisalOrOutsideCompCompany(
                                            "buyerTenantCompany",
                                            "displayName",
                                            event.target.value
                                        )
                                    }
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("displayName", "BUYER_TENANT")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="parentCompany"
                                    label={this.getCompanyFieldLabel("parentCompany", "BUYER_TENANT")}
                                    error={get(errors, "buyerTenantCompany.parentCompany.displayName", null)}
                                    value={get(deal, "buyerTenantCompany.parentCompany.displayName", "")}
                                    onChange={(event) =>
                                        this.handleAppraisalOrOutsideCompCompany(
                                            "buyerTenantCompany.parentCompany",
                                            "displayName",
                                            event.target.value,
                                            true
                                        )
                                    }
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("parentCompany", "BUYER_TENANT")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="legalName"
                                    label={this.getCompanyFieldLabel("legalName", "SELLER_LANDLORD")}
                                    error={get(errors, "sellerLandlordCompany.legalName", null)}
                                    errorText="Required"
                                    helperText={this.isOutside() ? "" : "Required"}
                                    value={
                                        get(deal, "sellerLandlordCompany", null)
                                            ? get(deal, "sellerLandlordCompany.legalName", "")
                                            : null
                                    }
                                    onChange={(event) =>
                                        this.handleAppraisalOrOutsideCompCompany(
                                            "sellerLandlordCompany",
                                            "legalName",
                                            event.target.value
                                        )
                                    }
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("legalName", "SELLER_LANDLORD")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="displayName"
                                    label={this.getCompanyFieldLabel("displayName", "SELLER_LANDLORD")}
                                    error={get(errors, "sellerLandlordCompany.displayName", null)}
                                    errorText="Required"
                                    helperText={this.isOutside() ? "" : "Required"}
                                    value={get(deal, "sellerLandlordCompany.displayName", "")}
                                    onChange={(event) =>
                                        this.handleAppraisalOrOutsideCompCompany(
                                            "sellerLandlordCompany",
                                            "displayName",
                                            event.target.value
                                        )
                                    }
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("displayName", "SELLER_LANDLORD")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="parentCompany"
                                    label={this.getCompanyFieldLabel("parentCompany", "SELLER_LANDLORD")}
                                    error={get(errors, "sellerLandlordCompany.parentCompany.displayName", null)}
                                    value={get(deal, "sellerLandlordCompany.parentCompany.displayName", "")}
                                    onChange={(event) =>
                                        this.handleAppraisalOrOutsideCompCompany(
                                            "sellerLandlordCompany.parentCompany",
                                            "displayName",
                                            event.target.value,
                                            true
                                        )
                                    }
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("parentCompany", "SELLER_LANDLORD")}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}

                {!this.isOutside() && (
                    <React.Fragment>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="buyerTenantLegalCompanyName"
                                    label={this.getCompanyFieldLabel("legalName", "BUYER_TENANT")}
                                    error={get(errors, "buyerTenantLegalCompanyName", null)}
                                    errorText="Required"
                                    helperText={this.isOutside() ? "" : "Required"}
                                    value={get(deal, "buyerTenantLegalCompanyName", null)}
                                    onChange={(event) => handleInput("buyerTenantLegalCompanyName", event.target.value)}
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("legalName", "BUYER_TENANT")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="buyerTenantCommonCompanyName"
                                    label={this.getCompanyFieldLabel("displayName", "BUYER_TENANT")}
                                    error={get(errors, "buyerTenantCommonCompanyName", null)}
                                    errorText="Required"
                                    helperText={this.isOutside() ? "" : "Required"}
                                    value={get(deal, "buyerTenantCommonCompanyName", "")}
                                    onChange={(event) =>
                                        handleInput("buyerTenantCommonCompanyName", event.target.value)
                                    }
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("displayName", "BUYER_TENANT")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="buyerTenantInstitutionalOwner"
                                    label={this.getCompanyFieldLabel("parentCompany", "BUYER_TENANT")}
                                    error={get(errors, "buyerTenantInstitutionalOwner", null)}
                                    value={get(deal, "buyerTenantInstitutionalOwner", "")}
                                    onChange={(event) =>
                                        handleInput("buyerTenantInstitutionalOwner", event.target.value)
                                    }
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("parentCompany", "BUYER_TENANT")}
                                />
                            </Grid>

                            {/*if role is admin or CDT have autocomplete company inputs */}
                            {includes(["dataAdmin", "dataEntry"], get(this.props.userProfile, "role", null)) && (
                                <Grid item xs={12} style={{ marginTop: "-30px", marginBottom: "20px" }}>
                                    <span className={classes.defTerm}>
                                        {" "}
                                        BUYER COMPANY
                                        <React.Fragment>
                                            <span data-tip data-for="buyerCompanyInfo">
                                                <Help
                                                    style={{
                                                        fontSize: "110%",
                                                        padding: "5px 10px 5px 5px",
                                                        marginLeft: "4px",
                                                        color: "grey",
                                                    }}
                                                />
                                            </span>
                                            <ReactTooltip
                                                id="buyerCompanyInfo"
                                                place="bottom"
                                                type="dark"
                                                effect="solid"
                                                className="statBoxTooltip"
                                                multiline={true}
                                            >
                                                {get(deal, "buyerTenantCompany.companyKey", "") +
                                                    " | " +
                                                    get(deal, "buyerTenantCompany.legalName", "") +
                                                    " | " +
                                                    get(deal, "buyerTenantCompany.displayName", "")}
                                                {get(deal, "buyerTenantCompany.dbaName", "") != null &&
                                                    " | " + get(deal, "buyerTenantCompany.dbaName", "")}
                                            </ReactTooltip>
                                        </React.Fragment>
                                    </span>
                                    <Autocomplete
                                        id="buyerCompany"
                                        error={get(errors, `buyerTenantCompany`, "")}
                                        initialInputValue={`${get(deal, `buyerTenantCompany.displayName`, "")}`}
                                        itemToString={(i) =>
                                            get(i, `legalName`, null) + " - " + get(i, `companyKey`, null)
                                        } //can add the other fields as well
                                        onSelect={(company) => handleInput("buyerTenantCompany", company)}
                                        placeholder="Enter Approved Company"
                                        searchFunction={(input) =>
                                            this.debounceSearchCompany(handleSearchCompany, input)
                                        }
                                        startAdornment={<Search />}
                                        suggestions={autocompleteResults}
                                        disabled={disableInputs}
                                        errorText="Required"
                                        helperText={<span style={{ color: "#E53935" }}>Required</span>}
                                        options={{
                                            key: "companyKey",
                                            display: (suggestion) => {
                                                return (
                                                    get(suggestion, "companyKey", "") +
                                                    " / " +
                                                    get(suggestion, "legalName", "") +
                                                    " / " +
                                                    get(suggestion, "displayName", "")
                                                );
                                            },
                                        }}
                                    />
                                    <br />
                                    <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="sellerLandlordLegalCompanyName"
                                    label={this.getCompanyFieldLabel("legalName", "SELLER_LANDLORD")}
                                    error={get(errors, "sellerLandlordLegalCompanyName", null)}
                                    errorText="Required"
                                    helperText={this.isOutside() ? "" : "Required"}
                                    value={get(deal, "sellerLandlordLegalCompanyName", null)}
                                    onChange={(event) =>
                                        handleInput("sellerLandlordLegalCompanyName", event.target.value)
                                    }
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("legalName", "SELLER_LANDLORD")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="sellerLandlordCommonCompanyName"
                                    label={this.getCompanyFieldLabel("displayName", "SELLER_LANDLORD")}
                                    error={get(errors, "sellerLandlordCommonCompanyName", null)}
                                    errorText="Required"
                                    helperText={this.isOutside() ? "" : "Required"}
                                    value={get(deal, "sellerLandlordCommonCompanyName", "")}
                                    onChange={(event) =>
                                        handleInput("sellerLandlordCommonCompanyName", event.target.value)
                                    }
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("displayName", "SELLER_LANDLORD")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={12}>
                                <BSInput
                                    id="sellerLandlordInstitutionalOwner"
                                    label={this.getCompanyFieldLabel("parentCompany", "SELLER_LANDLORD")}
                                    error={get(errors, "sellerLandlordInstitutionalOwner", null)}
                                    value={get(deal, "sellerLandlordInstitutionalOwner", "")}
                                    onChange={(event) =>
                                        handleInput("sellerLandlordInstitutionalOwner", event.target.value)
                                    }
                                    disabled={disableInputs === true}
                                    placeholder={this.getCompanyPlaceholderText("parentCompany", "SELLER_LANDLORD")}
                                />
                            </Grid>

                            {/*if role is admin or CDT have autocomplete company inputs */}
                            {includes(["dataAdmin", "dataEntry"], get(this.props.userProfile, "role", null)) && (
                                <Grid item xs={12} style={{ marginTop: "-30px", marginBottom: "20px" }}>
                                    <span className={classes.defTerm}>
                                        {" "}
                                        SELLER COMPANY
                                        <React.Fragment>
                                            <span data-tip data-for="sellerCompanyInfo">
                                                <Help
                                                    style={{
                                                        fontSize: "110%",
                                                        padding: "5px 10px 5px 5px",
                                                        marginLeft: "4px",
                                                        color: "grey",
                                                    }}
                                                />
                                            </span>
                                            <ReactTooltip
                                                id="sellerCompanyInfo"
                                                place="bottom"
                                                type="dark"
                                                effect="solid"
                                                className="statBoxTooltip"
                                                multiline={true}
                                            >
                                                {get(deal, "sellerLandlordCompany.companyKey", "") +
                                                    " | " +
                                                    get(deal, "sellerLandlordCompany.legalName", "") +
                                                    " | " +
                                                    get(deal, "sellerLandlordCompany.displayName", "")}
                                                {get(deal, "sellerLandlordCompany.dbaName", "") != null &&
                                                    " | " + get(deal, "sellerLandlordCompany.dbaName", "")}
                                            </ReactTooltip>
                                        </React.Fragment>
                                    </span>
                                    <Autocomplete
                                        id="sellerCompany"
                                        error={get(errors, `sellerLandlordCompany`, "")}
                                        initialInputValue={`${get(deal, `sellerLandlordCompany.displayName`, "")}`}
                                        itemToString={(i) => get(i, `legalName`, null)} //can add the other fields as well
                                        onSelect={(company) => handleInput("sellerLandlordCompany", company)}
                                        placeholder="Enter Approved Company"
                                        searchFunction={(input) =>
                                            this.debounceSearchCompany(handleSearchCompany, input)
                                        }
                                        startAdornment={<Search />}
                                        suggestions={autocompleteResults}
                                        disabled={disableInputs}
                                        errorText="Required"
                                        helpertext={<span style={{ color: "#E53935" }}>Required</span>}
                                        options={{
                                            key: "companyKey",
                                            display: (suggestion) => {
                                                return (
                                                    get(suggestion, "companyKey", "") +
                                                    " / " +
                                                    get(suggestion, "legalName", "") +
                                                    " / " +
                                                    get(suggestion, "displayName", "")
                                                );
                                            },
                                        }}
                                    />
                                    <br />
                                    <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </React.Fragment>
                )}

                {toLower(transactionType) === "sale" &&
                    !includes(["Appraisal", "Appraisal Draft"], get(deal, "compStatus", null)) && (
                        <React.Fragment>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={12}>
                                    <BSInput
                                        id="totalConsiderationAmount"
                                        label="Sale Price*"
                                        helperText="Required (ex. 981.01)"
                                        errorText="Required (ex. 981.01)"
                                        error={errors.totalConsiderationAmount}
                                        value={deal.totalConsiderationAmount}
                                        onChange={handleDealChange}
                                        placeholder="Enter sale price"
                                        disabled={disableInputs === true}
                                        startAdornment="$"
                                        numeric
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={12}>
                                    <BSInput
                                        id="squareFeet"
                                        type="number"
                                        label="Purchase SF*"
                                        error={errors.squareFeet}
                                        value={deal.squareFeet}
                                        onChange={handleDealChange}
                                        placeholder="Enter total transaction SF"
                                        disabled={disableInputs === true}
                                        helperText="Required (ex. 15678)"
                                        errorText="Required (ex. 15678)"
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}

                {includes(["Appraisal", "Appraisal Draft"], get(deal, "compStatus", null)) &&
                    toLower(transactionType) === "lease" && (
                        <React.Fragment>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={3}>
                                    <BSInput
                                        id="transactionLandSf"
                                        label="Leased Land SF"
                                        error={errors.transactionLandSf}
                                        value={deal.transactionLandSf}
                                        onChange={handleDealChange}
                                        placeholder="Enter leased land SF"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <BSInput
                                        id="landToBldgRatio"
                                        label="Land To Bldg Ratio"
                                        error={errors.landToBldgRatio}
                                        value={deal.landToBldgRatio}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <BSInput
                                        id="monthsOnMarket"
                                        label="Months on Market"
                                        error={errors.monthsOnMarket}
                                        value={deal.monthsOnMarket}
                                        onChange={handleDealChange}
                                        placeholder="Enter months on market"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <BSInput
                                        id="occupancyRate"
                                        label="Occupancy %"
                                        error={errors.occupancyRate}
                                        value={deal.occupancyRate}
                                        onChange={handleDealChange}
                                        placeholder="Enter occupancy %"
                                        disabled={disableInputs === true}
                                        numeric
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}

                {includes(["Appraisal", "Appraisal Draft"], get(deal, "compStatus", null)) &&
                    toLower(transactionType) === "sale" && (
                        <React.Fragment>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={appraisalSalePriceRowColSize}>
                                    <BSInput
                                        id="totalConsiderationAmount"
                                        label="Sale Price*"
                                        helperText="Required (ex. 981.01)"
                                        errorText="Required (ex. 981.01)"
                                        error={errors.totalConsiderationAmount}
                                        value={deal.totalConsiderationAmount}
                                        onChange={handleDealChange}
                                        placeholder="Enter sale price"
                                        disabled={disableInputs === true}
                                        startAdornment="$"
                                        numeric
                                    />
                                </Grid>

                                {includes(["Multifamily", "Hospitality"], get(deal, "propertyType", null)) && (
                                    <Grid item xs={appraisalSalePriceRowColSize}>
                                        <BSInput
                                            id="salePricePerUnit"
                                            label={
                                                get(deal, "propertyType", null) === "Hospitality"
                                                    ? "Sale Price per Room"
                                                    : "Sale Price Per Unit"
                                            }
                                            placeholder={
                                                get(deal, "propertyType", null) === "Hospitality"
                                                    ? "Sale price per room"
                                                    : "Sale price per unit"
                                            }
                                            error={errors.salePricePerUnit}
                                            value={deal.salePricePerUnit}
                                            disabled={true}
                                            endAdornment={
                                                get(deal, "propertyType", null) === "Hospitality" ? "$/room" : "$/unit"
                                            }
                                            numeric
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={appraisalSalePriceRowColSize}>
                                    <BSInput
                                        id="saleAdjustment"
                                        label="Sale Adjustment"
                                        error={errors.saleAdjustment}
                                        value={deal.saleAdjustment}
                                        onChange={handleDealChange}
                                        placeholder="Enter sale adjustment"
                                        disabled={disableInputs === true}
                                        startAdornment="$"
                                        numeric
                                    />
                                </Grid>
                                <Grid item xs={appraisalSalePriceRowColSize}>
                                    <BSInput
                                        id="analysisPrice"
                                        label="Analysis Price"
                                        error={errors.analysisPrice}
                                        value={deal.analysisPrice}
                                        onChange={handleDealChange}
                                        disabled={true}
                                        startAdornment="$"
                                        numeric
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="squareFeet"
                                        type="number"
                                        label="Purchase SF*"
                                        error={errors.squareFeet}
                                        value={deal.squareFeet}
                                        onChange={handleDealChange}
                                        placeholder="Enter purchased building SF"
                                        disabled={disableInputs === true}
                                        helperText="Required (ex. 15678)"
                                        errorText="Required (ex. 15678)"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="bldgSalePricePerSf"
                                        label="Purchased Building $/sf"
                                        error={errors.bldgSalePricePerSf}
                                        value={deal.bldgSalePricePerSf}
                                        onChange={handleDealChange}
                                        disabled={true}
                                        endAdornment="$/sf"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="transactionLandSf"
                                        label="Purchased Land SF"
                                        error={errors.transactionLandSf}
                                        value={deal.transactionLandSf}
                                        onChange={handleDealChange}
                                        placeholder="Enter purchased land SF"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="landSalePricePerSf"
                                        label="Purchased Land $/sf"
                                        error={errors.landSalePricePerSf}
                                        value={deal.landSalePricePerSf}
                                        onChange={handleDealChange}
                                        disabled={true}
                                        endAdornment="$/sf"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="landToBldgRatio"
                                        label="Land To Bldg Ratio"
                                        error={errors.landToBldgRatio}
                                        value={deal.landToBldgRatio}
                                        onChange={handleDealChange}
                                        disabled={true}
                                        numeric
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="exciseTaxNumber"
                                        label="Excise Tax Number"
                                        error={errors.exciseTaxNumber}
                                        value={deal.exciseTaxNumber}
                                        onChange={handleDealChange}
                                        placeholder="Enter excise tax number"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="monthsOnMarket"
                                        label="Months on Market"
                                        error={errors.monthsOnMarket}
                                        value={deal.monthsOnMarket}
                                        onChange={handleDealChange}
                                        placeholder="Enter months on market"
                                        disabled={disableInputs === true}
                                        integer
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={4}>
                                    <BSSelect
                                        id="investorType"
                                        label="Investor Type"
                                        error={errors.investorType}
                                        value={deal.investorType}
                                        onChange={(e) => handleInput(e.target.id, e.target.value)}
                                        disabled={disableInputs === true}
                                        placeholder="Select investor type"
                                        options={investor_type_options}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSSelect
                                        id="interestType"
                                        label="Interest"
                                        error={errors.interestType}
                                        value={deal.interestType}
                                        onChange={(e) => handleInput(e.target.id, e.target.value)}
                                        disabled={disableInputs === true}
                                        placeholder="Select interest type"
                                        options={interest_type_options}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSSelect
                                        id="capRateBasis"
                                        label="Cap Rate Basis"
                                        error={errors.capRateBasis}
                                        value={deal.capRateBasis}
                                        onChange={(e) => handleInput(e.target.id, e.target.value)}
                                        disabled={disableInputs === true}
                                        placeholder="Select cap rate basis"
                                        options={cap_rate_basis_options}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="noi"
                                        label="NOI"
                                        error={errors.noi}
                                        value={deal.noi}
                                        onChange={handleDealChange}
                                        placeholder="Enter NOI"
                                        startAdornment="$"
                                        disabled={disableInputs === true}
                                        numeric
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="noiPerSf"
                                        label="NOI Per SF"
                                        error={errors.noiPerSf}
                                        value={deal.noiPerSf}
                                        disabled={true}
                                        endAdornment="$/sf"
                                        numeric
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="capRate"
                                        label="Cap Rate"
                                        value={get(deal, "listing.capRate")}
                                        error={get(errors, "listing.capRate")}
                                        onChange={(e) => handleInput("listing.capRate", e.target.value)}
                                        errorText="Must be a number (ex. 5.7)"
                                        placeholder="Enter cap rate % (optional)"
                                        disabled={disableInputs === true}
                                        endAdornment="%"
                                        numeric
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={3}>
                                    <BSInput
                                        id="discountRatePerIrr"
                                        label="Discount Rate/IRR"
                                        error={errors.discountRatePerIrr}
                                        value={deal.discountRatePerIrr}
                                        onChange={handleDealChange}
                                        placeholder="Enter discount rate/IRR"
                                        disabled={disableInputs === true}
                                        numeric
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <BSInput
                                        id="reversionCapRate"
                                        label="Reversion Cap Rate"
                                        error={errors.reversionCapRate}
                                        value={deal.reversionCapRate}
                                        onChange={handleDealChange}
                                        placeholder="Enter reversion cap rate"
                                        disabled={disableInputs === true}
                                        numeric
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <BSInput
                                        id="numTenants"
                                        label="Tenants"
                                        error={errors.numTenants}
                                        value={deal.numTenants}
                                        onChange={handleDealChange}
                                        placeholder="Enter tenants"
                                        disabled={disableInputs === true}
                                        integer
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <BSInput
                                        id="occupancyRate"
                                        label="Occupancy %"
                                        error={errors.occupancyRate}
                                        value={deal.occupancyRate}
                                        onChange={handleDealChange}
                                        placeholder="Enter occupancy"
                                        disabled={disableInputs === true}
                                        numeric
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={3} style={{ marginTop: "15px" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={deal.sellerFinancedFlg ? true : false}
                                                onChange={(e) => handleInput("sellerFinancedFlg", e.target.checked)}
                                                value="sellerFinancedFlg"
                                                disabled={false}
                                                color="primary"
                                            />
                                        }
                                        label="Seller Financed"
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <BSInput
                                        id="sellerLoanTerms"
                                        label="Seller Loan Terms"
                                        error={errors.sellerLoanTerms}
                                        value={deal.sellerLoanTerms}
                                        onChange={handleDealChange}
                                        placeholder="Enter seller loan terms"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={3} style={{ marginTop: "15px" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={deal.loanAssumedFlg ? true : false}
                                                onChange={(e) => handleInput("loanAssumedFlg", e.target.checked)}
                                                value="loanAssumedFlg"
                                                disabled={false}
                                                color="primary"
                                            />
                                        }
                                        label="Loan Assumed"
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <BSInput
                                        id="loanAssumedTerms"
                                        label="Loan Assumed Terms"
                                        error={errors.loanAssumedTerms}
                                        value={deal.loanAssumedTerms}
                                        onChange={handleDealChange}
                                        placeholder="Enter loan assumed terms"
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}

                {displaySellerLandlordCompData &&
                    !includes(["Outside", "Outside Draft", "Appraisal", "Appraisal Draft"], deal.compStatus) && (
                        <React.Fragment>
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="totalKmCommission"
                                        label="Total Commission Due To KM Only*"
                                        helperText="Required (ex. 981.01)"
                                        errorText="Required (ex. 981.01)"
                                        error={errors.totalKmCommission}
                                        value={deal.totalKmCommission}
                                        onChange={this.calculateTotalCommission}
                                        placeholder="Enter amount"
                                        startAdornment="$"
                                        disabled={disableInputs === true}
                                        numeric
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BSInput
                                        id="outsideBrokerCommission"
                                        label="Total Commission to Outside Broker"
                                        helperText="(ex. 981.01)"
                                        helperTextNotRequired={true}
                                        errorText="Required (ex. 981.01)"
                                        error={errors.outsideBrokerCommission}
                                        value={deal.outsideBrokerCommission}
                                        onChange={this.calculateTotalCommission}
                                        placeholder="Enter amount"
                                        startAdornment="$"
                                        disabled={disableInputs === true}
                                        numeric
                                    />
                                </Grid>
                            </Grid>
                            <Installments
                                installments={deal.installments}
                                handleAddArrayElement={this.props.handleAddArrayElement}
                                handleDeleteArrayElement={this.props.handleDeleteArrayElement}
                                handleUpdateArrayElement={this.props.handleUpdateArrayElement}
                                handleAddInstallment={handleAddInstallment}
                                handleDeleteInstallment={handleDeleteInstallment}
                                updateInstallment={updateInstallment}
                                errors={get(errors, "installments", {})}
                                disableInputs={disableInputs}
                                onlyEditCurrentInstallment={false}
                                preventAddSubtract={disableInputs}
                                displayFirstWhenEmpty={includes(
                                    ["Draft", "Appraisal Draft"],
                                    get(deal, "dealStatus", null)
                                )}
                            />
                        </React.Fragment>
                    )}

                <Grid container alignItems="flex-start" spacing={8}>
                    {includes(["Outside", "Outside Draft", "Appraisal", "Appraisal Draft"], deal.compStatus) ? (
                        <React.Fragment>
                            <Grid item xs={6}>
                                <BSSelect
                                    id="representationType"
                                    label="Representation Type"
                                    error={errors.representationType}
                                    value={deal.representationType}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs === true}
                                    placeholder="Select representation"
                                    options={representation_options}
                                />
                            </Grid>
                            {includes(["Sale"], deal.transactionType) && (
                                <Grid item xs={6}>
                                    <BSSelect
                                        id="leaseBackFlg"
                                        label="Lease Back"
                                        options={yes_no_options}
                                        error={errors.leaseBackFlg}
                                        value={deal.leaseBackFlg}
                                        onChange={(e) => {
                                            handleInput(e.target.id, e.target.value);
                                            if (e.target.value === false || e.target.value === "false") {
                                                handleInput("spaceType", null);
                                            }
                                        }}
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                            )}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Grid item xs={6}>
                                <BSSelect
                                    id="representationType"
                                    label="Representation Type*"
                                    helperText="Required"
                                    errorText="Required"
                                    error={errors.representationType}
                                    value={deal.representationType}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={disableInputs === true}
                                    placeholder="Select representation"
                                    options={representation_options}
                                />
                            </Grid>
                            {includes(["Sale"], deal.transactionType) && (
                                <Grid item xs={6}>
                                    <BSSelect
                                        id="leaseBackFlg"
                                        label="Lease Back*"
                                        options={yes_no_options}
                                        helperText="Required"
                                        errorText="Required"
                                        error={errors.leaseBackFlg}
                                        value={deal.leaseBackFlg}
                                        onChange={(e) => {
                                            handleInput(e.target.id, e.target.value);
                                            if (e.target.value === false || e.target.value === "false") {
                                                handleInput("spaceType", null);
                                            }
                                        }}
                                        disabled={disableInputs === true}
                                    />
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                </Grid>

                <Grid container alignItems="flex-start" spacing={8}>
                    {includes(["Lease"], deal.transactionType) && (
                        <Grid item xs={6}>
                            <BSSelect
                                id="renewalFlg"
                                label="New Lease/Renewal"
                                value={deal.renewalFlg}
                                error={errors.renewalFlg}
                                errorText="Required"
                                onChange={(e) => handleInput(e.target.id, e.target.value)}
                                helperText="Required"
                                placeholder="Select"
                                options={lease_renewal_options}
                                disabled={disableInputs === true}
                            />
                        </Grid>
                    )}
                    {(includes(["Lease"], deal.transactionType) ||
                        (includes(["Sale"], deal.transactionType) &&
                            (deal.leaseBackFlg === "true" || deal.leaseBackFlg === true))) && (
                        <Grid item xs={6}>
                            <BSSelect
                                id="tenantSpace"
                                label="Tenant Space"
                                value={deal.spaceType}
                                error={errors.spaceType}
                                onChange={(e) => handleInput("spaceType", e.target.value)}
                                placeholder="Select"
                                options={[
                                    {
                                        value: "",
                                        label: "Select tenant space type ...",
                                        disabled: true,
                                    },
                                    {
                                        value: "EXPANSION",
                                        label: "Expansion",
                                    },
                                    {
                                        value: "CONTRACTION",
                                        label: "Contraction",
                                    },
                                    {
                                        value: "EXISTING_EQUIVALENT",
                                        label: "Existing/Equivalent",
                                    },
                                ]}
                                disabled={disableInputs === true}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={8}>
                    <Grid item>
                        <FormGroup>
                            <FormLabel component="legend" style={{ paddingTop: "25px", marginBottom: "5px" }}>
                                Additional Information
                            </FormLabel>
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={8}>
                    <Grid item xs={6}>
                        <FormGroup className={classes.spacedOut} style={{ marginBottom: "10px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={get(deal, "listing.subleaseFlg", false) ? true : false}
                                        onChange={(e) => handleInput("listing.subleaseFlg", e.target.checked)}
                                        value="subleaseFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Is this a Sublease?"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={deal.kmExclusiveFlg ? true : false}
                                        onChange={(e) => handleCheckboxClick("kmExclusiveFlg", e)}
                                        value="kmExclusiveFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Is this a KM Exclusive?"
                            />
                            <div className={classes.reducedWidth}>
                                <BSSelect
                                    id="sprinklers"
                                    label="Sprinklers"
                                    error={get(errors, "listing.attributes.sprinklers")}
                                    value={get(deal, "listing.attributes.sprinklers")}
                                    onChange={(e) => handleInput("listing.attributes.sprinklers", e.target.value)}
                                    options={sprinklerTypes}
                                    disabled={disableInputs}
                                />
                            </div>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} style={{ marginBottom: "10px" }}>
                        <FormGroup className={classes.spacedOut}>
                            {includes(["Appraisal", "Appraisal Draft"], get(deal, "compStatus", null)) && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={deal.offMarketTransactionFlg ? true : false}
                                            onChange={(e) => handleCheckboxClick("offMarketTransactionFlg", e)}
                                            value="offMarketTransactionFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Off Market Transaction?"
                                />
                            )}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={deal.kmManagedFlg ? true : false}
                                        onChange={(e) => handleCheckboxClick("kmManagedFlg", e)}
                                        value="kmManagedFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Is this a KM managed property?"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={deal.includeOnBrokerBioFlg ? true : false}
                                        onChange={(e) => handleCheckboxClick("includeOnBrokerBioFlg", e)}
                                        value="includeOnBrokerBioFlg"
                                        disabled={disableInputs === true}
                                        color="primary"
                                    />
                                }
                                label="Include on Broker Bio page?"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                {includes(["Outside", "Outside Draft", "Appraisal", "Appraisal Draft"], deal.compStatus) && (
                    <React.Fragment>
                        <Divider />
                        <Grid container alignItems="center" spacing={8}>
                            <Grid item xs={6}>
                                <span style={{ fontSize: "14px" }}>{`Created By: `}</span>
                                <span style={{ fontSize: "14px" }}>{deal.auditCreatedBy}</span>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={deal.approvedBy ? true : false}
                                            onChange={() =>
                                                handleInput("approvedBy", deal.approvedBy ? null : userProfile.user)
                                            }
                                            value="approvedBy"
                                            disabled={false}
                                            color="primary"
                                        />
                                    }
                                    label={deal.approvedBy ? `Validated By: ${deal.approvedBy}` : "Validate"}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const MyEnhancedForm = DealForm;
export default withStyles(styles)(MyEnhancedForm);
