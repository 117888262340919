import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Comments from "@material-ui/icons/Comment";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { DropdownButton, MenuItem, SplitButton } from "react-bootstrap";
import constants from "./constants";
import get from "lodash/get";
import { buildAddress } from "../utils/operations";
import { red, amber } from "@material-ui/core/colors";
import includes from "lodash/includes";
import { SaveAndExit } from "../../../common/js/icons";

const ConsultingDealToolbar = (props) => {
    const {
        userProfile,
        voucher,
        classes,
        numComments,
        handleSubmit,
        handleSave,
        handleSaveAndExit,
        toggleComments,
        toggleConfirm,
        confirmDialog,
        elementsSaving,
        handleChooseDisplayDeleteDialog,
        displayDeleteDialog,
        deleteDeal,
    } = props;

    const brokerSubmitActions = {
        Draft: {
            buttonText: "Submit Deal",
            submit: (callback) => {
                handleSubmit(true, constants.urls.broker_submit(voucher.voucherKey), true);
            },
            variant: "contained",
            confirm: false,
            url: constants.urls.broker_submit,
        },
        "Broker Action Required": {
            buttonText: "Submit",
            submit: (callback) => {
                handleSubmit(true, constants.urls.broker_submit(voucher.voucherKey), true);
            },
            variant: "contained",
            confirm: false,
            url: constants.urls.broker_submit,
        },
    };
    const submitActions = {
        broker: {
            ...brokerSubmitActions,
        },
        debtEquity: {
            ...brokerSubmitActions,
        },
        evp: {
            ...brokerSubmitActions,
        },
        portlandBrokerAdmin: {
            ...brokerSubmitActions,
            "Portland Review Required": {
                buttonText: "Portland Review Required",
                submit: (callback) => {
                    handleSubmit(true, callback, true);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_voucher_to_broker,
                    Approve: constants.urls.portland_approval,
                },
            },
        },
        arizonaBrokerAdmin: {
            ...brokerSubmitActions,
            "AZ Review Required": {
                buttonText: "AZ Review Required",
                submit: (callback) => {
                    handleSubmit(true, callback, true);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_voucher_to_broker,
                    Approve: constants.urls.arizona_approval,
                },
            },
        },
        dataEntry: {
            "Needs Review": {
                buttonText: "Start",
                submit: () => {
                    handleSubmit(false, constants.urls.assign_voucher_for_review(voucher.voucherKey), true);
                },
                confirm: false,
                url: constants.urls.assign_voucher_for_review,
            },
            "In Review": {
                buttonText: "In Review",
                submit: (callback) => {
                    handleSubmit(true, callback, true);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_voucher_to_broker,
                    "Final Review": constants.urls.approve_voucher,
                },
            },
            "Admin Action Required": {
                buttonText: "In Review",
                submit: (callback) => {
                    handleSubmit(true, callback, true);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_voucher_to_broker,
                    "Final Review": constants.urls.approve_voucher,
                },
            },
        },
        dataAdmin: {
            "Final Review": {
                buttonText: "In Review",
                submit: (callback) => {
                    handleSubmit(true, callback);
                },
                variant: "contained",
                options: {
                    "Broker Action Required": constants.urls.reassign_voucher_to_broker,
                    "Admin Action Required": constants.urls.reassign_voucher_to_dataentry,
                    Approve: constants.urls.approve_voucher,
                },
            },
        },
        accounting: {
            "Awaiting Payment": {
                buttonText: "Ready For Payment",
                submit: (callback) => {
                    handleSubmit(true, constants.urls.ready_for_payment(voucher.voucherKey), true);
                },
                variant: "contained",
                confirm: false,
                url: constants.urls.ready_for_payment,
            },
        },
    };

    const mainAction = get(submitActions, `${userProfile.role}.${voucher.voucherStatus}`, null);

    const confirm = (url) => {
        toggleConfirm(url(voucher.voucherKey));
    };

    const handleCancel = () => {
        toggleConfirm(null);
    };

    const handleDeleteDeal = () => {
        deleteDeal(get(voucher, "deal.dealKey"));
        handleChooseDisplayDeleteDialog(false, get(voucher, "deal.dealKey"));
    };

    const dealStatus = `${get(voucher, "deal.dealStatus")}`;
    const dealKey = `${get(voucher, "deal.dealKey")}`;

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
            <Grid item>
                <h2 className="page-title">{`Consulting Deal ${get(voucher, "deal.dealKey")}`}</h2>
                <p className="page-subtitle">
                    {buildAddress(get(voucher, "deal.property.primaryAddress"))}
                    {get(voucher, "voucherKey", null) ? " | " : " "}
                    {get(voucher, "deal.propertyType")} {get(voucher, "deal.transactionType")}
                </p>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" spacing={0}>
                    <Grid item className={classes.leftIcon}>
                        <Button onClick={toggleComments}>
                            <Comments /> Comments ({numComments})
                        </Button>
                    </Grid>
                    <Grid item>
                        {(includes(
                            ["dataAdmin", "dataEntry", "ROLE_ARIZONA_BROKER_ADMIN", "ROLE_PORTLAND_ADMIN"],
                            userProfile.role
                        ) &&
                            includes(["Needs Review", "In Review", "Needs Approval", "Final Review"], dealStatus)) ||
                        (includes(["Draft", "Awaiting Payment"], dealStatus) &&
                            get(voucher, "auditCreatedBy", null) === userProfile.user) ? (
                            <Button
                                variant="outlined"
                                className={classes.deleteButton}
                                onClick={() => handleChooseDisplayDeleteDialog(true, dealKey)}
                            >
                                Delete
                            </Button>
                        ) : (
                            ""
                        )}
                    </Grid>
                    <Grid item>
                        {elementsSaving.saveAndExit ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : (
                            <SplitButton title="Save" bsStyle="default" onClick={handleSave}>
                                <Button variant="contained" color="secondary" onClick={handleSaveAndExit}>
                                    <SaveAndExit />
                                    Save & Exit
                                </Button>
                            </SplitButton>
                        )}
                    </Grid>
                    <Grid item>
                        {elementsSaving.submit ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : mainAction && mainAction.options ? (
                            <DropdownButton title={get(mainAction, "buttonText")} pullRight>
                                {Object.keys(get(mainAction, "options", {})).map((x, i) => {
                                    let url = get(mainAction, `options.${x}`);
                                    return (
                                        <MenuItem className={classes.menuCategory} onClick={() => confirm(url)}>
                                            {x}
                                        </MenuItem>
                                    );
                                })}
                            </DropdownButton>
                        ) : mainAction !== null ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => confirm(get(mainAction, `url`))}
                            >
                                {mainAction.buttonText}
                            </Button>
                        ) : (
                            voucher && (
                                <Button disabled variant="contained" color="secondary">
                                    {voucher.voucherStatus}
                                </Button>
                            )
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={confirmDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Continue?</DialogTitle>
                <DialogContent>Are you sure you want to submit this status change?</DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => mainAction["submit"](confirmDialog.onSubmit)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={displayDeleteDialog.open} maxWidth="xs" aria-labelledby="confirmation-dialog-title">
                <DialogTitle id="confirmation-dialog-title">Delete Deal Request?</DialogTitle>
                <DialogContent>Are you sure you want to permanently delete this deal?</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleChooseDisplayDeleteDialog(false, get(voucher, "deal.dealKey"))}
                        color="primary"
                    >
                        No
                    </Button>
                    <Button onClick={() => handleDeleteDeal(get(voucher, "deal.dealKey"))} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

const styles = (theme) => ({
    warning: {
        backgroundColor: amber[700],
    },
    deleteButton: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800],
        },
    },
    pageToolbar: {
        padding: "10px 0px",
        backgroundColor: "#fafafa",
        position: "sticky",
        top: "62px",
        zIndex: 1,
    },
    leftIcon: {
        marginLeft: "25px",
    },
    progress: {
        paddingRight: "100px",
        paddingLeft: "100px",
    },
    progressDiv: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
    },
    menuCategory: {
        "&>a:focus": {
            outline: "none",
        },
    },
});

export default withStyles(styles)(ConsultingDealToolbar);
