import React from "react";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import BSInput from "../../../common/js/components/BSInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import includes from "lodash/includes";

class NameSearchModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            classes,
            searchType,
            setSearchName,
            toggleNameSearchModal,
            handleSaveSearch,
            handleSaveGroup,
            savedSearchName,
            showModal,
            setSearchSelectedToggle,
            saveSelectedOnly,
            tab,
        } = this.props;

        let today = new Date();
        let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        const type = searchType === "listings" ? "Listings" : searchType === "comps" ? "Comps" : "Properties";
        const tabType = includes(["all_km_listings", "all_km_comps"], tab) ? "Search" : "List";

        return (
            <Modal aria-labelledby="search-name-modal" aria-describedby="set-search-name" open={showModal}>
                <div style={getModalStyle()} className={classes.paper}>
                    <h4 className="modal-title" style={{ textAlign: "center", paddingbottom: "25px" }}>
                        Save Your {tabType}
                    </h4>
                    {tabType === "Search" ? (
                        <React.Fragment>
                            <div className={classes.instruction}>
                                <Typography variant="body1">
                                    Enter a name for your search below. Please remember to make this name unique, as it
                                    will be used to identify the search.
                                </Typography>
                            </div>
                            <BSInput
                                id="savedSearchTitle"
                                onChange={(e) => setSearchName(e.target.value, searchType)}
                                placeholder="Enter search name..."
                                extraInputStyles={{ marginBottom: "-20px", marginTop: "30px" }}
                                defaultValue={`${searchType}-${date}`}
                            />
                            {searchType !== "property" ? (
                                <React.Fragment>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={saveSelectedOnly}
                                                onChange={(e) => setSearchSelectedToggle(e.target.checked)}
                                                value="selectedItemsOnly"
                                                className={classes.statusSwitch}
                                            />
                                        }
                                        label={`Retain selected ${type}? (Only Active)`}
                                    />
                                    <p
                                        style={{
                                            paddingLeft: "47px",
                                            marginTop: "-2px",
                                            lineHeight: "1.46429em",
                                            fontStyle: "italic",
                                            fontWeight: 400,
                                            color: "rgba(0, 0, 0, 0.87)",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {`This will pull your selected ${type} to the top of the search and leave unselected ${type} at the bottom. Only selected ${type} are pulled into reports.`}
                                    </p>
                                </React.Fragment>
                            ) : (
                                ""
                            )}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className={classes.instruction}>
                                <Typography variant="body1">
                                    Enter a name for your list below. Please remember to make this name unique, as it
                                    will be used to identify the list.
                                </Typography>
                            </div>
                            <BSInput
                                id="savedGroupTitle"
                                onChange={(e) => setSearchName(e.target.value, searchType)}
                                placeholder="Enter list name..."
                                extraInputStyles={{ marginBottom: "-20px", marginTop: "30px" }}
                                defaultValue={`${searchType}-${date}`}
                            />
                        </React.Fragment>
                    )}
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Button variant="outlined" onClick={() => toggleNameSearchModal(false)}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={savedSearchName ? false : true}
                                variant="contained"
                                color="primary"
                                onClick={() => (tabType === "Search" ? handleSaveSearch() : handleSaveGroup())}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        );
    }
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = (theme) => ({
    switchTitle: {
        fontSize: 14,
        fontWeight: 600,
    },
    theSwitch: {
        marginRight: 0,
    },
});

export default withStyles(styles)(NameSearchModal);
