import React from "react";
import { get, toLower } from "lodash";
import Divider from "@material-ui/core/Divider";
import BuildingForm from "../beta/BuildingFormBeta";
import HealthcareForm from "../HealthcareForm";
import HospitalityForm from "../beta/HospitalityFormBeta";
import IndustrialForm from "../IndustrialForm";
import LandForm from "../beta/LandFormBeta";
import MixedUseForm from "./MixedUseFormBeta";
import MultifamilyForm from "../MultifamilyForm";
import OfficeForm from "../OfficeForm";
import OtherForm from "../OtherForm";
import ParkingInfoForm from "../beta/ParkingInfoFormBeta";
import PropertyForm from "./PropertyFormBeta";
import RetailForm from "./RetailFormBeta";
import FormTitle from "../../../../common/js/components/FormTitle";

const Property = (props) => {
    const { attributes, errors, handleInputChange, property, propertyType } = props;

    return (
        <div>
            <PropertyForm {...props} propertyType={get(property, "propertyType", null)} />
            <Divider />
            <FormTitle title="Building Information" />
            <BuildingForm
                {...props}
                errors={get(errors, "propertyAttributes", {})}
                data={attributes}
                context="property"
                onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
            />
            <Divider />
            <MixedUseForm
                {...props}
                data={attributes}
                onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                dataPathPrefix={`property.propertyAttributes`}
            />
            <Divider />
            <FormTitle title="Parking Information" />
            <ParkingInfoForm
                {...props}
                errors={get(errors, "propertyAttributes", {})}
                data={attributes}
                context="property"
                onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
            />
            {toLower(propertyType) === "office" && (
                <div>
                    <Divider />
                    <FormTitle title="Office Information" />
                    <OfficeForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "healthcare" && (
                <div>
                    <Divider />
                    <FormTitle title="Healthcare Information" />
                    <HealthcareForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "industrial" && (
                <div>
                    <Divider />
                    <FormTitle title="Industrial Information" />
                    <IndustrialForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "life science" && (
                <div>
                    <Divider />
                    <FormTitle title="Life Science Information" />
                    <IndustrialForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "retail" && (
                <div>
                    <Divider />
                    <FormTitle title="Retail Information" />
                    <RetailForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                        dataPathPrefix={`property.propertyAttributes`}
                    />
                </div>
            )}
            {toLower(propertyType) === "land" && (
                <div>
                    <Divider />
                    <FormTitle title="Land Information" />
                    <LandForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "multifamily" && (
                <div>
                    <Divider />
                    <FormTitle title="Multifamily Information" />
                    <MultifamilyForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "hospitality" && (
                <div>
                    <Divider />
                    <FormTitle title="Hospitality Information" />
                    <HospitalityForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
            {toLower(propertyType) === "other" && (
                <div>
                    <Divider />
                    <FormTitle title="Other Information" />
                    <OtherForm
                        {...props}
                        errors={get(errors, "propertyAttributes", {})}
                        data={attributes}
                        context="property"
                        onChange={(field, value) => handleInputChange("property.propertyAttributes." + field, value)}
                    />
                </div>
            )}
        </div>
    );
};

export default Property;
