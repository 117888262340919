import { request } from "../../utils/axios-wrapper";
import { push } from "connected-react-router";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import actions from "./actions";
import validation from "./validation";
import utils from "../../utils/index";
import constants from "../constants";
import numeral from "numeral";

const getTransactionsForUser = (userAccountKey) => {
    return (dispatch, _) => {
        dispatch(getOpenTransactionsForUser(userAccountKey));
        dispatch(getClosedTransactionsForUser(userAccountKey));
    };
};

const getOpenTransactionsForUser = (userAccountKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "transactions.open"));
        let user = userAccountKey;
        if (!user) {
            user = get(getState(), "authReducer.userProfile.userAccountKey");
        }
        request(
            {
                method: "get",
                url: constants.urls.get_open_transactions_for_user(user),
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.somethingSaving(false, "transactions.open"));
                dispatch(actions.loadTransactionsForUser(response.data, "open"));
                dispatch(countResults("open", response.data));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, "transactions.open"));
                dispatch(utils.actions.snackbar(true, "error", "Couldn't retrieve open transactions. Error:  " + err));
            });
    };
};

const getClosedTransactionsForUser = (userAccountKey) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "transactions.closed"));
        let user = userAccountKey;
        if (!user) {
            user = get(getState(), "authReducer.userProfile.userAccountKey");
        }
        request(
            {
                method: "get",
                url: constants.urls.get_closed_transactions_for_user(user),
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.somethingSaving(false, "transactions.closed"));
                dispatch(actions.loadTransactionsForUser(response.data, "closed"));
                dispatch(countResults("closed", response.data));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, "transactions.closed"));
                dispatch(
                    utils.actions.snackbar(true, "error", "Couldn't retrieve closed transactions. Error:  " + err)
                );
            });
    };
};

const getTransaction = (transactionId) => {
    return (dispatch, _) => {
        request(
            {
                method: "get",
                url: constants.urls.get_transaction(transactionId),
            },
            dispatch
        )
            .then((response) => {
                dispatch(unpackTransaction(response.data));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Couldn't retrieve transactions. Error:  " + err));
            });
    };
};

const saveTransaction = (options = {}) => {
    return (dispatch, _) => {
        const { key, complete, buttonClicked } = options;
        if (key) {
            dispatch(updateTransaction({ complete: complete, buttonClicked: buttonClicked }));
        } else {
            dispatch(createTransaction({ complete: complete, buttonClicked: buttonClicked }));
        }
    };
};

const unpackTransaction = (transaction) => {
    return (dispatch, _) => {
        transaction = {
            ...transaction,
            purchasePrice: numeral(transaction.purchasePrice).format("0.00"),
            refiValue: numeral(transaction.refiValue).format("0.00"),
            loanAmount: numeral(transaction.loanAmount).format("0.00"),
            refiBalance: numeral(transaction.refiBalance).format("0.00"),
        };
        dispatch(actions.loadTransaction(transaction));
    };
};

const addArrayElement = (pathToArray, obj = {}) => {
    return (dispatch, getState) => {
        let array = cloneDeep(get(getState().debtEquityReducer, pathToArray, []));
        array.push(obj);
        dispatch(actions.setDebtEquityArray(pathToArray, array));
    };
};

const deleteArrayElement = (pathToArray, index) => {
    return (dispatch, getState) => {
        let array = cloneDeep(get(getState().debtEquityReducer, pathToArray, []));
        array.splice(index, 1);
        dispatch(actions.setDebtEquityArray(pathToArray, array));
    };
};

const createTransaction = (options) => {
    const { complete, buttonClicked } = options;

    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        const transaction = get(getState(), "debtEquityReducer.transaction", null);
        if (transaction) {
            dispatch(utils.actions.somethingSaving(true, buttonClicked));

            const buildOptions = {
                complete: complete,
                transaction: transaction,
                createdBy: get(getState(), "authReducer.userProfile.userAccountKey", null),
            };
            const builtTransaction = buildTransactionRequest(buildOptions);
            const errors = validateTransaction(builtTransaction);
            console.log(errors);

            if (isEmpty(errors) || isNil(errors)) {
                request(
                    {
                        method: "post",
                        url: constants.urls.create_or_save_transaction(),
                        data: builtTransaction,
                    },
                    dispatch
                )
                    .then((response) => {
                        dispatch(utils.actions.snackbar(true, "success", "Success"));
                        dispatch(utils.actions.somethingSaving(false, buttonClicked));
                        dispatch(push("/transactions"));
                    })
                    .catch((err) => {
                        dispatch(utils.actions.snackbar(true, "error", "Could not add transaction. Error: " + err));
                        dispatch(utils.actions.somethingSaving(false, buttonClicked));
                    });
            } else {
                dispatch(utils.actions.addError("transaction", errors));
                dispatch(utils.actions.snackbar(true, "error", "Please fix errors"));
                dispatch(utils.actions.somethingSaving(false, buttonClicked));
            }
        }
    };
};

const updateTransaction = (options) => {
    const { complete, buttonClicked } = options;

    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        const transaction = get(getState(), "debtEquityReducer.transaction", null);
        if (transaction) {
            dispatch(utils.actions.somethingSaving(true, buttonClicked));

            const buildOptions = { complete: complete, transaction: transaction };
            const builtTransaction = buildTransactionRequest(buildOptions);
            const errors = validateTransaction(builtTransaction);
            console.log(errors);
            if (isEmpty(errors) || isNil(errors)) {
                request(
                    {
                        method: "put",
                        url: constants.urls.create_or_save_transaction(),
                        data: builtTransaction,
                    },
                    dispatch
                )
                    .then((response) => {
                        dispatch(utils.actions.snackbar(true, "success", "All changes saved"));
                        dispatch(utils.actions.somethingSaving(false, buttonClicked));
                        dispatch(push("/transactions"));
                    })
                    .catch((err) => {
                        dispatch(utils.actions.snackbar(true, "error", "Could not save transaction. Error: " + err));
                        dispatch(utils.actions.somethingSaving(false, buttonClicked));
                    });
            } else {
                dispatch(utils.actions.addError("transaction", errors));
                dispatch(utils.actions.snackbar(true, "error", "Please fix errors"));
                dispatch(utils.actions.somethingSaving(false, buttonClicked));
            }
        }
    };
};

const deleteTransaction = (transactionId, index) => {
    return (dispatch, _) => {
        request(
            {
                method: "delete",
                url: constants.urls.delete_transaction(transactionId),
            },
            dispatch
        )
            .then((response) => {
                dispatch(removeTransactionFromArray(transactionId, index));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Couldn't delete transaction. Error: " + err));
            });
    };
};

const removeTransactionFromArray = (transactionId, index) => {
    return (dispatch, getState) => {
        let currentTransactions = cloneDeep(getState().debtEquityReducer.transactions);
        const transactionToDelete = currentTransactions[index];
        if (transactionToDelete.transactionKey === transactionId) {
            currentTransactions.splice(index, 1);
            dispatch(actions.setDebtEquityArray("transactions", currentTransactions));
        }
    };
};

const cancelChanges = () => {
    return (dispatch, _) => {
        dispatch(push(`/transactions`));
    };
};

const filter = (query) => {
    return (dispatch, _) => {
        if (typeof query === "string") query = query.toLowerCase();
        dispatch(actions.filter(query));
    };
};

const sort = (columnName) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };
    return (dispatch, getState) => {
        let sortDirection =
            getState().debtEquityReducer.columnToSort === columnName
                ? invertDirection[getState().debtEquityReducer.sortDirection]
                : "asc";
        dispatch(actions.sort(columnName, sortDirection));
    };
};

const clearTransactionsState = () => {
    return (dispatch, _) => {
        dispatch(actions.loadTransactionsForUser([]));
    };
};

const buildTransactionRequest = (buildOptions) => {
    const { complete, transaction, createdBy } = buildOptions;
    let builtTransaction = {
        ...transaction,
        purchasePrice: numeral(transaction.purchasePrice).format("0.00"),
        refiValue: numeral(transaction.refiValue).format("0.00"),
        loanAmount: numeral(transaction.loanAmount).format("0.00"),
        refiBalance: numeral(transaction.refiBalance).format("0.00"),
        createdBy: createdBy ? { userAccountKey: createdBy } : transaction.createdBy,
        status: complete ? "Closed" : transaction.status,
        completionDate: complete ? moment(new Date()).format("YYYY-MM-DD") : transaction.completionDate,
    };
    return builtTransaction;
};

const validateTransaction = (transaction) => {
    return validation.validateTransaction(transaction);
};

const switchTab = (tab) => {
    return (dispatch, getState) => {
        dispatch(actions.switchTab(tab));
        dispatch(countResults(tab, getState().debtEquityReducer.transactions[tab]));
    };
};

const countResults = (tab, data) => {
    return (dispatch, _) => {
        dispatch(actions.countResults(tab, data ? data.length : 0));
    };
};

export default {
    getTransactionsForUser,
    getTransaction,
    addArrayElement,
    deleteArrayElement,
    createTransaction,
    updateTransaction,
    cancelChanges,
    deleteTransaction,
    filter,
    sort,
    clearTransactionsState,
    switchTab,
    saveTransaction,
};
