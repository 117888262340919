import React, { Component } from "react";
import "./Properties.css";
import { buttonActions } from "./reviewPropertiesTables";
import { Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Search, Sorty } from "../../../common/js/icons";
import { noPhotoUrl } from "../utils/constants";
import { toTwoDec, buildAddress } from "../utils/operations";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import BSInput from "../../../common/js/components/BSInput";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import InfiniteScroll from "react-infinite-scroll-component";
import orderBy from "lodash/orderBy";
import includes from "lodash/includes";
import Paper from "@material-ui/core/Paper";
import PropertiesFilters from "./PropertiesFilters";
import PropertyRequestModal from "./PropertyRequestModal";
import schema from "./reviewPropertiesTables";
import StatusChip from "../../../common/js/components/StatusChip";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Fade from "@material-ui/core/Fade";
import startCase from "lodash/startCase";
import SavedSearchesModal from "../search/SavedSearchesModal";
import NameSearchModal from "../search/NameSearchModal";
import Snackbar from "../../../common/js/components/KMSnackbar";
import ResearchSnapshotModal from "../research/ResearchSnapshotModal";
import ResearchExportModal from "../research/ResearchExportModal";

class ReviewProperties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            searchRun: false,
            researchSnapshotModalOpen: false,
            researchExportModalOpen: false,
            snackbarOpen: false,
            snackbarMessage: "",
            snackbarVariant: "",
        };
    }

    handleExpandClick = () => {
        this.setState((state) => ({ expanded: !state.expanded }));
    };

    handleOpenResearchSnapshotModal = () => {
        this.setState({ researchSnapshotModalOpen: true });
    };

    handleCloseResearchSnapshotModal = () => {
        this.setState({ researchSnapshotModalOpen: false });
    };

    handleOpenResearchExportModal = () => {
        this.setState({ researchExportModalOpen: true });
    };

    handleCloseResearchExportModal = () => {
        this.setState({ researchExportModalOpen: false });
    };

    handleResearchSnapshotResponse = (result) => {
        if (result === true) {
            this.setState({
                snackbarMessage: "Success: Your request will be processed.",
                snackbarVariant: "success",
                snackbarOpen: true,
            });
        } else if (result === false) {
            this.setState({
                snackbarMessage: "Failed: Your request encountered an error.",
                snackbarVariant: "error",
                snackbarOpen: true,
            });
        }
    };

    handleResearchExportResponse = (result) => {
        if (result === true) {
            this.setState({
                snackbarMessage: "Success: Your research export was generated.",
                snackbarVariant: "success",
                snackbarOpen: true,
            });
        } else if (result === false) {
            this.setState({
                snackbarMessage: "Failed: Your research export encountered an error.",
                snackbarVariant: "error",
                snackbarOpen: true,
            });
        }
    };

    render() {
        const {
            classes,
            clearFilters,
            columnToSort,
            elementsSaving,
            errors,
            filterQuery,
            handleDealTypeRadioClick,
            handleFilter,
            handleSearchSubmit,
            handleSelectAllPropertyType,
            handleSizeRadioClick,
            handleSort,
            handleStatusRadioClick,
            handleSubmitPropertyRequest,
            isLoading,
            loadMoreSearchResults,
            properties,
            propertyRequestModal,
            propertySearch,
            propertySearchFilters,
            propertyTab,
            propertyTypeExpansionPanels,
            marketFilterExpansionPanels,
            role,
            searchCriteria,
            sortDirection,
            switchTab,
            togglePropertyRequestModal,
            togglePropertyTypeExpansionPanel,
            toggleMarketFilterExpansionPanel,
            updateFilter,
            updatePropertyRequest,
            handleSelectMarket,
            handleSelectSubmarket,
            handleSelectMicromarket,
            savedSearchModal,
            nameSearchModal,
            handleDeleteCrumb,
            loadedSearchCrumbs,
        } = this.props;

        const propertiez = properties[propertyTab] ? properties[propertyTab] : [];
        const data = orderBy(
            filterQuery && propertiez
                ? propertiez.filter((x) => schema.filterByQuery(x, schema.columnsToQueryFilter, filterQuery))
                : propertiez,
            columnToSort,
            sortDirection
        );

        return (
            <React.Fragment>
                <Collapse
                    in={propertyTab === "all_properties"}
                    unmountOnExit
                    style={{ marginLeft: -20, marginRight: -20 }}
                >
                    <PropertiesFilters
                        updateFilter={updateFilter}
                        classes={classes}
                        searchSubmit={handleSearchSubmit}
                        propertySearchFilters={propertySearchFilters}
                        handleSelectAllPropertyType={handleSelectAllPropertyType}
                        clearFilters={clearFilters}
                        searchCriteria={searchCriteria}
                        handleStatusRadioClick={handleStatusRadioClick}
                        handleSizeRadioClick={handleSizeRadioClick}
                        handleDealTypeRadioClick={handleDealTypeRadioClick}
                        errors={errors}
                        togglePropertyTypeExpansionPanel={togglePropertyTypeExpansionPanel}
                        propertyTypeExpansionPanels={propertyTypeExpansionPanels}
                        toggleMarketFilterExpansionPanel={toggleMarketFilterExpansionPanel}
                        marketFilterExpansionPanels={marketFilterExpansionPanels}
                        handleSelectMarket={handleSelectMarket}
                        handleSelectSubmarket={handleSelectSubmarket}
                        handleSelectMicromarket={handleSelectMicromarket}
                        disabled={true}
                        loadedSearchCrumbs={loadedSearchCrumbs}
                        handleDeleteCrumb={handleDeleteCrumb}
                    />
                </Collapse>
                <Snackbar
                    open={this.state.snackbarOpen}
                    message={this.state.snackbarMessage}
                    variant={this.state.snackbarVariant}
                    vertical="top"
                    horizontal="center"
                    autoHideDuration={3000}
                    onClose={() => {
                        this.setState({ snackbarOpen: false });
                    }}
                />
                {/* new */}
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.pageToolbar}
                    spacing={16}
                >
                    <Grid item>
                        <h2 className="page-title">Properties</h2>
                        <div className="page-subtitle">
                            {propertyTab !== "all_properties" &&
                                `Showing ${propertiez.length} ${propertiez.length === 1 ? "property" : "properties"} `}
                            {filterQuery > "" && propertyTab !== "all_properties" && "(Filtered)"}
                            {propertyTab === "all_properties" &&
                                propertySearch.searchResponse &&
                                propertySearch.searchResponse.results &&
                                `Showing  ${propertySearch.searchResponse.results.length} ${
                                    propertySearch.searchResponse.results.length === 1
                                        ? "property"
                                        : `properties (${propertySearch.searchResponse.totalResultCount} TOTAL)`
                                }`}
                        </div>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={0}>
                            <Grid item className={classes.leftIcon}>
                                {includes(["dataAdmin", "researchAdmin", "research", "dataEntry"], role) ? (
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.handleOpenResearchExportModal()}
                                        >
                                            Export Research
                                        </Button>
                                        <ResearchExportModal
                                            modalOpen={this.state.researchExportModalOpen}
                                            closeModal={this.handleCloseResearchExportModal}
                                            snapshotResponse={this.handleResearchExportResponse}
                                        />
                                    </React.Fragment>
                                ) : (
                                    ""
                                )}
                                {includes(["dataAdmin", "researchAdmin"], role) ? (
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.handleOpenResearchSnapshotModal()}
                                        >
                                            Capture Research
                                        </Button>
                                        <ResearchSnapshotModal
                                            modalOpen={this.state.researchSnapshotModalOpen}
                                            closeModal={this.handleCloseResearchSnapshotModal}
                                            snapshotResponse={this.handleResearchSnapshotResponse}
                                        />
                                    </React.Fragment>
                                ) : (
                                    ""
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => togglePropertyRequestModal()}
                                >
                                    Request Property
                                </Button>
                                <PropertyRequestModal
                                    classes={classes}
                                    errors={errors}
                                    propertyRequestModal={propertyRequestModal}
                                    togglePropertyRequestModal={togglePropertyRequestModal}
                                    updatePropertyRequest={updatePropertyRequest}
                                    handleSubmit={handleSubmitPropertyRequest}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Paper className={classes.paperSpacing}>
                    <Grid container justify="space-between">
                        <Grid item style={{ position: "relative" }}>
                            <Tabs
                                className={propertyTab !== "all_properties" ? classes.tabs : classes.tabsNoAbsolute}
                                value={this.props.propertyTab}
                                onChange={switchTab}
                            >
                                <Tab className={classes.tab} value="all_properties" label="All Properties" />
                                {includes(["dataEntry", "dataAdmin"], role) && (
                                    <Tab
                                        className={classes.tab}
                                        value="data_team_needs_review"
                                        label={
                                            elementsSaving.data_team_needs_review &&
                                            this.props.propertyTab !== "data_team_needs_review" ? (
                                                <Badge
                                                    className={classes.badgePadding}
                                                    classes={classes}
                                                    style={{ backgroundColor: "transparent" }}
                                                    badgeContent={
                                                        <Fade in={true} timeout={{ exit: 600 }}>
                                                            <CircularProgress color="primary" size={13} />
                                                        </Fade>
                                                    }
                                                >
                                                    Needs Review
                                                </Badge>
                                            ) : properties.data_team_needs_review ? (
                                                <Badge
                                                    className={classes.badgePadding}
                                                    classes={classes}
                                                    color="error"
                                                    badgeContent={properties.data_team_needs_review.length}
                                                >
                                                    Needs Review
                                                </Badge>
                                            ) : (
                                                "Needs Review"
                                            )
                                        }
                                    />
                                )}
                            </Tabs>
                        </Grid>
                        {propertyTab !== "all_properties" && (
                            <Grid item className={classes.filterInput}>
                                <BSInput
                                    value={filterQuery}
                                    placeholder="Search properties..."
                                    className={classes.toplessInput}
                                    errorPlaceholder="hideIt"
                                    startAdornment={<Search />}
                                    startAdornmentStyles={{ color: "#777777" }}
                                    onChange={(event) => {
                                        handleFilter(event.target.value);
                                    }}
                                    extraInputStyles={{ backgroundColor: "#fafafa", border: "none", marginTop: "10px" }}
                                    extraFormControlStyles={{ minWidth: "16em" }}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {propertyTab !== "all_properties" && (
                        <React.Fragment>
                            <Divider classes={{ root: classes.divider }} />
                            <Grid container spacing={0}>
                                <Grid item xs>
                                    <Table>
                                        {/* {logger()} */}
                                        <TableHead>
                                            <TableRow>
                                                {schema.viewHeaders[propertyTab].map((x, i) => (
                                                    <TableCell classes={classes} key={`thc-${i}`}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                            onClick={() => handleSort(x.sort ? x.sort : x.prop)}
                                                        >
                                                            <span className={classes.sortable}>{x.name}</span>
                                                            {x.name && <Sorty />}
                                                        </div>
                                                    </TableCell>
                                                ))}
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {!isLoading && (
                                            <TableBody>
                                                {data.map((x, i) => row(x, i, role, propertyTab, classes, isLoading))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </Grid>
                            </Grid>
                            {isLoading ? (
                                <div className={classes.alternateContent}>
                                    <CircularProgress color="primary" />
                                </div>
                            ) : Object.keys(data).length === 0 ? (
                                <div className={classes.alternateContent}>Nothing to show.</div>
                            ) : (
                                ""
                            )}
                        </React.Fragment>
                    )}
                    {/* properties grid */}
                    {propertyTab === "all_properties" && (
                        <React.Fragment>
                            <Divider classes={{ root: classes.divider }} />
                            {get(elementsSaving, "elastic") ? (
                                <div className={classes.loadingPlaceholder}>
                                    <CircularProgress color="primary" />
                                </div>
                            ) : propertySearch.searchResponse && propertySearch.searchResponse.results ? (
                                <React.Fragment>
                                    <InfiniteScroll
                                        dataLength={propertySearch.searchResponse.results.length}
                                        next={loadMoreSearchResults}
                                        hasMore={Boolean(
                                            propertySearch.searchResponse.results.length <
                                                propertySearch.searchResponse.totalResultCount
                                        )}
                                        loader={<h4>Loading more results...</h4>}
                                    >
                                        <Grid
                                            container
                                            alignItems="stretch"
                                            className={classes.propertyGrid}
                                            spacing={16}
                                        >
                                            {Object.keys(propertySearch.searchResponse.results).map(
                                                (property, index) => {
                                                    return (
                                                        <Grid item xs={3}>
                                                            <Link
                                                                to={`/propdetail/${propertySearch.searchResponse.results[property]["property_id"]}`}
                                                                className={classes.propLink}
                                                            >
                                                                <div className="propertyCard">
                                                                    <div
                                                                        className="propertyImageContainer"
                                                                        style={{
                                                                            backgroundImage: `${
                                                                                propertySearch.searchResponse.results[
                                                                                    property
                                                                                ].property_photo
                                                                                    ? `url(${propertySearch.searchResponse.results[property].property_photo})`
                                                                                    : `url(${noPhotoUrl})`
                                                                            }`,
                                                                        }}
                                                                    >
                                                                        {propertySearch.searchResponse.results[property]
                                                                            .km_managed_flg && (
                                                                            <span className="propertyTag">
                                                                                KM Managed
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div className="propertyTitle">
                                                                        {
                                                                            propertySearch.searchResponse.results[
                                                                                property
                                                                            ].property_name
                                                                        }
                                                                    </div>
                                                                    <div className="propertyLocation">
                                                                        {
                                                                            propertySearch.searchResponse.results[
                                                                                property
                                                                            ].property_address
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                            propertySearch.searchResponse.results[
                                                                                property
                                                                            ].city
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                            propertySearch.searchResponse.results[
                                                                                property
                                                                            ].state_code
                                                                        }
                                                                    </div>
                                                                    <div className="propertySpace">
                                                                        {
                                                                            propertySearch.searchResponse.results[
                                                                                property
                                                                            ].num_listings
                                                                        }{" "}
                                                                        {propertySearch.searchResponse.results[property]
                                                                            .num_listings === 1
                                                                            ? "listing"
                                                                            : "listings"}{" "}
                                                                        -{" "}
                                                                        {propertySearch.searchResponse.results[property]
                                                                            .sf_avail
                                                                            ? propertySearch.searchResponse.results[
                                                                                  property
                                                                              ].sf_avail
                                                                            : "N/A"}{" "}
                                                                        Available SF
                                                                        <br />
                                                                        {startCase(
                                                                            propertySearch.searchResponse.results[
                                                                                property
                                                                            ].property_type
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </Grid>
                                                    );
                                                }
                                            )}
                                        </Grid>
                                    </InfiniteScroll>
                                    {propertySearch.searchResponse.results === 0 ? (
                                        <div className={classes.alternateContent}>Your search generated no results</div>
                                    ) : (
                                        ""
                                    )}
                                </React.Fragment>
                            ) : (
                                <div className={classes.alternateContent}>Please apply search criteria</div>
                            )}
                        </React.Fragment>
                    )}
                </Paper>
                <SavedSearchesModal {...this.props} searchType="property" showModal={savedSearchModal} />
                <NameSearchModal {...this.props} searchType="property" showModal={nameSearchModal} />
            </React.Fragment>
        );
    }
}
const row = (x, i, role, propertyTab, classes, isLoading) => {
    const buttonProperties = get(buttonActions, `${role}.${get(x, "propertyAttributes.propertyStatus", "")}`, {});
    return isLoading ? null : (
        <TableRow key={`tr-${i}`} selectable="false">
            {schema.viewHeaders[propertyTab].map((y, k) =>
                y.name === "Property" ? (
                    <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                        <Link to={`/propdetail/${x.propertyKey}`} className={classes.lb}>
                            <span className={classes.listingIdLink}>
                                {get(
                                    x,
                                    "propertyAttributes.propertyName",
                                    get(x, "propertyAttributes.businessParkName", "-")
                                )}
                            </span>
                        </Link>
                        <div>{buildAddress(get(x, "primaryAddress", {}))}</div>
                    </TableCell>
                ) : y.transform ? (
                    <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                        {y.transform(get(x, y.prop))}
                    </TableCell>
                ) : y.prop === "propertyStatus" ? (
                    <TableCell>
                        <StatusChip status={buttonProperties.statusToDisplay} />
                    </TableCell>
                ) : y.name === "Rate" ? (
                    <TableCell>{`${toTwoDec(get(x, "askingRentMax", 0))}`}</TableCell>
                ) : (
                    <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                        {get(x, y.prop)}
                    </TableCell>
                )
            )}
            <TableCell classes={{ root: classes.body }}>
                <Link to={`/property/${x.propertyKey}`} className={classes.lb}>
                    <Button variant={buttonProperties.variant} color="primary" onClick={() => null}>
                        {buttonProperties.text}
                    </Button>
                </Link>
            </TableCell>
        </TableRow>
    );
};

const styles = (theme) => ({
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    connectorActive: {
        "& $connectorLine": {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        "& $connectorLine": {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        "& $connectorLine": {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create("border-color"),
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    menuCategory: {
        fontSize: "13px",
        fontWeight: 800,
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
        "&>a": {
            color: "#999999 !important",
        },
    },
    menuSubItem: {
        "&>a": {
            paddingLeft: "30px !important",
        },
    },
    menuRequestListing: {
        paddingBottom: "0px",
        "&>a": {
            paddingBottom: "0px !important",
        },
        "&>a:hover": {
            backgroundColor: "#ffffff !important",
            cursor: "default",
        },
        "&>a:focus": {
            outline: "none",
        },
    },
    loadingPlaceholder: {
        marginTop: "3em",
        marginBottom: "1.5em",
        textAlign: "center",
        width: "100%",
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "6em",
        marginBottom: "6em",
        color: "rgba(0, 0, 0, 0.30)",
        fontSize: "20px",
    },
    lb: {
        textDecoration: "none",
    },
    propertyImage: {
        maxWidth: "100%",
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
    },
    propLink: {
        textDecoration: "none",
    },
    badge: {
        top: "1px",
        right: 0,
        width: "18px",
        height: "18px",
        fontSize: "11px", // spec says 12, but in the event of double digits in badge, it needs more room
        transform: "none",
        padding: 0,
        minWidth: 0,
    },
    sortable: {
        paddingRight: "5px",
    },
    toplessInput: {
        marginTop: 0,
    },
    badgePadding: {
        paddingRight: "24px",
    },
    filterInput: {
        height: "65px",
        marginBottom: "0px",
        minWidth: "10%",
    },
    divider: {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        margin: "0 -20px 0 -20px",
    },
    tabs: {
        borderBottom: "none",
        marginRight: "20px",
        position: "absolute",
        bottom: 0,
        width: "20em",
    },
    tabsNoAbsolute: {
        borderBottom: "none",
        marginRight: "20px",
        width: "20em",
    },
    tab: {
        borderBottom: "none",
        marginRight: "20px",
    },
    paperSpacing: {
        padding: "10px 20px",
        fontSize: 14,
        marginBottom: "15px",
    },
});

export default withStyles(styles)(ReviewProperties);
