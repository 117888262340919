import React from "react";
import get from "lodash/get";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import FormTitle from "../../../../common/js/components/FormTitle";

const PublicSiteDisplaySetting = (props) => {
    const { disableInputs, handleListingChange, listing } = props;
    const handleListingAttributeChange = (field, value) => {
        handleListingChange("attributes." + field, value);
    };
    return (
        <React.Fragment>
            <FormTitle display="inline-block" title="Public Site Display Settings" />
            <div style={{ display: "flex", gap: "10px" }}>
                <div>
                    <FormGroup>
                        <FormControlLabel
                            className="publicSiteDisplaySettings-formControlLabel"
                            control={
                                <Checkbox
                                    checked={get(listing, "attributes.optOutFlg", false) ? true : false}
                                    onChange={(e, checked) => {
                                        handleListingAttributeChange(e.target.value, checked);
                                        handleListingChange("dateListed", null);
                                    }}
                                    disabled={disableInputs === true}
                                    value="optOutFlg"
                                    color="primary"
                                />
                            }
                            label="Opt Out: kidder.com"
                        />
                    </FormGroup>
                </div>
                <div>
                    <FormControlLabel
                        className="publicSiteDisplaySettings-formControlLabel"
                        control={
                            <Checkbox
                                checked={get(listing, "attributes.hidePropertyInfoFlg", false) ? true : false}
                                onChange={(e, checked) => {
                                    handleListingAttributeChange(e.target.value, checked);
                                }}
                                disabled={disableInputs === true}
                                value="hidePropertyInfoFlg"
                                color="primary"
                            />
                        }
                        label="Hide Property Info"
                    />
                </div>
                <div>
                    <FormControlLabel
                        className="publicSiteDisplaySettings-formControlLabel"
                        control={
                            <Checkbox
                                checked={get(listing, "attributes.hideListingInfoFlg", false) ? true : false}
                                onChange={(e, checked) => {
                                    handleListingAttributeChange(e.target.value, checked);
                                }}
                                disabled={disableInputs === true}
                                value="hideListingInfoFlg"
                                color="primary"
                            />
                        }
                        label="Hide Listing Info"
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default PublicSiteDisplaySetting;
