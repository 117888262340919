import React from "react";
import { connect } from "react-redux";
import operations from "./ducks/operations";
import ReactQueryParams from "react-query-params";

class LoginContainer extends ReactQueryParams {
    componentDidMount() {
        const code = this.queryParams.code;
        if (code) {
            this.props.login(code);
        }
    }

    render() {
        const code = this.queryParams.code;
        return <div>{!code && "Invalid Login"}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        valid: state.authReducer.validFormValues,
        snackbarOpen: state.authReducer.snackbarOpen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // handleChange: event => {
        //   dispatch(handleChange(event.target.id, event.target.value))
        // },
        // validateForm: (user, pw) => {
        //   dispatch(validateLogin(user, pw))
        // },
        handleChangeAndValidate: (event) => {
            dispatch(operations.handleChangeAndValidate(event.target.id, event.target.value));
        },
        // handleSubmit: (username, password) => {
        //   dispatch(operations.authRequest(username, password))
        // },
        hideSnackbar: () => {
            dispatch(operations.hideSnackbar());
        },
        login: (code) => {
            dispatch(operations.login(code));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
