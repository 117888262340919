import actions from "./actions";
import dealActions from "../../deals/ducks/actions";
import utils from "../../utils/index";
import { push } from "connected-react-router";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import set from "lodash/set";
import get from "lodash/get";
import map from "lodash/map";
import forIn from "lodash/forIn";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import toLower from "lodash/toLower";
import startsWith from "lodash/startsWith";
import difference from "lodash/difference";
import shortid from "shortid";
import constants from "../constants";
import documentTypes from "../../documents/documentTypes";
import validation from "./validation";
import numeral from "numeral";
import { request } from "../../utils/axios-wrapper";
import utilOperations, { round } from "../../utils/operations";
import property_operations from "../../property/ducks/operations";

const startVoucher = (dealKey) => {
    return (dispatch, getState) => {
        const transactionType = getState().dealReducer.dealSummary.transactionType;
        request(
            {
                method: "post",
                url: constants.urls.create_voucher(),
                data: {
                    deal: {
                        dealKey: dealKey,
                        transactionType: transactionType,
                    },
                    voucherTransactionType: transactionType,
                },
                headers: { "Content-Type": "application/json" },
            },
            dispatch
        )
            .then((response) => {
                dispatch(push(`/voucher/${response.data.voucherKey}`));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Could not create voucher."));
                dispatch(utilOperations.reportError(err, "voucher:operations:startVoucher Error"));
            });
    };
};

const createVoucher = (voucher) => {
    return (dispatch, getState) => {
        request(
            {
                method: "post",
                url: constants.urls.create_voucher(),
                data: voucher,
                headers: { "Content-Type": "application/json" },
            },
            dispatch
        )
            .then((response) => {
                dispatch(push(`/voucher/${response.data.voucherKey}`));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Could not create voucher."));
                dispatch(utilOperations.reportError(err, "voucher:operations:createVoucher Error"));
            });
    };
};
const handleDeleteDeal = (dealKey) => {
    return (dispatch, getState) => {
        if (dealKey) {
            request(
                {
                    url: constants.urls.delete_deal(dealKey),
                    method: "put",
                    crossDomain: true,
                },
                dispatch
            )
                .then((response) => {
                    dispatch(push(`/deals`));
                })
                .catch((err) => {
                    console.log("error occurred!" + err);
                    dispatch(
                        utils.actions.snackbar(true, "error", "Something unexpected went wrong. Unable to delete deal.")
                    );
                    dispatch(utilOperations.reportError(err, "deal:operations:deleteDeal Error"));
                });
        }
    };
};

const handleGetVoucher = (key) => {
    return (dispatch, _) => {
        request(
            {
                url: constants.urls.get_voucher(key),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                const voucher = response.data;
                each(voucher.brokerCommissions, (b) => {
                    if (b && b.runnerCommissions) {
                        b.runnerCommissions = utils.operations.arrayToObject(
                            b.runnerCommissions,
                            "runnerCommissionKey"
                        );
                        Object.keys(b.runnerCommissions).forEach((r) => {
                            unpackRunner(b.runnerCommissions[r]);
                        });
                    }
                });
                voucher.brokerCommissions = utils.operations.arrayToObject(
                    voucher.brokerCommissions,
                    "brokerCommissionKey"
                );
                Object.keys(voucher.brokerCommissions).forEach((b) => {
                    unpackBrokerCommission(voucher.brokerCommissions[b]);
                });
                dispatch(actions.getVoucher(voucher));
                dispatch(utils.actions.isSaving(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(
                    utils.actions.snackbar(
                        true,
                        "error",
                        "Something went wrong. Couldn't load voucher or this voucher doesn't exist."
                    )
                );
                dispatch(utils.actions.isSaving(false));
            });
    };
};

const unpackRunner = (runner) => {
    set(runner, "firstName", runner.runner.firstName);
    set(runner, "lastName", runner.runner.lastName);
};

const unpackBrokerCommission = (commission) => {
    set(commission, "firstName", commission.brokerAgent.person.firstName);
    set(commission, "lastName", commission.brokerAgent.person.lastName);
    set(commission, "brokerAgentKey", commission.brokerAgent.brokerAgentKey);
    set(commission, "kmBrokerFlag", commission.brokerAgent.kmBrokerFlag);

    set(commission, "agentRepresentation", get(commission, "representationType"));
    set(commission, "companyName", get(commission, "company.displayName"));
    set(commission, "companyAddress", get(commission, "company.mainAddress.address1"));
    set(commission, "companyAddressKey", get(commission, "company.mainAddress.key"));
    set(commission, "companyCity", get(commission, "company.mainAddress.city.city"));
    set(commission, "companyCityKey", get(commission, "company.mainAddress.city.key"));
    set(commission, "companyState", get(commission, "company.mainAddress.city.state.stateCode"));
    set(commission, "companyZip", get(commission, "company.mainAddress.zipCode.zipCode"));
    set(commission, "companyZipKey", get(commission, "company.mainAddress.zipCode.key"));
    set(commission, "companyKey", get(commission, "company.companyKey"));
};

const handleSubmit = (save, statusChangeCallback, elementSaving, shouldExit, validationOptions = {}) => {
    return (dispatch, getState) => {
        let role = getState().authReducer.userProfile.role;
        if (save) {
            dispatch(saveVoucher(statusChangeCallback, elementSaving, shouldExit, validationOptions));
        } else {
            dispatch(utils.actions.somethingSaving(true, elementSaving));
            statusChangeCallback &&
                request(
                    {
                        url: statusChangeCallback,
                        method: "put",
                        crossDomain: true,
                    },
                    dispatch
                )
                    .then((response) => {
                        dispatch(utils.actions.snackbar(true, "success", "Success"));
                        if (role === "accounting") {
                            if (shouldExit) {
                                dispatch(push(`/vouchers`)); //redirect to vouchers if accounting(they came from vouchers screen)
                            }
                        } else if (response.data.deal.transactionType === "Consulting") {
                            dispatch(dealActions.clearDealsState());
                            if (shouldExit) {
                                dispatch(push(`/deals`)); //redirect to deals is consulting deal
                            }
                        } else {
                            if (shouldExit) {
                                dispatch(push(`/deal/${response.data.deal.dealKey}`)); //redirect to deal summary instead of loading the new status
                            }
                        }
                        dispatch(utils.actions.somethingSaving(false, elementSaving));
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(utils.actions.somethingSaving(false, elementSaving));
                        dispatch(utils.actions.snackbar(true, "error", "Something went wrong. " + err));
                        dispatch(utilOperations.reportError(err, "voucher:operations:handleSubmit Error"));
                        dispatch(handleGetVoucher(getState().voucherReducer.voucher.voucherKey));
                    });
        }
    };
};

const saveVoucher = (statusChangeCallback, elementSaving, shouldExit, validationOptions = {}) => {
    return (dispatch, getState) => {
        const { validateForm } = validationOptions;
        if (validateForm) {
            dispatch(validateVoucher());
            dispatch(callSaveVoucher(statusChangeCallback, elementSaving, shouldExit, validationOptions));
        } else {
            dispatch(callSaveVoucher(statusChangeCallback, elementSaving, shouldExit, validationOptions));
        }
    };
};

const callSaveVoucher = (statusChangeCallback, elementSaving, shouldExit, validationOptions = {}) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, elementSaving));
        let state = getState().voucherReducer;
        let role = getState().authReducer.userProfile.role;
        const { validateDocs, validateForm } = validationOptions;
        const documentRequirements = validateDocs
            ? validateRequiredDocuments(
                  get(state, "voucher.deal.transactionType"),
                  get(getState(), "documentsReducer.usedDocTypes", [])
              )
            : { toWarn: [], required: [] };
        if (documentRequirements.required.length > 0 && validateDocs) {
            dispatch(actions.addError("documents", "required"));
            dispatch(utils.actions.somethingSaving(false, elementSaving));
            if (state.validVoucher) {
                dispatch(utils.actions.snackbar(true, "error", "One or more required documents are not attached"));
            }
        }
        if (state.validVoucher !== false && !(documentRequirements.required.length > 0)) {
            dispatch(actions.clearErrors());
            dispatch(utils.actions.clearErrors());
            if (documentRequirements.toWarn.length > 0 && validateDocs) {
                dispatch(actions.documentWarning(true, false, documentRequirements.toWarn, statusChangeCallback));
                dispatch(utils.actions.somethingSaving(false, elementSaving));
            } else {
                //validate amounts
                const installmentNum = get(state, "voucher.installmentNum", 1) - 1;
                let total = 0;
                if (get(state, `voucher.voucherTransactionType`) === "Consulting") {
                    const installments = get(state, `voucher.deal.installments`, [{}]);
                    total = round(parseFloat(numeral(installments[0]["amount"]).format("0.00")), 2);
                } else {
                    total = round(
                        parseFloat(
                            numeral(get(state, `voucher.deal.installments[${installmentNum}].amount`, 0)).format("0.00")
                        ),
                        2
                    );
                }
                const validAmounts = validateAmounts(get(state, "voucher.brokerCommissions", []), total);

                if (validAmounts || (!validateForm && get(state, "voucher.voucherStatus", null) === "Draft")) {
                    const url = constants.urls.save_voucher(state.voucher.voucherKey);
                    const voucher = preparedVoucher(state.voucher);
                    request(
                        {
                            method: "put",
                            url: url,
                            data: JSON.stringify(voucher),
                            headers: { "Content-Type": "application/json" },
                        },
                        dispatch
                    )
                        .then((response) => {
                            if (statusChangeCallback) {
                                request(
                                    {
                                        url: statusChangeCallback,
                                        method: "put",
                                        crossDomain: true,
                                    },
                                    dispatch
                                )
                                    .then((response) => {
                                        dispatch(utils.actions.snackbar(true, "success", "Success."));
                                        if (shouldExit) {
                                            dispatch(
                                                push(
                                                    determineRedirectRoute(
                                                        role,
                                                        response.data.deal.transactionType,
                                                        response.data.deal.dealKey
                                                    )
                                                )
                                            );
                                        }
                                        dispatch(utils.actions.somethingSaving(false, elementSaving));
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        dispatch(
                                            utils.actions.snackbar(
                                                true,
                                                "error",
                                                "Couldn't change the voucher's status. " + err
                                            )
                                        );
                                        dispatch(utils.actions.somethingSaving(false, elementSaving));
                                        dispatch(
                                            utilOperations.reportError(
                                                err,
                                                "voucher:operations:callSaveVoucher(inner) Error"
                                            )
                                        );
                                    });
                            } else {
                                dispatch(utils.actions.somethingSaving(false, elementSaving));
                                if (shouldExit) {
                                    dispatch(
                                        push(
                                            determineRedirectRoute(
                                                role,
                                                response.data.deal.transactionType,
                                                state.voucher.deal.dealKey
                                            )
                                        )
                                    );
                                }
                            }
                            dispatch(utils.actions.snackbar(true, "success", "Success."));
                        })
                        .catch((err) => {
                            console.log(err);
                            dispatch(utils.actions.somethingSaving(false, elementSaving));
                            dispatch(
                                utils.actions.snackbar(true, "error", "Something went wrong. Couldn't save voucher.")
                            );
                            dispatch(
                                utilOperations.reportError(err, "voucher:operations:callSaveVoucher(outer) Error")
                            );
                        });
                } else {
                    //not valid commission amounts, dont add up
                    dispatch(utils.actions.somethingSaving(false, elementSaving));
                    dispatch(
                        utils.actions.snackbar(
                            true,
                            "error",
                            "Commission amounts must add up to the total of this installment."
                        )
                    );
                }
            }
        } else {
            dispatch(utils.actions.somethingSaving(false, elementSaving));
        }
    };
};

const determineRedirectRoute = (role, transactionType, dealKey) => {
    if (role === "accounting") {
        return `/vouchers`; // Redirect to vouchers if accounting (they came from vouchers screen)
    } else if (transactionType === "Consulting") {
        return `/deals`; // Redirect to deals if consulting deal
    } else {
        return `/deal/${dealKey}`; // Redirect to deal summary instead of loading the new status
    }
};

const validateAmounts = (commissions, paymentTotal) => {
    let total = 0;
    each(commissions, (c) => {
        total += round(parseFloat(numeral(get(c, "netAmount", 0)).format("0.00")), 2);
    });

    return round(total, 2) === round(paymentTotal, 2);
};

const preparedVoucher = (voucher) => {
    let builtVoucher = {
        ...voucher,
        submittedDate: utils.operations.serializeLocalDate(voucher.submittedDate),
        deal: {
            ...voucher.deal,
            installments: buildInstallmentsFromDeal(voucher.deal),
            transactionDate: utils.operations.serializeLocalDate(get(voucher, "deal.transactionDate", null)),
            commencementDate: utils.operations.serializeLocalDate(get(voucher, "deal.commencementDate", null)),
            expirationDate: utils.operations.serializeLocalDate(get(voucher, "deal.expirationDate", null)),
            totalConsiderationAmount: numeral(get(voucher, "deal.totalConsiderationAmount", null)).format("0.00"),
            totalKmCommission: numeral(get(voucher, "deal.totalKmCommission", null)).format("0.00"),
            outsideBrokerCommission: numeral(get(voucher, "deal.outsideBrokerCommission", null)).format("0.00"),
            totalCommission: numeral(get(voucher, "deal.totalCommission", null)).format("0.00"),
            listing: {
                ...voucher.deal.listing,
                dateListed: utils.operations.serializeLocalDate(get(voucher, "deal.dateListed", null)),
                leaseExpirationDate: utils.operations.serializeLocalDate(
                    get(voucher, "deal.leaseExpirationDate", null)
                ),
                dateAvailable: utils.operations.serializeLocalDate(get(voucher, "deal.dateAvailable", null)),
                expirationDate: utils.operations.serializeLocalDate(get(voucher, "deal.expirationDate", null)),
            },
        },
        approvedDate: utils.operations.serializeLocalDate(get(voucher, "approvedDate", null)),
        acceptedDate: utils.operations.serializeLocalDate(get(voucher, "acceptedDate", null)),
        readyDate: utils.operations.serializeLocalDate(get(voucher, "readyDate", null)),
        filedDate: utils.operations.serializeLocalDate(get(voucher, "filedDate", null)),
        brokerCommissions: packBrokerCommissions(get(voucher, "brokerCommissions", {})),
    };
    return builtVoucher;
};

const serializeVoucherDates = (voucher) => {
    let builtVoucher = {
        ...voucher,
        submittedDate: utils.operations.serializeLocalDate(voucher.submittedDate),
        approvedDate: utils.operations.serializeLocalDate(voucher.approvedDate),
        acceptedDate: utils.operations.serializeLocalDate(voucher.acceptedDate),
        datePaid: utils.operations.serializeLocalDate(voucher.datePaid),
        readyDate: utils.operations.serializeLocalDate(get(voucher, "readyDate", null)),
        filedDate: utils.operations.serializeLocalDate(get(voucher, "filedDate", null)),
    };
    return builtVoucher;
};

const packBrokerCommissions = (commissions, voucherKey) => {
    let commissionsObject = cloneDeep(commissions);
    each(Object.keys(commissionsObject), (x) => {
        let c = cloneDeep(commissionsObject[x]);
        let p = {
            netPercent: c.netPercent,
            netAmount: numeral(c.netAmount).format("0.00"),
            representationType: c.representationType,
            department: c.department,
            brokerAgent: {
                brokerAgentKey: c.brokerAgentKey,
                kmBrokerFlag: c.kmBrokerFlag,
                person: {
                    personKey: c.personKey,
                    firstName: c.firstName,
                    lastName: c.lastName,
                },
            },
            company: c.company,
            commissionType: c.commissionType === "REFERRAL" ? c.commissionType : null,
            commissionComment: c.commissionComment,
        };
        if (c.runnerCommissions && Object.keys(c.runnerCommissions).length > 0) {
            p.runnerCommissions = packRunners(c.runnerCommissions);
        }
        commissionsObject[x] = cloneDeep(p);
    });
    let commissionArray = utils.operations.objectToArray(commissionsObject, "brokerCommissionKey");
    each(commissionArray, (broker) => {
        if (startsWith(broker.brokerCommissionKey, "draft")) {
            broker.brokerCommissionKey = null;
        }
    });
    return commissionArray;
};

const packRunners = (runners) => {
    Object.keys(runners).forEach((x) => {
        const r = cloneDeep(runners[x]);
        let i = 0;
        runners[x] = {
            percentOfBrokerNet: r.percentOfBrokerNet,
            amount: numeral(r.amount).format("0.00"),
            orderSeq: i++,
            runner: {
                personKey: r.personKey ? r.personKey : get(r, "runner.personKey"),
                firstName: r.firstName,
                lastName: r.lastName,
            },
        };
    });
    let runnerArray = utils.operations.objectToArray(runners, "runnerCommissionKey");
    each(runnerArray, (runner) => {
        if (startsWith(runner.runnerCommissionKey, "draft")) {
            runner.runnerCommissionKey = null;
        }
    });
    return runnerArray;
};

const createOutsideSplitDraft = (isFreeformSearchEnabled, commissionType) => {
    return (dispatch, getState) => {
        const key = "draft-" + shortid.generate();
        const newSplit = {
            kmBrokerFlag: false,
            firstName: "",
            lastName: "",
            agentRepresentation: "tenant",
            department: null,
        };
        dispatch(
            toggleOutsideSplitModal({
                key: key,
                split: newSplit,
                secondaryAction: "Cancel",
                isFreeformSearchEnabled,
                commissionType,
            })
        );
    };
};

const saveOutsideSplit = (key, split) => {
    return (dispatch, getState) => {
        dispatch(actions.clearModalErrors());
        const errors = validateSplitModal(getState().voucherReducer.outsideSplitModal, key);
        if (isEmpty(errors) || isNil(errors)) {
            createCompany(packCompany(split), dispatch)
                .then((response) => {
                    handleCompanyResponsePayload(split, response.data);
                    dispatch(actions.saveOutsideSplit(key, split));
                    dispatch(toggleOutsideSplitModal({}));
                })
                .catch((err) => {
                    dispatch(utilOperations.reportError(err, "voucher:operations:saveOutsideSplit Error"));
                    console.log("Couldn't create company", err);
                });
        } else {
            forIn(errors, (value, key) => {
                dispatch(actions.addError("outsideSplitModal." + key, value));
            });
        }
    };
};

const packCompany = (split) => {
    return {
        companyKey: get(split, "companyKey"),
        mainAddress: {
            key: get(split, "companyAddressKey"),
            address1: get(split, "companyAddress"),
            city: {
                city: get(split, "companyCity"),
                key: get(split, "companyCityKey"),
                state: {
                    stateCode: get(split, "companyState"),
                },
            },
            zipCode: {
                key: get(split, "companyZipKey"),
                zipCode: get(split, "companyZip"),
            },
        },
        displayName: get(split, "companyName"),
        legalName: get(split, "companyName"),
        dbaName: get(split, "companyName"),
        activeFlg: true,
        internalFlg: false,
        parentFlg: false,
        publicFlg: true,
        approvedFlg: get(split, "approvedFlg"),
        companyTypes: get(split, "companyTypes"),
    };
};

const handleCompanyResponsePayload = (split, data) => {
    set(split, "companyKey", data.companyKey);
    set(split, "company.companyKey", data.companyKey);
    set(split, "companyAddressKey", data.mainAddress.key);
    set(split, "companyCityKey", data.mainAddress.city.key);
    set(split, "companyZipKey", data.mainAddress.zipCode.key);
};

const createCompany = (split, dispatch) => {
    return request(
        {
            method: "post",
            url: constants.urls.create_company(),
            data: JSON.stringify(split),
            headers: { "Content-Type": "application/json" },
        },
        dispatch
    );
};

const deleteOutsideSplit = (splitKey) => {
    return (dispatch, getState) => {
        let newOutsideSplits = cloneDeep(getState().voucherReducer.voucher.brokerCommissions);
        delete newOutsideSplits[splitKey];
        dispatch(actions.deleteOutsideSplit(newOutsideSplits));
        dispatch(toggleOutsideSplitModal({}));
    };
};

const handleAddBrokerCommission = () => {
    return (dispatch, getState) => {
        const key = "draft-" + shortid.generate();
        const draft = {
            [key]: {
                brokerCommissionKey: "",
                runnerCommissions: {},
                kmBrokerFlag: true,
                brokerAgentKey: "",
                firstName: "",
                lastName: "",
            },
        };
        dispatch(actions.addBrokerCommission(draft));
    };
};

const handleAddRunner = (parent) => {
    return (dispatch, getState) => {
        const key = "draft-" + shortid.generate();
        const runner = {
            [key]: {
                percentOfBrokerNet: null,
                amount: null,
                brokerAgentKey: "",
                firstName: "",
                lastName: "",
            },
        };
        dispatch(actions.addRunner(parent, runner));
    };
};

const updateSplit = (splitKey, field, value, source) => {
    return (dispatch, getState) => {
        dispatch(actions.updateSplit(splitKey, field, value, source));
        dispatch(calculateSplit(splitKey, field, value, source));
    };
};

const updateRunner = (parent, key, field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateRunner(parent, key, field, value));
    };
};

const toggleOutsideSplitModal = (options) => {
    return (dispatch, getState) => {
        dispatch(
            actions.toggleOutsideSplitModal(
                !getState().voucherReducer.outsideSplitModal.isOpen,
                options.key,
                options.split,
                options.secondaryAction,
                options.isFreeformSearchEnabled,
                options.commissionType
            )
        );
        dispatch(actions.clearErrors());
    };
};

const handleRemoveCommissionOrRunner = (key, parent, outside) => {
    return (dispatch, getState) => {
        let newCommissions = cloneDeep(getState().voucherReducer.voucher.brokerCommissions);

        if (parent) {
            delete newCommissions[parent]["runnerCommissions"][key];
            dispatch(actions.removeCommission(newCommissions));
        } else {
            if (!startsWith(key, "draft")) {
                request(
                    {
                        method: "delete",
                        url: constants.urls.delete_commission(key),
                    },
                    dispatch
                )
                    .then((response) => {
                        delete newCommissions[key];
                        dispatch(actions.removeCommission(newCommissions));
                    })
                    .catch((err) => {
                        dispatch(utils.actions.snackbar(true, "error", "Something went wrong. Couldn't delete split."));
                        dispatch(
                            utilOperations.reportError(err, "voucher:operations:handleRemoveCommissionOrRunner Error")
                        );
                    });
            } else {
                delete newCommissions[key];
                dispatch(actions.removeCommission(newCommissions));
            }
        }
    };
};

const voucherChange = (field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.voucherChange(field, value));
    };
};

const deleteVoucher = (voucherKey) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.delete_voucher(voucherKey),
                method: "delete", //put here because we are really updating status
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(push(`/deals`));
            })
            .catch((err) => {
                console.log("error occurred!" + err);
                dispatch(
                    utils.actions.snackbar(true, "error", "Something unexpected went wrong. Unable to delete voucher.")
                );
                dispatch(utilOperations.reportError(err, "deal:operations: Delete Voucher Error"));
            });
    };
};

const calculateSplit = (splitKey, field, value, source) => {
    return (dispatch, getState) => {
        let netPercent, netAmount, calculatedPercent, calculatedAmount;
        const installmentNum = get(getState(), "voucherReducer.voucher.installmentNum", 1) - 1;
        let total = parseFloat(
            numeral(get(getState(), `voucherReducer.voucher.deal.installments[${installmentNum}].amount`, 0)).format(
                "0.00"
            )
        );
        if (total) {
            switch (field) {
                case "netPercent":
                    netPercent = parseFloat(value);
                    calculatedAmount = utils.operations.round((netPercent / 100) * total, 2);
                    if (!isNaN(calculatedAmount)) {
                        dispatch(
                            actions.updateSplit(
                                splitKey,
                                "netAmount",
                                numeral(calculatedAmount).format("0,000.00"),
                                source
                            )
                        );
                    }
                    break;
                case "netAmount":
                    netAmount = parseFloat(numeral(value).format("0.00"));
                    calculatedPercent = utils.operations.round((netAmount / total) * 100, 2);
                    if (!isNaN(calculatedPercent)) {
                        dispatch(actions.updateSplit(splitKey, "netPercent", calculatedPercent, source));
                    }
                    break;
                default:
                    netPercent = undefined;
                    netAmount = undefined;
            }
        }
    };
};

const voucherChangeAndCalculateSplits = (field, value, km) => {
    return (dispatch, getState) => {
        dispatch(handleInput("voucher.deal." + field, value));
        const commissions = getState().voucherReducer.voucher.brokerCommissions;
        each(Object.keys(commissions), (c) => {
            if (km === commissions[c].kmBrokerFlag) {
                dispatch(calculateSplit(c, "netPercent", commissions[c]["netPercent"], "voucher", km));
            }
        });

        let kmCommission = parseFloat(
            numeral(get(getState(), `voucherReducer.voucher.deal.totalKmCommission`, 0)).format("0.00")
        );
        let outsideCommission = parseFloat(
            numeral(get(getState(), `voucherReducer.voucher.deal.outsideBrokerCommission`, 0)).format("0.00")
        );

        dispatch(handleInput("voucher.deal.totalCommission", kmCommission + outsideCommission));
    };
};

const handleCalculateSplits = () => {
    return (dispatch, getState) => {
        const commissions = getState().voucherReducer.voucher.brokerCommissions;
        each(Object.keys(commissions), (c) => {
            dispatch(calculateSplit(c, "netPercent", commissions[c]["netPercent"], "voucher"));
        });
    };
};

const validateVoucher = () => {
    return (dispatch, getState) => {
        dispatch(actions.clearErrors());
        let valid = true;
        const voucher = getState().voucherReducer.voucher;
        const validationResponse = getState().voucherReducer.outsideSplitsPresent
            ? validation.validateVoucher(voucher)
            : validation.validateVoucherNoOutside(voucher);
        if (validationResponse) {
            valid = false;
            each(Object.keys(validationResponse), (e) => {
                dispatch(actions.addError(`${e}`, validationResponse[e][0]));
            });
        }
        const currentInstallment = get(voucher, "installmentNum", null);
        const installmentValidationResponse = validation.validateInstallments(
            get(voucher, `deal.installments`, null),
            currentInstallment
        );
        if (installmentValidationResponse) {
            valid = false;
            dispatch(actions.addError(`deal.installments`, installmentValidationResponse));
        }
        if (!valid) {
            dispatch(utils.actions.snackbar(true, "error", "One or more fields is invalid"));
        }
        dispatch(validateSplits(valid));
    };
};

const validateSplits = (currentlyValid) => {
    return (dispatch, getState) => {
        const splits = get(getState(), "voucherReducer.voucher.brokerCommissions", {});
        if (Object.keys(splits).length === 0) {
            dispatch(utils.actions.snackbar(true, "error", "No commissions present."));
            dispatch(actions.validVoucher(false));
        } else {
            each(Object.keys(splits), (s) => {
                let validationResponse = null;
                if (splits[s]["kmBrokerFlag"]) {
                    validationResponse = validation.validateSplit(splits[s]);
                } else {
                    validationResponse = validation.validateOutsideSplit(splits[s]);
                }

                if (validationResponse) {
                    if (currentlyValid === true) {
                        dispatch(utils.actions.snackbar(true, "error", "One or more fields is invalid"));
                    }
                    currentlyValid = false;
                    each(Object.keys(validationResponse), (e) => {
                        dispatch(actions.addError(`${s}.${e}`, validationResponse[e][0]));
                    });
                }

                if (splits[s].runnerCommissions) {
                    dispatch(validateRunners(splits[s].runnerCommissions, s, currentlyValid));
                }
                dispatch(actions.validVoucher(currentlyValid));
            });
        }
    };
};

const validateRunners = (runners, split, currentlyValid) => {
    return (dispatch, getState) => {
        each(Object.keys(runners), (r) => {
            let validationResponse = validation.validateRunner(runners[r]);
            if (validationResponse) {
                if (currentlyValid === true) {
                    dispatch(utils.actions.snackbar(true, "error", "One or more fields is invalid"));
                }
                currentlyValid = false;
                each(Object.keys(validationResponse), (e) => {
                    dispatch(actions.addError(`${split}.runners.${r}.${e}`, validationResponse[e][0]));
                });
                dispatch(actions.validVoucher(false));
            } else {
                dispatch(actions.validVoucher(currentlyValid));
            }
        });
        dispatch(actions.validVoucher(currentlyValid));
    };
};

const validateSplitModal = (data, key) => {
    const validationResponse = validation.validateSplitModal(data.brokerCommissions[key], data.commissionType);
    return validationResponse;
};

const searchPerson = (input) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.find_brokers_with_name_starting_with(input),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                let data = response.data;
                let count = 0;
                data = data.filter((suggestion) => {
                    const keep = count < 5;

                    if (keep) {
                        count += 1;
                    }
                    return keep;
                });
                dispatch(actions.searchResults(data));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Something went wrong. Unable to search."));
            });
    };
};

const searchCompany = (input) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.find_companies_with_name_starting_with(input),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                let data = response.data;
                let count = 0;
                data = data.filter((suggestion) => {
                    const keep = count < 5;

                    if (keep) {
                        count += 1;
                    }
                    return keep;
                });
                dispatch(actions.searchResults(data));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Something went wrong. Unable to search."));
            });
    };
};

const validateRequiredDocuments = (transactionType, usedDocTypes) => {
    let documentValidationResults = {
        required: [],
        toWarn: [],
    };

    let valid = true;
    const voucherType = toLower(transactionType);
    const docTypes = documentTypes[voucherType];
    if (docTypes) {
        Object.keys(docTypes).forEach((j, _) => {
            if (valid && !usedDocTypes.includes(j) && docTypes[j]["required"]) {
                documentValidationResults.required.push(j);
                valid = false;
            }
        });
        documentValidationResults.toWarn = difference(documentTypes.warning[voucherType], usedDocTypes);
    } else {
        valid = false;
    }
    return documentValidationResults;
};

const handleDocumentWarning = (open, proceed, docs, callback) => {
    return (dispatch, getState) => {
        dispatch(actions.documentWarning(open, proceed, docs));
    };
};

const snackbar = (open, variant, message) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.snackbar(open, variant, message));
    };
};

const handleInput = (path, value) => {
    return (dispatch, _) => {
        dispatch(actions.voucherInput(path, value));
    };
};

const buildInstallmentsFromDeal = (deal) => {
    if (!deal.installments || isEmpty(deal.installments)) {
        return [];
    } else {
        const installments = map(deal.installments, (inst) => {
            return {
                ...inst,
                amount: numeral(inst.amount).format("0.00"),
                dueDate: utils.operations.serializeLocalDate(inst.dueDate),
            };
        });
        return installments;
    }
};

const addArrayElement = (pathToArray, obj = {}) => {
    return (dispatch, getState) => {
        let newArray = get(getState().voucherReducer, pathToArray, []).slice();
        newArray.push(obj);
        dispatch(actions.setArray(pathToArray, newArray));
    };
};

const deleteArrayElement = (pathToArray, index) => {
    return (dispatch, getState) => {
        let newArray = get(getState().voucherReducer, pathToArray, []).slice();
        newArray.splice(index, 1);
        dispatch(actions.setArray(pathToArray, newArray));
    };
};

const displayDeleteDialog = (isOpen, dealKey) => {
    return (dispatch, getState) => {
        dispatch(actions.updateDeleteDealDialog(isOpen));
    };
};

const togglePropertySearchModal = (isOpen) => {
    return (dispatch, getState) => {
        dispatch(property_operations.togglePropertySearchModal(isOpen));
    };
};

const updatePropertyForConsulting = (dealKey, propertyKey, user) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));

        request(
            {
                url: constants.urls.update_consulting_deal_property(dealKey, propertyKey),
                method: "put",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(property_operations.togglePropertySearchModal(false));
                dispatch(property_operations.toggleUpdateDialog(false));
                dispatch(utils.actions.snackbar(true, "success", "Property Updated"));
                //Need to call or access updated voucher to display updated property
                dispatch(loadNewConsultingDealProperty(response.data));
            })
            .catch((err) => {
                console.log(err);
            });
        dispatch(actions.updateConsultingRequest(false));
    };
};

const loadNewConsultingDealProperty = (property) => {
    return (dispatch, getState) => {
        dispatch(actions.loadNewConsultingDealProperty(property));
    };
};

const clearPropertyUpdate = () => {
    return (dispatch, getState) => {
        dispatch(actions.clearPropertyUpdate());
    };
};

export default {
    startVoucher,
    saveVoucher,
    handleGetVoucher,
    handleAddBrokerCommission,
    handleAddRunner,
    handleRemoveCommissionOrRunner,
    updateSplit,
    updateRunner,
    createOutsideSplitDraft,
    saveOutsideSplit,
    deleteOutsideSplit,
    toggleOutsideSplitModal,
    voucherChange,
    voucherChangeAndCalculateSplits,
    validateVoucher,
    validateSplits,
    searchPerson,
    searchCompany,
    validateRequiredDocuments,
    snackbar,
    handleDocumentWarning,
    handleSubmit,
    unpackRunner,
    unpackBrokerCommission,
    handleInput,
    addArrayElement,
    deleteArrayElement,
    deleteVoucher,
    createVoucher,
    serializeVoucherDates,
    handleCalculateSplits,
    handleDeleteDeal,
    displayDeleteDialog,
    togglePropertySearchModal,
    updatePropertyForConsulting,
    loadNewConsultingDealProperty,
    clearPropertyUpdate,
};
