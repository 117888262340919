import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ErrorBoundary from "../../../../common/js/components/ErrorBoundary";
import MultiAddInput from "../../../../common/js/components/MultiAddInput";
import MixedUseDetail from "./MixedUseDetailBeta";

const MixedUseForm = (props) => {
    const {
        data,
        dataPathPrefix,
        disableInputs,
        handleAddArrayElement,
        handleDeleteArrayElement,
        handleUpdateArrayElement,
        onChange,
    } = props;

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                    <span className="switchTitle">Mixed Use</span>
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={data.mixedUseFlg}
                                onChange={(e, checked) => onChange(e.target.value, checked)}
                                color="primary"
                                value="mixedUseFlg"
                                disabled={disableInputs === true}
                                className="theSwitch"
                            />
                        }
                    />
                </div>
            </div>
            {data.mixedUseFlg && (
                <ErrorBoundary>
                    <MultiAddInput
                        addButtonText="Add Property Type"
                        disableInputs={disableInputs === true}
                        displayFirstWhenEmpty={true}
                        elements={data.mixedUses}
                        onAdd={() => handleAddArrayElement(dataPathPrefix + ".mixedUses")}
                        onUpdate={(e, index) =>
                            handleUpdateArrayElement(dataPathPrefix + ".mixedUses", index, e.target.id, e.target.value)
                        }
                        render={(element, elementIndex) => (
                            <MixedUseDetail
                                {...props}
                                element={element}
                                elementIndex={elementIndex}
                                onDelete={(index) => handleDeleteArrayElement(dataPathPrefix + ".mixedUses", index)}
                            />
                        )}
                    />
                </ErrorBoundary>
            )}
        </div>
    );
};

export default MixedUseForm;
