import validate from "validate.js";
import { constraints } from "../../utils/validation";

const contactValidationSchema = {
    firstName: {
        ...constraints.required,
    },
    lastName: {
        ...constraints.required,
    },
    contactKey: {
        ...constraints.required,
    },
    address1: {
        ...constraints.required,
    },
    city: {
        ...constraints.required,
    },
    state: {
        ...constraints.required,
    },
    phoneNumber: {
        ...constraints.required,
    },
    emailAddress: {
        ...constraints.required,
    },
    zipCode: {
        ...constraints.required,
    },
};

const validateContact = (contact) => {
    return validate(contact, contactValidationSchema, { fullMessages: false });
};

export default {
    validateContact,
};
