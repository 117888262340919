import configs from "../../../config";

const ADMIN_URL = configs.HOST_URL + "/admin";

const urls = {
    active_users: (term, searchType) => {
        return ADMIN_URL + "/search/user/attributes?term=" + term + "&active=true&searchType=" + searchType;
    },
    inactive_users: (term, searchType) => {
        return ADMIN_URL + "/search/user/attributes?term=" + term + "&active=false&searchType=" + searchType;
    },
    unapproved_companies: (term, companyType) => {
        return (
            ADMIN_URL +
            "/company/search/attributes?term=" +
            encodeURIComponent(term) +
            "&companyType=" +
            companyType +
            "&approved=false"
        );
    },
    approved_companies: (term, companyType) => {
        return (
            ADMIN_URL +
            "/company/search/attributes?term=" +
            encodeURIComponent(term) +
            "&companyType=" +
            companyType +
            "&approved=true"
        );
    },
    user_office_history: (userAccountKey) => {
        return ADMIN_URL + "/user/history/" + userAccountKey;
    },
    user_office_save: (userAccountKey) => {
        return ADMIN_URL + "/officehistory/" + userAccountKey;
    },
    create_company: () => {
        return ADMIN_URL + "/company/";
    },
    delete_company: (companyKey) => {
        return ADMIN_URL + "/company/" + companyKey;
    },
    find_company_by_id: (id) => {
        return ADMIN_URL + "/company/" + id;
    },
    merge_companies: () => {
        return ADMIN_URL + "/company/merge";
    },
};

export default {
    urls,
};

export const user_search_options = [
    {
        value: "name",
        label: "Name/Username",
    },
    {
        value: "office",
        label: "Office",
    },
    {
        value: "role",
        label: "User Type",
    },
];

export const company_type_options = [
    {
        value: "BUSINESS",
        label: "Business",
        disabled: false,
    },
    {
        value: "REAL_ESTATE",
        label: "Real Estate",
        disabled: false,
    },
    {
        value: "CONTACT",
        label: "Contact",
        disabled: false,
    },
    {
        value: "OUTSIDE_COMP",
        label: "Outside Comp",
        disabled: false,
    },
    {
        value: "APPRAISAL",
        label: "Appraisal",
        disabled: false,
    },
    {
        value: "PROPERTY_MANAGEMENT",
        label: "Asset Services",
        disabled: false,
    },
];
