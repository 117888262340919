import get from "lodash/get";
import each from "lodash/each";

const viewHeaders = {
    default_headers: [
        {
            name: "LEGAL NAME",
            prop: "legalName",
            type: "plaintext",
        },
        {
            name: "COMMON NAME",
            prop: "displayName",
            type: "plaintext",
        },
        {
            name: "INSTITUTIONAL OWNER",
            prop: "parentCompany.displayName",
            type: "plaintext",
        },
        {
            name: "TYPE",
            prop: "companyTypes",
            type: "plaintext",
            transform: (company) => {
                const companyTypes = get(company, "companyTypes", []);
                if (companyTypes != null && companyTypes.length > 0) return companyTypes;
                else return null;
            },
        },
        {
            name: "COMPANY KEY",
            prop: "companyKey",
            type: "plaintext",
        },
    ],
    userOfficeHistory: [
        {
            name: "Office",
            prop: "currentOfficeName",
            type: "plaintext",
        },
        {
            name: "Start Date",
            prop: "officeStartDate",
            type: "plaintext",
        },
        {
            name: "End Date",
            prop: "officeEndDate",
            type: "plaintext",
        },
    ],
};

const columnsToQueryFilter = ["type", "legalName", "displayName", "parentCompany.displayName"];

const filterByQuery = (data, columnsToQuery, query) => {
    let included = false;
    each(columnsToQuery, (column) => {
        if (get(data, column)) {
            if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                included = true;
            }
        }
    });
    return included;
};

export default {
    viewHeaders,
    columnsToQueryFilter,
    filterByQuery,
};
