import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTypeAdmin from "./DocumentTypeAdmin";
import operations from "./ducks/operations";
import { includes } from "lodash";
import { push } from "connected-react-router";
import ReactGA from "react-ga4";

class CreateDocumentTypeContainer extends Component {
    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: "/document-type/create", title: "Document Admin Creation" });
    }

    render() {
        const { role } = this.props;
        if (includes(["dataAdmin"], role)) {
            return (
                <div>
                    <DocumentTypeAdmin {...this.props} />
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        stateDocumentRequirements: state.stateDocumentRequirementsReducer.stateDocumentRequirements,
        role: state.authReducer.userProfile.role,
        errors: state.stateDocumentRequirementsReducer.errors,
        savedDocuments: state.stateDocumentRequirementsReducer.savedDocuments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleInputChange: (field, value) => {
            dispatch(operations.handleInputChange(field, value));
        },
        handleSaveAll: (documentTypeKey, updatedDocumentType) => {
            dispatch(operations.createNewDocumentType(updatedDocumentType));
        },
        handleDeleteCancelDocumentType: (documentTypeKey) => {
            dispatch(push("/document-type"));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocumentTypeContainer);
