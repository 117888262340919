import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import ResearchSnapshotTable from "./ResearchSnapshotTable";

const ResearchSnapshot = (props) => {
    const { classes, role, snapshot, editResponse } = props;
    return (
        <div className={classes.container}>
            <Grid container justify="flex-end" alignItems="center" spacing={8} style={{ marginBottom: "10px" }}>
                <Grid item>
                    <div className={classes.buttons}></div>
                </Grid>
            </Grid>
            <Divider />
            <ResearchSnapshotTable editResponse={editResponse} snapshots={snapshot} role={role} />
        </div>
    );
};

const styles = (theme) => ({
    container: {
        minHeight: "100px",
        border: "1px solid #dbdbdb",
        padding: "15px",
    },
    buttons: {
        alignSelf: "center",
        marginRight: "20px",
    },
});

export default withStyles(styles)(ResearchSnapshot);
