import types from "./types";

export default (
    state = {
        snackbar: {
            open: false,
        },
        notification: {
            open: false,
        },
        confirmDialog: {
            open: false,
            onSubmit: null,
        },
        errors: {},
        searchResults: [],
        isSaving: false,
        loading: {},
        elementsSaving: {},
        panelsToExpand: [],
        checkErrorPanels: false,
    },
    action
) => {
    switch (action.type) {
        case types.API_RESPONSE:
            return Object.assign({}, state, {
                payload: action.payload,
                status: action.status,
            });
        case types.PAGE_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case types.PAGE_LOADING_2:
            return {
                ...state,
                isLoading2: action.isLoading,
            };
        case types.LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.name]: action.isLoading,
                },
            };
        case types.SNACKBAR:
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    open: action.open,
                    message: action.message,
                    variant: action.variant,
                },
            };
        case types.NOTIFICATION:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    open: action.open,
                    message: action.message,
                },
            };
        case types.SEARCH_RESULTS:
            return {
                ...state,
                searchResults: action.results,
            };
        case types.TOGGLE_CONFIRM:
            return {
                ...state,
                confirmDialog: {
                    open: action.open,
                    onSubmit: action.onSubmit,
                },
            };
        case types.RESET_CONFIRM:
            return {
                ...state,
                confirmDialog: {
                    open: false,
                    onSubmit: null,
                },
            };
        case types.IS_SAVING:
            return {
                ...state,
                isSaving: action.isSaving,
            };
        case types.SOMETHING_SAVING:
            return {
                ...state,
                elementsSaving: {
                    ...state.elementsSaving,
                    [action.element]: action.isSaving,
                },
            };
        case types.ADD_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.field]: action.error,
                },
            };
        case types.CLEAR_ERROR_BY_FIELD:
            const newState = { ...state };
            delete newState.errors[action.field];
            return newState;
        case types.CLEAR_ERRORS:
            return {
                ...state,
                errors: {},
            };
        case types.SET_ERROR_PANELS_EXPANDED:
            return {
                ...state,
                panelsToExpand: action.panelsToExpand,
            };
        case types.TOGGLE_CHECK_ERROR_PANELS:
            return {
                ...state,
                checkErrorPanels: action.value,
            };
        default:
            return state;
    }
};
