import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import FormTitle from "../../../common/js/components/FormTitle";
import { us_states_options } from "../utils/constants";
import Autocomplete from "../../../common/js/components/Autocomplete";
import KMSwitch from "../../../common/js/components/KMSwitch";
import Search from "../../../../node_modules/@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import Delete from "../../../../node_modules/@material-ui/icons/Delete";
import get from "lodash/get";
import AutocompleteFreeform from "../../../common/js/components/AutocompleteFreeform";

const styles = (theme) => ({
    iconOnly: {
        marginLeft: 0,
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
    clearLink: {
        textTransform: "none",
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
        },
    },
    invoiceRecipient: {
        paddingTop: "16px",
    },
    phoneOptions: {
        "& label": {
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.54)",
        },
        position: "absolute",
        width: "fit-content",
        left: "60%",
        top: "0px",
    },
    phoneContainer: {
        display: "flex",
        position: "relative",
    },
    phoneRadio: {
        flexWrap: "nowrap",
        paddingTop: "18px",
        paddingLeft: "10px",
    },
});
class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disableContactTypeInput: true,
        };
    }

    onCompanyStateChange = (changes, helpers, property) => {
        if (changes.type === "__autocomplete_change_input__") {
            //refactor 1.22.19 NDP-144 p.daniels
            this.props.handleInput("company.displayName", changes.inputValue);
            this.props.handleInput("company.companyKey", null);
            this.props.handleInput("company.legalName", changes.inputValue);
            this.props.handleInput("company.parentCompany", null);
            this.props.handleSearchCompany(changes.inputValue);
        }

        if (changes.type === "__autocomplete_click_item__") {
            this.handleCompanyChange(changes.selectedItem);
        }
    };

    selectCompany = (company) => {
        this.props.handleInput(`company`, company);
    };

    onSearch = (input, helpers) => {
        this.props.handleSearchCompany(input);
    };

    handleCompanyChange = (company) => {
        this.props.handleInput("company.companyKey", company["companyKey"]);
        this.props.handleInput("company.legalName", company["legalName"]);
        this.props.handleInput("company.displayName", company["displayName"]);
        this.props.handleInput("company.parentCompany", company["parentCompany"]);
    };

    clearCompany = () => {
        this.props.handleInput(`company`, {});
    };

    freeformUpdateCompany = (value) => {
        if (get(this.props.contact, `company.companyKey`, null)) {
            this.clearCompany();
        }
        this.props.handleInput("company.displayName", value);
        this.props.handleInput("company.companyTypes", ["CONTACT"]);
        this.props.handleInput("company.approvedFlg", true);
    };

    handleContactTypeInput = (e) => {
        if (e.target.value === "OTHER") {
            this.setState({
                disableContactTypeInput: false,
            });
        } else {
            this.setState({
                disableContactTypeInput: true,
            });
        }
        this.props.handleInput(e.target.id, e.target.value);
    };

    componentDidMount = () => {
        if (!this.props.contactTypeOptions.map((type) => type.value).includes(get(this.props.contact, "contactType"))) {
            this.setState({
                disableContactTypeInput: false,
            });
        }
    };

    render() {
        const {
            errors,
            title,
            contact,
            contactIndex,
            classes,
            allowDelete,
            handleDelete,
            disableInputs,
            contactTypeOptions,
            companyFieldAvailable,
            includeInvoiceRecipientCheckbox,
            handleSearchCompany,
            autocompleteResults,
            isSnapshot,
            location,
            handleInput,
            key,
            freeFormCompany,
        } = this.props;

        return (
            <form key={key}>
                {!this.props.hideTitle && (
                    <Grid container alignItems="center" spacing={8}>
                        <Grid item xs>
                            <FormTitle title={title} />
                        </Grid>
                        <Grid item xs={1}>
                            {allowDelete && disableInputs !== true && (
                                <Button onClick={() => handleDelete(contactIndex)}>
                                    <Delete />
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                )}

                <Grid container spacing={32}>
                    <Grid item xs={includeInvoiceRecipientCheckbox ? 8 : 12}>
                        <BSSelect
                            id="contactType"
                            label="Contact Type"
                            value={get(contact, "contactType")}
                            error={get(errors, `contact.${contactIndex}.contactType`, null)}
                            onChange={(e) => this.handleContactTypeInput(e)}
                            options={contactTypeOptions}
                            disabled={disableInputs === true}
                        />
                        {location.pathname.includes("propdetail") && (
                            <div style={{ display: this.state.disableContactTypeInput ? "none" : "" }}>
                                <BSInput
                                    id="contactType"
                                    label="Custom Contact Type"
                                    placeholder="Enter contact type"
                                    value={get(contact, "contactType")}
                                    error={get(errors, `contact.${contactIndex}.contactType`, null)}
                                    onChange={(e) => handleInput(e.target.id, e.target.value)}
                                    disabled={this.state.disableContactTypeInput || disableInputs === true}
                                />
                            </div>
                        )}
                    </Grid>

                    {includeInvoiceRecipientCheckbox && (
                        <Grid item xs={4}>
                            <FormControlLabel
                                className={classes.invoiceRecipient}
                                control={
                                    <KMSwitch
                                        checked={
                                            get(contact, "invoiceRecipientFlg")
                                                ? get(contact, "invoiceRecipientFlg")
                                                : false
                                        }
                                        onChange={(e) => handleInput("invoiceRecipientFlg", e.target.checked)}
                                        value={get(contact, "invoiceRecipientFlg")}
                                    />
                                }
                                label="Invoice Recipient?"
                            />
                        </Grid>
                    )}
                </Grid>
                {isSnapshot ? (
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <BSInput
                                id="company"
                                label="Company Name (Common)"
                                placeholder="N/A"
                                value={get(contact, "companyName")}
                                disabled
                            />
                        </Grid>
                    </Grid>
                ) : freeFormCompany ? (
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <AutocompleteFreeform
                                id="company"
                                label={"Company Name (Common)"}
                                error={get(errors, `contact.company.companyKey`, null)}
                                initialInputValue={`${get(contact, `company.displayName`, "")}`}
                                itemToString={(i) => (i == null ? "" : get(i, "displayName", ""))}
                                onSelect={(company) => this.selectCompany(company)}
                                placeholder="Enter company name (common)"
                                searchFunction={(input) => handleSearchCompany(input, "CONTACT")}
                                startAdornment={<Search />}
                                suggestions={autocompleteResults}
                                disabled={disableInputs === true}
                                options={{
                                    key: "companyKey",
                                    display: (suggestion) => {
                                        return get(suggestion, "displayName", "");
                                    },
                                    displaySub: (suggestion) => {
                                        return get(suggestion, "mainAddress.address1", "");
                                    },
                                }}
                                freeformUpdate={(value) => this.freeformUpdateCompany(value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>
                    </Grid>
                ) : (
                    companyFieldAvailable && (
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="company"
                                    label={"Company Name (Common)"}
                                    error={get(errors, `contact.company.companyKey`, null)}
                                    initialInputValue={`${get(contact, `company.displayName`, "")}`}
                                    itemToString={(i) => (i == null ? "" : get(i, "displayName", ""))}
                                    onSelect={(company) => this.selectCompany(company)}
                                    placeholder="Enter company name (common)"
                                    searchFunction={(input) => handleSearchCompany(input, "CONTACT")}
                                    startAdornment={<Search />}
                                    suggestions={autocompleteResults}
                                    disabled={disableInputs === true}
                                    options={{
                                        key: "companyKey",
                                        display: (suggestion) => {
                                            return get(suggestion, "displayName", "");
                                        },
                                        displaySub: (suggestion) => {
                                            return get(suggestion, "mainAddress.address1", "");
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                &nbsp;
                            </Grid>
                        </Grid>
                    )
                )}

                <Grid container spacing={8}>
                    <Grid item xs={4}>
                        <BSInput
                            id="title"
                            label="Title"
                            value={isSnapshot ? get(contact, "title") : get(contact, `contact.person.title`, "")}
                            error={get(errors, `contact.${contactIndex}.person.title`, null)}
                            onChange={(e) => handleInput("contact.person.title", e.target.value)}
                            placeholder={isSnapshot ? "N/A" : "Enter title"}
                            disabled={disableInputs === true}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <BSInput
                            id="firstName"
                            label="First Name"
                            value={
                                isSnapshot ? get(contact, "firstName") : get(contact, `contact.person.firstName`, "")
                            }
                            error={get(errors, `contact.${contactIndex}.contact.person.firstName`, null)}
                            onChange={(e) => handleInput("contact.person.firstName", e.target.value)}
                            placeholder={isSnapshot ? "N/A" : "Enter first name"}
                            disabled={disableInputs === true}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <BSInput
                            id="lastName"
                            label="Last Name"
                            value={isSnapshot ? get(contact, "lastName") : get(contact, `contact.person.lastName`, "")}
                            error={get(errors, `contact.${contactIndex}.contact.person.lastName`, null)}
                            onChange={(e) => handleInput("contact.person.lastName", e.target.value)}
                            placeholder={isSnapshot ? "N/A" : "Enter last name"}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={8}>
                    <Grid item xs={6} className={classes.phoneContainer}>
                        <BSInput
                            id="preferredPhoneNumber"
                            label="Preferred Phone"
                            value={
                                isSnapshot
                                    ? get(contact, "preferredPhone")
                                    : get(contact, `contact.phoneNumber.phoneNumber`, "")
                            }
                            error={get(errors, `contact.${contactIndex}.contact.phoneNumber.phoneNumber`, null)}
                            onChange={(e) => handleInput("contact.phoneNumber.phoneNumber", e.target.value)}
                            placeholder={isSnapshot ? "N/A" : "Enter preferred contact number"}
                            disabled={disableInputs === true}
                            phone={true}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <RadioGroup
                            aria-labelledby="phone-type-radio-buttons-group-label"
                            value={
                                isSnapshot
                                    ? get(contact, "preferredPhoneType")
                                    : get(contact, `contact.phoneNumber.phoneNumberType`, "")
                            }
                            error={get(errors, `contact.${contactIndex}.contact.phoneNumber.phoneNumberType`, null)}
                            onChange={(e) => handleInput("contact.phoneNumber.phoneNumberType", e.target.value)}
                            name="Type"
                            row
                            className={classes.phoneRadio}
                        >
                            <FormControlLabel
                                disabled={disableInputs === true}
                                value="mobile"
                                control={<Radio color="primary" />}
                                label="Mobile"
                            />
                            <FormControlLabel
                                disabled={disableInputs === true}
                                value="office"
                                control={<Radio color="primary" />}
                                label="Office"
                            />
                            <FormControlLabel
                                disabled={disableInputs === true}
                                value="other"
                                control={<Radio color="primary" />}
                                label="Other"
                            />
                        </RadioGroup>
                    </Grid>

                    <Grid container spacing={8}>
                        <Grid item xs={6} className={classes.phoneContainer}>
                            <BSInput
                                id="alternatePhoneNumber"
                                label="Alternate Phone"
                                value={
                                    isSnapshot
                                        ? get(contact, "alternatePhone")
                                        : get(contact, `contact.alternatePhoneNumber.phoneNumber`, "")
                                }
                                error={get(
                                    errors,
                                    `contact.${contactIndex}.contact.alternatePhoneNumber.phoneNumber`,
                                    null
                                )}
                                onChange={(e) =>
                                    handleInput("contact.alternatePhoneNumber.phoneNumber", e.target.value)
                                }
                                placeholder={isSnapshot ? "N/A" : "Enter alternative contact number"}
                                disabled={disableInputs === true}
                                phone={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <RadioGroup
                                aria-labelledby="alternate-phone-type-radio-buttons-group-label"
                                value={
                                    isSnapshot
                                        ? get(contact, "alternatePhoneType")
                                        : get(contact, `contact.alternatePhoneNumber.phoneNumberType`, null)
                                }
                                error={get(
                                    errors,
                                    `contact.${contactIndex}.contact.alternatePhoneNumber.phoneNumberType`,
                                    null
                                )}
                                onChange={(e) =>
                                    handleInput("contact.alternatePhoneNumber.phoneNumberType", e.target.value)
                                }
                                name="Type"
                                row
                                className={classes.phoneRadio}
                            >
                                <FormControlLabel
                                    disabled={disableInputs === true}
                                    value="mobile"
                                    control={<Radio color="primary" />}
                                    label="Mobile"
                                />
                                <FormControlLabel
                                    disabled={disableInputs === true}
                                    value="office"
                                    control={<Radio color="primary" />}
                                    label="Office"
                                />
                                <FormControlLabel
                                    disabled={disableInputs === true}
                                    value="other"
                                    control={<Radio color="primary" />}
                                    label="Other"
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <BSInput
                        id="emailAddress"
                        label="Email"
                        value={isSnapshot ? get(contact, "email") : get(contact, `contact.emailAddress`, "")}
                        error={get(errors, `contact.${contactIndex}.contact.emailAddress`, null)}
                        onChange={(e) => handleInput("contact.emailAddress", e.target.value)}
                        placeholder={isSnapshot ? "N/A" : "Enter contact email"}
                        disabled={disableInputs === true}
                    />
                </Grid>

                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <BSInput
                            id="address1"
                            label="Address"
                            value={isSnapshot ? get(contact, "address") : get(contact, `contact.address.address1`, "")}
                            error={get(errors, `contact.${contactIndex}.contact.address.address1`, null)}
                            onChange={(e) => handleInput("contact.address.address1", e.target.value)}
                            placeholder={isSnapshot ? "N/A" : "Enter address"}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={8}>
                    <Grid item xs={4}>
                        <BSInput
                            id="city"
                            label="City"
                            value={isSnapshot ? get(contact, "city") : get(contact, `contact.address.city.city`, "")}
                            error={get(errors, `contact.${contactIndex}.contact.address.city.city`, null)}
                            onChange={(e) => handleInput("contact.address.city.city", e.target.value)}
                            placeholder={isSnapshot ? "N/A" : "Enter city"}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSSelect
                            id="state"
                            label="State"
                            value={
                                isSnapshot
                                    ? get(contact, "state")
                                    : get(contact, `contact.address.city.state.stateCode`, "")
                            }
                            error={get(errors, `contact.${contactIndex}.contact.address.city.state.stateCode`, null)}
                            onChange={(e) => handleInput("contact.address.city.state.stateCode", e.target.value)}
                            options={us_states_options}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BSInput
                            id="zipCode"
                            label="Zip Code"
                            value={
                                isSnapshot
                                    ? get(contact, "zipCode")
                                    : get(contact, `contact.address.zipCode.zipCode`, "")
                            }
                            error={get(errors, `contact.${contactIndex}.contact.address.zipCode.zipCode`, null)}
                            onChange={(e) => handleInput("contact.address.zipCode.zipCode", e.target.value)}
                            placeholder={isSnapshot ? "N/A" : "Enter zip code"}
                            disabled={disableInputs === true}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default withStyles(styles)(ContactForm);
