import validate from "validate.js";
import mapValues from "lodash/mapValues";

const validateTransaction = (transaction) => {
    console.log(transaction);
    let errors = {
        ...mapValues(validate(transaction, {}, { fullMessages: false }), (error) => {
            return error[0];
        }),
    };
    console.log(errors);
    return errors;
};

export default {
    validateTransaction,
};
