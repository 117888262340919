import React from "react";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BSInput from "../../../common/js/components/BSInput";
import toLower from "lodash/toLower";

class HospitailityForm extends React.Component {
    handleAttributeUpdate = (event) => {
        this.props.onChange(event.target.id, event.target.value);
    };

    render() {
        const { data, errors, disableInputs, context, listingStatus, transactionType } = this.props;

        return (
            <div>
                {context === "property" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="condoUnitCount"
                                    label="CONDO UNITS"
                                    errorText="ex. 98"
                                    placeholder="Enter number"
                                    error={errors.condoUnitCount}
                                    value={data.condoUnitCount}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="avgRevPar"
                                    label="AVERAGE REVPAR"
                                    errorText="ex. 98.10"
                                    error={errors.avgRevPar}
                                    value={data.avgRevPar}
                                    onChange={this.handleAttributeUpdate}
                                    startAdornment="$"
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="officeSf"
                                    label="OFFICE SPACE"
                                    placeholder="Enter SF"
                                    errorText="ex. 98101"
                                    error={errors.officeSf}
                                    value={data.officeSf}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="retailSf"
                                    label="RETAIL SPACE"
                                    placeholder="Enter SF"
                                    errorText="ex. 98101"
                                    error={errors.retailSf}
                                    value={data.retailSf}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="penthouseUnitCount"
                                    label="PENTHOUSE UNIT COUNT"
                                    placeholder="Enter number"
                                    error={errors.penthouseUnitCount}
                                    value={data.penthouseUnitCount}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="multifamilyUnitCount"
                                    label="MULTIFAMILY UNIT COUNT"
                                    placeholder="Enter count"
                                    errorText="ex. 98"
                                    error={errors.multifamilyUnitCount}
                                    value={data.multifamilyUnitCount}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
                {context === "listing" && (
                    <div>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="hospitalityUnitCount"
                                    label="HOSPITALITY UNIT COUNT"
                                    placeholder="Enter count"
                                    errorText="ex. 98"
                                    error={errors.hospitalityUnitCount}
                                    value={data.hospitalityUnitCount}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BSInput
                                    id="hospitalitySf"
                                    label="HOSPITALITY SF"
                                    placeholder="Enter SF"
                                    errorText="ex. 98101"
                                    error={errors.hospitalitySf}
                                    value={data.hospitalitySf}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <BSInput
                                    id="roomCount"
                                    label="Room Count"
                                    placeholder="Enter room count"
                                    errorText="ex. 98"
                                    error={errors.roomCount}
                                    value={data.roomCount}
                                    onChange={this.handleAttributeUpdate}
                                    disabled={disableInputs}
                                />
                            </Grid>
                            {toLower(transactionType) === "sale" && listingStatus === "Appraisal" && (
                                <Grid item xs={6}>
                                    <BSInput
                                        id="commercialSf"
                                        label="Commercial SF"
                                        placeholder="Enter commercial sf"
                                        error={errors.commercialSf}
                                        value={data.commercialSf}
                                        onChange={this.handleAttributeUpdate}
                                        disabled={disableInputs}
                                        integer
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {toLower(transactionType) === "sale" && listingStatus === "Appraisal" && (
                            <Grid container alignItems="flex-start" spacing={8}>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="avgRevPar"
                                        label="REVPAR"
                                        placeholder="Enter revpar"
                                        errorText="ex. 98"
                                        error={errors.avgRevPar}
                                        value={data.avgRevPar}
                                        onChange={this.handleAttributeUpdate}
                                        disabled={disableInputs}
                                        numeric
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="adr"
                                        label="ADR"
                                        placeholder="Enter room count"
                                        error={errors.adr}
                                        value={data.adr}
                                        onChange={this.handleAttributeUpdate}
                                        disabled={disableInputs}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BSInput
                                        id="rating"
                                        label="Rating"
                                        placeholder="Enter rating"
                                        error={errors.rating}
                                        value={data.rating}
                                        onChange={this.handleAttributeUpdate}
                                        disabled={disableInputs}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid container alignItems="flex-start" spacing={8}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.affordableHousingFlg ? true : false}
                                            onChange={(e, checked) => this.props.onChange(e.target.value, checked)}
                                            value="affordableHousingFlg"
                                            disabled={disableInputs === true}
                                            color="primary"
                                        />
                                    }
                                    label="Affordable Housing"
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
                <Grid container alignItems="flex-start" spacing={8}>
                    <BSInput
                        id="unitMix"
                        label="Room Mix Breakdown"
                        placeholder="Enter room mix breakdown..."
                        error={errors.unitMix}
                        value={data.unitMix}
                        onChange={this.handleAttributeUpdate}
                        disabled={disableInputs}
                        multiline={true}
                        rows={4}
                    />
                </Grid>
            </div>
        );
    }
}

export default HospitailityForm;
