import each from "lodash/each";
import get from "lodash/get";
import utils from "../utils/operations";
import { aggregateBrokers } from "../utils/operations";
import numeral from "numeral";

const DEAL_STAGE_VOUCHER = 2;

const viewHeaders = {
    broker_open: [
        {
            name: "Deal",
            prop: "transactionType",
            sort: "listing.listingKey",
        },
        {
            name: "Prop Type",
            prop: "propertyType", //todo
            type: "plaintext",
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
        },
        {
            name: "Square Feet",
            prop: "squareFeet",
            type: "plaintext",
            transform: (value) => {
                return numeral(value).format("0,0") + " SF";
            },
        },
        {
            name: "Installments",
            prop: "installments",
            type: "plaintext",
            transform: (value) => {
                return `1 of ${value.length + 1}`;
            },
            sort: ["currentInstallment.installmentNumber", "totalInstallments"],
        },
        {
            name: "Next Payment Date",
            prop: "currentInstallment.dueDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Total KM Commission",
            prop: "totalKmCommission",
            type: "plaintext",
            transform: (value) => {
                return value > 0 ? "$ " + numeral(value).format("0,0.00") : "-";
            },
        },
        {
            name: "Step",
            prop: "dealStage",
            type: "plaintext",
            transform: (value) => {
                return value != null && value.stage === DEAL_STAGE_VOUCHER ? "Voucher" : "Comp";
            },
        },
        {
            name: "Status",
            prop: "dealStatus",
            type: "plaintext",
        },
    ],
    broker_closed: [
        {
            name: "Deal",
            prop: "propertyAttributes.address.address1",
            type: "hyperlink",
            sort: "dealKey",
            width: "18%",
        },
        {
            name: "Tenant/Buyer",
            prop: "buyerTenantName", //todo
            type: "plaintext",
            width: "16%",
        },
        {
            name: "Landlord/Seller",
            prop: "landlordSellerName",
            type: "plaintext",
            width: "16%",
        },
        {
            name: "Square Feet",
            prop: "squareFeet",
            type: "plaintext",
            transform: (value) => {
                return numeral(value).format("0,0") + " SF";
            },
        },
        {
            name: "Installments",
            prop: "installments",
            type: "plaintext",
            transform: (value) => {
                return `1 of ${value.length + 1}`;
            },
            sort: ["currentInstallment.installmentNumber", "totalInstallments"],
        },
        {
            name: "Batch Date",
            prop: "batchDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
            sortFunction: utils.formatDate("YYYY-MM-DD"),
        },
        {
            name: "My Amount",
            prop: `brokerNetAmount`,
            type: "plaintext",
            transform: (value) => {
                return value > 0 ? "$ " + numeral(value).format("0,0.00") : "-";
            },
        },
    ],
    data_entry_unassigned: [
        {
            name: "Deal",
            prop: "propertyAttributes.address.address1",
            type: "hyperlink",
            sort: "dealKey",
        },
        {
            name: "Brokers",
            prop: "brokerAgents",
            type: "aggregate",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "person");
            },
        },
        {
            name: "Modified",
            prop: "auditLastModified",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Prop Type",
            prop: "propertyType",
            type: "plaintext",
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
        },
        {
            name: "Total KM Commission",
            prop: "totalKmCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return "$ " + numeral(value).format("0,0.00");
            },
        },
        {
            name: "Status",
            prop: "dealStatus",
            type: "plaintext",
        },
    ],
    data_entry_open: [
        {
            name: "Deal",
            prop: "propertyAttributes.address.address1",
            type: "hyperlink",
            sort: "dealKey",
        },
        {
            name: "Brokers",
            prop: "brokerAgents",
            type: "aggregate",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "person");
            },
        },
        {
            name: "Modified",
            prop: "auditLastModified",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Prop Type",
            prop: "propertyType",
            type: "plaintext",
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
        },
        {
            name: "Installments",
            prop: "installments",
            type: "plaintext",
            transform: (value) => {
                return `1 of ${value.length + 1}`;
            },
            sort: ["currentInstallment.installmentNumber", "totalInstallments"],
        },
        {
            name: "Next Payment Date",
            prop: "currentInstallment.dueDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Total KM Commission",
            prop: "totalKmCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return "$ " + numeral(value).format("0,0.00");
            },
        },
        {
            name: "Status",
            prop: "dealStatus",
            type: "plaintext",
        },
    ],
    data_entry_closed: [
        {
            name: "Deal",
            prop: "propertyAttributes.address.address1",
            type: "hyperlink",
            sort: "dealKey",
        },
        {
            name: "Brokers",
            prop: "brokerAgents",
            type: "aggregate",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "person");
            },
        },
        {
            name: "Prop Type",
            prop: "propertyType",
            type: "plaintext",
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
        },
        {
            name: "Square Feet",
            prop: "squareFeet",
            type: "plaintext",
            transform: (value) => {
                return numeral(value).format("0,0") + " SF";
            },
        },
        {
            name: "Installments",
            prop: "installments",
            type: "plaintext",
            transform: (value) => {
                return `1 of ${value.length + 1}`;
            },
            sort: ["currentInstallment.installmentNumber", "totalInstallments"],
        },
        {
            name: "Next Payment Date",
            prop: "currentInstallment.dueDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Date Signed",
            prop: "transactionDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
    ],
    data_admin_needs_final_review: [
        {
            name: "Deal",
            prop: "propertyAttributes.address.address1",
            type: "hyperlink",
            sort: "dealKey",
        },
        {
            name: "Brokers",
            prop: "brokerAgents",
            type: "aggregate",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "person");
            },
        },
        {
            name: "Payment Attached Date",
            prop: "paymentAttachedDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Prop Type",
            prop: "propertyType", //todo
            type: "plaintext",
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
        },
        {
            name: "Total Commission",
            prop: "totalCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return "$ " + numeral(value).format("0,0.00");
            },
        },
        {
            name: "Total KM Commission",
            prop: "totalKmCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return "$ " + numeral(value).format("0,0.00");
            },
        },
        {
            name: "Status",
            prop: "dealStatus",
            type: "plaintext",
        },
    ],
    data_admin_submitted: [
        {
            name: "Address",
            prop: "deal.propertyAttributes.address.address1",
            type: "hyperlink",
        },
        {
            name: "Brokers",
            prop: "brokerCommissions",
            type: "aggregate",
        },
        {
            name: "Reviewer",
            prop: "dataEntryOwner",
            type: "plaintext",
        },
        {
            name: "Voucher Id",
            prop: "voucherKey",
            type: "plaintext",
        },
        {
            name: "Modified",
            prop: "auditLastModified",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Total KM Commission",
            prop: "totalKmCommission",
            type: "plaintext",
            numeric: true,
            transform: utils.formatAmount,
        },
        {
            name: "Status",
            prop: "voucherStatus",
            type: "plaintext",
        },
    ],
    data_admin_closed: [
        {
            name: "Deal",
            prop: "propertyAttributes.address.address1",
            type: "hyperlink",
            sort: "dealKey",
        },
        {
            name: "Brokers",
            prop: "brokerAgents",
            type: "aggregate",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "person");
            },
        },
        {
            name: "Prop Type",
            prop: "propertyType", //todo
            type: "plaintext",
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
        },
        {
            name: "Square Feet",
            prop: "squareFeet",
            type: "plaintext",
            transform: (value) => {
                return numeral(value).format("0,0") + " SF";
            },
        },
        {
            name: "Installments",
            prop: "installments",
            type: "plaintext",
            transform: (value) => {
                return `1 of ${value ? value.length : 0 + 1}`;
            },
            sort: ["currentInstallment.installmentNumber", "totalInstallments"],
        },
        {
            name: "Next Payment Date",
            prop: "currentInstallment.dueDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Date Signed",
            prop: "transactionDate",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
    ],
    portland_broker_admin: [
        {
            name: "Deal",
            prop: "propertyAttributes.address.address1",
            type: "hyperlink",
            sort: "listing.listingKey",
        },
        {
            name: "Brokers",
            prop: "brokerAgents",
            type: "aggregate",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "person");
            },
        },
        {
            name: "Modified",
            prop: "auditLastModified",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Reviewed By",
            prop: "reviewedBy",
            type: "plaintext",
        },
        {
            name: "Prop Type",
            prop: "propertyType",
            type: "plaintext",
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
        },
        {
            name: "Total KM Commission",
            prop: "totalKmCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return "$ " + numeral(value).format("0,0.00");
            },
        },
        {
            name: "Status",
            prop: "dealStatus",
            type: "plaintext",
        },
    ],
    portland_broker_admin_sale: [
        {
            name: "Deal",
            prop: "propertyAttributes.address.address1",
            type: "hyperlink",
            sort: "listing.listingKey",
        },
        {
            name: "Brokers",
            prop: "brokerAgents",
            type: "aggregate",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "person");
            },
        },

        {
            name: "Created",
            prop: "auditCreated",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Reviewed By",
            prop: "reviewedBy",
            type: "plaintext",
        },
        {
            name: "Prop Type",
            prop: "propertyType", //todo
            type: "plaintext",
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
        },
        {
            name: "Documents Needing Approval",
            prop: "documentsRequiringApproval",
            type: "plaintext",
        },
        {
            name: "Status",
            prop: "dealStatus",
            type: "plaintext",
        },
    ],
    portland_broker_admin_closed: [
        {
            name: "Deal",
            prop: "propertyAttributes.address.address1",
            type: "hyperlink",
            sort: "listing.listingKey",
        },
        {
            name: "Brokers",
            prop: "brokerAgents",
            type: "aggregate",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "person");
            },
        },
        {
            name: "Modified",
            prop: "auditLastModified",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Reviewed By",
            prop: "reviewedBy",
            type: "plaintext",
        },
        {
            name: "Prop Type",
            prop: "propertyType",
            type: "plaintext",
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
        },
        {
            name: "Total KM Commission",
            prop: "totalKmCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return "$ " + numeral(value).format("0,0.00");
            },
        },
        {
            name: "Status",
            prop: "dealStatus",
            type: "plaintext",
        },
    ],
    arizona_broker_admin_review: [
        {
            name: "Deal",
            prop: "propertyAttributes.address.address1",
            type: "hyperlink",
            sort: "listing.listingKey",
        },
        {
            name: "Brokers",
            prop: "brokerAgents",
            type: "aggregate",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "person");
            },
        },
        {
            name: "Modified",
            prop: "auditLastModified",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Prop Type",
            prop: "propertyType",
            type: "plaintext",
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
        },
        {
            name: "Total KM Commission",
            prop: "totalKmCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return "$ " + numeral(value).format("0,0.00");
            },
        },
        {
            name: "Status",
            prop: "dealStatus",
            type: "plaintext",
        },
    ],
    arizona_broker_admin_closed: [
        {
            name: "Deal",
            prop: "propertyAttributes.address.address1",
            type: "hyperlink",
            sort: "listing.listingKey",
        },
        {
            name: "Brokers",
            prop: "brokerAgents",
            type: "aggregate",
            transform: (brokerAgents) => {
                return aggregateBrokers(brokerAgents, "person");
            },
        },
        {
            name: "Modified",
            prop: "auditLastModified",
            type: "plaintext",
            transform: utils.formatDate("MM-DD-YYYY"),
        },
        {
            name: "Prop Type",
            prop: "propertyType",
            type: "plaintext",
        },
        {
            name: "Deal Type",
            prop: "transactionType",
            type: "plaintext",
        },
        {
            name: "Total KM Commission",
            prop: "totalKmCommission",
            type: "plaintext",
            numeric: true,
            transform: (value) => {
                return "$ " + numeral(value).format("0,0.00");
            },
        },
        {
            name: "Status",
            prop: "dealStatus",
            type: "plaintext",
        },
    ],
};

const columnsToQuery = [
    "deal.propertyAttributes.address.address1",
    "paymentTotal",
    "auditLastModified",
    "voucherKey",
    "voucherStatus",
    "dealKey",
];

const brokerColumnsToQuery = [
    "propertyType",
    "transactionType",
    "dealStatus",
    "landlordSellerName",
    "buyerTenantName",
    "address1",
    "city",
    "state",
    "zip",
    "listingName",
    "dealKey",
    "brokerNames",
    "listing.property.primaryAddress.address1",
    "listing.listingName",
    "listingName",
    "totalKmCommission",
    "installments",
    "currentInstallment",
];

const dataEntryColumnsToQuery = [
    "propertyAttributes.address.address1",
    "propertyType",
    "transactionType",
    "propertyType",
    "dealStatus",
    "listing.listingKey",
    "listing.property.primaryAddress.address1",
    "listing.property.primaryAddress.city.city",
    "auditLastModified",
    "currentSf",
    "brokerNames",
    "dealKey",
    "installments",
    "currentInstallment",
];

const dataAdminColumnsToQuery = [
    "propertyAttributes.address.address1",
    "propertyType",
    "transactionType",
    "propertyType",
    "dealStatus",
    "listing.listingKey",
    "auditLastModified",
    "currentSf",
    "brokerNames",
    "listing.property.primaryAddress.address1",
    "listing.property.primaryAddress.city.city",
    "dealKey",
];

const tabMetaData = {
    portland_broker_admin_sale: {
        refreshOnSwitch: true,
    },
    portland_broker_admin: {
        refreshOnSwitch: true,
    },
};

const filterByQuery = (data, columnsToQuery, query) => {
    let included = false;
    each(columnsToQuery, (column) => {
        if (get(data, column)) {
            if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                included = true;
            }
        }
    });
    return included;
};

const refreshTabOnSwitch = (tab) => {
    let refresh = false;
    if (tab && tabMetaData[tab]) {
        refresh = tabMetaData[tab].refreshOnSwitch;
    }
    return refresh;
};

export default {
    viewHeaders,
    columnsToQuery,
    brokerColumnsToQuery,
    dataEntryColumnsToQuery,
    dataAdminColumnsToQuery,
    filterByQuery,
    refreshTabOnSwitch,
};
