import types from "./types";

export default (
    state = {
        selected: [],
        usedPropDocTypes: [],
        usedDocTypes: [],
        documents: {},
        savedPropDocuments: [],
        savedDocuments: {},
        missingRequiredDocuments: [],
        anchorEl: null,
        docKey: null,
        documentsUploading: {},
        documentRequirements: [],
        parentEntityTypeKey: null,
        documentTypesForParentEntity: [],
        requiredDocs: [],
        optionalDocs: [],
    },
    action
) => {
    switch (action.type) {
        case types.SORT_DOCUMENTS:
            return Object.assign({}, state, {
                documentColumnToSort: action.columnToSort, //get rid of
                documentSortdirection: action.sortDirection,
            });
        case types.SELECT_DOC_TYPE:
            return {
                ...state,
                savedDocuments: {
                    ...state.savedDocuments,
                    [action.name]: {
                        ...state.savedDocuments[action.name],
                        mediaAssetType: action.docType,
                        documentType: action.documentType ? action.documentType : null,
                    },
                },
                usedDocTypes: action.usedDocTypes,
            };
        case types.REMOVE_DOCUMENT:
            return {
                ...state,
                savedDocuments: action.newSavedDocs,
                usedDocTypes: action.usedDocTypes,
            };
        case types.OPEN_DOC_MENU:
            return {
                ...state,
                anchorEl: action.anchorEl,
                docKey: action.key,
            };
        case types.CLOSE_DOC_MENU:
            return {
                ...state,
                anchorEl: null,
                docKey: null,
            };
        case types.RECEIVE_DOCUMENTS:
            return {
                ...state,
                savedDocuments: action.savedDocuments,
                usedDocTypes: action.usedDocTypes,
            };
        case types.RECEIVE_MISSING_REQUIRED_DOCUMENTS:
            return {
                ...state,
                missingRequiredDocuments: action.missingRequiredDocuments,
            };
        case types.RECEIVE_PROPERTY_DOCUMENTS:
            return {
                ...state,
                savedPropDocuments: action.savedPropDocuments,
                usedPropDocTypes: action.usedPropDocTypes,
            };

        case types.REMOVE_DOC_FROM_LIST:
            return {
                ...state,
                savedDocuments: action.newSavedDocs,
            };
        case types.APPROVE_DOCUMENT:
            return {
                ...state,
                savedDocuments: {
                    ...state.savedDocuments,
                    [action.key]: {
                        ...state.savedDocuments[action.key],
                        approvedBy: action.approvedBy,
                        approvedByTimestamp: action.approvedByTimestamp,
                    },
                },
            };
        case types.STASH_DOCUMENT_URL:
            return {
                ...state,
                savedDocuments: {
                    ...state.savedDocuments,
                    [action.key]: {
                        ...state.savedDocuments[action.key],
                        temporaryUrl: action.url,
                    },
                },
            };
        case types.DOCUMENTS_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case types.DOCUMENT_UPLOADING:
            return {
                ...state,
                documentsUploading: {
                    ...state.documentsUploading,
                    [action.fileName]: action.loading,
                },
            };
        case types.ADD_DOCUMENT:
            return {
                ...state,
                savedDocuments: {
                    ...state.savedDocuments,
                    [action.key]: action.file,
                },
            };
        case types.SET_DOCUMENT_UPDATED_FLAG:
            return {
                ...state,
                savedDocuments: {
                    ...state.savedDocuments,
                    [action.key]: {
                        ...state.savedDocuments[action.key],
                        updated: true,
                    },
                },
            };
        case types.REMOVE_FROM_UPLOADING_QUEUE:
            return {
                ...state,
                documentsUploading: action.documentsUploading,
            };
        case types.GET_DOCUMENT_REQUIREMENTS_BY_PARENT_ENTITY_TYPE:
            return {
                ...state,
                documentRequirements: action.requirements,
            };
        case types.GET_PARENT_ENTITY_TYPE_KEY:
            return {
                ...state,
                parentEntityTypeKey: action.parentEntityTypeKey,
            };
        case types.SET_ADMIN_DOCUMENT_TYPE_LIST_AVAILABLE:
            let requiredDocs = [];
            let optionalDocs = [];
            let documentTypesForParentEntity = [];
            state.documentRequirements.forEach((doc) => {
                documentTypesForParentEntity.push(doc);
                if (doc.requiredFlag === null) {
                    return;
                } else if (doc.requiredFlag) {
                    requiredDocs.push(doc);
                } else {
                    optionalDocs.push(doc);
                }
            });
            return {
                ...state,
                requiredDocs: [...requiredDocs],
                optionalDocs: [...optionalDocs],
                documentTypesForParentEntity: [...documentTypesForParentEntity],
            };
        case types.RESET_REQUIRED_MISSING_DOCUMENTS:
            return {
                ...state,
                requiredDocs: [],
                optionalDocs: [],
                savedDocuments: {},
                documentTypesForParentEntity: [],
            };
        default:
            return state;
    }
};
