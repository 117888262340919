import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Chip, Typography } from "@material-ui/core";
import { chipColors } from "./constants";

const ActionChips = (props) => {
    const { classes, noWrap, showDocName } = props;
    const { actions } = props.rule;

    return (
        <React.Fragment>
            <Typography noWrap={noWrap}>
                {actions.map((action, i) => {
                    const { actionTypeKey } = action.actionType;
                    const chipColor =
                        actionTypeKey === 1
                            ? chipColors.required
                            : actionTypeKey === 2
                            ? chipColors.optional
                            : chipColors.hidden;
                    return (
                        <Chip
                            variant="outlined"
                            className={classes.chipStyle}
                            style={{ border: `0.2em solid ${chipColor}`, color: chipColor }}
                            key={i}
                            label={showDocName ? action.documentName : action.actionType.displayName}
                        />
                    );
                })}
            </Typography>
        </React.Fragment>
    );
};

const styles = () => ({
    chipStyle: {
        marginRight: "5px",
        marginBottom: "5px",
    },
});

export default withStyles(styles)(ActionChips);
