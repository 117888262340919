import actions from "./actions";
import { push } from "connected-react-router";
import voucherActions from "../../voucher/ducks/actions";
import voucherOperations from "../../voucher/ducks/operations";
import each from "lodash/each";
import forEach from "lodash/forEach";
import constants from "../constants";
import utils from "../../utils/index";
import contactUtils from "../../contact/utils";
import contactOperations from "../../contact/ducks/operations";
import contactConstants from "../../contact/constants";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import validation from "./validation";
import find from "lodash/find";
import toLower from "lodash/toLower";
import compact from "lodash/compact";
import { request } from "../../utils/axios-wrapper";
import numeral from "numeral";
import compValidation from "../../comp/ducks/compValidation";
import isNil from "lodash/isNil";
import forIn from "lodash/forIn";
import isNumber from "lodash/isNumber";
import includes from "lodash/includes";
import utilOperations from "../../utils/operations";
import { convertForIntegerValidation, convertForNumberValidation, round, stripCommas } from "../../utils/operations";
import set from "lodash/set";
import { saveAs } from "file-saver";
import ReactGA from "react-ga4";
import { emptyValues } from "../../utils/constants";

const getDeal = (key) => {
    return (dispatch, _) => {
        request(
            {
                url: constants.urls.get_deal(key),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(
                    contactOperations.initContacts(
                        contactConstants.DEAL_CONTACTS,
                        contactUtils.initContactsFromDeal(response.data)
                    )
                );
                dispatch(
                    contactOperations.initContacts(
                        contactConstants.PROPERTY_CONTACTS,
                        contactUtils.initContactsFromPropertyAttributes(response.data.propertyAttributes)
                    )
                );
                dispatch(actions.getDeal(response));
            })
            .catch((err) => {
                console.log(err);
                dispatch(dealSnackbar(true, "error", "Error while attempting to retrieve deal: " + err));
            });
    };
};

const loadDealDocumentList = (key) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.get_deal_document_list(key),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.loadDealDocumentList(response.data));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

const getDealSummary = (key) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.loading(true));
        dispatch(utils.actions.loading2(true));
        //get deal
        request(
            {
                url: constants.urls.get_deal(key),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.getDealSummary(response.data));

                if (get(response.data, "listing.listingKey", 0)) {
                    dispatch(getListingAgent(get(response.data, "listing.listingKey", 0)));
                }
                dispatch(utils.actions.loading(false));
                request(
                    {
                        url: constants.urls.get_vouchers_by_deal(key),
                        method: "get",
                        crossDomain: true,
                    },
                    dispatch
                )
                    .then((response) => {
                        console.log(response);
                        dispatch(getBrokerCommissionAmounts(response.data));
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(utils.actions.loading2(false));
                    });

                if (get(response.data, "auditCreatedBy", null) != null) {
                    request(
                        {
                            url: constants.urls.get_name_for_username(response.data.auditCreatedBy),
                            method: "get",
                            crossDomain: true,
                        },
                        dispatch
                    )
                        .then((response) => {
                            console.log(response);
                            dispatch(actions.getDealCreator(response.data));
                            ///////////////// BUILD THIS OUT TO GET NAME OVER TO CREATED BY
                        })
                        .catch((err) => {
                            console.log(err);
                            dispatch(utils.actions.loading2(false));
                        });
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(utils.actions.loading(false));
                dispatch(utils.actions.loading2(false));
            });
    };
};

const getBrokerCommissionAmounts = (vouchers) => {
    return (dispatch, getState) => {
        dispatch(actions.getDealSummaryVouchers(vouchers));
        dispatch(utils.actions.loading2(false));
        const personKey = getState().authReducer.userProfile.personKey; // What is this doing?  userKey is hardcoded
        const brokerCommissions = [];
        each(vouchers, (x, i) => {
            brokerCommissions.push(...x.brokerCommissions);
            const usersCommission = find(x.brokerCommissions, (b) => {
                return get(b, "brokerAgent.person.personKey") === personKey;
            });
            dispatch(actions.getBrokerCommissionAmounts(x.voucherKey, usersCommission ? usersCommission : {}));
        });

        dispatch(actions.setDealSummaryBrokerCommissionAmounts(brokerCommissions));
    };
};

const getListingAgent = (listingKey) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.get_listing_agent(listingKey),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                dispatch(actions.getListingAgent(response.data));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

const handleSort = (columnName) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };
    return (dispatch, getState) => {
        let sortDirection =
            getState().dealReducer.dealSummary.columnToSort === columnName
                ? invertDirection[getState().dealReducer.dealSummary.sortDirection]
                : "asc";
        dispatch(actions.handleSort(columnName, sortDirection));
    };
};

/**
 * @deprecated use saveDealNEW
 */
const saveDeal = (callback) => {
    return (dispatch, getState) => {
        console.log("save deal deprecated");

        if (isEmpty(getState().dealReducer.errors)) {
            const deal = getState().dealReducer.deal;
            const url = constants.urls.save_deal(deal.transactionType, deal.dealKey);
            request(
                {
                    url: url,
                    method: "post",
                    crossDomain: true,
                    data: buildCompRequestFromState(getState()),
                },
                dispatch
            )
                .then((response) => {
                    callback
                        ? request(
                              {
                                  url: callback,
                                  method: "post",
                                  crossDomain: true,
                              },
                              dispatch
                          )
                              .then((response) => {
                                  dispatch(utils.actions.snackbar(true, "success", "Success."));
                                  dispatch(actions.getDeal(response));
                              })
                              .catch((err) => {
                                  console.log(err);
                                  dispatch(
                                      utils.actions.snackbar(
                                          true,
                                          "error",
                                          "Error occured while trying to submit this comp"
                                      )
                                  );
                                  dispatch(utilOperations.reportError(err, "deal:operations:saveDeal(inner) Error"));
                              })
                        : dispatch(actions.getDeal(response));
                    console.log();
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(dealSnackbar(true, "error", "Error attempting to save deal: " + err));
                    dispatch(utilOperations.reportError(err, "deal:operations:saveDeal(outer) Error"));
                });
        } else {
            console.log(getState().dealReducer.errors);
            dispatch(dealSnackbar(true, "error", "Errors must be resolved before the invoice can be saved"));
        }
    };
};

const cleanDeal = (deal) => {
    for (let key in deal) {
        if (emptyValues.has(deal[key])) deal[key] = null;
    }
    return deal;
};

const saveDealNEW = (deal, dispatch) => {
    const url = constants.urls.save_deal(deal.transactionType, deal.dealKey);
    return request(
        {
            url: url,
            method: "post",
            crossDomain: true,
            data: cleanDeal(deal),
        },
        dispatch
    );
};

const executeInvoice = () => {
    return (dispatch, getState) => {
        const deal = buildCompRequestFromState(getState());
        dispatch(handlePreGenerateInvoiceTasks(deal));
        const state = getState().dealReducer;
        dispatch(utils.actions.somethingSaving(true, "invoice"));
        if (isEmpty(state.errors)) {
            dispatch(utils.actions.somethingSaving(true, "comp"));
            saveDealNEW(deal)
                .then((response) => {
                    dispatch(utils.actions.somethingSaving(false, "comp"));
                    const url = constants.urls.generate_invoice(deal.dealKey);
                    request(
                        {
                            url: url,
                            method: "get",
                            crossDomain: true,
                            responseType: "blob",
                        },
                        dispatch
                    )
                        .then((response) => {
                            dispatch(handleToggleBooleanProperty("executeInvoiceModalOpen"));
                            dispatch(utils.actions.somethingSaving(false, "invoice"));
                            console.log(response);
                            const url = window.URL.createObjectURL(
                                new File([response.data], "invoice.docx", {
                                    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                })
                            );
                            window.open(url);
                            ReactGA.event("Generate Invoice", {
                                dealKey: deal.dealKey,
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            dispatch(handleToggleBooleanProperty("executeInvoiceModalOpen"));
                            dispatch(utils.actions.somethingSaving(false, "invoice"));
                            dispatch(
                                dealSnackbar(true, "error", "Error occurred while trying to execute invoice: " + err)
                            );
                            dispatch(utilOperations.reportError(err, "deal:operations:executeInvoice(inner) Error"));
                        });
                })
                .catch((err) => {
                    dispatch(
                        utils.actions.snackbar(
                            true,
                            "error",
                            "Error occured while trying to save this comp. Invoice not generated."
                        )
                    );
                    dispatch(utils.actions.somethingSaving(false, "comp"));
                    dispatch(utils.actions.somethingSaving(false, "invoice"));
                    dispatch(utilOperations.reportError(err, "deal:operations:executeInvoice(outer) Error"));
                    console.log(err);
                });
        } else {
            dispatch(utils.actions.somethingSaving(false, "invoice"));
        }
    };
};

const handlePreGenerateInvoiceTasks = (deal) => {
    return (dispatch, getState) => {
        console.log(deal);
        const d = buildCompRequestFromState(getState());
        dispatch(actions.clearErrors());
        const documents = getState().documentsReducer;
        let errors = {};
        errors = compValidation.validateComp(d, deal.propertyType, deal.transactionType, documents);
        const panelsToExpand = compValidation.getPanelsToExpand(errors, deal.propertyType, deal.transactionType);

        console.log("Form errors: ", errors);
        if (isEmpty(errors) || isNil(errors)) {
        } else {
            dispatch(utils.actions.setErrorPanelsToExpand(panelsToExpand));
            dispatch(utils.actions.toggleCheckErrorPanels(true));
            forIn(errors, (value, key) => {
                dispatch(actions.addError(key, value));
            });
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "You are either missing a Required Field or you have input the wrong datatype in a numeric or date field. Please expand all sections and correct the issue(s) before resubmitting"
                )
            );
        }
    };
};

const dealChange = (field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.dealChange(field, value));
    };
};

const handleToggleBooleanProperty = (field) => {
    return (dispatch, getState) => {
        dispatch(actions.toggleBooleanProperty(field));
    };
};

const handleCheckboxClick = (id) => {
    return (dispatch, getState) => {
        const selected = getState().dealReducer.selected;
        let newSelected = [];
        if (selected) {
            const selectedIndex = selected.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            }
        } else {
            newSelected.push(id);
        }
        dispatch(actions.checkboxClick(newSelected));
    };
};

const buildRequestFromState = (state) => {
    const deal = state.dealReducer.deal;
    console.log(deal);
    const landlord = contactUtils.getContactForType(contactConstants.DEAL_CONTACTS, "SELLER_LANDLORD", state);
    const tenant = contactUtils.getContactForType(contactConstants.DEAL_CONTACTS, "BUYER_TENANT", state);
    const outsideBroker = contactUtils.getContactForType(contactConstants.DEAL_CONTACTS, "OUTSIDE_BROKER", state);
    const invoiceRecipient = contactUtils.getInvoiceRecipient(contactConstants.DEAL_CONTACTS, state); //getInvoiceRecipient(state.contactInformation);
    const sellerLandlordContact = landlord ? contactUtils.packContactForRequest(landlord) : null;
    const buyerTenantContact = tenant ? contactUtils.packContactForRequest(tenant) : null;
    const outsideBrokerContact = outsideBroker ? contactUtils.packContactForRequest(outsideBroker) : null;
    const totalConsiderationInfo = buildTotalConsiderationDealInfo(state.dealReducer.totalConsideration);
    const invoice = state.dealReducer.invoice;

    var data = {
        dealKey: deal.dealKey,
        transactionType: deal.transactionType,
        useType: deal.useType,
        listing: deal.listingNumber != null ? { listingKey: deal.listingNumber } : null,
        buyerTenantCompany: state.dealReducer.companyInformation["BUYER_TENANT"]
            ? state.dealReducer.companyInformation["BUYER_TENANT"]
            : null,
        sellerLandlordCompany: landlord ? landlord.company : null,
        outsideBrokerCompany: outsideBroker ? outsideBroker.company : null,
        paymentMethod: deal.paymentMethod,
        representationType: deal.representationType,
        kmExclusiveFlg: deal.kmExclusiveFlg,
        kmManagedFlg: deal.kmManagedFlg,
        includeOnBrokerBioFlg: deal.includeOnBrokerBioFlg,
        installments: buildInstallmentsFromState(state.dealReducer),
        sellerLandlordContact: sellerLandlordContact,
        buyerTenantContact: buyerTenantContact,
        outsideBrokerContact: outsideBrokerContact,
        invoiceRecipient: invoiceRecipient ? contactUtils.packContactForRequest(invoiceRecipient) : null,
        invoice: invoice ? buildInvoiceInfo(invoice) : null,
        totalConsiderationAmount: deal.totalConsiderationAmount,
        totalKmCommission: deal.totalKmCommission,
        outsideBrokerCommission: deal.outsideBrokerCommission,
        totalCommission: deal.totalCommission,
        squareFeet: deal.squareFeet,
        ...deal,
    };

    const propertyAttributes = state.dealReducer.propertyAttributes;
    let propAttrs = buildPropertyAttributesInfo(propertyAttributes, state);
    data = {
        ...data,
        propertyAttributes: propAttrs,
    };

    if (deal.transactionType === "LEASE") {
        data = {
            ...data,
            leaseType: deal.leaseType,
            spaceType: deal.spaceType,
            tiRate: deal.tiRate,
            tiTotal: deal.tiTotal,
            previousLocation: deal.previousLocation,
            currentSf: deal.currentSf,
            squareFeet: deal.squareFeet,
            squareFeetChange: deal.squareFeetChange,
            buyerTenantBusinessType: deal.buyerTenantBusinessType,
            naicsCode: deal.naicsCode,
            sicCode: deal.sicCode,
            movingAllowanceAmount: deal.movingAllowanceAmount,
            improvementAllowanceAmount: deal.improvementAllowanceAmount,
            rateType: deal.rateType,
            leaseTypeAddition: deal.leaseTypeAddition,
            ...totalConsiderationInfo,
        };
    } else {
        data = {
            ...data,
            salePrice: deal.totalConsiderationAmount,
            investmentSaleFlg: deal.investmentSaleFlg,
            leaseBackFlg: deal.leaseBackFlg,
            displayCondoAddressFlg: deal.displayCondoAddressFlg,
            displayCondoSfFlg: deal.displayCondoSfFlg,
            portfolioFlg: deal.portfolioFlg,
            portfolioPropertyCount: deal.portfolioPropertyCount,
            portfolioPropertyIds: deal.portfolioPropertyIds,
            squareFeet: deal.squareFeet,
        };
    }
    return data;
};

const fieldsToStripCommasFor = [
    "listing.sfAvail",
    "listing.sfDivisibleTo",
    "listing.attributes.anchorSf",
    "listing.attributes.padSf",
    "listing.attributes.shopSf",
    "listing.improvementAllowance",
    "listing.askingRentMin",
    "listing.askingRentMax",
    "listing.askingRentPerMonth",
    "listing.baseRentPerYear",
    "listing.totalMonthlyRent",
    "listing.totalRentPerYear",
    "listing.nnnPerMonth",
    "listing.nnnPerYear",
    "listing.attributes.officeBreakdownSfRate",
    "listing.attributes.blendedRate",
    "listing.attributes.warehouseShellBreakdownRate",
    "listing.attributes.sfAvail",
    "listing.attributes.auditoriumSeatsCount",
    "listing.attributes.classroomCount",
    "ratePerMonth",
    "escalationRate",
    "ratePerSqftPerMonth",
    "ratePerSqftPerYear",
    "listing.listPrice",
    "listing.capRate",
    "totalCommission",
    "totalKmCommission",
    "outsideBrokerCommission",
    "saleAdjustment",
    "analysisPrice",
    "salePricePerUnit",
    "noi",
    "noiPerSf",
    "occupancyRate",
    "landToBldgRatio",
    "discountRatePerIrr",
    "reversionCapRate",
];

const fieldsToFormatAsInt = [
    "listing.attributes.parkingReservedSpaceCount",
    "listing.attributes.largestContigSf",
    "listing.attributes.industrialSf",
    "listing.attributes.officeSf",
    "listing.attributes.yardSf",
    "listing.attributes.carportParkingSpaceCount",
    "listing.attributes.parkingGarageSpaceCount",
    "listing.attributes.surfaceParkingSpaceCount",
    "listing.attributes.parkingTotal",
    "listing.attributes.classroomCount",
    "listing.attributes.auditoriumSeatsCount",
    "listing.attributes.sfAvail",
    "listing.attributes.anchorSf",
    "listing.attributes.padSf",
    "listing.attributes.shopSf",
    "squareFeet",
    "escalationTerm",
    "currentSf",
    "transactionLandSf",
    "monthsOnMarket",
    "numTenants",
];

const buildCompRequestFromState = (state) => {
    const deal = state.dealReducer.deal;
    const invoice = state.dealReducer.invoice;
    const totalConsiderationInfo = buildTotalConsiderationDealInfo(state.dealReducer.totalConsideration);
    console.log(totalConsiderationInfo);
    console.log(deal);

    let data = {
        ...deal,
        totalInstallments: deal.installments ? deal.installments.length : 1,
        transactionDate: utils.operations.serializeLocalDate(get(deal, "transactionDate", null)),
        closedDate: utils.operations.serializeLocalDate(get(deal, "closedDate", null)),
        listing: {
            ...deal.listing,
            listingType: get(state, "dealReducer.deal.transactionType", ""),
            dateAvailable: utils.operations.serializeLocalDate(get(deal, "listing.dateAvailable", null)),
            expirationDate: utils.operations.serializeLocalDate(get(deal, "listing.expirationDate", null)),
            leaseExpirationDate: utils.operations.serializeLocalDate(get(deal, "listing.leaseExpirationDate", null)),
            dateListed: utils.operations.serializeLocalDate(get(deal, "listing.dateListed", "")),
            nnnPerMonth: numeral(deal.listing.nnnPerMonth).format("0.00"),
            nnnPerYear: numeral(deal.listing.nnnPerYear).format("0.00"),
            operatingExpensePerMonth: numeral(deal.listing.operatingExpensePerMonth).format("0.00"),
            operatingExpensePerYear: numeral(deal.listing.operatingExpensePerYear).format("0.00"),
            baseRentPerYear: deal.listing.baseRentPerYear,
            askingRentMax: deal.listing.askingRentMax,
            askingRentMin: deal.listing.askingRentMin,
            askingRentPerMonth: deal.listing.askingRentPerMonth,
            availabilityStatus: deal.listing.availabilityStatus == null ? "Occupied" : deal.listing.availabilityStatus,
            attributes: {
                ...deal.listing.attributes,
                completionDate: utils.operations.serializeLocalDate(
                    get(deal, "listing.attributes.completionDate", null)
                ),
                demolitionDate: utils.operations.serializeLocalDate(
                    get(deal, "listing.attributes.demolitionDate", null)
                ),
                avgAnnualPsfRate: numeral(get(deal, "listing.attributes.avgAnnualPsfRate", null)).format("0.00"),
                avgMonthlyPsfRate: numeral(get(deal, "listing.attributes.avgMonthlyPsfRate", null)).format("0.00"),
                avgRevPar: numeral(get(deal, "listing.attributes.avgRevPar", null)).format("0.00"),
                blendedRate: numeral(get(deal, "listing.attributes.blendedRate", null)).format("0.00"),
                warehouseShellBreakdownRate: numeral(
                    get(deal, "listing.attributes.warehouseShellBreakdownRate", null)
                ).format("0.00"),
                yardBreakdownSfRate: numeral(get(deal, "listing.attributes.yardBreakdownSfRate", null)).format("0.00"),
            },
            listingBrokers: compact([
                ...get(deal, "listing.listingBrokers.kmListingBrokers", []),
                ...get(deal, "listing.listingBrokers.outsideListingBrokers", []),
            ]),
        },
        invoice: invoice ? buildInvoiceInfo(invoice) : null,
        installments: buildInstallmentsFromState(deal),
        totalConsiderationAmount: deal.totalConsiderationAmount
            ? convertForNumberValidation(deal.totalConsiderationAmount)
            : null,
        squareFeet: deal.squareFeet,
    };

    if (toLower(get(state, "dealReducer.deal.transactionType", "")) === "lease") {
        data = {
            ...data,
            ...totalConsiderationInfo,
        };
    } else {
        data = {
            ...data,
            salePrice: deal.totalConsiderationAmount ? numeral(deal.totalConsiderationAmount).format("0.00") : null,
        };
    }

    let invoiceRecipient;
    const contacts = get(deal, "propertyAttributes, contacts", []);
    if (contacts.length > 0) {
        each(contacts, (c) => {
            if (c.invoiceRecipientFlg) {
                invoiceRecipient = c;
            }
        });
        data = {
            ...data,
            invoiceRecipient: invoiceRecipient,
        };
    }

    forEach(fieldsToStripCommasFor, (field) => {
        set(
            data,
            field,
            Boolean(get(data, field))
                ? isNaN(stripCommas(get(data, field)))
                    ? get(data, field)
                    : numeral(get(data, field)).format("0.00")
                : get(data, field) === 0
                ? get(data, field)
                : null
        );
    });
    forEach(fieldsToFormatAsInt, (field) => {
        set(
            data,
            field,
            Boolean(get(data, field))
                ? isNaN(stripCommas(get(data, field)))
                    ? get(data, field)
                    : numeral(get(data, field)).format("0")
                : get(data, field) === 0
                ? get(data, field)
                : null
        );
    });
    console.log(data);
    return data;
};

const buildTotalConsiderationDealInfo = (totalConsideration) => {
    let data = {};
    if (totalConsideration) {
        data = {
            commencementDate: utils.operations.serializeLocalDate(get(totalConsideration, "commencementDate", null)),
            expirationDate: utils.operations.serializeLocalDate(get(totalConsideration, "expirationDate", null)),
            termNumMonths: totalConsideration.termNumMonths,
            ratePerMonth: totalConsideration.ratePerMonth,
            escalationRate: totalConsideration.escalationRate,
            escalationRateType: totalConsideration.escalationRateType,
            escalationTerm: totalConsideration.escalationTerm,
            squareFeet: utils.operations.convertForIntegerValidation(totalConsideration.squareFeet),
            ratePerSqftPerMonth: totalConsideration.ratePerSqftPerMonth,
            ratePerSqftPerYear: totalConsideration.ratePerSqftPerYear,
            escalateAtYearStart: totalConsideration.escalateAtYearStart,
            preTermFreeMonths: totalConsideration.preTermFreeMonths,
            postTermFreeMonths: totalConsideration.postTermFreeMonths,
        };
    }
    return data;
};

const unpackTotalConsideration = (deal) => {
    let tc = {};
    if (deal) {
        tc = {
            commencementDate: deal.commencementDate,
            expirationDate: deal.expirationDate,
            termNumMonths: deal.termNumMonths,
            ratePerMonth: deal.ratePerMonth,
            escalationRate: deal.escalationRate,
            escalationRateType: deal.escalationRateType !== null ? deal.escalationRateType : "%",
            escalationTerm: deal.escalationTerm,
            squareFeet: deal.squareFeet,
            ratePerSqftPerMonth: deal.ratePerSqftPerMonth,
            ratePerSqftPerYear: deal.ratePerSqftPerYear,
            escalateAtYearStart: deal.escalateAtYearStart,
            preTermFreeMonths: deal.preTermFreeMonths,
            postTermFreeMonths: deal.postTermFreeMonths,
        };
    }
    return tc;
};

const buildInvoiceInfo = (invoiceInfo) => {
    let data = null;
    if (Object.keys(invoiceInfo).length > 0) {
        data = {
            invoiceKey: invoiceInfo.invoiceKey,
            periods: buildInvoicePeriods(invoiceInfo.periods),
        };
    }
    return data;
};

const buildInvoicePeriods = (invoicePeriods) => {
    let data = [];
    if (invoicePeriods) {
        Object.keys(invoicePeriods).forEach((key, index) => {
            const period = invoicePeriods[key];
            data.push(buildInvoicePeriod(period));
        });
    }
    return data;
};

const buildInvoicePeriod = (invoicePeriod) => {
    let data = {
        invoicePeriodKey: invoicePeriod.invoicePeriodKey,
        startDate: invoicePeriod.startDate,
        endDate: invoicePeriod.endDate,
        termInMonths: invoicePeriod.termInMonths,
        termAdditionalDays: invoicePeriod.termAdditionalDays,
        baseMonthlyRate: invoicePeriod.baseMonthlyRate,
        periodNumber: invoicePeriod.periodNumber,
        periodId: invoicePeriod.periodId,
        months: buildInvoiceMonths(invoicePeriod.months),
        preTermPeriod: invoicePeriod.preTermPeriod,
        postTermPeriod: invoicePeriod.postTermPeriod,
        escalationRate: stripCommas(invoicePeriod.escalationRate),
        escalationRateType: invoicePeriod.escalationRateType,
    };
    return data;
};

const buildInvoiceMonths = (invoiceMonths) => {
    let data = [];
    if (invoiceMonths) {
        Object.keys(invoiceMonths).forEach((key, index) => {
            const month = invoiceMonths[key];
            data.push(month);
        });
    }
    return data;
};

const buildPropertyAttributesInfo = (propertyAttributes, state) => {
    let data = null;
    if (propertyAttributes) {
        const buildingOwner = contactUtils.getContactForType(
            contactConstants.PROPERTY_CONTACTS,
            "BUILDING_OWNER",
            state
        );
        const propertyManager = contactUtils.getContactForType(
            contactConstants.PROPERTY_CONTACTS,
            "PROPERTY_MANAGER",
            state
        );

        const buildingOwnerContact = buildingOwner ? contactUtils.packContactForRequest(buildingOwner) : null;
        const propertyManagerContact = propertyManager ? contactUtils.packContactForRequest(propertyManager) : null;

        data = {
            ...propertyAttributes,
            buildingOwnerContact: buildingOwnerContact,
            propertyManagerContact: propertyManagerContact,
            address: packAddress(propertyAttributes),
        };
    }
    console.log(data);
    return data;
};

const packAddress = (address) => {
    let data = {};
    if (address) {
        if (address.addressKey || address.address1 || address.address2) {
            data = {
                key: address.addressKey,
                address1: address.address1,
                address2: address.address2,
            };
        }

        if (address.cityKey || address.city || address.state) {
            data = {
                ...data,
                city: {
                    key: address.cityKey,
                    city: address.city,
                    state: {
                        stateCode: address.state,
                    },
                },
            };
        }

        if (address.zipCodeKey || address.zipCode) {
            data = {
                ...data,
                zipCode: {
                    key: address.zipCodeKey,
                    zipCode: address.zipCode,
                },
            };
        }

        if (address.countyKey || address.county) {
            data = {
                ...data,
                county: {
                    countyKey: address.countyKey,
                    name: address.county,
                },
            };
        }
    }
    return Object.keys(data).length > 0 ? data : null;
};

const handleAddInstallment = () => {
    return (dispatch, getState) => {
        const key = Object.keys(getState().dealReducer.installments).length;
        const installment = {
            [key]: {
                installmentKey: null,
                amount: null,
                dueDate: null,
            },
        };
        dispatch(actions.addInstallment(installment));
    };
};

const updateInstallment = (key, field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateInstallment(key, field, value));
    };
};

const handleDeleteInstallment = (key) => {
    return (dispatch, getState) => {
        dispatch(actions.deleteInstallment(key));
    };
};

const handleTotalConsiderationChange = (field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.totalConsiderationChange(field, value));
        dispatch(
            actions.totalConsiderationInputUpdates(
                recalcTotalConsiderationInputs(getState().dealReducer.totalConsideration, field, value)
            )
        );
    };
};

const handlePropertyInfoUpdate = (field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updatePropertyInfo(field, value));
    };
};

const recalcTotalConsiderationInputs = (totalConsideration, changedField, value) => {
    let expirationDate = null;
    let commencementDate = null;
    let termNumMonths = null;
    let squareFeet = null;
    let ratePerMonth = null;
    let ratePerSqftPerMonth = null;
    let ratePerSqftPerYear = null;
    let updates = {};

    if (changedField === "commencementDate") {
        if (!isNaN(Date.parse(totalConsideration.commencementDate))) {
            if (!isNaN(totalConsideration.termNumMonths)) {
                expirationDate = utils.operations.calculateExpirationDate(
                    totalConsideration.commencementDate,
                    totalConsideration.termNumMonths
                );
            } else if (!isNaN(Date.parse(totalConsideration.expirationDate))) {
                termNumMonths = utils.operations.calculateTerms(
                    totalConsideration.commencementDate,
                    totalConsideration.expirationDate
                );
            }
        }
    } else if (changedField === "expirationDate") {
        if (
            !isNaN(Date.parse(totalConsideration.commencementDate)) &&
            !isNaN(Date.parse(totalConsideration.expirationDate))
        ) {
            termNumMonths = utils.operations.calculateTerms(
                totalConsideration.commencementDate,
                totalConsideration.expirationDate
            );
        }
    } else if (changedField === "termNumMonths") {
        if (!isNaN(totalConsideration.termNumMonths) && !isNaN(Date.parse(totalConsideration.commencementDate))) {
            expirationDate = utils.operations.calculateExpirationDate(
                totalConsideration.commencementDate,
                totalConsideration.termNumMonths
            );
        }
    } else if (changedField === "squareFeet") {
        const sf = convertForIntegerValidation(value);
        if (!isNaN(sf)) {
            if (!isNaN(totalConsideration.ratePerSqftPerMonth)) {
                ratePerMonth = totalConsideration.ratePerSqftPerMonth * sf;
            }
        } else {
            ratePerMonth = "";
        }
    } else if (changedField === "ratePerSqftPerYear") {
        if (!isNaN(value)) {
            ratePerSqftPerMonth = round(value / 12, 2);
            const sf = convertForIntegerValidation(totalConsideration.squareFeet);
            if (!isNaN(sf)) {
                ratePerMonth = ratePerSqftPerMonth * sf;
            }
        } else {
            ratePerSqftPerMonth = "";
        }
    } else if (changedField === "ratePerSqftPerMonth") {
        if (!isNaN(value)) {
            const sf = convertForIntegerValidation(totalConsideration.squareFeet);
            if (!isNaN(sf)) {
                ratePerMonth = value * sf;
            }
            ratePerSqftPerYear = value * 12;
        } else {
            ratePerSqftPerYear = "";
            ratePerMonth = "";
        }
    } else if (changedField === "ratePerMonth") {
        if (!isNaN(value)) {
            const sf = convertForIntegerValidation(totalConsideration.squareFeet);
            if (!isNaN(totalConsideration.ratePerMonth) && !isNaN(sf)) {
                ratePerSqftPerMonth = round(value / sf, 2);
                ratePerSqftPerYear = round((value * 12) / sf, 2);
            }
        } else {
            ratePerSqftPerYear = "";
            ratePerSqftPerMonth = "";
        }
    }
    if (expirationDate != null && !isNaN(Date.parse(expirationDate))) {
        updates["expirationDate"] = expirationDate;
    }
    if (commencementDate != null && !isNaN(Date.parse(commencementDate))) {
        updates["commencementDate"] = commencementDate;
    }
    if (termNumMonths != null && !isNaN(termNumMonths)) {
        updates["termNumMonths"] = termNumMonths;
    }
    if (squareFeet != null && !isNaN(squareFeet)) {
        updates["squareFeet"] = squareFeet;
    }
    if (ratePerMonth != null && !isNaN(ratePerMonth)) {
        updates["ratePerMonth"] = ratePerMonth;
    }
    if (ratePerSqftPerMonth != null && !isNaN(ratePerSqftPerMonth)) {
        updates["ratePerSqftPerMonth"] = ratePerSqftPerMonth;
    }
    if (ratePerSqftPerYear != null && !isNaN(ratePerSqftPerYear)) {
        updates["ratePerSqftPerYear"] = ratePerSqftPerYear;
    }
    return updates;
};

const handleClearRentSchedule = () => {
    return (dispatch, getState) => {
        dispatch(actions.clearTotalConsiderationCalculation());
    };
};

const handleCalculateTotalConsiderationDetails = () => {
    return (dispatch, getState) => {
        let totalConsideration = getState().dealReducer.totalConsideration;
        totalConsideration = {
            ...totalConsideration,
            commencementDate: utils.operations.formatDate("YYYY-MM-DD")(
                get(totalConsideration, "commencementDate", null)
            ),
            expirationDate: utils.operations.formatDate("YYYY-MM-DD")(get(totalConsideration, "expirationDate", null)),
            squareFeet: totalConsideration.squareFeet
                ? utils.operations.convertForIntegerValidation(totalConsideration.squareFeet)
                : null,
        };
        dispatch(validateTotalConsideration(totalConsideration));

        if (get(getState().dealReducer, "totalConsiderationValid", false) === true) {
            const url = constants.urls.calculate_deal_consideration();
            request(
                {
                    url: url,
                    method: "post",
                    crossDomain: true,
                    data: buildTotalConsiderationCalculationRequest(totalConsideration),
                },
                dispatch
            )
                .then((response) => {
                    dispatch(
                        actions.updateTotalConsiderationCalculation(
                            extractTotalConsiderationCalculationFromResponse(response)
                        )
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
};

const extractTotalConsiderationCalculationFromResponse = (response) => {
    return {
        ...extractInvoiceFromResponse(response.data),
    };
};

const extractInvoiceFromResponse = (responseInvoiceData) => {
    let invoice = responseInvoiceData;
    if (invoice) {
        invoice["periods"] = utils.operations.arrayToObject(invoice["periods"], "periodNumber");
        Object.keys(invoice["periods"]).forEach((key, index) => {
            invoice = {
                ...invoice,
                periods: {
                    ...invoice["periods"],
                    [key]: {
                        ...invoice["periods"][key],
                        months: utils.operations.arrayToObject(invoice["periods"][key]["months"], "monthInPeriod"),
                    },
                },
            };
        });
    }
    return invoice;
};

const buildTotalConsiderationCalculationRequest = (totalConsideration) => {
    const builtTotalConsideration = {
        ...totalConsideration,
        commencementDate: utils.operations.formatDate("MM/DD/YYYY")(get(totalConsideration, "commencementDate", null)),
        expirationDate: utils.operations.formatDate("MM/DD/YYYY")(get(totalConsideration, "expirationDate", null)),
        termNumMonths: totalConsideration.termNumMonths !== null ? totalConsideration.termNumMonths : 0,
    };
    return builtTotalConsideration;
};

const handlePeriodMonthChange = (period, month, field, value) => {
    return (dispatch, getState) => {
        if (field !== "rate") {
            dispatch(actions.periodMonthChange(period, month, field, value));
            dispatch(
                actions.periodMonthChange(
                    period,
                    month,
                    "rate",
                    recalcMonthlyRate(
                        getState().dealReducer.invoice["periods"][period],
                        getState().dealReducer.invoice["periods"][period]["months"][month]
                    )
                )
            );
        } else {
            dispatch(actions.periodMonthChange(period, month, field, value));
        }
        dispatch(
            actions.updatePeriodSummary(period, rebuildPeriodSummary(getState().dealReducer.invoice.periods[period]))
        );
        dispatch(
            actions.updateTotalConsiderationSummary(rebuildTotalConsiderationSummary(getState().dealReducer.invoice))
        );
    };
};

const handlePeriodChange = (periodNumber, field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.periodChange(periodNumber, field, value));
        if (field === "escalationRate" || field === "escalationRateType") {
            dispatch(recalcPeriodBaseMonthlyRate(periodNumber));
        }
    };
};

const recalcPeriodBaseMonthlyRate = (periodNumber) => {
    return (dispatch, getState) => {
        const keys = Object.keys(getState().dealReducer.invoice["periods"]);
        let targetPeriodFound = false;
        for (var i = periodNumber; i < keys.length; ++i) {
            if (
                !isNaN(getState().dealReducer.invoice["periods"][i].periodId) &&
                (i === periodNumber || targetPeriodFound) &&
                getState().dealReducer.invoice["periods"].hasOwnProperty(i - 1) &&
                !isNaN(getState().dealReducer.invoice["periods"][i - 1].periodId)
            ) {
                targetPeriodFound = true;
                const previousRate = getState().dealReducer.invoice["periods"][i - 1].baseMonthlyRate;
                const escalationRate = getState().dealReducer.invoice["periods"][i].escalationRate;
                const escalationRateType = getState().dealReducer.invoice["periods"][i].escalationRateType;
                let newRate = null;
                if (escalationRateType === "$") {
                    newRate = previousRate + Number(convertForNumberValidation(escalationRate));
                } else {
                    newRate = round(previousRate + (escalationRate / 100) * previousRate, 6);
                }
                dispatch(actions.periodChange(i, "baseMonthlyRate", newRate));
                for (var j = 0; j < Object.keys(getState().dealReducer.invoice["periods"][i]["months"]).length; ++j) {
                    dispatch(
                        actions.periodMonthChange(
                            i,
                            j,
                            "rate",
                            recalcMonthlyRate(
                                getState().dealReducer.invoice["periods"][i],
                                getState().dealReducer.invoice["periods"][i]["months"][j]
                            )
                        )
                    );
                }
                dispatch(
                    actions.updatePeriodSummary(i, rebuildPeriodSummary(getState().dealReducer.invoice.periods[i]))
                );
            }
        }
        dispatch(
            actions.updateTotalConsiderationSummary(rebuildTotalConsiderationSummary(getState().dealReducer.invoice))
        );
    };
};

const recalcMonthlyRate = (period, month) => {
    let rate = period.baseMonthlyRate;
    if (month.freeRent === true) {
        rate = 0;
    } else if (month.discountPercent && month.discountPercent > 0) {
        rate = round(rate - (month.discountPercent / 100) * rate, 2);
    }
    return rate;
};

const rebuildPeriodSummary = (period) => {
    let freeMonths = 0;
    let discountedMonths = 0;
    let totalAmount = 0;
    let baseRate = period.baseMonthlyRate;

    const months = Object.values(period.months);
    for (const month of months) {
        const rate = isNumber(month.rate)
            ? month.rate
            : Number(month.rate ? Number(convertForNumberValidation(month.rate)) : month.rate);
        if (rate === 0) {
            ++freeMonths;
        } else if (rate < baseRate) {
            ++discountedMonths;
        }
        totalAmount += rate;
    }

    const periodSummary = {
        totalMonths: months.length,
        freeMonths: freeMonths,
        discountedMonths: discountedMonths,
        totalAmount: totalAmount,
        baseMonthlyRate: period.baseMonthlyRate,
        preTermPeriod: period.preTermPeriod,
        postTermPeriod: period.postTermPeriod,
    };
    return periodSummary;
};

const rebuildTotalConsiderationSummary = (invoice) => {
    let freeMonths = 0;
    let discountedMonths = 0;
    let totalAmount = 0;
    let totalMonths = 0;

    const periods = Object.values(invoice.periods);
    for (const period of periods) {
        if (period.preTermPeriod !== true && period.postTermPeriod !== true) {
            freeMonths += period.summary.freeMonths;
            discountedMonths += period.summary.discountedMonths;
            totalMonths += period.summary.totalMonths;
            totalAmount += period.summary.totalAmount;
        }
    }

    return {
        totalMonths: totalMonths,
        freeMonths: freeMonths,
        discountedMonths: discountedMonths,
        totalAmount: totalAmount,
        baseMonthlyRate: invoice.summary.baseMonthlyRate,
        preTermMonths: invoice.summary.preTermMonths,
        postTermMonths: invoice.summary.postTermMonths,
    };
};

const buildInstallmentsFromState = (deal) => {
    if (!deal.installments || isEmpty(deal.installments)) {
        return [];
    } else {
        const installments = map(deal.installments, (inst) => {
            return {
                ...inst,
                amount: inst.amount ? numeral(inst.amount).format("0.00") : null,
                dueDate: utils.operations.serializeLocalDate(inst.dueDate),
            };
        });
        return installments;
    }
};

const validateDeal = () => {
    return (dispatch, getState) => {
        dispatch(actions.clearErrors());
        const state = getState().dealReducer;
        dispatch(validateBasicDealInfo(state.deal));
        if (getState().dealReducer.deal.transactionType === "LEASE") {
            dispatch(validateTotalConsideration(state.totalConsideration));
        }
        dispatch(validateInstallments(state.installments));
        dispatch(validateContacts(contactUtils.getContacts(contactConstants.DEAL_CONTACTS, getState())));
        dispatch(validateInvoiceRecipientRules(contactUtils.getContacts(contactConstants.DEAL_CONTACTS, getState())));
        dispatch(actions.setFlag("dealValid", isEmpty(getState().dealReducer.errors)));
    };
};

const validateBasicDealInfo = (deal) => {
    return (dispatch, getState) => {
        dispatch(processSimpleFieldValidationResponse(validation.validateBasicDealInfo(deal)));
        if (getState().dealReducer.deal.transactionType === "LEASE") {
            dispatch(processSimpleFieldValidationResponse(validation.validateLeaseDealInfo(deal)));
        } else if (getState().dealReducer.deal.transactionType === "SALE") {
            dispatch(processSimpleFieldValidationResponse(validation.validateSaleDealInfo(deal)));
        }
    };
};

const validateInstallments = (installments) => {
    return (dispatch, getState) => {
        each(Object.keys(installments), (i) => {
            const validationResponse = validation.validateInstallments(installments[i]);
            dispatch(processArrayFieldTypeValidationResponse(validationResponse, "installments", i));
        });
    };
};

const validateContacts = (contacts) => {
    return (dispatch, getState) => {
        each(Object.keys(contacts), (i) => {
            const validationResponse = contactUtils.validateContact(contacts[i]);
            dispatch(processArrayFieldTypeValidationResponse(validationResponse, "contactInfo", i));
        });
    };
};

const validateTotalConsideration = (totalConsideration) => {
    return (dispatch, getState) => {
        dispatch(actions.clearErrors());
        let valid = true;
        const validationResponse = validation.validateTotalConsideration(totalConsideration);
        if (validationResponse) {
            valid = false;
            dispatch(processSimpleFieldValidationResponse(validationResponse));
        }
        dispatch(actions.setFlag("totalConsiderationValid", valid));
    };
};

const processSimpleFieldValidationResponse = (validationResponse) => {
    return (dispatch, getState) => {
        if (validationResponse) {
            each(Object.keys(validationResponse), (e) => {
                dispatch(actions.addError(`${e}`, validationResponse[e][0]));
            });
        }
    };
};

const processArrayFieldTypeValidationResponse = (validationResponse, identifier, position) => {
    return (dispatch, getState) => {
        if (validationResponse) {
            each(Object.keys(validationResponse), (e) => {
                dispatch(actions.addError(`${identifier}.${position}.${e}`, validationResponse[e][0]));
            });
        }
    };
};

const validateInvoiceRecipientRules = (contacts) => {
    // there must be invoice recipient
    return (dispatch, getState) => {
        let invoiceRecipients = 0;
        const keys = Object.keys(contacts);
        for (const key in keys) {
            if (contacts[key] && contacts[key].invoiceRecipient === true) {
                ++invoiceRecipients;
                break;
            }
        }
        if (invoiceRecipients < 1) {
            dispatch(actions.addError("invoiceRecipient", "Invoice Recipient Required"));
            dispatch(utils.actions.snackbar(true, "error", "Invoice recipient required"));
        }
    };
};

const dealSnackbar = (open, variant, message) => {
    return (dispatch, getState) => {
        dispatch(actions.dealSnackbar(open, variant, message));
    };
};

const handleCompanyPropertyChange = (companyType, property, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateCompanyProperty(companyType, property, value));
    };
};

const handleObjectPropertyChange = (objectPropertyName, field, value) => {
    return (dispatch, getState) => {
        dispatch(actions.updateStateObjectProperty(objectPropertyName, field, value));
    };
};

const addVoucherInstallment = (dealKey) => {
    return (dispatch, _) => {
        dispatch(utils.operations.setLoader("addVoucherInstallment", true));
        request(
            {
                url: constants.urls.create_voucher_installment(dealKey),
                method: "get",
                crossDomain: true,
            },
            dispatch
        )
            .then((response) => {
                console.log(response);
                const voucher = response.data;
                each(voucher.brokerCommissions, (b) => {
                    b.runnerCommissions = utils.operations.arrayToObject(b.runnerCommissions, "runnerCommissionKey");
                    Object.keys(b.runnerCommissions).forEach((r) => {
                        voucherOperations.unpackRunner(b.runnerCommissions[r]);
                    });
                });
                voucher.brokerCommissions = utils.operations.arrayToObject(
                    voucher.brokerCommissions,
                    "brokerCommissionKey"
                );
                Object.keys(voucher.brokerCommissions).forEach((b) => {
                    voucherOperations.unpackBrokerCommission(voucher.brokerCommissions[b]);
                });
                dispatch(voucherActions.getVoucher(voucher));
                dispatch(push(`/voucher/${voucher.voucherKey}`));
                dispatch(utils.operations.setLoader("addVoucherInstallment", false));
            })
            .catch((err) => {
                dispatch(utils.operations.setLoader("addVoucherInstallment", false));
                utils.actions.snackbar(true, "error", "Something went wrong. Couldn't load voucher.");
            });
    };
};

const addInvoiceInstallment = (key) => {
    return (dispatch, _) => {
        dispatch(push(`/invoice-installment/${key}`));
    };
};

const startDeal = (dealKey) => {
    return (dispatch, getState) => {
        if (dealKey) {
            request(
                {
                    url: constants.urls.start_deal(dealKey),
                    method: "get",
                    crossDomain: true,
                },
                dispatch
            )
                .then((response) => {
                    dispatch(push(`/comp/${dealKey}`));
                })
                .catch((err) => {
                    console.log("error occurred!" + err);
                    dispatch(dealSnackbar(true, "error", "Something unexpected went wrong. Unable to start deal."));
                    dispatch(utilOperations.reportError(err, "deal:operations:startDeal Error"));
                });
        }
    };
};

const deleteDeal = (dealKey) => {
    return (dispatch, getState) => {
        if (dealKey) {
            request(
                {
                    url: constants.urls.delete_deal(dealKey),
                    method: "put", //put here because we are really updating status
                    crossDomain: true,
                },
                dispatch
            )
                .then((response) => {
                    dispatch(push(`/deals`));
                })
                .catch((err) => {
                    console.log("error occurred!" + err);
                    dispatch(
                        utils.actions.snackbar(true, "error", "Something unexpected went wrong. Unable to delete deal.")
                    );
                    dispatch(utilOperations.reportError(err, "deal:operations:deleteDeal Error"));
                });
        }
    };
};

const verifyDocumentAndTerminatePendingDeal = (dealKey, terminationDocument) => {
    return (dispatch, getState) => {
        if (terminationDocument) {
            //HERE IS WHERE WE WILL HANDLE REQUESTING TERMINATION OF THE DEAL AND SEND IT TO PORTLAND/AZ TERMINATION REVIEW REQUIRED
            request(
                {
                    url: constants.urls.request_deal_termination(dealKey),
                    method: "put",
                    crossDomain: true,
                },
                dispatch
            )
                .then((response) => {
                    console.log("RESPONSE");
                    console.log(response);
                    dispatch(
                        utils.actions.snackbar(
                            true,
                            "success",
                            "Deal termination requested. You can view it under your Closed Deals upon confirmation by Admin."
                        )
                    );
                    dispatch(push("/deals"));
                })
                .catch((err) => {
                    console.log("ERROR");
                    console.log(err);
                    dispatch(utils.actions.loading2(false));
                    dispatch(
                        utils.actions.snackbar(
                            true,
                            "error",
                            "Something unexpected went wrong. Unable to terminate deal."
                        )
                    );
                    dispatch(utilOperations.reportError(err, "deal:operations:terminateDeal Error"));
                });
        } else {
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "Please attach a Termination Document to the Comp before terminating this Deal. This can be an official termination document, an email, or other correspondence."
                )
            );
        }
    };
};

const verifyDocumentAndTerminateDeal = (dealKey, terminationDocument, dealStatus) => {
    return (dispatch, getState) => {
        if (terminationDocument) {
            //HERE IS WHERE WE WILL HANDLE TERMINATION OF THE DEAL AND SEND IT TO CLOSED DEALS
            //MIGHT BE ABLE TO SIMPLIFY THIS CODE
            if (dealStatus === "Portland Termination Review Required") {
                request(
                    {
                        url: constants.urls.process_portland_terminate(dealKey),
                        method: "put",
                        crossDomain: true,
                    },
                    dispatch
                )
                    .then((response) => {
                        console.log("RESPONSE");
                        console.log(response);
                        dispatch(
                            utils.actions.snackbar(
                                true,
                                "success",
                                "Deal terminated. It can be viewed under Oregon Closed Deals."
                            )
                        );
                        dispatch(push("/deals"));
                    })
                    .catch((err) => {
                        console.log("ERROR");
                        console.log(err);
                        dispatch(utils.actions.loading2(false));
                        dispatch(
                            utils.actions.snackbar(
                                true,
                                "error",
                                "Something unexpected went wrong. Unable to terminate deal."
                            )
                        );
                        dispatch(utilOperations.reportError(err, "deal:operations:terminateDeal Error"));
                    });
            } else if (dealStatus === "AZ Termination Review Required") {
                request(
                    {
                        url: constants.urls.process_arizona_terminate(dealKey),
                        method: "put",
                        crossDomain: true,
                    },
                    dispatch
                )
                    .then((response) => {
                        console.log("RESPONSE");
                        console.log(response);
                        dispatch(
                            utils.actions.snackbar(
                                true,
                                "success",
                                "Deal terminated. It can be viewed under Arizona Closed Deals."
                            )
                        );
                        dispatch(push("/deals"));
                    })
                    .catch((err) => {
                        console.log("ERROR");
                        console.log(err);
                        dispatch(utils.actions.loading2(false));
                        dispatch(
                            utils.actions.snackbar(
                                true,
                                "error",
                                "Something unexpected went wrong. Unable to terminate deal."
                            )
                        );
                        dispatch(utilOperations.reportError(err, "deal:operations:terminateDeal Error"));
                    });
            }
        } else {
            dispatch(
                utils.actions.snackbar(
                    true,
                    "error",
                    "Please verify a Termination Document is attached to the Comp before terminating this Deal. This can be an official termination document, an email, or other correspondence."
                )
            );
        }
    };
};

const clearTotalConsiderationState = () => {
    return (dispatch, getState) => {
        dispatch(actions.clearTotalConsideration());
    };
};

const toggleTerminateDialog = (isOpen, dealStatus) => {
    return (dispatch, getState) => {
        if (includes(["Portland Termination Review Required", "AZ Termination Review Required"], dealStatus)) {
            dispatch(actions.toggleConfirmTerminateDialog(isOpen));
        } else {
            dispatch(actions.toggleTerminateDialog(isOpen));
        }
    };
};

const terminationDocUpload = (uploaded) => {
    return (dispatch, getState) => {
        dispatch(actions.toggleTerminationDocument(uploaded));
    };
};

const downloadDealDocuments = (dealKey) => {
    return (dispatch, getState) => {
        request(
            {
                url: constants.urls.download_deal_documents(dealKey),
                method: "get",
                crossDomain: true,
                responseType: "blob",
                headers: {
                    Accept: "application/zip",
                },
            },
            dispatch
        )
            .then((response) => {
                console.log(response);

                let blob = new Blob([response.data], { type: "application/zip" });
                saveAs(blob, "deal-docs-" + dealKey + ".zip");
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export default {
    getDeal,
    getDealSummary,
    handleSort,
    validateDeal,
    saveDeal,
    dealChange,
    handleCheckboxClick,
    handleAddInstallment,
    updateInstallment,
    handleDeleteInstallment,
    handleTotalConsiderationChange,
    handleCalculateTotalConsiderationDetails,
    handlePeriodMonthChange,
    handlePropertyInfoUpdate,
    dealSnackbar,
    handleCompanyPropertyChange,
    handleToggleBooleanProperty,
    executeInvoice,
    handleObjectPropertyChange,
    addVoucherInstallment,
    addInvoiceInstallment,
    buildRequestFromState,
    buildCompRequestFromState,
    saveDealNEW,
    unpackTotalConsideration,
    startDeal,
    deleteDeal,
    clearTotalConsiderationState,
    verifyDocumentAndTerminatePendingDeal,
    verifyDocumentAndTerminateDeal,
    toggleTerminateDialog,
    terminationDocUpload,
    loadDealDocumentList,
    downloadDealDocuments,
    handleClearRentSchedule,
    handlePeriodChange,
};
