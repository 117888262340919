export const us_states_options = [
    {
        value: "",
        label: "Select",
        disabled: true,
    },
    {
        label: "None",
        abbreviation: "NA",
        value: "NA",
    },
    {
        label: "Alabama",
        abbreviation: "AL",
        value: "AL",
    },
    {
        label: "Alaska",
        abbreviation: "AK",
        value: "AK",
    },
    {
        label: "Arizona",
        abbreviation: "AZ",
        value: "AZ",
    },
    {
        label: "Arkansas",
        abbreviation: "AR",
        value: "AR",
    },
    {
        label: "California",
        abbreviation: "CA",
        value: "CA",
    },
    {
        label: "Colorado",
        abbreviation: "CO",
        value: "CO",
    },
    {
        label: "Connecticut",
        abbreviation: "CT",
        value: "CT",
    },
    {
        label: "Delaware",
        abbreviation: "DE",
        value: "DE",
    },
    {
        label: "District Of Columbia",
        abbreviation: "DC",
        value: "DC",
    },
    {
        label: "Florida",
        abbreviation: "FL",
        value: "FL",
    },
    {
        label: "Georgia",
        abbreviation: "GA",
        value: "GA",
    },
    {
        label: "Hawaii",
        abbreviation: "HI",
        value: "HI",
    },
    {
        label: "Idaho",
        abbreviation: "ID",
        value: "ID",
    },
    {
        label: "Illinois",
        abbreviation: "IL",
        value: "IL",
    },
    {
        label: "Indiana",
        abbreviation: "IN",
        value: "IN",
    },
    {
        label: "Iowa",
        abbreviation: "IA",
        value: "IA",
    },
    {
        label: "Kansas",
        abbreviation: "KS",
        value: "KS",
    },
    {
        label: "Kentucky",
        abbreviation: "KY",
        value: "KY",
    },
    {
        label: "Louisiana",
        abbreviation: "LA",
        value: "LA",
    },
    {
        label: "Maine",
        abbreviation: "ME",
        value: "ME",
    },
    {
        label: "Maryland",
        abbreviation: "MD",
        value: "MD",
    },
    {
        label: "Massachusetts",
        abbreviation: "MA",
        value: "MA",
    },
    {
        label: "Michigan",
        abbreviation: "MI",
        value: "MI",
    },
    {
        label: "Minnesota",
        abbreviation: "MN",
        value: "MN",
    },
    {
        label: "Mississippi",
        abbreviation: "MS",
        value: "MS",
    },
    {
        label: "Missouri",
        abbreviation: "MO",
        value: "MO",
    },
    {
        label: "Montana",
        abbreviation: "MT",
        value: "MT",
    },
    {
        label: "Nebraska",
        abbreviation: "NE",
        value: "NE",
    },
    {
        label: "Nevada",
        abbreviation: "NV",
        value: "NV",
    },
    {
        label: "New Hampshire",
        abbreviation: "NH",
        value: "NH",
    },
    {
        label: "New Jersey",
        abbreviation: "NJ",
        value: "NJ",
    },
    {
        label: "New Mexico",
        abbreviation: "NM",
        value: "NM",
    },
    {
        label: "New York",
        abbreviation: "NY",
        value: "NY",
    },
    {
        label: "North Carolina",
        abbreviation: "NC",
        value: "NC",
    },
    {
        label: "North Dakota",
        abbreviation: "ND",
        value: "ND",
    },
    {
        label: "Ohio",
        abbreviation: "OH",
        value: "OH",
    },
    {
        label: "Oklahoma",
        abbreviation: "OK",
        value: "OK",
    },
    {
        label: "Oregon",
        abbreviation: "OR",
        value: "OR",
    },
    {
        label: "Pennsylvania",
        abbreviation: "PA",
        value: "PA",
    },
    {
        label: "Rhode Island",
        abbreviation: "RI",
        value: "RI",
    },
    {
        label: "South Carolina",
        abbreviation: "SC",
        value: "SC",
    },
    {
        label: "South Dakota",
        abbreviation: "SD",
        value: "SD",
    },
    {
        label: "Tennessee",
        abbreviation: "TN",
        value: "TN",
    },
    {
        label: "Texas",
        abbreviation: "TX",
        value: "TX",
    },
    {
        label: "Utah",
        abbreviation: "UT",
        value: "UT",
    },
    {
        label: "Vermont",
        abbreviation: "VT",
        value: "VT",
    },
    {
        label: "Virginia",
        abbreviation: "VA",
        value: "VA",
    },
    {
        label: "Washington",
        abbreviation: "WA",
        value: "WA",
    },
    {
        label: "West Virginia",
        abbreviation: "WV",
        value: "WV",
    },
    {
        label: "Wisconsin",
        abbreviation: "WI",
        value: "WI",
    },
    {
        label: "Wyoming",
        abbreviation: "WY",
        value: "WY",
    },
];

export const use_type_options = [
    {
        value: "",
        label: "Select Type",
        disabled: true,
    },
    {
        value: "Educational",
        label: "Educational",
    },
    {
        value: "Religious",
        label: "Religious",
    },
    {
        value: "Healthcare",
        label: "Healthcare",
    },
    {
        value: "Office",
        label: "Office",
    },
    {
        value: "Industrial",
        label: "Industrial",
    },
    {
        value: "Retail",
        label: "Retail",
    },
    {
        value: "Land",
        label: "Land",
    },
    {
        value: "Multifamily",
        label: "Multifamily",
    },
    {
        value: "Hospitality",
        label: "Hospitality",
    },
    {
        value: "Mixed Use",
        label: "Mixed Use",
    },
    {
        value: "Other",
        label: "Other",
    },
];

export const yes_no_options = [
    {
        value: "",
        label: "Select Yes or No",
        disabled: true,
    },
    {
        value: "true",
        label: "Yes",
    },
    {
        value: "false",
        label: "No",
    },
];

export const dateOrdinalOptions = [
    {
        value: "",
        label: "Select",
        disabled: true,
    },
    {
        value: 1,
        label: "1st",
    },
    {
        value: 2,
        label: "2nd",
    },
    {
        value: 3,
        label: "3rd",
    },
    {
        value: 4,
        label: "4th",
    },
    {
        value: 5,
        label: "5th",
    },
    {
        value: 6,
        label: "6th",
    },
    {
        value: 7,
        label: "7th",
    },
    {
        value: 8,
        label: "8th",
    },
    {
        value: 9,
        label: "9th",
    },
    {
        value: 10,
        label: "10th",
    },
    {
        value: 11,
        label: "11th",
    },
    {
        value: 12,
        label: "12th",
    },
    {
        value: 13,
        label: "13th",
    },
    {
        value: 14,
        label: "14th",
    },
    {
        value: 15,
        label: "15th",
    },
    {
        value: 16,
        label: "16th",
    },
    {
        value: 17,
        label: "17th",
    },
    {
        value: 18,
        label: "18th",
    },
    {
        value: 19,
        label: "19th",
    },
    {
        value: 20,
        label: "20th",
    },
    {
        value: 21,
        label: "21st",
    },
    {
        value: 22,
        label: "22nd",
    },
    {
        value: 23,
        label: "23rd",
    },
    {
        value: 24,
        label: "24th",
    },
    {
        value: 25,
        label: "25th",
    },
    {
        value: 26,
        label: "26th",
    },
    {
        value: 27,
        label: "27th",
    },
    {
        value: 28,
        label: "28th",
    },
    {
        value: 29,
        label: "29th",
    },
    {
        value: 30,
        label: "30th",
    },
    {
        value: 31,
        label: "31st",
    },
];

export const quarters = [
    {
        value: "Select",
        label: "Select",
    },
    {
        value: "1Q",
        label: "1Q",
    },
    {
        value: "2Q",
        label: "2Q",
    },
    {
        value: "3Q",
        label: "3Q",
    },
    {
        value: "4Q",
        label: "4Q",
    },
];

export const buildingClasses = [
    {
        value: "All",
        label: "All",
    },
    {
        value: "Trophy",
        label: "Trophy",
    },
    {
        value: "A",
        label: "A",
    },
    {
        value: "B",
        label: "B",
    },
    {
        value: "C",
        label: "C",
    },
    {
        value: "D",
        label: "D",
    },
];

export const buildingStatuses = [
    {
        value: "All",
        label: "All",
    },
    {
        value: "Existing",
        label: "Existing",
    },
    {
        value: "Proposed",
        label: "Proposed",
    },
    {
        value: "Under Construction",
        label: "Under Construction",
    },
    {
        value: "Under Renovation",
        label: "Under Renovation",
    },
    {
        value: "Demolished",
        label: "Demolished",
    },
    {
        value: "Land",
        label: "Land",
    },
];

export const us_states_map = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
};
export const noPhotoUrl = "https://s3.us-east-2.amazonaws.com/connect.kidder.com/images/no_photo_150.svg";

export const emptyValues = new Set([null, undefined, ""]);

export const acceptedFileTypes = [".csv", ".doc", ".docx", ".jpg", ".msg", ".pdf", ".png"];
