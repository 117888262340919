import types from "./types";
import get from "lodash/get";
import setWith from "lodash/setWith";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";

const INITIAL_STATE = {
    propertyRequestModal: {
        open: false,
    },
    propertySearchModal: {
        open: false,
    },
    displayDeleteDialog: {
        open: false,
        onSubmit: null,
    },
    displayNoDeleteDialog: {
        open: false,
        onSubmit: null,
    },
    displayUpdateDialog: {
        open: false,
        onSubmit: null,
    },
    propertyTypeExpansionPanels: {},
    marketFilterExpansionPanels: {},
    propertySearchFilters: {},
    properties: {},
    property: {},
    propertyManagement: {},
    images: {},
    propertyTab: "all_properties",
    isLoading: true,
    numResults: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.TOGGLE_PROPERTY_LISTING_MODAL:
            return {
                ...state,
                propertyRequestModal: {
                    open: action.open,
                },
            };
        case types.UPDATE_PROPERTY_REQUEST:
            return {
                ...state,
                propertyRequestModal: {
                    ...state.propertyRequestModal,
                    [action.field]: action.value,
                },
            };
        case types.TOGGLE_PROPERTY_SEARCH_MODAL:
            return {
                ...state,
                propertySearchModal: {
                    ...state.propertySearchModal,
                    open: action.open,
                },
            };
        case types.ADD_REQUEST_PROPERTY_MODAL_ERROR:
            return {
                ...state,
                propertyRequestModal: {
                    ...state.propertyRequestModal,
                    errors: {
                        ...state.propertyRequestModal.errors,
                        [action.field]: action.error,
                    },
                },
            };
        case types.UPDATE_PROPERTY_SEARCH_FILTER:
            return setWith(clone(state), `propertySearchFilters.${action.field}`, action.value, clone);
        case types.CLEAR_PROPERTY_SEARCH_FILTERS:
            return {
                ...state,
                propertySearchFilters: {},
            };
        case types.TOGGLE_PROPERTY_TYPE_EXPANSION_PANEL:
            console.log("propertyReducer,processing " + types.TOGGLE_PROPERTY_TYPE_EXPANSION_PANEL);
            return {
                ...state,
                propertyTypeExpansionPanels: {
                    ...state.propertyTypeExpansionPanels,
                    [action.panelName]: action.open,
                },
            };
        case types.TOGGLE_MARKET_FILTER_EXPANSION_PANEL:
            return {
                ...state,
                marketFilterExpansionPanels: {
                    ...state.marketFilterExpansionPanels,
                    [action.panelName]: action.open,
                },
            };
        case types.LOAD_PROPERTY:
            return {
                ...state,
                property: action.property,
            };

        case types.PROPERTY_INPUT_CHANGE:
            return setWith(cloneDeep(state), `${action.field}`, action.value, Object);

        case types.ADD_ARRAY_ELEMENT:
            let arrayElements = cloneDeep(get(state, action.path, []));
            if (arrayElements == null) {
                arrayElements = [];
            }
            arrayElements.push({});
            return setWith(clone(state), `${action.path}`, arrayElements, clone);

        case types.DELETE_ARRAY_ELEMENT:
            let elements = cloneDeep(get(state, action.path, []));
            elements.splice(action.index, 1);
            const setObj = setWith(clone(state), `${action.path}`, elements, clone);
            return setObj;

        case types.RECEIVE_REVIEW_PROPERTIES:
            return Object.assign({}, state, {
                properties: {
                    ...state.properties,
                    [action.tab]: action.properties,
                },
            });
        case types.SWITCH_REVIEW_PROPERTIES_TAB:
            return Object.assign({}, state, {
                propertyTab: action.tab,
            });
        case types.SWITCH_PROPERTY_DETAIL_TAB:
            return Object.assign({}, state, {
                propertyDetailTab: action.tab,
            });
        case types.FILTER_PROPERTIES:
            return Object.assign({}, state, {
                filterQuery: action.filterQuery,
            });
        case types.SORT_REVIEW_PROPERTIES:
            return Object.assign({}, state, {
                columnToSort: action.columnToSort,
                sortDirection: action.sortDirection,
            });
        case types.ADD_PROPERTY_MANAGEMENT_SECTION:
            return {
                ...state,
                propManagementInfo: action.propManagementObject,
            };
        case types.COUNT_PROPERTY_RESULTS:
            return Object.assign({}, state, {
                numResults: {
                    ...state.numResults,
                    [action.tab]: action.numResults,
                },
            });
        case types.SET_PROPERTY_ARRAY:
            return setWith(clone(state), `${action.pathToArray}`, action.array, Object);
        case types.LOAD_PROPERTY_IMAGES:
            return {
                ...state,
                images: action.images,
            };
        case types.RESET:
            return INITIAL_STATE;

        case types.UPDATE_DELETE_DIALOG:
            return {
                ...state,
                displayDeleteDialog: {
                    ...state.displayDeleteDialog,
                    open: action.open,
                },
            };
        case types.UPDATE_NO_DELETE_DIALOG:
            return {
                ...state,
                displayNoDeleteDialog: {
                    ...state.displayNoDeleteDialog,
                    open: action.open,
                },
            };
        case types.UPDATE_PROPERTY_FOR_LISTING_DIALOG:
            return {
                ...state,
                displayUpdateDialog: {
                    ...state.displayUpdateDialog,
                    open: action.open,
                },
            };
        default:
            return state;
    }
};
