import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import ReactTooltip from "react-tooltip";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { IconButton, ButtonBase, Button, Grid, Checkbox, Collapse } from "@material-ui/core";
import { sortBy, get } from "lodash";
import { Doc } from "../../../common/js/icons";
import schema from "./AuditSearchTables";
import PermissionsGate from "./permissions/PermissionsGate";
import { SCOPES } from "./constants";
import { noPhotoUrl } from "../utils/constants";

const AuditListRow = (props) => {
    const { classes, comp, i, toggleAuditorListAuditFlag, viewDocument, role } = props;
    const {
        activeTab,
        brokerAgents,
        dealKey,
        batchDate,
        listingName,
        primaryAddress,
        propertyPhotoUrl,
        listingPhotoUrl,
        propertyType,
        transactionType,
        auditFlag,
    } = comp;

    const [expanded, setExpanded] = useState(false);
    const [sortedDocuments, setSortedDocuments] = useState([]);

    useEffect(() => {
        const { attachedRequiredDocuments, missingRequiredDocuments } = comp.attachedRequiredDocuments;
        loadDocuments(attachedRequiredDocuments, missingRequiredDocuments);
    }, []);

    const loadDocuments = (attached, missing) => {
        let aggregatedDocs = undefined;
        if (attached && missing) {
            aggregatedDocs = [...attached, ...missing];
        } else if (attached) {
            aggregatedDocs = attached;
        } else if (missing) {
            aggregatedDocs = missing;
        }
        setSortedDocuments(sortBy(aggregatedDocs), ["documentTypeName", "documentTypeKey"]);
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        // change background colour if dragging
        background: isDragging ? "#C5C5C5" : "",

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    return (
        <Draggable draggableId={dealKey} index={i}>
            {(provided, snapshot) => {
                return (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                        <Grid container key={`tr-${i}`} selectable="false" className={classes.gridRow}>
                            {schema.viewHeaders(activeTab)["all_comps"].map((header, k) => {
                                const { name, prop, transform } = header;
                                switch (name) {
                                    case "ToggleToList":
                                        return (
                                            <PermissionsGate hide scopes={[SCOPES.canAddToList]}>
                                                <Grid item xs={1} className={classes.toggleCheckbox}>
                                                    <span>
                                                        <Checkbox
                                                            checked={!auditFlag}
                                                            color="primary"
                                                            onChange={() => {
                                                                toggleAuditorListAuditFlag(dealKey);
                                                            }}
                                                        />
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setExpanded(!expanded)}
                                                        >
                                                            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                        </IconButton>
                                                    </span>
                                                </Grid>
                                            </PermissionsGate>
                                        );

                                    case "Property":
                                        return (
                                            <Grid
                                                item
                                                xs={1}
                                                key={`trc-${k}`}
                                                style={{
                                                    display: "flex",
                                                    position: "relative",
                                                    minWidth: "110px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    className="propertySmallImageContainer"
                                                    style={{
                                                        width: "85px",
                                                        height: "80px",
                                                        margin: "10px 23px 10px 27px",
                                                        backgroundImage: `url(${
                                                            listingPhotoUrl
                                                                ? listingPhotoUrl
                                                                : propertyPhotoUrl
                                                                ? propertyPhotoUrl
                                                                : noPhotoUrl
                                                        })`,
                                                    }}
                                                ></div>
                                            </Grid>
                                        );
                                    case "Address":
                                        return (
                                            <Grid
                                                item
                                                xs={2}
                                                classes={{ root: classes.body }}
                                                key={`trc-${k}`}
                                                style={{
                                                    position: "relative",
                                                    padding: `15px 10px 8px 24px`,
                                                }}
                                            >
                                                <Link
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    to={`/deal/${dealKey}`}
                                                    className={classes.lb}
                                                >
                                                    <span className={classes.listingIdLink}>{listingName}</span>
                                                </Link>
                                                <div>{`${get(primaryAddress, "address1", "")}`}</div>
                                                <div>{`${get(get(primaryAddress, "city", {}), "city", "")}, ${get(
                                                    get(get(primaryAddress, "city", {}), "state", {}),
                                                    "stateCode",
                                                    ""
                                                )} ${get(get(primaryAddress, "zipCode", {}), "zipCode", "")}`}</div>
                                                <div>{propertyType}</div>
                                            </Grid>
                                        );
                                    case "Deal Type":
                                        return (
                                            <Grid
                                                item
                                                xs={1}
                                                classes={{ root: classes.body }}
                                                key={`trc-${k}`}
                                                className={classes.gridPadding}
                                            >
                                                <span>{transform(transactionType)}</span>
                                            </Grid>
                                        );
                                    case "Brokers":
                                        const brokerAgentsDisplay = brokerAgents.join(", ");
                                        return (
                                            <Grid
                                                item
                                                xs={1}
                                                key={`trc-${k}`}
                                                className={`${classes.tooltip} ${classes.gridPadding}`}
                                            >
                                                <span>
                                                    <span data-tip={brokerAgents.length > 1 ? brokerAgentsDisplay : ""}>
                                                        {header.transform(brokerAgents)}
                                                    </span>
                                                    <ReactTooltip place="top" type="dark" effect="solid" />
                                                </span>
                                            </Grid>
                                        );
                                    case "Batch Date":
                                        return (
                                            <Grid item xs={1} className={classes.gridPadding}>
                                                <span>{batchDate ? batchDate.toLocaleDateString() : "Unbatched"}</span>
                                            </Grid>
                                        );
                                    case "Documents":
                                        return (
                                            <Grid
                                                item
                                                xs={5}
                                                style={{
                                                    minWidth: "300px",
                                                    paddingTop: "30px",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                }}
                                            >
                                                {sortedDocuments.map((document, document_index) => {
                                                    const isMissingDocument =
                                                        get(document, "mediaAssetKey", null) === null;

                                                    return role === "auditor" && isMissingDocument ? null : (
                                                        <span>
                                                            <span data-tip data-for={`tr-${i}-doc-${document_index}`}>
                                                                <Button
                                                                    disabled={
                                                                        get(document, "mediaAssetKey", null) === null
                                                                    }
                                                                    onClick={() => {
                                                                        viewDocument(
                                                                            null,
                                                                            get(document, "mediaAssetKey")
                                                                        );
                                                                    }}
                                                                    color="primary"
                                                                    className={classes.lightButton}
                                                                >
                                                                    <Doc
                                                                        fill={
                                                                            get(document, "mediaAssetKey", null) !==
                                                                            null
                                                                                ? "#2C99D2"
                                                                                : "#9E9E9E"
                                                                        }
                                                                    />
                                                                </Button>
                                                            </span>
                                                            <ReactTooltip
                                                                id={`tr-${i}-doc-${document_index}`}
                                                                place="top"
                                                                type="dark"
                                                                effect="solid"
                                                                className="statBoxTooltip"
                                                            >
                                                                {get(document, "documentTypeName", "")}
                                                            </ReactTooltip>
                                                        </span>
                                                    );
                                                })}
                                            </Grid>
                                        );
                                    default:
                                        return (
                                            <Grid item xs={1} key={`trc-${k}`} className={classes.gridPadding}>
                                                {prop}
                                            </Grid>
                                        );
                                }
                            })}
                        </Grid>
                        <Collapse in={expanded} timeout="auto">
                            <Grid style={{ borderTop: "1px solid rgb(212, 212, 212)" }}>
                                {sortedDocuments.map((document, document_index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontSize: "13px",
                                            padding: `10px 10px 0px 0px`,
                                            borderBottom: "1px solid rgb(212, 212, 212)",
                                        }}
                                    >
                                        <span data-tip data-for={`tr-${i}-expandedDoc-${document_index}`}>
                                            <Button
                                                disabled={get(document, "mediaAssetKey", null) === null}
                                                onClick={() => viewDocument(null, get(document, "mediaAssetKey"))}
                                                color="primary"
                                                className={classes.lightButton}
                                            >
                                                <Doc
                                                    fill={
                                                        get(document, "mediaAssetKey", null) !== null
                                                            ? "#2C99D2"
                                                            : "#9E9E9E"
                                                    }
                                                />
                                            </Button>
                                        </span>
                                        <ButtonBase
                                            disabled={get(document, "mediaAssetKey", null) === null}
                                            onClick={() => viewDocument(null, get(document, "mediaAssetKey"))}
                                            color="primary"
                                            className={classes.documentLink}
                                        >
                                            <span
                                                className={
                                                    get(document, "mediaAssetKey", null)
                                                        ? classes.documentLink
                                                        : classes.linkDisabled
                                                }
                                            >
                                                {get(document, "documentTypeName", "")}
                                            </span>
                                        </ButtonBase>
                                    </Grid>
                                ))}
                            </Grid>
                        </Collapse>
                    </div>
                );
            }}
        </Draggable>
    );
};
const styles = (theme) => ({
    gridRow: {
        alignItems: "center",
        fontSize: "13px",
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    toggleCheckbox: {
        paddingLeft: "25px",
    },
    gridPadding: {
        padding: `40px 10px 0px 0px`,
    },
});

export default withStyles(styles)(AuditListRow);
