import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import includes from "lodash/includes";

const styles = {
    bsSelect: {
        fontSize: "14px",
        padding: "9px 6px 9px 6px",
        boxSizing: "border-box",
        height: "37px",
        width: "100%",
        "&:focus": {
            backgroundColor: "transparent",
            borderBottom: "none",
        },
        "&::before": {
            borderBottom: "none !important",
        },
        "&::after": {
            borderBottom: "none !important",
        },
    },
};

class AdminDocumentTypeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: "Select",
        };
    }

    componentDidMount = () => {
        if (this.props.preexistingValue["documentType"]) {
            this.setState({
                selectedValue: this.props.preexistingValue["documentType"]["name"],
            });
        } else {
            this.setState({
                selectedValue: "Select",
            });
        }
    };

    updateSelectedValue = () => {
        if (this.props.preexistingValue["documentType"]) {
            if (this.props.preexistingValue["documentType"]["name"] !== this.state.selectedValue) {
                this.setState({
                    selectedValue: this.props.preexistingValue["documentType"]["name"],
                });
            }
        } else if (this.state.selectedValue !== "Select") {
            this.setState({
                selectedValue: "Select",
            });
        }
    };

    handleValueSelect = (e) => {
        this.props.documentTypesForParentEntity.forEach((documentTypeObject) => {
            if (e.target.value.toLowerCase() === documentTypeObject.name.toLowerCase()) {
                for (const key in this.props.documentTypesEnumList) {
                    if (
                        documentTypeObject.name.toLowerCase() ===
                        this.props.documentTypesEnumList[key]["display"].toLowerCase()
                    ) {
                        this.props.updateDocumentFromAdminList(
                            this.props.mediaAssetKey,
                            key,
                            this.props.parentEntityTypeKey,
                            documentTypeObject.documentTypeKey,
                            documentTypeObject
                        );
                        this.setState({
                            selectedValue: e.target.value,
                        });
                        return;
                    }
                }
                this.props.updateDocumentFromAdminList(
                    this.props.mediaAssetKey,
                    "OTHER",
                    this.props.parentTypeKey,
                    documentTypeObject.documentTypeKey,
                    documentTypeObject
                );
            }
        });
        this.setState({
            selectedValue: e.target.value,
        });
    };

    shouldAllowMoreDocumentsOfSameType = (documentType) => {
        const documentNames = Object.values(this.props.savedDocuments).map((mediaAsset) =>
            mediaAsset.documentType ? mediaAsset.documentType.name.toLowerCase() : null
        );
        return documentType.multipleAllowed || !documentNames.includes(documentType.name.toLowerCase());
    };

    isFormDisabled = () => {
        const mediaAsset = this.props.preexistingValue;
        const voucherAwaitingPayment = this.props.voucherStatus === "Awaiting Payment";
        const voucherReadyForAccounting = this.props.voucherStatus === "Ready For Accounting";

        if (
            voucherAwaitingPayment &&
            this.props.role === "broker" &&
            includes(["OTHER", "COMMISSION_PAYMENT"], mediaAsset["mediaAssetType"])
        ) {
            return this.props.options
                ? !includes(this.props.options.canView, mediaAsset["mediaAssetType"]) && mediaAsset["updated"] === true
                : this.props.formDisabled;
        }
        return (voucherAwaitingPayment || voucherReadyForAccounting) && this.props.role === "broker"
            ? true
            : this.props.options && this.props.formDisabled
            ? !(includes(this.props.options.canUpload, mediaAsset["mediaAssetType"]) && mediaAsset["updated"] !== true)
            : this.props.formDisabled;
    };

    renderMenuItem = (documentType, index) => {
        return this.shouldAllowMoreDocumentsOfSameType(documentType) ? (
            <MenuItem key={index} value={documentType.name}>
                {documentType.name}
            </MenuItem>
        ) : (
            <MenuItem key={index} value={documentType.name} style={{ display: "none" }}>
                {documentType.name}
            </MenuItem>
        );
    };

    render() {
        const { classes, requiredDocs, optionalDocs } = this.props;

        this.updateSelectedValue();

        return (
            <Select
                name="document-admin-dropdown"
                value={this.state.selectedValue}
                onChange={(e) => this.handleValueSelect(e)}
                classes={{ select: classes.bsSelect }}
                className={classes.bsSelect}
                style={this.props.style}
                disabled={this.isFormDisabled()}
            >
                <MenuItem value="Select" disabled>
                    Select
                </MenuItem>
                <MenuItem value="" disabled>
                    Required
                </MenuItem>
                {requiredDocs.map((documentReq, indexReq) => {
                    return this.renderMenuItem(documentReq, indexReq);
                })}

                <MenuItem value="" disabled>
                    Optional
                </MenuItem>
                {optionalDocs.map((documentOpt, indexOpt) => {
                    return this.renderMenuItem(documentOpt, indexOpt);
                })}
            </Select>
        );
    }
}

export default withStyles(styles)(AdminDocumentTypeList);
