import React from "react";
import InvoiceMonth from "./InvoiceMonth";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "../../../../node_modules/@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import utils from "../utils/index";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import get from "lodash/get";

class InvoicePeriod extends React.Component {
    handleChangeEvent = (event) => {
        this.props.handleTotalConsiderationChange(event.target.id, event.target.value);
    };

    handlePeriodChange = (event) => {
        this.props.handlePeriodChange(this.props.period.periodNumber, event.target.id, event.target.value);
    };

    render() {
        const { classes, disableInputs, errors, period } = this.props;

        return (
            <ExpansionPanel className="withTable">
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="panelMegaLink">
                        <Grid container justify="space-between" alignItems="center" spacing={8}>
                            <Grid item zeroMinWidth>
                                <span className="tableTitleMonth">{period.periodId}</span>
                            </Grid>
                            <Grid item xs>
                                <div className="tableTitleDate">
                                    {period.startDate} - {period.endDate}{" "}
                                    {period.preTermPeriod === true || period.postTermPeriod === true
                                        ? "(Outside Term)"
                                        : ""}
                                </div>
                                <span className="tableTitleDetails">
                                    {period.summary.totalMonths} month(s) / {period.summary.freeMonths} month(s) free /{" "}
                                    {period.summary.discountedMonths} month(s) discounted
                                </span>
                            </Grid>
                            {period.periodId !== "S" && period.periodId !== "1" && period.periodId !== "E" && (
                                <Grid
                                    container
                                    item
                                    xs
                                    style={{ fontSize: "14px", fontWeight: "400", maxHeight: "55px" }}
                                    spacing={0}
                                >
                                    <Grid item>
                                        <div
                                            className="tableTitleDetails"
                                            style={{ paddingRight: "10px", paddingTop: "15px", fontSize: "13px" }}
                                        >
                                            Escalation Rate
                                        </div>
                                    </Grid>
                                    <Grid item style={{ margin: "0px", minWidth: "80px", maxWidth: "80px" }}>
                                        <BSInput
                                            id="escalationRate"
                                            onClick={(event) => event.stopPropagation()}
                                            value={get(period, "escalationRate", null)}
                                            error={errors.escalationRate}
                                            onChange={this.handlePeriodChange}
                                            placeholder="Enter escalation rate"
                                            disabled={disableInputs === true}
                                            extraFormControlStyles={{ margin: "0px", paddingTop: "4px" }}
                                            extraInputStyles={{ marginTop: "0px" }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BSSelect
                                            id="escalationRateType"
                                            combinedField="shiftLeft"
                                            error={errors.escalationRateType}
                                            value={get(period, "escalationRateType", null)}
                                            onChange={this.handlePeriodChange}
                                            disabled={disableInputs === true}
                                            onClick={(event) => event.stopPropagation()}
                                            options={[
                                                {
                                                    value: "%",
                                                    label: "%",
                                                },
                                                {
                                                    value: "$",
                                                    label: "$",
                                                },
                                            ]}
                                            extraFormControlStyles={{ margin: "0px", paddingTop: "4px" }}
                                            extraInputStyles={{ marginTop: "0px", marginBottom: "0px" }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item align="right">
                                <span className="tableTitleValue">
                                    {utils.operations.toDollarAmount(period.summary.totalAmount)}
                                </span>
                            </Grid>
                        </Grid>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="withTable">
                    <Divider />
                    <Table className={classes.table} padding="none">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={4}>Month Range</TableCell>
                                <TableCell className={classes.centerDatum}>Free Rent</TableCell>
                                <TableCell numeric>Discount %</TableCell>
                                <TableCell numeric style={{ minWidth: "5em" }}>
                                    Rate
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(period["months"]).map((month, i) => (
                                <InvoiceMonth
                                    {...this.props}
                                    monthDetail={period["months"][month]}
                                    periodNumber={this.props.period.periodNumber}
                                    disableInputs={
                                        this.props.disableInputs ||
                                        this.props.period.preTermPeriod ||
                                        this.props.period.postTermPeriod
                                    }
                                />
                            ))}
                        </TableBody>
                    </Table>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default InvoicePeriod;
