import { request } from "../../utils/axios-wrapper";
import { push } from "connected-react-router";
import moment from "moment";

import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import compact from "lodash/compact";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";

import actions from "./actions";
import validation from "./validation";
import utils from "../../utils/index";
import constants from "../constants";

const getContactsForUser = (username) => {
    return (dispatch, getState) => {
        dispatch(utils.actions.somethingSaving(true, "crmContacts"));
        let user = username;
        if (!user) {
            user = get(getState(), "authReducer.userProfile.user");
        }
        request(
            {
                method: "get",
                url: constants.urls.get_contacts_for_user(user),
            },
            dispatch
        )
            .then((response) => {
                dispatch(utils.actions.somethingSaving(false, "crmContacts"));
                dispatch(actions.loadContactsForUser(response.data));
            })
            .catch((err) => {
                dispatch(utils.actions.somethingSaving(false, "crmContacts"));
                dispatch(utils.actions.snackbar(true, "error", "Couldn't retrieve contacts. Error:  " + err));
            });
    };
};

const getContact = (contactId) => {
    return (dispatch, _) => {
        request(
            {
                method: "get",
                url: constants.urls.get_contact(contactId),
            },
            dispatch
        )
            .then((response) => {
                dispatch(unpackContact(response.data));
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Couldn't retrieve contacts. Error:  " + err));
            });
    };
};

const unpackContact = (contact) => {
    return (dispatch, _) => {
        contact.reminders.forEach((reminder) => {
            let splitDate = moment(reminder.reminderTime).format("YYYY-MM-DD HH:mm").split(" ");
            reminder.date = splitDate[0];
            reminder.time = splitDate[1];
        });
        dispatch(actions.loadContact(contact));
    };
};

const addArrayElement = (pathToArray, obj = {}) => {
    return (dispatch, getState) => {
        let array = cloneDeep(get(getState().crmReducer, pathToArray, []));
        array.push(obj);
        dispatch(actions.setCrmArray(pathToArray, array));
    };
};

const deleteArrayElement = (pathToArray, index) => {
    return (dispatch, getState) => {
        let array = cloneDeep(get(getState().crmReducer, pathToArray, []));
        array.splice(index, 1);
        dispatch(actions.setCrmArray(pathToArray, array));
    };
};

const createContact = () => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        const contact = get(getState(), "crmReducer.contact", null);
        if (contact) {
            dispatch(utils.actions.somethingSaving(true, "submit"));
            const builtContact = buildContactRequest(contact, get(getState(), "authReducer.userProfile.user", null));
            const errors = validateContact(builtContact);
            console.log(errors);
            if (isEmpty(errors) || isNil(errors)) {
                request(
                    {
                        method: "post",
                        url: constants.urls.create_or_save_contact(),
                        data: builtContact,
                    },
                    dispatch
                )
                    .then((response) => {
                        dispatch(utils.actions.snackbar(true, "success", "Success"));
                        dispatch(utils.actions.somethingSaving(false, "submit"));
                        dispatch(push("/contacts"));
                    })
                    .catch((err) => {
                        dispatch(utils.actions.snackbar(true, "error", "Could not add contact. Error: " + err));
                        dispatch(utils.actions.somethingSaving(false, "submit"));
                    });
            } else {
                dispatch(utils.actions.addError("contact", errors));
                dispatch(utils.actions.snackbar(true, "error", "Please fix errors"));
                dispatch(utils.actions.somethingSaving(false, "submit"));
            }
        }
    };
};

const updateContact = () => {
    return (dispatch, getState) => {
        dispatch(utils.actions.clearErrors());
        const contact = get(getState(), "crmReducer.contact", null);
        if (contact) {
            dispatch(utils.actions.somethingSaving(true, "submit"));
            const builtContact = buildContactRequest(contact);
            const errors = validateContact(builtContact);
            console.log(errors);
            if (isEmpty(errors) || isNil(errors)) {
                request(
                    {
                        method: "put",
                        url: constants.urls.create_or_save_contact(),
                        data: builtContact,
                    },
                    dispatch
                )
                    .then((response) => {
                        dispatch(utils.actions.snackbar(true, "success", "All changes saved"));
                        dispatch(utils.actions.somethingSaving(false, "submit"));
                        dispatch(push("/contacts"));
                    })
                    .catch((err) => {
                        dispatch(
                            utils.actions.snackbar(true, "error", "Could not save contact information. Error: " + err)
                        );
                        dispatch(utils.actions.somethingSaving(false, "submit"));
                    });
            } else {
                dispatch(utils.actions.addError("contact", errors));
                dispatch(utils.actions.snackbar(true, "error", "Please fix errors"));
                dispatch(utils.actions.somethingSaving(false, "submit"));
            }
        }
    };
};

const deleteContact = (contactId, index) => {
    return (dispatch, _) => {
        request(
            {
                method: "delete",
                url: constants.urls.delete_contact(contactId),
            },
            dispatch
        )
            .then((response) => {
                dispatch(removeContactFromArray(contactId, index));
                dispatch(getContactsForUser());
            })
            .catch((err) => {
                dispatch(utils.actions.snackbar(true, "error", "Couldn't delete contact. Error: " + err));
            });
    };
};

const removeContactFromArray = (contactId, index) => {
    return (dispatch, getState) => {
        let currentContacts = cloneDeep(getState().crmReducer.contacts);
        const contactToDelete = currentContacts[index];
        if (contactToDelete.contactKey === contactId) {
            currentContacts.splice(index, 1);
            dispatch(actions.setCrmArray("contacts", currentContacts));
        }
    };
};

const cancelChanges = () => {
    return (dispatch, _) => {
        dispatch(push(`/contacts`));
    };
};

const filter = (query) => {
    return (dispatch, _) => {
        if (typeof query === "string") query = query.toLowerCase();
        dispatch(actions.filter(query));
    };
};

const sort = (columnName) => {
    const invertDirection = {
        asc: "desc",
        desc: "asc",
    };
    return (dispatch, getState) => {
        let sortDirection =
            getState().crmReducer.columnToSort === columnName
                ? invertDirection[getState().crmReducer.sortDirection]
                : "asc";
        dispatch(actions.sort(columnName, sortDirection));
    };
};

const clearContactsState = () => {
    return (dispatch, _) => {
        dispatch(actions.loadContactsForUser([]));
    };
};

const buildContactRequest = (contact, owner) => {
    let reminders = contact.reminders;
    if (reminders && reminders.length > 0) {
        reminders = contact.reminders.map((reminder) => {
            if (isNil(reminder.date) || isNil(reminder.time)) {
                return null;
            }
            return {
                ...reminder,
                reminderTime: reminder.date + "T" + reminder.time,
                date: null,
                time: null,
                activeFlg: contact.remindersActiveFlg,
            };
        });
    }
    contact = {
        ...contact,
        reminders: reminders ? compact(reminders) : [],
        phoneNumber: {
            ...contact.phoneNumber,
            phoneNumber: get(contact, "phoneNumber.phoneNumber", "").replace("-", "").replace("(", "").replace(")", ""),
        },
        privateFlg: true,
        owner: owner ? { username: owner } : contact.owner,
    };
    return contact;
};

const validateContact = (contact) => {
    return validation.validateContact(contact);
};

export default {
    getContactsForUser,
    getContact,
    addArrayElement,
    deleteArrayElement,
    createContact,
    updateContact,
    cancelChanges,
    deleteContact,
    filter,
    sort,
    clearContactsState,
};
