import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Switch, Radio, FormControlLabel, Divider } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import BSInputSmallNumeric from "../../../common/js/components/BSInputSmallNumeric";
import BSInput from "../../../common/js/components/BSInput";
import { DownChevron } from "../../../common/js/icons";
import get from "lodash/get";
import set from "lodash/set";
import each from "lodash/each";
import includes from "lodash/includes";
import Chip from "@material-ui/core/Chip";
import PropertyTypeFilter from "../property/PropertyTypeFilter";
import LocationFilters from "../property/LocationFilters";
import MarketFilters from "../property/MarketFilters";
import BSSelect from "../../../common/js/components/BSSelect";
import Today from "../../../../node_modules/@material-ui/icons/Today";
import Scroll from "react-scroll";
import searchConstants from "../search/constants";

const styles = {
    menuSearchButton: {
        marginBottom: "15px",
    },
    root: {
        "&:hover": {
            cursor: "pointer",
        },
        backgroundColor: "white",
    },
    propertyTypeContainer: {
        width: "32em",
    },
    marketFilterContainer: {
        width: "32em",
    },
    checkbox: {
        border: "1px dotted",
    },
    statusContainer: {
        minWidth: "210px",
        padding: "0 15px",
    },
    labelThing: {
        marginRight: "0",
    },
    fakeSelect: {
        height: "39px",
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
        marginLeft: 0,
        fontWeight: 400,
        width: "100%",
        justifyContent: "flex-start",
        padding: "0 26px 0 6px",
        backgroundColor: "white",
        fontSize: "14px",
    },
    fakeSelectDisabled: {
        height: "39px",
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
        marginLeft: 0,
        fontWeight: 400,
        width: "100%",
        justifyContent: "flex-start",
        padding: "0 26px 0 6px",
        backgroundColor: "rgba(0, 0, 0, 0.26)",
        fontSize: "14px",
    },
    focusVisible: {
        border: "1px solid #2C99D2",
        backgroundColor: "white",
    },
    fakeSelectIcon: {
        position: "absolute",
        right: "6px",
        fontSize: "16px",
    },
    searchAdornment: {
        color: "white",
        background: "#2C99D2",
        "&:hover": {
            cursor: "pointer",
        },
    },
    filterBar: {
        backgroundColor: "white",
        paddingTop: "15px",
        paddingRight: "20px",
        paddingLeft: "15px",
        borderBottom: "1px solid #eee",
    },
    statusSwitch: {
        marginRight: "-15px",
    },
    menuTitle: {
        fontSize: "14px",
    },
    hrDivider: {
        position: "absolute",
        width: "100%",
        left: 0,
        top: "25px",
    },
    bootstrapInput: {
        marginTop: "300px",
    },
    locationFilterContainer: {
        minWidth: "350px",
        padding: "20px",
    },
    listingTypeFilterContainer: {
        minWidth: "550px",
        padding: "20px",
    },
    menuSectionTitle: {
        color: "#9E9E9E",
        fontSize: "13px",
        fontWeight: 600,
        textTransform: "uppercase",
        marginBottom: "5px",
    },
    officeDropdown: {
        marginTop: 0,
        height: "30px",
    },
};

class ListingFilters extends React.Component {
    state = {
        anchorEl: null,
        open: {},
        placement: null,
    };

    componentDidMount = () => {
        this.initializeSizeCriteria();
    };

    initializeSizeCriteria = () => {
        if (
            !get(this.props.searchCriteria, "sizeCriteria.availableSf", false) &&
            !get(this.props.searchCriteria, "sizeCriteria.totalSf", false) &&
            !get(this.props.searchCriteria, "sizeCriteria.acreage", false)
        ) {
            this.props.handleSizeRadioClick("availableSf", true);
        }
    };

    handleClick = (placement) => (event, filter) => {
        const { currentTarget } = event;
        this.setState((state) => ({
            anchorEl: currentTarget,
            open: {
                [filter]: state.placement !== placement || !state.open[filter],
            },
            placement,
        }));
    };

    handleClose = () => {
        this.setState({ open: {} });
    };

    onKeyPress = (event) => {
        if (event.key === "Enter") {
            this.props.searchSubmit();
            this.handleClose();
        }
    };

    handleClearFilters = () => {
        this.props.clearFilters();
        this.props.handleSearchCleared(true);
        this.handleClose();
        this.props.handleSizeRadioClick("availableSf", true);
    };

    handleSearchSubmit = () => {
        this.props.handleSearchCleared(false);
        this.props.searchSubmit();
        this.handleClose();
    };

    countPropertyTypeSelected = (searchCriteria) => {
        let count = 0;
        each(get(searchCriteria, "propertyTypes", []), (p) => {
            if (p.selected === true) {
                count += 1;
            }
        });
        return count;
    };

    countFilter = (searchCriteria, filterName) => {
        let count = 0;
        each(get(searchCriteria, filterName, []), (option) => {
            if (option === true) {
                count += 1;
            }
        });
        return count;
    };

    countFiltersSelected = (searchCriteria, filterName) => {
        let count = 0;
        each(get(searchCriteria, filterName, []), (option) => {
            if (option.selected === true) {
                count += 1;
            }
        });
        return count;
    };

    checkForLeaseOnly = (checked, box) => {
        let currentState = {
            excludeSublease: get(this.props.searchCriteria, "listingTypeCriteria.excludeSubleases"),
            forSublease: get(this.props.searchCriteria, "listingTypeCriteria.forSublease"),
            investmentSaleFlg: get(this.props.searchCriteria, "listingTypeCriteria.investmentSaleFlg"),
            forSale: get(this.props.searchCriteria, "listingTypeCriteria.forSale"),
            forLease: get(this.props.searchCriteria, "listingTypeCriteria.forLease"),
        };
        set(currentState, box, checked);
        if (
            !get(currentState, "forSublease") &&
            !get(currentState, "investmentSaleFlg") &&
            !get(currentState, "forSale") &&
            get(currentState, "forLease") &&
            get(currentState, "excludeSubleases")
        ) {
            this.props.handleSelectAllPropertyType("Hospitality", false);
        }
        this.props.updateFilter(`listingTypeCriteria.${box}`, checked);
    };

    combineFilterCount = (searchCriteria, criteriaOne, criteriaTwo) => {
        return this.countFilter(searchCriteria, criteriaOne) + this.countFilter(searchCriteria, criteriaTwo);
    };

    render() {
        const {
            role,
            classes,
            updateFilter,
            handleSizeRadioClick,
            propertySearchFilters,
            handleSelectAllPropertyType,
            searchCriteria,
            togglePropertyTypeExpansionPanel,
            propertyTypeExpansionPanels,
            toggleMarketFilterExpansionPanel,
            marketFilterExpansionPanels,
            handleSelectMarket,
            handleSelectSubmarket,
            handleSelectMicromarket,
            loadedSearchCrumbs,
            handleDeleteCrumb,
        } = this.props;

        const { anchorEl, open, placement } = this.state;

        let Element = Scroll.Element;
        const canViewExpiredOnly = includes(
            [
                "dataAdmin",
                "dataEntry",
                "research",
                "officeManager",
                "researchAdmin",
                "portlandBrokerAdmin",
                "arizonaBrokerAdmin",
                "evp",
            ],
            role
        );

        return (
            <div className={classes.filterBar}>
                <Grid container justify="space-between" alignItems="center" spacing={8}>
                    <Grid item xs style={{ fontWeight: 400, maxWidth: "70px", marginLeft: "12px", marginRight: "5px" }}>
                        <div>Filters:</div>
                    </Grid>

                    <Grid item xs>
                        <Popper open={open["location"]} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.locationFilterContainer}>
                                            <LocationFilters
                                                updateFilter={updateFilter}
                                                searchCriteria={searchCriteria}
                                                onKeyPress={this.onKeyPress}
                                                hideFilters={true}
                                            ></LocationFilters>

                                            <Grid container spacing={16} justify="flex-end">
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSearchSubmit}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "location")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {"Location"}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>

                    <Grid item xs>
                        <Popper open={open["marketArea"]} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.marketFilterContainer}>
                                            <Element style={{ height: "400px", overflowY: "scroll" }}>
                                                <MarketFilters
                                                    searchCriteria={searchCriteria}
                                                    toggleMarketFilterExpansionPanel={toggleMarketFilterExpansionPanel}
                                                    marketFilterExpansionPanels={marketFilterExpansionPanels}
                                                    handleSelectMarket={handleSelectMarket}
                                                    handleSelectSubmarket={handleSelectSubmarket}
                                                    handleSelectMicromarket={handleSelectMicromarket}
                                                ></MarketFilters>
                                            </Element>
                                            <Grid
                                                container
                                                spacing={16}
                                                justify="flex-end"
                                                style={{ padding: "10px", paddingRight: "16px" }}
                                            >
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSearchSubmit}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "marketArea")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {`Market Area ${
                                this.countFiltersSelected(searchCriteria, "locationCriteria.markets") > 0
                                    ? `(${this.countFiltersSelected(searchCriteria, "locationCriteria.markets")})`
                                    : ""
                            }`}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>

                    <Grid item xs>
                        <Popper open={open["propertyType"]} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.propertyTypeContainer}>
                                            <PropertyTypeFilter
                                                updateFilter={updateFilter}
                                                propertySearchFilters={propertySearchFilters}
                                                handleSelectAllPropertyType={handleSelectAllPropertyType}
                                                searchCriteria={searchCriteria}
                                                handleSearchSubmit={this.handleSearchSubmit}
                                                togglePropertyTypeExpansionPanel={togglePropertyTypeExpansionPanel}
                                                propertyTypeExpansionPanels={propertyTypeExpansionPanels}
                                            />
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "propertyType")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {`Space Use ${
                                this.countPropertyTypeSelected(searchCriteria) > 0
                                    ? `(${this.countPropertyTypeSelected(searchCriteria)})`
                                    : ""
                            }`}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>

                    <Grid item xs>
                        <Popper
                            open={get(open, "dealType", false)}
                            anchorEl={anchorEl}
                            placement={placement}
                            transition
                        >
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.listingTypeFilterContainer}>
                                            <Element
                                                style={{
                                                    height: "400px",
                                                    paddingLeft: "5px",
                                                    paddingRight: "15px",
                                                    overflowX: "hidden",
                                                    overflowY: "scroll",
                                                }}
                                            >
                                                <Grid container justify="space-between" alignItems="center">
                                                    <Grid item>
                                                        <span className={classes.menuTitle}>KM Listings Only</span>
                                                    </Grid>
                                                    <Grid item alignItems="center">
                                                        <Switch
                                                            checked={get(
                                                                searchCriteria,
                                                                "listingTypeCriteria.kmListingsOnly",
                                                                false
                                                            )}
                                                            onChange={(e) =>
                                                                updateFilter(
                                                                    "listingTypeCriteria.kmListingsOnly",
                                                                    e.target.checked
                                                                )
                                                            }
                                                            value="kmListingsOnly"
                                                            className={classes.statusSwitch}
                                                            color="primary"
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Divider style={{ margin: "4px -20px 10px -20px" }} />

                                                <Grid container justify="space-between" spacing={8}>
                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={get(
                                                                        searchCriteria,
                                                                        "listingTypeCriteria.forLease",
                                                                        false
                                                                    )}
                                                                    onChange={(e) =>
                                                                        this.checkForLeaseOnly(
                                                                            e.target.checked,
                                                                            "forLease"
                                                                        )
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Lease"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={get(
                                                                        searchCriteria,
                                                                        "listingTypeCriteria.forSale",
                                                                        false
                                                                    )}
                                                                    onChange={(e) =>
                                                                        this.checkForLeaseOnly(
                                                                            e.target.checked,
                                                                            "forSale"
                                                                        )
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Sale"
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Divider style={{ margin: "4px -20px 10px -20px" }} />

                                                <Grid container justify="space-between" spacing={8}>
                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={get(
                                                                        searchCriteria,
                                                                        "listingTypeCriteria.excludeSubleases",
                                                                        false
                                                                    )}
                                                                    checked={get(
                                                                        searchCriteria,
                                                                        "listingTypeCriteria.forSublease",
                                                                        false
                                                                    )}
                                                                    onChange={(e) =>
                                                                        this.checkForLeaseOnly(
                                                                            e.target.checked,
                                                                            "forSublease"
                                                                        )
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Sublease Only"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={get(
                                                                        searchCriteria,
                                                                        "listingTypeCriteria.forSublease",
                                                                        false
                                                                    )}
                                                                    checked={get(
                                                                        searchCriteria,
                                                                        "listingTypeCriteria.excludeSubleases",
                                                                        false
                                                                    )}
                                                                    onChange={(e) =>
                                                                        this.checkForLeaseOnly(
                                                                            e.target.checked,
                                                                            "excludeSubleases"
                                                                        )
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Exclude Subleases"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={get(
                                                                        searchCriteria,
                                                                        "listingTypeCriteria.investmentSaleFlg",
                                                                        false
                                                                    )}
                                                                    onChange={(e) =>
                                                                        this.checkForLeaseOnly(
                                                                            e.target.checked,
                                                                            "investmentSaleFlg"
                                                                        )
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Investment Only"
                                                        />
                                                    </Grid>

                                                    {canViewExpiredOnly && (
                                                        <Grid item xs={6}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={get(
                                                                            searchCriteria,
                                                                            "listingTypeCriteria.expiredOnly",
                                                                            false
                                                                        )}
                                                                        onChange={(e) =>
                                                                            updateFilter(
                                                                                "listingTypeCriteria.expiredOnly",
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Expired Only"
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>

                                                <Divider style={{ margin: "10px -20px 18px -20px" }} />

                                                <Grid
                                                    container
                                                    justify="space-between"
                                                    spacing={8}
                                                    style={{ height: "94px" }}
                                                >
                                                    <Grid item xs>
                                                        <BSInput
                                                            id="minPrice"
                                                            type="number"
                                                            label="Sale Price"
                                                            value={get(searchCriteria, "priceCriteria.minPrice", "")}
                                                            onChange={(e) =>
                                                                updateFilter("priceCriteria.minPrice", e.target.value)
                                                            }
                                                            placeholder="Min Sale price"
                                                            disabled={false}
                                                        />
                                                    </Grid>
                                                    <Grid item align="center">
                                                        <div
                                                            className="betweenInputs"
                                                            style={{ paddingTop: "30px", color: "grey" }}
                                                        >
                                                            &mdash;
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <BSInput
                                                            id="maxPrice"
                                                            type="number"
                                                            value={get(searchCriteria, "priceCriteria.maxPrice", "")}
                                                            onChange={(e) =>
                                                                updateFilter("priceCriteria.maxPrice", e.target.value)
                                                            }
                                                            placeholder="Max Sale price"
                                                            disabled={false}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    container
                                                    justify="space-between"
                                                    spacing={4}
                                                    style={{ height: "90px" }}
                                                >
                                                    <Grid item xs={4}>
                                                        <BSInputSmallNumeric
                                                            id="minLeaseRate"
                                                            label="Lease Rate"
                                                            placeholder="Min Lease Rate"
                                                            value={get(
                                                                searchCriteria,
                                                                "priceCriteria.minLeaseRate",
                                                                ""
                                                            )}
                                                            onChange={(e) =>
                                                                updateFilter(
                                                                    "priceCriteria.minLeaseRate",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item align="center">
                                                        <div
                                                            className="betweenInputs"
                                                            style={{ paddingTop: "30px", color: "grey" }}
                                                        >
                                                            &mdash;
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <BSInput
                                                            id="maxLeaseRate"
                                                            type="number"
                                                            placeholder="Max Lease Rate"
                                                            value={get(
                                                                searchCriteria,
                                                                "priceCriteria.maxLeaseRate",
                                                                ""
                                                            )}
                                                            onChange={(e) =>
                                                                updateFilter(
                                                                    "priceCriteria.maxLeaseRate",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <BSSelect
                                                            id="leaseRateType"
                                                            value={get(
                                                                searchCriteria,
                                                                "priceCriteria.leaseRateType",
                                                                ""
                                                            )}
                                                            onChange={(e) =>
                                                                updateFilter(
                                                                    "priceCriteria.leaseRateType",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder="Select"
                                                            options={[
                                                                {
                                                                    value: "$/SF/Month",
                                                                    label: "$/SF/Month",
                                                                },
                                                                {
                                                                    value: "$/SF/Year",
                                                                    label: "$/SF/Year",
                                                                },
                                                            ]}
                                                            disabled={false}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container justify="space-between" spacing={8}>
                                                    <Grid item xs>
                                                        <BSInput
                                                            label="Available On Or Before"
                                                            type={"date"}
                                                            startAdornment={<Today />}
                                                            placeholder="Available Before Date"
                                                            value={get(
                                                                searchCriteria,
                                                                "listingTypeCriteria.availableBefore",
                                                                ""
                                                            )}
                                                            onChange={(e) =>
                                                                updateFilter(
                                                                    "listingTypeCriteria.availableBefore",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container justify="space-between" spacing={16}>
                                                    <Grid item xs>
                                                        <BSInput
                                                            label="Date Listed"
                                                            placeholder="Date Listed (From)"
                                                            value={get(
                                                                searchCriteria,
                                                                "listingTypeCriteria.dateListedFrom",
                                                                ""
                                                            )}
                                                            type={"date"}
                                                            startAdornment={<Today />}
                                                            onChange={(e) =>
                                                                updateFilter(
                                                                    "listingTypeCriteria.dateListedFrom",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item align="center">
                                                        <div
                                                            className="betweenInputs"
                                                            style={{ paddingTop: "30px", color: "grey" }}
                                                        >
                                                            &mdash;
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <BSInput
                                                            placeholder="Date Listed (To)"
                                                            value={get(
                                                                searchCriteria,
                                                                "listingTypeCriteria.dateListedTo",
                                                                ""
                                                            )}
                                                            type={"date"}
                                                            startAdornment={<Today />}
                                                            onChange={(e) =>
                                                                updateFilter(
                                                                    "listingTypeCriteria.dateListedTo",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container justify="flex-end">
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.handleSearchSubmit}
                                                            className={classes.menuSearchButton}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Element>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "dealType")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {`Type & Price ${
                                this.countFilter(searchCriteria, "listingTypeCriteria") > 0
                                    ? `(${this.countFilter(searchCriteria, "listingTypeCriteria")})`
                                    : ""
                            }`}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>

                    <Grid item xs>
                        <Popper open={get(open, "status", false)} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.statusContainer}>
                                            <Grid container justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <span className={classes.menuTitle}>KM Managed Only</span>
                                                    <hr className={classes.hrDivider} />
                                                </Grid>
                                                <Grid item>
                                                    <Switch
                                                        checked={get(
                                                            searchCriteria,
                                                            "statusCriteria.kmManagedOnly",
                                                            false
                                                        )}
                                                        onChange={(e) =>
                                                            updateFilter(
                                                                "statusCriteria.kmManagedOnly",
                                                                e.target.checked
                                                            )
                                                        }
                                                        value="kmManagedOnly"
                                                        className={classes.statusSwitch}
                                                        color="primary"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <h4 className={classes.menuSectionTitle}>Status </h4>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="existing"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.existing",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.existing",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Existing"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="proposed"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.proposed",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.proposed",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Proposed"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="underConstruction"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.underConstruction",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.underConstruction",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Under Construction"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="underRenovation"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.underRenovation",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.underRenovation",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Under Renovation"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="demolished"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.demolished",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.demolished",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Demolished"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="land"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "statusCriteria.land",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    updateFilter(
                                                                        "statusCriteria.land",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Land"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row">
                                                <Grid item xs={12}>
                                                    <h4 className={classes.menuSectionTitle}>Tenancy </h4>
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "tenancyCriteria.single",
                                                                    false
                                                                )}
                                                                onChange={(e) =>
                                                                    updateFilter(
                                                                        "tenancyCriteria.single",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Single"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "tenancyCriteria.multi",
                                                                    false
                                                                )}
                                                                onChange={(e) =>
                                                                    updateFilter(
                                                                        "tenancyCriteria.multi",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Multi"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container justify="flex-end">
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSearchSubmit}
                                                        className={classes.menuSearchButton}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "status")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {`Status ${
                                this.combineFilterCount(searchCriteria, "statusCriteria", "tenancyCriteria") > 0
                                    ? `(${this.combineFilterCount(
                                          searchCriteria,
                                          "statusCriteria",
                                          "tenancyCriteria"
                                      )})`
                                    : ""
                            }`}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>

                    <Grid item xs>
                        <Popper open={open["size"]} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.statusContainer}>
                                            <Grid container justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <FormControlLabel
                                                        value="availableSf"
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "sizeCriteria.availableSf",
                                                                    true
                                                                )}
                                                                onClick={(e) =>
                                                                    handleSizeRadioClick(
                                                                        "availableSf",
                                                                        !get(
                                                                            searchCriteria,
                                                                            "sizeCriteria.availableSf",
                                                                            false
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Lease/Sale SF"
                                                        className={classes.labelThing}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        value="totalSf"
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "sizeCriteria.totalSf",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    handleSizeRadioClick(
                                                                        "totalSf",
                                                                        !get(
                                                                            searchCriteria,
                                                                            "sizeCriteria.totalSf",
                                                                            false
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Total SF"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        value="acreage"
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                checked={get(
                                                                    searchCriteria,
                                                                    "sizeCriteria.acreage",
                                                                    false
                                                                )}
                                                                onClick={(e) =>
                                                                    handleSizeRadioClick(
                                                                        "acreage",
                                                                        !get(
                                                                            searchCriteria,
                                                                            "sizeCriteria.acreage",
                                                                            false
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Total Acreage"
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container justify="space-between" spacing={16}>
                                                <Grid item xs>
                                                    <BSInputSmallNumeric
                                                        placeholder="Min SF"
                                                        value={get(searchCriteria, "sizeCriteria.min", "")}
                                                        onChange={(e) =>
                                                            updateFilter("sizeCriteria.min", e.target.value)
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item align="center">
                                                    <div className="betweenInputs">&mdash;</div>
                                                </Grid>
                                                <Grid item xs>
                                                    <BSInputSmallNumeric
                                                        placeholder="Max SF"
                                                        value={get(searchCriteria, "sizeCriteria.max", "")}
                                                        onChange={(e) =>
                                                            updateFilter("sizeCriteria.max", e.target.value)
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={16} justify="flex-end">
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSearchSubmit}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "size")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            Size
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>

                    <Grid item xs>
                        <Popper open={open["kmOffice"]} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={100}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Paper className={classes.statusContainer}>
                                            <Element style={{ height: "400px", overflowY: "scroll" }}>
                                                <Grid container direction="column">
                                                    {/*for each office*/}
                                                    {Object.keys(searchConstants.activeKmOffices).map((key, i) => {
                                                        const option = searchConstants.activeKmOffices[key];
                                                        return (
                                                            <Grid item className={classes.officeDropdown}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={get(
                                                                                searchCriteria,
                                                                                `officeCriteria.${key}`,
                                                                                false
                                                                            )}
                                                                            onChange={(e) =>
                                                                                updateFilter(
                                                                                    "officeCriteria." + key,
                                                                                    e.target.checked
                                                                                )
                                                                            }
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label={option.display}
                                                                />
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Element>
                                            <Grid container spacing={16} justify="flex-end">
                                                <Grid item style={{ paddingTop: "30px" }}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSearchSubmit}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ClickAwayListener>
                                </Fade>
                            )}
                        </Popper>
                        <ButtonBase
                            onClick={(e) => this.handleClick("bottom-start")(e, "kmOffice")}
                            className={classes.fakeSelect}
                            focusVisibleClassName={classes.focusVisible}
                        >
                            {`KM Office ${
                                this.countFilter(searchCriteria, "officeCriteria") > 0
                                    ? `(${this.countFilter(searchCriteria, "officeCriteria")})`
                                    : ""
                            }`}
                            <span className={classes.fakeSelectIcon}>
                                <DownChevron />
                            </span>
                        </ButtonBase>
                    </Grid>

                    <Grid item>
                        <Button variant="outlined" onClick={this.handleClearFilters}>
                            Clear
                        </Button>
                    </Grid>
                </Grid>

                <Grid container justify="space-between" alignItems="center" spacing={8} style={{ marginTop: "10px" }}>
                    <Grid item>
                        {loadedSearchCrumbs
                            ? Object.keys(loadedSearchCrumbs).map((criteria) => {
                                  if (loadedSearchCrumbs[criteria].value === true) {
                                      if (Object.keys(loadedSearchCrumbs[criteria]).length === 2) {
                                          return (
                                              <Chip
                                                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                                                  color="primary"
                                                  label={criteria}
                                                  onDelete={() =>
                                                      handleDeleteCrumb(loadedSearchCrumbs[criteria], false)
                                                  }
                                              />
                                          );
                                      } else if (
                                          (loadedSearchCrumbs[criteria].valueMin !== "* " &&
                                              loadedSearchCrumbs[criteria].valueMin !== undefined) ||
                                          (loadedSearchCrumbs[criteria].valueMax !== " *" &&
                                              loadedSearchCrumbs[criteria].valueMax !== undefined)
                                      ) {
                                          return (
                                              <Chip
                                                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                                                  color="primary"
                                                  label={
                                                      criteria +
                                                      get(loadedSearchCrumbs[criteria], "unit", "") +
                                                      ": " +
                                                      get(loadedSearchCrumbs[criteria], "valueMin", "* ") +
                                                      "-" +
                                                      get(loadedSearchCrumbs[criteria], "valueMax", " *")
                                                  }
                                                  onDelete={() => handleDeleteCrumb(loadedSearchCrumbs[criteria], null)}
                                              />
                                          );
                                      } else {
                                          return null;
                                      }
                                  } else if (
                                      typeof loadedSearchCrumbs[criteria].value == "string" &&
                                      loadedSearchCrumbs[criteria].value != null
                                  ) {
                                      return (
                                          <Chip
                                              style={{ marginLeft: "10px", marginBottom: "10px" }}
                                              color="primary"
                                              label={criteria + ": " + loadedSearchCrumbs[criteria].value}
                                              onDelete={() => handleDeleteCrumb(loadedSearchCrumbs[criteria], null)}
                                          />
                                      );
                                  } else {
                                      return null;
                                  }
                              })
                            : ""}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(ListingFilters);
