import React, { Component } from "react";

class StyleGuideApp extends Component {
    render() {
        return (
            <div>
                <div className="appContainer">zee guide</div>
            </div>
        );
    }
}

export default StyleGuideApp;
