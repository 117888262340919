const GET_VOUCHER = "GET_VOUCHER";
const RECEIVE_VOUCHERS = "RECEIVE_VOUCHERS";
const SWITCH_VOUCHER_VIEW = "SWITCH_VOUCHER_VIEW";
const SORT_VOUCHERS = "SORT_VOUCHERS";
const FILTER_VOUCHERS = "FILTER_VOUCHERS";
const SELECT_ALL = "SELECT_ALL";
const CHECKBOX_CLICK = "CHECKBOX_CLICK";
const AUTOSAVE_NOT_SUCCESS = "AUTOSAVE_NOT_SUCCESS";
const AUTOSAVE_SUCCESS = "AUTOSAVE_SUCCESS";
const RECEIVE_VOUCHER_DOCUMENTS = "RECEIVE_VOUCHER_DOCUMENTS";
const SET_VOUCHER_ID = "SET_VOUCHER_ID";
const REMOVE_DOC_FROM_LIST = "REMOVE_DOC_FROM_LIST";
const ADD_BROKER_COMMISSION = "ADD_BROKER_COMMISSION";
const SAVE_OUTSIDE_SPLIT = "SAVE_OUTSIDE_SPLIT";
const ADD_RUNNER = "ADD_RUNNER";
const UPDATE_SPLIT = "UPDATE_SPLIT";
const UPDATE_RUNNER = "UPDATE_RUNNER";
const REMOVE_COMMISSION = "REMOVE_COMMISSION";
const REMOVE_RUNNER = "REMOVE_RUNNER";
const TOGGLE_OUTSIDE_SPLIT_MODAL = "TOGGLE_OUTSIDE_SPLIT_MODAL";
const DELETE_OUTSIDE_SPLIT = "DELETE_OUTSIDE_SPLIT";
const VOUCHER_CHANGE = "VOUCHER_CHANGE";
const ADD_VOUCHER_ERROR = "ADD_VOUCHER_ERROR";
const CLEAR_VOUCHER_ERRORS = "CLEAR_VOUCHER_ERRORS";
const CLEAR_VOUCHER_MODAL_ERRORS = "CLEAR_VOUCHER_MODAL_ERRORS";
const VALID_VOUCHER = "VALID_VOUCHER";
const DETERMINE_OUTSIDE_SPLIT_PRESENT = "DETERMINE_OUTSIDE_SPLIT_PRESENT";
const SEARCH_RESULTS = "SEARCH_RESULTS";
const VALID_DOCUMENTS = "VALID_DOCUMENTS";
const VOUCHER_SNACKBAR = "VOUCHER_SNACKBAR";
const VOUCHER_INPUT = "VOUCHER_INPUT";
const SET_VOUCHER_ARRAY = "SET_VOUCHER_ARRAY";
const VOUCHER_DOCUMENT_WARNING = "VOUCHER_DOCUMENT_WARNING";
const OPEN_MODAL = "OPEN_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";
const UPDATE_DELETE_DIALOG = "UPDATE_DELETE_DIALOG";
const UPDATE_NO_DELETE_DIALOG = "UPDATE_NO_DELETE_DIALOG";
const UPDATE_NO_UPDATE_DIALOG = "UPDATE_NO_UPDATE_DIALOG";
const UPDATE_PROPERTY = "UPDATE_PROPERTY";
const CLEAR_UPDATE_PROPERTY = "CLEAR_UPDATE_PROPERTY";

export default {
    GET_VOUCHER,
    RECEIVE_VOUCHERS,
    SWITCH_VOUCHER_VIEW,
    SORT_VOUCHERS,
    FILTER_VOUCHERS,
    SELECT_ALL,
    CHECKBOX_CLICK,
    AUTOSAVE_NOT_SUCCESS,
    AUTOSAVE_SUCCESS,
    RECEIVE_VOUCHER_DOCUMENTS,
    SET_VOUCHER_ID,
    REMOVE_DOC_FROM_LIST,
    ADD_BROKER_COMMISSION,
    SAVE_OUTSIDE_SPLIT,
    DELETE_OUTSIDE_SPLIT,
    ADD_RUNNER,
    UPDATE_SPLIT,
    UPDATE_RUNNER,
    REMOVE_COMMISSION,
    REMOVE_RUNNER,
    TOGGLE_OUTSIDE_SPLIT_MODAL,
    VOUCHER_CHANGE,
    ADD_VOUCHER_ERROR,
    CLEAR_VOUCHER_ERRORS,
    CLEAR_VOUCHER_MODAL_ERRORS,
    VALID_VOUCHER,
    DETERMINE_OUTSIDE_SPLIT_PRESENT,
    SEARCH_RESULTS,
    VALID_DOCUMENTS,
    VOUCHER_SNACKBAR,
    VOUCHER_INPUT,
    SET_VOUCHER_ARRAY,
    VOUCHER_DOCUMENT_WARNING,
    OPEN_MODAL,
    CLOSE_MODAL,
    UPDATE_DELETE_DIALOG,
    UPDATE_NO_DELETE_DIALOG,
    UPDATE_NO_UPDATE_DIALOG,
    UPDATE_PROPERTY,
    CLEAR_UPDATE_PROPERTY,
};
