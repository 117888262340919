import React, { Component } from "react";
import { connect } from "react-redux";
import EditTransaction from "./EditTransaction";
import EditTransactionToolbar from "./EditTransactionToolbar";
import operations from "./ducks/operations";
import actions from "./ducks/actions";
import includes from "lodash/includes";
import get from "lodash/get";
import searchOperations from "../search/ducks/operations";
import ReactGA from "react-ga4";

class EditTransactionContainer extends Component {
    componentDidMount() {
        if (!(get(this.props, "location.state.newTransaction") === true)) {
            const { id } = this.props.match.params;
            if (id) {
                this.props.getTransaction(id);
            }
            ReactGA.send({ hitType: "pageview", page: "/transactions/*", title: `Edit Transaction: ${id}` });
        } else {
            ReactGA.send({ hitType: "pageview", page: "/transactions/create", title: "Create New Transaction" });
        }
    }

    componentWillUnmount() {
        this.props.flushTransactionState();
    }

    render() {
        if (includes(["appraiser", "broker", "debtEquity", "propertyManagement"], this.props.auth.userProfile.role)) {
            if (
                get(this.props, "auth.userProfile.user") === get(this.props, "transaction.createdBy.username") ||
                get(this.props, "location.state.newTransaction")
            ) {
                return (
                    <React.Fragment>
                        <EditTransactionToolbar
                            {...this.props}
                            newTransaction={get(this.props, "location.state.newTransaction")}
                        />
                        <EditTransaction
                            {...this.props}
                            newTransaction={get(this.props, "location.state.newTransaction")}
                        />
                    </React.Fragment>
                );
            }
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        errors: state.utilsReducer.errors.transaction,
        autocompleteResults: state.searchReducer.autocompleteResults,
        elementsSaving: state.utilsReducer.elementsSaving,
        transaction: state.debtEquityReducer.transaction,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTransaction: (transactionId) => {
            dispatch(operations.getTransaction(transactionId));
        },
        handleInput: (field, value) => {
            dispatch(actions.handleInput(field, value));
        },
        addArrayElement: (path, obj) => {
            dispatch(operations.addArrayElement(path, obj));
        },
        deleteArrayElement: (path, index) => {
            dispatch(operations.deleteArrayElement(path, index));
        },
        updateArrayElement: (path, index, field, value) => {
            dispatch(actions.handleInput(path + "[" + index + "]." + field, value));
        },
        saveTransaction: (options) => {
            dispatch(operations.saveTransaction(options));
        },
        cancelChanges: () => {
            dispatch(operations.cancelChanges());
        },
        flushTransactionState: () => {
            dispatch(actions.handleInput("transaction", {}));
        },
        searchProperty: (term) => {
            dispatch(searchOperations.searchPropertyAutocomplete(term));
        },
        searchCompany: (searchTerm) => {
            dispatch(searchOperations.searchCompany(searchTerm));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTransactionContainer);
