import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { Download } from "../../../common/js/icons";
import { docTypeIcons } from "./documentTypes";
import get from "lodash/get";

const styles = {
    attachmentCell: {
        fontSize: 14,
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    download: {
        cursor: "pointer",
        float: "right",
    },
    missingDocs: {
        color: "red",
    },
};

const DocumentListViewOnly = (props) => {
    const {
        open,
        docMenuKey,
        classes,
        anchorEl,
        savedDocuments,
        missingRequiredDocuments,
        handleMenuClose,
        handlePreviewDocument,
        handleDownloadDocument,
        handleOpenDocumentMenu,
        includeSource,
        downloadView,
        role,
    } = props;

    const columnDefs =
        includeSource === true
            ? [
                  { display: "Name", prop: "mediaAssetName" },
                  { display: "Document Source", prop: "ownerType" },
                  { display: "Document Type", prop: "mediaAssetType" },
                  { display: "Required" },
                  { display: "" },
              ]
            : [
                  { display: "Name", prop: "mediaAssetName" },
                  { display: "Document Type", prop: "mediaAssetType" },
                  { display: "" },
              ];
    return (
        <Table>
            <TableHead>
                <TableRow>
                    {columnDefs.map((x, i) => (
                        <TableCell key={`doc-head-${i}`} classes={classes}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <span className={classes.sortable}>{x.display}</span>
                            </div>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {role !== "auditor" &&
                    missingRequiredDocuments.map((doc, i) => {
                        const { name, type } = doc;
                        return (
                            <TableRow key={`tr-missing-${i}`}>
                                <TableCell className={[classes.attachmentCell, classes.missingDocs]}>
                                    <span>Missing Required Document - {name}</span>
                                </TableCell>
                                {includeSource === true && (
                                    <TableCell className={[classes.attachmentCell, classes.missingDocs]}>
                                        <span>{type}</span>
                                    </TableCell>
                                )}
                                <TableCell className={classes.missingDocs}>
                                    <div>{name}</div>
                                </TableCell>
                                <TableCell className={classes.missingDocs}>Yes</TableCell>
                                <TableCell />
                            </TableRow>
                        );
                    })}
                {Object.keys(savedDocuments).map((x, i) => {
                    const document = savedDocuments[x];
                    const excludeDocTypeIds = [49, 50];
                    const docType = get(document, "documentType", "");
                    const docTypeId = get(docType, "documentTypeKey", "");
                    const docTypeName = get(docType, "name", "");
                    if (role === "auditor" && excludeDocTypeIds.includes(docTypeId)) {
                        return null;
                    }
                    return (
                        <TableRow key={`tr-doc-${i}`}>
                            <TableCell className={classes.attachmentCell}>
                                {get(
                                    docTypeIcons,
                                    get(document, "mediaAssetName", " ").split(".").pop(),
                                    docTypeIcons["default"]
                                )()}
                                <span> {document["mediaAssetName"]}</span>
                            </TableCell>
                            {includeSource === true && (
                                <TableCell className={classes.attachmentCell}>
                                    <span> {document["ownerType"]}</span>
                                </TableCell>
                            )}
                            <TableCell>
                                <div>{docTypeName}</div>
                            </TableCell>
                            <TableCell>{get(document, "required", "") ? "Yes" : "No"}</TableCell>
                            {downloadView === true ? (
                                <TableCell style={{ paddingRight: "0px" }}>
                                    <div className={classes.download} onClick={() => handleDownloadDocument(document)}>
                                        <Download />
                                    </div>
                                </TableCell>
                            ) : (
                                <TableCell>
                                    <div>
                                        <IconButton
                                            disabled={false}
                                            aria-label="More"
                                            aria-owns={Boolean(document["anchorEl"]) ? `long-menu-${x}-${i}` : null}
                                            aria-haspopup="true"
                                            onClick={(e) => handleOpenDocumentMenu(x, e.currentTarget)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleMenuClose}
                                            PaperProps={{
                                                style: {
                                                    width: 200,
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                onClick={() =>
                                                    handlePreviewDocument(get(document, "url", null), docMenuKey)
                                                }
                                            >
                                                Open in a new tab
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </TableCell>
                            )}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default withStyles(styles)(DocumentListViewOnly);
