import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core/";
import BSInput from "../../../../common/js/components/BSInput";
import "./ParkingInfo.css";

const ParkingInfoForm = (props) => {
    const { data, disableInputs, errors, location, onChange, useType, context } = props;

    const handleParkingInfoChange = (event) => {
        onChange(event.target.id, event.target.value);
    };

    const filteredUseTypes = useType !== "Religious" && useType !== "Educational";

    const flexContainerClass = location.pathname.includes("listing")
        ? "parkingInfoFlexContainer"
        : "parkingInfoFlexContainerCompact";

    return filteredUseTypes ? (
        <div>
            <div className={flexContainerClass}>
                <div className="parkingInfoFlexContainerUnresponsive">
                    <div className="parkingInfoFlexField">
                        <BSInput
                            id="parkingTotal"
                            label="TOTAL PARKING SPACE COUNT"
                            placeholder="Enter count"
                            errorText="(ex. 98)"
                            error={errors.parkingTotal}
                            value={data.parkingTotal}
                            onChange={handleParkingInfoChange}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className={"parkingInfoFlexField"}>
                        <BSInput
                            id="parkingRatio"
                            label="PARKING RATIO (/1,000 SF)"
                            placeholder="Enter parking ratio"
                            errorText="(ex. 98.01)"
                            error={errors.parkingRatio}
                            value={data.parkingRatio}
                            onChange={handleParkingInfoChange}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
                <div className={"parkingInfoFlexContainerUnresponsive"}>
                    <div className={"parkingInfoFlexField"}>
                        <BSInput
                            id="surfaceParkingSpaceCount"
                            label="SURFACE PARKING COUNT"
                            errorText="(ex. 981)"
                            placeholder="Enter count"
                            error={errors.surfaceParkingSpaceCount}
                            value={data.surfaceParkingSpaceCount}
                            onChange={handleParkingInfoChange}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className={"parkingInfoFlexField"}>
                        <BSInput
                            id="parkingGarageSpaceCount"
                            label="PARKING GARAGE SPACE COUNT"
                            errorText="(ex. 981)"
                            placeholder="Enter count"
                            error={errors.parkingGarageSpaceCount}
                            value={data.parkingGarageSpaceCount}
                            onChange={handleParkingInfoChange}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
            </div>

            <div className={flexContainerClass}>
                <div className="parkingInfoFlexContainerUnresponsive">
                    <div className="parkingInfoFlexField">
                        <BSInput
                            id="parkingReservedSpaceCount"
                            label="RESERVED SPACE COUNT"
                            errorText="(ex. 98)"
                            placeholder="Enter count"
                            error={errors.parkingReservedSpaceCount}
                            value={data.parkingReservedSpaceCount}
                            onChange={handleParkingInfoChange}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className="parkingInfoFlexField">
                        <BSInput
                            id="carportParkingSpaceCount"
                            label="CARPORT PARKING COUNT"
                            errorText="(ex. 98)"
                            placeholder="Enter count"
                            error={errors.carportParkingSpaceCount}
                            value={data.carportParkingSpaceCount}
                            onChange={handleParkingInfoChange}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
                {filteredUseTypes && (
                    <div className="parkingInfoFlexContainerUnresponsive parkingInfoCheckboxes">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.valetParkingFlg ? true : false}
                                    onChange={(e, checked) => onChange(e.target.value, checked)}
                                    value="valetParkingFlg"
                                    disabled={disableInputs === true}
                                    color="primary"
                                />
                            }
                            label="Valet Parking"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.parkingVehicleElevatorFlg ? true : false}
                                    onChange={(e, checked) => onChange(e.target.value, checked)}
                                    value="parkingVehicleElevatorFlg"
                                    disabled={disableInputs === true}
                                    color="primary"
                                />
                            }
                            label="Parking Vehicle Elevator"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.parkingVehicleChargingStationsFlg ? true : false}
                                    onChange={(e, checked) => onChange(e.target.value, checked)}
                                    value="parkingVehicleChargingStationsFlg"
                                    disabled={disableInputs === true}
                                    color="primary"
                                />
                            }
                            label="EV Charging Stations"
                        />
                    </div>
                )}
            </div>

            {context === "listing" && (
                <div>
                    <BSInput
                        id="parkingNotes"
                        label="Parking Notes"
                        placeholder="Enter parking notes"
                        error={errors.parkingNotes}
                        value={data.parkingNotes}
                        onChange={handleParkingInfoChange}
                        disabled={disableInputs}
                    />
                </div>
            )}
        </div>
    ) : (
        <div>
            <div className="parkingInfoFlexContainer">
                <div className="parkingInfoFlexField">
                    <BSInput
                        id="parkingTotal"
                        label="TOTAL PARKING SPACE COUNT"
                        placeholder="Enter count"
                        errorText="(ex. 98)"
                        error={errors.parkingTotal}
                        value={data.parkingTotal}
                        onChange={handleParkingInfoChange}
                        disabled={disableInputs}
                    />
                </div>
                {context === "listing" && (
                    <div className="parkingInfoFlexField">
                        <BSInput
                            id="parkingNotes"
                            label="Parking Notes"
                            placeholder="Enter parking notes"
                            error={errors.parkingNotes}
                            value={data.parkingNotes}
                            onChange={handleParkingInfoChange}
                            disabled={disableInputs}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ParkingInfoForm;
