import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Chip, Typography, Button, Grid, InputLabel, CircularProgress } from "@material-ui/core";
import BSInput from "../../../common/js/components/BSInput";
import BSSelect from "../../../common/js/components/BSSelect";
import DeleteConfirmationModalContainer from "./DeleteConfirmationModalContainer";
import { chipColors } from "./constants";

const ComplianceRuleModal = (props) => {
    const {
        classes,
        modalHeader,
        isLoading,
        conditions,
        conditionValues,
        actionValues,
        selectConditions,
        selectConditionType,
        handleActionsSelect,
        addCondition,
        removeCondition,
        addAction,
        removeAction,
        handleRuleInputChange,
        handleSaveRule,
        modal,
        handleClose,
    } = props;

    const [openDeleteModal, setDeleteModal] = useState(false);

    const filterOptionsByDisplay = (options) => {
        const display = options.filter((option) => {
            return option.display;
        });
        return display;
    };

    const operatorOptions = (conditionTypeKey) => {
        if (conditionTypes.length < 1 || conditionTypeKey === 0) {
            return [];
        }
        let result = conditionTypes.find((conditionType) => {
            return conditionType.value === conditionTypeKey;
        });
        return result.conditionOperators.length > 0 ? result.conditionOperators : [];
    };

    const statesOptions = () => {
        return isDisabled(conditionTypeKey) ? [] : states;
    };

    const isDisabled = (conditionTypeKey) => {
        if (conditionTypes.length < 1 || conditionTypeKey === 0) {
            return true;
        }
        let result = conditionTypes.find((conditionType) => {
            return conditionType.value === conditionTypeKey;
        });
        return result.conditionOperators.length < 1;
    };

    const { conditionTypes, states, actionTypes, documentTypes } = conditions;
    const { conditionTypeKey, conditionOperatorKey, conditionValue } = conditionValues;
    const { documentType, actionType } = actionValues;
    const { ruleKey, ruleName, ruleDescription } = modal.data;

    return !isLoading ? (
        <React.Fragment>
            <div className={classes.paper}>
                <Grid container spacing={8}>
                    <Grid item xs={6}>
                        <Grid container spacing={8} direction="column">
                            <Grid item>
                                <Typography className={classes.modalHeader} variant="h6">
                                    {modalHeader}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <BSInput
                                    noHelperText
                                    label="Rule Name"
                                    onChange={(e) => handleRuleInputChange("ruleName", e.target.value)}
                                    value={ruleName}
                                />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.modalHeader} variant="h6">
                                    Add Conditions and Actions
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                        <BSSelect
                                            label="Condition Type"
                                            value={conditionTypeKey}
                                            options={filterOptionsByDisplay(conditionTypes)}
                                            onChange={(e) => {
                                                selectConditionType(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <BSSelect
                                            label="Operator"
                                            value={conditionOperatorKey}
                                            disabled={isDisabled(conditionTypeKey)}
                                            options={operatorOptions(conditionTypeKey)}
                                            onChange={(e) => {
                                                selectConditions("conditionOperatorKey", Number(e.target.value));
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <BSSelect
                                            label="Value"
                                            value={conditionValue}
                                            disabled={isDisabled(conditionTypeKey)}
                                            options={statesOptions()}
                                            onChange={(e) => selectConditions("conditionValue", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            className={classes.buttonContainer}
                                            disabled={conditionTypeKey === 0}
                                            variant="contained"
                                            color="primary"
                                            onClick={addCondition}
                                        >
                                            Add Condition
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={8} direction="column">
                                    <Grid item>
                                        <InputLabel shrink className={classes.bootstrapFormLabel}>
                                            Conditions
                                        </InputLabel>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.chipContainer}>
                                            {modal.data.conditions.map((condition, i) => {
                                                const { conditionType, conditionOperator, conditionValue } = condition;
                                                const label =
                                                    conditionValue !== null && conditionOperator !== null
                                                        ? `${conditionType.displayName} ${conditionOperator.displayName} ${conditionValue}`
                                                        : conditionType.displayName;
                                                return (
                                                    <Chip
                                                        variant="outlined"
                                                        onDelete={() => {
                                                            removeCondition(conditionType.conditionTypeKey);
                                                        }}
                                                        className={classes.chipStyle}
                                                        style={{ border: `0.2em solid ${chipColors.hidden}` }}
                                                        key={i}
                                                        label={label}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={8}>
                                        <Grid item>
                                            <Chip
                                                variant="outlined"
                                                style={{
                                                    border: `0.2em solid ${chipColors.required}`,
                                                    color: chipColors.required,
                                                }}
                                                label={"Document is Required"}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                variant="outlined"
                                                style={{
                                                    border: `0.2em solid ${chipColors.optional}`,
                                                    color: chipColors.optional,
                                                }}
                                                label={"Document is Optional"}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                variant="outlined"
                                                style={{
                                                    border: `0.2em solid ${chipColors.hidden}`,
                                                    color: chipColors.hidden,
                                                }}
                                                label={"Don't Show Document as an Option"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={8} direction="column">
                            <Grid item>
                                <Typography className={classes.modalHeader} variant="h6">
                                    {<br />}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <BSInput
                                    noHelperText
                                    label="Rule Description"
                                    onChange={(e) => handleRuleInputChange("ruleDescription", e.target.value)}
                                    value={ruleDescription}
                                />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.modalHeader} variant="h6">
                                    {<br />}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container justify="space-between">
                                    <Grid item xs={6}>
                                        <BSSelect
                                            label="Document Type"
                                            value={documentType}
                                            options={filterOptionsByDisplay(documentTypes)}
                                            onChange={(e) => {
                                                handleActionsSelect("documentType", e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BSSelect
                                            label="Action Type"
                                            value={actionType}
                                            options={actionTypes}
                                            onChange={(e) => {
                                                handleActionsSelect("actionType", e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            className={classes.buttonContainer}
                                            disabled={documentType === 0}
                                            variant="contained"
                                            color="primary"
                                            onClick={addAction}
                                        >
                                            Add Action
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={8} direction="column">
                                    <Grid item>
                                        <InputLabel shrink className={classes.bootstrapFormLabel}>
                                            Actions
                                        </InputLabel>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.chipContainer}>
                                            {modal.data.actions
                                                .sort((a, b) => a.actionType.actionTypeKey - b.actionType.actionTypeKey)
                                                .map((action, i) => {
                                                    const { actionType, documentTypeKey, documentName } = action;
                                                    const chipColor =
                                                        actionType.actionTypeKey === 1
                                                            ? chipColors.required
                                                            : actionType.actionTypeKey === 2
                                                            ? chipColors.optional
                                                            : chipColors.hidden;
                                                    return (
                                                        <Chip
                                                            variant="outlined"
                                                            onDelete={() => {
                                                                removeAction(documentTypeKey);
                                                            }}
                                                            className={classes.chipStyle}
                                                            style={{
                                                                border: `0.2em solid ${chipColor}`,
                                                                color: chipColor,
                                                            }}
                                                            key={i}
                                                            label={documentName}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    </Grid>
                                    <Grid item style={{ alignSelf: "end" }}>
                                        <Button variant="outlined" onClick={handleClose} color="primary">
                                            Cancel
                                        </Button>
                                        {ruleKey ? (
                                            <Button
                                                variant="contained"
                                                onClick={() => setDeleteModal(true)}
                                                className={classes.deleteButton}
                                            >
                                                Delete
                                            </Button>
                                        ) : null}
                                        <Button
                                            variant="contained"
                                            onClick={() => handleSaveRule(ruleKey)}
                                            color="primary"
                                        >
                                            Save Rule
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <DeleteConfirmationModalContainer open={openDeleteModal} toggleModal={setDeleteModal} />
        </React.Fragment>
    ) : (
        <CircularProgress style={{ position: "fixed", top: "50%", left: "50%" }} />
    );
};

const styles = (theme) => ({
    deleteButton: {
        color: "#fff",
        backgroundColor: "#d32f2f",
        "&:hover": {
            backgroundColor: "#c62828",
        },
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        background: "white",
        top: "50%",
        left: "50%",
        width: "1300px",
        height: "700px",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        borderRadius: "3px",
        boxShadow: " 0 3px 7px rgba(0, 0, 0, 0.3)",
        padding: "20px",
    },
    modalHeader: {
        fontWeight: "600",
        marginBottom: "20px",
    },
    buttonContainer: {
        padding: "7px 16px",
        marginTop: "22px",
        marginLeft: "0px",
        width: "auto",
    },
    chipContainer: {
        border: "1px solid #e7e7e7",
        borderRadius: "5px",
        height: "300px",
        marginTop: "10px",
        overflow: "auto",
    },
    chipStyle: {
        margin: "5px",
    },
    legend: {
        display: "flex",
    },
});

export default withStyles(styles)(ComplianceRuleModal);
