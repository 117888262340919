import React, { useState } from "react";
import { Button, Typography, Modal, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const SaveAndSyncModal = (props) => {
    const { isOpen, toggleModal, classes, handleSubmit } = props;

    const [syncChangesCheckbox, toggleSyncChangesCheckbox] = useState(false);

    const toggleModalClose = () => {
        toggleModal(false);
    };

    const toggleSyncChanges = () => {
        toggleSyncChangesCheckbox(!syncChangesCheckbox);
    };

    return (
        <Modal
            aria-labelledby="save-and-sync-modal"
            aria-describedby="saves information of property and syncs to all pertaining linked properties"
            open={isOpen}
            onClose={toggleModalClose}
        >
            <div className={classes.paper}>
                <Typography variant="h5" className={classes.modalHeader}>
                    Sync Changes?
                </Typography>
                <div className={classes.formContainer}>
                    <p>
                        This Asset Service record shares the same Accounting System Software ID with other properties.
                    </p>
                    <span className={classes.syncContainer} onClick={toggleSyncChanges}>
                        <Checkbox
                            classes={{ root: classes.syncCheckbox }}
                            color="primary"
                            checked={syncChangesCheckbox}
                        />
                        <span className={classes.syncDesc}>Check to apply changes to all linked properties.</span>
                    </span>
                    <SubmitButtons
                        classes={classes}
                        toggleModalClose={toggleModalClose}
                        handleSubmit={() => handleSubmit(syncChangesCheckbox)}
                    />
                </div>
            </div>
        </Modal>
    );
};

const SubmitButtons = (props) => {
    const { classes, toggleModalClose, handleSubmit } = props;
    const closeModal = () => {
        toggleModalClose();
    };
    return (
        <div className={classes.submitButtonsContainer}>
            <Button variant="contained" color="default" onClick={closeModal}>
                Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Save
            </Button>
        </div>
    );
};

const styles = (theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        background: "white",
        top: "50%",
        left: "50%",
        width: "500px",
        height: "250px",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        alignItems: "center",
        borderRadius: "3px",
        boxShadow: " 0 3px 7px rgba(0, 0, 0, 0.3)",
    },
    modalHeader: {
        alignSelf: "center",
        marginTop: "5%",
    },
    formContainer: {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        width: "80%",
        height: "90%",
    },
    submitButtonsContainer: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "end",
        paddingBottom: "15px",
    },
    syncCheckbox: {
        padding: 0,
        paddingRight: "15px",
    },
    syncDesc: {
        cursor: "pointer",
    },
    syncContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
});

export default withStyles(styles)(SaveAndSyncModal);
