import React from "react";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropertyManagementComponent from "./PropertyManagementComponent";

const PropertyHistory = (props) => {
    const {
        panelId,
        snapshot,
        contacts,
        personnel,
        mediaAssets,
        handleChange,
        expanded,
        role,
        classes,
        property,
        autocompleteResults,
        searchKMPerson,
        handleInput,
        handleAddArrayElement,
        handleDeleteArrayElement,
        handleUpdateArrayElement,
        prop_management_contact_options,
        handleSearchCompany,
        errors,
        location,
    } = props;

    const { terminationDate } = snapshot;
    return (
        <React.Fragment>
            <ExpansionPanel expanded={expanded === panelId} onChange={handleChange(panelId)}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>{terminationDate}</Typography>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                    <PropertyManagementComponent
                        role={role}
                        snapshot={snapshot}
                        snapshotContacts={contacts}
                        snapshotPersonnel={personnel}
                        snapshotMediaAssets={mediaAssets}
                        classes={classes}
                        property={property}
                        propertyManagement={snapshot}
                        autocompleteResults={autocompleteResults}
                        searchKMPerson={searchKMPerson}
                        handleInput={handleInput}
                        handleAddArrayElement={handleAddArrayElement}
                        handleDeleteArrayElement={handleDeleteArrayElement}
                        handleUpdateArrayElement={handleUpdateArrayElement}
                        prop_management_contact_options={prop_management_contact_options}
                        handleSearchCompany={handleSearchCompany}
                        errors={errors}
                        isSnapshot={true}
                        location={location}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </React.Fragment>
    );
};

export default PropertyHistory;
