const RECEIVE_DEALS = "RECEIVE_DEALS";
const RECEIVE_DEALS_EXPORT = "RECEIVE_DEALS_EXPORT";
const INITIATE_DEALS_EXPORT = "INITIATE_DEALS_EXPORT";
const CLEAR_DEALS_EXPORT = "CLEAR_DEALS_EXPORT";
const SWITCH_DEAL_VIEW = "SWITCH_DEAL_VIEW";
const SORT_DEALS = "SORT_DEALS";
const FILTER_DEALS = "FILTER_DEALS";
const SELECT_ALL = "SELECT_ALL";
const CHECKBOX_CLICK = "CHECKBOX_CLICK";
const ADD_DOCUMENTS = "ADD_DOCUMENTS";
const SORT_DOCUMENTS = "SORT_DOCUMENTS";
const SELECT_DOC_TYPE = "SELECT_DOC_TYPE";
const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
const OPEN_DOC_MENU = "OPEN_DOC_MENU";
const AUTOSAVE_NOT_SUCCESS = "AUTOSAVE_NOT_SUCCESS";
const AUTOSAVE_SUCCESS = "AUTOSAVE_SUCCESS";
const RECEIVE_VOUCHER_DOCUMENT = "RECEIVE_VOUCHER_DOCUMENT";
const SET_DEAL_PROGRESS = "SET_DEAL_PROGRESS";
const PAGE_LOADING = "PAGE_LOADING";
const CLEAR_DEALS_STATE = "CLEAR_DEALS_STATE";

export default {
    RECEIVE_DEALS,
    RECEIVE_DEALS_EXPORT,
    INITIATE_DEALS_EXPORT,
    CLEAR_DEALS_EXPORT,
    SWITCH_DEAL_VIEW,
    SORT_DEALS,
    FILTER_DEALS,
    SELECT_ALL,
    CHECKBOX_CLICK,
    ADD_DOCUMENTS,
    SORT_DOCUMENTS,
    SELECT_DOC_TYPE,
    REMOVE_DOCUMENT,
    OPEN_DOC_MENU,
    AUTOSAVE_NOT_SUCCESS,
    AUTOSAVE_SUCCESS,
    RECEIVE_VOUCHER_DOCUMENT,
    SET_DEAL_PROGRESS,
    PAGE_LOADING,
    CLEAR_DEALS_STATE,
};
