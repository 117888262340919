import React from "react";

import operations from "./ducks/operations";
import UserListings from "./UserListings";
import connect from "react-redux/es/connect/connect";
import ReactGA from "react-ga4";

class UserListingsContainer extends React.Component {
    componentDidMount() {
        this.props.switchTab(null, this.props.userTab);
        ReactGA.send({ hitType: "pageview", page: "/admin/users", title: "User Account Admin" });
    }

    render() {
        return <UserListings {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.adminReducer.tabs[state.adminReducer.userTab],
        userTab: state.adminReducer.userTab ? state.adminReducer.userTab : "active_users",
        columnToSort: state.adminReducer.columnToSort,
        sortDirection: state.adminReducer.sortDirection,
        filterQuery: state.adminReducer.filterQuery,
        userSearchType: state.adminReducer.userSearchType,
        isLoading: state.utilsReducer.isLoading,
        searchResults: state.searchReducer.autocompleteResults,
        userProfile: state.authReducer.userProfile,
        userOfficeHistory: state.adminReducer.userOfficeHistory,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchTab: (event, value) => {
            dispatch(operations.switchTab(value));
        },
        handleFilter: (query) => {
            dispatch(operations.filterUsers(query));
        },
        handleSort: (columnName) => {
            dispatch(operations.handleSort(columnName));
        },
        handleSearchUsers: () => {
            dispatch(operations.getUsersByStatus());
        },
        handleChangeUserSearchType: (searchType) => {
            dispatch(operations.handleChangeUserSearchType(searchType));
        },
        handleGetUserOfficeHistory: (userAccountKey) => {
            dispatch(operations.getUserOfficeHistory(userAccountKey));
        },
        handleOfficeDateChange: (userOfficeHistoryKey, value, dateType, userAccountKey) => {
            dispatch(operations.updateOfficeDate(userOfficeHistoryKey, value, dateType, userAccountKey));
        },
        handleSaveHistory: (userAccountKey) => {
            dispatch(operations.saveOfficeHistory(userAccountKey));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListingsContainer);
