export const accountingMethodTypes = [
    {
        value: "Accrual",
        label: "Accrual",
    },
    {
        value: "Cash",
        label: "Cash",
    },
    {
        value: "Modified Accrual",
        label: "Modified Accrual",
    },
];
