import React from "react";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { all_user_types } from "../auth/constants";

const lease = {
    //voucher for lease deal
    COMMISSION_PAYMENT: {
        required: false,
        display: "Commission payment or equivalent",
        available: false,
        multipleAllowed: true,
    },
    INVOICE: {
        required: true,
        display: "Invoice",
        available: false,
        multipleAllowed: true,
    },
    W9: {
        required: false,
        display: "W9 (New client or company)",
        available: false,
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
        multipleAllowed: true,
    },
};

const sale = {
    //voucher for sale deal
    COMMISSION_PAYMENT: {
        required: false,
        display: "Commission payment or equivalent",
        available: false,
        multipleAllowed: true,
    },
    INVOICE: {
        required: true,
        display: "Invoice",
        available: false,
        multipleAllowed: true,
    },
    W9: {
        required: false,
        display: "W9 (New client or company)",
        available: false,
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
        multipleAllowed: true,
    },
};

const propertyForm = {
    AMENITIES: {
        required: false,
        display: "Amenities",
        available: false,
    },
    FLOOR_PLAN: {
        required: false,
        display: "Floor Plan",
        available: false,
    },
    FLYER: {
        required: false,
        display: "Flyer",
        available: false,
    },
    PHOTO: {
        required: false,
        display: "Photo",
        available: false,
        multipleAllowed: true,
    },
    PRIMARY_PHOTO: {
        required: true,
        display: "Primary Photo",
        available: false,
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
        multipleAllowed: true,
    },
};

const propertyDetail = {
    AIR_PROPERTY_INFORMATION: {
        required: false,
        display: "AIR property information sheet (CA)",
        available: false,
    },
    ANY_OFFER: {
        required: false,
        display: "Any offer (OR)",
        available: false,
    },
    AMENDMENTS: {
        required: false,
        display: "Amendment",
        available: false,
        multipleAllowed: true,
    },
    AMENITIES: {
        required: false,
        display: "Amenities",
        available: false,
    },
    COMMISSION_PAYMENT: {
        required: true,
        display: "Commission payment or equivalent",
        available: false,
        multipleAllowed: true,
    },
    COMMISSION_STATEMENT: {
        required: false,
        display: "Commission statement",
        available: false,
    },
    CORRESPONDENCE: {
        required: false,
        display: "Correspondence (OR)",
        available: false,
    },
    DEPOSIT_HISTORY: {
        required: false,
        display: "Deposit history (CA)",
        available: false,
    },
    EARTHQUAKE_DISCLOSURE: {
        required: false,
        display: "NHD and commercial earthquake disclosure (CA)",
        available: false,
    },
    EXHIBITS: {
        required: false,
        display: "Exhibits",
        available: false,
    },
    FLOOR_PLAN: {
        required: false,
        display: "Floor Plan",
        available: false,
    },
    FLYER: {
        required: false,
        display: "Flyer",
        available: false,
    },
    FULLY_EXECUTED_LEASE: {
        required: false,
        display: "Fully executed lease",
        available: false,
    },
    INVOICE: {
        required: false,
        display: "Invoice",
        available: false,
        multipleAllowed: true,
    },
    MASTER_LEASE_IF_SUBLEASE: {
        required: false,
        display: "Master lease (If sublease)",
        available: false,
    },
    PHOTO: {
        required: false,
        display: "Photo",
        available: false,
        multipleAllowed: true,
    },
    PRIMARY_PHOTO: {
        required: true,
        display: "Primary Photo",
        available: false,
    },
    RELEVANT_CORRESPONDENCE: {
        required: false,
        display: "Relevant correspondence (Letters, Memos, Emails)",
        available: false,
    },
    W9: {
        required: false,
        display: "W9 (New client or company)",
        available: false,
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
        multipleAllowed: true,
    },
};

const listingForm = {
    AMENDED_LISTING_AGREEMENT: {
        required: false,
        display: "Amended Listing Agreement",
        available: false,
        multipleAllowed: true,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
    AMENITIES: {
        required: false,
        display: "Amenities",
        available: false,
    },
    EXCLUSIVE: {
        required: false,
        display: "Exclusive",
        available: false,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
    FLOOR_PLAN: {
        required: false,
        display: "Floor Plan",
        available: false,
        // upload: {
        //   roles: [...all_user_types],
        //   status: ["Active"]
        // },
    },
    LISTING_AGREEMENT: {
        required: true,
        display: "Executed Listing Agreement",
        available: false,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
    SECONDARY_FLYER: {
        required: false,
        display: "Flyer",
        available: false,
        multipleAllowed: true,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
        delete: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
    PHOTO: {
        required: false,
        display: "Photo",
        available: false,
        multipleAllowed: true,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
    FLYER: {
        required: false,
        display: "Primary Flyer",
        available: false,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
        delete: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
    PRIMARY_PHOTO: {
        required: false,
        display: "Primary Photo",
        available: false,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
        delete: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
        multipleAllowed: true,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
};

const listingFormOutside = {
    AMENDED_LISTING_AGREEMENT: {
        required: false,
        display: "Amended Listing Agreement",
        available: false,
        multipleAllowed: true,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
    AMENITIES: {
        required: false,
        display: "Amenities",
        available: false,
    },
    EXCLUSIVE: {
        required: false,
        display: "Exclusive",
        available: false,
        // upload: {
        //   roles: [...all_user_types],
        //   status: ["Active"]
        // },
    },
    LISTING_AGREEMENT: {
        required: false,
        display: "Executed Listing Agreement",
        available: false,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
        FLOOR_PLAN: {
            required: false,
            display: "Floor Plan",
            available: false,
            upload: {
                roles: [...all_user_types],
                status: ["Active"],
            },
        },
        SECONDARY_FLYER: {
            required: false,
            display: "Flyer",
            available: false,
            upload: {
                roles: [...all_user_types],
                status: ["Active"],
            },
            delete: {
                roles: [...all_user_types],
                status: ["Active"],
            },
        },
    },
    PHOTO: {
        required: false,
        display: "Photo",
        available: false,
        multipleAllowed: true,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
    FLYER: {
        required: false,
        display: "Primary Flyer",
        available: false,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
        delete: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
    PRIMARY_PHOTO: {
        required: false,
        display: "Primary Photo",
        available: false,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
        delete: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
        multipleAllowed: true,
        upload: {
            roles: [...all_user_types],
            status: ["Active"],
        },
    },
};

const comp = {
    AIR_PROPERTY_INFORMATION: {
        required: false,
        display: "AIR property information sheet (CA)",
        available: false,
    },
    ANY_OFFER: {
        required: false,
        display: "Any offer (OR)",
        available: false,
    },
    AMENDMENTS: {
        required: false,
        display: "Amendment",
        available: false,
        multipleAllowed: true,
    },
    CLOSING_STATEMENT_BUYERS: {
        required: false,
        display: `Closing statement - buyer"s (If represented)`,
        available: false,
    },
    CLOSING_STATEMENT_BUYER_NOT_REPRESENTED: {
        required: false,
        display: `Closing statement - buyer"s (Not represented, if available)`,
        available: false,
    },
    CLOSING_STATEMENT_SELLERS: {
        required: false,
        display: `Closing statement - seller"s (If represented)`,
        available: false,
    },
    CLOSING_STATEMENT_SELLERS_NOT_REPRESENTED: {
        required: false,
        display: `Closing statement - sellers"s (Not represented, if available)`,
        available: false,
    },
    CONTINGENCY_REMOVAL: {
        required: false,
        display: `Contingency Removal`,
        available: false,
    },
    COMMISSION_STATEMENT: {
        required: false,
        display: "Commission statement",
        available: false,
    },
    CORRESPONDENCE: {
        required: false,
        display: "Correspondence (OR)",
        available: false,
    },
    EARNEST_MONEY_OR_EQUIVALENT: {
        required: false,
        display: "Earnest money or equivalent (WA)",
        available: false,
    },
    EARTHQUAKE_DISCLOSURE: {
        required: false,
        display: "NHD and commercial earthquake disclosure (CA)",
        available: false,
    },
    ESCROW_INSTRUCTIONS: {
        required: false,
        display: `Escrow Instructions`,
        available: false,
    },
    ESCROW_RECEIPT: {
        required: false,
        display: `Escrow Receipt`,
        available: false,
    },
    EXHIBITS: {
        required: false,
        display: "Exhibits",
        available: false,
    },
    FIRPTA: {
        required: false,
        display: `FIRPTA`,
        available: false,
    },
    SECONDARY_FLYER: {
        required: false,
        display: "Flyer",
        available: false,
    },
    FULLY_EXECUTED_LEASE: {
        required: false,
        display: "Fully executed lease",
        available: false,
    },
    MASTER_LEASE_IF_SUBLEASE: {
        required: false,
        display: "Master lease (If sublease)",
        available: false,
    },
    MARKETING_FLYER_PHOTO: {
        required: false,
        display: "Marketing Flyer Photo",
        available: false,
    },
    OFFERING_MEMORANDUM: {
        required: false,
        display: "Offering Memorandum",
        available: false,
    },
    PRELIM_TITLE_REPORT: {
        required: false,
        display: `Prelim Title Report`,
        available: false,
    },
    FLYER: {
        required: false,
        display: "Primary Flyer",
        available: false,
    },
    PURCHASE_AND_SALE_AGREEMENT: {
        required: false,
        display: "PSA",
        available: false,
    },
    RELEVANT_CORRESPONDENCE: {
        required: false,
        display: "Relevant correspondence (Letters, Memos, Emails)",
        available: false,
    },
    SELLERS_DISCLOSURE: {
        required: false,
        display: `Seller"s mandatory disclosure`,
        available: false,
    },
    TERMINATION_DOCUMENT: {
        required: false,
        display: `Termination Document`,
        available: false,
    },
    TERMITE_INSPECTION_REPORT: {
        required: false,
        display: `Termite Inspection Report`,
        available: false,
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
        multipleAllowed: true,
    },
};

const consulting = {
    AMENDMENTS: {
        required: false,
        display: "Amendment",
        available: false,
        multipleAllowed: true,
    },
    CLOSING_STATEMENT_BUYERS: {
        required: false,
        display: `Closing statement - buyer"s (If represented)`,
        available: false,
    },
    CLOSING_STATEMENT_SELLERS: {
        required: false,
        display: `Closing statement - seller"s (If represented)`,
        available: false,
    },
    CLOSING_STATEMENT_BUYER_NOT_REPRESENTED: {
        required: false,
        display: `Closing statement - buyer"s (Not represented, if available)`,
        available: false,
    },
    CLOSING_STATEMENT_SELLERS_NOT_REPRESENTED: {
        required: false,
        display: `Closing statement - sellers"s (Not represented, if available)`,
        available: false,
    },
    CONTINGENCY_REMOVAL: {
        required: false,
        display: `Contingency Removal`,
        available: false,
    },
    COMMISSION_PAYMENT: {
        required: false,
        display: "Commission payment or equivalent",
        available: false,
        multipleAllowed: true,
    },
    DEPOSIT_HISTORY: {
        required: false,
        display: "Deposit history (CA)",
        available: false,
    },
    EARNEST_MONEY_OR_EQUIVALENT: {
        required: false,
        display: "Earnest money or equivalent (WA)",
        available: false,
    },
    ESCROW_INSTRUCTIONS: {
        required: false,
        display: `Escrow Instructions`,
        available: false,
    },
    FULLY_EXECUTED_LEASE: {
        required: false,
        display: "Fully executed lease",
        available: false,
    },
    INVOICE: {
        required: true,
        display: "Invoice",
        available: false,
        multipleAllowed: true,
    },
    PRELIM_TITLE_REPORT: {
        required: false,
        display: `Prelim Title Report`,
        available: false,
    },
    FLYER: {
        required: false,
        display: "Primary Flyer",
        available: false,
    },
    PURCHASE_AND_SALE_AGREEMENT: {
        required: false,
        display: "PSA",
        available: false,
    },
    TERMITE_INSPECTION_REPORT: {
        required: false,
        display: `Termite Inspection Report`,
        available: false,
    },
    W9: {
        required: false,
        display: "W9 (New client or company)",
        available: false,
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
        multipleAllowed: true,
    },
};

const propertyManagement = {
    FLOOR_PLAN: {
        required: false,
        display: "Floor Plan",
        available: false,
        multipleAllowed: true,
    },
    PHOTO: {
        required: false,
        display: "Photo",
        available: false,
        multipleAllowed: true,
    },
    PRIMARY_PHOTO: {
        required: true,
        display: "Primary Photo",
        available: false,
    },
    PROPERTY_MANAGEMENT_AGREEMENT: {
        required: false,
        display: "Property Management Agreement",
        available: false,
        multipleAllowed: true,
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
        multipleAllowed: true,
    },
};

const transaction = {
    BANK_STATEMENT: {
        required: false,
        display: "Bank Statement",
        available: true,
        multipleAllowed: true,
    },
    INSURANCE: {
        required: false,
        display: "Insurance",
        available: true,
        multipleAllowed: true,
    },
    LLC_INFORMATION: {
        required: false,
        display: "LLC Information",
        available: true,
        multipleAllowed: true,
    },
    PROPERTY_NUMBERS: {
        required: false,
        display: "Property Numbers",
        available: true,
        multipleAllowed: true,
    },
    TAXES: {
        required: false,
        display: "Taxes",
        available: true,
        multipleAllowed: true,
    },
    THIRD_PARTY_REPORT: {
        required: false,
        display: "3rd Party Report",
        available: true,
        multipleAllowed: true,
    },
    TITLE: {
        required: false,
        display: "Title",
        available: true,
        multipleAllowed: true,
    },
    OTHER: {
        required: false,
        display: "Other",
        available: false,
        multipleAllowed: true,
    },
};

const migratedTypes = {
    CLOSING_STATEMENT: {
        required: false,
        display: `Closing statement - general`,
        available: false,
    },
};

const warning = {
    lease: [
        // "FULLY_EXECUTED_LEASE",
        "COMMISSION_PAYMENT",
        "INVOICE",
    ],
    sale: ["COMMISSION_PAYMENT", "INVOICE"],
};

const user = {
    LICENSE: {
        required: false,
        display: "License",
        available: true,
        multipleAllowed: true,
    },
    EXHIBIT_A: {
        required: false,
        display: "Exhibit-A",
        available: true,
        multipleAllowed: true,
    },
    OTHER: {
        required: false,
        display: `Other`,
        available: true,
        multipleAllowed: true,
    },
};

const all = {
    ...lease,
    ...sale,
    ...propertyDetail,
    ...propertyManagement,
    ...listingForm,
    ...listingFormOutside,
    ...comp,
    ...transaction,
    ...consulting,
    ...migratedTypes,
};

export const docTypeIcons = {
    pdf: () => {
        return <PictureAsPdfOutlinedIcon style={{ fill: "#2C99D2", verticalAlign: "middle" }} />;
    },
    PDF: () => {
        return <PictureAsPdfOutlinedIcon style={{ fill: "#2C99D2", verticalAlign: "middle" }} />;
    },
    png: () => {
        return <InsertPhotoIcon style={{ fill: "#2C99D2", verticalAlign: "middle" }} />;
    },
    PNG: () => {
        return <InsertPhotoIcon style={{ fill: "#2C99D2", verticalAlign: "middle" }} />;
    },
    msg: () => {
        return <EmailOutlinedIcon style={{ fill: "#2C99D2", verticalAlign: "middle" }} />;
    },
    MSG: () => {
        return <EmailOutlinedIcon style={{ fill: "#2C99D2", verticalAlign: "middle" }} />;
    },
    default: () => {
        return <InsertDriveFileOutlinedIcon style={{ fill: "#2C99D2", verticalAlign: "middle" }} />;
    },
};

const options = {
    listingForm: {
        Active: {
            broker: {
                delete: ["PRIMARY_PHOTO", "FLYER", "SECONDARY_FLYER"],
                upload: [
                    "LISTING_AGREEMENT",
                    "AMENDED_LISTING_AGREEMENT",
                    "PHOTO",
                    "FLOOR_PLAN",
                    "PRIMARY_PHOTO",
                    "FLYER",
                    "SECONDARY_FLYER",
                    "EXCLUSIVE",
                    "OTHER",
                ],
            },
        },
    },
};

const allDocTypesList = [
    "ADDENDUM",
    "COMMISSION_PAYMENT",
    "INVOICE",
    "W9",
    "AMENITIES",
    "FLOOR_PLAN",
    "FLYER",
    "PHOTO",
    "PRIMARY_PHOTO",
    "COMPANY_LOGO",
    "COMPANY_MAP_LOGO",
    "AIR_PROPERTY_INFORMATION",
    "ANY_OFFER",
    "AMENDMENTS",
    "COMMISSION_STATEMENT",
    "CORRESPONDENCE",
    "DEPOSIT_HISTORY",
    "EARTHQUAKE_DISCLOSURE",
    "EXHIBITS",
    "FULLY_EXECUTED_LEASE",
    "MASTER_LEASE_IF_SUBLEASE",
    "SUBLEASE",
    "RELEVANT_CORRESPONDENCE",
    "CLOSING_STATEMENT",
    "CLOSING_STATEMENT_BUYERS",
    "CLOSING_STATEMENT_BUYER_NOT_REPRESENTED",
    "CLOSING_STATEMENT_SELLERS",
    "CLOSING_STATEMENT_SELLERS_NOT_REPRESENTED",
    "CONTINGENCY_REMOVAL",
    "EARNEST_MONEY_OR_EQUIVALENT",
    "ESCROW_INSTRUCTIONS",
    "ESCROW_RECEIPT",
    "FIRPTA",
    "PRELIM_TITLE_REPORT",
    "PURCHASE_AND_SALE_AGREEMENT",
    "SELLERS_DISCLOSURE",
    "TERMINATION_DOCUMENT",
    "TERMITE_INSPECTION_REPORT",
    "LICENSE",
    "PROPERTY_MANAGEMENT_AGREEMENT",
    "BANK_STATEMENT",
    "INSURANCE",
    "LLC_INFORMATION",
    "PROPERTY_NUMBERS",
    "TAXES",
    "THIRD_PARTY_REPORT",
    "TITLE",
    "LISTING_AGREEMENT",
    "AMENDED_LISTING_AGREEMENT",
    "PHOTO",
    "FLOOR_PLAN",
    "PRIMARY_PHOTO",
    "FLYER",
    "SECONDARY_FLYER",
    "MARKETING_FLYER_PHOTO",
    "EXCLUSIVE",
    "OTHER",
    "OFFERING_MEMORANDUM",
];

export default {
    lease,
    sale,
    propertyDetail,
    propertyForm,
    listingForm,
    listingFormOutside,
    comp,
    propertyManagement,
    warning,
    transaction,
    consulting,
    user,
    options,
    all,
    allDocTypesList,
};
