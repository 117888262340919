import validate from "validate.js";
import { constraints } from "../../utils/validation";
import listingValidation from "../../listing/ducks/validation";
import each from "lodash/each";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import forIn from "lodash/forIn";
import mapValues from "lodash/mapValues";
import includes from "lodash/includes";

const compConstraints = {
    Office: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Industrial: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
        "listing.attributes.sfAvail": {
            ...constraints.number,
        },
        "listing.attributes.largestContigSf": {
            ...constraints.number,
        },
        "listing.attributes.officeSf": {
            ...constraints.number,
        },
        "listing.attributes.yardSf": {
            ...constraints.number,
        },
        "listing.attributes.suite": {},
        "listing.attributes.officeBreakdownSfRate": {
            ...constraints.number,
        },
        "listing.attributes.warehouseShellBreakdownRate": {
            ...constraints.number,
        },
        "listing.attributes.blendedRate": {
            ...constraints.number,
        },
        "listing.attributes.voltsNum": {},
        "listing.attributes.ampsNum": {
            ...constraints.number,
        },
        "listing.attributes.phaseNum": {
            ...constraints.number,
        },
        "listing.attributes.gradeLevelDoorsNum": {
            ...constraints.number,
        },
        "listing.attributes.dockHighDoorsNum": {
            ...constraints.number,
        },
        "listing.attributes.railDoorsNum": {
            ...constraints.id,
        },
        "listing.attributes.clearHeightMin": {
            ...constraints.number,
        },
        "listing.attributes.clearHeightMax": {
            ...constraints.number,
        },
        "listing.attributes.cranesQty": {
            ...constraints.number,
        },
        "listing.attributes.craneNotes": {},
        "listing.attributes.yardNotes": {},
        "attributes.industrialSf": {
            ...constraints.id,
        },
    },
    Retail: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Land: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Multifamily: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Hospitality: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
        "listing.attributes.hospitalitySf": {
            ...constraints.id,
        },
        "listing.attributes.hospitalityUnitCount": {
            ...constraints.id,
        },
        "listing.attributes.roomCount": {
            ...constraints.id,
        },
    },
    Other: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    Educational: {
        "listing.attributes.classroomCount": {
            ...constraints.number,
        },
        "listing.attributes.auditoriumSeatsCount": {
            ...constraints.number,
        },
    },
    Healthcare: {
        "listing.attributes.specialtyType": {
            ...constraints.required,
        },
    },
    totalConsideration: {
        totalConsiderationAmount: {
            ...constraints.required,
            ...constraints.number,
        },
        commencementDate: {
            ...constraints.required,
            ...constraints.date,
        },
        expirationDate: {
            ...constraints.required,
            ...constraints.date,
        },
        termNumMonths: {
            ...constraints.required,
            ...constraints.number,
        },
        squareFeet: {
            ...constraints.required,
            ...constraints.number,
        },
        ratePerSqftPerMonth: {
            ...constraints.required,
            ...constraints.number,
        },
        ratePerSqftPerYear: {
            ...constraints.required,
            ...constraints.number,
        },
        escalationRate: {
            ...constraints.required,
            ...constraints.number,
        },
        escalationTerm: {
            ...constraints.required,
            ...constraints.number,
        },
    },
    listingInformation: {
        //LISTING INFORMATION NO LONGER REQUIRED FOR OUTSIDE COMPS
        "listing.listingName": {
            ...constraints.required,
        },
    },
    leaseDetails: {
        tiRate: {
            ...constraints.number,
            ...constraints.required,
        },
        leaseType: {
            ...constraints.required,
        },
        movingAllowanceAmount: {
            ...constraints.number,
        },
        buyerTenantBusinessType: {},
        naicsCode: {
            ...constraints.id,
        },
        sicCode: {
            ...constraints.id,
        },
        previousLocation: {},
        currentSf: {
            ...constraints.number,
        },
        squareFeetChange: {
            ...constraints.number,
        },
    },
    Lease: {
        //general information
        //required fields
        transactionDate: {
            ...constraints.required,
            ...constraints.date,
        },
        renewalFlg: {
            ...constraints.required,
        },
        paymentMethod: {},
        leaseBackFlg: {},
        representationType: {},
        //other constraints
        "buyerTenantCompany.displayName": {},
        "buyerTenantCompany.legalName": {},
        "buyerTenantCompany.parentCompany.displayName": {},
        outsideBrokerCommission: {
            ...constraints.number,
        },
    },
    Sale: {
        //required fields
        transactionDate: {
            ...constraints.required,
            ...constraints.date,
        },
        closedDate: {
            ...constraints.required,
            ...constraints.date,
        },
        totalKmCommission: {},
        paymentMethod: {},
        leaseBackFlg: {},
        representationType: {},
        totalConsiderationAmount: {
            ...constraints.required,
            ...constraints.number,
        },
        squareFeet: {
            ...constraints.required,
            ...constraints.number,
        },
        //other constraints
    },
    parkingInformation: {
        "listing.attributes.parkingTotal": {
            ...constraints.number,
        },
        "listing.attributes.parkingRatio": {},
        "listing.attributes.surfaceParkingSpaceCount": {
            ...constraints.number,
        },
        "listing.attributes.parkingGarageSpaceCount": {
            ...constraints.number,
        },
        "listing.attributes.parkingReservedSpaceCount": {
            ...constraints.number,
        },
        "listing.attributes.carportParkingSpaceCount": {
            ...constraints.number,
        },
        "listing.attributes.parkingNotes": {},
    },
    listingFinancial: {},
    saleFinancial: {
        "listing.listPrice": {
            ...constraints.required,
        },
    },
};

const validateOutsideOrAppraisalComp = (comp, useType, transactionType, documents, compType) => {
    let whatToValidate = [];
    switch (transactionType) {
        case "Lease":
            whatToValidate = [
                useType,
                transactionType,
                "leaseDetails",
                "totalConsideration",
                "listingInformation",
                "buildingInformation",
                "parkingInformation",
                "listingFinancial",
            ];
            break;
        case "Sale":
            whatToValidate = [
                useType,
                transactionType,
                "listingInformation",
                "buildingInformation",
                "parkingInformation",
                "saleFinancial",
            ];
            break;
        default:
            break;
    }
    let combinedConstraints = {};
    each(whatToValidate, (section) => {
        combinedConstraints = { ...combinedConstraints, ...compConstraints[section] };
    });

    let errors = {
        ...mapValues(validate(comp, combinedConstraints, { fullMessages: false }), (error) => {
            return error[0];
        }),
        ...listingValidation.validateListingBrokersCompany(get(comp, "listing.listingBrokers")),
        ...listingValidation.validateMixedUses(get(comp, "listing.attributes.mixedUses")),
        ...listingValidation.validatePortfolioProperties(get(comp, "listing.attributes.portfolioProperties")),
        ...listingValidation.validateCondos(get(comp, "listing.attributes.condos")),
    };

    console.log("Form Errors: ", errors);
    return errors;
};

const getPanelsToExpand = (errors, useType, transactionType) => {
    let generalInformationConstraints = {};
    each([transactionType], (section) => {
        generalInformationConstraints = { ...generalInformationConstraints, ...compConstraints[section] };
    });

    const otherListingInfo = [
        "attributes.condos",
        "attributes.mixedUses",
        "attributes.portfolioProperties",
        "listing.listingBrokers",
    ];
    const otherGeneralInfo = ["installments"];

    let panelsToExpand = [];
    forIn(errors, (value, key) => {
        if (
            includes(
                [...Object.keys(compConstraints.listingFinancial), ...Object.keys(compConstraints.saleFinancial)],
                key
            )
        ) {
            panelsToExpand.push("listingFinancial");
        } else if (includes([...Object.keys(compConstraints.listingInformation), ...otherListingInfo], key)) {
            panelsToExpand.push("listingInformation");
        } else if (includes([...Object.keys(generalInformationConstraints), ...otherGeneralInfo], key)) {
            //ignore, general information opened and closed manually
            panelsToExpand.push("generalInformation");
        }
    });

    panelsToExpand = uniqBy(panelsToExpand, function (e) {
        return e;
    });

    return panelsToExpand;
};

export default {
    validateOutsideOrAppraisalComp,
    getPanelsToExpand,
};
