import actions from "./actions";
import set from "lodash/set";
import get from "lodash/get";
import toLower from "lodash/toLower";
import toUpper from "lodash/toUpper";
import map from "lodash/map";
import isNull from "lodash/isNull";
import each from "lodash/each";
import moment from "moment";
import numeral from "numeral";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import join from "lodash/join";
import identity from "lodash/identity";
import includes from "lodash/includes";
import compact from "lodash/compact";
import configs from "../../../config";
import { request } from "./axios-wrapper";
import ReactGA from "react-ga4";

const apiResponse = (response) => {
    return (dispatch, getState) => {
        dispatch(actions.apiResponse(response));
    };
};

const snackbar = (open, variant, message) => {
    return (dispatch, getState) => {
        dispatch(actions.snackbar(open, variant, message));
    };
};

export const round = (value, decimals, placeholder = "") => {
    if (!isNil(value) && !isNaN(value)) {
        return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
    } else {
        return placeholder;
    }
};

const arrayToObject = (array, keyField) => {
    let object = {};
    each(array, (item) => {
        if (item) {
            set(object, `${item[keyField]}`, item);
        }
    });
    return object;
};

const objectToArray = (object, keyField) => {
    const array = [];
    Object.keys(object).forEach((k) => {
        set(object[k], keyField, k);
        array.push(object[k]);
    });
    return array;
};

const calculateExpirationDate = (commencementDateStr, termInMonths) => {
    let expirationDate = null;
    if (termInMonths && !isNaN(termInMonths) && commencementDateStr) {
        const dashes = (commencementDateStr.match(/-/g) || []).length;
        if (dashes === 2) {
            const dateParts = commencementDateStr.split("-");
            commencementDateStr = dateParts[1] + "/" + dateParts[2] + "/" + dateParts[0];
        }
        expirationDate = new Date(commencementDateStr);
        let termArray = termInMonths.toString().split(".");
        let wholeMonths = parseInt(termArray[0]);
        let partialMonths = null;
        if (termArray.length > 1) {
            partialMonths = parseInt(termArray[1]);
            if (partialMonths < 10) {
                partialMonths = partialMonths * 10;
            }
        }
        expirationDate.setMonth(expirationDate.getMonth() + wholeMonths);

        // Subtract 1 day
        expirationDate.setDate(expirationDate.getDate() - 1);

        if (partialMonths && partialMonths !== 0) {
            //Now calculate partial Month
            var nextMonthDate = new Date(expirationDate);
            nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
            var time1 = expirationDate.getTime();
            var time2 = nextMonthDate.getTime();
            var daysBetween = Math.round((time2 - time1) / (1000 * 60 * 60 * 24));

            // Calculate as a percent
            var additionalDays = Math.round((partialMonths / 100) * daysBetween);
            expirationDate.setDate(expirationDate.getDate() + additionalDays);
        }
    }
    return expirationDate != null ? expirationDate.toLocaleDateString("en-US") : null;
};

export const calculateDaysBetween = (date1, date2) => {
    if (date1 === null || date2 === null) return null;
    const dateC = moment(date1);
    const dateB = moment(date2);
    const daysBetween = dateB.diff(dateC, "days");
    return daysBetween;
};

const calculateTerms = (commencementDateStr, expirationDateStr) => {
    let expirationDate = new Date(expirationDateStr);
    let commencementDate = new Date(commencementDateStr);
    expirationDate.setDate(expirationDate.getDate() + 1);
    return halfMonthDiff(commencementDate, expirationDate);
};

const monthsDiff = (startDate, endDate) => {
    let startYear,
        startMonth = startDate.getMonth() + 1;
    let endYear,
        endMonth = endDate.getMonth() + 1;
    if ((startYear = startDate.getFullYear()) < (endYear = endDate.getFullYear())) {
        endMonth += (endYear - startYear) * 12;
    }
    return endMonth - startMonth;
};

const halfMonthDiff = (startDate, endDate) => {
    let startDay = startDate.getDate();
    let endDay = endDate.getDate() - 1;

    let diffMonths = monthsDiff(startDate, endDate);
    let roundValue = endDay - startDay;

    if (roundValue < -22) {
        diffMonths--;
    } else if (roundValue < -7) {
        diffMonths -= 0.5;
    } else if (roundValue < 7) {
        //do nothing
    } else if (roundValue < 22) {
        diffMonths += 0.5;
    } else {
        diffMonths++;
    }

    return diffMonths;
};

export const formatDate =
    (format, placeholder = "") =>
    (date) => {
        return date ? moment(date).format(format) : placeholder;
    };

export const formatLocalDate = (date) => {
    if (!isNaN(Date.parse(date))) {
        return date ? moment(date).format("YYYY-MM-DD") : null;
    } else {
        return date;
    }
};

const serializeDate = (date) => {
    return !isEmpty(date) ? moment(date).format("YYYY-MM-DD HH:mm:ss.SSSSSSSSS") : null;
};

export const serializeLocalDateTime = (date) => {
    const stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
};

const serializeLocalDate = (date) => {
    return !isEmpty(date) ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : null;
};

const formatAmount = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
    });

    return formatter.format(amount);
};

const displayNotEmpty = (value) => {
    if (isNull(value)) {
        return "";
    }
    return value;
};

const aggregate = (data, fieldIfNotMultiple, wording) => {
    if (data) {
        return data.length > 1 ? data.length.toString() + " " + wording : get(data[0], fieldIfNotMultiple);
    }
};

export const aggregateBrokers = (data, personPath) => {
    if (data) {
        const cleanedData = compact(data, identity);
        return cleanedData.length > 1
            ? cleanedData.length.toString() + " Brokers"
            : cleanedData.length > 0
            ? get(cleanedData[0], personPath + ".firstName") + " " + get(cleanedData[0], personPath + ".lastName")
            : "";
    }
};

export const aggregateBrokerNames = (brokers) => {
    if (brokers) {
        const cleanedData = compact(brokers, identity);
        return cleanedData.length > 1
            ? cleanedData.length.toString() + " Brokers"
            : cleanedData.length > 0
            ? cleanedData[0]
            : "";
    }
};

export const aggregateBrokersByPersonObjectForTooltip = (data, personPath) => {
    if (data) {
        const cleanedData = compact(data, identity);
        return cleanedData.length > 1
            ? join(
                  cleanedData.map((source) => {
                      return source === null
                          ? ""
                          : `${get(source, `${personPath}.firstName`)} ${get(source, `${personPath}.lastName`)}`;
                  }),
                  ", "
              )
            : cleanedData.length > 0
            ? get(cleanedData[0], personPath + ".firstName") + " " + get(cleanedData[0], personPath + ".lastName")
            : "";
    }
};

export const toDollarAmount = (value, placeholder = "") => {
    return value > 0 ? "$" + numeral(value).format("0,0.00") : placeholder;
};

export const toDollarAmountAcceptZero = (value, placeholder = "") => {
    return !isNull(value) ? "$" + numeral(value).format("0,0.00") : placeholder;
};

export const toTwoDec = (value) => {
    return value && value > 0 ? numeral(value).format("0,0.00") : "";
};

export const toSqft = (value, strictNullCheck = false) => {
    if (strictNullCheck) {
        return value !== null ? numeral(value).format("0,0") + " SF" : "";
    }
    return value ? numeral(value).format("0,0") + " SF" : "";
};

export const minMaxSqftConcat = (min, max) => {
    if (min && max) return `${toSqft(min)} - ${toSqft(max)}`;
    return toSqft(min) || toSqft(max);
};

export const toSqftWithoutAppend = (value, strictNullCheck = false) => {
    if (strictNullCheck) {
        return value !== null ? numeral(value).format("0,0") : "";
    }
    return value ? numeral(value).format("0,0") : "";
};

export const withCommas = (value) => {
    return value ? numeral(value).format("0,0") : "";
};

export const stripCommas = (value) => {
    return value ? (isNaN(value) ? value.replace(/,/g, "") : value) : null;
};

export const yNToBoolean = (value) => {
    if (toUpper(value) === "Y") {
        return true;
    }
    return false;
};

export const booleanToYN = (value) => {
    if (value === true) {
        return "Y";
    }
    return "N";
};

export const binaryToYN = (value) => {
    if (value === 1) {
        return "Y";
    }
    return "N";
};

export const ordinalRepresentation = (n) => {
    // found here http://forums.shopify.com/categories/2/posts/29259
    // https://gist.github.com/jlbruno/1535691/db35b4f3af3dcbb42babc01541410f291a8e8fac
    if (n === null) {
        n = 1;
    }
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const toggleConfirm = (onSubmit) => {
    return (dispatch, getState) => {
        const open = !getState().utilsReducer.confirmDialog.open;
        dispatch(actions.toggleConfirm(open, onSubmit));
    };
};

export const resetConfirm = () => {
    return (dispatch, getState) => {
        dispatch(actions.resetConfirm());
    };
};

export const setLoader = (name, isLoading) => {
    return (dispatch, getState) => {
        dispatch(actions.setLoader(name, isLoading));
    };
};

export const buildAddress = (value) => {
    let address = "";
    address += get(value, "address1", "");
    address += get(value, "address2") ? " " : "";
    address += get(value, "address2") ? get(value, "address2") + ", " : get(value, "address1") ? ", " : "";
    address += get(value, "city.city") ? get(value, "city.city") + ", " : " ";
    address += get(value, "city.state.stateCode", "");
    address += " ";
    address += get(value, "zipCode.zipCode", "");

    return address;
};

export const buildAddressModal = (value) => {
    let address = "";
    address += get(value, "property_address", "");
    address += get(value, "secondary_address") ? " " : "";
    address += get(value, "secondary_address")
        ? get(value, "secondary_address") + ", "
        : get(value, "property_address")
        ? ", "
        : "";
    address += get(value, "city") ? get(value, "city") + ", " : " ";
    address += get(value, "state_code", "");
    address += " ";
    address += get(value, "zip_postal_code", "");

    return address;
};

export const buildAddressFromViewObject = (address1, address2, city, state, zip) => {
    let address = "";
    address += address1 ? address1 : "";
    address += address2 ? " " : "";
    address += address2 ? address2 + ", " : address1 ? ", " : "";
    address += city ? city + ", " : " ";
    address += state ? state : "";
    address += " ";
    address += zip ? zip : "";

    return address;
};

export const countCommaSeparated = (list) => {
    if (list) {
        if (list instanceof Array) {
            return list;
        } else {
            const separatedList = list.split(",");
            return separatedList;
        }
    } else {
        return [];
    }
};

export const countCommaSeparatedBrokerObj = (array) => {
    let list = [];
    if (array != null) {
        for (var i = 0; i < array.length; i++) {
            if (get(array[i], "brokerAgentKey.person", null) != null) {
                list.push(
                    get(array[i], "brokerAgentKey.person.firstName", null) +
                        " " +
                        get(array[i], "brokerAgentKey.person.lastName", null)
                );
            }
        }
    }
    return list;
};

export const filterByQuery = (data, columnsToQuery, query) => {
    let included = false;
    each(columnsToQuery, (column) => {
        if (get(data, column)) {
            if (get(data, column.toString()).toString().toLowerCase().includes(query)) {
                included = true;
            }
        }
    });
    return included;
};

export const filterActiveListings = (data, inactiveOnly) => {
    let included = false;
    if (inactiveOnly) {
        if (includes(["Inactive"], data.status)) {
            included = true;
        }
    } else {
        if (includes(["Active", "Available", "Update Requested", "Pending"], data.status)) {
            included = true;
        }
    }
    return included;
};

export const parseTransactionType = (type) => {
    switch (toLower(type)) {
        case "lease": {
            return "Lease";
        }
        case "for lease": {
            return "Lease";
        }
        case "sale": {
            return "Sale";
        }
        case "for sale": {
            return "Sale";
        }
        default:
            return type;
    }
};

const HOST = configs.HOST_URL + "/";
const reportError = (error, description) => {
    return (dispatch, getState) => {
        const errorInfo = {
            description: "CLIENT ERROR REPORT: " + description,
            currentUrl: window.location.href,
            user: getState().authReducer.user,
            role: getState().authReducer.role,
            error: error,
        };

        request(
            {
                method: "post",
                url: "/logging/error",
                data: JSON.stringify(errorInfo),

                headers: { "Content-Type": "application/json" },
            },
            dispatch
        )
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

const getAllStates = async () => {
    return request({
        url: HOST + "locations/states",
        method: "get",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return "error";
        });
};

export const aggregateFromArrayForDisplay = (data, multiplePhrase) => {
    if (data !== null) {
        if (data.length === 1) {
            return data[0];
        } else {
            return `${data.length} ${multiplePhrase}`;
        }
    }
    return "";
};

export const displayFloorAndSuite = (floor, suite) => {
    return `${ordinalRepresentation(floor)} Floor ${!isNil(suite) && suite !== "" ? ", Suite " + suite : ""}`;
};

export const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})?(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? "+1 " : "";
        const areaCode = match[2] ? "(" + match[2] + ")" : "";
        return [intlCode, areaCode, match[3], "-", match[4]].join("");
    }
    return phoneNumberString;
};

export const formatDollarPreserveNil = (value) => {
    return isNil(value) || value === "" ? null : numeral(value).format("0.00");
};

export const convertForNumberValidation = (value) => {
    return !isNil(value) ? (isNaN(stripCommas(value)) ? value : numeral(value).format("0.00")) : null;
};

export const convertForIntegerValidation = (value) => {
    return !isNil(value) ? (isNaN(stripCommas(value)) ? value : numeral(value).format("0")) : null;
};

export const isBrokerOnDeal = (brokerCommissions, personKey) => {
    const brokerPersonKeys = map(brokerCommissions, (commission) => {
        return get(commission, "brokerAgent.person.personKey");
    });
    return includes(brokerPersonKeys, personKey);
};

export const isBrokerOnListing = (listingBrokers, personKey) => {
    const brokerPersonKeys = map(listingBrokers, (listingBroker) => {
        return get(listingBroker, "brokerAgentKey.person.personKey");
    });
    return includes(brokerPersonKeys, personKey);
};

export const isPropertyInState = (property, stateCode) => {
    return stateCode && stateCode === get(property, "primaryAddress.city.state.stateCode", null);
};

export const keyValueOptionsBuilder = (keyValuePairs) => {
    let options = [{ label: "Select ...", value: "", disabled: true }];
    if (keyValuePairs) {
        options = options.concat(
            Object.keys(keyValuePairs).map((key) => {
                return { label: keyValuePairs[key], value: key };
            })
        );
    }
    return options;
};

export const getFromAttributeValues = (data, attributes, defaultVal) => {
    for (let i = 0; i < attributes.length; i++) {
        if (get(data, attributes[i])) {
            return get(data, attributes[i]);
        }
    }
    return defaultVal;
};

export const decimalToPercent = (value, strictNullCheck = false) => {
    if (strictNullCheck) {
        return value !== null ? round(value, 2) + "%" : "";
    }
    return value ? round(value, 2) + "%" : "";
};

export const clearErrorByField = (field) => {
    return (dispatch) => {
        dispatch(actions.clearErrorByField(field));
    };
};

export const navigateToLandvision = (propertyKey, listingKey) => {
    ReactGA.event("Navigate To Landvision", {
        property: propertyKey,
        listing: listingKey,
    });
    window.open(`${configs.LANDVISION_URL}?resource=property&key=${propertyKey}`, "_blank");
};

export const formatPrice = (price) => {
    return price.toLocaleString("en-US");
};

export const isEmptyStr = (inputStr) => {
    return inputStr.length === 0;
};

export const markerLabelContent = (locations, isListing) => {
    let content = "";
    locations.forEach((location) => {
        const photoUrl = getFromAttributeValues(location, ["propertyPhotoUrl", "property_photo"], "");
        const listingName = getFromAttributeValues(location, ["listingName", "listing_name"], "");
        const address = getFromAttributeValues(location, ["property.primaryAddress.address1", "address1"], "");
        const city = getFromAttributeValues(location, ["property.primaryAddress.city.city", "city"], "");
        const state = getFromAttributeValues(
            location,
            ["property.primaryAddress.city.state.stateCode", "state_code"],
            ""
        );
        const zip = getFromAttributeValues(
            location,
            ["property.primaryAddress.zipCode.zipCode", "zip_postal_code"],
            ""
        );
        const useType = getFromAttributeValues(location, ["useType", "property_type"], "");
        const SQFT = getFromAttributeValues(location, ["sfAvail", "total_transaction_size_sf"], "");
        const transactionType = isListing
            ? get(location, "listingType", "")
            : parseTransactionType(get(location, "transaction_type", ""));
        const detailsUrl = isListing ? `listing/${location.listingKey}` : `comp/${location.dealkey}`;

        content = content.concat(
            `
          <div id="iw-container">
            <div id="iw-image">
            <img src=${photoUrl} height="120" width="120">
            </div>
            <div id="iw-listing">
                <div class="title">
                  <span>${listingName}</span><br/>
                </div>
                <span>${address}</span><br/>
                <span>${city}, ${state} ${zip}</span><br/>
                <div style="padding-top: 5px; padding-bottom: 5px">
                  <span class="body">Type  </span><span>${useType}, ${transactionType}</span><br/>
                </div>
                <span class="body">SQFT  </span><span>${formatPrice(SQFT)}</span><br/>
                  <div style="padding-top: 8px">
                      <a class="link" target="_blank" href="${detailsUrl}">
                        VIEW DETAILS
                      </a><br/>
                  </div>
            </div>
          </div>
        `
        );
    });
    return content;
};

export default {
    apiResponse,
    snackbar,
    round,
    arrayToObject,
    objectToArray,
    calculateExpirationDate,
    calculateTerms,
    monthsDiff,
    halfMonthDiff,
    formatDate,
    formatAmount,
    displayNotEmpty,
    aggregate,
    toDollarAmount,
    toSqft,
    ordinalRepresentation,
    toggleConfirm,
    resetConfirm,
    setLoader,
    serializeDate,
    serializeLocalDate,
    serializeLocalDateTime,
    parseTransactionType,
    reportError,
    withCommas,
    formatLocalDate,
    formatPhoneNumber,
    aggregateBrokerNames,
    isBrokerOnDeal,
    isBrokerOnListing,
    isPropertyInState,
    convertForIntegerValidation,
    keyValueOptionsBuilder,
    getFromAttributeValues,
    calculateDaysBetween,
    aggregateBrokersByPersonObjectForTooltip,
    decimalToPercent,
    getAllStates,
    clearErrorByField,
};
