import types from "./types";

const updateStateDocumentRequirements = (stateDocumentRequirements) => {
    return {
        type: types.UPDATE_STATE_DOCUMENT_REQUIREMENTS,
        stateDocumentRequirements: stateDocumentRequirements,
    };
};

const handleSort = (columnName, sortDirection) => {
    return {
        type: types.SORT_COLUMN,
        columnToSort: columnName,
        sortDirection: sortDirection,
    };
};

const filterDocuments = (query) => {
    return {
        type: types.FILTER_DOCUMENTS,
        filterQuery: query,
    };
};

const getDocumentType = (documentType) => {
    return {
        type: types.GET_DOCUMENT_TYPE,
        documentType: documentType,
    };
};

const clearErrors = () => {
    return {
        type: types.CLEAR_REQUEST_DOCUMENT_UPDATE_ERRORS,
    };
};

const addError = (field, error) => {
    console.log(field);
    return {
        type: types.ADD_DOCUMENT_TYPE_UPDATE_ERRORS,
        field: field,
        error: error,
    };
};
export default {
    updateStateDocumentRequirements,
    getDocumentType,
    handleSort,
    filterDocuments,
    clearErrors,
    addError,
};
