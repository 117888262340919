import types from "./types";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import setWith from "lodash/setWith";
import clone from "lodash/clone";

function Contact() {
    this.contactInfoKey = null;
    this.personContactInfoKey = null;
    this.personKey = null;
    this.firstName = null;
    this.lastName = null;
    this.addressKey = null;
    this.address1 = null;
    this.cityKey = null;
    this.city = null;
    this.key = null;
    this.zipCode = null;
    this.plus4 = null;
    this.stateCode = null;
    this.phoneNumber = null;
    this.emailAddress = null;
    this.company = null;
}
export default (
    state = {
        contacts: {},
        deal: {},
    },
    action
) => {
    switch (action.type) {
        case types.INIT_CONTACTS:
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    [action.contactType]: action.value,
                },
            };

        case types.ADD_CONTACT:
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    [action.contactType]: {
                        ...state.contacts[action.contactType],
                        [action.key]: new Contact(),
                    },
                },
            };

        case types.UPDATE_CONTACT:
            //console.log("contact.actions.contacts before update");
            //console.log(state.contacts);
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    [action.contactType]: {
                        ...state.contacts[action.contactType],
                        [action.key]: {
                            ...state.contacts[action.contactType][action.key],
                            [action.field]: action.value,
                        },
                    },
                },
            };

        case types.UPDATE_CONTACT_COMPANY:
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    [action.contactType]: {
                        ...state.contacts[action.contactType],
                        [action.key]: {
                            ...state.contacts[action.contactType][action.key],
                            company: {
                                ...state.contacts[action.contactType][action.key]["company"],
                                [action.field]: action.value,
                            },
                        },
                    },
                },
            };
        case types.DELETE_CONTACT:
            let contacts = cloneDeep(state.contacts);
            delete contacts[action.contactType][action.key];
            return {
                ...state,
                contacts: {
                    ...contacts,
                },
            };

        case types.ADD_CONTACT_ELEMENT:
            return setWith(clone(state), `${action.contactType}[${action.index}]`, {}, clone);

        case types.DELETE_CONTACT_ELEMENT:
            let elements = cloneDeep(get(state, `${action.contactType}`, []));
            elements.splice(action.index, 1);

            const setObj = setWith(clone(state), `${action.contactType}`, elements, clone);
            return setObj;

        case types.UPDATE_CONTACT_ELEMENT:
            return setWith(clone(state), `${action.contactType}[${action.index}].${action.field}`, action.value, clone);
        case types.INITIALIZE_CONTACTS:
            return setWith(clone(state), `${action.contactType}`, action.contacts, clone);
        case types.UPDATE_CONTACT_ELEMENT_COMPANY:
            return setWith(
                clone(state),
                `${action.contactType}[${action.index}].company.${action.field}`,
                action.value,
                clone
            );
        default:
            return state;
    }
};
