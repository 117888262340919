import React from "react";

import operations from "./ducks/operations";
import connect from "react-redux/es/connect/connect";
import Companies from "./Companies";
import searchOperations from "../search/ducks/operations";
import ReactGA from "react-ga4";

class CompaniesContainer extends React.Component {
    componentDidMount() {
        this.props.switchTab(null, this.props.companyTab);
        ReactGA.send({ hitType: "pageview", page: "/admin/companies", title: "Company Admin" });
    }

    render() {
        return <Companies {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        companies: state.adminReducer.tabs[state.adminReducer.companyTab],
        companyTab: state.adminReducer.companyTab ? state.adminReducer.companyTab : "unapproved_companies",
        columnToSort: state.adminReducer.columnToSort,
        sortDirection: state.adminReducer.sortDirection,
        filterQuery: state.adminReducer.filterQuery,
        isLoading: state.utilsReducer.isLoading,
        userProfile: state.authReducer.userProfile,
        selectedApprovedCompanies: state.adminReducer.selectedApprovedCompanies,
        selectedUnapprovedCompanies: state.adminReducer.selectedUnapprovedCompanies,
        company: state.adminReducer.company,
        autocompleteResults: state.searchReducer.autocompleteResults,
        errors: state.utilsReducer.errors ? state.utilsReducer.errors : {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchTab: (event, value) => {
            dispatch(operations.switchCompanyTab(value));
        },
        handleSort: (columnName) => {
            dispatch(operations.handleSort(columnName));
        },
        handleSearchCompanies: () => {
            dispatch(operations.getCompaniesByStatus());
        },
        addCompany: () => {
            dispatch(operations.clearCompany());
            dispatch(operations.addCompany());
        },
        handleFilter: (query) => {
            dispatch(operations.filterCompanies(query));
            dispatch(operations.clearCheckboxes());
        },
        handleChangeCompanySearchType: (companySearchType) => {
            dispatch(operations.handleChangeCompanySearchType(companySearchType));
        },
        handleCheckboxClick: (key, companyTab) => {
            dispatch(operations.checkboxClick(key, companyTab));
        },
        handleClearCheckboxes: () => {
            dispatch(operations.clearCheckboxes());
        },
        clearCompany: () => {
            dispatch(operations.clearCompany());
        },
        handleInputChange: (field, value) => {
            dispatch(operations.handleInputChange(field, value));
        },
        handleSearchParentCompany: (term, type) => {
            dispatch(searchOperations.searchParentCompany(term, type));
        },
        getCompany: (id) => {
            dispatch(operations.getCompany(id));
        },
        handleSubmit: (selectedCompanies) => {
            dispatch(operations.mergeCompanies(selectedCompanies));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesContainer);
