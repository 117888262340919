const MEDIA_URL = "/media/";
const VOUCHER_URL = "/vouchers/";
const COMPLIANCE_URL = "compliance/";
const urls = {
    get_document: (documentKey) => {
        return VOUCHER_URL + "document/" + documentKey;
    },
    get_voucher_documents: (voucherKey) => {
        return VOUCHER_URL + "documents/" + voucherKey;
    },
    save_document: (voucherKey) => {
        return VOUCHER_URL + "document/" + voucherKey;
    },
    delete_document: (documentId) => {
        return VOUCHER_URL + "document/" + documentId;
    },
    update_voucher_document: (documentKey) => {
        return VOUCHER_URL + "document/" + documentKey;
    },
    get_property_docs: (listingKey) => {
        return MEDIA_URL + "property/" + listingKey;
    },
    copy_property_photo_to_listing: (listingKey) => {
        return MEDIA_URL + "copy/photo/" + listingKey;
    },
    view_document: (documentKey) => {
        return MEDIA_URL + documentKey;
    },
    add_document: (entityKey, type) => {
        return MEDIA_URL + type + "/" + entityKey;
    },
    remove_document: (documentKey) => {
        return MEDIA_URL + documentKey;
    },
    approve_document: (documentKey) => {
        return MEDIA_URL + "approve/" + documentKey;
    },
    update_document: (documentKey) => {
        return MEDIA_URL + documentKey;
    },
    get_documents: (entityKey, type) => {
        return MEDIA_URL + "find/" + type + "/" + entityKey;
    },
    download_document: (documentKey) => {
        return MEDIA_URL + "download/" + documentKey;
    },
    get_document_requirements_by_parent_entity_type_key: () => COMPLIANCE_URL + "document-requirements",
    get_images_for_owner: (mediaOwnerType, ownerId) => {
        return MEDIA_URL + "find/images/" + mediaOwnerType + "/" + ownerId;
    },
};

export default {
    urls,
};
