import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import ErrorBoundary from "../../../../common/js/components/ErrorBoundary";
import MultiAddInput from "../../../../common/js/components/MultiAddInput";
import CondoDetail from "./CondoDetailBeta";
import "./Condo.css";

const CondoForm = (props) => {
    const {
        data,
        dataPathPrefix,
        disableInputs,
        handleAddArrayElement,
        handleDeleteArrayElement,
        handleUpdateArrayElement,
        onChange,
    } = props;

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                    <span className="switchTitle">Condo Information</span>
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={data.condoFlg === true ? true : false}
                                onChange={(e, checked) => onChange(e.target.value, checked)}
                                color="primary"
                                value="condoFlg"
                                disabled={disableInputs}
                                className="theSwitch"
                            />
                        }
                    />
                </div>
            </div>
            {data.condoFlg && (
                <ErrorBoundary>
                    <MultiAddInput
                        addButtonText="Add Condo"
                        disableInputs={disableInputs}
                        displayFirstWhenEmpty={true}
                        elements={data.condos}
                        onAdd={() => handleAddArrayElement(dataPathPrefix + ".condos")}
                        onUpdate={(e, index) =>
                            handleUpdateArrayElement(dataPathPrefix + ".condos", index, e.target.id, e.target.value)
                        }
                        render={(element, elementIndex) => (
                            <CondoDetail
                                {...props}
                                element={element}
                                elementIndex={elementIndex}
                                onDelete={(index) => handleDeleteArrayElement(dataPathPrefix + ".condos", index)}
                                disableInputs={disableInputs}
                            />
                        )}
                    />
                </ErrorBoundary>
            )}
        </div>
    );
};

export default CondoForm;
