const UPDATE_SEARCH_CRITERIA = "UPDATE_SEARCH_CRITERIA";
const REPLACE_SEARCH_CRITERIA = "REPLACE_SEARCH_CRITERIA";
const REPLACE_SEARCH_CRUMBS = "REPLACE_SEARCH_CRUMBS";
const LOAD_SEARCH_RESPONSE = "LOAD_SEARCH_RESPONSE";
const RESET_SEARCH_CRITERIA = "RESET_SEARCH_CRITERIA";
const EXECUTE_SEARCH = "EXECUTE_SEARCH";
const UPDATE_SPECIALTY_TYPE_CRITERIA = "UPDATE_SPECIALTY_TYPE_CRITERIA";
const UPDATE_PROPERTY_TYPE_CRITERIA = "UPDATE_PROPERTY_TYPE_CRITERIA";
const UPDATE_PROPERTY_TYPE = "UPDATE_PROPERTY_TYPE";
const UPDATE_BUILDING_CLASS_CRITERIA = "UPDATE_BUILDING_CLASS_CRITERIA";
const APPEND_SEARCH_RESULTS = "APPEND_SEARCH_RESULTS";
const UPDATE_TENANCY_TYPE_CRITERIA = "UPDATE_TENANCY_TYPE_CRITERIA";
const CLEAR_FILTERS = "CLEAR_FILTERS";
const LOAD_DATA_EXPORT = "LOAD_DATA_EXPORT";
const CLEAR_DATA_EXPORT = "CLEAR_DATA_EXPORT";
const INITIATE_DATA_EXPORT = "INITIATE_DATA_EXPORT";
const FILTER_TABLE_DATA = "FILTER_TABLE_DATA";
const SORT_TABLE_DATA = "SORT_TABLE_DATA";
const SEARCH_RESULTS_X = "SEARCH_RESULTS_X";
const AUTOCOMPLETE_RESULTS = "AUTOCOMPLETE_RESULTS";
const AUTOCOMPLETE_SEARCH_TERM = "AUTOCOMPLETE_SEARCH_TERM";
const UPDATE_CRUMB = "UPDATE_CRUMB";
const UPDATE_RANGE_CRUMB = "UPDATE_RANGE_CRUMB";
const CLEAR_CRUMBS = "CLEAR_CRUMBS";
const CLEAR_LOADED_CRUMBS = "CLEAR_LOADED_CRUMBS";
const LOAD_CRUMBS = "LOAD_CRUMBS";
const LOADING_SEARCH_COMPS = "LOADING_SEARCH_COMPS";
const CLEAR_SEARCH_RESPONSE = "CLEAR_SEARCH_RESPONSE";
const MAP_CRUMB_CLEAR = "MAP_CRUMB_CLEAR";
const STORE_SEARCH_CLEARED = "STORE_SEARCH_CLEARED";
const UPDATE_SEARCH_POLYGON = "UPDATE_SEARCH_POLYGON";
const SAVE_MAP_SEARCH_SETTINGS = "SAVE_MAP_SEARCH_SETTINGS";
const VIEW_SAVED_MODAL = "VIEW_SAVED_MODAL";
const NAME_SAVE_MODAL = "NAME_SAVE_MODAL";
const ON_SEARCH_PAGE = "ON_SEARCH_PAGE";
const LOAD_SAVED_SEARCHES = "LOAD_SAVED_SEARCHES";
const LOAD_SAVED_GROUPS = "LOAD_SAVED_GROUPS";
const CLEAR_SAVED_SEARCHES = "CLEAR_SAVED_SEARCHES";
const STORE_SEARCH_RUN = "STORE_SEARCH_RUN";
const SET_SEARCH_NAME = "SET_SEARCH_NAME";
const SELECTED_ARRAY_FROM_SAVE = "SELECTED_ARRAY_FROM_SAVE";
const SEARCH_FROM_SAVE = "SEARCH_FROM_SAVE";
const SAVE_SELECTED_ONLY = "SAVE_SELECTED_ONLY";
const RETAIN_SELECTED_ITEMS = "RETAIN_SELECTED_ITEMS";
const STORE_SAVED_COORDS = "STORE_SAVED_COORDS";
const SET_CURRENT_GROUP = "SET_CURRENT_GROUP";
const SET_SAVED_GROUPS = "SET_SAVED_GROUPS";
const ON_GROUP_PAGE = "ON_GROUP_PAGE";

export default {
    UPDATE_SEARCH_CRITERIA,
    RESET_SEARCH_CRITERIA,
    EXECUTE_SEARCH,
    UPDATE_SPECIALTY_TYPE_CRITERIA,
    UPDATE_PROPERTY_TYPE_CRITERIA,
    UPDATE_BUILDING_CLASS_CRITERIA,
    UPDATE_PROPERTY_TYPE,
    LOAD_SEARCH_RESPONSE,
    APPEND_SEARCH_RESULTS,
    UPDATE_TENANCY_TYPE_CRITERIA,
    CLEAR_FILTERS,
    LOAD_DATA_EXPORT,
    CLEAR_DATA_EXPORT,
    INITIATE_DATA_EXPORT,
    FILTER_TABLE_DATA,
    SORT_TABLE_DATA,
    SEARCH_RESULTS_X,
    AUTOCOMPLETE_RESULTS,
    AUTOCOMPLETE_SEARCH_TERM,
    UPDATE_CRUMB,
    UPDATE_RANGE_CRUMB,
    CLEAR_CRUMBS,
    CLEAR_LOADED_CRUMBS,
    LOAD_CRUMBS,
    LOADING_SEARCH_COMPS,
    CLEAR_SEARCH_RESPONSE,
    REPLACE_SEARCH_CRITERIA,
    MAP_CRUMB_CLEAR,
    STORE_SEARCH_CLEARED,
    STORE_SEARCH_RUN,
    UPDATE_SEARCH_POLYGON,
    SAVE_MAP_SEARCH_SETTINGS,
    VIEW_SAVED_MODAL,
    ON_SEARCH_PAGE,
    ON_GROUP_PAGE,
    LOAD_SAVED_SEARCHES,
    LOAD_SAVED_GROUPS,
    NAME_SAVE_MODAL,
    SET_SEARCH_NAME,
    SAVE_SELECTED_ONLY,
    CLEAR_SAVED_SEARCHES,
    REPLACE_SEARCH_CRUMBS,
    SELECTED_ARRAY_FROM_SAVE,
    SEARCH_FROM_SAVE,
    RETAIN_SELECTED_ITEMS,
    STORE_SAVED_COORDS,
    SET_CURRENT_GROUP,
    SET_SAVED_GROUPS,
};
