import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { LeftChevron } from "../../../common/js/icons";

const styles = {
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    leftIcon: {
        marginLeft: "25px",
    },
    progressDiv: {
        textAlign: "center",
        width: "5em",
        display: "inline-block",
    },
};

const EditTransactionToolbar = (props) => {
    const {
        classes,
        newTransaction,
        elementsSaving,
        saveTransaction, //options: {key, complete, buttonClicked}
        transaction,
    } = props;

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.pageToolbar} spacing={16}>
            <Grid item zeroMinWidth style={{ paddingBottom: "2px", paddingRight: "0px" }}>
                <Link to="/transactions">
                    <LeftChevron />
                </Link>
            </Grid>
            <Grid item xs>
                <h2 className="page-title">{newTransaction ? "Create New Transaction" : "Edit Transaction"}</h2>
            </Grid>
            <Grid item xs>
                <Grid container alignItems="center" spacing={0} justify="flex-end">
                    <Grid item className={classes.leftIcon}>
                        {elementsSaving.saveAndExit ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                    saveTransaction({
                                        key: transaction.transactionKey,
                                        complete: false,
                                        buttonClicked: "saveAndExit",
                                    })
                                }
                            >
                                Save & Exit
                            </Button>
                        )}
                        {elementsSaving.submit ? (
                            <div className={classes.progressDiv}>
                                <CircularProgress size={20} color="primary" />
                            </div>
                        ) : (
                            (newTransaction || transaction.status === "Draft") && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                        saveTransaction({
                                            key: transaction.transactionKey,
                                            complete: true,
                                            buttonClicked: "submit",
                                        })
                                    }
                                >
                                    Complete Transaction
                                </Button>
                            )
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(EditTransactionToolbar);
