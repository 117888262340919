import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import get from "lodash/get";
import FormTitle from "../../../common/js/components/FormTitle";

const PublicSiteDisplaySetting = (props) => {
    const { listing, handleListingChange, disableInputs } = props;
    const handleListingAttributeChange = (field, value) => {
        handleListingChange("attributes." + field, value);
    };
    return (
        <React.Fragment>
            <FormTitle display="inline-block" title="Public Site Display Settings" />
            <Grid container alignItems="flex-start" spacing={8}>
                <Grid item xs={4}>
                    <FormGroup>
                        <FormControlLabel
                            className="publicSiteDisplaySettings-formControlLabel"
                            control={
                                <Checkbox
                                    checked={get(listing, "attributes.optOutFlg", false) ? true : false}
                                    onChange={(e, checked) => {
                                        handleListingAttributeChange(e.target.value, checked);
                                        handleListingChange("dateListed", null);
                                    }}
                                    disabled={disableInputs === true}
                                    value="optOutFlg"
                                    color="primary"
                                />
                            }
                            label="Opt Out: kidder.com"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        className="publicSiteDisplaySettings-formControlLabel"
                        control={
                            <Checkbox
                                checked={get(listing, "attributes.hidePropertyInfoFlg", false) ? true : false}
                                onChange={(e, checked) => {
                                    handleListingAttributeChange(e.target.value, checked);
                                }}
                                disabled={disableInputs === true}
                                value="hidePropertyInfoFlg"
                                color="primary"
                            />
                        }
                        label="Hide Property Info"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        className="publicSiteDisplaySettings-formControlLabel"
                        control={
                            <Checkbox
                                checked={get(listing, "attributes.hideListingInfoFlg", false) ? true : false}
                                onChange={(e, checked) => {
                                    handleListingAttributeChange(e.target.value, checked);
                                }}
                                disabled={disableInputs === true}
                                value="hideListingInfoFlg"
                                color="primary"
                            />
                        }
                        label="Hide Listing Info"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const styles = {};

export default withStyles(styles)(PublicSiteDisplaySetting);
