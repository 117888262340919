const SET_PARENT_ENTITY_TYPE_KEY = "SET_PARENT_ENTITY_TYPE_KEY";
const GET_ACTION_TYPES = "GET_ACTION_TYPES";
const GET_DOCUMENT_TYPES = "GET_DOCUMENT_TYPES";
const GET_CONDITION_TYPES = "GET_CONDITION_TYPES";
const GET_CONDITION_OPERATORS = "GET_CONDITION_OPERATORS";
const GET_STATES = "GET_STATES";
const UPDATE_CONDITION_OPERATORS_DROPDOWN = "UPDATE_CONDITION_OPERATORS_DROPDOWN";
const SELECT_CONDITIONS = "SELECT_FROM_CONDITIONS";
const SELECT_FROM_ACTIONS = "SELECT_FROM_ACTIONS";
const GET_RULES_BY_PARENT_ENTITY_TYPE_KEY = "GET_RULES_BY_PARENT_ENTITY_TYPE_KEY";
const ADD_CONDITION = "ADD_CONDITION";
const REMOVE_CONDITION = "REMOVE_CONDITION";
const ADD_ACTION = "ADD_ACTION";
const REMOVE_ACTION = "REMOVE_ACTION";
const HANDLE_RULE_INPUT_CHANGE = "HANDLE_INPUT_CHANGE";
const UPDATE_DOCUMENT_TYPE_DROPDOWN = "UPDATE_DOCUMENT_TYPE_DROPDOWN";
const SELECT_CONDITION_TYPE = "SELECT_CONDITION_TYPE";
const SAVE_RULE = "SAVE_RULE";
const ADD_RULE = "ADD_RULE";
const EDIT_RULE = "EDIT_RULE";
const TOGGLE_MODAL = "TOGGLE_MODAL";
const UPDATE_CONDITION_TYPE_DROPDOWN = "UPDATE_CONDITION_TYPE_DROPDOWN";
const UPDATE_RULE_ORDER = "UPDATE_RULE_ORDER";
const GET_RULES_BY_DOC_TYPE_KEY = "GET_RULES_BY_DOC_TYPE_KEY";
const REVERT_DROPDOWN_DISPLAYS = "REVERT_DROPDOWN_DISPLAYS";
const RESET_PRIORITIES = "RESET_PRIORITIES";
const GET_ALL_PARENT_ENTITY_TYPES = "GET_ALL_PARENT_ENTITY_TYPES";

export default {
    SET_PARENT_ENTITY_TYPE_KEY,
    GET_ACTION_TYPES,
    GET_DOCUMENT_TYPES,
    GET_CONDITION_TYPES,
    GET_CONDITION_OPERATORS,
    GET_STATES,
    UPDATE_CONDITION_OPERATORS_DROPDOWN,
    SELECT_CONDITIONS,
    SELECT_FROM_ACTIONS,
    GET_RULES_BY_PARENT_ENTITY_TYPE_KEY,
    ADD_CONDITION,
    REMOVE_CONDITION,
    ADD_ACTION,
    REMOVE_ACTION,
    HANDLE_RULE_INPUT_CHANGE,
    UPDATE_DOCUMENT_TYPE_DROPDOWN,
    SELECT_CONDITION_TYPE,
    SAVE_RULE,
    ADD_RULE,
    EDIT_RULE,
    TOGGLE_MODAL,
    UPDATE_CONDITION_TYPE_DROPDOWN,
    UPDATE_RULE_ORDER,
    GET_RULES_BY_DOC_TYPE_KEY,
    REVERT_DROPDOWN_DISPLAYS,
    RESET_PRIORITIES,
    GET_ALL_PARENT_ENTITY_TYPES,
};
