import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { withStyles } from "@material-ui/core/styles";
import { acceptedFileTypes } from "../utils/constants";

const styles = () => ({
    dropzoneStyle: {
        position: "relative",
        height: "100vh",
        width: "100vw",
        border: "none",
    },
    dropzoneInstructionStyle: {
        textAlign: "center",
        backgroundColor: "#3f3f3f",
        "& > h4": {
            fontSize: "26px",
            marginBottom: "15px",
        },
    },
    icon: {
        marginRight: "10px",
        width: "50px",
        height: "50px",
        marginBottom: "30px",
    },
    overlayStyle: {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        color: "#ffffff",
        zIndex: 9999,
    },
});

const FullPageDocumentDrop = (props) => {
    const [isDragActive, setIsDragActive] = useState(false);

    useEffect(() => {
        const handleWindowDragEnter = (event) => {
            if (
                event.dataTransfer.items &&
                event.dataTransfer.items.length &&
                Array.from(event.dataTransfer.items).some((item) => item.kind === "file")
            ) {
                setIsDragActive(true);
            }
        };

        const handleWindowDragOver = (event) => {
            event.preventDefault(); // Necessary to allow the drop event to fire
        };

        const handleWindowDragLeave = (event) => {
            if (
                event.clientX <= 1 ||
                event.clientY <= 1 ||
                event.clientX >= window.innerWidth - 1 ||
                event.clientY >= window.innerHeight - 1
            ) {
                setIsDragActive(false);
            }
        };

        const handleWindowMouseLeave = () => {
            setIsDragActive(false);
        };

        const handleWindowDrop = () => {
            setIsDragActive(false);
        };

        window.addEventListener("dragenter", handleWindowDragEnter);
        window.addEventListener("dragover", handleWindowDragOver);
        window.addEventListener("dragleave", handleWindowDragLeave);
        window.addEventListener("mouseleave", handleWindowMouseLeave);
        window.addEventListener("drop", handleWindowDrop);

        return () => {
            window.removeEventListener("dragenter", handleWindowDragEnter);
            window.removeEventListener("dragover", handleWindowDragOver);
            window.removeEventListener("dragleave", handleWindowDragLeave);
            window.removeEventListener("drop", handleWindowDrop);
        };
    }, []);

    const { classes, addDocuments, disableUploads, entityKey, handleRejectedDocs, type, viewOnly } = props;

    const onDrop = (acceptedFiles, rejectedFiles) => {
        setIsDragActive(false);
        addDocuments(entityKey, type, acceptedFiles, type);
        if (rejectedFiles.length > 0) {
            handleRejectedDocs(rejectedFiles);
        }
    };

    return (
        <React.Fragment>
            {isDragActive && !disableUploads && !viewOnly && (
                <Dropzone
                    accept={acceptedFileTypes}
                    onDrop={onDrop}
                    disableClick={true}
                    disablePreview={true}
                    className={classes.dropzoneStyle}
                >
                    <div className={classes.overlayStyle}>
                        <CloudUploadIcon className={classes.icon} />
                        <div className={classes.dropzoneInstructionStyle}>
                            <h4>Drop files here to attach</h4>
                            <p>(.csv, .doc, .docx, .jpg, .msg, .pdf, and .png files only)</p>
                        </div>
                    </div>
                </Dropzone>
            )}
        </React.Fragment>
    );
};

export default withStyles(styles)(FullPageDocumentDrop);
