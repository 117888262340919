import React from "react";
import { CircularProgress, Modal } from "@material-ui/core";
import GenerateImageEditorModal from "./GenerateImageEditorModal";

const GenerateImageEditorModalContainer = (props) => {
    const { modalContent, elementsSaving, handleCloseModal } = props;

    return (
        <React.Fragment>
            <Modal open={modalContent.isOpen} onClose={handleCloseModal}>
                {elementsSaving.gettingPhoto || elementsSaving.retrievingImagesForOwner ? (
                    <CircularProgress style={{ position: "absolute", top: "50%", left: "50%" }} />
                ) : (
                    <GenerateImageEditorModal {...props} handleCloseModal={handleCloseModal} />
                )}
            </Modal>
        </React.Fragment>
    );
};

export default GenerateImageEditorModalContainer;
