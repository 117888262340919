const TOGGLE_PROPERTY_LISTING_MODAL = "TOGGLE_PROPERTY_LISTING_MODAL";
const UPDATE_PROPERTY_REQUEST = "UPDATE_PROPERTY_REQUEST";
const TOGGLE_PROPERTY_SEARCH_MODAL = "TOGGLE_PROPERTY_SEARCH_MODAL";
const UPDATE_PROPERTY_FOR_LISTING_DIALOG = "UPDATE_PROPERTY_FOR_LISTING_DIALOG";
const ADD_REQUEST_PROPERTY_MODAL_ERROR = "ADD_REQUEST_PROPERTY_MODAL_ERROR";
const UPDATE_PROPERTY_SEARCH_FILTER = "UPDATE_PROPERTY_SEARCH_FILTER";
const CLEAR_PROPERTY_SEARCH_FILTERS = "CLEAR_PROPERTY_SEARCH_FILTERS";
const TOGGLE_PROPERTY_TYPE_EXPANSION_PANEL = "TOGGLE_PROPERTY_TYPE_EXPANSION_PANEL";
const LOAD_PROPERTY = "LOAD_PROPERTY";
const ADD_ARRAY_ELEMENT = "ADD_ARRAY_ELEMENT_PROPERTY";
const DELETE_ARRAY_ELEMENT = "DELETE_ARRAY_ELEMENT_PROPERTY";
const RECEIVE_REVIEW_PROPERTIES = "RECEIVE_REVIEW_PROPERTIES";
const SWITCH_REVIEW_PROPERTIES_TAB = "SWITCH_REVIEW_PROPERTIES_TAB";
const FILTER_PROPERTIES = "FILTER_PROPERTIES";
const SORT_REVIEW_PROPERTIES = "SORT_REVIEW_PROPERTIES";
const PROPERTY_INPUT_CHANGE = "PROPERTY_INPUT_CHANGE";
const SWITCH_PROPERTY_DETAIL_TAB = "SWITCH_PROPERTY_DETAIL_TAB";
const ADD_PROPERTY_MANAGEMENT_SECTION = "ADD_PROPERTY_MANAGEMENT_SECTION";
const SET_PROPERTY_ARRAY = "SET_PROPERTY_ARRAY";
const LOAD_PROPERTY_IMAGES = "LOAD_PROPERTY_IMAGES";
const RESET = "RESET";
const COUNT_PROPERTY_RESULTS = "COUNT_PROPERTY_RESULTS";
const TOGGLE_MARKET_FILTER_EXPANSION_PANEL = "TOGGLE_MARKET_FILTER_EXPANSION_PANEL";
const UPDATE_DELETE_DIALOG = "UPDATE_DELETE_DIALOG";
const UPDATE_NO_DELETE_DIALOG = "UPDATE_NO_DELETE_DIALOG";

export default {
    TOGGLE_PROPERTY_SEARCH_MODAL,
    UPDATE_PROPERTY_FOR_LISTING_DIALOG,
    TOGGLE_PROPERTY_LISTING_MODAL,
    UPDATE_PROPERTY_REQUEST,
    ADD_REQUEST_PROPERTY_MODAL_ERROR,
    UPDATE_PROPERTY_SEARCH_FILTER,
    CLEAR_PROPERTY_SEARCH_FILTERS,
    TOGGLE_PROPERTY_TYPE_EXPANSION_PANEL,
    LOAD_PROPERTY,
    ADD_ARRAY_ELEMENT,
    DELETE_ARRAY_ELEMENT,
    RECEIVE_REVIEW_PROPERTIES,
    SWITCH_REVIEW_PROPERTIES_TAB,
    FILTER_PROPERTIES,
    SORT_REVIEW_PROPERTIES,
    PROPERTY_INPUT_CHANGE,
    SWITCH_PROPERTY_DETAIL_TAB,
    ADD_PROPERTY_MANAGEMENT_SECTION,
    SET_PROPERTY_ARRAY,
    LOAD_PROPERTY_IMAGES,
    RESET,
    COUNT_PROPERTY_RESULTS,
    TOGGLE_MARKET_FILTER_EXPANSION_PANEL,
    UPDATE_DELETE_DIALOG,
    UPDATE_NO_DELETE_DIALOG,
};
