import configs from "../../../config";

const HOST = configs.HOST_URL + "/";

const REQUIRED_DOC_URL = HOST + "required-documents/";
const REQUIRED_DOC_BY_STATE_URL = REQUIRED_DOC_URL + "state/";

const PARENT_ENTITY_URL = HOST + "parent-entities";
const DOCUMENT_TYPES_URL = HOST + "document-types/";
const LOCATION_URL = HOST + "locations/";

const urls = {
    get_docs_by_state: (stateCode) => {
        return REQUIRED_DOC_BY_STATE_URL + stateCode;
    },
    get_all_parent_entities: () => {
        return PARENT_ENTITY_URL;
    },
    get_all_document_types: () => {
        return DOCUMENT_TYPES_URL;
    },
    get_save_updated_dtsr: () => {
        return REQUIRED_DOC_URL;
    },
    get_save_document_type: (documentTypeKey) => {
        return DOCUMENT_TYPES_URL + documentTypeKey;
    },
    delete_document_type: (documentTypeKey) => {
        return DOCUMENT_TYPES_URL + documentTypeKey;
    },
    get_location_states: () => {
        return LOCATION_URL + "states";
    },
    create_new_document_type: () => {
        return DOCUMENT_TYPES_URL;
    },
};

export default {
    urls,
};
