import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import BSSelect from "../../../common/js/components/BSSelect";
import operations from "./ducks/operations";
import { quarters } from "../utils/constants";
import {
    yearsForResearchSnapshot,
    marketAreasForResearchSnapshot,
    submarketOptionsForResearch,
    micromarketOptionsForResearch,
    propertyTypeFilterOptionsForResearchSnapshot,
} from "../property/constants";

class ResearchSnapshotModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            year: yearsForResearchSnapshot()[1].value,
            quarter: "Select",
            marketArea: "Select",
            subMarketArea: "All",
            microMarketArea: "All",
            propertyType: "All",
        };
    }

    onExitModal = () => {
        this.setState({
            sending: false,
            year: yearsForResearchSnapshot()[1].value,
            quarter: "Select",
            marketArea: "Select",
            subMarketArea: "All",
            microMarketArea: "All",
            propertyType: "All",
        });
        this.props.closeModal();
    };

    onSubmit = async () => {
        this.setState({ sending: true });
        const status = await operations.uploadResearchCaptureJob(
            this.state.year,
            this.state.quarter,
            this.state.marketArea,
            this.state.propertyType,
            this.state.subMarketArea,
            this.state.microMarketArea
        );
        if (status >= 200 && status < 300) {
            this.props.snapshotResponse(true);
        } else {
            this.props.snapshotResponse(false);
        }
        this.onExitModal();
    };

    handleQuarterUpdate = (event) => {
        this.setState({
            quarter: event.target.value,
        });
    };

    handleYearUpdate = (event) => {
        this.setState({
            year: event.target.value,
        });
    };

    handleMarketAreaUpdate = (event) => {
        this.setState({
            marketArea: event.target.value,
            subMarketArea: "All",
            microMarketArea: "All",
        });
    };

    handleSubMarketAreaUpdate = (event) => {
        this.setState({
            subMarketArea: event.target.value,
            microMarketArea: "All",
        });
    };

    handleMicroMarketAreaUpdate = (event) => {
        this.setState({
            microMarketArea: event.target.value,
        });
    };

    handlePropertyTypeUpdate = (event) => {
        this.setState({
            propertyType: event.target.value,
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Modal
                open={this.props.modalOpen}
                onClose={() => this.onExitModal()}
                style={{
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10vh",
                }}
            >
                <div className={classes.container}>
                    <h4 className={classes.header}>Capture Research</h4>
                    <Typography variant="body1" style={{ marginRight: "24px", marginLeft: "24px" }}>
                        Scroll and use the drop downs below to capture research for a corresponding Year, Quarter,
                        Market Area, Submarket Area, Micromarket Area, and Property Type.
                    </Typography>
                    <div className={classes.selectionColumn}>
                        <BSSelect
                            id="yearSelect"
                            label="Year"
                            value={this.state.year}
                            onChange={this.handleYearUpdate}
                            placeholder="Select a year"
                            options={yearsForResearchSnapshot()}
                            disabled={false}
                        />
                        <BSSelect
                            id="quarterSelect"
                            label="Quarter"
                            value={this.state.quarter}
                            onChange={this.handleQuarterUpdate}
                            placeholder="Select a quarter"
                            options={quarters}
                            disabled={false}
                        />
                        <BSSelect
                            id="marketAreaSelect"
                            label="Market Area"
                            value={this.state.marketArea}
                            onChange={this.handleMarketAreaUpdate}
                            placeholder="Select a market area"
                            options={marketAreasForResearchSnapshot()}
                            disabled={false}
                        />
                        <BSSelect
                            id="subMarketAreaSelect"
                            label="SubMarket"
                            value={this.state.subMarketArea}
                            onChange={this.handleSubMarketAreaUpdate}
                            placeholder="Select a submarket area"
                            options={submarketOptionsForResearch(this.state.marketArea)}
                            disabled={this.state.marketArea !== "Select" ? false : true}
                        />
                        <BSSelect
                            id="microMarketAreaSelect"
                            label="MicroMarket"
                            value={this.state.microMarketArea}
                            onChange={this.handleMicroMarketAreaUpdate}
                            placeholder="Select a micromarket area"
                            options={micromarketOptionsForResearch(this.state.marketArea, this.state.subMarketArea)}
                            disabled={this.state.marketArea !== "Select" ? false : true}
                        />
                        <BSSelect
                            id="propertyTypeSelect"
                            label="Property Type"
                            value={this.state.propertyType}
                            onChange={this.handlePropertyTypeUpdate}
                            placeholder="Select a property type"
                            options={propertyTypeFilterOptionsForResearchSnapshot()}
                            disabled={false}
                        />
                    </div>
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "end",
                            margin: "0vw 1vw 1vw 0vw",
                        }}
                    >
                        <Button
                            style={{ display: "inline-block", marginLeft: "10px" }}
                            variant="outlined"
                            onClick={() => this.onExitModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ display: "inline-block", marginLeft: "10px" }}
                            variant="contained"
                            color="primary"
                            disabled={
                                this.state.quarter !== "Select" &&
                                this.state.marketArea !== "Select" &&
                                this.state.sending === false
                                    ? false
                                    : true
                            }
                            onClick={() => this.onSubmit()}
                        >
                            Capture
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const styles = (theme) => ({
    header: {
        marginTop: "45px",
        textAlign: "left",
        fontSize: "18px",
        marginLeft: "24px",
    },
    container: {
        margin: "0 auto",
        marginTop: "10%",
        position: "relative",
        alignItems: "center",
        borderRadius: "3px",
        "&:focus": {
            outline: "none",
        },
        width: "525px",
        height: "675px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        overflowY: "auto",
    },
    selectionColumn: {
        margin: "24px 24px 0px 24px",
    },
    select: {
        marginTop: "50px",
        marginBottom: "10px",
    },
});

export default withStyles(styles)(ResearchSnapshotModal);
