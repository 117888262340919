import React from "react";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import get from "lodash/get";
import { ExpansionPanelDetails, ExpansionPanelSummary, FormControlLabel } from "@material-ui/core";
import ExpandMoreIcon from "../../../../node_modules/@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import { marketFilters } from "./constants";
import { us_states_map } from "../utils/constants";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import each from "lodash/each";

class MarketFilters extends React.Component {
    countMarketSubmarketsSelected = (searchCriteria, path) => {
        let count = 0;
        each(Object.keys(get(searchCriteria, `locationCriteria.markets.${path}`, {})), (key) => {
            if (get(searchCriteria, `locationCriteria.markets.${path}.${key}.selected`, false) === true) {
                count += 1;
            }
        });
        return count;
    };

    render() {
        const {
            classes,
            searchCriteria,
            toggleMarketFilterExpansionPanel,
            marketFilterExpansionPanels,
            handleSelectMarket,
            handleSelectSubmarket,
            handleSelectMicromarket,
        } = this.props;

        return (
            <Grid container>
                <Grid container>
                    {Object.keys(marketFilters).map((stateCode) => {
                        return (
                            <React.Fragment>
                                <FormLabel
                                    component="legend"
                                    style={{ marginBottom: "10px", paddingLeft: "20px", paddingTop: "10px" }}
                                >
                                    {us_states_map[stateCode]}
                                </FormLabel>
                                {Object.keys(marketFilters[stateCode]).map((market) => {
                                    return (
                                        <Grid item xs={12}>
                                            <ExpansionPanel
                                                expanded={get(marketFilterExpansionPanels, market, false)}
                                                classes={{
                                                    root: classes.expansionPanel,
                                                }}
                                            >
                                                <ExpansionPanelSummary
                                                    classes={{
                                                        root: classes.expansionPanelSummary,
                                                        expanded: classes.expansionPanelSummaryExpanded,
                                                        content: classes.expansionPanelSummaryContent,
                                                    }}
                                                    expandIcon={
                                                        <ExpandMoreIcon
                                                            onClick={() => {
                                                                toggleMarketFilterExpansionPanel(market);
                                                            }}
                                                        />
                                                    }
                                                >
                                                    <Grid container>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={get(
                                                                            searchCriteria,
                                                                            `locationCriteria.markets.${market}.selected`,
                                                                            false
                                                                        )}
                                                                        onChange={(e) =>
                                                                            handleSelectMarket(market, e.target.checked)
                                                                        }
                                                                        value={market}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={`${market} ${
                                                                    this.countMarketSubmarketsSelected(
                                                                        searchCriteria,
                                                                        market
                                                                    ) > 0
                                                                        ? `(${this.countMarketSubmarketsSelected(
                                                                              searchCriteria,
                                                                              market
                                                                          )})`
                                                                        : ""
                                                                }`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails
                                                    classes={{
                                                        root: classes.expansionPanelDetails,
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={12} className={classes.gridItemCheckbox}>
                                                            <h4 className={classes.menuSectionTitle}>
                                                                {market} Submarkets
                                                            </h4>
                                                        </Grid>
                                                        {Object.keys(marketFilters[stateCode][market]).map(
                                                            (submarket) => {
                                                                if (
                                                                    marketFilters[stateCode][market][submarket].length >
                                                                    0
                                                                ) {
                                                                    return (
                                                                        <Grid item xs={12}>
                                                                            <ExpansionPanel
                                                                                expanded={get(
                                                                                    marketFilterExpansionPanels,
                                                                                    `${market}.${submarket}`,
                                                                                    false
                                                                                )}
                                                                                classes={{
                                                                                    root: classes.expansionPanel,
                                                                                }}
                                                                            >
                                                                                <ExpansionPanelSummary
                                                                                    classes={{
                                                                                        root: classes.expansionPanelSummary,
                                                                                        expanded:
                                                                                            classes.expansionPanelSummaryExpanded,
                                                                                        content:
                                                                                            classes.expansionPanelSummaryContent,
                                                                                    }}
                                                                                    expandIcon={
                                                                                        marketFilters[stateCode][
                                                                                            market
                                                                                        ][submarket].length > 0 ? (
                                                                                            <ExpandMoreIcon
                                                                                                onClick={() => {
                                                                                                    toggleMarketFilterExpansionPanel(
                                                                                                        `${market}.${submarket}`
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            ""
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Grid container>
                                                                                        <Grid item>
                                                                                            <FormControlLabel
                                                                                                control={
                                                                                                    <Checkbox
                                                                                                        checked={get(
                                                                                                            searchCriteria,
                                                                                                            `locationCriteria.markets.${market}.${submarket}.selected`,
                                                                                                            false
                                                                                                        )}
                                                                                                        onChange={(e) =>
                                                                                                            handleSelectSubmarket(
                                                                                                                market,
                                                                                                                submarket,
                                                                                                                e.target
                                                                                                                    .checked
                                                                                                            )
                                                                                                        }
                                                                                                        value={
                                                                                                            submarket
                                                                                                        }
                                                                                                        color="primary"
                                                                                                    />
                                                                                                }
                                                                                                label={`${submarket} ${
                                                                                                    this.countMarketSubmarketsSelected(
                                                                                                        searchCriteria,
                                                                                                        `${market}.${submarket}`
                                                                                                    ) > 0
                                                                                                        ? `(${this.countMarketSubmarketsSelected(
                                                                                                              searchCriteria,
                                                                                                              `${market}.${submarket}`
                                                                                                          )})`
                                                                                                        : ""
                                                                                                }`}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </ExpansionPanelSummary>
                                                                                <ExpansionPanelDetails
                                                                                    classes={{
                                                                                        root: classes.expansionPanelDetails,
                                                                                    }}
                                                                                >
                                                                                    <Grid container>
                                                                                        <Grid
                                                                                            item
                                                                                            xs={12}
                                                                                            className={
                                                                                                classes.gridItemCheckbox
                                                                                            }
                                                                                        >
                                                                                            <h4
                                                                                                className={
                                                                                                    classes.menuSectionTitle
                                                                                                }
                                                                                            >
                                                                                                {market} - {submarket}{" "}
                                                                                                Micromarkets
                                                                                            </h4>
                                                                                        </Grid>
                                                                                        {marketFilters[stateCode][
                                                                                            market
                                                                                        ][submarket].map(
                                                                                            (micromarket) => {
                                                                                                return (
                                                                                                    <Grid
                                                                                                        item
                                                                                                        xs={6}
                                                                                                        className={
                                                                                                            classes.gridItemCheckbox
                                                                                                        }
                                                                                                    >
                                                                                                        <FormControlLabel
                                                                                                            control={
                                                                                                                <Checkbox
                                                                                                                    checked={get(
                                                                                                                        searchCriteria,
                                                                                                                        `locationCriteria.markets.${market}.${submarket}.${micromarket}.selected`,
                                                                                                                        false
                                                                                                                    )}
                                                                                                                    onChange={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        handleSelectMicromarket(
                                                                                                                            market,
                                                                                                                            submarket,
                                                                                                                            micromarket,
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .checked
                                                                                                                        )
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        micromarket
                                                                                                                    }
                                                                                                                    color="primary"
                                                                                                                />
                                                                                                            }
                                                                                                            label={
                                                                                                                micromarket
                                                                                                            }
                                                                                                        />
                                                                                                    </Grid>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Grid>
                                                                                </ExpansionPanelDetails>
                                                                            </ExpansionPanel>
                                                                        </Grid>
                                                                    );
                                                                }
                                                                return null;
                                                            }
                                                        )}
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </Grid>
            </Grid>
        );
    }
}

const styles = (theme) => ({
    menuSectionTitle: {
        color: "#9E9E9E",
        fontSize: "13px",
        fontWeight: 600,
        textTransform: "uppercase",
        marginBottom: "5px",
    },
    expansionPanel: {
        margin: 0,
        borderTop: "1px solid #eeeeee",
    },
    expansionPanelSummary: {
        minHeight: "0 !important",
    },
    expansionPanelSummaryExpanded: {
        margin: "0 !important",
        background: "#fafafa",
    },
    expansionPanelSummaryContent: {
        margin: 0,
    },
    expansionPanelDetails: {
        padding: "0 20px",
        borderTop: "1px solid #eeeeee",
    },
});

export default withStyles(styles)(MarketFilters);
