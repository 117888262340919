import React, { useEffect } from "react";
import { connect } from "react-redux";
import ComplianceRulesByDocumentType from "./ComplianceRulesByDocumentType";
import { Divider } from "@material-ui/core";
import complianceOperations from "../compliance/ducks/operations";

const ComplianceRulesByDocumentTypeContainer = (props) => {
    const { getRulesByDocTypeKey, rules, resetRules } = props;

    useEffect(() => {
        const { id } = props.match.params;
        if (id !== undefined) getRulesByDocTypeKey(id);
        return () => {
            resetRules();
        };
    }, []);

    return (
        <React.Fragment>
            {rules.map((rule) => {
                return (
                    <React.Fragment>
                        <ComplianceRulesByDocumentType {...props} rule={rule} />
                        <Divider />
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    rules: state.complianceReducer.rules,
});

const mapDispatchToProps = (dispatch) => ({
    getRulesByDocTypeKey: (docKey) => {
        dispatch(complianceOperations.getRulesByDocTypeKey(docKey));
    },
    resetRules: () => {
        dispatch(complianceOperations.resetRules());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceRulesByDocumentTypeContainer);
