import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import set from "lodash/set";
import schema from "./voucherTables";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { aggregateBrokerNames } from "../utils/operations";
import ReactTooltip from "react-tooltip";
import BSInput from "../../../common/js/components/BSInput";
import StatusChip from "../../../common/js/components/StatusChip";
import { Search, Play, Sorty } from "../../../common/js/icons";
import "../../styles/voucherDashboard.css";
import moment from "moment";

const styles = (theme) => ({
    root: {},
    label: {
        textTransform: "none",
    },
    indicator: {},
    pageToolbar: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    connectorActive: {
        "& $connectorLine": {
            borderColor: theme.palette.secondary.main,
        },
    },
    connectorCompleted: {
        "& $connectorLine": {
            borderColor: theme.palette.primary.main,
        },
    },
    connectorDisabled: {
        "& $connectorLine": {
            borderColor: theme.palette.grey[100],
        },
    },
    connectorLine: {
        transition: theme.transitions.create("border-color"),
    },
    listingIdLink: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#2C99D2",
        fontWeight: 600,
    },
    idDisplay: {
        fontWeight: 600,
    },
    alternateContent: {
        margin: "auto",
        textAlign: "center",
        marginTop: "4em",
        marginBottom: "1em",
    },
    tooltip: {
        "&>a:hover": {
            cursor: "pointer",
        },
    },
    tooltipIcon: {
        "&:hover": {
            cursor: "pointer",
        },
        color: "grey",
    },
    processLink: {
        "&:hover": {
            cursor: "pointer",
            color: "#2C99D2",
        },
        color: "#595959",
        textDecoration: "underline",
    },
    undoProcess: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
        color: "#595959",
        fontSize: "80%",
        marginLeft: "3px",
    },
    sortable: {
        paddingRight: "5px",
        paddingBottom: "3px",
    },
    dateRangeInput: {
        width: "30%",
    },
    applyFilterButton: {
        marginRight: "10px",
        marginLeft: "0px",
    },
    dateFilterSelector: {
        flexGrow: "inherit",
        width: "11em",
        marginBottom: "25x",
        marginLeft: "5px",
        marginRight: "5px",
    },
    checkboxCell: {
        paddingLeft: "0px",
    },
});

class AccountingVouchers extends Component {
    render() {
        const {
            userProfile,
            header,
            classes,
            vouchers,
            selectedBoxes,
            isLoading,
            handleSort,
            filterQuery,
            voucherView,
            handleSelect,
            columnToSort,
            sortDirection,
            generateBatch,
            reopenVouchers,
            handleSelectAll,
            handleCheckboxClick,
            toggleProcessedBy,
            togglePrintedBy,
            elementsSaving,
            handleViewDeal,
            handleViewVoucherDeal,
            handleApplyDateRangeFilter,
            accountingBatchViewInputParams,
            handleChangeAccountingInputParam,
            errors,
        } = this.props;

        let voucherz = get(vouchers, voucherView, []);
        const selected = get(selectedBoxes, voucherView, []);
        const sortFunction = get(
            get(schema, `viewHeaders.${voucherView}`).find((x) => x.prop === columnToSort),
            "sortFunction"
        );

        if (sortFunction && voucherz) {
            voucherz = voucherz.map((voucher) => {
                set(voucher, columnToSort, sortFunction(get(voucher, columnToSort)));
                return voucher;
            });
        }

        const data = orderBy(
            filterQuery
                ? voucherz.filter((x) => schema.filterByQuery(x, schema.columnsToQuery, filterQuery))
                : voucherz,
            [
                (v) => {
                    return get(v, columnToSort) || "";
                },
            ],
            sortDirection
        );

        return (
            <div>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.pageToolbar}
                    spacing={16}
                >
                    <Grid item>
                        <h2 className="page-title">Vouchers</h2>
                        <p className="page-subtitle">
                            Showing {voucherz ? voucherz.length : 0}{" "}
                            {!voucherz || voucherz.length > 1 || voucherz.length === 0 ? "vouchers" : "voucher"}
                        </p>
                    </Grid>
                    <Grid item>
                        {voucherView === "accounting_ready_payment" && (
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={userProfile.role !== "accounting"}
                                onClick={generateBatch}
                            >
                                <Play fill="#ffffff" />
                                Generate Batch Report
                            </Button>
                        )}
                        {voucherView === "accounting_closed" && (
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={userProfile.role !== "accounting"}
                                onClick={reopenVouchers}
                            >
                                Un-batch Selected Vouchers
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={16}>
                    <Grid item xs>
                        <div className="simPaper">
                            <Grid container justify="space-between" alignItems="center" spacing={0}>
                                <Grid item>
                                    <Tabs
                                        classes={classes}
                                        value={this.props.voucherView}
                                        onChange={this.props.switchVoucherView}
                                    >
                                        <Tab
                                            classes={classes}
                                            value="accounting_awaiting_payment"
                                            label="Awaiting Payment"
                                        />
                                        <Tab
                                            classes={classes}
                                            value="accounting_ready_payment"
                                            label="Ready For Payment"
                                        />
                                        <Tab classes={classes} value="accounting_closed" label="Batched Vouchers" />
                                    </Tabs>
                                </Grid>
                                {voucherView === "accounting_closed" && (
                                    <React.Fragment>
                                        <Grid item>
                                            <Grid container alignItems="center" spacing={0} justify="flex-end">
                                                <Grid item align="center">
                                                    <div style={{ color: "grey" }}>Start / End Date: </div>
                                                </Grid>
                                                <Grid item className={classes.dateFilterSelector}>
                                                    <BSInput
                                                        id="startDate"
                                                        type={"date"}
                                                        value={get(accountingBatchViewInputParams, "startDate")}
                                                        errorText={get(errors, `startDate`, null)}
                                                        error={get(errors, `startDate`, null)}
                                                        onChange={handleChangeAccountingInputParam}
                                                        placeholder="Start Date"
                                                    />
                                                </Grid>
                                                <Grid item align="center">
                                                    <div style={{ color: "grey" }}>&mdash;</div>
                                                </Grid>
                                                <Grid item className={classes.dateFilterSelector}>
                                                    <BSInput
                                                        id="endDate"
                                                        errorText={get(errors, `endDate`, null)}
                                                        type={"date"}
                                                        value={get(accountingBatchViewInputParams, "endDate")}
                                                        error={get(errors, `endDate`, null)}
                                                        onChange={handleChangeAccountingInputParam}
                                                        placeholder="End Date"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleApplyDateRangeFilter}
                                                        className={classes.applyFilterButton}
                                                    >
                                                        Apply Filter
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <BSInput
                                                        value={this.props.filterQuery}
                                                        placeholder="Search vouchers"
                                                        startAdornment={<Search />}
                                                        onChange={(event) => {
                                                            this.props.handleFilter(event.target.value);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </Grid>
                            <Grid container spacing={0}>
                                <Grid item xs>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {voucherView !== "accounting_awaiting_payment" && (
                                                    <TableCell className={classes.checkboxCell}>
                                                        <Checkbox
                                                            indeterminate={
                                                                selected.length > 0 && selected.length < voucherz.length
                                                            }
                                                            checked={selected.length === voucherz.length}
                                                            value={selected.length === voucherz.length}
                                                            onChange={(e) => handleSelectAll(e, voucherView)}
                                                            color="primary"
                                                        />
                                                    </TableCell>
                                                )}
                                                {schema.viewHeaders[voucherView].map((x, i) => (
                                                    <TableCell classes={classes} numeric={x.numeric} key={`thc-${i}`}>
                                                        <div
                                                            style={{
                                                                alignItems: "center",
                                                            }}
                                                            onClick={() => handleSort(x.sort ? x.sort : x.prop)}
                                                        >
                                                            <div style={{ display: "inline-block" }}>
                                                                <span className={classes.sortable}>{x.name}</span>
                                                                {x.name && (
                                                                    <div style={{ display: "inline-block" }}>
                                                                        <Sorty />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!elementsSaving[voucherView] &&
                                                data.map((x, index) =>
                                                    row(
                                                        x,
                                                        index,
                                                        header,
                                                        classes,
                                                        selected,
                                                        isLoading,
                                                        voucherView,
                                                        handleSelect,
                                                        handleCheckboxClick,
                                                        toggleProcessedBy,
                                                        elementsSaving,
                                                        togglePrintedBy,
                                                        handleViewDeal,
                                                        handleViewVoucherDeal
                                                    )
                                                )}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            {elementsSaving[voucherView] ? (
                                <div className={classes.alternateContent}>
                                    <CircularProgress color="primary" />
                                </div>
                            ) : data.length > 0 ? null : (
                                <div className={classes.alternateContent}>Nothing to show.</div>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const row = (
    x,
    index,
    header,
    classes,
    selected,
    isLoading,
    voucherView,
    handleSelect,
    handleCheckboxClick,
    toggleProcessedBy,
    elementsSaving,
    togglePrintedBy,
    handleViewDeal,
    handleViewVoucherDeal
) => {
    return isLoading ? null : (
        <TableRow key={`tr-${index}`} selectable="false">
            {voucherView !== "accounting_awaiting_payment" && (
                <TableCell className={classes.checkboxCell}>
                    <Checkbox
                        id={`ch-${index}`}
                        checked={selected.indexOf(`ch-${index}`) !== -1}
                        onChange={() => handleCheckboxClick(`ch-${index}`, voucherView, get(x, "voucherKey"))}
                        color="primary"
                    />
                </TableCell>
            )}
            {schema.viewHeaders[voucherView].map((y, k) =>
                y.name === "Deal" ? (
                    <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                        <span className={classes.listingIdLink} onClick={() => handleViewVoucherDeal(x)}>
                            {get(x, "dealKey")}{" "}
                            {get(x, "listingName", null) !== null
                                ? ` | ${get(x, "listingName")}`
                                : get(x, "consultingDealName", null) !== null
                                ? ` | ${get(x, "consultingDealName")}`
                                : ""}
                        </span>
                        <div>{get(x, "address1")}</div>
                    </TableCell>
                ) : y.prop === "voucherKey" ? (
                    <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                        <Link to={`/voucher/${get(x, y.prop)}`} style={{ textDecoration: "none" }}>
                            <span className={classes.listingIdLink}>{get(x, y.prop)}</span>
                        </Link>
                    </TableCell>
                ) : y.prop === "brokers" && !elementsSaving[voucherView] ? (
                    <TableCell classes={{ root: classes.body }} key={`trc-${k}`}>
                        <span className={classes.tooltip} data-tip={get(x, "brokers", "")}>
                            {aggregateBrokerNames(get(x, "brokers"))}
                        </span>
                        <ReactTooltip place="top" type="dark" effect="solid" />
                    </TableCell>
                ) : y.name === "Processed By" ? (
                    <TableCell style={{ textAlign: "left" }} classes={{ root: classes.body }} key={`trc-${k}`}>
                        {get(x, y.prop) ? (
                            <React.Fragment>
                                <div style={{ fontWeight: 600 }}>{get(x, y.prop)}</div>
                                <span>{moment(get(x, "acceptedDate")).format("M-D-YYYY h:mm a")}</span>
                                <span
                                    onClick={() => toggleProcessedBy(true, get(x, "voucherKey"), voucherView)}
                                    className={classes.undoProcess}
                                >
                                    (undo)
                                </span>
                            </React.Fragment>
                        ) : (
                            <span
                                className={classes.processLink}
                                onClick={() => toggleProcessedBy(false, get(x, "voucherKey"), voucherView)}
                            >
                                Process
                            </span>
                        )}
                    </TableCell>
                ) : y.name === "Printed By" ? (
                    <TableCell style={{ textAlign: "left" }} classes={{ root: classes.body }} key={`trc-${k}`}>
                        {get(x, y.prop) ? (
                            <React.Fragment>
                                <div>Printed by {get(x, y.prop)}</div>
                                <span
                                    onClick={() => togglePrintedBy(true, get(x, "voucherKey"), voucherView)}
                                    className={classes.undoProcess}
                                >
                                    (undo)
                                </span>
                            </React.Fragment>
                        ) : (
                            <span
                                className={classes.processLink}
                                onClick={() => togglePrintedBy(false, get(x, "voucherKey"), voucherView)}
                            >
                                Print
                            </span>
                        )}
                    </TableCell>
                ) : y.transform ? (
                    <TableCell classes={{ root: classes.body }} key={`trc-${k}`} numeric={y.numeric}>
                        {y.transform(get(x, y.prop))}
                    </TableCell>
                ) : y.prop === "voucherStatus" ? (
                    <TableCell>
                        <StatusChip status={get(x, y.prop)} />
                    </TableCell>
                ) : (
                    <TableCell classes={{ root: classes.body }} key={`trc-${k}`} numeric={y.numeric}>
                        {get(x, y.prop)}
                    </TableCell>
                )
            )}
        </TableRow>
    );
};

export default withStyles(styles)(AccountingVouchers);
