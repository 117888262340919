export const consultingDealTypes = [
    {
        value: "BOV",
        label: "BOV",
    },
    {
        value: "Lease Termination Fee",
        label: "Lease Termination Fee",
    },
    {
        value: "Referral",
        label: "Referral",
    },
    {
        value: "Consulting Fee",
        label: "Consulting Fee",
    },
    {
        value: "Loan Placement",
        label: "Loan Placement",
    },
    {
        value: "Debt & Equity Fee",
        label: "Debt & Equity Fee",
    },
];
