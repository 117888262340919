import React from "react";
import InvoicePeriod from "./InvoicePeriod";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import get from "lodash/get";
import utils from "../utils/index";
import isEmpty from "lodash/isEmpty";

class InvoiceDetail extends React.Component {
    render() {
        const { invoice } = this.props;

        if (get(invoice, "summary") && !isEmpty(get(invoice, "periods", null))) {
            return (
                <div className="TableSuperHeaderContainer">
                    <Grid container justify="space-between" alignItems="center" spacing={0}>
                        <Grid item>
                            <span className="preTableHeader">Calculated Total Consideration (Reference Only)</span>
                            <br />
                            <span className="preTableHeaderSub">
                                {invoice["summary"].totalMonths} months / {invoice["summary"].freeMonths} months free /{" "}
                                {invoice["summary"].discountedMonths} months discounted
                                {invoice["summary"].preTermMonths + invoice["summary"].postTermMonths > 0
                                    ? ", " +
                                      (invoice["summary"].preTermMonths + invoice["summary"].postTermMonths) +
                                      " months free outside term"
                                    : ""}
                            </span>
                        </Grid>
                        <Grid item>
                            <span className="preTableHeaderValue">
                                {utils.operations.toDollarAmount(invoice["summary"].totalAmount)} total
                            </span>
                        </Grid>
                    </Grid>

                    <Divider />
                    {invoice &&
                        invoice["periods"] &&
                        Object.keys(invoice["periods"]).map((period, i) => (
                            <InvoicePeriod period={invoice["periods"][period]} key={i} {...this.props} />
                        ))}
                </div>
            );
        } else {
            return null;
        }
    }
}

export default InvoiceDetail;
