import React from "react";
import BSInput from "../../../common/js/components/BSInput";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import BSSelect from "../../../common/js/components/BSSelect";
import { propertyTypeFilterOptions } from "./constants";
import Button from "@material-ui/core/Button";
import Delete from "../../../../node_modules/@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";

import toLower from "lodash/toLower";

const styles = (theme) => ({
    iconOnly: {
        marginLeft: 0,
        marginBottom: "10px",
        padding: 0,
        "&:hover": {
            background: "transparent",
        },
    },
    theCounter: {
        border: "1px solid #2C99D2",
        borderRadius: "50%",
        display: "inline-block",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 600,
        width: "20px",
        height: "20px",
        marginBottom: "10px",
    },
    centerCell: {
        textAlign: "center",
        fill: "#9e9e9e",
        marginTop: "-25px",
    },
    shrinkButton: {
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        margin: 0,
        color: "#9e9e9e",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
});
class MixedUseDetail extends React.Component {
    render() {
        const {
            classes,
            dataPathPrefix,
            element,
            elementIndex,
            errors,
            handleUpdateArrayElement,
            disableInputs,
            onDelete,
        } = this.props;
        return (
            <div>
                <Grid
                    container
                    alignItems="center"
                    spacing={8}
                    style={elementIndex === 0 ? { height: "90px" } : { height: "90px", marginTop: "-30px" }}
                >
                    <Grid item xs={1}>
                        <span className={classes.theCounter}>{parseInt(elementIndex) + 1}</span>
                    </Grid>
                    <Grid item xs={4}>
                        <BSSelect
                            id="propertyType"
                            label={elementIndex === 0 ? "Property Type" : null}
                            error={get(errors, `mixedUses.${elementIndex}.propertyType`, null)}
                            value={element ? element.propertyType : null}
                            onChange={(e) =>
                                handleUpdateArrayElement(
                                    dataPathPrefix + ".mixedUses",
                                    elementIndex,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            options={propertyTypeFilterOptions()}
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs>
                        <BSInput
                            id="sf"
                            label={elementIndex === 0 ? "Square Feet" : null}
                            errorText="(ex. 98101 or 981.01)"
                            placeholder="SF"
                            value={element ? element.sf : null}
                            error={get(errors, `mixedUses.${elementIndex}.sf`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement(
                                    dataPathPrefix + ".mixedUses",
                                    elementIndex,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            disabled={disableInputs}
                        />
                    </Grid>
                    <Grid item xs>
                        <BSInput
                            id="unitCount"
                            label={elementIndex === 0 ? "Unit Count" : null}
                            placeholder="Unit Count"
                            value={element ? element.unitCount : null}
                            error={get(errors, `mixedUses.${elementIndex}.unitCount`, null)}
                            onChange={(e) =>
                                handleUpdateArrayElement(
                                    dataPathPrefix + ".mixedUses",
                                    elementIndex,
                                    e.target.id,
                                    e.target.value
                                )
                            }
                            disabled={
                                disableInputs ||
                                (element &&
                                    toLower(element.propertyType) !== "hospitality" &&
                                    toLower(element.propertyType) !== "multifamily")
                            }
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.centerCell}>
                        {elementIndex > 0 && disableInputs !== true && (
                            <Button onClick={() => onDelete(elementIndex)} className={classes.shrinkButton}>
                                <Delete />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(MixedUseDetail);
