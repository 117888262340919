import React from "react";
import toLower from "lodash/toLower";
import { Checkbox, FormControlLabel } from "@material-ui/core/";
import BSInput from "../../../../common/js/components/BSInput";
import MultiAddInput from "../../../../common/js/components/MultiAddInput";
import Anchor from "./AnchorBeta";
import ErrorBoundary from "../../../../common/js/components/ErrorBoundary";
import "./Retail.css";

const RetailForm = (props) => {
    const {
        data,
        dataPathPrefix,
        disableInputs,
        errors,
        handleAddArrayElement,
        handleDeleteArrayElement,
        handleUpdateArrayElement,
        listingStatus,
        onChange,
        transactionType,
    } = props;

    const handleAttributeUpdate = (event) => {
        onChange(event.target.id, event.target.value);
    };

    return (
        <div>
            <div className="retailFlexContainer">
                <div className="retailFlexField">
                    <BSInput
                        id="singleTenantUse"
                        label="SINGLE TENANT PROPERTY USE"
                        placeholder="Enter single tenant property use"
                        error={errors.singleTenantUse}
                        value={data.singleTenantUse}
                        onChange={handleAttributeUpdate}
                        disabled={disableInputs}
                    />
                </div>
                <div className="retailFlexField">
                    <BSInput
                        id="multiTenantType"
                        label="MULTI TENANT PROPERTY TYPE"
                        placeholder="Enter multi tenant property type"
                        error={errors.multiTenantType}
                        value={data.multiTenantType}
                        onChange={handleAttributeUpdate}
                        disabled={disableInputs}
                    />
                </div>
            </div>
            {toLower(transactionType) === "sale" && listingStatus === "Appraisal" && (
                <div className="retailFlexContainer">
                    <div className="retailFlexField">
                        <BSInput
                            id="anchorSf"
                            label="Anchor SF"
                            placeholder="Enter anchor sf"
                            error={errors.anchorSf}
                            value={data.anchorSf}
                            onChange={handleAttributeUpdate}
                            disabled={disableInputs}
                            integer
                        />
                    </div>
                    <div className="retailFlexField">
                        <BSInput
                            id="padSf"
                            label="Pad SF"
                            placeholder="Enter pad sf"
                            error={errors.padSf}
                            value={data.padSf}
                            onChange={handleAttributeUpdate}
                            disabled={disableInputs}
                            integer
                        />
                    </div>
                    <div className="retailFlexField">
                        <BSInput
                            id="shopSf"
                            label="Shop SF"
                            placeholder="Enter shop sf"
                            error={errors.shopSf}
                            value={data.shopSf}
                            onChange={handleAttributeUpdate}
                            disabled={disableInputs}
                            integer
                        />
                    </div>
                </div>
            )}
            <div className="retailFlexContainer">
                <div className="retailCheckbox">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={data.liveWorkFlg ? true : false}
                                onChange={(e, checked) => onChange(e.target.value, checked)}
                                value="liveWorkFlg"
                                disabled={disableInputs === true}
                                color="primary"
                            />
                        }
                        label="Live/Work"
                    />
                </div>
                {toLower(transactionType) === "sale" && listingStatus === "Appraisal" && (
                    <div className="retailCheckbox">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.shadowAnchoredFlg ? true : false}
                                    onChange={(e, checked) => onChange(e.target.value, checked)}
                                    value="shadowAnchoredFlg"
                                    disabled={disableInputs === true}
                                    color="primary"
                                />
                            }
                            label="Shadow Anchored"
                        />
                    </div>
                )}
            </div>
            <ErrorBoundary>
                <MultiAddInput
                    addButtonText="Add Anchor"
                    disableInputs={disableInputs === true}
                    displayFirstWhenEmpty={Boolean(true)}
                    elements={data.anchors}
                    onAdd={() => handleAddArrayElement(dataPathPrefix + ".anchors")}
                    onUpdate={(e, index) =>
                        handleUpdateArrayElement(dataPathPrefix + ".anchors", index, e.target.id, e.target.value)
                    }
                    render={(element, elementIndex) => (
                        <Anchor
                            element={element}
                            elementIndex={elementIndex}
                            onDelete={(index) => handleDeleteArrayElement(dataPathPrefix + ".anchors", index)}
                            {...props}
                        />
                    )}
                />
            </ErrorBoundary>
        </div>
    );
};

export default RetailForm;
