import types from "./types";
import cloneDeep from "lodash/cloneDeep";
import setWith from "lodash/setWith";

export default (
    state = {
        transactions: {},
        transaction: {
            open: {},
            closed: {},
        },
        transactionsTab: "open",
        numResults: {},
    },
    action
) => {
    switch (action.type) {
        case types.LOAD_TRANSACTIONS_FOR_USER:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    [action.transactionsType]: action.transactions,
                },
            };
        case types.LOAD_TRANSACTION:
            return {
                ...state,
                transaction: action.transaction,
            };
        case types.FILTER_DEBT_EQUITY_TRANSACTIONS:
            return Object.assign({}, state, {
                filterQuery: action.filterQuery,
            });
        case types.SORT_DEBT_EQUITY_TRANSACTIONS:
            return Object.assign({}, state, {
                columnToSort: action.columnToSort,
                sortDirection: action.sortDirection,
            });
        case types.SWITCH_TRANSACTIONS_TAB:
            return Object.assign({}, state, {
                transactionsTab: action.tab,
            });
        case types.COUNT_DEBT_EQUITY_RESULTS:
            return Object.assign({}, state, {
                numResults: {
                    ...state.numResults,
                    [action.tab]: action.numResults,
                },
            });
        case types.ADD_DEBT_EQUITY_TRANSACTION_INPUT:
            return setWith(cloneDeep(state), `${action.field}`, action.value, Object);
        case types.SET_DEBT_EQUITY_ARRAY:
            return setWith(cloneDeep(state), `${action.pathToArray}`, action.array, Object);
        default:
            return state;
    }
};
